import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_REACTIONS,
  DELETE_POST,
  ADD_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_POST_WITH_COMMENTS,
  LOGOUT,
  SET_POST_REDUCER_LOADING,
  GET_POSTS_FOR_GROUP,
  OPEN_POST_REACTIONS,
  SET_PROFILE_REDUCER_LOADING_FALSE,
  SET_POST_REDUCER_LOADING_FALSE,
} from '../types';

const initialState = {
  posts: [],
  post: null,
  loading: true,
  error: {},
  userReaction: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_POSTS:
      const posts = payload;
      return {
        ...state,
        posts: posts, //payload will be the array of posts
        loading: false,
        error: {}, // clear any previous errors if loaded successfully
      };
    case GET_POSTS_FOR_GROUP:
      const groupPosts = payload;
      return {
        ...state,
        posts: groupPosts, //payload will be the array of posts
        loading: false,
        error: {}, // clear any previous errors if loaded successfully
      };
    case GET_POST_WITH_COMMENTS:
      return {
        ...state,
        post: { ...payload.post, comments: payload.comments },
        // comments: payload.comments,
        loading: false,
      };
    case ADD_POST:
      const newPost = payload;
      return {
        ...state,
        posts: [newPost, ...state.posts],
        loading: false,
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== payload), //payload is post id
        post: state.post
          ? state.post._id.toString() === payload
            ? null
            : state.post
          : null,
        // comments: null,
        loading: false,
      };

    case UPDATE_REACTIONS:
      // update 'POST' in redux
      let newP = { ...state.post };
      // if post is liked
      if (state.post && state.post._id.toString() === payload.postId) {
        newP = { ...state.post, reactions: payload.postReactions };
      } else if (
        // if a comment in post is liked
        state.post &&
        state.post.comments.some(
          (c) => c._id.toString() === payload.postId.toString()
        )
      ) {
        let newC = state.post.comments.map((c) => {
          if (c._id.toString() === payload.postId) {
            return { ...c, reactions: payload.postReactions };
          } else {
            return c;
          }
        });
        newP = { ...state.post, comments: newC };
      }
      // --------------------------------------------------------

      // update 'POSTS' in redux
      let newP2 = {};
      let newPosts = [];
      // if post is comment
      if (payload.parentPost) {
        // if list of posts are in redux, create new version of post
        if (state.posts) {
          state.posts.map((p) => {
            if (p._id === payload.parentPost) {
              // loop over comments of this
              let newC2 = p.comments
                ? p.comments.map((c) => {
                    if (c._id.toString() === payload.postId) {
                      return { ...c, reactions: payload.postReactions };
                    } else {
                      return c;
                    }
                  })
                : [];
              newP2 = { ...p, comments: newC2 };
            }
            return p;
          });
        }
        // update 'posts' with 'newPosts'
        newPosts = state.posts.map((p) => {
          return p._id.toString() === newP2._id ? { ...newP2 } : p;
        });
      } else {
        // if post is post
        newPosts = state.posts.map((p) =>
          p._id.toString() === payload.postId
            ? { ...p, reactions: payload.postReactions }
            : p
        );
      }
      // --------------------------------------------------------

      return {
        ...state,
        post: state.post ? newP : null,
        posts: newPosts,
        loading: false,
      };
    case ADD_COMMENT:
      const newComment = payload;
      return {
        ...state,
        post: state.post
          ? state.post._id.toString() === newComment.parentPost
            ? {
                ...state.post,
                commentCount: state.post.commentCount + 1,
                comments: [newComment, ...state.post.comments],
              }
            : state.post
          : null,
        // comments: [newComment, ...state.comments],
        posts: state.posts.map((p) =>
          p._id.toString() === newComment.parentPost
            ? {
                ...p,
                commentCount: p.commentCount + 1,
                comments: p.comments
                  ? [newComment, ...p.comments]
                  : [newComment],
              }
            : p
        ),
        loading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        // post: { ...state.post, commentCount: state.post.commentCount - 1 },
        post:
          state.post && state.post.comments
            ? state.post.comments.some((c) => c._id.toString() === payload)
              ? {
                  ...state.post,
                  commentCount: state.post.commentCount - 1,
                  comments: state.post.comments.filter(
                    (c) => c._id !== payload
                  ),
                }
              : state.post
            : null,
        // comments: state.comments.filter((c) => c._id !== payload),
        posts: state.posts.map((p) =>
          p.comments && p.comments.some((c) => c._id.toString() === payload)
            ? {
                ...p,
                commentCount: p.commentCount - 1,
                comments: p.comments.filter((c) => c._id !== payload),
              }
            : p
        ),
        loading: false,
      };
    case OPEN_POST_REACTIONS:
      return {
        ...state,
        userReaction: payload.usersWhoReacted,
      };
    case LOGOUT:
      return initialState;
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case SET_POST_REDUCER_LOADING:
      return { ...state, loading: true };
    case SET_PROFILE_REDUCER_LOADING_FALSE:
      return { ...state, loading: false };
    case SET_POST_REDUCER_LOADING_FALSE:
      return { ...state, loading: false };

    default:
      return state;
  }
};
