import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Badge,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardImg,
} from 'reactstrap';

import classNames from 'classnames';
import { connect } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';

// static assets
import generic13 from '../../../assets/img/generic/13.jpg';

// queries
import {
  useGetClubById_Query,
  useJoinClub_Query,
  useRecindClubRequest_Query,
} from '../../../queries/clubQueries';

// components
import ClubMembersTab from '../tabComponents/ClubMembersTab';
import ClubEvents from '../tabComponents/ClubEvents';
import AdminTab from '../tabComponents/AdminTab';
import ClubInformationTab from '../tabComponents/ClubInformationTab';
import GroupTab from '../tabComponents/GroupTab';
import CalendarTab from '../tabComponents/CalendarTab';
import PhotosFilesTab from '../tabComponents/PhotosFilesTab';
import Loader from '../../common/Loader';
import FalconCardHeader from '../../common/FalconCardHeader';
import ButtonIcon from '../../common/ButtonIcon';
import Avatar from '../../common/Avatar';
import PublicJoinClubModal from '../components/PublicJoinClubModal';

export const ClubContext = React.createContext();

const ViewClubPage = ({
  match,
  authStateFromRedux: { user, isAuthenticated },
  isPublicView,
}) => {
  const params = useParams();

  const { isLoading: isClubLoading, data: clubData } = useGetClubById_Query(
    params?.clubId,
    isPublicView,
    params?.clubName
  );

  const { mutate: joinClub } = useJoinClub_Query();
  const { mutate: cancelClubRequest } = useRecindClubRequest_Query();

  // default open tab
  const history = useHistory();
  const location = useLocation();
  const tabInfoFromParams = queryString.parse(location ? location.search : '');
  const [activeTab, setActiveTab] = useState('info_tab');

  useEffect(() => {
    if (
      tabInfoFromParams &&
      tabInfoFromParams.ti &&
      [
        'info_tab',
        'members_tab',
        'group_tab',
        'events_tab',
        'photos_files_tab',
        'calendar_tab',
        'admin_tab',
      ].includes(tabInfoFromParams.ti)
    ) {
      setActiveTab(tabInfoFromParams.ti);
    } else {
      history.push(`?ti=info_tab`);
    }
  }, [tabInfoFromParams, history]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`?ti=${tab}`);
    }
  };

  const {
    clubName,
    isMember,
    isAdmin,
    logo,
    memberCount,
    clubUniversity,
    tags,
    isRequested,
    clubBanner,
  } = clubData ?? {};

  const [eventModalShow, setModalShow] = useState(false);
  const showEventModal = () => setModalShow(true);
  const closeEventModal = () => setModalShow(false);

  const dynamicTabs = isAdmin
    ? [
        { title: 'Information', tabId: 'info_tab' },
        { title: 'Members', tabId: 'members_tab' },
        { title: 'Group', tabId: 'group_tab' },
        { title: 'Events', tabId: 'events_tab' },
        { title: 'Photos', tabId: 'photos_files_tab' },
        { title: 'Calendar', tabId: 'calendar_tab' },
        { title: 'Admin', tabId: 'admin_tab' },
      ]
    : [
        { title: 'Information', tabId: 'info_tab' },
        { title: 'Members', tabId: 'members_tab' },
        { title: 'Group', tabId: 'group_tab' },
        { title: 'Events', tabId: 'events_tab' },
        { title: 'Photos', tabId: 'photos_files_tab' },
        { title: 'Calendar', tabId: 'calendar_tab' },
      ];

  const handleJoinClub = (clubId) => {
    if (isMember) return;
    if (isRequested) {
      cancelClubRequest(clubId);
      return;
    }
    // Only joins if not a member and not requested
    joinClub(clubId);
  };

  // Redirect from public page if user is logged in
  if (isAuthenticated && clubData && params.clubName) {
    return <Redirect to={`/clubs/view/${clubData._id}`} />;
  }

  return isClubLoading && (clubData === null || clubData === undefined) ? (
    <Loader />
  ) : (
    <ClubContext.Provider
      value={{
        ...clubData,
        eventModalShow,
        showEventModal,
        closeEventModal,
      }}
    >
      <Card className='mb-3'>
        {clubBanner && (
          <CardImg top src={clubBanner} alt='Card image' height={'250px'} />
        )}

        <div className='d-flex justify-content-between p-3 bg-light'>
          <Avatar size={'3xl'} className='d-flex' src={logo ?? generic13} />

          <FalconCardHeader className='d-flex' title={clubName} titleTag='h3'>
            {user && (
              // discuss - @adit - this is a bit hacky, make this simpler - talk to calvyn
              <ButtonIcon
                color={
                  isMember ? `warning` : isRequested ? `danger` : `success`
                }
                icon={isMember ? `check` : isRequested ? `` : `plus`}
                iconAlign={isMember ? `right` : `left`}
                onClick={() => handleJoinClub(params?.clubId)}
              >
                {isMember
                  ? `Member`
                  : isRequested
                  ? `Cancel Join Request`
                  : `Join Club`}
              </ButtonIcon>
            )}

            {isAuthenticated !== true && isPublicView === true ? (
              <Fragment>
                <PublicJoinClubModal
                  clubName={params?.clubName}
                  showbigButton={true}
                />
              </Fragment>
            ) : null}
          </FalconCardHeader>
        </div>
        <CardBody className='fs--1'>
          <Badge color={`info`} className='mr-2'>
            {`${memberCount} members`}
          </Badge>
          <Badge color={`primary`} className='mr-2'>
            {clubUniversity?.name}
          </Badge>
          {tags?.map?.((t, index) => (
            <Badge key={index} className='mr-2'>
              {t}
            </Badge>
          ))}
        </CardBody>
      </Card>

      <Fragment>
        <Nav tabs>
          {dynamicTabs.map((category, i) => {
            return (
              <NavItem className='cursor-pointer' key={i}>
                <NavLink
                  className={classNames({
                    active: activeTab === category.tabId.toString(),
                  })}
                  onClick={() => {
                    toggle(category.tabId.toString());
                  }}
                >
                  {category.title}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId='info_tab'>
            <ClubInformationTab />
          </TabPane>

          <TabPane tabId='calendar_tab'>
            <CalendarTab />
          </TabPane>

          <TabPane tabId='group_tab'>
            <GroupTab
              clubData={clubData}
              match={match}
              isPublicView={isPublicView}
            />
          </TabPane>

          <TabPane tabId='events_tab'>
            <ClubEvents />
          </TabPane>

          <TabPane tabId='photos_files_tab'>
            <PhotosFilesTab />
          </TabPane>

          <TabPane tabId='members_tab'>
            <ClubMembersTab clubData={clubData} />
          </TabPane>

          <TabPane tabId='admin_tab'>
            <AdminTab clubData={clubData} />
          </TabPane>
        </TabContent>
      </Fragment>
    </ClubContext.Provider>
  );
};
const mapStateToProps = (state) => ({
  authStateFromRedux: state.authReducer,
});

ViewClubPage.propTypes = {
  authStateFromRedux: PropTypes.object,
  match: PropTypes.object,
  isPublicView: PropTypes.bool,
};

export default connect(mapStateToProps, {})(ViewClubPage);
