import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// components
import IconStatus2 from './IconStatus2';
import LSCCount from './LSCCount';
import Flex from '../../common/Flex';
import Avatar from '../../common/Avatar';
import Comments from './Comments';
import LightBoxGallery from '../../common/LightBoxGallery';
import ButtonImgPostCreate from '../ButtonImgPostCreate';

// libraries
import {
  Col,
  Row,
  Media,
  Input,
  Button,
  FormGroup,
  Label,
  CardFooter,
  Form,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';

// misc
import { isIterableArray } from '../../../helpers/utils';

// css
import './FeedCardFooterWithCommentImages.css';

// actions
import { addComment } from '../../../redux/actions/postActions';

// assets
import postImage from '../../../assets/img/illustrations/image.svg';

const FeedCardFooterWithCommentImages = ({
  // state
  user,
  // functions
  addComment,
  //props
  post,
  id, //refers to post id
  countLCS,
  comments,
  otherComments,
  postReactions,
  showOpen,
  showWriteComment,
}) => {
  // const { feeds, feedDispatch } = useContext(FeedContext);
  const [commentHtml, setCommentHtml] = useState('');
  const [commentText, setCommentText] = useState('');
  const [commentLength, setCommentLength] = useState(0);

  const [useAliasForComment, setUseAliasForComment] = useState(false);
  const [filesUploaded, setFiles] = useState([]);

  const [commentEditorFocused, setCommentEditorFocused] = useState(false);

  const onAddImageClicked = (e) => {
    //takes the multiple files uploaded and adds them to an array
    let filesToPush = [];
    for (const i of e.target.files) {
      filesToPush.push(i);
    }
    // checks if more then 9 files are attached, if so, deletes them
    if (filesToPush.length > 9) {
      toast.error(`Cannot attach more then 9 images in a post`);
      filesToPush = filesToPush.slice(0, 9);
    }

    // sets files
    setFiles([...filesToPush]);
  };

  const onChangeReactQuill = (content, delta, source, editor) => {
    setCommentHtml(content);
    setCommentText(editor.getText());
    setCommentLength(editor.getLength());
  };

  const submitComment = (e) => {
    e.preventDefault();

    if (post.commentLevel >= 1) {
      if (commentText.length > 0) {
        const newComment = {
          commentContent: commentHtml,
          commentText: commentText,
          isAnon: useAliasForComment,
          imageFiles: filesUploaded,
        };
        addComment(id, newComment);
      } else {
        toast.warning(`Your comment needs text`);
      }
      setCommentText('');
      setCommentHtml('');
      setCommentLength(0);
      setUseAliasForComment(false);
      setFiles([]);
    } else {
      toast.success(`Join group before you can comment here`);
    }
  };

  return (
    <CardFooter className='bg-light pt-0'>
      <LSCCount countLCS={countLCS} id={id} post={post} />
      <IconStatus2
        id={id}
        countLCS={countLCS}
        comments={comments}
        otherComments={otherComments}
        postReactions={postReactions}
        postId={post._id}
      />

      {showWriteComment === true ? (
        <Flex
          tag={Form}
          align='center'
          className='border-top border-200 pt-3'
          // onSubmit={submitComment}
        >
          <Media>
            {useAliasForComment === true ? (
              <Avatar name={'anon'} size='xl' isExact />
            ) : (
              <Avatar src={user.avatar} size='xl' />
            )}
          </Media>

          <ReactQuill
            className='quillCommentBox'
            type='textarea'
            style={{ height: commentEditorFocused === false ? null : '150px' }}
            value={commentHtml}
            onChange={onChangeReactQuill}
            modules={{ toolbar: commentEditorFocused === true }}
            placeholder='Write a comment...'
            onFocus={() => setCommentEditorFocused(true)}
          />
        </Flex>
      ) : null}

      {/* changed component to show comment anon btn even if nothing typed */}
      {commentLength > 0 ? (
        <Fragment>
          {filesUploaded.length > 0 ? (
            <LightBoxGallery
              images={filesUploaded.map((f) => URL.createObjectURL(f))}
            >
              {(openImgIndex) => (
                <Row noGutters className='mx-n1 px-2 py-2'>
                  {filesUploaded.map((f, index) => {
                    return (
                      <Col
                        xs={6}
                        md={
                          filesUploaded.length === 1
                            ? 12
                            : filesUploaded.length === 2
                            ? 6
                            : 4
                        }
                        className='px-1'
                        style={{ minWidth: 50 }}
                        key={index}
                      >
                        <img
                          className='img-fluid rounded mb-2 cursor-pointer'
                          src={URL.createObjectURL(f)}
                          alt=''
                          onClick={() => openImgIndex(index)}
                        />
                      </Col>
                    );
                  })}
                </Row>
              )}
            </LightBoxGallery>
          ) : null}
          <Row noGutters className='justify-content-between mt-3'>
            <Col className='col'>
              <label>
                <input
                  type='file'
                  id='real-file'
                  accept='image/png, image/jpeg'
                  hidden
                  name='attachimg'
                  multiple
                  onChange={(e) => onAddImageClicked(e)}
                />
                <ButtonImgPostCreate className={'bg-light'} imgSrc={postImage}>
                  <span className='d-none d-md-inline-block '>Image</span>
                </ButtonImgPostCreate>
              </label>
            </Col>
            <Col xs='auto'>
              {post.commentLevel === 2 ? (
                <Row className='d-inline-block mr-1'>
                  <FormGroup className={`form-check `}>
                    <Input
                      type='checkbox'
                      name='check'
                      id={`checkForComment-${post._id}`}
                      onChange={() => {
                        setUseAliasForComment(!useAliasForComment);
                      }}
                      checked={useAliasForComment}
                    />
                    <Label for={`checkForComment-${post._id}`} check>
                      Comment Anonymously
                    </Label>
                  </FormGroup>
                </Row>
              ) : null}

              <Button
                className='px-3 px-sm-3 rounded-capsule'
                type='submit'
                color='dark'
                size='sm'
                onClick={submitComment}
              >
                {`Send `}
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Col>
          </Row>

          <hr className='pt-0 mt-0' />
        </Fragment>
      ) : null}

      {/* only populate comments info if there is populated data */}
      {isIterableArray(comments) &&
        comments.length > 0 &&
        comments[0].content !== undefined && (
          <Comments comments={comments} loadComment={otherComments} />
        )}

      {showOpen === true ? (
        <Fragment>
          <div className='pt-3 d-flex justify-content-center'>
            <Link
              to={`/post/${id}`}
              onClick={() => {}}
              type='button'
              className='btn btn-primary btn-sm'
            >
              <i className='fas fa-times' />
              {` Open Post`}
            </Link>
          </div>
        </Fragment>
      ) : null}
    </CardFooter>
  );
};

FeedCardFooterWithCommentImages.propTypes = {
  id: PropTypes.string.isRequired,
  countLCS: PropTypes.object,
  post: PropTypes.object.isRequired,
  comments: PropTypes.array,
  otherComments: PropTypes.object,
  addComment: PropTypes.func.isRequired,
  postReactions: PropTypes.array,
  user: PropTypes.object,
  showOpen: PropTypes.bool,
  showWriteComment: PropTypes.bool,
};

FeedCardFooterWithCommentImages.defaultProps = {
  countLCS: { like: 0, dislike: 0 },
  comments: [],
  showOpen: false,
  showWriteComment: true,
};
const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

// export default FeedCardFooterWithCommentImages;
export default connect(mapStateToProps, { addComment })(
  FeedCardFooterWithCommentImages
);
