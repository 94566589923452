import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

// libraries
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import posthog from 'posthog-js';
import axios from 'axios';
import queryString from 'query-string';
import ReactPixel from 'react-facebook-pixel';

// css
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

// Component
import Layout from './peernet/layouts/Layout';
import store from './store';

// actions
import { loadUser } from './redux/actions/authActions';
import { setReferId_Action } from './redux/actions/otherActions';

// misc
import setAuthTokenIntoRequestHeader from './utils/setAuthTokenIntoRequestHeader';
import withAppInsights from './AppInsights';

// static
import { LOGOUT } from './redux/types';

//Post hog setup
posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: 'https://app.posthog.com',
});

// react pixel setup
const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(process.env.REACT_APP_PIXEL_ID, {}, options);

// * info remove this if proxy not working
// add base url to all axios requests
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

// if token is found in local storage, set it in your req header
if (localStorage.token) {
  setAuthTokenIntoRequestHeader(localStorage.token);
}

// set up react query
export const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  const pageQuery = queryString.parse(
    window.location.search ? window.location.search : ''
  );

  useEffect(() => {
    if (pageQuery?.tlid) {
      posthog.capture('trackingLinkUsed', {
        trackingLinkId: pageQuery.tlid,
      });
    }

    if (pageQuery?.referId) {
      store.dispatch(setReferId_Action(pageQuery.referId));
      posthog.capture('referralCodeUsed', {
        referId: pageQuery.referId,
      });
    }
  }, [pageQuery]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <Layout />
        </Router>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withAppInsights(App);
