import React, { Fragment } from 'react';

// libraries
import { Card, CardBody, Col, Row } from 'reactstrap';

// assets
import videochat from '../../../assets/img/undraw/undraw_Video_call_re_4p26.svg';

const PhotosFilesTab = () => {
  return (
    <div className='mt-3'>
      <Card>
        <CardBody className='overflow-hidden p-lg-6'>
          <Row className='align-items-center justify-content-between'>
            <Fragment>
              <Col lg={6}>
                <img src={videochat} className='img-fluid' alt='' />
              </Col>
              <Col lg={6} className='pl-lg-4 my-5 text-center text-lg-left'>
                <h3>{`See club photos`}</h3>
                <p className='lead'>{`Share photos from your events!`}</p>
              </Col>
            </Fragment>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default PhotosFilesTab;
