import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// library
import {
  Card,
  CardBody,
  Row,
  Button,
  CardFooter,
  Badge,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import FalconCardHeader from '../../common/FalconCardHeader';
import Flex from '../../common/Flex';
import TutorCard from '../components/tutorListings/TutorCard';
import AllTutorsBanner from '../components/tutorListings/AllTutorsBanner';
import TutorSearch from '../components/tutorListings/TutorSearch';

// actions
import { useGetAllTutors_Query } from '../../../queries/tutorQueries';

// events
import {
  posthog_ViewAllTutors_Event,
  posthog_ViewAllTutors_Event_Public,
} from '../../../utils/posthogEvents/posthogTutorEvents';

const AllTutors = ({ authState: { user }, publicState }) => {
  // Setting up page parameters
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const history = useHistory();
  const currentPage = Math.abs(parseInt(params.get('page'), 10)) || 1;
  const cardLimit = 24;
  const [allTutors, setAllTutors] = useState([]);
  const {
    isLoading: tutorsLoading,
    data: tutorsQueried,
    isError: tutorsError,
  } = useGetAllTutors_Query();

  useEffect(() => {
    if (tutorsQueried) {
      setAllTutors(tutorsQueried);
    }
  }, [tutorsQueried]);

  useEffect(() => {
    if (allTutors.length > 0 && user) {
      posthog_ViewAllTutors_Event(allTutors, user);
    } else if (allTutors) {
      posthog_ViewAllTutors_Event_Public(allTutors);
    }
  }, [allTutors, user]);

  // altering base url depending on access
  let baseUrl = '';
  if (publicState) {
    baseUrl = '/tutor/public';
  } else {
    baseUrl = '/tutor/view-all';
  }
  const leftClick = (e) => {
    e.preventDefault();
    history.push(`${baseUrl}?page=${currentPage - 1}`);
  };

  const rightClick = (e) => {
    e.preventDefault();

    history.push(`${baseUrl}?page=${currentPage + 1}`);
  };

  return (
    <Fragment>
      {tutorsLoading || tutorsError === true ? (
        <div className='text-center'>
          <div className='text-center'>
            <Spinner color='primary' />
          </div>
        </div>
      ) : (
        <Fragment>
          <AllTutorsBanner />
          <TutorSearch />
          <Card className='rounded-lg'>
            <FalconCardHeader title={`Tutors`}>
              <Flex>
                <Badge color='primary' size='sm' className='mr-2'>
                  {`${allTutors.length} tutors available`}
                </Badge>
              </Flex>
            </FalconCardHeader>

            <CardBody className='p-0'>
              <Row
                noGutters
                className='text-center fs--1 justify-content-center align-content-center mx-4'
              >
                {allTutors
                  .slice((currentPage - 1) * cardLimit, currentPage * cardLimit)
                  .map((t, index) => (
                    <TutorCard key={index} tutor={t} />
                  ))}
              </Row>
            </CardBody>
            <CardFooter
              tag={Flex}
              justify='center'
              align='center'
              className='bg-light border-top  justify-content-center'
            >
              <Button
                color='falcon-default'
                size='sm'
                className='ml-1 ml-sm-2'
                disabled={currentPage === 1}
                onClick={leftClick}
              >
                <FontAwesomeIcon icon={`chevron-left`} />
              </Button>
              <Button
                color='falcon-default'
                size='sm'
                className='ml-1 ml-sm-2'
                onClick={rightClick}
                disabled={
                  currentPage * cardLimit >= allTutors.length ? true : false
                }
              >
                <FontAwesomeIcon icon={`chevron-right`} />
              </Button>
            </CardFooter>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

AllTutors.propTypes = {
  authState: PropTypes.object.isRequired,
  publicState: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  authState: state.authReducer,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AllTutors);
