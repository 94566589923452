import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// actions
import { searchHousing_Action } from '../../redux/actions/housingActions';

// libraries
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card,
  CardFooter,
  CardBody,
  Button,
  Collapse,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import qs from 'qs';
import classNames from 'classnames';

// components
import FalconCardHeader from '../common/FalconCardHeader';

const intialSearchParamsState = {
  rentalType: null,
  rentalTerm: null,
  availableBedrooms: null,
  totalBedrooms: null,
  totalBathrooms: null,
  rent: null,
  amenities: [],
  utilities: [],
  accessibilityInfo: [],
};

const HousingSearch = ({ searchHousing_Action }) => {
  const [searchParams, setSearchParams] = useState(intialSearchParamsState);

  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const toggle = () => setShowMoreFilters(!showMoreFilters);

  const history = useHistory();

  const onChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value === 'Select' ? null : e.target.value,
    });
  };

  const onAmenitiesChange = (e) => {
    const { amenities } = searchParams;
    let newArr = [];

    if (!amenities.includes(e.target.value)) {
      newArr = [...amenities, e.target.value];
    } else {
      newArr = amenities.filter((a) => a !== e.target.value);
    }
    setSearchParams({
      ...searchParams,
      amenities: newArr,
    });
  };

  const onUtilitiesChange = (e) => {
    const { utilities } = searchParams;
    let newArr = [];

    if (!utilities.includes(e.target.value)) {
      newArr = [...utilities, e.target.value];
    } else {
      newArr = utilities.filter((a) => a !== e.target.value);
    }
    setSearchParams({
      ...searchParams,
      utilities: newArr,
    });
  };

  const onAccessibilityInfoChange = (e) => {
    const { accessibilityInfo } = searchParams;
    let newArr = [];

    if (!accessibilityInfo.includes(e.target.value)) {
      newArr = [...accessibilityInfo, e.target.value];
    } else {
      newArr = accessibilityInfo.filter((a) => a !== e.target.value);
    }
    setSearchParams({
      ...searchParams,
      accessibilityInfo: newArr,
    });
  };

  const submitSearch = (e) => {
    const finalObj = {
      ...searchParams,
      rent: { $lte: parseInt(searchParams.rent, 10) || null },
      amenities: { $in: searchParams.amenities },
      utilities: { $in: searchParams.utilities },
      accessibilityInfo: { $in: searchParams.accessibilityInfo },
    };
    const queryStr = qs.stringify(finalObj, {
      arrayFormat: 'comma',
      skipNulls: true,
    });

    history.push(`/housing/products/grid?${queryStr}`);
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setSearchParams(intialSearchParamsState);
    history.push(`/housing/products/grid`);
  };

  return (
    <Fragment>
      <Fragment>
        <Card className='mb-3'>
          <FalconCardHeader title='Search Listings' titleTag='h5' />
          <CardBody>
            <Row>
              <div className='col-xl-4 col-lg-4 col-sm-12'>
                <FormGroup>
                  <Label for='availableBedrooms'>Available Bedrooms</Label>
                  <Input
                    type='select'
                    name='availableBedrooms'
                    id='availableBedrooms'
                    onChange={onChange}
                    value={searchParams.availableBedrooms || 'Select'}
                    required
                  >
                    <option value={null}>Select</option>

                    <option value='1'>1 </option>
                    <option value='2'>2 </option>
                    <option value='3'>3 </option>
                    <option value='4'>4 </option>
                    <option value='5'>5 </option>
                    <option value='6'>6 </option>
                    <option value='7'>7 </option>
                    <option value='8'>8 </option>
                    <option value='9'>9 </option>
                    <option value='10'>10 </option>
                    <option value='11'>11 </option>
                    <option value='12'>12 </option>
                  </Input>
                </FormGroup>
              </div>
              <div className='col-xl-4 col-lg-4 col-sm-12'>
                <FormGroup>
                  <Label for='totalBedrooms'>Total Number of Bedrooms</Label>
                  <Input
                    type='select'
                    name='totalBedrooms'
                    id='totalBedrooms'
                    value={searchParams.totalBedrooms || 'Select'}
                    onChange={onChange}
                    required
                  >
                    <option value={null}>Select</option>

                    <option value='1'>1 </option>
                    <option value='2'>2 </option>
                    <option value='3'>3 </option>
                    <option value='4'>4 </option>
                    <option value='5'>5 </option>
                    <option value='6'>6 </option>
                    <option value='7'>7 </option>
                    <option value='8'>8 </option>
                    <option value='9'>9 </option>
                    <option value='10'>10 </option>
                    <option value='11'>11 </option>
                    <option value='12'>12 </option>
                  </Input>
                </FormGroup>
              </div>
              <div className='col-xl-4 col-lg-4 col-sm-12'>
                <FormGroup>
                  <Label for='totalBathrooms'>Total Number of Bathrooms</Label>
                  <Input
                    type='select'
                    name='totalBathrooms'
                    id='totalBathrooms'
                    onChange={onChange}
                    value={searchParams.totalBathrooms || 'Select'}
                    required
                  >
                    <option value={null}>Select</option>
                    <option value='1'>1 </option>
                    <option value='2'>2 </option>
                    <option value='3'>3 </option>
                    <option value='4'>4 </option>
                    <option value='5'>5 </option>
                    <option value='6'>6 </option>
                    <option value='7'>7 </option>
                    <option value='8'>8 </option>
                    <option value='9'>9 </option>
                    <option value='10'>10 </option>
                    <option value='11'>11 </option>
                    <option value='12'>12 </option>
                  </Input>
                </FormGroup>
              </div>
            </Row>
            <Row>
              <div className='col-xl-4 col-lg-4 col-sm-12'>
                <FormGroup>
                  <Label for='rentalTypeSelect'>Rental Type</Label>
                  <Input
                    type='select'
                    id='rentalTypeSelect'
                    name='rentalType'
                    onChange={onChange}
                    value={searchParams.rentalType || 'Select'}
                    required
                  >
                    <option value={null}>Select</option>

                    <option value='Whole Apartment'>Whole Apartment</option>
                    <option value='Whole House'>Whole House </option>
                    <option value='Room in Shared Student Apartment'>
                      Room in Shared Student Apartment
                    </option>
                    <option value='Room in Shared Landlord Apartment'>
                      Room in Shared Landlord Apartment
                    </option>
                    <option value='Room in Shared Student House'>
                      Room in Shared Student House
                    </option>
                    <option value='Room in Shared Landlord House'>
                      Room in Shared Landlord House
                    </option>
                  </Input>
                </FormGroup>
              </div>
              <div className='col-xl-4 col-lg-4 col-sm-12'>
                <FormGroup>
                  <Label for='rentalTermSelect'>Rental Term</Label>
                  <Input
                    type='select'
                    id='rentalTermSelect'
                    name='rentalTerm'
                    value={searchParams.rentalTerm || 'Select'}
                    onChange={onChange}
                    required
                  >
                    <option value={null}>Select</option>
                    <option value='12 Month Lease'>{'12 Month Lease'}</option>
                    <option value='8 Month Lease'>{'8 Month Lease'}</option>
                    <option value='4 Month Lease'>{'4 Month Lease'}</option>
                    <option value='Short-term/Sublet/Flexible'>
                      {'Short-term/Sublet/Flexible'}
                    </option>
                  </Input>
                </FormGroup>
              </div>
              <div className='col-xl-4 col-lg-4 col-sm-12'>
                <FormGroup>
                  <Label for='rentalTypeSelect'>Max Rent for Room</Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                    <Input
                      type='number'
                      id='rent'
                      name='rent'
                      value={searchParams.rent || ''}
                      onChange={onChange}
                      required
                    />
                    <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </div>
            </Row>
            <Row className='my-1'>
              <Col className='float-right'>
                <Button className='btn' color='primary' onClick={toggle}>
                  {showMoreFilters ? 'Close Filters' : 'Show More Filters'}
                </Button>
              </Col>
            </Row>
            <Collapse isOpen={showMoreFilters}>
              <Row>
                <div className='col-lg-4 col-sm-12 mt-2'>
                  <div className='card card-shadow px-3'>
                    <div className='card-body'>
                      <p className='d-inline-block'>
                        <strong className='text-dark'>Amenities</strong>
                      </p>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='laundryCheck'
                          name='laundryCheck'
                          value='On-Site Laundry'
                          checked={searchParams.amenities.includes(
                            'On-Site Laundry'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='laundryCheck' check>
                          On-Site Laundry
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='dishwasherCheck'
                          name='dishwasherCheck'
                          value='Dishwasher'
                          checked={searchParams.amenities.includes(
                            'Dishwasher'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='dishwasherCheck' check>
                          Dishwasher
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='cleaningServiceCheck'
                          name='cleaningServiceCheck'
                          value='Cleaning Service Included'
                          checked={searchParams.amenities.includes(
                            'Cleaning Service Included'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='cleaningServiceCheck' check>
                          Cleaning Service Included
                        </Label>
                      </FormGroup>
                      <hr />
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='acCheck'
                          name='acCheck'
                          value='Air-Conditioning'
                          checked={searchParams.amenities.includes(
                            'Air-Conditioning'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='acCheck' check>
                          Air-Conditioning
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='parkingCheck'
                          name='parkingCheck'
                          value='Parking'
                          checked={searchParams.amenities.includes('Parking')}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='parkingCheck' check>
                          Parking
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='furnishedCheck'
                          name='furnishedCheck'
                          value='Furnished'
                          checked={searchParams.amenities.includes('Furnished')}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='furnishedCheck' check>
                          Furnished
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='partiallyFurnishedCheck'
                          name='partiallyFurnishedCheck'
                          value='Partially Furnished'
                          checked={searchParams.amenities.includes(
                            'Partially Furnished'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='partiallyFurnishedCheck' check>
                          Partially Furnished
                        </Label>
                      </FormGroup>
                      <hr />
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='poolCheck'
                          name='poolCheck'
                          value='Pool'
                          checked={searchParams.amenities.includes('Pool')}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='poolCheck' check>
                          Pool
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='gymCheck'
                          name='gymCheck'
                          value='Gym'
                          checked={searchParams.amenities.includes('Gym')}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='gymCheck' check>
                          Gym
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='petCheck'
                          name='petCheck'
                          value='Pet Friendly'
                          checked={searchParams.amenities.includes(
                            'Pet Friendly'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='petCheck' check>
                          Pet Friendly
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='balconyCheck'
                          name='balconyCheck'
                          value='Balcony'
                          checked={searchParams.amenities.includes('Balcony')}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='balconyCheck' check>
                          Balcony
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='frontYardCheck'
                          name='frontYardCheck'
                          value='Front Yard'
                          checked={searchParams.amenities.includes(
                            'Front Yard'
                          )}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='frontYardCheck' check>
                          Front Yard
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='backYardCheck'
                          name='backYardCheck'
                          value='Back Yard'
                          checked={searchParams.amenities.includes('Back Yard')}
                          onChange={onAmenitiesChange}
                        />
                        <Label for='backYardCheck' check>
                          Back Yard
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-sm-12 mt-2'>
                  <div className='card card-shadow px-3'>
                    <div className='card-body'>
                      <p className='d-inline-block'>
                        <strong className='text-dark'>
                          Utilities Included in Rent
                        </strong>
                      </p>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='heatCheck'
                          name='heatCheck'
                          value='Heat'
                          checked={searchParams.utilities.includes('Heat')}
                          onChange={onUtilitiesChange}
                        />
                        <Label for='heatCheck' check>
                          Heat
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='waterCheck'
                          name='waterCheck'
                          value='Water'
                          checked={searchParams.utilities.includes('Water')}
                          onChange={onUtilitiesChange}
                        />
                        <Label for='waterChecks' check>
                          Water
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='electricityCheck'
                          name='electricityCheck'
                          value='Electricity'
                          checked={searchParams.utilities.includes(
                            'Electricity'
                          )}
                          onChange={onUtilitiesChange}
                        />
                        <Label for='electricityCheck' check>
                          Electricity
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='wifiCheck'
                          name='wifiCheck'
                          value='Wi-Fi'
                          checked={searchParams.utilities.includes('Wi-Fi')}
                          onChange={onUtilitiesChange}
                        />
                        <Label for='wifiCheck' check>
                          Wi-Fi
                        </Label>
                      </FormGroup>
                      <FormGroup className='form-check mb-0'>
                        <Input
                          type='checkbox'
                          id='cableCheck'
                          name='cableCheck'
                          value='Cable'
                          checked={searchParams.utilities.includes('Cable')}
                          onChange={onUtilitiesChange}
                        />
                        <Label for='cableCheck' check>
                          Cable
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-sm-12 mt-2'>
                  <div className='card card-shadow px-3'>
                    <div className='card-body form-check'>
                      <p className='d-inline-block'>
                        <strong className='text-dark'>Accessibility</strong>
                      </p>
                      <FormGroup className='form-check'>
                        <Input
                          type='checkbox'
                          id='accessibilityCheck'
                          name='accessibilityCheck'
                          value='This property is wheelchair accessible'
                          checked={searchParams.accessibilityInfo.includes(
                            'This property is wheelchair accessible'
                          )}
                          onChange={onAccessibilityInfoChange}
                        />
                        <Label for='accessibilityCheck' check>
                          This property is wheelchair accessible
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </Row>
            </Collapse>
          </CardBody>
          <CardFooter
            className={classNames(`bg-light py-2`, {
              'border-top': true,
            })}
          >
            <Row>
              <Col className='float-right'>
                <Button
                  className='btn mx-2 float-right'
                  color='success'
                  onClick={submitSearch}
                >
                  Search
                </Button>
                <Button
                  className='float-right btn-outline-warning mx-2'
                  // color='danger'
                  color='light'
                  onClick={clearFilters}
                >
                  Clear Filters
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Fragment>
    </Fragment>
  );
};

HousingSearch.propTypes = {
  searchHousing_Action: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  searchHousing_Action,
};

export default connect(mapStateToProps, mapDispatchToProps)(HousingSearch);
