import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { toast } from 'react-toastify';
import { Button, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import { HashLink } from 'react-router-hash-link';

// components
import RedirectUser from '../layouts/RedirectUser';

// actions
import { registerLandlordAction } from '../../redux/actions/authActions';

const RegistrationFormForLandlords = ({
  hasLabel,
  registerLandlordAction,
  isAuthenticated,
}) => {
  // State
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error(`Passwords do not match, try again`);
    } else if (password.length < 8) {
      toast.error(`Password is too short, try again`);
    } else if (password.length > 40) {
      toast.error(`Password is too long, try again`);
    } else {
      registerLandlordAction({ name, email, password });
    }
    setPassword('');
    setConfirmPassword('');
  };

  useEffect(() => {
    setIsDisabled(
      !name || !email || !password || !confirmPassword || !isAccepted
    );
  }, [name, email, password, confirmPassword, isAccepted]);

  if (isAuthenticated) {
    // return <Redirect to='/feed' />;
    return <RedirectUser />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Full Name</Label>}
        <Input
          placeholder={!hasLabel ? 'Name' : ''}
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          type='email'
        />
      </FormGroup>
      <div className='form-row'>
        <FormGroup className='col-6'>
          {hasLabel && <Label>Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Password' : ''}
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type='password'
            minLength='8'
          />
        </FormGroup>
        <FormGroup className='col-6'>
          {hasLabel && <Label>Confirm Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type='password'
          />
        </FormGroup>
      </div>

      <CustomInput
        id='customCheckTerms'
        label={
          <Fragment>
            I accept the <HashLink to='/terms/#terms'>terms</HashLink> and{' '}
            <HashLink to='/terms/#privacy'>privacy policy</HashLink>
          </Fragment>
        }
        checked={isAccepted}
        onChange={({ target }) => setIsAccepted(target.checked)}
        type='checkbox'
      />
      <FormGroup>
        <Button color='secondary' block className='mt-3' disabled={isDisabled}>
          Register
        </Button>
      </FormGroup>
      {/* <Divider className='mt-4'>or register with</Divider>
      <SocialAuthButtons /> */}
    </Form>
  );
};

RegistrationFormForLandlords.propTypes = {
  // ui props
  hasLabel: PropTypes.bool,

  // redux actions
  registerLandlordAction: PropTypes.func.isRequired,
  // redux state
  isAuthenticated: PropTypes.bool,
};

RegistrationFormForLandlords.defaultProps = {
  hasLabel: false,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, { registerLandlordAction })(
  RegistrationFormForLandlords
);
