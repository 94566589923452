import React from 'react';

// libraries
import { Col, Row } from 'reactstrap';

// misc
import { version } from '../../config';

const Footer = () => (
  <footer>
    <Row
      noGutters
      className='justify-content-between text-center fs--1 mt-4 mb-3'
    >
      <Col sm='auto'>
        <p className='mb-0 text-600'>
          {`Made by students for students`}
          <span className='d-none d-sm-inline-block'>| </span>
          <br className='d-sm-none' /> {new Date().getFullYear()} &copy;{' '}
          <a href='https://www.peernet.co'>Peernet</a>
        </p>
      </Col>
      <Col sm='auto'>
        <p className='mb-0 text-600'>v{version}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
