import React from 'react';
import { Link } from 'react-router-dom';

// libraries
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import Section from '../common/Section';

const Cta = () => (
  <Section
    overlay
    image={`https://images.unsplash.com/photo-1562349275-f5e7360af2dd?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjF9`}
    position='center bottom'
  >
    <Row className='justify-content-center text-center'>
      <Col lg={8}>
        <p className='fs-3 fs-sm-4 text-white'>
          Join this community of <b>2100+</b> students on their mission for a
          better undergrad experience.
        </p>
        <Button
          color='outline-light'
          size='lg'
          className='border-2x rounded-pill mt-4 fs-0 py-2'
          type='button'
          tag={Link}
          to={`/authentication/register`}
        >
          Start now
          <FontAwesomeIcon icon='play' transform='shrink-6 down-1 right-5' />
        </Button>
      </Col>
    </Row>
  </Section>
);

export default Cta;
