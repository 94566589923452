import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// libraries
import classNames from 'classnames';

// actions
import { searchProduct } from '../../../redux/actions/productActions';

import { posthog_productSearched_Event } from '../../../utils/posthogEvents/posthogMarketplaceEvents';

// reactstrap components
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card,
  CardFooter,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';

// components
import FalconCardHeader from '../../common/FalconCardHeader';
import TextbookCourseSelect from './TextbookCourseSelect';
import FormGroupInput from '../../common/FormGroupInput';

const ProductSearch = ({ searchProduct, auth, productType }) => {
  const initalProductSearchState = {
    productType: productType,
    // productType: productType && '',
    price: '',
    courses: [],
    ISBN: '',
  };

  const [searchParams, setSearchParams] = useState(initalProductSearchState);

  const onChange = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value === 'Select' ? '' : e.target.value,
    });
  };
  const onChangeISBN = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCourse = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      courses: selectedOption.map((course) => course.value) ?? null,
    });
  };

  const history = useHistory();
  const { pathname } = useLocation();

  const submitSearch = (e) => {
    e.preventDefault();
    searchProduct(searchParams);
    history.push(`/marketplace/buy`);
    posthog_productSearched_Event(searchParams, auth.user);
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setSearchParams(initalProductSearchState);
    searchProduct(initalProductSearchState);
    if (pathname.startsWith('/textbookexchange')) {
      history.push(`/textbookexchange`);
    } else {
      history.push(`/marketplace/buy`);
    }
  };

  return (
    <Fragment>
      <Fragment>
        <Card className='mb-3 '>
          <FalconCardHeader title='Search Products' titleTag='h5' />
          <CardBody>
            <Row>
              <Col xs={12} lg={6}>
                <FormGroup>
                  <Label for='Product Type'>Product Type</Label>
                  <Input
                    type='select'
                    id='productTypeSelect'
                    name='productType'
                    onChange={onChange}
                    value={searchParams.productType}
                    required
                  >
                    <option value={''}>Select</option>

                    <option value='textbook'>Textbook</option>
                    <option value='lab equipment'>Lab equipment </option>
                    <option value='furniture'>Furniture</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={12} lg={6}>
                <FormGroup>
                  <Label for='Price'>Max Price for Product</Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                    <Input
                      type='number'
                      id='price'
                      name='price'
                      value={searchParams.price}
                      onChange={onChange}
                      required
                    />
                    <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              {searchParams.productType === 'textbook' && (
                <Fragment>
                  <Col xs={12}>
                    <TextbookCourseSelect
                      onChangeCourse={onChangeCourse}
                      auth={auth}
                    />
                  </Col>
                  <Col xs={12}>
                    <FormGroupInput
                      id='ISBN'
                      label='ISBN'
                      name='ISBN'
                      value={searchParams.ISBN}
                      onChange={onChangeISBN}
                      type='textarea'
                      rows='1'
                      placeholder='Write the ISBN number of the textbook'
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </CardBody>
          <CardFooter
            className={classNames(`bg-light py-2`, {
              'border-top': true,
            })}
          >
            <Row>
              <Col className='float-right'>
                <Button
                  className='btn mx-2 float-right'
                  color='success'
                  onClick={submitSearch}
                >
                  Search
                </Button>
                <Button
                  className='float-right btn-outline-warning mx-2'
                  // color='danger'
                  color='light'
                  onClick={clearFilters}
                >
                  Clear Filters
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Fragment>
    </Fragment>
  );
};

ProductSearch.propTypes = {
  searchProduct: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  productType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  productState: state.productReducer,
  auth: state.authReducer,
});

const mapDispatchToProps = {
  searchProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
