import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import NavbarStandard from '../navbar/NavbarStandard';
import Cta from '../landing/Cta';
import FooterStandard from '../landing/FooterStandard';
import OpenSingleHousingAd from './OpenSingleHousingAd';

const HousingLayoutDetailsPub = ({ match }) => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container mt-7 mb-4'}>
          <OpenSingleHousingAd match={match} />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

HousingLayoutDetailsPub.propTypes = {
  match: PropTypes.object.isRequired,
};

export default connect(null, {})(HousingLayoutDetailsPub);
