import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_AUTH_REDUCER_LOADING,
  SET_AUTH_REDUCER_LOADING_FALSE,
} from '../types';
import setAuthTokenIntoRequestHeader from '../../utils/setAuthTokenIntoRequestHeader';

// utils
import {
  axiosErrorToast,
  axiosSuccessToast,
  // errorToast,
  successToast,
} from '../../utils/toastHandler';
import { queryClient } from '../../App';

// Load User
export const loadUser = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_REDUCER_LOADING });
  if (localStorage.token) {
    setAuthTokenIntoRequestHeader(localStorage.token);
    try {
      const res = await axios.get('/api/v2/auth/me');
      dispatch({
        type: USER_LOADED,
        payload: res.data.dataPayload,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  }

  dispatch({ type: SET_AUTH_REDUCER_LOADING_FALSE });
};

// Register User
export const register =
  ({ name, email, password }, referId = null) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let body = { name, email, password };
    if (referId !== null && referId !== undefined) {
      body.referId = referId;
    }

    try {
      const res = await axios.post(
        '/api/v2/auth/registerStudent',
        body,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.dataPayload,
      });

      successToast(
        'Successfully registerd. Check your email for a confirmation.'
      );
      dispatch(loadUser());
    } catch (err) {
      axiosErrorToast(err);
    }
  };

// Register User
export const registerNonStudentEmail_Action =
  ({ name, email, password, secretId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let body = { name, email, password, secretId };

    try {
      const res = await axios.post(
        '/api/v2/auth/registerAsStudentWithNonStudentEmail',
        body,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.dataPayload,
      });

      successToast(
        'Successfully registerd. Check your email for a confirmation.'
      );
      dispatch(loadUser());
    } catch (err) {
      axiosErrorToast(err);
    }
  };

// Register User As Landlord
export const registerLandlordAction =
  ({ name, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = { name, email, password };
    try {
      const res = await axios.post(
        '/api/v2/auth/registerLandlord',
        body,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.dataPayload,
      });

      successToast(
        'Successfully registered. Check your email for a confirmation.'
      );

      dispatch(loadUser());
    } catch (err) {
      axiosErrorToast(err);
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };
// Register User As Tutor
export const registerTutorAction = (tutorData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = tutorData;

  try {
    const res = await axios.post('/api/v2/auth/registerTutor', body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data.dataPayload,
    });

    successToast(
      'Successfully registered as a tutor. Check your email for a confirmation.'
    );

    dispatch(loadUser());
  } catch (err) {
    axiosErrorToast(err);
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      '/api/v2/auth/login',
      { email, password },
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.dataPayload,
    });

    dispatch(loadUser());
  } catch (err) {
    axiosErrorToast(err);
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
  queryClient.clear();
};

// Confirm Email
export const confirmEmail = (email, password, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = { email, password, id };

  try {
    const res = await axios.post('/api/v2/auth/confirmEmail', body, config);
    axiosSuccessToast(res);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data.dataPayload,
    });

    dispatch(loadUser());
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Resend Email Verification link
export const resendEmailConfirmationLink = (email) => async (dispatch) => {
  if (localStorage.token) {
    setAuthTokenIntoRequestHeader(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = { email };
  try {
    const res = await axios.post(
      '/api/v2/auth/resendConfirmationLink',
      body,
      config
    );
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Resend Email Verification link
export const sendForgotPasswordEmail = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { email };

  try {
    const res = await axios.post('/api/v2/auth/forgotPassword', body, config);
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const passwordResetSubmission = (password, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = { password };
  try {
    const res = await axios.put(
      `/api/v2/auth/resetPassword/${id}`,
      body,
      config
    );
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const changePasswordAction = (curP, newP) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = { cp: curP, np: newP };

  try {
    const res = await axios.put(`/api/v2/auth/updatepassword`, body, config);
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};
