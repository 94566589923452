import React from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import SearchCourseBox from './SearchCourseBox';
import SearchHousingBox from './SearchHousingBox';

const SearchHandler = ({ auth: { user, loading } }) => {
  const location = useLocation();

  if (loading) {
    return null;
  } else if (user && user.role && user.role.includes('student')) {
    switch (location.pathname) {
      case '/housing/products/grid':
        return <SearchHousingBox />;
      default:
        return <SearchCourseBox />;
    }
  } else if (user && user.role && user.role.includes('landlord')) {
    return <SearchHousingBox />;
  }
};

SearchHandler.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {})(SearchHandler);
