import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// actions
import { resendEmailConfirmationLink } from '../../redux/actions/authActions';

// libraries
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

// components
import NavbarTop from '../navbar/NavbarTop';
import NavbarVertical from '../navbar/NavbarVertical';
import Footer from '../footer/Footer';
import ProductProvider from '../e-commerce/ProductProvider';
import SidePanelModal from '../side-panel/SidePanelModal';

const CheckEmailAuth = ({
  resendEmailConfirmationLink,
  auth: { user, loading },
}) => {
  const onResendEmail = (async) => {
    resendEmailConfirmationLink(user.email);
  };

  return loading ? (
    <Spinner color='warning' />
  ) : (
    <Fragment>
      <div className={'container'}>
        <NavbarVertical />
        <ProductProvider>
          <div className='content'>
            <NavbarTop />
            <div className=''>
              <div className='container mt-4'>
                <h3 className='large text-dark'>
                  1. Confirm your <b>email</b> to continue!
                </h3>
                <hr />

                <div>
                  <h6>
                    {`We've sent a confirmation link to your email! `}
                    {`Remember to check your spam folder if you can't find it in your inbox!`}
                  </h6>

                  <div className='my-2'>
                    <button
                      className='btn btn-dark btn-sm my-1'
                      onClick={(e) => {
                        onResendEmail(e);
                        toast.warning(`Sending you a confirmation link now...`);
                      }}
                    >
                      Resend Confirmation link
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
          <SidePanelModal autoShow={false} />
        </ProductProvider>
      </div>
    </Fragment>
  );
};

CheckEmailAuth.propTypes = {
  resendEmailConfirmationLink: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, { resendEmailConfirmationLink })(
  CheckEmailAuth
);
