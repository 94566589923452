import React, { Fragment, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import WizardInput from '../WizardInput';
import Avatar from '../../../common/Avatar';
import ReactSelect from 'react-select';

// libraries
import { CustomInput, Media, Label, Row, Col } from 'reactstrap';
import FalconDropzoneProfilePic from '../../../common/FalconDropzoneProfilePic';
import { Controller } from 'react-hook-form';

// assets
import avatarImg from '../../../../assets/img/team/avatar.png';
import cloudUpload from '../../../../assets/img/icons/cloud-upload.svg';

// misc
import { isIterableArray } from '../../../../helpers/utils';
import { AuthWizardContext } from '../../../../context/Context';

//static data
import { UNIS_WITH_COURSES } from '../../../static/staticCourseInfo';

const AdvanceUserForm = ({
  // react-hook-form info from parent
  register,
  errors,
  control,

  //redux
  authStateFromRedux: { user: loggedInUser },
}) => {
  // get info from context
  const { user } = useContext(AuthWizardContext);
  const [avatar, setAvatar] = useState([
    ...(user.avatar ? user.avatar : []),
    { src: avatarImg },
  ]);
  const { handleInputChange } = useContext(AuthWizardContext);

  // state for user's uni's courses
  const [courses, setCourses] = useState([]);

  // calculate which courses to show
  useEffect(() => {
    const currentUni = UNIS_WITH_COURSES.find(
      (uni) => uni.name === loggedInUser.uni
    );

    if (currentUni) {
      const currentCourses = currentUni.courses.map((course) => {
        return {
          value: course._id,
          label: course.courseName,
        };
      });
      setCourses(currentCourses);
    }
  }, [loggedInUser]);

  // state for users selected courses
  const [userCourseSelection, setUserCourseSelection] = useState([]);

  // logic to allow courses dropdown after 3 chars have been typed
  const [showOptions, setShowOptions] = useState(false);
  const handleInputChangeForDropdown = (typedOption) => {
    let typedOptionWithoutWhitespace = typedOption.replace(/\s/g, '');
    if (typedOptionWithoutWhitespace.length >= 3) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  return (
    <Fragment>
      <Media className='flex-center pb-3 d-block d-md-flex text-center mb-2'>
        <Avatar
          size='4xl'
          className='mb-2'
          src={
            isIterableArray(avatar) ? avatar[0]?.base64 || avatar[0]?.src : ''
          }
        />
        <Media body className='ml-md-4'>
          <FalconDropzoneProfilePic
            files={avatar}
            onChange={(files) => {
              setAvatar(files);
              handleInputChange({ name: 'avatar', value: files });
            }}
            multiple={false}
            accept='image/*'
            placeholder={
              <Fragment>
                <Media className=' fs-0 mx-auto d-inline-flex align-items-center'>
                  <img src={cloudUpload} alt='' width={25} className='mr-2' />
                  <Media>
                    <p className='fs-0 mb-0 text-700'>
                      Upload your profile picture
                    </p>
                  </Media>
                </Media>
                <p className='mb-0 w-75 mx-auto text-500'>
                  Upload a square image for best results
                </p>
              </Fragment>
            }
          />
        </Media>
      </Media>
      <WizardInput
        type='select'
        label='Gender'
        placeholder='Select your gender'
        tag={CustomInput}
        name='selectGender'
        id='selectGender'
        onChange={({ target }) => {
          handleInputChange(target);
        }}
        innerRef={register({
          required: 'Please select your gender',
        })}
        errors={errors}
        options={['Male', 'Female', 'Other']}
      />

      <WizardInput
        label='Date of Birth'
        id='date'
        customType='datetime'
        name='birthDate'
        placeholder='DD/MM/YYYY'
        innerRef={register({
          required: 'Please enter your birthday',
        })}
        errors={errors}
      />
      <hr />
      <WizardInput
        type='textarea'
        label='Program'
        name='program'
        rows='1'
        id='program'
        innerRef={register({
          required: 'Please enter your program',
        })}
        errors={errors}
      />
      <WizardInput
        type='select'
        label='Year'
        placeholder='Select your school year'
        tag={CustomInput}
        name='selectYear'
        id='selectYear'
        onChange={({ target }) => {
          handleInputChange(target);
        }}
        innerRef={register({
          required: 'Please select your school year',
        })}
        errors={errors}
        options={['1', '2', '3', '4', '5', '6+', 'Coop', 'Alumni']}
      />

      <Label className='mb-0'>{`Current Courses`}</Label>
      <br></br>

      <span className='text-warning mt-1'>
        {`• Start typing course name to view options`}
      </span>
      <br></br>
      <span className='text-warning mt-1'>
        {`• You can select multiple courses`}
      </span>
      <br></br>
      <small className='text-warning mt-1'></small>
      <Controller
        name='userSelectedCourses'
        control={control}
        rules={{ required: 'Please select your courses' }}
        as={
          <ReactSelect
            className={`basic-single border rounded ${
              errors.userSelectedCourses ? 'border-danger' : ''
            }`}
            classNamePrefix='react-select'
            isClearable={true}
            isSearchable={true}
            name='courses'
            placeholder='Type Course name (3 characters required before options appear)'
            styles={{
              // Fixes the overlapping problem of the component
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            options={showOptions === true ? courses : []}
            onInputChange={handleInputChangeForDropdown}
            isMulti
            value={userCourseSelection}
            onChange={(userCourseSelection) =>
              setUserCourseSelection(userCourseSelection)
            }
          />
        }
      ></Controller>
      <Row>
        <Col>
          {errors.userSelectedCourses && (
            <span className='text-danger'>
              {errors.userSelectedCourses.message}
            </span>
          )}
        </Col>
      </Row>
      {/* <WizardInput
        type='select'
        label='Year'
        placeholder='Select your faculty'
        tag={CustomInput}
        name='selectYear'
        id='selectYear'
        onChange={({ target }) => {
          handleInputChange(target);
        }}
        innerRef={register({
          required: 'Please select your faculty',
        })}
        errors={errors}
        options={['1', '2', '3', '4', '5', '6+', 'Coop', 'Alumni']}
      /> */}
    </Fragment>
  );
};

AdvanceUserForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,

  // redux state
  authStateFromRedux: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authStateFromRedux: state.authReducer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceUserForm);
