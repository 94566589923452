import React, { Fragment } from 'react';
import { Alert } from 'reactstrap';

//components
import SingleHousingAdComponent from './SingleHousingAdComponent';

const PreviewHousingAd = () => {
  return (
    <Fragment>
      <div className='mb-3'>
        <Alert color='warning' style={{ backgroundColor: '#f9ca24' }}>
          <h4 className='text-gray mb-0'>
            Your Off-Campus Housing ad preview:
          </h4>
          <h6 className='text-secondary'>
            Interact with the ad to experience what your users will see.
          </h6>
          <SingleHousingAdComponent />
        </Alert>
      </div>
    </Fragment>
  );
};

export default PreviewHousingAd;
