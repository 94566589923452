import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Row,
} from 'reactstrap';
import LightBoxGallery from '../../../common/LightBoxGallery';

const ProductDetailsMediaHousingAd = ({ images, plan }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  const slides = images.map((item, index) => {
    return (
      <CarouselItem key={item}>
        <LightBoxGallery images={images}>
          {(openImgIndex) => (
            <img
              src={item}
              alt={'idk'}
              onClick={() => openImgIndex(index)}
              className='img-fluid rounded mb-2 cursor-pointer'
            />
          )}
        </LightBoxGallery>
      </CarouselItem>
    );
  });

  return (
    <Fragment>
      {plan === 'premium' && (
        <Badge
          color='danger'
          pill
          className='position-absolute t-0 l-0 ml-4 mt-2 fs--2 z-index-2'
        >
          Featured Property
        </Badge>
      )}
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {images.length > 1 && (
          <CarouselIndicators
            items={images}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
        )}

        {slides}

        {images.length > 1 && (
          <Fragment>
            <CarouselControl
              direction='prev'
              directionText='Previous'
              onClickHandler={previous}
            />
            <CarouselControl
              direction='next'
              directionText='Next'
              onClickHandler={next}
            />
          </Fragment>
        )}
      </Carousel>
      {images.length > 1 && (
        <LightBoxGallery images={images}>
          {(openImgIndex) => (
            <Row noGutters className='py-2'>
              {images.map((f, index) => {
                return (
                  <Row className='mx-2' key={index}>
                    <img
                      className='img-fluid rounded mb-2 cursor-pointer'
                      src={f}
                      alt=''
                      style={{ maxHeight: 100 }}
                      onClick={() => openImgIndex(index)}
                    />
                  </Row>
                );
              })}
            </Row>
          )}
        </LightBoxGallery>
      )}
    </Fragment>
  );
};

ProductDetailsMediaHousingAd.propTypes = {
  images: PropTypes.array,
  plan: PropTypes.string,
};

ProductDetailsMediaHousingAd.defaultProps = {};

export default ProductDetailsMediaHousingAd;
