import React, { useContext, useState, Fragment, useEffect } from 'react';

// libraries
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from 'reactstrap';

// components
import ProfileInfo from './ProfileInfo';
import Success from './Success';
import WizardModal from '../WizardModal';
import ButtonIcon from '../../../common/ButtonIcon';

// misc
import AppContext, { AuthWizardContext } from '../../../../context/Context';

// queries
import { useCreateMyProfile_Query } from '../../../../queries/profileQueries';

// static
import { FILE_SIZE_MAP } from '../../../static/config';

const UserForm = () => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const { register, handleSubmit, errors, watch, control } = useForm();

  const {
    mutate: createProfile,
    isLoading: createProfileActionLoading,
    isSuccess: createProfileActionSuccess,
  } = useCreateMyProfile_Query();

  const onSubmitData = (data) => {
    setUser({ ...user, ...data });

    if (
      user.avatar &&
      user.avatar[0].uploadedFile.size < FILE_SIZE_MAP.PROFILE_PIC
    ) {
      const input = {};
      if (user.avatar) {
        input.file = user.avatar[0].uploadedFile;
      }
      if (user.selectGender) {
        input.gender = user.selectGender;
      }
      if (user.dob) {
        input.dob = user.dob;
      }
      if (data.program) {
        input.currentProgram = data.program;
      }
      if (user.selectYear) {
        input.currentYear = user.selectYear;
      }
      if (data.userSelectedCourses) {
        input.courses = data.userSelectedCourses.map((course) => course.value);
      }
      createProfile(input);
    } else {
      toast.error(
        `Please upload a profile picture less than ${parseInt(
          FILE_SIZE_MAP.PROFILE_PIC / (1024 * 1024)
        )} MB`
      );
    }
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = (targetStep) => {
    if (step !== 2) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  useEffect(() => {
    if (createProfileActionSuccess) {
      setStep(step + 1);
    }
  }, [createProfileActionLoading, createProfileActionSuccess]);

  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card
        tag={Form}
        onSubmit={handleSubmit(onSubmitData)}
        className='theme-wizard'
      >
        <CardHeader className='bg-light'>
          <Nav className='justify-content-center'>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1,
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className='nav-item-circle-parent'>
                  <span className='nav-item-circle'>
                    <FontAwesomeIcon icon='lock' />
                  </span>
                </span>
                <span className='d-none d-md-block mt-1 fs--1'>
                  Profile Setup
                </span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 1,
                })}
              >
                <span className='nav-item-circle-parent'>
                  <span className='nav-item-circle'>
                    <FontAwesomeIcon icon='thumbs-up' />
                  </span>
                </span>
                <span className='d-none d-md-block mt-1 fs--1'>Done</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>

        <CardBody className='fs--1 font-weight-normal px-md-6 pt-4 pb-3'>
          {step === 1 && (
            <ProfileInfo
              register={register}
              errors={errors}
              watch={watch}
              control={control}
            />
          )}
          {step === 2 && <Success />}
        </CardBody>

        <CardFooter
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 2,
            ' d-flex': step < 2,
          })}
        >
          <ButtonIcon
            color='link'
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign='left'
            transform='down-1 shrink-4'
            className={classNames('px-0 font-weight-semi-bold', {
              'd-none': step === 1,
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </ButtonIcon>

          <ButtonIcon
            color='primary'
            className='ml-auto px-5'
            type='submit'
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign='right'
            transform='down-1 shrink-4'
          >
            {createProfileActionLoading === true ? <Spinner /> : 'Next'}
          </ButtonIcon>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default UserForm;
