import React, { useState, useContext, useEffect } from 'react';
// import { useForm } from 'react-hook-form';

// components
// context
import { ClubContext } from '../../privatePages/ViewClubPage';

// queries
import {
  useGetClubById_Query,
  useUpdateLogo_Mutation,
} from '../../../../../src/queries/clubQueries';
import UpdateClubLogoForm from './UpdateClubLogoForm';
import { errorToast } from './../../../../utils/toastHandler';

const UpdateClubLogo = () => {
  const { _id } = useContext(ClubContext);
  const { data: clubData, isLoading } = useGetClubById_Query(_id);
  const [clubInfo, setClubInfo] = useState({});
  const { mutate: updateLogo, isLoading: isImgLoading } =
    useUpdateLogo_Mutation();

  useEffect(() => {
    setClubInfo({ logo: clubData.logo });
  }, [clubData]);

  const changeFormField = (e, custom, customValues) => {
    if (custom) {
      setClubInfo({ ...clubInfo, [custom]: customValues });
    } else {
      setClubInfo({
        ...clubInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onUpdateImgSubmit = (data) => {
    // Checks if logo or banner is being updated
    let formattedData = {
      clubId: _id,
      logo:
        data?.attachLogo[0] instanceof File && data?.attachLogo.length > 0
          ? data.attachLogo[0]
          : null,
    };
    if (!formattedData.logo) {
      return errorToast('Update either image or banner');
    }
    updateLogo(formattedData);
  };
  const props = {
    clubInfo,
    changeFormField,
    onUpdateImgSubmit,
    isImgLoading,
  };
  return !isLoading && <UpdateClubLogoForm {...props} />;
};

export default UpdateClubLogo;
