import React, { useState } from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import PropTypes from 'prop-types';

// queries
import { useCreateClubQAndA_Mutation } from '../../../queries/clubQueries';

const CreateClubQandAModal = ({ closeModal, clubId }) => {
  const initialState = {
    question: '',
    answer: '',
  };
  const [QandA, setQandA] = useState(initialState);
  const { question, answer } = QandA;

  // update lifecycle
  const { mutate: createQandA } = useCreateClubQAndA_Mutation();

  const handleFormSubmit = () => {
    const data = { QandA, clubId };
    createQandA(data);
    // setQandA(initialState);
    // closeModal();
  };
  const onHandleTextChange = (e) => {
    setQandA({
      ...QandA,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormGroup>
        <Label for='question'>Question</Label>
        <Input
          type='textarea'
          rows='4'
          id='question'
          name='question'
          placeholder='Enter Question'
          onChange={(e) => onHandleTextChange(e)}
        />
      </FormGroup>
      <FormGroup>
        <Label for='answer'>Answer</Label>
        <Input
          type='textarea'
          rows='8'
          id='answer'
          name='answer'
          placeholder='Enter Answer'
          onChange={(e) => onHandleTextChange(e)}
        />
      </FormGroup>
      <Button
        disabled={!(question && answer)}
        color='primary'
        onClick={handleFormSubmit}
      >
        Submit
      </Button>
    </Form>
  );
};

CreateClubQandAModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  clubId: PropTypes.string.isRequired,
};

export default CreateClubQandAModal;
