import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// libraries
import { Row, Badge, Button } from 'reactstrap';

// Components
import Section from '../common/Section';
import SectionHeader from './SectionHeader';
import ProductH from '../housing/housingProductComponents/ProductH';

// misc
import { isIterableArray } from '../../helpers/utils';

//actions
import { getHousingAds_Action } from '../../redux/actions/housingActions';

const HousingDemo = ({
  getHousingAds_Action,
  housingState: { housePosts, loading },
}) => {
  useEffect(() => {
    getHousingAds_Action();
  }, [getHousingAds_Action]);

  useEffect(() => {
    if (housePosts && isIterableArray(housePosts)) {
      const a = Math.floor(Math.random() * housePosts.length);
      const b = Math.floor(Math.random() * Math.min(housePosts.length, 20));
      const c = Math.floor(Math.random() * housePosts.length);
      setLocalAds([housePosts[a], housePosts[b], housePosts[c]]);
    }
  }, [housePosts]);

  const [localAds, setLocalAds] = useState([]);

  return (
    <Section bg='light' className='text-center py-5 bg-white'>
      <SectionHeader
        title='Student Housing'
        subtitle='Services like Student Housing and Roommate match!'
      />
      <Badge color='soft-primary' pill className='mx-2'>
        {`100+ New Listings`}
      </Badge>
      <Badge color='soft-success' pill className='mx-2'>
        {`2100+ McMaster University Students`}
      </Badge>
      <Badge color='soft-warning' pill className='mx-2'>
        {`200+ Hamilton Based Landlords`}
      </Badge>

      <Row noGutters={false} className='mt-4'>
        {localAds &&
          isIterableArray(localAds) &&
          localAds.map((house, index) => (
            <ProductH
              {...house}
              key={index}
              index={index}
              layoutStyle={'grid'}
            />
          ))}
      </Row>
      <Button
        tag={Link}
        color='primary'
        className='mb-4 fs--1 border-2x rounded-pill'
        to='/listings'
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        Open All Listings
      </Button>
    </Section>
  );
};

HousingDemo.propTypes = {
  getHousingAds_Action: PropTypes.func.isRequired,
  housingState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  housingState: state.housingReducer,
});

export default connect(mapStateToProps, { getHousingAds_Action })(HousingDemo);
