import React, { Fragment } from 'react';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import OpenMarketplacePage from '../privatePages/OpenMarketplacePage';

const OpenMarketplacePublic = (props) => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container mt-7 mb-4'}>
          <OpenMarketplacePage url={'/marketplace/public'} isPublic />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

export default OpenMarketplacePublic;
