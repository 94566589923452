import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_ViewAllTutors_Event = (tutors, user) => {
  if (user && user._id && tutors && tutors.length > 0) {
    posthog.capture('viewAllTutors', {
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewAllTutors' },
      {
        userId: user._id,
      }
    );
  }
};

export const posthog_ViewAllTutors_Event_Public = (tutors) => {
  if (tutors && tutors.length > 0) {
    posthog.capture('viewAllTutorsPublic');

    appInsights.trackEvent({ name: 'viewAllTutorsPublic' });
  }
};

export const posthog_ViewSingleTutor_Event = (tutor, user) => {
  if (user && user._id && tutor && tutor.user.name && tutor._id) {
    posthog.capture('viewSingleTutor', {
      viewTutor___tutorId: tutor._id,
      viewTutor___tutorName: tutor.user.name,
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewSingleTutor' },
      {
        viewTutor___tutorId: tutor._id,
        viewTutor___tutorName: tutor.user.name,
        userId: user._id,
      }
    );
  }
};
export const posthog_ViewSingleTutor_Event_Public = (tutor) => {
  if (tutor && tutor.user.name && tutor._id) {
    posthog.capture('viewSingleTutorPublic', {
      viewTutor___tutorId: tutor._id,
      viewTutor___tutorName: tutor.user.name,
    });

    appInsights.trackEvent(
      { name: 'viewSingleTutorPublic' },
      {
        viewTutor___tutorId: tutor._id,
        viewTutor___tutorName: tutor.user.name,
      }
    );
  }
};

export const posthog_createTutor_Event = (tutorInfo, user) => {
  if (user && user._id && tutorInfo) {
    posthog.capture('tutorCreated', {
      userId: user._id,
      createdTutor___tutorInfo: tutorInfo,
    });

    appInsights.trackEvent(
      { name: 'tutorCreated' },
      {
        userId: user._id,
        createdTutor___tutorInfo: tutorInfo,
      }
    );
  }
};

export const posthog_updatedTutorDetails_Event = (tutorInfo, user) => {
  if (user && user._id && tutorInfo) {
    posthog.capture('tutorDetailsUpdated', {
      userId: user._id,
      updatedTutor___tutorInfo: tutorInfo,
    });

    appInsights.trackEvent(
      { name: 'tutorDetailsUpdated' },
      {
        userId: user._id,
        updatedTutor___tutorInfo: tutorInfo,
      }
    );
  }
};
