import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import classNames from 'classnames';
import {
  Button,
  CustomInput,
  Modal,
  Media,
  ModalHeader,
  ButtonGroup,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// misc
import AppContext from '../../context/Context';

// assets
import defaultModeImg from '../../assets/img/generic/falcon-mode-default.jpg';

// components
import ScrollBarCustom from '../common/ScrollBarCustom';
import Flex from '../common/Flex';
import arrowsH from '../../assets/img/icons/arrows-h.svg';

// misc
import { createCookie, getCookieValue, getPageName } from '../../helpers/utils';

const SidePanelModal = ({
  autoShow,
  showOnce,
  autoShowDelay,
  cookieExpireTime,
}) => {
  const {
    isOpenSidePanel,
    toggleModal,
    isDark,
    setIsDark,
    // isFluid,
    setIsFluid,
    setIsOpenSidePanel,
    autoTriggerKanbanWideMode,
    setAutoTriggerKanbanWideMode,
    userEnabledWideMode,
    setUserEnabledWideMode,
  } = useContext(AppContext);
  const isKanban = getPageName('kanban');

  useEffect(() => {
    let modalStatus = getCookieValue('modalClose');

    if (modalStatus === null && autoShow) {
      setTimeout(() => {
        setIsOpenSidePanel((prev) => !prev);
        showOnce && createCookie('modalClose', false, cookieExpireTime);
      }, autoShowDelay);
    }
  }, [autoShow, showOnce, setIsOpenSidePanel, autoShowDelay, cookieExpireTime]);

  useEffect(() => {
    if (isKanban === true && autoTriggerKanbanWideMode === true) {
      setIsFluid(true);
    } else {
      if (userEnabledWideMode === false) {
        setIsFluid(false);
      } else if (userEnabledWideMode === true) {
        setIsFluid(true);
      }
    }
  }, [isKanban, userEnabledWideMode, autoTriggerKanbanWideMode, setIsFluid]);

  return (
    <Modal
      isOpen={isOpenSidePanel}
      toggle={toggleModal}
      modalClassName='overflow-hidden modal-fixed-right modal-theme'
      className='modal-dialog-vertical'
      contentClassName='vh-100 border-0'
    >
      <ModalHeader
        tag='div'
        toggle={toggleModal}
        className='modal-header-settings'
      >
        <div className='py-1 flex-grow-1'>
          <h5 className='text-white'>
            <FontAwesomeIcon icon='palette' className='mr-2 fs-0' />
            Settings
          </h5>
          <p className='mb-0 fs--1 text-white opacity-75'>
            Set your own customized style
          </p>
        </div>
      </ModalHeader>
      <ScrollBarCustom
        className='modal-body'
        contentProps={{
          renderer: ({ elementRef, ...restProps }) => (
            <span
              {...restProps}
              ref={elementRef}
              className={classNames('p-card position-absolute', {
                'border-left': isDark,
              })}
            />
          ),
        }}
      >
        <h5 className='fs-0'>Color Scheme</h5>
        <p className='fs--1'>Dark Mode Coming Soon!</p>
        <ButtonGroup className='btn-group-toggle btn-block'>
          <Button
            color='theme-default'
            className={classNames('custom-radio-success', { active: !isDark })}
          >
            <Label
              for='theme-mode-default'
              className='cursor-pointer hover-overlay'
            >
              <img className='w-100' src={defaultModeImg} alt='' />
            </Label>
            <CustomInput
              type='radio'
              id='theme-mode-default'
              label='Light'
              checked={!isDark}
              onChange={({ target }) => setIsDark(!target.checked)}
            />
          </Button>
          {/* <Button
            color='theme-dark'
            className={classNames('custom-radio-success', { active: isDark })}>
            <Label
              for='theme-mode-dark'
              className='cursor-pointer hover-overlay'>
              <img className='w-100' src={darkModeImg} alt='' />
            </Label>
            <CustomInput
              type='radio'
              id='theme-mode-dark'
              label='Dark'
              checked={isDark}
              onChange={({ target }) => setIsDark(target.checked)}
            />
          </Button> */}{' '}
        </ButtonGroup>

        <hr />

        <Fragment>
          <Flex justify='between'>
            <Media className='flex-grow-1'>
              <img src={arrowsH} alt='' width={20} className='mr-2' />
              <Media body>
                <h5 className='fs-0'>Wide Mode</h5>
                <p className='fs--1 mb-0'>Toggle wider layout system</p>
              </Media>
            </Media>
            <CustomInput
              type='switch'
              id='fluid-switch'
              checked={userEnabledWideMode}
              onChange={({ target }) => setUserEnabledWideMode(target.checked)}
            />
          </Flex>
        </Fragment>

        <hr />

        <Fragment>
          <Flex justify='between'>
            <Media className='flex-grow-1'>
              <img src={arrowsH} alt='' width={20} className='mr-2' />
              <Media body>
                <h5 className='fs-0'>Kanban Wide Mode</h5>
                <p className='fs--1 mb-0'>
                  Auto Trigger Wide Mode For Kanban Page
                </p>
              </Media>
            </Media>
            <CustomInput
              type='switch'
              id='auto-trigger-kanban-switch'
              checked={autoTriggerKanbanWideMode}
              onChange={({ target }) =>
                setAutoTriggerKanbanWideMode(target.checked)
              }
            />
          </Flex>
        </Fragment>

        <hr />
      </ScrollBarCustom>
    </Modal>
  );
};

SidePanelModal.propTypes = {
  autoShow: PropTypes.bool,
  showOnce: PropTypes.bool,
  autoShowDelay: PropTypes.number,
  cookieExpireTime: PropTypes.number,
};

SidePanelModal.defaultProps = {
  autoShow: true,
  showOnce: true,
  autoShowDelay: 3000,
  cookieExpireTime: 7200000,
};

export default SidePanelModal;
