import axios from 'axios';
import { useQuery } from 'react-query';

// misc
import { minutesToMiliseconds } from '../../utils/timeFunctions';
import { axiosErrorToast } from '../../utils/toastHandler';

export const useGetCompanies_Query = () => {
  return useQuery(
    ['companies'],
    () =>
      axios
        .get(`/api/v2/company/`)
        .then((res) => res.data.dataPayload.companies),
    {
      cacheTime: minutesToMiliseconds(400),
      staleTime: minutesToMiliseconds(200),
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetCompanyById_Query = (companyId) => {
  return useQuery(
    ['company', companyId],
    () =>
      axios
        .get(`/api/v2/company/${companyId}`)
        .then((res) => res.data.dataPayload.company),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      onError: (err) => axiosErrorToast(err),
    }
  );
};
