const feedRoutes = {
  name: 'Feed',
  to: '/feed',
  exact: true,
  icon: 'poll',
};

// * student routes
const submitResRoutes = {
  name: 'Share Resources',
  to: '/submit-resource',
  exact: true,
  icon: 'arrow-up',
};

const viewSubmittedResourcesRoutes = {
  name: 'View Submitted Res',
  to: '/getsubres',
  exact: true,
  icon: 'arrow-up',
};

const coursesRoutes = {
  name: 'Course Materials',
  to: '/uni',
  exact: true,
  icon: 'book',
};

const homeworkHelpRoutes = {
  name: 'Homework Help',
  to: '/homework-help',
  exact: true,
  icon: 'pencil-alt',
  badge: {
    text: `New`,
    color: 'soft-success',
  },
};

const peersRoutes = {
  name: 'Peers',
  to: '/peers',
  exact: true,
  icon: 'user-circle',
};

const housing = {
  name: 'Student Housing',
  to: '/housing/products/grid',
  icon: 'house-user',
  exact: true,
  children: [
    { to: '/housing/products/grid', name: 'All House Listings' },
    { to: '/housing/favourites', name: 'Favourite Listings' },
  ],
};

const internships = {
  name: 'Internships',
  to: '/internships/companies',
  icon: 'briefcase',
  exact: true,
};

const groupRoutes = {
  name: 'Groups',
  exact: true,
  icon: 'users',
  children: [
    { to: '/group/all', name: 'Discover' },
    { to: '/group/me', name: 'My groups' },
    { to: '/group/courses', name: 'Course groups' },
    { to: '/group/create', name: 'Create group' },
  ],
};

const eventRoutes = {
  name: 'Events',
  // to: '/group',
  exact: true,
  icon: 'calendar-alt',
  children: [
    { to: '/events/view', name: 'View Events' },
    { to: '/events/create-event', name: 'Create Event' },
    { to: '/events/view-my-events', name: 'My Events' },
  ],
};

export const studentandlandlordOptions_forLanding = {
  name: 'Housing Ads',
  to: '/',
  icon: 'chart-pie',
  children: [
    {
      to: '/listings',
      name: 'See All Listings',
      exact: true,
      badge: {
        text: `New Listings Available`,
        color: 'soft-primary',
      },
    },
  ],
};

export const studentOptions_forLanding = {
  name: 'Students',
  to: '/',
  icon: 'chart-pie',
  children: [
    {
      to: '/create-housing-ad',
      name: 'Post Sublet Ad',
      exact: true,
    },
  ],
};

export const landlordOptions_forLanding = {
  name: 'Landlords',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/create-housing-ad',
      name: 'Post Housing Ad',
      exact: true,
      badge: {
        text: `New`,
        color: 'soft-success',
      },
    },
    {
      to: '/authentication/register-landlord',
      name: 'Sign Up as Landlord',
      exact: true,
    },
  ],
};

export const studyMaterialOptions_forLanding = {
  name: 'Collaborate with peers from your school',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/all-universities?uni=McMaster University',
      name: 'McMaster',
      exact: true,
      badge: {
        text: `3000+ resources`,
        color: 'soft-success',
      },
    },
    {
      to: '/all-universities?uni=Ryerson University',
      name: 'Ryerson',
      exact: true,
      badge: {
        text: `1000+ resources`,
        color: 'soft-success',
      },
    },
    {
      to: '/all-universities?uni=Western University',
      name: 'Western',
      exact: true,
      badge: {
        text: `New! 700+ Resources`,
        color: 'soft-primary',
      },
    },
  ],
};

export const clubOptions_forLanding = {
  name: 'Find a club to join!',
  exact: true,
  children: [
    {
      to: '/clubs/view/public',
      name: 'See all clubs',
      exact: true,
    },
  ],
};

export const products_forLanding = {
  name: 'Find products to buy or sell your own',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/marketplace/public',
      name: 'View available products',
      exact: true,
      badge: {
        text: `New Listings!`,
        color: 'soft-success',
      },
    },
  ],
};
export const products_textbook_forLanding = {
  name: 'Textbook Exchange',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/marketplace/public',
      name: 'Resell your used textbook',
      exact: true,
      badge: {
        text: `New Feature!`,
        color: 'soft-warning',
      },
    },
  ],
};

export const tutors_see_all_forLanding = {
  name: 'Find a tutor or register as one',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/tutor/public',
      name: 'View all tutors',
      exact: true,
      badge: {
        text: `New`,
        color: 'soft-success',
      },
    },
  ],
};

export const tutors_register_forLanding = {
  name: 'Submit a tutoring profile',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/authentication/register-tutor',
      name: 'Register to teach & Earn ',
      exact: true,
      badge: {
        text: `New Feature!`,
        color: 'soft-warning',
      },
    },
  ],
};

export const view_internships_forLanding = {
  name: 'View Internships',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/internships/public',
      name: 'Get access to internships',
      exact: true,
      badge: {
        text: `300+ opportunities`,
        color: 'soft-primary',
      },
    },
  ],
};

const landlord_Dash = {
  name: 'Dashboard',
  to: '/dash',
  exact: true,
  icon: 'chart-pie',
};

const landloard_CreateAds = {
  name: 'Create Ad',
  to: '/create-ad',
  exact: true,
  icon: 'plus-square',
};

const landlord_Ads = {
  to: '/housing/products/grid',
  name: 'Available Properties',
  exact: true,
  icon: 'chart-pie',
};

const tutoring = {
  name: 'Find a Tutor',
  exact: true,
  icon: 'chalkboard-teacher',
  children: [
    { to: '/tutor/view-all', name: 'View all Tutors' },
    { to: '/tutor/create-profile', name: 'Become a Tutor' },
    { to: '/tutor/profile', name: 'My Tutor Profile' },
  ],
  badge: {
    text: `New`,
    color: 'soft-success',
  },
};

const tutoring_comingsoon = {
  to: '/comingsoon/tutor',
  name: 'Find a Tutor',
  exact: true,
  icon: 'chalkboard-teacher',
};

const rideShare_comingsoon = {
  to: '/comingsoon/rideshare',
  name: 'Find a Ride',
  exact: true,
  icon: 'car',
};

const roommates = {
  name: 'Roommates',
  exact: true,
  icon: 'hotel',
  children: [
    { to: '/roommate/all', name: 'Roommate Listings' },
    { to: '/roommate/create', name: 'Create Roommate Listing' },
    { to: '/roommate/myads', name: 'My Listings' },
  ],
};
const roommates_comingsoon = {
  name: 'Find Roommates',
  exact: true,
  icon: 'hotel',
  to: '/comingsoon/roommates',
};

const chat = {
  to: '/chatrooms',
  name: 'Chat',
  exact: true,
  icon: 'comments',
  badge: {
    text: `New`,
    color: 'soft-success',
  },
};
const chat_comingsoon = {
  to: '/comingsoon/chatrooms',
  name: 'Chat',
  exact: true,
  icon: 'comments',
};

const events_comingsoon = {
  to: '/comingsoon/events',
  name: 'Events Near Me',
  exact: true,
  icon: 'calendar-plus',
};

const textbookSwap_comingsoon = {
  to: '/comingsoon/textbookexchange',
  name: 'Textbook Exchange',
  icon: 'book',
  exact: true,
};

const textbookSwap = {
  name: 'Textbook Swap',
  to: '/marketplace/textbookexchange',
  icon: 'book',
  children: [
    { to: '/marketplace/textbookexchange/buy', name: 'View Textbooks' },
    { to: '/marketplace/textbookexchange/sell', name: 'Sell' },
    { to: '/marketplace/edit', name: 'My Listings' },
    { to: '/marketplace/favourites/all', name: 'Favourites' },
  ],
  badge: {
    text: `New`,
    color: 'soft-success',
  },
};

const products_comingsoon = {
  name: 'Marketplace',
  to: '/comingsoon/marketplace',
  icon: 'search-dollar',
  exact: true,
};

const products = {
  name: 'Marketplace',
  to: '/marketplace',
  icon: 'search-dollar',
  children: [
    { to: '/marketplace/buy', name: 'View Marketplace' },
    { to: '/marketplace/sell', name: 'Sell' },
    { to: '/marketplace/edit', name: 'My Listings' },
    { to: '/marketplace/favourites/all', name: 'Favourites' },
  ],
  badge: {
    text: `New`,
    color: 'soft-success',
  },
};

const calendar_comingsoon = {
  to: '/comingsoon/calendar',
  name: 'Calendar',
  exact: true,
  icon: 'calendar-alt',
};

const agenda_comingsoon = {
  to: '/comingsoon/agenda',
  name: 'Agenda',
  exact: true,
  icon: 'calendar-week',
};

const clubs_comingsoon = {
  to: '/comingsoon/clubs',
  name: 'Clubs',
  exact: true,
  icon: 'users-cog',
};

const clubs = {
  name: 'Clubs',
  to: '/clubs',
  icon: 'users-cog',
  children: [
    { to: '/clubs/view-all', name: 'All Clubs' },
    { to: '/clubs/my-clubs', name: 'My Clubs' },
    { to: '/clubs/create', name: 'Create Club' },
  ],
};

const kanbanManagement_comingsoon = {
  name: 'Kanban Board',
  to: '/comingsoon/kanban',
  exact: true,
  icon: ['fab', 'trello'],
};

const kanbanManagement = {
  name: 'Kanban Board',
  to: '/kanban',
  exact: true,
  icon: ['fab', 'trello'],
};

export const modRoutes = [viewSubmittedResourcesRoutes];

export const landlordRoutes = [
  landlord_Dash,
  landloard_CreateAds,
  landlord_Ads,
];

export const comingSoonRoutes =
  process.env.REACT_APP_ENABLED_FEATURES === 'show_prod_enabled_features'
    ? [
        products_comingsoon,
        tutoring_comingsoon,
        textbookSwap_comingsoon,
        roommates_comingsoon,
        chat_comingsoon,
        events_comingsoon,
        calendar_comingsoon,
        rideShare_comingsoon,
        agenda_comingsoon,
        clubs_comingsoon,
        kanbanManagement_comingsoon,
      ]
    : [calendar_comingsoon, rideShare_comingsoon, agenda_comingsoon];

export const studentRoutes_Social =
  process.env.REACT_APP_ENABLED_FEATURES === 'show_prod_enabled_features'
    ? [feedRoutes, chat, peersRoutes, groupRoutes, clubs, eventRoutes]
    : [feedRoutes, chat, peersRoutes, groupRoutes, clubs, eventRoutes];

export const studentRoutes_Academic =
  process.env.REACT_APP_ENABLED_FEATURES === 'show_prod_enabled_features'
    ? [coursesRoutes, submitResRoutes, homeworkHelpRoutes]
    : [coursesRoutes, submitResRoutes, homeworkHelpRoutes];

export const studentRoutes_Services =
  process.env.REACT_APP_ENABLED_FEATURES === 'show_prod_enabled_features'
    ? [housing, internships, roommates, tutoring, products, textbookSwap]
    : [
        housing,
        internships,
        roommates,
        tutoring,
        products,
        textbookSwap,
        kanbanManagement,
      ];
