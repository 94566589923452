// SORT FUNCTIONS

export const sortByFeatured = (data, isAsc) => {
  const tierMap = {
    premium: 3,
    standard: 2,
    basic: 1,
  };

  if (data) {
    return data.slice().sort((a, b) => {
      if (isAsc) {
        return tierMap[a.plan] - tierMap[b.plan];
      } else {
        return tierMap[b.plan] - tierMap[a.plan];
      }
    });
  } else {
    return null;
  }
};

export const sortByStatus = (data) => {
  const tierMap = {
    active: 5,
    created_private: 4,
    created_public: 3,
    expired_successfully: 2,
    inactive: 1,
    unclaimed: 0,
  };
  return data.slice().sort((a, b) => tierMap[b.status] - tierMap[a.status]);
};

export const sortByDateAvailable = (data, isAsc) => {
  return data.slice().sort((a, b) => {
    if (isAsc) {
      return (
        new Date(
          b.details.dateAvailable ? b.details.dateAvailable : Date.now()
        ) -
        new Date(a.details.dateAvailable ? a.details.dateAvailable : Date.now())
      );
    } else {
      return (
        new Date(
          a.details.dateAvailable ? a.details.dateAvailable : Date.now()
        ) -
        new Date(b.details.dateAvailable ? b.details.dateAvailable : Date.now())
      );
    }
  });
};

export const sortByDateCreated = (data, isAsc) => {
  return data.slice().sort((a, b) => {
    if (isAsc) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });
};

export const sortByRent = (data, isAsc) => {
  return data.slice().sort((a, b) => {
    if (isAsc) {
      return b.details.rent - a.details.rent;
    } else {
      return a.details.rent - b.details.rent;
    }
  });
};

export const filterIgnoredAd = (ignoredAdId, data) => {
  if (data && data.length > 1) {
    const out = data.filter((d) => d._id.toString() !== ignoredAdId.toString());
    return out;
  } else {
    return data;
  }
};
