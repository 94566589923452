import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
} from 'reactstrap';

// actions
import { deletePost } from '../../../redux/actions/postActions';
import { reportPost } from '../../../redux/actions/reportActions';

// components
import RedirectUser from '../../layouts/RedirectUser';

const FeedDropDown = ({ deletePost, reportPost, post }) => {
  const location = useLocation();
  const history = useHistory();

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFormData({
      details: '',
      bullying: false,
      hateSpeech: false,
      spam: false,
      misinformation: false,
      nudity: false,
    });
  };
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    details: '',
    bullying: false,
    hateSpeech: false,
    spam: false,
    misinformation: false,
    nudity: false,
  });
  const {
    // details,
    bullying,
    hateSpeech,
    spam,
    misinformation,
    nudity,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onChangeForBoolean = (e) => {
    setFormData({ ...formData, [e.target.name]: !formData[e.target.name] });
  };

  // on button click
  const onSubmit = async (e) => {
    e.preventDefault();

    reportPost(formData, post._id);
    handleClose();
  };

  if (deleteClicked) {
    if (location.pathname.includes('/post/')) {
      history.goBack();
    } else {
      // return <Redirect to='/feed' />;
      return <RedirectUser />;
    }
  }

  return (
    <UncontrolledDropdown>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Report This Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <Label for='exampleName'>
                {`Select Reason(s) for Reporting this Post:`}
              </Label>
              <CustomInput
                type='checkbox'
                id='customCheck1'
                label='Bullying'
                className='mb-0'
                name={`bullying`}
                checked={bullying}
                onChange={(e) => onChangeForBoolean(e)}
              />
              <CustomInput
                type='checkbox'
                id='customCheck2'
                label='Hate Speech'
                name={`hateSpeech`}
                checked={hateSpeech}
                onChange={(e) => onChangeForBoolean(e)}
              />
              <CustomInput
                type='checkbox'
                id='customCheck3'
                label='Misinformation, False News'
                name={`misinformation`}
                checked={misinformation}
                onChange={(e) => onChangeForBoolean(e)}
              />
              <CustomInput
                type='checkbox'
                id='customCheck4'
                label='Spam'
                name={`spam`}
                checked={spam}
                onChange={(e) => onChangeForBoolean(e)}
              />
              <CustomInput
                type='checkbox'
                id='customCheck5'
                label='Nudity'
                name={`nudity`}
                checked={nudity}
                onChange={(e) => onChangeForBoolean(e)}
              />
            </FormGroup>

            <FormGroup>
              <Label for='exampleText'>Add Details:</Label>
              <Input
                type='textarea'
                name='details'
                id='exampleText'
                // value={details}
                onChange={(e) => onChange(e)}
              />
              <small id='emailHelp' className='form-text text-muted'>
                Add any other relevent information
              </small>
            </FormGroup>
            <Button color='warning' onClick={(e) => onSubmit(e)}>
              Submit
            </Button>
          </Form>
          {/* <form onSubmit={(e) => onSubmit(e)}>
            <Fragment />
            <div className='form-group'>
              <label>Add Details:</label>
              <input
                className='form-control'
                placeholder=''
                name='oldPass'
                value={oldPass}
                onChange={(e) => onChange(e)}
                required
              />
              <small id='emailHelp' className='form-text text-muted'>
                Tell us about a problem with this post
              </small>
            </div>

            <button type='submit' className='btn btn-success'>
              Submit
            </button>
          </form> */}
        </Modal.Body>
      </Modal>

      <DropdownToggle color='Secondary' size='sm' className='p-0'>
        <FontAwesomeIcon icon='ellipsis-h' />
      </DropdownToggle>

      <DropdownMenu right>
        <DropdownItem onClick={handleShow}>Report</DropdownItem>

        <DropdownItem>Save Post</DropdownItem>
        {/* <DropdownItem className="text-warning">Archive</DropdownItem> */}
        {!!post && post.isMyPost ? (
          <Fragment>
            <DropdownItem divider />
            {/* <DropdownItem>Edit</DropdownItem> */}

            <DropdownItem
              className='text-warning'
              onClick={() => {
                deletePost(post._id);
                setDeleteClicked(true);
              }}
            >
              Delete
            </DropdownItem>
          </Fragment>
        ) : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

FeedDropDown.propTypes = {
  deletePost: PropTypes.func.isRequired,
  reportPost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

export default connect(null, { deletePost, reportPost })(FeedDropDown);
