import {
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  SET_PRODUCTS_REDUCER_LOADING,
} from '../types';

const initialState = {
  products: [],
  currentProduct: null,
  loading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        currentProduct: null,
        products: payload,
        loading: false,
      };
    }
    case SET_PRODUCTS_REDUCER_LOADING:
      return { ...state, loading: true };

    case GET_PRODUCT_BY_ID: {
      return {
        ...state,
        currentProduct: payload,
        loading: false,
      };
    }
    // case REGISTER_TUTOR_PROFILE: {
    // 	return {
    // 		...state,
    // 		currentTutor: null,
    // 		tutorProfile: payload,
    // 		loading: false,
    // 	};
    // }

    default:
      return state;
  }
};
