import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// libraries
import classNames from 'classnames';
import { Button, Col, Form, FormGroup, Label, Row, Table } from 'reactstrap';

//components
import FalconInput from '../../../common/FalconInput';
import GoogleMap from '../../../map/GoogleMap';
import { isIterableArray } from '../../../../helpers/utils';
import { cleanKeyTerms } from './SharedHelperFunctions';

// Events
import { posthog_housingDetailsViewed_Event } from '../../../../utils/posthogEvents/posthogHousingEvents';

function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

const ProductDetailsFooterHousingAd = ({
  //for tab 1 = Location
  locationDetails,
  //for tab 2 = Description
  description,
  //for tab 3 = Detials
  amenities,
  details,
  utilities,
  user,
  houseAd,
}) => {
  const [locat, setlocat] = useState({
    lat: 48.8583736,
    lng: 2.2922926,
  });
  const [mapMarkerTitle, setMapMarkerTitle] = useState('');
  const [activeTab, setActiveTab] = useState('location');
  const [indicatorLeft, setIndicatorLeft] = useState(null);
  const [indicatorRight, setIndicatorRight] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(3);
  const [isReverse, setIsReverse] = useState(false);

  const updateIndicator = (id) => {
    const navbar = document.getElementById('fancy-tab-footer');
    const tabnavCurrentItem = document.getElementById(id);
    const navbarLeft = offset(navbar).left;
    const left = offset(tabnavCurrentItem).left - navbarLeft;
    const right = navbar.offsetWidth - (left + tabnavCurrentItem.offsetWidth);
    setIndicatorLeft(left);
    setIndicatorRight(right);
  };

  const handleActiveTab = ({ target }) => {
    const { id, tabIndex } = target;
    setActiveTab(id);
    updateIndicator(id);
    setIsReverse(currentTabIndex > tabIndex);
    setCurrentTabIndex(tabIndex);

    // send event
    posthog_housingDetailsViewed_Event(houseAd, activeTab, user);
  };

  const specifications = {
    ...details,
    amenities: amenities.join(', '),
    utilites: utilities.join(', '),
  };

  useEffect(() => {
    if (locationDetails) {
      setlocat({
        lat: locationDetails.coordinates[1],
        lng: locationDetails.coordinates[0],
      });
      setMapMarkerTitle(locationDetails.street);
    }
  }, [locationDetails]);

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='fancy-tab overflow-hidden mt-4' id='fancy-tab-footer'>
          <div className='nav-bar'>
            <div
              className={classNames('nav-bar-item pl-0 pr-2 pr-sm-4', {
                active: activeTab === 'location',
              })}
            >
              <div
                className='mt-1 fs--1'
                id='location'
                tabIndex={3}
                onClick={handleActiveTab}
              >
                Location
              </div>
            </div>
            <div
              className={classNames('nav-bar-item px-2 px-sm-4', {
                active: activeTab === 'description',
              })}
            >
              <div
                className='mt-1 fs--1'
                id='description'
                tabIndex={1}
                onClick={handleActiveTab}
              >
                Description
              </div>
            </div>
            <div
              className={classNames('nav-bar-item px-2 px-sm-4', {
                active: activeTab === 'specifications',
              })}
            >
              <div
                className='mt-1 fs--1'
                id='specifications'
                tabIndex={2}
                onClick={handleActiveTab}
              >
                Details
              </div>
            </div>

            {/* <div
              className={classNames("nav-bar-item px-2 px-sm-4", {
                active: activeTab === "reviews",
              })}
            >
              <div
                className="mt-1 fs--1"
                id="reviews"
                tabIndex={4}
                onClick={handleActiveTab}
              >
                Reviews
              </div>
            </div> */}
            <div
              className={classNames('tab-indicator', {
                'transition-reverse': isReverse,
              })}
              style={{ left: indicatorLeft, right: indicatorRight }}
            />
          </div>
          <div className='tab-contents'>
            <div
              className={classNames('tab-content', {
                active: activeTab === 'description',
              })}
            >
              <p>{description}</p>
            </div>
            <div
              className={classNames('tab-content', {
                active: activeTab === 'specifications',
              })}
            >
              {isIterableArray(Object.keys(specifications)) && (
                <Table bordered className='fs--1'>
                  <tbody>
                    {Object.keys(specifications).map((specification, index) => (
                      <tr key={index}>
                        <td className='bg-100' style={{ width: '30%' }}>
                          {cleanKeyTerms(specification)}
                        </td>
                        <td>{specifications[specification]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
            <div
              className={classNames('tab-content', {
                active: activeTab === 'location',
              })}
            >
              {/* <select
              // onChange={(x) => {
              //   setlocat(location[x.target.value]);
              // }}
              >
                <option value='l1'>McMaster University</option>
                <option value='l2'>David Braley Health Sciences Centre</option>
                <option value='l3'>
                  McMaster University Centre for Continuing Education
                </option>
                <option value='l4'>
                  Ron Joyce Centre | DeGroote School of Business
                </option>
                <option value='l5'>
                  McMaster University Waterloo Regional Campus
                </option>
                <option value='l6'>McMaster Innovation Park</option>
              </select>

              <h6>
                <strong>Distance: </strong>
                {locat['distance']}
              </h6>
              <h6>
                <strong>Driving Time: </strong>
                {locat['drivingTime']}
              </h6>
              <h6>
                <strong>Walking Time: </strong>
                {locat['walkingTime']}
              </h6>
              <h6>
                <strong>Transit Time: </strong>
                {locat['transitTime']}
              </h6> */}
              {/* <iframe title={'unique'} height='450' src={map} /> */}

              <GoogleMap
                // initialCenter={{
                //   lat: -122.103149,
                //   lng: 47.675192,
                // }}
                initialCenter={locat}
                mapStyle={'Default'}
                // mapStyle={getItemFromStore('isDark') ? 'Midnight' : 'Default'}
                className='min-vh-50 rounded-soft'
              >
                <h5>
                  <small>{mapMarkerTitle}</small>
                </h5>
                {/* <p>
                  Gustave Eiffel's iconic, wrought-iron 1889 tower,
                  <br />
                  with steps and elevators to observation decks.
                </p> */}
              </GoogleMap>
            </div>

            <div
              className={classNames('tab-content', {
                active: activeTab === 'reviews',
              })}
            >
              <Row>
                <Col lg={6} className='mb-4 mb-lg-0'>
                  <div className='mb-1'>
                    {/* <StarCount stars={4.5} /> */}
                    <span className='ml-3 text-dark font-weight-semi-bold'>
                      Awesome support, great code{' '}
                      <span role='img' aria-label='emoji'>
                        😍
                      </span>
                    </span>
                  </div>
                  <p className='fs--1 mb-2 text-600'>
                    By Drik Smith • October 14, 2019
                  </p>
                  <p className='mb-0'>
                    You shouldn't need to read a review to see how nice and
                    polished this theme is. So I'll tell you something you won't
                    find in the demo. After the download I had a technical
                    question, emailed the team and got a response right from the
                    team CEO with helpful advice.
                  </p>
                  <hr className='my-4' />
                  <div className='mb-1'>
                    {/* <StarCount stars={3.5} /> */}
                    <span className='ml-3 text-dark font-weight-semi-bold'>
                      Outstanding Design, Awesome Support
                    </span>
                  </div>
                  <p className='fs--1 mb-2 text-600'>
                    By Liane • December 14, 2019
                  </p>
                  <p className='mb-0'>
                    This really is an amazing template - from the style to the
                    font - clean layout. SO worth the money! The demo pages show
                    off what Bootstrap 4 can impressively do. Great template!!
                    Support response is FAST and the team is amazing -
                    communication is important.
                  </p>
                  <hr className='my-4' />
                </Col>
                <Col lg={6} className='pl-lg-5'>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <h5 className='mb-3'>Write your Review</h5>
                    <FormGroup>
                      <Label>Rating:</Label>
                      <br />
                      {/* <StarCount stars={0} /> */}
                    </FormGroup>
                    <FormGroup>
                      <FalconInput label='Name:' />
                    </FormGroup>
                    <FormGroup>
                      <FalconInput label='Email:' type='email' />
                    </FormGroup>
                    <FormGroup>
                      <FalconInput label='Review:' type='textarea' rows='2' />
                    </FormGroup>
                    <Button color='primary' type='submit'>
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default ProductDetailsFooterHousingAd;

ProductDetailsFooterHousingAd.propTypes = {
  locationDetails: PropTypes.object,
  //for tab 2 = Description
  description: PropTypes.string,
  //for tab 3 = Detials
  amenities: PropTypes.array,
  details: PropTypes.array,
  utilities: PropTypes.array,
  user: PropTypes.object,
  houseAd: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  houseAd: state.housingReducer.houseAd,
});

export default connect(mapStateToProps, {})(ProductDetailsFooterHousingAd);
