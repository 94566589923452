import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';

// components
import { isIterableArray } from '../../../../helpers/utils';
import { cleanKeyTerms } from './SharedHelperFunctions';

const ProductDetailsMainHousingAd = ({
  phone,
  email,
  description,
  university,
  details,
  title,
  amenities,
  utilities,
}) => {
  return (
    <Fragment>
      <div>
        {/* <Alert color={`secondary`}> */}
        <h4>{title}</h4>

        <Badge
          color='primary'
          // pill
          className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
        >
          {`Rent: $ ${details.rent} / month`}
        </Badge>

        {/* </Alert> */}
        <div className='fs--1'>{description}</div>

        <br />
        <div className='fs--1'>
          {details &&
            Object.keys(details).map((key, index) => (
              <div key={index}>
                <strong>{cleanKeyTerms(key) + ': '}</strong>
                <b className='text-primary'>
                  {key === 'rent' ? `$ ${details[key]}.00` : details[key]}
                </b>
              </div>
            ))}
        </div>

        <br />
        {isIterableArray(amenities) && (
          <div>
            <h6>Amenities</h6>
            <ul className='fs--1 pl-0'>
              {amenities.map((feature, index) => (
                <Badge
                  key={index}
                  color='soft-info'
                  className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                >
                  {feature}
                </Badge>
              ))}
            </ul>
          </div>
        )}
        {isIterableArray(utilities) && (
          <div>
            <h6>Utilities Included In Rent</h6>
            <ul className='fs--1 pl-3'>
              {utilities
                .filter((item) => item.length > 0)
                .map((feature, index) => (
                  <li key={index}>
                    <Badge
                      color='soft-success'
                      // pill
                      className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                    >
                      {feature}
                    </Badge>
                  </li>
                  // <li key={i}>{feature}</li>
                ))}
            </ul>
          </div>
        )}

        <div className='fs--1'>
          <h6>Contact Information</h6>

          {email !== '' && (
            <div>
              <FontAwesomeIcon icon='envelope' className='mr-1' /> {email}
            </div>
          )}
          {phone !== '' && (
            <div>
              <FontAwesomeIcon icon='phone' className='mr-1' /> {phone}
            </div>
          )}
        </div>
        {university && (
          <div className='fs--1 mt-3'>
            <h6>University</h6>
            <div>
              <FontAwesomeIcon icon='graduation-cap' /> {university}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

ProductDetailsMainHousingAd.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.object,
  title: PropTypes.string,
  amenities: PropTypes.array,
  utilities: PropTypes.array,
  university: PropTypes.string,
};

export default ProductDetailsMainHousingAd;
