import React, { Fragment } from 'react';

//components
import PreviewHousingAd from '../../previewHousingAd/PreviewHousingAd';
import ClaimAdPrompt from './ClaimAdPrompt';

const SeeDemoAd = () => {
  return (
    <Fragment>
      <PreviewHousingAd />
      <ClaimAdPrompt />
    </Fragment>
  );
};

export default SeeDemoAd;
