import React, { Fragment } from 'react';
import { connect } from 'react-redux';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import CompaniesPublic from './CompaniesPublic';

const ViewCompaniesPublicPage = () => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container mt-7 mb-4'}>
          <CompaniesPublic />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

ViewCompaniesPublicPage.defaultProps = { isLast: false };

ViewCompaniesPublicPage.propTypes = {};

export default connect(null, {})(ViewCompaniesPublicPage);
