import axios from 'axios';

import {
  GET_HOUSING,
  SET_HOUSING_REDUCER_LOADING,
  GET_MY_HOUSING_ADS_STATS,
  GET_SINGLE_HOUSE_AD,
  DELETE_HOUSING,
  SET_IGNORED,
  GET_MY_HOUSING_ADS,
  ADD_HOUSING_TO_FAV,
  REMOVE_HOUSING_FROM_FAV,
  GET_FAVOURITE_HOUSING_ADS,
} from '../types';
import {
  sortByRent,
  sortByDateAvailable,
  sortByDateCreated,
  sortByFeatured,
  sortByStatus,
} from '../helpers/sortFunctions';
// utils
import {
  axiosErrorToast,
  // axiosSuccessToast,
  // errorToast,
  // successToast,
} from '../../utils/toastHandler';

// import store
import store from '../../store';

// Get housing
export const getHousingAds_Action =
  (query = '') =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_HOUSING_REDUCER_LOADING,
        payload: {},
      });
      const api_url =
        query === ''
          ? `/api/v1/housing/search`
          : `/api/v1/housing/search${query}`;
      const res = await axios.post(api_url);
      dispatch({
        type: GET_HOUSING,
        payload: {
          datapayload: sortByFeatured(res.data.dataPayload.housingAds, false),
        },
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  };

export const getFavouriteHousingAds_Action = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_HOUSING_REDUCER_LOADING,
      payload: {},
    });

    const res = await axios.get(`/api/v1/housing/favourites`);
    dispatch({
      type: GET_FAVOURITE_HOUSING_ADS,
      payload: res.data.dataPayload.allFavouriteAds,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const addFavouriteHousing_Action = (houseId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/housing/addFavouriteHouse/${houseId}`);
    dispatch({
      type: ADD_HOUSING_TO_FAV,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};
export const removeFavouriteHousing_Action = (houseId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/v1/housing/removeFavouriteHouse/${houseId}`
    );
    dispatch({
      type: REMOVE_HOUSING_FROM_FAV,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const getHouseAdById_Action = (hid) => async (dispatch) => {
  try {
    dispatch({
      type: SET_HOUSING_REDUCER_LOADING,
      payload: {},
    });
    const res = await axios.get(`/api/v1/housing/${hid}`);
    dispatch({
      type: GET_SINGLE_HOUSE_AD,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Search Housing
export const searchHousing_Action = (searchParams) => async (dispatch) => {
  try {
    dispatch({
      type: SET_HOUSING_REDUCER_LOADING,
      payload: {},
    });
    const res = await axios.post('/api/v1/housing/search', {
      searchParams: searchParams,
    });
    dispatch({
      type: GET_HOUSING,
      payload: {
        datapayload: sortByFeatured(res.data.dataPayload.housingAds, false),
      },
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Get my housing ads stats
export const getMyHousingAdsStats_Action = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/v1/housing/stats');

    dispatch({
      type: GET_MY_HOUSING_ADS_STATS,
      payload: res.data.dataPayload.myStats,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Get My Housing Ads
export const getMyHousingAds_Action = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_HOUSING_REDUCER_LOADING,
      payload: {},
    });
    const res = await axios.get('/api/v1/housing/getmyads');
    dispatch({
      type: GET_MY_HOUSING_ADS,
      payload: { datapayload: sortByStatus(res.data.dataPayload.houseAds) },
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// delete housing Ad
export const deleteHousingAd_Action = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/v1/housing/${id}`);
    dispatch({
      type: DELETE_HOUSING,
      payload: id,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const sortHousing = (sortField, isAsc) => async (dispatch) => {
  dispatch({
    type: SET_HOUSING_REDUCER_LOADING,
    payload: {},
  });

  const data = await store.getState().housingReducer.housePosts;

  if (sortField === 'featured') {
    dispatch({
      type: GET_HOUSING,
      payload: { datapayload: sortByFeatured(data, isAsc) },
    });
  } else if (sortField === 'date available') {
    dispatch({
      type: GET_HOUSING,
      payload: { datapayload: sortByDateAvailable(data, isAsc) },
    });
  } else if (sortField === 'date created') {
    dispatch({
      type: GET_HOUSING,
      payload: { datapayload: sortByDateCreated(data, isAsc) },
    });
  } else if (sortField === 'rent') {
    dispatch({
      type: GET_HOUSING,
      payload: { datapayload: sortByRent(data, isAsc) },
    });
  } else {
    dispatch({
      type: SET_HOUSING_REDUCER_LOADING,
      payload: {},
    });
  }
};

export const hideAd = (id) => async (dispatch) => {
  dispatch({
    type: SET_IGNORED,
    payload: id,
  });
};
