import {
  LOGOUT,
  SET_PRODUCT_REDUCER_LOADING,
  GET_PRODUCTS,
  UPDATE_USER_PRODUCT,
  GET_USER_PRODUCTS,
  ADD_PRODUCT_TO_FAV,
  GET_PRODUCT,
  REMOVE_PRODUCT_FROM_FAV,
  GET_FAVOURITE_PRODUCTS,
  GET_FILTERED_PRODUCTS,
  DELETE_PRODUCT,
  SELL_PRODUCT,
  RELIST_PRODUCT,
} from '../types';

const initialState = {
  products: [],
  product: null,
  loading: true,
  favProducts: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        products: payload.products,
        loading: false,
      };
    }
    case GET_FILTERED_PRODUCTS: {
      return {
        ...state,
        products: payload.datapayload.products,
        loading: false,
      };
    }
    case DELETE_PRODUCT: {
      return {
        ...state,
        products: state.products.filter((p) => p._id !== payload),
        loading: false,
      };
    }
    case GET_PRODUCT:
      return {
        ...state,
        product: payload.product,
        loading: false,
      };
    case SELL_PRODUCT:
      return {
        ...state,
        product: payload.updatedProduct,
        products: state.products.map((p) => {
          if (p._id === payload.updatedProduct._id) {
            return payload.updatedProduct;
          } else {
            return p;
          }
        }),
      };
    case RELIST_PRODUCT:
      return {
        ...state,
        product: payload.updatedProduct,
        products: state.products.map((p) => {
          if (p._id === payload.updatedProduct._id) {
            return payload.updatedProduct;
          } else {
            return p;
          }
        }),
      };
    case GET_FAVOURITE_PRODUCTS:
      return {
        ...state,
        favProducts: payload.allFavouriteProducts,
        loading: false,
      };
    case UPDATE_USER_PRODUCT:
      return {
        ...state,
        product: payload.updatedProduct,
        products: state.products.map((p) => {
          if (p._id === payload.updatedProduct._id) {
            return payload.updatedProduct;
          } else {
            return p;
          }
        }),
      };
    case ADD_PRODUCT_TO_FAV: {
      return {
        ...state,
        favProducts: [...state.favProducts, payload.addedProd[0]],
        loading: false,
      };
    }
    case REMOVE_PRODUCT_FROM_FAV:
      return {
        ...state,
        favProducts: state.favProducts.filter((product) =>
          payload.removedProd.favouriteProducts.includes(product._id)
        ),
        loading: false,
      };
    case GET_USER_PRODUCTS:
      return {
        ...state,
        products: payload.userProducts,
        loading: false,
      };
    case SET_PRODUCT_REDUCER_LOADING:
      return { ...state, loading: true };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
