import React from 'react';
import PropTypes from 'prop-types';

// libraries
import { CardBody, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import parse from 'html-react-parser';

// misc
// import createMarkup from "../../../helpers/createMarkup";

// components
import FeedEvent from '../FeedEvent';
import FeedUrl from '../FeedUrl';
import FalconPlyr from '../../common/FalconPlyr';
import FalconLightBox from '../../common/FalconLightBox';
import LightBoxGallery from '../../common/LightBoxGallery';

const FeedCardContent = ({
  status,
  imgSrc,
  gallery,
  feedEvent,
  url,
  video,
}) => {
  return (
    <CardBody className={classNames({ 'p-0': !!feedEvent })}>
      {!!status && (
        <ReactQuill value={parse(status)} readOnly={true} theme={'bubble'} />
      )}
      {!!imgSrc && (
        <FalconLightBox imgSrc={imgSrc}>
          <img src={imgSrc} className='img-fluid rounded' alt='' />
        </FalconLightBox>
      )}

      <LightBoxGallery images={gallery}>
        {(openImgIndex) => (
          <Row noGutters className='mx-n1 px-2 py-2'>
            {gallery.map((img, index) => {
              return (
                <Col
                  xs={6}
                  md={gallery.length === 1 ? 12 : gallery.length === 2 ? 6 : 4}
                  className='px-1'
                  style={{ minWidth: 50 }}
                  key={index}
                >
                  <img
                    className='img-fluid rounded mb-2 cursor-pointer'
                    src={img}
                    alt=''
                    onClick={() => openImgIndex(index)}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </LightBoxGallery>
      {!!feedEvent && <FeedEvent {...feedEvent} />}
      {!!url && <FeedUrl {...url} />}
      {!!video && (
        <div className='rounded-soft overflow-hidden position-relative'>
          <FalconPlyr {...video} />
        </div>
      )}
    </CardBody>
  );
};

FeedCardContent.propTypes = {
  status: PropTypes.string,
  imgSrc: PropTypes.string,
  gallery: PropTypes.array,
  feedEvent: PropTypes.object,
  url: PropTypes.object,
  video: PropTypes.object,
};

export default FeedCardContent;
