import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FalconLightBox from '../common/FalconLightBox';

const Background = ({
  image,
  overlay,
  position,
  video,
  className,
  style,
  expandable,
}) => {
  const bgStyle = { backgroundImage: `url(${image})`, ...style };
  if (typeof position === 'string') {
    bgStyle.backgroundPosition = position;
  } else if (typeof position === 'object') {
    position.x && (bgStyle.backgroundPositionX = position.x);
    position.y && (bgStyle.backgroundPositionY = position.y);
  }

  const imageSyntax = (
    <Fragment>
      <div
        className={classNames(
          'bg-holder',
          {
            overlay: overlay,
            [`overlay-${overlay}`]: typeof overlay === 'string',
          },
          className
        )}
        style={bgStyle}
      >
        {video && (
          <video className='bg-video' autoPlay loop muted playsInline>
            {video.map((src, index) => (
              <source
                key={index}
                src={src}
                type={`video/${src.split('.').pop()}`}
              />
            ))}
          </video>
        )}
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {expandable === true ? (
        <FalconLightBox imgSrc={image}>{imageSyntax}</FalconLightBox>
      ) : (
        imageSyntax
      )}
    </Fragment>
  );
};

Background.propTypes = {
  image: PropTypes.string,
  overlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
    }),
  ]),
  video: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  expandable: PropTypes.bool,
};

Background.defaultProps = {
  expandable: false,
};

export default Background;
