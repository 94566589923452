import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';
import {
  axiosErrorToast,
  axiosSuccessToast,
  errorToast,
} from '../utils/toastHandler';

const getTutors = async () => {
  const res = await axios.post(`/api/v2/tutor/search/tutors`, {});

  return res.data.dataPayload.tutors;
};

export const useGetAllTutors_Query = () => {
  return useQuery(['tutors'], () => getTutors(), {
    cacheTime: minutesToMiliseconds(600),
    staleTime: minutesToMiliseconds(600),
    onError: (err) => axiosErrorToast(err),
  });
};

export const useGetTutorById_Query = (tutorId) => {
  return useQuery(
    ['tutor', tutorId],
    () =>
      axios
        .get(`/api/v2/tutor/view/${tutorId}`)
        .then((res) => res.data.dataPayload.tutor),
    {
      cacheTime: minutesToMiliseconds(600),
      staleTime: minutesToMiliseconds(600),
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

const createTutor = async (tutorInfo) => {
  const res = await axios.post('/api/v2/tutor/', tutorInfo);
  return { res: res, newTutor: res.data.dataPayload.newTutor };
};

export const useCreateTutor_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((tutorInfo) => createTutor(tutorInfo), {
    onSuccess: async (data) => {
      const oldTutorsData = await QueryClient.getQueriesData(['tutors']);

      axiosSuccessToast(data.res);

      Promise.all([
        QueryClient.setQueryData(['tutors'], [...oldTutorsData, data.newTutor]),
        QueryClient.setQueryData(['tutor', data.newTutor._id], data.newTutor),
      ]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const searchTutors = async (searchParams) => {
  const res = await axios.post(`/api/v2/tutor/search/tutors`, { searchParams });

  return { res: res, tutors: res.data.dataPayload.tutors };
};

export const useSearchTutors_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((searchParams) => searchTutors(searchParams), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);

      await QueryClient.invalidateQueries(['tutors']);

      QueryClient.setQueryData(['tutors'], [...data.tutors]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

// const update background pic
const updateTutorBackgroundPic = async (input) => {
  const formData = new FormData();
  formData.append('bg', input.bg);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await axios.put('/api/v2/tutor/newBg', formData, config);

  return { res: res, newProfile: res.data.dataPayload.profile };
};

export const useUpdateTutorBackgroundPic_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => updateTutorBackgroundPic(inputData), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);
      await QueryClient.cancelQueries(['tutors', inputData.userId]);

      const oldProfileData = QueryClient.getQueryData([
        'tutors',
        inputData.userId,
      ]);

      QueryClient.setQueryData(['tutors', inputData.userId], {
        ...oldProfileData,
        ...data.newProfile,
      });
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const updateTutorInfo = async (tutorInfo) => {
  const res = await axios.put(`/api/v2/tutor/update/${tutorInfo.userId}`, {
    tutorData: tutorInfo.tutorData,
  });
  return { res: res, tutorId: tutorInfo.id };
};

export const useUpdateTutorProfile_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((tutorInfo) => updateTutorInfo(tutorInfo), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);
      await QueryClient.refetchQueries(['tutor', inputData.userId]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

export const useDeactivateTutorQuery = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (tutorId) =>
      axios.put(`/api/v2/tutor/deactivate/${tutorId}`).then((res) => res.data),
    {
      onSuccess: async (data, tutorId) => {
        errorToast(data.message);

        await QueryClient.invalidateQueries(['tutor', tutorId]);

        const oldProfileData = QueryClient.getQueryData(['tutor', tutorId]);

        QueryClient.setQueryData(['tutor', tutorId], {
          ...oldProfileData,
          profileStatus: 'deactivated',
        });
      },
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useReactivateTutorQuery = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (tutorId) =>
      axios.put(`/api/v2/tutor/reactivate/${tutorId}`).then((res) => res),
    {
      onSuccess: async (data, tutorId) => {
        axiosSuccessToast(data);

        await QueryClient.invalidateQueries(['tutor', tutorId]);

        const oldProfileData = QueryClient.getQueryData(['tutor', tutorId]);

        QueryClient.setQueryData(['tutor', tutorId], {
          ...oldProfileData,
          profileStatus: 'active',
        });
      },
      onError: (err) => axiosErrorToast(err),
    }
  );
};
