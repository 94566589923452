import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';
import team3 from '../../assets/img/team/3.jpg';
import Avatar from '../common/Avatar';

// actions
import { logout } from '../../redux/actions/authActions';

// Events
import { posthog_userLoggedOut_Event } from '../../utils/posthogEvents/posthogAuthEvents';

const ProfileDropdown = ({
  auth: { isAuthenticated, loading, user },
  logout,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className='pr-0'>
        <Avatar src={user ? user.avatar : team3} />
      </DropdownToggle>

      <DropdownMenu right className='dropdown-menu-card'>
        <div className='bg-white rounded-soft py-2'>
          {/* <DropdownItem className='font-weight-bold text-warning' href='#!'>
            <FontAwesomeIcon icon='crown' className='mr-1' />
            <span>Go Pro</span>
          </DropdownItem> */}
          <DropdownItem href='#!' className='text-dark'>
            {user ? user.name : ''}
          </DropdownItem>
          <DropdownItem divider />

          {/* <DropdownItem href='#!'>Set status</DropdownItem> */}
          {user ? (
            <DropdownItem tag={Link} to={`/profile/${user._id}`}>
              {/* Profile &amp; account */}
              My Profile
            </DropdownItem>
          ) : null}

          <DropdownItem tag={Link} to='/profile/settings'>
            My Settings
          </DropdownItem>

          <DropdownItem tag={Link} to='/profile/referrals'>
            My Referrals
          </DropdownItem>
          <DropdownItem divider />

          {/* <DropdownItem tag={Link} to='/authentication/logout'> */}
          <DropdownItem
            className='text-danger'
            tag={Link}
            to='/'
            onClick={() => {
              toast.info(`Successfully Logged out!`);
              posthog_userLoggedOut_Event(user);
              logout();
            }}
          >
            <div>
              <b>Logout</b>
            </div>
          </DropdownItem>
          <DropdownItem divider />

          <DropdownItem className='text-warning' tag={Link} to={`/contact`}>
            Contact Peernet
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, { logout })(ProfileDropdown);
