// libraries
import axios from 'axios';
import { toast } from 'react-toastify';

// misc
import { axiosErrorToast, axiosSuccessToast } from '../../utils/toastHandler';

// static
import { SET_REFER_ID } from '../types';

export const inviteUserToJoinWeb = (input) => async () => {
  const data = { email: input.email };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(`/api/v2/other/invitetop`, data, config);
    toast.success(`Invited user to Peernet: ${input.email}`);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const contactUsLoggedIn = (input) => async (dispatch) => {
  const data = { content: input.message };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(`/api/v2/other/contactus`, data, config);
    toast.success(
      `Thank you for reaching out, ${input.name}! We will be in touch shortly`
    );
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const contactLandlordLoggedIn = (input) => async (dispatch) => {
  const data = { content: input.message, receiver: input.landlordEmail };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(`/api/v2/other/contactLandlord`, data, config);
    toast.success(`Your message will be sent to the landlord, ${input.name}`);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const contactUsPublicAction = (input) => async (dispatch) => {
  const data = { content: input.message, name: input.name, email: input.email };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    await axios.post(`/api/v2/other/contactuspub`, data, config);
    toast.info(
      `Thank you for reaching out, ${input.name}! We will be in touch shortly`
    );
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const updateEmailPreferenceAction = (input) => async (dispatch) => {
  // const data = { content: input.message, name: input.name, email: input.email };
  if (input.email && input.preference) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(
        `/api/v2/other/addUnsubscription`,
        input,
        config
      );
      toast.success(
        `Thank you for reaching out! We will update your email settings`
      );
      axiosSuccessToast(res);
    } catch (err) {
      axiosErrorToast(err);
    }
  }
};

export const contactClubLoggedIn = (input) => async (dispatch) => {
  const data = {
    senderName: input.name,
    messageContent: input.message,
    senderEmail: input.email,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    await axios.post(`/api/v2/club/misc/contact/${input.clubId}`, data, config);
    toast.success(`Your message will be sent to the club, ${input.name}`);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const setReferId_Action = (referId) => async (dispatch) => {
  dispatch({
    type: SET_REFER_ID,
    payload: { referId },
  });
};
