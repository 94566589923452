import React from 'react';
import PropTypes from 'prop-types';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed }) => {
  return <></>;
};

NavbarTopDropDownMenus.propTypes = {
  setNavbarCollapsed: PropTypes.func.isRequired,
};

export default NavbarTopDropDownMenus;
