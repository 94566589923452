import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  Card,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import Scrollbar from 'react-scrollbars-custom';

// misc
import { isIterableArray } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';

// components
import NotificationSelector from '../notification/NotificationSelector';

// queries
import {
  useGetMyNotifications_Query,
  useMarkAllNotificationsAsRead_Query,
} from '../../queries/notificationQueries';

const NotificationDropdown = () => {
  const {
    isLoading: notiLoading,
    isError: notiError,
    data: notifications,
  } = useGetMyNotifications_Query();

  const { mutate: markAllNotiAsRead } = useMarkAllNotificationsAsRead_Query();

  const sliceAmount = 25;
  const [isUnread, setIsUnread] = useState(false);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setIsUnread(notifications.some((noti) => noti.status === 'unread'));
    }
  }, [notifications]);

  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const markAsRead = (e) => {
    e.preventDefault();
    const notificationsIdArr = notifications
      .filter((n) => n.status === 'unread')
      .map((n) => n._id);
    markAllNotiAsRead({ notiIds: notificationsIdArr });
  };

  return (
    <Fragment>
      {(notifications === undefined && notiError === false) || notiLoading ? (
        <div className='text-center'>
          <div className='text-center'>
            <Spinner color='primary' size='sm' />
          </div>
        </div>
      ) : (
        <Fragment>
          <Dropdown
            nav
            inNavbar
            isOpen={isOpen}
            toggle={handleToggle}
            onMouseOver={() => {
              let windowWidth = window.innerWidth;
              windowWidth > 992 && setIsOpen(true);
            }}
            onMouseLeave={() => {
              let windowWidth = window.innerWidth;
              windowWidth > 992 && setIsOpen(false);
            }}
          >
            <DropdownToggle
              nav
              className={classNames('px-0', {
                'notification-indicator notification-indicator-primary': isUnread,
              })}
            >
              <FontAwesomeIcon
                icon='bell'
                transform='shrink-6'
                className='fs-4'
              />
            </DropdownToggle>
            <DropdownMenu right className='dropdown-menu-card'>
              <Card
                className='card-notification shadow-none'
                style={{ maxWidth: '20rem' }}
              >
                <FalconCardHeader
                  className='card-header'
                  title='Notifications'
                  titleTag='h6'
                  light={false}
                >
                  <Link
                    className='card-link font-weight-normal'
                    to='#!'
                    onClick={markAsRead}
                  >
                    Mark all as read
                  </Link>
                </FalconCardHeader>
                <ListGroup flush className='font-weight-normal fs--1'>
                  {notifications.length > 0 ? (
                    <div className='list-group-title'>NEW</div>
                  ) : null}
                  <Scrollbar
                    style={{
                      width: '100%',
                      height: Math.min(
                        sliceAmount * 59,
                        400,
                        notifications.length * 59
                      ),
                    }}
                  >
                    {isIterableArray(notifications) &&
                      notifications.slice(0, sliceAmount).map((n, index) => {
                        return (
                          <ListGroupItem key={index} onClick={handleToggle}>
                            <NotificationSelector
                              notificationData={n}
                              notificationType={n.notifType}
                            />
                          </ListGroupItem>
                        );
                      })}
                  </Scrollbar>
                </ListGroup>

                {notifications.length > 0 ? null : (
                  <div className='text-center'>no notifications</div>
                )}

                <div
                  className='card-footer text-center border-top-0'
                  onClick={handleToggle}
                >
                  <Link
                    className='card-link d-block'
                    to='/pages/my-notifications'
                  >
                    See more
                  </Link>
                </div>
              </Card>
            </DropdownMenu>
          </Dropdown>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NotificationDropdown;
