import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';

// components
import EventSummary from '../event/EventSummary';
import FalconCardHeader from '../../peernet/common/FalconCardHeader';

//misc
import { isIterableArray } from '../../helpers/utils';

// queries
import { useGetEvents_Query } from '../../queries/eventQueries';

const AllEvents = ({ clubEvents, hideOrganizedByLine }) => {
  const { isLoading: isEventsLoading, data: allEvents } = useGetEvents_Query();
  const events = clubEvents ?? allEvents;

  return (
    <Fragment>
      {isEventsLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <Card>
            <FalconCardHeader title='All Events'>
              {/* {isIterableArray(events) && (
                <Form inline>
                  <CustomInput
                    type='select'
                    id='customSelectCategory'
                    name='customSelectCategory'
                    bsSize='sm'
                  >
                    {eventCategories.map((option, index) => (
                      <option value={index} key={index}>
                        {option}
                      </option>
                    ))}
                  </CustomInput>
                </Form>
              )} */}
            </FalconCardHeader>
            <CardBody className='fs--1'>
              {isEventsLoading ? (
                <Spinner />
              ) : (
                <Row>
                  {isIterableArray(events) &&
                    events.map((event, index) => {
                      return (
                        <Col md={6} className='h-100' key={index}>
                          <EventSummary
                            divider={
                              (events.length % 2 === 0 &&
                                index + 1 + 2 > events.length) ||
                              (events.length % 2 === 1 &&
                                index + 1 + 1 > events.length)
                                ? false
                                : true
                            }
                            event={event}
                            hideOrganizedByLine={hideOrganizedByLine}
                          />
                        </Col>
                      );
                    })}
                </Row>
              )}
            </CardBody>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

AllEvents.propTypes = {
  clubEvents: PropTypes.array,
  hideOrganizedByLine: PropTypes.bool,
};

AllEvents.defaultProps = {
  clubEvents: null,
  hideOrganizedByLine: false,
};

export default AllEvents;
