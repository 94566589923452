import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

// discuss - add house address/ title for better logs
export const posthog_HousingAdOpened = (houseAd, user) => {
  if (houseAd && user && user._id) {
    posthog.capture('housingAdViewed', {
      userId: user._id,
      housingAdViewed___houseId: houseAd._id,
    });

    appInsights.trackEvent(
      { name: 'housingAdViewed' },
      {
        userId: user._id,
        housingAdViewed___houseId: houseAd._id,
      }
    );
  }
};

export const posthog_HousingAdOpened_Public = (houseAd) => {
  if (houseAd && houseAd._id) {
    posthog.capture('housingAdViewedPublic', {
      housingAdViewed___houseId: houseAd._id,
    });

    appInsights.trackEvent(
      { name: 'housingAdViewedPublic' },
      {
        housingAdViewed___houseId: houseAd._id,
      }
    );
  }
};

export const posthog_ViewAllHousingAdsOpened = (housePosts, user) => {
  if (housePosts && housePosts.length > 0 && user && user._id) {
    posthog.capture('viewAllHousing', {
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewAllHousing' },
      {
        userId: user._id,
      }
    );
  }
};

export const posthog_ViewAllHousingAdsOpened_Public = (housePosts) => {
  if (housePosts && housePosts.length > 0) {
    posthog.capture('viewAllHousingPublic');

    appInsights.trackEvent({ name: 'viewAllHousingPublic' });
  }
};

export const posthog_housingDetailsViewed_Event = (
  houseAd,
  activeTab,
  user
) => {
  if (user && user._id && houseAd && activeTab) {
    posthog.capture('housingDetailsViewed', {
      housingDetailsViewed___detailSection: activeTab,
      userId: user._id,
      housingDetailsViewed___houseId: houseAd._id,
    });

    appInsights.trackEvent(
      { name: 'housingDetailsViewed' },
      {
        housingDetailsViewed___detailSection: activeTab,
        userId: user._id,
        housingDetailsViewed___houseId: houseAd._id,
      }
    );
  }
};

export const posthog_unclaimedAdOpened_Event = (hid, email) => {
  if (hid && email) {
    posthog.capture('unclaimedAdOpened', {
      unclaimedAdOpened___housingId: hid,
      unclaimedAdOpened___openerEmail: email,
    });

    appInsights.trackEvent(
      { name: 'unclaimedAdOpened' },
      {
        unclaimedAdOpened___housingId: hid,
        unclaimedAdOpened___openerEmail: email,
      }
    );
  }
};
