// libraries
import qs from 'query-string';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';
import { appInsights } from '../../AppInsights';

// utils
import { decryptCampaignTrackerParams } from '../encryption';

function useCampaignTracking() {
  const locationInfo = useLocation();
  const paramsInfo = qs.parse(locationInfo.search);
  let pncid = paramsInfo.pncid || '';

  if (pncid && pncid.length > 0) {
    let paramsObj = {};
    paramsObj = decryptCampaignTrackerParams(pncid);
    if (
      paramsObj &&
      Object.keys(paramsObj).length > 0 &&
      paramsObj.name &&
      paramsObj.createdDate
    ) {
      posthog.capture('peernetCampaign', {
        peernetCampaign___campaignName: paramsObj.name,
        peernetCampaign___createdDate: paramsObj.createdDate,
        peernetCampaign___pathname: locationInfo.pathname,
        peernetCampaign___search: locationInfo.search,
      });

      appInsights.trackEvent(
        { name: 'peernetCampaign' },
        {
          peernetCampaign___campaignName: paramsObj.name,
          peernetCampaign___createdDate: paramsObj.createdDate,
          peernetCampaign___pathname: locationInfo.pathname,
          peernetCampaign___search: locationInfo.search,
        }
      );
    }
  }
}

export default useCampaignTracking;
