import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// actions
import {
  favouriteProduct_Action,
  removeFavouriteProduct_Action,
  deleteProduct_Action,
} from '../../../../redux/actions/productActions';

// components
import Flex from '../../../common/Flex';
import ButtonIcon from '../../../common/ButtonIcon';

// libraries
import moment from 'moment';
import {
  Badge,
  Card,
  CardBody,
  Button,
  CardImg,
  ButtonToolbar,
} from 'reactstrap';

const ProductListings = ({
  product,
  isPublic,

  // actions
  favouriteProduct_Action,
  removeFavouriteProduct_Action,

  // reducer state
  productState: { favProducts },
  authState: { user },
}) => {
  const handleFavouriteProduct = (productId) => {
    if (favProducts.map((p) => p._id).indexOf(productId) < 0) {
      favouriteProduct_Action(productId);
    } else {
      removeFavouriteProduct_Action(productId);
    }
  };

  const { createdAt, productName, price, description, productType } = product;

  return (
    <div className='p-2 mt-0'>
      <Card className='rounded-lg' style={{ minHeight: '21.75rem' }}>
        <Flex justify='between' column className='border rounded h-100'>
          <div className='overflow-hidden'>
            <div className='position-relative rounded-top overflow-hidden'>
              <Link
                className='d-block h-100'
                to={`/marketplace/${product._id}`}
                key={product?._id}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <CardImg
                  variant='top'
                  className='rounded-lg fit-cover'
                  style={{ borderRadius: 0, height: '16rem' }}
                  src={product?.thumbnail}
                />
              </Link>

              {product?.stock === 'sold' ? (
                <Badge
                  color='danger'
                  pill
                  className='position-absolute t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                >
                  <strong>Sold</strong>
                </Badge>
              ) : (
                createdAt &&
                Math.abs(
                  moment(createdAt, 'YYYY-MM-DD').diff(
                    moment(new Date(), 'YYYY-MM-DD'),
                    'days'
                  )
                ) <= 10 && (
                  <Badge
                    color='success'
                    pill
                    className='position-absolute t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                  >
                    New
                  </Badge>
                )
              )}
              {
                <CardBody className='text-decoration-none'>
                  <h5 className='fs-2'>{productName}</h5>
                  <p className='fs-0'>
                    {(
                      productType[0].toUpperCase() + productType.slice(1)
                    ).replace('_', ' ')}
                  </p>
                  <Flex column className='fs-md-2 text-warning'>
                    <h5>
                      {product?.textbookDetails?.courses &&
                        productType === 'textbook' &&
                        product?.textbookDetails?.courses.map((c, index) => {
                          return (
                            <Badge color='soft-warning my-2 mr-1' key={index}>
                              {c.courseId.courseCode}
                            </Badge>
                          );
                        })}
                    </h5>
                    {`Price: $${price || `-`}`}
                    <h6>
                      {product.negotiable ? (
                        <Badge color='soft-success' pill>
                          Negotiable
                        </Badge>
                      ) : null}
                    </h6>
                  </Flex>
                  <hr />
                  <h6 className='p-2 fs-0'>
                    {description.length > 100
                      ? description.substring(0, 100) + '...'
                      : description}
                  </h6>
                  <hr />
                  <Flex align='center' justify='between'>
                    {!isPublic && (
                      <div>
                        <ButtonIcon
                          color={
                            favProducts
                              .map((p) => p._id)
                              .indexOf(product._id) >= 0
                              ? 'falcon-danger'
                              : 'falcon-default'
                          }
                          size='sm'
                          icon={[
                            favProducts
                              .map((p) => p._id)
                              .indexOf(product._id) >= 0
                              ? 'fas'
                              : 'far',
                            'heart',
                          ]}
                          onClick={() => handleFavouriteProduct(product._id)}
                          className='mr-2 mb-1'
                        />
                      </div>
                    )}
                    <ButtonToolbar>
                      {!isPublic && user._id === product.user._id && (
                        <Link
                          to={
                            productType === 'textbook'
                              ? `/marketplace/textbookexchange/edit/${product._id}`
                              : `/marketplace/edit/${product._id}`
                          }
                        >
                          <Button color='warning' className='me-2' size='sm'>
                            Edit
                          </Button>
                        </Link>
                      )}
                      <Link to={`/marketplace/${product._id}`}>
                        <Button color='primary' className='ml-1' size='sm'>
                          Info
                        </Button>
                      </Link>
                    </ButtonToolbar>
                  </Flex>
                </CardBody>
              }
            </div>
          </div>
        </Flex>
      </Card>
    </div>
  );
};

ProductListings.propTypes = {
  product: PropTypes.object.isRequired,
  isPublic: PropTypes.bool,

  // redux
  productState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,

  // actions
  favouriteProduct_Action: PropTypes.func.isRequired,
  removeFavouriteProduct_Action: PropTypes.func.isRequired,
};

ProductListings.defaultProps = {
  isPublic: false,
};

const mapStateToProps = (state) => ({
  productState: state.productReducer,
  authState: state.authReducer,
});

const mapDispatchToProps = {
  favouriteProduct_Action,
  removeFavouriteProduct_Action,
  deleteProduct_Action,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListings);
