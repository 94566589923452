import React, { Fragment } from 'react';

// libs
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

// static files
import club from '../../../assets/img/undraw/undraw_team_ih79.svg';

const ClubUpsellCard = () => {
  return (
    <Fragment>
      <Card className='mb-4'>
        <CardBody className='overflow-hidden p-4'>
          <Row className='align-items-center justify-content-between'>
            <Col lg={4}>
              <img src={club} className='img-fluid' alt='' />
            </Col>
            <Col lg={8} className='pl-lg-4 my-5 text-center text-lg-left'>
              <h3>{`Are you a Club Leader? Manage your club here!`}</h3>
              <p className='lead'>
                {`All the tools you need for your club or team under one service! Chat, Groups, Events, Social, Photos, Files, Calendar & More`}
              </p>
              <Link
                className='text-primary center-align text-underline'
                to='/clubs/view-all'
              >
                <Button className='btn btn-falcon-primary'>
                  {`See all clubs!`}
                </Button>
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ClubUpsellCard;
