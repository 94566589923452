export const UNIS_WITH_COURSES = [
  {
    location: {
      type: 'Point',
      coordinates: [-79.917013, 43.257928],
      formattedAddress: '1280 Main St W, Hamilton, ON L8S 4S5, CA',
      street: '1280 Main St W',
      city: 'Hamilton',
      state: 'ON',
      zipcode: 'L8S 4S5',
      country: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960199/Web%20App%20Static%20Files/McMaster_wb1b08.jpg',
    _id: '5ef1880c28f09dee8c2a2867',
    name: 'McMaster University',
    description:
      'McMaster University is a public research university in Hamilton, Ontario, Canada. The main McMaster campus is on 121 hectares of land near the residential neighbourhoods of Ainslie Wood and Westdale, adjacent to the Royal Botanical Gardens',
    website: 'https://www.mcmaster.ca/',
    phone: '905-525-9140',
    user: '5ee5584fea52cb1dd7536806',
    createdAt: '2020-06-23T04:41:48.385Z',
    slug: 'mcmaster-university',
    __v: 0,
    averageCost: 0,
    courses: [
      {
        _id: '6073b6f856f56ef638973f14',
        courseName: 'COMPENG 3SK3 - Computer-Aided Engineering',
        courseCode: '3SK3',
        shortName: 'COMPENG 3SK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 15,
        id: '6073b6f856f56ef638973f14',
      },
      {
        _id: '6073b6f856f56ef638973f16',
        courseName: 'COMPENG 4DK4 - Computer Communication Networks',
        courseCode: '4DK4',
        shortName: 'COMPENG 4DK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '6073b6f856f56ef638973f16',
      },
      {
        _id: '6073b6f856f56ef638973f18',
        courseName: 'COMPENG 4DM4 - Computer Architecture',
        courseCode: '4DM4',
        shortName: 'COMPENG 4DM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f856f56ef638973f18',
      },
      {
        _id: '6073b6f856f56ef638973f1a',
        courseName: 'COMPENG 4DN4 - Advanced Internet Communications',
        courseCode: '4DN4',
        shortName: 'COMPENG 4DN4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f856f56ef638973f1a',
      },
      {
        _id: '6073b6f856f56ef638973f1c',
        courseName: 'COMPENG 4DS4 - Embedded Systems',
        courseCode: '4DS4',
        shortName: 'COMPENG 4DS4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f856f56ef638973f1c',
      },
      {
        _id: '6073b6f856f56ef638973f1e',
        courseName: 'COMPENG 4EK4 - Microelectronics',
        courseCode: '4EK4',
        shortName: 'COMPENG 4EK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f856f56ef638973f1e',
      },
      {
        _id: '6073b6f856f56ef638973f20',
        courseName: 'COMPENG 4OH4 - Advanced Research Project',
        courseCode: '4OH4',
        shortName: 'COMPENG 4OH4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f856f56ef638973f20',
      },
      {
        _id: '6073b6f856f56ef638973f22',
        courseName: 'COMPENG 4OJ4 - Research Project',
        courseCode: '4OJ4',
        shortName: 'COMPENG 4OJ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f856f56ef638973f22',
      },
      {
        _id: '6073b6f856f56ef638973f24',
        courseName: 'COMPENG 4OK4 - Research Project',
        courseCode: '4OK4',
        shortName: 'COMPENG 4OK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f856f56ef638973f24',
      },
      {
        _id: '6073b6f856f56ef638973f26',
        courseName: 'COMPENG 4SL3 - Fundamentals of Machine Learning',
        courseCode: '4SL3',
        shortName: 'COMPENG 4SL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f856f56ef638973f26',
      },
      {
        _id: '6073b6f856f56ef638973f28',
        courseName: 'COMPENG 4TL4 - Digital Signal Processing',
        courseCode: '4TL4',
        shortName: 'COMPENG 4TL4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f856f56ef638973f28',
      },
      {
        _id: '6073b6f856f56ef638973f2a',
        courseName: 'COMPENG 4TN4 - Image Processing',
        courseCode: '4TN4',
        shortName: 'COMPENG 4TN4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f856f56ef638973f2a',
      },
      {
        _id: '6073b6f956f56ef638973f2c',
        courseName: 'COMPSCI 1DM3 - Discrete Mathematics for Computer Science',
        courseCode: '1DM3',
        shortName: 'COMPSCI 1DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f2c',
      },
      {
        _id: '6073b6f956f56ef638973f2e',
        courseName: 'COMPSCI 1JC3 - Introduction to Computational Thinking',
        courseCode: '1JC3',
        shortName: 'COMPSCI 1JC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f2e',
      },
      {
        _id: '6073b6f956f56ef638973f30',
        courseName: 'COMPSCI 1MD3 - Introduction to Programming',
        courseCode: '1MD3',
        shortName: 'COMPSCI 1MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f30',
      },
      {
        _id: '6073b6f956f56ef638973f32',
        courseName: 'COMPSCI 1TA3 - Elementary Computing and Computer Use',
        courseCode: '1TA3',
        shortName: 'COMPSCI 1TA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f32',
      },
      {
        _id: '6073b6f956f56ef638973f34',
        courseName:
          'COMPSCI 1XC3 - Computer Science Practice and Experience: Development Basics',
        courseCode: '1XC3',
        shortName: 'COMPSCI 1XC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f34',
      },
      {
        _id: '6073b6f956f56ef638973f36',
        courseName:
          'COMPSCI 1XD3 - Computer Science Practice and Experience: Introduction to Software Design Using Web Programming',
        courseCode: '1XD3',
        shortName: 'COMPSCI 1XD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f956f56ef638973f36',
      },
      {
        _id: '6073b6f956f56ef638973f38',
        courseName: 'COMPSCI 2AC3 - Automata and Computability',
        courseCode: '2AC3',
        shortName: 'COMPSCI 2AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f38',
      },
      {
        _id: '6073b6f956f56ef638973f3a',
        courseName: 'COMPSCI 2C03 - Data Structures and Algorithms',
        courseCode: '2C03',
        shortName: 'COMPSCI 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f3a',
      },
      {
        _id: '6073b6f956f56ef638973f3c',
        courseName: 'COMPSCI 2DB3 - Databases',
        courseCode: '2DB3',
        shortName: 'COMPSCI 2DB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f3c',
      },
      {
        _id: '6073b6f956f56ef638973f3e',
        courseName: 'COMPSCI 2DM3 - Discrete Mathematics with Applications I',
        courseCode: '2DM3',
        shortName: 'COMPSCI 2DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f956f56ef638973f3e',
      },
      {
        _id: '6073b6f956f56ef638973f40',
        courseName: 'COMPSCI 2FA3 - Discrete Mathematics with Applications II',
        courseCode: '2FA3',
        shortName: 'COMPSCI 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f956f56ef638973f40',
      },
      {
        _id: '6073b6fa56f56ef638973f42',
        courseName: 'COMPSCI 2GA3 - Computer Architecture',
        courseCode: '2GA3',
        shortName: 'COMPSCI 2GA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f42',
      },
      {
        _id: '6073b6fa56f56ef638973f44',
        courseName: 'COMPSCI 2LC3 - Logical Reasoning for Computer Science',
        courseCode: '2LC3',
        shortName: 'COMPSCI 2LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f44',
      },
      {
        _id: '6073b6fa56f56ef638973f46',
        courseName: 'COMPSCI 2ME3 - Introduction to Software Development',
        courseCode: '2ME3',
        shortName: 'COMPSCI 2ME3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f46',
      },
      {
        _id: '6073b6fa56f56ef638973f48',
        courseName: 'COMPSCI 2S03 - Principles of Programming',
        courseCode: '2S03',
        shortName: 'COMPSCI 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f48',
      },
      {
        _id: '6073b6fa56f56ef638973f4a',
        courseName: 'COMPSCI 2SD3 - Concurrent Systems',
        courseCode: '2SD3',
        shortName: 'COMPSCI 2SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f4a',
      },
      {
        _id: '6073b6fa56f56ef638973f4c',
        courseName:
          'COMPSCI 2XA3 - Computer Science Practice and Experience: Software Development Skills',
        courseCode: '2XA3',
        shortName: 'COMPSCI 2XA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f4c',
      },
      {
        _id: '6073b6fa56f56ef638973f4e',
        courseName:
          'COMPSCI 2XB3 - Computer Science Practice and Experience: Binding Theory to Practice',
        courseCode: '2XB3',
        shortName: 'COMPSCI 2XB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f4e',
      },
      {
        _id: '6073b6fa56f56ef638973f50',
        courseName:
          'COMPSCI 2XC3 - Computer Science Practice and Experience: Algorithms and Software Design',
        courseCode: '2XC3',
        shortName: 'COMPSCI 2XC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f50',
      },
      {
        _id: '6073b6fa56f56ef638973f52',
        courseName: 'COMPSCI 3AC3 - Algorithms and Complexity',
        courseCode: '3AC3',
        shortName: 'COMPSCI 3AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f52',
      },
      {
        _id: '6073b6fa56f56ef638973f54',
        courseName: 'COMPSCI 3DB3 - Databases',
        courseCode: '3DB3',
        shortName: 'COMPSCI 3DB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f54',
      },
      {
        _id: '6073b6fa56f56ef638973f56',
        courseName: 'COMPSCI 3EA3 - Software and System Correctness',
        courseCode: '3EA3',
        shortName: 'COMPSCI 3EA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f56',
      },
      {
        _id: '6073b6fa56f56ef638973f58',
        courseName: 'COMPSCI 3FP3 - Functional Programming',
        courseCode: '3FP3',
        shortName: 'COMPSCI 3FP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fa56f56ef638973f58',
      },
      {
        _id: '6073b6fb56f56ef638973f5a',
        courseName: 'COMPSCI 3GC3 - Computer Graphics',
        courseCode: '3GC3',
        shortName: 'COMPSCI 3GC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f5a',
      },
      {
        _id: '6073b6fb56f56ef638973f5c',
        courseName: 'COMPSCI 3I03 - Communication Skills',
        courseCode: '3I03',
        shortName: 'COMPSCI 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f5c',
      },
      {
        _id: '6073b6fb56f56ef638973f5e',
        courseName: 'COMPSCI 3IS3 - Information Security',
        courseCode: '3IS3',
        shortName: 'COMPSCI 3IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f5e',
      },
      {
        _id: '6073b6fb56f56ef638973f60',
        courseName: 'COMPSCI 3MI3 - Principles of Programming Languages',
        courseCode: '3MI3',
        shortName: 'COMPSCI 3MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f60',
      },
      {
        _id: '6073b6fb56f56ef638973f62',
        courseName: 'COMPSCI 3N03 - Computer Networks and Security',
        courseCode: '3N03',
        shortName: 'COMPSCI 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f62',
      },
      {
        _id: '6073b6fb56f56ef638973f64',
        courseName:
          'COMPSCI 3RA3 - Software Requirements and Security Considerations',
        courseCode: '3RA3',
        shortName: 'COMPSCI 3RA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f64',
      },
      {
        _id: '6073b6fb56f56ef638973f66',
        courseName: 'COMPSCI 3SD3 - Concurrent Systems',
        courseCode: '3SD3',
        shortName: 'COMPSCI 3SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6fb56f56ef638973f66',
      },
      {
        _id: '6073b6fb56f56ef638973f68',
        courseName:
          'COMPSCI 3SH3 - Computer Science Practice and Experience: Operating Systems',
        courseCode: '3SH3',
        shortName: 'COMPSCI 3SH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f68',
      },
      {
        _id: '6073b6fb56f56ef638973f6a',
        courseName: 'COMPSCI 3TB3 - Syntax-Based Tools and Compilers',
        courseCode: '3TB3',
        shortName: 'COMPSCI 3TB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6fb56f56ef638973f6a',
      },
      {
        _id: '6073b6fb56f56ef638973f6c',
        courseName: 'COMPSCI 4AD3 - Advanced Databases',
        courseCode: '4AD3',
        shortName: 'COMPSCI 4AD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f6c',
      },
      {
        _id: '6073b6fb56f56ef638973f6e',
        courseName: 'COMPSCI 4AR3 - Software Architecture',
        courseCode: '4AR3',
        shortName: 'COMPSCI 4AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f6e',
      },
      {
        _id: '6073b6fb56f56ef638973f70',
        courseName: 'COMPSCI 4C03 - Computer Networks and Security',
        courseCode: '4C03',
        shortName: 'COMPSCI 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fb56f56ef638973f70',
      },
      {
        _id: '6073b6fc56f56ef638973f72',
        courseName: 'COMPSCI 4DC3 - Distributed Computing',
        courseCode: '4DC3',
        shortName: 'COMPSCI 4DC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f72',
      },
      {
        _id: '6073b6fc56f56ef638973f74',
        courseName: 'COMPSCI 4E03 - Performance Analysis of Computer Systems',
        courseCode: '4E03',
        shortName: 'COMPSCI 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f74',
      },
      {
        _id: '6073b6fc56f56ef638973f76',
        courseName: 'COMPSCI 4EN3 A/B - Software Entrepreneurship',
        courseCode: '4EN3',
        shortName: 'COMPSCI 4EN3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f76',
      },
      {
        _id: '6073b6fc56f56ef638973f78',
        courseName: 'COMPSCI 4F03 - Parallel Computing',
        courseCode: '4F03',
        shortName: 'COMPSCI 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f78',
      },
      {
        _id: '6073b6fc56f56ef638973f7a',
        courseName: 'COMPSCI 4HC3 - Human Computer Interfaces',
        courseCode: '4HC3',
        shortName: 'COMPSCI 4HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f7a',
      },
      {
        _id: '6073b6fc56f56ef638973f7c',
        courseName: 'COMPSCI 4ML3 - Introduction to Machine Learning',
        courseCode: '4ML3',
        shortName: 'COMPSCI 4ML3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f7c',
      },
      {
        _id: '6073b6fc56f56ef638973f7e',
        courseName: 'COMPSCI 4O03 - Linear Optimization',
        courseCode: '4O03',
        shortName: 'COMPSCI 4O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f7e',
      },
      {
        _id: '6073b6fc56f56ef638973f80',
        courseName: 'COMPSCI 4TB3 - Syntax-Based Tools and Compilers',
        courseCode: '4TB3',
        shortName: 'COMPSCI 4TB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f80',
      },
      {
        _id: '6073b6fc56f56ef638973f82',
        courseName: 'COMPSCI 4TE3 - Continuous Optimization',
        courseCode: '4TE3',
        shortName: 'COMPSCI 4TE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f82',
      },
      {
        _id: '6073b6fc56f56ef638973f84',
        courseName: 'COMPSCI 4TH3 - Theory of Computation',
        courseCode: '4TH3',
        shortName: 'COMPSCI 4TH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f84',
      },
      {
        _id: '6073b6fc56f56ef638973f86',
        courseName: 'COMPSCI 4TI3 - Fundamentals of Image Processing',
        courseCode: '4TI3',
        shortName: 'COMPSCI 4TI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fc56f56ef638973f86',
      },
      {
        _id: '6073b6fd56f56ef638973f88',
        courseName: 'COMPSCI 4WW3 - Web Systems and Web Computing',
        courseCode: '4WW3',
        shortName: 'COMPSCI 4WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f88',
      },
      {
        _id: '6073b6fd56f56ef638973f8a',
        courseName: 'COMPSCI 4X03 - Scientific Computation',
        courseCode: '4X03',
        shortName: 'COMPSCI 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f8a',
      },
      {
        _id: '6073b6fd56f56ef638973f8c',
        courseName: 'COMPSCI 4Z03 - Directed Readings',
        courseCode: '4Z03',
        shortName: 'COMPSCI 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f8c',
      },
      {
        _id: '6073b6fd56f56ef638973f8e',
        courseName: 'COMPSCI 4ZP6 A/B - Capstone Project',
        courseCode: '4ZP6',
        shortName: 'COMPSCI 4ZP6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f8e',
      },
      {
        _id: '6073b6fd56f56ef638973f90',
        courseName: 'EARTHSC 1G03 - Earth and the Environment',
        courseCode: '1G03',
        shortName: 'EARTHSC 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f90',
      },
      {
        _id: '6073b6fd56f56ef638973f92',
        courseName: 'EARTHSC 2E03 - Earth History',
        courseCode: '2E03',
        shortName: 'EARTHSC 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f92',
      },
      {
        _id: '6073b6fd56f56ef638973f94',
        courseName:
          'EARTHSC 2FE3 - Introduction to Field Methods in Earth Sciences',
        courseCode: '2FE3',
        shortName: 'EARTHSC 2FE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f94',
      },
      {
        _id: '6073b6fd56f56ef638973f96',
        courseName: 'EARTHSC 2GG3 - Natural Disasters',
        courseCode: '2GG3',
        shortName: 'EARTHSC 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f96',
      },
      {
        _id: '6073b6fd56f56ef638973f98',
        courseName: 'EARTHSC 2K03 - Optical Crystallography and Mineralogy',
        courseCode: '2K03',
        shortName: 'EARTHSC 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f98',
      },
      {
        _id: '6073b6fd56f56ef638973f9a',
        courseName: 'EARTHSC 2T03 - Geology of Canada',
        courseCode: '2T03',
        shortName: 'EARTHSC 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f9a',
      },
      {
        _id: '6073b6fd56f56ef638973f9c',
        courseName: 'EARTHSC 3CC3 - Earthâ€™s Changing Climate',
        courseCode: '3CC3',
        shortName: 'EARTHSC 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fd56f56ef638973f9c',
      },
      {
        _id: '6073b6fe56f56ef638973f9e',
        courseName: 'EARTHSC 3E03 - Clastic Sedimentary Environments',
        courseCode: '3E03',
        shortName: 'EARTHSC 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973f9e',
      },
      {
        _id: '6073b6fe56f56ef638973fa0',
        courseName: 'EARTHSC 3FE3 - Field Camp',
        courseCode: '3FE3',
        shortName: 'EARTHSC 3FE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fa0',
      },
      {
        _id: '6073b6fe56f56ef638973fa2',
        courseName: 'EARTHSC 3K03 - Petrology',
        courseCode: '3K03',
        shortName: 'EARTHSC 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fa2',
      },
      {
        _id: '6073b6fe56f56ef638973fa4',
        courseName:
          'EARTHSC 3RD3 - Research Design and Dissemination in Earth and Environmental Sciences',
        courseCode: '3RD3',
        shortName: 'EARTHSC 3RD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fa4',
      },
      {
        _id: '6073b6fe56f56ef638973fa6',
        courseName: 'EARTHSC 3V03 - Environmental Geophysics',
        courseCode: '3V03',
        shortName: 'EARTHSC 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fa6',
      },
      {
        _id: '6073b6fe56f56ef638973fa8',
        courseName: 'EARTHSC 3W03 - Physical Hydrogeology',
        courseCode: '3W03',
        shortName: 'EARTHSC 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fa8',
      },
      {
        _id: '6073b6fe56f56ef638973faa',
        courseName: 'EARTHSC 3Z03 - Structural Geology',
        courseCode: '3Z03',
        shortName: 'EARTHSC 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973faa',
      },
      {
        _id: '6073b6fe56f56ef638973fac',
        courseName:
          'EARTHSC 4CC3 - Stable Isotopes in Earth and Environmental Systems',
        courseCode: '4CC3',
        shortName: 'EARTHSC 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fac',
      },
      {
        _id: '6073b6fe56f56ef638973fae',
        courseName: 'EARTHSC 4FF3 - Topics of Field Research',
        courseCode: '4FF3',
        shortName: 'EARTHSC 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fae',
      },
      {
        _id: '6073b6fe56f56ef638973fb0',
        courseName: 'EARTHSC 4G03 - Glacial Sediments and Environments',
        courseCode: '4G03',
        shortName: 'EARTHSC 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fb0',
      },
      {
        _id: '6073b6fe56f56ef638973fb2',
        courseName: 'EARTHSC 4J03 - Basin Analysis',
        courseCode: '4J03',
        shortName: 'EARTHSC 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6fe56f56ef638973fb2',
      },
      {
        _id: '6073b6ff56f56ef638973fb4',
        courseName: 'EARTHSC 4MT6 A/B - Senior Thesis',
        courseCode: '4MT6',
        shortName: 'EARTHSC 4MT6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fb4',
      },
      {
        _id: '6073b6ff56f56ef638973fb6',
        courseName: 'EARTHSC 4P03 - Coral Reef Environments',
        courseCode: '4P03',
        shortName: 'EARTHSC 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fb6',
      },
      {
        _id: '6073b6ff56f56ef638973fb8',
        courseName: 'EARTHSC 4T03 - Plate Tectonics and Ore Deposits',
        courseCode: '4T03',
        shortName: 'EARTHSC 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fb8',
      },
      {
        _id: '6073b6ff56f56ef638973fba',
        courseName: 'EARTHSC 4WB3 - Contaminant Hydrogeology',
        courseCode: '4WB3',
        shortName: 'EARTHSC 4WB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fba',
      },
      {
        _id: '6073b6ff56f56ef638973fbc',
        courseName:
          'ECON 1BA3 - Introductory Macroeconomics for Economics students',
        courseCode: '1BA3',
        shortName: 'ECON 1BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fbc',
      },
      {
        _id: '6073b6ff56f56ef638973fbe',
        courseName:
          'ECON 1BX3 - Introductory Microeconomics for Business Students',
        courseCode: '1BX3',
        shortName: 'ECON 1BX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fbe',
      },
      {
        _id: '6073b6ff56f56ef638973fc0',
        courseName: 'ECON 2A03 - Economics of Labour-Market Issues',
        courseCode: '2A03',
        shortName: 'ECON 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fc0',
      },
      {
        _id: '6073b6ff56f56ef638973fc2',
        courseName: 'ECON 2B03 - Analysis of Economic Data',
        courseCode: '2B03',
        shortName: 'ECON 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fc2',
      },
      {
        _id: '6073b6ff56f56ef638973fc4',
        courseName:
          'ECON 2CC3 - Health Economics and its Application to Health Policy',
        courseCode: '2CC3',
        shortName: 'ECON 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fc4',
      },
      {
        _id: '6073b6ff56f56ef638973fc6',
        courseName: 'ECON 2D03 - Economic Issues',
        courseCode: '2D03',
        shortName: 'ECON 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fc6',
      },
      {
        _id: '6073b6ff56f56ef638973fc8',
        courseName: 'ECON 2GG3 - Intermediate Microeconomics II',
        courseCode: '2GG3',
        shortName: 'ECON 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fc8',
      },
      {
        _id: '6073b6ff56f56ef638973fca',
        courseName: 'ECON 2H03 - Intermediate Macroeconomics I',
        courseCode: '2H03',
        shortName: 'ECON 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ff56f56ef638973fca',
      },
      {
        _id: '6073b70056f56ef638973fcc',
        courseName: 'ECON 2HH3 - Intermediate Macroeconomics II',
        courseCode: '2HH3',
        shortName: 'ECON 2HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fcc',
      },
      {
        _id: '6073b70056f56ef638973fce',
        courseName: 'ECON 2I03 - Financial Economics',
        courseCode: '2I03',
        shortName: 'ECON 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fce',
      },
      {
        _id: '6073b70056f56ef638973fd0',
        courseName: 'ECON 2J03 - Environmental Economics',
        courseCode: '2J03',
        shortName: 'ECON 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fd0',
      },
      {
        _id: '6073b70056f56ef638973fd2',
        courseName: 'ECON 2K03 - Economic History of Canada',
        courseCode: '2K03',
        shortName: 'ECON 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fd2',
      },
      {
        _id: '6073b70056f56ef638973fd4',
        courseName: 'ECON 2N03 - Public Policy Toward Business',
        courseCode: '2N03',
        shortName: 'ECON 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b70056f56ef638973fd4',
      },
      {
        _id: '6073b70056f56ef638973fd6',
        courseName: 'ECON 2P03 - Economics of Professional Sports',
        courseCode: '2P03',
        shortName: 'ECON 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fd6',
      },
      {
        _id: '6073b70056f56ef638973fd8',
        courseName: 'ECON 2Q03 - Economics of Bad Behaviour',
        courseCode: '2Q03',
        shortName: 'ECON 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fd8',
      },
      {
        _id: '6073b70056f56ef638973fda',
        courseName: 'ECON 2T03 - Economics of Trade Unionism and Labour',
        courseCode: '2T03',
        shortName: 'ECON 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fda',
      },
      {
        _id: '6073b70056f56ef638973fdc',
        courseName: 'ECON 2Z03 - Intermediate Microeconomics I',
        courseCode: '2Z03',
        shortName: 'ECON 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fdc',
      },
      {
        _id: '6073b70056f56ef638973fde',
        courseName: 'ECON 2ZZ3 - Intermediate Microeconomics II',
        courseCode: '2ZZ3',
        shortName: 'ECON 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fde',
      },
      {
        _id: '6073b70056f56ef638973fe0',
        courseName: 'ECON 3B03 - Public Sector Economics: Expenditures',
        courseCode: '3B03',
        shortName: 'ECON 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70056f56ef638973fe0',
      },
      {
        _id: '6073b70156f56ef638973fe2',
        courseName: 'ECON 3BE3 - Behavioural Economics',
        courseCode: '3BE3',
        shortName: 'ECON 3BE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973fe2',
      },
      {
        _id: '6073b70156f56ef638973fe4',
        courseName: 'ECON 3C03 - Public Sector Economics: Taxation',
        courseCode: '3C03',
        shortName: 'ECON 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '6073b70156f56ef638973fe4',
      },
      {
        _id: '6073b70156f56ef638973fe6',
        courseName: 'ECON 3D03 - Labour Economics',
        courseCode: '3D03',
        shortName: 'ECON 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973fe6',
      },
      {
        _id: '6073b70156f56ef638973fe8',
        courseName: 'ECON 3E03 - Applied Econometrics',
        courseCode: '3E03',
        shortName: 'ECON 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973fe8',
      },
      {
        _id: '6073b70156f56ef638973fea',
        courseName: 'ECON 3EE3 - Econometrics I',
        courseCode: '3EE3',
        shortName: 'ECON 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973fea',
      },
      {
        _id: '6073b70156f56ef638973fec',
        courseName: 'ECON 3G03 - Introduction to Advanced Economic Theory',
        courseCode: '3G03',
        shortName: 'ECON 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973fec',
      },
      {
        _id: '6073b70156f56ef638973fee',
        courseName: 'ECON 3H03 - International Monetary Economics',
        courseCode: '3H03',
        shortName: 'ECON 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973fee',
      },
      {
        _id: '6073b70156f56ef638973ff0',
        courseName: 'ECON 3HH3 - International Trade',
        courseCode: '3HH3',
        shortName: 'ECON 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973ff0',
      },
      {
        _id: '6073b70156f56ef638973ff2',
        courseName: 'ECON 3K03 - Monetary Economics',
        courseCode: '3K03',
        shortName: 'ECON 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973ff2',
      },
      {
        _id: '6073b70156f56ef638973ff4',
        courseName: 'ECON 3M03 - Introduction to Game Theory',
        courseCode: '3M03',
        shortName: 'ECON 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973ff4',
      },
      {
        _id: '6073b70156f56ef638973ff6',
        courseName: 'ECON 3Q03 - The Economics of Aging',
        courseCode: '3Q03',
        shortName: 'ECON 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973ff6',
      },
      {
        _id: '6073b70156f56ef638973ff8',
        courseName: 'ECON 3S03 - Industrial Organization',
        courseCode: '3S03',
        shortName: 'ECON 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70156f56ef638973ff8',
      },
      {
        _id: '6073b70256f56ef638973ffa',
        courseName: 'ECON 3T03 - Economic Development',
        courseCode: '3T03',
        shortName: 'ECON 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 27,
        id: '6073b70256f56ef638973ffa',
      },
      {
        _id: '6073b70256f56ef638973ffc',
        courseName: 'ECON 3W03 - Natural Resources',
        courseCode: '3W03',
        shortName: 'ECON 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638973ffc',
      },
      {
        _id: '6073b70256f56ef638973ffe',
        courseName: 'ECON 3Y03 - Selected Topics',
        courseCode: '3Y03',
        shortName: 'ECON 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638973ffe',
      },
      {
        _id: '6073b70256f56ef638974000',
        courseName: 'ECON 3Z03 - Health Economics',
        courseCode: '3Z03',
        shortName: 'ECON 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638974000',
      },
      {
        _id: '6073b70256f56ef638974002',
        courseName: 'ECON 4A03 - Honours Economic Analysis',
        courseCode: '4A03',
        shortName: 'ECON 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638974002',
      },
      {
        _id: '6073b70256f56ef638974004',
        courseName: 'ECON 4AA3 - Economic Specialist Seminar',
        courseCode: '4AA3',
        shortName: 'ECON 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638974004',
      },
      {
        _id: '6073b70256f56ef638974006',
        courseName: 'ECON 4B03 - Selected Topics',
        courseCode: '4B03',
        shortName: 'ECON 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638974006',
      },
      {
        _id: '6073b70256f56ef638974008',
        courseName: 'ECON 4F03 - Methods of Inquiry in Economics',
        courseCode: '4F03',
        shortName: 'ECON 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638974008',
      },
      {
        _id: '6073b70256f56ef63897400a',
        courseName: 'ECON 4FF3 - Research Methods in Economics',
        courseCode: '4FF3',
        shortName: 'ECON 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef63897400a',
      },
      {
        _id: '6073b70256f56ef63897400c',
        courseName: 'ECON 4G03 - Econometrics II',
        courseCode: '4G03',
        shortName: 'ECON 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef63897400c',
      },
      {
        _id: '6073b70256f56ef63897400e',
        courseName: 'ECON 4M06 A/B S - Directed Research I',
        courseCode: '4M06',
        shortName: 'ECON 4M06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef63897400e',
      },
      {
        _id: '6073b70256f56ef638974010',
        courseName: 'ECON 4N03 - Directed Research II',
        courseCode: '4N03',
        shortName: 'ECON 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70256f56ef638974010',
      },
      {
        _id: '6073b70356f56ef638974012',
        courseName: 'ECON 4T03 - Advanced Economic Theory I',
        courseCode: '4T03',
        shortName: 'ECON 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974012',
      },
      {
        _id: '6073b70356f56ef638974014',
        courseName: 'ECON 4TT3 - Advanced Economic Theory II',
        courseCode: '4TT3',
        shortName: 'ECON 4TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974014',
      },
      {
        _id: '6073b70356f56ef638974016',
        courseName: 'ELECENG 2CI5 - Introduction to Electrical Engineering',
        courseCode: '2CI5',
        shortName: 'ELECENG 2CI5',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974016',
      },
      {
        _id: '6073b70356f56ef638974018',
        courseName: 'ELECENG 2CJ4 - Circuits and Systems',
        courseCode: '2CJ4',
        shortName: 'ELECENG 2CJ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '6073b70356f56ef638974018',
      },
      {
        _id: '6073b70356f56ef63897401a',
        courseName: 'ELECENG 2EI5 - Electronic Devices and Circuits I',
        courseCode: '2EI5',
        shortName: 'ELECENG 2EI5',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef63897401a',
      },
      {
        _id: '6073b70356f56ef63897401c',
        courseName: 'ELECENG 2FH4 - Electromagnetics I',
        courseCode: '2FH4',
        shortName: 'ELECENG 2FH4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef63897401c',
      },
      {
        _id: '6073b70356f56ef63897401e',
        courseName: 'ELECENG 2FL3 - Applied Electromagnetics',
        courseCode: '2FL3',
        shortName: 'ELECENG 2FL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef63897401e',
      },
      {
        _id: '6073b70356f56ef638974020',
        courseName: 'ELECENG 3CL4 - Introduction to Control Systems',
        courseCode: '3CL4',
        shortName: 'ELECENG 3CL4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974020',
      },
      {
        _id: '6073b70356f56ef638974022',
        courseName: 'ELECENG 3EJ4 - Electronic Devices and Circuits II',
        courseCode: '3EJ4',
        shortName: 'ELECENG 3EJ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974022',
      },
      {
        _id: '6073b70356f56ef638974024',
        courseName: 'ELECENG 3EY4 - Electrical Systems Integration Project',
        courseCode: '3EY4',
        shortName: 'ELECENG 3EY4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974024',
      },
      {
        _id: '6073b70356f56ef638974026',
        courseName: 'ELECENG 3FK4 - Electromagnetics II',
        courseCode: '3FK4',
        shortName: 'ELECENG 3FK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974026',
      },
      {
        _id: '6073b70356f56ef638974028',
        courseName: 'ELECENG 3PI4 - Energy Conversion',
        courseCode: '3PI4',
        shortName: 'ELECENG 3PI4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70356f56ef638974028',
      },
      {
        _id: '6073b70456f56ef63897402a',
        courseName: 'ELECENG 3TP3 - Signal & Systems',
        courseCode: '3TP3',
        shortName: 'ELECENG 3TP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef63897402a',
      },
      {
        _id: '6073b70456f56ef63897402c',
        courseName: 'ELECENG 3TQ3 - Advanced Probability and Random Processes',
        courseCode: '3TQ3',
        shortName: 'ELECENG 3TQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef63897402c',
      },
      {
        _id: '6073b70456f56ef63897402e',
        courseName: 'ELECENG 3TR4 - Communication Systems',
        courseCode: '3TR4',
        shortName: 'ELECENG 3TR4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef63897402e',
      },
      {
        _id: '6073b70456f56ef638974030',
        courseName: 'ELECENG 4BB3 - Cellular Bioelectricity',
        courseCode: '4BB3',
        shortName: 'ELECENG 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef638974030',
      },
      {
        _id: '6073b70456f56ef638974032',
        courseName: 'ELECENG 4BC3 - Modelling of Biological Systems',
        courseCode: '4BC3',
        shortName: 'ELECENG 4BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef638974032',
      },
      {
        _id: '6073b70456f56ef638974034',
        courseName: 'ELECENG 4BD4 - Biomedical Instrumentation',
        courseCode: '4BD4',
        shortName: 'ELECENG 4BD4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef638974034',
      },
      {
        _id: '6073b70456f56ef638974036',
        courseName: 'ELECENG 4BE4 - Medical Robotics',
        courseCode: '4BE4',
        shortName: 'ELECENG 4BE4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef638974036',
      },
      {
        _id: '6073b70456f56ef638974038',
        courseName: 'ELECENG 4BF4 - Advanced Medical Imaging',
        courseCode: '4BF4',
        shortName: 'ELECENG 4BF4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef638974038',
      },
      {
        _id: '6073b70456f56ef63897403a',
        courseName: 'ELECENG 4BI6 A/B - Biomedical Design Project',
        courseCode: '4BI6',
        shortName: 'ELECENG 4BI6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef63897403a',
      },
      {
        _id: '6073b70456f56ef63897403c',
        courseName: 'ELECENG 4CL4 - Control System Design',
        courseCode: '4CL4',
        shortName: 'ELECENG 4CL4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef63897403c',
      },
      {
        _id: '6073b70456f56ef63897403e',
        courseName: 'ELECENG 4EM4 - Photonic Devices and Systems',
        courseCode: '4EM4',
        shortName: 'ELECENG 4EM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef63897403e',
      },
      {
        _id: '6073b70456f56ef638974040',
        courseName: 'ELECENG 4FJ4 - Devices and Antennas for Wireless Systems',
        courseCode: '4FJ4',
        shortName: 'ELECENG 4FJ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70456f56ef638974040',
      },
      {
        _id: '6073b70556f56ef638974042',
        courseName: 'ELECENG 4OH4 - Advanced Research Project',
        courseCode: '4OH4',
        shortName: 'ELECENG 4OH4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef638974042',
      },
      {
        _id: '6073b70556f56ef638974044',
        courseName: 'ELECENG 4OI6 A/B - Engineering Design',
        courseCode: '4OI6',
        shortName: 'ELECENG 4OI6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef638974044',
      },
      {
        _id: '6073b70556f56ef638974046',
        courseName: 'ELECENG 4OJ4 - Research Project',
        courseCode: '4OJ4',
        shortName: 'ELECENG 4OJ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef638974046',
      },
      {
        _id: '6073b70556f56ef638974048',
        courseName: 'ELECENG 4PK4 - Power Electronics',
        courseCode: '4PK4',
        shortName: 'ELECENG 4PK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef638974048',
      },
      {
        _id: '6073b70556f56ef63897404a',
        courseName: 'ELECENG 4PM4 - Electrical Power Systems',
        courseCode: '4PM4',
        shortName: 'ELECENG 4PM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef63897404a',
      },
      {
        _id: '6073b70556f56ef63897404c',
        courseName: 'ELECENG 4PN4 - Electric Motor Drives',
        courseCode: '4PN4',
        shortName: 'ELECENG 4PN4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef63897404c',
      },
      {
        _id: '6073b70556f56ef63897404e',
        courseName: 'ELECENG 4PP4 - Smart and Micro Grids',
        courseCode: '4PP4',
        shortName: 'ELECENG 4PP4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef63897404e',
      },
      {
        _id: '6073b70556f56ef638974050',
        courseName: 'ELECENG 4TK4 - Digital Communications Systems',
        courseCode: '4TK4',
        shortName: 'ELECENG 4TK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef638974050',
      },
      {
        _id: '6073b70556f56ef638974052',
        courseName:
          'ELECENG 4TM4 - Digital Communications II\tâ€¢  ENRTECH 3EP3 - Power Systems and Electrical Machines',
        courseCode: '4TM4',
        shortName: 'ELECENG 4TM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70556f56ef638974052',
      },
      {
        _id: '6073b70656f56ef638974054',
        courseName: 'ENRTECH 3IE3 - Industrial Electronics',
        courseCode: '3IE3',
        shortName: 'ENRTECH 3IE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974054',
      },
      {
        _id: '6073b70656f56ef638974056',
        courseName:
          'ENRTECH 3IN3 - Industrial Networks and Communication Systems',
        courseCode: '3IN3',
        shortName: 'ENRTECH 3IN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974056',
      },
      {
        _id: '6073b70656f56ef638974058',
        courseName: 'ENRTECH 3MI3 - Measurements and Instrumentation',
        courseCode: '3MI3',
        shortName: 'ENRTECH 3MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974058',
      },
      {
        _id: '6073b70656f56ef63897405a',
        courseName: 'ENRTECH 3PD3 - Power Distribution I',
        courseCode: '3PD3',
        shortName: 'ENRTECH 3PD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef63897405a',
      },
      {
        _id: '6073b70656f56ef63897405c',
        courseName: 'ENRTECH 4EM3 - Transmission Lines and Electromagnetics',
        courseCode: '4EM3',
        shortName: 'ENRTECH 4EM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef63897405c',
      },
      {
        _id: '6073b70656f56ef63897405e',
        courseName: 'ENRTECH 4PD3 - Power System Analysis and Control',
        courseCode: '4PD3',
        shortName: 'ENRTECH 4PD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef63897405e',
      },
      {
        _id: '6073b70656f56ef638974060',
        courseName: 'ENRTECH 4PM3 - Power Protection and Maintenance I',
        courseCode: '4PM3',
        shortName: 'ENRTECH 4PM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974060',
      },
      {
        _id: '6073b70656f56ef638974062',
        courseName: 'ENRTECH 4PP3 - Power Protection and Maintenance II',
        courseCode: '4PP3',
        shortName: 'ENRTECH 4PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974062',
      },
      {
        _id: '6073b70656f56ef638974064',
        courseName: 'ENRTECH 4PQ3 - Power Quality',
        courseCode: '4PQ3',
        shortName: 'ENRTECH 4PQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974064',
      },
      {
        _id: '6073b70656f56ef638974066',
        courseName:
          'ENRTECH 4RE3 - Fuel Cell, Geothermal and Biomass Power Generation',
        courseCode: '4RE3',
        shortName: 'ENRTECH 4RE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974066',
      },
      {
        _id: '6073b70656f56ef638974068',
        courseName:
          'ENRTECH 4RT3 - Renewable Power Generation from Wind, Solar and Hydro',
        courseCode: '4RT3',
        shortName: 'ENRTECH 4RT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70656f56ef638974068',
      },
      {
        _id: '6073b70756f56ef63897406a',
        courseName:
          'ENGINEER 1EE0 - Introduction to the Engineering Co-op Program',
        courseCode: '1EE0',
        shortName: 'ENGINEER 1EE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef63897406a',
      },
      {
        _id: '6073b70756f56ef63897406c',
        courseName:
          'ENGINEER 1P13 A/B - Integrated Cornerstone Design Projects in Engineering',
        courseCode: '1P13',
        shortName: 'ENGINEER 1P13 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '6073b70756f56ef63897406c',
      },
      {
        _id: '6073b70756f56ef63897406e',
        courseName: 'ENGINEER 2B03 - Engineering Economics',
        courseCode: '2B03',
        shortName: 'ENGINEER 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef63897406e',
      },
      {
        _id: '6073b70756f56ef638974070',
        courseName: 'ENGINEER 2EC0 - Engineering Work Term',
        courseCode: '2EC0',
        shortName: 'ENGINEER 2EC0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef638974070',
      },
      {
        _id: '6073b70756f56ef638974072',
        courseName: 'ENGINEER 2H03 - Thermodynamics',
        courseCode: '2H03',
        shortName: 'ENGINEER 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef638974072',
      },
      {
        _id: '6073b70756f56ef638974074',
        courseName: 'ENGINEER 2MM3 - Electrical Circuits and Power',
        courseCode: '2MM3',
        shortName: 'ENGINEER 2MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef638974074',
      },
      {
        _id: '6073b70756f56ef638974076',
        courseName: 'ENGINEER 2P04 - Engineering Mechanics',
        courseCode: '2P04',
        shortName: 'ENGINEER 2P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 11,
        id: '6073b70756f56ef638974076',
      },
      {
        _id: '6073b70756f56ef638974078',
        courseName:
          'ENGINEER 3CX3 A/B - Experiential Learning in Complementary Studies',
        courseCode: '3CX3',
        shortName: 'ENGINEER 3CX3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef638974078',
      },
      {
        _id: '6073b70756f56ef63897407a',
        courseName:
          'ENGINEER 3IC0 - Full-Time Internship for International Students',
        courseCode: '3IC0',
        shortName: 'ENGINEER 3IC0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef63897407a',
      },
      {
        _id: '6073b70756f56ef63897407c',
        courseName: 'ENGINEER 3MF3 - Materials Fabrication',
        courseCode: '3MF3',
        shortName: 'ENGINEER 3MF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef63897407c',
      },
      {
        _id: '6073b70756f56ef63897407e',
        courseName: 'ENGINEER 3N03 - Electronics and Instrumentation',
        courseCode: '3N03',
        shortName: 'ENGINEER 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70756f56ef63897407e',
      },
      {
        _id: '6073b70856f56ef638974080',
        courseName: 'ENGINEER 4A03 - Ethics, Equity and Law in Engineering',
        courseCode: '4A03',
        shortName: 'ENGINEER 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974080',
      },
      {
        _id: '6073b70856f56ef638974082',
        courseName: 'ENGINEER 4EX3 A/B - Experiential Engineering Design',
        courseCode: '4EX3',
        shortName: 'ENGINEER 4EX3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974082',
      },
      {
        _id: '6073b70856f56ef638974084',
        courseName: 'ENGINEER 4F00 A/B - Mech Eng Work Term Report',
        courseCode: '4F00',
        shortName: 'ENGINEER 4F00 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974084',
      },
      {
        _id: '6073b70856f56ef638974086',
        courseName: 'ENGINEER 4IW3 - Inclusion in the Engineering Workplace',
        courseCode: '4IW3',
        shortName: 'ENGINEER 4IW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974086',
      },
      {
        _id: '6073b70856f56ef638974088',
        courseName:
          'ENGINEER 4K01 A/B S - Engineering Report for Exchange Students',
        courseCode: '4K01',
        shortName: 'ENGINEER 4K01 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974088',
      },
      {
        _id: '6073b70856f56ef63897408a',
        courseName:
          'ENGINEER 4L00 A/B - Introduction to the Overseas Workplace',
        courseCode: '4L00',
        shortName: 'ENGINEER 4L00 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef63897408a',
      },
      {
        _id: '6073b70856f56ef63897408c',
        courseName:
          'ENGINEER 4T04 - Materials Selection in Design and Manufacturing',
        courseCode: '4T04',
        shortName: 'ENGINEER 4T04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef63897408c',
      },
      {
        _id: '6073b70856f56ef63897408e',
        courseName:
          'ENGINEER 4V04 - Physico-Chemical Processes in Water and Wastewater',
        courseCode: '4V04',
        shortName: 'ENGINEER 4V04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef63897408e',
      },
      {
        _id: '6073b70856f56ef638974090',
        courseName: 'ENGSOCTY 2X03 - Inquiry in an Engineering Context I',
        courseCode: '2X03',
        shortName: 'ENGSOCTY 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974090',
      },
      {
        _id: '6073b70856f56ef638974092',
        courseName: 'ENGSOCTY 2Y03 - Case Studies in History and Technology',
        courseCode: '2Y03',
        shortName: 'ENGSOCTY 2Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974092',
      },
      {
        _id: '6073b70856f56ef638974094',
        courseName: 'ENGSOCTY 3X03 - Inquiry in an Engineering Context II',
        courseCode: '3X03',
        shortName: 'ENGSOCTY 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974094',
      },
      {
        _id: '6073b70856f56ef638974096',
        courseName: 'ENGSOCTY 3Y03 - Technology and Society',
        courseCode: '3Y03',
        shortName: 'ENGSOCTY 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70856f56ef638974096',
      },
      {
        _id: '6073b70956f56ef638974098',
        courseName:
          'ENGSOCTY 3Z03 - Preventive Engineering: Environmental Perspectives',
        courseCode: '3Z03',
        shortName: 'ENGSOCTY 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef638974098',
      },
      {
        _id: '6073b70956f56ef63897409a',
        courseName: 'ENGSOCTY 4X03 A/B - Inquiry in an Engineering Context III',
        courseCode: '4X03',
        shortName: 'ENGSOCTY 4X03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef63897409a',
      },
      {
        _id: '6073b70956f56ef63897409c',
        courseName: 'ENGSOCTY 4Y03 - Society Capstone Design',
        courseCode: '4Y03',
        shortName: 'ENGSOCTY 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef63897409c',
      },
      {
        _id: '6073b70956f56ef63897409e',
        courseName: 'ENGLISH 1CS3 - Studying Culture: A Critical Introduction',
        courseCode: '1CS3',
        shortName: 'ENGLISH 1CS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef63897409e',
      },
      {
        _id: '6073b70956f56ef6389740a0',
        courseName: 'ENGLISH 1F03 - The Written World',
        courseCode: '1F03',
        shortName: 'ENGLISH 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740a0',
      },
      {
        _id: '6073b70956f56ef6389740a2',
        courseName: 'ENGLISH 1G03 - Making and Unmaking Literary Traditions',
        courseCode: '1G03',
        shortName: 'ENGLISH 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740a2',
      },
      {
        _id: '6073b70956f56ef6389740a4',
        courseName: 'ENGLISH 1H03 - Words in Place',
        courseCode: '1H03',
        shortName: 'ENGLISH 1H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740a4',
      },
      {
        _id: '6073b70956f56ef6389740a6',
        courseName: 'ENGLISH 2AA3 - American Literature Before 1900',
        courseCode: '2AA3',
        shortName: 'ENGLISH 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740a6',
      },
      {
        _id: '6073b70956f56ef6389740a8',
        courseName:
          'ENGLISH 2BB3 - Topics in 20th and 21st-century American Literature and Culture',
        courseCode: '2BB3',
        shortName: 'ENGLISH 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740a8',
      },
      {
        _id: '6073b70956f56ef6389740aa',
        courseName:
          'ENGLISH 2BL3 - Twentieth- and Twenty-First Century British Literature and Film',
        courseCode: '2BL3',
        shortName: 'ENGLISH 2BL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740aa',
      },
      {
        _id: '6073b70956f56ef6389740ac',
        courseName: 'ENGLISH 2C03 - Contemporary Canadian Fiction',
        courseCode: '2C03',
        shortName: 'ENGLISH 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70956f56ef6389740ac',
      },
      {
        _id: '6073b70a56f56ef6389740ae',
        courseName: 'ENGLISH 2CC3 - Settler Colonialism and Writing in Canada',
        courseCode: '2CC3',
        shortName: 'ENGLISH 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740ae',
      },
      {
        _id: '6073b70a56f56ef6389740b0',
        courseName:
          'ENGLISH 2CL3 - Canadian Literature of Dissent and Social Justice',
        courseCode: '2CL3',
        shortName: 'ENGLISH 2CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740b0',
      },
      {
        _id: '6073b70a56f56ef6389740b2',
        courseName:
          'ENGLISH 2CR3 - Shakespeare: Comedies, Problem Plays, and Romances',
        courseCode: '2CR3',
        shortName: 'ENGLISH 2CR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740b2',
      },
      {
        _id: '6073b70a56f56ef6389740b4',
        courseName: 'ENGLISH 2D03 - Creative Writing Inquiry',
        courseCode: '2D03',
        shortName: 'ENGLISH 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740b4',
      },
      {
        _id: '6073b70a56f56ef6389740b6',
        courseName: 'ENGLISH 2HT3 - Shakespeare: Histories and Tragedies',
        courseCode: '2HT3',
        shortName: 'ENGLISH 2HT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740b6',
      },
      {
        _id: '6073b70a56f56ef6389740b8',
        courseName: 'ENGLISH 2KA3 - Indigenous Futurisms and Wonderworks',
        courseCode: '2KA3',
        shortName: 'ENGLISH 2KA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740b8',
      },
      {
        _id: '6073b70a56f56ef6389740ba',
        courseName: 'ENGLISH 2KK3 - Studies in Women Writers',
        courseCode: '2KK3',
        shortName: 'ENGLISH 2KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740ba',
      },
      {
        _id: '6073b70a56f56ef6389740bc',
        courseName: 'ENGLISH 2M03 - Concepts of Culture',
        courseCode: '2M03',
        shortName: 'ENGLISH 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740bc',
      },
      {
        _id: '6073b70a56f56ef6389740be',
        courseName: 'ENGLISH 2NH3 - Narratives of Health',
        courseCode: '2NH3',
        shortName: 'ENGLISH 2NH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740be',
      },
      {
        _id: '6073b70a56f56ef6389740c0',
        courseName: 'ENGLISH 2P03 - Modernity, Postmodernity,',
        courseCode: '2P03',
        shortName: 'ENGLISH 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740c0',
      },
      {
        _id: '6073b70a56f56ef6389740c2',
        courseName: 'ENGLISH 2RW6 A/B - Reading and Writing Criticism',
        courseCode: '2RW6',
        shortName: 'ENGLISH 2RW6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70a56f56ef6389740c2',
      },
      {
        _id: '6073b70b56f56ef6389740c4',
        courseName: 'ENGLISH 2S03 - Spectacular Bodies',
        courseCode: '2S03',
        shortName: 'ENGLISH 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740c4',
      },
      {
        _id: '6073b70b56f56ef6389740c6',
        courseName:
          'ENGLISH 2Z03 - Nature, Literature, Culture: Introduction to the Environmental Humanities',
        courseCode: '2Z03',
        shortName: 'ENGLISH 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740c6',
      },
      {
        _id: '6073b70b56f56ef6389740c8',
        courseName: 'ENGLISH 3A03 - Critical Race Studies',
        courseCode: '3A03',
        shortName: 'ENGLISH 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740c8',
      },
      {
        _id: '6073b70b56f56ef6389740ca',
        courseName: 'ENGLISH 3AA3 - Theories of Gender and Sexuality',
        courseCode: '3AA3',
        shortName: 'ENGLISH 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740ca',
      },
      {
        _id: '6073b70b56f56ef6389740cc',
        courseName: 'ENGLISH 3CC3 - Reading Film',
        courseCode: '3CC3',
        shortName: 'ENGLISH 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740cc',
      },
      {
        _id: '6073b70b56f56ef6389740ce',
        courseName:
          'ENGLISH 3CL3 - Topics in Twentieth- and Twenty-First-Century British Literature and Cultural Studies',
        courseCode: '3CL3',
        shortName: 'ENGLISH 3CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740ce',
      },
      {
        _id: '6073b70b56f56ef6389740d0',
        courseName: 'ENGLISH 3CW3 - Creating Writing in/for/with Communities',
        courseCode: '3CW3',
        shortName: 'ENGLISH 3CW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740d0',
      },
      {
        _id: '6073b70b56f56ef6389740d2',
        courseName: 'ENGLISH 3D03 - Science Fiction',
        courseCode: '3D03',
        shortName: 'ENGLISH 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740d2',
      },
      {
        _id: '6073b70b56f56ef6389740d4',
        courseName:
          'ENGLISH 3EC3 - Eighteenth-Century Literature and Culture: Enlightenment and its Shadows',
        courseCode: '3EC3',
        shortName: 'ENGLISH 3EC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740d4',
      },
      {
        _id: '6073b70b56f56ef6389740d6',
        courseName: 'ENGLISH 3EE3 - African American Literature',
        courseCode: '3EE3',
        shortName: 'ENGLISH 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740d6',
      },
      {
        _id: '6073b70b56f56ef6389740d8',
        courseName: 'ENGLISH 3F03 - The Fairy Tale',
        courseCode: '3F03',
        shortName: 'ENGLISH 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740d8',
      },
      {
        _id: '6073b70b56f56ef6389740da',
        courseName: 'ENGLISH 3GF3 - Studies in Popular Genres',
        courseCode: '3GF3',
        shortName: 'ENGLISH 3GF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70b56f56ef6389740da',
      },
      {
        _id: '6073b70c56f56ef6389740dc',
        courseName: 'ENGLISH 3GG3 - Theories of Decolonization and Resistance',
        courseCode: '3GG3',
        shortName: 'ENGLISH 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740dc',
      },
      {
        _id: '6073b70c56f56ef6389740de',
        courseName: 'ENGLISH 3H03 - Jane Austen',
        courseCode: '3H03',
        shortName: 'ENGLISH 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740de',
      },
      {
        _id: '6073b70c56f56ef6389740e0',
        courseName: 'ENGLISH 3L03 - Old English Literature in Translation',
        courseCode: '3L03',
        shortName: 'ENGLISH 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740e0',
      },
      {
        _id: '6073b70c56f56ef6389740e2',
        courseName:
          'ENGLISH 3NN3 - Medieval Literature and Culture: An Overview',
        courseCode: '3NN3',
        shortName: 'ENGLISH 3NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740e2',
      },
      {
        _id: '6073b70c56f56ef6389740e4',
        courseName: 'ENGLISH 3PT3 - Perspective and Time in Fiction',
        courseCode: '3PT3',
        shortName: 'ENGLISH 3PT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740e4',
      },
      {
        _id: '6073b70c56f56ef6389740e6',
        courseName: 'ENGLISH 3Q03 - The History of Critical Theory',
        courseCode: '3Q03',
        shortName: 'ENGLISH 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740e6',
      },
      {
        _id: '6073b70c56f56ef6389740e8',
        courseName: 'ENGLISH 3QQ3 - Contemporary Critical Theory',
        courseCode: '3QQ3',
        shortName: 'ENGLISH 3QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740e8',
      },
      {
        _id: '6073b70c56f56ef6389740ea',
        courseName:
          'ENGLISH 3RW3 - Experiential Practicum: Reading and Writing in the Community',
        courseCode: '3RW3',
        shortName: 'ENGLISH 3RW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740ea',
      },
      {
        _id: '6073b70c56f56ef6389740ec',
        courseName: 'ENGLISH 3SS3 - Topics in Medieval Literature and Culture',
        courseCode: '3SS3',
        shortName: 'ENGLISH 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740ec',
      },
      {
        _id: '6073b70c56f56ef6389740ee',
        courseName: 'ENGLISH 3TT3 - The Age of Elizabeth I',
        courseCode: '3TT3',
        shortName: 'ENGLISH 3TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740ee',
      },
      {
        _id: '6073b70c56f56ef6389740f0',
        courseName:
          'ENGLISH 3UU3 - Renaissance and Revolution: Studies in 17th-Century Literature',
        courseCode: '3UU3',
        shortName: 'ENGLISH 3UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70c56f56ef6389740f0',
      },
      {
        _id: '6073b70d56f56ef6389740f2',
        courseName: 'ENGLISH 3V03 - Global Anglophone Literature and Film',
        courseCode: '3V03',
        shortName: 'ENGLISH 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740f2',
      },
      {
        _id: '6073b70d56f56ef6389740f4',
        courseName:
          'ENGLISH 3VC3 - â€˜We Other Victoriansâ€™: Victorian Literature and Culture and Its Afterlives',
        courseCode: '3VC3',
        shortName: 'ENGLISH 3VC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740f4',
      },
      {
        _id: '6073b70d56f56ef6389740f6',
        courseName: 'ENGLISH 3W03 - Contemporary Native Literature in Canada',
        courseCode: '3W03',
        shortName: 'ENGLISH 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740f6',
      },
      {
        _id: '6073b70d56f56ef6389740f8',
        courseName:
          'ENGLISH 3WE3 - British Romantic Literature and Culture: Revolution, War, Empire',
        courseCode: '3WE3',
        shortName: 'ENGLISH 3WE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740f8',
      },
      {
        _id: '6073b70d56f56ef6389740fa',
        courseName:
          'ENGLISH 3WP3 - The Writerâ€™s Process: Short Stories from Beginning to End',
        courseCode: '3WP3',
        shortName: 'ENGLISH 3WP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740fa',
      },
      {
        _id: '6073b70d56f56ef6389740fc',
        courseName:
          'ENGLISH 3X03 - Contemporary Native Literature in the United States',
        courseCode: '3X03',
        shortName: 'ENGLISH 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740fc',
      },
      {
        _id: '6073b70d56f56ef6389740fe',
        courseName: 'ENGLISH 3Y03 - Childrenâ€™s Literature',
        courseCode: '3Y03',
        shortName: 'ENGLISH 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef6389740fe',
      },
      {
        _id: '6073b70d56f56ef638974100',
        courseName: 'ENGLISH 4AN3 - Nineteenth-Century Adaptations',
        courseCode: '4AN3',
        shortName: 'ENGLISH 4AN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef638974100',
      },
      {
        _id: '6073b70d56f56ef638974102',
        courseName:
          'ENGLISH 4AR3 - Rhetoric, Culture, Catastrophe: AIDS and its Representations',
        courseCode: '4AR3',
        shortName: 'ENGLISH 4AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef638974102',
      },
      {
        _id: '6073b70d56f56ef638974104',
        courseName: 'ENGLISH 4AW3 - Asian American Writing',
        courseCode: '4AW3',
        shortName: 'ENGLISH 4AW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef638974104',
      },
      {
        _id: '6073b70d56f56ef638974106',
        courseName:
          'ENGLISH 4CB3 - Reading the Bestseller: Contemporary British Fiction',
        courseCode: '4CB3',
        shortName: 'ENGLISH 4CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef638974106',
      },
      {
        _id: '6073b70d56f56ef638974108',
        courseName: 'ENGLISH 4CF3 - Contemporary Fiction',
        courseCode: '4CF3',
        shortName: 'ENGLISH 4CF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef638974108',
      },
      {
        _id: '6073b70d56f56ef63897410a',
        courseName: 'ENGLISH 4CS3 - Canadian Short Stories',
        courseCode: '4CS3',
        shortName: 'ENGLISH 4CS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70d56f56ef63897410a',
      },
      {
        _id: '6073b70e56f56ef63897410c',
        courseName: 'ENGLISH 4DD3 - Canadian Documentary',
        courseCode: '4DD3',
        shortName: 'ENGLISH 4DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef63897410c',
      },
      {
        _id: '6073b70e56f56ef63897410e',
        courseName: 'ENGLISH 4DL3 - Digital Lives',
        courseCode: '4DL3',
        shortName: 'ENGLISH 4DL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef63897410e',
      },
      {
        _id: '6073b70e56f56ef638974110',
        courseName: 'ENGLISH 4E03 - Literature, Culture and the Anthropocene',
        courseCode: '4E03',
        shortName: 'ENGLISH 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef638974110',
      },
      {
        _id: '6073b70e56f56ef638974112',
        courseName: 'ENGLISH 4FW3 - Forms of Creative Writing',
        courseCode: '4FW3',
        shortName: 'ENGLISH 4FW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef638974112',
      },
      {
        _id: '6073b70e56f56ef638974114',
        courseName: 'ENGLISH 4GN3 - Graphic Narrative in Canada',
        courseCode: '4GN3',
        shortName: 'ENGLISH 4GN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef638974114',
      },
      {
        _id: '6073b70e56f56ef638974116',
        courseName: 'ENGLISH 4HL3 - Canadian Holocaust Novels',
        courseCode: '4HL3',
        shortName: 'ENGLISH 4HL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef638974116',
      },
      {
        _id: '6073b70e56f56ef638974118',
        courseName:
          'ENGLISH 4IP3 - Longing and Belonging: Narratives of Israel/Palestine',
        courseCode: '4IP3',
        shortName: 'ENGLISH 4IP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef638974118',
      },
      {
        _id: '6073b70e56f56ef63897411a',
        courseName:
          'ENGLISH 4IW3 - Twenty-First Century Indigenous Writing and Film',
        courseCode: '4IW3',
        shortName: 'ENGLISH 4IW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef63897411a',
      },
      {
        _id: '6073b70e56f56ef63897411c',
        courseName: 'ENGLISH 4KK3 - Kafka after Kafka',
        courseCode: '4KK3',
        shortName: 'ENGLISH 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef63897411c',
      },
      {
        _id: '6073b70e56f56ef63897411e',
        courseName:
          'ENGLISH 4QA3 - Queerness in the Archives: Lesbian and Gay Writing, Art and Activism in Canada, 1969-1989',
        courseCode: '4QA3',
        shortName: 'ENGLISH 4QA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef63897411e',
      },
      {
        _id: '6073b70e56f56ef638974120',
        courseName: 'ENGLISH 4RD3 - Renaissance Drama, Excluding Shakespeare',
        courseCode: '4RD3',
        shortName: 'ENGLISH 4RD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70e56f56ef638974120',
      },
      {
        _id: '6073b70f56f56ef638974122',
        courseName:
          'ENGLISH 4RI3 - Colonialism and Resistance in Representations of Indigenous Womanhood',
        courseCode: '4RI3',
        shortName: 'ENGLISH 4RI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974122',
      },
      {
        _id: '6073b70f56f56ef638974124',
        courseName:
          'ENGLISH 4RL3 - Playing, Winning, Losing: Strategies of Power in Renaissance Literature',
        courseCode: '4RL3',
        shortName: 'ENGLISH 4RL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974124',
      },
      {
        _id: '6073b70f56f56ef638974126',
        courseName:
          'ENGLISH 4RS3 - Reading, Spirituality and Cultural Politics',
        courseCode: '4RS3',
        shortName: 'ENGLISH 4RS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974126',
      },
      {
        _id: '6073b70f56f56ef638974128',
        courseName: 'ENGLISH 4SD3 - Sentenced to Death',
        courseCode: '4SD3',
        shortName: 'ENGLISH 4SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974128',
      },
      {
        _id: '6073b70f56f56ef63897412a',
        courseName: 'ENGLISH 4SF3 - Science Fiction Tomorrow or the Day After',
        courseCode: '4SF3',
        shortName: 'ENGLISH 4SF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef63897412a',
      },
      {
        _id: '6073b70f56f56ef63897412c',
        courseName: 'ENGLISH 4ST3 - Even Stranger Things: The Early Gothic',
        courseCode: '4ST3',
        shortName: 'ENGLISH 4ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef63897412c',
      },
      {
        _id: '6073b70f56f56ef63897412e',
        courseName: 'ENGLISH 4UT3 - Utopian Literature',
        courseCode: '4UT3',
        shortName: 'ENGLISH 4UT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef63897412e',
      },
      {
        _id: '6073b70f56f56ef638974130',
        courseName:
          'ENGLISH 4VL3 - Imagining the Past: Violence, Literature, and the Archive',
        courseCode: '4VL3',
        shortName: 'ENGLISH 4VL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974130',
      },
      {
        _id: '6073b70f56f56ef638974132',
        courseName: 'ENGLISH 4WL3 - Globalization and Postcolonial Fiction',
        courseCode: '4WL3',
        shortName: 'ENGLISH 4WL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974132',
      },
      {
        _id: '6073b70f56f56ef638974134',
        courseName: 'ENGLISH 4X03 - Honours Essay',
        courseCode: '4X03',
        shortName: 'ENGLISH 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974134',
      },
      {
        _id: '6073b70f56f56ef638974136',
        courseName: 'ENGLISH 4Y03 - Experiential Practicum I',
        courseCode: '4Y03',
        shortName: 'ENGLISH 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974136',
      },
      {
        _id: '6073b70f56f56ef638974138',
        courseName: 'ENGLISH 4Y06 A/B S - Research Practicum',
        courseCode: '4Y06',
        shortName: 'ENGLISH 4Y06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef638974138',
      },
      {
        _id: '6073b70f56f56ef63897413a',
        courseName: 'ENGLISH 4YY3 - Experiential Practicum II',
        courseCode: '4YY3',
        shortName: 'ENGLISH 4YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b70f56f56ef63897413a',
      },
      {
        _id: '6073b71056f56ef63897413c',
        courseName: 'ENGNMGT 2AA3 - Communication Skills',
        courseCode: '2AA3',
        shortName: 'ENGNMGT 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef63897413c',
      },
      {
        _id: '6073b71056f56ef63897413e',
        courseName:
          'ENGNMGT 4A03 - Innovation Driven Project Development and Management',
        courseCode: '4A03',
        shortName: 'ENGNMGT 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef63897413e',
      },
      {
        _id: '6073b71056f56ef638974140',
        courseName: 'ENGNMGT 5B03 - Engineering and Management Projects',
        courseCode: '5B03',
        shortName: 'ENGNMGT 5B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974140',
      },
      {
        _id: '6073b71056f56ef638974142',
        courseName: 'ENGPHYS 2A04 - Electricity and Magnetism',
        courseCode: '2A04',
        shortName: 'ENGPHYS 2A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974142',
      },
      {
        _id: '6073b71056f56ef638974144',
        courseName: 'ENGPHYS 2CM4 - Computational Multiphysics',
        courseCode: '2CM4',
        shortName: 'ENGPHYS 2CM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974144',
      },
      {
        _id: '6073b71056f56ef638974146',
        courseName: 'ENGPHYS 2E04 - Analog and Digital Circuits',
        courseCode: '2E04',
        shortName: 'ENGPHYS 2E04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974146',
      },
      {
        _id: '6073b71056f56ef638974148',
        courseName: 'ENGPHYS 2H04 - Statistical Thermodynamics',
        courseCode: '2H04',
        shortName: 'ENGPHYS 2H04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974148',
      },
      {
        _id: '6073b71056f56ef63897414a',
        courseName: 'ENGPHYS 2NE3 - Thermal Systems Design',
        courseCode: '2NE3',
        shortName: 'ENGPHYS 2NE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef63897414a',
      },
      {
        _id: '6073b71056f56ef63897414c',
        courseName: 'ENGPHYS 2P04 - Computational Mechanics',
        courseCode: '2P04',
        shortName: 'ENGPHYS 2P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef63897414c',
      },
      {
        _id: '6073b71056f56ef63897414e',
        courseName: 'ENGPHYS 2QM3 - Introduction to Quantum Mechanics',
        courseCode: '2QM3',
        shortName: 'ENGPHYS 2QM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef63897414e',
      },
      {
        _id: '6073b71056f56ef638974150',
        courseName:
          'ENGPHYS 3BA4 - Electronics I: Circuits with Non-Linear and Active Components',
        courseCode: '3BA4',
        shortName: 'ENGPHYS 3BA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974150',
      },
      {
        _id: '6073b71056f56ef638974152',
        courseName:
          'ENGPHYS 3BB4 - Electronics II: Embedding and Programming a Micro-Controller',
        courseCode: '3BB4',
        shortName: 'ENGPHYS 3BB4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71056f56ef638974152',
      },
      {
        _id: '6073b71156f56ef638974154',
        courseName: 'ENGPHYS 3D03 - Principles of Nuclear Engineering',
        courseCode: '3D03',
        shortName: 'ENGPHYS 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974154',
      },
      {
        _id: '6073b71156f56ef638974156',
        courseName: 'ENGPHYS 3E04 - Fundamentals of Physical Optics',
        courseCode: '3E04',
        shortName: 'ENGPHYS 3E04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974156',
      },
      {
        _id: '6073b71156f56ef638974158',
        courseName:
          'ENGPHYS 3EC4 - Professional Communication and Project Management',
        courseCode: '3EC4',
        shortName: 'ENGPHYS 3EC4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974158',
      },
      {
        _id: '6073b71156f56ef63897415a',
        courseName: 'ENGPHYS 3ES3 - Introduction to Energy Systems',
        courseCode: '3ES3',
        shortName: 'ENGPHYS 3ES3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef63897415a',
      },
      {
        _id: '6073b71156f56ef63897415c',
        courseName:
          'ENGPHYS 3F03 - Principles of Solid-state Materials and Devices',
        courseCode: '3F03',
        shortName: 'ENGPHYS 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef63897415c',
      },
      {
        _id: '6073b71156f56ef63897415e',
        courseName:
          'ENGPHYS 3H04 A/B S - Research Project in Engineering Physics',
        courseCode: '3H04',
        shortName: 'ENGPHYS 3H04 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef63897415e',
      },
      {
        _id: '6073b71156f56ef638974160',
        courseName:
          'ENGPHYS 3L04 - Engineering Metrology: Fundamentals and Applications',
        courseCode: '3L04',
        shortName: 'ENGPHYS 3L04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974160',
      },
      {
        _id: '6073b71156f56ef638974162',
        courseName: 'ENGPHYS 3NM4 - Numerical Methods for Engineering',
        courseCode: '3NM4',
        shortName: 'ENGPHYS 3NM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974162',
      },
      {
        _id: '6073b71156f56ef638974164',
        courseName:
          'ENGPHYS 3O04 - Introduction to Fluid Mechanics and Heat Transfer',
        courseCode: '3O04',
        shortName: 'ENGPHYS 3O04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974164',
      },
      {
        _id: '6073b71156f56ef638974166',
        courseName: 'ENGPHYS 3PD3 - Photonic Devices',
        courseCode: '3PD3',
        shortName: 'ENGPHYS 3PD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974166',
      },
      {
        _id: '6073b71156f56ef638974168',
        courseName: 'ENGPHYS 3PN4 - Semiconductor Junction Devices',
        courseCode: '3PN4',
        shortName: 'ENGPHYS 3PN4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef638974168',
      },
      {
        _id: '6073b71156f56ef63897416a',
        courseName: 'ENGPHYS 3SM3 - Statistical Mechanics',
        courseCode: '3SM3',
        shortName: 'ENGPHYS 3SM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71156f56ef63897416a',
      },
      {
        _id: '6073b71256f56ef63897416c',
        courseName: 'ENGPHYS 3W04 - Signals and Systems for Engineering',
        courseCode: '3W04',
        shortName: 'ENGPHYS 3W04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef63897416c',
      },
      {
        _id: '6073b71256f56ef63897416e',
        courseName:
          'ENGPHYS 4A06 A/B - Engineering Physics Design and Synthesis Project',
        courseCode: '4A06',
        shortName: 'ENGPHYS 4A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef63897416e',
      },
      {
        _id: '6073b71256f56ef638974170',
        courseName: 'ENGPHYS 4D03 - Nuclear Reactor Physics',
        courseCode: '4D03',
        shortName: 'ENGPHYS 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974170',
      },
      {
        _id: '6073b71256f56ef638974172',
        courseName:
          'ENGPHYS 4H04 A/B S - Research Project in Engineering Physics',
        courseCode: '4H04',
        shortName: 'ENGPHYS 4H04 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974172',
      },
      {
        _id: '6073b71256f56ef638974174',
        courseName: 'ENGPHYS 4I03 - Introduction to Biophotonics',
        courseCode: '4I03',
        shortName: 'ENGPHYS 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974174',
      },
      {
        _id: '6073b71256f56ef638974176',
        courseName: 'ENGPHYS 4MD3 - Nanoscale Semiconductor Devices',
        courseCode: '4MD3',
        shortName: 'ENGPHYS 4MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974176',
      },
      {
        _id: '6073b71256f56ef638974178',
        courseName: 'ENGPHYS 4NE3 - Advanced Nuclear Engineering',
        courseCode: '4NE3',
        shortName: 'ENGPHYS 4NE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974178',
      },
      {
        _id: '6073b71256f56ef63897417a',
        courseName: 'ENGPHYS 4P03 - Nuclear Power Plant Systems and Operation',
        courseCode: '4P03',
        shortName: 'ENGPHYS 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef63897417a',
      },
      {
        _id: '6073b71256f56ef63897417c',
        courseName: 'ENGPHYS 4PP3 - Plasma Physics Applications',
        courseCode: '4PP3',
        shortName: 'ENGPHYS 4PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef63897417c',
      },
      {
        _id: '6073b71256f56ef63897417e',
        courseName: 'ENGPHYS 4QC3 - Introduction to Quantum Computing',
        courseCode: '4QC3',
        shortName: 'ENGPHYS 4QC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef63897417e',
      },
      {
        _id: '6073b71256f56ef638974180',
        courseName: 'ENGPHYS 4S03 - Lasers and Electro-Optics',
        courseCode: '4S03',
        shortName: 'ENGPHYS 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974180',
      },
      {
        _id: '6073b71256f56ef638974182',
        courseName:
          'ENGPHYS 4UB2 - Modern and Applied Physics Laboratory: Biomedical',
        courseCode: '4UB2',
        shortName: 'ENGPHYS 4UB2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71256f56ef638974182',
      },
      {
        _id: '6073b71356f56ef638974184',
        courseName:
          'ENGPHYS 4UM2 - Modern and Applied Physics Laboratory: Nano- and Micro-devices',
        courseCode: '4UM2',
        shortName: 'ENGPHYS 4UM2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974184',
      },
      {
        _id: '6073b71356f56ef638974186',
        courseName:
          'ENGPHYS 4UN2 - Modern and Applied Physics Laboratory: Nuclear Labs',
        courseCode: '4UN2',
        shortName: 'ENGPHYS 4UN2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974186',
      },
      {
        _id: '6073b71356f56ef638974188',
        courseName:
          'ENGPHYS 4UP2 - Modern and Applied Physics Laboratory: Photonics',
        courseCode: '4UP2',
        shortName: 'ENGPHYS 4UP2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974188',
      },
      {
        _id: '6073b71356f56ef63897418a',
        courseName:
          'ENGPHYS 4US2 - Modern and Applied Physics Laboratory: Smart Systems',
        courseCode: '4US2',
        shortName: 'ENGPHYS 4US2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef63897418a',
      },
      {
        _id: '6073b71356f56ef63897418c',
        courseName: 'ENGPHYS 4X03 - Introduction to Photovoltaics',
        courseCode: '4X03',
        shortName: 'ENGPHYS 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef63897418c',
      },
      {
        _id: '6073b71356f56ef63897418e',
        courseName: 'ENGPHYS 4Z03 - Semiconductor Manufacturing Technology',
        courseCode: '4Z03',
        shortName: 'ENGPHYS 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef63897418e',
      },
      {
        _id: '6073b71356f56ef638974190',
        courseName: 'ENGTECH 1AC3 - Analytical Chemistry',
        courseCode: '1AC3',
        shortName: 'ENGTECH 1AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974190',
      },
      {
        _id: '6073b71356f56ef638974192',
        courseName: 'ENGTECH 1BI3 - Biology',
        courseCode: '1BI3',
        shortName: 'ENGTECH 1BI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974192',
      },
      {
        _id: '6073b71356f56ef638974194',
        courseName: 'ENGTECH 1CH3 - Chemistry',
        courseCode: '1CH3',
        shortName: 'ENGTECH 1CH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974194',
      },
      {
        _id: '6073b71356f56ef638974196',
        courseName: 'ENGTECH 1CP3 - C++ Programming',
        courseCode: '1CP3',
        shortName: 'ENGTECH 1CP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974196',
      },
      {
        _id: '6073b71356f56ef638974198',
        courseName: 'ENGTECH 1EL3 - Electricity and Electronics I',
        courseCode: '1EL3',
        shortName: 'ENGTECH 1EL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef638974198',
      },
      {
        _id: '6073b71356f56ef63897419a',
        courseName:
          'ENGTECH 1ET0 - Introduction to the Technology Co-op Program',
        courseCode: '1ET0',
        shortName: 'ENGTECH 1ET0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71356f56ef63897419a',
      },
      {
        _id: '6073b71456f56ef63897419c',
        courseName: 'ENGTECH 1MC3 - Mathematics I',
        courseCode: '1MC3',
        shortName: 'ENGTECH 1MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef63897419c',
      },
      {
        _id: '6073b71456f56ef63897419e',
        courseName: 'ENGTECH 1ME3 - Statics and Mechanics of Materials',
        courseCode: '1ME3',
        shortName: 'ENGTECH 1ME3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef63897419e',
      },
      {
        _id: '6073b71456f56ef6389741a0',
        courseName: 'ENGTECH 1MT3 - Mathematics II',
        courseCode: '1MT3',
        shortName: 'ENGTECH 1MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b71456f56ef6389741a0',
      },
      {
        _id: '6073b71456f56ef6389741a2',
        courseName: 'ENGTECH 1PH3 - Physics',
        courseCode: '1PH3',
        shortName: 'ENGTECH 1PH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741a2',
      },
      {
        _id: '6073b71456f56ef6389741a4',
        courseName: 'ENGTECH 1PP3 - Python Programming',
        courseCode: '1PP3',
        shortName: 'ENGTECH 1PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741a4',
      },
      {
        _id: '6073b71456f56ef6389741a6',
        courseName: 'ENGTECH 1PR3 - Object-Oriented Programming',
        courseCode: '1PR3',
        shortName: 'ENGTECH 1PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741a6',
      },
      {
        _id: '6073b71456f56ef6389741a8',
        courseName: 'ENGTECH 2EE0 - Four Month Co-op Experience I',
        courseCode: '2EE0',
        shortName: 'ENGTECH 2EE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741a8',
      },
      {
        _id: '6073b71456f56ef6389741aa',
        courseName: 'ENGTECH 2ES3 - Engineering Statistics',
        courseCode: '2ES3',
        shortName: 'ENGTECH 2ES3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741aa',
      },
      {
        _id: '6073b71456f56ef6389741ac',
        courseName: 'ENGTECH 2ET0 - Four Month Co-op Experience I',
        courseCode: '2ET0',
        shortName: 'ENGTECH 2ET0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741ac',
      },
      {
        _id: '6073b71456f56ef6389741ae',
        courseName: 'ENGTECH 2MA3 - Mathematics III',
        courseCode: '2MA3',
        shortName: 'ENGTECH 2MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741ae',
      },
      {
        _id: '6073b71456f56ef6389741b0',
        courseName: 'ENGTECH 2MS3 - Modelling and Numerical Solutions',
        courseCode: '2MS3',
        shortName: 'ENGTECH 2MS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741b0',
      },
      {
        _id: '6073b71456f56ef6389741b2',
        courseName: 'ENGTECH 2MT3 - Mathematics IV',
        courseCode: '2MT3',
        shortName: 'ENGTECH 2MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71456f56ef6389741b2',
      },
      {
        _id: '6073b71556f56ef6389741b4',
        courseName: 'ENGTECH 3DM3 - Discrete Mathematics',
        courseCode: '3DM3',
        shortName: 'ENGTECH 3DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741b4',
      },
      {
        _id: '6073b71556f56ef6389741b6',
        courseName: 'ENGTECH 3EE0 - Four Month Co-op Experience II',
        courseCode: '3EE0',
        shortName: 'ENGTECH 3EE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741b6',
      },
      {
        _id: '6073b71556f56ef6389741b8',
        courseName: 'ENGTECH 3ES3 - Engineering Statistics',
        courseCode: '3ES3',
        shortName: 'ENGTECH 3ES3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741b8',
      },
      {
        _id: '6073b71556f56ef6389741ba',
        courseName: 'ENGTECH 3ET0 - Four Month Co-op Experience II',
        courseCode: '3ET0',
        shortName: 'ENGTECH 3ET0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741ba',
      },
      {
        _id: '6073b71556f56ef6389741bc',
        courseName: 'ENGTECH 3FE3 - Finite Element Analysis',
        courseCode: '3FE3',
        shortName: 'ENGTECH 3FE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741bc',
      },
      {
        _id: '6073b71556f56ef6389741be',
        courseName: 'ENGTECH 3MA3 - Mathematics V',
        courseCode: '3MA3',
        shortName: 'ENGTECH 3MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741be',
      },
      {
        _id: '6073b71556f56ef6389741c0',
        courseName: 'ENGTECH 3ML3 - Strength of Materials',
        courseCode: '3ML3',
        shortName: 'ENGTECH 3ML3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741c0',
      },
      {
        _id: '6073b71556f56ef6389741c2',
        courseName: 'ENGTECH 3MN3 - Modelling and Numerical Solutions',
        courseCode: '3MN3',
        shortName: 'ENGTECH 3MN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741c2',
      },
      {
        _id: '6073b71556f56ef6389741c4',
        courseName: 'ENGTECH 3SD3 - Statics and Dynamics',
        courseCode: '3SD3',
        shortName: 'ENGTECH 3SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741c4',
      },
      {
        _id: '6073b71556f56ef6389741c6',
        courseName: 'ENGTECH 3SP3 - Structure and Properties of Materials',
        courseCode: '3SP3',
        shortName: 'ENGTECH 3SP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741c6',
      },
      {
        _id: '6073b71556f56ef6389741c8',
        courseName: 'ENGTECH 3ST3 - Probability and Statistics',
        courseCode: '3ST3',
        shortName: 'ENGTECH 3ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741c8',
      },
      {
        _id: '6073b71556f56ef6389741ca',
        courseName: 'ENGTECH 3TD3 - Thermodynamics',
        courseCode: '3TD3',
        shortName: 'ENGTECH 3TD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71556f56ef6389741ca',
      },
      {
        _id: '6073b71656f56ef6389741cc',
        courseName: 'ENGTECH 4CT3 - Systems and Control',
        courseCode: '4CT3',
        shortName: 'ENGTECH 4CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741cc',
      },
      {
        _id: '6073b71656f56ef6389741ce',
        courseName: 'ENGTECH 4ED3 - Senior Engineering Design Project',
        courseCode: '4ED3',
        shortName: 'ENGTECH 4ED3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741ce',
      },
      {
        _id: '6073b71656f56ef6389741d0',
        courseName: 'ENGTECH 4EE0 - Four Month Co-op Experience III',
        courseCode: '4EE0',
        shortName: 'ENGTECH 4EE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741d0',
      },
      {
        _id: '6073b71656f56ef6389741d2',
        courseName: 'ENGTECH 4EP3 - Senior Engineering Project',
        courseCode: '4EP3',
        shortName: 'ENGTECH 4EP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741d2',
      },
      {
        _id: '6073b71656f56ef6389741d4',
        courseName: 'ENGTECH 4EX0 - Four Month Co-op Experience',
        courseCode: '4EX0',
        shortName: 'ENGTECH 4EX0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741d4',
      },
      {
        _id: '6073b71656f56ef6389741d6',
        courseName: 'ENGTECH 4FA3 - Finite Element Analysis',
        courseCode: '4FA3',
        shortName: 'ENGTECH 4FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741d6',
      },
      {
        _id: '6073b71656f56ef6389741d8',
        courseName: 'ENGTECH 4FD3 - Senior Engineering Project',
        courseCode: '4FD3',
        shortName: 'ENGTECH 4FD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741d8',
      },
      {
        _id: '6073b71656f56ef6389741da',
        courseName: 'ENGTECH 4MA3 - Advanced Mathematics',
        courseCode: '4MA3',
        shortName: 'ENGTECH 4MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741da',
      },
      {
        _id: '6073b71656f56ef6389741dc',
        courseName: 'ENGTECH 4TF3 - Mechanics of Fluids',
        courseCode: '4TF3',
        shortName: 'ENGTECH 4TF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741dc',
      },
      {
        _id: '6073b71656f56ef6389741de',
        courseName: 'ENVIRSC 1C03 - Climate, Water And Environment',
        courseCode: '1C03',
        shortName: 'ENVIRSC 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741de',
      },
      {
        _id: '6073b71656f56ef6389741e0',
        courseName: 'ENVIRSC 2B03 - Soils and the Environment',
        courseCode: '2B03',
        shortName: 'ENVIRSC 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741e0',
      },
      {
        _id: '6073b71656f56ef6389741e2',
        courseName:
          'ENVIRSC 2C03 - Surface Climate Processes and Environmental Interactions',
        courseCode: '2C03',
        shortName: 'ENVIRSC 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71656f56ef6389741e2',
      },
      {
        _id: '6073b71756f56ef6389741e4',
        courseName: 'ENVIRSC 2Q03 - Introduction to Environmental Geochemistry',
        courseCode: '2Q03',
        shortName: 'ENVIRSC 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741e4',
      },
      {
        _id: '6073b71756f56ef6389741e6',
        courseName: 'ENVIRSC 2W03 - Physical Hydrology',
        courseCode: '2W03',
        shortName: 'ENVIRSC 2W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741e6',
      },
      {
        _id: '6073b71756f56ef6389741e8',
        courseName: 'ENVIRSC 2WW3 - Water and the Environment',
        courseCode: '2WW3',
        shortName: 'ENVIRSC 2WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741e8',
      },
      {
        _id: '6073b71756f56ef6389741ea',
        courseName: 'ENVIRSC 3B03 - Ecosystems and Global Change',
        courseCode: '3B03',
        shortName: 'ENVIRSC 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741ea',
      },
      {
        _id: '6073b71756f56ef6389741ec',
        courseName:
          'ENVIRSC 3IN3 - Internship in Earth and Environmental Sciences',
        courseCode: '3IN3',
        shortName: 'ENVIRSC 3IN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741ec',
      },
      {
        _id: '6073b71756f56ef6389741ee',
        courseName: 'ENVIRSC 3ME3 - Environmental Field Camp',
        courseCode: '3ME3',
        shortName: 'ENVIRSC 3ME3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741ee',
      },
      {
        _id: '6073b71756f56ef6389741f0',
        courseName: 'ENVIRSC 3O03 - Contaminant Fate and Transport',
        courseCode: '3O03',
        shortName: 'ENVIRSC 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741f0',
      },
      {
        _id: '6073b71756f56ef6389741f2',
        courseName: 'ENVIRSC 3U03 - Environmental Systems Modelling',
        courseCode: '3U03',
        shortName: 'ENVIRSC 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741f2',
      },
      {
        _id: '6073b71756f56ef6389741f4',
        courseName: 'ENVIRSC 4BB3 - Field Techniques in Hydrology',
        courseCode: '4BB3',
        shortName: 'ENVIRSC 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741f4',
      },
      {
        _id: '6073b71756f56ef6389741f6',
        courseName: 'ENVIRSC 4C03 - Advanced Physical Climatology',
        courseCode: '4C03',
        shortName: 'ENVIRSC 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741f6',
      },
      {
        _id: '6073b71756f56ef6389741f8',
        courseName: 'ENVIRSC 4EA3 - Environmental Assessment',
        courseCode: '4EA3',
        shortName: 'ENVIRSC 4EA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741f8',
      },
      {
        _id: '6073b71756f56ef6389741fa',
        courseName:
          'ENVIRSC 4MI3 - Independent Study in Earth and Environmental Sciences',
        courseCode: '4MI3',
        shortName: 'ENVIRSC 4MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71756f56ef6389741fa',
      },
      {
        _id: '6073b71856f56ef6389741fc',
        courseName: 'ENVIRSC 4MT6 A/B - Senior Thesis',
        courseCode: '4MT6',
        shortName: 'ENVIRSC 4MT6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef6389741fc',
      },
      {
        _id: '6073b71856f56ef6389741fe',
        courseName: 'ENVIRSC 4N03 - Global Biogeochemical Cycles',
        courseCode: '4N03',
        shortName: 'ENVIRSC 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef6389741fe',
      },
      {
        _id: '6073b71856f56ef638974200',
        courseName: 'ENVIRSC 4W03 - Hydrologic Modelling',
        courseCode: '4W03',
        shortName: 'ENVIRSC 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974200',
      },
      {
        _id: '6073b71856f56ef638974202',
        courseName: 'ENVSOCTY 1HA3 - Society, Culture and Environment',
        courseCode: '1HA3',
        shortName: 'ENVSOCTY 1HA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974202',
      },
      {
        _id: '6073b71856f56ef638974204',
        courseName: 'ENVSOCTY 1HB3 - Population, Cities and Development',
        courseCode: '1HB3',
        shortName: 'ENVSOCTY 1HB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974204',
      },
      {
        _id: '6073b71856f56ef638974206',
        courseName:
          'ENVSOCTY 2EI3 - Environment & Society: Challenges and Solutions',
        courseCode: '2EI3',
        shortName: 'ENVSOCTY 2EI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974206',
      },
      {
        _id: '6073b71856f56ef638974208',
        courseName:
          'ENVSOCTY 2EK3 - Traditional Indigenous Ecological Knowledge',
        courseCode: '2EK3',
        shortName: 'ENVSOCTY 2EK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974208',
      },
      {
        _id: '6073b71856f56ef63897420a',
        courseName: 'ENVSOCTY 2GI3 - Geographic Information Systems',
        courseCode: '2GI3',
        shortName: 'ENVSOCTY 2GI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef63897420a',
      },
      {
        _id: '6073b71856f56ef63897420c',
        courseName: 'ENVSOCTY 2HI3 - Health and Place',
        courseCode: '2HI3',
        shortName: 'ENVSOCTY 2HI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef63897420c',
      },
      {
        _id: '6073b71856f56ef63897420e',
        courseName:
          'ENVSOCTY 2LE3 - Economic Geography: Innovation, Inequality and Identity',
        courseCode: '2LE3',
        shortName: 'ENVSOCTY 2LE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef63897420e',
      },
      {
        _id: '6073b71856f56ef638974210',
        courseName: 'ENVSOCTY 2OC3 - Regional Geography of Canada',
        courseCode: '2OC3',
        shortName: 'ENVSOCTY 2OC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974210',
      },
      {
        _id: '6073b71856f56ef638974212',
        courseName: 'ENVSOCTY 2RC3 - Regional Geography of Canada',
        courseCode: '2RC3',
        shortName: 'ENVSOCTY 2RC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71856f56ef638974212',
      },
      {
        _id: '6073b71956f56ef638974214',
        courseName: 'ENVSOCTY 2RU3 - Regional Geography of the United States',
        courseCode: '2RU3',
        shortName: 'ENVSOCTY 2RU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974214',
      },
      {
        _id: '6073b71956f56ef638974216',
        courseName: 'ENVSOCTY 2RW3 - World Regional Geography',
        courseCode: '2RW3',
        shortName: 'ENVSOCTY 2RW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974216',
      },
      {
        _id: '6073b71956f56ef638974218',
        courseName: 'ENVSOCTY 2TF3 - Food, Power and Place',
        courseCode: '2TF3',
        shortName: 'ENVSOCTY 2TF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974218',
      },
      {
        _id: '6073b71956f56ef63897421a',
        courseName: 'ENVSOCTY 2TS3 - Society and Space',
        courseCode: '2TS3',
        shortName: 'ENVSOCTY 2TS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef63897421a',
      },
      {
        _id: '6073b71956f56ef63897421c',
        courseName: 'ENVSOCTY 2UI3 - The Urban Experience',
        courseCode: '2UI3',
        shortName: 'ENVSOCTY 2UI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef63897421c',
      },
      {
        _id: '6073b71956f56ef63897421e',
        courseName: 'ENVSOCTY 3EC3 - Environmental Catastrophes',
        courseCode: '3EC3',
        shortName: 'ENVSOCTY 3EC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef63897421e',
      },
      {
        _id: '6073b71956f56ef638974220',
        courseName: 'ENVSOCTY 3EE3 - Energy and Society',
        courseCode: '3EE3',
        shortName: 'ENVSOCTY 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974220',
      },
      {
        _id: '6073b71956f56ef638974222',
        courseName: 'ENVSOCTY 3EG3 - Global Climate Change',
        courseCode: '3EG3',
        shortName: 'ENVSOCTY 3EG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974222',
      },
      {
        _id: '6073b71956f56ef638974224',
        courseName: 'ENVSOCTY 3EN3 - Northern Environments and Societies',
        courseCode: '3EN3',
        shortName: 'ENVSOCTY 3EN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974224',
      },
      {
        _id: '6073b71956f56ef638974226',
        courseName: 'ENVSOCTY 3ER3 - Sustainability and the Economy',
        courseCode: '3ER3',
        shortName: 'ENVSOCTY 3ER3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974226',
      },
      {
        _id: '6073b71956f56ef638974228',
        courseName: 'ENVSOCTY 3GI3 - Advanced Raster GIS',
        courseCode: '3GI3',
        shortName: 'ENVSOCTY 3GI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef638974228',
      },
      {
        _id: '6073b71956f56ef63897422a',
        courseName: 'ENVSOCTY 3GV3 - Advanced Vector GIS',
        courseCode: '3GV3',
        shortName: 'ENVSOCTY 3GV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71956f56ef63897422a',
      },
      {
        _id: '6073b71a56f56ef63897422c',
        courseName: 'ENVSOCTY 3HP3 - Population, Health and Aging',
        courseCode: '3HP3',
        shortName: 'ENVSOCTY 3HP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef63897422c',
      },
      {
        _id: '6073b71a56f56ef63897422e',
        courseName: 'ENVSOCTY 3LT3 - Transportation Geography',
        courseCode: '3LT3',
        shortName: 'ENVSOCTY 3LT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef63897422e',
      },
      {
        _id: '6073b71a56f56ef638974230',
        courseName: 'ENVSOCTY 3MA3 - Research Methods',
        courseCode: '3MA3',
        shortName: 'ENVSOCTY 3MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef638974230',
      },
      {
        _id: '6073b71a56f56ef638974232',
        courseName: 'ENVSOCTY 3MB3 - Data Analysis',
        courseCode: '3MB3',
        shortName: 'ENVSOCTY 3MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef638974232',
      },
      {
        _id: '6073b71a56f56ef638974234',
        courseName: 'ENVSOCTY 3MF3 - Urban Field Camp',
        courseCode: '3MF3',
        shortName: 'ENVSOCTY 3MF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef638974234',
      },
      {
        _id: '6073b71a56f56ef638974236',
        courseName: 'ENVSOCTY 3MI3 - Internship in Environment and Society',
        courseCode: '3MI3',
        shortName: 'ENVSOCTY 3MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef638974236',
      },
      {
        _id: '6073b71a56f56ef638974238',
        courseName:
          'ENVSOCTY 3RW3 - Regional Geography of a Selected World Region',
        courseCode: '3RW3',
        shortName: 'ENVSOCTY 3RW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef638974238',
      },
      {
        _id: '6073b71a56f56ef63897423a',
        courseName: 'ENVSOCTY 3SR3 - Remote Sensing',
        courseCode: '3SR3',
        shortName: 'ENVSOCTY 3SR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef63897423a',
      },
      {
        _id: '6073b71a56f56ef63897423c',
        courseName: 'ENVSOCTY 3TG3 - Geographies of Globalization',
        courseCode: '3TG3',
        shortName: 'ENVSOCTY 3TG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef63897423c',
      },
      {
        _id: '6073b71a56f56ef63897423e',
        courseName: 'ENVSOCTY 3UP3 - Urban Planning',
        courseCode: '3UP3',
        shortName: 'ENVSOCTY 3UP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef63897423e',
      },
      {
        _id: '6073b71a56f56ef638974240',
        courseName: 'ENVSOCTY 3UR3 - Urban Social Geography',
        courseCode: '3UR3',
        shortName: 'ENVSOCTY 3UR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71a56f56ef638974240',
      },
      {
        _id: '6073b71b56f56ef638974242',
        courseName: 'ENVSOCTY 3UW3 - Cities of the Developing World',
        courseCode: '3UW3',
        shortName: 'ENVSOCTY 3UW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974242',
      },
      {
        _id: '6073b71b56f56ef638974244',
        courseName: 'ENVSOCTY 4EA3 - Environmental Assessment',
        courseCode: '4EA3',
        shortName: 'ENVSOCTY 4EA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974244',
      },
      {
        _id: '6073b71b56f56ef638974246',
        courseName: 'ENVSOCTY 4ET3 - Environmental Policy, Ethics and Risk',
        courseCode: '4ET3',
        shortName: 'ENVSOCTY 4ET3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974246',
      },
      {
        _id: '6073b71b56f56ef638974248',
        courseName: 'ENVSOCTY 4GA3 - Applied Spatial Statistics',
        courseCode: '4GA3',
        shortName: 'ENVSOCTY 4GA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974248',
      },
      {
        _id: '6073b71b56f56ef63897424a',
        courseName: 'ENVSOCTY 4GS3 - GIS Programming',
        courseCode: '4GS3',
        shortName: 'ENVSOCTY 4GS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef63897424a',
      },
      {
        _id: '6073b71b56f56ef63897424c',
        courseName: 'ENVSOCTY 4GT3 - Special Topics in GIS',
        courseCode: '4GT3',
        shortName: 'ENVSOCTY 4GT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef63897424c',
      },
      {
        _id: '6073b71b56f56ef63897424e',
        courseName: 'ENVSOCTY 4HC3 - Public and Community Health',
        courseCode: '4HC3',
        shortName: 'ENVSOCTY 4HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef63897424e',
      },
      {
        _id: '6073b71b56f56ef638974250',
        courseName: 'ENVSOCTY 4HD3 - Disability, Society and Environment',
        courseCode: '4HD3',
        shortName: 'ENVSOCTY 4HD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974250',
      },
      {
        _id: '6073b71b56f56ef638974252',
        courseName: 'ENVSOCTY 4HH3 - Environment and Health',
        courseCode: '4HH3',
        shortName: 'ENVSOCTY 4HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974252',
      },
      {
        _id: '6073b71b56f56ef638974254',
        courseName:
          'ENVSOCTY 4LE3 - Geographies of the North American Political Economy',
        courseCode: '4LE3',
        shortName: 'ENVSOCTY 4LE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974254',
      },
      {
        _id: '6073b71b56f56ef638974256',
        courseName: 'ENVSOCTY 4LP3 - Transport Policy',
        courseCode: '4LP3',
        shortName: 'ENVSOCTY 4LP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974256',
      },
      {
        _id: '6073b71b56f56ef638974258',
        courseName: 'ENVSOCTY 4LW3 - Work and the Environment',
        courseCode: '4LW3',
        shortName: 'ENVSOCTY 4LW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71b56f56ef638974258',
      },
      {
        _id: '6073b71c56f56ef63897425a',
        courseName: 'ENVSOCTY 4MF3 - Senior Urban Field Camp',
        courseCode: '4MF3',
        shortName: 'ENVSOCTY 4MF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef63897425a',
      },
      {
        _id: '6073b71c56f56ef63897425c',
        courseName: 'ENVSOCTY 4MS3 - Independent Study',
        courseCode: '4MS3',
        shortName: 'ENVSOCTY 4MS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef63897425c',
      },
      {
        _id: '6073b71c56f56ef63897425e',
        courseName: 'ENVSOCTY 4MT6 A/B - Senior Thesis',
        courseCode: '4MT6',
        shortName: 'ENVSOCTY 4MT6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef63897425e',
      },
      {
        _id: '6073b71c56f56ef638974260',
        courseName: 'ENVSOCTY 4SR3 - Advanced Remote Sensing',
        courseCode: '4SR3',
        shortName: 'ENVSOCTY 4SR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef638974260',
      },
      {
        _id: '6073b71c56f56ef638974262',
        courseName: 'ENVSOCTY 4UD3 - Special Topics in Urban Planning',
        courseCode: '4UD3',
        shortName: 'ENVSOCTY 4UD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef638974262',
      },
      {
        _id: '6073b71c56f56ef638974264',
        courseName: 'ENVSOCTY 4UF3 - The Geography of Gender',
        courseCode: '4UF3',
        shortName: 'ENVSOCTY 4UF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef638974264',
      },
      {
        _id: '6073b71c56f56ef638974266',
        courseName: 'ENVSOCTY 4US3 - Sustainable Cities',
        courseCode: '4US3',
        shortName: 'ENVSOCTY 4US3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef638974266',
      },
      {
        _id: '6073b71c56f56ef638974268',
        courseName: 'EXPLORE 3IE1 - Interdisciplinary Experiences',
        courseCode: '3IE1',
        shortName: 'EXPLORE 3IE1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef638974268',
      },
      {
        _id: '6073b71c56f56ef63897426a',
        courseName: 'EXPLORE 3IE2 - Interdisciplinary Experiences',
        courseCode: '3IE2',
        shortName: 'EXPLORE 3IE2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef63897426a',
      },
      {
        _id: '6073b71c56f56ef63897426c',
        courseName: 'EXPLORE 3IE3 - Interdisciplinary Experiences',
        courseCode: '3IE3',
        shortName: 'EXPLORE 3IE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef63897426c',
      },
      {
        _id: '6073b71c56f56ef63897426e',
        courseName: 'EXPLORE 3IS0 - Interdisciplinary Science Field Work',
        courseCode: '3IS0',
        shortName: 'EXPLORE 3IS0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef63897426e',
      },
      {
        _id: '6073b71c56f56ef638974270',
        courseName: 'EXPLORE 3IS3 - Interdisciplinary Science Field Camp',
        courseCode: '3IS3',
        shortName: 'EXPLORE 3IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71c56f56ef638974270',
      },
      {
        _id: '6073b71d56f56ef638974272',
        courseName: 'FARSI 1Z03 - Introductory Farsi I',
        courseCode: '1Z03',
        shortName: 'FARSI 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974272',
      },
      {
        _id: '6073b71d56f56ef638974274',
        courseName: 'FARSI 1ZZ3 - Introductory Farsi II',
        courseCode: '1ZZ3',
        shortName: 'FARSI 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974274',
      },
      {
        _id: '6073b71d56f56ef638974276',
        courseName:
          'FRENCH 1A06 A/B - Introduction to French Studies: Advanced Level',
        courseCode: '1A06',
        shortName: 'FRENCH 1A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974276',
      },
      {
        _id: '6073b71d56f56ef638974278',
        courseName: 'FRENCH 1Z06 A/B - Beginnerâ€™s Intensive French I',
        courseCode: '1Z06',
        shortName: 'FRENCH 1Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974278',
      },
      {
        _id: '6073b71d56f56ef63897427a',
        courseName:
          'FRENCH 2AC3 - Introduction to Francophone Literatures and Cultures',
        courseCode: '2AC3',
        shortName: 'FRENCH 2AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef63897427a',
      },
      {
        _id: '6073b71d56f56ef63897427c',
        courseName: 'FRENCH 2B03 - French Language Practice I',
        courseCode: '2B03',
        shortName: 'FRENCH 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef63897427c',
      },
      {
        _id: '6073b71d56f56ef63897427e',
        courseName: 'FRENCH 2BB3 - French Language Practice II',
        courseCode: '2BB3',
        shortName: 'FRENCH 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef63897427e',
      },
      {
        _id: '6073b71d56f56ef638974280',
        courseName: 'FRENCH 2CC3 - Womenâ€™s Writing',
        courseCode: '2CC3',
        shortName: 'FRENCH 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974280',
      },
      {
        _id: '6073b71d56f56ef638974282',
        courseName: 'FRENCH 2E03 - Survey of Quebec Literature and Culture',
        courseCode: '2E03',
        shortName: 'FRENCH 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974282',
      },
      {
        _id: '6073b71d56f56ef638974284',
        courseName: 'FRENCH 2F03 - Survey of French and Francophone Literature',
        courseCode: '2F03',
        shortName: 'FRENCH 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974284',
      },
      {
        _id: '6073b71d56f56ef638974286',
        courseName:
          'FRENCH 2G03 - French Language Practice: Elementary Translation from English to French',
        courseCode: '2G03',
        shortName: 'FRENCH 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71d56f56ef638974286',
      },
      {
        _id: '6073b71e56f56ef638974288',
        courseName: 'FRENCH 2I03 - Professional French I',
        courseCode: '2I03',
        shortName: 'FRENCH 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef638974288',
      },
      {
        _id: '6073b71e56f56ef63897428a',
        courseName: 'FRENCH 2JJ3 - Nineteenth-Century French Literature',
        courseCode: '2JJ3',
        shortName: 'FRENCH 2JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef63897428a',
      },
      {
        _id: '6073b71e56f56ef63897428c',
        courseName: 'FRENCH 2L03 - Introduction to Literary Analysis',
        courseCode: '2L03',
        shortName: 'FRENCH 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef63897428c',
      },
      {
        _id: '6073b71e56f56ef63897428e',
        courseName:
          'FRENCH 2M06 A/B - Introduction to French Studies: Advanced Level',
        courseCode: '2M06',
        shortName: 'FRENCH 2M06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef63897428e',
      },
      {
        _id: '6073b71e56f56ef638974290',
        courseName: 'FRENCH 2Z06 A/B - Beginnerâ€™s Intensive French II',
        courseCode: '2Z06',
        shortName: 'FRENCH 2Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef638974290',
      },
      {
        _id: '6073b71e56f56ef638974292',
        courseName: 'FRENCH 3AA3 - The Modern French-Canadian Novel',
        courseCode: '3AA3',
        shortName: 'FRENCH 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef638974292',
      },
      {
        _id: '6073b71e56f56ef638974294',
        courseName: 'FRENCH 3AC3 - Francophone Writers',
        courseCode: '3AC3',
        shortName: 'FRENCH 3AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef638974294',
      },
      {
        _id: '6073b71e56f56ef638974296',
        courseName: 'FRENCH 3C03 - French Language Practice: Written',
        courseCode: '3C03',
        shortName: 'FRENCH 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef638974296',
      },
      {
        _id: '6073b71e56f56ef638974298',
        courseName:
          'FRENCH 3CC3 - French Language Practice: Intermediate Translation from English into French',
        courseCode: '3CC3',
        shortName: 'FRENCH 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef638974298',
      },
      {
        _id: '6073b71e56f56ef63897429a',
        courseName: 'FRENCH 3EE3 - Recent French Literature',
        courseCode: '3EE3',
        shortName: 'FRENCH 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef63897429a',
      },
      {
        _id: '6073b71e56f56ef63897429c',
        courseName: 'FRENCH 3FF3 - Francophone Cinemas',
        courseCode: '3FF3',
        shortName: 'FRENCH 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef63897429c',
      },
      {
        _id: '6073b71e56f56ef63897429e',
        courseName:
          'FRENCH 3GG3 - French Language Practice: Elementary Translation from French to English',
        courseCode: '3GG3',
        shortName: 'FRENCH 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71e56f56ef63897429e',
      },
      {
        _id: '6073b71f56f56ef6389742a0',
        courseName: 'FRENCH 3HH3 - Francophone Voices in Canada',
        courseCode: '3HH3',
        shortName: 'FRENCH 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742a0',
      },
      {
        _id: '6073b71f56f56ef6389742a2',
        courseName: 'FRENCH 3II3 - Professional French II',
        courseCode: '3II3',
        shortName: 'FRENCH 3II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742a2',
      },
      {
        _id: '6073b71f56f56ef6389742a4',
        courseName:
          'FRENCH 3KK3 - Revolutionary Literature Before the Revolution: Voltaire, Rousseau and Beaumarchais',
        courseCode: '3KK3',
        shortName: 'FRENCH 3KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742a4',
      },
      {
        _id: '6073b71f56f56ef6389742a6',
        courseName: 'FRENCH 3LT3 - Introduction to Modern Literary Theory',
        courseCode: '3LT3',
        shortName: 'FRENCH 3LT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742a6',
      },
      {
        _id: '6073b71f56f56ef6389742a8',
        courseName: 'FRENCH 3P03 - History and Philosophy of Education',
        courseCode: '3P03',
        shortName: 'FRENCH 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742a8',
      },
      {
        _id: '6073b71f56f56ef6389742aa',
        courseName: 'FRENCH 3Q03 - Seventeenth-Century French Literature',
        courseCode: '3Q03',
        shortName: 'FRENCH 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742aa',
      },
      {
        _id: '6073b71f56f56ef6389742ac',
        courseName: 'FRENCH 3SS3 - Medieval Civilization and the Imaginaire',
        courseCode: '3SS3',
        shortName: 'FRENCH 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742ac',
      },
      {
        _id: '6073b71f56f56ef6389742ae',
        courseName: 'FRENCH 3V03 - Image and Knowledge Representation',
        courseCode: '3V03',
        shortName: 'FRENCH 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742ae',
      },
      {
        _id: '6073b71f56f56ef6389742b0',
        courseName: 'FRENCH 3W03 - Twentieth-Century French Literature',
        courseCode: '3W03',
        shortName: 'FRENCH 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742b0',
      },
      {
        _id: '6073b71f56f56ef6389742b2',
        courseName: 'FRENCH 4A03 - French Language Practice',
        courseCode: '4A03',
        shortName: 'FRENCH 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742b2',
      },
      {
        _id: '6073b71f56f56ef6389742b4',
        courseName:
          'FRENCH 4CC3 - Theoretical Reflections on Interdisciplinarity',
        courseCode: '4CC3',
        shortName: 'FRENCH 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742b4',
      },
      {
        _id: '6073b71f56f56ef6389742b6',
        courseName:
          'FRENCH 4DD3 - Animals in French and Francophone Literatures',
        courseCode: '4DD3',
        shortName: 'FRENCH 4DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b71f56f56ef6389742b6',
      },
      {
        _id: '6073b72056f56ef6389742b8',
        courseName:
          'FRENCH 4I03 - French Poetry from the Renaissance to the Present',
        courseCode: '4I03',
        shortName: 'FRENCH 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742b8',
      },
      {
        _id: '6073b72056f56ef6389742ba',
        courseName: 'FRENCH 4LL3 - Topics in Francophone Literatures',
        courseCode: '4LL3',
        shortName: 'FRENCH 4LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742ba',
      },
      {
        _id: '6073b72056f56ef6389742bc',
        courseName:
          'FRENCH 4MM3 - Sex, Violence and Elegance: The 18th-Century Novel',
        courseCode: '4MM3',
        shortName: 'FRENCH 4MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742bc',
      },
      {
        _id: '6073b72056f56ef6389742be',
        courseName:
          'FRENCH 4P06 A/B - French as a Second Language: From Theory to Practice',
        courseCode: '4P06',
        shortName: 'FRENCH 4P06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742be',
      },
      {
        _id: '6073b72056f56ef6389742c0',
        courseName: 'FRENCH 4T03 - Independent Study',
        courseCode: '4T03',
        shortName: 'FRENCH 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742c0',
      },
      {
        _id: '6073b72056f56ef6389742c2',
        courseName:
          'FRENCH 4U03 - Topics in Literature and Culture of Quebec and Francophone Canada',
        courseCode: '4U03',
        shortName: 'FRENCH 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742c2',
      },
      {
        _id: '6073b72056f56ef6389742c4',
        courseName: 'FRENCH 4Y03 - Topics in 20th-Century French Literature',
        courseCode: '4Y03',
        shortName: 'FRENCH 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742c4',
      },
      {
        _id: '6073b72056f56ef6389742c6',
        courseName: 'GENTECH 1BZ3 - Foundations of Business',
        courseCode: '1BZ3',
        shortName: 'GENTECH 1BZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742c6',
      },
      {
        _id: '6073b72056f56ef6389742c8',
        courseName: 'GENTECH 1PC3 - Professional Communications',
        courseCode: '1PC3',
        shortName: 'GENTECH 1PC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742c8',
      },
      {
        _id: '6073b72056f56ef6389742ca',
        courseName: 'GENTECH 2EE3 - Engineering Economics',
        courseCode: '2EE3',
        shortName: 'GENTECH 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742ca',
      },
      {
        _id: '6073b72056f56ef6389742cc',
        courseName: 'GENTECH 2HR3 - Human Resource Fundamentals',
        courseCode: '2HR3',
        shortName: 'GENTECH 2HR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742cc',
      },
      {
        _id: '6073b72056f56ef6389742ce',
        courseName: 'GENTECH 2MP3 - Management Principles',
        courseCode: '2MP3',
        shortName: 'GENTECH 2MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742ce',
      },
      {
        _id: '6073b72056f56ef6389742d0',
        courseName: 'GENTECH 3DM3 - Creativity, Innovation and Technology',
        courseCode: '3DM3',
        shortName: 'GENTECH 3DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72056f56ef6389742d0',
      },
      {
        _id: '6073b72156f56ef6389742d2',
        courseName: 'GENTECH 3EE3 - Engineering Economics',
        courseCode: '3EE3',
        shortName: 'GENTECH 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742d2',
      },
      {
        _id: '6073b72156f56ef6389742d4',
        courseName: 'GENTECH 3EN3 - Entrepreneurial Thinking and Innovation',
        courseCode: '3EN3',
        shortName: 'GENTECH 3EN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742d4',
      },
      {
        _id: '6073b72156f56ef6389742d6',
        courseName: 'GENTECH 3ET3 - Entrepreneurial Thinking and Innovation',
        courseCode: '3ET3',
        shortName: 'GENTECH 3ET3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742d6',
      },
      {
        _id: '6073b72156f56ef6389742d8',
        courseName: 'GENTECH 3FF3 - Financial Systems',
        courseCode: '3FF3',
        shortName: 'GENTECH 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742d8',
      },
      {
        _id: '6073b72156f56ef6389742da',
        courseName: 'GENTECH 3FS3 - Financial Systems',
        courseCode: '3FS3',
        shortName: 'GENTECH 3FS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742da',
      },
      {
        _id: '6073b72156f56ef6389742dc',
        courseName: 'GENTECH 3LS3 - Quality Control and Assurance Methods',
        courseCode: '3LS3',
        shortName: 'GENTECH 3LS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742dc',
      },
      {
        _id: '6073b72156f56ef6389742de',
        courseName: 'GENTECH 3MP3 - Management Principles',
        courseCode: '3MP3',
        shortName: 'GENTECH 3MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742de',
      },
      {
        _id: '6073b72156f56ef6389742e0',
        courseName: 'GENTECH 3MT3 - Project Management',
        courseCode: '3MT3',
        shortName: 'GENTECH 3MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742e0',
      },
      {
        _id: '6073b72156f56ef6389742e2',
        courseName: 'GENTECH 4EM3 - Legal and Regulatory Issues',
        courseCode: '4EM3',
        shortName: 'GENTECH 4EM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742e2',
      },
      {
        _id: '6073b72156f56ef6389742e4',
        courseName: 'GENTECH 4FT3 - Strategic Management',
        courseCode: '4FT3',
        shortName: 'GENTECH 4FT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742e4',
      },
      {
        _id: '6073b72156f56ef6389742e6',
        courseName: 'GENTECH 4LM3 - Lean Thinking and Practices',
        courseCode: '4LM3',
        shortName: 'GENTECH 4LM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742e6',
      },
      {
        _id: '6073b72156f56ef6389742e8',
        courseName: 'GENTECH 4MB3 - Fundamentals of Marketing',
        courseCode: '4MB3',
        shortName: 'GENTECH 4MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72156f56ef6389742e8',
      },
      {
        _id: '6073b72256f56ef6389742ea',
        courseName: 'GENTECH 4MK3 - Fundamentals of Marketing',
        courseCode: '4MK3',
        shortName: 'GENTECH 4MK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742ea',
      },
      {
        _id: '6073b72256f56ef6389742ec',
        courseName: 'GENTECH 4OM3 - Operations Management',
        courseCode: '4OM3',
        shortName: 'GENTECH 4OM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742ec',
      },
      {
        _id: '6073b72256f56ef6389742ee',
        courseName: 'GENTECH 4PM3 - Project Management',
        courseCode: '4PM3',
        shortName: 'GENTECH 4PM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742ee',
      },
      {
        _id: '6073b72256f56ef6389742f0',
        courseName: 'GENTECH 4SE3 - Technology Ethics and Sustainability',
        courseCode: '4SE3',
        shortName: 'GENTECH 4SE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742f0',
      },
      {
        _id: '6073b72256f56ef6389742f2',
        courseName: 'GENTECH 4SF3 - Strategic Management',
        courseCode: '4SF3',
        shortName: 'GENTECH 4SF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742f2',
      },
      {
        _id: '6073b72256f56ef6389742f4',
        courseName: 'GENTECH 4ST3 - Contemporary Issues in Management',
        courseCode: '4ST3',
        shortName: 'GENTECH 4ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742f4',
      },
      {
        _id: '6073b72256f56ef6389742f6',
        courseName: 'GENTECH 4TC3 - Technical Communications',
        courseCode: '4TC3',
        shortName: 'GENTECH 4TC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742f6',
      },
      {
        _id: '6073b72256f56ef6389742f8',
        courseName: 'GENTECH 4TE3 - Technology Ethics and Sustainability',
        courseCode: '4TE3',
        shortName: 'GENTECH 4TE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742f8',
      },
      {
        _id: '6073b72256f56ef6389742fa',
        courseName: 'GENTECH 4TS3 - Technology and Society',
        courseCode: '4TS3',
        shortName: 'GENTECH 4TS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742fa',
      },
      {
        _id: '6073b72256f56ef6389742fc',
        courseName: 'GERMAN 1B03 - Intermediate German I',
        courseCode: 'GERMAN',
        shortName: 'GERMAN 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742fc',
      },
      {
        _id: '6073b72256f56ef6389742fe',
        courseName: 'GERMAN 1BB3 - Intermediate German II',
        courseCode: '1BB3',
        shortName: 'GERMAN 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef6389742fe',
      },
      {
        _id: '6073b72256f56ef638974300',
        courseName: 'GERMAN 1Z06 A/B S - Beginnerâ€™s Intensive German',
        courseCode: '1Z06',
        shortName: 'GERMAN 1Z06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72256f56ef638974300',
      },
      {
        _id: '6073b72356f56ef638974302',
        courseName:
          'GERMAN 2CC3 - Germany Through the Ages: Culture and Society (Taught in English)',
        courseCode: '2CC3',
        shortName: 'GERMAN 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974302',
      },
      {
        _id: '6073b72356f56ef638974304',
        courseName: 'GERMAN 2FT3 - The Fairy Tale (Taught in English)',
        courseCode: '2FT3',
        shortName: 'GERMAN 2FT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974304',
      },
      {
        _id: '6073b72356f56ef638974306',
        courseName:
          'GERMAN 2N03 - The Holocaust in Film and Fiction (Taught in English)',
        courseCode: '2N03',
        shortName: 'GERMAN 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974306',
      },
      {
        _id: '6073b72356f56ef638974308',
        courseName:
          'GERMAN 2P03 - Modern Germany Through Film: Symphonies of Magic & Horror (Taught in English)',
        courseCode: '2P03',
        shortName: 'GERMAN 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974308',
      },
      {
        _id: '6073b72356f56ef63897430a',
        courseName: 'GERMAN 2Z03 - Intermediate German I',
        courseCode: '2Z03',
        shortName: 'GERMAN 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef63897430a',
      },
      {
        _id: '6073b72356f56ef63897430c',
        courseName: 'GERMAN 2ZZ3 - Intermediate German II',
        courseCode: '2ZZ3',
        shortName: 'GERMAN 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef63897430c',
      },
      {
        _id: '6073b72356f56ef63897430e',
        courseName:
          'GERMAN 3H03 - The New Europe: A New Germany (Taught in English)',
        courseCode: '3H03',
        shortName: 'GERMAN 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef63897430e',
      },
      {
        _id: '6073b72356f56ef638974310',
        courseName: 'GERMAN 3Z03 - Advanced German I',
        courseCode: '3Z03',
        shortName: 'GERMAN 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974310',
      },
      {
        _id: '6073b72356f56ef638974312',
        courseName: 'GERMAN 3ZZ3 - Advanced German II',
        courseCode: '3ZZ3',
        shortName: 'GERMAN 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974312',
      },
      {
        _id: '6073b72356f56ef638974314',
        courseName: 'GERMAN 4CC3 - Translation: Techniques and Practice',
        courseCode: '4CC3',
        shortName: 'GERMAN 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974314',
      },
      {
        _id: '6073b72356f56ef638974316',
        courseName: 'GERMAN 4II3 A/B S - Independent Study',
        courseCode: '4II3',
        shortName: 'GERMAN 4II3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974316',
      },
      {
        _id: '6073b72356f56ef638974318',
        courseName:
          'GERMAN 4RC6 - Advanced German Reading Course (Taught in English)',
        courseCode: '4RC6',
        shortName: 'GERMAN 4RC6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72356f56ef638974318',
      },
      {
        _id: '6073b72456f56ef63897431a',
        courseName: 'GREEK 1Z03 - Beginnerâ€™s Intensive Ancient Greek I',
        courseCode: '1Z03',
        shortName: 'GREEK 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef63897431a',
      },
      {
        _id: '6073b72456f56ef63897431c',
        courseName: 'GREEK 1ZZ3 - Beginnerâ€™s Intensive Ancient Greek II',
        courseCode: '1ZZ3',
        shortName: 'GREEK 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef63897431c',
      },
      {
        _id: '6073b72456f56ef63897431e',
        courseName: 'GREEK 2A03 - Intermediate Greek I',
        courseCode: '2A03',
        shortName: 'GREEK 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef63897431e',
      },
      {
        _id: '6073b72456f56ef638974320',
        courseName: 'GREEK 2AA3 - Intermediate Greek II',
        courseCode: '2AA3',
        shortName: 'GREEK 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef638974320',
      },
      {
        _id: '6073b72456f56ef638974322',
        courseName: 'GREEK 3AA3 - Greek Prose',
        courseCode: '3AA3',
        shortName: 'GREEK 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef638974322',
      },
      {
        _id: '6073b72456f56ef638974324',
        courseName: 'GREEK 3BB3 - Topics in Greek Literature',
        courseCode: '3BB3',
        shortName: 'GREEK 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef638974324',
      },
      {
        _id: '6073b72456f56ef638974326',
        courseName: 'GREEK 3E03 - Topics in Greek Poetry',
        courseCode: '3E03',
        shortName: 'GREEK 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef638974326',
      },
      {
        _id: '6073b72456f56ef638974328',
        courseName: 'GREEK 4T03 - Independent Study in Greek',
        courseCode: '4T03',
        shortName: 'GREEK 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef638974328',
      },
      {
        _id: '6073b72456f56ef63897432a',
        courseName: 'HISTORY 1CC3 - The Rise of Empires, 500-1950',
        courseCode: '1CC3',
        shortName: 'HISTORY 1CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef63897432a',
      },
      {
        _id: '6073b72456f56ef63897432c',
        courseName: 'HISTORY 1DD3 - The Making of the Modern World, 1750-1945',
        courseCode: '1DD3',
        shortName: 'HISTORY 1DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef63897432c',
      },
      {
        _id: '6073b72456f56ef63897432e',
        courseName:
          'HISTORY 1EE3 - The Historical Roots of Contemporary Issues',
        courseCode: '1EE3',
        shortName: 'HISTORY 1EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef63897432e',
      },
      {
        _id: '6073b72456f56ef638974330',
        courseName: 'HISTORY 1FF3 - Exploring History in a Small Group Setting',
        courseCode: '1FF3',
        shortName: 'HISTORY 1FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72456f56ef638974330',
      },
      {
        _id: '6073b72556f56ef638974332',
        courseName: 'HISTORY 1M03 - History of Greece and Rome',
        courseCode: '1M03',
        shortName: 'HISTORY 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974332',
      },
      {
        _id: '6073b72556f56ef638974334',
        courseName: 'HISTORY 1P03 - A History of Magic',
        courseCode: '1P03',
        shortName: 'HISTORY 1P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974334',
      },
      {
        _id: '6073b72556f56ef638974336',
        courseName: 'HISTORY 2A03 - Modern Middle Eastern Societies',
        courseCode: '2A03',
        shortName: 'HISTORY 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974336',
      },
      {
        _id: '6073b72556f56ef638974338',
        courseName: 'HISTORY 2CC3 - The Medieval World 400-1050',
        courseCode: '2CC3',
        shortName: 'HISTORY 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974338',
      },
      {
        _id: '6073b72556f56ef63897433a',
        courseName: 'HISTORY 2CS3 - Caribbean Slavery in the Atlantic World',
        courseCode: '2CS3',
        shortName: 'HISTORY 2CS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef63897433a',
      },
      {
        _id: '6073b72556f56ef63897433c',
        courseName: 'HISTORY 2DD3 - The Medieval World 1050-1400',
        courseCode: '2DD3',
        shortName: 'HISTORY 2DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef63897433c',
      },
      {
        _id: '6073b72556f56ef63897433e',
        courseName: 'HISTORY 2DF3 - Art and Revolutions in France, 1789-1914',
        courseCode: '2DF3',
        shortName: 'HISTORY 2DF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef63897433e',
      },
      {
        _id: '6073b72556f56ef638974340',
        courseName: 'HISTORY 2EE3 - Science and Technology in World History',
        courseCode: '2EE3',
        shortName: 'HISTORY 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974340',
      },
      {
        _id: '6073b72556f56ef638974342',
        courseName: 'HISTORY 2EN3 - Caribbean History',
        courseCode: '2EN3',
        shortName: 'HISTORY 2EN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974342',
      },
      {
        _id: '6073b72556f56ef638974344',
        courseName: 'HISTORY 2G03 - Modern Latin America Since 1820',
        courseCode: '2G03',
        shortName: 'HISTORY 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974344',
      },
      {
        _id: '6073b72556f56ef638974346',
        courseName: 'HISTORY 2GR3 - A History of Monsters',
        courseCode: '2GR3',
        shortName: 'HISTORY 2GR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974346',
      },
      {
        _id: '6073b72556f56ef638974348',
        courseName: 'HISTORY 2GW3 - A History of Global War',
        courseCode: '2GW3',
        shortName: 'HISTORY 2GW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72556f56ef638974348',
      },
      {
        _id: '6073b72656f56ef63897434a',
        courseName: 'HISTORY 2H03 - Tudor and Stuart Britain, 1485-1714',
        courseCode: '2H03',
        shortName: 'HISTORY 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef63897434a',
      },
      {
        _id: '6073b72656f56ef63897434c',
        courseName:
          'HISTORY 2HH3 - Pirates, Pilgrims and Slaves in the Mediterranean, 1450-1750',
        courseCode: '2HH3',
        shortName: 'HISTORY 2HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef63897434c',
      },
      {
        _id: '6073b72656f56ef63897434e',
        courseName: 'HISTORY 2II3 - Modern Germany',
        courseCode: '2II3',
        shortName: 'HISTORY 2II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef63897434e',
      },
      {
        _id: '6073b72656f56ef638974350',
        courseName:
          'HISTORY 2IS3 - Impeached! Scandal and Intrigue in American Political and Social History',
        courseCode: '2IS3',
        shortName: 'HISTORY 2IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef638974350',
      },
      {
        _id: '6073b72656f56ef638974352',
        courseName: 'HISTORY 2J03 - Africa up to 1800',
        courseCode: '2J03',
        shortName: 'HISTORY 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef638974352',
      },
      {
        _id: '6073b72656f56ef638974354',
        courseName: 'HISTORY 2JJ3 - Africa since 1800',
        courseCode: '2JJ3',
        shortName: 'HISTORY 2JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef638974354',
      },
      {
        _id: '6073b72656f56ef638974356',
        courseName: 'HISTORY 2KK3 - History of Capitalism',
        courseCode: '2KK3',
        shortName: 'HISTORY 2KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef638974356',
      },
      {
        _id: '6073b72656f56ef638974358',
        courseName: 'HISTORY 2MC3 - Modern China',
        courseCode: '2MC3',
        shortName: 'HISTORY 2MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef638974358',
      },
      {
        _id: '6073b72656f56ef63897435a',
        courseName: 'HISTORY 2Q03 - Imperial Russia',
        courseCode: '2Q03',
        shortName: 'HISTORY 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef63897435a',
      },
      {
        _id: '6073b72656f56ef63897435c',
        courseName: 'HISTORY 2QQ3 - The Soviet Union',
        courseCode: '2QQ3',
        shortName: 'HISTORY 2QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef63897435c',
      },
      {
        _id: '6073b72656f56ef63897435e',
        courseName: 'HISTORY 2R03 - U.S. History to the Civil War',
        courseCode: '2R03',
        shortName: 'HISTORY 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef63897435e',
      },
      {
        _id: '6073b72656f56ef638974360',
        courseName: 'HISTORY 2RR3 - U.S. History Since the Civil War',
        courseCode: '2RR3',
        shortName: 'HISTORY 2RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72656f56ef638974360',
      },
      {
        _id: '6073b72756f56ef638974362',
        courseName: 'HISTORY 2SH3 - Canadian Sport History',
        courseCode: '2SH3',
        shortName: 'HISTORY 2SH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974362',
      },
      {
        _id: '6073b72756f56ef638974364',
        courseName:
          'HISTORY 2SS3 - Liberty, Empire and Industry: Britain, 1688-1867',
        courseCode: '2SS3',
        shortName: 'HISTORY 2SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974364',
      },
      {
        _id: '6073b72756f56ef638974366',
        courseName:
          'HISTORY 2T03 - Survey of Canadian History, Beginnings to 1885',
        courseCode: '2T03',
        shortName: 'HISTORY 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974366',
      },
      {
        _id: '6073b72756f56ef638974368',
        courseName:
          'HISTORY 2TT3 - Survey of Canadian History, 1885 to the Present',
        courseCode: '2TT3',
        shortName: 'HISTORY 2TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974368',
      },
      {
        _id: '6073b72756f56ef63897436a',
        courseName: 'HISTORY 2UV3 - American Foreign Relations since 1898',
        courseCode: '2UV3',
        shortName: 'HISTORY 2UV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef63897436a',
      },
      {
        _id: '6073b72756f56ef63897436c',
        courseName: 'HISTORY 2V03 - Re-Making History',
        courseCode: '2V03',
        shortName: 'HISTORY 2V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef63897436c',
      },
      {
        _id: '6073b72756f56ef63897436e',
        courseName:
          'HISTORY 3CG3 - Canadians in a Global Age, 1914 to the Present',
        courseCode: '3CG3',
        shortName: 'HISTORY 3CG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef63897436e',
      },
      {
        _id: '6073b72756f56ef638974370',
        courseName:
          'HISTORY 3CH3 - Catastrophic History: Natural & Technological Disasters',
        courseCode: '3CH3',
        shortName: 'HISTORY 3CH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974370',
      },
      {
        _id: '6073b72756f56ef638974372',
        courseName: 'HISTORY 3CW3 - Canada in a World of Empires, 1492-1919',
        courseCode: '3CW3',
        shortName: 'HISTORY 3CW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974372',
      },
      {
        _id: '6073b72756f56ef638974374',
        courseName: 'HISTORY 3DD3 - Jews and Jesus',
        courseCode: '3DD3',
        shortName: 'HISTORY 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974374',
      },
      {
        _id: '6073b72756f56ef638974376',
        courseName:
          'HISTORY 3DF3 - Art and Politics in Second Empire France Visuality',
        courseCode: '3DF3',
        shortName: 'HISTORY 3DF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974376',
      },
      {
        _id: '6073b72756f56ef638974378',
        courseName: 'ENGLISH 2PC3 - Popular Culture',
        courseCode: '2PC3',
        shortName: 'ENGLISH 2PC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72756f56ef638974378',
      },
      {
        _id: '6073b72856f56ef63897437a',
        courseName: 'HISTORY 3EC3 - Chinese Intellectual Traditions',
        courseCode: '3EC3',
        shortName: 'HISTORY 3EC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef63897437a',
      },
      {
        _id: '6073b72856f56ef63897437c',
        courseName: 'HISTORY 3FF3 - Nazi Germany',
        courseCode: '3FF3',
        shortName: 'HISTORY 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef63897437c',
      },
      {
        _id: '6073b72856f56ef63897437e',
        courseName:
          'HISTORY 3GN3 - Moments in Twentieth Century History Through the Graphic Novel',
        courseCode: '3GN3',
        shortName: 'HISTORY 3GN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef63897437e',
      },
      {
        _id: '6073b72856f56ef638974380',
        courseName: 'HISTORY 3H03 - Italian Renaissance, 1300-1600',
        courseCode: '3H03',
        shortName: 'HISTORY 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef638974380',
      },
      {
        _id: '6073b72856f56ef638974382',
        courseName: 'HISTORY 3HI3 - Advanced Historical Inquiry',
        courseCode: '3HI3',
        shortName: 'HISTORY 3HI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef638974382',
      },
      {
        _id: '6073b72856f56ef638974384',
        courseName: 'HISTORY 3HQ3 - History of Quebec',
        courseCode: '3HQ3',
        shortName: 'HISTORY 3HQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef638974384',
      },
      {
        _id: '6073b72856f56ef638974386',
        courseName:
          'HISTORY 3I03 - The International Relations of the European Powers, 1870-1945',
        courseCode: '3I03',
        shortName: 'HISTORY 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef638974386',
      },
      {
        _id: '6073b72856f56ef638974388',
        courseName: 'HISTORY 3J03 - The United States in the 1960s',
        courseCode: '3J03',
        shortName: 'HISTORY 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef638974388',
      },
      {
        _id: '6073b72856f56ef63897438a',
        courseName:
          'HISTORY 3JJ3 - Crime, Criminal Justice and Punishment in Modern History',
        courseCode: '3JJ3',
        shortName: 'HISTORY 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef63897438a',
      },
      {
        _id: '6073b72856f56ef63897438c',
        courseName: 'HISTORY 3KK3 - The Vietnam War',
        courseCode: '3KK3',
        shortName: 'HISTORY 3KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef63897438c',
      },
      {
        _id: '6073b72856f56ef63897438e',
        courseName:
          'HISTORY 3N03 - Poverty, Privilege and Protest in Canadian History',
        courseCode: '3N03',
        shortName: 'HISTORY 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef63897438e',
      },
      {
        _id: '6073b72856f56ef638974390',
        courseName: 'HISTORY 3RU3 - Early Modern Russia',
        courseCode: '3RU3',
        shortName: 'HISTORY 3RU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72856f56ef638974390',
      },
      {
        _id: '6073b72956f56ef638974392',
        courseName: 'HISTORY 3UA3 - The History of the Future',
        courseCode: '3UA3',
        shortName: 'HISTORY 3UA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef638974392',
      },
      {
        _id: '6073b72956f56ef638974394',
        courseName: 'HISTORY 3W03 - Women in Canada and the U.S. to 1920',
        courseCode: '3W03',
        shortName: 'HISTORY 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef638974394',
      },
      {
        _id: '6073b72956f56ef638974396',
        courseName: 'HISTORY 3WW3 - Women in Canada and the U.S. from 1920',
        courseCode: '3WW3',
        shortName: 'HISTORY 3WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef638974396',
      },
      {
        _id: '6073b72956f56ef638974398',
        courseName: 'HISTORY 3XX3 - Human Rights in History',
        courseCode: '3XX3',
        shortName: 'HISTORY 3XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef638974398',
      },
      {
        _id: '6073b72956f56ef63897439a',
        courseName: 'HISTORY 3YY3 - Britain and the First World War',
        courseCode: '3YY3',
        shortName: 'HISTORY 3YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef63897439a',
      },
      {
        _id: '6073b72956f56ef63897439c',
        courseName: 'HISTORY 3ZZ3 - Judaism in the Modern World',
        courseCode: '3ZZ3',
        shortName: 'HISTORY 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef63897439c',
      },
      {
        _id: '6073b72956f56ef63897439e',
        courseName: 'HISTORY 4AW3 - North Atlantic Crossings, 1750-1940',
        courseCode: '4AW3',
        shortName: 'HISTORY 4AW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef63897439e',
      },
      {
        _id: '6073b72956f56ef6389743a0',
        courseName: 'HISTORY 4CE3 - Early Canadian History',
        courseCode: '4CE3',
        shortName: 'HISTORY 4CE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef6389743a0',
      },
      {
        _id: '6073b72956f56ef6389743a2',
        courseName: 'HISTORY 4CM3 - Modern Canadian History',
        courseCode: '4CM3',
        shortName: 'HISTORY 4CM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef6389743a2',
      },
      {
        _id: '6073b72956f56ef6389743a4',
        courseName:
          'HISTORY 4CZ3 - Advanced Research in Early Canadian History',
        courseCode: '4CZ3',
        shortName: 'HISTORY 4CZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef6389743a4',
      },
      {
        _id: '6073b72956f56ef6389743a6',
        courseName: 'HISTORY 4E03 - Medieval People',
        courseCode: '4E03',
        shortName: 'HISTORY 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef6389743a6',
      },
      {
        _id: '6073b72956f56ef6389743a8',
        courseName: 'HISTORY 4FF3 - History of Health and Medicine',
        courseCode: '4FF3',
        shortName: 'HISTORY 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72956f56ef6389743a8',
      },
      {
        _id: '6073b72a56f56ef6389743aa',
        courseName: 'HISTORY 4G03 - Nation and Genocide in the Modern World',
        courseCode: '4G03',
        shortName: 'HISTORY 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743aa',
      },
      {
        _id: '6073b72a56f56ef6389743ac',
        courseName: 'HISTORY 4H03 - The Making of Modern China',
        courseCode: '4H03',
        shortName: 'HISTORY 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743ac',
      },
      {
        _id: '6073b72a56f56ef6389743ae',
        courseName: 'HISTORY 4HH3 - Chinaâ€™s Great Cultural Revolution',
        courseCode: '4HH3',
        shortName: 'HISTORY 4HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743ae',
      },
      {
        _id: '6073b72a56f56ef6389743b0',
        courseName: 'HISTORY 4HP3 - The History Practicum',
        courseCode: '4HP3',
        shortName: 'HISTORY 4HP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743b0',
      },
      {
        _id: '6073b72a56f56ef6389743b2',
        courseName:
          'HISTORY 4I03 - Women and Social Movements in the 19th- and 20th- Century United States',
        courseCode: '4I03',
        shortName: 'HISTORY 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743b2',
      },
      {
        _id: '6073b72a56f56ef6389743b4',
        courseName: 'HISTORY 4JJ3 - U.S. Foreign Relations',
        courseCode: '4JJ3',
        shortName: 'HISTORY 4JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743b4',
      },
      {
        _id: '6073b72a56f56ef6389743b6',
        courseName:
          'HISTORY 4K03 - Environment and Environmentalism in Modern North America',
        courseCode: '4K03',
        shortName: 'HISTORY 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743b6',
      },
      {
        _id: '6073b72a56f56ef6389743b8',
        courseName:
          'HISTORY 4KK3 - Research on the British Atlantic, 1750-1850',
        courseCode: '4KK3',
        shortName: 'HISTORY 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743b8',
      },
      {
        _id: '6073b72a56f56ef6389743ba',
        courseName:
          'HISTORY 4LJ3 - Law, Order and Justice in Canada, 1800-2000',
        courseCode: '4LJ3',
        shortName: 'HISTORY 4LJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743ba',
      },
      {
        _id: '6073b72a56f56ef6389743bc',
        courseName: 'HISTORY 4LP3 - The Cultural History of Paris, 1789-1914',
        courseCode: '4LP3',
        shortName: 'HISTORY 4LP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743bc',
      },
      {
        _id: '6073b72a56f56ef6389743be',
        courseName:
          'HISTORY 4MM3 - White Supremacists and Human Rights Activists in Modern Canadian History',
        courseCode: '4MM3',
        shortName: 'HISTORY 4MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743be',
      },
      {
        _id: '6073b72a56f56ef6389743c0',
        courseName: 'HISTORY 4NN3 - Utopia and Its Histories',
        courseCode: '4NN3',
        shortName: 'HISTORY 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72a56f56ef6389743c0',
      },
      {
        _id: '6073b72b56f56ef6389743c2',
        courseName: 'HISTORY 4P03 - Contemporary Europe',
        courseCode: '4P03',
        shortName: 'HISTORY 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743c2',
      },
      {
        _id: '6073b72b56f56ef6389743c4',
        courseName: 'HISTORY 4PP3 - Divided Germany',
        courseCode: '4PP3',
        shortName: 'HISTORY 4PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743c4',
      },
      {
        _id: '6073b72b56f56ef6389743c6',
        courseName: 'HISTORY 4QQ3 - The Soviet Experience',
        courseCode: '4QQ3',
        shortName: 'HISTORY 4QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743c6',
      },
      {
        _id: '6073b72b56f56ef6389743c8',
        courseName:
          'HISTORY 4QR3 - Quantitative Research on Major Topics in History',
        courseCode: '4QR3',
        shortName: 'HISTORY 4QR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743c8',
      },
      {
        _id: '6073b72b56f56ef6389743ca',
        courseName: 'HISTORY 4RP3 - Independent Research Project',
        courseCode: '4RP3',
        shortName: 'HISTORY 4RP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743ca',
      },
      {
        _id: '6073b72b56f56ef6389743cc',
        courseName: 'HISTORY 4RP6 A/B - Advanced Independent Research',
        courseCode: '4RP6',
        shortName: 'HISTORY 4RP6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743cc',
      },
      {
        _id: '6073b72b56f56ef6389743ce',
        courseName: 'HISTORY 4RR3 - Truth and Reconciliation After Atrocity',
        courseCode: '4RR3',
        shortName: 'HISTORY 4RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743ce',
      },
      {
        _id: '6073b72b56f56ef6389743d0',
        courseName: 'HISTORY 4S03 - The German Reformation',
        courseCode: '4S03',
        shortName: 'HISTORY 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743d0',
      },
      {
        _id: '6073b72b56f56ef6389743d2',
        courseName: 'HISTORY 4SC3 - Sport and Culture',
        courseCode: '4SC3',
        shortName: 'HISTORY 4SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743d2',
      },
      {
        _id: '6073b72b56f56ef6389743d4',
        courseName: 'HISTORY 4SS3 - Early Modern France, 1450-1789',
        courseCode: '4SS3',
        shortName: 'HISTORY 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743d4',
      },
      {
        _id: '6073b72b56f56ef6389743d6',
        courseName: 'HISTORY 4YY6 A/B - The World Wars',
        courseCode: '4YY6',
        shortName: 'HISTORY 4YY6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72b56f56ef6389743d6',
      },
      {
        _id: '6073b72b56f56ef6389743d8',
        courseName: 'HLTHAGE 1AA3 - Introduction to Health and Society',
        courseCode: '1AA3',
        shortName: 'HLTHAGE 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '6073b72b56f56ef6389743d8',
      },
      {
        _id: '6073b72c56f56ef6389743da',
        courseName: 'HLTHAGE 1BB3 - Introduction to Aging and Society',
        courseCode: '1BB3',
        shortName: 'HLTHAGE 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743da',
      },
      {
        _id: '6073b72c56f56ef6389743dc',
        courseName: 'HLTHAGE 1CC3 - Introduction to Mental Health and Illness',
        courseCode: '1CC3',
        shortName: 'HLTHAGE 1CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743dc',
      },
      {
        _id: '6073b72c56f56ef6389743de',
        courseName:
          'HLTHAGE 1ZZ3 - Inquiry: Introduction to Health and Society',
        courseCode: '1ZZ3',
        shortName: 'HLTHAGE 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743de',
      },
      {
        _id: '6073b72c56f56ef6389743e0',
        courseName: 'HLTHAGE 2A03 - Research Methods in Health and in Aging I',
        courseCode: '2A03',
        shortName: 'HLTHAGE 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743e0',
      },
      {
        _id: '6073b72c56f56ef6389743e2',
        courseName: 'HLTHAGE 2AN3 - The Anthropology of Food and Nutrition',
        courseCode: '2AN3',
        shortName: 'HLTHAGE 2AN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743e2',
      },
      {
        _id: '6073b72c56f56ef6389743e4',
        courseName: 'HLTHAGE 2B03 - Social Identity, Health and Illness',
        courseCode: '2B03',
        shortName: 'HLTHAGE 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743e4',
      },
      {
        _id: '6073b72c56f56ef6389743e6',
        courseName: 'HLTHAGE 2BB3 - Perspectives in Health, Aging and Society',
        courseCode: '2BB3',
        shortName: 'HLTHAGE 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743e6',
      },
      {
        _id: '6073b72c56f56ef6389743e8',
        courseName:
          'HLTHAGE 2C03 - Health Economics and its Application to Health Policy',
        courseCode: '2C03',
        shortName: 'HLTHAGE 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743e8',
      },
      {
        _id: '6073b72c56f56ef6389743ea',
        courseName: 'HLTHAGE 2D03 - Continuum of Care',
        courseCode: '2D03',
        shortName: 'HLTHAGE 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743ea',
      },
      {
        _id: '6073b72c56f56ef6389743ec',
        courseName: 'HLTHAGE 2F03 - Aging and Health Care Systems',
        courseCode: '2F03',
        shortName: 'HLTHAGE 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743ec',
      },
      {
        _id: '6073b72c56f56ef6389743ee',
        courseName: 'HLTHAGE 2GG3 - Mental Health and Society',
        courseCode: '2GG3',
        shortName: 'HLTHAGE 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743ee',
      },
      {
        _id: '6073b72c56f56ef6389743f0',
        courseName: 'HLTHAGE 2HI3 - Geographies of Death & Disease',
        courseCode: '2HI3',
        shortName: 'HLTHAGE 2HI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72c56f56ef6389743f0',
      },
      {
        _id: '6073b72d56f56ef6389743f2',
        courseName: 'HLTHAGE 2J03 - Selected Topics in Aging and Society',
        courseCode: '2J03',
        shortName: 'HLTHAGE 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743f2',
      },
      {
        _id: '6073b72d56f56ef6389743f4',
        courseName: 'HLTHAGE 2K03 - Selected Topics in Health and Society',
        courseCode: '2K03',
        shortName: 'HLTHAGE 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743f4',
      },
      {
        _id: '6073b72d56f56ef6389743f6',
        courseName:
          'HLTHAGE 2L03 - Drugs, Sex and Alcohol: Society and its Addictions',
        courseCode: '2L03',
        shortName: 'HLTHAGE 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743f6',
      },
      {
        _id: '6073b72d56f56ef6389743f8',
        courseName: 'HLTHAGE 2M03 - Aging in Modern (and Post-Modern) Families',
        courseCode: '2M03',
        shortName: 'HLTHAGE 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743f8',
      },
      {
        _id: '6073b72d56f56ef6389743fa',
        courseName: 'HLTHAGE 3AA3 - State, Civil Society and Health',
        courseCode: '3AA3',
        shortName: 'HLTHAGE 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743fa',
      },
      {
        _id: '6073b72d56f56ef6389743fc',
        courseName: 'HLTHAGE 3B03 - Advanced Research Methods',
        courseCode: '3B03',
        shortName: 'HLTHAGE 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743fc',
      },
      {
        _id: '6073b72d56f56ef6389743fe',
        courseName: 'HLTHAGE 3BB3 - Field Experience',
        courseCode: '3BB3',
        shortName: 'HLTHAGE 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef6389743fe',
      },
      {
        _id: '6073b72d56f56ef638974400',
        courseName:
          'HLTHAGE 3CC3 - Health and Environment: Anthropological Approaches',
        courseCode: '3CC3',
        shortName: 'HLTHAGE 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef638974400',
      },
      {
        _id: '6073b72d56f56ef638974402',
        courseName:
          'HLTHAGE 3D03 - Perspectives on Disability, Chronic Illness and Aging',
        courseCode: '3D03',
        shortName: 'HLTHAGE 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef638974402',
      },
      {
        _id: '6073b72d56f56ef638974404',
        courseName: 'HLTHAGE 3DD3 - Work: Dangerous to your Health?',
        courseCode: '3DD3',
        shortName: 'HLTHAGE 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef638974404',
      },
      {
        _id: '6073b72d56f56ef638974406',
        courseName: 'HLTHAGE 3E03 - Ethical Issues in Health and Aging',
        courseCode: '3E03',
        shortName: 'HLTHAGE 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef638974406',
      },
      {
        _id: '6073b72d56f56ef638974408',
        courseName:
          'HLTHAGE 3EE3 - The Practice of Everyday Life: Observations and Inquiry',
        courseCode: '3EE3',
        shortName: 'HLTHAGE 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72d56f56ef638974408',
      },
      {
        _id: '6073b72e56f56ef63897440a',
        courseName: 'HLTHAGE 3G03 - Community Based Research',
        courseCode: '3G03',
        shortName: 'HLTHAGE 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef63897440a',
      },
      {
        _id: '6073b72e56f56ef63897440c',
        courseName: 'HLTHAGE 3HP3 - Population Growth and Aging',
        courseCode: '3HP3',
        shortName: 'HLTHAGE 3HP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef63897440c',
      },
      {
        _id: '6073b72e56f56ef63897440e',
        courseName:
          'HLTHAGE 3I03 - Independent Study in Health, Aging and Society',
        courseCode: '3I03',
        shortName: 'HLTHAGE 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef63897440e',
      },
      {
        _id: '6073b72e56f56ef638974410',
        courseName:
          'HLTHAGE 3K03 - Social Determinants of Population Health in Canada',
        courseCode: '3K03',
        shortName: 'HLTHAGE 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef638974410',
      },
      {
        _id: '6073b72e56f56ef638974412',
        courseName: 'HLTHAGE 3L03 - Embodied Aging',
        courseCode: '3L03',
        shortName: 'HLTHAGE 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef638974412',
      },
      {
        _id: '6073b72e56f56ef638974414',
        courseName: 'HLTHAGE 3M03 - Approaches to Mental Health and Resilience',
        courseCode: '3M03',
        shortName: 'HLTHAGE 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef638974414',
      },
      {
        _id: '6073b72e56f56ef638974416',
        courseName: 'HLTHAGE 3N03 - Aging and Mental Health',
        courseCode: '3N03',
        shortName: 'HLTHAGE 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef638974416',
      },
      {
        _id: '6073b72e56f56ef638974418',
        courseName: 'HLTHAGE 3Q03 - Selected Topics in Health and Aging',
        courseCode: '3Q03',
        shortName: 'HLTHAGE 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef638974418',
      },
      {
        _id: '6073b72e56f56ef63897441a',
        courseName: 'HLTHAGE 3R03 - Health Inequalities',
        courseCode: '3R03',
        shortName: 'HLTHAGE 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef63897441a',
      },
      {
        _id: '6073b72e56f56ef63897441c',
        courseName: 'HLTHAGE 3S03 - Global Health and Environment Policy',
        courseCode: '3S03',
        shortName: 'HLTHAGE 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef63897441c',
      },
      {
        _id: '6073b72e56f56ef63897441e',
        courseName: 'HLTHAGE 3T03 - Health and Incarceration',
        courseCode: '3T03',
        shortName: 'HLTHAGE 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef63897441e',
      },
      {
        _id: '6073b72e56f56ef638974420',
        courseName: 'HLTHAGE 3YY3 - Indigenous Community Health and Wellbeing',
        courseCode: '3YY3',
        shortName: 'HLTHAGE 3YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72e56f56ef638974420',
      },
      {
        _id: '6073b72f56f56ef638974422',
        courseName: 'HLTHAGE 4B03 - Death and Dying in Later Life',
        courseCode: '4B03',
        shortName: 'HLTHAGE 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974422',
      },
      {
        _id: '6073b72f56f56ef638974424',
        courseName:
          'HLTHAGE 4C03 - Representations of Health and Illness Across the Lifecourse',
        courseCode: '4C03',
        shortName: 'HLTHAGE 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974424',
      },
      {
        _id: '6073b72f56f56ef638974426',
        courseName: 'HLTHAGE 4D03 - Health, Culture and Diversity',
        courseCode: '4D03',
        shortName: 'HLTHAGE 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974426',
      },
      {
        _id: '6073b72f56f56ef638974428',
        courseName: 'HLTHAGE 4F03 - Selected Issues in Health and Society',
        courseCode: '4F03',
        shortName: 'HLTHAGE 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974428',
      },
      {
        _id: '6073b72f56f56ef63897442a',
        courseName: 'HLTHAGE 4G03 - Global Health',
        courseCode: '4G03',
        shortName: 'HLTHAGE 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef63897442a',
      },
      {
        _id: '6073b72f56f56ef63897442c',
        courseName: 'HLTHAGE 4H03 - History and Culture of Aging',
        courseCode: '4H03',
        shortName: 'HLTHAGE 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef63897442c',
      },
      {
        _id: '6073b72f56f56ef63897442e',
        courseName: 'HLTHAGE 4I03 - Aging and Health',
        courseCode: '4I03',
        shortName: 'HLTHAGE 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef63897442e',
      },
      {
        _id: '6073b72f56f56ef638974430',
        courseName: 'HLTHAGE 4J03 - Narratives of Illness',
        courseCode: '4J03',
        shortName: 'HLTHAGE 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974430',
      },
      {
        _id: '6073b72f56f56ef638974432',
        courseName: 'HLTHAGE 4L03 - Social Policy and Aging',
        courseCode: '4L03',
        shortName: 'HLTHAGE 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974432',
      },
      {
        _id: '6073b72f56f56ef638974434',
        courseName: 'HLTHAGE 4M03 - Environment and Health',
        courseCode: '4M03',
        shortName: 'HLTHAGE 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974434',
      },
      {
        _id: '6073b72f56f56ef638974436',
        courseName: 'HLTHAGE 4N03 - Aging and Wellbeing',
        courseCode: '4N03',
        shortName: 'HLTHAGE 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974436',
      },
      {
        _id: '6073b72f56f56ef638974438',
        courseName: 'HLTHAGE 4O03 - Soundscapes of Wellbeing in Popular Music',
        courseCode: '4O03',
        shortName: 'HLTHAGE 4O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b72f56f56ef638974438',
      },
      {
        _id: '6073b73056f56ef63897443a',
        courseName: 'HLTHAGE 4P03 - Leisure and Recreation in Later Life',
        courseCode: '4P03',
        shortName: 'HLTHAGE 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef63897443a',
      },
      {
        _id: '6073b73056f56ef63897443c',
        courseName: 'HLTHAGE 4Q03 - Representations of Mental Illness',
        courseCode: '4Q03',
        shortName: 'HLTHAGE 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef63897443c',
      },
      {
        _id: '6073b73056f56ef63897443e',
        courseName:
          'HLTHAGE 4R03 - Beyond the Social: Determinants of Indigenous Peoples Health',
        courseCode: '4R03',
        shortName: 'HLTHAGE 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef63897443e',
      },
      {
        _id: '6073b73056f56ef638974440',
        courseName: 'HLTHAGE 4S03 - Health and the Unfairly Structured City',
        courseCode: '4S03',
        shortName: 'HLTHAGE 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef638974440',
      },
      {
        _id: '6073b73056f56ef638974442',
        courseName: 'HLTHAGE 4T03 - Gender, Sex and Health',
        courseCode: '4T03',
        shortName: 'HLTHAGE 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef638974442',
      },
      {
        _id: '6073b73056f56ef638974444',
        courseName:
          'HLTHAGE 4U03 - Professions and Occupations in Health and Aging',
        courseCode: '4U03',
        shortName: 'HLTHAGE 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef638974444',
      },
      {
        _id: '6073b73056f56ef638974446',
        courseName:
          'HLTHAGE 4V03 - Aging and Humour: The Good, the Bad and the Funny',
        courseCode: '4V03',
        shortName: 'HLTHAGE 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef638974446',
      },
      {
        _id: '6073b73056f56ef638974448',
        courseName: 'HLTHAGE 4W03 - Selected Issues in Aging and Society',
        courseCode: '4W03',
        shortName: 'HLTHAGE 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef638974448',
      },
      {
        _id: '6073b73056f56ef63897444a',
        courseName: 'HLTHAGE 4Z06 A/B - Health, Aging and Society Thesis',
        courseCode: '4Z06',
        shortName: 'HLTHAGE 4Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef63897444a',
      },
      {
        _id: '6073b73056f56ef63897444c',
        courseName:
          'HTHSCI 1A00 - Competencies in Animal Care and Research: Orientation',
        courseCode: '1A00',
        shortName: 'HTHSCI 1A00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef63897444c',
      },
      {
        _id: '6073b73056f56ef63897444e',
        courseName:
          'HTHSCI 1B00 - Competencies in Animal Care and Research: Methodologies',
        courseCode: '1B00',
        shortName: 'HTHSCI 1B00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef63897444e',
      },
      {
        _id: '6073b73056f56ef638974450',
        courseName:
          'HTHSCI 1C00 - Competencies in Animal Care and Research: Completion of Ethical Research',
        courseCode: '1C00',
        shortName: 'HTHSCI 1C00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73056f56ef638974450',
      },
      {
        _id: '6073b73156f56ef638974452',
        courseName: 'HTHSCI 1DT3 - Discover Immunology Today',
        courseCode: '1DT3',
        shortName: 'HTHSCI 1DT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974452',
      },
      {
        _id: '6073b73156f56ef638974454',
        courseName: 'HTHSCI 1E06 A/B - Inquiry I: Introduction',
        courseCode: '1E06',
        shortName: 'HTHSCI 1E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974454',
      },
      {
        _id: '6073b73156f56ef638974456',
        courseName: 'HTHSCI 1G02 - Interdisciplinary Problem-Solving in Health',
        courseCode: '1G02',
        shortName: 'HTHSCI 1G02',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974456',
      },
      {
        _id: '6073b73156f56ef638974458',
        courseName: 'HTHSCI 1H06 A/B - Human Anatomy and Physiology I',
        courseCode: '1H06',
        shortName: 'HTHSCI 1H06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974458',
      },
      {
        _id: '6073b73156f56ef63897445a',
        courseName: 'HTHSCI 1I06 A/B - Cellular and Molecular Biology',
        courseCode: '1I06',
        shortName: 'HTHSCI 1I06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef63897445a',
      },
      {
        _id: '6073b73156f56ef63897445c',
        courseName: 'HTHSCI 1K03 - Health Sciences in the Media',
        courseCode: '1K03',
        shortName: 'HTHSCI 1K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef63897445c',
      },
      {
        _id: '6073b73156f56ef63897445e',
        courseName: 'HTHSCI 1M03 - Foundations of Data Science',
        courseCode: '1M03',
        shortName: 'HTHSCI 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef63897445e',
      },
      {
        _id: '6073b73156f56ef638974460',
        courseName: 'HTHSCI 1X01 A/B - Praxis Pathways 1',
        courseCode: '1X01',
        shortName: 'HTHSCI 1X01 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974460',
      },
      {
        _id: '6073b73156f56ef638974462',
        courseName: 'HTHSCI 2AE3 - Artistic Explorations of Community Issues',
        courseCode: '2AE3',
        shortName: 'HTHSCI 2AE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974462',
      },
      {
        _id: '6073b73156f56ef638974464',
        courseName: 'HTHSCI 2CH3 A/B - CHS Learning Modules',
        courseCode: '2CH3',
        shortName: 'HTHSCI 2CH3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974464',
      },
      {
        _id: '6073b73156f56ef638974466',
        courseName: 'HTHSCI 2CH6 A/B - CHS Inquiry Fundamentals',
        courseCode: '2CH6',
        shortName: 'HTHSCI 2CH6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974466',
      },
      {
        _id: '6073b73156f56ef638974468',
        courseName:
          'HTHSCI 2D06 A/B - Inquiry II: Introduction and Biochemistry',
        courseCode: '2D06',
        shortName: 'HTHSCI 2D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73156f56ef638974468',
      },
      {
        _id: '6073b73256f56ef63897446a',
        courseName:
          'HTHSCI 2DS3 - Global Health and the Complexities of Disease',
        courseCode: '2DS3',
        shortName: 'HTHSCI 2DS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef63897446a',
      },
      {
        _id: '6073b73256f56ef63897446c',
        courseName: 'HTHSCI 2E03 - Inquiry II: Biochemistry',
        courseCode: '2E03',
        shortName: 'HTHSCI 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef63897446c',
      },
      {
        _id: '6073b73256f56ef63897446e',
        courseName: 'HTHSCI 2F03 - Human Physiology and Anatomy I',
        courseCode: '2F03',
        shortName: 'HTHSCI 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef63897446e',
      },
      {
        _id: '6073b73256f56ef638974470',
        courseName: 'HTHSCI 2FF3 - Human Physiology and Anatomy II',
        courseCode: '2FF3',
        shortName: 'HTHSCI 2FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef638974470',
      },
      {
        _id: '6073b73256f56ef638974472',
        courseName: 'HTHSCI 2G03 - Statistics & Epidemiology 1',
        courseCode: '2G03',
        shortName: 'HTHSCI 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef638974472',
      },
      {
        _id: '6073b73256f56ef638974474',
        courseName: 'HTHSCI 2GG3 - Statistics & Epidemiology 2',
        courseCode: '2GG3',
        shortName: 'HTHSCI 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef638974474',
      },
      {
        _id: '6073b73256f56ef638974476',
        courseName: 'HTHSCI 2K03 - Cell Biology',
        courseCode: '2K03',
        shortName: 'HTHSCI 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef638974476',
      },
      {
        _id: '6073b73256f56ef638974478',
        courseName: 'HTHSCI 2L03 - Anatomy and Physiology I: Communication',
        courseCode: '2L03',
        shortName: 'HTHSCI 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef638974478',
      },
      {
        _id: '6073b73256f56ef63897447a',
        courseName: 'HTHSCI 2LL3 - Anatomy and Physiology II: Homeostasis',
        courseCode: '2LL3',
        shortName: 'HTHSCI 2LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef63897447a',
      },
      {
        _id: '6073b73256f56ef63897447c',
        courseName: 'HTHSCI 2T03 - Sex, Gender, & Health',
        courseCode: '2T03',
        shortName: 'HTHSCI 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef63897447c',
      },
      {
        _id: '6073b73256f56ef63897447e',
        courseName: 'HTHSCI 2X03 A/B - Praxis Pathways 2',
        courseCode: '2X03',
        shortName: 'HTHSCI 2X03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef63897447e',
      },
      {
        _id: '6073b73256f56ef638974480',
        courseName: 'HTHSCI 3AH3 - Indigenous Health',
        courseCode: '3AH3',
        shortName: 'HTHSCI 3AH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73256f56ef638974480',
      },
      {
        _id: '6073b73356f56ef638974482',
        courseName: 'HTHSCI 3BA3 - Symptomatology',
        courseCode: '3BA3',
        shortName: 'HTHSCI 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974482',
      },
      {
        _id: '6073b73356f56ef638974484',
        courseName: 'HTHSCI 3BE4 A/B S - Nursing Concepts in Continence Care',
        courseCode: '3BE4',
        shortName: 'HTHSCI 3BE4 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974484',
      },
      {
        _id: '6073b73356f56ef638974486',
        courseName: 'HTHSCI 3BM3 - Inquiry Project in Biomedical Sciences',
        courseCode: '3BM3',
        shortName: 'HTHSCI 3BM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974486',
      },
      {
        _id: '6073b73356f56ef638974488',
        courseName:
          'HTHSCI 3BM6 A/B S - Research Project in Biomedical Sciences',
        courseCode: '3BM6',
        shortName: 'HTHSCI 3BM6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974488',
      },
      {
        _id: '6073b73356f56ef63897448a',
        courseName:
          'HTHSCI 3C04 - Research Appraisal and Utilization in Evidence Informed Decision Making',
        courseCode: '3C04',
        shortName: 'HTHSCI 3C04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef63897448a',
      },
      {
        _id: '6073b73356f56ef63897448c',
        courseName: 'HTHSCI 3CC3 - Theatre for Development',
        courseCode: '3CC3',
        shortName: 'HTHSCI 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef63897448c',
      },
      {
        _id: '6073b73356f56ef63897448e',
        courseName: 'HTHSCI 3CH3 - CHS Inquiry Intermediate',
        courseCode: '3CH3',
        shortName: 'HTHSCI 3CH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef63897448e',
      },
      {
        _id: '6073b73356f56ef638974490',
        courseName: 'HTHSCI 3CH6 A/B S - CHS Research Practicum',
        courseCode: '3CH6',
        shortName: 'HTHSCI 3CH6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974490',
      },
      {
        _id: '6073b73356f56ef638974492',
        courseName: 'HTHSCI 3CH9 A/B - CHS Inquiry Intermediate',
        courseCode: '3CH9',
        shortName: 'HTHSCI 3CH9 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974492',
      },
      {
        _id: '6073b73356f56ef638974494',
        courseName:
          'HTHSCI 3DD6 A/B - Engaging the City: An Introduction to Community-Based Research in Hamilton',
        courseCode: '3DD6',
        shortName: 'HTHSCI 3DD6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974494',
      },
      {
        _id: '6073b73356f56ef638974496',
        courseName:
          'HTHSCI 3E03 - Inquiry III: Advanced Inquiry in Health Sciences',
        courseCode: '3E03',
        shortName: 'HTHSCI 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974496',
      },
      {
        _id: '6073b73356f56ef638974498',
        courseName: 'HTHSCI 3EE3 - Biomedical Graphics',
        courseCode: '3EE3',
        shortName: 'HTHSCI 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73356f56ef638974498',
      },
      {
        _id: '6073b73456f56ef63897449a',
        courseName: 'HTHSCI 3FC3 - Science of Fictional Characters',
        courseCode: '3FC3',
        shortName: 'HTHSCI 3FC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef63897449a',
      },
      {
        _id: '6073b73456f56ef63897449c',
        courseName:
          'HTHSCI 3G03 - Critical Appraisal of the Medical Literature',
        courseCode: '3G03',
        shortName: 'HTHSCI 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef63897449c',
      },
      {
        _id: '6073b73456f56ef63897449e',
        courseName: 'HTHSCI 3GG3 - Health Systems and Health Policy',
        courseCode: '3GG3',
        shortName: 'HTHSCI 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef63897449e',
      },
      {
        _id: '6073b73456f56ef6389744a0',
        courseName: 'HTHSCI 3H03 A/B S - Inquiry Project',
        courseCode: '3H03',
        shortName: 'HTHSCI 3H03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744a0',
      },
      {
        _id: '6073b73456f56ef6389744a2',
        courseName: 'HTHSCI 3H06 A/B S - Inquiry Project',
        courseCode: '3H06',
        shortName: 'HTHSCI 3H06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744a2',
      },
      {
        _id: '6073b73456f56ef6389744a4',
        courseName: 'HTHSCI 3HH3 - Deceptions in Decision Making',
        courseCode: '3HH3',
        shortName: 'HTHSCI 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744a4',
      },
      {
        _id: '6073b73456f56ef6389744a6',
        courseName: 'HTHSCI 3HL3 - Health Law: Current and Emerging Issues',
        courseCode: '3HL3',
        shortName: 'HTHSCI 3HL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744a6',
      },
      {
        _id: '6073b73456f56ef6389744a8',
        courseName: 'HTHSCI 3I03 - Introductory Immunology',
        courseCode: '3I03',
        shortName: 'HTHSCI 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744a8',
      },
      {
        _id: '6073b73456f56ef6389744aa',
        courseName: 'HTHSCI 3K03 - Introductory Virology',
        courseCode: '3K03',
        shortName: 'HTHSCI 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744aa',
      },
      {
        _id: '6073b73456f56ef6389744ac',
        courseName: 'HTHSCI 3L03 - Introduction to Bioethics',
        courseCode: '3L03',
        shortName: 'HTHSCI 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744ac',
      },
      {
        _id: '6073b73456f56ef6389744ae',
        courseName: 'HTHSCI 3MH3 - Critical Examination of Mental Health',
        courseCode: '3MH3',
        shortName: 'HTHSCI 3MH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744ae',
      },
      {
        _id: '6073b73456f56ef6389744b0',
        courseName: 'HTHSCI 3MU3 - Music, Health, & the Community',
        courseCode: '3MU3',
        shortName: 'HTHSCI 3MU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73456f56ef6389744b0',
      },
      {
        _id: '6073b73556f56ef6389744b2',
        courseName: 'HTHSCI 3N03 - Written Communication in Health Sciences I',
        courseCode: '3N03',
        shortName: 'HTHSCI 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744b2',
      },
      {
        _id: '6073b73556f56ef6389744b4',
        courseName: 'HTHSCI 3PA2 A/B - Integrated Pathophysiology for Nursing',
        courseCode: '3PA2',
        shortName: 'HTHSCI 3PA2 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744b4',
      },
      {
        _id: '6073b73556f56ef6389744b6',
        courseName: 'HTHSCI 3QA3 - Qualitative Research Methods in Health',
        courseCode: '3QA3',
        shortName: 'HTHSCI 3QA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744b6',
      },
      {
        _id: '6073b73556f56ef6389744b8',
        courseName:
          'HTHSCI 3RS3 - Exploring the Foundations of Rehabilitation Sciences',
        courseCode: '3RS3',
        shortName: 'HTHSCI 3RS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744b8',
      },
      {
        _id: '6073b73556f56ef6389744ba',
        courseName: 'HTHSCI 3S03 - Communication Skills',
        courseCode: '3S03',
        shortName: 'HTHSCI 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744ba',
      },
      {
        _id: '6073b73556f56ef6389744bc',
        courseName: 'HTHSCI 3SB3 - Superbugs: Bacterial Antibiotic Resistance',
        courseCode: '3SB3',
        shortName: 'HTHSCI 3SB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744bc',
      },
      {
        _id: '6073b73556f56ef6389744be',
        courseName: 'HTHSCI 3T03 - Inquiry into Work, Self and Purpose',
        courseCode: '3T03',
        shortName: 'HTHSCI 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744be',
      },
      {
        _id: '6073b73556f56ef6389744c0',
        courseName: 'HTHSCI 3U03 - Medical Genetics',
        courseCode: '3U03',
        shortName: 'HTHSCI 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744c0',
      },
      {
        _id: '6073b73556f56ef6389744c2',
        courseName: 'HTHSCI 3V03 - Research and Experimental Design',
        courseCode: '3V03',
        shortName: 'HTHSCI 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744c2',
      },
      {
        _id: '6073b73556f56ef6389744c4',
        courseName: 'HTHSCI 3X00 A/B - Praxis Pathways 3',
        courseCode: '3X00',
        shortName: 'HTHSCI 3X00 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744c4',
      },
      {
        _id: '6073b73556f56ef6389744c6',
        courseName:
          'HTHSCI 3X03 - Pain: Perceptions, Mechanisms and Management',
        courseCode: '3X03',
        shortName: 'HTHSCI 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744c6',
      },
      {
        _id: '6073b73556f56ef6389744c8',
        courseName: 'HTHSCI 4A09 A/B S - Thesis',
        courseCode: '4A09',
        shortName: 'HTHSCI 4A09 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73556f56ef6389744c8',
      },
      {
        _id: '6073b73656f56ef6389744ca',
        courseName: 'HTHSCI 4A12 A/B S - Thesis',
        courseCode: '4A12',
        shortName: 'HTHSCI 4A12 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744ca',
      },
      {
        _id: '6073b73656f56ef6389744cc',
        courseName: 'HTHSCI 4A15 A/B S - Thesis',
        courseCode: '4A15',
        shortName: 'HTHSCI 4A15 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744cc',
      },
      {
        _id: '6073b73656f56ef6389744ce',
        courseName: 'HTHSCI 4AC3 - Advanced Communication Skills',
        courseCode: '4AC3',
        shortName: 'HTHSCI 4AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744ce',
      },
      {
        _id: '6073b73656f56ef6389744d0',
        courseName: 'HTHSCI 4AL3 - Model Systems',
        courseCode: '4AL3',
        shortName: 'HTHSCI 4AL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744d0',
      },
      {
        _id: '6073b73656f56ef6389744d2',
        courseName: 'HTHSCI 4B06 A/B S - Senior Projects',
        courseCode: '4B06',
        shortName: 'HTHSCI 4B06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744d2',
      },
      {
        _id: '6073b73656f56ef6389744d4',
        courseName: 'HTHSCI 4BB3 - Neuroimmunology',
        courseCode: '4BB3',
        shortName: 'HTHSCI 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744d4',
      },
      {
        _id: '6073b73656f56ef6389744d6',
        courseName: 'HTHSCI 4BL3 - Peer Tutoring in Design Thinking',
        courseCode: '4BL3',
        shortName: 'HTHSCI 4BL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744d6',
      },
      {
        _id: '6073b73656f56ef6389744d8',
        courseName: 'HTHSCI 4C06 A/B - Senior Project in Child Health',
        courseCode: '4C06',
        shortName: 'HTHSCI 4C06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744d8',
      },
      {
        _id: '6073b73656f56ef6389744da',
        courseName: 'HTHSCI 4C09 A/B - Thesis in Child Health',
        courseCode: '4C09',
        shortName: 'HTHSCI 4C09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744da',
      },
      {
        _id: '6073b73656f56ef6389744dc',
        courseName: 'HTHSCI 4C12 A/B S - Thesis in Child Health',
        courseCode: '4C12',
        shortName: 'HTHSCI 4C12 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744dc',
      },
      {
        _id: '6073b73656f56ef6389744de',
        courseName: 'HTHSCI 4C15 A/B - Thesis in Child Health',
        courseCode: '4C15',
        shortName: 'HTHSCI 4C15 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73656f56ef6389744de',
      },
      {
        _id: '6073b73756f56ef6389744e0',
        courseName: 'HTHSCI 4CH3 A/B - CHS Education Practicum',
        courseCode: '4CH3',
        shortName: 'HTHSCI 4CH3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744e0',
      },
      {
        _id: '6073b73756f56ef6389744e2',
        courseName: 'HTHSCI 4CH6 A/B - CHS Inquiry Advanced',
        courseCode: '4CH6',
        shortName: 'HTHSCI 4CH6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744e2',
      },
      {
        _id: '6073b73756f56ef6389744e4',
        courseName: 'HTHSCI 4CU3 - Cultural Competency in Health Sciences',
        courseCode: '4CU3',
        shortName: 'HTHSCI 4CU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744e4',
      },
      {
        _id: '6073b73756f56ef6389744e6',
        courseName: 'HTHSCI 4D03 - Special Topics in Health Sciences',
        courseCode: '4D03',
        shortName: 'HTHSCI 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744e6',
      },
      {
        _id: '6073b73756f56ef6389744e8',
        courseName: 'HTHSCI 4D06 A/B - Senior Project in Engaging the City',
        courseCode: '4D06',
        shortName: 'HTHSCI 4D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744e8',
      },
      {
        _id: '6073b73756f56ef6389744ea',
        courseName: 'HTHSCI 4D09 A/B - Thesis in Engaging the City',
        courseCode: '4D09',
        shortName: 'HTHSCI 4D09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744ea',
      },
      {
        _id: '6073b73756f56ef6389744ec',
        courseName: 'HTHSCI 4D12 A/B - Thesis in Engaging the City',
        courseCode: '4D12',
        shortName: 'HTHSCI 4D12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744ec',
      },
      {
        _id: '6073b73756f56ef6389744ee',
        courseName: 'HTHSCI 4DE3 - Senior Project in Engaging the City',
        courseCode: '4DE3',
        shortName: 'HTHSCI 4DE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744ee',
      },
      {
        _id: '6073b73756f56ef6389744f0',
        courseName: 'HTHSCI 4DM3 - Demystifying Medicine',
        courseCode: '4DM3',
        shortName: 'HTHSCI 4DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744f0',
      },
      {
        _id: '6073b73756f56ef6389744f2',
        courseName: 'HTHSCI 4EE3 A/B - Education Practicum in Health Sciences',
        courseCode: '4EE3',
        shortName: 'HTHSCI 4EE3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744f2',
      },
      {
        _id: '6073b73756f56ef6389744f4',
        courseName: 'HTHSCI 4F03 - Interdisciplinary Health & Wellness',
        courseCode: '4F03',
        shortName: 'HTHSCI 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744f4',
      },
      {
        _id: '6073b73756f56ef6389744f6',
        courseName: 'HTHSCI 4FF3 - Integrative Leadership Project',
        courseCode: '4FF3',
        shortName: 'HTHSCI 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73756f56ef6389744f6',
      },
      {
        _id: '6073b73856f56ef6389744f8',
        courseName: 'HTHSCI 4G03 - Pathoanatomy',
        courseCode: '4G03',
        shortName: 'HTHSCI 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef6389744f8',
      },
      {
        _id: '6073b73856f56ef6389744fa',
        courseName: 'HTHSCI 4G06 A/B S - Senior Project in Global Health',
        courseCode: '4G06',
        shortName: 'HTHSCI 4G06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef6389744fa',
      },
      {
        _id: '6073b73856f56ef6389744fc',
        courseName: 'HTHSCI 4G09 A/B S - Thesis in Global Health',
        courseCode: '4G09',
        shortName: 'HTHSCI 4G09 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef6389744fc',
      },
      {
        _id: '6073b73856f56ef6389744fe',
        courseName: 'HTHSCI 4G12 A/B - Thesis in Global Health',
        courseCode: '4G12',
        shortName: 'HTHSCI 4G12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef6389744fe',
      },
      {
        _id: '6073b73856f56ef638974500',
        courseName: 'HTHSCI 4G15 A/B S - Thesis in Global Health',
        courseCode: '4G15',
        shortName: 'HTHSCI 4G15 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef638974500',
      },
      {
        _id: '6073b73856f56ef638974502',
        courseName:
          'HTHSCI 4IC3 - Integration of Childrenâ€™s Physical and Mental Health',
        courseCode: '4IC3',
        shortName: 'HTHSCI 4IC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef638974502',
      },
      {
        _id: '6073b73856f56ef638974504',
        courseName: 'HTHSCI 4ID3 - Innovation By Design I',
        courseCode: '4ID3',
        shortName: 'HTHSCI 4ID3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef638974504',
      },
      {
        _id: '6073b73856f56ef638974506',
        courseName: 'HTHSCI 4II3 - Advanced Concepts in Immunology',
        courseCode: '4II3',
        shortName: 'HTHSCI 4II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef638974506',
      },
      {
        _id: '6073b73856f56ef638974508',
        courseName: 'HTHSCI 4J03 - Immunological Principles In Practice',
        courseCode: '4J03',
        shortName: 'HTHSCI 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef638974508',
      },
      {
        _id: '6073b73856f56ef63897450a',
        courseName: 'HTHSCI 4JJ3 - Building Undergraduate Research Capacity',
        courseCode: '4JJ3',
        shortName: 'HTHSCI 4JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef63897450a',
      },
      {
        _id: '6073b73856f56ef63897450c',
        courseName: 'HTHSCI 4K03 - Human Pathophysiology',
        courseCode: '4K03',
        shortName: 'HTHSCI 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef63897450c',
      },
      {
        _id: '6073b73856f56ef63897450e',
        courseName: 'HTHSCI 4KK3 - Pathophysiology of Infectious Diseases',
        courseCode: '4KK3',
        shortName: 'HTHSCI 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73856f56ef63897450e',
      },
      {
        _id: '6073b73956f56ef638974510',
        courseName: 'HTHSCI 4LA3 - Innovation By Design II',
        courseCode: '4LA3',
        shortName: 'HTHSCI 4LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974510',
      },
      {
        _id: '6073b73956f56ef638974512',
        courseName: 'HTHSCI 4LB3 - Leadership and Management 1',
        courseCode: '4LB3',
        shortName: 'HTHSCI 4LB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974512',
      },
      {
        _id: '6073b73956f56ef638974514',
        courseName: 'HTHSCI 4LC3 - Leadership and Management 2',
        courseCode: '4LC3',
        shortName: 'HTHSCI 4LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974514',
      },
      {
        _id: '6073b73956f56ef638974516',
        courseName: 'HTHSCI 4LD3 - Global Health Governance, Law and Politics',
        courseCode: '4LD3',
        shortName: 'HTHSCI 4LD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974516',
      },
      {
        _id: '6073b73956f56ef638974518',
        courseName: 'HTHSCI 4LL3 - Integrated Health Systems',
        courseCode: '4LL3',
        shortName: 'HTHSCI 4LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974518',
      },
      {
        _id: '6073b73956f56ef63897451a',
        courseName: 'HTHSCI 4M03 - Advanced Concepts in Health Psychology',
        courseCode: '4M03',
        shortName: 'HTHSCI 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef63897451a',
      },
      {
        _id: '6073b73956f56ef63897451c',
        courseName: 'HTHSCI 4MS3 - The Social Lives of Molecules',
        courseCode: '4MS3',
        shortName: 'HTHSCI 4MS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef63897451c',
      },
      {
        _id: '6073b73956f56ef63897451e',
        courseName: 'HTHSCI 4NN3 - Written Communication in Health Sciences II',
        courseCode: '4NN3',
        shortName: 'HTHSCI 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef63897451e',
      },
      {
        _id: '6073b73956f56ef638974520',
        courseName: 'HTHSCI 4NR3 - Nursing Research',
        courseCode: '4NR3',
        shortName: 'HTHSCI 4NR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974520',
      },
      {
        _id: '6073b73956f56ef638974522',
        courseName: 'HTHSCI 4NU3 - Nutrition',
        courseCode: '4NU3',
        shortName: 'HTHSCI 4NU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974522',
      },
      {
        _id: '6073b73956f56ef638974524',
        courseName: 'HTHSCI 4O03 - Principles of Virus Pathogenesis',
        courseCode: '4O03',
        shortName: 'HTHSCI 4O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73956f56ef638974524',
      },
      {
        _id: '6073b73a56f56ef638974526',
        courseName: 'HTHSCI 4PA3 - Global Health Innovation',
        courseCode: '4PA3',
        shortName: 'HTHSCI 4PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974526',
      },
      {
        _id: '6073b73a56f56ef638974528',
        courseName: 'HTHSCI 4QQ3 A/B S - Communication Skills Practicum',
        courseCode: '4QQ3',
        shortName: 'HTHSCI 4QQ3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974528',
      },
      {
        _id: '6073b73a56f56ef63897452a',
        courseName: 'HTHSCI 4R09 A/B - Thesis in Biomedical Sciences',
        courseCode: '4R09',
        shortName: 'HTHSCI 4R09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef63897452a',
      },
      {
        _id: '6073b73a56f56ef63897452c',
        courseName: 'HTHSCI 4R12 A/B S - Thesis in Biomedical Sciences',
        courseCode: '4R12',
        shortName: 'HTHSCI 4R12 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef63897452c',
      },
      {
        _id: '6073b73a56f56ef63897452e',
        courseName: 'HTHSCI 4SA3 - Competitive Advantage Through People',
        courseCode: '4SA3',
        shortName: 'HTHSCI 4SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef63897452e',
      },
      {
        _id: '6073b73a56f56ef638974530',
        courseName: 'HTHSCI 4SC3 - Social Determinants of Child Health',
        courseCode: '4SC3',
        shortName: 'HTHSCI 4SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974530',
      },
      {
        _id: '6073b73a56f56ef638974532',
        courseName: 'HTHSCI 4SS6 A/B - Group Process Practicum',
        courseCode: '4SS6',
        shortName: 'HTHSCI 4SS6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974532',
      },
      {
        _id: '6073b73a56f56ef638974534',
        courseName: 'HTHSCI 4TE3 - The Teaching Hospital',
        courseCode: '4TE3',
        shortName: 'HTHSCI 4TE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974534',
      },
      {
        _id: '6073b73a56f56ef638974536',
        courseName: 'HTHSCI 4TT3 A/B S - Research Practicum',
        courseCode: '4TT3',
        shortName: 'HTHSCI 4TT3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974536',
      },
      {
        _id: '6073b73a56f56ef638974538',
        courseName: 'HTHSCI 4W03 - Special Topics in Health Sciences II',
        courseCode: '4W03',
        shortName: 'HTHSCI 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef638974538',
      },
      {
        _id: '6073b73a56f56ef63897453a',
        courseName: 'HTHSCI 4WW3 A/B - Education Practicum',
        courseCode: '4WW3',
        shortName: 'HTHSCI 4WW3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef63897453a',
      },
      {
        _id: '6073b73a56f56ef63897453c',
        courseName: 'HTHSCI 4X03 A/B S - Collaboration and Peer Tutoring',
        courseCode: '4X03',
        shortName: 'HTHSCI 4X03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73a56f56ef63897453c',
      },
      {
        _id: '6073b73b56f56ef63897453e',
        courseName: 'HTHSCI 4XP3 A/B - Praxis Pathways 4',
        courseCode: '4XP3',
        shortName: 'HTHSCI 4XP3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef63897453e',
      },
      {
        _id: '6073b73b56f56ef638974540',
        courseName: 'HTHSCI 4XX3 - Professional Transitions',
        courseCode: '4XX3',
        shortName: 'HTHSCI 4XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974540',
      },
      {
        _id: '6073b73b56f56ef638974542',
        courseName: 'HTHSCI 4Y03 - Science, Culture and Identity',
        courseCode: '4Y03',
        shortName: 'HTHSCI 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974542',
      },
      {
        _id: '6073b73b56f56ef638974544',
        courseName: 'HTHSCI 4YY3 - Health Forum Practicum',
        courseCode: '4YY3',
        shortName: 'HTHSCI 4YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974544',
      },
      {
        _id: '6073b73b56f56ef638974546',
        courseName: 'HTHSCI 4ZZ3 - Global Health Advocacy',
        courseCode: '4ZZ3',
        shortName: 'HTHSCI 4ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974546',
      },
      {
        _id: '6073b73b56f56ef638974548',
        courseName: 'HTHSCI 1C06 A/B - Working Across Difference in Midwifery',
        courseCode: '1C06',
        shortName: 'HTHSCI 1C06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974548',
      },
      {
        _id: '6073b73b56f56ef63897454a',
        courseName:
          'HTHSCI 1CC6 - Integrated Biological Bases of Nursing Practice I',
        courseCode: '1CC6',
        shortName: 'HTHSCI 1CC6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef63897454a',
      },
      {
        _id: '6073b73b56f56ef63897454c',
        courseName: 'HTHSCI 1D06 A/B - Anatomy and Physiology',
        courseCode: '1D06',
        shortName: 'HTHSCI 1D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef63897454c',
      },
      {
        _id: '6073b73b56f56ef63897454e',
        courseName: 'HTHSCI 1J03 - Life Sciences for Clinical Practice',
        courseCode: '1J03',
        shortName: 'HTHSCI 1J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef63897454e',
      },
      {
        _id: '6073b73b56f56ef638974550',
        courseName: 'HTHSCI 1LL3 - Human Biochemistry I',
        courseCode: '1LL3',
        shortName: 'HTHSCI 1LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974550',
      },
      {
        _id: '6073b73b56f56ef638974552',
        courseName:
          'HTHSCI 2C06 - Integrated Biological Bases of Nursing Practice II',
        courseCode: '2C06',
        shortName: 'HTHSCI 2C06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73b56f56ef638974552',
      },
      {
        _id: '6073b73c56f56ef638974554',
        courseName: 'HTHSCI 2H03 - Introductory Pharmacology',
        courseCode: '2H03',
        shortName: 'HTHSCI 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974554',
      },
      {
        _id: '6073b73c56f56ef638974556',
        courseName: 'HTHSCI 2HH3 - Introductory Microbiology',
        courseCode: '2HH3',
        shortName: 'HTHSCI 2HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974556',
      },
      {
        _id: '6073b73c56f56ef638974558',
        courseName:
          'HTHSCI 2LA2 A/B - Introduction to Integrated Pathophysiology for Nursing for Basic Stream',
        courseCode: '2LA2',
        shortName: 'HTHSCI 2LA2 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974558',
      },
      {
        _id: '6073b73c56f56ef63897455a',
        courseName: 'HTHSCI 2M03 - Reproductive Physiology',
        courseCode: '2M03',
        shortName: 'HTHSCI 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef63897455a',
      },
      {
        _id: '6073b73c56f56ef63897455c',
        courseName:
          'HTHSCI 2PF3 A/B - Introduction to Integrated Pathophysiology for Accelerated Stream',
        courseCode: '2PF3',
        shortName: 'HTHSCI 2PF3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef63897455c',
      },
      {
        _id: '6073b73c56f56ef63897455e',
        courseName:
          'HTHSCI 2RR3 - Introduction to the Social Determinants of Health',
        courseCode: '2RR3',
        shortName: 'HTHSCI 2RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef63897455e',
      },
      {
        _id: '6073b73c56f56ef638974560',
        courseName: 'HTHSCI 2S03 - Introduction to Statistics for Nursing',
        courseCode: '2S03',
        shortName: 'HTHSCI 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974560',
      },
      {
        _id: '6073b73c56f56ef638974562',
        courseName:
          'HTHSCI 3BB3 - Human Biochemistry II: Nutrition and Metabolism',
        courseCode: '3BB3',
        shortName: 'HTHSCI 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974562',
      },
      {
        _id: '6073b73c56f56ef638974564',
        courseName:
          'HTHSCI 3HN3 - Partnering with Hamilton Neighbourhoods for Health',
        courseCode: '3HN3',
        shortName: 'HTHSCI 3HN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974564',
      },
      {
        _id: '6073b73c56f56ef638974566',
        courseName:
          'HTHSCI 3PF1 - Integrated Pathophysiology for Accelerated Stream',
        courseCode: '3PF1',
        shortName: 'HTHSCI 3PF1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974566',
      },
      {
        _id: '6073b73c56f56ef638974568',
        courseName: 'HTHSCI 4AR3 - Nursing Research Project',
        courseCode: '4AR3',
        shortName: 'HTHSCI 4AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef638974568',
      },
      {
        _id: '6073b73c56f56ef63897456a',
        courseName: 'HTHSCI 4BR3 - Independent Study',
        courseCode: '4BR3',
        shortName: 'HTHSCI 4BR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73c56f56ef63897456a',
      },
      {
        _id: '6073b73d56f56ef63897456c',
        courseName: 'HTHSCI 4DD6 A/B S - Advanced Leadership and Management',
        courseCode: '4DD6',
        shortName: 'HTHSCI 4DD6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef63897456c',
      },
      {
        _id: '6073b73d56f56ef63897456e',
        courseName: 'HTHSCI 4E06 A/B S - Leadership and Management',
        courseCode: '4E06',
        shortName: 'HTHSCI 4E06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef63897456e',
      },
      {
        _id: '6073b73d56f56ef638974570',
        courseName: 'HTHSCI 4HH3 A/B S - Quality Management',
        courseCode: '4HH3',
        shortName: 'HTHSCI 4HH3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef638974570',
      },
      {
        _id: '6073b73d56f56ef638974572',
        courseName: 'HTHSCI 4I03 A/B S - Leading Interprofessional Teams',
        courseCode: '4I03',
        shortName: 'HTHSCI 4I03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef638974572',
      },
      {
        _id: '6073b73d56f56ef638974574',
        courseName: 'HTHSCI 4Z03 A/B S - Conflict Management',
        courseCode: '4Z03',
        shortName: 'HTHSCI 4Z03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef638974574',
      },
      {
        _id: '6073b73d56f56ef638974576',
        courseName: 'HEBREW 2A03 - Introduction to Biblical Hebrew I',
        courseCode: '2A03',
        shortName: 'HEBREW 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef638974576',
      },
      {
        _id: '6073b73d56f56ef638974578',
        courseName: 'HEBREW 2B03 - Introduction to Biblical Hebrew II',
        courseCode: '2B03',
        shortName: 'HEBREW 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef638974578',
      },
      {
        _id: '6073b73d56f56ef63897457a',
        courseName: 'HEBREW 3A03 - Intermediate Hebrew I',
        courseCode: '3A03',
        shortName: 'HEBREW 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef63897457a',
      },
      {
        _id: '6073b73d56f56ef63897457c',
        courseName: 'HEBREW 3B03 - Intermediate Hebrew II',
        courseCode: '3B03',
        shortName: 'HEBREW 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef63897457c',
      },
      {
        _id: '6073b73d56f56ef63897457e',
        courseName:
          'HUMBEHV 2A06 A/B - Introduction to Autism Spectrum Disorder (ASD)',
        courseCode: '2A06',
        shortName: 'HUMBEHV 2A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef63897457e',
      },
      {
        _id: '6073b73d56f56ef638974580',
        courseName:
          'HUMBEHV 2B06 A/B - Introduction to Applied Behaviour Analysis (ABA) I',
        courseCode: '2B06',
        shortName: 'HUMBEHV 2B06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73d56f56ef638974580',
      },
      {
        _id: '6073b73e56f56ef638974582',
        courseName:
          'HUMBEHV 2C03 - Specialized Instructional Strategies (SIS) I',
        courseCode: '2C03',
        shortName: 'HUMBEHV 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974582',
      },
      {
        _id: '6073b73e56f56ef638974584',
        courseName: 'HUMBEHV 2FP6 - Field Placement I',
        courseCode: '2FP6',
        shortName: 'HUMBEHV 2FP6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974584',
      },
      {
        _id: '6073b73e56f56ef638974586',
        courseName: 'HUMBEHV 2FS3 - Field Placement I Seminar',
        courseCode: '2FS3',
        shortName: 'HUMBEHV 2FS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974586',
      },
      {
        _id: '6073b73e56f56ef638974588',
        courseName: 'HUMBEHV 2HB0 - Human Behaviour Professional Development',
        courseCode: '2HB0',
        shortName: 'HUMBEHV 2HB0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974588',
      },
      {
        _id: '6073b73e56f56ef63897458a',
        courseName: 'HUMBEHV 2L03 - ECE Curriculum I',
        courseCode: '2L03',
        shortName: 'HUMBEHV 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef63897458a',
      },
      {
        _id: '6073b73e56f56ef63897458c',
        courseName: 'HUMBEHV 2M03 - Learning Environment I',
        courseCode: '2M03',
        shortName: 'HUMBEHV 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef63897458c',
      },
      {
        _id: '6073b73e56f56ef63897458e',
        courseName: 'HUMBEHV 2TL3 - Responsive Care for Infants and Toddlers',
        courseCode: '2TL3',
        shortName: 'HUMBEHV 2TL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef63897458e',
      },
      {
        _id: '6073b73e56f56ef638974590',
        courseName: 'HUMBEHV 2XP6 - ECE Field Placement I',
        courseCode: '2XP6',
        shortName: 'HUMBEHV 2XP6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974590',
      },
      {
        _id: '6073b73e56f56ef638974592',
        courseName: 'HUMBEHV 3CB3 - Treating Challenging Behaviour I',
        courseCode: '3CB3',
        shortName: 'HUMBEHV 3CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974592',
      },
      {
        _id: '6073b73e56f56ef638974594',
        courseName: 'HUMBEHV 3D03 - Applied Behavioural Analysis (ABA) II',
        courseCode: '3D03',
        shortName: 'HUMBEHV 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974594',
      },
      {
        _id: '6073b73e56f56ef638974596',
        courseName: 'HUMBEHV 3E06 A/B - Behavioural Skill Building',
        courseCode: '3E06',
        shortName: 'HUMBEHV 3E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73e56f56ef638974596',
      },
      {
        _id: '6073b73f56f56ef638974598',
        courseName: 'HUMBEHV 3F03 - Ethics and Professionalism',
        courseCode: '3F03',
        shortName: 'HUMBEHV 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef638974598',
      },
      {
        _id: '6073b73f56f56ef63897459a',
        courseName: 'HUMBEHV 3FP9 - Field Placement II',
        courseCode: '3FP9',
        shortName: 'HUMBEHV 3FP9',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef63897459a',
      },
      {
        _id: '6073b73f56f56ef63897459c',
        courseName: 'HUMBEHV 3FS3 - Field Placement II Seminar',
        courseCode: '3FS3',
        shortName: 'HUMBEHV 3FS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef63897459c',
      },
      {
        _id: '6073b73f56f56ef63897459e',
        courseName:
          'HUMBEHV 3G03 - Specialized Instructional Strategies (SIS) II',
        courseCode: '3G03',
        shortName: 'HUMBEHV 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef63897459e',
      },
      {
        _id: '6073b73f56f56ef6389745a0',
        courseName: 'HUMBEHV 3H03 - Working with Families and Teams',
        courseCode: '3H03',
        shortName: 'HUMBEHV 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745a0',
      },
      {
        _id: '6073b73f56f56ef6389745a2',
        courseName: 'HUMBEHV 3IP3 A/B S - Inquiry Project',
        courseCode: '3IP3',
        shortName: 'HUMBEHV 3IP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745a2',
      },
      {
        _id: '6073b73f56f56ef6389745a4',
        courseName: 'HUMBEHV 3MD3 - Research Methods for Human Behaviour',
        courseCode: '3MD3',
        shortName: 'HUMBEHV 3MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745a4',
      },
      {
        _id: '6073b73f56f56ef6389745a6',
        courseName: 'HUMBEHV 3O03 - ECE Curriculum II',
        courseCode: '3O03',
        shortName: 'HUMBEHV 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745a6',
      },
      {
        _id: '6073b73f56f56ef6389745a8',
        courseName: 'HUMBEHV 3P03 - Learning Environment II',
        courseCode: '3P03',
        shortName: 'HUMBEHV 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745a8',
      },
      {
        _id: '6073b73f56f56ef6389745aa',
        courseName: 'HUMBEHV 3Q03 - Health, Safety & Nutrition',
        courseCode: '3Q03',
        shortName: 'HUMBEHV 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745aa',
      },
      {
        _id: '6073b73f56f56ef6389745ac',
        courseName: 'HUMBEHV 3RS3 - ABA Research Designs',
        courseCode: '3RS3',
        shortName: 'HUMBEHV 3RS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745ac',
      },
      {
        _id: '6073b73f56f56ef6389745ae',
        courseName: 'HUMBEHV 3ST3 - Statistics for Human Behaviour',
        courseCode: '3ST3',
        shortName: 'HUMBEHV 3ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b73f56f56ef6389745ae',
      },
      {
        _id: '6073b74056f56ef6389745b0',
        courseName: 'HUMBEHV 3XP6 - ECE Field Placement II',
        courseCode: '3XP6',
        shortName: 'HUMBEHV 3XP6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745b0',
      },
      {
        _id: '6073b74056f56ef6389745b2',
        courseName: 'HUMBEHV 3XS3 - Theory to Practice I and II',
        courseCode: '3XS3',
        shortName: 'HUMBEHV 3XS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745b2',
      },
      {
        _id: '6073b74056f56ef6389745b4',
        courseName: 'HUMBEHV 4CB3 - Treating Challenging Behaviour II',
        courseCode: '4CB3',
        shortName: 'HUMBEHV 4CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745b4',
      },
      {
        _id: '6073b74056f56ef6389745b6',
        courseName:
          'HUMBEHV 4HB3 - Seminar I for Honours Human Behaviour (B.A.Sc.)',
        courseCode: '4HB3',
        shortName: 'HUMBEHV 4HB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745b6',
      },
      {
        _id: '6073b74056f56ef6389745b8',
        courseName:
          'HUMBEHV 4HC3 - Seminar II for Honours Human Behaviour (B.A.Sc.)',
        courseCode: '4HC3',
        shortName: 'HUMBEHV 4HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745b8',
      },
      {
        _id: '6073b74056f56ef6389745ba',
        courseName: 'HUMBEHV 4I03 - Parent and Staff Training',
        courseCode: '4I03',
        shortName: 'HUMBEHV 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745ba',
      },
      {
        _id: '6073b74056f56ef6389745bc',
        courseName: 'HUMBEHV 4IP3 A/B S - Senior Inquiry Project',
        courseCode: '4IP3',
        shortName: 'HUMBEHV 4IP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745bc',
      },
      {
        _id: '6073b74056f56ef6389745be',
        courseName: 'HUMBEHV 4J03 - Transition Planning and Implementation',
        courseCode: '4J03',
        shortName: 'HUMBEHV 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745be',
      },
      {
        _id: '6073b74056f56ef6389745c0',
        courseName: 'HUMBEHV 4N03 - Principles of Ethical Practice',
        courseCode: '4N03',
        shortName: 'HUMBEHV 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745c0',
      },
      {
        _id: '6073b74056f56ef6389745c2',
        courseName: 'HUMBEHV 4RP6 A/B - Independent Research Project',
        courseCode: '4RP6',
        shortName: 'HUMBEHV 4RP6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745c2',
      },
      {
        _id: '6073b74056f56ef6389745c4',
        courseName:
          'HUMBEHV 4SC6 A/B - Science Communication in the Behavioural Sciences',
        courseCode: '4SC6',
        shortName: 'HUMBEHV 4SC6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745c4',
      },
      {
        _id: '6073b74056f56ef6389745c6',
        courseName: 'HUMBEHV 4U03 - Inclusion in the ECE Classroom',
        courseCode: '4U03',
        shortName: 'HUMBEHV 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74056f56ef6389745c6',
      },
      {
        _id: '6073b74156f56ef6389745c8',
        courseName: 'HUMBEHV 4V12 - ECE Field Placement III',
        courseCode: '4V12',
        shortName: 'HUMBEHV 4V12',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745c8',
      },
      {
        _id: '6073b74156f56ef6389745ca',
        courseName: 'HUMBEHV 4VS3 - Theory to Practice III',
        courseCode: '4VS3',
        shortName: 'HUMBEHV 4VS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745ca',
      },
      {
        _id: '6073b74156f56ef6389745cc',
        courseName: 'HUMBEHV 4W03 - Supervising for Leadership and Quality',
        courseCode: '4W03',
        shortName: 'HUMBEHV 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745cc',
      },
      {
        _id: '6073b74156f56ef6389745ce',
        courseName:
          'HUMAN 1QU3 - Insight and Inquiry: Questions to Change the World',
        courseCode: '1QU3',
        shortName: 'HUMAN 1QU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745ce',
      },
      {
        _id: '6073b74156f56ef6389745d0',
        courseName: 'HUMAN 1VV3 - Voice and Vision: Words to Change the World',
        courseCode: '1VV3',
        shortName: 'HUMAN 1VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745d0',
      },
      {
        _id: '6073b74156f56ef6389745d2',
        courseName: 'HUMAN 2DH3 - Introduction to Digital Humanities',
        courseCode: '2DH3',
        shortName: 'HUMAN 2DH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745d2',
      },
      {
        _id: '6073b74156f56ef6389745d4',
        courseName: 'HUMAN 3CL3 - Community Leadership at McMaster',
        courseCode: '3CL3',
        shortName: 'HUMAN 3CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b74156f56ef6389745d4',
      },
      {
        _id: '6073b74156f56ef6389745d6',
        courseName: 'HUMAN 3CM3 - Leadership: Cross-Cultural Mentoring Lab',
        courseCode: '3CM3',
        shortName: 'HUMAN 3CM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745d6',
      },
      {
        _id: '6073b74156f56ef6389745d8',
        courseName: 'HUMAN 3D12 - Full-time Discovery Channel Internship',
        courseCode: '3D12',
        shortName: 'HUMAN 3D12',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745d8',
      },
      {
        _id: '6073b74156f56ef6389745da',
        courseName: 'HUMAN 3IF0 - Full-time Internship',
        courseCode: '3IF0',
        shortName: 'HUMAN 3IF0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745da',
      },
      {
        _id: '6073b74156f56ef6389745dc',
        courseName: 'HUMAN 3IP0 - Part-time Internship',
        courseCode: '3IP0',
        shortName: 'HUMAN 3IP0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745dc',
      },
      {
        _id: '6073b74156f56ef6389745de',
        courseName: 'HUMAN 3LM3 - Foundations of Leadership',
        courseCode: '3LM3',
        shortName: 'HUMAN 3LM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74156f56ef6389745de',
      },
      {
        _id: '6073b74256f56ef6389745e0',
        courseName: 'HUMAN 3W03 - Applied Humanities I',
        courseCode: '3W03',
        shortName: 'HUMAN 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745e0',
      },
      {
        _id: '6073b74256f56ef6389745e2',
        courseName:
          'HUMAN 4CM3 - Cross-cultural Mentoring and Coaching Practicum',
        courseCode: '4CM3',
        shortName: 'HUMAN 4CM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745e2',
      },
      {
        _id: '6073b74256f56ef6389745e4',
        courseName: 'HUMAN 4LC3 - Leadership Capstone: Theory and Practice',
        courseCode: '4LC3',
        shortName: 'HUMAN 4LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745e4',
      },
      {
        _id: '6073b74256f56ef6389745e6',
        courseName: 'HUMAN 4RM3 - Leadership: Relationship Management',
        courseCode: '4RM3',
        shortName: 'HUMAN 4RM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745e6',
      },
      {
        _id: '6073b74256f56ef6389745e8',
        courseName: 'HUMAN 4W03 A/B S - Applied Humanities II',
        courseCode: '4W03',
        shortName: 'HUMAN 4W03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745e8',
      },
      {
        _id: '6073b74256f56ef6389745ea',
        courseName: 'HUMAN 4WL3 A/B - Wilson Leadership Scholar Capstone',
        courseCode: '4WL3',
        shortName: 'HUMAN 4WL3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745ea',
      },
      {
        _id: '6073b74256f56ef6389745ec',
        courseName: 'IBH 1AA3 - Financial Accounting',
        courseCode: '1AA3',
        shortName: 'IBH 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745ec',
      },
      {
        _id: '6073b74256f56ef6389745ee',
        courseName: 'IBH 1AB3 - Perspectives on Canadian Business',
        courseCode: '1AB3',
        shortName: 'IBH 1AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745ee',
      },
      {
        _id: '6073b74256f56ef6389745f0',
        courseName: 'IBH 1AC3 - Introduction to Language and Society',
        courseCode: '1AC3',
        shortName: 'IBH 1AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745f0',
      },
      {
        _id: '6073b74256f56ef6389745f2',
        courseName: 'IBH 1AD3 - IBH in the Community',
        courseCode: '1AD3',
        shortName: 'IBH 1AD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745f2',
      },
      {
        _id: '6073b74256f56ef6389745f4',
        courseName: 'IBH 1BA3 - Leadership Coaching 1',
        courseCode: '1BA3',
        shortName: 'IBH 1BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74256f56ef6389745f4',
      },
      {
        _id: '6073b74356f56ef6389745f6',
        courseName:
          'IBH 1BB3 - Insight and Inquiry: Questions to Change the World',
        courseCode: '1BB3',
        shortName: 'IBH 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef6389745f6',
      },
      {
        _id: '6073b74356f56ef6389745f8',
        courseName: 'IBH 1BC3 - Fundamentals of Ethics',
        courseCode: '1BC3',
        shortName: 'IBH 1BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef6389745f8',
      },
      {
        _id: '6073b74356f56ef6389745fa',
        courseName: 'IBH 1BD3 - Introduction to Peace Studies for IBH',
        courseCode: '1BD3',
        shortName: 'IBH 1BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef6389745fa',
      },
      {
        _id: '6073b74356f56ef6389745fc',
        courseName: 'IBH 2AA3 - Introduction to Marketing',
        courseCode: 'IBH',
        shortName: 'IBH 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef6389745fc',
      },
      {
        _id: '6073b74356f56ef6389745fe',
        courseName: 'IBH 2AB3 - Information Systems in Management',
        courseCode: '2AB3',
        shortName: 'IBH 2AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef6389745fe',
      },
      {
        _id: '6073b74356f56ef638974600',
        courseName: 'IBH 2AC3 - Talent Management',
        courseCode: '2AC3',
        shortName: 'IBH 2AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef638974600',
      },
      {
        _id: '6073b74356f56ef638974602',
        courseName: 'IBH 2AD3 - Statistical Data Analysis',
        courseCode: '2AD3',
        shortName: 'IBH 2AD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef638974602',
      },
      {
        _id: '6073b74356f56ef638974604',
        courseName: 'IBH 2AE3 - Critical Thinking',
        courseCode: '2AE3',
        shortName: 'IBH 2AE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef638974604',
      },
      {
        _id: '6073b74356f56ef638974606',
        courseName: 'IBH 2AF3 - Global Business Experience',
        courseCode: '2AF3',
        shortName: 'IBH 2AF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef638974606',
      },
      {
        _id: '6073b74356f56ef638974608',
        courseName: 'IBH 2BA3 - Managerial Accounting',
        courseCode: '2BA3',
        shortName: 'IBH 2BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef638974608',
      },
      {
        _id: '6073b74356f56ef63897460a',
        courseName: 'IBH 2BB3 - Introduction to Finance',
        courseCode: '2BB3',
        shortName: 'IBH 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef63897460a',
      },
      {
        _id: '6073b74356f56ef63897460c',
        courseName: 'IBH 2BC3 - Operations Management',
        courseCode: '2BC3',
        shortName: 'IBH 2BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74356f56ef63897460c',
      },
      {
        _id: '6073b74456f56ef63897460e',
        courseName: 'IBH 2BD3 - Moral Issues',
        courseCode: '2BD3',
        shortName: 'IBH 2BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef63897460e',
      },
      {
        _id: '6073b74456f56ef638974610',
        courseName:
          'IBH 2BE3 - Canadian Business History: the Canadian Experience in International Perspective',
        courseCode: '2BE3',
        shortName: 'IBH 2BE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974610',
      },
      {
        _id: '6073b74456f56ef638974612',
        courseName: 'IBH 2BF3 - History of Capitalism',
        courseCode: '2BF3',
        shortName: 'IBH 2BF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974612',
      },
      {
        _id: '6073b74456f56ef638974614',
        courseName: 'IBH 3AA3 - Relationship Management',
        courseCode: '3AA3',
        shortName: 'IBH 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974614',
      },
      {
        _id: '6073b74456f56ef638974616',
        courseName: 'IBH 3AB3 - Applied Marketing Management',
        courseCode: '3AB3',
        shortName: 'IBH 3AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974616',
      },
      {
        _id: '6073b74456f56ef638974618',
        courseName: 'IBH 3AC3 - Corporate Finance',
        courseCode: '3AC3',
        shortName: 'IBH 3AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974618',
      },
      {
        _id: '6073b74456f56ef63897461a',
        courseName: 'IBH 3AD3 - Cross-Cultural Communication',
        courseCode: '3AD3',
        shortName: 'IBH 3AD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef63897461a',
      },
      {
        _id: '6073b74456f56ef63897461c',
        courseName:
          'IBH 3BA3 - Understanding Entrepreneurship and Social Entrepreneurship From a Historical and Theoretical Lens',
        courseCode: '3BA3',
        shortName: 'IBH 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef63897461c',
      },
      {
        _id: '6073b74456f56ef63897461e',
        courseName: 'IBH 3BB3 - Organizational Strategy',
        courseCode: '3BB3',
        shortName: 'IBH 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef63897461e',
      },
      {
        _id: '6073b74456f56ef638974620',
        courseName:
          'IBH 3BC3 - Poverty, Privilege and Protest in Canadian History',
        courseCode: '3BC3',
        shortName: 'IBH 3BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974620',
      },
      {
        _id: '6073b74456f56ef638974622',
        courseName: 'IBH 3BD3 - Interpersonal Communication',
        courseCode: '3BD3',
        shortName: 'IBH 3BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74456f56ef638974622',
      },
      {
        _id: '6073b74556f56ef638974624',
        courseName: 'IBH 3BE3 - Operations Management',
        courseCode: '3BE3',
        shortName: 'IBH 3BE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974624',
      },
      {
        _id: '6073b74556f56ef638974626',
        courseName:
          'IBH 4AA3 - Leadership: Fostering Effective Communication Through Visual Literacy',
        courseCode: '4AA3',
        shortName: 'IBH 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974626',
      },
      {
        _id: '6073b74556f56ef638974628',
        courseName: 'IBH 4AB6 A/B - Social Entrepreneurship Capstone',
        courseCode: '4AB6',
        shortName: 'IBH 4AB6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974628',
      },
      {
        _id: '6073b74556f56ef63897462a',
        courseName:
          'IBH 4BA3 - Leadership Effectiveness: Building Personal and Organizational Success',
        courseCode: '4BA3',
        shortName: 'IBH 4BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef63897462a',
      },
      {
        _id: '6073b74556f56ef63897462c',
        courseName: 'â€¢  INDIGST 1A03 - Introduction to Indigenous Studies',
        courseCode: 'â€¢',
        shortName: 'â€¢  INDIGST 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef63897462c',
      },
      {
        _id: '6073b74556f56ef63897462e',
        courseName:
          'INDIGST 1AA3 - Introduction to Contemporary Indigenous Studies',
        courseCode: '1AA3',
        shortName: 'INDIGST 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef63897462e',
      },
      {
        _id: '6073b74556f56ef638974630',
        courseName:
          'INDIGST 1B03 - Reconciling What? Indigenous Relations in Canada',
        courseCode: '1B03',
        shortName: 'INDIGST 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974630',
      },
      {
        _id: '6073b74556f56ef638974632',
        courseName: 'INDIGST 2A03 - Indigenous Peoplesâ€™ Spirituality',
        courseCode: '2A03',
        shortName: 'INDIGST 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974632',
      },
      {
        _id: '6073b74556f56ef638974634',
        courseName:
          'INDIGST 2B03 - History of Indigenous Peoplesâ€™ Sovereignty',
        courseCode: '2B03',
        shortName: 'INDIGST 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974634',
      },
      {
        _id: '6073b74556f56ef638974636',
        courseName:
          'INDIGST 2BB3 - Contemporary Indigenous Knowledge and Societies',
        courseCode: '2BB3',
        shortName: 'INDIGST 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974636',
      },
      {
        _id: '6073b74556f56ef638974638',
        courseName:
          'INDIGST 2C03 - Current Issues in Indigenous Studies: Selected Topics',
        courseCode: '2C03',
        shortName: 'INDIGST 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74556f56ef638974638',
      },
      {
        _id: '6073b74656f56ef63897463a',
        courseName:
          'INDIGST 2D03 - Traditional Indigenous Ecological Knowledge',
        courseCode: '2D03',
        shortName: 'INDIGST 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef63897463a',
      },
      {
        _id: '6073b74656f56ef63897463c',
        courseName:
          'INDIGST 2F03 - Residential Schools in Canada: History and Impact',
        courseCode: '2F03',
        shortName: 'INDIGST 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef63897463c',
      },
      {
        _id: '6073b74656f56ef63897463e',
        courseName:
          'INDIGST 2G03 - Indigenous Perspectives on Peace and Conflict',
        courseCode: '2G03',
        shortName: 'INDIGST 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef63897463e',
      },
      {
        _id: '6073b74656f56ef638974640',
        courseName: 'INDIGST 2H03 - Indigenous Celebrity',
        courseCode: '2H03',
        shortName: 'INDIGST 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef638974640',
      },
      {
        _id: '6073b74656f56ef638974642',
        courseName: 'INDIGST 2J03 - Indigenous Experiential Education',
        courseCode: '2J03',
        shortName: 'INDIGST 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef638974642',
      },
      {
        _id: '6073b74656f56ef638974644',
        courseName: 'INDIGST 2K03 - Indigenous Futurisms and Wonderworks',
        courseCode: '2K03',
        shortName: 'INDIGST 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef638974644',
      },
      {
        _id: '6073b74656f56ef638974646',
        courseName: 'INDIGST 2M03 - Indigenous Research Methods and Ethics',
        courseCode: '2M03',
        shortName: 'INDIGST 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef638974646',
      },
      {
        _id: '6073b74656f56ef638974648',
        courseName: 'INDIGST 2MM3 - Indigenous Ways of Knowing: Theory',
        courseCode: '2MM3',
        shortName: 'INDIGST 2MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef638974648',
      },
      {
        _id: '6073b74656f56ef63897464a',
        courseName: 'INDIGST 2U03 - Indigenous Textiles and Design',
        courseCode: '2U03',
        shortName: 'INDIGST 2U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef63897464a',
      },
      {
        _id: '6073b74656f56ef63897464c',
        courseName:
          'INDIGST 3C03 - Study of Iroquois First Nations in Contemporary Times',
        courseCode: '3C03',
        shortName: 'INDIGST 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef63897464c',
      },
      {
        _id: '6073b74656f56ef63897464e',
        courseName:
          'INDIGST 3CC3 - Contemporary Indigenous Societies: Selected Topics',
        courseCode: '3CC3',
        shortName: 'INDIGST 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef63897464e',
      },
      {
        _id: '6073b74656f56ef638974650',
        courseName: 'INDIGST 3D03 - Contemporary Native Literature in Canada',
        courseCode: '3D03',
        shortName: 'INDIGST 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74656f56ef638974650',
      },
      {
        _id: '6073b74756f56ef638974652',
        courseName:
          'INDIGST 3E03 - Contemporary Native Literature in the United States',
        courseCode: '3E03',
        shortName: 'INDIGST 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974652',
      },
      {
        _id: '6073b74756f56ef638974654',
        courseName: 'INDIGST 3EE3 - Indigenous Representations in Film',
        courseCode: '3EE3',
        shortName: 'INDIGST 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974654',
      },
      {
        _id: '6073b74756f56ef638974656',
        courseName:
          'INDIGST 3F03 - Indigenous Art and Visual Culture in Canada, 1960 to the Present',
        courseCode: '3F03',
        shortName: 'INDIGST 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974656',
      },
      {
        _id: '6073b74756f56ef638974658',
        courseName:
          'INDIGST 3G03 - Indigenous Creative Arts and Drama: Selected Topics',
        courseCode: '3G03',
        shortName: 'INDIGST 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974658',
      },
      {
        _id: '6073b74756f56ef63897465a',
        courseName: 'INDIGST 3H03 - Indigenous Medicine I - Philosophy',
        courseCode: '3H03',
        shortName: 'INDIGST 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef63897465a',
      },
      {
        _id: '6073b74756f56ef63897465c',
        courseName: 'INDIGST 3HH3 - Indigenous Medicine II - Practical',
        courseCode: '3HH3',
        shortName: 'INDIGST 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef63897465c',
      },
      {
        _id: '6073b74756f56ef63897465e',
        courseName:
          'INDIGST 3J03 - Government and Politics of Indigenous People',
        courseCode: '3J03',
        shortName: 'INDIGST 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef63897465e',
      },
      {
        _id: '6073b74756f56ef638974660',
        courseName: 'INDIGST 3K03 - Indigenous Human Rights',
        courseCode: '3K03',
        shortName: 'INDIGST 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974660',
      },
      {
        _id: '6073b74756f56ef638974662',
        courseName: 'INDIGST 3L03 - Indigenous Independent Study',
        courseCode: '3L03',
        shortName: 'INDIGST 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974662',
      },
      {
        _id: '6073b74756f56ef638974664',
        courseName:
          'INDIGST 3N03 - Indigenous Women: Land, Rights, and Politics',
        courseCode: '3N03',
        shortName: 'INDIGST 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974664',
      },
      {
        _id: '6073b74756f56ef638974666',
        courseName:
          'INDIGST 3P03 - Haudenosaunee Health, Diet and Traditional Botany',
        courseCode: '3P03',
        shortName: 'INDIGST 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974666',
      },
      {
        _id: '6073b74756f56ef638974668',
        courseName:
          'INDIGST 3Q03 - Histories of Indigenous Sport and Recreation',
        courseCode: '3Q03',
        shortName: 'INDIGST 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74756f56ef638974668',
      },
      {
        _id: '6073b74856f56ef63897466a',
        courseName:
          'INDIGST 3R03 - Ogweho:weh Experiential Land-Based Learning',
        courseCode: '3R03',
        shortName: 'INDIGST 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef63897466a',
      },
      {
        _id: '6073b74856f56ef63897466c',
        courseName: 'INDIGST 3S03 - Other-than-Human Worlds and Relations',
        courseCode: '3S03',
        shortName: 'INDIGST 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef63897466c',
      },
      {
        _id: '6073b74856f56ef63897466e',
        courseName:
          'INDIGST 4A03 - Storytelling and Environmental Conservation',
        courseCode: '4A03',
        shortName: 'INDIGST 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef63897466e',
      },
      {
        _id: '6073b74856f56ef638974670',
        courseName:
          'INDIGST 4B03 - Indigenous Literary Governance and Resistance',
        courseCode: '4B03',
        shortName: 'INDIGST 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef638974670',
      },
      {
        _id: '6073b74856f56ef638974672',
        courseName: 'INDIGST 4D03 - Indigenous Critical Theory and Inquiry',
        courseCode: '4D03',
        shortName: 'INDIGST 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef638974672',
      },
      {
        _id: '6073b74856f56ef638974674',
        courseName:
          'INDIGST 4HH3 - Indigenous Health and Interdisciplinary Approaches',
        courseCode: '4HH3',
        shortName: 'INDIGST 4HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef638974674',
      },
      {
        _id: '6073b74856f56ef638974676',
        courseName:
          'INDIGST 4IW3 - Twenty-First Century Indigenous Writing and Film',
        courseCode: '4IW3',
        shortName: 'INDIGST 4IW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef638974676',
      },
      {
        _id: '6073b74856f56ef638974678',
        courseName: 'INDIGST 4L03 - Indigenous Community Research Experience',
        courseCode: '4L03',
        shortName: 'INDIGST 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef638974678',
      },
      {
        _id: '6073b74856f56ef63897467a',
        courseName:
          'INDIGST 4RI3 - Colonialism and Resistance in Representations of Indigenous Womanhood',
        courseCode: '4RI3',
        shortName: 'INDIGST 4RI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef63897467a',
      },
      {
        _id: '6073b74856f56ef63897467c',
        courseName: 'INDIGST 4T06 A/B - Honours Thesis',
        courseCode: '4T06',
        shortName: 'INDIGST 4T06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef63897467c',
      },
      {
        _id: '6073b74856f56ef63897467e',
        courseName: 'INNOVATE 1X03 - The World of Entrepreneurship',
        courseCode: '1X03',
        shortName: 'INNOVATE 1X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74856f56ef63897467e',
      },
      {
        _id: '6073b74956f56ef638974680',
        courseName:
          'INNOVATE 1Z03 - Artificial Intelligence - Innovative Technologies',
        courseCode: '1Z03',
        shortName: 'INNOVATE 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974680',
      },
      {
        _id: '6073b74956f56ef638974682',
        courseName: 'INNOVATE 2X03 - Lean Startup',
        courseCode: '2X03',
        shortName: 'INNOVATE 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974682',
      },
      {
        _id: '6073b74956f56ef638974684',
        courseName: 'INNOVATE 2Z03 - Sprint Methodologies',
        courseCode: '2Z03',
        shortName: 'INNOVATE 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974684',
      },
      {
        _id: '6073b74956f56ef638974686',
        courseName: 'INNOVATE 3EX3 - Experiential Learning in Innovation',
        courseCode: '3EX3',
        shortName: 'INNOVATE 3EX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974686',
      },
      {
        _id: '6073b74956f56ef638974688',
        courseName: 'INNOVATE 3X03 - Persuasion, Pitching Skills and Marketing',
        courseCode: '3X03',
        shortName: 'INNOVATE 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974688',
      },
      {
        _id: '6073b74956f56ef63897468a',
        courseName: 'INNOVATE 3Z03 - From Founder to CEO',
        courseCode: '3Z03',
        shortName: 'INNOVATE 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef63897468a',
      },
      {
        _id: '6073b74956f56ef63897468c',
        courseName: 'INNOVATE 3ZZ3 - Imagining and Navigating the Future',
        courseCode: '3ZZ3',
        shortName: 'INNOVATE 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef63897468c',
      },
      {
        _id: '6073b74956f56ef63897468e',
        courseName: 'INNOVATE 4EX6 A/B - Founders Startup',
        courseCode: '4EX6',
        shortName: 'INNOVATE 4EX6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef63897468e',
      },
      {
        _id: '6073b74956f56ef638974690',
        courseName:
          'INSPIRE 1A03 - Multidisciplinary Experiential Learning Opportunities for Everyone',
        courseCode: '1A03',
        shortName: 'INSPIRE 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974690',
      },
      {
        _id: '6073b74956f56ef638974692',
        courseName: 'INSPIRE 3EL1 - Experiential Learning Opportunities',
        courseCode: '3EL1',
        shortName: 'INSPIRE 3EL1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974692',
      },
      {
        _id: '6073b74956f56ef638974694',
        courseName: 'INSPIRE 3EL2 - Experiential Learning Opportunities',
        courseCode: '3EL2',
        shortName: 'INSPIRE 3EL2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74956f56ef638974694',
      },
      {
        _id: '6073b74a56f56ef638974696',
        courseName: 'INSPIRE 3EL3 - Experiential Learning Opportunities',
        courseCode: '3EL3',
        shortName: 'INSPIRE 3EL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef638974696',
      },
      {
        _id: '6073b74a56f56ef638974698',
        courseName: 'ISCI 1A24 A/B - Integrated Science I',
        courseCode: '1A24',
        shortName: 'ISCI 1A24 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef638974698',
      },
      {
        _id: '6073b74a56f56ef63897469a',
        courseName: 'ISCI 2A18 A/B - Integrated Science II',
        courseCode: '2A18',
        shortName: 'ISCI 2A18 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef63897469a',
      },
      {
        _id: '6073b74a56f56ef63897469c',
        courseName: 'ISCI 3A12 A/B - Integrated Science III',
        courseCode: '3A12',
        shortName: 'ISCI 3A12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef63897469c',
      },
      {
        _id: '6073b74a56f56ef63897469e',
        courseName: 'ISCI 3Z09 - Integrated Science III for Exchange Students',
        courseCode: '3Z09',
        shortName: 'ISCI 3Z09',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef63897469e',
      },
      {
        _id: '6073b74a56f56ef6389746a0',
        courseName: 'ISCI 4A12 A/B - Integrated Science IV',
        courseCode: '4A12',
        shortName: 'ISCI 4A12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef6389746a0',
      },
      {
        _id: '6073b74a56f56ef6389746a2',
        courseName: 'ISCI 4ZF0 - Integrated Science Field Work',
        courseCode: '4ZF0',
        shortName: 'ISCI 4ZF0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef6389746a2',
      },
      {
        _id: '6073b74a56f56ef6389746a4',
        courseName:
          'IBEHS 1EE0 - Introduction to the Engineering Co-op Program',
        courseCode: '1EE0',
        shortName: 'IBEHS 1EE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef6389746a4',
      },
      {
        _id: '6073b74a56f56ef6389746a6',
        courseName: 'IBEHS 1P10 A/B - Health Solutions Design Projects I',
        courseCode: '1P10',
        shortName: 'IBEHS 1P10 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef6389746a6',
      },
      {
        _id: '6073b74a56f56ef6389746a8',
        courseName:
          'IBEHS 2E06 A/B - Health, Engineering Science and Entrepreneurship I',
        courseCode: '2E06',
        shortName: 'IBEHS 2E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef6389746a8',
      },
      {
        _id: '6073b74a56f56ef6389746aa',
        courseName: 'IBEHS 2EC0 - Engineering Work Term',
        courseCode: '2EC0',
        shortName: 'IBEHS 2EC0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74a56f56ef6389746aa',
      },
      {
        _id: '6073b74b56f56ef6389746ac',
        courseName: 'IBEHS 2P03 - Health Solutions Design Projects II',
        courseCode: '2P03',
        shortName: 'IBEHS 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746ac',
      },
      {
        _id: '6073b74b56f56ef6389746ae',
        courseName: 'IBEHS 2R00 A/B - Current Research Initiatives I',
        courseCode: '2R00',
        shortName: 'IBEHS 2R00 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746ae',
      },
      {
        _id: '6073b74b56f56ef6389746b0',
        courseName: 'IBEHS 3A03 - Biomedical Signals and Systems',
        courseCode: '3A03',
        shortName: 'IBEHS 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746b0',
      },
      {
        _id: '6073b74b56f56ef6389746b2',
        courseName:
          'IBEHS 3E06 A/B - Health, Engineering Science and Entrepreneurship II',
        courseCode: '3E06',
        shortName: 'IBEHS 3E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746b2',
      },
      {
        _id: '6073b74b56f56ef6389746b4',
        courseName: 'IBEHS 3H03 - Research Project',
        courseCode: '3H03',
        shortName: 'IBEHS 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746b4',
      },
      {
        _id: '6073b74b56f56ef6389746b6',
        courseName: 'IBEHS 3I06 A/B - Research Project',
        courseCode: '3I06',
        shortName: 'IBEHS 3I06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746b6',
      },
      {
        _id: '6073b74b56f56ef6389746b8',
        courseName:
          'IBEHS 3P04 - Health Solutions Design Projects III: Analysis and Decision Making',
        courseCode: '3P04',
        shortName: 'IBEHS 3P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746b8',
      },
      {
        _id: '6073b74b56f56ef6389746ba',
        courseName: 'IBEHS 3R00 A/B - Current Research Initiatives II',
        courseCode: '3R00',
        shortName: 'IBEHS 3R00 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746ba',
      },
      {
        _id: '6073b74b56f56ef6389746bc',
        courseName: 'IBEHS 4A03 - Biomedical Control Systems',
        courseCode: '4A03',
        shortName: 'IBEHS 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746bc',
      },
      {
        _id: '6073b74b56f56ef6389746be',
        courseName: 'IBEHS 4B03 - Biomechanics',
        courseCode: '4B03',
        shortName: 'IBEHS 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746be',
      },
      {
        _id: '6073b74b56f56ef6389746c0',
        courseName:
          'IBEHS 4C03 - Statistical Methods in Biomedical Engineering',
        courseCode: '4C03',
        shortName: 'IBEHS 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746c0',
      },
      {
        _id: '6073b74b56f56ef6389746c2',
        courseName: 'IBEHS 4D03 - Introduction to Medical Imaging',
        courseCode: '4D03',
        shortName: 'IBEHS 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74b56f56ef6389746c2',
      },
      {
        _id: '6073b74c56f56ef6389746c4',
        courseName:
          'IBEHS 4E06 A/B - Health, Engineering Science and Entrepreneurship III',
        courseCode: 'IBEHS',
        shortName: 'IBEHS 4E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746c4',
      },
      {
        _id: '6073b74c56f56ef6389746c6',
        courseName: 'IBEHS 4F04 - Biomedical Instrumentation and Measurement',
        courseCode: '4F04',
        shortName: 'IBEHS 4F04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746c6',
      },
      {
        _id: '6073b74c56f56ef6389746c8',
        courseName:
          'IBEHS 4P04 - Health Solutions Design Projects IV: Economics and Project Management',
        courseCode: '4P04',
        shortName: 'IBEHS 4P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746c8',
      },
      {
        _id: '6073b74c56f56ef6389746ca',
        courseName: 'IBEHS 4QZ3 - Modelling of Biological Systems',
        courseCode: '4QZ3',
        shortName: 'IBEHS 4QZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746ca',
      },
      {
        _id: '6073b74c56f56ef6389746cc',
        courseName: 'IBEHS 4R00 A/B - Current Research Initiatives III',
        courseCode: '4R00',
        shortName: 'IBEHS 4R00 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746cc',
      },
      {
        _id: '6073b74c56f56ef6389746ce',
        courseName:
          'IBEHS 5E15 A/B - Health, Engineering Science and Entrepreneurship (Capstone)',
        courseCode: '5E15',
        shortName: 'IBEHS 5E15 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746ce',
      },
      {
        _id: '6073b74c56f56ef6389746d0',
        courseName: 'IBEHS 5P06 A/B - Biomedical Capstone Design Project',
        courseCode: '5P06',
        shortName: 'IBEHS 5P06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746d0',
      },
      {
        _id: '6073b74c56f56ef6389746d2',
        courseName: 'IBEHS 5R06 A/B - Current Research Initiatives IV',
        courseCode: '5R06',
        shortName: 'IBEHS 5R06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746d2',
      },
      {
        _id: '6073b74c56f56ef6389746d4',
        courseName: 'INTENG 2A03 A/B S - International Engagement at Home',
        courseCode: '2A03',
        shortName: 'INTENG 2A03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746d4',
      },
      {
        _id: '6073b74c56f56ef6389746d6',
        courseName: 'INTENG 3A03 - International Engagement Capstone',
        courseCode: '3A03',
        shortName: 'INTENG 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746d6',
      },
      {
        _id: '6073b74c56f56ef6389746d8',
        courseName: 'INUKTUT 1Z03 - Introduction to Inuit Language and Culture',
        courseCode: '1Z03',
        shortName: 'INUKTUT 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746d8',
      },
      {
        _id: '6073b74c56f56ef6389746da',
        courseName: 'INUKTUT 2Z03 - Intermediate Inuktitut',
        courseCode: '2Z03',
        shortName: 'INUKTUT 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74c56f56ef6389746da',
      },
      {
        _id: '6073b74d56f56ef6389746dc',
        courseName: 'ITALIAN 1A03 - Intermediate Italian I',
        courseCode: '1A03',
        shortName: 'ITALIAN 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746dc',
      },
      {
        _id: '6073b74d56f56ef6389746de',
        courseName: 'ITALIAN 1AA3 - Intermediate Italian II',
        courseCode: '1AA3',
        shortName: 'ITALIAN 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746de',
      },
      {
        _id: '6073b74d56f56ef6389746e0',
        courseName: 'ITALIAN 1Z06 A/B S - Beginnerâ€™s Intensive Italian',
        courseCode: '1Z06',
        shortName: 'ITALIAN 1Z06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746e0',
      },
      {
        _id: '6073b74d56f56ef6389746e2',
        courseName: 'ITALIAN 2Z03 - Intermediate Italian I',
        courseCode: '2Z03',
        shortName: 'ITALIAN 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746e2',
      },
      {
        _id: '6073b74d56f56ef6389746e4',
        courseName: 'ITALIAN 2ZZ3 - Intermediate Italian II',
        courseCode: '2ZZ3',
        shortName: 'ITALIAN 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746e4',
      },
      {
        _id: '6073b74d56f56ef6389746e6',
        courseName:
          'ITALIAN 3X03 - Italy Today Through Film (Taught in English)',
        courseCode: '3X03',
        shortName: 'ITALIAN 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746e6',
      },
      {
        _id: '6073b74d56f56ef6389746e8',
        courseName: 'ITALIAN 3Z03 - Advanced Italian I',
        courseCode: '3Z03',
        shortName: 'ITALIAN 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746e8',
      },
      {
        _id: '6073b74d56f56ef6389746ea',
        courseName: 'ITALIAN 4II3 A/B S - Independent Study',
        courseCode: '4II3',
        shortName: 'ITALIAN 4II3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746ea',
      },
      {
        _id: '6073b74d56f56ef6389746ec',
        courseName: 'JAPANESE 1Z06 A/B S - Beginnerâ€™s Intensive Japanese',
        courseCode: '1Z06',
        shortName: 'JAPANESE 1Z06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746ec',
      },
      {
        _id: '6073b74d56f56ef6389746ee',
        courseName: 'JAPANESE 2X03 - Japanese Anime (Taught in English)',
        courseCode: '2X03',
        shortName: 'JAPANESE 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746ee',
      },
      {
        _id: '6073b74d56f56ef6389746f0',
        courseName: 'JAPANESE 2Z03 - Intermediate Intensive Japanese I',
        courseCode: '2Z03',
        shortName: 'JAPANESE 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746f0',
      },
      {
        _id: '6073b74d56f56ef6389746f2',
        courseName: 'JAPANESE 2ZZ3 - Intermediate Intensive Japanese II',
        courseCode: '2ZZ3',
        shortName: 'JAPANESE 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74d56f56ef6389746f2',
      },
      {
        _id: '6073b74e56f56ef6389746f4',
        courseName: 'KINESIOL 1A03 - Human Anatomy and Physiology I',
        courseCode: '1A03',
        shortName: 'KINESIOL 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef6389746f4',
      },
      {
        _id: '6073b74e56f56ef6389746f6',
        courseName: 'KINESIOL 1AA3 - Human Anatomy and Physiology II',
        courseCode: '1AA3',
        shortName: 'KINESIOL 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef6389746f6',
      },
      {
        _id: '6073b74e56f56ef6389746f8',
        courseName: 'KINESIOL 1E03 - Motor Control and Learning',
        courseCode: '1E03',
        shortName: 'KINESIOL 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef6389746f8',
      },
      {
        _id: '6073b74e56f56ef6389746fa',
        courseName: 'KINESIOL 1F03 - Human Nutrition and Health',
        courseCode: '1F03',
        shortName: 'KINESIOL 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef6389746fa',
      },
      {
        _id: '6073b74e56f56ef6389746fc',
        courseName: 'KINESIOL 1K03 - Foundations in Kinesiology',
        courseCode: '1K03',
        shortName: 'KINESIOL 1K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef6389746fc',
      },
      {
        _id: '6073b74e56f56ef6389746fe',
        courseName: 'KINESIOL 1Y03 - Human Anatomy and Physiology I',
        courseCode: '1Y03',
        shortName: 'KINESIOL 1Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef6389746fe',
      },
      {
        _id: '6073b74e56f56ef638974700',
        courseName: 'KINESIOL 1YY3 - Human Anatomy and Physiology II',
        courseCode: '1YY3',
        shortName: 'KINESIOL 1YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef638974700',
      },
      {
        _id: '6073b74e56f56ef638974702',
        courseName: 'KINESIOL 2A03 - Biomechanics',
        courseCode: '2A03',
        shortName: 'KINESIOL 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef638974702',
      },
      {
        _id: '6073b74e56f56ef638974704',
        courseName: 'KINESIOL 2C03 - Neuromuscular Exercise Physiology',
        courseCode: '2C03',
        shortName: 'KINESIOL 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef638974704',
      },
      {
        _id: '6073b74e56f56ef638974706',
        courseName:
          'KINESIOL 2CC3 - Cardiorespiratory and Metabolic Exercise Physiology',
        courseCode: '2CC3',
        shortName: 'KINESIOL 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef638974706',
      },
      {
        _id: '6073b74e56f56ef638974708',
        courseName: 'KINESIOL 2E03 - Musculoskeletal Anatomy',
        courseCode: '2E03',
        shortName: 'KINESIOL 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef638974708',
      },
      {
        _id: '6073b74e56f56ef63897470a',
        courseName:
          'KINESIOL 2F03 - Growth, Maturation and Physical Activity in Children and Youth',
        courseCode: '2F03',
        shortName: 'KINESIOL 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74e56f56ef63897470a',
      },
      {
        _id: '6073b74f56f56ef63897470c',
        courseName: 'KINESIOL 2G03 - Health Psychology',
        courseCode: '2G03',
        shortName: 'KINESIOL 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef63897470c',
      },
      {
        _id: '6073b74f56f56ef63897470e',
        courseName: 'KINESIOL 2Y03 - Human Anatomy and Physiology I',
        courseCode: '2Y03',
        shortName: 'KINESIOL 2Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef63897470e',
      },
      {
        _id: '6073b74f56f56ef638974710',
        courseName: 'KINESIOL 2YY3 - Human Anatomy and Physiology II',
        courseCode: '2YY3',
        shortName: 'KINESIOL 2YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef638974710',
      },
      {
        _id: '6073b74f56f56ef638974712',
        courseName: 'KINESIOL 3AA3 - Biomechanics II',
        courseCode: '3AA3',
        shortName: 'KINESIOL 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef638974712',
      },
      {
        _id: '6073b74f56f56ef638974714',
        courseName: 'KINESIOL 3B03 - Adapted Physical Activity',
        courseCode: '3B03',
        shortName: 'KINESIOL 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef638974714',
      },
      {
        _id: '6073b74f56f56ef638974716',
        courseName: 'KINESIOL 3E03 - Neural Control of Human Movement',
        courseCode: '3E03',
        shortName: 'KINESIOL 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef638974716',
      },
      {
        _id: '6073b74f56f56ef638974718',
        courseName: 'KINESIOL 3F03 - Athletic Training & Conditioning',
        courseCode: '3F03',
        shortName: 'KINESIOL 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef638974718',
      },
      {
        _id: '6073b74f56f56ef63897471a',
        courseName: 'KINESIOL 3H03 - Exercise Psychology',
        courseCode: '3H03',
        shortName: 'KINESIOL 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef63897471a',
      },
      {
        _id: '6073b74f56f56ef63897471c',
        courseName: 'KINESIOL 3HN3 - Human Neurophysiology',
        courseCode: '3HN3',
        shortName: 'KINESIOL 3HN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef63897471c',
      },
      {
        _id: '6073b74f56f56ef63897471e',
        courseName: 'KINESIOL 3K03 - Sports Injuries',
        courseCode: '3K03',
        shortName: 'KINESIOL 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef63897471e',
      },
      {
        _id: '6073b74f56f56ef638974720',
        courseName: 'KINESIOL 3L03 - Exercise Testing and Prescription',
        courseCode: '3L03',
        shortName: 'KINESIOL 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b74f56f56ef638974720',
      },
      {
        _id: '6073b75056f56ef638974722',
        courseName:
          'KINESIOL 3N03 - Ergonomics I: Workplace Injury Risk Assessment',
        courseCode: '3N03',
        shortName: 'KINESIOL 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974722',
      },
      {
        _id: '6073b75056f56ef638974724',
        courseName: 'KINESIOL 3Q03 - Motor Development Across the Lifespan',
        courseCode: '3Q03',
        shortName: 'KINESIOL 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974724',
      },
      {
        _id: '6073b75056f56ef638974726',
        courseName: 'KINESIOL 3RP3 - Kinesiology Research Practicum',
        courseCode: '3RP3',
        shortName: 'KINESIOL 3RP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974726',
      },
      {
        _id: '6073b75056f56ef638974728',
        courseName: 'KINESIOL 3U03 - Human Growth and Maturation',
        courseCode: '3U03',
        shortName: 'KINESIOL 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974728',
      },
      {
        _id: '6073b75056f56ef63897472a',
        courseName: 'KINESIOL 3V03 - Sport Psychology',
        courseCode: '3V03',
        shortName: 'KINESIOL 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef63897472a',
      },
      {
        _id: '6073b75056f56ef63897472c',
        courseName: 'KINESIOL 3Y03 - Human Nutrition and Metabolism',
        courseCode: '3Y03',
        shortName: 'KINESIOL 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef63897472c',
      },
      {
        _id: '6073b75056f56ef63897472e',
        courseName:
          'KINESIOL 3Z03 - Neuromuscular Plasticity In Health And Disease',
        courseCode: '3Z03',
        shortName: 'KINESIOL 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef63897472e',
      },
      {
        _id: '6073b75056f56ef638974730',
        courseName: 'KINESIOL 4A03 - Advanced Biomechanics',
        courseCode: '4A03',
        shortName: 'KINESIOL 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974730',
      },
      {
        _id: '6073b75056f56ef638974732',
        courseName: 'KINESIOL 4AA3 - Applied Biomechanics',
        courseCode: '4AA3',
        shortName: 'KINESIOL 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974732',
      },
      {
        _id: '6073b75056f56ef638974734',
        courseName:
          'KINESIOL 4B03 - Cardiovascular Disease: Pathophysiology and Rehabilitation',
        courseCode: '4B03',
        shortName: 'KINESIOL 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974734',
      },
      {
        _id: '6073b75056f56ef638974736',
        courseName:
          'KINESIOL 4BB3 - Ergonomics II: Mechanism of Injury and Prevention',
        courseCode: '4BB3',
        shortName: 'KINESIOL 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974736',
      },
      {
        _id: '6073b75056f56ef638974738',
        courseName:
          'KINESIOL 4C03 - Integrative Physiology of Human Performance',
        courseCode: '4C03',
        shortName: 'KINESIOL 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75056f56ef638974738',
      },
      {
        _id: '6073b75156f56ef63897473a',
        courseName: 'KINESIOL 4CN3 - Clinical Neurophysiology',
        courseCode: '4CN3',
        shortName: 'KINESIOL 4CN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef63897473a',
      },
      {
        _id: '6073b75156f56ef63897473c',
        courseName: 'KINESIOL 4EE3 - Professional Placement in Kinesiology',
        courseCode: '4EE3',
        shortName: 'KINESIOL 4EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef63897473c',
      },
      {
        _id: '6073b75156f56ef63897473e',
        courseName: 'KINESIOL 4GG3 - Clinical Biomechanics',
        courseCode: '4GG3',
        shortName: 'KINESIOL 4GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef63897473e',
      },
      {
        _id: '6073b75156f56ef638974740',
        courseName: 'KINESIOL 4H03 - Physical Activity Behaviour Change',
        courseCode: '4H03',
        shortName: 'KINESIOL 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef638974740',
      },
      {
        _id: '6073b75156f56ef638974742',
        courseName: 'KINESIOL 4J03 - Functional Anatomy',
        courseCode: '4J03',
        shortName: 'KINESIOL 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef638974742',
      },
      {
        _id: '6073b75156f56ef638974744',
        courseName: 'KINESIOL 4KK3 - Fundamentals of Rehabilitation',
        courseCode: '4KK3',
        shortName: 'KINESIOL 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef638974744',
      },
      {
        _id: '6073b75156f56ef638974746',
        courseName: 'KINESIOL 4Q03 - Paediatric Exercise Physiology',
        courseCode: '4Q03',
        shortName: 'KINESIOL 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef638974746',
      },
      {
        _id: '6073b75156f56ef638974748',
        courseName: 'KINESIOL 4RR6 A/B - Thesis',
        courseCode: '4RR6',
        shortName: 'KINESIOL 4RR6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef638974748',
      },
      {
        _id: '6073b75156f56ef63897474a',
        courseName:
          'KINESIOL 4S03 - Physical Activity in Chronic Health Impairments',
        courseCode: '4S03',
        shortName: 'KINESIOL 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef63897474a',
      },
      {
        _id: '6073b75156f56ef63897474c',
        courseName:
          'KINESIOL 4SS3 - Human Aging: Biological and Lifestyle Influences',
        courseCode: '4SS3',
        shortName: 'KINESIOL 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef63897474c',
      },
      {
        _id: '6073b75156f56ef63897474e',
        courseName: 'KINESIOL 4V03 - Human Factors and Cognitive Ergonomics',
        courseCode: '4V03',
        shortName: 'KINESIOL 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef63897474e',
      },
      {
        _id: '6073b75156f56ef638974750',
        courseName: 'KINESIOL 4W03 - Exploring Movement and Posture',
        courseCode: '4W03',
        shortName: 'KINESIOL 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75156f56ef638974750',
      },
      {
        _id: '6073b75256f56ef638974752',
        courseName: 'KINESIOL 4Y03 - Cognitive Neuroscience of Exercise',
        courseCode: '4Y03',
        shortName: 'KINESIOL 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974752',
      },
      {
        _id: '6073b75256f56ef638974754',
        courseName: 'KOREAN 1Z03 - Introductory Korean I',
        courseCode: '1Z03',
        shortName: 'KOREAN 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974754',
      },
      {
        _id: '6073b75256f56ef638974756',
        courseName: 'KOREAN 1ZZ3 - Introductory Korean II',
        courseCode: '1ZZ3',
        shortName: 'KOREAN 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974756',
      },
      {
        _id: '6073b75256f56ef638974758',
        courseName: 'KOREAN 2X03 - Korean Pop-Culture (Taught in English)',
        courseCode: '2X03',
        shortName: 'KOREAN 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974758',
      },
      {
        _id: '6073b75256f56ef63897475a',
        courseName: 'LABRST 1D03 - Will Robots Take All Our Jobs?',
        courseCode: '1D03',
        shortName: 'LABRST 1D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef63897475a',
      },
      {
        _id: '6073b75256f56ef63897475c',
        courseName: 'LABRST 1E03 - Navigating the World of Work',
        courseCode: '1E03',
        shortName: 'LABRST 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef63897475c',
      },
      {
        _id: '6073b75256f56ef63897475e',
        courseName: 'LABRST 2A03 - Unions in Action',
        courseCode: '2A03',
        shortName: 'LABRST 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef63897475e',
      },
      {
        _id: '6073b75256f56ef638974760',
        courseName: 'LABRST 2G03 - Labour and Globalization',
        courseCode: '2G03',
        shortName: 'LABRST 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974760',
      },
      {
        _id: '6073b75256f56ef638974762',
        courseName: 'LABRST 2H03 - Sports, Work And Labour',
        courseCode: '2H03',
        shortName: 'LABRST 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974762',
      },
      {
        _id: '6073b75256f56ef638974764',
        courseName: 'LABRST 2J03 - Work and Racism',
        courseCode: '2J03',
        shortName: 'LABRST 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974764',
      },
      {
        _id: '6073b75256f56ef638974766',
        courseName: 'LABRST 2M03 - Pop Culture, Media and Work',
        courseCode: '2M03',
        shortName: 'LABRST 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75256f56ef638974766',
      },
      {
        _id: '6073b75356f56ef638974768',
        courseName: 'LABRST 2W03 - Human Rights and Social Justice',
        courseCode: '2W03',
        shortName: 'LABRST 2W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef638974768',
      },
      {
        _id: '6073b75356f56ef63897476a',
        courseName: 'LABRST 3A03 - Economics of Labour Market Issues',
        courseCode: '3A03',
        shortName: 'LABRST 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef63897476a',
      },
      {
        _id: '6073b75356f56ef63897476c',
        courseName: 'LABRST 3B03 - Economics of Trade Unionism and Labour',
        courseCode: '3B03',
        shortName: 'LABRST 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef63897476c',
      },
      {
        _id: '6073b75356f56ef63897476e',
        courseName: 'LABRST 3C03 - Labour and Employment Law',
        courseCode: '3C03',
        shortName: 'LABRST 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef63897476e',
      },
      {
        _id: '6073b75356f56ef638974770',
        courseName: 'LABRST 3D03 - Work: Dangerous to your Health?',
        courseCode: '3D03',
        shortName: 'LABRST 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef638974770',
      },
      {
        _id: '6073b75356f56ef638974772',
        courseName: 'LABRST 3E03 - Gender, Sexuality and Work',
        courseCode: '3E03',
        shortName: 'LABRST 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef638974772',
      },
      {
        _id: '6073b75356f56ef638974774',
        courseName: 'LABRST 3K03 - On the Move: Workers in a Global World',
        courseCode: '3K03',
        shortName: 'LABRST 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef638974774',
      },
      {
        _id: '6073b75356f56ef638974776',
        courseName: 'LABRST 3L03 - Labour Policy and Advocacy',
        courseCode: '3L03',
        shortName: 'LABRST 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef638974776',
      },
      {
        _id: '6073b75356f56ef638974778',
        courseName: 'LABRST 3M03 - Theoretical Approaches to Labour Studies',
        courseCode: '3M03',
        shortName: 'LABRST 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef638974778',
      },
      {
        _id: '6073b75356f56ef63897477a',
        courseName: 'LABRST 3P03 - Workers',
        courseCode: '3P03',
        shortName: 'LABRST 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef63897477a',
      },
      {
        _id: '6073b75356f56ef63897477c',
        courseName: 'LABRST 3Q03 - Community Engaged Research',
        courseCode: '3Q03',
        shortName: 'LABRST 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef63897477c',
      },
      {
        _id: '6073b75356f56ef63897477e',
        courseName: 'LABRST 3T03 - Poverty and Homelessness',
        courseCode: '3T03',
        shortName: 'LABRST 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75356f56ef63897477e',
      },
      {
        _id: '6073b75456f56ef638974780',
        courseName: 'LABRST 4A06 A/B - Labour Studies Practicum',
        courseCode: '4A06',
        shortName: 'LABRST 4A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974780',
      },
      {
        _id: '6073b75456f56ef638974782',
        courseName: 'LABRST 4C03 - Public Sector Collective Bargaining',
        courseCode: '4C03',
        shortName: 'LABRST 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974782',
      },
      {
        _id: '6073b75456f56ef638974784',
        courseName: 'LABRST 4F03 - Work and the Environment',
        courseCode: '4F03',
        shortName: 'LABRST 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974784',
      },
      {
        _id: '6073b75456f56ef638974786',
        courseName: 'LABRST 4G03 - Advanced Topics in Labour Studies',
        courseCode: '4G03',
        shortName: 'LABRST 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974786',
      },
      {
        _id: '6073b75456f56ef638974788',
        courseName:
          'LABRST 4H03 - Working Precariously: Labour Strategies, Labour Renewal',
        courseCode: '4H03',
        shortName: 'LABRST 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974788',
      },
      {
        _id: '6073b75456f56ef63897478a',
        courseName: 'LABRST 4J03 - Independent Study',
        courseCode: '4J03',
        shortName: 'LABRST 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef63897478a',
      },
      {
        _id: '6073b75456f56ef63897478c',
        courseName: 'LATIN 1Z03 - Beginnerâ€™s Intensive Latin I',
        courseCode: '1Z03',
        shortName: 'LATIN 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef63897478c',
      },
      {
        _id: '6073b75456f56ef63897478e',
        courseName: 'LATIN 1ZZ3 - Beginnerâ€™s Intensive Latin II',
        courseCode: '1ZZ3',
        shortName: 'LATIN 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef63897478e',
      },
      {
        _id: '6073b75456f56ef638974790',
        courseName: 'LATIN 2A03 - Intermediate Latin I',
        courseCode: '2A03',
        shortName: 'LATIN 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974790',
      },
      {
        _id: '6073b75456f56ef638974792',
        courseName: 'LATIN 2AA3 - Intermediate Latin II',
        courseCode: '2AA3',
        shortName: 'LATIN 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974792',
      },
      {
        _id: '6073b75456f56ef638974794',
        courseName: 'LATIN 3AA3 - Latin Prose',
        courseCode: '3AA3',
        shortName: 'LATIN 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974794',
      },
      {
        _id: '6073b75456f56ef638974796',
        courseName: 'LATIN 3BB3 - Topics in Latin Literature',
        courseCode: '3BB3',
        shortName: 'LATIN 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75456f56ef638974796',
      },
      {
        _id: '6073b75556f56ef638974798',
        courseName: 'LATIN 3H03 - Topics in Latin Poetry',
        courseCode: '3H03',
        shortName: 'LATIN 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef638974798',
      },
      {
        _id: '6073b75556f56ef63897479a',
        courseName: 'LATIN 4T03 - Independent Study in Latin',
        courseCode: '4T03',
        shortName: 'LATIN 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef63897479a',
      },
      {
        _id: '6073b75556f56ef63897479c',
        courseName: 'LIFESCI 1D03 - Medical Imaging Physics',
        courseCode: 'LIFESCI',
        shortName: 'LIFESCI 1D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef63897479c',
      },
      {
        _id: '6073b75556f56ef63897479e',
        courseName: 'LIFESCI 2A03 - Research Methods in Life Sciences',
        courseCode: '2A03',
        shortName: 'LIFESCI 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef63897479e',
      },
      {
        _id: '6073b75556f56ef6389747a0',
        courseName: 'LIFESCI 2AA3 - Introduction to Topics in Life Sciences',
        courseCode: '2AA3',
        shortName: 'LIFESCI 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747a0',
      },
      {
        _id: '6073b75556f56ef6389747a2',
        courseName:
          'LIFESCI 2BP3 - Biophysics of the Cell and Living Organisms',
        courseCode: '2BP3',
        shortName: 'LIFESCI 2BP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747a2',
      },
      {
        _id: '6073b75556f56ef6389747a4',
        courseName: 'LIFESCI 2CC3 - Fundamentals of Neuroscience',
        courseCode: '2CC3',
        shortName: 'LIFESCI 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747a4',
      },
      {
        _id: '6073b75556f56ef6389747a6',
        courseName: 'LIFESCI 2D03 - Behavioural Processes',
        courseCode: '2D03',
        shortName: 'LIFESCI 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747a6',
      },
      {
        _id: '6073b75556f56ef6389747a8',
        courseName: 'LIFESCI 2G03 - Genes, Genomes and Society',
        courseCode: '2G03',
        shortName: 'LIFESCI 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747a8',
      },
      {
        _id: '6073b75556f56ef6389747aa',
        courseName: 'LIFESCI 2L03 - Living Systems Laboratory',
        courseCode: '2L03',
        shortName: 'LIFESCI 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747aa',
      },
      {
        _id: '6073b75556f56ef6389747ac',
        courseName: 'LIFESCI 2N03 - Human Nutrition for Life Sciences',
        courseCode: '2N03',
        shortName: 'LIFESCI 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747ac',
      },
      {
        _id: '6073b75556f56ef6389747ae',
        courseName: 'LIFESCI 2X03 - Environmental Change and Human Health',
        courseCode: '2X03',
        shortName: 'LIFESCI 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75556f56ef6389747ae',
      },
      {
        _id: '6073b75656f56ef6389747b0',
        courseName: 'LIFESCI 3AA3 - Human Pathophysiology',
        courseCode: '3AA3',
        shortName: 'LIFESCI 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747b0',
      },
      {
        _id: '6073b75656f56ef6389747b2',
        courseName: 'LIFESCI 3BB3 - Neurobiology of Disease',
        courseCode: '3BB3',
        shortName: 'LIFESCI 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747b2',
      },
      {
        _id: '6073b75656f56ef6389747b4',
        courseName: 'LIFESCI 3BM3 - Implanted Biomaterials',
        courseCode: '3BM3',
        shortName: 'LIFESCI 3BM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747b4',
      },
      {
        _id: '6073b75656f56ef6389747b6',
        courseName: 'LIFESCI 3BP3 - Modelling Life',
        courseCode: '3BP3',
        shortName: 'LIFESCI 3BP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747b6',
      },
      {
        _id: '6073b75656f56ef6389747b8',
        courseName: 'LIFESCI 3E03 - Reproductive Endocrinology',
        courseCode: '3E03',
        shortName: 'LIFESCI 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747b8',
      },
      {
        _id: '6073b75656f56ef6389747ba',
        courseName: 'LIFESCI 3EP3 - Life Sciences Applied Placement',
        courseCode: '3EP3',
        shortName: 'LIFESCI 3EP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747ba',
      },
      {
        _id: '6073b75656f56ef6389747bc',
        courseName: 'LIFESCI 3G03 - Introduction to Epidemiology',
        courseCode: '3G03',
        shortName: 'LIFESCI 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747bc',
      },
      {
        _id: '6073b75656f56ef6389747be',
        courseName: 'LIFESCI 3J03 - Human Biomechanics',
        courseCode: '3J03',
        shortName: 'LIFESCI 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747be',
      },
      {
        _id: '6073b75656f56ef6389747c0',
        courseName: 'LIFESCI 3K03 - Neural Control of Human Movement',
        courseCode: '3K03',
        shortName: 'LIFESCI 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747c0',
      },
      {
        _id: '6073b75656f56ef6389747c2',
        courseName: 'LIFESCI 3L03 - Laboratory Methods in Life Sciences',
        courseCode: '3L03',
        shortName: 'LIFESCI 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747c2',
      },
      {
        _id: '6073b75656f56ef6389747c4',
        courseName: 'LIFESCI 3LL3 - Living Systems Laboratory Practicum',
        courseCode: '3LL3',
        shortName: 'LIFESCI 3LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75656f56ef6389747c4',
      },
      {
        _id: '6073b75756f56ef6389747c6',
        courseName: 'LIFESCI 3M03 - Cellular Dynamics',
        courseCode: '3M03',
        shortName: 'LIFESCI 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747c6',
      },
      {
        _id: '6073b75756f56ef6389747c8',
        courseName: 'LIFESCI 3N03 - Human Nutritional Toxicology',
        courseCode: '3N03',
        shortName: 'LIFESCI 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747c8',
      },
      {
        _id: '6073b75756f56ef6389747ca',
        courseName: 'LIFESCI 3P03 - Science Communication in Life Sciences',
        courseCode: '3P03',
        shortName: 'LIFESCI 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747ca',
      },
      {
        _id: '6073b75756f56ef6389747cc',
        courseName: 'LIFESCI 3Q03 - Global Human Health and Disease',
        courseCode: '3Q03',
        shortName: 'LIFESCI 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747cc',
      },
      {
        _id: '6073b75756f56ef6389747ce',
        courseName: 'LIFESCI 3RC3 - Radioisotopes in Medicine',
        courseCode: '3RC3',
        shortName: 'LIFESCI 3RC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747ce',
      },
      {
        _id: '6073b75756f56ef6389747d0',
        courseName: 'LIFESCI 3RP3 - Life Sciences Research Practicum',
        courseCode: '3RP3',
        shortName: 'LIFESCI 3RP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747d0',
      },
      {
        _id: '6073b75756f56ef6389747d2',
        courseName: 'LIFESCI 3XX3 - Peer Mentoring in Science Communication',
        courseCode: '3XX3',
        shortName: 'LIFESCI 3XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747d2',
      },
      {
        _id: '6073b75756f56ef6389747d4',
        courseName:
          'LIFESCI 3YY3 - Peer Mentoring in Laboratory Skill Development',
        courseCode: '3YY3',
        shortName: 'LIFESCI 3YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747d4',
      },
      {
        _id: '6073b75756f56ef6389747d6',
        courseName: 'LIFESCI 3Z03 - Life Sciences Inquiry',
        courseCode: '3Z03',
        shortName: 'LIFESCI 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747d6',
      },
      {
        _id: '6073b75756f56ef6389747d8',
        courseName: 'LIFESCI 4A03 - Independent Study',
        courseCode: '4A03',
        shortName: 'LIFESCI 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747d8',
      },
      {
        _id: '6073b75756f56ef6389747da',
        courseName: 'LIFESCI 4B09 A/B S - Independent Project',
        courseCode: '4B09',
        shortName: 'LIFESCI 4B09 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747da',
      },
      {
        _id: '6073b75756f56ef6389747dc',
        courseName: 'LIFESCI 4C12 A/B S - Independent Thesis',
        courseCode: '4C12',
        shortName: 'LIFESCI 4C12 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75756f56ef6389747dc',
      },
      {
        _id: '6073b75856f56ef6389747de',
        courseName: 'LIFESCI 4CM3 - Foundations of Disease States Inquiry Lab',
        courseCode: '4CM3',
        shortName: 'LIFESCI 4CM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747de',
      },
      {
        _id: '6073b75856f56ef6389747e0',
        courseName: 'LIFESCI 4D15 A/B S - Independent Thesis',
        courseCode: '4D15',
        shortName: 'LIFESCI 4D15 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747e0',
      },
      {
        _id: '6073b75856f56ef6389747e2',
        courseName: 'LIFESCI 4E03 - Science & Storytelling',
        courseCode: '4E03',
        shortName: 'LIFESCI 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747e2',
      },
      {
        _id: '6073b75856f56ef6389747e4',
        courseName: 'LIFESCI 4EP6 A/B S - Life Sciences Advanced Placement',
        courseCode: '4EP6',
        shortName: 'LIFESCI 4EP6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747e4',
      },
      {
        _id: '6073b75856f56ef6389747e6',
        courseName:
          'LIFESCI 4F03 - Emerging Paradigms in Environmental Change and Health',
        courseCode: '4F03',
        shortName: 'LIFESCI 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747e6',
      },
      {
        _id: '6073b75856f56ef6389747e8',
        courseName:
          'LIFESCI 4H03 - Regeneration: What Can We Learn From Animal Models?',
        courseCode: '4H03',
        shortName: 'LIFESCI 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747e8',
      },
      {
        _id: '6073b75856f56ef6389747ea',
        courseName: 'LIFESCI 4I03 - Research Seminar',
        courseCode: '4I03',
        shortName: 'LIFESCI 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747ea',
      },
      {
        _id: '6073b75856f56ef6389747ec',
        courseName: 'LIFESCI 4J03 - Science Communication in the Media',
        courseCode: '4J03',
        shortName: 'LIFESCI 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747ec',
      },
      {
        _id: '6073b75856f56ef6389747ee',
        courseName: 'LIFESCI 4L03 - Research Seminar',
        courseCode: '4L03',
        shortName: 'LIFESCI 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747ee',
      },
      {
        _id: '6073b75856f56ef6389747f0',
        courseName: 'LIFESCI 4M03 - Research Seminar',
        courseCode: '4M03',
        shortName: 'LIFESCI 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747f0',
      },
      {
        _id: '6073b75856f56ef6389747f2',
        courseName: 'LIFESCI 4N03 - Research Seminar',
        courseCode: '4N03',
        shortName: 'LIFESCI 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75856f56ef6389747f2',
      },
      {
        _id: '6073b75956f56ef6389747f4',
        courseName: 'LIFESCI 4O03 - Research Seminar',
        courseCode: '4O03',
        shortName: 'LIFESCI 4O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef6389747f4',
      },
      {
        _id: '6073b75956f56ef6389747f6',
        courseName: 'LIFESCI 4P03 - Research Seminar',
        courseCode: '4P03',
        shortName: 'LIFESCI 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef6389747f6',
      },
      {
        _id: '6073b75956f56ef6389747f8',
        courseName: 'LIFESCI 4Q03 - Research Seminar',
        courseCode: '4Q03',
        shortName: 'LIFESCI 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef6389747f8',
      },
      {
        _id: '6073b75956f56ef6389747fa',
        courseName: 'LIFESCI 4U03 - Mechanisms of Disease',
        courseCode: '4U03',
        shortName: 'LIFESCI 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef6389747fa',
      },
      {
        _id: '6073b75956f56ef6389747fc',
        courseName:
          'LIFESCI 4V03 - Extracellular Vesicles in Health and Disease',
        courseCode: '4V03',
        shortName: 'LIFESCI 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef6389747fc',
      },
      {
        _id: '6073b75956f56ef6389747fe',
        courseName: 'LIFESCI 4W03 - Advanced Topics in Nutrition',
        courseCode: '4W03',
        shortName: 'LIFESCI 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef6389747fe',
      },
      {
        _id: '6073b75956f56ef638974800',
        courseName: 'LIFESCI 4X03 - The Biopsychology of Sex',
        courseCode: '4X03',
        shortName: 'LIFESCI 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef638974800',
      },
      {
        _id: '6073b75956f56ef638974802',
        courseName: 'LIFESCI 4XX3 - The Synapse',
        courseCode: '4XX3',
        shortName: 'LIFESCI 4XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef638974802',
      },
      {
        _id: '6073b75956f56ef638974804',
        courseName: 'LIFESCI 4Y03 - Applied Biomechanics',
        courseCode: '4Y03',
        shortName: 'LIFESCI 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef638974804',
      },
      {
        _id: '6073b75956f56ef638974806',
        courseName:
          'LINGUIST 1A03 - Introduction to Linguistics: Sounds, Speech and Hearing',
        courseCode: '1A03',
        shortName: 'LINGUIST 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef638974806',
      },
      {
        _id: '6073b75956f56ef638974808',
        courseName:
          'LINGUIST 1AA3 - Introduction to Linguistics: Words, Sentences and Meaning',
        courseCode: '1AA3',
        shortName: 'LINGUIST 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75956f56ef638974808',
      },
      {
        _id: '6073b75a56f56ef63897480a',
        courseName: 'LINGUIST 1Z03 - Structure of Modern English I',
        courseCode: '1Z03',
        shortName: 'LINGUIST 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef63897480a',
      },
      {
        _id: '6073b75a56f56ef63897480c',
        courseName: 'LINGUIST 1ZZ3 - Structure of Modern English II',
        courseCode: '1ZZ3',
        shortName: 'LINGUIST 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef63897480c',
      },
      {
        _id: '6073b75a56f56ef63897480e',
        courseName: 'LINGUIST 2D03 - Research Methods',
        courseCode: '2D03',
        shortName: 'LINGUIST 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef63897480e',
      },
      {
        _id: '6073b75a56f56ef638974810',
        courseName: 'LINGUIST 2DD3 - Statistics for Language Research',
        courseCode: '2DD3',
        shortName: 'LINGUIST 2DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974810',
      },
      {
        _id: '6073b75a56f56ef638974812',
        courseName:
          'LINGUIST 2E03 - The Nature of Texts: From Slang to Formal Discourse',
        courseCode: '2E03',
        shortName: 'LINGUIST 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974812',
      },
      {
        _id: '6073b75a56f56ef638974814',
        courseName: 'LINGUIST 2FL3 - Introduction to Forensic Linguistics',
        courseCode: '2FL3',
        shortName: 'LINGUIST 2FL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974814',
      },
      {
        _id: '6073b75a56f56ef638974816',
        courseName: 'LINGUIST 2L03 - Phonetics',
        courseCode: '2L03',
        shortName: 'LINGUIST 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974816',
      },
      {
        _id: '6073b75a56f56ef638974818',
        courseName:
          'LINGUIST 2LC3 - Historical Linguistics: Language Evolution and Change',
        courseCode: '2LC3',
        shortName: 'LINGUIST 2LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974818',
      },
      {
        _id: '6073b75a56f56ef63897481a',
        courseName: 'LINGUIST 2LL3 - Introduction to Linguistic Typology',
        courseCode: '2LL3',
        shortName: 'LINGUIST 2LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef63897481a',
      },
      {
        _id: '6073b75a56f56ef63897481c',
        courseName: 'LINGUIST 2PH3 - Phonology',
        courseCode: '2PH3',
        shortName: 'LINGUIST 2PH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef63897481c',
      },
      {
        _id: '6073b75a56f56ef63897481e',
        courseName: 'LINGUIST 2PS3 - Psycholinguistics',
        courseCode: '2PS3',
        shortName: 'LINGUIST 2PS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef63897481e',
      },
      {
        _id: '6073b75a56f56ef638974820',
        courseName: 'LINGUIST 2S03 - Language and Society',
        courseCode: '2S03',
        shortName: 'LINGUIST 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974820',
      },
      {
        _id: '6073b75a56f56ef638974822',
        courseName: 'LINGUIST 2SL3 - Introduction to American Sign Language',
        courseCode: '2SL3',
        shortName: 'LINGUIST 2SL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75a56f56ef638974822',
      },
      {
        _id: '6073b75b56f56ef638974824',
        courseName: 'LINGUIST 2SY3 - Syntax',
        courseCode: '2SY3',
        shortName: 'LINGUIST 2SY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974824',
      },
      {
        _id: '6073b75b56f56ef638974826',
        courseName: 'LINGUIST 3C03 - Child Language Acquisition',
        courseCode: '3C03',
        shortName: 'LINGUIST 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974826',
      },
      {
        _id: '6073b75b56f56ef638974828',
        courseName:
          'LINGUIST 3F03 - Anatomy and Physiology for Speech, Language and Hearing',
        courseCode: '3F03',
        shortName: 'LINGUIST 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974828',
      },
      {
        _id: '6073b75b56f56ef63897482a',
        courseName: 'LINGUIST 3II3 - Semantics',
        courseCode: '3II3',
        shortName: 'LINGUIST 3II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef63897482a',
      },
      {
        _id: '6073b75b56f56ef63897482c',
        courseName:
          'LINGUIST 3LA3 - Introduction to Second Language Acquisition',
        courseCode: '3LA3',
        shortName: 'LINGUIST 3LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef63897482c',
      },
      {
        _id: '6073b75b56f56ef63897482e',
        courseName: 'LINGUIST 3M03 - Morphology',
        courseCode: '3M03',
        shortName: 'LINGUIST 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef63897482e',
      },
      {
        _id: '6073b75b56f56ef638974830',
        courseName: 'LINGUIST 3NL3 - Cognitive Neuroscience of Language',
        courseCode: '3NL3',
        shortName: 'LINGUIST 3NL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974830',
      },
      {
        _id: '6073b75b56f56ef638974832',
        courseName: 'LINGUIST 3P03 - Pragmatics',
        courseCode: '3P03',
        shortName: 'LINGUIST 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974832',
      },
      {
        _id: '6073b75b56f56ef638974834',
        courseName: 'LINGUIST 3RP3 A/B S - Individual Research Practicum',
        courseCode: '3RP3',
        shortName: 'LINGUIST 3RP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974834',
      },
      {
        _id: '6073b75b56f56ef638974836',
        courseName: 'LINGUIST 3SL3 - Intermediate American Sign Language',
        courseCode: '3SL3',
        shortName: 'LINGUIST 3SL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75b56f56ef638974836',
      },
      {
        _id: '6073b75c56f56ef638974838',
        courseName: 'LINGUIST 3TT3 - Perspectives on Translation',
        courseCode: '3TT3',
        shortName: 'LINGUIST 3TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef638974838',
      },
      {
        _id: '6073b75c56f56ef63897483a',
        courseName: 'LINGUIST 4AA3 - Seminar in Applied Linguistics',
        courseCode: '4AA3',
        shortName: 'LINGUIST 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef63897483a',
      },
      {
        _id: '6073b75c56f56ef63897483c',
        courseName: 'LINGUIST 4AS3 - Topics in Advanced Semantics',
        courseCode: '4AS3',
        shortName: 'LINGUIST 4AS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef63897483c',
      },
      {
        _id: '6073b75c56f56ef63897483e',
        courseName: 'LINGUIST 4D03 - Computers and Linguistic Analysis',
        courseCode: '4D03',
        shortName: 'LINGUIST 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef63897483e',
      },
      {
        _id: '6073b75c56f56ef638974840',
        courseName:
          'LINGUIST 4E03 - English as a Second Language (ESL) Teaching Methods',
        courseCode: '4E03',
        shortName: 'LINGUIST 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef638974840',
      },
      {
        _id: '6073b75c56f56ef638974842',
        courseName: 'LINGUIST 4EL3 - Laboratory in Experimental Linguistics',
        courseCode: '4EL3',
        shortName: 'LINGUIST 4EL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef638974842',
      },
      {
        _id: '6073b75c56f56ef638974844',
        courseName: 'LINGUIST 4G03 - Language, Sex and Gender',
        courseCode: '4G03',
        shortName: 'LINGUIST 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef638974844',
      },
      {
        _id: '6073b75c56f56ef638974846',
        courseName:
          'LINGUIST 4HL3 - Heritage Languages in the Hamilton Diaspora',
        courseCode: '4HL3',
        shortName: 'LINGUIST 4HL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef638974846',
      },
      {
        _id: '6073b75c56f56ef638974848',
        courseName: 'LINGUIST 4II3 A/B S - Independent Study',
        courseCode: '4II3',
        shortName: 'LINGUIST 4II3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef638974848',
      },
      {
        _id: '6073b75c56f56ef63897484a',
        courseName: 'LINGUIST 4LB3 - Advanced Phonetics and Phonology',
        courseCode: '4LB3',
        shortName: 'LINGUIST 4LB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef63897484a',
      },
      {
        _id: '6073b75c56f56ef63897484c',
        courseName: 'LINGUIST 4LC3 - Advanced Morphology and Syntax',
        courseCode: '4LC3',
        shortName: 'LINGUIST 4LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef63897484c',
      },
      {
        _id: '6073b75c56f56ef63897484e',
        courseName: 'LINGUIST 4LX3 - The Structure of X',
        courseCode: '4LX3',
        shortName: 'LINGUIST 4LX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75c56f56ef63897484e',
      },
      {
        _id: '6073b75d56f56ef638974850',
        courseName: 'LINGUIST 4NN3 - Cognitive Neurolinguistics Laboratory',
        courseCode: '4NN3',
        shortName: 'LINGUIST 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974850',
      },
      {
        _id: '6073b75d56f56ef638974852',
        courseName: 'LINGUIST 4PL3 - Programming for Linguists',
        courseCode: '4PL3',
        shortName: 'LINGUIST 4PL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974852',
      },
      {
        _id: '6073b75d56f56ef638974854',
        courseName: 'LINGUIST 4R03 - Cross-Cultural Communication',
        courseCode: '4R03',
        shortName: 'LINGUIST 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974854',
      },
      {
        _id: '6073b75d56f56ef638974856',
        courseName: 'LINGUIST 4S03 - Interpersonal Communication',
        courseCode: '4S03',
        shortName: 'LINGUIST 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974856',
      },
      {
        _id: '6073b75d56f56ef638974858',
        courseName: 'LINGUIST 4SL3 - SLP Practicum',
        courseCode: '4SL3',
        shortName: 'LINGUIST 4SL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974858',
      },
      {
        _id: '6073b75d56f56ef63897485a',
        courseName: 'LINGUIST 4SS3 - Seminar in Sociolinguistics',
        courseCode: '4SS3',
        shortName: 'LINGUIST 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef63897485a',
      },
      {
        _id: '6073b75d56f56ef63897485c',
        courseName: 'LINGUIST 4TE3 - TESL Practicum',
        courseCode: '4TE3',
        shortName: 'LINGUIST 4TE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef63897485c',
      },
      {
        _id: '6073b75d56f56ef63897485e',
        courseName: 'LINGUIST 4XX3 - Topics in Linguistic Theory',
        courseCode: '4XX3',
        shortName: 'LINGUIST 4XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef63897485e',
      },
      {
        _id: '6073b75d56f56ef638974860',
        courseName: 'LINGUIST 4Y06 A/B - Honours Thesis',
        courseCode: '4Y06',
        shortName: 'LINGUIST 4Y06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974860',
      },
      {
        _id: '6073b75d56f56ef638974862',
        courseName: 'MANTECH 3LS3 - Quality Control and Assurance Methods',
        courseCode: '3LS3',
        shortName: 'MANTECH 3LS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974862',
      },
      {
        _id: '6073b75d56f56ef638974864',
        courseName: 'MANTECH 3MF3 - Micro Manufacturing and Fabrication',
        courseCode: '3MF3',
        shortName: 'MANTECH 3MF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974864',
      },
      {
        _id: '6073b75d56f56ef638974866',
        courseName: 'MANTECH 4DA3 - Design and Advanced Manufacturing',
        courseCode: '4DA3',
        shortName: 'MANTECH 4DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75d56f56ef638974866',
      },
      {
        _id: '6073b75e56f56ef638974868',
        courseName: 'MANTECH 4FM3 - CIM and Flexible Manufacturing',
        courseCode: '4FM3',
        shortName: 'MANTECH 4FM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef638974868',
      },
      {
        _id: '6073b75e56f56ef63897486a',
        courseName:
          'MANTECH 4MM3 - Design and Manufacturing of Machine Elements',
        courseCode: '4MM3',
        shortName: 'MANTECH 4MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef63897486a',
      },
      {
        _id: '6073b75e56f56ef63897486c',
        courseName: 'MANTECH 4PM3 - Production Management',
        courseCode: '4PM3',
        shortName: 'MANTECH 4PM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef63897486c',
      },
      {
        _id: '6073b75e56f56ef63897486e',
        courseName: 'MANTECH 4RM3 - Robot Mechanics and Mechatronics',
        courseCode: '4RM3',
        shortName: 'MANTECH 4RM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef63897486e',
      },
      {
        _id: '6073b75e56f56ef638974870',
        courseName: 'MATLS 1M03 - Structure and Properties of Materials',
        courseCode: '1M03',
        shortName: 'MATLS 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef638974870',
      },
      {
        _id: '6073b75e56f56ef638974872',
        courseName:
          'MATLS 2B03 - Introduction to the Thermodynamics of Materials',
        courseCode: '2B03',
        shortName: 'MATLS 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef638974872',
      },
      {
        _id: '6073b75e56f56ef638974874',
        courseName: 'MATLS 2D03 - Thermodynamics of Alloys and Phase Diagrams',
        courseCode: '2D03',
        shortName: 'MATLS 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef638974874',
      },
      {
        _id: '6073b75e56f56ef638974876',
        courseName:
          'MATLS 2H04 A/B - Integrated Materials Engineering Laboratory',
        courseCode: '2H04',
        shortName: 'MATLS 2H04 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef638974876',
      },
      {
        _id: '6073b75e56f56ef638974878',
        courseName: 'MATLS 2Q03 - Electronic Properties of Materials',
        courseCode: '2Q03',
        shortName: 'MATLS 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 23,
        id: '6073b75e56f56ef638974878',
      },
      {
        _id: '6073b75e56f56ef63897487a',
        courseName: 'MATLS 2X03 - Crystalline Structure of Materials',
        courseCode: '2X03',
        shortName: 'MATLS 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef63897487a',
      },
      {
        _id: '6073b75e56f56ef63897487c',
        courseName:
          'MATLS 3B03 - Manufacturing Engineering of Multifunctional and Biomedical Materials',
        courseCode: '3B03',
        shortName: 'MATLS 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef63897487c',
      },
      {
        _id: '6073b75e56f56ef63897487e',
        courseName: 'MATLS 3C03 - Applied Thermodynamics',
        courseCode: '3C03',
        shortName: 'MATLS 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75e56f56ef63897487e',
      },
      {
        _id: '6073b75f56f56ef638974880',
        courseName: 'MATLS 3E04 - Mass Transfer',
        courseCode: '3E04',
        shortName: 'MATLS 3E04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974880',
      },
      {
        _id: '6073b75f56f56ef638974882',
        courseName: 'MATLS 3F03 - High-Temperature Materials Production',
        courseCode: '3F03',
        shortName: 'MATLS 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974882',
      },
      {
        _id: '6073b75f56f56ef638974884',
        courseName: 'MATLS 3J03 - Statistical Methods for Materials Engineers',
        courseCode: '3J03',
        shortName: 'MATLS 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974884',
      },
      {
        _id: '6073b75f56f56ef638974886',
        courseName: 'MATLS 3M03 - Mechanical Behaviour of Materials',
        courseCode: '3M03',
        shortName: 'MATLS 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974886',
      },
      {
        _id: '6073b75f56f56ef638974888',
        courseName: 'MATLS 3Q03 - Materials for Electronic Applications',
        courseCode: '3Q03',
        shortName: 'MATLS 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974888',
      },
      {
        _id: '6073b75f56f56ef63897488a',
        courseName: 'MATLS 3T04 - Phase Transformations',
        courseCode: '3T04',
        shortName: 'MATLS 3T04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef63897488a',
      },
      {
        _id: '6073b75f56f56ef63897488c',
        courseName: 'MATLS 4B03 - Biomaterials and Tissue Engineering',
        courseCode: '4B03',
        shortName: 'MATLS 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef63897488c',
      },
      {
        _id: '6073b75f56f56ef63897488e',
        courseName: 'MATLS 4C03 - Modern Iron and Steelmaking',
        courseCode: '4C03',
        shortName: 'MATLS 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef63897488e',
      },
      {
        _id: '6073b75f56f56ef638974890',
        courseName: 'MATLS 4D03 - Corrosion and its Control',
        courseCode: '4D03',
        shortName: 'MATLS 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974890',
      },
      {
        _id: '6073b75f56f56ef638974892',
        courseName:
          'MATLS 4FF3 - Synthesis, Applications and Environmental Impact of Nanomaterials',
        courseCode: '4FF3',
        shortName: 'MATLS 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974892',
      },
      {
        _id: '6073b75f56f56ef638974894',
        courseName: 'MATLS 4G03 - Characterization of Nanomaterials',
        courseCode: '4G03',
        shortName: 'MATLS 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b75f56f56ef638974894',
      },
      {
        _id: '6073b76056f56ef638974896',
        courseName: 'MATLS 4H03 - Thin Film Science and Engineering',
        courseCode: '4H03',
        shortName: 'MATLS 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef638974896',
      },
      {
        _id: '6073b76056f56ef638974898',
        courseName: 'MATLS 4I03 - Sustainable Manufacturing Processes',
        courseCode: '4I03',
        shortName: 'MATLS 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef638974898',
      },
      {
        _id: '6073b76056f56ef63897489a',
        courseName: 'MATLS 4KA3 - Research Project 1',
        courseCode: '4KA3',
        shortName: 'MATLS 4KA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef63897489a',
      },
      {
        _id: '6073b76056f56ef63897489c',
        courseName: 'MATLS 4KB3 - Research Project 2',
        courseCode: '4KB3',
        shortName: 'MATLS 4KB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef63897489c',
      },
      {
        _id: '6073b76056f56ef63897489e',
        courseName: 'MATLS 4LA2 - Heat-Treatable Al Alloys',
        courseCode: '4LA2',
        shortName: 'MATLS 4LA2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef63897489e',
      },
      {
        _id: '6073b76056f56ef6389748a0',
        courseName:
          'MATLS 4LB2 - Synthesis and Characterization of Biomedical Coatings',
        courseCode: '4LB2',
        shortName: 'MATLS 4LB2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748a0',
      },
      {
        _id: '6073b76056f56ef6389748a2',
        courseName:
          'MATLS 4LF2 - Synthesis, Properties and Application of Thin Films',
        courseCode: '4LF2',
        shortName: 'MATLS 4LF2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748a2',
      },
      {
        _id: '6073b76056f56ef6389748a4',
        courseName: 'MATLS 4LS2 - Physical Metallurgy of Steels',
        courseCode: '4LS2',
        shortName: 'MATLS 4LS2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748a4',
      },
      {
        _id: '6073b76056f56ef6389748a6',
        courseName:
          'MATLS 4ML3 - Applications of Machine Learning to Materials and Chemical Engineering',
        courseCode: '4ML3',
        shortName: 'MATLS 4ML3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748a6',
      },
      {
        _id: '6073b76056f56ef6389748a8',
        courseName:
          'MATLS 4NN3 - Computational Modelling in Materials Engineering at the Nano-scale',
        courseCode: '4NN3',
        shortName: 'MATLS 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748a8',
      },
      {
        _id: '6073b76056f56ef6389748aa',
        courseName:
          'MATLS 4NP3 - Computational Modelling in Materials Engineering at the Process-Scale',
        courseCode: '4NP3',
        shortName: 'MATLS 4NP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748aa',
      },
      {
        _id: '6073b76056f56ef6389748ac',
        courseName: 'MATLS 4P03 - Properties of Polymeric Materials',
        courseCode: '4P03',
        shortName: 'MATLS 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76056f56ef6389748ac',
      },
      {
        _id: '6073b76156f56ef6389748ae',
        courseName:
          'MATLS 4Q03 - Materials for Sensors in Big Data and AI Systems',
        courseCode: '4Q03',
        shortName: 'MATLS 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748ae',
      },
      {
        _id: '6073b76156f56ef6389748b0',
        courseName: 'MATLS 4T03 - Properties and Processing of Composites',
        courseCode: '4T03',
        shortName: 'MATLS 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748b0',
      },
      {
        _id: '6073b76156f56ef6389748b2',
        courseName:
          'MATLS 4Y03 - Advanced Biomaterials: Applications and Device Design',
        courseCode: '4Y03',
        shortName: 'MATLS 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748b2',
      },
      {
        _id: '6073b76156f56ef6389748b4',
        courseName: 'MATLS 4Z06 A/B - Materials Engineering Capstone',
        courseCode: '4Z06',
        shortName: 'MATLS 4Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748b4',
      },
      {
        _id: '6073b76156f56ef6389748b6',
        courseName: 'MATH 1A03 - Calculus For Science I',
        courseCode: '1A03',
        shortName: 'MATH 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 7,
        id: '6073b76156f56ef6389748b6',
      },
      {
        _id: '6073b76156f56ef6389748b8',
        courseName: 'MATH 1AA3 - Calculus For Science II',
        courseCode: '1AA3',
        shortName: 'MATH 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748b8',
      },
      {
        _id: '6073b76156f56ef6389748ba',
        courseName: 'MATH 1B03 - Linear Algebra I',
        courseCode: '1B03',
        shortName: 'MATH 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748ba',
      },
      {
        _id: '6073b76156f56ef6389748bc',
        courseName: 'MATH 1C03 - Introduction to Mathematical Reasoning',
        courseCode: '1C03',
        shortName: 'MATH 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748bc',
      },
      {
        _id: '6073b76156f56ef6389748be',
        courseName:
          'MATH 1F03 - Introduction to Calculus and Analytic Geometry',
        courseCode: '1F03',
        shortName: 'MATH 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748be',
      },
      {
        _id: '6073b76156f56ef6389748c0',
        courseName:
          'MATH 1K03 - Advanced Functions & Introductory Calculus for Humanities and the Social Sciences',
        courseCode: '1K03',
        shortName: 'MATH 1K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748c0',
      },
      {
        _id: '6073b76156f56ef6389748c2',
        courseName: 'MATH 1LT3 - Calculus for the Life Sciences II',
        courseCode: '1LT3',
        shortName: 'MATH 1LT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76156f56ef6389748c2',
      },
      {
        _id: '6073b76256f56ef6389748c4',
        courseName:
          'MATH 1M03 - Calculus for Business, Humanities and the Social Sciences',
        courseCode: '1M03',
        shortName: 'MATH 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748c4',
      },
      {
        _id: '6073b76256f56ef6389748c6',
        courseName:
          'MATH 1MP3 - Introduction to Mathematical Scientific Computation',
        courseCode: '1MP3',
        shortName: 'MATH 1MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748c6',
      },
      {
        _id: '6073b76256f56ef6389748c8',
        courseName: 'MATH 1X03 - Calculus for Math and Stats I',
        courseCode: '1X03',
        shortName: 'MATH 1X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748c8',
      },
      {
        _id: '6073b76256f56ef6389748ca',
        courseName: 'MATH 1XA3 A/B - Proofs in Calculus',
        courseCode: '1XA3',
        shortName: 'MATH 1XA3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748ca',
      },
      {
        _id: '6073b76256f56ef6389748cc',
        courseName: 'MATH 1XX3 - Calculus for Math and Stats II',
        courseCode: '1XX3',
        shortName: 'MATH 1XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748cc',
      },
      {
        _id: '6073b76256f56ef6389748ce',
        courseName: 'MATH 2C03 - Introduction to Differential Equations',
        courseCode: '2C03',
        shortName: 'MATH 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748ce',
      },
      {
        _id: '6073b76256f56ef6389748d0',
        courseName: 'MATH 2ET3* - Theory and Practice of Teaching Mathematics',
        courseCode: '2ET3*',
        shortName: 'MATH 2ET3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748d0',
      },
      {
        _id: '6073b76256f56ef6389748d2',
        courseName: 'MATH 2FM3 - Introduction To Mathematical Finance',
        courseCode: '2FM3',
        shortName: 'MATH 2FM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748d2',
      },
      {
        _id: '6073b76256f56ef6389748d4',
        courseName:
          'MATH 2L03 - Mathematical Methods for Business and Social Sciences',
        courseCode: '2L03',
        shortName: 'MATH 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748d4',
      },
      {
        _id: '6073b76256f56ef6389748d6',
        courseName: 'MATH 2LA3 - Applied Linear Algebra',
        courseCode: '2LA3',
        shortName: 'MATH 2LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748d6',
      },
      {
        _id: '6073b76256f56ef6389748d8',
        courseName: 'MATH 2R03 - Linear Algebra II',
        courseCode: '2R03',
        shortName: 'MATH 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748d8',
      },
      {
        _id: '6073b76256f56ef6389748da',
        courseName: 'MATH 2UU3 - Numbers for Life',
        courseCode: '2UU3',
        shortName: 'MATH 2UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76256f56ef6389748da',
      },
      {
        _id: '6073b76356f56ef6389748dc',
        courseName: 'MATH 2X03 - Advanced Calculus I',
        courseCode: '2X03',
        shortName: 'MATH 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '6073b76356f56ef6389748dc',
      },
      {
        _id: '6073b76356f56ef6389748de',
        courseName: 'MATH 2XX3 - Advanced Calculus II',
        courseCode: '2XX3',
        shortName: 'MATH 2XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748de',
      },
      {
        _id: '6073b76356f56ef6389748e0',
        courseName: 'MATH 2Z03 - Engineering Mathematics III',
        courseCode: '2Z03',
        shortName: 'MATH 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '6073b76356f56ef6389748e0',
      },
      {
        _id: '6073b76356f56ef6389748e2',
        courseName: 'MATH 2ZZ3 - Engineering Mathematics IV',
        courseCode: '2ZZ3',
        shortName: 'MATH 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748e2',
      },
      {
        _id: '6073b76356f56ef6389748e4',
        courseName: 'MATH 3A03 - Real Analysis I',
        courseCode: '3A03',
        shortName: 'MATH 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748e4',
      },
      {
        _id: '6073b76356f56ef6389748e6',
        courseName: 'MATH 3B03 - Geometry',
        courseCode: '3B03',
        shortName: 'MATH 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748e6',
      },
      {
        _id: '6073b76356f56ef6389748e8',
        courseName: 'MATH 3C03 - Mathematical Physics I',
        courseCode: '3C03',
        shortName: 'MATH 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748e8',
      },
      {
        _id: '6073b76356f56ef6389748ea',
        courseName: 'MATH 3CY3 - Cryptography',
        courseCode: '3CY3',
        shortName: 'MATH 3CY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748ea',
      },
      {
        _id: '6073b76356f56ef6389748ec',
        courseName: 'MATH 3D03 - Mathematical Physics II',
        courseCode: '3D03',
        shortName: 'MATH 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748ec',
      },
      {
        _id: '6073b76356f56ef6389748ee',
        courseName: 'MATH 3DC3 - Discrete Dynamical Systems and Chaos',
        courseCode: '3DC3',
        shortName: 'MATH 3DC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748ee',
      },
      {
        _id: '6073b76356f56ef6389748f0',
        courseName: 'MATH 3ET3 A/B S - Mathematics Teaching Placement',
        courseCode: '3ET3',
        shortName: 'MATH 3ET3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748f0',
      },
      {
        _id: '6073b76356f56ef6389748f2',
        courseName: 'MATH 3F03 - Ordinary Differential Equations',
        courseCode: '3F03',
        shortName: 'MATH 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76356f56ef6389748f2',
      },
      {
        _id: '6073b76456f56ef6389748f4',
        courseName: 'MATH 3FF3 - Partial Differential Equations',
        courseCode: '3FF3',
        shortName: 'MATH 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef6389748f4',
      },
      {
        _id: '6073b76456f56ef6389748f6',
        courseName: 'MATH 3FM3 - Mathematics of Finance',
        courseCode: '3FM3',
        shortName: 'MATH 3FM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef6389748f6',
      },
      {
        _id: '6073b76456f56ef6389748f8',
        courseName: 'MATH 3G03 - Problem Solving',
        courseCode: '3G03',
        shortName: 'MATH 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef6389748f8',
      },
      {
        _id: '6073b76456f56ef6389748fa',
        courseName: 'MATH 3GR3 - Abstract Algebra',
        courseCode: '3GR3',
        shortName: 'MATH 3GR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef6389748fa',
      },
      {
        _id: '6073b76456f56ef6389748fc',
        courseName: 'MATH 3H03* - Number Theory',
        courseCode: '3H03*',
        shortName: 'MATH 3H03*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef6389748fc',
      },
      {
        _id: '6073b76456f56ef6389748fe',
        courseName:
          'MATH 3I03 - Partial Differential Equations for Engineering',
        courseCode: '3I03',
        shortName: 'MATH 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef6389748fe',
      },
      {
        _id: '6073b76456f56ef638974900',
        courseName: 'MATH 3IA3 - Introduction to Analysis',
        courseCode: '3IA3',
        shortName: 'MATH 3IA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef638974900',
      },
      {
        _id: '6073b76456f56ef638974902',
        courseName: 'MATH 3MB3 - Introduction to Modelling',
        courseCode: '3MB3',
        shortName: 'MATH 3MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef638974902',
      },
      {
        _id: '6073b76456f56ef638974904',
        courseName: 'MATH 3NA3 - Numerical Linear Algebra',
        courseCode: '3NA3',
        shortName: 'MATH 3NA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef638974904',
      },
      {
        _id: '6073b76456f56ef638974906',
        courseName: 'MATH 3QC3 - Introduction to Quantum Computing',
        courseCode: '3QC3',
        shortName: 'MATH 3QC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef638974906',
      },
      {
        _id: '6073b76456f56ef638974908',
        courseName: 'MATH 3T03 - Inquiry in Topology',
        courseCode: '3T03',
        shortName: 'MATH 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef638974908',
      },
      {
        _id: '6073b76456f56ef63897490a',
        courseName: 'MATH 3TP3 - Truth and Provability',
        courseCode: '3TP3',
        shortName: 'MATH 3TP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76456f56ef63897490a',
      },
      {
        _id: '6073b76556f56ef63897490c',
        courseName: 'MATH 3U03 - Combinatorics',
        courseCode: '3U03',
        shortName: 'MATH 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef63897490c',
      },
      {
        _id: '6073b76556f56ef63897490e',
        courseName: 'MATH 3V03 - Graph Theory',
        courseCode: '3V03',
        shortName: 'MATH 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef63897490e',
      },
      {
        _id: '6073b76556f56ef638974910',
        courseName: 'MATH 3X03 - Complex Analysis I',
        courseCode: '3X03',
        shortName: 'MATH 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef638974910',
      },
      {
        _id: '6073b76556f56ef638974912',
        courseName: 'MATH 3Z03 - Inquiry: History of Mathematics',
        courseCode: '3Z03',
        shortName: 'MATH 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef638974912',
      },
      {
        _id: '6073b76556f56ef638974914',
        courseName: 'MATH 4A03 - Real Analysis II',
        courseCode: '4A03',
        shortName: 'MATH 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef638974914',
      },
      {
        _id: '6073b76556f56ef638974916',
        courseName: 'MATH 4AT3* - Topics in Analysis',
        courseCode: '4AT3*',
        shortName: 'MATH 4AT3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef638974916',
      },
      {
        _id: '6073b76556f56ef638974918',
        courseName: 'MATH 4B03* - Calculus on Manifolds',
        courseCode: '4B03*',
        shortName: 'MATH 4B03*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef638974918',
      },
      {
        _id: '6073b76556f56ef63897491a',
        courseName: 'MATH 4BT3* - Topics in Geometry',
        courseCode: '4BT3*',
        shortName: 'MATH 4BT3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef63897491a',
      },
      {
        _id: '6073b76556f56ef63897491c',
        courseName: 'MATH 4E03 - Galois Theory',
        courseCode: '4E03',
        shortName: 'MATH 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef63897491c',
      },
      {
        _id: '6073b76556f56ef63897491e',
        courseName: 'MATH 4ET3* - Topics in Algebra',
        courseCode: '4ET3*',
        shortName: 'MATH 4ET3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef63897491e',
      },
      {
        _id: '6073b76556f56ef638974920',
        courseName: 'MATH 4FM3 - Financial Markets and Derivatives',
        courseCode: '4FM3',
        shortName: 'MATH 4FM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76556f56ef638974920',
      },
      {
        _id: '6073b76656f56ef638974922',
        courseName: 'MATH 4FT3 - Topics in Differential Equations',
        courseCode: '4FT3',
        shortName: 'MATH 4FT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974922',
      },
      {
        _id: '6073b76656f56ef638974924',
        courseName: 'MATH 4GR3 - Groups and Rings',
        courseCode: '4GR3',
        shortName: 'MATH 4GR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974924',
      },
      {
        _id: '6073b76656f56ef638974926',
        courseName: 'MATH 4L03* - Introduction to Mathematical Logic',
        courseCode: '4L03*',
        shortName: 'MATH 4L03*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974926',
      },
      {
        _id: '6073b76656f56ef638974928',
        courseName: 'MATH 4LT3* - Topics in Logic',
        courseCode: '4LT3*',
        shortName: 'MATH 4LT3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974928',
      },
      {
        _id: '6073b76656f56ef63897492a',
        courseName: 'MATH 4MB3 - Mathematical Biology',
        courseCode: '4MB3',
        shortName: 'MATH 4MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef63897492a',
      },
      {
        _id: '6073b76656f56ef63897492c',
        courseName: 'MATH 4NA3 - Numerical Methods for Differential Equations',
        courseCode: '4NA3',
        shortName: 'MATH 4NA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef63897492c',
      },
      {
        _id: '6073b76656f56ef63897492e',
        courseName: 'MATH 4P06 A/B S - Senior Research Project',
        courseCode: '4P06',
        shortName: 'MATH 4P06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef63897492e',
      },
      {
        _id: '6073b76656f56ef638974930',
        courseName: 'MATH 4TT3* - Topics in Topology',
        courseCode: '4TT3*',
        shortName: 'MATH 4TT3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974930',
      },
      {
        _id: '6073b76656f56ef638974932',
        courseName: 'MATH 4W03 - Reading in Mathematics',
        courseCode: '4W03',
        shortName: 'MATH 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974932',
      },
      {
        _id: '6073b76656f56ef638974934',
        courseName: 'MATH 4WW3 - Reading in Mathematics II',
        courseCode: '4WW3',
        shortName: 'MATH 4WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974934',
      },
      {
        _id: '6073b76656f56ef638974936',
        courseName: 'MATH 4X03* - Complex Analysis II',
        courseCode: '4X03*',
        shortName: 'MATH 4X03*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974936',
      },
      {
        _id: '6073b76656f56ef638974938',
        courseName: 'MATH 5GT3 - Graduate Level Topics in Mathematics',
        courseCode: '5GT3',
        shortName: 'MATH 5GT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76656f56ef638974938',
      },
      {
        _id: '6073b76756f56ef63897493a',
        courseName: 'MELD 1A03 - Academic Writing and Integrity',
        courseCode: '1A03',
        shortName: 'MELD 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef63897493a',
      },
      {
        _id: '6073b76756f56ef63897493c',
        courseName: 'MELD 1AA3 - Advanced Academic Writing',
        courseCode: '1AA3',
        shortName: 'MELD 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef63897493c',
      },
      {
        _id: '6073b76756f56ef63897493e',
        courseName: 'MELD 1B03 - English Phonetics and Pronunciation',
        courseCode: '1B03',
        shortName: 'MELD 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef63897493e',
      },
      {
        _id: '6073b76756f56ef638974940',
        courseName: 'MELD 1BB3 - Advanced Speaking and Presentation Skills',
        courseCode: '1BB3',
        shortName: 'MELD 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef638974940',
      },
      {
        _id: '6073b76756f56ef638974942',
        courseName: 'MELD 1C03 - Academic Reading Skills',
        courseCode: '1C03',
        shortName: 'MELD 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef638974942',
      },
      {
        _id: '6073b76756f56ef638974944',
        courseName: 'MELD 1CC3 - Advanced Academic Reading Skills',
        courseCode: '1CC3',
        shortName: 'MELD 1CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef638974944',
      },
      {
        _id: '6073b76756f56ef638974946',
        courseName: 'MELD 1D03 - Social Perspectives on Language',
        courseCode: '1D03',
        shortName: 'MELD 1D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef638974946',
      },
      {
        _id: '6073b76756f56ef638974948',
        courseName: 'MELD 1DD3 - Advanced Academic Listening Skills',
        courseCode: '1DD3',
        shortName: 'MELD 1DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef638974948',
      },
      {
        _id: '6073b76756f56ef63897494a',
        courseName: 'MELD 1L00 - Linguistics Lab 1',
        courseCode: '1L00',
        shortName: 'MELD 1L00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef63897494a',
      },
      {
        _id: '6073b76756f56ef63897494c',
        courseName: 'MELD 1LL0 - Linguistics Lab 2',
        courseCode: '1LL0',
        shortName: 'MELD 1LL0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef63897494c',
      },
      {
        _id: '6073b76756f56ef63897494e',
        courseName: 'MELD 1M00 - Mentorship Lab 1',
        courseCode: '1M00',
        shortName: 'MELD 1M00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef63897494e',
      },
      {
        _id: '6073b76756f56ef638974950',
        courseName: 'MELD 1MM0 - Mentorship Lab 2',
        courseCode: '1MM0',
        shortName: 'MELD 1MM0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76756f56ef638974950',
      },
      {
        _id: '6073b76856f56ef638974952',
        courseName: 'MECHENG 2A03 - Design Communication',
        courseCode: '2A03',
        shortName: 'MECHENG 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974952',
      },
      {
        _id: '6073b76856f56ef638974954',
        courseName: 'MECHENG 2B03 - Mechanical Engineering Measurements',
        courseCode: '2B03',
        shortName: 'MECHENG 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974954',
      },
      {
        _id: '6073b76856f56ef638974956',
        courseName: 'MECHENG 2BA3 - Mechanical Engineering Measurements',
        courseCode: '2BA3',
        shortName: 'MECHENG 2BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974956',
      },
      {
        _id: '6073b76856f56ef638974958',
        courseName: 'MECHENG 2C04 - Mechanical Engineering Design I',
        courseCode: '2C04',
        shortName: 'MECHENG 2C04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974958',
      },
      {
        _id: '6073b76856f56ef63897495a',
        courseName: 'MECHENG 2D03 - Mechanical Engineering Design Elements',
        courseCode: '2D03',
        shortName: 'MECHENG 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef63897495a',
      },
      {
        _id: '6073b76856f56ef63897495c',
        courseName: 'MECHENG 2DA3 - Mechanical Engineering Design Elements',
        courseCode: '2DA3',
        shortName: 'MECHENG 2DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef63897495c',
      },
      {
        _id: '6073b76856f56ef63897495e',
        courseName: 'MECHENG 2P04 - Statics and Mechanics of Materials',
        courseCode: '2P04',
        shortName: 'MECHENG 2P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef63897495e',
      },
      {
        _id: '6073b76856f56ef638974960',
        courseName:
          'MECHENG 2Q04 - Engineering Mechanics: Kinetics and Dynamics',
        courseCode: '2Q04',
        shortName: 'MECHENG 2Q04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974960',
      },
      {
        _id: '6073b76856f56ef638974962',
        courseName:
          'MECHENG 2QA4 - Engineering Mechanics: Kinetics and Dynamics',
        courseCode: '2QA4',
        shortName: 'MECHENG 2QA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974962',
      },
      {
        _id: '6073b76856f56ef638974964',
        courseName: 'MECHENG 2W04 - Thermodynamics',
        courseCode: '2W04',
        shortName: 'MECHENG 2W04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974964',
      },
      {
        _id: '6073b76856f56ef638974966',
        courseName: 'MECHENG 3A03 - Engineering Mechanics',
        courseCode: '3A03',
        shortName: 'MECHENG 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974966',
      },
      {
        _id: '6073b76856f56ef638974968',
        courseName: 'MECHENG 3C03 - Manufacturing Engineering',
        courseCode: '3C03',
        shortName: 'MECHENG 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76856f56ef638974968',
      },
      {
        _id: '6073b76956f56ef63897496a',
        courseName: 'MECHENG 3E05 - Mechanical Engineering Design II',
        courseCode: '3E05',
        shortName: 'MECHENG 3E05',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef63897496a',
      },
      {
        _id: '6073b76956f56ef63897496c',
        courseName: 'MECHENG 3F04 - Modelling and Numerical Solutions',
        courseCode: '3F04',
        shortName: 'MECHENG 3F04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef63897496c',
      },
      {
        _id: '6073b76956f56ef63897496e',
        courseName: 'MECHENG 3M03 A/B - Composite Laboratory',
        courseCode: '3M03',
        shortName: 'MECHENG 3M03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef63897496e',
      },
      {
        _id: '6073b76956f56ef638974970',
        courseName: 'MECHENG 3O04 - Fluid Mechanics',
        courseCode: '3O04',
        shortName: 'MECHENG 3O04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef638974970',
      },
      {
        _id: '6073b76956f56ef638974972',
        courseName: 'MECHENG 3R03 - Heat Transfer',
        courseCode: '3R03',
        shortName: 'MECHENG 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef638974972',
      },
      {
        _id: '6073b76956f56ef638974974',
        courseName: 'MECHENG 4AA3 - Aerodynamics',
        courseCode: '4AA3',
        shortName: 'MECHENG 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef638974974',
      },
      {
        _id: '6073b76956f56ef638974976',
        courseName: 'MECHENG 4B03 - Topics in Product Development',
        courseCode: '4B03',
        shortName: 'MECHENG 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef638974976',
      },
      {
        _id: '6073b76956f56ef638974978',
        courseName: 'MECHENG 4BB3 - Biomechanics',
        courseCode: '4BB3',
        shortName: 'MECHENG 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef638974978',
      },
      {
        _id: '6073b76956f56ef63897497a',
        courseName: 'MECHENG 4BF3 - Biofluid Mechanics Systems',
        courseCode: '4BF3',
        shortName: 'MECHENG 4BF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef63897497a',
      },
      {
        _id: '6073b76956f56ef63897497c',
        courseName: 'MECHENG 4C03 - Production Systems Engineering',
        courseCode: '4C03',
        shortName: 'MECHENG 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef63897497c',
      },
      {
        _id: '6073b76956f56ef63897497e',
        courseName:
          'MECHENG 4CC3 - Experimental and Computational Biomechanics',
        courseCode: '4CC3',
        shortName: 'MECHENG 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef63897497e',
      },
      {
        _id: '6073b76956f56ef638974980',
        courseName: 'MECHENG 4D03 - Manufacturing Processes (Metal Removal)',
        courseCode: '4D03',
        shortName: 'MECHENG 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76956f56ef638974980',
      },
      {
        _id: '6073b76a56f56ef638974982',
        courseName: 'MECHENG 4E03 - Microelectromechanical Systems (MEMS)',
        courseCode: '4E03',
        shortName: 'MECHENG 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974982',
      },
      {
        _id: '6073b76a56f56ef638974984',
        courseName: 'MECHENG 4H03 - Mechatronics',
        courseCode: '4H03',
        shortName: 'MECHENG 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974984',
      },
      {
        _id: '6073b76a56f56ef638974986',
        courseName: 'MECHENG 4I03 - Noise Analysis and Control',
        courseCode: '4I03',
        shortName: 'MECHENG 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974986',
      },
      {
        _id: '6073b76a56f56ef638974988',
        courseName:
          'MECHENG 4J03 - Introduction to Computational Fluid Mechanics and Heat Transfer',
        courseCode: '4J03',
        shortName: 'MECHENG 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974988',
      },
      {
        _id: '6073b76a56f56ef63897498a',
        courseName: 'MECHENG 4K03 - Robotics',
        courseCode: '4K03',
        shortName: 'MECHENG 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef63897498a',
      },
      {
        _id: '6073b76a56f56ef63897498c',
        courseName: 'MECHENG 4L03 - Industrial Design',
        courseCode: '4L03',
        shortName: 'MECHENG 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef63897498c',
      },
      {
        _id: '6073b76a56f56ef63897498e',
        courseName: 'MECHENG 4M06 A/B - Project',
        courseCode: '4M06',
        shortName: 'MECHENG 4M06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef63897498e',
      },
      {
        _id: '6073b76a56f56ef638974990',
        courseName: 'MECHENG 4N03 - Nanobio Engineering',
        courseCode: '4N03',
        shortName: 'MECHENG 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974990',
      },
      {
        _id: '6073b76a56f56ef638974992',
        courseName: 'MECHENG 4O04 - Sustainable Energy Systems',
        courseCode: '4O04',
        shortName: 'MECHENG 4O04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974992',
      },
      {
        _id: '6073b76a56f56ef638974994',
        courseName: 'MECHENG 4P03 A/B - Composite Laboratory',
        courseCode: '4P03',
        shortName: 'MECHENG 4P03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974994',
      },
      {
        _id: '6073b76a56f56ef638974996',
        courseName: 'MECHENG 4Q03 - Mechanical Vibrations',
        courseCode: '4Q03',
        shortName: 'MECHENG 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974996',
      },
      {
        _id: '6073b76a56f56ef638974998',
        courseName: 'MECHENG 4R03 - Control Systems',
        courseCode: '4R03',
        shortName: 'MECHENG 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76a56f56ef638974998',
      },
      {
        _id: '6073b76b56f56ef63897499a',
        courseName: 'MECHENG 4S03 - Incompressible Flow',
        courseCode: '4S03',
        shortName: 'MECHENG 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef63897499a',
      },
      {
        _id: '6073b76b56f56ef63897499c',
        courseName: 'MECHENG 4T03 - Finite Element Applications',
        courseCode: '4T03',
        shortName: 'MECHENG 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef63897499c',
      },
      {
        _id: '6073b76b56f56ef63897499e',
        courseName: 'MECHENG 4U03 - Compressible Flow and Turbomachinery',
        courseCode: '4U03',
        shortName: 'MECHENG 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef63897499e',
      },
      {
        _id: '6073b76b56f56ef6389749a0',
        courseName: 'MECHENG 4V03 - Thermo-Fluids Systems Design and Analysis',
        courseCode: '4V03',
        shortName: 'MECHENG 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749a0',
      },
      {
        _id: '6073b76b56f56ef6389749a2',
        courseName: 'MECHENG 4W03 - Air Conditioning and Refrigeration Systems',
        courseCode: '4W03',
        shortName: 'MECHENG 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749a2',
      },
      {
        _id: '6073b76b56f56ef6389749a4',
        courseName: 'MECHENG 4X04 A/B - Independent Research Project',
        courseCode: '4X04',
        shortName: 'MECHENG 4X04 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749a4',
      },
      {
        _id: '6073b76b56f56ef6389749a6',
        courseName: 'MECHENG 4Y03 - Internal Combustion Engines',
        courseCode: '4Y03',
        shortName: 'MECHENG 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749a6',
      },
      {
        _id: '6073b76b56f56ef6389749a8',
        courseName: 'MECHENG 4Z03 - CAD/CAM/CAE',
        courseCode: '4Z03',
        shortName: 'MECHENG 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749a8',
      },
      {
        _id: '6073b76b56f56ef6389749aa',
        courseName:
          'MECHTRON 3DX4 - Dynamic Models and Control of Physical Systems',
        courseCode: '3DX4',
        shortName: 'MECHTRON 3DX4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749aa',
      },
      {
        _id: '6073b76b56f56ef6389749ac',
        courseName: 'MECHTRON 3K04 - Software Development',
        courseCode: '3K04',
        shortName: 'MECHTRON 3K04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749ac',
      },
      {
        _id: '6073b76b56f56ef6389749ae',
        courseName: 'MECHTRON 3MX3 - Signals and Systems',
        courseCode: '3MX3',
        shortName: 'MECHTRON 3MX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749ae',
      },
      {
        _id: '6073b76b56f56ef6389749b0',
        courseName: 'MECHTRON 3TA4 - Embedded Systems Design I',
        courseCode: '3TA4',
        shortName: 'MECHTRON 3TA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76b56f56ef6389749b0',
      },
      {
        _id: '6073b76c56f56ef6389749b2',
        courseName: 'MECHTRON 3TB4 - Embedded Systems Design II',
        courseCode: '3TB4',
        shortName: 'MECHTRON 3TB4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749b2',
      },
      {
        _id: '6073b76c56f56ef6389749b4',
        courseName:
          'MECHTRON 4AA4 - Real-Time Systems and Control Applications',
        courseCode: '4AA4',
        shortName: 'MECHTRON 4AA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749b4',
      },
      {
        _id: '6073b76c56f56ef6389749b6',
        courseName: 'MECHTRON 4AX3 - Predictive and Intelligent Control',
        courseCode: '4AX3',
        shortName: 'MECHTRON 4AX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749b6',
      },
      {
        _id: '6073b76c56f56ef6389749b8',
        courseName: 'MECHTRON 4TB6 A/B - Mechatronics Capstone Design Project',
        courseCode: '4TB6',
        shortName: 'MECHTRON 4TB6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749b8',
      },
      {
        _id: '6073b76c56f56ef6389749ba',
        courseName:
          'MEDPHYS 3C03 - Operational Health Physics: Laboratory & Communication',
        courseCode: '3C03',
        shortName: 'MEDPHYS 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749ba',
      },
      {
        _id: '6073b76c56f56ef6389749bc',
        courseName: 'MEDPHYS 4B03 - Radioactivity and Radiation Interactions',
        courseCode: '4B03',
        shortName: 'MEDPHYS 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749bc',
      },
      {
        _id: '6073b76c56f56ef6389749be',
        courseName: 'MEDPHYS 4D03 - Imaging in Medicine and Biology',
        courseCode: '4D03',
        shortName: 'MEDPHYS 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749be',
      },
      {
        _id: '6073b76c56f56ef6389749c0',
        courseName: 'MEDPHYS 4F03 - Fundamentals of Health Physics',
        courseCode: '4F03',
        shortName: 'MEDPHYS 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749c0',
      },
      {
        _id: '6073b76c56f56ef6389749c2',
        courseName: 'MEDPHYS 4I03 - Introduction to Biophotonics',
        courseCode: '4I03',
        shortName: 'MEDPHYS 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749c2',
      },
      {
        _id: '6073b76c56f56ef6389749c4',
        courseName: 'MEDPHYS 4RA3 - Radiation and Radioisotope Methodology I',
        courseCode: '4RA3',
        shortName: 'MEDPHYS 4RA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749c4',
      },
      {
        _id: '6073b76c56f56ef6389749c6',
        courseName: 'MEDPHYS 4RB3 - Radiation and Radioisotope Methodology II',
        courseCode: '4RB3',
        shortName: 'MEDPHYS 4RB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749c6',
      },
      {
        _id: '6073b76c56f56ef6389749c8',
        courseName:
          'MEDPHYS 4T03 - Clinical Applications of Physics in Medicine',
        courseCode: '4T03',
        shortName: 'MEDPHYS 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76c56f56ef6389749c8',
      },
      {
        _id: '6073b76d56f56ef6389749ca',
        courseName: 'MEDPHYS 4U03 - Radiation Biology',
        courseCode: '4U03',
        shortName: 'MEDPHYS 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749ca',
      },
      {
        _id: '6073b76d56f56ef6389749cc',
        courseName: 'MEDPHYS 4Y06 A/B - Senior Thesis',
        courseCode: '4Y06',
        shortName: 'MEDPHYS 4Y06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749cc',
      },
      {
        _id: '6073b76d56f56ef6389749ce',
        courseName: 'MEDRADSC 1B03 - Introduction to Pathology',
        courseCode: '1B03',
        shortName: 'MEDRADSC 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749ce',
      },
      {
        _id: '6073b76d56f56ef6389749d0',
        courseName: 'MEDRADSC 1E03 - Inquiry in Medical Radiation Sciences',
        courseCode: '1E03',
        shortName: 'MEDRADSC 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749d0',
      },
      {
        _id: '6073b76d56f56ef6389749d2',
        courseName: 'MEDRADSC 1F03 - Professions in Medical Radiation Sciences',
        courseCode: '1F03',
        shortName: 'MEDRADSC 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749d2',
      },
      {
        _id: '6073b76d56f56ef6389749d4',
        courseName: 'MEDRADSC 2A03 - Patient Care',
        courseCode: '2A03',
        shortName: 'MEDRADSC 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749d4',
      },
      {
        _id: '6073b76d56f56ef6389749d6',
        courseName: 'MEDRADSC 2AA3 - Imaging Procedures in Health Care',
        courseCode: '2AA3',
        shortName: 'MEDRADSC 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749d6',
      },
      {
        _id: '6073b76d56f56ef6389749d8',
        courseName: 'MEDRADSC 2D03 - Relational Anatomy I',
        courseCode: '2D03',
        shortName: 'MEDRADSC 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749d8',
      },
      {
        _id: '6073b76d56f56ef6389749da',
        courseName: 'MEDRADSC 2G03 - Radiographic Skills I',
        courseCode: '2G03',
        shortName: 'MEDRADSC 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749da',
      },
      {
        _id: '6073b76d56f56ef6389749dc',
        courseName: 'MEDRADSC 2H03 - Radiographic Skills II',
        courseCode: '2H03',
        shortName: 'MEDRADSC 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749dc',
      },
      {
        _id: '6073b76d56f56ef6389749de',
        courseName: 'MEDRADSC 2I03 - Pathology and Procedures I',
        courseCode: '2I03',
        shortName: 'MEDRADSC 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76d56f56ef6389749de',
      },
      {
        _id: '6073b76e56f56ef6389749e0',
        courseName: 'MEDRADSC 2J15 - Radiography Clinical Practicum I',
        courseCode: '2J15',
        shortName: 'MEDRADSC 2J15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749e0',
      },
      {
        _id: '6073b76e56f56ef6389749e2',
        courseName:
          'MEDRADSC 2K03 - Applied Sonographic Physics and Instrumentation I',
        courseCode: '2K03',
        shortName: 'MEDRADSC 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749e2',
      },
      {
        _id: '6073b76e56f56ef6389749e4',
        courseName: 'MEDRADSC 2L03 - Abdominal Ultrasonography I',
        courseCode: '2L03',
        shortName: 'MEDRADSC 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749e4',
      },
      {
        _id: '6073b76e56f56ef6389749e6',
        courseName:
          'MEDRADSC 2M03 - Obstetrical and Gynecologic Ultrasonography I',
        courseCode: '2M03',
        shortName: 'MEDRADSC 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749e6',
      },
      {
        _id: '6073b76e56f56ef6389749e8',
        courseName: 'MEDRADSC 2N03 - Sonographic Skills I',
        courseCode: '2N03',
        shortName: 'MEDRADSC 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749e8',
      },
      {
        _id: '6073b76e56f56ef6389749ea',
        courseName: 'MEDRADSC 2O03 - Abdominal Ultrasonography II',
        courseCode: '2O03',
        shortName: 'MEDRADSC 2O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749ea',
      },
      {
        _id: '6073b76e56f56ef6389749ec',
        courseName:
          'MEDRADSC 2P03 - Obstetrical and Gynecological Ultrasonography II',
        courseCode: '2P03',
        shortName: 'MEDRADSC 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749ec',
      },
      {
        _id: '6073b76e56f56ef6389749ee',
        courseName: 'MEDRADSC 2Q03 - Sonographic Skills II',
        courseCode: '2Q03',
        shortName: 'MEDRADSC 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749ee',
      },
      {
        _id: '6073b76e56f56ef6389749f0',
        courseName: 'MEDRADSC 2R15 - Ultrasonography Clinical Practicum I',
        courseCode: '2R15',
        shortName: 'MEDRADSC 2R15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749f0',
      },
      {
        _id: '6073b76e56f56ef6389749f2',
        courseName: 'MEDRADSC 2RA3 - Relational Anatomy II',
        courseCode: '2RA3',
        shortName: 'MEDRADSC 2RA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749f2',
      },
      {
        _id: '6073b76e56f56ef6389749f4',
        courseName: 'MEDRADSC 2S03 - Clinical Oncology I',
        courseCode: '2S03',
        shortName: 'MEDRADSC 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749f4',
      },
      {
        _id: '6073b76e56f56ef6389749f6',
        courseName: 'MEDRADSC 2T03 - Clinical Oncology II',
        courseCode: '2T03',
        shortName: 'MEDRADSC 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76e56f56ef6389749f6',
      },
      {
        _id: '6073b76f56f56ef6389749f8',
        courseName: 'MEDRADSC 2U03 - Radiation Therapy Skills I',
        courseCode: '2U03',
        shortName: 'MEDRADSC 2U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef6389749f8',
      },
      {
        _id: '6073b76f56f56ef6389749fa',
        courseName: 'MEDRADSC 2V15 - Radiation Therapy Clinical Practicum I',
        courseCode: '2V15',
        shortName: 'MEDRADSC 2V15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef6389749fa',
      },
      {
        _id: '6073b76f56f56ef6389749fc',
        courseName:
          'MEDRADSC 2W03 - Physics and Instrumentation for Radiation Therapy',
        courseCode: '2W03',
        shortName: 'MEDRADSC 2W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef6389749fc',
      },
      {
        _id: '6073b76f56f56ef6389749fe',
        courseName: 'MEDRADSC 2X03 - Radiobiology and Protection',
        courseCode: '2X03',
        shortName: 'MEDRADSC 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef6389749fe',
      },
      {
        _id: '6073b76f56f56ef638974a00',
        courseName:
          'MEDRADSC 2Y03 - Radiographic Imaging and Instrumentation I',
        courseCode: '2Y03',
        shortName: 'MEDRADSC 2Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a00',
      },
      {
        _id: '6073b76f56f56ef638974a02',
        courseName:
          'MEDRADSC 2ZZ0 - Pre-Clinical Professional Skills Reassessment I',
        courseCode: '2ZZ0',
        shortName: 'MEDRADSC 2ZZ0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a02',
      },
      {
        _id: '6073b76f56f56ef638974a04',
        courseName:
          'MEDRADSC 3AA3 - Interdisciplinary Rounds in Medical Radiation Sciences',
        courseCode: '3AA3',
        shortName: 'MEDRADSC 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a04',
      },
      {
        _id: '6073b76f56f56ef638974a06',
        courseName:
          'MEDRADSC 3B03 - Quality Management in Medical Radiation Sciences',
        courseCode: '3B03',
        shortName: 'MEDRADSC 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a06',
      },
      {
        _id: '6073b76f56f56ef638974a08',
        courseName: 'MEDRADSC 3BB3 - Radiation Therapy Skills III',
        courseCode: '3BB3',
        shortName: 'MEDRADSC 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a08',
      },
      {
        _id: '6073b76f56f56ef638974a0a',
        courseName:
          'MEDRADSC 3DA3 - Subspecialties in Medical Radiation Sciences - Advanced Studies in Computed Tomography',
        courseCode: '3DA3',
        shortName: 'MEDRADSC 3DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a0a',
      },
      {
        _id: '6073b76f56f56ef638974a0c',
        courseName:
          'MEDRADSC 3DD3 - Subspecialties in Medical Radiation Sciences: Mammography',
        courseCode: '3DD3',
        shortName: 'MEDRADSC 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a0c',
      },
      {
        _id: '6073b76f56f56ef638974a0e',
        courseName:
          'MEDRADSC 3DE3 - Subspecialties in Medical Radiation Sciences: Introduction to Magnetic Resonance Imaging',
        courseCode: '3DE3',
        shortName: 'MEDRADSC 3DE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b76f56f56ef638974a0e',
      },
      {
        _id: '6073b77056f56ef638974a10',
        courseName:
          'MEDRADSC 3DI3 - Subspecialties in Medical Radiation Sciences: Image Guidance in Radiation Therapy',
        courseCode: '3DI3',
        shortName: 'MEDRADSC 3DI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a10',
      },
      {
        _id: '6073b77056f56ef638974a12',
        courseName:
          'MEDRADSC 3DJ3 - Subspecialties in Medical Radiation Sciences: Pediatric Sonography',
        courseCode: '3DJ3',
        shortName: 'MEDRADSC 3DJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a12',
      },
      {
        _id: '6073b77056f56ef638974a14',
        courseName:
          'MEDRADSC 3G03 - Radiographic Imaging and Instrumentation II',
        courseCode: '3G03',
        shortName: 'MEDRADSC 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a14',
      },
      {
        _id: '6073b77056f56ef638974a16',
        courseName: 'MEDRADSC 3H03 - Quality Control in Radiography',
        courseCode: '3H03',
        shortName: 'MEDRADSC 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a16',
      },
      {
        _id: '6073b77056f56ef638974a18',
        courseName: 'MEDRADSC 3J03 - Pathology and Procedures II',
        courseCode: '3J03',
        shortName: 'MEDRADSC 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a18',
      },
      {
        _id: '6073b77056f56ef638974a1a',
        courseName: 'MEDRADSC 3K03 - Computed Tomography',
        courseCode: '3K03',
        shortName: 'MEDRADSC 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a1a',
      },
      {
        _id: '6073b77056f56ef638974a1c',
        courseName: 'MEDRADSC 3L03 - Radiographic Skills III',
        courseCode: '3L03',
        shortName: 'MEDRADSC 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a1c',
      },
      {
        _id: '6073b77056f56ef638974a1e',
        courseName: 'MEDRADSC 3M03 - Abdominal Ultrasonography III',
        courseCode: '3M03',
        shortName: 'MEDRADSC 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a1e',
      },
      {
        _id: '6073b77056f56ef638974a20',
        courseName: 'MEDRADSC 3N03 - Vascular Ultrasonography',
        courseCode: '3N03',
        shortName: 'MEDRADSC 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a20',
      },
      {
        _id: '6073b77056f56ef638974a22',
        courseName: 'MEDRADSC 3O03 - Sonographic Skills III',
        courseCode: '3O03',
        shortName: 'MEDRADSC 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a22',
      },
      {
        _id: '6073b77056f56ef638974a24',
        courseName:
          'MEDRADSC 3P03 - Obstetrical and Gynecologic Ultrasonography III',
        courseCode: '3P03',
        shortName: 'MEDRADSC 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a24',
      },
      {
        _id: '6073b77056f56ef638974a26',
        courseName:
          'MEDRADSC 3Q03 - Applied Sonographic Physics and Instrumentation II',
        courseCode: '3Q03',
        shortName: 'MEDRADSC 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77056f56ef638974a26',
      },
      {
        _id: '6073b77156f56ef638974a28',
        courseName: 'MEDRADSC 3R03 - Musculoskeletal Ultrasonography',
        courseCode: '3R03',
        shortName: 'MEDRADSC 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a28',
      },
      {
        _id: '6073b77156f56ef638974a2a',
        courseName: 'MEDRADSC 3S03 - Treatment Planning I',
        courseCode: '3S03',
        shortName: 'MEDRADSC 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a2a',
      },
      {
        _id: '6073b77156f56ef638974a2c',
        courseName: 'MEDRADSC 3V03 - Treatment Planning II',
        courseCode: '3V03',
        shortName: 'MEDRADSC 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a2c',
      },
      {
        _id: '6073b77156f56ef638974a2e',
        courseName: 'MEDRADSC 3W03 - Radiation Therapy Skills II',
        courseCode: '3W03',
        shortName: 'MEDRADSC 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a2e',
      },
      {
        _id: '6073b77156f56ef638974a30',
        courseName:
          'MEDRADSC 3X03 - Research Methods in Medical Radiation Sciences',
        courseCode: '3X03',
        shortName: 'MEDRADSC 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a30',
      },
      {
        _id: '6073b77156f56ef638974a32',
        courseName: 'MEDRADSC 3Y03 - Ethics for Medical Radiation Sciences',
        courseCode: '3Y03',
        shortName: 'MEDRADSC 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a32',
      },
      {
        _id: '6073b77156f56ef638974a34',
        courseName: 'MEDRADSC 3Z06 - Research Project',
        courseCode: '3Z06',
        shortName: 'MEDRADSC 3Z06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a34',
      },
      {
        _id: '6073b77156f56ef638974a36',
        courseName:
          'MEDRADSC 3ZZ0 - Clinical Skill Development for Professionals',
        courseCode: '3ZZ0',
        shortName: 'MEDRADSC 3ZZ0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a36',
      },
      {
        _id: '6073b77156f56ef638974a38',
        courseName: 'MEDRADSC 4A15 - Radiography Clinical Practicum II',
        courseCode: '4A15',
        shortName: 'MEDRADSC 4A15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a38',
      },
      {
        _id: '6073b77156f56ef638974a3a',
        courseName: 'MEDRADSC 4B15 - Radiography Clinical Practicum III',
        courseCode: '4B15',
        shortName: 'MEDRADSC 4B15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a3a',
      },
      {
        _id: '6073b77156f56ef638974a3c',
        courseName: 'MEDRADSC 4C15 - Ultrasonography Clinical Practicum II',
        courseCode: '4C15',
        shortName: 'MEDRADSC 4C15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a3c',
      },
      {
        _id: '6073b77156f56ef638974a3e',
        courseName: 'MEDRADSC 4D15 - Ultrasonography Clinical Practicum III',
        courseCode: '4D15',
        shortName: 'MEDRADSC 4D15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77156f56ef638974a3e',
      },
      {
        _id: '6073b77256f56ef638974a40',
        courseName: 'MEDRADSC 4E15 - Radiation Therapy Clinical Practicum II',
        courseCode: '4E15',
        shortName: 'MEDRADSC 4E15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a40',
      },
      {
        _id: '6073b77256f56ef638974a42',
        courseName: 'MEDRADSC 4F15 - Radiation Therapy Clinical Practicum III',
        courseCode: '4F15',
        shortName: 'MEDRADSC 4F15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a42',
      },
      {
        _id: '6073b77256f56ef638974a44',
        courseName:
          'MEDRADSC 4ZZ0 - Pre-Clinical Professional Skills Reassessment II',
        courseCode: '4ZZ0',
        shortName: 'MEDRADSC 4ZZ0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a44',
      },
      {
        _id: '6073b77256f56ef638974a46',
        courseName: 'MIDWIF 1D03 - Midwifery The Profession I',
        courseCode: '1D03',
        shortName: 'MIDWIF 1D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a46',
      },
      {
        _id: '6073b77256f56ef638974a48',
        courseName:
          'MIDWIF 1F03 - Introduction to Research Methods and Critical Appraisal',
        courseCode: '1F03',
        shortName: 'MIDWIF 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a48',
      },
      {
        _id: '6073b77256f56ef638974a4a',
        courseName: 'MIDWIF 1G03 - Midwifery The Profession II',
        courseCode: '1G03',
        shortName: 'MIDWIF 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a4a',
      },
      {
        _id: '6073b77256f56ef638974a4c',
        courseName: 'MIDWIF 2F03 - Pharmacotherapy',
        courseCode: '2F03',
        shortName: 'MIDWIF 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a4c',
      },
      {
        _id: '6073b77256f56ef638974a4e',
        courseName: 'MIDWIF 2G06 - Clinical Skills for Midwifery Practice',
        courseCode: '2G06',
        shortName: 'MIDWIF 2G06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a4e',
      },
      {
        _id: '6073b77256f56ef638974a50',
        courseName: 'MIDWIF 2H15 - Normal Childbearing',
        courseCode: '2H15',
        shortName: 'MIDWIF 2H15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a50',
      },
      {
        _id: '6073b77256f56ef638974a52',
        courseName: 'MIDWIF 3A09 - Interprofessional Practice I',
        courseCode: '3A09',
        shortName: 'MIDWIF 3A09',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a52',
      },
      {
        _id: '6073b77256f56ef638974a54',
        courseName: 'MIDWIF 3F03 - Midwifery Issues',
        courseCode: '3F03',
        shortName: 'MIDWIF 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a54',
      },
      {
        _id: '6073b77256f56ef638974a56',
        courseName: 'MIDWIF 3H15 - Complications and Consultation',
        courseCode: '3H15',
        shortName: 'MIDWIF 3H15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77256f56ef638974a56',
      },
      {
        _id: '6073b77356f56ef638974a58',
        courseName: 'MIDWIF 3I03 - Advanced Clinical Skills I',
        courseCode: '3I03',
        shortName: 'MIDWIF 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a58',
      },
      {
        _id: '6073b77356f56ef638974a5a',
        courseName: 'MIDWIF 3J06 - Preparation for Primary Maternity Care',
        courseCode: '3J06',
        shortName: 'MIDWIF 3J06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a5a',
      },
      {
        _id: '6073b77356f56ef638974a5c',
        courseName: 'MIDWIF 3K06 - Interprofessional Practice II',
        courseCode: '3K06',
        shortName: 'MIDWIF 3K06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a5c',
      },
      {
        _id: '6073b77356f56ef638974a5e',
        courseName: 'MIDWIF 3L03 - Advanced Clinical Skills II',
        courseCode: '3L03',
        shortName: 'MIDWIF 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a5e',
      },
      {
        _id: '6073b77356f56ef638974a60',
        courseName: 'MIDWIF 4A15 - Maternal and Newborn Pathology',
        courseCode: '4A15',
        shortName: 'MIDWIF 4A15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a60',
      },
      {
        _id: '6073b77356f56ef638974a62',
        courseName: 'MIDWIF 4B15 - Midwifery Clerkship',
        courseCode: '4B15',
        shortName: 'MIDWIF 4B15',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a62',
      },
      {
        _id: '6073b77356f56ef638974a64',
        courseName: 'MOLBIOL 2C03 - Genetics',
        courseCode: '2C03',
        shortName: 'MOLBIOL 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a64',
      },
      {
        _id: '6073b77356f56ef638974a66',
        courseName:
          'MOLBIOL 3A03 - Current Topics in Molecular Biology and Genetics',
        courseCode: '3A03',
        shortName: 'MOLBIOL 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a66',
      },
      {
        _id: '6073b77356f56ef638974a68',
        courseName: 'MOLBIOL 3B03 - Advanced Cell Biology',
        courseCode: '3B03',
        shortName: 'MOLBIOL 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a68',
      },
      {
        _id: '6073b77356f56ef638974a6a',
        courseName: 'MOLBIOL 3CC3 - Genomics and Systems Biology',
        courseCode: '3CC3',
        shortName: 'MOLBIOL 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a6a',
      },
      {
        _id: '6073b77356f56ef638974a6c',
        courseName: 'MOLBIOL 3D03 - Experimental Approaches in Cell Biology',
        courseCode: '3D03',
        shortName: 'MOLBIOL 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a6c',
      },
      {
        _id: '6073b77356f56ef638974a6e',
        courseName: 'MOLBIOL 3I03 A/B S - Independent Research Project',
        courseCode: '3I03',
        shortName: 'MOLBIOL 3I03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77356f56ef638974a6e',
      },
      {
        _id: '6073b77456f56ef638974a70',
        courseName: 'MOLBIOL 3II3 - Molecular Genetics of Eukaryotes',
        courseCode: '3II3',
        shortName: 'MOLBIOL 3II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a70',
      },
      {
        _id: '6073b77456f56ef638974a72',
        courseName: 'MOLBIOL 3M03 - Fundamental Concepts of Development',
        courseCode: '3M03',
        shortName: 'MOLBIOL 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a72',
      },
      {
        _id: '6073b77456f56ef638974a74',
        courseName: 'MOLBIOL 3O03 - Microbial Genetics',
        courseCode: '3O03',
        shortName: 'MOLBIOL 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a74',
      },
      {
        _id: '6073b77456f56ef638974a76',
        courseName: 'MOLBIOL 3V03 - Techniques in Molecular Genetics',
        courseCode: '3V03',
        shortName: 'MOLBIOL 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a76',
      },
      {
        _id: '6073b77456f56ef638974a78',
        courseName: 'MOLBIOL 3Y03 - Plant Responses to the Environment',
        courseCode: '3Y03',
        shortName: 'MOLBIOL 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a78',
      },
      {
        _id: '6073b77456f56ef638974a7a',
        courseName: 'MOLBIOL 4BB3 - Plant Metabolism and Molecular Biology',
        courseCode: '4BB3',
        shortName: 'MOLBIOL 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a7a',
      },
      {
        _id: '6073b77456f56ef638974a7c',
        courseName: 'MOLBIOL 4DD3 - Molecular Evolution',
        courseCode: '4DD3',
        shortName: 'MOLBIOL 4DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a7c',
      },
      {
        _id: '6073b77456f56ef638974a7e',
        courseName: 'MOLBIOL 4G12 A/B S - Senior Thesis',
        courseCode: '4G12',
        shortName: 'MOLBIOL 4G12 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a7e',
      },
      {
        _id: '6073b77456f56ef638974a80',
        courseName: 'MOLBIOL 4H03 - Molecular Biology of Cancer',
        courseCode: '4H03',
        shortName: 'MOLBIOL 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a80',
      },
      {
        _id: '6073b77456f56ef638974a82',
        courseName: 'MOLBIOL 4K03 - Research Advances in Biology of Aging',
        courseCode: '4K03',
        shortName: 'MOLBIOL 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a82',
      },
      {
        _id: '6073b77456f56ef638974a84',
        courseName: 'MOLBIOL 4P03 - Medical Microbiology',
        courseCode: '4P03',
        shortName: 'MOLBIOL 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a84',
      },
      {
        _id: '6073b77456f56ef638974a86',
        courseName: 'MOLBIOL 4RR3 - Human Genetics',
        courseCode: '4RR3',
        shortName: 'MOLBIOL 4RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77456f56ef638974a86',
      },
      {
        _id: '6073b77556f56ef638974a88',
        courseName: 'MOHAWK 1Z03 - Introduction to Mohawk Language and Culture',
        courseCode: '1Z03',
        shortName: 'MOHAWK 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a88',
      },
      {
        _id: '6073b77556f56ef638974a8a',
        courseName: 'MOHAWK 2Z03 - Intermediate Mohawk',
        courseCode: '2Z03',
        shortName: 'MOHAWK 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a8a',
      },
      {
        _id: '6073b77556f56ef638974a8c',
        courseName: 'MMEDIA 1A03 - Multimedia and Digital Society',
        courseCode: '1A03',
        shortName: 'MMEDIA 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a8c',
      },
      {
        _id: '6073b77556f56ef638974a8e',
        courseName: 'MMEDIA 2A06 - Design & Code',
        courseCode: '2A06',
        shortName: 'MMEDIA 2A06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a8e',
      },
      {
        _id: '6073b77556f56ef638974a90',
        courseName: 'MMEDIA 2B06 - Time-Based Media I',
        courseCode: '2B06',
        shortName: 'MMEDIA 2B06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a90',
      },
      {
        _id: '6073b77556f56ef638974a92',
        courseName: 'MMEDIA 2G03 - Introduction to Digital Audio',
        courseCode: '2G03',
        shortName: 'MMEDIA 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a92',
      },
      {
        _id: '6073b77556f56ef638974a94',
        courseName: 'MMEDIA 3AN3 - Animation',
        courseCode: '3AN3',
        shortName: 'MMEDIA 3AN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a94',
      },
      {
        _id: '6073b77556f56ef638974a96',
        courseName: 'MMEDIA 3B03 - Digital Cultures',
        courseCode: '3B03',
        shortName: 'MMEDIA 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a96',
      },
      {
        _id: '6073b77556f56ef638974a98',
        courseName: 'MMEDIA 3BB3 - New Media Art Practices',
        courseCode: '3BB3',
        shortName: 'MMEDIA 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a98',
      },
      {
        _id: '6073b77556f56ef638974a9a',
        courseName: 'MMEDIA 3C03 - Interactive and Spatial Audio',
        courseCode: '3C03',
        shortName: 'MMEDIA 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a9a',
      },
      {
        _id: '6073b77556f56ef638974a9c',
        courseName: 'MMEDIA 3EE3 - Graphic Design',
        courseCode: '3EE3',
        shortName: 'MMEDIA 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a9c',
      },
      {
        _id: '6073b77556f56ef638974a9e',
        courseName: 'MMEDIA 3H03 - Time-Based Media II',
        courseCode: '3H03',
        shortName: 'MMEDIA 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77556f56ef638974a9e',
      },
      {
        _id: '6073b77656f56ef638974aa0',
        courseName: 'MMEDIA 3I03 - Narrative Strategies',
        courseCode: '3I03',
        shortName: 'MMEDIA 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aa0',
      },
      {
        _id: '6073b77656f56ef638974aa2',
        courseName: 'MMEDIA 3K03 - Game Studies',
        courseCode: '3K03',
        shortName: 'MMEDIA 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aa2',
      },
      {
        _id: '6073b77656f56ef638974aa4',
        courseName: 'MMEDIA 3L03 - Game Design',
        courseCode: 'MMEDIA',
        shortName: 'MMEDIA 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aa4',
      },
      {
        _id: '6073b77656f56ef638974aa6',
        courseName: 'MMEDIA 3MU3 - Musics, Technologies and Audio Cultures',
        courseCode: '3MU3',
        shortName: 'MMEDIA 3MU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aa6',
      },
      {
        _id: '6073b77656f56ef638974aa8',
        courseName: 'MMEDIA 3PC3 - Photographic Collage and Composite Images',
        courseCode: '3PC3',
        shortName: 'MMEDIA 3PC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aa8',
      },
      {
        _id: '6073b77656f56ef638974aaa',
        courseName: 'MMEDIA 3Q03 - Emerging Media',
        courseCode: '3Q03',
        shortName: 'MMEDIA 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aaa',
      },
      {
        _id: '6073b77656f56ef638974aac',
        courseName: 'MMEDIA 3S03 - Sound and Image',
        courseCode: '3S03',
        shortName: 'MMEDIA 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aac',
      },
      {
        _id: '6073b77656f56ef638974aae',
        courseName: 'MMEDIA 3VA3 - Video Art and Digital Cinema',
        courseCode: '3VA3',
        shortName: 'MMEDIA 3VA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974aae',
      },
      {
        _id: '6073b77656f56ef638974ab0',
        courseName: 'MMEDIA 3X03 A/B - Presentation and Critique',
        courseCode: '3X03',
        shortName: 'MMEDIA 3X03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974ab0',
      },
      {
        _id: '6073b77656f56ef638974ab2',
        courseName: 'MMEDIA 4F03 - Topics in Multimedia Production',
        courseCode: '4F03',
        shortName: 'MMEDIA 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974ab2',
      },
      {
        _id: '6073b77656f56ef638974ab4',
        courseName: 'MMEDIA 4ST6 A/B - Senior Thesis Research and Production',
        courseCode: '4ST6',
        shortName: 'MMEDIA 4ST6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974ab4',
      },
      {
        _id: '6073b77656f56ef638974ab6',
        courseName: 'MUSIC 1A03 - Introduction to the History of Music I',
        courseCode: '1A03',
        shortName: 'MUSIC 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77656f56ef638974ab6',
      },
      {
        _id: '6073b77756f56ef638974ab8',
        courseName: 'MUSIC 1AA3 - Introduction to the History of Music II',
        courseCode: '1AA3',
        shortName: 'MUSIC 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ab8',
      },
      {
        _id: '6073b77756f56ef638974aba',
        courseName: 'MUSIC 1CB3 - Theory and Analysis I',
        courseCode: '1CB3',
        shortName: 'MUSIC 1CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974aba',
      },
      {
        _id: '6073b77756f56ef638974abc',
        courseName: 'MUSIC 1CR3 - Rudiments of Music',
        courseCode: '1CR3',
        shortName: 'MUSIC 1CR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974abc',
      },
      {
        _id: '6073b77756f56ef638974abe',
        courseName: 'MUSIC 1DA3 - Practical Musicianship I',
        courseCode: '1DA3',
        shortName: 'MUSIC 1DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974abe',
      },
      {
        _id: '6073b77756f56ef638974ac0',
        courseName: 'MUSIC 1DB3 - Practical Musicianship II',
        courseCode: '1DB3',
        shortName: 'MUSIC 1DB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ac0',
      },
      {
        _id: '6073b77756f56ef638974ac2',
        courseName: 'MUSIC 1E06 A/B - Solo Performance',
        courseCode: '1E06',
        shortName: 'MUSIC 1E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ac2',
      },
      {
        _id: '6073b77756f56ef638974ac4',
        courseName: 'MUSIC 1EE6 A/B - Solo Performance',
        courseCode: '1EE6',
        shortName: 'MUSIC 1EE6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ac4',
      },
      {
        _id: '6073b77756f56ef638974ac6',
        courseName:
          'MUSIC 1GB3 A/B - Ensemble Performance: McMaster Concert Band',
        courseCode: '1GB3',
        shortName: 'MUSIC 1GB3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ac6',
      },
      {
        _id: '6073b77756f56ef638974ac8',
        courseName:
          'MUSIC 1GC3 A/B - Ensemble Performance: McMaster University Choir',
        courseCode: '1GC3',
        shortName: 'MUSIC 1GC3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ac8',
      },
      {
        _id: '6073b77756f56ef638974aca',
        courseName:
          'MUSIC 1GF3 A/B - Ensemble Performance: David Gerry Flute Ensemble',
        courseCode: '1GF3',
        shortName: 'MUSIC 1GF3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974aca',
      },
      {
        _id: '6073b77756f56ef638974acc',
        courseName: 'MUSIC 1GJ3 A/B - Ensemble Performance: McMaster Jazz Band',
        courseCode: '1GJ3',
        shortName: 'MUSIC 1GJ3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974acc',
      },
      {
        _id: '6073b77756f56ef638974ace',
        courseName:
          'MUSIC 1GP3 A/B - Ensemble Performance: McMaster Percussion Ensemble',
        courseCode: '1GP3',
        shortName: 'MUSIC 1GP3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77756f56ef638974ace',
      },
      {
        _id: '6073b77856f56ef638974ad0',
        courseName:
          'MUSIC 1GR3 A/B - Ensemble Performance: McMaster Chamber Orchestra',
        courseCode: '1GR3',
        shortName: 'MUSIC 1GR3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ad0',
      },
      {
        _id: '6073b77856f56ef638974ad2',
        courseName:
          'MUSIC 1GW3 A/B - Ensemble Performance: McMaster Cantemus Vocal Ensemble',
        courseCode: '1GW3',
        shortName: 'MUSIC 1GW3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ad2',
      },
      {
        _id: '6073b77856f56ef638974ad4',
        courseName: 'MUSIC 1MH3 - Music History I: Music and Culture',
        courseCode: '1MH3',
        shortName: 'MUSIC 1MH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ad4',
      },
      {
        _id: '6073b77856f56ef638974ad6',
        courseName: 'MUSIC 2A03 - Music of the Worldâ€™s Cultures',
        courseCode: '2A03',
        shortName: 'MUSIC 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ad6',
      },
      {
        _id: '6073b77856f56ef638974ad8',
        courseName:
          'MUSIC 2B03 - Music History: Music in Western Culture from c. 1750 to the Present',
        courseCode: '2B03',
        shortName: 'MUSIC 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ad8',
      },
      {
        _id: '6073b77856f56ef638974ada',
        courseName: 'MUSIC 2CA3 - Theory and Analysis II',
        courseCode: '2CA3',
        shortName: 'MUSIC 2CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ada',
      },
      {
        _id: '6073b77856f56ef638974adc',
        courseName: 'MUSIC 2CB3 - Theory and Analysis III',
        courseCode: '2CB3',
        shortName: 'MUSIC 2CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974adc',
      },
      {
        _id: '6073b77856f56ef638974ade',
        courseName: 'MUSIC 2DA3 - Practical Musicianship III',
        courseCode: '2DA3',
        shortName: 'MUSIC 2DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ade',
      },
      {
        _id: '6073b77856f56ef638974ae0',
        courseName: 'MUSIC 2E06 A/B - Solo Performance',
        courseCode: '2E06',
        shortName: 'MUSIC 2E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ae0',
      },
      {
        _id: '6073b77856f56ef638974ae2',
        courseName: 'MUSIC 2EE6 A/B - Solo Performance',
        courseCode: '2EE6',
        shortName: 'MUSIC 2EE6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ae2',
      },
      {
        _id: '6073b77856f56ef638974ae4',
        courseName: 'MUSIC 2F03 - Music for Film and Television',
        courseCode: '2F03',
        shortName: 'MUSIC 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ae4',
      },
      {
        _id: '6073b77856f56ef638974ae6',
        courseName:
          'MUSIC 2GB3 A/B - Ensemble Performance: McMaster Concert Band',
        courseCode: '2GB3',
        shortName: 'MUSIC 2GB3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77856f56ef638974ae6',
      },
      {
        _id: '6073b77956f56ef638974ae8',
        courseName:
          'MUSIC 2GC3 A/B - Ensemble Performance: McMaster University Choir',
        courseCode: '2GC3',
        shortName: 'MUSIC 2GC3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974ae8',
      },
      {
        _id: '6073b77956f56ef638974aea',
        courseName:
          'MUSIC 2GF3 A/B - Ensemble Performance: David Gerry Flute Ensemble',
        courseCode: '2GF3',
        shortName: 'MUSIC 2GF3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974aea',
      },
      {
        _id: '6073b77956f56ef638974aec',
        courseName: 'MUSIC 2GJ3 A/B - Ensemble Performance: McMaster Jazz Band',
        courseCode: '2GJ3',
        shortName: 'MUSIC 2GJ3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974aec',
      },
      {
        _id: '6073b77956f56ef638974aee',
        courseName:
          'MUSIC 2GP3 A/B - Ensemble Performance: McMaster Percussion Ensemble',
        courseCode: '2GP3',
        shortName: 'MUSIC 2GP3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974aee',
      },
      {
        _id: '6073b77956f56ef638974af0',
        courseName:
          'MUSIC 2GR3 A/B - Ensemble Performance: McMaster Chamber Orchestra',
        courseCode: '2GR3',
        shortName: 'MUSIC 2GR3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974af0',
      },
      {
        _id: '6073b77956f56ef638974af2',
        courseName:
          'MUSIC 2GW3 A/B - Ensemble Performance: McMaster Cantemus Vocal Ensemble',
        courseCode: '2GW3',
        shortName: 'MUSIC 2GW3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974af2',
      },
      {
        _id: '6073b77956f56ef638974af4',
        courseName:
          'MUSIC 2II3 - Popular Music in North America and the United Kingdom: Post-World War II',
        courseCode: '2II3',
        shortName: 'MUSIC 2II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974af4',
      },
      {
        _id: '6073b77956f56ef638974af6',
        courseName: 'MUSIC 2MC3 - Psychology of Music',
        courseCode: '2MC3',
        shortName: 'MUSIC 2MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974af6',
      },
      {
        _id: '6073b77956f56ef638974af8',
        courseName:
          'MUSIC 2MH3 - Music History: Music in Western Culture from Antiquity to c. 1750',
        courseCode: '2MH3',
        shortName: 'MUSIC 2MH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974af8',
      },
      {
        _id: '6073b77956f56ef638974afa',
        courseName:
          'MUSIC 2MT3 - Introduction to the Practice of Music Therapy',
        courseCode: '2MT3',
        shortName: 'MUSIC 2MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974afa',
      },
      {
        _id: '6073b77956f56ef638974afc',
        courseName: 'MUSIC 2MU3 - Introduction to Music Therapy Research',
        courseCode: '2MU3',
        shortName: 'MUSIC 2MU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974afc',
      },
      {
        _id: '6073b77956f56ef638974afe',
        courseName: 'MUSIC 2TT3 - Broadway and the Popular Song',
        courseCode: '2TT3',
        shortName: 'MUSIC 2TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77956f56ef638974afe',
      },
      {
        _id: '6073b77a56f56ef638974b00',
        courseName: 'MUSIC 2U03 - Jazz',
        courseCode: '2U03',
        shortName: 'MUSIC 2U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b00',
      },
      {
        _id: '6073b77a56f56ef638974b02',
        courseName: 'MUSIC 3AA3 - Elementary Music Education',
        courseCode: '3AA3',
        shortName: 'MUSIC 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b02',
      },
      {
        _id: '6073b77a56f56ef638974b04',
        courseName: 'MUSIC 3CM3 - Modal Counterpoint',
        courseCode: '3CM3',
        shortName: 'MUSIC 3CM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b04',
      },
      {
        _id: '6073b77a56f56ef638974b06',
        courseName: 'MUSIC 3CT3 - Tonal Counterpoint',
        courseCode: '3CT3',
        shortName: 'MUSIC 3CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b06',
      },
      {
        _id: '6073b77a56f56ef638974b08',
        courseName: 'MUSIC 3E03 - Solo Performance',
        courseCode: '3E03',
        shortName: 'MUSIC 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b08',
      },
      {
        _id: '6073b77a56f56ef638974b0a',
        courseName: 'MUSIC 3E06 A/B - Solo Performance',
        courseCode: '3E06',
        shortName: 'MUSIC 3E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b0a',
      },
      {
        _id: '6073b77a56f56ef638974b0c',
        courseName: 'MUSIC 3EE3 - Solo Performance',
        courseCode: '3EE3',
        shortName: 'MUSIC 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b0c',
      },
      {
        _id: '6073b77a56f56ef638974b0e',
        courseName: 'MUSIC 3EE6 A/B - Solo Performance',
        courseCode: '3EE6',
        shortName: 'MUSIC 3EE6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b0e',
      },
      {
        _id: '6073b77a56f56ef638974b10',
        courseName: 'MUSIC 3GA3 A/B - Ensemble Performance: Accompanying',
        courseCode: '3GA3',
        shortName: 'MUSIC 3GA3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b10',
      },
      {
        _id: '6073b77a56f56ef638974b12',
        courseName:
          'MUSIC 3GB3 A/B - Ensemble Performance: McMaster Concert Band',
        courseCode: '3GB3',
        shortName: 'MUSIC 3GB3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b12',
      },
      {
        _id: '6073b77a56f56ef638974b14',
        courseName:
          'MUSIC 3GC3 A/B - Ensemble Performance: McMaster University Choir',
        courseCode: '3GC3',
        shortName: 'MUSIC 3GC3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b14',
      },
      {
        _id: '6073b77a56f56ef638974b16',
        courseName:
          'MUSIC 3GF3 A/B - Ensemble Performance: David Gerry Flute Ensemble',
        courseCode: '3GF3',
        shortName: 'MUSIC 3GF3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77a56f56ef638974b16',
      },
      {
        _id: '6073b77b56f56ef638974b18',
        courseName: 'MUSIC 3GJ3 A/B - Ensemble Performance: McMaster Jazz Band',
        courseCode: '3GJ3',
        shortName: 'MUSIC 3GJ3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b18',
      },
      {
        _id: '6073b77b56f56ef638974b1a',
        courseName:
          'MUSIC 3GP3 A/B - Ensemble Performance: McMaster Percussion Ensemble',
        courseCode: '3GP3',
        shortName: 'MUSIC 3GP3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b1a',
      },
      {
        _id: '6073b77b56f56ef638974b1c',
        courseName:
          'MUSIC 3GR3 A/B - Ensemble Performance: McMaster Chamber Orchestra',
        courseCode: '3GR3',
        shortName: 'MUSIC 3GR3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b1c',
      },
      {
        _id: '6073b77b56f56ef638974b1e',
        courseName:
          'MUSIC 3GW3 A/B - Ensemble Performance: McMaster Cantemus Vocal Ensemble',
        courseCode: '3GW3',
        shortName: 'MUSIC 3GW3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b1e',
      },
      {
        _id: '6073b77b56f56ef638974b20',
        courseName: 'MUSIC 3H03 - Analysis',
        courseCode: '3H03',
        shortName: 'MUSIC 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b20',
      },
      {
        _id: '6073b77b56f56ef638974b22',
        courseName: 'MUSIC 3J03 - Orchestration and Arranging',
        courseCode: '3J03',
        shortName: 'MUSIC 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b22',
      },
      {
        _id: '6073b77b56f56ef638974b24',
        courseName:
          'MUSIC 3JJ3 - Topics in Music History: Music Before c. 1750',
        courseCode: '3JJ3',
        shortName: 'MUSIC 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b24',
      },
      {
        _id: '6073b77b56f56ef638974b26',
        courseName: 'MUSIC 3K03 - Brass Methods',
        courseCode: '3K03',
        shortName: 'MUSIC 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b26',
      },
      {
        _id: '6073b77b56f56ef638974b28',
        courseName:
          'MUSIC 3KK3 - Topics in Music History: Music from c. 1750-c. 1900',
        courseCode: '3KK3',
        shortName: 'MUSIC 3KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b28',
      },
      {
        _id: '6073b77b56f56ef638974b2a',
        courseName: 'MUSIC 3L03 - Woodwind Methods',
        courseCode: '3L03',
        shortName: 'MUSIC 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b2a',
      },
      {
        _id: '6073b77b56f56ef638974b2c',
        courseName: 'MUSIC 3M03 A/B - String Methods',
        courseCode: '3M03',
        shortName: 'MUSIC 3M03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b2c',
      },
      {
        _id: '6073b77b56f56ef638974b2e',
        courseName: 'MUSIC 3N03 - Vocal Methods',
        courseCode: '3N03',
        shortName: 'MUSIC 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77b56f56ef638974b2e',
      },
      {
        _id: '6073b77c56f56ef638974b30',
        courseName: 'MUSIC 3O03 - Conducting',
        courseCode: '3O03',
        shortName: 'MUSIC 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b30',
      },
      {
        _id: '6073b77c56f56ef638974b32',
        courseName: 'MUSIC 3P03 - Percussion Methods',
        courseCode: '3P03',
        shortName: 'MUSIC 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b32',
      },
      {
        _id: '6073b77c56f56ef638974b34',
        courseName:
          'MUSIC 3SS3 - Special Studies in Chamber Music or Accompanying I',
        courseCode: '3SS3',
        shortName: 'MUSIC 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b34',
      },
      {
        _id: '6073b77c56f56ef638974b36',
        courseName: 'MUSIC 3V03 - Foundations of Music Education',
        courseCode: '3V03',
        shortName: 'MUSIC 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b36',
      },
      {
        _id: '6073b77c56f56ef638974b38',
        courseName: 'MUSIC 3X03 - Independent Study',
        courseCode: '3X03',
        shortName: 'MUSIC 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b38',
      },
      {
        _id: '6073b77c56f56ef638974b3a',
        courseName: 'MUSIC 4C03 - Advanced Studies in Harmony and Counterpoint',
        courseCode: '4C03',
        shortName: 'MUSIC 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b3a',
      },
      {
        _id: '6073b77c56f56ef638974b3c',
        courseName: 'MUSIC 4E03 - Solo Performance',
        courseCode: '4E03',
        shortName: 'MUSIC 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b3c',
      },
      {
        _id: '6073b77c56f56ef638974b3e',
        courseName: 'MUSIC 4E06 A/B - Solo Performance',
        courseCode: '4E06',
        shortName: 'MUSIC 4E06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b3e',
      },
      {
        _id: '6073b77c56f56ef638974b40',
        courseName: 'MUSIC 4E09 A/B - Solo Performance, Diploma',
        courseCode: '4E09',
        shortName: 'MUSIC 4E09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b40',
      },
      {
        _id: '6073b77c56f56ef638974b42',
        courseName: 'MUSIC 4EE3 - Solo Performance',
        courseCode: '4EE3',
        shortName: 'MUSIC 4EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b42',
      },
      {
        _id: '6073b77c56f56ef638974b44',
        courseName: 'MUSIC 4EE6 A/B - Solo Performance',
        courseCode: '4EE6',
        shortName: 'MUSIC 4EE6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b44',
      },
      {
        _id: '6073b77c56f56ef638974b46',
        courseName: 'MUSIC 4EE9 A/B - Solo Performance, Diploma',
        courseCode: '4EE9',
        shortName: 'MUSIC 4EE9 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77c56f56ef638974b46',
      },
      {
        _id: '6073b77d56f56ef638974b48',
        courseName: 'MUSIC 4GA3 A/B - Ensemble Performance: Accompanying',
        courseCode: '4GA3',
        shortName: 'MUSIC 4GA3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b48',
      },
      {
        _id: '6073b77d56f56ef638974b4a',
        courseName:
          'MUSIC 4GB3 A/B - Ensemble Performance: McMaster Concert Band',
        courseCode: '4GB3',
        shortName: 'MUSIC 4GB3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b4a',
      },
      {
        _id: '6073b77d56f56ef638974b4c',
        courseName:
          'MUSIC 4GC3 A/B - Ensemble Performance: McMaster University Choir',
        courseCode: '4GC3',
        shortName: 'MUSIC 4GC3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b4c',
      },
      {
        _id: '6073b77d56f56ef638974b4e',
        courseName:
          'MUSIC 4GF3 A/B - Ensemble Performance: David Gerry Flute Ensemble',
        courseCode: '4GF3',
        shortName: 'MUSIC 4GF3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b4e',
      },
      {
        _id: '6073b77d56f56ef638974b50',
        courseName: 'MUSIC 4GJ3 A/B - Ensemble Performance: McMaster Jazz Band',
        courseCode: '4GJ3',
        shortName: 'MUSIC 4GJ3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b50',
      },
      {
        _id: '6073b77d56f56ef638974b52',
        courseName:
          'MUSIC 4GP3 A/B - Ensemble Performance: McMaster Percussion Ensemble',
        courseCode: '4GP3',
        shortName: 'MUSIC 4GP3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b52',
      },
      {
        _id: '6073b77d56f56ef638974b54',
        courseName:
          'MUSIC 4GR3 A/B - Ensemble Performance: McMaster Chamber Orchestra',
        courseCode: '4GR3',
        shortName: 'MUSIC 4GR3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b54',
      },
      {
        _id: '6073b77d56f56ef638974b56',
        courseName:
          'MUSIC 4GW3 A/B - Ensemble Performance: McMaster Cantemus Vocal Ensemble',
        courseCode: '4GW3',
        shortName: 'MUSIC 4GW3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b56',
      },
      {
        _id: '6073b77d56f56ef638974b58',
        courseName: 'MUSIC 4H03 - Advanced Studies in Analysis',
        courseCode: '4H03',
        shortName: 'MUSIC 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b58',
      },
      {
        _id: '6073b77d56f56ef638974b5a',
        courseName: 'MUSIC 4K03 - Brass Methods',
        courseCode: '4K03',
        shortName: 'MUSIC 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b5a',
      },
      {
        _id: '6073b77d56f56ef638974b5c',
        courseName: 'MUSIC 4L03 - Woodwind Methods',
        courseCode: '4L03',
        shortName: 'MUSIC 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b5c',
      },
      {
        _id: '6073b77d56f56ef638974b5e',
        courseName: 'MUSIC 4M03 A/B - String Methods',
        courseCode: '4M03',
        shortName: 'MUSIC 4M03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77d56f56ef638974b5e',
      },
      {
        _id: '6073b77e56f56ef638974b60',
        courseName: 'MUSIC 4N03 - Choral Methods',
        courseCode: '4N03',
        shortName: 'MUSIC 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b60',
      },
      {
        _id: '6073b77e56f56ef638974b62',
        courseName: 'MUSIC 4OC3 - Advanced Conducting: Choral',
        courseCode: '4OC3',
        shortName: 'MUSIC 4OC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b62',
      },
      {
        _id: '6073b77e56f56ef638974b64',
        courseName: 'MUSIC 4OI3 - Advanced Conducting: Instrumental',
        courseCode: '4OI3',
        shortName: 'MUSIC 4OI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b64',
      },
      {
        _id: '6073b77e56f56ef638974b66',
        courseName:
          'MUSIC 4SS3 - Special Studies in Chamber Music or Accompanying II',
        courseCode: '4SS3',
        shortName: 'MUSIC 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b66',
      },
      {
        _id: '6073b77e56f56ef638974b68',
        courseName: 'MUSIC 4V03 - Current Issues in Music Education',
        courseCode: '4V03',
        shortName: 'MUSIC 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b68',
      },
      {
        _id: '6073b77e56f56ef638974b6a',
        courseName: 'MUSIC 4X03 - Advanced Independent Study',
        courseCode: '4X03',
        shortName: 'MUSIC 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b6a',
      },
      {
        _id: '6073b77e56f56ef638974b6c',
        courseName:
          'MUSIC 4Y03 - Topics in Music History: Advanced Musicology Seminar',
        courseCode: '4Y03',
        shortName: 'MUSIC 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b6c',
      },
      {
        _id: '6073b77e56f56ef638974b6e',
        courseName: 'MUSIC 4Z03 - Composition',
        courseCode: '4Z03',
        shortName: 'MUSIC 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b6e',
      },
      {
        _id: '6073b77e56f56ef638974b70',
        courseName: 'MUSIC 4ZZ3 - Advanced Composition',
        courseCode: '4ZZ3',
        shortName: 'MUSIC 4ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b70',
      },
      {
        _id: '6073b77e56f56ef638974b72',
        courseName: 'MUSICCOG 2MP3 - Introduction to Music Cognition',
        courseCode: '2MP3',
        shortName: 'MUSICCOG 2MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b72',
      },
      {
        _id: '6073b77e56f56ef638974b74',
        courseName:
          'MUSICCOG 3QQ3 A/B S - Experimental Laboratory in Music Cognition I',
        courseCode: '3QQ3',
        shortName: 'MUSICCOG 3QQ3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b74',
      },
      {
        _id: '6073b77e56f56ef638974b76',
        courseName: 'MUSICCOG 3SP3 - The Science of Performance',
        courseCode: '3SP3',
        shortName: 'MUSICCOG 3SP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77e56f56ef638974b76',
      },
      {
        _id: '6073b77f56f56ef638974b78',
        courseName: 'MUSICCOG 4D06 A/B - Thesis in Music Cognition',
        courseCode: '4D06',
        shortName: 'MUSICCOG 4D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b78',
      },
      {
        _id: '6073b77f56f56ef638974b7a',
        courseName: 'MUSICCOG 4MP3 - Neuroscience of Music',
        courseCode: '4MP3',
        shortName: 'MUSICCOG 4MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b7a',
      },
      {
        _id: '6073b77f56f56ef638974b7c',
        courseName:
          'MUSICCOG 4QQ3 A/B S - Experimental Laboratory in Music Cognition II',
        courseCode: '4QQ3',
        shortName: 'MUSICCOG 4QQ3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b7c',
      },
      {
        _id: '6073b77f56f56ef638974b7e',
        courseName:
          'NEUROSCI 2BB3 A/B S - Research Practicum in Cellular/Molecular Neuroscience',
        courseCode: '2BB3',
        shortName: 'NEUROSCI 2BB3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b7e',
      },
      {
        _id: '6073b77f56f56ef638974b80',
        courseName:
          'NEUROSCI 2CC3 A/B S - Research Practicum in Systems/Circuits Neuroscience',
        courseCode: '2CC3',
        shortName: 'NEUROSCI 2CC3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b80',
      },
      {
        _id: '6073b77f56f56ef638974b82',
        courseName:
          'NEUROSCI 2DD3 A/B S - Research Practicum in Behavioural/Cognitive Neuroscience',
        courseCode: '2DD3',
        shortName: 'NEUROSCI 2DD3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b82',
      },
      {
        _id: '6073b77f56f56ef638974b84',
        courseName: 'NEUROSCI 2XN0 A/B - Neuroscience Tutorial',
        courseCode: '2XN0',
        shortName: 'NEUROSCI 2XN0 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b84',
      },
      {
        _id: '6073b77f56f56ef638974b86',
        courseName:
          'NEUROSCI 3BB3 A/B S - Individual Lab Study in Cellular/Molecular Neuroscience',
        courseCode: '3BB3',
        shortName: 'NEUROSCI 3BB3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b86',
      },
      {
        _id: '6073b77f56f56ef638974b88',
        courseName:
          'NEUROSCI 3CC3 A/B S - Individual Lab Study in Systems/Circuits Neuroscience',
        courseCode: '3CC3',
        shortName: 'NEUROSCI 3CC3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b88',
      },
      {
        _id: '6073b77f56f56ef638974b8a',
        courseName:
          'NEUROSCI 3DD3 A/B S - Individual Lab Study in Behavioural/Cognitive Neuroscience',
        courseCode: '3DD3',
        shortName: 'NEUROSCI 3DD3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b8a',
      },
      {
        _id: '6073b77f56f56ef638974b8c',
        courseName: 'NEUROSCI 3E03 - Neuroscience Lab',
        courseCode: '3E03',
        shortName: 'NEUROSCI 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b77f56f56ef638974b8c',
      },
      {
        _id: '6073b78056f56ef638974b8e',
        courseName: 'NEUROSCI 3J03 - Visual Neuroscience',
        courseCode: '3J03',
        shortName: 'NEUROSCI 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b8e',
      },
      {
        _id: '6073b78056f56ef638974b90',
        courseName: 'NEUROSCI 3SN3 - Neural Circuits',
        courseCode: '3SN3',
        shortName: 'NEUROSCI 3SN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b90',
      },
      {
        _id: '6073b78056f56ef638974b92',
        courseName: 'NEUROSCI 4L09 A/B - Neuroscience Thesis',
        courseCode: '4L09',
        shortName: 'NEUROSCI 4L09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b92',
      },
      {
        _id: '6073b78056f56ef638974b94',
        courseName: 'NEUROSCI 4L12 A/B - Neuroscience Senior Thesis',
        courseCode: '4L12',
        shortName: 'NEUROSCI 4L12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b94',
      },
      {
        _id: '6073b78056f56ef638974b96',
        courseName: 'NEUROSCI 4S03 A/B - Neuroscience Seminar',
        courseCode: '4S03',
        shortName: 'NEUROSCI 4S03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b96',
      },
      {
        _id: '6073b78056f56ef638974b98',
        courseName:
          'NURSING 1F03 - Introduction to Nursing and Health I for Basic Stream',
        courseCode: '1F03',
        shortName: 'NURSING 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b98',
      },
      {
        _id: '6073b78056f56ef638974b9a',
        courseName:
          'NURSING 1G03 - Introduction to Nursing and Health II for Basic Stream',
        courseCode: '1G03',
        shortName: 'NURSING 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b9a',
      },
      {
        _id: '6073b78056f56ef638974b9c',
        courseName:
          'NURSING 1I02 - Introduction to Nursing Practice for Basic Stream',
        courseCode: '1I02',
        shortName: 'NURSING 1I02',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b9c',
      },
      {
        _id: '6073b78056f56ef638974b9e',
        courseName:
          'NURSING 1J02 - Professional Nursing Practice I for Basic Stream',
        courseCode: '1J02',
        shortName: 'NURSING 1J02',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974b9e',
      },
      {
        _id: '6073b78056f56ef638974ba0',
        courseName:
          'NURSING 1K02 A/B - Community Engagement and Citizenship for Basic Stream',
        courseCode: '1K02',
        shortName: 'NURSING 1K02 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974ba0',
      },
      {
        _id: '6073b78056f56ef638974ba2',
        courseName: 'NURSING 1RP0 - Reintegration Course',
        courseCode: '1RP0',
        shortName: 'NURSING 1RP0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974ba2',
      },
      {
        _id: '6073b78056f56ef638974ba4',
        courseName:
          'NURSING 2A04 - Introduction to Professional RN Practice I for Post Diploma RPN Stream',
        courseCode: '2A04',
        shortName: 'NURSING 2A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78056f56ef638974ba4',
      },
      {
        _id: '6073b78156f56ef638974ba6',
        courseName:
          'NURSING 2AA3 - Professional RN Practice II for Post Diploma RPN Stream',
        courseCode: '2AA3',
        shortName: 'NURSING 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974ba6',
      },
      {
        _id: '6073b78156f56ef638974ba8',
        courseName:
          'NURSING 2I04 - Introduction to Professional Nursing and Health for Accelerated Stream',
        courseCode: '2I04',
        shortName: 'NURSING 2I04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974ba8',
      },
      {
        _id: '6073b78156f56ef638974baa',
        courseName:
          'NURSING 2J04 - Professional Nursing Practice II for Accelerated Stream',
        courseCode: '2J04',
        shortName: 'NURSING 2J04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974baa',
      },
      {
        _id: '6073b78156f56ef638974bac',
        courseName:
          'NURSING 2L03 - Professional Nursing Practice II for Basic Stream',
        courseCode: '2L03',
        shortName: 'NURSING 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bac',
      },
      {
        _id: '6073b78156f56ef638974bae',
        courseName:
          'NURSING 2MM3 - Nursing Concepts in Health and Illness I for Basic Stream',
        courseCode: '2MM3',
        shortName: 'NURSING 2MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bae',
      },
      {
        _id: '6073b78156f56ef638974bb0',
        courseName:
          'NURSING 2NN3 - Nursing Concepts in Health and Illness II for Basic Stream',
        courseCode: '2NN3',
        shortName: 'NURSING 2NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bb0',
      },
      {
        _id: '6073b78156f56ef638974bb2',
        courseName:
          'NURSING 2P03 - Professional Nursing Practice III for Basic Stream',
        courseCode: '2P03',
        shortName: 'NURSING 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bb2',
      },
      {
        _id: '6073b78156f56ef638974bb4',
        courseName:
          'NURSING 2U04 - Introduction to Nursing Practice I for Accelerated Stream',
        courseCode: '2U04',
        shortName: 'NURSING 2U04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bb4',
      },
      {
        _id: '6073b78156f56ef638974bb6',
        courseName:
          'NURSING 2V04 - Nursing Concepts in Health & Illness for Accelerated Stream',
        courseCode: '2V04',
        shortName: 'NURSING 2V04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bb6',
      },
      {
        _id: '6073b78156f56ef638974bb8',
        courseName:
          'NURSING 3A04 - Clinical Reasoning in RN Practice for Post Diploma RPN Stream',
        courseCode: '3A04',
        shortName: 'NURSING 3A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bb8',
      },
      {
        _id: '6073b78156f56ef638974bba',
        courseName: 'NURSING 3QQ3 - Professional Community Nursing Practice',
        courseCode: '3QQ3',
        shortName: 'NURSING 3QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bba',
      },
      {
        _id: '6073b78156f56ef638974bbc',
        courseName:
          'NURSING 3RS3 - Nursing Concepts in Health and Illness III for Post Diploma RPN Stream',
        courseCode: '3RS3',
        shortName: 'NURSING 3RS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bbc',
      },
      {
        _id: '6073b78156f56ef638974bbe',
        courseName:
          'NURSING 3RT3 - Nursing Concepts in Health and Illness IV for Post Diploma RPN Stream',
        courseCode: '3RT3',
        shortName: 'NURSING 3RT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78156f56ef638974bbe',
      },
      {
        _id: '6073b78256f56ef638974bc0',
        courseName:
          'NURSING 3RY4 - Professional Nursing Practice V for Post Diploma RPN Stream',
        courseCode: '3RY4',
        shortName: 'NURSING 3RY4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bc0',
      },
      {
        _id: '6073b78256f56ef638974bc2',
        courseName:
          'NURSING 3SS3 - Nursing Concepts in Health and Illness III for Basic Stream',
        courseCode: '3SS3',
        shortName: 'NURSING 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bc2',
      },
      {
        _id: '6073b78256f56ef638974bc4',
        courseName:
          'NURSING 3TT3 - Nursing Concepts in Health and Illness IV for Basic Stream',
        courseCode: '3TT3',
        shortName: 'NURSING 3TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bc4',
      },
      {
        _id: '6073b78256f56ef638974bc6',
        courseName:
          'NURSING 3V03 - Nursing Concepts in Health & Illness for Accelerated Stream II',
        courseCode: '3V03',
        shortName: 'NURSING 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bc6',
      },
      {
        _id: '6073b78256f56ef638974bc8',
        courseName: 'NURSING 3X04 - Professional Nursing Practice IV',
        courseCode: '3X04',
        shortName: 'NURSING 3X04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bc8',
      },
      {
        _id: '6073b78256f56ef638974bca',
        courseName: 'NURSING 3Y04 - Professional Nursing Practice V',
        courseCode: '3Y04',
        shortName: 'NURSING 3Y04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bca',
      },
      {
        _id: '6073b78256f56ef638974bcc',
        courseName:
          'NURSING 3ZA3 - Professional Nursing Practice III for Accelerated Stream',
        courseCode: '3ZA3',
        shortName: 'NURSING 3ZA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bcc',
      },
      {
        _id: '6073b78256f56ef638974bce',
        courseName:
          'NURSING 3ZB3 - Professional Nursing Practice IV for Accelerated Stream',
        courseCode: '3ZB3',
        shortName: 'NURSING 3ZB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bce',
      },
      {
        _id: '6073b78256f56ef638974bd0',
        courseName: 'NURSING 4DD6 A/B S - Advanced Leadership and Management',
        courseCode: '4DD6',
        shortName: 'NURSING 4DD6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bd0',
      },
      {
        _id: '6073b78256f56ef638974bd2',
        courseName: 'NURSING 4FF3 A/B S - Integrative Leadership Project',
        courseCode: '4FF3',
        shortName: 'NURSING 4FF3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bd2',
      },
      {
        _id: '6073b78256f56ef638974bd4',
        courseName: 'NURSING 4H03 - Introduction to Concepts in Global Health',
        courseCode: '4H03',
        shortName: 'NURSING 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bd4',
      },
      {
        _id: '6073b78256f56ef638974bd6',
        courseName: 'NURSING 4HH3 A/B S - Quality Management',
        courseCode: '4HH3',
        shortName: 'NURSING 4HH3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78256f56ef638974bd6',
      },
      {
        _id: '6073b78356f56ef638974bd8',
        courseName: 'NURSING 4I03 A/B S - Leading Interprofessional Teams',
        courseCode: '4I03',
        shortName: 'NURSING 4I03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bd8',
      },
      {
        _id: '6073b78356f56ef638974bda',
        courseName: 'NURSING 4J07 - Professional Nursing Practice VI',
        courseCode: '4J07',
        shortName: 'NURSING 4J07',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bda',
      },
      {
        _id: '6073b78356f56ef638974bdc',
        courseName: 'NURSING 4K10 - Professional Practice and the New Graduate',
        courseCode: '4K10',
        shortName: 'NURSING 4K10',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bdc',
      },
      {
        _id: '6073b78356f56ef638974bde',
        courseName: 'NURSING 4P04 - Advanced Nursing Concepts I',
        courseCode: '4P04',
        shortName: 'NURSING 4P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bde',
      },
      {
        _id: '6073b78356f56ef638974be0',
        courseName: 'NURSING 4Q03 - Advanced Nursing Concepts II',
        courseCode: '4Q03',
        shortName: 'NURSING 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974be0',
      },
      {
        _id: '6073b78356f56ef638974be2',
        courseName: 'NURSING 4Z03 A/B S - Conflict Management',
        courseCode: '4Z03',
        shortName: 'NURSING 4Z03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974be2',
      },
      {
        _id: '6073b78356f56ef638974be4',
        courseName: 'OJIBWE 1Z03 - Introduction to Ojibwe Language and Culture',
        courseCode: '1Z03',
        shortName: 'OJIBWE 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974be4',
      },
      {
        _id: '6073b78356f56ef638974be6',
        courseName: 'OJIBWE 2Z03 - Intermediate Ojibwe',
        courseCode: '2Z03',
        shortName: 'OJIBWE 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974be6',
      },
      {
        _id: '6073b78356f56ef638974be8',
        courseName: 'PEACEST 1A03 - Introduction to Peace Studies',
        courseCode: '1A03',
        shortName: 'PEACEST 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974be8',
      },
      {
        _id: '6073b78356f56ef638974bea',
        courseName:
          'PEACEST 2A03 - Conflict Transformation: Theory and Practice',
        courseCode: '2A03',
        shortName: 'PEACEST 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bea',
      },
      {
        _id: '6073b78356f56ef638974bec',
        courseName: 'PEACEST 2B03 - Human Rights and Social Justice',
        courseCode: '2B03',
        shortName: 'PEACEST 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bec',
      },
      {
        _id: '6073b78356f56ef638974bee',
        courseName: 'PEACEST 2BB3 - Introduction to the Study of War',
        courseCode: '2BB3',
        shortName: 'PEACEST 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78356f56ef638974bee',
      },
      {
        _id: '6073b78456f56ef638974bf0',
        courseName: 'PEACEST 2C03 - Peace and Popular Culture',
        courseCode: '2C03',
        shortName: 'PEACEST 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bf0',
      },
      {
        _id: '6073b78456f56ef638974bf2',
        courseName:
          'PEACEST 2E03 - Peer-to-Peer Problem-Based Inquiry: Archival Peace Research',
        courseCode: '2E03',
        shortName: 'PEACEST 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bf2',
      },
      {
        _id: '6073b78456f56ef638974bf4',
        courseName: 'PEACEST 2GW3 - A History of Global War',
        courseCode: '2GW3',
        shortName: 'PEACEST 2GW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bf4',
      },
      {
        _id: '6073b78456f56ef638974bf6',
        courseName: 'PEACEST 2LS3 - Language and Society',
        courseCode: '2LS3',
        shortName: 'PEACEST 2LS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bf6',
      },
      {
        _id: '6073b78456f56ef638974bf8',
        courseName: 'PEACEST 3B03 - Peace-Building and Health Initiatives',
        courseCode: '3B03',
        shortName: 'PEACEST 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bf8',
      },
      {
        _id: '6073b78456f56ef638974bfa',
        courseName: 'PEACEST 3C03 - Research Methods for Peace Studies',
        courseCode: '3C03',
        shortName: 'PEACEST 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bfa',
      },
      {
        _id: '6073b78456f56ef638974bfc',
        courseName: 'PEACEST 3D03 - Globalization and Peace',
        courseCode: '3D03',
        shortName: 'PEACEST 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bfc',
      },
      {
        _id: '6073b78456f56ef638974bfe',
        courseName: 'PEACEST 3GG3 - Theories of Decolonization and Resistance',
        courseCode: '3GG3',
        shortName: 'PEACEST 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974bfe',
      },
      {
        _id: '6073b78456f56ef638974c00',
        courseName: 'PEACEST 3P03 - Practicum: Practical Peace Building',
        courseCode: '3P03',
        shortName: 'PEACEST 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974c00',
      },
      {
        _id: '6073b78456f56ef638974c02',
        courseName: 'PEACEST 3Q03 - Philosophy of Law',
        courseCode: '3Q03',
        shortName: 'PEACEST 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974c02',
      },
      {
        _id: '6073b78456f56ef638974c04',
        courseName: 'PEACEST 3W03 - Contemporary Native Literature in Canada',
        courseCode: '3W03',
        shortName: 'PEACEST 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78456f56ef638974c04',
      },
      {
        _id: '6073b78556f56ef638974c06',
        courseName:
          'PEACEST 3X03 - Contemporary Native Literature in the United States',
        courseCode: '3X03',
        shortName: 'PEACEST 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c06',
      },
      {
        _id: '6073b78556f56ef638974c08',
        courseName: 'PEACEST 3XX3 - Human Rights in History',
        courseCode: '3XX3',
        shortName: 'PEACEST 3XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c08',
      },
      {
        _id: '6073b78556f56ef638974c0a',
        courseName: 'PEACEST 3Y03 - Special Topics in Peace Studies',
        courseCode: '3Y03',
        shortName: 'PEACEST 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c0a',
      },
      {
        _id: '6073b78556f56ef638974c0c',
        courseName: 'PEACEST 4B03 - Independent Research',
        courseCode: '4B03',
        shortName: 'PEACEST 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c0c',
      },
      {
        _id: '6073b78556f56ef638974c0e',
        courseName: 'PEACEST 4FC3 - Experiential Learning, Theory and Practice',
        courseCode: '4FC3',
        shortName: 'PEACEST 4FC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c0e',
      },
      {
        _id: '6073b78556f56ef638974c10',
        courseName: 'PEACEST 4G03 - Peace Through Health: Praxis',
        courseCode: '4G03',
        shortName: 'PEACEST 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c10',
      },
      {
        _id: '6073b78556f56ef638974c12',
        courseName: 'PEACEST 4GG3 - Nation and Genocide in the Modern World',
        courseCode: '4GG3',
        shortName: 'PEACEST 4GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c12',
      },
      {
        _id: '6073b78556f56ef638974c14',
        courseName: 'PEACEST 4J03 - International Law, Peace and Ecology',
        courseCode: '4J03',
        shortName: 'PEACEST 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c14',
      },
      {
        _id: '6073b78556f56ef638974c16',
        courseName: 'PEACEST 4L03 - Peace, Environment and Health',
        courseCode: '4L03',
        shortName: 'PEACEST 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c16',
      },
      {
        _id: '6073b78556f56ef638974c18',
        courseName:
          'PEACEST 4MA3 - Forensic Archival Research in Conflict and Peace',
        courseCode: '4MA3',
        shortName: 'PEACEST 4MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c18',
      },
      {
        _id: '6073b78556f56ef638974c1a',
        courseName: 'PEACEST 4RR3 - Truth and Reconciliation After Atrocity',
        courseCode: '4RR3',
        shortName: 'PEACEST 4RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c1a',
      },
      {
        _id: '6073b78556f56ef638974c1c',
        courseName: 'PEACEST 4ST3 - Special Topics Seminar',
        courseCode: '4ST3',
        shortName: 'PEACEST 4ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c1c',
      },
      {
        _id: '6073b78556f56ef638974c1e',
        courseName: 'PHARMAC 3A06 A/B - Introduction to Pharmacology',
        courseCode: '3A06',
        shortName: 'PHARMAC 3A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78556f56ef638974c1e',
      },
      {
        _id: '6073b78656f56ef638974c20',
        courseName: 'PHARMAC 3B06 A/B - Methods in Pharmacology',
        courseCode: '3B06',
        shortName: 'PHARMAC 3B06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c20',
      },
      {
        _id: '6073b78656f56ef638974c22',
        courseName: 'PHARMAC 4A03 - Receptor-Drug Interactions',
        courseCode: '4A03',
        shortName: 'PHARMAC 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c22',
      },
      {
        _id: '6073b78656f56ef638974c24',
        courseName: 'PHARMAC 4AA3 - Advanced Topics in Pharmacology',
        courseCode: '4AA3',
        shortName: 'PHARMAC 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c24',
      },
      {
        _id: '6073b78656f56ef638974c26',
        courseName: 'PHARMAC 4C03 - Principles of Toxicology',
        courseCode: '4C03',
        shortName: 'PHARMAC 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c26',
      },
      {
        _id: '6073b78656f56ef638974c28',
        courseName: 'PHARMAC 4D03 - Drug Design',
        courseCode: '4D03',
        shortName: 'PHARMAC 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c28',
      },
      {
        _id: '6073b78656f56ef638974c2a',
        courseName: 'PHARMAC 4E03 - Social Pharmacology',
        courseCode: '4E03',
        shortName: 'PHARMAC 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c2a',
      },
      {
        _id: '6073b78656f56ef638974c2c',
        courseName: 'PHARMAC 4T12 - Senior Thesis',
        courseCode: '4T12',
        shortName: 'PHARMAC 4T12',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c2c',
      },
      {
        _id: '6073b78656f56ef638974c2e',
        courseName: 'PHILOS 1A03 - Philosophical Texts',
        courseCode: '1A03',
        shortName: 'PHILOS 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c2e',
      },
      {
        _id: '6073b78656f56ef638974c30',
        courseName: 'PHILOS 1B03 - Philosophy, Law and Society',
        courseCode: '1B03',
        shortName: 'PHILOS 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c30',
      },
      {
        _id: '6073b78656f56ef638974c32',
        courseName: 'PHILOS 1E03 - Philosophical Questions',
        courseCode: '1E03',
        shortName: 'PHILOS 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c32',
      },
      {
        _id: '6073b78656f56ef638974c34',
        courseName: 'PHILOS 1F03 - Meaning in Life',
        courseCode: '1F03',
        shortName: 'PHILOS 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c34',
      },
      {
        _id: '6073b78656f56ef638974c36',
        courseName: 'PHILOS 2B03 - Introductory Logic',
        courseCode: '2B03',
        shortName: 'PHILOS 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78656f56ef638974c36',
      },
      {
        _id: '6073b78756f56ef638974c38',
        courseName: 'PHILOS 2CT3 - Critical Thinking',
        courseCode: '2CT3',
        shortName: 'PHILOS 2CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c38',
      },
      {
        _id: '6073b78756f56ef638974c3a',
        courseName: 'PHILOS 2D03 - Bioethics',
        courseCode: '2D03',
        shortName: 'PHILOS 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c3a',
      },
      {
        _id: '6073b78756f56ef638974c3c',
        courseName: 'PHILOS 2E03 - Classical Chinese Philosophy',
        courseCode: '2E03',
        shortName: 'PHILOS 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c3c',
      },
      {
        _id: '6073b78756f56ef638974c3e',
        courseName: 'PHILOS 2F03 - Philosophical Psychology',
        courseCode: '2F03',
        shortName: 'PHILOS 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c3e',
      },
      {
        _id: '6073b78756f56ef638974c40',
        courseName: 'PHILOS 2G03 - Social and Political Issues',
        courseCode: '2G03',
        shortName: 'PHILOS 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c40',
      },
      {
        _id: '6073b78756f56ef638974c42',
        courseName: 'PHILOS 2H03 - Aesthetics',
        courseCode: '2H03',
        shortName: 'PHILOS 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c42',
      },
      {
        _id: '6073b78756f56ef638974c44',
        courseName: 'PHILOS 2N03 - Business Ethics',
        courseCode: '2N03',
        shortName: 'PHILOS 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c44',
      },
      {
        _id: '6073b78756f56ef638974c46',
        courseName: 'PHILOS 2P03 - Ancient Greek Philosophy',
        courseCode: '2P03',
        shortName: 'PHILOS 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c46',
      },
      {
        _id: '6073b78756f56ef638974c48',
        courseName: 'PHILOS 2Q03 - Justice, Political Philosophy, and Law',
        courseCode: '2Q03',
        shortName: 'PHILOS 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c48',
      },
      {
        _id: '6073b78756f56ef638974c4a',
        courseName: 'PHILOS 2S03 - History of Political Philosophy',
        courseCode: '2S03',
        shortName: 'PHILOS 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c4a',
      },
      {
        _id: '6073b78756f56ef638974c4c',
        courseName: 'PHILOS 2TT3 - Ethical Issues in Communication',
        courseCode: '2TT3',
        shortName: 'PHILOS 2TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c4c',
      },
      {
        _id: '6073b78756f56ef638974c4e',
        courseName: 'PHILOS 2X03 - Early Modern Philosophy I',
        courseCode: '2X03',
        shortName: 'PHILOS 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78756f56ef638974c4e',
      },
      {
        _id: '6073b78856f56ef638974c50',
        courseName: 'PHILOS 2XX3 - Early Modern Philosophy II',
        courseCode: '2XX3',
        shortName: 'PHILOS 2XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c50',
      },
      {
        _id: '6073b78856f56ef638974c52',
        courseName: 'PHILOS 2YY3 - Ethics',
        courseCode: '2YY3',
        shortName: 'PHILOS 2YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c52',
      },
      {
        _id: '6073b78856f56ef638974c54',
        courseName: 'PHILOS 2ZZ3 - Philosophy of Love and Sex',
        courseCode: '2ZZ3',
        shortName: 'PHILOS 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c54',
      },
      {
        _id: '6073b78856f56ef638974c56',
        courseName: 'PHILOS 3B03 - Continental Philosophy after Hegel',
        courseCode: '3B03',
        shortName: 'PHILOS 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c56',
      },
      {
        _id: '6073b78856f56ef638974c58',
        courseName: 'PHILOS 3C03 - Advanced Bioethics',
        courseCode: '3C03',
        shortName: 'PHILOS 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c58',
      },
      {
        _id: '6073b78856f56ef638974c5a',
        courseName: 'PHILOS 3CC3 - Advanced Ethics',
        courseCode: '3CC3',
        shortName: 'PHILOS 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c5a',
      },
      {
        _id: '6073b78856f56ef638974c5c',
        courseName: 'PHILOS 3D03 - Philosophy of Science',
        courseCode: '3D03',
        shortName: 'PHILOS 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c5c',
      },
      {
        _id: '6073b78856f56ef638974c5e',
        courseName: 'PHILOS 3E03 - Philosophy of Language',
        courseCode: '3E03',
        shortName: 'PHILOS 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c5e',
      },
      {
        _id: '6073b78856f56ef638974c60',
        courseName: 'PHILOS 3EE3 - Contemporary Continental Philosophy',
        courseCode: '3EE3',
        shortName: 'PHILOS 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c60',
      },
      {
        _id: '6073b78856f56ef638974c62',
        courseName: 'PHILOS 3HH3 - Metaphysics',
        courseCode: '3HH3',
        shortName: 'PHILOS 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c62',
      },
      {
        _id: '6073b78856f56ef638974c64',
        courseName: 'PHILOS 3I03 - Philosophy and Feminism',
        courseCode: '3I03',
        shortName: 'PHILOS 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c64',
      },
      {
        _id: '6073b78856f56ef638974c66',
        courseName: 'PHILOS 3L03 - Environmental Philosophy',
        courseCode: '3L03',
        shortName: 'PHILOS 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78856f56ef638974c66',
      },
      {
        _id: '6073b78956f56ef638974c68',
        courseName: 'PHILOS 3N03 - Political Philosophy',
        courseCode: '3N03',
        shortName: 'PHILOS 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c68',
      },
      {
        _id: '6073b78956f56ef638974c6a',
        courseName: 'PHILOS 3NN3 - Philosophy of the Enlightenment',
        courseCode: '3NN3',
        shortName: 'PHILOS 3NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c6a',
      },
      {
        _id: '6073b78956f56ef638974c6c',
        courseName: 'PHILOS 3O03 - Theory of Knowledge',
        courseCode: '3O03',
        shortName: 'PHILOS 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c6c',
      },
      {
        _id: '6073b78956f56ef638974c6e',
        courseName: 'PHILOS 3P03 - Philosophies of War and Peace',
        courseCode: '3P03',
        shortName: 'PHILOS 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c6e',
      },
      {
        _id: '6073b78956f56ef638974c70',
        courseName: 'PHILOS 3Q03 - Philosophy of Law',
        courseCode: '3Q03',
        shortName: 'PHILOS 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c70',
      },
      {
        _id: '6073b78956f56ef638974c72',
        courseName: 'PHILOS 3VV3 - Kant',
        courseCode: '3VV3',
        shortName: 'PHILOS 3VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c72',
      },
      {
        _id: '6073b78956f56ef638974c74',
        courseName: 'PHILOS 3W03 - Reading Course',
        courseCode: '3W03',
        shortName: 'PHILOS 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c74',
      },
      {
        _id: '6073b78956f56ef638974c76',
        courseName: 'PHILOS 3XX3 - Plato',
        courseCode: '3XX3',
        shortName: 'PHILOS 3XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c76',
      },
      {
        _id: '6073b78956f56ef638974c78',
        courseName: 'PHILOS 3YY3 - Hegel',
        courseCode: '3YY3',
        shortName: 'PHILOS 3YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c78',
      },
      {
        _id: '6073b78956f56ef638974c7a',
        courseName: 'PHILOS 3ZZ3 - Aristotle',
        courseCode: '3ZZ3',
        shortName: 'PHILOS 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c7a',
      },
      {
        _id: '6073b78956f56ef638974c7c',
        courseName: 'PHILOS 4A03 - Early Modern Philosophy',
        courseCode: '4A03',
        shortName: 'PHILOS 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78956f56ef638974c7c',
      },
      {
        _id: '6073b78a56f56ef638974c7e',
        courseName: 'PHILOS 4C03 - Philosophy of Constitutional Law',
        courseCode: '4C03',
        shortName: 'PHILOS 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c7e',
      },
      {
        _id: '6073b78a56f56ef638974c80',
        courseName: 'PHILOS 4D03 - Twentieth-Century Analytic Philosophy',
        courseCode: '4D03',
        shortName: 'PHILOS 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c80',
      },
      {
        _id: '6073b78a56f56ef638974c82',
        courseName: 'PHILOS 4F03 - Issues in Continental Philosophy',
        courseCode: '4F03',
        shortName: 'PHILOS 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c82',
      },
      {
        _id: '6073b78a56f56ef638974c84',
        courseName: 'PHILOS 4I03 - Medieval Philosophy',
        courseCode: '4I03',
        shortName: 'PHILOS 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c84',
      },
      {
        _id: '6073b78a56f56ef638974c86',
        courseName: 'PHILOS 4K03 - Seminar in Ancient Philosophy',
        courseCode: '4K03',
        shortName: 'PHILOS 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c86',
      },
      {
        _id: '6073b78a56f56ef638974c88',
        courseName: 'PHILOS 4Q03 - Normative Jurisprudence',
        courseCode: '4Q03',
        shortName: 'PHILOS 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c88',
      },
      {
        _id: '6073b78a56f56ef638974c8a',
        courseName: 'PHILOS 4S03 - Human Rights and Global Justice',
        courseCode: '4S03',
        shortName: 'PHILOS 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c8a',
      },
      {
        _id: '6073b78a56f56ef638974c8c',
        courseName:
          'PHILOS 4V03 - Multidisciplinary Workshop in Applied Ethics and Policy',
        courseCode: '4V03',
        shortName: 'PHILOS 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c8c',
      },
      {
        _id: '6073b78a56f56ef638974c8e',
        courseName: 'PHILOS 4W03 - Independent Study',
        courseCode: '4W03',
        shortName: 'PHILOS 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c8e',
      },
      {
        _id: '6073b78a56f56ef638974c90',
        courseName: 'PHILOS 4XP3 A/B - Law And Community',
        courseCode: '4XP3',
        shortName: 'PHILOS 4XP3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c90',
      },
      {
        _id: '6073b78a56f56ef638974c92',
        courseName: 'PHILOS 4XX3 - Intermediate Logic',
        courseCode: '4XX3',
        shortName: 'PHILOS 4XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78a56f56ef638974c92',
      },
      {
        _id: '6073b78b56f56ef638974c94',
        courseName: 'PHILOS 4YE3 A/B - Philosophy, Pedagogy And Community',
        courseCode: '4YE3',
        shortName: 'PHILOS 4YE3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974c94',
      },
      {
        _id: '6073b78b56f56ef638974c96',
        courseName: 'PHILOS 4YY3 - Topics in Ethics',
        courseCode: '4YY3',
        shortName: 'PHILOS 4YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974c96',
      },
      {
        _id: '6073b78b56f56ef638974c98',
        courseName: 'PHYSICS 1A03 - Introductory Physics',
        courseCode: '1A03',
        shortName: 'PHYSICS 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974c98',
      },
      {
        _id: '6073b78b56f56ef638974c9a',
        courseName: 'PHYSICS 1AA3 - Introduction To Modern Physics',
        courseCode: '1AA3',
        shortName: 'PHYSICS 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974c9a',
      },
      {
        _id: '6073b78b56f56ef638974c9c',
        courseName:
          'PHYSICS 1C03 - Physics for the Chemical and Physical Sciences',
        courseCode: '1C03',
        shortName: 'PHYSICS 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974c9c',
      },
      {
        _id: '6073b78b56f56ef638974c9e',
        courseName:
          'PHYSICS 1CC3 - Modern Physics for the Chemical and Physical Sciences',
        courseCode: '1CC3',
        shortName: 'PHYSICS 1CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974c9e',
      },
      {
        _id: '6073b78b56f56ef638974ca0',
        courseName: 'PHYSICS 1D03 - Introductory Mechanics',
        courseCode: '1D03',
        shortName: 'PHYSICS 1D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b78b56f56ef638974ca0',
      },
      {
        _id: '6073b78b56f56ef638974ca2',
        courseName: 'PHYSICS 1E03 - Waves, Electricity and Magnetic Fields',
        courseCode: '1E03',
        shortName: 'PHYSICS 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 9,
        id: '6073b78b56f56ef638974ca2',
      },
      {
        _id: '6073b78b56f56ef638974ca4',
        courseName: 'PHYSICS 2B03 - Electricity and Magnetism I',
        courseCode: '2B03',
        shortName: 'PHYSICS 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974ca4',
      },
      {
        _id: '6073b78b56f56ef638974ca6',
        courseName: 'PHYSICS 2BB3 - Electricity and Magnetism II',
        courseCode: '2BB3',
        shortName: 'PHYSICS 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974ca6',
      },
      {
        _id: '6073b78b56f56ef638974ca8',
        courseName: 'PHYSICS 2C03 - Modern Physics',
        courseCode: '2C03',
        shortName: 'PHYSICS 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78b56f56ef638974ca8',
      },
      {
        _id: '6073b78c56f56ef638974caa',
        courseName: 'PHYSICS 2E03 - Mechanics',
        courseCode: '2E03',
        shortName: 'PHYSICS 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974caa',
      },
      {
        _id: '6073b78c56f56ef638974cac',
        courseName: 'PHYSICS 2G03 - Scientific Computing',
        courseCode: '2G03',
        shortName: 'PHYSICS 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cac',
      },
      {
        _id: '6073b78c56f56ef638974cae',
        courseName:
          'PHYSICS 2NM3 - Media Numeracy: Telling Stories With Numbers',
        courseCode: '2NM3',
        shortName: 'PHYSICS 2NM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cae',
      },
      {
        _id: '6073b78c56f56ef638974cb0',
        courseName: 'PHYSICS 2P03 - Introductory Laboratory',
        courseCode: '2P03',
        shortName: 'PHYSICS 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cb0',
      },
      {
        _id: '6073b78c56f56ef638974cb2',
        courseName:
          'PHYSICS 2X00 - From Classical Physics Into the Quantum World',
        courseCode: '2X00',
        shortName: 'PHYSICS 2X00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cb2',
      },
      {
        _id: '6073b78c56f56ef638974cb4',
        courseName:
          'PHYSICS 3A03 - Relativity, Gravity and the Geometry of Spacetime',
        courseCode: '3A03',
        shortName: 'PHYSICS 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cb4',
      },
      {
        _id: '6073b78c56f56ef638974cb6',
        courseName: 'PHYSICS 3C03 - Analytical Mechanics',
        courseCode: '3C03',
        shortName: 'PHYSICS 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cb6',
      },
      {
        _id: '6073b78c56f56ef638974cb8',
        courseName: 'PHYSICS 3D03 A/B - Inquiry in Physics',
        courseCode: '3D03',
        shortName: 'PHYSICS 3D03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cb8',
      },
      {
        _id: '6073b78c56f56ef638974cba',
        courseName: 'PHYSICS 3DA1 - Inquiry in Physics (I)',
        courseCode: '3DA1',
        shortName: 'PHYSICS 3DA1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cba',
      },
      {
        _id: '6073b78c56f56ef638974cbc',
        courseName: 'PHYSICS 3DB2 - Inquiry in Physics (II)',
        courseCode: '3DB2',
        shortName: 'PHYSICS 3DB2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cbc',
      },
      {
        _id: '6073b78c56f56ef638974cbe',
        courseName: 'PHYSICS 3ET3 A/B S - Physics Teaching Placement',
        courseCode: '3ET3',
        shortName: 'PHYSICS 3ET3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cbe',
      },
      {
        _id: '6073b78c56f56ef638974cc0',
        courseName:
          'PHYSICS 3G03 - Introduction to Neural Networks and Machine Learning',
        courseCode: '3G03',
        shortName: 'PHYSICS 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78c56f56ef638974cc0',
      },
      {
        _id: '6073b78d56f56ef638974cc2',
        courseName: 'PHYSICS 3H03 A/B - Intermediate Laboratory',
        courseCode: '3H03',
        shortName: 'PHYSICS 3H03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cc2',
      },
      {
        _id: '6073b78d56f56ef638974cc4',
        courseName: 'PHYSICS 3HC1 - Advanced Laboratory (I)',
        courseCode: '3HC1',
        shortName: 'PHYSICS 3HC1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cc4',
      },
      {
        _id: '6073b78d56f56ef638974cc6',
        courseName: 'PHYSICS 3HD2 - Advanced Laboratory (II)',
        courseCode: '3HD2',
        shortName: 'PHYSICS 3HD2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cc6',
      },
      {
        _id: '6073b78d56f56ef638974cc8',
        courseName: 'PHYSICS 3K03 - Thermodynamics and Statistical Mechanics',
        courseCode: '3K03',
        shortName: 'PHYSICS 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cc8',
      },
      {
        _id: '6073b78d56f56ef638974cca',
        courseName: 'PHYSICS 3MM3 - Quantum Mechanics I',
        courseCode: '3MM3',
        shortName: 'PHYSICS 3MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cca',
      },
      {
        _id: '6073b78d56f56ef638974ccc',
        courseName: 'PHYSICS 3N04 - Physical Optics',
        courseCode: '3N04',
        shortName: 'PHYSICS 3N04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974ccc',
      },
      {
        _id: '6073b78d56f56ef638974cce',
        courseName: 'PHYSICS 3P03 A/B - Advanced Laboratory',
        courseCode: '3P03',
        shortName: 'PHYSICS 3P03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cce',
      },
      {
        _id: '6073b78d56f56ef638974cd0',
        courseName: 'PHYSICS 3QI3 - Introduction to Quantum Information',
        courseCode: '3QI3',
        shortName: 'PHYSICS 3QI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cd0',
      },
      {
        _id: '6073b78d56f56ef638974cd2',
        courseName: 'PHYSICS 4B03 - Electromagnetic Theory',
        courseCode: '4B03',
        shortName: 'PHYSICS 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cd2',
      },
      {
        _id: '6073b78d56f56ef638974cd4',
        courseName: 'PHYSICS 4E03 - Particle and Nuclear Physics',
        courseCode: '4E03',
        shortName: 'PHYSICS 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cd4',
      },
      {
        _id: '6073b78d56f56ef638974cd6',
        courseName: 'PHYSICS 4F03 - Quantum Mechanics II',
        courseCode: '4F03',
        shortName: 'PHYSICS 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78d56f56ef638974cd6',
      },
      {
        _id: '6073b78e56f56ef638974cd8',
        courseName: 'PHYSICS 4G03 - Computational Physics',
        courseCode: '4G03',
        shortName: 'PHYSICS 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974cd8',
      },
      {
        _id: '6073b78e56f56ef638974cda',
        courseName: 'PHYSICS 4IS3 - Independent Study',
        courseCode: '4IS3',
        shortName: 'PHYSICS 4IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974cda',
      },
      {
        _id: '6073b78e56f56ef638974cdc',
        courseName: 'PHYSICS 4K03 - Solid State Physics',
        courseCode: '4K03',
        shortName: 'PHYSICS 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974cdc',
      },
      {
        _id: '6073b78e56f56ef638974cde',
        courseName: 'PHYSICS 4L03 A/B - Literature Review',
        courseCode: '4L03',
        shortName: 'PHYSICS 4L03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974cde',
      },
      {
        _id: '6073b78e56f56ef638974ce0',
        courseName: 'PHYSICS 4P06 A/B - Senior Research Project',
        courseCode: '4P06',
        shortName: 'PHYSICS 4P06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974ce0',
      },
      {
        _id: '6073b78e56f56ef638974ce2',
        courseName: 'PHYSICS 4Q03 - Introduction to Quantum Field Theory',
        courseCode: '4Q03',
        shortName: 'PHYSICS 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974ce2',
      },
      {
        _id: '6073b78e56f56ef638974ce4',
        courseName: 'PHYSICS 5GT3 - Graduate Topics in Physics',
        courseCode: '5GT3',
        shortName: 'PHYSICS 5GT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974ce4',
      },
      {
        _id: '6073b78e56f56ef638974ce6',
        courseName: 'PNB 2A03 - Python for PNB',
        courseCode: '2A03',
        shortName: 'PNB 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974ce6',
      },
      {
        _id: '6073b78e56f56ef638974ce8',
        courseName: 'PNB 2QQ3 A/B S - Introductory Independent Research',
        courseCode: '2QQ3',
        shortName: 'PNB 2QQ3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974ce8',
      },
      {
        _id: '6073b78e56f56ef638974cea',
        courseName: 'PNB 2XA3 - Human Perception & Cognition',
        courseCode: '2XA3',
        shortName: 'PNB 2XA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974cea',
      },
      {
        _id: '6073b78e56f56ef638974cec',
        courseName: 'PNB 2XB3 - Neuroanatomy & Neurophysiology',
        courseCode: '2XB3',
        shortName: 'PNB 2XB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78e56f56ef638974cec',
      },
      {
        _id: '6073b78f56f56ef638974cee',
        courseName: 'PNB 2XC3 - Animal Behaviour & Evolution',
        courseCode: '2XC3',
        shortName: 'PNB 2XC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cee',
      },
      {
        _id: '6073b78f56f56ef638974cf0',
        courseName: 'PNB 2XD3 - Integrative PNB Through Scientific Writing',
        courseCode: '2XD3',
        shortName: 'PNB 2XD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cf0',
      },
      {
        _id: '6073b78f56f56ef638974cf2',
        courseName: 'PNB 2XE3 - Descriptive Statistics and Research Methods',
        courseCode: '2XE3',
        shortName: 'PNB 2XE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cf2',
      },
      {
        _id: '6073b78f56f56ef638974cf4',
        courseName: 'PNB 2XF3 - Perspectives in PNB',
        courseCode: '2XF3',
        shortName: 'PNB 2XF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cf4',
      },
      {
        _id: '6073b78f56f56ef638974cf6',
        courseName: 'PNB 2XT0 - PNB Tutorial',
        courseCode: '2XT0',
        shortName: 'PNB 2XT0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cf6',
      },
      {
        _id: '6073b78f56f56ef638974cf8',
        courseName: 'PNB 3EE3 - Perception Laboratory',
        courseCode: '3EE3',
        shortName: 'PNB 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cf8',
      },
      {
        _id: '6073b78f56f56ef638974cfa',
        courseName: 'PNB 3EV3 - Evolutionary Psychology Lab',
        courseCode: '3EV3',
        shortName: 'PNB 3EV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cfa',
      },
      {
        _id: '6073b78f56f56ef638974cfc',
        courseName: 'PNB 3HP3 - History of Psychology',
        courseCode: '3HP3',
        shortName: 'PNB 3HP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cfc',
      },
      {
        _id: '6073b78f56f56ef638974cfe',
        courseName: 'PNB 3I06 A/B - Practica in Psychology',
        courseCode: '3I06',
        shortName: 'PNB 3I06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974cfe',
      },
      {
        _id: '6073b78f56f56ef638974d00',
        courseName: 'PNB 3L03 - Neurodevelopment & Plasticity Lab',
        courseCode: '3L03',
        shortName: 'PNB 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974d00',
      },
      {
        _id: '6073b78f56f56ef638974d02',
        courseName: 'PNB 3MM3 - Cognitive Neuroscience Lab',
        courseCode: '3MM3',
        shortName: 'PNB 3MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b78f56f56ef638974d02',
      },
      {
        _id: '6073b79056f56ef638974d04',
        courseName: 'PNB 3Q03 A/B S - Independent Library Study',
        courseCode: '3Q03',
        shortName: 'PNB 3Q03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d04',
      },
      {
        _id: '6073b79056f56ef638974d06',
        courseName: 'PNB 3QM3 A/B S - Independent Research in Mental Health',
        courseCode: '3QM3',
        shortName: 'PNB 3QM3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d06',
      },
      {
        _id: '6073b79056f56ef638974d08',
        courseName: 'PNB 3QQ3 A/B S - Intermediate Independent Research',
        courseCode: '3QQ3',
        shortName: 'PNB 3QQ3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d08',
      },
      {
        _id: '6073b79056f56ef638974d0a',
        courseName: 'PNB 3S03 - Animal Behaviour Lab',
        courseCode: '3S03',
        shortName: 'PNB 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d0a',
      },
      {
        _id: '6073b79056f56ef638974d0c',
        courseName: 'PNB 3V03 - Laboratory in Human Memory and Cognition',
        courseCode: '3V03',
        shortName: 'PNB 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d0c',
      },
      {
        _id: '6073b79056f56ef638974d0e',
        courseName: 'PNB 3XE3 - Inferential Statistics and Research Methods',
        courseCode: '3XE3',
        shortName: 'PNB 3XE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d0e',
      },
      {
        _id: '6073b79056f56ef638974d10',
        courseName: 'PNB 4A03 - Assessment in Children',
        courseCode: '4A03',
        shortName: 'PNB 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d10',
      },
      {
        _id: '6073b79056f56ef638974d12',
        courseName: 'PNB 4D06 A/B - Senior Thesis',
        courseCode: '4D06',
        shortName: 'PNB 4D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d12',
      },
      {
        _id: '6073b79056f56ef638974d14',
        courseName: 'PNB 4D09 A/B - Senior Honours Thesis',
        courseCode: '4D09',
        shortName: 'PNB 4D09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d14',
      },
      {
        _id: '6073b79056f56ef638974d16',
        courseName: 'PNB 4DD6 A/B - Senior Thesis',
        courseCode: '4DD6',
        shortName: 'PNB 4DD6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d16',
      },
      {
        _id: '6073b79056f56ef638974d18',
        courseName: 'PNB 4E03 - Social Cognitive Neuroscience',
        courseCode: '4E03',
        shortName: 'PNB 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d18',
      },
      {
        _id: '6073b79056f56ef638974d1a',
        courseName:
          'PNB 4J03 - Inquiry in Psychology, Neuroscience & Behaviour',
        courseCode: '4J03',
        shortName: 'PNB 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79056f56ef638974d1a',
      },
      {
        _id: '6073b79156f56ef638974d1c',
        courseName: 'PNB 4Q03 A/B S - Senior Independent Library Study',
        courseCode: '4Q03',
        shortName: 'PNB 4Q03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d1c',
      },
      {
        _id: '6073b79156f56ef638974d1e',
        courseName: 'PNB 4QQ3 A/B S - Senior Independent Research',
        courseCode: '4QQ3',
        shortName: 'PNB 4QQ3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d1e',
      },
      {
        _id: '6073b79156f56ef638974d20',
        courseName:
          'PNB 4SC6 A/B - Science Communication in the Behavioural Sciences',
        courseCode: '4SC6',
        shortName: 'PNB 4SC6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d20',
      },
      {
        _id: '6073b79156f56ef638974d22',
        courseName: 'POLISH 1Z03 - Beginnerâ€™s Polish I',
        courseCode: '1Z03',
        shortName: 'POLISH 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d22',
      },
      {
        _id: '6073b79156f56ef638974d24',
        courseName: 'POLISH 1ZZ3 - Beginnerâ€™s Polish II',
        courseCode: '1ZZ3',
        shortName: 'POLISH 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d24',
      },
      {
        _id: '6073b79156f56ef638974d26',
        courseName: 'POLISH 2Z03 - Intermediate Polish I',
        courseCode: '2Z03',
        shortName: 'POLISH 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d26',
      },
      {
        _id: '6073b79156f56ef638974d28',
        courseName: 'POLISH 2ZZ3 - Intermediate Polish II',
        courseCode: '2ZZ3',
        shortName: 'POLISH 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d28',
      },
      {
        _id: '6073b79156f56ef638974d2a',
        courseName: 'POLSCI 1AA3 - Government, Politics, and Power',
        courseCode: '1AA3',
        shortName: 'POLSCI 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d2a',
      },
      {
        _id: '6073b79156f56ef638974d2c',
        courseName: 'POLSCI 1AB3 - Politics and Power in a Globalizing World',
        courseCode: '1AB3',
        shortName: 'POLSCI 1AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d2c',
      },
      {
        _id: '6073b79156f56ef638974d2e',
        courseName: 'POLSCI 2C03 - Force and Fear, Crime and Punishment',
        courseCode: '2C03',
        shortName: 'POLSCI 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d2e',
      },
      {
        _id: '6073b79156f56ef638974d30',
        courseName: 'POLSCI 2D03 - Canadian Democracy',
        courseCode: '2D03',
        shortName: 'POLSCI 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79156f56ef638974d30',
      },
      {
        _id: '6073b79256f56ef638974d32',
        courseName: 'POLSCI 2EM3 - Political Economy of the Media',
        courseCode: '2EM3',
        shortName: 'POLSCI 2EM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d32',
      },
      {
        _id: '6073b79256f56ef638974d34',
        courseName: 'POLSCI 2F03 - Politics, Power and Influence in Canada',
        courseCode: '2F03',
        shortName: 'POLSCI 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d34',
      },
      {
        _id: '6073b79256f56ef638974d36',
        courseName: 'POLSCI 2H03 - Globalization and the State',
        courseCode: '2H03',
        shortName: 'POLSCI 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d36',
      },
      {
        _id: '6073b79256f56ef638974d38',
        courseName: 'POLSCI 2I03 - Global Politics',
        courseCode: '2I03',
        shortName: 'POLSCI 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d38',
      },
      {
        _id: '6073b79256f56ef638974d3a',
        courseName: 'POLSCI 2J03 - Global Political Economy',
        courseCode: '2J03',
        shortName: 'POLSCI 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d3a',
      },
      {
        _id: '6073b79256f56ef638974d3c',
        courseName: 'POLSCI 2LW3 - Communication Policy and Law',
        courseCode: '2LW3',
        shortName: 'POLSCI 2LW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d3c',
      },
      {
        _id: '6073b79256f56ef638974d3e',
        courseName:
          'POLSCI 2M03 - Governance, Representation, and Participation in Democracies',
        courseCode: '2M03',
        shortName: 'POLSCI 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d3e',
      },
      {
        _id: '6073b79256f56ef638974d40',
        courseName: 'POLSCI 2MN3 - Reel Politics',
        courseCode: '2MN3',
        shortName: 'POLSCI 2MN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d40',
      },
      {
        _id: '6073b79256f56ef638974d42',
        courseName: 'POLSCI 2NN3 - Politics by Design',
        courseCode: '2NN3',
        shortName: 'POLSCI 2NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d42',
      },
      {
        _id: '6073b79256f56ef638974d44',
        courseName: 'POLSCI 2O06 A/B - Political Theory',
        courseCode: '2O06',
        shortName: 'POLSCI 2O06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d44',
      },
      {
        _id: '6073b79256f56ef638974d46',
        courseName: 'POLSCI 2PF3 - Politics of Funny',
        courseCode: '2PF3',
        shortName: 'POLSCI 2PF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79256f56ef638974d46',
      },
      {
        _id: '6073b79356f56ef638974d48',
        courseName: 'POLSCI 2U03 - Public Policy and Administration',
        courseCode: '2U03',
        shortName: 'POLSCI 2U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d48',
      },
      {
        _id: '6073b79356f56ef638974d4a',
        courseName: 'POLSCI 2US3 - US Politics',
        courseCode: '2US3',
        shortName: 'POLSCI 2US3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d4a',
      },
      {
        _id: '6073b79356f56ef638974d4c',
        courseName: 'POLSCI 2XX3 - Politics of the Developing World',
        courseCode: '2XX3',
        shortName: 'POLSCI 2XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d4c',
      },
      {
        _id: '6073b79356f56ef638974d4e',
        courseName:
          'POLSCI 3B03 - Honours Issues in International Relations and Global Public Policy',
        courseCode: '3B03',
        shortName: 'POLSCI 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d4e',
      },
      {
        _id: '6073b79356f56ef638974d50',
        courseName:
          'POLSCI 3BB3 - Political Communication: Canada and the World',
        courseCode: '3BB3',
        shortName: 'POLSCI 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d50',
      },
      {
        _id: '6073b79356f56ef638974d52',
        courseName:
          'POLSCI 3C03 - Government and Politics of Indigenous People',
        courseCode: '3C03',
        shortName: 'POLSCI 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d52',
      },
      {
        _id: '6073b79356f56ef638974d54',
        courseName:
          'POLSCI 3CC3 - Political Authority: 20th-Century Political Theory',
        courseCode: '3CC3',
        shortName: 'POLSCI 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79356f56ef638974d54',
      },
      {
        _id: '6073b79456f56ef638974d56',
        courseName: 'POLSCI 3CL3 - Constitutional and Public Law in Canada',
        courseCode: '3CL3',
        shortName: 'POLSCI 3CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d56',
      },
      {
        _id: '6073b79456f56ef638974d58',
        courseName: 'POLSCI 3EE3 - International Relations: North-South',
        courseCode: '3EE3',
        shortName: 'POLSCI 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d58',
      },
      {
        _id: '6073b79456f56ef638974d5a',
        courseName: 'POLSCI 3FG3 - Public Service Leadership',
        courseCode: '3FG3',
        shortName: 'POLSCI 3FG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d5a',
      },
      {
        _id: '6073b79456f56ef638974d5c',
        courseName:
          'POLSCI 3G03 - Ethnicity and Multiculturalism: Theory and Practice',
        courseCode: '3G03',
        shortName: 'POLSCI 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d5c',
      },
      {
        _id: '6073b79456f56ef638974d5e',
        courseName: 'POLSCI 3GC3 - Global Climate Change',
        courseCode: '3GC3',
        shortName: 'POLSCI 3GC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d5e',
      },
      {
        _id: '6073b79456f56ef638974d60',
        courseName: 'POLSCI 3GG3 - Federalism',
        courseCode: '3GG3',
        shortName: 'POLSCI 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d60',
      },
      {
        _id: '6073b79456f56ef638974d62',
        courseName: 'POLSCI 3H03 - Honours Issues in Comparative Politics',
        courseCode: '3H03',
        shortName: 'POLSCI 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d62',
      },
      {
        _id: '6073b79456f56ef638974d64',
        courseName: 'POLSCI 3I03 - Topics in American Politics',
        courseCode: '3I03',
        shortName: 'POLSCI 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79456f56ef638974d64',
      },
      {
        _id: '6073b79556f56ef638974d66',
        courseName: 'POLSCI 3IP3 - Intellectual Property',
        courseCode: '3IP3',
        shortName: 'POLSCI 3IP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d66',
      },
      {
        _id: '6073b79556f56ef638974d68',
        courseName:
          'POLSCI 3J03 - Honours Issues in Canadian Politics and Canadian Public Policy',
        courseCode: '3J03',
        shortName: 'POLSCI 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d68',
      },
      {
        _id: '6073b79556f56ef638974d6a',
        courseName: 'POLSCI 3JJ3 - Provincial Politics in Canada',
        courseCode: '3JJ3',
        shortName: 'POLSCI 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d6a',
      },
      {
        _id: '6073b79556f56ef638974d6c',
        courseName:
          'POLSCI 3JR3 - The Rule of Law and Legal and Judicial Reforms in the Developing World',
        courseCode: '3JR3',
        shortName: 'POLSCI 3JR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d6c',
      },
      {
        _id: '6073b79556f56ef638974d6e',
        courseName:
          'POLSCI 3K03 - Migration and Citizenship: Canadian, Comparative and Global Perspectives',
        courseCode: '3K03',
        shortName: 'POLSCI 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d6e',
      },
      {
        _id: '6073b79556f56ef638974d70',
        courseName: 'POLSCI 3KA3 - Indigenous Human Rights',
        courseCode: '3KA3',
        shortName: 'POLSCI 3KA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d70',
      },
      {
        _id: '6073b79556f56ef638974d72',
        courseName:
          'POLSCI 3KK3 - Genocide: Sociological and Political Perspectives',
        courseCode: '3KK3',
        shortName: 'POLSCI 3KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d72',
      },
      {
        _id: '6073b79556f56ef638974d74',
        courseName: 'POLSCI 3LA3 - Religion and Politics',
        courseCode: '3LA3',
        shortName: 'POLSCI 3LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d74',
      },
      {
        _id: '6073b79556f56ef638974d76',
        courseName: 'POLSCI 3LB3 - Globalization and the World Order',
        courseCode: '3LB3',
        shortName: 'POLSCI 3LB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d76',
      },
      {
        _id: '6073b79556f56ef638974d78',
        courseName: 'POLSCI 3LC3 - Southeast Asian Politics',
        courseCode: '3LC3',
        shortName: 'POLSCI 3LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79556f56ef638974d78',
      },
      {
        _id: '6073b79656f56ef638974d7a',
        courseName: 'POLSCI 3LL3 - Development and Public Policy',
        courseCode: '3LL3',
        shortName: 'POLSCI 3LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d7a',
      },
      {
        _id: '6073b79656f56ef638974d7c',
        courseName: 'POLSCI 3LP3 - Topics in Law and Policy',
        courseCode: '3LP3',
        shortName: 'POLSCI 3LP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d7c',
      },
      {
        _id: '6073b79656f56ef638974d7e',
        courseName: 'POLSCI 3NN3 - Statistical Analysis of Primary Data',
        courseCode: '3NN3',
        shortName: 'POLSCI 3NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d7e',
      },
      {
        _id: '6073b79656f56ef638974d80',
        courseName: 'POLSCI 3PB3 - Politics from Below',
        courseCode: '3PB3',
        shortName: 'POLSCI 3PB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d80',
      },
      {
        _id: '6073b79656f56ef638974d82',
        courseName: 'POLSCI 3PG3 - Political Geography',
        courseCode: '3PG3',
        shortName: 'POLSCI 3PG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d82',
      },
      {
        _id: '6073b79656f56ef638974d84',
        courseName: 'POLSCI 3PR3 - Practice of Politics',
        courseCode: '3PR3',
        shortName: 'POLSCI 3PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d84',
      },
      {
        _id: '6073b79656f56ef638974d86',
        courseName: 'POLSCI 3Q03 - The Causes of War',
        courseCode: '3Q03',
        shortName: 'POLSCI 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d86',
      },
      {
        _id: '6073b79656f56ef638974d88',
        courseName: 'POLSCI 3RF3 - The Charter of Rights and Freedoms',
        courseCode: '3RF3',
        shortName: 'POLSCI 3RF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d88',
      },
      {
        _id: '6073b79656f56ef638974d8a',
        courseName: 'POLSCI 3UU3 - Reading Course',
        courseCode: '3UU3',
        shortName: 'POLSCI 3UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d8a',
      },
      {
        _id: '6073b79656f56ef638974d8c',
        courseName: 'POLSCI 3V03 - Gender and Politics',
        courseCode: '3V03',
        shortName: 'POLSCI 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d8c',
      },
      {
        _id: '6073b79656f56ef638974d8e',
        courseName: 'POLSCI 3VV3 - Democratic Theory',
        courseCode: '3VV3',
        shortName: 'POLSCI 3VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79656f56ef638974d8e',
      },
      {
        _id: '6073b79756f56ef638974d90',
        courseName: 'POLSCI 3WP3 - Working in Politics',
        courseCode: '3WP3',
        shortName: 'POLSCI 3WP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d90',
      },
      {
        _id: '6073b79756f56ef638974d92',
        courseName: 'POLSCI 3Y03 - Democratization and Human Rights',
        courseCode: '3Y03',
        shortName: 'POLSCI 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d92',
      },
      {
        _id: '6073b79756f56ef638974d94',
        courseName: 'POLSCI 3Z03 - Canadian Public Sector Management',
        courseCode: '3Z03',
        shortName: 'POLSCI 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d94',
      },
      {
        _id: '6073b79756f56ef638974d96',
        courseName: 'POLSCI 4AA6 A/B - Contemporary Politics',
        courseCode: '4AA6',
        shortName: 'POLSCI 4AA6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d96',
      },
      {
        _id: '6073b79756f56ef638974d98',
        courseName: 'POLSCI 4CA3 - Issues in Canadian Politics',
        courseCode: '4CA3',
        shortName: 'POLSCI 4CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d98',
      },
      {
        _id: '6073b79756f56ef638974d9a',
        courseName: 'POLSCI 4CF3 - Canadian Foreign Policy',
        courseCode: '4CF3',
        shortName: 'POLSCI 4CF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d9a',
      },
      {
        _id: '6073b79756f56ef638974d9c',
        courseName: 'POLSCI 4D06 A/B - International Politics',
        courseCode: '4D06',
        shortName: 'POLSCI 4D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d9c',
      },
      {
        _id: '6073b79756f56ef638974d9e',
        courseName: 'POLSCI 4DV3 - Death and Violence',
        courseCode: '4DV3',
        shortName: 'POLSCI 4DV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974d9e',
      },
      {
        _id: '6073b79756f56ef638974da0',
        courseName: 'POLSCI 4FF3 - Rights and Justice',
        courseCode: '4FF3',
        shortName: 'POLSCI 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974da0',
      },
      {
        _id: '6073b79756f56ef638974da2',
        courseName: 'POLSCI 4G06 A/B - Politics of Public Policy',
        courseCode: '4G06',
        shortName: 'POLSCI 4G06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974da2',
      },
      {
        _id: '6073b79756f56ef638974da4',
        courseName: 'POLSCI 4GC3 - Advanced Issues in Global Citizenship',
        courseCode: '4GC3',
        shortName: 'POLSCI 4GC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974da4',
      },
      {
        _id: '6073b79756f56ef638974da6',
        courseName: 'POLSCI 4GG3 - Conceptual Issues in Global Politics',
        courseCode: '4GG3',
        shortName: 'POLSCI 4GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79756f56ef638974da6',
      },
      {
        _id: '6073b79856f56ef638974da8',
        courseName: 'POLSCI 4HH3 - Critical Theory',
        courseCode: '4HH3',
        shortName: 'POLSCI 4HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974da8',
      },
      {
        _id: '6073b79856f56ef638974daa',
        courseName: 'POLSCI 4HR3 - Human Rights',
        courseCode: '4HR3',
        shortName: 'POLSCI 4HR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974daa',
      },
      {
        _id: '6073b79856f56ef638974dac',
        courseName: 'POLSCI 4JJ3 - Cosmopolitanism',
        courseCode: '4JJ3',
        shortName: 'POLSCI 4JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974dac',
      },
      {
        _id: '6073b79856f56ef638974dae',
        courseName: 'POLSCI 4JS3 - Judicial Studies',
        courseCode: '4JS3',
        shortName: 'POLSCI 4JS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974dae',
      },
      {
        _id: '6073b79856f56ef638974db0',
        courseName: 'POLSCI 4KB3 - Non-Western International Relations',
        courseCode: '4KB3',
        shortName: 'POLSCI 4KB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974db0',
      },
      {
        _id: '6073b79856f56ef638974db2',
        courseName: 'POLSCI 4KC3 - Comparative Democratization',
        courseCode: '4KC3',
        shortName: 'POLSCI 4KC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974db2',
      },
      {
        _id: '6073b79856f56ef638974db4',
        courseName: 'POLSCI 4KK3 - Advanced Issues in Global Security',
        courseCode: '4KK3',
        shortName: 'POLSCI 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974db4',
      },
      {
        _id: '6073b79856f56ef638974db6',
        courseName: 'POLSCI 4LA3 - Politics in Latin America',
        courseCode: '4LA3',
        shortName: 'POLSCI 4LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974db6',
      },
      {
        _id: '6073b79856f56ef638974db8',
        courseName: 'POLSCI 4LC3 - Research on Law and Courts',
        courseCode: '4LC3',
        shortName: 'POLSCI 4LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974db8',
      },
      {
        _id: '6073b79856f56ef638974dba',
        courseName: 'POLSCI 4NN3 - Studies in Global Political Economy',
        courseCode: '4NN3',
        shortName: 'POLSCI 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974dba',
      },
      {
        _id: '6073b79856f56ef638974dbc',
        courseName: 'POLSCI 4O06 A/B - Canadian Politics',
        courseCode: '4O06',
        shortName: 'POLSCI 4O06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79856f56ef638974dbc',
      },
      {
        _id: '6073b79956f56ef638974dbe',
        courseName: 'POLSCI 4OL3 - Origins of Law',
        courseCode: '4OL3',
        shortName: 'POLSCI 4OL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dbe',
      },
      {
        _id: '6073b79956f56ef638974dc0',
        courseName: 'POLSCI 4PA3 - Policy Analysis and Implementation',
        courseCode: '4PA3',
        shortName: 'POLSCI 4PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dc0',
      },
      {
        _id: '6073b79956f56ef638974dc2',
        courseName: 'POLSCI 4PE3 - Global Political Ecology',
        courseCode: '4PE3',
        shortName: 'POLSCI 4PE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dc2',
      },
      {
        _id: '6073b79956f56ef638974dc4',
        courseName: 'POLSCI 4PO3 - Public Opinion',
        courseCode: '4PO3',
        shortName: 'POLSCI 4PO3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dc4',
      },
      {
        _id: '6073b79956f56ef638974dc6',
        courseName: 'POLSCI 4Q06 A/B - Comparative Politics',
        courseCode: '4Q06',
        shortName: 'POLSCI 4Q06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dc6',
      },
      {
        _id: '6073b79956f56ef638974dc8',
        courseName: 'POLSCI 4QQ3 - Issues in International Politics',
        courseCode: '4QQ3',
        shortName: 'POLSCI 4QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dc8',
      },
      {
        _id: '6073b79956f56ef638974dca',
        courseName: 'POLSCI 4RR3 - Health Policy in the Industrialized World',
        courseCode: '4RR3',
        shortName: 'POLSCI 4RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dca',
      },
      {
        _id: '6073b79956f56ef638974dcc',
        courseName: 'POLSCI 4RT3 - Radical Political Theory',
        courseCode: '4RT3',
        shortName: 'POLSCI 4RT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dcc',
      },
      {
        _id: '6073b79956f56ef638974dce',
        courseName: 'POLSCI 4SS3 - Public Opinion and Policy',
        courseCode: '4SS3',
        shortName: 'POLSCI 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dce',
      },
      {
        _id: '6073b79956f56ef638974dd0',
        courseName: 'POLSCI 4UF3 - US Foreign Policy',
        courseCode: '4UF3',
        shortName: 'POLSCI 4UF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dd0',
      },
      {
        _id: '6073b79956f56ef638974dd2',
        courseName:
          'POLSCI 4UP3 - Urban Politics in the Post-Industrial Era: Hamilton & Detroit',
        courseCode: '4UP3',
        shortName: 'POLSCI 4UP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79956f56ef638974dd2',
      },
      {
        _id: '6073b79a56f56ef638974dd4',
        courseName: 'POLSCI 4Y03 - Domination and Decolonization',
        courseCode: '4Y03',
        shortName: 'POLSCI 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974dd4',
      },
      {
        _id: '6073b79a56f56ef638974dd6',
        courseName:
          'POLSCI 4YR3 - Child/Youth Rights and Security in Global Political Perspective',
        courseCode: '4YR3',
        shortName: 'POLSCI 4YR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974dd6',
      },
      {
        _id: '6073b79a56f56ef638974dd8',
        courseName: 'POLSCI 4Z06 A/B - Honours Essay',
        courseCode: '4Z06',
        shortName: 'POLSCI 4Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974dd8',
      },
      {
        _id: '6073b79a56f56ef638974dda',
        courseName: 'POLSCI 4ZZ3 - Experiential Learning in Research',
        courseCode: '4ZZ3',
        shortName: 'POLSCI 4ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974dda',
      },
      {
        _id: '6073b79a56f56ef638974ddc',
        courseName: 'POLSCI 4ZZ6 A/B - Experiential Learning in Research',
        courseCode: '4ZZ6',
        shortName: 'POLSCI 4ZZ6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974ddc',
      },
      {
        _id: '6073b79a56f56ef638974dde',
        courseName: 'PROCTECH 2CA3 - CAD for Design',
        courseCode: '2CA3',
        shortName: 'PROCTECH 2CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974dde',
      },
      {
        _id: '6073b79a56f56ef638974de0',
        courseName: 'PROCTECH 2CE3 - Chemical Engineering I: Mass Balance',
        courseCode: '2CE3',
        shortName: 'PROCTECH 2CE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974de0',
      },
      {
        _id: '6073b79a56f56ef638974de2',
        courseName: 'PROCTECH 2EC3 - Chemical Engineering II: Energy Balance',
        courseCode: '2EC3',
        shortName: 'PROCTECH 2EC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974de2',
      },
      {
        _id: '6073b79a56f56ef638974de4',
        courseName: 'PROCTECH 2EE3 - Electricity and Electronics II',
        courseCode: '2EE3',
        shortName: 'PROCTECH 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974de4',
      },
      {
        _id: '6073b79a56f56ef638974de6',
        courseName: 'PROCTECH 2I03 - Industrial Organic Chemistry',
        courseCode: '2I03',
        shortName: 'PROCTECH 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974de6',
      },
      {
        _id: '6073b79a56f56ef638974de8',
        courseName: 'PROCTECH 2IC3 - Instrumentation and Control',
        courseCode: '2IC3',
        shortName: 'PROCTECH 2IC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79a56f56ef638974de8',
      },
      {
        _id: '6073b79b56f56ef638974dea',
        courseName: 'PROCTECH 2PL3 - Introduction to PLC Programming',
        courseCode: '2PL3',
        shortName: 'PROCTECH 2PL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974dea',
      },
      {
        _id: '6073b79b56f56ef638974dec',
        courseName:
          'PROCTECH 3CE3 - Chemical Engineering III: Unit and Process Design',
        courseCode: '3CE3',
        shortName: 'PROCTECH 3CE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974dec',
      },
      {
        _id: '6073b79b56f56ef638974dee',
        courseName: 'PROCTECH 3CT3 - Control Theory I',
        courseCode: '3CT3',
        shortName: 'PROCTECH 3CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974dee',
      },
      {
        _id: '6073b79b56f56ef638974df0',
        courseName: 'PROCTECH 3MC3 - Motion Control and Robotics',
        courseCode: '3MC3',
        shortName: 'PROCTECH 3MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974df0',
      },
      {
        _id: '6073b79b56f56ef638974df2',
        courseName: 'PROCTECH 3PL3 - Advanced PLC Programming and Control',
        courseCode: '3PL3',
        shortName: 'PROCTECH 3PL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974df2',
      },
      {
        _id: '6073b79b56f56ef638974df4',
        courseName:
          'PROCTECH 4AS3 - Industrial System Components and Integration',
        courseCode: '4AS3',
        shortName: 'PROCTECH 4AS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974df4',
      },
      {
        _id: '6073b79b56f56ef638974df6',
        courseName: 'PROCTECH 4CT3 - Advanced Control Theory II',
        courseCode: '4CT3',
        shortName: 'PROCTECH 4CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974df6',
      },
      {
        _id: '6073b79b56f56ef638974df8',
        courseName: 'PROCTECH 4IC3 - Industrial Networks and Controllers',
        courseCode: '4IC3',
        shortName: 'PROCTECH 4IC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974df8',
      },
      {
        _id: '6073b79b56f56ef638974dfa',
        courseName: 'PROCTECH 4IT3 - Internet Technologies and Databases',
        courseCode: '4IT3',
        shortName: 'PROCTECH 4IT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974dfa',
      },
      {
        _id: '6073b79b56f56ef638974dfc',
        courseName: 'PROCTECH 4MH3 - Machine Health and Remote Monitoring',
        courseCode: '4MH3',
        shortName: 'PROCTECH 4MH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974dfc',
      },
      {
        _id: '6073b79b56f56ef638974dfe',
        courseName: 'PROCTECH 4MS3 - Manufacturing Technologies',
        courseCode: '4MS3',
        shortName: 'PROCTECH 4MS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974dfe',
      },
      {
        _id: '6073b79b56f56ef638974e00',
        courseName: 'PROCTECH 4MT2 - Materials Technology',
        courseCode: '4MT2',
        shortName: 'PROCTECH 4MT2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79b56f56ef638974e00',
      },
      {
        _id: '6073b79c56f56ef638974e02',
        courseName: 'PROCTECH 4SS3 - System Specification and Design',
        courseCode: '4SS3',
        shortName: 'PROCTECH 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e02',
      },
      {
        _id: '6073b79c56f56ef638974e04',
        courseName: 'PROCTECH 4TR1 - Capstone Design Project I',
        courseCode: '4TR1',
        shortName: 'PROCTECH 4TR1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e04',
      },
      {
        _id: '6073b79c56f56ef638974e06',
        courseName: 'PROCTECH 4TR3 - Capstone Design Project II',
        courseCode: '4TR3',
        shortName: 'PROCTECH 4TR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e06',
      },
      {
        _id: '6073b79c56f56ef638974e08',
        courseName: 'PSYCH 1F03 - Survey of Psychology',
        courseCode: '1F03',
        shortName: 'PSYCH 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '6073b79c56f56ef638974e08',
      },
      {
        _id: '6073b79c56f56ef638974e0a',
        courseName: 'PSYCH 1FF3 - Survey of Biological Basis of Psychology',
        courseCode: '1FF3',
        shortName: 'PSYCH 1FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e0a',
      },
      {
        _id: '6073b79c56f56ef638974e0c',
        courseName:
          'PSYCH 1N03 - Introduction to Psychology, Neuroscience & Behaviour',
        courseCode: '1N03',
        shortName: 'PSYCH 1N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e0c',
      },
      {
        _id: '6073b79c56f56ef638974e0e',
        courseName:
          'PSYCH 1NN3 - Foundations of Psychology, Neuroscience & Behaviour',
        courseCode: '1NN3',
        shortName: 'PSYCH 1NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e0e',
      },
      {
        _id: '6073b79c56f56ef638974e10',
        courseName:
          'PSYCH 1X03 - Introduction to Psychology, Neuroscience & Behaviour',
        courseCode: '1X03',
        shortName: 'PSYCH 1X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 16,
        id: '6073b79c56f56ef638974e10',
      },
      {
        _id: '6073b79c56f56ef638974e12',
        courseName:
          'PSYCH 1XX3 - Foundations of Psychology, Neuroscience & Behaviour',
        courseCode: '1XX3',
        shortName: 'PSYCH 1XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b79c56f56ef638974e12',
      },
      {
        _id: '6073b79c56f56ef638974e14',
        courseName: 'PSYCH 2AA3 - Child Development',
        courseCode: '2AA3',
        shortName: 'PSYCH 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79c56f56ef638974e14',
      },
      {
        _id: '6073b79c56f56ef638974e16',
        courseName:
          'PSYCH 2AP3 - Abnormal Psychology: Fundamentals and Major Disorders',
        courseCode: '2AP3',
        shortName: 'PSYCH 2AP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '6073b79c56f56ef638974e16',
      },
      {
        _id: '6073b79d56f56ef638974e18',
        courseName: 'PSYCH 2B03 - Personality',
        courseCode: '2B03',
        shortName: 'PSYCH 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e18',
      },
      {
        _id: '6073b79d56f56ef638974e1a',
        courseName: 'PSYCH 2C03 - Social Psychology',
        courseCode: '2C03',
        shortName: 'PSYCH 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e1a',
      },
      {
        _id: '6073b79d56f56ef638974e1c',
        courseName: 'PSYCH 2E03 - Sensory Processes',
        courseCode: '2E03',
        shortName: 'PSYCH 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e1c',
      },
      {
        _id: '6073b79d56f56ef638974e1e',
        courseName: 'PSYCH 2GG3 - Learning, Measuring, and Shaping Behaviour',
        courseCode: '2GG3',
        shortName: 'PSYCH 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e1e',
      },
      {
        _id: '6073b79d56f56ef638974e20',
        courseName: 'PSYCH 2H03 - Human Learning and Cognition',
        courseCode: '2H03',
        shortName: 'PSYCH 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e20',
      },
      {
        _id: '6073b79d56f56ef638974e22',
        courseName: 'PSYCH 2MP3 - Introduction to Music Cognition',
        courseCode: '2MP3',
        shortName: 'PSYCH 2MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e22',
      },
      {
        _id: '6073b79d56f56ef638974e24',
        courseName: 'PSYCH 2NF3 - Clinical Neuropsychology',
        courseCode: '2NF3',
        shortName: 'PSYCH 2NF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e24',
      },
      {
        _id: '6073b79d56f56ef638974e26',
        courseName: 'PSYCH 3A03 - Audition',
        courseCode: '3A03',
        shortName: 'PSYCH 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e26',
      },
      {
        _id: '6073b79d56f56ef638974e28',
        courseName: 'PSYCH 3AB3 - Adolescent Psychology',
        courseCode: '3AB3',
        shortName: 'PSYCH 3AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e28',
      },
      {
        _id: '6073b79d56f56ef638974e2a',
        courseName: 'PSYCH 3AC3 - Human Sexuality',
        courseCode: '3AC3',
        shortName: 'PSYCH 3AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e2a',
      },
      {
        _id: '6073b79d56f56ef638974e2c',
        courseName: 'PSYCH 3AG3 - Aging',
        courseCode: '3AG3',
        shortName: 'PSYCH 3AG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79d56f56ef638974e2c',
      },
      {
        _id: '6073b79e56f56ef638974e2e',
        courseName: 'PSYCH 3B03 - Special Populations',
        courseCode: '3B03',
        shortName: 'PSYCH 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e2e',
      },
      {
        _id: '6073b79e56f56ef638974e30',
        courseName: 'PSYCH 3BA3 - Positive Psychology',
        courseCode: '3BA3',
        shortName: 'PSYCH 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e30',
      },
      {
        _id: '6073b79e56f56ef638974e32',
        courseName: 'PSYCH 3BN3 - Cognitive Neuroscience I',
        courseCode: '3BN3',
        shortName: 'PSYCH 3BN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e32',
      },
      {
        _id: '6073b79e56f56ef638974e34',
        courseName: 'PSYCH 3C03 - Child Language Acquisition',
        courseCode: '3C03',
        shortName: 'PSYCH 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e34',
      },
      {
        _id: '6073b79e56f56ef638974e36',
        courseName: 'PSYCH 3CB3 - Attitudes and Persuasion',
        courseCode: '3CB3',
        shortName: 'PSYCH 3CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e36',
      },
      {
        _id: '6073b79e56f56ef638974e38',
        courseName: 'PSYCH 3CC3 - Forensic Psychology',
        courseCode: '3CC3',
        shortName: 'PSYCH 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e38',
      },
      {
        _id: '6073b79e56f56ef638974e3a',
        courseName: 'PSYCH 3D03 - The Multisensory Mind',
        courseCode: '3D03',
        shortName: 'PSYCH 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e3a',
      },
      {
        _id: '6073b79e56f56ef638974e3c',
        courseName: 'PSYCH 3EV3 - Evolution and Mental Health',
        courseCode: '3EV3',
        shortName: 'PSYCH 3EV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e3c',
      },
      {
        _id: '6073b79e56f56ef638974e3e',
        courseName: 'PSYCH 3F03 - Evolution and Human Behaviour',
        courseCode: '3F03',
        shortName: 'PSYCH 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e3e',
      },
      {
        _id: '6073b79e56f56ef638974e40',
        courseName: 'PSYCH 3FA3 - The Neurobiology of Learning and Memory',
        courseCode: '3FA3',
        shortName: 'PSYCH 3FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e40',
      },
      {
        _id: '6073b79e56f56ef638974e42',
        courseName: 'PSYCH 3GG3 - Essentials of Developmental Psychology',
        courseCode: '3GG3',
        shortName: 'PSYCH 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79e56f56ef638974e42',
      },
      {
        _id: '6073b79f56f56ef638974e44',
        courseName: 'PSYCH 3H03 - The Arts and The Brain',
        courseCode: '3H03',
        shortName: 'PSYCH 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e44',
      },
      {
        _id: '6073b79f56f56ef638974e46',
        courseName: 'PSYCH 3JJ3 - Socio-Emotional Development',
        courseCode: '3JJ3',
        shortName: 'PSYCH 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e46',
      },
      {
        _id: '6073b79f56f56ef638974e48',
        courseName: 'PSYCH 3M03 - Motivation and Emotion',
        courseCode: '3M03',
        shortName: 'PSYCH 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e48',
      },
      {
        _id: '6073b79f56f56ef638974e4a',
        courseName: 'PSYCH 3MT3 - Psychometrics',
        courseCode: '3MT3',
        shortName: 'PSYCH 3MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e4a',
      },
      {
        _id: '6073b79f56f56ef638974e4c',
        courseName: 'PSYCH 3NL3 - Cognitive Neuroscience of Language',
        courseCode: '3NL3',
        shortName: 'PSYCH 3NL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e4c',
      },
      {
        _id: '6073b79f56f56ef638974e4e',
        courseName: 'PSYCH 3SE3 - Comparative Social Evolution',
        courseCode: '3SE3',
        shortName: 'PSYCH 3SE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e4e',
      },
      {
        _id: '6073b79f56f56ef638974e50',
        courseName: 'PSYCH 3SP3 - The Science of Performance',
        courseCode: '3SP3',
        shortName: 'PSYCH 3SP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e50',
      },
      {
        _id: '6073b79f56f56ef638974e52',
        courseName: 'PSYCH 3T03 - Behavioural Ecology',
        courseCode: '3T03',
        shortName: 'PSYCH 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e52',
      },
      {
        _id: '6073b79f56f56ef638974e54',
        courseName: 'PSYCH 3TT3 - Science of Teaching and Learning',
        courseCode: '3TT3',
        shortName: 'PSYCH 3TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e54',
      },
      {
        _id: '6073b79f56f56ef638974e56',
        courseName: 'PSYCH 3UU3 - Psychology of Language',
        courseCode: '3UU3',
        shortName: 'PSYCH 3UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e56',
      },
      {
        _id: '6073b79f56f56ef638974e58',
        courseName: 'PSYCH 3VV3 - Human Memory',
        courseCode: '3VV3',
        shortName: 'PSYCH 3VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b79f56f56ef638974e58',
      },
      {
        _id: '6073b7a056f56ef638974e5a',
        courseName: 'PSYCH 3WA3 - The Mind as a Work of Art',
        courseCode: '3WA3',
        shortName: 'PSYCH 3WA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e5a',
      },
      {
        _id: '6073b7a056f56ef638974e5c',
        courseName: 'PSYCH 4BN3 - Cognitive Neuroscience II',
        courseCode: '4BN3',
        shortName: 'PSYCH 4BN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e5c',
      },
      {
        _id: '6073b7a056f56ef638974e5e',
        courseName: 'PSYCH 4KK3 - Bayesian Inference',
        courseCode: '4KK3',
        shortName: 'PSYCH 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e5e',
      },
      {
        _id: '6073b7a056f56ef638974e60',
        courseName: 'PSYCH 4MP3 - Neuroscience of Music',
        courseCode: '4MP3',
        shortName: 'PSYCH 4MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e60',
      },
      {
        _id: '6073b7a056f56ef638974e62',
        courseName: 'PSYCH 4R03 - Special Topics in Animal Behaviour',
        courseCode: '4R03',
        shortName: 'PSYCH 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e62',
      },
      {
        _id: '6073b7a056f56ef638974e64',
        courseName: 'RUSSIAN 1Z03 - Intensive Beginnerâ€™s Russian I',
        courseCode: '1Z03',
        shortName: 'RUSSIAN 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e64',
      },
      {
        _id: '6073b7a056f56ef638974e66',
        courseName: 'RUSSIAN 1ZZ3 - Intensive Beginnerâ€™s Russian II',
        courseCode: '1ZZ3',
        shortName: 'RUSSIAN 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e66',
      },
      {
        _id: '6073b7a056f56ef638974e68',
        courseName:
          'RUSSIAN 2G03 - Masterpieces of Russian Literature in Film and TV Series (Taught in English)',
        courseCode: '2G03',
        shortName: 'RUSSIAN 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e68',
      },
      {
        _id: '6073b7a056f56ef638974e6a',
        courseName:
          'RUSSIAN 2H03 - Soviet Propaganda in Films and Other Mass Media (Taught in English)',
        courseCode: '2H03',
        shortName: 'RUSSIAN 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e6a',
      },
      {
        _id: '6073b7a056f56ef638974e6c',
        courseName: 'RUSSIAN 2Z03 - Intermediate Russian I',
        courseCode: '2Z03',
        shortName: 'RUSSIAN 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e6c',
      },
      {
        _id: '6073b7a056f56ef638974e6e',
        courseName: 'RUSSIAN 2ZZ3 - Intermediate Russian II',
        courseCode: '2ZZ3',
        shortName: 'RUSSIAN 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e6e',
      },
      {
        _id: '6073b7a056f56ef638974e70',
        courseName: 'SANSKRIT 3A06 A/B - Introduction to Sanskrit Grammar',
        courseCode: '3A06',
        shortName: 'SANSKRIT 3A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a056f56ef638974e70',
      },
      {
        _id: '6073b7a156f56ef638974e72',
        courseName: 'SANSKRIT 4B06 A/B - Readings in Sanskrit Texts',
        courseCode: '4B06',
        shortName: 'SANSKRIT 4B06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e72',
      },
      {
        _id: '6073b7a156f56ef638974e74',
        courseName: 'SEP 4E03 - Entrepreneurial Opportunity Identification',
        courseCode: '4E03',
        shortName: 'SEP 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e74',
      },
      {
        _id: '6073b7a156f56ef638974e76',
        courseName: 'SEP 4EL3 - Leading Innovation',
        courseCode: '4EL3',
        shortName: 'SEP 4EL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e76',
      },
      {
        _id: '6073b7a156f56ef638974e78',
        courseName: 'SEP 4EP3 - New Enterprise Capstone Project',
        courseCode: '4EP3',
        shortName: 'SEP 4EP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e78',
      },
      {
        _id: '6073b7a156f56ef638974e7a',
        courseName:
          'SEP 4X03 - Livable Cities, the Built and Natural Environment',
        courseCode: '4X03',
        shortName: 'SEP 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e7a',
      },
      {
        _id: '6073b7a156f56ef638974e7c',
        courseName:
          'SCIENCE 1A03 - Investigating Science: Opportunities & Experiences',
        courseCode: '1A03',
        shortName: 'SCIENCE 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e7c',
      },
      {
        _id: '6073b7a156f56ef638974e7e',
        courseName: 'SCIENCE 2A03 - Peer Mentoring in Science',
        courseCode: '2A03',
        shortName: 'SCIENCE 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e7e',
      },
      {
        _id: '6073b7a156f56ef638974e80',
        courseName: 'SCIENCE 2C00 - Skills for Career Success in Science',
        courseCode: '2C00',
        shortName: 'SCIENCE 2C00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e80',
      },
      {
        _id: '6073b7a156f56ef638974e82',
        courseName: 'SCIENCE 2P03 - Impactful Initiatives in Health',
        courseCode: '2P03',
        shortName: 'SCIENCE 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e82',
      },
      {
        _id: '6073b7a156f56ef638974e84',
        courseName:
          'SCIENCE 3C00 - Advanced Job Search Skills For Science Co-op Students',
        courseCode: '3C00',
        shortName: 'SCIENCE 3C00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a156f56ef638974e84',
      },
      {
        _id: '6073b7a256f56ef638974e86',
        courseName: 'SCIENCE 3EP3 A/B S - Applied Science Placement',
        courseCode: '3EP3',
        shortName: 'SCIENCE 3EP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e86',
      },
      {
        _id: '6073b7a256f56ef638974e88',
        courseName: 'SCIENCE 3EX6 A/B S - Applied Science Placement',
        courseCode: '3EX6',
        shortName: 'SCIENCE 3EX6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e88',
      },
      {
        _id: '6073b7a256f56ef638974e8a',
        courseName: 'SCIENCE 3IE0 - Undergraduate Science Internship',
        courseCode: '3IE0',
        shortName: 'SCIENCE 3IE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e8a',
      },
      {
        _id: '6073b7a256f56ef638974e8c',
        courseName: 'SCIENCE 3IF0 - Undergraduate Science Internship',
        courseCode: '3IF0',
        shortName: 'SCIENCE 3IF0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e8c',
      },
      {
        _id: '6073b7a256f56ef638974e8e',
        courseName: 'SCIENCE 3IG0 - Undergraduate Science Internship',
        courseCode: '3IG0',
        shortName: 'SCIENCE 3IG0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e8e',
      },
      {
        _id: '6073b7a256f56ef638974e90',
        courseName: 'SCIENCE 3IH0 - Undergraduate Science Internship',
        courseCode: '3IH0',
        shortName: 'SCIENCE 3IH0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e90',
      },
      {
        _id: '6073b7a256f56ef638974e92',
        courseName: 'SCIENCE 3M03 - Applied Curriculum Design in Science',
        courseCode: '3M03',
        shortName: 'SCIENCE 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e92',
      },
      {
        _id: '6073b7a256f56ef638974e94',
        courseName: 'SCIENCE 3RP3 A/B S - Research Practicum',
        courseCode: '3RP3',
        shortName: 'SCIENCE 3RP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e94',
      },
      {
        _id: '6073b7a256f56ef638974e96',
        courseName: 'SCIENCE 3WT0 - Science Co-op Work Term',
        courseCode: '3WT0',
        shortName: 'SCIENCE 3WT0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e96',
      },
      {
        _id: '6073b7a256f56ef638974e98',
        courseName: 'SCIENCE 3WW0 - Science Co-op Work Term',
        courseCode: '3WW0',
        shortName: 'SCIENCE 3WW0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e98',
      },
      {
        _id: '6073b7a256f56ef638974e9a',
        courseName: 'SCIENCE 4WT0 - Science Co-op Work Term',
        courseCode: '4WT0',
        shortName: 'SCIENCE 4WT0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a256f56ef638974e9a',
      },
      {
        _id: '6073b7a356f56ef638974e9c',
        courseName: 'SCIENCE 4WW0 - Science Co-op Work Term',
        courseCode: '4WW0',
        shortName: 'SCIENCE 4WW0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974e9c',
      },
      {
        _id: '6073b7a356f56ef638974e9e',
        courseName: 'SCIENCE 5WT0 - Science Co-op Work Term',
        courseCode: '5WT0',
        shortName: 'SCIENCE 5WT0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974e9e',
      },
      {
        _id: '6073b7a356f56ef638974ea0',
        courseName: 'SCIENCE 5WW0 - Science Co-op Work Term',
        courseCode: '5WW0',
        shortName: 'SCIENCE 5WW0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974ea0',
      },
      {
        _id: '6073b7a356f56ef638974ea2',
        courseName: 'SCICOMM 2A03 - Foundations in Science Communication',
        courseCode: '2A03',
        shortName: 'SCICOMM 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974ea2',
      },
      {
        _id: '6073b7a356f56ef638974ea4',
        courseName:
          'SCICOMM 2M03 - Engaging Your World: Science for the Global Citizen',
        courseCode: '2M03',
        shortName: 'SCICOMM 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974ea4',
      },
      {
        _id: '6073b7a356f56ef638974ea6',
        courseName: 'SCICOMM 3P03 - Science Communication in Life Sciences',
        courseCode: '3P03',
        shortName: 'SCICOMM 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974ea6',
      },
      {
        _id: '6073b7a356f56ef638974ea8',
        courseName: 'SMRTTECH 3CC3 - Cloud Computing and Internet of Things',
        courseCode: '3CC3',
        shortName: 'SMRTTECH 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974ea8',
      },
      {
        _id: '6073b7a356f56ef638974eaa',
        courseName: 'SMRTTECH 3DE3 - Digital Electronics',
        courseCode: '3DE3',
        shortName: 'SMRTTECH 3DE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974eaa',
      },
      {
        _id: '6073b7a356f56ef638974eac',
        courseName:
          'SMRTTECH 4AI3 - Artificial Intelligence and Machine Learning',
        courseCode: '4AI3',
        shortName: 'SMRTTECH 4AI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974eac',
      },
      {
        _id: '6073b7a356f56ef638974eae',
        courseName: 'SMRTTECH 4ES3 - Embedded Systems',
        courseCode: '4ES3',
        shortName: 'SMRTTECH 4ES3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974eae',
      },
      {
        _id: '6073b7a356f56ef638974eb0',
        courseName: 'SMRTTECH 4HM3 - Human Monitoring and Smart Health Systems',
        courseCode: '4HM3',
        shortName: 'SMRTTECH 4HM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a356f56ef638974eb0',
      },
      {
        _id: '6073b7a456f56ef638974eb2',
        courseName: 'SMRTTECH 4ID3 - IoT Devices and Networks',
        courseCode: '4ID3',
        shortName: 'SMRTTECH 4ID3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974eb2',
      },
      {
        _id: '5ef42181b8f8632cab89d638',
        courseName: 'ENGINEER 1C03 - Engineering Design and Graphics',
        courseCode: '1C03',
        shortName: 'ENGINEER 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 32,
        id: '5ef42181b8f8632cab89d638',
      },
      {
        _id: '5ef42181b8f8632cab89d639',
        courseName: 'ECON 1B03 - Introductory Microeconomics',
        courseCode: '1B03',
        shortName: 'ECON 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 161,
        id: '5ef42181b8f8632cab89d639',
      },
      {
        _id: '5ef42181b8f8632cab89d63b',
        courseName: 'ENGINEER 1D04 - Engineering Computation',
        courseCode: '1D04',
        shortName: 'ENGINEER 1D04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 57,
        id: '5ef42181b8f8632cab89d63b',
      },
      {
        _id: '5ef42181b8f8632cab89d63c',
        courseName: 'ENGINEER 1P03 - Engineering Profession and Practice',
        courseCode: '1P03',
        shortName: 'ENGINEER 1P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 39,
        id: '5ef42181b8f8632cab89d63c',
      },
      {
        _id: '5ef42181b8f8632cab89d63a',
        courseName: 'ECON 1BB3 - Introductory Macroeconomics',
        courseCode: '1BB3',
        shortName: 'ECON 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 55,
        id: '5ef42181b8f8632cab89d63a',
      },
      {
        _id: '5ef42181b8f8632cab89d640',
        courseCode: '3CF3',
        courseName: 'arts-and-science-3CF3',
        shortName: 'arts-and-science-3CF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d640',
      },
      {
        _id: '5ef42181b8f8632cab89d63e',
        courseCode: '1A03',
        courseName: 'Anthropology 1A03',
        shortName: 'Anthropology 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d63e',
      },
      {
        _id: '5ef42181b8f8632cab89d63d',
        courseName: 'BIOLOGY 1M03 - Biodiversity, Evolution and Humanity',
        courseCode: '1M03',
        shortName: 'BIOLOGY 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 15,
        id: '5ef42181b8f8632cab89d63d',
      },
      {
        _id: '5ef42181b8f8632cab89d63f',
        courseCode: '1a06',
        courseName: 'arts-and-science-1a06',
        shortName: 'arts-and-science-1a06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d63f',
      },
      {
        _id: '5ef42181b8f8632cab89d642',
        courseCode: '3U03',
        courseName: 'Biology 3U03',
        shortName: 'Biology 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d642',
      },
      {
        _id: '5ef42181b8f8632cab89d643',
        courseCode: '3Y03',
        courseName: 'Biology 3Y03',
        shortName: 'Biology 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d643',
      },
      {
        _id: '5ef42181b8f8632cab89d645',
        courseCode: '2EE3',
        courseName: 'Biochem 2EE3',
        shortName: 'Biochem 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 46,
        id: '5ef42181b8f8632cab89d645',
      },
      {
        _id: '5ef42181b8f8632cab89d64a',
        courseCode: '3SS3',
        courseName: 'Biology 3SS3',
        shortName: 'Biology 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d64a',
      },
      {
        _id: '5ef42181b8f8632cab89d648',
        courseCode: '3C03',
        courseName: 'Biochem 3C03',
        shortName: 'Biochem 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d648',
      },
      {
        _id: '5ef42181b8f8632cab89d647',
        courseCode: '4S03',
        courseName: 'Biochem 4S03',
        shortName: 'Biochem 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d647',
      },
      {
        _id: '5ef42181b8f8632cab89d64f',
        courseCode: '3I03',
        courseName: 'CHEM 3I03',
        shortName: 'CHEM 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 7,
        id: '5ef42181b8f8632cab89d64f',
      },
      {
        _id: '5ef42181b8f8632cab89d64d',
        courseCode: '1E03',
        courseName: 'CHEM 1E03 - General Chemistry for Engineering I',
        shortName: 'CHEM 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 139,
        id: '5ef42181b8f8632cab89d64d',
      },
      {
        _id: '5ef42181b8f8632cab89d641',
        courseCode: '1A03',
        courseName: 'Biology 1A03',
        shortName: 'Biology 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d641',
      },
      {
        _id: '5ef42181b8f8632cab89d644',
        courseCode: '4U03',
        courseName: 'Biology 4U03',
        shortName: 'Biology 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d644',
      },
      {
        _id: '5ef42181b8f8632cab89d64c',
        courseCode: '1A03',
        courseName: 'CHEM 1A03 - Introductory Chemistry I',
        shortName: 'CHEM 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 66,
        id: '5ef42181b8f8632cab89d64c',
      },
      {
        _id: '5ef42181b8f8632cab89d646',
        courseCode: '4N03',
        courseName: 'Biochem 4N03',
        shortName: 'Biochem 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d646',
      },
      {
        _id: '5ef42181b8f8632cab89d652',
        courseCode: '3AA3E',
        courseName: 'CMST 3AA3E',
        shortName: 'CMST 3AA3E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d652',
      },
      {
        _id: '5ef42181b8f8632cab89d654',
        courseCode: '3E03',
        courseName: 'CMST 3E03',
        shortName: 'CMST 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d654',
      },
      {
        _id: '5ef42181b8f8632cab89d649',
        courseCode: '3G03',
        courseName: 'Biochem 3G03',
        shortName: 'Biochem 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 38,
        id: '5ef42181b8f8632cab89d649',
      },
      {
        _id: '5ef42181b8f8632cab89d64b',
        courseCode: '4E03',
        courseName: 'Biology 4E03',
        shortName: 'Biology 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d64b',
      },
      {
        _id: '5ef42181b8f8632cab89d657',
        courseCode: '2CS3',
        courseName: 'COMP Science 2CS3',
        shortName: 'COMP Science 2CS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d657',
      },
      {
        _id: '5ef42181b8f8632cab89d659',
        courseCode: '2SH4',
        courseName: 'COMP Engineering 2SH4',
        shortName: 'COMP Engineering 2SH4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 18,
        id: '5ef42181b8f8632cab89d659',
      },
      {
        _id: '5ef42181b8f8632cab89d64e',
        courseCode: '3D03',
        courseName: 'CHEM 3D03',
        shortName: 'CHEM 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 8,
        id: '5ef42181b8f8632cab89d64e',
      },
      {
        _id: '5ef42181b8f8632cab89d650',
        courseCode: '3P04',
        courseName: 'CHEM Engineering 3P04',
        shortName: 'CHEM Engineering 3P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 11,
        id: '5ef42181b8f8632cab89d650',
      },
      {
        _id: '5ef42181b8f8632cab89d65e',
        courseCode: '2OA3',
        courseName: 'Chem 2OA3',
        shortName: 'Chem 2OA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 72,
        id: '5ef42181b8f8632cab89d65e',
      },
      {
        _id: '5ef42181b8f8632cab89d653',
        courseCode: '3D03',
        courseName: 'CMST 3D03',
        shortName: 'CMST 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d653',
      },
      {
        _id: '5ef42181b8f8632cab89d656',
        courseCode: '3Y03',
        courseName: 'CMST 3Y03',
        shortName: 'CMST 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d656',
      },
      {
        _id: '5ef42181b8f8632cab89d65c',
        courseCode: '1R03',
        courseName: 'Chem 1R03',
        shortName: 'Chem 1R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d65c',
      },
      {
        _id: '5ef42181b8f8632cab89d655',
        courseCode: '3G03',
        courseName: 'CMST 3G03',
        shortName: 'CMST 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d655',
      },
      {
        _id: '5ef42181b8f8632cab89d658',
        courseCode: '2DI4',
        courseName: 'COMP Engineering 2DI4',
        shortName: 'COMP Engineering 2DI4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 12,
        id: '5ef42181b8f8632cab89d658',
      },
      {
        _id: '5ef42181b8f8632cab89d663',
        courseCode: '2F04',
        courseName: 'Chem Engineering 2F04',
        shortName: 'Chem Engineering 2F04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d663',
      },
      {
        _id: '5ef42181b8f8632cab89d65a',
        courseCode: '3SK3',
        courseName: 'COMP Engineering 3SK3',
        shortName: 'COMP Engineering 3SK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 11,
        id: '5ef42181b8f8632cab89d65a',
      },
      {
        _id: '5ef42181b8f8632cab89d65b',
        courseCode: '1AA3',
        courseName: 'CHEM 1AA3 - Introductory Chemistry II',
        shortName: 'Chem 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 89,
        id: '5ef42181b8f8632cab89d65b',
      },
      {
        _id: '5ef42181b8f8632cab89d661',
        courseCode: '3BB3',
        courseName: 'Chem 3BB3',
        shortName: 'Chem 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d661',
      },
      {
        _id: '5ef42181b8f8632cab89d65d',
        courseCode: '2E03',
        courseName: 'Chem 2E03',
        shortName: 'Chem 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 47,
        id: '5ef42181b8f8632cab89d65d',
      },
      {
        _id: '5ef42181b8f8632cab89d65f',
        courseCode: '2OB3',
        courseName: 'Chem 2OB3',
        shortName: 'Chem 2OB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 164,
        id: '5ef42181b8f8632cab89d65f',
      },
      {
        _id: '5ef42181b8f8632cab89d668',
        courseCode: '3G04',
        courseName: 'Chem Engineering 3G04',
        shortName: 'Chem Engineering 3G04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 9,
        id: '5ef42181b8f8632cab89d668',
      },
      {
        _id: '5ef42181b8f8632cab89d660',
        courseCode: '2PB3',
        courseName: 'Chem 2PB3',
        shortName: 'Chem 2PB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d660',
      },
      {
        _id: '5ef42181b8f8632cab89d666',
        courseCode: '4T03',
        courseName: 'Chem Engineering 4T03',
        shortName: 'Chem Engineering 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d666',
      },
      {
        _id: '5ef42181b8f8632cab89d662',
        courseCode: '4B03',
        courseName: 'Chem 4B03',
        shortName: 'Chem 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d662',
      },
      {
        _id: '5ef42181b8f8632cab89d664',
        courseCode: '3M04',
        courseName: 'Chem Engineering 3M04',
        shortName: 'Chem Engineering 3M04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d664',
      },
      {
        _id: '5ef42181b8f8632cab89d66b',
        courseCode: '3C03',
        courseName: 'Civil Engineering 3C03',
        shortName: 'Civil Engineering 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d66b',
      },
      {
        _id: '5ef42181b8f8632cab89d667',
        courseCode: '3E04',
        courseName: 'Chem Engineering 3E04',
        shortName: 'Chem Engineering 3E04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 12,
        id: '5ef42181b8f8632cab89d667',
      },
      {
        _id: '5ef42181b8f8632cab89d665',
        courseCode: '4C03',
        courseName: 'Chem Engineering 4C03',
        shortName: 'Chem Engineering 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d665',
      },
      {
        _id: '5ef42181b8f8632cab89d66d',
        courseCode: '4G03',
        courseName: 'Civil Engineering 4G03',
        shortName: 'Civil Engineering 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d66d',
      },
      {
        _id: '5ef42181b8f8632cab89d670',
        courseCode: '1M03',
        courseName: 'CLASSICS 1M03',
        shortName: 'CLASSICS 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d670',
      },
      {
        _id: '5ef42181b8f8632cab89d669',
        courseCode: '2PD3',
        courseName: 'Chemistry 2PD3',
        shortName: 'Chemistry 2PD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d669',
      },
      {
        _id: '5ef42181b8f8632cab89d66a',
        courseCode: '3A03',
        courseName: 'Civil Engineering 3A03',
        shortName: 'Civil Engineering 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d66a',
      },
      {
        _id: '5ef42181b8f8632cab89d66c',
        courseCode: '3R03',
        courseName: 'Civil Engineering 3R03',
        shortName: 'Civil Engineering 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d66c',
      },
      {
        _id: '5ef42181b8f8632cab89d672',
        courseCode: '2FA3',
        courseName: 'Commerce 2FA3',
        shortName: 'Commerce 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 13,
        id: '5ef42181b8f8632cab89d672',
      },
      {
        _id: '5ef42181b8f8632cab89d675',
        courseCode: '4BG3',
        courseName: 'Commerce 4BG3',
        shortName: 'Commerce 4BG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d675',
      },
      {
        _id: '5ef42181b8f8632cab89d671',
        courseCode: '2BC3',
        courseName: 'Commerce 2BC3',
        shortName: 'Commerce 2BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d671',
      },
      {
        _id: '5ef42181b8f8632cab89d66e',
        courseCode: '2E03',
        courseName: 'Civil Engineering 2E03',
        shortName: 'Civil Engineering 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d66e',
      },
      {
        _id: '5ef42181b8f8632cab89d67a',
        courseCode: '3MI3',
        courseName: 'Comp Science 3MI3',
        shortName: 'Comp Science 3MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d67a',
      },
      {
        _id: '5ef42181b8f8632cab89d66f',
        courseCode: '2O04',
        courseName: 'Civil Engineering 2O04',
        shortName: 'Civil Engineering 2O04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d66f',
      },
      {
        _id: '5ef42181b8f8632cab89d677',
        courseCode: '4SA3',
        courseName: 'Commerce 4SA3',
        shortName: 'Commerce 4SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d677',
      },
      {
        _id: '5ef42181b8f8632cab89d674',
        courseCode: '4BD3',
        courseName: 'Commerce 4BD3',
        shortName: 'Commerce 4BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d674',
      },
      {
        _id: '5ef42181b8f8632cab89d67f',
        courseCode: '2Q04',
        courseName: 'Dynamics 2Q04',
        shortName: 'Dynamics 2Q04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d67f',
      },
      {
        _id: '5ef42181b8f8632cab89d673',
        courseCode: '4BC3',
        courseName: 'Commerce 4BC3',
        shortName: 'Commerce 4BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d673',
      },
      {
        _id: '5ef42181b8f8632cab89d676',
        courseCode: '4FH3',
        courseName: 'Commerce 4FH3',
        shortName: 'Commerce 4FH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d676',
      },
      {
        _id: '5ef42181b8f8632cab89d67c',
        courseCode: '3RR3',
        courseName: 'Comparative Literature 3RR3',
        shortName: 'Comparative Literature 3RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d67c',
      },
      {
        _id: '5ef42181b8f8632cab89d678',
        courseCode: '2E03',
        courseName: 'Communication Studies 2E03',
        shortName: 'Communication Studies 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d678',
      },
      {
        _id: '5ef42181b8f8632cab89d681',
        courseCode: '2P03',
        courseName: 'Economics 2P03',
        shortName: 'Economics 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d681',
      },
      {
        _id: '5ef42181b8f8632cab89d67b',
        courseCode: '3IS3',
        courseName: 'Comp Science 3IS3',
        shortName: 'Comp Science 3IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d67b',
      },
      {
        _id: '5ef42181b8f8632cab89d684',
        courseCode: '3BA3',
        courseName: 'Electrical Engineering 3BA3',
        shortName: 'Electrical Engineering 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 21,
        id: '5ef42181b8f8632cab89d684',
      },
      {
        _id: '5ef42181b8f8632cab89d679',
        courseCode: '1BA3',
        courseName: 'Comp Science 1BA3',
        shortName: 'Comp Science 1BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d679',
      },
      {
        _id: '5ef42181b8f8632cab89d680',
        courseCode: '2B03',
        courseName: 'Economics 2B03',
        shortName: 'Economics 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 6,
        id: '5ef42181b8f8632cab89d680',
      },
      {
        _id: '5ef42181b8f8632cab89d686',
        courseCode: '3EJ4',
        courseName: 'Electrical Engineering 3EJ4',
        shortName: 'Electrical Engineering 3EJ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d686',
      },
      {
        _id: '5ef42181b8f8632cab89d67d',
        courseCode: '3MI3',
        courseName: 'Computer Science 3MI3',
        shortName: 'Computer Science 3MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d67d',
      },
      {
        _id: '5ef42181b8f8632cab89d689',
        courseCode: '3PI4',
        courseName: 'Electrical Engineering 3PI4',
        shortName: 'Electrical Engineering 3PI4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 10,
        id: '5ef42181b8f8632cab89d689',
      },
      {
        _id: '5ef42181b8f8632cab89d67e',
        courseCode: '3A03',
        courseName: 'Cultural-Studies-and-Critical-Theory 3A03',
        shortName: 'Cultural-Studies-and-Critical-Theory 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d67e',
      },
      {
        _id: '5ef42181b8f8632cab89d683',
        courseCode: '2FH3',
        courseName: 'Electrical Engineering 2FH3',
        shortName: 'Electrical Engineering 2FH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 44,
        id: '5ef42181b8f8632cab89d683',
      },
      {
        _id: '5ef42181b8f8632cab89d68e',
        courseCode: '4EM3',
        courseName: 'Electrical Engineering 4EM3',
        shortName: 'Electrical Engineering 4EM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d68e',
      },
      {
        _id: '5ef42181b8f8632cab89d685',
        courseCode: '3BB3',
        courseName: 'Electrical Engineering 3BB3',
        shortName: 'Electrical Engineering 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d685',
      },
      {
        _id: '5ef42181b8f8632cab89d68b',
        courseCode: '3TQ4',
        courseName: 'Electrical Engineering 3TQ4',
        shortName: 'Electrical Engineering 3TQ4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 19,
        id: '5ef42181b8f8632cab89d68b',
      },
      {
        _id: '5ef42181b8f8632cab89d693',
        courseCode: '2B03',
        courseName: 'Engineering 2B03',
        shortName: 'Engineering 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 12,
        id: '5ef42181b8f8632cab89d693',
      },
      {
        _id: '5ef42181b8f8632cab89d688',
        courseCode: '3K04',
        courseName: 'Electrical Engineering 3K04',
        shortName: 'Electrical Engineering 3K04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d688',
      },
      {
        _id: '5ef42181b8f8632cab89d682',
        courseCode: '4B03',
        courseName: 'Economics 4B03',
        shortName: 'Economics 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d682',
      },
      {
        _id: '5ef42181b8f8632cab89d68a',
        courseCode: '3TP4',
        courseName: 'Electrical Engineering 3TP4',
        shortName: 'Electrical Engineering 3TP4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d68a',
      },
      {
        _id: '5ef42181b8f8632cab89d687',
        courseCode: '3FK4',
        courseName: 'Electrical Engineering 3FK4',
        shortName: 'Electrical Engineering 3FK4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 9,
        id: '5ef42181b8f8632cab89d687',
      },
      {
        _id: '5ef42181b8f8632cab89d68f',
        courseCode: '2CI5',
        courseName: 'Electrical Engineering 2CI5',
        shortName: 'Electrical Engineering 2CI5',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 9,
        id: '5ef42181b8f8632cab89d68f',
      },
      {
        _id: '5ef42181b8f8632cab89d68d',
        courseCode: '4BD4',
        courseName: 'Electrical Engineering 4BD4',
        shortName: 'Electrical Engineering 4BD4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d68d',
      },
      {
        _id: '5ef42181b8f8632cab89d698',
        courseCode: '1PR3',
        courseName: 'ENGTECH 1PR3',
        shortName: 'ENGTECH 1PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d698',
      },
      {
        _id: '5ef42181b8f8632cab89d694',
        courseCode: '2MM3',
        courseName: 'Engineering 2MM3',
        shortName: 'Engineering 2MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 16,
        id: '5ef42181b8f8632cab89d694',
      },
      {
        _id: '5ef42181b8f8632cab89d68c',
        courseCode: '3TR4',
        courseName: 'Electrical Engineering 3TR4',
        shortName: 'Electrical Engineering 3TR4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d68c',
      },
      {
        _id: '5ef42181b8f8632cab89d695',
        courseCode: '3N03',
        courseName: 'Engineering 3N03',
        shortName: 'Engineering 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 42,
        id: '5ef42181b8f8632cab89d695',
      },
      {
        _id: '5ef42181b8f8632cab89d69d',
        courseCode: '3E03',
        courseName: 'Earth Envir Sci 3E03',
        shortName: 'Earth Envir Sci 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d69d',
      },
      {
        _id: '5ef42181b8f8632cab89d6a2',
        courseCode: '1G03',
        courseName: 'Earth Sciences 1G03',
        shortName: 'Earth Sciences 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6a2',
      },
      {
        _id: '5ef42181b8f8632cab89d69a',
        courseCode: '3D03',
        courseName: 'Engineering PHYS 3D03',
        shortName: 'Engineering PHYS 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d69a',
      },
      {
        _id: '5ef42181b8f8632cab89d699',
        courseCode: '2S03',
        courseName: 'Engineering PHYS 2S03',
        shortName: 'Engineering PHYS 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d699',
      },
      {
        _id: '5ef42181b8f8632cab89d697',
        courseCode: '2H03',
        courseName: 'Engineering PHYS 2H03',
        shortName: 'Engineering PHYS 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 8,
        id: '5ef42181b8f8632cab89d697',
      },
      {
        _id: '5ef42181b8f8632cab89d69e',
        courseCode: '3K03',
        courseName: 'Earth Envir Sci 3K03',
        shortName: 'Earth Envir Sci 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d69e',
      },
      {
        _id: '5ef42181b8f8632cab89d69c',
        courseCode: '3X03',
        courseName: 'Engineering PHYS 3X03',
        shortName: 'Engineering PHYS 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d69c',
      },
      {
        _id: '5ef42181b8f8632cab89d6a7',
        courseCode: '4G03',
        courseName: 'Economics 4G03',
        shortName: 'Economics 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6a7',
      },
      {
        _id: '5ef42181b8f8632cab89d69f',
        courseCode: '3V03',
        courseName: 'Earth Envir Sci 3V03',
        shortName: 'Earth Envir Sci 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d69f',
      },
      {
        _id: '5ef42181b8f8632cab89d696',
        courseCode: '2E04',
        courseName: 'Engineering PHYS 2E04',
        shortName: 'Engineering PHYS 2E04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d696',
      },
      {
        _id: '5ef42181b8f8632cab89d6a1',
        courseCode: '4Z03',
        courseName: 'Earth Envir Sci 4Z03',
        shortName: 'Earth Envir Sci 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6a1',
      },
      {
        _id: '5ef42181b8f8632cab89d6ac',
        courseCode: '4J03',
        courseName: 'Engineering 4J03',
        shortName: 'Engineering 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ac',
      },
      {
        _id: '5ef42181b8f8632cab89d6a3',
        courseCode: '2K03',
        courseName: 'Earth Sciences 2K03',
        shortName: 'Earth Sciences 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6a3',
      },
      {
        _id: '5ef42181b8f8632cab89d69b',
        courseCode: '3F04',
        courseName: 'Engineering PHYS 3F04',
        shortName: 'Engineering PHYS 3F04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d69b',
      },
      {
        _id: '5ef42181b8f8632cab89d6a6',
        courseCode: '2K03',
        courseName: 'Economics 2K03',
        shortName: 'Economics 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6a6',
      },
      {
        _id: '5ef42181b8f8632cab89d6a9',
        courseCode: '2EI5',
        courseName: 'Electrical Engineering 2EI5',
        shortName: 'Electrical Engineering 2EI5',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6a9',
      },
      {
        _id: '5ef42181b8f8632cab89d6b1',
        courseCode: '2C03E',
        courseName: 'English 2C03E',
        shortName: 'English 2C03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b1',
      },
      {
        _id: '5ef42181b8f8632cab89d6a0',
        courseCode: '4T03',
        courseName: 'Earth Envir Sci 4T03',
        shortName: 'Earth Envir Sci 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d6a0',
      },
      {
        _id: '5ef42181b8f8632cab89d6a8',
        courseCode: '4G3',
        courseName: 'Economics 4G3',
        shortName: 'Economics 4G3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6a8',
      },
      {
        _id: '5ef42181b8f8632cab89d6ab',
        courseCode: '4H03',
        courseName: 'Engineering 4H03',
        shortName: 'Engineering 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ab',
      },
      {
        _id: '5ef42181b8f8632cab89d6ae',
        courseCode: '4Z03',
        courseName: 'Engineering PHYS 4Z03',
        shortName: 'Engineering PHYS 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ae',
      },
      {
        _id: '5ef42181b8f8632cab89d6b6',
        courseCode: '3H03',
        courseName: 'English 3H03',
        shortName: 'English 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b6',
      },
      {
        _id: '5ef42181b8f8632cab89d6ad',
        courseCode: '4NE3',
        courseName: 'Engineering PHYS 4NE3',
        shortName: 'Engineering PHYS 4NE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ad',
      },
      {
        _id: '5ef42181b8f8632cab89d6b0',
        courseCode: '1C06',
        courseName: 'English 1C06',
        shortName: 'English 1C06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b0',
      },
      {
        _id: '5ef42181b8f8632cab89d6aa',
        courseCode: '2A04',
        courseName: 'Electrodynamics 2A04',
        shortName: 'Electrodynamics 2A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6aa',
      },
      {
        _id: '5ef42181b8f8632cab89d6b3',
        courseCode: '2G06E',
        courseName: 'English 2G06E',
        shortName: 'English 2G06E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b3',
      },
      {
        _id: '5ef42181b8f8632cab89d6b2',
        courseCode: '2G06',
        courseName: 'English 2G06',
        shortName: 'English 2G06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b2',
      },
      {
        _id: '5ef42181b8f8632cab89d6b5',
        courseCode: '3A03',
        courseName: 'English 3A03',
        shortName: 'English 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b5',
      },
      {
        _id: '5ef42181b8f8632cab89d6c0',
        courseCode: '2M06',
        courseName: 'French 2M06',
        shortName: 'French 2M06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d6c0',
      },
      {
        _id: '5ef42181b8f8632cab89d6af',
        courseCode: '1AA3',
        courseName: 'English 1AA3',
        shortName: 'English 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6af',
      },
      {
        _id: '5ef42181b8f8632cab89d6ba',
        courseCode: '3E03',
        courseName: 'Environmental Science 3E03',
        shortName: 'Environmental Science 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d6ba',
      },
      {
        _id: '5ef42181b8f8632cab89d6b8',
        courseCode: '3JJ3',
        courseName: 'English 3JJ3',
        shortName: 'English 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b8',
      },
      {
        _id: '5ef42181b8f8632cab89d6b7',
        courseCode: '3J03',
        courseName: 'English 3J03',
        shortName: 'English 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b7',
      },
      {
        _id: '5ef42181b8f8632cab89d6c5',
        courseCode: '1HA3',
        courseName: 'Geography 1HA3',
        shortName: 'Geography 1HA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6c5',
      },
      {
        _id: '5ef42181b8f8632cab89d6bf',
        courseCode: '2F03',
        courseName: 'French 2F03',
        shortName: 'French 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6bf',
      },
      {
        _id: '5ef42181b8f8632cab89d6bd',
        courseCode: '1Z06',
        courseName: 'French 1Z06',
        shortName: 'French 1Z06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6bd',
      },
      {
        _id: '5ef42181b8f8632cab89d6b4',
        courseCode: '2H06E',
        courseName: 'English 2H06E',
        shortName: 'English 2H06E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6b4',
      },
      {
        _id: '5ef42181b8f8632cab89d6bc',
        courseCode: '1A06',
        courseName: 'French 1A06',
        shortName: 'French 1A06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d6bc',
      },
      {
        _id: '5ef42181b8f8632cab89d6ca',
        courseCode: '3V03',
        courseName: 'Geography 3V03',
        shortName: 'Geography 3V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ca',
      },
      {
        _id: '5ef42181b8f8632cab89d6c4',
        courseCode: '4MM3',
        courseName: 'French 4MM3',
        shortName: 'French 4MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d6c4',
      },
      {
        _id: '5ef42181b8f8632cab89d6b9',
        courseCode: '1G03',
        courseName: 'Environmental Science 1G03',
        shortName: 'Environmental Science 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6b9',
      },
      {
        _id: '5ef42181b8f8632cab89d6c2',
        courseCode: '3KK3',
        courseName: 'French 3KK3',
        shortName: 'French 3KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6c2',
      },
      {
        _id: '5ef42181b8f8632cab89d6c1',
        courseCode: '3AA3',
        courseName: 'French 3AA3',
        shortName: 'French 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6c1',
      },
      {
        _id: '5ef42181b8f8632cab89d6cf',
        courseCode: '3UG3',
        courseName: 'Geography 3UG3',
        shortName: 'Geography 3UG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6cf',
      },
      {
        _id: '5ef42181b8f8632cab89d6c9',
        courseCode: '3K03',
        courseName: 'Geography 3K03',
        shortName: 'Geography 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6c9',
      },
      {
        _id: '5ef42181b8f8632cab89d6c6',
        courseCode: '1HB3',
        courseName: 'Geography 1HB3',
        shortName: 'Geography 1HB3',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d6c6',
      },
      {
        _id: '5ef42181b8f8632cab89d6d4',
        courseCode: '2A03E',
        courseName: 'History 2A03E',
        shortName: 'History 2A03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d4',
      },
      {
        _id: '5ef42181b8f8632cab89d6be',
        courseCode: '2B03',
        courseName: 'French 2B03',
        shortName: 'French 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6be',
      },
      {
        _id: '5ef42181b8f8632cab89d6c7',
        courseCode: '2E03',
        courseName: 'Geography 2E03',
        shortName: 'Geography 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6c7',
      },
      {
        _id: '5ef42181b8f8632cab89d6ce',
        courseCode: '4T03',
        courseName: 'Geography 4T03',
        shortName: 'Geography 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ce',
      },
      {
        _id: '5ef42181b8f8632cab89d6cb',
        courseCode: '3Z03',
        courseName: 'Geography 3Z03',
        shortName: 'Geography 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6cb',
      },
      {
        _id: '5ef42181b8f8632cab89d6c3',
        courseCode: '4I03',
        courseName: 'French 4I03',
        shortName: 'French 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6c3',
      },
      {
        _id: '5ef42181b8f8632cab89d6d9',
        courseCode: '3G03',
        courseName: 'History 3G03',
        shortName: 'History 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d9',
      },
      {
        _id: '5ef42181b8f8632cab89d6cc',
        courseCode: '4G03',
        courseName: 'Geography 4G03',
        shortName: 'Geography 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6cc',
      },
      {
        _id: '5ef42181b8f8632cab89d6d1',
        courseCode: '4UH3',
        courseName: 'Geography 4UH3',
        shortName: 'Geography 4UH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d6d1',
      },
      {
        _id: '5ef42181b8f8632cab89d6de',
        courseCode: '2G03',
        courseName: 'Kinesiology 2G03',
        shortName: 'Kinesiology 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6de',
      },
      {
        _id: '5ef42181b8f8632cab89d6d0',
        courseCode: '3UR3',
        courseName: 'Geography 3UR3',
        shortName: 'Geography 3UR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d0',
      },
      {
        _id: '5ef42181b8f8632cab89d6d3',
        courseCode: '1BB3',
        courseName: 'Health and Aging 1BB3',
        shortName: 'Health and Aging 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d3',
      },
      {
        _id: '5ef42181b8f8632cab89d6c8',
        courseCode: '2K03',
        courseName: 'Geography 2K03',
        shortName: 'Geography 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6c8',
      },
      {
        _id: '5ef42181b8f8632cab89d6cd',
        courseCode: '4HZ3',
        courseName: 'Geography 4HZ3',
        shortName: 'Geography 4HZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6cd',
      },
      {
        _id: '5ef42181b8f8632cab89d6d6',
        courseCode: '2MM3E',
        courseName: 'History 2MM3E',
        shortName: 'History 2MM3E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d6',
      },
      {
        _id: '5ef42181b8f8632cab89d6d8',
        courseCode: '2UV3',
        courseName: 'History 2UV3',
        shortName: 'History 2UV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d8',
      },
      {
        _id: '5ef42181b8f8632cab89d6e3',
        courseCode: '3F03',
        courseName: 'Labour Studies 3F03',
        shortName: 'Labour Studies 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e3',
      },
      {
        _id: '5ef42181b8f8632cab89d6d5',
        courseCode: '2AA3',
        courseName: 'History 2AA3',
        shortName: 'History 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6d5',
      },
      {
        _id: '5ef42181b8f8632cab89d6dd',
        courseCode: '3ZZ3',
        courseName: 'Italian 3ZZ3',
        shortName: 'Italian 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6dd',
      },
      {
        _id: '5ef42181b8f8632cab89d6e8',
        courseCode: '2GE3',
        courseName: 'Linguistics 2GE3',
        shortName: 'Linguistics 2GE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e8',
      },
      {
        _id: '5ef42181b8f8632cab89d6db',
        courseCode: '3W03',
        courseName: 'History 3W03',
        shortName: 'History 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6db',
      },
      {
        _id: '5ef42181b8f8632cab89d6d2',
        courseCode: '2A03',
        courseName: 'History 2A03',
        shortName: 'History 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d6d2',
      },
      {
        _id: '5ef42181b8f8632cab89d6da',
        courseCode: '3SA3',
        courseName: 'History 3SA3',
        shortName: 'History 3SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6da',
      },
      {
        _id: '5ef42181b8f8632cab89d6df',
        courseCode: '4B03E',
        courseName: 'Kinesiology 4B03E',
        shortName: 'Kinesiology 4B03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6df',
      },
      {
        _id: '5ef42181b8f8632cab89d6ed',
        courseCode: '1M03',
        courseName: 'MATLS 1M03',
        shortName: 'MATLS 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 193,
        id: '5ef42181b8f8632cab89d6ed',
      },
      {
        _id: '5ef42181b8f8632cab89d6d7',
        courseCode: '2TT3',
        courseName: 'History 2TT3',
        shortName: 'History 2TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6d7',
      },
      {
        _id: '5ef42181b8f8632cab89d6e2',
        courseCode: '3E03',
        courseName: 'Labour Studies 3E03',
        shortName: 'Labour Studies 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e2',
      },
      {
        _id: '5ef42181b8f8632cab89d6e0',
        courseCode: '4SS3E',
        courseName: 'Kinesiology 4SS3E',
        shortName: 'Kinesiology 4SS3E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e0',
      },
      {
        _id: '5ef42181b8f8632cab89d6dc',
        courseCode: '3WW3',
        courseName: 'History 3WW3',
        shortName: 'History 3WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6dc',
      },
      {
        _id: '5ef42181b8f8632cab89d6f2',
        courseCode: '3J03',
        courseName: 'MATLS 3J03',
        shortName: 'MATLS 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 13,
        id: '5ef42181b8f8632cab89d6f2',
      },
      {
        _id: '5ef42181b8f8632cab89d6e4',
        courseCode: '4C03',
        courseName: 'Labour Studies 4C03',
        shortName: 'Labour Studies 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e4',
      },
      {
        _id: '5ef42181b8f8632cab89d6e5',
        courseCode: '4C03E',
        courseName: 'Labour Studies 4C03E',
        shortName: 'Labour Studies 4C03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e5',
      },
      {
        _id: '5ef42181b8f8632cab89d6e7',
        courseCode: '2E03',
        courseName: 'Linguistics 2E03',
        shortName: 'Linguistics 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6e7',
      },
      {
        _id: '5ef42181b8f8632cab89d6e1',
        courseCode: '1C03E',
        courseName: 'Labour Studies 1C03E',
        shortName: 'Labour Studies 1C03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e1',
      },
      {
        _id: '5ef42181b8f8632cab89d6f7',
        courseCode: '4F03',
        courseName: 'MATLS 4F03',
        shortName: 'MATLS 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6f7',
      },
      {
        _id: '5ef42181b8f8632cab89d6ea',
        courseCode: '3X03',
        courseName: 'Linguistics 3X03',
        shortName: 'Linguistics 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ea',
      },
      {
        _id: '5ef42181b8f8632cab89d6e9',
        courseCode: '3M03',
        courseName: 'Linguistics 3M03',
        shortName: 'Linguistics 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e9',
      },
      {
        _id: '5ef42181b8f8632cab89d6ec',
        courseCode: '3J04',
        courseName: 'MATH 3J04',
        shortName: 'MATH 3J04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6ec',
      },
      {
        _id: '5ef42181b8f8632cab89d6ee',
        courseCode: '2D03',
        courseName: 'MATLS 2D03',
        shortName: 'MATLS 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ee',
      },
      {
        _id: '5ef42181b8f8632cab89d6ef',
        courseCode: '3A03',
        courseName: 'MATLS 3A03',
        shortName: 'MATLS 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 93,
        id: '5ef42181b8f8632cab89d6ef',
      },
      {
        _id: '5ef42181b8f8632cab89d6fc',
        courseCode: '3TA4',
        courseName: 'MECHATRON 3TA4',
        shortName: 'MECHATRON 3TA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 15,
        id: '5ef42181b8f8632cab89d6fc',
      },
      {
        _id: '5ef42181b8f8632cab89d6e6',
        courseCode: '1A03',
        courseName: 'Labour Studies 1A03',
        shortName: 'Labour Studies 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6e6',
      },
      {
        _id: '5ef42181b8f8632cab89d6f1',
        courseCode: '3F03',
        courseName: 'MATLS 3F03',
        shortName: 'MATLS 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6f1',
      },
      {
        _id: '5ef42181b8f8632cab89d6f4',
        courseCode: '3Q03',
        courseName: 'MATLS 3Q03',
        shortName: 'MATLS 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6f4',
      },
      {
        _id: '5ef42181b8f8632cab89d701',
        courseCode: '1ZB3',
        courseName: 'MATH 1ZB3 - Engineering Mathematics II-A',
        shortName: 'Math 1ZB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 161,
        id: '5ef42181b8f8632cab89d701',
      },
      {
        _id: '5ef42181b8f8632cab89d6eb',
        courseCode: '1LS3',
        courseName: 'MATH 1LS3 - Calculus for the Life Sciences I',
        shortName: 'MATH 1LS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 80,
        id: '5ef42181b8f8632cab89d6eb',
      },
      {
        _id: '5ef42181b8f8632cab89d6f3',
        courseCode: '3M03',
        courseName: 'MATLS 3M03',
        shortName: 'MATLS 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 24,
        id: '5ef42181b8f8632cab89d6f3',
      },
      {
        _id: '5ef42181b8f8632cab89d6f8',
        courseCode: '4I03',
        courseName: 'MATLS 4I03',
        shortName: 'MATLS 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6f8',
      },
      {
        _id: '5ef42181b8f8632cab89d6f0',
        courseCode: '3C03',
        courseName: 'MATLS 3C03',
        shortName: 'MATLS 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d6f0',
      },
      {
        _id: '5ef42181b8f8632cab89d6f9',
        courseCode: '4R03',
        courseName: 'MATLS 4R03',
        shortName: 'MATLS 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6f9',
      },
      {
        _id: '5ef42181b8f8632cab89d6f6',
        courseCode: '4C03',
        courseName: 'MATLS 4C03',
        shortName: 'MATLS 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6f6',
      },
      {
        _id: '5ef42181b8f8632cab89d706',
        courseCode: '3CY3',
        courseName: 'Math 3CY3',
        shortName: 'Math 3CY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d706',
      },
      {
        _id: '5ef42181b8f8632cab89d6fb',
        courseCode: '4H03',
        courseName: 'MECH Engineering 4H03',
        shortName: 'MECH Engineering 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d6fb',
      },
      {
        _id: '5ef42181b8f8632cab89d6fe',
        courseCode: '4I03',
        courseName: 'Materials 4I03',
        shortName: 'Materials 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6fe',
      },
      {
        _id: '5ef42181b8f8632cab89d6f5',
        courseCode: '3T04',
        courseName: 'MATLS 3T04',
        shortName: 'MATLS 3T04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 73,
        id: '5ef42181b8f8632cab89d6f5',
      },
      {
        _id: '5ef42181b8f8632cab89d70b',
        courseCode: '3U03',
        courseName: 'Math 3U03',
        shortName: 'Math 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d70b',
      },
      {
        _id: '5ef42181b8f8632cab89d6fd',
        courseCode: '3TB4',
        courseName: 'MECHATRON 3TB4',
        shortName: 'MECHATRON 3TB4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d6fd',
      },
      {
        _id: '5ef42181b8f8632cab89d6fa',
        courseCode: '2B03',
        courseName: 'MECH Engineering 2B03',
        shortName: 'MECH Engineering 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d6fa',
      },
      {
        _id: '5ef42181b8f8632cab89d700',
        courseCode: '1ZA3',
        courseName: 'MATH 1ZA3 - Engineering Mathematics I',
        shortName: 'Math 1ZA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 138,
        id: '5ef42181b8f8632cab89d700',
      },
      {
        _id: '5ef42181b8f8632cab89d710',
        courseCode: '4B03',
        courseName: 'Medical Physics 4B03',
        shortName: 'Medical Physics 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d710',
      },
      {
        _id: '5ef42181b8f8632cab89d703',
        courseCode: '2T03',
        courseName: 'Math 2T03',
        shortName: 'Math 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d703',
      },
      {
        _id: '5ef42181b8f8632cab89d702',
        courseCode: '1ZC3',
        courseName: 'MATH 1ZC3 - Engineering Mathematics II-B',
        shortName: 'Math 1ZC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 156,
        id: '5ef42181b8f8632cab89d702',
      },
      {
        _id: '5ef42181b8f8632cab89d705',
        courseCode: '2ZZ3',
        courseName: 'Math 2ZZ3',
        shortName: 'Math 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 36,
        id: '5ef42181b8f8632cab89d705',
      },
      {
        _id: '5ef42181b8f8632cab89d6ff',
        courseCode: '1B03',
        courseName: 'Math 1B03',
        shortName: 'Math 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d6ff',
      },
      {
        _id: '5ef42181b8f8632cab89d715',
        courseCode: '1A03',
        courseName: 'MultiMedia 1A03',
        shortName: 'MultiMedia 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d715',
      },
      {
        _id: '5ef42181b8f8632cab89d708',
        courseCode: '3I03',
        courseName: 'Math 3I03',
        shortName: 'Math 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 8,
        id: '5ef42181b8f8632cab89d708',
      },
      {
        _id: '5ef42181b8f8632cab89d707',
        courseCode: '3DC3',
        courseName: 'Math 3DC3',
        shortName: 'Math 3DC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d707',
      },
      {
        _id: '5ef42181b8f8632cab89d70c',
        courseCode: '4K03',
        courseName: 'Math 4K03',
        shortName: 'Math 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d70c',
      },
      {
        _id: '5ef42181b8f8632cab89d70d',
        courseCode: '2W04',
        courseName: 'Mech Engineering 2W04',
        shortName: 'Mech Engineering 2W04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d70d',
      },
      {
        _id: '5ef42181b8f8632cab89d704',
        courseCode: '2Z03',
        courseName: 'Math 2Z03',
        shortName: 'Math 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 72,
        id: '5ef42181b8f8632cab89d704',
      },
      {
        _id: '5ef42181b8f8632cab89d71a',
        courseCode: '2Z03',
        courseName: 'Music 2Z03',
        shortName: 'Music 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d71a',
      },
      {
        _id: '5ef42181b8f8632cab89d70a',
        courseCode: '3QC03',
        courseName: 'Math 3QC03',
        shortName: 'Math 3QC03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d70a',
      },
      {
        _id: '5ef42181b8f8632cab89d71f',
        courseCode: '1X03',
        courseName: 'PSYCH 1X03',
        shortName: 'PSYCH 1X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d71f',
      },
      {
        _id: '5ef42181b8f8632cab89d711',
        courseCode: '4U03',
        courseName: 'Medical Physics 4U03',
        shortName: 'Medical Physics 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d711',
      },
      {
        _id: '5ef42181b8f8632cab89d709',
        courseCode: '3N03',
        courseName: 'Math 3N03',
        shortName: 'Math 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d709',
      },
      {
        _id: '5ef42181b8f8632cab89d70f',
        courseCode: '4S03',
        courseName: 'Mech Engineering 4S03',
        shortName: 'Mech Engineering 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d70f',
      },
      {
        _id: '5ef42181b8f8632cab89d724',
        courseCode: '3AA3E',
        courseName: 'Peace Studies 3AA3E',
        shortName: 'Peace Studies 3AA3E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d724',
      },
      {
        _id: '5ef42181b8f8632cab89d717',
        courseCode: '2G03',
        courseName: 'MultiMedia 2G03',
        shortName: 'MultiMedia 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d717',
      },
      {
        _id: '5ef42181b8f8632cab89d70e',
        courseCode: '3R03',
        courseName: 'Mech Engineering 3R03',
        shortName: 'Mech Engineering 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d70e',
      },
      {
        _id: '5ef42181b8f8632cab89d714',
        courseCode: '3Y03',
        courseName: 'Molecular Biology 3Y03',
        shortName: 'Molecular Biology 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d714',
      },
      {
        _id: '5ef42181b8f8632cab89d71b',
        courseCode: '3CT3',
        courseName: 'Music 3CT3',
        shortName: 'Music 3CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d71b',
      },
      {
        _id: '5ef42181b8f8632cab89d719',
        courseCode: '1CC3',
        courseName: 'Music 1CC3',
        shortName: 'Music 1CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d719',
      },
      {
        _id: '5ef42181b8f8632cab89d71c',
        courseCode: '3H03',
        courseName: 'Music 3H03',
        shortName: 'Music 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d71c',
      },
      {
        _id: '5ef42181b8f8632cab89d729',
        courseCode: '2X03',
        courseName: 'Philosophy 2X03',
        shortName: 'Philosophy 2X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d729',
      },
      {
        _id: '5ef42181b8f8632cab89d713',
        courseCode: '3Y03',
        courseName: 'Medical Radiation Sciences 3Y03',
        shortName: 'Medical Radiation Sciences 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d713',
      },
      {
        _id: '5ef42181b8f8632cab89d71e',
        courseCode: '2FF3',
        courseName: 'Origins 2FF3',
        shortName: 'Origins 2FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d71e',
      },
      {
        _id: '5ef42181b8f8632cab89d721',
        courseCode: '2F03E',
        courseName: 'Peace Studies 2F03E',
        shortName: 'Peace Studies 2F03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d721',
      },
      {
        _id: '5ef42181b8f8632cab89d72e',
        courseCode: '3Q03',
        courseName: 'Philosophy 3Q03',
        shortName: 'Philosophy 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d72e',
      },
      {
        _id: '5ef42181b8f8632cab89d720',
        courseCode: '2AA3',
        courseName: 'Peace Studies 2AA3',
        shortName: 'Peace Studies 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d720',
      },
      {
        _id: '5ef42181b8f8632cab89d718',
        courseCode: '1A03',
        courseName: 'Music 1A03',
        shortName: 'Music 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d718',
      },
      {
        _id: '5ef42181b8f8632cab89d71d',
        courseCode: '3U03',
        courseName: 'Music 3U03',
        shortName: 'Music 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d71d',
      },
      {
        _id: '5ef42181b8f8632cab89d725',
        courseCode: '1C03',
        courseName: 'Philosophy 1C03',
        shortName: 'Philosophy 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d725',
      },
      {
        _id: '5ef42181b8f8632cab89d726',
        courseCode: '1E03',
        courseName: 'Philosophy 1E03',
        shortName: 'Philosophy 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d726',
      },
      {
        _id: '5ef42181b8f8632cab89d723',
        courseCode: '3A03',
        courseName: 'Peace Studies 3A03',
        shortName: 'Peace Studies 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d723',
      },
      {
        _id: '5ef42181b8f8632cab89d733',
        courseCode: '1D03',
        courseName: 'Physics 1D03 - Introductory Mechanics',
        shortName: 'Physics 1D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 111,
        id: '5ef42181b8f8632cab89d733',
      },
      {
        _id: '5ef42181b8f8632cab89d72b',
        courseCode: '3E03',
        courseName: 'Philosophy 3E03',
        shortName: 'Philosophy 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d72b',
      },
      {
        _id: '5ef42181b8f8632cab89d722',
        courseCode: '2UV3',
        courseName: 'Peace Studies 2UV3',
        shortName: 'Peace Studies 2UV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d722',
      },
      {
        _id: '5ef42181b8f8632cab89d72a',
        courseCode: '3A06',
        courseName: 'Philosophy 3A06',
        shortName: 'Philosophy 3A06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d72a',
      },
      {
        _id: '5ef42181b8f8632cab89d738',
        courseCode: '3BA3',
        courseName: 'Physics 3BA3',
        shortName: 'Physics 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d738',
      },
      {
        _id: '5ef42181b8f8632cab89d728',
        courseCode: '2C06',
        courseName: 'Philosophy 2C06',
        shortName: 'Philosophy 2C06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d728',
      },
      {
        _id: '5ef42181b8f8632cab89d73d',
        courseCode: '2J03',
        courseName: 'Political Science 2J03',
        shortName: 'Political Science 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d73d',
      },
      {
        _id: '5ef42181b8f8632cab89d72d',
        courseCode: '3M03',
        courseName: 'Philosophy 3M03',
        shortName: 'Philosophy 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d72d',
      },
      {
        _id: '5ef42181b8f8632cab89d730',
        courseCode: '3o03',
        courseName: 'Philosophy 3o03',
        shortName: 'Philosophy 3o03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d730',
      },
      {
        _id: '5ef42181b8f8632cab89d727',
        courseCode: '2B03',
        courseName: 'Philosophy 2B03',
        shortName: 'Philosophy 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d727',
      },
      {
        _id: '5ef42181b8f8632cab89d72f',
        courseCode: '3VV3',
        courseName: 'Philosophy 3VV3',
        shortName: 'Philosophy 3VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d72f',
      },
      {
        _id: '5ef42181b8f8632cab89d732',
        courseCode: '1BB3',
        courseName: 'Physics 1BB3',
        shortName: 'Physics 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d732',
      },
      {
        _id: '5ef42181b8f8632cab89d72c',
        courseCode: '3I03',
        courseName: 'Philosophy 3I03',
        shortName: 'Philosophy 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d72c',
      },
      {
        _id: '5ef42181b8f8632cab89d735',
        courseCode: '2B04',
        courseName: 'Physics 2B04',
        shortName: 'Physics 2B04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d735',
      },
      {
        _id: '5ef42181b8f8632cab89d734',
        courseCode: '1E03',
        courseName: 'Physics 1E03 - Waves, Electricity and Magnetic Fields',
        shortName: 'Physics 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 172,
        id: '5ef42181b8f8632cab89d734',
      },
      {
        _id: '5ef42181b8f8632cab89d742',
        courseCode: '4O03',
        courseName: 'Political Science 4O03',
        shortName: 'Political Science 4O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d742',
      },
      {
        _id: '5ef42181b8f8632cab89d747',
        courseCode: '2Q03',
        courseName: 'Religious Studies 2Q03',
        shortName: 'Religious Studies 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d747',
      },
      {
        _id: '5ef42181b8f8632cab89d73a',
        courseCode: '3K03',
        courseName: 'Physics 3K03',
        shortName: 'Physics 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d73a',
      },
      {
        _id: '5ef42181b8f8632cab89d737',
        courseCode: '2C03',
        courseName: 'Physics 2C03',
        shortName: 'Physics 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d737',
      },
      {
        _id: '5ef42181b8f8632cab89d731',
        courseCode: '1B03',
        courseName: 'Physics 1B03',
        shortName: 'Physics 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d731',
      },
      {
        _id: '5ef42181b8f8632cab89d739',
        courseCode: '3C03',
        courseName: 'Physics 3C03',
        shortName: 'Physics 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d739',
      },
      {
        _id: '5ef42181b8f8632cab89d736',
        courseCode: '2B06',
        courseName: 'Physics 2B06',
        shortName: 'Physics 2B06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d736',
      },
      {
        _id: '5ef42181b8f8632cab89d73f',
        courseCode: '3BB3',
        courseName: 'Political Science 3BB3',
        shortName: 'Political Science 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d73f',
      },
      {
        _id: '5ef42181b8f8632cab89d73e',
        courseCode: '2XX3E',
        courseName: 'Political Science 2XX3E',
        shortName: 'Political Science 2XX3E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d73e',
      },
      {
        _id: '5ef42181b8f8632cab89d73c',
        courseCode: '4F03',
        courseName: 'Physics 4F03',
        shortName: 'Physics 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d73c',
      },
      {
        _id: '5ef42181b8f8632cab89d74c',
        courseCode: '4F03',
        courseName: 'Software Engineering 4F03',
        shortName: 'Software Engineering 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d74c',
      },
      {
        _id: '5ef42181b8f8632cab89d73b',
        courseCode: '4B03',
        courseName: 'Physics 4B03',
        shortName: 'Physics 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d73b',
      },
      {
        _id: '5ef42181b8f8632cab89d741',
        courseCode: '4NN3',
        courseName: 'Political Science 4NN3',
        shortName: 'Political Science 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d741',
      },
      {
        _id: '5ef42181b8f8632cab89d743',
        courseCode: '4O06',
        courseName: 'Political Science 4O06',
        shortName: 'Political Science 4O06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d743',
      },
      {
        _id: '5ef42181b8f8632cab89d744',
        courseCode: '4QQ3',
        courseName: 'Political Science 4QQ3',
        shortName: 'Political Science 4QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d744',
      },
      {
        _id: '5ef42181b8f8632cab89d751',
        courseCode: '2FA3',
        courseName: 'Software Engineering 2FA3',
        shortName: 'Software Engineering 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 26,
        id: '5ef42181b8f8632cab89d751',
      },
      {
        _id: '5ef42181b8f8632cab89d748',
        courseCode: '3K03',
        courseName: 'Religious Studies 3K03',
        shortName: 'Religious Studies 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d748',
      },
      {
        _id: '5ef42181b8f8632cab89d749',
        courseCode: '3R03E',
        courseName: 'Religious Studies 3R03E',
        shortName: 'Religious Studies 3R03E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d749',
      },
      {
        _id: '5ef42181b8f8632cab89d740',
        courseCode: '3Y03',
        courseName: 'Political Science 3Y03',
        shortName: 'Political Science 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d740',
      },
      {
        _id: '5ef42181b8f8632cab89d756',
        courseCode: '3DX4',
        courseName: 'Software Engineering 3DX4',
        shortName: 'Software Engineering 3DX4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 7,
        id: '5ef42181b8f8632cab89d756',
      },
      {
        _id: '5ef42181b8f8632cab89d746',
        courseCode: '2HH3',
        courseName: 'Religious Studies 2HH3',
        shortName: 'Religious Studies 2HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d746',
      },
      {
        _id: '5ef42181b8f8632cab89d74b',
        courseCode: '1ZZ3',
        courseName: 'Russian 1ZZ3',
        shortName: 'Russian 1ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d74b',
      },
      {
        _id: '5ef42181b8f8632cab89d74e',
        courseCode: '4O03',
        courseName: 'Software Engineering 4O03',
        shortName: 'Software Engineering 4O03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d74e',
      },
      {
        _id: '5ef42181b8f8632cab89d75b',
        courseCode: '3RA3',
        courseName: 'Software Engineering 3RA3',
        shortName: 'Software Engineering 3RA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 9,
        id: '5ef42181b8f8632cab89d75b',
      },
      {
        _id: '5ef42181b8f8632cab89d74d',
        courseCode: '4J03',
        courseName: 'Software Engineering 4J03',
        shortName: 'Software Engineering 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d74d',
      },
      {
        _id: '5ef42181b8f8632cab89d745',
        courseCode: '2EE3',
        courseName: 'Religious Studies 2EE3',
        shortName: 'Religious Studies 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d745',
      },
      {
        _id: '5ef42181b8f8632cab89d74a',
        courseCode: '1Z03',
        courseName: 'Russian 1Z03',
        shortName: 'Russian 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d74a',
      },
      {
        _id: '5ef42181b8f8632cab89d753',
        courseCode: '2S03',
        courseName: 'Software Engineering 2S03',
        shortName: 'Software Engineering 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d753',
      },
      {
        _id: '5ef42181b8f8632cab89d750',
        courseCode: '2C03',
        courseName: 'Software Engineering 2C03',
        shortName: 'Software Engineering 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 10,
        id: '5ef42181b8f8632cab89d750',
      },
      {
        _id: '5ef42181b8f8632cab89d752',
        courseCode: '2MX3',
        courseName: 'Software Engineering 2MX3',
        shortName: 'Software Engineering 2MX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 118,
        id: '5ef42181b8f8632cab89d752',
      },
      {
        _id: '5ef42181b8f8632cab89d74f',
        courseCode: '2AA4',
        courseName: 'Software Engineering 2AA4',
        shortName: 'Software Engineering 2AA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 15,
        id: '5ef42181b8f8632cab89d74f',
      },
      {
        _id: '5ef42181b8f8632cab89d758',
        courseCode: '3GA3',
        courseName: 'Software Engineering 3GA3',
        shortName: 'Software Engineering 3GA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '5ef42181b8f8632cab89d758',
      },
      {
        _id: '5ef42181b8f8632cab89d754',
        courseCode: '3A04',
        courseName: 'Software Engineering 3A04',
        shortName: 'Software Engineering 3A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '5ef42181b8f8632cab89d754',
      },
      {
        _id: '5ef42181b8f8632cab89d757',
        courseCode: '3F03',
        courseName: 'Software Engineering 3F03',
        shortName: 'Software Engineering 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 21,
        id: '5ef42181b8f8632cab89d757',
      },
      {
        _id: '5ef42181b8f8632cab89d765',
        courseCode: '2E06E',
        courseName: 'Sociology 2E06E',
        shortName: 'Sociology 2E06E',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d765',
      },
      {
        _id: '5ef42181b8f8632cab89d755',
        courseCode: '3BB4',
        courseName: 'Software Engineering 3BB4',
        shortName: 'Software Engineering 3BB4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 6,
        id: '5ef42181b8f8632cab89d755',
      },
      {
        _id: '5ef42181b8f8632cab89d75d',
        courseCode: '3X03',
        courseName: 'Software Engineering 3X03',
        shortName: 'Software Engineering 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 58,
        id: '5ef42181b8f8632cab89d75d',
      },
      {
        _id: '5ef42181b8f8632cab89d759',
        courseCode: '3I03',
        courseName: 'Software Engineering 3I03',
        shortName: 'Software Engineering 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d759',
      },
      {
        _id: '5ef42181b8f8632cab89d75c',
        courseCode: '3SH3',
        courseName: 'Software Engineering 3SH3',
        shortName: 'Software Engineering 3SH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 16,
        id: '5ef42181b8f8632cab89d75c',
      },
      {
        _id: '5ef42181b8f8632cab89d75a',
        courseCode: '3K04',
        courseName: 'Software Engineering 3K04',
        shortName: 'Software Engineering 3K04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 4,
        id: '5ef42181b8f8632cab89d75a',
      },
      {
        _id: '5ef42181b8f8632cab89d762',
        courseCode: '2K03',
        courseName: 'Science 2K03',
        shortName: 'Science 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d762',
      },
      {
        _id: '5ef42181b8f8632cab89d761',
        courseCode: '2A03',
        courseName: 'Science 2A03',
        shortName: 'Science 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d761',
      },
      {
        _id: '5ef42181b8f8632cab89d75e',
        courseCode: '4AA4',
        courseName: 'Software Engineering 4AA4',
        shortName: 'Software Engineering 4AA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 6,
        id: '5ef42181b8f8632cab89d75e',
      },
      {
        _id: '5ef42181b8f8632cab89d76f',
        courseCode: '3GG3',
        courseName: "Women's Studies 3GG3",
        shortName: "Women's Studies 3GG3",
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d76f',
      },
      {
        _id: '5ef42181b8f8632cab89d767',
        courseCode: '2MB3',
        courseName: 'Statistics 2MB3',
        shortName: 'Statistics 2MB3',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d767',
      },
      {
        _id: '5ef42181b8f8632cab89d75f',
        courseCode: '4E03',
        courseName: 'Software Engineering 4E03',
        shortName: 'Software Engineering 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 11,
        id: '5ef42181b8f8632cab89d75f',
      },
      {
        _id: '5ef42181b8f8632cab89d766',
        courseCode: '2P04',
        courseName: 'Statistics 2P04',
        shortName: 'Statistics 2P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 21,
        id: '5ef42181b8f8632cab89d766',
      },
      {
        _id: '5ef42181b8f8632cab89d763',
        courseCode: '2A03',
        courseName: 'Medical Physics 2A03',
        shortName: 'Medical Physics 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d763',
      },
      {
        _id: '5ef42181b8f8632cab89d76c',
        courseCode: '3Y03',
        courseName: 'Statistics 3Y03',
        shortName: 'Statistics 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 33,
        id: '5ef42181b8f8632cab89d76c',
      },
      {
        _id: '5ef42181b8f8632cab89d764',
        courseCode: '2MR3',
        courseName: 'Social Sciences 2MR3',
        shortName: 'Social Sciences 2MR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d764',
      },
      {
        _id: '5ef42181b8f8632cab89d76b',
        courseCode: '3DD3',
        courseName: 'Statistics 3DD3',
        shortName: 'Statistics 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d76b',
      },
      {
        _id: '5ef42181b8f8632cab89d771',
        courseCode: '3H03',
        courseName: "Women's Studies 3H03",
        shortName: "Women's Studies 3H03",
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d771',
      },
      {
        _id: '5ef42181b8f8632cab89d768',
        courseCode: '3A03',
        courseName: 'Statistics 3A03',
        shortName: 'Statistics 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d768',
      },
      {
        _id: '5ef42181b8f8632cab89d769',
        courseCode: '2B03',
        courseName: 'Statistics 2B03',
        shortName: 'Statistics 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '5ef42181b8f8632cab89d769',
      },
      {
        _id: '5ef42181b8f8632cab89d770',
        courseCode: '3G03',
        courseName: "Women's Studies 3G03",
        shortName: "Women's Studies 3G03",
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d770',
      },
      {
        _id: '5ef42181b8f8632cab89d76d',
        courseCode: '1T03',
        courseName: 'THTRFLM 1T03',
        shortName: 'THTRFLM 1T03',
        university: '5ef1880c28f09dee8c2a2867',
        id: '5ef42181b8f8632cab89d76d',
      },
      {
        _id: '5ef42181b8f8632cab89d76e',
        courseCode: '1AA3',
        courseName: "Women's Studies 1AA3",
        shortName: "Women's Studies 1AA3",
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '5ef42181b8f8632cab89d76e',
      },
      {
        _id: '5ef42181b8f8632cab89d772',
        courseCode: '3I03',
        courseName: "Women's Studies 3I03",
        shortName: "Women's Studies 3I03",
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 62,
        id: '5ef42181b8f8632cab89d772',
      },
      {
        _id: '6073b6b956f56ef6389739a4',
        courseName:
          'ANTHROP 1AA3 - Introduction to Anthropology: Sex, Food and Death',
        courseCode: '1AA3',
        shortName: 'ANTHROP 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '6073b6b956f56ef6389739a4',
      },
      {
        _id: '6073b6b956f56ef6389739a6',
        courseName:
          'ANTHROP 1AB3 - Introduction to Anthropology: Race, Religion, and Conflict',
        courseCode: '1AB3',
        shortName: 'ANTHROP 1AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6b956f56ef6389739a6',
      },
      {
        _id: '6073b6b956f56ef6389739a8',
        courseName: 'ANTHROP 2AN3 - The Anthropology of Food and Nutrition',
        courseCode: '2AN3',
        shortName: 'ANTHROP 2AN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6b956f56ef6389739a8',
      },
      {
        _id: '6073b6ba56f56ef6389739aa',
        courseName: 'ANTHROP 2BB3 - Ancient Mesoamerica: Aztecs to Zapotecs',
        courseCode: '2BB3',
        shortName: 'ANTHROP 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739aa',
      },
      {
        _id: '6073b6ba56f56ef6389739ac',
        courseName:
          'ANTHROP 2C03 - Archaeology of Environmental Crisis and Response',
        courseCode: '2C03',
        shortName: 'ANTHROP 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739ac',
      },
      {
        _id: '6073b6ba56f56ef6389739ae',
        courseName: 'ANTHROP 2D03 - DNA Meets Anthropology',
        courseCode: '2D03',
        shortName: 'ANTHROP 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739ae',
      },
      {
        _id: '6073b6ba56f56ef6389739b0',
        courseName:
          'ANTHROP 2DA3 - Traditional Indigenous Ecological Knowledge',
        courseCode: '2DA3',
        shortName: 'ANTHROP 2DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739b0',
      },
      {
        _id: '6073b6ba56f56ef6389739b2',
        courseName: 'ANTHROP 2E03 - Introduction to Biological Anthropology',
        courseCode: '2E03',
        shortName: 'ANTHROP 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739b2',
      },
      {
        _id: '6073b6ba56f56ef6389739b4',
        courseName: 'ANTHROP 2EE3 - Sport and/as Religion',
        courseCode: '2EE3',
        shortName: 'ANTHROP 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739b4',
      },
      {
        _id: '6073b6ba56f56ef6389739b6',
        courseName:
          'ANTHROP 2F03 - Listening across Difference: An Introduction to Cultural Anthropology',
        courseCode: '2F03',
        shortName: 'ANTHROP 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739b6',
      },
      {
        _id: '6073b6ba56f56ef6389739b8',
        courseName: 'ANTHROP 2FF3 - Human Skeletal Biology and Bioarchaeology',
        courseCode: '2FF3',
        shortName: 'ANTHROP 2FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739b8',
      },
      {
        _id: '6073b6ba56f56ef6389739ba',
        courseName: 'ANTHROP 2G03 - Readings in Indo-European Myth',
        courseCode: '2G03',
        shortName: 'ANTHROP 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739ba',
      },
      {
        _id: '6073b6ba56f56ef6389739bc',
        courseName: 'ANTHROP 2HE3 - Heritage, Economy, and Ethics',
        courseCode: '2HE3',
        shortName: 'ANTHROP 2HE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739bc',
      },
      {
        _id: '6073b6ba56f56ef6389739be',
        courseName:
          'ANTHROP 2HH3 - Science, Technology & Society: Archaeological Perspectives',
        courseCode: '2HH3',
        shortName: 'ANTHROP 2HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739be',
      },
      {
        _id: '6073b6ba56f56ef6389739c0',
        courseName: 'ANTHROP 2MA3 - Media, Art and Anthropology',
        courseCode: '2MA3',
        shortName: 'ANTHROP 2MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ba56f56ef6389739c0',
      },
      {
        _id: '6073b6bb56f56ef6389739c2',
        courseName:
          'ANTHROP 2O03 - Themes in the Archaeological History of North America',
        courseCode: '2O03',
        shortName: 'ANTHROP 2O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739c2',
      },
      {
        _id: '6073b6bb56f56ef6389739c4',
        courseName:
          'ANTHROP 2PA3 - Introduction to Anthropological Archaeology',
        courseCode: '2PA3',
        shortName: 'ANTHROP 2PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739c4',
      },
      {
        _id: '6073b6bb56f56ef6389739c6',
        courseName:
          'ANTHROP 2PC3 - Aliens, Curses and Nazis: Archaeology and Hollywood',
        courseCode: '2PC3',
        shortName: 'ANTHROP 2PC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739c6',
      },
      {
        _id: '6073b6bb56f56ef6389739c8',
        courseName: 'ANTHROP 2R03 - Religion, Magic and Witchcraft',
        courseCode: '2R03',
        shortName: 'ANTHROP 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739c8',
      },
      {
        _id: '6073b6bb56f56ef6389739ca',
        courseName: 'ANTHROP 2RP3 - Religion and Power in the Past',
        courseCode: '2RP3',
        shortName: 'ANTHROP 2RP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739ca',
      },
      {
        _id: '6073b6bb56f56ef6389739cc',
        courseName: 'ANTHROP 2U03 - Plagues and People',
        courseCode: '2U03',
        shortName: 'ANTHROP 2U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739cc',
      },
      {
        _id: '6073b6bb56f56ef6389739ce',
        courseName:
          'ANTHROP 2WA3 - Neanderthals to Pyramids: Introduction to World Archaeology',
        courseCode: '2WA3',
        shortName: 'ANTHROP 2WA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739ce',
      },
      {
        _id: '6073b6bb56f56ef6389739d0',
        courseName: 'ANTHROP 3AR3 - Culture and Religion',
        courseCode: '3AR3',
        shortName: 'ANTHROP 3AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739d0',
      },
      {
        _id: '6073b6bb56f56ef6389739d2',
        courseName: 'ANTHROP 3AS3 - Archaeology and Society',
        courseCode: '3AS3',
        shortName: 'ANTHROP 3AS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739d2',
      },
      {
        _id: '6073b6bb56f56ef6389739d4',
        courseName: 'ANTHROP 3BA3 - Special Topics in Biological Anthropology',
        courseCode: '3BA3',
        shortName: 'ANTHROP 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739d4',
      },
      {
        _id: '6073b6bb56f56ef6389739d6',
        courseName:
          'ANTHROP 3BB3 - Ancient Agriculture to Criminal Investigations: Paleoethnobotany in Practice',
        courseCode: '3BB3',
        shortName: 'ANTHROP 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bb56f56ef6389739d6',
      },
      {
        _id: '6073b6bc56f56ef6389739d8',
        courseName: 'ANTHROP 3BD3 - The Black Death',
        courseCode: '3BD3',
        shortName: 'ANTHROP 3BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739d8',
      },
      {
        _id: '6073b6bc56f56ef6389739da',
        courseName: 'ANTHROP 3BF3 - Bioarchaeological Field School',
        courseCode: '3BF3',
        shortName: 'ANTHROP 3BF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739da',
      },
      {
        _id: '6073b6bc56f56ef6389739dc',
        courseName:
          'ANTHROP 3C03 - Health and Environment: Anthropological Approaches',
        courseCode: '3C03',
        shortName: 'ANTHROP 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739dc',
      },
      {
        _id: '6073b6bc56f56ef6389739de',
        courseName: 'ANTHROP 3CA3 - Ceramic Analysis',
        courseCode: '3CA3',
        shortName: 'ANTHROP 3CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739de',
      },
      {
        _id: '6073b6bc56f56ef6389739e0',
        courseName: 'ANTHROP 3CC6 - Archaeological Field School',
        courseCode: '3CC6',
        shortName: 'ANTHROP 3CC6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739e0',
      },
      {
        _id: '6073b6bc56f56ef6389739e2',
        courseName: 'ANTHROP 3DD3 - Archaeology of Death',
        courseCode: '3DD3',
        shortName: 'ANTHROP 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739e2',
      },
      {
        _id: '6073b6bc56f56ef6389739e4',
        courseName: 'ANTHROP 3E03 - Special Topics in Archaeology I',
        courseCode: '3E03',
        shortName: 'ANTHROP 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739e4',
      },
      {
        _id: '6073b6bc56f56ef6389739e6',
        courseName: 'ANTHROP 3EE3 - Special Topics in Archaeology II',
        courseCode: '3EE3',
        shortName: 'ANTHROP 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739e6',
      },
      {
        _id: '6073b6bc56f56ef6389739e8',
        courseName:
          'ANTHROP 3EM3 - Current Debates in Eastern Mediterranean Prehistory',
        courseCode: '3EM3',
        shortName: 'ANTHROP 3EM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739e8',
      },
      {
        _id: '6073b6bc56f56ef6389739ea',
        courseName: 'ANTHROP 3F03 - Anthropology and the â€˜Otherâ€™',
        courseCode: '3F03',
        shortName: 'ANTHROP 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739ea',
      },
      {
        _id: '6073b6bc56f56ef6389739ec',
        courseName: 'ANTHROP 3FA3 - Forensic Anthropology',
        courseCode: '3FA3',
        shortName: 'ANTHROP 3FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739ec',
      },
      {
        _id: '6073b6bc56f56ef6389739ee',
        courseName: 'ANTHROP 3FF3 - Key Debates In Andean Archaeology',
        courseCode: '3FF3',
        shortName: 'ANTHROP 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bc56f56ef6389739ee',
      },
      {
        _id: '6073b6bd56f56ef6389739f0',
        courseName: 'ANTHROP 3G03 - Comparative Mythology',
        courseCode: '3G03',
        shortName: 'ANTHROP 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739f0',
      },
      {
        _id: '6073b6bd56f56ef6389739f2',
        courseName: 'ANTHROP 3GG3 - Anthropology of Contemporary Europe',
        courseCode: '3GG3',
        shortName: 'ANTHROP 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739f2',
      },
      {
        _id: '6073b6bd56f56ef6389739f4',
        courseName:
          'ANTHROP 3GH3 - Interdisciplinary Global Health Field Course: Maternal and Infant Health in Morocco',
        courseCode: '3GH3',
        shortName: 'ANTHROP 3GH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739f4',
      },
      {
        _id: '6073b6bd56f56ef6389739f6',
        courseName:
          'ANTHROP 3HH3 - Globalization, Social Justice and Human Rights',
        courseCode: '3HH3',
        shortName: 'ANTHROP 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739f6',
      },
      {
        _id: '6073b6bd56f56ef6389739f8',
        courseName: 'ANTHROP 3HI3 - Medical Anthropology',
        courseCode: '3HI3',
        shortName: 'ANTHROP 3HI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739f8',
      },
      {
        _id: '6073b6bd56f56ef6389739fa',
        courseName: 'ANTHROP 3IS3 - Independent Study in Anthropology',
        courseCode: '3IS3',
        shortName: 'ANTHROP 3IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739fa',
      },
      {
        _id: '6073b6bd56f56ef6389739fc',
        courseName: 'ANTHROP 3K03 - Archaeological Interpretation',
        courseCode: '3K03',
        shortName: 'ANTHROP 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739fc',
      },
      {
        _id: '6073b6bd56f56ef6389739fe',
        courseName: 'ANTHROP 3LA3 - Lithics Analysis',
        courseCode: '3LA3',
        shortName: 'ANTHROP 3LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef6389739fe',
      },
      {
        _id: '6073b6bd56f56ef638973a00',
        courseName: 'ANTHROP 3LL3 - Of Beauty and Violence',
        courseCode: '3LL3',
        shortName: 'ANTHROP 3LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef638973a00',
      },
      {
        _id: '6073b6bd56f56ef638973a02',
        courseName:
          'ANTHROP 3P03 - Doing Ethnography: Theory and Research Methods',
        courseCode: '3P03',
        shortName: 'ANTHROP 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef638973a02',
      },
      {
        _id: '6073b6bd56f56ef638973a04',
        courseName:
          'ANTHROP 3PA3 - Haudenosaunee Health, Diet and Traditional Botany',
        courseCode: '3PA3',
        shortName: 'ANTHROP 3PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bd56f56ef638973a04',
      },
      {
        _id: '6073b6be56f56ef638973a06',
        courseName: 'ANTHROP 3PD3 - Anthropological Perspectives and Debates',
        courseCode: '3PD3',
        shortName: 'ANTHROP 3PD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6be56f56ef638973a06',
      },
      {
        _id: '6073b6be56f56ef638973a08',
        courseName: 'ANTHROP 3PH3 - Dissent, Power and History',
        courseCode: '3PH3',
        shortName: 'ANTHROP 3PH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a08',
      },
      {
        _id: '6073b6be56f56ef638973a0a',
        courseName: 'ANTHROP 3PP3 - Paleopathology',
        courseCode: '3PP3',
        shortName: 'ANTHROP 3PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a0a',
      },
      {
        _id: '6073b6be56f56ef638973a0c',
        courseName: 'ANTHROP 3SS3 - Sacred Journeys',
        courseCode: '3SS3',
        shortName: 'ANTHROP 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a0c',
      },
      {
        _id: '6073b6be56f56ef638973a0e',
        courseName: 'ANTHROP 3W03 - Special Topics in Anthropology',
        courseCode: '3W03',
        shortName: 'ANTHROP 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a0e',
      },
      {
        _id: '6073b6be56f56ef638973a10',
        courseName: 'ANTHROP 3X03 - Zooarchaeology',
        courseCode: '3X03',
        shortName: 'ANTHROP 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a10',
      },
      {
        _id: '6073b6be56f56ef638973a12',
        courseName: 'ANTHROP 3Y03 - Indigenous Community Health and Wellbeing',
        courseCode: '3Y03',
        shortName: 'ANTHROP 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a12',
      },
      {
        _id: '6073b6be56f56ef638973a14',
        courseName: 'ANTHROP 4AA3 - Materiality, Matter and Social Lives',
        courseCode: '4AA3',
        shortName: 'ANTHROP 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a14',
      },
      {
        _id: '6073b6be56f56ef638973a16',
        courseName:
          'ANTHROP 4AH3 - Archaeology and Heritage: Ethics, Politics, and Practice',
        courseCode: '4AH3',
        shortName: 'ANTHROP 4AH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a16',
      },
      {
        _id: '6073b6be56f56ef638973a18',
        courseName:
          'ANTHROP 4B03 - Current Problems in Cultural Anthropology I',
        courseCode: '4B03',
        shortName: 'ANTHROP 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a18',
      },
      {
        _id: '6073b6be56f56ef638973a1a',
        courseName:
          'ANTHROP 4BB3 - Current Problems in Cultural Anthropology II',
        courseCode: '4BB3',
        shortName: 'ANTHROP 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a1a',
      },
      {
        _id: '6073b6be56f56ef638973a1c',
        courseName: 'ANTHROP 4CC3 - Archaeology Of Foodways',
        courseCode: '4CC3',
        shortName: 'ANTHROP 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6be56f56ef638973a1c',
      },
      {
        _id: '6073b6bf56f56ef638973a1e',
        courseName: 'ANTHROP 4CP3 - Cultural Politics of Food and Eating',
        courseCode: '4CP3',
        shortName: 'ANTHROP 4CP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a1e',
      },
      {
        _id: '6073b6bf56f56ef638973a20',
        courseName:
          'ANTHROP 4D03 - Practicing Anthropology: Ethics, Theory, Engagement',
        courseCode: '4D03',
        shortName: 'ANTHROP 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a20',
      },
      {
        _id: '6073b6bf56f56ef638973a22',
        courseName: 'ANTHROP 4DD3 - Anthropology of Zombies and the Undead',
        courseCode: '4DD3',
        shortName: 'ANTHROP 4DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a22',
      },
      {
        _id: '6073b6bf56f56ef638973a24',
        courseName:
          'ANTHROP 4DN3 - Diet & Nutrition: Biocultural and Bioarchaeological Perspectives',
        courseCode: '4DN3',
        shortName: 'ANTHROP 4DN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a24',
      },
      {
        _id: '6073b6bf56f56ef638973a26',
        courseName: 'ANTHROP 4E03 - Advanced Topics in Archaeology I',
        courseCode: '4E03',
        shortName: 'ANTHROP 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a26',
      },
      {
        _id: '6073b6bf56f56ef638973a28',
        courseName: 'ANTHROP 4EE3 - Advanced Topics in Archaeology II',
        courseCode: '4EE3',
        shortName: 'ANTHROP 4EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a28',
      },
      {
        _id: '6073b6bf56f56ef638973a2a',
        courseName: 'ANTHROP 4F03 - Current Debates in Archaeology',
        courseCode: '4F03',
        shortName: 'ANTHROP 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a2a',
      },
      {
        _id: '6073b6bf56f56ef638973a2c',
        courseName:
          'ANTHROP 4FF3 - Digging the City: The Archaeology of Urbanism',
        courseCode: '4FF3',
        shortName: 'ANTHROP 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a2c',
      },
      {
        _id: '6073b6bf56f56ef638973a2e',
        courseName: 'ANTHROP 4G03 - Independent Research I',
        courseCode: '4G03',
        shortName: 'ANTHROP 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a2e',
      },
      {
        _id: '6073b6bf56f56ef638973a30',
        courseName: 'ANTHROP 4GG3 - Independent Research II',
        courseCode: '4GG3',
        shortName: 'ANTHROP 4GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6bf56f56ef638973a30',
      },
      {
        _id: '6073b6c056f56ef638973a32',
        courseName: 'ANTHROP 4GS3 - Genetics and Society',
        courseCode: '4GS3',
        shortName: 'ANTHROP 4GS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a32',
      },
      {
        _id: '6073b6c056f56ef638973a34',
        courseName: 'ANTHROP 4HF3 - Archaeology of Hunter-Fisher-Gatherers',
        courseCode: '4HF3',
        shortName: 'ANTHROP 4HF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a34',
      },
      {
        _id: '6073b6c056f56ef638973a36',
        courseName: 'ANTHROP 4HH3 - Archaeologies of Space and Place',
        courseCode: '4HH3',
        shortName: 'ANTHROP 4HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a36',
      },
      {
        _id: '6073b6c056f56ef638973a38',
        courseName:
          'ANTHROP 4J03 - Advanced Topics in Biological Anthropology I',
        courseCode: '4J03',
        shortName: 'ANTHROP 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a38',
      },
      {
        _id: '6073b6c056f56ef638973a3a',
        courseName:
          'ANTHROP 4JJ3 - Advanced Topics in Biological Anthropology II',
        courseCode: '4JJ3',
        shortName: 'ANTHROP 4JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a3a',
      },
      {
        _id: '6073b6c056f56ef638973a3c',
        courseName:
          'ANTHROP 4KK3 - The Archaeology of Neanderthals and Other Early Humans',
        courseCode: '4KK3',
        shortName: 'ANTHROP 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a3c',
      },
      {
        _id: '6073b6c056f56ef638973a3e',
        courseName:
          'ANTHROP 4R03 - Advanced Bioarchaeology and Skeletal Biology',
        courseCode: '4R03',
        shortName: 'ANTHROP 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a3e',
      },
      {
        _id: '6073b6c056f56ef638973a40',
        courseName: 'ANTHROP 4S03 - The Anthropology of Infectious Disease',
        courseCode: '4S03',
        shortName: 'ANTHROP 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a40',
      },
      {
        _id: '6073b6c056f56ef638973a42',
        courseName: 'ANTHROP 4SG3 - The Secret of the Gift',
        courseCode: '4SG3',
        shortName: 'ANTHROP 4SG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a42',
      },
      {
        _id: '6073b6c056f56ef638973a44',
        courseName: 'ARABIC 2AA3 - Introduction to Modern Standard Arabic',
        courseCode: '2AA3',
        shortName: 'ARABIC 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c056f56ef638973a44',
      },
      {
        _id: '6073b6c156f56ef638973a46',
        courseName: 'ARABIC 2AR3 - Introduction to Modern Standard Arabic II',
        courseCode: '2AR3',
        shortName: 'ARABIC 2AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a46',
      },
      {
        _id: '6073b6c156f56ef638973a48',
        courseName: 'ARABIC 3AA3 - Intermediate Modern Standard Arabic',
        courseCode: '3AA3',
        shortName: 'ARABIC 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a48',
      },
      {
        _id: '6073b6c156f56ef638973a4a',
        courseName: 'ARABIC 3GH3 - Spoken Moroccan Arabic',
        courseCode: '3GH3',
        shortName: 'ARABIC 3GH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a4a',
      },
      {
        _id: '6073b6c156f56ef638973a4c',
        courseName:
          'ART 1DM3 - Dimensional Material Investigations and Concepts',
        courseCode: '1DM3',
        shortName: 'ART 1DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a4c',
      },
      {
        _id: '6073b6c156f56ef638973a4e',
        courseName: 'ART 1MI3 - Material Investigations and Concepts',
        courseCode: '1MI3',
        shortName: 'ART 1MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a4e',
      },
      {
        _id: '6073b6c156f56ef638973a50',
        courseName: 'ART 1OS3 - Observational Studies',
        courseCode: '1OS3',
        shortName: 'ART 1OS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a50',
      },
      {
        _id: '6073b6c156f56ef638973a52',
        courseName: 'ART 1SI3 - Studio Investigations',
        courseCode: '1SI3',
        shortName: 'ART 1SI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a52',
      },
      {
        _id: '6073b6c156f56ef638973a54',
        courseName:
          'ART 1TI3 - Making Art and Understanding Technology & Images',
        courseCode: '1TI3',
        shortName: 'ART 1TI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a54',
      },
      {
        _id: '6073b6c156f56ef638973a56',
        courseName: 'ART 1UI3 - Making Art and Understanding Images',
        courseCode: '1UI3',
        shortName: 'ART 1UI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c156f56ef638973a56',
      },
      {
        _id: '6073b6c256f56ef638973a58',
        courseName: 'ART 2AT3 - Art Today',
        courseCode: '2AT3',
        shortName: 'ART 2AT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a58',
      },
      {
        _id: '6073b6c256f56ef638973a5a',
        courseName: 'ART 2DG3 - Contemporary Approaches to Drawing',
        courseCode: '2DG3',
        shortName: 'ART 2DG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a5a',
      },
      {
        _id: '6073b6c256f56ef638973a5c',
        courseName: 'ART 2DP3 - Digital Practices',
        courseCode: '2DP3',
        shortName: 'ART 2DP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a5c',
      },
      {
        _id: '6073b6c256f56ef638973a5e',
        courseName: 'ART 2ER3 - Environmentally Responsible Art',
        courseCode: '2ER3',
        shortName: 'ART 2ER3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a5e',
      },
      {
        _id: '6073b6c256f56ef638973a60',
        courseName: 'ART 2IS3 - Independent Studio Methods',
        courseCode: '2IS3',
        shortName: 'ART 2IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a60',
      },
      {
        _id: '6073b6c256f56ef638973a62',
        courseName: 'ART 2PG3 - Contemporary Approaches to Painting',
        courseCode: '2PG3',
        shortName: 'ART 2PG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a62',
      },
      {
        _id: '6073b6c256f56ef638973a64',
        courseName: 'ART 2PM3 - Contemporary Approaches to Print Media',
        courseCode: '2PM3',
        shortName: 'ART 2PM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a64',
      },
      {
        _id: '6073b6c256f56ef638973a66',
        courseName: 'ART 2SC3 - Contemporary Approaches to Sculpture',
        courseCode: '2SC3',
        shortName: 'ART 2SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a66',
      },
      {
        _id: '6073b6c256f56ef638973a68',
        courseName: 'ART 3BA3 - Concentrated Study - Book Arts',
        courseCode: '3BA3',
        shortName: 'ART 3BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a68',
      },
      {
        _id: '6073b6c256f56ef638973a6a',
        courseName: 'ART 3CC3 - Concentrated Study - Ceramics',
        courseCode: '3CC3',
        shortName: 'ART 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a6a',
      },
      {
        _id: '6073b6c256f56ef638973a6c',
        courseName: 'ART 3CE3 - Community Exhibitions',
        courseCode: '3CE3',
        shortName: 'ART 3CE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c256f56ef638973a6c',
      },
      {
        _id: '6073b6c356f56ef638973a6e',
        courseName: 'ART 3CF3 - Concentrated Study - Foundry',
        courseCode: '3CF3',
        shortName: 'ART 3CF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a6e',
      },
      {
        _id: '6073b6c356f56ef638973a70',
        courseName: 'ART 3CI3 - Concentrated Study - Intaglio',
        courseCode: '3CI3',
        shortName: 'ART 3CI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a70',
      },
      {
        _id: '6073b6c356f56ef638973a72',
        courseName: 'ART 3CL3 - Concentrated Study - Lithography',
        courseCode: '3CL3',
        shortName: 'ART 3CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a72',
      },
      {
        _id: '6073b6c356f56ef638973a74',
        courseName: 'ART 3D03 - Practical Issues',
        courseCode: '3D03',
        shortName: 'ART 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a74',
      },
      {
        _id: '6073b6c356f56ef638973a76',
        courseName: 'ART 3FW3 - Field Work: On-Site Explorations',
        courseCode: '3FW3',
        shortName: 'ART 3FW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a76',
      },
      {
        _id: '6073b6c356f56ef638973a78',
        courseName: 'ART 3GS6 A/B - Guided Studio Practice',
        courseCode: '3GS6',
        shortName: 'ART 3GS6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a78',
      },
      {
        _id: '6073b6c356f56ef638973a7a',
        courseName: 'ART 3ID3 - Integrated Dimensional Media Concentration',
        courseCode: '3ID3',
        shortName: 'ART 3ID3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a7a',
      },
      {
        _id: '6073b6c356f56ef638973a7c',
        courseName: 'ART 3IM3 - Integrated Media Concentration',
        courseCode: '3IM3',
        shortName: 'ART 3IM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a7c',
      },
      {
        _id: '6073b6c356f56ef638973a7e',
        courseName: 'ART 3IP3 - Media Installation and Performance',
        courseCode: '3IP3',
        shortName: 'ART 3IP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a7e',
      },
      {
        _id: '6073b6c356f56ef638973a80',
        courseName:
          'ART 3J03 - Concentrated Study - Collaborative Community Projects',
        courseCode: '3J03',
        shortName: 'ART 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a80',
      },
      {
        _id: '6073b6c356f56ef638973a82',
        courseName: 'ART 3PB3 - Photography Beyond the Frame',
        courseCode: '3PB3',
        shortName: 'ART 3PB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c356f56ef638973a82',
      },
      {
        _id: '6073b6c456f56ef638973a84',
        courseName: 'ART 3PD3 - New Directions in Painting/drawing',
        courseCode: '3PD3',
        shortName: 'ART 3PD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a84',
      },
      {
        _id: '6073b6c456f56ef638973a86',
        courseName: 'ART 3VA3 - Video Art and Digital Cinema',
        courseCode: '3VA3',
        shortName: 'ART 3VA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a86',
      },
      {
        _id: '6073b6c456f56ef638973a88',
        courseName: 'ART 4AR3 - Advanced Research and Presentation Strategies',
        courseCode: '4AR3',
        shortName: 'ART 4AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a88',
      },
      {
        _id: '6073b6c456f56ef638973a8a',
        courseName:
          'ART 4AS6 A/B - Advanced Studio Production and Critical Discourse',
        courseCode: '4AS6',
        shortName: 'ART 4AS6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a8a',
      },
      {
        _id: '6073b6c456f56ef638973a8c',
        courseName:
          'ART 4CA3 - 20th Century and Contemporary Art Practices: How Artists Think, Act and Engage',
        courseCode: '4CA3',
        shortName: 'ART 4CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a8c',
      },
      {
        _id: '6073b6c456f56ef638973a8e',
        courseName: 'ART 4CC3 - Concentrated Study - Ceramics',
        courseCode: '4CC3',
        shortName: 'ART 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a8e',
      },
      {
        _id: '6073b6c456f56ef638973a90',
        courseName: 'ART 4CI3 - Concentrated Study - Intaglio',
        courseCode: '4CI3',
        shortName: 'ART 4CI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a90',
      },
      {
        _id: '6073b6c456f56ef638973a92',
        courseName: 'ART 4CL3 - Concentrated Study - Lithography',
        courseCode: '4CL3',
        shortName: 'ART 4CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a92',
      },
      {
        _id: '6073b6c456f56ef638973a94',
        courseName: 'ART 4EP3 - Exhibition Preparation and Documentation',
        courseCode: '4EP3',
        shortName: 'ART 4EP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a94',
      },
      {
        _id: '6073b6c456f56ef638973a96',
        courseName: 'ART 4PR3 - Professional Residency',
        courseCode: '4PR3',
        shortName: 'ART 4PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a96',
      },
      {
        _id: '6073b6c456f56ef638973a98',
        courseName: 'ARTHIST 1A03 - World Art and Cultural Heritage I',
        courseCode: '1A03',
        shortName: 'ARTHIST 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c456f56ef638973a98',
      },
      {
        _id: '6073b6c556f56ef638973a9a',
        courseName: 'ARTHIST 1AA3 - World Art and Cultural Heritage II',
        courseCode: '1AA3',
        shortName: 'ARTHIST 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973a9a',
      },
      {
        _id: '6073b6c556f56ef638973a9c',
        courseName:
          'ARTHIST 1PA3 - Arts in Society: Social Constructions of Race and Gender',
        courseCode: '1PA3',
        shortName: 'ARTHIST 1PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973a9c',
      },
      {
        _id: '6073b6c556f56ef638973a9e',
        courseName: 'ARTHIST 2A03 - Visual Literacy',
        courseCode: '2A03',
        shortName: 'ARTHIST 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973a9e',
      },
      {
        _id: '6073b6c556f56ef638973aa0',
        courseName:
          'ARTHIST 2AA3 - Introduction to the Practice of Art Therapy',
        courseCode: '2AA3',
        shortName: 'ARTHIST 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aa0',
      },
      {
        _id: '6073b6c556f56ef638973aa2',
        courseName: 'ARTHIST 2B03 - Greek Art',
        courseCode: '2B03',
        shortName: 'ARTHIST 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aa2',
      },
      {
        _id: '6073b6c556f56ef638973aa4',
        courseName: 'ARTHIST 2C03 - Roman Art',
        courseCode: '2C03',
        shortName: 'ARTHIST 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aa4',
      },
      {
        _id: '6073b6c556f56ef638973aa6',
        courseName: 'ARTHIST 2DF3 - Art and Revolutions in France, 1789-1914',
        courseCode: '2DF3',
        shortName: 'ARTHIST 2DF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aa6',
      },
      {
        _id: '6073b6c556f56ef638973aa8',
        courseName: 'ARTHIST 2FA3 - Film Analysis',
        courseCode: '2FA3',
        shortName: 'ARTHIST 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aa8',
      },
      {
        _id: '6073b6c556f56ef638973aaa',
        courseName: 'ARTHIST 2H03 - Aesthetics',
        courseCode: '2H03',
        shortName: 'ARTHIST 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aaa',
      },
      {
        _id: '6073b6c556f56ef638973aac',
        courseName: 'ARTHIST 2I03 - Renaissance Art',
        courseCode: '2I03',
        shortName: 'ARTHIST 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aac',
      },
      {
        _id: '6073b6c556f56ef638973aae',
        courseName:
          'ARTHIST 2J03 - Architecture from the Pre-Romanesque to Palladio',
        courseCode: '2J03',
        shortName: 'ARTHIST 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c556f56ef638973aae',
      },
      {
        _id: '6073b6c656f56ef638973ab0',
        courseName: 'ARTHIST 2R03 - The History of Fashion and Identity',
        courseCode: '2R03',
        shortName: 'ARTHIST 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ab0',
      },
      {
        _id: '6073b6c656f56ef638973ab2',
        courseName: 'ARTHIST 2S03 - The History of Printing and Printmaking',
        courseCode: '2S03',
        shortName: 'ARTHIST 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ab2',
      },
      {
        _id: '6073b6c656f56ef638973ab4',
        courseName:
          'ARTHIST 2T03 - Art, Theatre and Music in the Enlightenment',
        courseCode: '2T03',
        shortName: 'ARTHIST 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ab4',
      },
      {
        _id: '6073b6c656f56ef638973ab6',
        courseName: 'ARTHIST 2Y03 - Early Islamic Art to the Middle Ages',
        courseCode: '2Y03',
        shortName: 'ARTHIST 2Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ab6',
      },
      {
        _id: '6073b6c656f56ef638973ab8',
        courseName:
          'ARTHIST 2Z03 - Art and Visual Culture in East and South Asia',
        courseCode: '2Z03',
        shortName: 'ARTHIST 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ab8',
      },
      {
        _id: '6073b6c656f56ef638973aba',
        courseName:
          'ARTHIST 3AB3 - Applied Art History: Experiential Learning with Placement as an Intern',
        courseCode: '3AB3',
        shortName: 'ARTHIST 3AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973aba',
      },
      {
        _id: '6073b6c656f56ef638973abc',
        courseName: 'ARTHIST 3B03 - Aspects of Canadian Art',
        courseCode: '3B03',
        shortName: 'ARTHIST 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973abc',
      },
      {
        _id: '6073b6c656f56ef638973abe',
        courseName:
          'ARTHIST 3BB3 - Indigenous Art and Visual Culture in Canada, 1960 to the Present',
        courseCode: '3BB3',
        shortName: 'ARTHIST 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973abe',
      },
      {
        _id: '6073b6c656f56ef638973ac0',
        courseName: 'ARTHIST 3D03 - Seventeenth-Century Art',
        courseCode: '3D03',
        shortName: 'ARTHIST 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ac0',
      },
      {
        _id: '6073b6c656f56ef638973ac2',
        courseName: 'ARTHIST 3DF3 - Art and Politics in Second Empire France',
        courseCode: '3DF3',
        shortName: 'ARTHIST 3DF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c656f56ef638973ac2',
      },
      {
        _id: '6073b6c756f56ef638973ac4',
        courseName: 'ARTHIST 3FL3 - Early Cinema History',
        courseCode: '3FL3',
        shortName: 'ARTHIST 3FL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ac4',
      },
      {
        _id: '6073b6c756f56ef638973ac6',
        courseName: 'ARTHIST 3H03 - Archaic Greek Art',
        courseCode: '3H03',
        shortName: 'ARTHIST 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ac6',
      },
      {
        _id: '6073b6c756f56ef638973ac8',
        courseName: 'ARTHIST 3I03 - Italian Painting and Sculpture 1400-1580',
        courseCode: '3I03',
        shortName: 'ARTHIST 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ac8',
      },
      {
        _id: '6073b6c756f56ef638973aca',
        courseName: 'ARTHIST 3JA3 - The History of Art 1970 to the Present',
        courseCode: '3JA3',
        shortName: 'ARTHIST 3JA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973aca',
      },
      {
        _id: '6073b6c756f56ef638973acc',
        courseName: 'ARTHIST 3P03 - Issues in Studio Criticism',
        courseCode: '3P03',
        shortName: 'ARTHIST 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973acc',
      },
      {
        _id: '6073b6c756f56ef638973ace',
        courseName: 'ARTHIST 3Q03 - Colours of the World',
        courseCode: '3Q03',
        shortName: 'ARTHIST 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ace',
      },
      {
        _id: '6073b6c756f56ef638973ad0',
        courseName: 'ARTHIST 3QQ3 - Greek Sanctuaries',
        courseCode: '3QQ3',
        shortName: 'ARTHIST 3QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ad0',
      },
      {
        _id: '6073b6c756f56ef638973ad2',
        courseName: 'ARTHIST 3SS3 - Pompeii, Herculaneum, and Ostia',
        courseCode: '3SS3',
        shortName: 'ARTHIST 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ad2',
      },
      {
        _id: '6073b6c756f56ef638973ad4',
        courseName: 'ARTHIST 3XX3 - Cinema History from WWII',
        courseCode: '3XX3',
        shortName: 'ARTHIST 3XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ad4',
      },
      {
        _id: '6073b6c756f56ef638973ad6',
        courseName: 'ARTHIST 3Z03 - The Silk Road in the First Millennium',
        courseCode: '3Z03',
        shortName: 'ARTHIST 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ad6',
      },
      {
        _id: '6073b6c756f56ef638973ad8',
        courseName:
          'ARTHIST 4AA3 - Seminar in Contemporary Art and Visual Culture',
        courseCode: '4AA3',
        shortName: 'ARTHIST 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ad8',
      },
      {
        _id: '6073b6c756f56ef638973ada',
        courseName: 'ARTHIST 4BB3 - Seminar in Ancient Art',
        courseCode: '4BB3',
        shortName: 'ARTHIST 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c756f56ef638973ada',
      },
      {
        _id: '6073b6c856f56ef638973adc',
        courseName: 'ARTHIST 4C03 - Seminar in Art and Visual Culture 900-1400',
        courseCode: '4C03',
        shortName: 'ARTHIST 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973adc',
      },
      {
        _id: '6073b6c856f56ef638973ade',
        courseName:
          'ARTHIST 4CA3 - 20th Century and Contemporary Art Practices: How Artists Think, Act and Engage',
        courseCode: '4CA3',
        shortName: 'ARTHIST 4CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973ade',
      },
      {
        _id: '6073b6c856f56ef638973ae0',
        courseName:
          'ARTHIST 4E03 - Seminar in Art and Visual Culture 1400 - 1750',
        courseCode: '4E03',
        shortName: 'ARTHIST 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973ae0',
      },
      {
        _id: '6073b6c856f56ef638973ae2',
        courseName: 'ARTHIST 4LP3 - The Cultural History of Paris, 1789-1914',
        courseCode: '4LP3',
        shortName: 'ARTHIST 4LP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973ae2',
      },
      {
        _id: '6073b6c856f56ef638973ae4',
        courseName: 'ARTHIST 4O06 A/B - Thesis',
        courseCode: '4O06',
        shortName: 'ARTHIST 4O06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973ae4',
      },
      {
        _id: '6073b6c856f56ef638973ae6',
        courseName: 'ARTHIST 4U03 - The Severe Style in Greek Art',
        courseCode: '4U03',
        shortName: 'ARTHIST 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973ae6',
      },
      {
        _id: '6073b6c856f56ef638973ae8',
        courseName: 'ARTHIST 4V03 - The Study, Criticism and Evaluation of Art',
        courseCode: '4V03',
        shortName: 'ARTHIST 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973ae8',
      },
      {
        _id: '6073b6c856f56ef638973aea',
        courseName: 'ARTHIST 4X03 - Introduction to Art Galleries and Museums',
        courseCode: '4X03',
        shortName: 'ARTHIST 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973aea',
      },
      {
        _id: '6073b6c856f56ef638973aec',
        courseName: 'ARTSSCI 1A06 A/B - Practices of Knowledge',
        courseCode: '1A06',
        shortName: 'ARTSSCI 1A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973aec',
      },
      {
        _id: '6073b6c856f56ef638973aee',
        courseName: 'ARTSSCI 1B03 - Writing',
        courseCode: '1B03',
        shortName: 'ARTSSCI 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973aee',
      },
      {
        _id: '6073b6c856f56ef638973af0',
        courseName: 'ARTSSCI 1BB3 - Argumentation',
        courseCode: '1BB3',
        shortName: 'ARTSSCI 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c856f56ef638973af0',
      },
      {
        _id: '6073b6c956f56ef638973af2',
        courseName: 'ARTSSCI 1C03 - Inquiry: Global Challenges',
        courseCode: '1C03',
        shortName: 'ARTSSCI 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973af2',
      },
      {
        _id: '6073b6c956f56ef638973af4',
        courseName: 'ARTSSCI 1CC3 - Contemporary Indigenous Studies',
        courseCode: '1CC3',
        shortName: 'ARTSSCI 1CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973af4',
      },
      {
        _id: '6073b6c956f56ef638973af6',
        courseName: 'ARTSSCI 1D06 A/B - Calculus',
        courseCode: '1D06',
        shortName: 'ARTSSCI 1D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973af6',
      },
      {
        _id: '6073b6c956f56ef638973af8',
        courseName: 'ARTSSCI 2A06 A/B - Social and Political Thought',
        courseCode: '2A06',
        shortName: 'ARTSSCI 2A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973af8',
      },
      {
        _id: '6073b6c956f56ef638973afa',
        courseName: 'ARTSSCI 2D06 A/B - Physics',
        courseCode: '2D06',
        shortName: 'ARTSSCI 2D06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973afa',
      },
      {
        _id: '6073b6c956f56ef638973afc',
        courseName: 'ARTSSCI 2E03 - Economics: Principles and Policy',
        courseCode: '2E03',
        shortName: 'ARTSSCI 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973afc',
      },
      {
        _id: '6073b6c956f56ef638973afe',
        courseName: 'ARTSSCI 2R03 - Applied Statistical Inference',
        courseCode: '2R03',
        shortName: 'ARTSSCI 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973afe',
      },
      {
        _id: '6073b6c956f56ef638973b00',
        courseName: 'ARTSSCI 3A06 A/B - Literature',
        courseCode: '3A06',
        shortName: 'ARTSSCI 3A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973b00',
      },
      {
        _id: '6073b6c956f56ef638973b02',
        courseName: 'ARTSSCI 3B03 - Technology and Society I',
        courseCode: '3B03',
        shortName: 'ARTSSCI 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973b02',
      },
      {
        _id: '6073b6c956f56ef638973b04',
        courseName: 'ARTSSCI 3BB3 - Technology and Society II',
        courseCode: '3BB3',
        shortName: 'ARTSSCI 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6c956f56ef638973b04',
      },
      {
        _id: '6073b6ca56f56ef638973b06',
        courseName: 'ARTSSCI 3BC3 - Technology and Society III',
        courseCode: '3BC3',
        shortName: 'ARTSSCI 3BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b06',
      },
      {
        _id: '6073b6ca56f56ef638973b08',
        courseName: 'ARTSSCI 3CL3 - Theatre, Self, and Social Development',
        courseCode: '3CL3',
        shortName: 'ARTSSCI 3CL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b08',
      },
      {
        _id: '6073b6ca56f56ef638973b0a',
        courseName: 'ARTSSCI 3CU3 - Alumni Experience Inquiry',
        courseCode: '3CU3',
        shortName: 'ARTSSCI 3CU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b0a',
      },
      {
        _id: '6073b6ca56f56ef638973b0c',
        courseName: 'ARTSSCI 3DS1 - Deep Skills Development',
        courseCode: '3DS1',
        shortName: 'ARTSSCI 3DS1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b0c',
      },
      {
        _id: '6073b6ca56f56ef638973b0e',
        courseName: 'ARTSSCI 3EH3 - Exploring Hamilton Inquiry',
        courseCode: '3EH3',
        shortName: 'ARTSSCI 3EH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b0e',
      },
      {
        _id: '6073b6ca56f56ef638973b10',
        courseName:
          'ARTSSCI 3F03 - Experiential Project in Teaching and Learning',
        courseCode: '3F03',
        shortName: 'ARTSSCI 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b10',
      },
      {
        _id: '6073b6ca56f56ef638973b12',
        courseName: 'ARTSSCI 3GJ3 - Global Justice Inquiry',
        courseCode: '3GJ3',
        shortName: 'ARTSSCI 3GJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b12',
      },
      {
        _id: '6073b6ca56f56ef638973b14',
        courseName: 'ARTSSCI 3RL3 - Religion and Law',
        courseCode: '3RL3',
        shortName: 'ARTSSCI 3RL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b14',
      },
      {
        _id: '6073b6ca56f56ef638973b16',
        courseName: 'ARTSSCI 3S03 - The East Asian Religious Tradition',
        courseCode: '3S03',
        shortName: 'ARTSSCI 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b16',
      },
      {
        _id: '6073b6ca56f56ef638973b18',
        courseName: 'ARTSSCI 3TR3 - Trees Inquiry',
        courseCode: '3TR3',
        shortName: 'ARTSSCI 3TR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b18',
      },
      {
        _id: '6073b6ca56f56ef638973b1a',
        courseName: 'ARTSSCI 3X03 - Individual Study',
        courseCode: '3X03',
        shortName: 'ARTSSCI 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b1a',
      },
      {
        _id: '6073b6ca56f56ef638973b1c',
        courseName: 'ARTSSCI 4A06 A/B - Individual Study',
        courseCode: '4A06',
        shortName: 'ARTSSCI 4A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ca56f56ef638973b1c',
      },
      {
        _id: '6073b6cb56f56ef638973b1e',
        courseName: 'ARTSSCI 4A09 A/B - Individual Study',
        courseCode: '4A09',
        shortName: 'ARTSSCI 4A09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b1e',
      },
      {
        _id: '6073b6cb56f56ef638973b20',
        courseName: 'ARTSSCI 4C06 A/B - Thesis',
        courseCode: '4C06',
        shortName: 'ARTSSCI 4C06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b20',
      },
      {
        _id: '6073b6cb56f56ef638973b22',
        courseName: 'ARTSSCI 4C09 A/B - Thesis',
        courseCode: '4C09',
        shortName: 'ARTSSCI 4C09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b22',
      },
      {
        _id: '6073b6cb56f56ef638973b24',
        courseName: 'ARTSSCI 4CB3 - Education Inquiry',
        courseCode: '4CB3',
        shortName: 'ARTSSCI 4CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b24',
      },
      {
        _id: '6073b6cb56f56ef638973b26',
        courseName: 'ARTSSCI 4CD3 - Research and Creative Writing',
        courseCode: '4CD3',
        shortName: 'ARTSSCI 4CD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b26',
      },
      {
        _id: '6073b6cb56f56ef638973b28',
        courseName: 'ARTSSCI 4CF3 - How Science Speaks to Power',
        courseCode: '4CF3',
        shortName: 'ARTSSCI 4CF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b28',
      },
      {
        _id: '6073b6cb56f56ef638973b2a',
        courseName: 'ARTSSCI 4CI3 - Diversity and Human Rights Inquiry',
        courseCode: '4CI3',
        shortName: 'ARTSSCI 4CI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b2a',
      },
      {
        _id: '6073b6cb56f56ef638973b2c',
        courseName: 'ARTSSCI 4CP3 - Media Inquiry',
        courseCode: '4CP3',
        shortName: 'ARTSSCI 4CP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b2c',
      },
      {
        _id: '6073b6cb56f56ef638973b2e',
        courseName: 'ARTSSCI 4CT3 - Medical Humanities Inquiry',
        courseCode: '4CT3',
        shortName: 'ARTSSCI 4CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b2e',
      },
      {
        _id: '6073b6cb56f56ef638973b30',
        courseName: 'ARTSSCI 4EP3 - Environmental Policy Inquiry',
        courseCode: '4EP3',
        shortName: 'ARTSSCI 4EP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b30',
      },
      {
        _id: '6073b6cb56f56ef638973b32',
        courseName: 'ARTSSCI 4HS3 - History of Science Inquiry',
        courseCode: '4HS3',
        shortName: 'ARTSSCI 4HS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b32',
      },
      {
        _id: '6073b6cb56f56ef638973b34',
        courseName: 'ARTSSCI 4MN1 - Local Explorations',
        courseCode: '4MN1',
        shortName: 'ARTSSCI 4MN1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cb56f56ef638973b34',
      },
      {
        _id: '6073b6cc56f56ef638973b36',
        courseName: 'ARTSSCI 4MN2 - Movement and Integration',
        courseCode: '4MN2',
        shortName: 'ARTSSCI 4MN2',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b36',
      },
      {
        _id: '6073b6cc56f56ef638973b38',
        courseName: 'ARTSSCI 4ST3 - Selected Topics in Inquiry',
        courseCode: '4ST3',
        shortName: 'ARTSSCI 4ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b38',
      },
      {
        _id: '6073b6cc56f56ef638973b3a',
        courseName: 'ARTSSCI 4VC3 - Visual Culture Inquiry',
        courseCode: '4VC3',
        shortName: 'ARTSSCI 4VC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b3a',
      },
      {
        _id: '6073b6cc56f56ef638973b3c',
        courseName: 'ASTRON 1F03 - Introduction to Astronomy and Astrophysics',
        courseCode: '1F03',
        shortName: 'ASTRON 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b3c',
      },
      {
        _id: '6073b6cc56f56ef638973b3e',
        courseName: 'ASTRON 2B03 - The Big Questions',
        courseCode: '2B03',
        shortName: 'ASTRON 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b3e',
      },
      {
        _id: '6073b6cc56f56ef638973b40',
        courseName: 'ASTRON 2E03 - Planetary Astronomy',
        courseCode: '2E03',
        shortName: 'ASTRON 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b40',
      },
      {
        _id: '6073b6cc56f56ef638973b42',
        courseName: 'ASTRON 3X03 - Galaxies and Cosmology',
        courseCode: '3X03',
        shortName: 'ASTRON 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b42',
      },
      {
        _id: '6073b6cc56f56ef638973b44',
        courseName: 'ASTRON 3Y03 - Stellar Structure',
        courseCode: '3Y03',
        shortName: 'ASTRON 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b44',
      },
      {
        _id: '6073b6cc56f56ef638973b46',
        courseName: 'ASTRON 4X03 - Astrophysics Data Analysis Project Course',
        courseCode: '4X03',
        shortName: 'ASTRON 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b46',
      },
      {
        _id: '6073b6cc56f56ef638973b48',
        courseName: 'ASTRON 5X03 A/B S - Graduate Topics in Astrophysics',
        courseCode: '5X03',
        shortName: 'ASTRON 5X03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b48',
      },
      {
        _id: '6073b6cc56f56ef638973b4a',
        courseName: 'AUTOTECH 2AC3 - Advanced CAD',
        courseCode: '2AC3',
        shortName: 'AUTOTECH 2AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cc56f56ef638973b4a',
      },
      {
        _id: '6073b6cd56f56ef638973b4c',
        courseName: 'AUTOTECH 2AE3 - Design of Machine Elements',
        courseCode: '2AE3',
        shortName: 'AUTOTECH 2AE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b4c',
      },
      {
        _id: '6073b6cd56f56ef638973b4e',
        courseName: 'AUTOTECH 2CD3 - Computer Aided Design',
        courseCode: '2CD3',
        shortName: 'AUTOTECH 2CD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b4e',
      },
      {
        _id: '6073b6cd56f56ef638973b50',
        courseName: 'AUTOTECH 2MT3 - Structure and Properties of Materials',
        courseCode: '2MT3',
        shortName: 'AUTOTECH 2MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b50',
      },
      {
        _id: '6073b6cd56f56ef638973b52',
        courseName: 'AUTOTECH 2TS3 - Thermodynamics and Heat Transfer',
        courseCode: '2TS3',
        shortName: 'AUTOTECH 2TS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b52',
      },
      {
        _id: '6073b6cd56f56ef638973b54',
        courseName: 'AUTOTECH 3AE3 - Automotive Engineering Technology',
        courseCode: '3AE3',
        shortName: 'AUTOTECH 3AE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b54',
      },
      {
        _id: '6073b6cd56f56ef638973b56',
        courseName: 'AUTOTECH 3AV3 - Electric and Hybrid Vehicles',
        courseCode: '3AV3',
        shortName: 'AUTOTECH 3AV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b56',
      },
      {
        _id: '6073b6cd56f56ef638973b58',
        courseName: 'AUTOTECH 3CT3 - Control Theory',
        courseCode: '3CT3',
        shortName: 'AUTOTECH 3CT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b58',
      },
      {
        _id: '6073b6cd56f56ef638973b5a',
        courseName: 'AUTOTECH 3MP3 - Manufacturing Processes and Systems',
        courseCode: '3MP3',
        shortName: 'AUTOTECH 3MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b5a',
      },
      {
        _id: '6073b6cd56f56ef638973b5c',
        courseName: 'AUTOTECH 3MV3 - Mechatronics',
        courseCode: '3MV3',
        shortName: 'AUTOTECH 3MV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b5c',
      },
      {
        _id: '6073b6cd56f56ef638973b5e',
        courseName: 'AUTOTECH 3TS3 - Fluid Mechanics',
        courseCode: '3TS3',
        shortName: 'AUTOTECH 3TS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b5e',
      },
      {
        _id: '6073b6cd56f56ef638973b60',
        courseName: 'AUTOTECH 3VD3 - Mechanical Vibrations',
        courseCode: '3VD3',
        shortName: 'AUTOTECH 3VD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b60',
      },
      {
        _id: '6073b6cd56f56ef638973b62',
        courseName: 'AUTOTECH 4AE3 - Internal Combustion Engines',
        courseCode: '4AE3',
        shortName: 'AUTOTECH 4AE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cd56f56ef638973b62',
      },
      {
        _id: '6073b6ce56f56ef638973b64',
        courseName:
          'AUTOTECH 4AT3 - Conceptual Design of Electric and Hybrid Vehicles',
        courseCode: '4AT3',
        shortName: 'AUTOTECH 4AT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b64',
      },
      {
        _id: '6073b6ce56f56ef638973b66',
        courseName:
          'AUTOTECH 4CI3 - Robotics and Computer Integrated Manufacturing',
        courseCode: '4CI3',
        shortName: 'AUTOTECH 4CI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b66',
      },
      {
        _id: '6073b6ce56f56ef638973b68',
        courseName: 'AUTOTECH 4DV3 - Vehicle Dynamics',
        courseCode: '4DV3',
        shortName: 'AUTOTECH 4DV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b68',
      },
      {
        _id: '6073b6ce56f56ef638973b6a',
        courseName:
          'AUTOTECH 4EC3 - Electrical and Electronics Control Systems',
        courseCode: '4EC3',
        shortName: 'AUTOTECH 4EC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b6a',
      },
      {
        _id: '6073b6ce56f56ef638973b6c',
        courseName:
          'AUTOTECH 4MS3 - Kinematic and Dynamic Modelling and Simulation',
        courseCode: '4MS3',
        shortName: 'AUTOTECH 4MS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b6c',
      },
      {
        _id: '6073b6ce56f56ef638973b6e',
        courseName: 'AUTOTECH 4TR1 - Capstone Design I',
        courseCode: '4TR1',
        shortName: 'AUTOTECH 4TR1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b6e',
      },
      {
        _id: '6073b6ce56f56ef638973b70',
        courseName: 'AUTOTECH 4TR3 - Capstone Design II',
        courseCode: '4TR3',
        shortName: 'AUTOTECH 4TR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b70',
      },
      {
        _id: '6073b6ce56f56ef638973b72',
        courseName: 'BIOCHEM 2B03 - Nucleic Acid Structure and Function',
        courseCode: '2B03',
        shortName: 'BIOCHEM 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b72',
      },
      {
        _id: '6073b6ce56f56ef638973b74',
        courseName: 'BIOCHEM 2BB3 - Protein Structure and Enzyme Function',
        courseCode: '2BB3',
        shortName: 'BIOCHEM 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b74',
      },
      {
        _id: '6073b6ce56f56ef638973b76',
        courseName: 'BIOCHEM 2EE3 - Metabolism and Physiological Chemistry',
        courseCode: '2EE3',
        shortName: 'BIOCHEM 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b76',
      },
      {
        _id: '6073b6ce56f56ef638973b78',
        courseName: 'BIOCHEM 2L06 A/B - Inquiry in Biochemical Techniques',
        courseCode: '2L06',
        shortName: 'BIOCHEM 2L06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b78',
      },
      {
        _id: '6073b6ce56f56ef638973b7a',
        courseName: 'BIOCHEM 3A03 - Biochemical Research Practice',
        courseCode: '3A03',
        shortName: 'BIOCHEM 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ce56f56ef638973b7a',
      },
      {
        _id: '6073b6cf56f56ef638973b7c',
        courseName:
          'BIOCHEM 3BP3 - Practical Bioinformatics in the Genomics Era',
        courseCode: '3BP3',
        shortName: 'BIOCHEM 3BP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b7c',
      },
      {
        _id: '6073b6cf56f56ef638973b7e',
        courseName: 'BIOCHEM 3D03 - Metabolism and Regulation',
        courseCode: '3D03',
        shortName: 'BIOCHEM 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b7e',
      },
      {
        _id: '6073b6cf56f56ef638973b80',
        courseName:
          'BIOCHEM 3EE3 - Research Advances in Cell Biology and Biochemistry',
        courseCode: '3EE3',
        shortName: 'BIOCHEM 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b80',
      },
      {
        _id: '6073b6cf56f56ef638973b82',
        courseName: 'BIOCHEM 3G03 - Proteins and Nucleic Acids',
        courseCode: '3G03',
        shortName: 'BIOCHEM 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b82',
      },
      {
        _id: '6073b6cf56f56ef638973b84',
        courseName: 'BIOCHEM 3H03 - Clinical Biochemistry',
        courseCode: '3H03',
        shortName: 'BIOCHEM 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b84',
      },
      {
        _id: '6073b6cf56f56ef638973b86',
        courseName: 'BIOCHEM 3LA3 - Advanced Biochemistry Techniques',
        courseCode: '3LA3',
        shortName: 'BIOCHEM 3LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b86',
      },
      {
        _id: '6073b6cf56f56ef638973b88',
        courseName: 'BIOCHEM 3MI3 - Microbial Interactions',
        courseCode: '3MI3',
        shortName: 'BIOCHEM 3MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b88',
      },
      {
        _id: '6073b6cf56f56ef638973b8a',
        courseName: 'BIOCHEM 3R06 A/B S - Research Project',
        courseCode: '3R06',
        shortName: 'BIOCHEM 3R06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b8a',
      },
      {
        _id: '6073b6cf56f56ef638973b8c',
        courseName:
          'BIOCHEM 3Z03 - Structural Determination and Analysis of Macromolecules',
        courseCode: '3Z03',
        shortName: 'BIOCHEM 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b8c',
      },
      {
        _id: '6073b6cf56f56ef638973b8e',
        courseName: 'BIOCHEM 4C03 - Inquiry in Biochemistry',
        courseCode: '4C03',
        shortName: 'BIOCHEM 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b8e',
      },
      {
        _id: '6073b6cf56f56ef638973b90',
        courseName:
          'BIOCHEM 4E03 - Gene Regulation in Stem Cells and Development',
        courseCode: '4E03',
        shortName: 'BIOCHEM 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6cf56f56ef638973b90',
      },
      {
        _id: '6073b6d056f56ef638973b92',
        courseName: 'BIOCHEM 4F09 A/B - Senior Thesis',
        courseCode: '4F09',
        shortName: 'BIOCHEM 4F09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b92',
      },
      {
        _id: '6073b6d056f56ef638973b94',
        courseName: 'BIOCHEM 4H03 - Biotechnology and Drug Discovery',
        courseCode: '4H03',
        shortName: 'BIOCHEM 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b94',
      },
      {
        _id: '6073b6d056f56ef638973b96',
        courseName: 'BIOCHEM 4J03 - Immunological Principles in Practice',
        courseCode: '4J03',
        shortName: 'BIOCHEM 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b96',
      },
      {
        _id: '6073b6d056f56ef638973b98',
        courseName: 'BIOCHEM 4M03 - Cellular and Integrated Metabolism',
        courseCode: '4M03',
        shortName: 'BIOCHEM 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b98',
      },
      {
        _id: '6073b6d056f56ef638973b9a',
        courseName: 'BIOCHEM 4N03 - Molecular Membrane Biology',
        courseCode: '4N03',
        shortName: 'BIOCHEM 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b9a',
      },
      {
        _id: '6073b6d056f56ef638973b9c',
        courseName: 'BIOCHEM 4Q03 - Biochemical Pharmacology',
        courseCode: '4Q03',
        shortName: 'BIOCHEM 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b9c',
      },
      {
        _id: '6073b6d056f56ef638973b9e',
        courseName: 'BIOCHEM 4S03 - Introduction to Molecular Biophysics',
        courseCode: '4S03',
        shortName: 'BIOCHEM 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973b9e',
      },
      {
        _id: '6073b6d056f56ef638973ba0',
        courseName: 'BIOCHEM 4T15 A/B - Senior Thesis',
        courseCode: '4T15',
        shortName: 'BIOCHEM 4T15 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973ba0',
      },
      {
        _id: '6073b6d056f56ef638973ba2',
        courseName: 'BIOCHEM 4Z03 - Senior Project',
        courseCode: '4Z03',
        shortName: 'BIOCHEM 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973ba2',
      },
      {
        _id: '6073b6d056f56ef638973ba4',
        courseName: 'BIOLOGY 1A03 - Cellular and Molecular Biology',
        courseCode: '1A03',
        shortName: 'BIOLOGY 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973ba4',
      },
      {
        _id: '6073b6d056f56ef638973ba6',
        courseName: 'BIOLOGY 1P03 - Introductory Biology',
        courseCode: '1P03',
        shortName: 'BIOLOGY 1P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973ba6',
      },
      {
        _id: '6073b6d056f56ef638973ba8',
        courseName: 'BIOLOGY 2A03 - Integrative Physiology of Animals',
        courseCode: '2A03',
        shortName: 'BIOLOGY 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d056f56ef638973ba8',
      },
      {
        _id: '6073b6d156f56ef638973baa',
        courseName: 'BIOLOGY 2B03 - Cell Biology',
        courseCode: '2B03',
        shortName: 'BIOLOGY 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973baa',
      },
      {
        _id: '6073b6d156f56ef638973bac',
        courseName: 'BIOLOGY 2C03 - Genetics',
        courseCode: '2C03',
        shortName: 'BIOLOGY 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bac',
      },
      {
        _id: '6073b6d156f56ef638973bae',
        courseName: 'BIOLOGY 2D03 - Plant Biodiversity and Biotechnology',
        courseCode: '2D03',
        shortName: 'BIOLOGY 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bae',
      },
      {
        _id: '6073b6d156f56ef638973bb0',
        courseName:
          'BIOLOGY 2EE3 - Introduction to Microbiology and Biotechnology',
        courseCode: '2EE3',
        shortName: 'BIOLOGY 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bb0',
      },
      {
        _id: '6073b6d156f56ef638973bb2',
        courseName: 'BIOLOGY 2F03 - Fundamental and Applied Ecology',
        courseCode: '2F03',
        shortName: 'BIOLOGY 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bb2',
      },
      {
        _id: '6073b6d156f56ef638973bb4',
        courseName: 'BIOLOGY 2L03 - Experimental Design in Biology',
        courseCode: '2L03',
        shortName: 'BIOLOGY 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bb4',
      },
      {
        _id: '6073b6d156f56ef638973bb6',
        courseName: 'BIOLOGY 3AA3 - Fundamental Concepts of Pharmacology',
        courseCode: '3AA3',
        shortName: 'BIOLOGY 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bb6',
      },
      {
        _id: '6073b6d156f56ef638973bb8',
        courseName: 'BIOLOGY 3B03 - Plant Physiology',
        courseCode: '3B03',
        shortName: 'BIOLOGY 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bb8',
      },
      {
        _id: '6073b6d156f56ef638973bba',
        courseName: 'BIOLOGY 3DD3 - Communities and Ecosystems',
        courseCode: '3DD3',
        shortName: 'BIOLOGY 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bba',
      },
      {
        _id: '6073b6d156f56ef638973bbc',
        courseName: 'BIOLOGY 3EI3 - Ecological Indicators',
        courseCode: '3EI3',
        shortName: 'BIOLOGY 3EI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bbc',
      },
      {
        _id: '6073b6d156f56ef638973bbe',
        courseName: 'BIOLOGY 3EP3 A/B S - Applied Biology Placement',
        courseCode: '3EP3',
        shortName: 'BIOLOGY 3EP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d156f56ef638973bbe',
      },
      {
        _id: '6073b6d256f56ef638973bc0',
        courseName: 'BIOLOGY 3ET3 - Ecotoxicology',
        courseCode: '3ET3',
        shortName: 'BIOLOGY 3ET3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bc0',
      },
      {
        _id: '6073b6d256f56ef638973bc2',
        courseName: 'BIOLOGY 3FF3 - Evolution',
        courseCode: '3FF3',
        shortName: 'BIOLOGY 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bc2',
      },
      {
        _id: '6073b6d256f56ef638973bc4',
        courseName: 'BIOLOGY 3HD3 - Human Disasters',
        courseCode: '3HD3',
        shortName: 'BIOLOGY 3HD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bc4',
      },
      {
        _id: '6073b6d256f56ef638973bc6',
        courseName: 'BIOLOGY 3IR3 A/B S - Independent Research Project',
        courseCode: '3IR3',
        shortName: 'BIOLOGY 3IR3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bc6',
      },
      {
        _id: '6073b6d256f56ef638973bc8',
        courseName: 'BIOLOGY 3JJ3 - Field Methods In Ecology',
        courseCode: '3JJ3',
        shortName: 'BIOLOGY 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bc8',
      },
      {
        _id: '6073b6d256f56ef638973bca',
        courseName: 'BIOLOGY 3MM3 - Invertebrate Form and Function',
        courseCode: '3MM3',
        shortName: 'BIOLOGY 3MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bca',
      },
      {
        _id: '6073b6d256f56ef638973bcc',
        courseName: 'BIOLOGY 3P03 - Cell Physiology',
        courseCode: '3P03',
        shortName: 'BIOLOGY 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bcc',
      },
      {
        _id: '6073b6d256f56ef638973bce',
        courseName: 'BIOLOGY 3PG3 - Population Genetics',
        courseCode: '3PG3',
        shortName: 'BIOLOGY 3PG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bce',
      },
      {
        _id: '6073b6d256f56ef638973bd0',
        courseName: 'BIOLOGY 3R03 - Field Biology I',
        courseCode: '3R03',
        shortName: 'BIOLOGY 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bd0',
      },
      {
        _id: '6073b6d256f56ef638973bd2',
        courseName: 'BIOLOGY 3RF0 - Field Work I',
        courseCode: '3RF0',
        shortName: 'BIOLOGY 3RF0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bd2',
      },
      {
        _id: '6073b6d256f56ef638973bd4',
        courseName: 'BIOLOGY 3S03 - An Introduction to Bioinformatics',
        courseCode: '3S03',
        shortName: 'BIOLOGY 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d256f56ef638973bd4',
      },
      {
        _id: '6073b6d356f56ef638973bd6',
        courseName: 'BIOLOGY 3SA3 - Applied Statistics for Biology',
        courseCode: '3SA3',
        shortName: 'BIOLOGY 3SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973bd6',
      },
      {
        _id: '6073b6d356f56ef638973bd8',
        courseName: 'BIOLOGY 3SS3 - Population Ecology',
        courseCode: '3SS3',
        shortName: 'BIOLOGY 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973bd8',
      },
      {
        _id: '6073b6d356f56ef638973bda',
        courseName: 'BIOLOGY 3U03 - Animal Physiology - Homeostasis',
        courseCode: '3U03',
        shortName: 'BIOLOGY 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973bda',
      },
      {
        _id: '6073b6d356f56ef638973bdc',
        courseName: 'BIOLOGY 3UU3 - Animal Physiology - Regulatory Systems',
        courseCode: '3UU3',
        shortName: 'BIOLOGY 3UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973bdc',
      },
      {
        _id: '6073b6d356f56ef638973bde',
        courseName: 'BIOLOGY 3VV3 - Laboratory Methods in Molecular Biology',
        courseCode: '3VV3',
        shortName: 'BIOLOGY 3VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973bde',
      },
      {
        _id: '6073b6d356f56ef638973be0',
        courseName:
          'BIOLOGY 3XL3 - Comparative Vertebrate Anatomy & Physiology',
        courseCode: '3XL3',
        shortName: 'BIOLOGY 3XL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973be0',
      },
      {
        _id: '6073b6d356f56ef638973be2',
        courseName: 'BIOLOGY 3ZZ3 - Topics in Physiology',
        courseCode: '3ZZ3',
        shortName: 'BIOLOGY 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973be2',
      },
      {
        _id: '6073b6d356f56ef638973be4',
        courseName: 'BIOLOGY 4A03 - Advanced Topics in Ecology',
        courseCode: '4A03',
        shortName: 'BIOLOGY 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973be4',
      },
      {
        _id: '6073b6d356f56ef638973be6',
        courseName: 'BIOLOGY 4AA3 - Conservation Biology',
        courseCode: '4AA3',
        shortName: 'BIOLOGY 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973be6',
      },
      {
        _id: '6073b6d356f56ef638973be8',
        courseName: 'BIOLOGY 4AE3 - The Ecology and Evolution of Organisms',
        courseCode: '4AE3',
        shortName: 'BIOLOGY 4AE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973be8',
      },
      {
        _id: '6073b6d356f56ef638973bea',
        courseName: 'BIOLOGY 4C12 A/B S - Senior Thesis',
        courseCode: '4C12',
        shortName: 'BIOLOGY 4C12 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d356f56ef638973bea',
      },
      {
        _id: '6073b6d456f56ef638973bec',
        courseName: 'BIOLOGY 4ED3 - Evolutionary Developmental Biology',
        courseCode: '4ED3',
        shortName: 'BIOLOGY 4ED3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bec',
      },
      {
        _id: '6073b6d456f56ef638973bee',
        courseName: 'BIOLOGY 4EE3 - Human Diversity and Human Nature',
        courseCode: '4EE3',
        shortName: 'BIOLOGY 4EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bee',
      },
      {
        _id: '6073b6d456f56ef638973bf0',
        courseName: 'BIOLOGY 4F06 A/B S - Senior Project',
        courseCode: '4F06',
        shortName: 'BIOLOGY 4F06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bf0',
      },
      {
        _id: '6073b6d456f56ef638973bf2',
        courseName: 'BIOLOGY 4IR3 - Advanced Independent Research Project',
        courseCode: '4IR3',
        shortName: 'BIOLOGY 4IR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bf2',
      },
      {
        _id: '6073b6d456f56ef638973bf4',
        courseName: 'BIOLOGY 4J03 - Field Biology II',
        courseCode: '4J03',
        shortName: 'BIOLOGY 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bf4',
      },
      {
        _id: '6073b6d456f56ef638973bf6',
        courseName: 'BIOLOGY 4JF0 - Field Work II',
        courseCode: '4JF0',
        shortName: 'BIOLOGY 4JF0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bf6',
      },
      {
        _id: '6073b6d456f56ef638973bf8',
        courseName:
          'BIOLOGY 4PP3 - Environmental Microbiology and Biotechnology',
        courseCode: '4PP3',
        shortName: 'BIOLOGY 4PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bf8',
      },
      {
        _id: '6073b6d456f56ef638973bfa',
        courseName: 'BIOLOGY 4T03 - Molecular and Cellular Neuroscience',
        courseCode: '4T03',
        shortName: 'BIOLOGY 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bfa',
      },
      {
        _id: '6073b6d456f56ef638973bfc',
        courseName: 'BIOLOGY 4X03 - Environmental Physiology',
        courseCode: '4X03',
        shortName: 'BIOLOGY 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bfc',
      },
      {
        _id: '6073b6d456f56ef638973bfe',
        courseName: 'BIOMEDDC 2C03 - Exploring Careers in Biomedical Sciences',
        courseCode: '2C03',
        shortName: 'BIOMEDDC 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973bfe',
      },
      {
        _id: '6073b6d456f56ef638973c00',
        courseName:
          'BIOMEDDC 2W03 - Write Right for Your Science: Scientific Writing for the Biomedical Sciences',
        courseCode: '2W03',
        shortName: 'BIOMEDDC 2W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973c00',
      },
      {
        _id: '6073b6d456f56ef638973c02',
        courseName: 'BIOMEDDC 3A03 - Road to Biomedical Discovery',
        courseCode: '3A03',
        shortName: 'BIOMEDDC 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d456f56ef638973c02',
      },
      {
        _id: '6073b6d556f56ef638973c04',
        courseName: 'BIOMEDDC 3B06 A/B - Drug Discovery and Development',
        courseCode: '3B06',
        shortName: 'BIOMEDDC 3B06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c04',
      },
      {
        _id: '6073b6d556f56ef638973c06',
        courseName:
          'BIOMEDDC 3C09 A/B - Research Skills Laboratory and Inquiry',
        courseCode: '3C09',
        shortName: 'BIOMEDDC 3C09 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c06',
      },
      {
        _id: '6073b6d556f56ef638973c08',
        courseName: 'BIOMEDDC 4A15 A/B - Senior Research Thesis',
        courseCode: '4A15',
        shortName: 'BIOMEDDC 4A15 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c08',
      },
      {
        _id: '6073b6d556f56ef638973c0a',
        courseName: 'BIOMEDDC 4B03 - Road to Biomedical Commercialization',
        courseCode: '4B03',
        shortName: 'BIOMEDDC 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c0a',
      },
      {
        _id: '6073b6d556f56ef638973c0c',
        courseName:
          'BIOPHYS 1S03 - Biophysics of Movement and the Senses: From Microbes to Moose',
        courseCode: '1S03',
        shortName: 'BIOPHYS 1S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c0c',
      },
      {
        _id: '6073b6d556f56ef638973c0e',
        courseName:
          'BIOPHYS 2A03 - Biophysics of the Cell and Living Organisms',
        courseCode: '2A03',
        shortName: 'BIOPHYS 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c0e',
      },
      {
        _id: '6073b6d556f56ef638973c10',
        courseName:
          'BIOPHYS 2S03 - Explorations in Medical and Biological Physics',
        courseCode: '2S03',
        shortName: 'BIOPHYS 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c10',
      },
      {
        _id: '6073b6d556f56ef638973c12',
        courseName: 'BIOPHYS 3D03 - Origin of Life',
        courseCode: '3D03',
        shortName: 'BIOPHYS 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c12',
      },
      {
        _id: '6073b6d556f56ef638973c14',
        courseName: 'BIOPHYS 3G03 - Modelling Life',
        courseCode: '3G03',
        shortName: 'BIOPHYS 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c14',
      },
      {
        _id: '6073b6d556f56ef638973c16',
        courseName: 'BIOPHYS 3S03 - Soft Condensed Matter Physics',
        courseCode: '3S03',
        shortName: 'BIOPHYS 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c16',
      },
      {
        _id: '6073b6d556f56ef638973c18',
        courseName: 'BIOPHYS 4L03 A/B - Literature Review',
        courseCode: '4L03',
        shortName: 'BIOPHYS 4L03 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d556f56ef638973c18',
      },
      {
        _id: '6073b6d656f56ef638973c1a',
        courseName: 'BIOPHYS 4P06 A/B - Senior Research Project',
        courseCode: '4P06',
        shortName: 'BIOPHYS 4P06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c1a',
      },
      {
        _id: '6073b6d656f56ef638973c1c',
        courseName: 'BIOPHYS 4S03 - Introduction to Molecular Biophysics',
        courseCode: '4S03',
        shortName: 'BIOPHYS 4S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c1c',
      },
      {
        _id: '6073b6d656f56ef638973c1e',
        courseName: 'BIOSAFE 1BS0 - Biosafety Training',
        courseCode: '1BS0',
        shortName: 'BIOSAFE 1BS0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c1e',
      },
      {
        _id: '6073b6d656f56ef638973c20',
        courseName: 'BIOTECH 2B03 - Biotechnology Concepts',
        courseCode: '2B03',
        shortName: 'BIOTECH 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c20',
      },
      {
        _id: '6073b6d656f56ef638973c22',
        courseName: 'BIOTECH 2BC3 - Biochemistry',
        courseCode: '2BC3',
        shortName: 'BIOTECH 2BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c22',
      },
      {
        _id: '6073b6d656f56ef638973c24',
        courseName: 'BIOTECH 2CB3 - Cell Biology',
        courseCode: '2CB3',
        shortName: 'BIOTECH 2CB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c24',
      },
      {
        _id: '6073b6d656f56ef638973c26',
        courseName: 'BIOTECH 2EC3 - Chemical Engineering Concepts',
        courseCode: '2EC3',
        shortName: 'BIOTECH 2EC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c26',
      },
      {
        _id: '6073b6d656f56ef638973c28',
        courseName: 'BIOTECH 2GT3 - Genetics',
        courseCode: '2GT3',
        shortName: 'BIOTECH 2GT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c28',
      },
      {
        _id: '6073b6d656f56ef638973c2a',
        courseName: 'BIOTECH 2M03 - Molecular Biology',
        courseCode: '2M03',
        shortName: 'BIOTECH 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c2a',
      },
      {
        _id: '6073b6d656f56ef638973c2c',
        courseName: 'BIOTECH 2MB3 - Microbiology',
        courseCode: '2MB3',
        shortName: 'BIOTECH 2MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c2c',
      },
      {
        _id: '6073b6d656f56ef638973c2e',
        courseName: 'BIOTECH 2OC3 - Organic Chemistry',
        courseCode: '2OC3',
        shortName: 'BIOTECH 2OC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d656f56ef638973c2e',
      },
      {
        _id: '6073b6d756f56ef638973c30',
        courseName: 'BIOTECH 3B03 - Industrial Biotechnology',
        courseCode: '3B03',
        shortName: 'BIOTECH 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c30',
      },
      {
        _id: '6073b6d756f56ef638973c32',
        courseName: 'BIOTECH 3BC3 - Bioprocess Control and Dynamics',
        courseCode: '3BC3',
        shortName: 'BIOTECH 3BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c32',
      },
      {
        _id: '6073b6d756f56ef638973c34',
        courseName: 'BIOTECH 3BP3 - Bioreactor Processes and Design',
        courseCode: '3BP3',
        shortName: 'BIOTECH 3BP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c34',
      },
      {
        _id: '6073b6d756f56ef638973c36',
        courseName:
          'BIOTECH 3CM3 - Computational Modeling of Biological Systems',
        courseCode: '3CM3',
        shortName: 'BIOTECH 3CM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c36',
      },
      {
        _id: '6073b6d756f56ef638973c38',
        courseName: 'BIOTECH 3FM3 - Food Microbiology',
        courseCode: '3FM3',
        shortName: 'BIOTECH 3FM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c38',
      },
      {
        _id: '6073b6d756f56ef638973c3a',
        courseName: 'BIOTECH 3IV3 - Immunology and Virology',
        courseCode: '3IV3',
        shortName: 'BIOTECH 3IV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c3a',
      },
      {
        _id: '6073b6d756f56ef638973c3c',
        courseName: 'BIOTECH 3PM3 - Pharmacology',
        courseCode: '3PM3',
        shortName: 'BIOTECH 3PM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c3c',
      },
      {
        _id: '6073b6d756f56ef638973c3e',
        courseName: 'BIOTECH 4BI3 - Bioinformatics',
        courseCode: '4BI3',
        shortName: 'BIOTECH 4BI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c3e',
      },
      {
        _id: '6073b6d756f56ef638973c40',
        courseName: 'BIOTECH 4BL3 - Biomaterials and Biocompatibility',
        courseCode: '4BL3',
        shortName: 'BIOTECH 4BL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c40',
      },
      {
        _id: '6073b6d756f56ef638973c42',
        courseName: 'BIOTECH 4BM3 - Biopharmaceuticals',
        courseCode: '4BM3',
        shortName: 'BIOTECH 4BM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c42',
      },
      {
        _id: '6073b6d756f56ef638973c44',
        courseName: 'BIOTECH 4BS3 - Biotechnology Regulations',
        courseCode: '4BS3',
        shortName: 'BIOTECH 4BS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d756f56ef638973c44',
      },
      {
        _id: '6073b6d856f56ef638973c46',
        courseName: 'BIOTECH 4GP3 - Genomics and Proteomics',
        courseCode: '4GP3',
        shortName: 'BIOTECH 4GP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c46',
      },
      {
        _id: '6073b6d856f56ef638973c48',
        courseName: 'BIOTECH 4TB3 - Advanced Biotechnology',
        courseCode: '4TB3',
        shortName: 'BIOTECH 4TB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c48',
      },
      {
        _id: '6073b6d856f56ef638973c4a',
        courseName: 'BIOTECH 4TR1 - Capstone Project I',
        courseCode: '4TR1',
        shortName: 'BIOTECH 4TR1',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c4a',
      },
      {
        _id: '6073b6d856f56ef638973c4c',
        courseName: 'BIOTECH 4TR3 - Capstone Project II',
        courseCode: '4TR3',
        shortName: 'BIOTECH 4TR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c4c',
      },
      {
        _id: '6073b6d856f56ef638973c4e',
        courseName: 'CAYUGA 1Z03 - Introduction to Cayuga Language and Culture',
        courseCode: '1Z03',
        shortName: 'CAYUGA 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c4e',
      },
      {
        _id: '6073b6d856f56ef638973c50',
        courseName: 'CAYUGA 2Z03 - Intermediate Cayuga',
        courseCode: '2Z03',
        shortName: 'CAYUGA 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c50',
      },
      {
        _id: '6073b6d856f56ef638973c52',
        courseName: 'CHEMBIO 2A03 - Introduction to Bio-Analytical Chemistry',
        courseCode: '2A03',
        shortName: 'CHEMBIO 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c52',
      },
      {
        _id: '6073b6d856f56ef638973c54',
        courseName:
          'CHEMBIO 2L03 - Pharmaceutical Chemistry Laboratory I: Biomolecular Interactions',
        courseCode: '2L03',
        shortName: 'CHEMBIO 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c54',
      },
      {
        _id: '6073b6d856f56ef638973c56',
        courseName: 'CHEMBIO 2OD3 - Organic Chemistry II',
        courseCode: '2OD3',
        shortName: 'CHEMBIO 2OD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c56',
      },
      {
        _id: '6073b6d856f56ef638973c58',
        courseName: 'CHEMBIO 2OG3 - Organic Chemistry I',
        courseCode: '2OG3',
        shortName: 'CHEMBIO 2OG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c58',
      },
      {
        _id: '6073b6d856f56ef638973c5a',
        courseName:
          'CHEMBIO 2P03 - Physical Chemistry Tools for Chemical Biology',
        courseCode: '2P03',
        shortName: 'CHEMBIO 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d856f56ef638973c5a',
      },
      {
        _id: '6073b6d956f56ef638973c5c',
        courseName: 'CHEMBIO 2Q03 - Inquiry for Chemical Biology',
        courseCode: '2Q03',
        shortName: 'CHEMBIO 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c5c',
      },
      {
        _id: '6073b6d956f56ef638973c5e',
        courseName: 'CHEMBIO 3AA3 - Analytical Tools for Chemical Biology',
        courseCode: '3AA3',
        shortName: 'CHEMBIO 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c5e',
      },
      {
        _id: '6073b6d956f56ef638973c60',
        courseName: 'CHEMBIO 3BM3 - Implanted Biomaterials',
        courseCode: '3BM3',
        shortName: 'CHEMBIO 3BM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c60',
      },
      {
        _id: '6073b6d956f56ef638973c62',
        courseName: 'CHEMBIO 3EP3 A/B S - Advanced Chemical Biology Placement',
        courseCode: '3EP3',
        shortName: 'CHEMBIO 3EP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c62',
      },
      {
        _id: '6073b6d956f56ef638973c64',
        courseName: 'CHEMBIO 3L03 - Chemical Biology Laboratory II',
        courseCode: '3L03',
        shortName: 'CHEMBIO 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c64',
      },
      {
        _id: '6073b6d956f56ef638973c66',
        courseName:
          'CHEMBIO 3OA3 - Organic Mechanistic Tools for Chemical Biology',
        courseCode: '3OA3',
        shortName: 'CHEMBIO 3OA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c66',
      },
      {
        _id: '6073b6d956f56ef638973c68',
        courseName:
          'CHEMBIO 3OB3 - Structural Elucidation of Natural Products and Small Molecules',
        courseCode: '3OB3',
        shortName: 'CHEMBIO 3OB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c68',
      },
      {
        _id: '6073b6d956f56ef638973c6a',
        courseName: 'CHEMBIO 3P03 - Biomolecular Interactions and Kinetics',
        courseCode: '3P03',
        shortName: 'CHEMBIO 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c6a',
      },
      {
        _id: '6073b6d956f56ef638973c6c',
        courseName: 'CHEMBIO 3RP3 - Research Practicum in Chemical Biology',
        courseCode: '3RP3',
        shortName: 'CHEMBIO 3RP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c6c',
      },
      {
        _id: '6073b6d956f56ef638973c6e',
        courseName:
          'CHEMBIO 4A03 - Bio-Analytical Chemistry and Assay Development',
        courseCode: '4A03',
        shortName: 'CHEMBIO 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c6e',
      },
      {
        _id: '6073b6d956f56ef638973c70',
        courseName: 'CHEMBIO 4G12 A/B - Senior Thesis in Chemical Biology',
        courseCode: '4G12',
        shortName: 'CHEMBIO 4G12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c70',
      },
      {
        _id: '6073b6d956f56ef638973c72',
        courseName: 'CHEMBIO 4IB3 - Bio-Inorganic Chemistry',
        courseCode: '4IB3',
        shortName: 'CHEMBIO 4IB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6d956f56ef638973c72',
      },
      {
        _id: '6073b6da56f56ef638973c74',
        courseName: 'CHEMBIO 4OA3 - Natural Products',
        courseCode: '4OA3',
        shortName: 'CHEMBIO 4OA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c74',
      },
      {
        _id: '6073b6da56f56ef638973c76',
        courseName:
          'CHEMBIO 4OB3 - Medicinal Chemistry: Drug Design and Development',
        courseCode: '4OB3',
        shortName: 'CHEMBIO 4OB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c76',
      },
      {
        _id: '6073b6da56f56ef638973c78',
        courseName:
          'CHEMBIO 4Q03 - Peer Tutoring in Chemical Biology or Chemistry',
        courseCode: '4Q03',
        shortName: 'CHEMBIO 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c78',
      },
      {
        _id: '6073b6da56f56ef638973c7a',
        courseName: 'CHEMBIO 4RP6 A/B S - Research Project in Chemical Biology',
        courseCode: '4RP6',
        shortName: 'CHEMBIO 4RP6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c7a',
      },
      {
        _id: '6073b6da56f56ef638973c7c',
        courseName: 'CHEMENG 2D04 - Chemical Engineering Principles I',
        courseCode: '2D04',
        shortName: 'CHEMENG 2D04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c7c',
      },
      {
        _id: '6073b6da56f56ef638973c7e',
        courseName:
          'CHEMENG 2E04 - Numerical Methods and Computing for Chemical Engineers',
        courseCode: '2E04',
        shortName: 'CHEMENG 2E04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c7e',
      },
      {
        _id: '6073b6da56f56ef638973c80',
        courseName: 'CHEMENG 2F04 - Chemical Engineering Principles II',
        courseCode: '2F04',
        shortName: 'CHEMENG 2F04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c80',
      },
      {
        _id: '6073b6da56f56ef638973c82',
        courseName:
          'CHEMENG 2G03 - Problem Solving and Technical Communication',
        courseCode: '2G03',
        shortName: 'CHEMENG 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c82',
      },
      {
        _id: '6073b6da56f56ef638973c84',
        courseName: 'CHEMENG 2O04 - Fluid Mechanics',
        courseCode: '2O04',
        shortName: 'CHEMENG 2O04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c84',
      },
      {
        _id: '6073b6da56f56ef638973c86',
        courseName: 'CHEMENG 3A04 - Heat Transfer',
        courseCode: '3A04',
        shortName: 'CHEMENG 3A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c86',
      },
      {
        _id: '6073b6da56f56ef638973c88',
        courseName: 'CHEMENG 3BK3 - Bio-Reaction Engineering',
        courseCode: '3BK3',
        shortName: 'CHEMENG 3BK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c88',
      },
      {
        _id: '6073b6da56f56ef638973c8a',
        courseName: 'CHEMENG 3BM3 - Bioseparations Engineering',
        courseCode: '3BM3',
        shortName: 'CHEMENG 3BM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6da56f56ef638973c8a',
      },
      {
        _id: '6073b6db56f56ef638973c8c',
        courseName: 'CHEMENG 3D04 - Chemical Engineering Thermodynamics',
        courseCode: '3D04',
        shortName: 'CHEMENG 3D04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c8c',
      },
      {
        _id: '6073b6db56f56ef638973c8e',
        courseName: 'CHEMENG 3G04 - Chemical Process Design and Simulation',
        courseCode: '3G04',
        shortName: 'CHEMENG 3G04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c8e',
      },
      {
        _id: '6073b6db56f56ef638973c90',
        courseName: 'CHEMENG 3I03 - Data Acquisition and Analysis',
        courseCode: '3I03',
        shortName: 'CHEMENG 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c90',
      },
      {
        _id: '6073b6db56f56ef638973c92',
        courseName: 'CHEMENG 3K04 - Introduction to Reactor Design',
        courseCode: '3K04',
        shortName: 'CHEMENG 3K04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c92',
      },
      {
        _id: '6073b6db56f56ef638973c94',
        courseName: 'CHEMENG 3L02 - Intermediate Laboratory Skills',
        courseCode: '3L02',
        shortName: 'CHEMENG 3L02',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c94',
      },
      {
        _id: '6073b6db56f56ef638973c96',
        courseName: 'CHEMENG 3M04 - Mass Transfer and Stagewise Operations',
        courseCode: '3M04',
        shortName: 'CHEMENG 3M04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c96',
      },
      {
        _id: '6073b6db56f56ef638973c98',
        courseName: 'CHEMENG 3P04 - Process Control',
        courseCode: '3P04',
        shortName: 'CHEMENG 3P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c98',
      },
      {
        _id: '6073b6db56f56ef638973c9a',
        courseName: 'CHEMENG 3Q03 - Introduction to Polymer Science',
        courseCode: '3Q03',
        shortName: 'CHEMENG 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c9a',
      },
      {
        _id: '6073b6db56f56ef638973c9c',
        courseName: 'CHEMENG 4A03 - Energy Systems Engineering',
        courseCode: '4A03',
        shortName: 'CHEMENG 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c9c',
      },
      {
        _id: '6073b6db56f56ef638973c9e',
        courseName: 'CHEMENG 4B03 - Polymer Reaction Engineering',
        courseCode: '4B03',
        shortName: 'CHEMENG 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973c9e',
      },
      {
        _id: '6073b6db56f56ef638973ca0',
        courseName: 'CHEMENG 4E03 - Digital Computer Process Control',
        courseCode: '4E03',
        shortName: 'CHEMENG 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6db56f56ef638973ca0',
      },
      {
        _id: '6073b6dc56f56ef638973ca2',
        courseName: 'CHEMENG 4G03 - Optimization in Chemical Engineering',
        courseCode: '4G03',
        shortName: 'CHEMENG 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973ca2',
      },
      {
        _id: '6073b6dc56f56ef638973ca4',
        courseName:
          'CHEMENG 4H03 - Big Data Methods and Modeling in Chemical and Materials Engineering',
        courseCode: '4H03',
        shortName: 'CHEMENG 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973ca4',
      },
      {
        _id: '6073b6dc56f56ef638973ca6',
        courseName: 'CHEMENG 4K03 - Reactor Design for Heterogeneous Systems',
        courseCode: '4K03',
        shortName: 'CHEMENG 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973ca6',
      },
      {
        _id: '6073b6dc56f56ef638973ca8',
        courseName: 'CHEMENG 4L02 - Advanced Laboratory Skills',
        courseCode: '4L02',
        shortName: 'CHEMENG 4L02',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973ca8',
      },
      {
        _id: '6073b6dc56f56ef638973caa',
        courseName: 'CHEMENG 4M03 - Industrial Separation Processes',
        courseCode: '4M03',
        shortName: 'CHEMENG 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973caa',
      },
      {
        _id: '6073b6dc56f56ef638973cac',
        courseName: 'CHEMENG 4N04 - Engineering Economics and Problem Solving',
        courseCode: '4N04',
        shortName: 'CHEMENG 4N04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cac',
      },
      {
        _id: '6073b6dc56f56ef638973cae',
        courseName:
          'CHEMENG 4T03 - Applications of Chemical Engineering in Medicine',
        courseCode: '4T03',
        shortName: 'CHEMENG 4T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cae',
      },
      {
        _id: '6073b6dc56f56ef638973cb0',
        courseName: 'CHEMENG 4TA3 A/B - Engineering Practicum',
        courseCode: '4TA3',
        shortName: 'CHEMENG 4TA3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cb0',
      },
      {
        _id: '6073b6dc56f56ef638973cb2',
        courseName: 'CHEMENG 4W04 - Chemical Plant Design and Capstone Project',
        courseCode: '4W04',
        shortName: 'CHEMENG 4W04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cb2',
      },
      {
        _id: '6073b6dc56f56ef638973cb4',
        courseName: 'CHEMENG 4X03 - Polymer Processing',
        courseCode: '4X03',
        shortName: 'CHEMENG 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cb4',
      },
      {
        _id: '6073b6dc56f56ef638973cb6',
        courseName: 'CHEMENG 4Y04 A/B - Senior Independent Project',
        courseCode: '4Y04',
        shortName: 'CHEMENG 4Y04 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cb6',
      },
      {
        _id: '6073b6dc56f56ef638973cb8',
        courseName: 'CHEMENG 4Z03 - Interfacial Engineering',
        courseCode: '4Z03',
        shortName: 'CHEMENG 4Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dc56f56ef638973cb8',
      },
      {
        _id: '6073b6dd56f56ef638973cba',
        courseName: 'CHEM 1R03 - General Chemistry',
        courseCode: '1R03',
        shortName: 'CHEM 1R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cba',
      },
      {
        _id: '6073b6dd56f56ef638973cbc',
        courseName: 'CHEM 2A03 - Quantitative Chemical Analysis',
        courseCode: '2A03',
        shortName: 'CHEM 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cbc',
      },
      {
        _id: '6073b6dd56f56ef638973cbe',
        courseName: 'CHEM 2BC3 - Making Chemistry More Sustainable',
        courseCode: '2BC3',
        shortName: 'CHEM 2BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cbe',
      },
      {
        _id: '6073b6dd56f56ef638973cc0',
        courseName: 'CHEM 2E03 - Introductory Organic Chemistry',
        courseCode: '2E03',
        shortName: 'CHEM 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cc0',
      },
      {
        _id: '6073b6dd56f56ef638973cc2',
        courseName:
          'CHEM 2II3 - Introductory Inorganic Chemistry: Structure and Bonding',
        courseCode: '2II3',
        shortName: 'CHEM 2II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cc2',
      },
      {
        _id: '6073b6dd56f56ef638973cc4',
        courseName: 'CHEM 2LB3 - Tools for Chemical Discovery',
        courseCode: '2LB3',
        shortName: 'CHEM 2LB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cc4',
      },
      {
        _id: '6073b6dd56f56ef638973cc6',
        courseName: 'CHEM 2OA3 - Organic Chemistry I',
        courseCode: '2OA3',
        shortName: 'CHEM 2OA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cc6',
      },
      {
        _id: '6073b6dd56f56ef638973cc8',
        courseName: 'CHEM 2OB3 - Organic Chemistry II',
        courseCode: '2OB3',
        shortName: 'CHEM 2OB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cc8',
      },
      {
        _id: '6073b6dd56f56ef638973cca',
        courseName: 'CHEM 2OD3 - Synthesis and Function of Organic Molecules',
        courseCode: '2OD3',
        shortName: 'CHEM 2OD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cca',
      },
      {
        _id: '6073b6dd56f56ef638973ccc',
        courseName: 'CHEM 2OG3 - Structure and Reactivity of Organic Molecules',
        courseCode: '2OG3',
        shortName: 'CHEM 2OG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973ccc',
      },
      {
        _id: '6073b6dd56f56ef638973cce',
        courseName: 'CHEM 2P03 - Applications of Physical Chemistry',
        courseCode: '2P03',
        shortName: 'CHEM 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6dd56f56ef638973cce',
      },
      {
        _id: '6073b6de56f56ef638973cd0',
        courseName: 'CHEM 2Q03 - Inquiry for Chemistry',
        courseCode: '2Q03',
        shortName: 'CHEM 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cd0',
      },
      {
        _id: '6073b6de56f56ef638973cd2',
        courseName: 'CHEM 2SC3 - Sustainable Chemistry - Green Chemistry',
        courseCode: '2SC3',
        shortName: 'CHEM 2SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cd2',
      },
      {
        _id: '6073b6de56f56ef638973cd4',
        courseName: 'CHEM 3AA3 - Instrumental Analysis',
        courseCode: '3AA3',
        shortName: 'CHEM 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cd4',
      },
      {
        _id: '6073b6de56f56ef638973cd6',
        courseName: 'CHEM 3EP3 A/B S - Advanced Chemistry Placement',
        courseCode: '3EP3',
        shortName: 'CHEM 3EP3 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cd6',
      },
      {
        _id: '6073b6de56f56ef638973cd8',
        courseName: 'CHEM 3I03 - Industrial Chemistry',
        courseCode: '3I03',
        shortName: 'CHEM 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cd8',
      },
      {
        _id: '6073b6de56f56ef638973cda',
        courseName: 'CHEM 3II3 - Introduction to Transition Metal Chemistry',
        courseCode: '3II3',
        shortName: 'CHEM 3II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cda',
      },
      {
        _id: '6073b6de56f56ef638973cdc',
        courseName: 'CHEM 3LA3 - Strategies for Chemical Discovery',
        courseCode: '3LA3',
        shortName: 'CHEM 3LA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cdc',
      },
      {
        _id: '6073b6de56f56ef638973cde',
        courseName: 'CHEM 3OA3 - Organic Synthesis',
        courseCode: '3OA3',
        shortName: 'CHEM 3OA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973cde',
      },
      {
        _id: '6073b6de56f56ef638973ce0',
        courseName: 'CHEM 3PA3 - Quantum Mechanics and Spectroscopy',
        courseCode: '3PA3',
        shortName: 'CHEM 3PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973ce0',
      },
      {
        _id: '6073b6de56f56ef638973ce2',
        courseName: 'CHEM 3PC3 - Mathematical Tools for Chemical Problems',
        courseCode: '3PC3',
        shortName: 'CHEM 3PC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973ce2',
      },
      {
        _id: '6073b6de56f56ef638973ce4',
        courseName: 'CHEM 3RC3 - Radioisotopes in Medicine',
        courseCode: '3RC3',
        shortName: 'CHEM 3RC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6de56f56ef638973ce4',
      },
      {
        _id: '6073b6df56f56ef638973ce6',
        courseName: 'CHEM 3RP3 - Research Practicum in Chemistry',
        courseCode: '3RP3',
        shortName: 'CHEM 3RP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973ce6',
      },
      {
        _id: '6073b6df56f56ef638973ce8',
        courseName:
          'CHEM 3SC3 - Sustainable Chemistry - Natural Resources and Energy',
        courseCode: '3SC3',
        shortName: 'CHEM 3SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973ce8',
      },
      {
        _id: '6073b6df56f56ef638973cea',
        courseName: 'CHEM 4AA3 - Recent Advances in Analytical Chemistry',
        courseCode: '4AA3',
        shortName: 'CHEM 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cea',
      },
      {
        _id: '6073b6df56f56ef638973cec',
        courseName: 'CHEM 4D03 - Organic Structure and Synthesis',
        courseCode: '4D03',
        shortName: 'CHEM 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cec',
      },
      {
        _id: '6073b6df56f56ef638973cee',
        courseName: 'CHEM 4G12 A/B - Senior Thesis',
        courseCode: '4G12',
        shortName: 'CHEM 4G12 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cee',
      },
      {
        _id: '6073b6df56f56ef638973cf0',
        courseName:
          'CHEM 4IA3 - Physical Methods of Inorganic Structure Determination',
        courseCode: '4IA3',
        shortName: 'CHEM 4IA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cf0',
      },
      {
        _id: '6073b6df56f56ef638973cf2',
        courseName: 'CHEM 4IB3 - Bio-Inorganic Chemistry',
        courseCode: '4IB3',
        shortName: 'CHEM 4IB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cf2',
      },
      {
        _id: '6073b6df56f56ef638973cf4',
        courseName:
          'CHEM 4IC3 - Solid State Inorganic Materials: Structures, Properties, Characterization and Applications',
        courseCode: '4IC3',
        shortName: 'CHEM 4IC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cf4',
      },
      {
        _id: '6073b6df56f56ef638973cf6',
        courseName:
          'CHEM 4II3 - Transition Metal Organometallic Chemistry and Catalysis',
        courseCode: '4II3',
        shortName: 'CHEM 4II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cf6',
      },
      {
        _id: '6073b6df56f56ef638973cf8',
        courseName: 'CHEM 4OA3 - Natural Products',
        courseCode: '4OA3',
        shortName: 'CHEM 4OA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cf8',
      },
      {
        _id: '6073b6df56f56ef638973cfa',
        courseName: 'CHEM 4OB3 - Polymers and Organic Materials',
        courseCode: '4OB3',
        shortName: 'CHEM 4OB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6df56f56ef638973cfa',
      },
      {
        _id: '6073b6e056f56ef638973cfc',
        courseName:
          'CHEM 4PB3 - Computational Models for Electronic Structure and Chemical Bonding',
        courseCode: '4PB3',
        shortName: 'CHEM 4PB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973cfc',
      },
      {
        _id: '6073b6e056f56ef638973cfe',
        courseName: 'CHEM 4RP6 A/B S - Research Project in Chemistry',
        courseCode: '4RP6',
        shortName: 'CHEM 4RP6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973cfe',
      },
      {
        _id: '6073b6e056f56ef638973d00',
        courseName:
          'CHEM 4SC3 - Sustainable Chemistry - Analysis and Regulation',
        courseCode: '4SC3',
        shortName: 'CHEM 4SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d00',
      },
      {
        _id: '6073b6e056f56ef638973d02',
        courseName: 'CHEM 4W03 - Natural and Synthetic Materials',
        courseCode: '4W03',
        shortName: 'CHEM 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d02',
      },
      {
        _id: '6073b6e056f56ef638973d04',
        courseName: 'CHINESE 1Z06 A/B - Mandarin Chinese for Beginners',
        courseCode: '1Z06',
        shortName: 'CHINESE 1Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d04',
      },
      {
        _id: '6073b6e056f56ef638973d06',
        courseName: 'CIVTECH 3GE3 - Geotechnical Materials and Analysis',
        courseCode: '3GE3',
        shortName: 'CIVTECH 3GE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d06',
      },
      {
        _id: '6073b6e056f56ef638973d08',
        courseName: 'CIVTECH 3GT3 - Geotechnical Design',
        courseCode: '3GT3',
        shortName: 'CIVTECH 3GT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d08',
      },
      {
        _id: '6073b6e056f56ef638973d0a',
        courseName:
          'CIVTECH 3PM3 - Highway Design, Construction, and Maintenance',
        courseCode: '3PM3',
        shortName: 'CIVTECH 3PM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d0a',
      },
      {
        _id: '6073b6e056f56ef638973d0c',
        courseName: 'CIVTECH 3SA3 - Structural Analysis',
        courseCode: '3SA3',
        shortName: 'CIVTECH 3SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d0c',
      },
      {
        _id: '6073b6e056f56ef638973d0e',
        courseName: 'CIVTECH 3TP3 - Transportation Planning and Modelling',
        courseCode: '3TP3',
        shortName: 'CIVTECH 3TP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d0e',
      },
      {
        _id: '6073b6e056f56ef638973d10',
        courseName: 'CIVTECH 4BC3 - Building Science',
        courseCode: '4BC3',
        shortName: 'CIVTECH 4BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e056f56ef638973d10',
      },
      {
        _id: '6073b6e156f56ef638973d12',
        courseName: 'CIVTECH 4BD3 - Bridge Design, Maintenance and Repair',
        courseCode: '4BD3',
        shortName: 'CIVTECH 4BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d12',
      },
      {
        _id: '6073b6e156f56ef638973d14',
        courseName: 'CIVTECH 4LU3 - Advanced Land Use Planning',
        courseCode: '4LU3',
        shortName: 'CIVTECH 4LU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d14',
      },
      {
        _id: '6073b6e156f56ef638973d16',
        courseName: 'CIVTECH 4MH3 - Hydraulic Engineering',
        courseCode: '4MH3',
        shortName: 'CIVTECH 4MH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d16',
      },
      {
        _id: '6073b6e156f56ef638973d18',
        courseName: 'CIVTECH 4RC3 - Reinforced Concrete and Masonry Design',
        courseCode: '4RC3',
        shortName: 'CIVTECH 4RC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d18',
      },
      {
        _id: '6073b6e156f56ef638973d1a',
        courseName: 'CIVTECH 4SD3 - Structural Steel Design',
        courseCode: '4SD3',
        shortName: 'CIVTECH 4SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d1a',
      },
      {
        _id: '6073b6e156f56ef638973d1c',
        courseName: 'CIVTECH 4UM3 - Asset Management',
        courseCode: '4UM3',
        shortName: 'CIVTECH 4UM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d1c',
      },
      {
        _id: '6073b6e156f56ef638973d1e',
        courseName: 'CIVTECH 4WT3 - Municipal and Environmental Engineering',
        courseCode: '4WT3',
        shortName: 'CIVTECH 4WT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d1e',
      },
      {
        _id: '6073b6e156f56ef638973d20',
        courseName:
          'CIVENG 2A03 - Surveying and Measurement for Transportation and Construction',
        courseCode: '2A03',
        shortName: 'CIVENG 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d20',
      },
      {
        _id: '6073b6e156f56ef638973d22',
        courseName: 'CIVENG 2B04 - Principles of Environmental Engineering',
        courseCode: '2B04',
        shortName: 'CIVENG 2B04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d22',
      },
      {
        _id: '6073b6e156f56ef638973d24',
        courseName: 'CIVENG 2C04 - Structural Mechanics',
        courseCode: '2C04',
        shortName: 'CIVENG 2C04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d24',
      },
      {
        _id: '6073b6e156f56ef638973d26',
        courseName: 'CIVENG 2E03 - Computer Applications in Civil Engineering',
        courseCode: '2E03',
        shortName: 'CIVENG 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d26',
      },
      {
        _id: '6073b6e156f56ef638973d28',
        courseName: 'CIVENG 2I03 - Communications in Civil Engineering',
        courseCode: '2I03',
        shortName: 'CIVENG 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e156f56ef638973d28',
      },
      {
        _id: '6073b6e256f56ef638973d2a',
        courseName:
          'CIVENG 2J04 - Principles of Geological and Geo-Environmental Engineering',
        courseCode: '2J04',
        shortName: 'CIVENG 2J04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d2a',
      },
      {
        _id: '6073b6e256f56ef638973d2c',
        courseName: 'CIVENG 2O04 - Fluid Mechanics',
        courseCode: '2O04',
        shortName: 'CIVENG 2O04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d2c',
      },
      {
        _id: '6073b6e256f56ef638973d2e',
        courseName: 'CIVENG 2P04 - Statics and Mechanics of Materials',
        courseCode: '2P04',
        shortName: 'CIVENG 2P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d2e',
      },
      {
        _id: '6073b6e256f56ef638973d30',
        courseName: 'CIVENG 2Q03 - Engineering Mechanics: Dynamics',
        courseCode: '2Q03',
        shortName: 'CIVENG 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d30',
      },
      {
        _id: '6073b6e256f56ef638973d32',
        courseName: 'CIVENG 3A03 - Geotechnical Engineering I',
        courseCode: '3A03',
        shortName: 'CIVENG 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d32',
      },
      {
        _id: '6073b6e256f56ef638973d34',
        courseName: 'CIVENG 3B03 - Geotechnical Engineering II',
        courseCode: '3B03',
        shortName: 'CIVENG 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d34',
      },
      {
        _id: '6073b6e256f56ef638973d36',
        courseName: 'CIVENG 3C03 - Engineering Systems',
        courseCode: '3C03',
        shortName: 'CIVENG 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d36',
      },
      {
        _id: '6073b6e256f56ef638973d38',
        courseName: 'CIVENG 3G04 - Structural Analysis',
        courseCode: '3G04',
        shortName: 'CIVENG 3G04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d38',
      },
      {
        _id: '6073b6e256f56ef638973d3a',
        courseName: 'CIVENG 3J04 - Reinforced Concrete Design',
        courseCode: '3J04',
        shortName: 'CIVENG 3J04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d3a',
      },
      {
        _id: '6073b6e256f56ef638973d3c',
        courseName: 'CIVENG 3K03 - Introduction to Transportation Engineering',
        courseCode: '3K03',
        shortName: 'CIVENG 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d3c',
      },
      {
        _id: '6073b6e256f56ef638973d3e',
        courseName: 'CIVENG 3L03 - Water Quality',
        courseCode: '3L03',
        shortName: 'CIVENG 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d3e',
      },
      {
        _id: '6073b6e256f56ef638973d40',
        courseName: 'CIVENG 3M03 - Municipal Hydraulics',
        courseCode: '3M03',
        shortName: 'CIVENG 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e256f56ef638973d40',
      },
      {
        _id: '6073b6e356f56ef638973d42',
        courseName: 'CIVENG 3P04 - Civil Engineering Materials and Design',
        courseCode: '3P04',
        shortName: 'CIVENG 3P04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d42',
      },
      {
        _id: '6073b6e356f56ef638973d44',
        courseName:
          'CIVENG 3RR3 - Engineering Economics and Project Management',
        courseCode: '3RR3',
        shortName: 'CIVENG 3RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d44',
      },
      {
        _id: '6073b6e356f56ef638973d46',
        courseName: 'CIVENG 4A04 - Engineering Hydrology',
        courseCode: '4A04',
        shortName: 'CIVENG 4A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d46',
      },
      {
        _id: '6073b6e356f56ef638973d48',
        courseName: 'CIVENG 4BP4 - Building Science',
        courseCode: '4BP4',
        shortName: 'CIVENG 4BP4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d48',
      },
      {
        _id: '6073b6e356f56ef638973d4a',
        courseName:
          'CIVENG 4CM4 - Advanced Construction Engineering and Management',
        courseCode: '4CM4',
        shortName: 'CIVENG 4CM4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d4a',
      },
      {
        _id: '6073b6e356f56ef638973d4c',
        courseName: 'CIVENG 4DD4 - Structural Dynamics and Seismic Design',
        courseCode: '4DD4',
        shortName: 'CIVENG 4DD4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d4c',
      },
      {
        _id: '6073b6e356f56ef638973d4e',
        courseName: 'CIVENG 4G04 - Pavement Materials and Design',
        courseCode: '4G04',
        shortName: 'CIVENG 4G04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d4e',
      },
      {
        _id: '6073b6e356f56ef638973d50',
        courseName: 'CIVENG 4K04 - Modern Methods of Structural Analysis',
        courseCode: '4K04',
        shortName: 'CIVENG 4K04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d50',
      },
      {
        _id: '6073b6e356f56ef638973d52',
        courseName: 'CIVENG 4L04 - Design of Water Resources Systems',
        courseCode: '4L04',
        shortName: 'CIVENG 4L04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d52',
      },
      {
        _id: '6073b6e356f56ef638973d54',
        courseName: 'CIVENG 4N04 - Steel Structures',
        courseCode: '4N04',
        shortName: 'CIVENG 4N04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d54',
      },
      {
        _id: '6073b6e356f56ef638973d56',
        courseName: 'CIVENG 4S04 - Foundation Engineering',
        courseCode: '4S04',
        shortName: 'CIVENG 4S04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d56',
      },
      {
        _id: '6073b6e356f56ef638973d58',
        courseName:
          'CIVENG 4T04 - Transit Modelling and Intelligent Transportation Systems',
        courseCode: '4T04',
        shortName: 'CIVENG 4T04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e356f56ef638973d58',
      },
      {
        _id: '6073b6e456f56ef638973d5a',
        courseName: 'CIVENG 4TA4 - Traffic Analysis: Theory and Simulation',
        courseCode: '4TA4',
        shortName: 'CIVENG 4TA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d5a',
      },
      {
        _id: '6073b6e456f56ef638973d5c',
        courseName: 'CIVENG 4V04 - Biological Aspects of Wastewater Treatment',
        courseCode: '4V04',
        shortName: 'CIVENG 4V04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d5c',
      },
      {
        _id: '6073b6e456f56ef638973d5e',
        courseName: 'CIVENG 4W04 - Design of Low Rise Buildings',
        courseCode: '4W04',
        shortName: 'CIVENG 4W04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d5e',
      },
      {
        _id: '6073b6e456f56ef638973d60',
        courseName:
          'CIVENG 4X06 A/B - Design and Synthesis Project in Civil Engineering',
        courseCode: '4X06',
        shortName: 'CIVENG 4X06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d60',
      },
      {
        _id: '6073b6e456f56ef638973d62',
        courseName: 'CIVENG 4Y04 - Bridges and Other Structural Systems',
        courseCode: '4Y04',
        shortName: 'CIVENG 4Y04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d62',
      },
      {
        _id: '6073b6e456f56ef638973d64',
        courseName: 'CIVENG 4Z04 A/B S - Independent Study',
        courseCode: '4Z04',
        shortName: 'CIVENG 4Z04 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d64',
      },
      {
        _id: '6073b6e456f56ef638973d66',
        courseName: 'COLLAB 1AS3 - Aging and Society',
        courseCode: '1AS3',
        shortName: 'COLLAB 1AS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d66',
      },
      {
        _id: '6073b6e456f56ef638973d68',
        courseName: 'COLLAB 1F03 - Political Structures and Issues',
        courseCode: '1F03',
        shortName: 'COLLAB 1F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d68',
      },
      {
        _id: '6073b6e456f56ef638973d6a',
        courseName: 'COLLAB 1G03 - Multiculturalism',
        courseCode: '1G03',
        shortName: 'COLLAB 1G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d6a',
      },
      {
        _id: '6073b6e456f56ef638973d6c',
        courseName: 'COLLAB 2A03 - Abnormal Psychology',
        courseCode: '2A03',
        shortName: 'COLLAB 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d6c',
      },
      {
        _id: '6073b6e456f56ef638973d6e',
        courseName: 'COLLAB 2C03 - Sociology I',
        courseCode: '2C03',
        shortName: 'COLLAB 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e456f56ef638973d6e',
      },
      {
        _id: '6073b6e556f56ef638973d70',
        courseName: 'COLLAB 2D03 - Human Sexuality',
        courseCode: '2D03',
        shortName: 'COLLAB 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d70',
      },
      {
        _id: '6073b6e556f56ef638973d72',
        courseName: 'COLLAB 2F03 - Medical Informatics',
        courseCode: '2F03',
        shortName: 'COLLAB 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d72',
      },
      {
        _id: '6073b6e556f56ef638973d74',
        courseName: 'COLLAB 2G03 - Quest for Meaning',
        courseCode: '2G03',
        shortName: 'COLLAB 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d74',
      },
      {
        _id: '6073b6e556f56ef638973d76',
        courseName: 'COLLAB 2I03 - The Uses of Laughter: Comedy and Satire',
        courseCode: '2I03',
        shortName: 'COLLAB 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d76',
      },
      {
        _id: '6073b6e556f56ef638973d78',
        courseName: 'COLLAB 2K03 - Introduction to Health Informatics',
        courseCode: '2K03',
        shortName: 'COLLAB 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d78',
      },
      {
        _id: '6073b6e556f56ef638973d7a',
        courseName:
          'COLLAB 2M03 - Medical Anthropology: Illness and Healthcare in Cross-Cultural Perspective and Social Issues',
        courseCode: '2M03',
        shortName: 'COLLAB 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d7a',
      },
      {
        _id: '6073b6e556f56ef638973d7c',
        courseName: 'COLLAB 2N03 - Organizational Behaviour',
        courseCode: '2N03',
        shortName: 'COLLAB 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d7c',
      },
      {
        _id: '6073b6e556f56ef638973d7e',
        courseName: 'COLLAB 2P03 - Epidemiology in Public Health',
        courseCode: '2P03',
        shortName: 'COLLAB 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d7e',
      },
      {
        _id: '6073b6e556f56ef638973d80',
        courseName: 'COLLAB 2R03 - Epidemiological Methods',
        courseCode: '2R03',
        shortName: 'COLLAB 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d80',
      },
      {
        _id: '6073b6e556f56ef638973d82',
        courseName: 'COLLAB 2T03 - Aging in Place',
        courseCode: '2T03',
        shortName: 'COLLAB 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d82',
      },
      {
        _id: '6073b6e556f56ef638973d84',
        courseName: 'COLLAB 3B03 - Sociology: Diversity and Inequality',
        courseCode: '3B03',
        shortName: 'COLLAB 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d84',
      },
      {
        _id: '6073b6e556f56ef638973d86',
        courseName:
          'COLLAB 3D03 - Illness Narratives in Fiction and Non-Fiction',
        courseCode: '3D03',
        shortName: 'COLLAB 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e556f56ef638973d86',
      },
      {
        _id: '6073b6e656f56ef638973d88',
        courseName: 'COLLAB 3IP3 - Interprofessional Case Management',
        courseCode: '3IP3',
        shortName: 'COLLAB 3IP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d88',
      },
      {
        _id: '6073b6e656f56ef638973d8a',
        courseName: 'COLLAB 4H03 - Introduction to Concepts in Global Health',
        courseCode: '4H03',
        shortName: 'COLLAB 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d8a',
      },
      {
        _id: '6073b6e656f56ef638973d8c',
        courseName: 'COMMERCE 1AA3 - Introductory Financial Accounting',
        courseCode: '1AA3',
        shortName: 'COMMERCE 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d8c',
      },
      {
        _id: '6073b6e656f56ef638973d8e',
        courseName: 'COMMERCE 1B03 - Business Environment & Organization',
        courseCode: '1B03',
        shortName: 'COMMERCE 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d8e',
      },
      {
        _id: '6073b6e656f56ef638973d90',
        courseName: 'COMMERCE 1BA3 - Organizational Behaviour',
        courseCode: '1BA3',
        shortName: 'COMMERCE 1BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d90',
      },
      {
        _id: '6073b6e656f56ef638973d92',
        courseName: 'COMMERCE 1DA3 - Business Data Analytics',
        courseCode: '1DA3',
        shortName: 'COMMERCE 1DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '6073b6e656f56ef638973d92',
      },
      {
        _id: '6073b6e656f56ef638973d94',
        courseName: 'COMMERCE 1DE0 - Business I Orientation',
        courseCode: '1DE0',
        shortName: 'COMMERCE 1DE0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d94',
      },
      {
        _id: '6073b6e656f56ef638973d96',
        courseName: 'COMMERCE 1E03 - Business Environment and Organization',
        courseCode: '1E03',
        shortName: 'COMMERCE 1E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d96',
      },
      {
        _id: '6073b6e656f56ef638973d98',
        courseName:
          'COMMERCE 1GR0 A/B - DeGroote Student Experience and Development I',
        courseCode: '1GR0',
        shortName: 'COMMERCE 1GR0 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d98',
      },
      {
        _id: '6073b6e656f56ef638973d9a',
        courseName: 'COMMERCE 1MA3 - Introduction to Marketing',
        courseCode: '1MA3',
        shortName: 'COMMERCE 1MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6e656f56ef638973d9a',
      },
      {
        _id: '6073b6e656f56ef638973d9c',
        courseName: 'COMMERCE 2AB3 - Managerial Accounting I',
        courseCode: '2AB3',
        shortName: 'COMMERCE 2AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d9c',
      },
      {
        _id: '6073b6e656f56ef638973d9e',
        courseName:
          'COMMERCE 2BC3 - Human Resource Management and Labour Relations',
        courseCode: '2BC3',
        shortName: 'COMMERCE 2BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e656f56ef638973d9e',
      },
      {
        _id: '6073b6e756f56ef638973da0',
        courseName: 'COMMERCE 2DA3 - Decision Making with Analytics',
        courseCode: '2DA3',
        shortName: 'COMMERCE 2DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973da0',
      },
      {
        _id: '6073b6e756f56ef638973da2',
        courseName: 'COMMERCE 2FA3 - Introduction to Finance',
        courseCode: '2FA3',
        shortName: 'COMMERCE 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973da2',
      },
      {
        _id: '6073b6e756f56ef638973da4',
        courseName: 'COMMERCE 2FB3 - Managerial Finance',
        courseCode: '2FB3',
        shortName: 'COMMERCE 2FB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973da4',
      },
      {
        _id: '6073b6e756f56ef638973da6',
        courseName: 'COMMERCE 2IN0 - Career Development Course',
        courseCode: '2IN0',
        shortName: 'COMMERCE 2IN0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973da6',
      },
      {
        _id: '6073b6e756f56ef638973da8',
        courseName: 'COMMERCE 2KA3 - Information Systems in Business',
        courseCode: '2KA3',
        shortName: 'COMMERCE 2KA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973da8',
      },
      {
        _id: '6073b6e756f56ef638973daa',
        courseName: 'COMMERCE 2MA3 - Introduction to Marketing',
        courseCode: '2MA3',
        shortName: 'COMMERCE 2MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973daa',
      },
      {
        _id: '6073b6e756f56ef638973dac',
        courseName: 'COMMERCE 2OC3 - Operations Management',
        courseCode: '2OC3',
        shortName: 'COMMERCE 2OC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973dac',
      },
      {
        _id: '6073b6e756f56ef638973dae',
        courseName: 'COMMERCE 2QA3 - Applied Statistics for Business',
        courseCode: '2QA3',
        shortName: 'COMMERCE 2QA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973dae',
      },
      {
        _id: '6073b6e756f56ef638973db0',
        courseName: 'COMMERCE 3AB3 - Intermediate Financial Accounting I',
        courseCode: '3AB3',
        shortName: 'COMMERCE 3AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '6073b6e756f56ef638973db0',
      },
      {
        _id: '6073b6e756f56ef638973db2',
        courseName: 'COMMERCE 3AC3 - Intermediate Financial Accounting II',
        courseCode: '3AC3',
        shortName: 'COMMERCE 3AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973db2',
      },
      {
        _id: '6073b6e756f56ef638973db4',
        courseName: 'COMMERCE 3DA3 - Predictive Analytics',
        courseCode: '3DA3',
        shortName: 'COMMERCE 3DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973db4',
      },
      {
        _id: '6073b6e756f56ef638973db6',
        courseName: 'COMMERCE 3FA3 - Managerial Finance',
        courseCode: '3FA3',
        shortName: 'COMMERCE 3FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e756f56ef638973db6',
      },
      {
        _id: '6073b6e856f56ef638973db8',
        courseName: 'COMMERCE 3FB3 - Securities Analysis',
        courseCode: '3FB3',
        shortName: 'COMMERCE 3FB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973db8',
      },
      {
        _id: '6073b6e856f56ef638973dba',
        courseName: 'COMMERCE 3FC3 - International Finance',
        courseCode: '3FC3',
        shortName: 'COMMERCE 3FC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dba',
      },
      {
        _id: '6073b6e856f56ef638973dbc',
        courseName: 'COMMERCE 3FD3 - Financial Modelling',
        courseCode: '3FD3',
        shortName: 'COMMERCE 3FD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dbc',
      },
      {
        _id: '6073b6e856f56ef638973dbe',
        courseName:
          'COMMERCE 3FH3 - Alternative Investments and Portfolio Management',
        courseCode: '3FH3',
        shortName: 'COMMERCE 3FH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dbe',
      },
      {
        _id: '6073b6e856f56ef638973dc0',
        courseName: 'COMMERCE 3FI3 - Market Trading with Options and Futures',
        courseCode: '3FI3',
        shortName: 'COMMERCE 3FI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dc0',
      },
      {
        _id: '6073b6e856f56ef638973dc2',
        courseName: 'COMMERCE 3FK3 - Intermediate Corporate Finance',
        courseCode: '3FK3',
        shortName: 'COMMERCE 3FK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dc2',
      },
      {
        _id: '6073b6e856f56ef638973dc4',
        courseName: 'COMMERCE 3FL3 - Sustainable and Social Finance',
        courseCode: '3FL3',
        shortName: 'COMMERCE 3FL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dc4',
      },
      {
        _id: '6073b6e856f56ef638973dc6',
        courseName: 'COMMERCE 3KA3 - System Analysis and Design',
        courseCode: '3KA3',
        shortName: 'COMMERCE 3KA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dc6',
      },
      {
        _id: '6073b6e856f56ef638973dc8',
        courseName:
          'COMMERCE 3KD3 - Database Design Management and Applications',
        courseCode: '3KD3',
        shortName: 'COMMERCE 3KD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dc8',
      },
      {
        _id: '6073b6e856f56ef638973dca',
        courseName: 'COMMERCE 3KE3 - Management of Enterprise Data Analytics',
        courseCode: '3KE3',
        shortName: 'COMMERCE 3KE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dca',
      },
      {
        _id: '6073b6e856f56ef638973dcc',
        courseName: 'COMMERCE 3MA3 - Marketing Research',
        courseCode: '3MA3',
        shortName: 'COMMERCE 3MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e856f56ef638973dcc',
      },
      {
        _id: '6073b6e956f56ef638973dce',
        courseName: 'COMMERCE 3MB3 - Consumer Behaviour',
        courseCode: '3MB3',
        shortName: 'COMMERCE 3MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dce',
      },
      {
        _id: '6073b6e956f56ef638973dd0',
        courseName: 'COMMERCE 3MC3 - Applied Marketing Management',
        courseCode: '3MC3',
        shortName: 'COMMERCE 3MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dd0',
      },
      {
        _id: '6073b6e956f56ef638973dd2',
        courseName:
          'COMMERCE 3MD3 - Introduction to Contemporary Applied Marketing',
        courseCode: '3MD3',
        shortName: 'COMMERCE 3MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dd2',
      },
      {
        _id: '6073b6e956f56ef638973dd4',
        courseName: 'COMMERCE 3QA3 - Management Science for Business',
        courseCode: '3QA3',
        shortName: 'COMMERCE 3QA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dd4',
      },
      {
        _id: '6073b6e956f56ef638973dd6',
        courseName: 'COMMERCE 3S03 - Management Skills Development',
        courseCode: '3S03',
        shortName: 'COMMERCE 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dd6',
      },
      {
        _id: '6073b6e956f56ef638973dd8',
        courseName: 'COMMERCE 4AA3 - Managerial Accounting II',
        courseCode: '4AA3',
        shortName: 'COMMERCE 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dd8',
      },
      {
        _id: '6073b6e956f56ef638973dda',
        courseName: 'COMMERCE 4AC3 - Advanced Financial Accounting',
        courseCode: '4AC3',
        shortName: 'COMMERCE 4AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 12,
        id: '6073b6e956f56ef638973dda',
      },
      {
        _id: '6073b6e956f56ef638973ddc',
        courseName: 'COMMERCE 4AD3 - Introduction to Auditing',
        courseCode: '4AD3',
        shortName: 'COMMERCE 4AD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973ddc',
      },
      {
        _id: '6073b6e956f56ef638973dde',
        courseName: 'COMMERCE 4AF3 - Accounting Theory',
        courseCode: '4AF3',
        shortName: 'COMMERCE 4AF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973dde',
      },
      {
        _id: '6073b6e956f56ef638973de0',
        courseName:
          'COMMERCE 4AK3 - Accounting Information for Decision Making',
        courseCode: '4AK3',
        shortName: 'COMMERCE 4AK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973de0',
      },
      {
        _id: '6073b6e956f56ef638973de2',
        courseName: 'COMMERCE 4BB3 - Recruitment and Selection',
        courseCode: '4BB3',
        shortName: 'COMMERCE 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6e956f56ef638973de2',
      },
      {
        _id: '6073b6ea56f56ef638973de4',
        courseName: 'COMMERCE 4BC3 - Collective Bargaining',
        courseCode: '4BC3',
        shortName: 'COMMERCE 4BC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973de4',
      },
      {
        _id: '6073b6ea56f56ef638973de6',
        courseName: 'COMMERCE 4BD3 - Settlement of Industrial Disputes',
        courseCode: '4BD3',
        shortName: 'COMMERCE 4BD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973de6',
      },
      {
        _id: '6073b6ea56f56ef638973de8',
        courseName: 'COMMERCE 4BE3 - Strategic Compensation/Reward Systems',
        courseCode: '4BE3',
        shortName: 'COMMERCE 4BE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973de8',
      },
      {
        _id: '6073b6ea56f56ef638973dea',
        courseName: 'COMMERCE 4BF3 - Labour Law and Policy',
        courseCode: '4BF3',
        shortName: 'COMMERCE 4BF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973dea',
      },
      {
        _id: '6073b6ea56f56ef638973dec',
        courseName: 'COMMERCE 4BG3 - Public Sector Collective Bargaining',
        courseCode: '4BG3',
        shortName: 'COMMERCE 4BG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973dec',
      },
      {
        _id: '6073b6ea56f56ef638973dee',
        courseName: 'COMMERCE 4BI3 - Training and Development',
        courseCode: '4BI3',
        shortName: 'COMMERCE 4BI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973dee',
      },
      {
        _id: '6073b6ea56f56ef638973df0',
        courseName: 'COMMERCE 4BK3 - The Management of Technology',
        courseCode: '4BK3',
        shortName: 'COMMERCE 4BK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973df0',
      },
      {
        _id: '6073b6ea56f56ef638973df2',
        courseName: 'COMMERCE 4BL3 - Occupational Health and Safety Management',
        courseCode: '4BL3',
        shortName: 'COMMERCE 4BL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973df2',
      },
      {
        _id: '6073b6ea56f56ef638973df4',
        courseName: 'COMMERCE 4BM3 - Strategic Human Resource Planning',
        courseCode: '4BM3',
        shortName: 'COMMERCE 4BM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973df4',
      },
      {
        _id: '6073b6ea56f56ef638973df6',
        courseName: 'COMMERCE 4BN3 - Leadership Development',
        courseCode: '4BN3',
        shortName: 'COMMERCE 4BN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973df6',
      },
      {
        _id: '6073b6ea56f56ef638973df8',
        courseName: 'COMMERCE 4BP3 - Principles of Leadership',
        courseCode: '4BP3',
        shortName: 'COMMERCE 4BP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ea56f56ef638973df8',
      },
      {
        _id: '6073b6eb56f56ef638973dfa',
        courseName:
          'COMMERCE 4BX3 - Special Topics in Human Resource Management',
        courseCode: '4BX3',
        shortName: 'COMMERCE 4BX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973dfa',
      },
      {
        _id: '6073b6eb56f56ef638973dfc',
        courseName:
          'COMMERCE 4CA3 - Managing and Promoting Health and Healthcare Services',
        courseCode: '4CA3',
        shortName: 'COMMERCE 4CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973dfc',
      },
      {
        _id: '6073b6eb56f56ef638973dfe',
        courseName: 'COMMERCE 4DA3 - Modelling and Analytics Using Excel',
        courseCode: '4DA3',
        shortName: 'COMMERCE 4DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973dfe',
      },
      {
        _id: '6073b6eb56f56ef638973e00',
        courseName: 'COMMERCE 4EL3 - Experiential Learning in Business',
        courseCode: '4EL3',
        shortName: 'COMMERCE 4EL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973e00',
      },
      {
        _id: '6073b6eb56f56ef638973e02',
        courseName: 'COMMERCE 4FA3 - Applied Corporate Finance',
        courseCode: '4FA3',
        shortName: 'COMMERCE 4FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973e02',
      },
      {
        _id: '6073b6eb56f56ef638973e04',
        courseName: 'COMMERCE 4FB3 - Valuation for Finance Professionals',
        courseCode: '4FB3',
        shortName: 'COMMERCE 4FB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973e04',
      },
      {
        _id: '6073b6eb56f56ef638973e06',
        courseName:
          'COMMERCE 4FC3 - Ethics and Professional Practice in Finance',
        courseCode: '4FC3',
        shortName: 'COMMERCE 4FC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6eb56f56ef638973e06',
      },
      {
        _id: '6073b6ec56f56ef638973e08',
        courseName: 'COMMERCE 4FD3 - Financial Institutions',
        courseCode: '4FD3',
        shortName: 'COMMERCE 4FD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e08',
      },
      {
        _id: '6073b6ec56f56ef638973e0a',
        courseName: 'COMMERCE 4FE3 - Options and Futures',
        courseCode: '4FE3',
        shortName: 'COMMERCE 4FE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e0a',
      },
      {
        _id: '6073b6ec56f56ef638973e0c',
        courseName: 'COMMERCE 4FF3 - Portfolio Theory and Management',
        courseCode: '4FF3',
        shortName: 'COMMERCE 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e0c',
      },
      {
        _id: '6073b6ec56f56ef638973e0e',
        courseName: 'COMMERCE 4FG3 - Financial Theory',
        courseCode: '4FG3',
        shortName: 'COMMERCE 4FG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e0e',
      },
      {
        _id: '6073b6ec56f56ef638973e10',
        courseName:
          'COMMERCE 4FH3 - Mergers, Acquisitions and Corporate Control',
        courseCode: '4FH3',
        shortName: 'COMMERCE 4FH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e10',
      },
      {
        _id: '6073b6ec56f56ef638973e12',
        courseName: 'COMMERCE 4FJ3 - Fixed Income Analysis',
        courseCode: '4FJ3',
        shortName: 'COMMERCE 4FJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e12',
      },
      {
        _id: '6073b6ec56f56ef638973e14',
        courseName: 'COMMERCE 4FK3 - Financial Statement Analysis',
        courseCode: '4FK3',
        shortName: 'COMMERCE 4FK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e14',
      },
      {
        _id: '6073b6ec56f56ef638973e16',
        courseName: 'COMMERCE 4FL3 - Personal Financial Management',
        courseCode: '4FL3',
        shortName: 'COMMERCE 4FL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 2,
        id: '6073b6ec56f56ef638973e16',
      },
      {
        _id: '6073b6ec56f56ef638973e18',
        courseName: 'COMMERCE 4FM3 - Personal Financial Planning and Advising',
        courseCode: '4FM3',
        shortName: 'COMMERCE 4FM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e18',
      },
      {
        _id: '6073b6ec56f56ef638973e1a',
        courseName: 'COMMERCE 4FN3 - Financial Risk Management',
        courseCode: '4FN3',
        shortName: 'COMMERCE 4FN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ec56f56ef638973e1a',
      },
      {
        _id: '6073b6ed56f56ef638973e1c',
        courseName:
          'COMMERCE 4FO3 - Small Business and Entrepreneurial Finance',
        courseCode: '4FO3',
        shortName: 'COMMERCE 4FO3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ed56f56ef638973e1c',
      },
      {
        _id: '6073b6ed56f56ef638973e1e',
        courseName: 'COMMERCE 4FP3 - Personal Finance',
        courseCode: '4FP3',
        shortName: 'COMMERCE 4FP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ed56f56ef638973e1e',
      },
      {
        _id: '6073b6ed56f56ef638973e20',
        courseName: 'COMMERCE 4FQ3 - Working Capital Management',
        courseCode: '4FQ3',
        shortName: 'COMMERCE 4FQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '6073b6ed56f56ef638973e20',
      },
      {
        _id: '6073b6ed56f56ef638973e22',
        courseName: 'COMMERCE 4FR3 - Insurance and Risk Management',
        courseCode: '4FR3',
        shortName: 'COMMERCE 4FR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ed56f56ef638973e22',
      },
      {
        _id: '6073b6ed56f56ef638973e24',
        courseName: 'COMMERCE 4FS3 - Pension, Retirement and Estate Planning',
        courseCode: '4FS3',
        shortName: 'COMMERCE 4FS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ed56f56ef638973e24',
      },
      {
        _id: '6073b6ed56f56ef638973e26',
        courseName: 'COMMERCE 4FT3 - Real Estate Finance and Investment',
        courseCode: '4FT3',
        shortName: 'COMMERCE 4FT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 5,
        id: '6073b6ed56f56ef638973e26',
      },
      {
        _id: '6073b6ed56f56ef638973e28',
        courseName:
          'COMMERCE 4FU3 - Behavioural Finance: The Psychology of Markets',
        courseCode: '4FU3',
        shortName: 'COMMERCE 4FU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ed56f56ef638973e28',
      },
      {
        _id: '6073b6ed56f56ef638973e2a',
        courseName: 'COMMERCE 4FV3 - Venture Capital',
        courseCode: '4FV3',
        shortName: 'COMMERCE 4FV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ed56f56ef638973e2a',
      },
      {
        _id: '6073b6ee56f56ef638973e2c',
        courseName: 'COMMERCE 4FW3 - Finance for Entrepreneurs',
        courseCode: '4FW3',
        shortName: 'COMMERCE 4FW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e2c',
      },
      {
        _id: '6073b6ee56f56ef638973e2e',
        courseName: 'COMMERCE 4FX3 - Special Topics in Finance',
        courseCode: '4FX3',
        shortName: 'COMMERCE 4FX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e2e',
      },
      {
        _id: '6073b6ee56f56ef638973e30',
        courseName: 'COMMERCE 4FY3 - Introduction to FinTech',
        courseCode: '4FY3',
        shortName: 'COMMERCE 4FY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e30',
      },
      {
        _id: '6073b6ee56f56ef638973e32',
        courseName: 'COMMERCE 4FZ3 - Islamic Finance',
        courseCode: '4FZ3',
        shortName: 'COMMERCE 4FZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e32',
      },
      {
        _id: '6073b6ee56f56ef638973e34',
        courseName: 'COMMERCE 4IA0 - Internship 1',
        courseCode: '4IA0',
        shortName: 'COMMERCE 4IA0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e34',
      },
      {
        _id: '6073b6ee56f56ef638973e36',
        courseName: 'COMMERCE 4IB0 - Internship 2',
        courseCode: '4IB0',
        shortName: 'COMMERCE 4IB0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e36',
      },
      {
        _id: '6073b6ee56f56ef638973e38',
        courseName: 'COMMERCE 4IC0 - Internship 3',
        courseCode: '4IC0',
        shortName: 'COMMERCE 4IC0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e38',
      },
      {
        _id: '6073b6ee56f56ef638973e3a',
        courseName: 'COMMERCE 4ID0 - Internship Final Term',
        courseCode: '4ID0',
        shortName: 'COMMERCE 4ID0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e3a',
      },
      {
        _id: '6073b6ee56f56ef638973e3c',
        courseName: 'COMMERCE 4KF3 - Project Management',
        courseCode: '4KF3',
        shortName: 'COMMERCE 4KF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e3c',
      },
      {
        _id: '6073b6ee56f56ef638973e3e',
        courseName: 'COMMERCE 4KG3 - Data Mining For Business Analytics',
        courseCode: '4KG3',
        shortName: 'COMMERCE 4KG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e3e',
      },
      {
        _id: '6073b6ee56f56ef638973e40',
        courseName:
          'COMMERCE 4KH3 - Strategies for Electronic and Mobile Business',
        courseCode: '4KH3',
        shortName: 'COMMERCE 4KH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e40',
      },
      {
        _id: '6073b6ee56f56ef638973e42',
        courseName: 'COMMERCE 4KI3 - Business Process Management',
        courseCode: '4KI3',
        shortName: 'COMMERCE 4KI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ee56f56ef638973e42',
      },
      {
        _id: '6073b6ef56f56ef638973e44',
        courseName: 'COMMERCE 4KX3 - Special Topics in Information Systems',
        courseCode: '4KX3',
        shortName: 'COMMERCE 4KX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e44',
      },
      {
        _id: '6073b6ef56f56ef638973e46',
        courseName:
          'COMMERCE 4MA3 - Advertising and Integrated Marketing Communication',
        courseCode: '4MA3',
        shortName: 'COMMERCE 4MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e46',
      },
      {
        _id: '6073b6ef56f56ef638973e48',
        courseName: 'COMMERCE 4MC3 - New Product Marketing',
        courseCode: '4MC3',
        shortName: 'COMMERCE 4MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e48',
      },
      {
        _id: '6073b6ef56f56ef638973e4a',
        courseName: 'COMMERCE 4MD3 - Business Marketing',
        courseCode: '4MD3',
        shortName: 'COMMERCE 4MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e4a',
      },
      {
        _id: '6073b6ef56f56ef638973e4c',
        courseName: 'COMMERCE 4ME3 - Sales Management',
        courseCode: '4ME3',
        shortName: 'COMMERCE 4ME3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e4c',
      },
      {
        _id: '6073b6ef56f56ef638973e4e',
        courseName: 'COMMERCE 4MF3 - Retailing Management',
        courseCode: '4MF3',
        shortName: 'COMMERCE 4MF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e4e',
      },
      {
        _id: '6073b6ef56f56ef638973e50',
        courseName: 'COMMERCE 4MG3 - Strategic Philanthropy and Leadership',
        courseCode: '4MG3',
        shortName: 'COMMERCE 4MG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e50',
      },
      {
        _id: '6073b6ef56f56ef638973e52',
        courseName: 'COMMERCE 4MH3 - Electronic Marketing',
        courseCode: '4MH3',
        shortName: 'COMMERCE 4MH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e52',
      },
      {
        _id: '6073b6ef56f56ef638973e54',
        courseName: 'COMMERCE 4MI3 - Marketing Analytics',
        courseCode: '4MI3',
        shortName: 'COMMERCE 4MI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e54',
      },
      {
        _id: '6073b6ef56f56ef638973e56',
        courseName:
          'COMMERCE 4OB3 - Analysis of Production/Operations Problems',
        courseCode: '4OB3',
        shortName: 'COMMERCE 4OB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e56',
      },
      {
        _id: '6073b6ef56f56ef638973e58',
        courseName: 'COMMERCE 4OD3 - Purchasing and Supply Management',
        courseCode: '4OD3',
        shortName: 'COMMERCE 4OD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6ef56f56ef638973e58',
      },
      {
        _id: '6073b6f056f56ef638973e5a',
        courseName: 'COMMERCE 4OI3 - Supply Chain Management',
        courseCode: '4OI3',
        shortName: 'COMMERCE 4OI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e5a',
      },
      {
        _id: '6073b6f056f56ef638973e5c',
        courseName: 'COMMERCE 4OT3 - Transportation and Warehousing Management',
        courseCode: '4OT3',
        shortName: 'COMMERCE 4OT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e5c',
      },
      {
        _id: '6073b6f056f56ef638973e5e',
        courseName: 'COMMERCE 4PA3 - Business Policy: Strategic Management',
        courseCode: '4PA3',
        shortName: 'COMMERCE 4PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 13,
        id: '6073b6f056f56ef638973e5e',
      },
      {
        _id: '6073b6f056f56ef638973e60',
        courseName: 'COMMERCE 4QA3 - Operations Modelling and Analysis',
        courseCode: '4QA3',
        shortName: 'COMMERCE 4QA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e60',
      },
      {
        _id: '6073b6f056f56ef638973e62',
        courseName: 'COMMERCE 4QX3 - Special Topics in Operations Management',
        courseCode: '4QX3',
        shortName: 'COMMERCE 4QX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e62',
      },
      {
        _id: '6073b6f056f56ef638973e64',
        courseName: 'COMMERCE 4SA3 - International Business',
        courseCode: '4SA3',
        shortName: 'COMMERCE 4SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e64',
      },
      {
        _id: '6073b6f056f56ef638973e66',
        courseName: 'COMMERCE 4SB3 - Introduction to Canadian Taxation',
        courseCode: '4SB3',
        shortName: 'COMMERCE 4SB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e66',
      },
      {
        _id: '6073b6f056f56ef638973e68',
        courseName: 'COMMERCE 4SC3 - Advanced Canadian Taxation',
        courseCode: '4SC3',
        shortName: 'COMMERCE 4SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e68',
      },
      {
        _id: '6073b6f056f56ef638973e6a',
        courseName: 'COMMERCE 4SD3 - Commercial Law',
        courseCode: '4SD3',
        shortName: 'COMMERCE 4SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 3,
        id: '6073b6f056f56ef638973e6a',
      },
      {
        _id: '6073b6f056f56ef638973e6c',
        courseName: 'COMMERCE 4SE3 - Entrepreneurship',
        courseCode: '4SE3',
        shortName: 'COMMERCE 4SE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e6c',
      },
      {
        _id: '6073b6f056f56ef638973e6e',
        courseName: 'COMMERCE 4SG3 - Sustainability: Corporations and Society',
        courseCode: '4SG3',
        shortName: 'COMMERCE 4SG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e6e',
      },
      {
        _id: '6073b6f056f56ef638973e70',
        courseName: 'COMMERCE 4SH3 - Case Analysis and Presentation Skills',
        courseCode: '4SH3',
        shortName: 'COMMERCE 4SH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f056f56ef638973e70',
      },
      {
        _id: '6073b6f156f56ef638973e72',
        courseName: 'COMMERCE 4SM3 - Sports Management',
        courseCode: '4SM3',
        shortName: 'COMMERCE 4SM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e72',
      },
      {
        _id: '6073b6f156f56ef638973e74',
        courseName: 'COMMERCE 4SX3 - Special Topics in Strategic Management',
        courseCode: '4SX3',
        shortName: 'COMMERCE 4SX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e74',
      },
      {
        _id: '6073b6f156f56ef638973e76',
        courseName: 'COMMERCE 4SY3 - Independent Study in Business',
        courseCode: '4SY3',
        shortName: 'COMMERCE 4SY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e76',
      },
      {
        _id: '6073b6f156f56ef638973e78',
        courseName: 'CLASSICS 1A03 - Introduction to Classical Archaeology',
        courseCode: '1A03',
        shortName: 'CLASSICS 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e78',
      },
      {
        _id: '6073b6f156f56ef638973e7a',
        courseName:
          'CLASSICS 1B03 - An Introduction to Ancient Myth and Literature',
        courseCode: '1B03',
        shortName: 'CLASSICS 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e7a',
      },
      {
        _id: '6073b6f156f56ef638973e7c',
        courseName: 'CLASSICS 1M03 - History of Greece and Rome',
        courseCode: '1M03',
        shortName: 'CLASSICS 1M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e7c',
      },
      {
        _id: '6073b6f156f56ef638973e7e',
        courseName: 'CLASSICS 2B03 - Greek Art',
        courseCode: '2B03',
        shortName: 'CLASSICS 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e7e',
      },
      {
        _id: '6073b6f156f56ef638973e80',
        courseName: 'CLASSICS 2C03 - Roman Art',
        courseCode: '2C03',
        shortName: 'CLASSICS 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e80',
      },
      {
        _id: '6073b6f156f56ef638973e82',
        courseName: 'CLASSICS 2D03 - Greek and Roman Mythology',
        courseCode: '2D03',
        shortName: 'CLASSICS 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e82',
      },
      {
        _id: '6073b6f156f56ef638973e84',
        courseName: 'CLASSICS 2E03 - The Ancient World in Film',
        courseCode: '2E03',
        shortName: 'CLASSICS 2E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e84',
      },
      {
        _id: '6073b6f156f56ef638973e86',
        courseName:
          'CLASSICS 2HA3 - Athens and Sparta: Democracy, Oligarchy, and War',
        courseCode: '2HA3',
        shortName: 'CLASSICS 2HA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f156f56ef638973e86',
      },
      {
        _id: '6073b6f256f56ef638973e88',
        courseName:
          'CLASSICS 2HB3 - From Alexander to Cleopatra: Cosmopolis and Empire',
        courseCode: '2HB3',
        shortName: 'CLASSICS 2HB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e88',
      },
      {
        _id: '6073b6f256f56ef638973e8a',
        courseName: 'CLASSICS 2HC3 - Caesar and the Rise of Autocracy',
        courseCode: '2HC3',
        shortName: 'CLASSICS 2HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e8a',
      },
      {
        _id: '6073b6f256f56ef638973e8c',
        courseName: 'CLASSICS 2HD3 - Nero, Decadence, and the End of a Dynasty',
        courseCode: '2HD3',
        shortName: 'CLASSICS 2HD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e8c',
      },
      {
        _id: '6073b6f256f56ef638973e8e',
        courseName: 'CLASSICS 2K03 - The Society of Greece and Rome',
        courseCode: '2K03',
        shortName: 'CLASSICS 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e8e',
      },
      {
        _id: '6073b6f256f56ef638973e90',
        courseName: 'CLASSICS 2LW3 - Ancient Law',
        courseCode: '2LW3',
        shortName: 'CLASSICS 2LW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e90',
      },
      {
        _id: '6073b6f256f56ef638973e92',
        courseName: 'CLASSICS 2MT3 - Ancient Roots of Medical Terminology',
        courseCode: '2MT3',
        shortName: 'CLASSICS 2MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e92',
      },
      {
        _id: '6073b6f256f56ef638973e94',
        courseName: 'CLASSICS 2YY3 - Greek Tragedy',
        courseCode: '2YY3',
        shortName: 'CLASSICS 2YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e94',
      },
      {
        _id: '6073b6f256f56ef638973e96',
        courseName: 'CLASSICS 3EE3 - The Greek Historians',
        courseCode: '3EE3',
        shortName: 'CLASSICS 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e96',
      },
      {
        _id: '6073b6f256f56ef638973e98',
        courseName: 'CLASSICS 3ER3 - Epics of Rome',
        courseCode: '3ER3',
        shortName: 'CLASSICS 3ER3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e98',
      },
      {
        _id: '6073b6f256f56ef638973e9a',
        courseName: 'CLASSICS 3H03 - Archaic Greek Art',
        courseCode: '3H03',
        shortName: 'CLASSICS 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e9a',
      },
      {
        _id: '6073b6f256f56ef638973e9c',
        courseName: 'CLASSICS 3HH3 - Roman Slavery',
        courseCode: '3HH3',
        shortName: 'CLASSICS 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f256f56ef638973e9c',
      },
      {
        _id: '6073b6f356f56ef638973e9e',
        courseName: 'CLASSICS 3M03 - Greek Intellectual Revolution',
        courseCode: '3M03',
        shortName: 'CLASSICS 3M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973e9e',
      },
      {
        _id: '6073b6f356f56ef638973ea0',
        courseName:
          'CLASSICS 3MT3 - Advanced Ancient Roots of Medical Terminology',
        courseCode: '3MT3',
        shortName: 'CLASSICS 3MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973ea0',
      },
      {
        _id: '6073b6f356f56ef638973ea2',
        courseName: 'CLASSICS 3Q03 - Greek Sanctuaries',
        courseCode: '3Q03',
        shortName: 'CLASSICS 3Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973ea2',
      },
      {
        _id: '6073b6f356f56ef638973ea4',
        courseName: 'CLASSICS 3S03 - Pompeii, Herculaneum, and Ostia',
        courseCode: '3S03',
        shortName: 'CLASSICS 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973ea4',
      },
      {
        _id: '6073b6f356f56ef638973ea6',
        courseName: 'CLASSICS 3X03 - Roman Religion',
        courseCode: '3X03',
        shortName: 'CLASSICS 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973ea6',
      },
      {
        _id: '6073b6f356f56ef638973ea8',
        courseName: 'CLASSICS 3YY3 - Ovid',
        courseCode: '3YY3',
        shortName: 'CLASSICS 3YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973ea8',
      },
      {
        _id: '6073b6f356f56ef638973eaa',
        courseName: 'CLASSICS 4BB3 - Seminar in Ancient Art',
        courseCode: '4BB3',
        shortName: 'CLASSICS 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973eaa',
      },
      {
        _id: '6073b6f356f56ef638973eac',
        courseName: 'CLASSICS 4E03 - Seminar in Ancient Culture',
        courseCode: '4E03',
        shortName: 'CLASSICS 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973eac',
      },
      {
        _id: '6073b6f356f56ef638973eae',
        courseName: 'CLASSICS 4F03 - Seminar in Ancient History',
        courseCode: '4F03',
        shortName: 'CLASSICS 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973eae',
      },
      {
        _id: '6073b6f356f56ef638973eb0',
        courseName: 'CLASSICS 4FP3 - Field Practicum in Classical Archaeology',
        courseCode: '4FP3',
        shortName: 'CLASSICS 4FP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973eb0',
      },
      {
        _id: '6073b6f356f56ef638973eb2',
        courseName:
          'CLASSICS 4H03 - Death and Commemoration in the Roman World',
        courseCode: '4H03',
        shortName: 'CLASSICS 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f356f56ef638973eb2',
      },
      {
        _id: '6073b6f456f56ef638973eb4',
        courseName: 'CLASSICS 4L03 - Athenian Democracy',
        courseCode: '4L03',
        shortName: 'CLASSICS 4L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973eb4',
      },
      {
        _id: '6073b6f456f56ef638973eb6',
        courseName: 'CLASSICS 4MR3 - The Myth and Reality of Troy',
        courseCode: '4MR3',
        shortName: 'CLASSICS 4MR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973eb6',
      },
      {
        _id: '6073b6f456f56ef638973eb8',
        courseName: 'CLASSICS 4T03 A/B S - Independent Study',
        courseCode: '4T03',
        shortName: 'CLASSICS 4T03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973eb8',
      },
      {
        _id: '6073b6f456f56ef638973eba',
        courseName: 'CLASSICS 4U03 - The Severe Style in Greek Art',
        courseCode: '4U03',
        shortName: 'CLASSICS 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973eba',
      },
      {
        _id: '6073b6f456f56ef638973ebc',
        courseName: 'CMST 1A03 - Introduction to Communication',
        courseCode: '1A03',
        shortName: 'CMST 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ebc',
      },
      {
        _id: '6073b6f456f56ef638973ebe',
        courseName: 'CMST 2BB3 - Culture and Communication',
        courseCode: '2BB3',
        shortName: 'CMST 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ebe',
      },
      {
        _id: '6073b6f456f56ef638973ec0',
        courseName: 'CMST 2DD3 - Media Organizations',
        courseCode: '2DD3',
        shortName: 'CMST 2DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ec0',
      },
      {
        _id: '6073b6f456f56ef638973ec2',
        courseName: 'CMST 2G03 - Performance and Performativity',
        courseCode: '2G03',
        shortName: 'CMST 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ec2',
      },
      {
        _id: '6073b6f456f56ef638973ec4',
        courseName: 'CMST 2H03 - Gender and Performance',
        courseCode: '2H03',
        shortName: 'CMST 2H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ec4',
      },
      {
        _id: '6073b6f456f56ef638973ec6',
        courseName: 'CMST 2HM3 - Human Communication',
        courseCode: '2HM3',
        shortName: 'CMST 2HM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ec6',
      },
      {
        _id: '6073b6f456f56ef638973ec8',
        courseName: 'CMST 2K03 - Political Economy of the Media',
        courseCode: '2K03',
        shortName: 'CMST 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973ec8',
      },
      {
        _id: '6073b6f456f56ef638973eca',
        courseName: 'CMST 2LW3 - Communication Policy and Law',
        courseCode: '2LW3',
        shortName: 'CMST 2LW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f456f56ef638973eca',
      },
      {
        _id: '6073b6f556f56ef638973ecc',
        courseName: 'CMST 2PR3 - Public Relations: Principles and Practices',
        courseCode: '2PR3',
        shortName: 'CMST 2PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ecc',
      },
      {
        _id: '6073b6f556f56ef638973ece',
        courseName:
          'CMST 2RA3 - Application in Communication Theory and Methods',
        courseCode: '2RA3',
        shortName: 'CMST 2RA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ece',
      },
      {
        _id: '6073b6f556f56ef638973ed0',
        courseName:
          'CMST 2TM6 - Foundations in Communication Theory and Methods',
        courseCode: '2TM6',
        shortName: 'CMST 2TM6',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ed0',
      },
      {
        _id: '6073b6f556f56ef638973ed2',
        courseName: 'CMST 3B03 A/B S - Practical Aspects of Media Production',
        courseCode: '3B03',
        shortName: 'CMST 3B03 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ed2',
      },
      {
        _id: '6073b6f556f56ef638973ed4',
        courseName: 'CMST 3CY3 - Children, Youth, and Media',
        courseCode: '3CY3',
        shortName: 'CMST 3CY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ed4',
      },
      {
        _id: '6073b6f556f56ef638973ed6',
        courseName: 'CMST 3D03 - Political Communication',
        courseCode: '3D03',
        shortName: 'CMST 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ed6',
      },
      {
        _id: '6073b6f556f56ef638973ed8',
        courseName: 'CMST 3H03 - Creating Ceremonies',
        courseCode: '3H03',
        shortName: 'CMST 3H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ed8',
      },
      {
        _id: '6073b6f556f56ef638973eda',
        courseName: 'CMST 3HC3 - History of Communication',
        courseCode: '3HC3',
        shortName: 'CMST 3HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973eda',
      },
      {
        _id: '6073b6f556f56ef638973edc',
        courseName: 'CMST 3II3 - Intellectual Property',
        courseCode: '3II3',
        shortName: 'CMST 3II3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973edc',
      },
      {
        _id: '6073b6f556f56ef638973ede',
        courseName: 'CMST 3JJ3 - The Rise of the Music Industry',
        courseCode: '3JJ3',
        shortName: 'CMST 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ede',
      },
      {
        _id: '6073b6f556f56ef638973ee0',
        courseName: 'CMST 3K03 - Media Audiences and Effects',
        courseCode: '3K03',
        shortName: 'CMST 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ee0',
      },
      {
        _id: '6073b6f556f56ef638973ee2',
        courseName: 'CMST 3RR3 - Race, Religion and Media',
        courseCode: '3RR3',
        shortName: 'CMST 3RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f556f56ef638973ee2',
      },
      {
        _id: '6073b6f656f56ef638973ee4',
        courseName: 'CMST 3S03 - Television and Society',
        courseCode: '3S03',
        shortName: 'CMST 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ee4',
      },
      {
        _id: '6073b6f656f56ef638973ee6',
        courseName: 'CMST 3SM3 - Building Publics using Social Media',
        courseCode: '3SM3',
        shortName: 'CMST 3SM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ee6',
      },
      {
        _id: '6073b6f656f56ef638973ee8',
        courseName: 'CMST 3WR3 - Professional Writing',
        courseCode: '3WR3',
        shortName: 'CMST 3WR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ee8',
      },
      {
        _id: '6073b6f656f56ef638973eea',
        courseName: 'CMST 3Z03 - Mobile Practices, Technologies and Art',
        courseCode: '3Z03',
        shortName: 'CMST 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973eea',
      },
      {
        _id: '6073b6f656f56ef638973eec',
        courseName: 'CMST 4A03 - Independent Research Project',
        courseCode: '4A03',
        shortName: 'CMST 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973eec',
      },
      {
        _id: '6073b6f656f56ef638973eee',
        courseName: 'CMST 4D03 - International Communication',
        courseCode: '4D03',
        shortName: 'CMST 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973eee',
      },
      {
        _id: '6073b6f656f56ef638973ef0',
        courseName: 'CMST 4E03 - Media and Promotionalism',
        courseCode: '4E03',
        shortName: 'CMST 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ef0',
      },
      {
        _id: '6073b6f656f56ef638973ef2',
        courseName: 'CMST 4M03 - Communication, Culture and Technology',
        courseCode: '4M03',
        shortName: 'CMST 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ef2',
      },
      {
        _id: '6073b6f656f56ef638973ef4',
        courseName: 'CMST 4N03 - News Analysis: Theory and Practice',
        courseCode: '4N03',
        shortName: 'CMST 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ef4',
      },
      {
        _id: '6073b6f656f56ef638973ef6',
        courseName: 'CMST 4P03 - Social Activism and the Media',
        courseCode: '4P03',
        shortName: 'CMST 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ef6',
      },
      {
        _id: '6073b6f656f56ef638973ef8',
        courseName:
          'CMST 4Q03 - Broadcasting Transformation in A Multimedia Era',
        courseCode: '4Q03',
        shortName: 'CMST 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f656f56ef638973ef8',
      },
      {
        _id: '6073b6f756f56ef638973efa',
        courseName: 'CMST 4X03 - Communications for Campaigns and Elections',
        courseCode: '4X03',
        shortName: 'CMST 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973efa',
      },
      {
        _id: '6073b6f756f56ef638973efc',
        courseName: 'CMTYENGA 2A03 - Foundations of Community Engagement',
        courseCode: '2A03',
        shortName: 'CMTYENGA 2A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973efc',
      },
      {
        _id: '6073b6f756f56ef638973efe',
        courseName:
          'CMTYENGA 2MC3 - Design and Creation of Engaged Learning for Community Youth',
        courseCode: '2MC3',
        shortName: 'CMTYENGA 2MC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973efe',
      },
      {
        _id: '6073b6f756f56ef638973f00',
        courseName:
          'CMTYENGA 2MD3 - Community-based Learning with McMaster Children and Youth University',
        courseCode: '2MD3',
        shortName: 'CMTYENGA 2MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f00',
      },
      {
        _id: '6073b6f756f56ef638973f02',
        courseName: 'CMTYENGA 3A03 - The Art of Change',
        courseCode: '3A03',
        shortName: 'CMTYENGA 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f02',
      },
      {
        _id: '6073b6f756f56ef638973f04',
        courseName:
          'CMTYENGA 4A06 - Semester at CityLAB: Design and Dialogue Inquiry',
        courseCode: '4A06',
        shortName: 'CMTYENGA 4A06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f04',
      },
      {
        _id: '6073b6f756f56ef638973f06',
        courseName:
          'CMTYENGA 4A09 - Semester at CityLAB: Applied Project Experience',
        courseCode: '4A09',
        shortName: 'CMTYENGA 4A09',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f06',
      },
      {
        _id: '6073b6f756f56ef638973f08',
        courseName: 'COMPENG 2DI4 - Logic Design',
        courseCode: '2DI4',
        shortName: 'COMPENG 2DI4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f08',
      },
      {
        _id: '6073b6f756f56ef638973f0a',
        courseName: 'COMPENG 2DX4 - Microprocessor Systems Project',
        courseCode: '2DX4',
        shortName: 'COMPENG 2DX4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f0a',
      },
      {
        _id: '6073b6f756f56ef638973f0c',
        courseName: 'COMPENG 2SH4 - Principles of Programming',
        courseCode: '2SH4',
        shortName: 'COMPENG 2SH4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f0c',
      },
      {
        _id: '6073b6f756f56ef638973f0e',
        courseName:
          'COMPENG 2SI4 - Data Structures, Algorithms and Discrete Mathematics',
        courseCode: '2SI4',
        shortName: 'COMPENG 2SI4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 1,
        id: '6073b6f756f56ef638973f0e',
      },
      {
        _id: '6073b6f756f56ef638973f10',
        courseName: 'COMPENG 3DQ5 - Digital Systems Design',
        courseCode: '3DQ5',
        shortName: 'COMPENG 3DQ5',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f10',
      },
      {
        _id: '6073b6f756f56ef638973f12',
        courseName: 'COMPENG 3DY4 - Computer Systems Integration Project',
        courseCode: '3DY4',
        shortName: 'COMPENG 3DY4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b6f756f56ef638973f12',
      },
      {
        _id: '6073b7a456f56ef638974eb4',
        courseName: 'SMRTTECH 4SC3 - Smart Cities and Communities',
        courseCode: '4SC3',
        shortName: 'SMRTTECH 4SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974eb4',
      },
      {
        _id: '6073b7a456f56ef638974eb6',
        courseName: 'SOCPSY 1Z03 - An Introduction to Social Psychology',
        courseCode: '1Z03',
        shortName: 'SOCPSY 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 16,
        id: '6073b7a456f56ef638974eb6',
      },
      {
        _id: '6073b7a456f56ef638974eb8',
        courseName: 'SOCPSY 2B03 - Psychology of Well-Being',
        courseCode: '2B03',
        shortName: 'SOCPSY 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974eb8',
      },
      {
        _id: '6073b7a456f56ef638974eba',
        courseName: 'SOCPSY 2C03 - Social Psychology of Popular Culture',
        courseCode: '2C03',
        shortName: 'SOCPSY 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974eba',
      },
      {
        _id: '6073b7a456f56ef638974ebc',
        courseName: 'SOCPSY 2D03 - Making and Breaking Rules',
        courseCode: '2D03',
        shortName: 'SOCPSY 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974ebc',
      },
      {
        _id: '6073b7a456f56ef638974ebe',
        courseName: 'SOCPSY 2K03 - Research Methods in Social Psychology',
        courseCode: '2K03',
        shortName: 'SOCPSY 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974ebe',
      },
      {
        _id: '6073b7a456f56ef638974ec0',
        courseName: 'SOCPSY 2YY3 - Theories in Social Psychology',
        courseCode: '2YY3',
        shortName: 'SOCPSY 2YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974ec0',
      },
      {
        _id: '6073b7a456f56ef638974ec2',
        courseName: 'SOCPSY 3A03 - Mental Health',
        courseCode: '3A03',
        shortName: 'SOCPSY 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974ec2',
      },
      {
        _id: '6073b7a456f56ef638974ec4',
        courseName: 'SOCPSY 3B03 - Understanding Lived Experiences',
        courseCode: '3B03',
        shortName: 'SOCPSY 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a456f56ef638974ec4',
      },
      {
        _id: '6073b7a556f56ef638974ec6',
        courseName: 'SOCPSY 3C03 - Regimes of Social Control',
        courseCode: '3C03',
        shortName: 'SOCPSY 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ec6',
      },
      {
        _id: '6073b7a556f56ef638974ec8',
        courseName:
          'SOCPSY 3E03 - Big Ideas/Great Thinkers in Social Psychology',
        courseCode: '3E03',
        shortName: 'SOCPSY 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ec8',
      },
      {
        _id: '6073b7a556f56ef638974eca',
        courseName: 'SOCPSY 3F03 - Who Am I? Self and Identity',
        courseCode: '3F03',
        shortName: 'SOCPSY 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974eca',
      },
      {
        _id: '6073b7a556f56ef638974ecc',
        courseName: 'SOCPSY 3L03 - Advanced Research Methods',
        courseCode: '3L03',
        shortName: 'SOCPSY 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ecc',
      },
      {
        _id: '6073b7a556f56ef638974ece',
        courseName: 'SOCPSY 3RR3 - Imprisonment',
        courseCode: '3RR3',
        shortName: 'SOCPSY 3RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ece',
      },
      {
        _id: '6073b7a556f56ef638974ed0',
        courseName: 'SOCPSY 3Y03 - Social Psychology in Action',
        courseCode: '3Y03',
        shortName: 'SOCPSY 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ed0',
      },
      {
        _id: '6073b7a556f56ef638974ed2',
        courseName: 'SOCPSY 3ZZ3 - Small Worlds: Children and Childhood',
        courseCode: '3ZZ3',
        shortName: 'SOCPSY 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ed2',
      },
      {
        _id: '6073b7a556f56ef638974ed4',
        courseName: 'SOCPSY 4B03 - Special Topics in Social Psychology',
        courseCode: '4B03',
        shortName: 'SOCPSY 4B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ed4',
      },
      {
        _id: '6073b7a556f56ef638974ed6',
        courseName: 'SOCPSY 4D03 - Contemporary Social Issues',
        courseCode: '4D03',
        shortName: 'SOCPSY 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ed6',
      },
      {
        _id: '6073b7a556f56ef638974ed8',
        courseName: 'SOCPSY 4E03 - Special Topics in Social Psychology',
        courseCode: '4E03',
        shortName: 'SOCPSY 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974ed8',
      },
      {
        _id: '6073b7a556f56ef638974eda',
        courseName: 'SOCPSY 4IS3 - Independent Research',
        courseCode: '4IS3',
        shortName: 'SOCPSY 4IS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974eda',
      },
      {
        _id: '6073b7a556f56ef638974edc',
        courseName: 'SOCPSY 4IS6 A/B - Independent Research',
        courseCode: '4IS6',
        shortName: 'SOCPSY 4IS6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a556f56ef638974edc',
      },
      {
        _id: '6073b7a656f56ef638974ede',
        courseName: 'SOCPSY 4MM3 - Public Social Psychology',
        courseCode: '4MM3',
        shortName: 'SOCPSY 4MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ede',
      },
      {
        _id: '6073b7a656f56ef638974ee0',
        courseName: 'SOCPSY 4ZZ6 A/B - Social Psychology Research Project',
        courseCode: '4ZZ6',
        shortName: 'SOCPSY 4ZZ6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ee0',
      },
      {
        _id: '6073b7a656f56ef638974ee2',
        courseName: 'SOCSCI 1HS3 - Pathways into the Social Sciences',
        courseCode: '1HS3',
        shortName: 'SOCSCI 1HS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ee2',
      },
      {
        _id: '6073b7a656f56ef638974ee4',
        courseName:
          'SOCSCI 1RM3 - How do we Know? Doing Social Sciences Research',
        courseCode: '1RM3',
        shortName: 'SOCSCI 1RM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ee4',
      },
      {
        _id: '6073b7a656f56ef638974ee6',
        courseName: 'SOCSCI 1SR3 - Inquiry: Successful Societies',
        courseCode: '1SR3',
        shortName: 'SOCSCI 1SR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ee6',
      },
      {
        _id: '6073b7a656f56ef638974ee8',
        courseName: 'SOCSCI 1SS3 - Inquiry in the Social Sciences',
        courseCode: '1SS3',
        shortName: 'SOCSCI 1SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ee8',
      },
      {
        _id: '6073b7a656f56ef638974eea',
        courseName: 'SOCSCI 1T03 - Life, the University, and Everything',
        courseCode: '1T03',
        shortName: 'SOCSCI 1T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974eea',
      },
      {
        _id: '6073b7a656f56ef638974eec',
        courseName: 'SOCSCI 1TR3 - Social Sciences Foundations',
        courseCode: '1TR3',
        shortName: 'SOCSCI 1TR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974eec',
      },
      {
        _id: '6073b7a656f56ef638974eee',
        courseName:
          'SOCSCI 2AC3 - Financial & Managerial Accounting for Social Sciences',
        courseCode: '2AC3',
        shortName: 'SOCSCI 2AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974eee',
      },
      {
        _id: '6073b7a656f56ef638974ef0',
        courseName:
          'SOCSCI 2BA3 - Introduction to Business Analysis for Social Sciences',
        courseCode: '2BA3',
        shortName: 'SOCSCI 2BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ef0',
      },
      {
        _id: '6073b7a656f56ef638974ef2',
        courseName:
          'SOCSCI 2BR3 - Board and Staff Responsibilities for the Not-for-Profit Sector',
        courseCode: '2BR3',
        shortName: 'SOCSCI 2BR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a656f56ef638974ef2',
      },
      {
        _id: '6073b7a756f56ef638974ef4',
        courseName:
          'SOCSCI 2BU3 - Introduction to Business for Social Sciences',
        courseCode: '2BU3',
        shortName: 'SOCSCI 2BU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974ef4',
      },
      {
        _id: '6073b7a756f56ef638974ef6',
        courseName: 'SOCSCI 2CC3 - Children and Family in Canada',
        courseCode: '2CC3',
        shortName: 'SOCSCI 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974ef6',
      },
      {
        _id: '6073b7a756f56ef638974ef8',
        courseName:
          'SOCSCI 2EL0 - Introduction to Career Planning Through Experiential Learning',
        courseCode: '2EL0',
        shortName: 'SOCSCI 2EL0',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974ef8',
      },
      {
        _id: '6073b7a756f56ef638974efa',
        courseName:
          'SOCSCI 2EN3 - Entrepreneurial Training for Social Sciences',
        courseCode: '2EN3',
        shortName: 'SOCSCI 2EN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974efa',
      },
      {
        _id: '6073b7a756f56ef638974efc',
        courseName:
          'SOCSCI 2HR3 - Human Resources Management for Social Sciences',
        courseCode: '2HR3',
        shortName: 'SOCSCI 2HR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974efc',
      },
      {
        _id: '6073b7a756f56ef638974efe',
        courseName: 'SOCSCI 2J03 - Introduction to Statistics',
        courseCode: '2J03',
        shortName: 'SOCSCI 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974efe',
      },
      {
        _id: '6073b7a756f56ef638974f00',
        courseName:
          'SOCSCI 2LC3 - Leadership and Communications for the Not-For-Profit Sector',
        courseCode: '2LC3',
        shortName: 'SOCSCI 2LC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974f00',
      },
      {
        _id: '6073b7a756f56ef638974f02',
        courseName:
          'SOCSCI 2MR3 - Introduction to Marketing for Social Sciences',
        courseCode: '2MR3',
        shortName: 'SOCSCI 2MR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974f02',
      },
      {
        _id: '6073b7a756f56ef638974f04',
        courseName:
          'SOCSCI 2N03 - Conspiracy Theories, Fake News and Critical Investigation',
        courseCode: '2N03',
        shortName: 'SOCSCI 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974f04',
      },
      {
        _id: '6073b7a756f56ef638974f06',
        courseName: 'SOCSCI 2O03 - Canadian Children',
        courseCode: '2O03',
        shortName: 'SOCSCI 2O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974f06',
      },
      {
        _id: '6073b7a756f56ef638974f08',
        courseName:
          'SOCSCI 2OP3 - Operational Planning for the Not-For-Profit Sector',
        courseCode: '2OP3',
        shortName: 'SOCSCI 2OP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974f08',
      },
      {
        _id: '6073b7a756f56ef638974f0a',
        courseName: 'SOCSCI 2P03 - Canadian Adolescents',
        courseCode: '2P03',
        shortName: 'SOCSCI 2P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a756f56ef638974f0a',
      },
      {
        _id: '6073b7a856f56ef638974f0c',
        courseName:
          'SOCSCI 2RD3 - Resource Development for the Not-For-Profit Sector',
        courseCode: '2RD3',
        shortName: 'SOCSCI 2RD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f0c',
      },
      {
        _id: '6073b7a856f56ef638974f0e',
        courseName:
          'SOCSCI 2SP3 - Strategic and Long Range Planning for the Not-for-Profit Sector',
        courseCode: '2SP3',
        shortName: 'SOCSCI 2SP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f0e',
      },
      {
        _id: '6073b7a856f56ef638974f10',
        courseName: 'SOCSCI 2UA3 - Principles of Applied Behaviour Analysis I',
        courseCode: '2UA3',
        shortName: 'SOCSCI 2UA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f10',
      },
      {
        _id: '6073b7a856f56ef638974f12',
        courseName: 'SOCSCI 2UB3 - Principles of Applied Behaviour Analysis 2',
        courseCode: '2UB3',
        shortName: 'SOCSCI 2UB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f12',
      },
      {
        _id: '6073b7a856f56ef638974f14',
        courseName:
          'SOCSCI 2UE3 - Ethical Practice in the Field of Applied Behaviour Analysis',
        courseCode: '2UE3',
        shortName: 'SOCSCI 2UE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f14',
      },
      {
        _id: '6073b7a856f56ef638974f16',
        courseName:
          'SOCSCI 2UF3 - Professional Relationships with Families and Teams',
        courseCode: '2UF3',
        shortName: 'SOCSCI 2UF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f16',
      },
      {
        _id: '6073b7a856f56ef638974f18',
        courseName: 'SOCSCI 2UR3 - Single Subject Research Design',
        courseCode: '2UR3',
        shortName: 'SOCSCI 2UR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f18',
      },
      {
        _id: '6073b7a856f56ef638974f1a',
        courseName: 'SOCSCI 2US3 - Introduction to Autism Spectrum Disorder',
        courseCode: '2US3',
        shortName: 'SOCSCI 2US3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f1a',
      },
      {
        _id: '6073b7a856f56ef638974f1c',
        courseName: 'SOCSCI 3EL3 - Leadership Through Experiential Learning',
        courseCode: '3EL3',
        shortName: 'SOCSCI 3EL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f1c',
      },
      {
        _id: '6073b7a856f56ef638974f1e',
        courseName: 'SOCSCI 3F03 - Social Sciences in Action',
        courseCode: '3F03',
        shortName: 'SOCSCI 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f1e',
      },
      {
        _id: '6073b7a856f56ef638974f20',
        courseName: 'SOCSCI 3IF0 A/B S - Full-Time Internship',
        courseCode: '3IF0',
        shortName: 'SOCSCI 3IF0 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f20',
      },
      {
        _id: '6073b7a856f56ef638974f22',
        courseName: 'SOCSCI 3IP0 A/B S - Part-Time Internship',
        courseCode: '3IP0',
        shortName: 'SOCSCI 3IP0 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a856f56ef638974f22',
      },
      {
        _id: '6073b7a956f56ef638974f24',
        courseName:
          'SOCSCI 4ID3 - Addressing Social Problems Through Business, Engineering and the Social Sciences',
        courseCode: '4ID3',
        shortName: 'SOCSCI 4ID3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f24',
      },
      {
        _id: '6073b7a956f56ef638974f26',
        courseName:
          'SOCWORK 1AA3 - So You Think You Can Help? Introduction to Social Work I',
        courseCode: '1AA3',
        shortName: 'SOCWORK 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f26',
      },
      {
        _id: '6073b7a956f56ef638974f28',
        courseName:
          'SOCWORK 1BB3 - Re-Imagining Help: Introduction to Social Work II',
        courseCode: '1BB3',
        shortName: 'SOCWORK 1BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f28',
      },
      {
        _id: '6073b7a956f56ef638974f2a',
        courseName:
          'SOCWORK 2A06 A/B - Theory, Process and Communication Skills for Social Work',
        courseCode: '2A06',
        shortName: 'SOCWORK 2A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f2a',
      },
      {
        _id: '6073b7a956f56ef638974f2c',
        courseName: 'SOCWORK 2BB3 - Anti-Oppressive Social Work',
        courseCode: '2BB3',
        shortName: 'SOCWORK 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f2c',
      },
      {
        _id: '6073b7a956f56ef638974f2e',
        courseName: 'SOCWORK 2CC3 - Introduction to Community Practice',
        courseCode: '2CC3',
        shortName: 'SOCWORK 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f2e',
      },
      {
        _id: '6073b7a956f56ef638974f30',
        courseName: 'SOCWORK 3B03 - Transnational Lives in a Globalizing World',
        courseCode: '3B03',
        shortName: 'SOCWORK 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f30',
      },
      {
        _id: '6073b7a956f56ef638974f32',
        courseName: 'SOCWORK 3C03 - Social Aspects of Health and Illness',
        courseCode: '3C03',
        shortName: 'SOCWORK 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f32',
      },
      {
        _id: '6073b7a956f56ef638974f34',
        courseName: 'SOCWORK 3D06 A/B S - General Social Work I',
        courseCode: '3D06',
        shortName: 'SOCWORK 3D06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f34',
      },
      {
        _id: '6073b7a956f56ef638974f36',
        courseName: 'SOCWORK 3DD6 A/B S - Field Practicum I',
        courseCode: '3DD6',
        shortName: 'SOCWORK 3DD6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f36',
      },
      {
        _id: '6073b7a956f56ef638974f38',
        courseName: 'SOCWORK 3E03 - Individual Practice Across the Lifespan',
        courseCode: '3E03',
        shortName: 'SOCWORK 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f38',
      },
      {
        _id: '6073b7a956f56ef638974f3a',
        courseName: 'SOCWORK 3F03 - Social Work with Groups',
        courseCode: '3F03',
        shortName: 'SOCWORK 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7a956f56ef638974f3a',
      },
      {
        _id: '6073b7aa56f56ef638974f3c',
        courseName: 'SOCWORK 3I03 - Social Work and Indigenous Peoples',
        courseCode: '3I03',
        shortName: 'SOCWORK 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f3c',
      },
      {
        _id: '6073b7aa56f56ef638974f3e',
        courseName:
          'SOCWORK 3L03 - Violence: Social Justice Perspectives and Responses',
        courseCode: '3L03',
        shortName: 'SOCWORK 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f3e',
      },
      {
        _id: '6073b7aa56f56ef638974f40',
        courseName: 'SOCWORK 3O03 - Social Work and Sexualities',
        courseCode: '3O03',
        shortName: 'SOCWORK 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f40',
      },
      {
        _id: '6073b7aa56f56ef638974f42',
        courseName: 'SOCWORK 3S03 - Social Work, Disability and Dis/Ableism',
        courseCode: '3S03',
        shortName: 'SOCWORK 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f42',
      },
      {
        _id: '6073b7aa56f56ef638974f44',
        courseName: 'SOCWORK 3T03 - Poverty and Homelessness',
        courseCode: '3T03',
        shortName: 'SOCWORK 3T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f44',
      },
      {
        _id: '6073b7aa56f56ef638974f46',
        courseName:
          'SOCWORK 4C03 - Critical Perspectives on Race, Racialization, Racism and Colonialism in Canadian Society',
        courseCode: '4C03',
        shortName: 'SOCWORK 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f46',
      },
      {
        _id: '6073b7aa56f56ef638974f48',
        courseName: 'SOCWORK 4D06 A/B S - General Social Work II',
        courseCode: '4D06',
        shortName: 'SOCWORK 4D06 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f48',
      },
      {
        _id: '6073b7aa56f56ef638974f4a',
        courseName: 'SOCWORK 4DD6 A/B S - Field Practicum II',
        courseCode: '4DD6',
        shortName: 'SOCWORK 4DD6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f4a',
      },
      {
        _id: '6073b7aa56f56ef638974f4c',
        courseName: 'SOCWORK 4G03 - Selected Topics',
        courseCode: '4G03',
        shortName: 'SOCWORK 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f4c',
      },
      {
        _id: '6073b7aa56f56ef638974f4e',
        courseName:
          'SOCWORK 4J03 - Social Change: Social Movements and Advocacy',
        courseCode: '4J03',
        shortName: 'SOCWORK 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f4e',
      },
      {
        _id: '6073b7aa56f56ef638974f50',
        courseName:
          'SOCWORK 4QQ3 - Indigenizing Social Work Practice Approaches',
        courseCode: '4QQ3',
        shortName: 'SOCWORK 4QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f50',
      },
      {
        _id: '6073b7aa56f56ef638974f52',
        courseName:
          'SOCWORK 4R03 - Feminist Approaches to Social Work and Social Justice',
        courseCode: '4R03',
        shortName: 'SOCWORK 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7aa56f56ef638974f52',
      },
      {
        _id: '6073b7ab56f56ef638974f54',
        courseName:
          'SOCWORK 4SA3 - Critical Child Welfare: From Theory to Practice Part I',
        courseCode: '4SA3',
        shortName: 'SOCWORK 4SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f54',
      },
      {
        _id: '6073b7ab56f56ef638974f56',
        courseName:
          'SOCWORK 4SB3 - Critical Child Welfare: From Theory to Practice Part II',
        courseCode: '4SB3',
        shortName: 'SOCWORK 4SB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f56',
      },
      {
        _id: '6073b7ab56f56ef638974f58',
        courseName: 'SOCWORK 4U03 - Immigration, Settlement and Social Work',
        courseCode: '4U03',
        shortName: 'SOCWORK 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f58',
      },
      {
        _id: '6073b7ab56f56ef638974f5a',
        courseName: 'SOCWORK 4W03 - Child Welfare',
        courseCode: '4W03',
        shortName: 'SOCWORK 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f5a',
      },
      {
        _id: '6073b7ab56f56ef638974f5c',
        courseName: 'SOCWORK 4X03 - Social Work with Families',
        courseCode: '4X03',
        shortName: 'SOCWORK 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f5c',
      },
      {
        _id: '6073b7ab56f56ef638974f5e',
        courseName:
          'SOCWORK 4Y03 - Critical Issues in Mental Health & Addiction: Mad & Critical Disability Studies Perspectives for SW',
        courseCode: '4Y03',
        shortName: 'SOCWORK 4Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f5e',
      },
      {
        _id: '6073b7ab56f56ef638974f60',
        courseName:
          'SOCWORK 4ZZ3 - Social Welfare: Practice Implications and Advocacy',
        courseCode: '4ZZ3',
        shortName: 'SOCWORK 4ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f60',
      },
      {
        _id: '6073b7ab56f56ef638974f62',
        courseName: 'SCAR 1B03 - What on Earth is Religion?',
        courseCode: '1B03',
        shortName: 'SCAR 1B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f62',
      },
      {
        _id: '6073b7ab56f56ef638974f64',
        courseName:
          'SCAR 1R03 - Introduction to Anthropology: Race, Religion and Conflict',
        courseCode: '1R03',
        shortName: 'SCAR 1R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f64',
      },
      {
        _id: '6073b7ab56f56ef638974f66',
        courseName:
          'SCAR 1SC3 - The Big Questions: Introduction to Society, Culture & Religion',
        courseCode: '1SC3',
        shortName: 'SCAR 1SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f66',
      },
      {
        _id: '6073b7ab56f56ef638974f68',
        courseName: 'SCAR 2AA3 - Introduction to Modern Standard Arabic',
        courseCode: '2AA3',
        shortName: 'SCAR 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f68',
      },
      {
        _id: '6073b7ab56f56ef638974f6a',
        courseName: 'SCAR 2AB3 - Archaeology and the Bible',
        courseCode: '2AB3',
        shortName: 'SCAR 2AB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ab56f56ef638974f6a',
      },
      {
        _id: '6073b7ac56f56ef638974f6c',
        courseName: 'SCAR 2AM3 - Abraham in Judaism, Christianity, and Islam',
        courseCode: '2AM3',
        shortName: 'SCAR 2AM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f6c',
      },
      {
        _id: '6073b7ac56f56ef638974f6e',
        courseName: 'SCAR 2AP3 - Apocalypse, Then and Now',
        courseCode: '2AP3',
        shortName: 'SCAR 2AP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f6e',
      },
      {
        _id: '6073b7ac56f56ef638974f70',
        courseName: 'SCAR 2AR3 - Introduction to Modern Standard Arabic II',
        courseCode: '2AR3',
        shortName: 'SCAR 2AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f70',
      },
      {
        _id: '6073b7ac56f56ef638974f72',
        courseName: 'SCAR 2BA3 - The Bible Then and Now',
        courseCode: '2BA3',
        shortName: 'SCAR 2BA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f72',
      },
      {
        _id: '6073b7ac56f56ef638974f74',
        courseName: 'SCAR 2BB3 - Images of the Divine Feminine',
        courseCode: '2BB3',
        shortName: 'SCAR 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f74',
      },
      {
        _id: '6073b7ac56f56ef638974f76',
        courseName:
          'SCAR 2BN3 - Sex and the City in Buddhism: The Urban Life of Buddhist Nuns in North India',
        courseCode: '2BN3',
        shortName: 'SCAR 2BN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f76',
      },
      {
        _id: '6073b7ac56f56ef638974f78',
        courseName: 'SCAR 2CA3 - Religion and the Arts',
        courseCode: '2CA3',
        shortName: 'SCAR 2CA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f78',
      },
      {
        _id: '6073b7ac56f56ef638974f7a',
        courseName: 'SCAR 2CH3 - Introduction to Christianity',
        courseCode: '2CH3',
        shortName: 'SCAR 2CH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f7a',
      },
      {
        _id: '6073b7ac56f56ef638974f7c',
        courseName: 'SCAR 2EE3 - Prophets of the Bible',
        courseCode: '2EE3',
        shortName: 'SCAR 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f7c',
      },
      {
        _id: '6073b7ac56f56ef638974f7e',
        courseName: 'SCAR 2ER3 - Religion, the Body, and the Machine',
        courseCode: '2ER3',
        shortName: 'SCAR 2ER3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f7e',
      },
      {
        _id: '6073b7ac56f56ef638974f80',
        courseName: 'SCAR 2F03 - Storytelling in Asian Religions',
        courseCode: '2F03',
        shortName: 'SCAR 2F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f80',
      },
      {
        _id: '6073b7ac56f56ef638974f82',
        courseName: 'SCAR 2FF3 - Mediterranean Encounters 1500-1800',
        courseCode: '2FF3',
        shortName: 'SCAR 2FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ac56f56ef638974f82',
      },
      {
        _id: '6073b7ad56f56ef638974f84',
        courseName: 'SCAR 2G03 - Religious Themes in Modern Culture',
        courseCode: '2G03',
        shortName: 'SCAR 2G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f84',
      },
      {
        _id: '6073b7ad56f56ef638974f86',
        courseName: 'SCAR 2GB3 - Great Books in Asian Religions',
        courseCode: '2GB3',
        shortName: 'SCAR 2GB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f86',
      },
      {
        _id: '6073b7ad56f56ef638974f88',
        courseName: 'SCAR 2GG3 - Who was Jesus?',
        courseCode: '2GG3',
        shortName: 'SCAR 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f88',
      },
      {
        _id: '6073b7ad56f56ef638974f8a',
        courseName: 'SCAR 2GR3 - Evil',
        courseCode: '2GR3',
        shortName: 'SCAR 2GR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f8a',
      },
      {
        _id: '6073b7ad56f56ef638974f8c',
        courseName: 'SCAR 2HB3 - From Creation to Exile',
        courseCode: '2HB3',
        shortName: 'SCAR 2HB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f8c',
      },
      {
        _id: '6073b7ad56f56ef638974f8e',
        courseName: 'SCAR 2HC3 - Introduction to Biblical Hebrew I',
        courseCode: '2HC3',
        shortName: 'SCAR 2HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f8e',
      },
      {
        _id: '6073b7ad56f56ef638974f90',
        courseName: 'SCAR 2HD3 - Introduction to Biblical Hebrew II',
        courseCode: '2HD3',
        shortName: 'SCAR 2HD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f90',
      },
      {
        _id: '6073b7ad56f56ef638974f92',
        courseName: 'SCAR 2HR3 - Humour and Religion',
        courseCode: '2HR3',
        shortName: 'SCAR 2HR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f92',
      },
      {
        _id: '6073b7ad56f56ef638974f94',
        courseName: 'SCAR 2IR3 - Hollywood/Bollywood and Indian Religions',
        courseCode: '2IR3',
        shortName: 'SCAR 2IR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f94',
      },
      {
        _id: '6073b7ad56f56ef638974f96',
        courseName: 'SCAR 2J03 - Introduction To Judaism',
        courseCode: '2J03',
        shortName: 'SCAR 2J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f96',
      },
      {
        _id: '6073b7ad56f56ef638974f98',
        courseName: 'SCAR 2K03 - Introduction to Buddhism',
        courseCode: '2K03',
        shortName: 'SCAR 2K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ad56f56ef638974f98',
      },
      {
        _id: '6073b7ae56f56ef638974f9a',
        courseName: 'SCAR 2LL3 - God and Philosophy',
        courseCode: '2LL3',
        shortName: 'SCAR 2LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974f9a',
      },
      {
        _id: '6073b7ae56f56ef638974f9c',
        courseName: 'SCAR 2M03 - Death and Dying: Comparative Views',
        courseCode: '2M03',
        shortName: 'SCAR 2M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974f9c',
      },
      {
        _id: '6073b7ae56f56ef638974f9e',
        courseName: 'SCAR 2MT3 - Asian Meditation Traditions',
        courseCode: '2MT3',
        shortName: 'SCAR 2MT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974f9e',
      },
      {
        _id: '6073b7ae56f56ef638974fa0',
        courseName: 'SCAR 2MW3 - Religion, Magic and Witchcraft',
        courseCode: '2MW3',
        shortName: 'SCAR 2MW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fa0',
      },
      {
        _id: '6073b7ae56f56ef638974fa2',
        courseName: 'SCAR 2N03 - Death and Dying: The Western Experience',
        courseCode: '2N03',
        shortName: 'SCAR 2N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fa2',
      },
      {
        _id: '6073b7ae56f56ef638974fa4',
        courseName: 'SCAR 2NT3 - The New Testament',
        courseCode: '2NT3',
        shortName: 'SCAR 2NT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fa4',
      },
      {
        _id: '6073b7ae56f56ef638974fa6',
        courseName: 'SCAR 2Q03 - Introduction to Islam',
        courseCode: '2Q03',
        shortName: 'SCAR 2Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fa6',
      },
      {
        _id: '6073b7ae56f56ef638974fa8',
        courseName: 'SCAR 2QQ3 - Cults, Conspiracies and Close Encounters',
        courseCode: '2QQ3',
        shortName: 'SCAR 2QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fa8',
      },
      {
        _id: '6073b7ae56f56ef638974faa',
        courseName: 'SCAR 2RD3 - Religion and Diversity',
        courseCode: '2RD3',
        shortName: 'SCAR 2RD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974faa',
      },
      {
        _id: '6073b7ae56f56ef638974fac',
        courseName: 'SCAR 2RN3 - Religion in the News',
        courseCode: '2RN3',
        shortName: 'SCAR 2RN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fac',
      },
      {
        _id: '6073b7ae56f56ef638974fae',
        courseName: 'SCAR 2SA3 - Encountering the Sacred',
        courseCode: '2SA3',
        shortName: 'SCAR 2SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fae',
      },
      {
        _id: '6073b7ae56f56ef638974fb0',
        courseName: 'SCAR 2SG3 - Spirits, Ghosts and Demons',
        courseCode: '2SG3',
        shortName: 'SCAR 2SG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ae56f56ef638974fb0',
      },
      {
        _id: '6073b7af56f56ef638974fb2',
        courseName: 'SCAR 2SP3 - Sport and/as Religion',
        courseCode: '2SP3',
        shortName: 'SCAR 2SP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fb2',
      },
      {
        _id: '6073b7af56f56ef638974fb4',
        courseName: 'SCAR 2TA3 - Islam in North America',
        courseCode: '2TA3',
        shortName: 'SCAR 2TA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fb4',
      },
      {
        _id: '6073b7af56f56ef638974fb6',
        courseName:
          'SCAR 2TT3 - Religion and Popular Culture in Contemporary Japan',
        courseCode: '2TT3',
        shortName: 'SCAR 2TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fb6',
      },
      {
        _id: '6073b7af56f56ef638974fb8',
        courseName: 'SCAR 2UD3 - Utopias, Dystopias',
        courseCode: '2UD3',
        shortName: 'SCAR 2UD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fb8',
      },
      {
        _id: '6073b7af56f56ef638974fba',
        courseName: 'SCAR 2VR3 - Violence and Religion',
        courseCode: '2VR3',
        shortName: 'SCAR 2VR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fba',
      },
      {
        _id: '6073b7af56f56ef638974fbc',
        courseName: 'SCAR 2VV3 - The Bible as Literature',
        courseCode: '2VV3',
        shortName: 'SCAR 2VV3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fbc',
      },
      {
        _id: '6073b7af56f56ef638974fbe',
        courseName:
          'SCAR 2WW3 - Health, Healing and Religion: Western Perspectives',
        courseCode: '2WW3',
        shortName: 'SCAR 2WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fbe',
      },
      {
        _id: '6073b7af56f56ef638974fc0',
        courseName:
          'SCAR 2WX3 - Health, Healing and Religion: Comparative Views',
        courseCode: '2WX3',
        shortName: 'SCAR 2WX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fc0',
      },
      {
        _id: '6073b7af56f56ef638974fc2',
        courseName: 'SCAR 2YY3 - The Bible and Film',
        courseCode: '2YY3',
        shortName: 'SCAR 2YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fc2',
      },
      {
        _id: '6073b7af56f56ef638974fc4',
        courseName: 'SCAR 3AA3 - Intermediate Modern Standard Arabic',
        courseCode: '3AA3',
        shortName: 'SCAR 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fc4',
      },
      {
        _id: '6073b7af56f56ef638974fc6',
        courseName: 'SCAR 3AR3 - Culture and Religion',
        courseCode: '3AR3',
        shortName: 'SCAR 3AR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fc6',
      },
      {
        _id: '6073b7af56f56ef638974fc8',
        courseName: 'SCAR 3BE3 - Buddhist Ethics',
        courseCode: '3BE3',
        shortName: 'SCAR 3BE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7af56f56ef638974fc8',
      },
      {
        _id: '6073b7b056f56ef638974fca',
        courseName: 'SCAR 3BW3 - Women in the Biblical Tradition',
        courseCode: '3BW3',
        shortName: 'SCAR 3BW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fca',
      },
      {
        _id: '6073b7b056f56ef638974fcc',
        courseName: 'SCAR 3C03 - Islam in the Modern World',
        courseCode: '3C03',
        shortName: 'SCAR 3C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fcc',
      },
      {
        _id: '6073b7b056f56ef638974fce',
        courseName: 'SCAR 3CC3 - Religion and Politics',
        courseCode: '3CC3',
        shortName: 'SCAR 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fce',
      },
      {
        _id: '6073b7b056f56ef638974fd0',
        courseName: 'SCAR 3DD3 - Jews and Jesus',
        courseCode: '3DD3',
        shortName: 'SCAR 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fd0',
      },
      {
        _id: '6073b7b056f56ef638974fd2',
        courseName: 'SCAR 3E03 - Japanese Film and Religion',
        courseCode: '3E03',
        shortName: 'SCAR 3E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fd2',
      },
      {
        _id: '6073b7b056f56ef638974fd4',
        courseName: 'SCAR 3EE3 - Sacred Journeys',
        courseCode: '3EE3',
        shortName: 'SCAR 3EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fd4',
      },
      {
        _id: '6073b7b056f56ef638974fd6',
        courseName: 'SCAR 3EP3 - Jewish Ethics and Politics',
        courseCode: '3EP3',
        shortName: 'SCAR 3EP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fd6',
      },
      {
        _id: '6073b7b056f56ef638974fd8',
        courseName: 'SCAR 3F03 - Approaches to the Study of Religion',
        courseCode: '3F03',
        shortName: 'SCAR 3F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fd8',
      },
      {
        _id: '6073b7b056f56ef638974fda',
        courseName: 'SCAR 3FF3 - Gender and Religion',
        courseCode: '3FF3',
        shortName: 'SCAR 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b056f56ef638974fda',
      },
      {
        _id: '6073b7b156f56ef638974fdc',
        courseName:
          'SCAR 3GH3 - Interdisciplinary Global Health Field Course: Maternal and Infant Health in Morocco',
        courseCode: '3GH3',
        shortName: 'SCAR 3GH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fdc',
      },
      {
        _id: '6073b7b156f56ef638974fde',
        courseName: 'SCAR 3HC3 - Intermediate Hebrew I',
        courseCode: '3HC3',
        shortName: 'SCAR 3HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fde',
      },
      {
        _id: '6073b7b156f56ef638974fe0',
        courseName: 'SCAR 3HD3 - Intermediate Hebrew II',
        courseCode: '3HD3',
        shortName: 'SCAR 3HD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fe0',
      },
      {
        _id: '6073b7b156f56ef638974fe2',
        courseName: 'SCAR 3JF3 - Jewish Life Through Film',
        courseCode: '3JF3',
        shortName: 'SCAR 3JF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fe2',
      },
      {
        _id: '6073b7b156f56ef638974fe4',
        courseName: 'SCAR 3JJ3 - Jesus, Justice and Contemporary Culture',
        courseCode: '3JJ3',
        shortName: 'SCAR 3JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fe4',
      },
      {
        _id: '6073b7b156f56ef638974fe6',
        courseName: 'SCAR 3LL3 - Religion and Human Nature',
        courseCode: '3LL3',
        shortName: 'SCAR 3LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fe6',
      },
      {
        _id: '6073b7b156f56ef638974fe8',
        courseName: 'SCAR 3PA3 - The Birth of Christianity',
        courseCode: '3PA3',
        shortName: 'SCAR 3PA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fe8',
      },
      {
        _id: '6073b7b156f56ef638974fea',
        courseName:
          'SCAR 3R03 - Death and the Afterlife in Early Judaism and Christianity',
        courseCode: '3R03',
        shortName: 'SCAR 3R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b156f56ef638974fea',
      },
      {
        _id: '6073b7b256f56ef638974fec',
        courseName: 'SCAR 3RB3 - Ethnicity, Race, and the Bible',
        courseCode: '3RB3',
        shortName: 'SCAR 3RB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974fec',
      },
      {
        _id: '6073b7b256f56ef638974fee',
        courseName: 'SCAR 3RL3 - Religion and Law',
        courseCode: '3RL3',
        shortName: 'SCAR 3RL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974fee',
      },
      {
        _id: '6073b7b256f56ef638974ff0',
        courseName: 'SCAR 3S03 - The East Asian Religious Tradition',
        courseCode: '3S03',
        shortName: 'SCAR 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ff0',
      },
      {
        _id: '6073b7b256f56ef638974ff2',
        courseName: 'SCAR 3SA6 A/B - Introduction to Sanskrit Grammar',
        courseCode: '3SA6',
        shortName: 'SCAR 3SA6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ff2',
      },
      {
        _id: '6073b7b256f56ef638974ff4',
        courseName: 'SCAR 3U03 - The Buddhist Tradition in India',
        courseCode: '3U03',
        shortName: 'SCAR 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ff4',
      },
      {
        _id: '6073b7b256f56ef638974ff6',
        courseName: 'SCAR 3US3 - Uses and Abuses of the Bible',
        courseCode: '3US3',
        shortName: 'SCAR 3US3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ff6',
      },
      {
        _id: '6073b7b256f56ef638974ff8',
        courseName: 'SCAR 3UU3 - Buddhism in East Asia',
        courseCode: '3UU3',
        shortName: 'SCAR 3UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ff8',
      },
      {
        _id: '6073b7b256f56ef638974ffa',
        courseName: 'SCAR 3X03 - Mysticism',
        courseCode: '3X03',
        shortName: 'SCAR 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ffa',
      },
      {
        _id: '6073b7b256f56ef638974ffc',
        courseName: 'SCAR 3Y03 - Love',
        courseCode: '3Y03',
        shortName: 'SCAR 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ffc',
      },
      {
        _id: '6073b7b256f56ef638974ffe',
        courseName: 'SCAR 3ZZ3 - Judaism in the Modern World',
        courseCode: '3ZZ3',
        shortName: 'SCAR 3ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b256f56ef638974ffe',
      },
      {
        _id: '6073b7b356f56ef638975000',
        courseName:
          'SCAR 4AE3 A/B - Academic Engagement in Society, Culture, Religion',
        courseCode: '4AE3',
        shortName: 'SCAR 4AE3 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975000',
      },
      {
        _id: '6073b7b356f56ef638975002',
        courseName: 'SCAR 4B06 A/B - Readings in Sanskrit Texts',
        courseCode: '4B06',
        shortName: 'SCAR 4B06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975002',
      },
      {
        _id: '6073b7b356f56ef638975004',
        courseName:
          'SCAR 4Q03 - Advanced Readings in Society, Culture and Religion',
        courseCode: '4Q03',
        shortName: 'SCAR 4Q03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975004',
      },
      {
        _id: '6073b7b356f56ef638975006',
        courseName: 'SCAR 4R06 A/B - Honours Thesis',
        courseCode: '4R06',
        shortName: 'SCAR 4R06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975006',
      },
      {
        _id: '6073b7b356f56ef638975008',
        courseName: 'SCAR 4SC3 - Society, Culture, Religion Seminar',
        courseCode: '4SC3',
        shortName: 'SCAR 4SC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975008',
      },
      {
        _id: '6073b7b356f56ef63897500a',
        courseName:
          'SOCIOL 1C03 - Canadian Society: Social Problems, Social Policy, and the Law',
        courseCode: '1C03',
        shortName: 'SOCIOL 1C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef63897500a',
      },
      {
        _id: '6073b7b356f56ef63897500c',
        courseName: 'SOCIOL 1Z03 - An Introduction to Sociology',
        courseCode: '1Z03',
        shortName: 'SOCIOL 1Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef63897500c',
      },
      {
        _id: '6073b7b356f56ef63897500e',
        courseName: 'SOCIOL 2BB3 - Sociology of Deviance',
        courseCode: '2BB3',
        shortName: 'SOCIOL 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef63897500e',
      },
      {
        _id: '6073b7b356f56ef638975010',
        courseName: 'SOCIOL 2CC3 - Constructing Deviance',
        courseCode: '2CC3',
        shortName: 'SOCIOL 2CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975010',
      },
      {
        _id: '6073b7b356f56ef638975012',
        courseName: 'SOCIOL 2DD3 - Immigration and the Canadian Mosaic',
        courseCode: '2DD3',
        shortName: 'SOCIOL 2DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975012',
      },
      {
        _id: '6073b7b356f56ef638975014',
        courseName:
          'SOCIOL 2EE3 - Introduction to Indigenous-Settler Relations in Canada',
        courseCode: '2EE3',
        shortName: 'SOCIOL 2EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b356f56ef638975014',
      },
      {
        _id: '6073b7b456f56ef638975016',
        courseName: 'SOCIOL 2FF3 - The Sociology of â€˜Raceâ€™ and Ethnicity',
        courseCode: '2FF3',
        shortName: 'SOCIOL 2FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975016',
      },
      {
        _id: '6073b7b456f56ef638975018',
        courseName: 'SOCIOL 2GG3 - Sociology of Education',
        courseCode: '2GG3',
        shortName: 'SOCIOL 2GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975018',
      },
      {
        _id: '6073b7b456f56ef63897501a',
        courseName: 'SOCIOL 2HH3 - Sociology of Gender',
        courseCode: '2HH3',
        shortName: 'SOCIOL 2HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef63897501a',
      },
      {
        _id: '6073b7b456f56ef63897501c',
        courseName: 'SOCIOL 2I03 - Sociology of Organizations',
        courseCode: '2I03',
        shortName: 'SOCIOL 2I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef63897501c',
      },
      {
        _id: '6073b7b456f56ef63897501e',
        courseName: 'SOCIOL 2JJ3 - Race, Class, Gender, and Sexuality',
        courseCode: '2JJ3',
        shortName: 'SOCIOL 2JJ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef63897501e',
      },
      {
        _id: '6073b7b456f56ef638975020',
        courseName:
          'SOCIOL 2KK3 - Introduction to Classical Sociological Theory',
        courseCode: '2KK3',
        shortName: 'SOCIOL 2KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975020',
      },
      {
        _id: '6073b7b456f56ef638975022',
        courseName: 'SOCIOL 2L03 - Media Institutions',
        courseCode: '2L03',
        shortName: 'SOCIOL 2L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975022',
      },
      {
        _id: '6073b7b456f56ef638975024',
        courseName:
          'SOCIOL 2LL3 - Introduction to Contemporary Sociological Theory',
        courseCode: '2LL3',
        shortName: 'SOCIOL 2LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975024',
      },
      {
        _id: '6073b7b456f56ef638975026',
        courseName: 'SOCIOL 2PP3 - Sociology of Families',
        courseCode: '2PP3',
        shortName: 'SOCIOL 2PP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975026',
      },
      {
        _id: '6073b7b456f56ef638975028',
        courseName:
          'SOCIOL 2QQ3 - Dynamics and Transitions in Intimate Relationships and Families',
        courseCode: '2QQ3',
        shortName: 'SOCIOL 2QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef638975028',
      },
      {
        _id: '6073b7b456f56ef63897502a',
        courseName: 'SOCIOL 2R03 - Perspectives on Social Inequality',
        courseCode: '2R03',
        shortName: 'SOCIOL 2R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef63897502a',
      },
      {
        _id: '6073b7b456f56ef63897502c',
        courseName: 'SOCIOL 2RR3 - Case Studies of Social Inequality',
        courseCode: '2RR3',
        shortName: 'SOCIOL 2RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b456f56ef63897502c',
      },
      {
        _id: '6073b7b556f56ef63897502e',
        courseName: 'SOCIOL 2SS3 - Sociology of Work and Occupations',
        courseCode: '2SS3',
        shortName: 'SOCIOL 2SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef63897502e',
      },
      {
        _id: '6073b7b556f56ef638975030',
        courseName: 'SOCIOL 2T03 - Sociology of Sport',
        courseCode: '2T03',
        shortName: 'SOCIOL 2T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef638975030',
      },
      {
        _id: '6073b7b556f56ef638975032',
        courseName: 'SOCIOL 2TA3 - Islam In North America',
        courseCode: '2TA3',
        shortName: 'SOCIOL 2TA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef638975032',
      },
      {
        _id: '6073b7b556f56ef638975034',
        courseName: 'SOCIOL 2TT3 - Environmental Sociology',
        courseCode: '2TT3',
        shortName: 'SOCIOL 2TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef638975034',
      },
      {
        _id: '6073b7b556f56ef638975036',
        courseName: 'SOCIOL 2UU3 - Indigenous Ontologies and Ways of Knowing',
        courseCode: '2UU3',
        shortName: 'SOCIOL 2UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef638975036',
      },
      {
        _id: '6073b7b556f56ef638975038',
        courseName: 'SOCIOL 2Z03 - Introduction to Sociological Research',
        courseCode: '2Z03',
        shortName: 'SOCIOL 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef638975038',
      },
      {
        _id: '6073b7b556f56ef63897503a',
        courseName:
          'SOCIOL 3A03 - Advanced Analysis of Classical Sociological Theory',
        courseCode: '3A03',
        shortName: 'SOCIOL 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef63897503a',
      },
      {
        _id: '6073b7b556f56ef63897503c',
        courseName: 'SOCIOL 3AC3 - Sociology of Sexualities',
        courseCode: '3AC3',
        shortName: 'SOCIOL 3AC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef63897503c',
      },
      {
        _id: '6073b7b556f56ef63897503e',
        courseName:
          'SOCIOL 3B03 - Selected Topics in the Sociology of Education',
        courseCode: '3B03',
        shortName: 'SOCIOL 3B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef63897503e',
      },
      {
        _id: '6073b7b556f56ef638975040',
        courseName: 'SOCIOL 3CC3 - Sociology of the Family and the Life Cycle',
        courseCode: '3CC3',
        shortName: 'SOCIOL 3CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b556f56ef638975040',
      },
      {
        _id: '6073b7b656f56ef638975042',
        courseName:
          'SOCIOL 3D03 - Special Topics in the Sociology of the Family',
        courseCode: '3D03',
        shortName: 'SOCIOL 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975042',
      },
      {
        _id: '6073b7b656f56ef638975044',
        courseName: 'SOCIOL 3FF3 - Introductory Statistics for Sociology',
        courseCode: '3FF3',
        shortName: 'SOCIOL 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975044',
      },
      {
        _id: '6073b7b656f56ef638975046',
        courseName: 'SOCIOL 3G03 - Sociology of Health Care',
        courseCode: '3G03',
        shortName: 'SOCIOL 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975046',
      },
      {
        _id: '6073b7b656f56ef638975048',
        courseName: 'SOCIOL 3GG3 - Special Topics in the Sociology of Deviance',
        courseCode: '3GG3',
        shortName: 'SOCIOL 3GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975048',
      },
      {
        _id: '6073b7b656f56ef63897504a',
        courseName: 'SOCIOL 3HH3 - Sociology of Health',
        courseCode: '3HH3',
        shortName: 'SOCIOL 3HH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef63897504a',
      },
      {
        _id: '6073b7b656f56ef63897504c',
        courseName: 'SOCIOL 3J03 - Special Topics in Sociological Analysis I',
        courseCode: '3J03',
        shortName: 'SOCIOL 3J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef63897504c',
      },
      {
        _id: '6073b7b656f56ef63897504e',
        courseName: 'SOCIOL 3K03 - Special Topics in Sociological Analysis II',
        courseCode: '3K03',
        shortName: 'SOCIOL 3K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef63897504e',
      },
      {
        _id: '6073b7b656f56ef638975050',
        courseName:
          'SOCIOL 3KK3 - Genocide: Sociological and Political Perspectives',
        courseCode: '3KK3',
        shortName: 'SOCIOL 3KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975050',
      },
      {
        _id: '6073b7b656f56ef638975052',
        courseName: 'SOCIOL 3MM3 - Political Sociology',
        courseCode: '3MM3',
        shortName: 'SOCIOL 3MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975052',
      },
      {
        _id: '6073b7b656f56ef638975054',
        courseName: 'SOCIOL 3NN3 - Popular Culture and Inequality',
        courseCode: '3NN3',
        shortName: 'SOCIOL 3NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975054',
      },
      {
        _id: '6073b7b656f56ef638975056',
        courseName: 'SOCIOL 3O03 - Qualitative Research Methods',
        courseCode: '3O03',
        shortName: 'SOCIOL 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b656f56ef638975056',
      },
      {
        _id: '6073b7b756f56ef638975058',
        courseName:
          'SOCIOL 3P03 - Advanced Analysis of Contemporary Sociological Theory',
        courseCode: '3P03',
        shortName: 'SOCIOL 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef638975058',
      },
      {
        _id: '6073b7b756f56ef63897505a',
        courseName: 'SOCIOL 3QQ3 - Sociology Students and the Work World',
        courseCode: '3QQ3',
        shortName: 'SOCIOL 3QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef63897505a',
      },
      {
        _id: '6073b7b756f56ef63897505c',
        courseName: 'SOCIOL 3RR3 - Imprisonment',
        courseCode: '3RR3',
        shortName: 'SOCIOL 3RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef63897505c',
      },
      {
        _id: '6073b7b756f56ef63897505e',
        courseName: 'SOCIOL 3SS3 - Sociology of Judgement',
        courseCode: '3SS3',
        shortName: 'SOCIOL 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef63897505e',
      },
      {
        _id: '6073b7b756f56ef638975060',
        courseName: 'SOCIOL 3U03 - Sociology of Sexualities',
        courseCode: '3U03',
        shortName: 'SOCIOL 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef638975060',
      },
      {
        _id: '6073b7b756f56ef638975062',
        courseName: 'SOCIOL 3W03 - Historical Methods in Sociology',
        courseCode: '3W03',
        shortName: 'SOCIOL 3W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef638975062',
      },
      {
        _id: '6073b7b756f56ef638975064',
        courseName: 'SOCIOL 3X03 - Sociology of Aging',
        courseCode: '3X03',
        shortName: 'SOCIOL 3X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef638975064',
      },
      {
        _id: '6073b7b756f56ef638975066',
        courseName: 'SOCIOL 3YY3 - Sociology of Globalization',
        courseCode: '3YY3',
        shortName: 'SOCIOL 3YY3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef638975066',
      },
      {
        _id: '6073b7b756f56ef638975068',
        courseName: 'SOCIOL 3Z03 - Ethnic Relations',
        courseCode: '3Z03',
        shortName: 'SOCIOL 3Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef638975068',
      },
      {
        _id: '6073b7b756f56ef63897506a',
        courseName: 'SOCIOL 4A03 - Ethnic/Racial Tensions',
        courseCode: '4A03',
        shortName: 'SOCIOL 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef63897506a',
      },
      {
        _id: '6073b7b756f56ef63897506c',
        courseName:
          'SOCIOL 4AA3 - Selected Topics in the Sociology of the Family',
        courseCode: '4AA3',
        shortName: 'SOCIOL 4AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b756f56ef63897506c',
      },
      {
        _id: '6073b7b856f56ef63897506e',
        courseName:
          'SOCIOL 4BB3 - Selected Topics in the Sociology of Education',
        courseCode: '4BB3',
        shortName: 'SOCIOL 4BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef63897506e',
      },
      {
        _id: '6073b7b856f56ef638975070',
        courseName: 'SOCIOL 4DD3 - Social Movements and Social Change',
        courseCode: '4DD3',
        shortName: 'SOCIOL 4DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975070',
      },
      {
        _id: '6073b7b856f56ef638975072',
        courseName: 'SOCIOL 4E03 - Self and Identity',
        courseCode: '4E03',
        shortName: 'SOCIOL 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975072',
      },
      {
        _id: '6073b7b856f56ef638975074',
        courseName: 'SOCIOL 4EE3 - Selected Topics in the Sociology of Culture',
        courseCode: '4EE3',
        shortName: 'SOCIOL 4EE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975074',
      },
      {
        _id: '6073b7b856f56ef638975076',
        courseName:
          'SOCIOL 4FF3 - Applications of Quantitative Methods in the Social Sciences',
        courseCode: '4FF3',
        shortName: 'SOCIOL 4FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975076',
      },
      {
        _id: '6073b7b856f56ef638975078',
        courseName:
          'SOCIOL 4G03 - Advanced Topics in the Sociology of Health and Illness',
        courseCode: '4G03',
        shortName: 'SOCIOL 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975078',
      },
      {
        _id: '6073b7b856f56ef63897507a',
        courseName: 'SOCIOL 4GG3 - Special Topics in the Sociology of Deviance',
        courseCode: '4GG3',
        shortName: 'SOCIOL 4GG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef63897507a',
      },
      {
        _id: '6073b7b856f56ef63897507c',
        courseName: 'SOCIOL 4J03 - Selected Topics in Sociology I',
        courseCode: '4J03',
        shortName: 'SOCIOL 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef63897507c',
      },
      {
        _id: '6073b7b856f56ef63897507e',
        courseName: 'SOCIOL 4K03 - Selected Topics in Sociology II',
        courseCode: '4K03',
        shortName: 'SOCIOL 4K03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef63897507e',
      },
      {
        _id: '6073b7b856f56ef638975080',
        courseName:
          'SOCIOL 4KK3 - Sociology through Community Engagement and Service',
        courseCode: '4KK3',
        shortName: 'SOCIOL 4KK3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975080',
      },
      {
        _id: '6073b7b856f56ef638975082',
        courseName:
          'SOCIOL 4LL3 - Selected Topics in the Sociology of Immigration',
        courseCode: '4LL3',
        shortName: 'SOCIOL 4LL3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b856f56ef638975082',
      },
      {
        _id: '6073b7b956f56ef638975084',
        courseName: 'SOCIOL 4M03 - Directed Research I for Honours Students',
        courseCode: '4M03',
        shortName: 'SOCIOL 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975084',
      },
      {
        _id: '6073b7b956f56ef638975086',
        courseName: 'SOCIOL 4MM3 - Public Social Psychology',
        courseCode: '4MM3',
        shortName: 'SOCIOL 4MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975086',
      },
      {
        _id: '6073b7b956f56ef638975088',
        courseName:
          'SOCIOL 4MM6 A/B S - Directed Research for Honours Students',
        courseCode: '4MM6',
        shortName: 'SOCIOL 4MM6 A/B S',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975088',
      },
      {
        _id: '6073b7b956f56ef63897508a',
        courseName: 'SOCIOL 4N03 - Directed Research II for Honours Students',
        courseCode: '4N03',
        shortName: 'SOCIOL 4N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef63897508a',
      },
      {
        _id: '6073b7b956f56ef63897508c',
        courseName: 'SOCIOL 4QQ3 - Women, Sexuality and the Welfare State',
        courseCode: '4QQ3',
        shortName: 'SOCIOL 4QQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef63897508c',
      },
      {
        _id: '6073b7b956f56ef63897508e',
        courseName: 'SOCIOL 4R03 - Individual and Society',
        courseCode: '4R03',
        shortName: 'SOCIOL 4R03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef63897508e',
      },
      {
        _id: '6073b7b956f56ef638975090',
        courseName: 'SOCIOL 4RR3 - Indigenous Peoples and Canada',
        courseCode: '4RR3',
        shortName: 'SOCIOL 4RR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975090',
      },
      {
        _id: '6073b7b956f56ef638975092',
        courseName: 'SOCIOL 4SR3 - Topics in the Sociology of Religion',
        courseCode: '4SR3',
        shortName: 'SOCIOL 4SR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975092',
      },
      {
        _id: '6073b7b956f56ef638975094',
        courseName: 'SOCIOL 4SS3 - The Sociology of the 1960s',
        courseCode: '4SS3',
        shortName: 'SOCIOL 4SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975094',
      },
      {
        _id: '6073b7b956f56ef638975096',
        courseName: 'SOCIOL 4TT3 - The Sociology of Mass Media',
        courseCode: '4TT3',
        shortName: 'SOCIOL 4TT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975096',
      },
      {
        _id: '6073b7b956f56ef638975098',
        courseName: 'SOCIOL 4U03 - Special Topics in the Sociology of Women',
        courseCode: '4U03',
        shortName: 'SOCIOL 4U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7b956f56ef638975098',
      },
      {
        _id: '6073b7ba56f56ef63897509a',
        courseName: 'SOCIOL 4UU3 - Global Family and Sexual Politics',
        courseCode: '4UU3',
        shortName: 'SOCIOL 4UU3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef63897509a',
      },
      {
        _id: '6073b7ba56f56ef63897509c',
        courseName:
          'SOCIOL 4V03 - Issues in the Sociology of Occupations and the Professions',
        courseCode: '4V03',
        shortName: 'SOCIOL 4V03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef63897509c',
      },
      {
        _id: '6073b7ba56f56ef63897509e',
        courseName: 'SOCIOL 4W03 - Social Problems',
        courseCode: '4W03',
        shortName: 'SOCIOL 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef63897509e',
      },
      {
        _id: '6073b7ba56f56ef6389750a0',
        courseName: 'SOCIOL 4XX3 - Sociology of At-Risk Youth',
        courseCode: '4XX3',
        shortName: 'SOCIOL 4XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750a0',
      },
      {
        _id: '6073b7ba56f56ef6389750a2',
        courseName:
          'SFWRENG 2AA4 - Software Design I - Introduction to Software Development',
        courseCode: '2AA4',
        shortName: 'SFWRENG 2AA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750a2',
      },
      {
        _id: '6073b7ba56f56ef6389750a4',
        courseName: 'SFWRENG 2C03 - Data Structures and Algorithms',
        courseCode: '2C03',
        shortName: 'SFWRENG 2C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750a4',
      },
      {
        _id: '6073b7ba56f56ef6389750a6',
        courseName: 'SFWRENG 2DA4 - Digital Systems and Interfacing',
        courseCode: '2DA4',
        shortName: 'SFWRENG 2DA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750a6',
      },
      {
        _id: '6073b7ba56f56ef6389750a8',
        courseName: 'SFWRENG 2DM3 - Discrete Mathematics with Applications I',
        courseCode: '2DM3',
        shortName: 'SFWRENG 2DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750a8',
      },
      {
        _id: '6073b7ba56f56ef6389750aa',
        courseName: 'SFWRENG 2FA3 - Discrete Mathematics and Applications II',
        courseCode: '2FA3',
        shortName: 'SFWRENG 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750aa',
      },
      {
        _id: '6073b7ba56f56ef6389750ac',
        courseName: 'SFWRENG 2GA3 - Computer Architecture',
        courseCode: '2GA3',
        shortName: 'SFWRENG 2GA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750ac',
      },
      {
        _id: '6073b7ba56f56ef6389750ae',
        courseName:
          'SFWRENG 2MD3 - Data Structures, Algorithms, and Language Concepts for Mechatronics',
        courseCode: '2MD3',
        shortName: 'SFWRENG 2MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7ba56f56ef6389750ae',
      },
      {
        _id: '6073b7bb56f56ef6389750b0',
        courseName: 'SFWRENG 2MP3 - Programming for Mechatronics',
        courseCode: '2MP3',
        shortName: 'SFWRENG 2MP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750b0',
      },
      {
        _id: '6073b7bb56f56ef6389750b2',
        courseName: 'SFWRENG 2S03 - Principles of Programming',
        courseCode: '2S03',
        shortName: 'SFWRENG 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750b2',
      },
      {
        _id: '6073b7bb56f56ef6389750b4',
        courseName:
          'SFWRENG 2XA3 - Software Engineering Practice and Experience: Software Development Skills',
        courseCode: '2XA3',
        shortName: 'SFWRENG 2XA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750b4',
      },
      {
        _id: '6073b7bb56f56ef6389750b6',
        courseName:
          'SFWRENG 2XB3 - Software Engineering Practice and Experience: Binding Theory to Practice',
        courseCode: '2XB3',
        shortName: 'SFWRENG 2XB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750b6',
      },
      {
        _id: '6073b7bb56f56ef6389750b8',
        courseName: 'SFWRENG 3A04 - Software Design III - Large System Design',
        courseCode: '3A04',
        shortName: 'SFWRENG 3A04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750b8',
      },
      {
        _id: '6073b7bb56f56ef6389750ba',
        courseName:
          'SFWRENG 3BB4 - Software Design II - Concurrent System Design',
        courseCode: '3BB4',
        shortName: 'SFWRENG 3BB4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750ba',
      },
      {
        _id: '6073b7bb56f56ef6389750bc',
        courseName: 'SFWRENG 3DB3 - Databases',
        courseCode: '3DB3',
        shortName: 'SFWRENG 3DB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750bc',
      },
      {
        _id: '6073b7bb56f56ef6389750be',
        courseName: 'SFWRENG 3DX4 - Dynamic Systems and Control',
        courseCode: '3DX4',
        shortName: 'SFWRENG 3DX4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bb56f56ef6389750be',
      },
      {
        _id: '6073b7bc56f56ef6389750c0',
        courseName: 'SFWRENG 3FP3 - Functional Programming',
        courseCode: '3FP3',
        shortName: 'SFWRENG 3FP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750c0',
      },
      {
        _id: '6073b7bc56f56ef6389750c2',
        courseName: 'SFWRENG 3GB3 - Game Design',
        courseCode: '3GB3',
        shortName: 'SFWRENG 3GB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750c2',
      },
      {
        _id: '6073b7bc56f56ef6389750c4',
        courseName: 'SFWRENG 3GC3 - Computer Graphics',
        courseCode: '3GC3',
        shortName: 'SFWRENG 3GC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750c4',
      },
      {
        _id: '6073b7bc56f56ef6389750c6',
        courseName: 'SFWRENG 3I03 - Communication Skills',
        courseCode: '3I03',
        shortName: 'SFWRENG 3I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750c6',
      },
      {
        _id: '6073b7bc56f56ef6389750c8',
        courseName: 'SFWRENG 3K04 - Software Development',
        courseCode: '3K04',
        shortName: 'SFWRENG 3K04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750c8',
      },
      {
        _id: '6073b7bc56f56ef6389750ca',
        courseName: 'SFWRENG 3MD3 - Safe Software-Intensive Medical Devices',
        courseCode: '3MD3',
        shortName: 'SFWRENG 3MD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750ca',
      },
      {
        _id: '6073b7bc56f56ef6389750cc',
        courseName: 'SFWRENG 3MX3 - Signals and Systems',
        courseCode: '3MX3',
        shortName: 'SFWRENG 3MX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750cc',
      },
      {
        _id: '6073b7bc56f56ef6389750ce',
        courseName: 'SFWRENG 3O03 - Linear Optimization',
        courseCode: '3O03',
        shortName: 'SFWRENG 3O03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bc56f56ef6389750ce',
      },
      {
        _id: '6073b7bd56f56ef6389750d0',
        courseName:
          'SFWRENG 3RA3 - Software Requirements and Security Considerations',
        courseCode: '3RA3',
        shortName: 'SFWRENG 3RA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750d0',
      },
      {
        _id: '6073b7bd56f56ef6389750d2',
        courseName: 'SFWRENG 3S03 - Software Testing',
        courseCode: '3S03',
        shortName: 'SFWRENG 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750d2',
      },
      {
        _id: '6073b7bd56f56ef6389750d4',
        courseName: 'SFWRENG 3SH3 - Operating Systems',
        courseCode: '3SH3',
        shortName: 'SFWRENG 3SH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750d4',
      },
      {
        _id: '6073b7bd56f56ef6389750d6',
        courseName:
          'SFWRENG 3XA3 - Software Engineering Practice and Experience: Software Project Management',
        courseCode: '3XA3',
        shortName: 'SFWRENG 3XA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750d6',
      },
      {
        _id: '6073b7bd56f56ef6389750d8',
        courseName: 'SFWRENG 4AA4 - Real-Time Systems and Control Applications',
        courseCode: '4AA4',
        shortName: 'SFWRENG 4AA4',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750d8',
      },
      {
        _id: '6073b7bd56f56ef6389750da',
        courseName: 'SFWRENG 4AD3 - Advanced Databases',
        courseCode: '4AD3',
        shortName: 'SFWRENG 4AD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750da',
      },
      {
        _id: '6073b7bd56f56ef6389750dc',
        courseName: 'SFWRENG 4C03 - Computer Networks and Security',
        courseCode: '4C03',
        shortName: 'SFWRENG 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750dc',
      },
      {
        _id: '6073b7bd56f56ef6389750de',
        courseName: 'SFWRENG 4E03 - Performance Analysis of Computer Systems',
        courseCode: '4E03',
        shortName: 'SFWRENG 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750de',
      },
      {
        _id: '6073b7bd56f56ef6389750e0',
        courseName: 'SFWRENG 4F03 - Parallel Computing',
        courseCode: '4F03',
        shortName: 'SFWRENG 4F03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bd56f56ef6389750e0',
      },
      {
        _id: '6073b7be56f56ef6389750e2',
        courseName:
          'SFWRENG 4G06 A/B - Software Design IV - Capstone Design Project',
        courseCode: '4G06',
        shortName: 'SFWRENG 4G06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750e2',
      },
      {
        _id: '6073b7be56f56ef6389750e4',
        courseName: 'SFWRENG 4HC3 - Human Computer Interfaces',
        courseCode: '4HC3',
        shortName: 'SFWRENG 4HC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750e4',
      },
      {
        _id: '6073b7be56f56ef6389750e6',
        courseName: 'SFWRENG 4J03 - Communications Systems',
        courseCode: '4J03',
        shortName: 'SFWRENG 4J03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750e6',
      },
      {
        _id: '6073b7be56f56ef6389750e8',
        courseName: 'SFWRENG 4TE3 - Continuous Optimization',
        courseCode: '4TE3',
        shortName: 'SFWRENG 4TE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750e8',
      },
      {
        _id: '6073b7be56f56ef6389750ea',
        courseName: 'SFWRENG 4TH3 - Theory of Computation',
        courseCode: '4TH3',
        shortName: 'SFWRENG 4TH3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750ea',
      },
      {
        _id: '6073b7be56f56ef6389750ec',
        courseName: 'SFWRENG 4X03 - Scientific Computation',
        courseCode: '4X03',
        shortName: 'SFWRENG 4X03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750ec',
      },
      {
        _id: '6073b7be56f56ef6389750ee',
        courseName: 'SFWRTECH 3CS3 - Computer Security',
        courseCode: '3CS3',
        shortName: 'SFWRTECH 3CS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750ee',
      },
      {
        _id: '6073b7be56f56ef6389750f0',
        courseName: 'SFWRTECH 3IT3 - Fundamentals of Networking',
        courseCode: '3IT3',
        shortName: 'SFWRTECH 3IT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750f0',
      },
      {
        _id: '6073b7be56f56ef6389750f2',
        courseName: 'SFWRTECH 3OS3 - Operating Systems',
        courseCode: '3OS3',
        shortName: 'SFWRTECH 3OS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750f2',
      },
      {
        _id: '6073b7be56f56ef6389750f4',
        courseName:
          'SFWRTECH 3PR3 - Procedural and Object Oriented Programming Concepts',
        courseCode: '3PR3',
        shortName: 'SFWRTECH 3PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7be56f56ef6389750f4',
      },
      {
        _id: '6073b7bf56f56ef6389750f6',
        courseName: 'SFWRTECH 3RQ3 - Software Requirements and Specification',
        courseCode: '3RQ3',
        shortName: 'SFWRTECH 3RQ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef6389750f6',
      },
      {
        _id: '6073b7bf56f56ef6389750f8',
        courseName: 'SFWRTECH 4AI3 - Artificial Intelligence',
        courseCode: '4AI3',
        shortName: 'SFWRTECH 4AI3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef6389750f8',
      },
      {
        _id: '6073b7bf56f56ef6389750fa',
        courseName: 'SFWRTECH 4CC3 - Parallel Programming',
        courseCode: '4CC3',
        shortName: 'SFWRTECH 4CC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef6389750fa',
      },
      {
        _id: '6073b7bf56f56ef6389750fc',
        courseName: 'SFWRTECH 4CG3 - Computer Graphics',
        courseCode: '4CG3',
        shortName: 'SFWRTECH 4CG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef6389750fc',
      },
      {
        _id: '6073b7bf56f56ef6389750fe',
        courseName: 'SFWRTECH 4DA3 - Data Analytics and Big Data',
        courseCode: '4DA3',
        shortName: 'SFWRTECH 4DA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef6389750fe',
      },
      {
        _id: '6073b7bf56f56ef638975100',
        courseName: 'SFWRTECH 4DM3 - Data Mining',
        courseCode: '4DM3',
        shortName: 'SFWRTECH 4DM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef638975100',
      },
      {
        _id: '6073b7bf56f56ef638975102',
        courseName: 'SFWRTECH 4DS3 - Data Structures and Algorithms',
        courseCode: '4DS3',
        shortName: 'SFWRTECH 4DS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef638975102',
      },
      {
        _id: '6073b7bf56f56ef638975104',
        courseName: 'SFWRTECH 4ES3 - Real-Time Systems',
        courseCode: '4ES3',
        shortName: 'SFWRTECH 4ES3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef638975104',
      },
      {
        _id: '6073b7bf56f56ef638975106',
        courseName:
          'SFWRTECH 4MA3 - Numerical Linear Algebra and Numerical Optimization',
        courseCode: '4MA3',
        shortName: 'SFWRTECH 4MA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef638975106',
      },
      {
        _id: '6073b7bf56f56ef638975108',
        courseName: 'SFWRTECH 4NN3 - Neural Networks and Deep Learning',
        courseCode: '4NN3',
        shortName: 'SFWRTECH 4NN3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef638975108',
      },
      {
        _id: '6073b7bf56f56ef63897510a',
        courseName: 'SFWRTECH 4NS3 - Advanced Network Security',
        courseCode: '4NS3',
        shortName: 'SFWRTECH 4NS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7bf56f56ef63897510a',
      },
      {
        _id: '6073b7c056f56ef63897510c',
        courseName: 'SFWRTECH 4SA3 - Software Architecture',
        courseCode: '4SA3',
        shortName: 'SFWRTECH 4SA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef63897510c',
      },
      {
        _id: '6073b7c056f56ef63897510e',
        courseName:
          'SFWRTECH 4VE3 - Visual Effects and Technology for Animated Production',
        courseCode: '4VE3',
        shortName: 'SFWRTECH 4VE3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef63897510e',
      },
      {
        _id: '6073b7c056f56ef638975110',
        courseName: 'SFWRTECH 4WP3 - Advanced Web Programming',
        courseCode: '4WP3',
        shortName: 'SFWRTECH 4WP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef638975110',
      },
      {
        _id: '6073b7c056f56ef638975112',
        courseName: 'SPANISH 1A03 - Intermediate Spanish I',
        courseCode: '1A03',
        shortName: 'SPANISH 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef638975112',
      },
      {
        _id: '6073b7c056f56ef638975114',
        courseName: 'SPANISH 1AA3 - Intermediate Spanish II',
        courseCode: '1AA3',
        shortName: 'SPANISH 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef638975114',
      },
      {
        _id: '6073b7c056f56ef638975116',
        courseName: 'SPANISH 1Z06 A/B - Beginnerâ€™s Intensive Spanish',
        courseCode: '1Z06',
        shortName: 'SPANISH 1Z06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef638975116',
      },
      {
        _id: '6073b7c056f56ef638975118',
        courseName: 'SPANISH 2Z03 - Intermediate Spanish I',
        courseCode: '2Z03',
        shortName: 'SPANISH 2Z03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef638975118',
      },
      {
        _id: '6073b7c056f56ef63897511a',
        courseName: 'SPANISH 2ZZ3 - Intermediate Spanish II',
        courseCode: '2ZZ3',
        shortName: 'SPANISH 2ZZ3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef63897511a',
      },
      {
        _id: '6073b7c056f56ef63897511c',
        courseName: 'STATS 1L03 - Probability and Linear Algebra',
        courseCode: '1L03',
        shortName: 'STATS 1L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c056f56ef63897511c',
      },
      {
        _id: '6073b7c156f56ef63897511e',
        courseName: 'STATS 2B03 - Statistical Methods for Science',
        courseCode: '2B03',
        shortName: 'STATS 2B03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef63897511e',
      },
      {
        _id: '6073b7c156f56ef638975120',
        courseName: 'STATS 2D03 - Introduction to Probability',
        courseCode: '2D03',
        shortName: 'STATS 2D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef638975120',
      },
      {
        _id: '6073b7c156f56ef638975122',
        courseName: 'STATS 2MB3 - Statistical Methods and Applications',
        courseCode: '2MB3',
        shortName: 'STATS 2MB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef638975122',
      },
      {
        _id: '6073b7c156f56ef638975124',
        courseName: 'STATS 3A03 - Applied Regression Analysis with SAS',
        courseCode: '3A03',
        shortName: 'STATS 3A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef638975124',
      },
      {
        _id: '6073b7c156f56ef638975126',
        courseName: 'STATS 3D03 - Mathematical Statistics',
        courseCode: '3D03',
        shortName: 'STATS 3D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef638975126',
      },
      {
        _id: '6073b7c156f56ef638975128',
        courseName: 'STATS 3DS3 - Introduction to Data Science Theory',
        courseCode: '3DS3',
        shortName: 'STATS 3DS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef638975128',
      },
      {
        _id: '6073b7c156f56ef63897512a',
        courseName: 'STATS 3G03 - Actuarial Mathematics I',
        courseCode: '3G03',
        shortName: 'STATS 3G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c156f56ef63897512a',
      },
      {
        _id: '6073b7c256f56ef63897512c',
        courseName:
          'STATS 3J04 - Probability and Statistics for Civil Engineering',
        courseCode: '3J04',
        shortName: 'STATS 3J04',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef63897512c',
      },
      {
        _id: '6073b7c256f56ef63897512e',
        courseName: 'STATS 3PG3 - Probability and Games of Chance',
        courseCode: '3PG3',
        shortName: 'STATS 3PG3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef63897512e',
      },
      {
        _id: '6073b7c256f56ef638975130',
        courseName: 'STATS 3S03* - Survey Sampling',
        courseCode: '3S03*',
        shortName: 'STATS 3S03*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef638975130',
      },
      {
        _id: '6073b7c256f56ef638975132',
        courseName: 'STATS 3ST3 - Actuarial Models in Non-Life Insurance',
        courseCode: '3ST3',
        shortName: 'STATS 3ST3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef638975132',
      },
      {
        _id: '6073b7c256f56ef638975134',
        courseName: 'STATS 3U03* - Stochastic Processes',
        courseCode: '3U03*',
        shortName: 'STATS 3U03*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef638975134',
      },
      {
        _id: '6073b7c256f56ef638975136',
        courseName: 'STATS 3Y03 - Probability and Statistics for Engineering',
        courseCode: '3Y03',
        shortName: 'STATS 3Y03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef638975136',
      },
      {
        _id: '6073b7c256f56ef638975138',
        courseName: 'STATS 4A03 - Time Series',
        courseCode: '4A03',
        shortName: 'STATS 4A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef638975138',
      },
      {
        _id: '6073b7c256f56ef63897513a',
        courseName: 'STATS 4C03 - Generalized Linear Models',
        courseCode: '4C03',
        shortName: 'STATS 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef63897513a',
      },
      {
        _id: '6073b7c256f56ef63897513c',
        courseName: 'STATS 4CI3* - Computational Methods for Inference',
        courseCode: '4CI3*',
        shortName: 'STATS 4CI3*',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef63897513c',
      },
      {
        _id: '6073b7c256f56ef63897513e',
        courseName: 'STATS 4D03 - Intermediate Probability Theory',
        courseCode: '4D03',
        shortName: 'STATS 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c256f56ef63897513e',
      },
      {
        _id: '6073b7c356f56ef638975140',
        courseName: 'STATS 4G03 - Advanced Topics in Actuarial Science',
        courseCode: '4G03',
        shortName: 'STATS 4G03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef638975140',
      },
      {
        _id: '6073b7c356f56ef638975142',
        courseName: 'STATS 4H03 - Actuarial Mathematics II',
        courseCode: '4H03',
        shortName: 'STATS 4H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef638975142',
      },
      {
        _id: '6073b7c356f56ef638975144',
        courseName: 'STATS 4I03 - Inference',
        courseCode: '4I03',
        shortName: 'STATS 4I03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef638975144',
      },
      {
        _id: '6073b7c356f56ef638975146',
        courseName: 'STATS 4M03 - Multivariate Analysis',
        courseCode: '4M03',
        shortName: 'STATS 4M03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef638975146',
      },
      {
        _id: '6073b7c356f56ef638975148',
        courseName: 'STATS 4P03 - Advanced Applied Statistics',
        courseCode: '4P03',
        shortName: 'STATS 4P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef638975148',
      },
      {
        _id: '6073b7c356f56ef63897514a',
        courseName: 'STATS 4T06 A/B - Senior Research Project',
        courseCode: '4T06',
        shortName: 'STATS 4T06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef63897514a',
      },
      {
        _id: '6073b7c356f56ef63897514c',
        courseName: 'STATS 4W03 - Reading in Statistics',
        courseCode: '4W03',
        shortName: 'STATS 4W03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c356f56ef63897514c',
      },
      {
        _id: '6073b7c456f56ef63897514e',
        courseName: 'STATS 5GT3 - Graduate Level Topics in Statistics',
        courseCode: '5GT3',
        shortName: 'STATS 5GT3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef63897514e',
      },
      {
        _id: '6073b7c456f56ef638975150',
        courseName: 'SUSTAIN 1S03 - Introduction to Sustainability',
        courseCode: '1S03',
        shortName: 'SUSTAIN 1S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef638975150',
      },
      {
        _id: '6073b7c456f56ef638975152',
        courseName:
          'SUSTAIN 2S03 - Evaluating Problems & Sustainable Solutions',
        courseCode: '2S03',
        shortName: 'SUSTAIN 2S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef638975152',
      },
      {
        _id: '6073b7c456f56ef638975154',
        courseName: 'SUSTAIN 2SS3 - Advocating for Sustainability',
        courseCode: '2SS3',
        shortName: 'SUSTAIN 2SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef638975154',
      },
      {
        _id: '6073b7c456f56ef638975156',
        courseName: 'SUSTAIN 3S03 - Implementing Sustainable Change',
        courseCode: '3S03',
        shortName: 'SUSTAIN 3S03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef638975156',
      },
      {
        _id: '6073b7c456f56ef638975158',
        courseName:
          'SUSTAIN 3SS3 - Fostering Sustainable Communities through 100in1Day Hamilton',
        courseCode: '3SS3',
        shortName: 'SUSTAIN 3SS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef638975158',
      },
      {
        _id: '6073b7c456f56ef63897515a',
        courseName: 'SUSTAIN 4S06 A/B - Leadership in Sustainability',
        courseCode: '4S06',
        shortName: 'SUSTAIN 4S06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef63897515a',
      },
      {
        _id: '6073b7c456f56ef63897515c',
        courseName: 'THTRFLM 1H03 - Acting Skills for Life and Work',
        courseCode: '1H03',
        shortName: 'THTRFLM 1H03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef63897515c',
      },
      {
        _id: '6073b7c456f56ef63897515e',
        courseName:
          'THTRFLM 1T03 - Introduction to Theatre, Cinema and Society',
        courseCode: '1T03',
        shortName: 'THTRFLM 1T03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c456f56ef63897515e',
      },
      {
        _id: '6073b7c556f56ef638975160',
        courseName: 'THTRFLM 2AA3 - Acting as Devising',
        courseCode: '2AA3',
        shortName: 'THTRFLM 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef638975160',
      },
      {
        _id: '6073b7c556f56ef638975162',
        courseName: 'THTRFLM 2BB3 - Designing as Devising',
        courseCode: '2BB3',
        shortName: 'THTRFLM 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef638975162',
      },
      {
        _id: '6073b7c556f56ef638975164',
        courseName: 'THTRFLM 2CP3 - Culture and Performance',
        courseCode: '2CP3',
        shortName: 'THTRFLM 2CP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef638975164',
      },
      {
        _id: '6073b7c556f56ef638975166',
        courseName: 'THTRFLM 2DP3 - Devising Processes',
        courseCode: '2DP3',
        shortName: 'THTRFLM 2DP3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef638975166',
      },
      {
        _id: '6073b7c556f56ef638975168',
        courseName: 'THTRFLM 2FA3 - Film Analysis',
        courseCode: '2FA3',
        shortName: 'THTRFLM 2FA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef638975168',
      },
      {
        _id: '6073b7c556f56ef63897516a',
        courseName: 'THTRFLM 2MM3 - Movies and Me',
        courseCode: '2MM3',
        shortName: 'THTRFLM 2MM3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef63897516a',
      },
      {
        _id: '6073b7c556f56ef63897516c',
        courseName: 'THTRFLM 3AA3 - Modernist Drama and Theatre in Europe',
        courseCode: '3AA3',
        shortName: 'THTRFLM 3AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef63897516c',
      },
      {
        _id: '6073b7c556f56ef63897516e',
        courseName: 'THTRFLM 3DD3 - Contemporary Canadian Drama and Theatre',
        courseCode: '3DD3',
        shortName: 'THTRFLM 3DD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef63897516e',
      },
      {
        _id: '6073b7c556f56ef638975170',
        courseName: 'THTRFLM 3FF3 - Early Cinema History',
        courseCode: '3FF3',
        shortName: 'THTRFLM 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c556f56ef638975170',
      },
      {
        _id: '6073b7c656f56ef638975172',
        courseName: 'THTRFLM 3L03 - Cinema History from WWII',
        courseCode: '3L03',
        shortName: 'THTRFLM 3L03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef638975172',
      },
      {
        _id: '6073b7c656f56ef638975174',
        courseName: 'THTRFLM 3N03 - Artistsâ€™ Alternative Film and Video',
        courseCode: '3N03',
        shortName: 'THTRFLM 3N03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef638975174',
      },
      {
        _id: '6073b7c656f56ef638975176',
        courseName: 'THTRFLM 3OP6 A/B - Organizing the Performance Space',
        courseCode: '3OP6',
        shortName: 'THTRFLM 3OP6 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef638975176',
      },
      {
        _id: '6073b7c656f56ef638975178',
        courseName: 'THTRFLM 3P03 - Gender and Visual Culture',
        courseCode: '3P03',
        shortName: 'THTRFLM 3P03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef638975178',
      },
      {
        _id: '6073b7c656f56ef63897517a',
        courseName: 'THTRFLM 3PC3 - Performance and Community Engagement',
        courseCode: '3PC3',
        shortName: 'THTRFLM 3PC3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef63897517a',
      },
      {
        _id: '6073b7c656f56ef63897517c',
        courseName:
          'THTRFLM 3PR3 - Text-based Devising: Research and Development',
        courseCode: '3PR3',
        shortName: 'THTRFLM 3PR3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef63897517c',
      },
      {
        _id: '6073b7c656f56ef63897517e',
        courseName:
          'THTRFLM 3PS3 - Devising New Plays: Research and Development',
        courseCode: '3PS3',
        shortName: 'THTRFLM 3PS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c656f56ef63897517e',
      },
      {
        _id: '6073b7c756f56ef638975180',
        courseName: 'THTRFLM 3S06 - Major Production Workshop',
        courseCode: '3S06',
        shortName: 'THTRFLM 3S06',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef638975180',
      },
      {
        _id: '6073b7c756f56ef638975182',
        courseName: 'THTRFLM 3SD3 - Scripting the Devised Performance',
        courseCode: '3SD3',
        shortName: 'THTRFLM 3SD3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef638975182',
      },
      {
        _id: '6073b7c756f56ef638975184',
        courseName:
          'THTRFLM 3U03 - Pleasure and Critique in Dramatic Performance',
        courseCode: '3U03',
        shortName: 'THTRFLM 3U03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef638975184',
      },
      {
        _id: '6073b7c756f56ef638975186',
        courseName: 'THTRFLM 3VS3 - Visual Storytelling',
        courseCode: '3VS3',
        shortName: 'THTRFLM 3VS3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef638975186',
      },
      {
        _id: '6073b7c756f56ef638975188',
        courseName:
          'THTRFLM 3WW3 - Acting and the Voice: Devising from Classical Texts',
        courseCode: '3WW3',
        shortName: 'THTRFLM 3WW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef638975188',
      },
      {
        _id: '6073b7c756f56ef63897518a',
        courseName:
          'THTRFLM 3XX3 - Acting and the Body: Devising Physical Theatre',
        courseCode: '3XX3',
        shortName: 'THTRFLM 3XX3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef63897518a',
      },
      {
        _id: '6073b7c756f56ef63897518c',
        courseName:
          'THTRFLM 4A06 A/B - Theatre and Society: A Performance Project',
        courseCode: '4A06',
        shortName: 'THTRFLM 4A06 A/B',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef63897518c',
      },
      {
        _id: '6073b7c756f56ef63897518e',
        courseName: 'THTRFLM 4C03 - Performance and Society',
        courseCode: '4C03',
        shortName: 'THTRFLM 4C03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c756f56ef63897518e',
      },
      {
        _id: '6073b7c856f56ef638975190',
        courseName: 'THTRFLM 4D03 - Theatre, Society and Early Cinema',
        courseCode: '4D03',
        shortName: 'THTRFLM 4D03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef638975190',
      },
      {
        _id: '6073b7c856f56ef638975192',
        courseName: 'THTRFLM 4E03 - Cinema and Society',
        courseCode: '4E03',
        shortName: 'THTRFLM 4E03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef638975192',
      },
      {
        _id: '6073b7c856f56ef638975194',
        courseName: 'WOMENST 1A03 - Women, Culture, Power',
        courseCode: '1A03',
        shortName: 'WOMENST 1A03',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef638975194',
      },
      {
        _id: '6073b7c856f56ef638975196',
        courseName: 'WOMENST 1AA3 - Women Transforming the World',
        courseCode: '1AA3',
        shortName: 'WOMENST 1AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef638975196',
      },
      {
        _id: '6073b7c856f56ef638975198',
        courseName: 'WOMENST 2AA3 - Introduction to Feminist Thought',
        courseCode: '2AA3',
        shortName: 'WOMENST 2AA3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef638975198',
      },
      {
        _id: '6073b7c856f56ef63897519a',
        courseName: 'WOMENST 2BB3 - Images of the Divine Feminine',
        courseCode: '2BB3',
        shortName: 'WOMENST 2BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef63897519a',
      },
      {
        _id: '6073b7c856f56ef63897519c',
        courseName: 'WOMENST 3BB3 - Gender and Visual Culture',
        courseCode: '3BB3',
        shortName: 'WOMENST 3BB3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef63897519c',
      },
      {
        _id: '6073b7c856f56ef63897519e',
        courseName: 'WOMENST 3BW3 - Women in the Biblical Tradition',
        courseCode: '3BW3',
        shortName: 'WOMENST 3BW3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef63897519e',
      },
      {
        _id: '6073b7c856f56ef6389751a0',
        courseName: 'WOMENST 3FF3 - Gender and Religion',
        courseCode: '3FF3',
        shortName: 'WOMENST 3FF3',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c856f56ef6389751a0',
      },
      {
        _id: '6073b7c956f56ef6389751a2',
        courseName: 'WHMIS 1A00 - Introduction to Health and Safety',
        courseCode: '1A00',
        shortName: 'WHMIS 1A00',
        university: '5ef1880c28f09dee8c2a2867',
        numResources: 0,
        id: '6073b7c956f56ef6389751a2',
      },
    ],
    id: '5ef1880c28f09dee8c2a2867',
  },
  {
    location: {
      type: 'Point',
      latitude: 43.65784,
      longitude: -79.380172,
      coordinates: [-79.380172, 43.65784],
      formattedAddress: '350 Victoria St, Toronto, ON M5B 2K3, CA',
      street: '350 Victoria St',
      city: 'Toronto',
      state: 'ON',
      zipcode: 'M5B 2K3',
      countryCode: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960406/Web%20App%20Static%20Files/Ryerson_brk5bs.jpg',
    _id: '60b8581c4055ac0015fa2215',
    name: 'Ryerson University',
    description:
      "Ryerson University is a public research university located in Toronto, Ontario, Canada. The university's core campus is situated within the Garden District, although it also operates facilities elsewhere in downtown Toronto.",
    website: 'https://www.ryerson.ca/',
    phone: '(416)-979-5000',
    email: 'help@ryerson.ca',
    createdAt: '2021-06-03T04:18:36.175Z',
    slug: 'ryerson-university',
    __v: 0,
    courses: [
      {
        _id: '60b8581c4055ac0015fa2217',
        courseCode: 'BLG 143',
        shortName: 'BLG 143 - Biology I',
        courseName: 'BLG 143 - Biology I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 24,
        id: '60b8581c4055ac0015fa2217',
      },
      {
        _id: '60b858224055ac0015fa2231',
        courseCode: 'CHY 103',
        shortName: 'CHY 103 - General Chemistry I',
        courseName: 'CHY 103 - General Chemistry I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 89,
        id: '60b858224055ac0015fa2231',
      },
      {
        _id: '60b858394055ac0015fa228c',
        courseCode: 'CPS 118',
        shortName: 'CPS 118 - Introductory Programming for Scientists',
        courseName: 'CPS 118 - Introductory Programming for Scientists',
        university: '60b8581c4055ac0015fa2215',
        numResources: 20,
        id: '60b858394055ac0015fa228c',
      },
      {
        _id: '60b8583e4055ac0015fa22a2',
        courseCode: 'MTH 131',
        shortName: 'MTH 131 - Modern Mathematics I',
        courseName: 'MTH 131 - Modern Mathematics I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8583e4055ac0015fa22a2',
      },
      {
        _id: '60b8583f4055ac0015fa22a5',
        courseCode: 'PCS 120',
        shortName: 'PCS 120 - Physics I',
        courseName: 'PCS 120 - Physics I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 52,
        id: '60b8583f4055ac0015fa22a5',
      },
      {
        _id: '60b8584c4055ac0015fa22db',
        courseCode: 'SCI 180',
        shortName: 'SCI 180 - Orientation',
        courseName: 'SCI 180 - Orientation',
        university: '60b8581c4055ac0015fa2215',
        numResources: 8,
        id: '60b8584c4055ac0015fa22db',
      },
      {
        _id: '60b8584e4055ac0015fa22e5',
        courseCode: 'MTH 231',
        shortName: 'MTH 231 - Modern Mathematics II',
        courseName: 'MTH 231 - Modern Mathematics II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8584e4055ac0015fa22e5',
      },
      {
        _id: '60b8584e4055ac0015fa22e8',
        courseCode: 'CPS 109',
        shortName: 'CPS 109 - Computer Science I',
        courseName: 'CPS 109 - Computer Science I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 42,
        id: '60b8584e4055ac0015fa22e8',
      },
      {
        _id: '60b858594055ac0015fa2314',
        courseCode: 'ECN 801',
        shortName: 'ECN 801 - Principles of Engineering Economics',
        courseName: 'ECN 801 - Principles of Engineering Economics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 41,
        id: '60b858594055ac0015fa2314',
      },
      {
        _id: '60b858634055ac0015fa233f',
        courseCode: 'MTH 110',
        shortName: 'MTH 110 - Discrete Mathematics I',
        courseName: 'MTH 110 - Discrete Mathematics I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 51,
        id: '60b858634055ac0015fa233f',
      },
      {
        _id: '60b858704055ac0015fa2374',
        courseCode: 'MTH 207',
        shortName: 'MTH 207 - Calculus and Computational Methods I',
        courseName: 'MTH 207 - Calculus and Computational Methods I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 5,
        id: '60b858704055ac0015fa2374',
      },
      {
        _id: '60b858714055ac0015fa237b',
        courseCode: 'CHY 113',
        shortName: 'CHY 113 - General Chemistry II',
        courseName: 'CHY 113 - General Chemistry II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 40,
        id: '60b858714055ac0015fa237b',
      },
      {
        _id: '60b8587c4055ac0015fa23a5',
        courseCode: 'MTH 210',
        shortName: 'MTH 210 - Discrete Mathematics II',
        courseName: 'MTH 210 - Discrete Mathematics II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 70,
        id: '60b8587c4055ac0015fa23a5',
      },
      {
        _id: '60b8588d4055ac0015fa23ed',
        courseCode: 'MTH 310',
        shortName: 'MTH 310 - Calculus and Computational Methods II',
        courseName: 'MTH 310 - Calculus and Computational Methods II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 22,
        id: '60b8588d4055ac0015fa23ed',
      },
      {
        _id: '60b858934055ac0015fa2405',
        courseCode: 'MTH 607',
        shortName: 'MTH 607 - Graph Theory',
        courseName: 'MTH 607 - Graph Theory',
        university: '60b8581c4055ac0015fa2215',
        numResources: 48,
        id: '60b858934055ac0015fa2405',
      },
      {
        _id: '60b8589f4055ac0015fa2437',
        courseCode: 'PCS 130',
        shortName: 'PCS 130 - Physics II',
        courseName: 'PCS 130 - Physics II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 6,
        id: '60b8589f4055ac0015fa2437',
      },
      {
        _id: '60b858a04055ac0015fa243f',
        courseCode: 'CMN 432',
        shortName: 'CMN 432 - Communication in the Engineering Professions',
        courseName: 'CMN 432 - Communication in the Engineering Professions',
        university: '60b8581c4055ac0015fa2215',
        numResources: 8,
        id: '60b858a04055ac0015fa243f',
      },
      {
        _id: '60b858a24055ac0015fa2449',
        courseCode: 'CPS 213',
        shortName: 'CPS 213 - Computer Organization I',
        courseName: 'CPS 213 - Computer Organization I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 9,
        id: '60b858a24055ac0015fa2449',
      },
      {
        _id: '60b858a54055ac0015fa2454',
        courseCode: 'MTH 108',
        shortName: 'MTH 108 - Linear Algebra',
        courseName: 'MTH 108 - Linear Algebra',
        university: '60b8581c4055ac0015fa2215',
        numResources: 31,
        id: '60b858a54055ac0015fa2454',
      },
      {
        _id: '60b858ad4055ac0015fa2475',
        courseCode: 'MTH 304',
        shortName: 'MTH 304 - Probability and Statistics I',
        courseName: 'MTH 304 - Probability and Statistics I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 17,
        id: '60b858ad4055ac0015fa2475',
      },
      {
        _id: '60b858b14055ac0015fa2488',
        courseCode: 'EES 512',
        shortName: 'EES 512 - Electric Circuits',
        courseName: 'EES 512 - Electric Circuits',
        university: '60b8581c4055ac0015fa2215',
        numResources: 3,
        id: '60b858b14055ac0015fa2488',
      },
      {
        _id: '60b858b24055ac0015fa248d',
        courseCode: 'EES 612',
        shortName: 'EES 612 - Electric Machines and Actuators',
        courseName: 'EES 612 - Electric Machines and Actuators',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b858b24055ac0015fa248d',
      },
      {
        _id: '60b858b34055ac0015fa2490',
        courseCode: 'MTH 312',
        shortName: 'MTH 312 - Differential Equations and Vector Calculus',
        courseName: 'MTH 312 - Differential Equations and Vector Calculus',
        university: '60b8581c4055ac0015fa2215',
        numResources: 21,
        id: '60b858b34055ac0015fa2490',
      },
      {
        _id: '60b858b84055ac0015fa24a7',
        courseCode: 'MTH 410',
        shortName: 'MTH 410 - Statistics',
        courseName: 'MTH 410 - Statistics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b858b84055ac0015fa24a7',
      },
      {
        _id: '60b858b94055ac0015fa24ab',
        courseCode: 'MTH 510',
        shortName: 'MTH 510 - Numerical Analysis',
        courseName: 'MTH 510 - Numerical Analysis',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b858b94055ac0015fa24ab',
      },
      {
        _id: '60b858b94055ac0015fa24ae',
        courseCode: 'CEN 100',
        shortName: 'CEN 100 - Introduction to Engineering',
        courseName: 'CEN 100 - Introduction to Engineering',
        university: '60b8581c4055ac0015fa2215',
        numResources: 5,
        id: '60b858b94055ac0015fa24ae',
      },
      {
        _id: '60b858bb4055ac0015fa24b5',
        courseCode: 'CHY 102',
        shortName: 'CHY 102 - General Chemistry',
        courseName: 'CHY 102 - General Chemistry',
        university: '60b8581c4055ac0015fa2215',
        numResources: 31,
        id: '60b858bb4055ac0015fa24b5',
      },
      {
        _id: '60b858c34055ac0015fa24d6',
        courseCode: 'MTH 140',
        shortName: 'MTH 140 - Calculus I',
        courseName: 'MTH 140 - Calculus I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 52,
        id: '60b858c34055ac0015fa24d6',
      },
      {
        _id: '60b858d04055ac0015fa250c',
        courseCode: 'MTH 141',
        shortName: 'MTH 141 - Linear Algebra',
        courseName: 'MTH 141 - Linear Algebra',
        university: '60b8581c4055ac0015fa2215',
        numResources: 26,
        id: '60b858d04055ac0015fa250c',
      },
      {
        _id: '60b858d64055ac0015fa2528',
        courseCode: 'PCS 211',
        shortName: 'PCS 211 - Physics: Mechanics',
        courseName: 'PCS 211 - Physics: Mechanics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 24,
        id: '60b858d64055ac0015fa2528',
      },
      {
        _id: '60b858dc4055ac0015fa2542',
        courseCode: 'CPS 125',
        shortName: 'CPS 125 - Digital Computation and Programming',
        courseName: 'CPS 125 - Digital Computation and Programming',
        university: '60b8581c4055ac0015fa2215',
        numResources: 25,
        id: '60b858dc4055ac0015fa2542',
      },
      {
        _id: '60b858e24055ac0015fa255d',
        courseCode: 'ELE 202',
        shortName: 'ELE 202 - Electric Circuit Analysis',
        courseName: 'ELE 202 - Electric Circuit Analysis',
        university: '60b8581c4055ac0015fa2215',
        numResources: 10,
        id: '60b858e24055ac0015fa255d',
      },
      {
        _id: '60b858e54055ac0015fa2569',
        courseCode: 'MEC 222',
        shortName: 'MEC 222 - Engineering Graphical Communication',
        courseName: 'MEC 222 - Engineering Graphical Communication',
        university: '60b8581c4055ac0015fa2215',
        numResources: 6,
        id: '60b858e54055ac0015fa2569',
      },
      {
        _id: '60b858e64055ac0015fa2571',
        courseCode: 'MTH 240',
        shortName: 'MTH 240 - Calculus II',
        courseName: 'MTH 240 - Calculus II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 49,
        id: '60b858e64055ac0015fa2571',
      },
      {
        _id: '60b858f34055ac0015fa25a4',
        courseCode: 'MTL 200',
        shortName: 'MTL 200 - Materials Science Fundamentals',
        courseName: 'MTL 200 - Materials Science Fundamentals',
        university: '60b8581c4055ac0015fa2215',
        numResources: 22,
        id: '60b858f34055ac0015fa25a4',
      },
      {
        _id: '60b858f94055ac0015fa25bc',
        courseCode: 'PCS 125',
        shortName: 'PCS 125 - Physics: Waves and Fields',
        courseName: 'PCS 125 - Physics: Waves and Fields',
        university: '60b8581c4055ac0015fa2215',
        numResources: 29,
        id: '60b858f94055ac0015fa25bc',
      },
      {
        _id: '60b859004055ac0015fa25db',
        courseCode: 'AER 222',
        shortName: 'AER 222 - Engineering Design and Graphical Communication',
        courseName: 'AER 222 - Engineering Design and Graphical Communication',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859004055ac0015fa25db',
      },
      {
        _id: '60b859004055ac0015fa25de',
        courseCode: 'AER 309',
        shortName: 'AER 309 - Basic Thermodynamics',
        courseName: 'AER 309 - Basic Thermodynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 10,
        id: '60b859004055ac0015fa25de',
      },
      {
        _id: '60b859034055ac0015fa25ea',
        courseCode: 'AER 316',
        shortName: 'AER 316 - Fluid Mechanics',
        courseName: 'AER 316 - Fluid Mechanics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 11,
        id: '60b859034055ac0015fa25ea',
      },
      {
        _id: '60b859064055ac0015fa25f7',
        courseCode: 'AER 318',
        shortName: 'AER 318 - Dynamics',
        courseName: 'AER 318 - Dynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859064055ac0015fa25f7',
      },
      {
        _id: '60b859064055ac0015fa25fb',
        courseCode: 'AER 320',
        shortName: 'AER 320 - Statics and Intro to Strength of Materials',
        courseName: 'AER 320 - Statics and Intro to Strength of Materials',
        university: '60b8581c4055ac0015fa2215',
        numResources: 4,
        id: '60b859064055ac0015fa25fb',
      },
      {
        _id: '60b859084055ac0015fa2601',
        courseCode: 'AER 403',
        shortName: 'AER 403 - Mechanisms and Vibrations',
        courseName: 'AER 403 - Mechanisms and Vibrations',
        university: '60b8581c4055ac0015fa2215',
        numResources: 5,
        id: '60b859084055ac0015fa2601',
      },
      {
        _id: '60b859094055ac0015fa2608',
        courseCode: 'AER 416',
        shortName: 'AER 416 - Flight Mechanics',
        courseName: 'AER 416 - Flight Mechanics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859094055ac0015fa2608',
      },
      {
        _id: '60b859094055ac0015fa260b',
        courseCode: 'AER 423',
        shortName: 'AER 423 - Thermodynamics and Heat Transfer',
        courseName: 'AER 423 - Thermodynamics and Heat Transfer',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859094055ac0015fa260b',
      },
      {
        _id: '60b8590a4055ac0015fa260e',
        courseCode: 'AER 504',
        shortName: 'AER 504 - Aerodynamics',
        courseName: 'AER 504 - Aerodynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 9,
        id: '60b8590a4055ac0015fa260e',
      },
      {
        _id: '60b8590c4055ac0015fa2619',
        courseCode: 'AER 507',
        shortName: 'AER 507 - Materials and Manufacturing',
        courseName: 'AER 507 - Materials and Manufacturing',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8590c4055ac0015fa2619',
      },
      {
        _id: '60b8590d4055ac0015fa261d',
        courseCode: 'AER 520',
        shortName: 'AER 520 - Stress Analysis',
        courseName: 'AER 520 - Stress Analysis',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8590d4055ac0015fa261d',
      },
      {
        _id: '60b8590e4055ac0015fa2621',
        courseCode: 'AER 606',
        shortName: 'AER 606 - Component Design and Material Selection',
        courseName: 'AER 606 - Component Design and Material Selection',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8590e4055ac0015fa2621',
      },
      {
        _id: '60b8590e4055ac0015fa2624',
        courseCode: 'AER 615',
        shortName: 'AER 615 - Aircraft Performance',
        courseName: 'AER 615 - Aircraft Performance',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8590e4055ac0015fa2624',
      },
      {
        _id: '60b8590f4055ac0015fa2627',
        courseCode: 'AER 621',
        shortName: 'AER 621 - Aerospace Structural Design',
        courseName: 'AER 621 - Aerospace Structural Design',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8590f4055ac0015fa2627',
      },
      {
        _id: '60b8590f4055ac0015fa262a',
        courseCode: 'AER 622',
        shortName: 'AER 622 - Gas Dynamics',
        courseName: 'AER 622 - Gas Dynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8590f4055ac0015fa262a',
      },
      {
        _id: '60b859104055ac0015fa262d',
        courseCode: 'AER 721',
        shortName: 'AER 721 - Orbital Dynamics',
        courseName: 'AER 721 - Orbital Dynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859104055ac0015fa262d',
      },
      {
        _id: '60b859104055ac0015fa2631',
        courseCode: 'AER 722',
        shortName: 'AER 722 - Aeroelasticity',
        courseName: 'AER 722 - Aeroelasticity',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859104055ac0015fa2631',
      },
      {
        _id: '60b859114055ac0015fa2634',
        courseCode: 'AER 723',
        shortName: 'AER 723 - Introduction to Space Systems Design',
        courseName: 'AER 723 - Introduction to Space Systems Design',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859114055ac0015fa2634',
      },
      {
        _id: '60b859114055ac0015fa2638',
        courseCode: 'AER 817',
        shortName: 'AER 817 - Systems Engineering',
        courseName: 'AER 817 - Systems Engineering',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859114055ac0015fa2638',
      },
      {
        _id: '60b859124055ac0015fa263b',
        courseCode: 'AER 710',
        shortName: 'AER 710 - Propulsion',
        courseName: 'AER 710 - Propulsion',
        university: '60b8581c4055ac0015fa2215',
        numResources: 4,
        id: '60b859124055ac0015fa263b',
      },
      {
        _id: '60b859134055ac0015fa2641',
        courseCode: 'COE 538',
        shortName: 'COE 538 - Microprocessor Systems',
        courseName: 'COE 538 - Microprocessor Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 21,
        id: '60b859134055ac0015fa2641',
      },
      {
        _id: '60b859184055ac0015fa2658',
        courseCode: 'BME 406',
        shortName: 'BME 406 - Biomechanics',
        courseName: 'BME 406 - Biomechanics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859184055ac0015fa2658',
      },
      {
        _id: '60b859194055ac0015fa265b',
        courseCode: 'BME 506',
        shortName: 'BME 506 - Introduction to Software',
        courseName: 'BME 506 - Introduction to Software',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859194055ac0015fa265b',
      },
      {
        _id: '60b859194055ac0015fa265e',
        courseCode: 'CHE 200',
        shortName: 'CHE 200 - Chemical Engineering Fundamentals',
        courseName: 'CHE 200 - Chemical Engineering Fundamentals',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859194055ac0015fa265e',
      },
      {
        _id: '60b8591a4055ac0015fa2661',
        courseCode: 'CHE 217',
        shortName: 'CHE 217 - Fluid Mechanics',
        courseName: 'CHE 217 - Fluid Mechanics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8591a4055ac0015fa2661',
      },
      {
        _id: '60b8591a4055ac0015fa2664',
        courseCode: 'CHE 215',
        shortName: 'CHE 215 - Process Measurements',
        courseName: 'CHE 215 - Process Measurements',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8591a4055ac0015fa2664',
      },
      {
        _id: '60b8591b4055ac0015fa2667',
        courseCode: 'CHE 318',
        shortName: 'CHE 318 - Separation Processes',
        courseName: 'CHE 318 - Separation Processes',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8591b4055ac0015fa2667',
      },
      {
        _id: '60b8591b4055ac0015fa266b',
        courseCode: 'CHE 319',
        shortName: 'CHE 319 - Process Modeling and Simulation',
        courseName: 'CHE 319 - Process Modeling and Simulation',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8591b4055ac0015fa266b',
      },
      {
        _id: '60b8591c4055ac0015fa266e',
        courseCode: 'CVL 323',
        shortName: 'CVL 323 - Fundamentals of Surveying',
        courseName: 'CVL 323 - Fundamentals of Surveying',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8591c4055ac0015fa266e',
      },
      {
        _id: '60b8591d4055ac0015fa2672',
        courseCode: 'CVL 423',
        shortName: 'CVL 423 - Geology for Engineers',
        courseName: 'CVL 423 - Geology for Engineers',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8591d4055ac0015fa2672',
      },
      {
        _id: '60b8591d4055ac0015fa2676',
        courseCode: 'CVL 313',
        shortName: 'CVL 313 - Structural Analysis',
        courseName: 'CVL 313 - Structural Analysis',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8591d4055ac0015fa2676',
      },
      {
        _id: '60b8591e4055ac0015fa2679',
        courseCode: 'COE 318',
        shortName: 'COE 318 - Software Systems',
        courseName: 'COE 318 - Software Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 18,
        id: '60b8591e4055ac0015fa2679',
      },
      {
        _id: '60b859224055ac0015fa268d',
        courseCode: 'COE 328',
        shortName: 'COE 328 - Digital Systems',
        courseName: 'COE 328 - Digital Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 11,
        id: '60b859224055ac0015fa268d',
      },
      {
        _id: '60b859254055ac0015fa269a',
        courseCode: 'ELE 302',
        shortName: 'ELE 302 - Electric Networks',
        courseName: 'ELE 302 - Electric Networks',
        university: '60b8581c4055ac0015fa2215',
        numResources: 17,
        id: '60b859254055ac0015fa269a',
      },
      {
        _id: '60b8592a4055ac0015fa26ad',
        courseCode: 'PCS 224',
        shortName: 'PCS 224 - Solid State Physics',
        courseName: 'PCS 224 - Solid State Physics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 14,
        id: '60b8592a4055ac0015fa26ad',
      },
      {
        _id: '60b8592e4055ac0015fa26bd',
        courseCode: 'COE 428',
        shortName: 'COE 428 - Engineering Algorithms and Data Structures',
        courseName: 'COE 428 - Engineering Algorithms and Data Structures',
        university: '60b8581c4055ac0015fa2215',
        numResources: 12,
        id: '60b8592e4055ac0015fa26bd',
      },
      {
        _id: '60b859314055ac0015fa26cb',
        courseCode: 'ELE 401',
        shortName: 'ELE 401 - Field Theory',
        courseName: 'ELE 401 - Field Theory',
        university: '60b8581c4055ac0015fa2215',
        numResources: 14,
        id: '60b859314055ac0015fa26cb',
      },
      {
        _id: '60b859354055ac0015fa26db',
        courseCode: 'ELE 404',
        shortName: 'ELE 404 - Electronic Circuits I',
        courseName: 'ELE 404 - Electronic Circuits I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 14,
        id: '60b859354055ac0015fa26db',
      },
      {
        _id: '60b859384055ac0015fa26eb',
        courseCode: 'MTH 314',
        shortName: 'MTH 314 - Discrete Mathematics for Engineers',
        courseName: 'MTH 314 - Discrete Mathematics for Engineers',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859384055ac0015fa26eb',
      },
      {
        _id: '60b859394055ac0015fa26ee',
        courseCode: 'COE 528',
        shortName: 'COE 528 - Object Oriented Eng Analysis and Design',
        courseName: 'COE 528 - Object Oriented Eng Analysis and Design',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859394055ac0015fa26ee',
      },
      {
        _id: '60b859394055ac0015fa26f2',
        courseCode: 'COE 608',
        shortName: 'COE 608 - Computer Organization and Architecture',
        courseName: 'COE 608 - Computer Organization and Architecture',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859394055ac0015fa26f2',
      },
      {
        _id: '60b8593a4055ac0015fa26f6',
        courseCode: 'COE 628',
        shortName: 'COE 628 - Operating Systems',
        courseName: 'COE 628 - Operating Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 3,
        id: '60b8593a4055ac0015fa26f6',
      },
      {
        _id: '60b8593b4055ac0015fa26fb',
        courseCode: 'ELE 734',
        shortName: 'ELE 734 - Low Power Digital Integrated Circuits',
        courseName: 'ELE 734 - Low Power Digital Integrated Circuits',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8593b4055ac0015fa26fb',
      },
      {
        _id: '60b8593c4055ac0015fa26fe',
        courseCode: 'ELE 531',
        shortName: 'ELE 531 - Electromagnetics',
        courseName: 'ELE 531 - Electromagnetics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8593c4055ac0015fa26fe',
      },
      {
        _id: '60b8593c4055ac0015fa2702',
        courseCode: 'IND 303',
        shortName: 'IND 303 - Work Measurement, Analysis and Design',
        courseName: 'IND 303 - Work Measurement, Analysis and Design',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8593c4055ac0015fa2702',
      },
      {
        _id: '60b8593d4055ac0015fa2706',
        courseCode: 'IND 405',
        shortName: 'IND 405 - Introduction to Data Science and Analytics',
        courseName: 'IND 405 - Introduction to Data Science and Analytics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8593d4055ac0015fa2706',
      },
      {
        _id: '60b8593e4055ac0015fa270a',
        courseCode: 'IND 508',
        shortName: 'IND 508 - Operations Research I',
        courseName: 'IND 508 - Operations Research I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8593e4055ac0015fa270a',
      },
      {
        _id: '60b8593e4055ac0015fa270d',
        courseCode: 'IND 605',
        shortName: 'IND 605 - Experimental Design and Quality Assurance',
        courseName: 'IND 605 - Experimental Design and Quality Assurance',
        university: '60b8581c4055ac0015fa2215',
        numResources: 3,
        id: '60b8593e4055ac0015fa270d',
      },
      {
        _id: '60b8593f4055ac0015fa2712',
        courseCode: 'IND 600',
        shortName: 'IND 600 - Systems Modeling and Simulation',
        courseName: 'IND 600 - Systems Modeling and Simulation',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8593f4055ac0015fa2712',
      },
      {
        _id: '60b859404055ac0015fa2715',
        courseCode: 'IND 604',
        shortName: 'IND 604 - Operations Research II',
        courseName: 'IND 604 - Operations Research II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859404055ac0015fa2715',
      },
      {
        _id: '60b859404055ac0015fa2718',
        courseCode: 'IND 710',
        shortName: 'IND 710 - Production and Inventory Systems',
        courseName: 'IND 710 - Production and Inventory Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859404055ac0015fa2718',
      },
      {
        _id: '60b859414055ac0015fa271b',
        courseCode: 'MEC 617',
        shortName: 'MEC 617 - Manufacturing System Control',
        courseName: 'MEC 617 - Manufacturing System Control',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859414055ac0015fa271b',
      },
      {
        _id: '60b859414055ac0015fa271e',
        courseCode: 'MEC 626',
        shortName: 'MEC 626 - Applied Finite Elements',
        courseName: 'MEC 626 - Applied Finite Elements',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859414055ac0015fa271e',
      },
      {
        _id: '60b859424055ac0015fa2721',
        courseCode: 'MEC 733',
        shortName: 'MEC 733 - Microprocessor Systems',
        courseName: 'MEC 733 - Microprocessor Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859424055ac0015fa2721',
      },
      {
        _id: '60b859424055ac0015fa2724',
        courseCode: 'MEC 817',
        shortName: 'MEC 817 - Combustion Engineering',
        courseName: 'MEC 817 - Combustion Engineering',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859424055ac0015fa2724',
      },
      {
        _id: '60b859434055ac0015fa2727',
        courseCode: 'MTL 700',
        shortName: 'MTL 700 - Materials Selection for Engineering Design',
        courseName: 'MTL 700 - Materials Selection for Engineering Design',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859434055ac0015fa2727',
      },
      {
        _id: '60b859434055ac0015fa272a',
        courseCode: 'MEC 309',
        shortName: 'MEC 309 - Basic Thermodynamics',
        courseName: 'MEC 309 - Basic Thermodynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859434055ac0015fa272a',
      },
      {
        _id: '60b859434055ac0015fa272d',
        courseCode: 'MEC 311',
        shortName: 'MEC 311 - Dynamics',
        courseName: 'MEC 311 - Dynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859434055ac0015fa272d',
      },
      {
        _id: '60b859444055ac0015fa2730',
        courseCode: 'MEC 325',
        shortName: 'MEC 325 - Introduction to Engineering Design',
        courseName: 'MEC 325 - Introduction to Engineering Design',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859444055ac0015fa2730',
      },
      {
        _id: '60b859454055ac0015fa2734',
        courseCode: 'MEC 516',
        shortName: 'MEC 516 - Fluid Mechanics I',
        courseName: 'MEC 516 - Fluid Mechanics I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b859454055ac0015fa2734',
      },
      {
        _id: '60b859454055ac0015fa2738',
        courseCode: 'MEC 411',
        shortName: 'MEC 411 - Mechanics of Machines',
        courseName: 'MEC 411 - Mechanics of Machines',
        university: '60b8581c4055ac0015fa2215',
        numResources: 10,
        id: '60b859454055ac0015fa2738',
      },
      {
        _id: '60b859494055ac0015fa2744',
        courseCode: 'MEC 430',
        shortName: 'MEC 430 - Stress Analysis',
        courseName: 'MEC 430 - Stress Analysis',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b859494055ac0015fa2744',
      },
      {
        _id: '60b8594a4055ac0015fa2747',
        courseCode: 'MEC 514',
        shortName: 'MEC 514 - Applied Thermodynamics',
        courseName: 'MEC 514 - Applied Thermodynamics',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8594a4055ac0015fa2747',
      },
      {
        _id: '60b8594b4055ac0015fa274b',
        courseCode: 'MEC 613',
        shortName: 'MEC 613 - Machine Design I',
        courseName: 'MEC 613 - Machine Design I',
        university: '60b8581c4055ac0015fa2215',
        numResources: 4,
        id: '60b8594b4055ac0015fa274b',
      },
      {
        _id: '60b8594c4055ac0015fa2751',
        courseCode: 'MEC 616',
        shortName: 'MEC 616 - Fluid Mechanics II',
        courseName: 'MEC 616 - Fluid Mechanics II',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8594c4055ac0015fa2751',
      },
      {
        _id: '60b8594d4055ac0015fa2754',
        courseCode: 'MEC 701',
        shortName: 'MEC 701 - Heat Transfer',
        courseName: 'MEC 701 - Heat Transfer',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8594d4055ac0015fa2754',
      },
      {
        _id: '60b8594d4055ac0015fa2757',
        courseCode: 'MEC 709',
        shortName: 'MEC 709 - Control Systems',
        courseName: 'MEC 709 - Control Systems',
        university: '60b8581c4055ac0015fa2215',
        numResources: 2,
        id: '60b8594d4055ac0015fa2757',
      },
      {
        _id: '60b8594e4055ac0015fa275b',
        courseCode: 'MEC 721',
        shortName: 'MEC 721 - Vibrations',
        courseName: 'MEC 721 - Vibrations',
        university: '60b8581c4055ac0015fa2215',
        numResources: 1,
        id: '60b8594e4055ac0015fa275b',
      },
    ],
    id: '60b8581c4055ac0015fa2215',
  },
  {
    location: {
      type: 'Point',
      latitude: 43.009787,
      longitude: -81.262054,
      coordinates: [-81.262054, 43.009787],
      formattedAddress: '1151 Richmond St, London, ON N6A 5B9, CA',
      street: '1151 Richmond St',
      city: 'London',
      state: 'ON',
      zipcode: 'N6A 5B9',
      countryCode: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960407/Web%20App%20Static%20Files/Western_rev3zz.jpg',
    _id: '60cc1a0747f7e10015fd5945',
    name: 'Western University',
    description:
      'The University of Western Ontario, branded as Western University as of 2012 and commonly shortened to Western, is a public research university in London, Ontario, Canada.',
    website: 'https://www.uwo.ca/',
    phone: '(519)-661-2111',
    email: 'welcome@uwo.ca',
    createdAt: '2021-06-18T03:59:03.242Z',
    slug: 'western-university',
    __v: 0,
    dataloader_status: 'inserted_by_dataloader',
    courses: [
      {
        _id: '60cc1a3947f7e10015fd5947',
        courseCode: 'ACTURSCI 2053',
        shortName:
          'Actuarial Science 2053 - MATHEMATICS FOR FINANCIAL ANALYSIS',
        courseName:
          'Actuarial Science 2053 - MATHEMATICS FOR FINANCIAL ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 18,
        id: '60cc1a3947f7e10015fd5947',
      },
      {
        _id: '60cc1a3b47f7e10015fd5949',
        courseCode: 'AMERICAN 1020',
        shortName:
          'American Studies 1020 - AN INTRODUCTION TO AMERICAN STUDIES',
        courseName:
          'American Studies 1020 - AN INTRODUCTION TO AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc1a3b47f7e10015fd5949',
      },
      {
        _id: '60cc204c47f7e10015fd594b',
        courseCode: 'ACTURSCI 1021',
        shortName:
          'Actuarial Science 1021A/B - INTRODUCTION TO FINANCIAL SECURITY SYSTEMS',
        courseName:
          'Actuarial Science 1021A/B - INTRODUCTION TO FINANCIAL SECURITY SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204c47f7e10015fd594b',
      },
      {
        _id: '60cc204d47f7e10015fd594d',
        courseCode: 'ACTURSCI 2427',
        shortName:
          'Actuarial Science 2427A/B - LONG TERM ACTUARIAL MATHEMATICS I',
        courseName:
          'Actuarial Science 2427A/B - LONG TERM ACTUARIAL MATHEMATICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204d47f7e10015fd594d',
      },
      {
        _id: '60cc204d47f7e10015fd594f',
        courseCode: 'ACTURSCI 2553',
        shortName: 'Actuarial Science 2553A/B - MATHEMATICS OF FINANCE',
        courseName: 'Actuarial Science 2553A/B - MATHEMATICS OF FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204d47f7e10015fd594f',
      },
      {
        _id: '60cc204d47f7e10015fd5951',
        courseCode: 'ACTURSCI 3424',
        shortName:
          'Actuarial Science 3424A/B - SHORT TERM ACTUARIAL MATHEMATICS I (LOSS MODELS)',
        courseName:
          'Actuarial Science 3424A/B - SHORT TERM ACTUARIAL MATHEMATICS I (LOSS MODELS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204d47f7e10015fd5951',
      },
      {
        _id: '60cc204e47f7e10015fd5953',
        courseCode: 'ACTURSCI 3429',
        shortName:
          'Actuarial Science 3429A/B - LONG TERM ACTUARIAL MATHEMATICS II',
        courseName:
          'Actuarial Science 3429A/B - LONG TERM ACTUARIAL MATHEMATICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204e47f7e10015fd5953',
      },
      {
        _id: '60cc204e47f7e10015fd5955',
        courseCode: 'ACTURSCI 3431',
        shortName:
          'Actuarial Science 3431A/B - LONG TERM ACTUARIAL MATHEMATICS III',
        courseName:
          'Actuarial Science 3431A/B - LONG TERM ACTUARIAL MATHEMATICS III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204e47f7e10015fd5955',
      },
      {
        _id: '60cc204e47f7e10015fd5957',
        courseCode: 'ACTURSCI 4426',
        shortName: 'Actuarial Science 4426F/G - ACTUARIAL PRACTICE I',
        courseName: 'Actuarial Science 4426F/G - ACTUARIAL PRACTICE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204e47f7e10015fd5957',
      },
      {
        _id: '60cc204e47f7e10015fd5959',
        courseCode: 'ACTURSCI 4823',
        shortName: 'Actuarial Science 4823A/B - SURVIVAL ANALYSIS',
        courseName: 'Actuarial Science 4823A/B - SURVIVAL ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204e47f7e10015fd5959',
      },
      {
        _id: '60cc204f47f7e10015fd595b',
        courseCode: 'ACTURSCI 4824',
        shortName:
          'Actuarial Science 4824A/B - SHORT TERM ACTUARIAL MATHEMATICS II',
        courseName:
          'Actuarial Science 4824A/B - SHORT TERM ACTUARIAL MATHEMATICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204f47f7e10015fd595b',
      },
      {
        _id: '60cc204f47f7e10015fd595d',
        courseCode: 'ACTURSCI 4950',
        shortName:
          'Actuarial Science 4950A/B - SELECTED TOPICS IN ACTUARIAL SCIENCE',
        courseName:
          'Actuarial Science 4950A/B - SELECTED TOPICS IN ACTUARIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204f47f7e10015fd595d',
      },
      {
        _id: '60cc204f47f7e10015fd595f',
        courseCode: 'ACTURSCI 4960',
        shortName:
          'Actuarial Science 4960F/G - SELECTED TOPICS IN ACTUARIAL SCIENCE',
        courseName:
          'Actuarial Science 4960F/G - SELECTED TOPICS IN ACTUARIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc204f47f7e10015fd595f',
      },
      {
        _id: '60cc205047f7e10015fd5961',
        courseCode: 'ACTURSCI 4997',
        shortName: 'Actuarial Science 4997F/G/Z - PROJECT IN ACTUARIAL SCIENCE',
        courseName:
          'Actuarial Science 4997F/G/Z - PROJECT IN ACTUARIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205047f7e10015fd5961',
      },
      {
        _id: '60cc205047f7e10015fd5963',
        courseCode: 'AMERICAN 2230',
        shortName:
          'American Studies 2230F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        courseName:
          'American Studies 2230F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205047f7e10015fd5963',
      },
      {
        _id: '60cc205047f7e10015fd5965',
        courseCode: 'AMERICAN 2231',
        shortName:
          'American Studies 2231F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        courseName:
          'American Studies 2231F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205047f7e10015fd5965',
      },
      {
        _id: '60cc205147f7e10015fd5967',
        courseCode: 'AMERICAN 2310',
        shortName:
          'American Studies 2310F/G - AMERICAN NIGHTMARE: AND INTRODUCTION TO AMERICAN STUDIES',
        courseName:
          'American Studies 2310F/G - AMERICAN NIGHTMARE: AND INTRODUCTION TO AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205147f7e10015fd5967',
      },
      {
        _id: '60cc205147f7e10015fd5969',
        courseCode: 'AMERICAN 3310',
        shortName:
          'American Studies 3310F/G - ADVANCED AMERICAN STUDIES: BEING AMERICAN',
        courseName:
          'American Studies 3310F/G - ADVANCED AMERICAN STUDIES: BEING AMERICAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205147f7e10015fd5969',
      },
      {
        _id: '60cc205147f7e10015fd596b',
        courseCode: 'AMERICAN 3330',
        shortName:
          'American Studies 3330F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        courseName:
          'American Studies 3330F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205147f7e10015fd596b',
      },
      {
        _id: '60cc205147f7e10015fd596d',
        courseCode: 'AMERICAN 3340',
        shortName:
          'American Studies 3340F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        courseName:
          'American Studies 3340F/G - SELECTED TOPICS IN AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205147f7e10015fd596d',
      },
      {
        _id: '60cc205247f7e10015fd596f',
        courseCode: 'AMERICAN 4420',
        shortName:
          'American Studies 4420E - INDEPENDENT THESIS IN AMERICAN STUDIES',
        courseName:
          'American Studies 4420E - INDEPENDENT THESIS IN AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205247f7e10015fd596f',
      },
      {
        _id: '60cc205247f7e10015fd5971',
        courseCode: 'AMERICAN 4430',
        shortName:
          'American Studies 4430F/G - DIRECTED READINGS IN AMERICAN STUDIES',
        courseName:
          'American Studies 4430F/G - DIRECTED READINGS IN AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205247f7e10015fd5971',
      },
      {
        _id: '60cc205247f7e10015fd5973',
        courseCode: 'ADS 2298',
        shortName:
          'Analytics and Decision Sciences 2298A/B - INTRODUCTION TO OPERATIONS RESEARCH',
        courseName:
          'Analytics and Decision Sciences 2298A/B - INTRODUCTION TO OPERATIONS RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205247f7e10015fd5973',
      },
      {
        _id: '60cc205347f7e10015fd5975',
        courseCode: 'ADS 3293',
        shortName:
          'Analytics and Decision Sciences 3293A/B - MODELING, RISK AND SIMULATION',
        courseName:
          'Analytics and Decision Sciences 3293A/B - MODELING, RISK AND SIMULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205347f7e10015fd5975',
      },
      {
        _id: '60cc205347f7e10015fd5977',
        courseCode: 'ADS 3864',
        shortName:
          'Analytics and Decision Sciences 3864A/B - DATA VISUALIZATION FOR MANAGEMENT AND SOCIAL SCIENCES',
        courseName:
          'Analytics and Decision Sciences 3864A/B - DATA VISUALIZATION FOR MANAGEMENT AND SOCIAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205347f7e10015fd5977',
      },
      {
        _id: '60cc205347f7e10015fd5979',
        courseCode: 'ADS 4293',
        shortName:
          'Analytics and Decision Sciences 4293A/B - PROFESSIONAL ANALYTICS',
        courseName:
          'Analytics and Decision Sciences 4293A/B - PROFESSIONAL ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205347f7e10015fd5979',
      },
      {
        _id: '60cc205447f7e10015fd597b',
        courseCode: 'ADS 4294',
        shortName: 'Analytics and Decision Sciences 4294A/B - COMPLEX PROBLEMS',
        courseName:
          'Analytics and Decision Sciences 4294A/B - COMPLEX PROBLEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205447f7e10015fd597b',
      },
      {
        _id: '60cc205447f7e10015fd597d',
        courseCode: 'ANATCELL 2200',
        shortName:
          'Anatomy and Cell Biology 2200A/B - SYSTEMIC ANATOMY OF THE HUMAN BODY',
        courseName:
          'Anatomy and Cell Biology 2200A/B - SYSTEMIC ANATOMY OF THE HUMAN BODY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205447f7e10015fd597d',
      },
      {
        _id: '60cc205447f7e10015fd597f',
        courseCode: 'ANATCELL 3200',
        shortName:
          'Anatomy and Cell Biology 3200A/B - FUNCTIONAL HUMAN NEUROANATOMY',
        courseName:
          'Anatomy and Cell Biology 3200A/B - FUNCTIONAL HUMAN NEUROANATOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205447f7e10015fd597f',
      },
      {
        _id: '60cc205447f7e10015fd5981',
        courseCode: 'ANATCELL 3309',
        shortName: 'Anatomy and Cell Biology 3309 - MAMMALIAN HISTOLOGY',
        courseName: 'Anatomy and Cell Biology 3309 - MAMMALIAN HISTOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205447f7e10015fd5981',
      },
      {
        _id: '60cc205547f7e10015fd5983',
        courseCode: 'ANATCELL 3329',
        shortName: 'Anatomy and Cell Biology 3329A/B - MEDICAL CELL BIOLOGY',
        courseName: 'Anatomy and Cell Biology 3329A/B - MEDICAL CELL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205547f7e10015fd5983',
      },
      {
        _id: '60cc205547f7e10015fd5985',
        courseCode: 'ANATCELL 3700',
        shortName:
          'Anatomy and Cell Biology 3700F/G - MODERN APPROACHES IN BIOMEDICAL RESEARCH',
        courseName:
          'Anatomy and Cell Biology 3700F/G - MODERN APPROACHES IN BIOMEDICAL RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205547f7e10015fd5985',
      },
      {
        _id: '60cc205547f7e10015fd5987',
        courseCode: 'ANATCELL 4200',
        shortName:
          'Anatomy and Cell Biology 4200A - CADAVERIC ANATOMY: MUSCULOSKELETAL',
        courseName:
          'Anatomy and Cell Biology 4200A - CADAVERIC ANATOMY: MUSCULOSKELETAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205547f7e10015fd5987',
      },
      {
        _id: '60cc205547f7e10015fd5989',
        courseCode: 'ANATCELL 4201',
        shortName:
          'Anatomy and Cell Biology 4201B - CADAVERIC ANATOMY: ORGANS & SYSTEMS',
        courseName:
          'Anatomy and Cell Biology 4201B - CADAVERIC ANATOMY: ORGANS & SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205547f7e10015fd5989',
      },
      {
        _id: '60cc205647f7e10015fd598b',
        courseCode: 'ANATCELL 4410',
        shortName:
          'Anatomy and Cell Biology 4410A - DISCOVERY-BASED CELL BIOLOGY I',
        courseName:
          'Anatomy and Cell Biology 4410A - DISCOVERY-BASED CELL BIOLOGY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205647f7e10015fd598b',
      },
      {
        _id: '60cc205647f7e10015fd598d',
        courseCode: 'ANATCELL 4411',
        shortName:
          'Anatomy and Cell Biology 4411B - DISCOVERY-BASED CELL BIOLOGY II',
        courseName:
          'Anatomy and Cell Biology 4411B - DISCOVERY-BASED CELL BIOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205647f7e10015fd598d',
      },
      {
        _id: '60cc205647f7e10015fd598f',
        courseCode: 'ANATCELL 4425',
        shortName:
          'Anatomy and Cell Biology 4425A/B - ADVANCED CLINICAL HISTOLOGY',
        courseName:
          'Anatomy and Cell Biology 4425A/B - ADVANCED CLINICAL HISTOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205647f7e10015fd598f',
      },
      {
        _id: '60cc205647f7e10015fd5991',
        courseCode: 'ANATCELL 4451',
        shortName:
          'Anatomy and Cell Biology 4451F/G - INTEGRATIVE NEUROSCIENCE',
        courseName:
          'Anatomy and Cell Biology 4451F/G - INTEGRATIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205647f7e10015fd5991',
      },
      {
        _id: '60cc205647f7e10015fd5993',
        courseCode: 'ANATCELL 4452',
        shortName:
          'Anatomy and Cell Biology 4452A/B - NEUROBIOLOGY OF MENTAL ILLNESS',
        courseName:
          'Anatomy and Cell Biology 4452A/B - NEUROBIOLOGY OF MENTAL ILLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205647f7e10015fd5993',
      },
      {
        _id: '60cc205747f7e10015fd5995',
        courseCode: 'ANATCELL 4461',
        shortName:
          'Anatomy and Cell Biology 4461B - TRANSLATIONAL MODELS OF CANCER',
        courseName:
          'Anatomy and Cell Biology 4461B - TRANSLATIONAL MODELS OF CANCER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205747f7e10015fd5995',
      },
      {
        _id: '60cc205747f7e10015fd5997',
        courseCode: 'ANATCELL 4480',
        shortName:
          'Anatomy and Cell Biology 4480E - RESEARCH PROJECT AND SEMINAR',
        courseName:
          'Anatomy and Cell Biology 4480E - RESEARCH PROJECT AND SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205747f7e10015fd5997',
      },
      {
        _id: '60cc205747f7e10015fd5999',
        courseCode: 'ANTHRO 1020',
        shortName: 'Anthropology 1020 - MANY WAYS OF BEING HUMAN',
        courseName: 'Anthropology 1020 - MANY WAYS OF BEING HUMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205747f7e10015fd5999',
      },
      {
        _id: '60cc205747f7e10015fd599b',
        courseCode: 'ANTHRO 1021',
        shortName:
          'Anthropology 1021A/B - INTRODUCTION TO SOCIOCULTURAL AND LINGUISTIC ANTHROPOLOGY',
        courseName:
          'Anthropology 1021A/B - INTRODUCTION TO SOCIOCULTURAL AND LINGUISTIC ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205747f7e10015fd599b',
      },
      {
        _id: '60cc205847f7e10015fd599d',
        courseCode: 'ANTHRO 1022',
        shortName:
          'Anthropology 1022A/B - INTRODUCTION TO BIOLOGICAL ANTHROPOLOGY AND ARCHAEOLOGY',
        courseName:
          'Anthropology 1022A/B - INTRODUCTION TO BIOLOGICAL ANTHROPOLOGY AND ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205847f7e10015fd599d',
      },
      {
        _id: '60cc205847f7e10015fd599f',
        courseCode: 'ANTHRO 1025',
        shortName:
          'Anthropology 1025F/G - INTRODUCTION TO SOCIOCULTURAL AND LINGUISTIC ANTHROPOLOGY',
        courseName:
          'Anthropology 1025F/G - INTRODUCTION TO SOCIOCULTURAL AND LINGUISTIC ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205847f7e10015fd599f',
      },
      {
        _id: '60cc205847f7e10015fd59a1',
        courseCode: 'ANTHRO 1026',
        shortName:
          'Anthropology 1026F/G - INTRODUCTION TO BIOLOGICAL ANTHROPOLOGY AND ARCHAEOLOGY',
        courseName:
          'Anthropology 1026F/G - INTRODUCTION TO BIOLOGICAL ANTHROPOLOGY AND ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205847f7e10015fd59a1',
      },
      {
        _id: '60cc205947f7e10015fd59a3',
        courseCode: 'ANTHRO 1027',
        shortName: 'Anthropology 1027A/B - INTRODUCTION TO LINGUISTICS',
        courseName: 'Anthropology 1027A/B - INTRODUCTION TO LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205947f7e10015fd59a3',
      },
      {
        _id: '60cc205947f7e10015fd59a5',
        courseCode: 'ANTHRO 2100',
        shortName: 'Anthropology 2100 - ARCHAEOLOGY AND WORLD PREHISTORY',
        courseName: 'Anthropology 2100 - ARCHAEOLOGY AND WORLD PREHISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205947f7e10015fd59a5',
      },
      {
        _id: '60cc205947f7e10015fd59a7',
        courseCode: 'ANTHRO 2101',
        shortName: 'Anthropology 2101A/B - GREAT ARCHAEOLOGICAL SITES',
        courseName: 'Anthropology 2101A/B - GREAT ARCHAEOLOGICAL SITES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205947f7e10015fd59a7',
      },
      {
        _id: '60cc205947f7e10015fd59a9',
        courseCode: 'ANTHRO 2151',
        shortName: 'Anthropology 2151A/B - LANGUAGE, GENDER AND SEXUALITY',
        courseName: 'Anthropology 2151A/B - LANGUAGE, GENDER AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205947f7e10015fd59a9',
      },
      {
        _id: '60cc205a47f7e10015fd59ab',
        courseCode: 'ANTHRO 2152',
        shortName: 'Anthropology 2152A/B - LANGUAGES IN CANADA',
        courseName: 'Anthropology 2152A/B - LANGUAGES IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205a47f7e10015fd59ab',
      },
      {
        _id: '60cc205a47f7e10015fd59ad',
        courseCode: 'ANTHRO 2201',
        shortName: 'Anthropology 2201F/G - URBAN ANTHROPOLOGY',
        courseName: 'Anthropology 2201F/G - URBAN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205a47f7e10015fd59ad',
      },
      {
        _id: '60cc205a47f7e10015fd59af',
        courseCode: 'ANTHRO 2203',
        shortName:
          'Anthropology 2203F/G - INDIGENOUS PEOPLES, GLOBALIZATION AND THE ENVIRONMENT',
        courseName:
          'Anthropology 2203F/G - INDIGENOUS PEOPLES, GLOBALIZATION AND THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205a47f7e10015fd59af',
      },
      {
        _id: '60cc205a47f7e10015fd59b1',
        courseCode: 'ANTHRO 2211',
        shortName: 'Anthropology 2211F/G - CULTURES OF THE CARIBBEAN',
        courseName: 'Anthropology 2211F/G - CULTURES OF THE CARIBBEAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205a47f7e10015fd59b1',
      },
      {
        _id: '60cc205a47f7e10015fd59b3',
        courseCode: 'ANTHRO 2212',
        shortName: 'Anthropology 2212F/G - CULTURES OF THE PACIFIC',
        courseName: 'Anthropology 2212F/G - CULTURES OF THE PACIFIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205a47f7e10015fd59b3',
      },
      {
        _id: '60cc205b47f7e10015fd59b5',
        courseCode: 'ANTHRO 2216',
        shortName:
          'Anthropology 2216F/G - ANTHROPOLOGICAL PERSPECTIVES ON LATIN AMERICA',
        courseName:
          'Anthropology 2216F/G - ANTHROPOLOGICAL PERSPECTIVES ON LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205b47f7e10015fd59b5',
      },
      {
        _id: '60cc205b47f7e10015fd59b7',
        courseCode: 'ANTHRO 2219',
        shortName: 'Anthropology 2219F/G - CULTURES OF THE MIDDLE EAST',
        courseName: 'Anthropology 2219F/G - CULTURES OF THE MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205b47f7e10015fd59b7',
      },
      {
        _id: '60cc205b47f7e10015fd59b9',
        courseCode: 'ANTHRO 2222',
        shortName:
          'Anthropology 2222F/G - DEBATES IN SOCIOCULTURAL ANTHROPOLOGY',
        courseName:
          'Anthropology 2222F/G - DEBATES IN SOCIOCULTURAL ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205b47f7e10015fd59b9',
      },
      {
        _id: '60cc205c47f7e10015fd59bb',
        courseCode: 'ANTHRO 2226',
        shortName: 'Anthropology 2226A/B - BIOLOGICAL ANTHROPOLOGY',
        courseName: 'Anthropology 2226A/B - BIOLOGICAL ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205c47f7e10015fd59bb',
      },
      {
        _id: '60cc205c47f7e10015fd59bd',
        courseCode: 'ANTHRO 2228',
        shortName: 'Anthropology 2228F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 2228F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205c47f7e10015fd59bd',
      },
      {
        _id: '60cc205c47f7e10015fd59bf',
        courseCode: 'ANTHRO 2229',
        shortName: 'Anthropology 2229F/G - PRINCIPLES OF ARCHAEOLOGY',
        courseName: 'Anthropology 2229F/G - PRINCIPLES OF ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205c47f7e10015fd59bf',
      },
      {
        _id: '60cc205c47f7e10015fd59c1',
        courseCode: 'ANTHRO 2230',
        shortName: 'Anthropology 2230F/G - ARCTIC ARCHAEOLOGY',
        courseName: 'Anthropology 2230F/G - ARCTIC ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205c47f7e10015fd59c1',
      },
      {
        _id: '60cc205c47f7e10015fd59c3',
        courseCode: 'ANTHRO 2232',
        shortName:
          'Anthropology 2232F/G - SPECIAL TOPICS IN REGIONAL ARCHAEOLOGY',
        courseName:
          'Anthropology 2232F/G - SPECIAL TOPICS IN REGIONAL ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205c47f7e10015fd59c3',
      },
      {
        _id: '60cc205d47f7e10015fd59c5',
        courseCode: 'ANTHRO 2233',
        shortName:
          'Anthropology 2233F/G - ARCHAEOLOGY OF ONTARIO AND THE GREAT LAKES',
        courseName:
          'Anthropology 2233F/G - ARCHAEOLOGY OF ONTARIO AND THE GREAT LAKES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205d47f7e10015fd59c5',
      },
      {
        _id: '60cc205d47f7e10015fd59c7',
        courseCode: 'ANTHRO 2234',
        shortName: 'Anthropology 2234F/G - ANDEAN PREHISTORY',
        courseName: 'Anthropology 2234F/G - ANDEAN PREHISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205d47f7e10015fd59c7',
      },
      {
        _id: '60cc205d47f7e10015fd59c9',
        courseCode: 'ANTHRO 2235',
        shortName: 'Anthropology 2235A/B - INDIVIDUATION IN FORENSIC SCIENCE',
        courseName: 'Anthropology 2235A/B - INDIVIDUATION IN FORENSIC SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205d47f7e10015fd59c9',
      },
      {
        _id: '60cc205e47f7e10015fd59cb',
        courseCode: 'ANTHRO 2236',
        shortName:
          'Anthropology 2236A/B - ANTHROPOLOGICAL PERSPECTIVES ON HUMAN GROWTH, DEVELOPMENT AND AGING',
        courseName:
          'Anthropology 2236A/B - ANTHROPOLOGICAL PERSPECTIVES ON HUMAN GROWTH, DEVELOPMENT AND AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205e47f7e10015fd59cb',
      },
      {
        _id: '60cc205e47f7e10015fd59cd',
        courseCode: 'ANTHRO 2237',
        shortName:
          'Anthropology 2237A/B - HUMAN ADAPTABILITY AND RESILIENCE: LIFE AT THE EXTREMES',
        courseName:
          'Anthropology 2237A/B - HUMAN ADAPTABILITY AND RESILIENCE: LIFE AT THE EXTREMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205e47f7e10015fd59cd',
      },
      {
        _id: '60cc205e47f7e10015fd59cf',
        courseCode: 'ANTHRO 2238',
        shortName:
          'Anthropology 2238A/B - READING LIFE AND DEATH THROUGH THE HUMAN BODY',
        courseName:
          'Anthropology 2238A/B - READING LIFE AND DEATH THROUGH THE HUMAN BODY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205e47f7e10015fd59cf',
      },
      {
        _id: '60cc205e47f7e10015fd59d1',
        courseCode: 'ANTHRO 2239',
        shortName:
          'Anthropology 2239A/B - MUMMIES: THE SCIENTIFIC AND CULTURAL ANALYSIS OF HUMAN MUMMIES',
        courseName:
          'Anthropology 2239A/B - MUMMIES: THE SCIENTIFIC AND CULTURAL ANALYSIS OF HUMAN MUMMIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205e47f7e10015fd59d1',
      },
      {
        _id: '60cc205e47f7e10015fd59d3',
        courseCode: 'ANTHRO 2240',
        shortName: 'Anthropology 2240A/B - EVOLUTION, ECOLOGY AND HUMAN HEALTH',
        courseName:
          'Anthropology 2240A/B - EVOLUTION, ECOLOGY AND HUMAN HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205e47f7e10015fd59d3',
      },
      {
        _id: '60cc205f47f7e10015fd59d5',
        courseCode: 'ANTHRO 2245',
        shortName:
          'Anthropology 2245F/G - ANTHROPOLOGICAL APPROACHES TO LANGUAGE',
        courseName:
          'Anthropology 2245F/G - ANTHROPOLOGICAL APPROACHES TO LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205f47f7e10015fd59d5',
      },
      {
        _id: '60cc205f47f7e10015fd59d7',
        courseCode: 'ANTHRO 2246',
        shortName: 'Anthropology 2246F/G - ANTHROPOLOGY OF READING AND WRITING',
        courseName:
          'Anthropology 2246F/G - ANTHROPOLOGY OF READING AND WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205f47f7e10015fd59d7',
      },
      {
        _id: '60cc205f47f7e10015fd59d9',
        courseCode: 'ANTHRO 2249',
        shortName: 'Anthropology 2249F/G - DISCOURSE ANALYSIS',
        courseName: 'Anthropology 2249F/G - DISCOURSE ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205f47f7e10015fd59d9',
      },
      {
        _id: '60cc205f47f7e10015fd59db',
        courseCode: 'ANTHRO 2250',
        shortName:
          'Anthropology 2250F/G - VERBAL ART, PERFORMANCE AND SPEECH PLAY',
        courseName:
          'Anthropology 2250F/G - VERBAL ART, PERFORMANCE AND SPEECH PLAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc205f47f7e10015fd59db',
      },
      {
        _id: '60cc206047f7e10015fd59dd',
        courseCode: 'ANTHRO 2251',
        shortName: 'Anthropology 2251F/G - LANGUAGE, GENDER AND SEXUALITY',
        courseName: 'Anthropology 2251F/G - LANGUAGE, GENDER AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206047f7e10015fd59dd',
      },
      {
        _id: '60cc206047f7e10015fd59df',
        courseCode: 'ANTHRO 2252',
        shortName: 'Anthropology 2252F/G - LANGUAGES IN CANADA',
        courseName: 'Anthropology 2252F/G - LANGUAGES IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206047f7e10015fd59df',
      },
      {
        _id: '60cc206047f7e10015fd59e1',
        courseCode: 'ANTHRO 2253',
        shortName:
          'Anthropology 2253A/B - ENDANGERED LANGUAGES AND REVITALIZATION',
        courseName:
          'Anthropology 2253A/B - ENDANGERED LANGUAGES AND REVITALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206047f7e10015fd59e1',
      },
      {
        _id: '60cc206047f7e10015fd59e3',
        courseCode: 'ANTHRO 2255',
        shortName: 'Anthropology 2255E - FEMINIST PERSPECTIVES IN ANTHROPOLOGY',
        courseName:
          'Anthropology 2255E - FEMINIST PERSPECTIVES IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206047f7e10015fd59e3',
      },
      {
        _id: '60cc206147f7e10015fd59e5',
        courseCode: 'ANTHRO 2260',
        shortName: 'Anthropology 2260F/G - "NATURE" IN THE CITY',
        courseName: 'Anthropology 2260F/G - "NATURE" IN THE CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206147f7e10015fd59e5',
      },
      {
        _id: '60cc206147f7e10015fd59e7',
        courseCode: 'ANTHRO 2261',
        shortName:
          'Anthropology 2261F/G - ADVENTURES IN POP CULTURE ARCHAEOLOGY',
        courseName:
          'Anthropology 2261F/G - ADVENTURES IN POP CULTURE ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206147f7e10015fd59e7',
      },
      {
        _id: '60cc206147f7e10015fd59e9',
        courseCode: 'ANTHRO 2262',
        shortName:
          'Anthropology 2262F/G - THE PRODUCTION AND CONSUMPTION OF GLOBAL COMMODITIES',
        courseName:
          'Anthropology 2262F/G - THE PRODUCTION AND CONSUMPTION OF GLOBAL COMMODITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206147f7e10015fd59e9',
      },
      {
        _id: '60cc206147f7e10015fd59eb',
        courseCode: 'ANTHRO 2263',
        shortName:
          'Anthropology 2263A/B - BODIES: MAKING, BUYING, LIVING AND DYING',
        courseName:
          'Anthropology 2263A/B - BODIES: MAKING, BUYING, LIVING AND DYING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206147f7e10015fd59eb',
      },
      {
        _id: '60cc206247f7e10015fd59ed',
        courseCode: 'ANTHRO 2264',
        shortName: 'Anthropology 2264F/G - ISSUES IN PRIMATE CONSERVATION',
        courseName: 'Anthropology 2264F/G - ISSUES IN PRIMATE CONSERVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206247f7e10015fd59ed',
      },
      {
        _id: '60cc206247f7e10015fd59ef',
        courseCode: 'ANTHRO 2265',
        shortName: 'Anthropology 2265F/G - PRIMATE BEHAVIOR',
        courseName: 'Anthropology 2265F/G - PRIMATE BEHAVIOR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206247f7e10015fd59ef',
      },
      {
        _id: '60cc206247f7e10015fd59f1',
        courseCode: 'ANTHRO 2267',
        shortName: 'Anthropology 2267A/B - ANTHROPOLOGY OF ZOOS',
        courseName: 'Anthropology 2267A/B - ANTHROPOLOGY OF ZOOS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206247f7e10015fd59f1',
      },
      {
        _id: '60cc206247f7e10015fd59f3',
        courseCode: 'ANTHRO 2270',
        shortName: 'Anthropology 2270F/G - ANTHROPOLOGY OF THE FAMILY',
        courseName: 'Anthropology 2270F/G - ANTHROPOLOGY OF THE FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206247f7e10015fd59f3',
      },
      {
        _id: '60cc206247f7e10015fd59f5',
        courseCode: 'ANTHRO 2272',
        shortName: 'Anthropology 2272F/G - ANTHROPOLOGY OF TOURISM',
        courseName: 'Anthropology 2272F/G - ANTHROPOLOGY OF TOURISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206247f7e10015fd59f5',
      },
      {
        _id: '60cc206347f7e10015fd59f7',
        courseCode: 'ANTHRO 2275',
        shortName: 'Anthropology 2275A/B - ANTHROPOLOGY OF SEX AND GENDER',
        courseName: 'Anthropology 2275A/B - ANTHROPOLOGY OF SEX AND GENDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206347f7e10015fd59f7',
      },
      {
        _id: '60cc206347f7e10015fd59f9',
        courseCode: 'ANTHRO 2276',
        shortName: 'Anthropology 2276F/G - THE ANTHROPOLOGY OF MUSIC',
        courseName: 'Anthropology 2276F/G - THE ANTHROPOLOGY OF MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206347f7e10015fd59f9',
      },
      {
        _id: '60cc206347f7e10015fd59fb',
        courseCode: 'ANTHRO 2277',
        shortName: 'Anthropology 2277F/G - ANTHROPOLOGY OF DISASTER',
        courseName: 'Anthropology 2277F/G - ANTHROPOLOGY OF DISASTER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206347f7e10015fd59fb',
      },
      {
        _id: '60cc206347f7e10015fd59fd',
        courseCode: 'ANTHRO 2278',
        shortName:
          'Anthropology 2278F/G - ANTHROPOLOGY OF RACE, ETHNICITY, AND IDENTITY',
        courseName:
          'Anthropology 2278F/G - ANTHROPOLOGY OF RACE, ETHNICITY, AND IDENTITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206347f7e10015fd59fd',
      },
      {
        _id: '60cc206447f7e10015fd59ff',
        courseCode: 'ANTHRO 2280',
        shortName: 'Anthropology 2280F/G - ECONOMIC ANTHROPOLOGY',
        courseName: 'Anthropology 2280F/G - ECONOMIC ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206447f7e10015fd59ff',
      },
      {
        _id: '60cc206447f7e10015fd5a01',
        courseCode: 'ANTHRO 2281',
        shortName:
          'Anthropology 2281F/G - ANTHROPOLOGY OF INTERNATIONAL DEVELOPMENT',
        courseName:
          'Anthropology 2281F/G - ANTHROPOLOGY OF INTERNATIONAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206447f7e10015fd5a01',
      },
      {
        _id: '60cc206447f7e10015fd5a03',
        courseCode: 'ANTHRO 2282',
        shortName: 'Anthropology 2282F/G - THE ANTHROPOLOGY OF MIGRATION',
        courseName: 'Anthropology 2282F/G - THE ANTHROPOLOGY OF MIGRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206447f7e10015fd5a03',
      },
      {
        _id: '60cc206447f7e10015fd5a05',
        courseCode: 'ANTHRO 2283',
        shortName:
          'Anthropology 2283F/G - REFUGEES AND THE DISPLACED: AN ANTHROPOLOGICAL APPROACH TO FORCED MIGRATION',
        courseName:
          'Anthropology 2283F/G - REFUGEES AND THE DISPLACED: AN ANTHROPOLOGICAL APPROACH TO FORCED MIGRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206447f7e10015fd5a05',
      },
      {
        _id: '60cc206547f7e10015fd5a07',
        courseCode: 'ANTHRO 2284',
        shortName:
          'Anthropology 2284F/G - MOBILE PHONES AND THE INTERNET IN THE GLOBAL SOUTH',
        courseName:
          'Anthropology 2284F/G - MOBILE PHONES AND THE INTERNET IN THE GLOBAL SOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206547f7e10015fd5a07',
      },
      {
        _id: '60cc206547f7e10015fd5a09',
        courseCode: 'ANTHRO 2285',
        shortName:
          'Anthropology 2285F/G - CLOTHING AND CULTURE: THE ANTHROPOLOGY OF FASHION',
        courseName:
          'Anthropology 2285F/G - CLOTHING AND CULTURE: THE ANTHROPOLOGY OF FASHION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206547f7e10015fd5a09',
      },
      {
        _id: '60cc206547f7e10015fd5a0b',
        courseCode: 'ANTHRO 2290',
        shortName:
          'Anthropology 2290F/G - CULTURES OF HEALTH, ILLNESS, DISABILITY AND HEALING',
        courseName:
          'Anthropology 2290F/G - CULTURES OF HEALTH, ILLNESS, DISABILITY AND HEALING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206547f7e10015fd5a0b',
      },
      {
        _id: '60cc206547f7e10015fd5a0d',
        courseCode: 'ANTHRO 2293',
        shortName: 'Anthropology 2293F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 2293F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206547f7e10015fd5a0d',
      },
      {
        _id: '60cc206647f7e10015fd5a0f',
        courseCode: 'ANTHRO 2294',
        shortName: 'Anthropology 2294F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 2294F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206647f7e10015fd5a0f',
      },
      {
        _id: '60cc206647f7e10015fd5a11',
        courseCode: 'ANTHRO 2295',
        shortName: 'Anthropology 2295F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 2295F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206647f7e10015fd5a11',
      },
      {
        _id: '60cc206647f7e10015fd5a13',
        courseCode: 'ANTHRO 2296',
        shortName: 'Anthropology 2296A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 2296A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206647f7e10015fd5a13',
      },
      {
        _id: '60cc206647f7e10015fd5a15',
        courseCode: 'ANTHRO 2297',
        shortName: 'Anthropology 2297A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 2297A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206647f7e10015fd5a15',
      },
      {
        _id: '60cc206647f7e10015fd5a17',
        courseCode: 'ANTHRO 3223',
        shortName:
          'Anthropology 3223F/G - DOING FIELDWORK IN SOCIOCULTURAL ANTHROPOLOGY',
        courseName:
          'Anthropology 3223F/G - DOING FIELDWORK IN SOCIOCULTURAL ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206647f7e10015fd5a17',
      },
      {
        _id: '60cc206747f7e10015fd5a19',
        courseCode: 'ANTHRO 3237',
        shortName: 'Anthropology 3237A/B - FIELD TECHNIQUES IN LINGUISITCS',
        courseName: 'Anthropology 3237A/B - FIELD TECHNIQUES IN LINGUISITCS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206747f7e10015fd5a19',
      },
      {
        _id: '60cc206747f7e10015fd5a1b',
        courseCode: 'ANTHRO 3243',
        shortName: 'Anthropology 3243F/G - APPLIED LINGUISTICS',
        courseName: 'Anthropology 3243F/G - APPLIED LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206747f7e10015fd5a1b',
      },
      {
        _id: '60cc206747f7e10015fd5a1d',
        courseCode: 'ANTHRO 3305',
        shortName:
          'Anthropology 3305F/G - HISTORY, TERRITORY AND THE POLITICS OF IDENTITY',
        courseName:
          'Anthropology 3305F/G - HISTORY, TERRITORY AND THE POLITICS OF IDENTITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206747f7e10015fd5a1d',
      },
      {
        _id: '60cc206747f7e10015fd5a1f',
        courseCode: 'ANTHRO 3307',
        shortName: 'Anthropology 3307A - FIELD METHODS IN ARCHAEOLOGY',
        courseName: 'Anthropology 3307A - FIELD METHODS IN ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206747f7e10015fd5a1f',
      },
      {
        _id: '60cc206847f7e10015fd5a21',
        courseCode: 'ANTHRO 3308',
        shortName: 'Anthropology 3308F/G - ARCHAEOLOGY: THEORY AND PRACTICE',
        courseName: 'Anthropology 3308F/G - ARCHAEOLOGY: THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206847f7e10015fd5a21',
      },
      {
        _id: '60cc206847f7e10015fd5a23',
        courseCode: 'ANTHRO 3309',
        shortName: 'Anthropology 3309F/G - HUNTING AND GATHERING SOCIETIES',
        courseName: 'Anthropology 3309F/G - HUNTING AND GATHERING SOCIETIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206847f7e10015fd5a23',
      },
      {
        _id: '60cc206847f7e10015fd5a25',
        courseCode: 'ANTHRO 3310',
        shortName: 'Anthropology 3310A/B - ZOOARCHAELOGY',
        courseName: 'Anthropology 3310A/B - ZOOARCHAELOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206847f7e10015fd5a25',
      },
      {
        _id: '60cc206847f7e10015fd5a27',
        courseCode: 'ANTHRO 3311',
        shortName: 'Anthropology 3311F/G - BIOARCHAEOLOGY: PRACTICE AND THEORY',
        courseName:
          'Anthropology 3311F/G - BIOARCHAEOLOGY: PRACTICE AND THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206847f7e10015fd5a27',
      },
      {
        _id: '60cc206947f7e10015fd5a29',
        courseCode: 'ANTHRO 3312',
        shortName:
          'Anthropology 3312F/G - HISTORICAL ARCHAEOLOGY - INTERPRETING THE RECENT PAST',
        courseName:
          'Anthropology 3312F/G - HISTORICAL ARCHAEOLOGY - INTERPRETING THE RECENT PAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206947f7e10015fd5a29',
      },
      {
        _id: '60cc206947f7e10015fd5a2b',
        courseCode: 'ANTHRO 3313',
        shortName: 'Anthropology 3313A/B - ARTIFACT ANALYSIS',
        courseName: 'Anthropology 3313A/B - ARTIFACT ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206947f7e10015fd5a2b',
      },
      {
        _id: '60cc206947f7e10015fd5a2d',
        courseCode: 'ANTHRO 3320',
        shortName: 'Anthropology 3320 - ARCHAELOGICAL SUMMER FIELD SCHOOL',
        courseName: 'Anthropology 3320 - ARCHAELOGICAL SUMMER FIELD SCHOOL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206947f7e10015fd5a2d',
      },
      {
        _id: '60cc206947f7e10015fd5a2f',
        courseCode: 'ANTHRO 3321',
        shortName: 'Anthropology 3321 - FIELD COURSE IN ANTHROPOLOGY',
        courseName: 'Anthropology 3321 - FIELD COURSE IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206947f7e10015fd5a2f',
      },
      {
        _id: '60cc206a47f7e10015fd5a31',
        courseCode: 'ANTHRO 3322',
        shortName:
          'Anthropology 3322 - FIELD COURSE IN ENVIRONMENTAL ANTHROPOLOGY',
        courseName:
          'Anthropology 3322 - FIELD COURSE IN ENVIRONMENTAL ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206a47f7e10015fd5a31',
      },
      {
        _id: '60cc206a47f7e10015fd5a33',
        courseCode: 'ANTHRO 3323',
        shortName: 'Anthropology 3323A/B - FIELD COURSE IN ANTHROPOLOGY',
        courseName: 'Anthropology 3323A/B - FIELD COURSE IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206a47f7e10015fd5a33',
      },
      {
        _id: '60cc206a47f7e10015fd5a35',
        courseCode: 'ANTHRO 3324',
        shortName:
          'Anthropology 3324A/B - ARCHAEOLOGICAL FIELD SCHOOL - SITE MANAGEMENT',
        courseName:
          'Anthropology 3324A/B - ARCHAEOLOGICAL FIELD SCHOOL - SITE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206a47f7e10015fd5a35',
      },
      {
        _id: '60cc206a47f7e10015fd5a37',
        courseCode: 'ANTHRO 3325',
        shortName: 'Anthropology 3325F/G - READINGS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3325F/G - READINGS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206a47f7e10015fd5a37',
      },
      {
        _id: '60cc206b47f7e10015fd5a39',
        courseCode: 'ANTHRO 3326',
        shortName: 'Anthropology 3326F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3326F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206b47f7e10015fd5a39',
      },
      {
        _id: '60cc206b47f7e10015fd5a3b',
        courseCode: 'ANTHRO 3331',
        shortName: 'Anthropology 3331F/G - READING ETHNOGRAPHY',
        courseName: 'Anthropology 3331F/G - READING ETHNOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206b47f7e10015fd5a3b',
      },
      {
        _id: '60cc206b47f7e10015fd5a3d',
        courseCode: 'ANTHRO 3332',
        shortName:
          'Anthropology 3332F/G - SOCIOCULTURAL ANTHROPOLOGICAL PERSPECTIVES ON THE LIFECOURSE',
        courseName:
          'Anthropology 3332F/G - SOCIOCULTURAL ANTHROPOLOGICAL PERSPECTIVES ON THE LIFECOURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206b47f7e10015fd5a3d',
      },
      {
        _id: '60cc206b47f7e10015fd5a3f',
        courseCode: 'ANTHRO 3334',
        shortName: 'Anthropology 3334F/G - PRIMATE AND HUMAN PALEONTOLOGY',
        courseName: 'Anthropology 3334F/G - PRIMATE AND HUMAN PALEONTOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206b47f7e10015fd5a3f',
      },
      {
        _id: '60cc206b47f7e10015fd5a41',
        courseCode: 'ANTHRO 3336',
        shortName: 'Anthropology 3336F/G - DEBATES IN HUMAN EVOLUTION',
        courseName: 'Anthropology 3336F/G - DEBATES IN HUMAN EVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206b47f7e10015fd5a41',
      },
      {
        _id: '60cc206c47f7e10015fd5a43',
        courseCode: 'ANTHRO 3338',
        shortName: 'Anthropology 3338F/G - HUMAN SKELETAL BIOLOGY',
        courseName: 'Anthropology 3338F/G - HUMAN SKELETAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206c47f7e10015fd5a43',
      },
      {
        _id: '60cc206c47f7e10015fd5a45',
        courseCode: 'ANTHRO 3339',
        shortName: 'Anthropology 3339F/G - LANGUAGE VARIATION AND CHANGE',
        courseName: 'Anthropology 3339F/G - LANGUAGE VARIATION AND CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206c47f7e10015fd5a45',
      },
      {
        _id: '60cc206c47f7e10015fd5a47',
        courseCode: 'ANTHRO 3340',
        shortName:
          'Anthropology 3340A/B - BIOMEDICAL ANTHROPOLOGY AND EVOLUTIONARY MEDICINE',
        courseName:
          'Anthropology 3340A/B - BIOMEDICAL ANTHROPOLOGY AND EVOLUTIONARY MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206c47f7e10015fd5a47',
      },
      {
        _id: '60cc206c47f7e10015fd5a49',
        courseCode: 'ANTHRO 3341',
        shortName: 'Anthropology 3341F/G - ANTHROPOLOGY OF FOOD AND NUTRITION',
        courseName: 'Anthropology 3341F/G - ANTHROPOLOGY OF FOOD AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206c47f7e10015fd5a49',
      },
      {
        _id: '60cc206d47f7e10015fd5a4b',
        courseCode: 'ANTHRO 3343',
        shortName: 'Anthropology 3343A/B - LANGUAGE REVITALIZATION IN PRACTICE',
        courseName:
          'Anthropology 3343A/B - LANGUAGE REVITALIZATION IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206d47f7e10015fd5a4b',
      },
      {
        _id: '60cc206d47f7e10015fd5a4d',
        courseCode: 'ANTHRO 3350',
        shortName:
          'Anthropology 3350F - SOCIETY AND CULTURE I: HISTORICAL PERSPECTIVES',
        courseName:
          'Anthropology 3350F - SOCIETY AND CULTURE I: HISTORICAL PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206d47f7e10015fd5a4d',
      },
      {
        _id: '60cc206d47f7e10015fd5a4f',
        courseCode: 'ANTHRO 3351',
        shortName:
          'Anthropology 3351G - SOCIETY AND CULTURE II: IDENTITY, POWER AND SOCIAL FORMATION',
        courseName:
          'Anthropology 3351G - SOCIETY AND CULTURE II: IDENTITY, POWER AND SOCIAL FORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206d47f7e10015fd5a4f',
      },
      {
        _id: '60cc206d47f7e10015fd5a51',
        courseCode: 'ANTHRO 3354',
        shortName:
          'Anthropology 3354F/G - DISABILITY AND HEALTH IN LOCAL AND GLOBAL WORLDS',
        courseName:
          'Anthropology 3354F/G - DISABILITY AND HEALTH IN LOCAL AND GLOBAL WORLDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206d47f7e10015fd5a51',
      },
      {
        _id: '60cc206d47f7e10015fd5a53',
        courseCode: 'ANTHRO 3355',
        shortName:
          'Anthropology 3355F/G - ZOMBIES IN CULTURAL AND HISTORICAL PERSPECTIVE',
        courseName:
          'Anthropology 3355F/G - ZOMBIES IN CULTURAL AND HISTORICAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206d47f7e10015fd5a53',
      },
      {
        _id: '60cc206e47f7e10015fd5a55',
        courseCode: 'ANTHRO 3389',
        shortName:
          'Anthropology 3389F/G - ADVANCED SPECIAL TOPICS IN REFUGEE AND MIGRANT STUDIES',
        courseName:
          'Anthropology 3389F/G - ADVANCED SPECIAL TOPICS IN REFUGEE AND MIGRANT STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206e47f7e10015fd5a55',
      },
      {
        _id: '60cc206e47f7e10015fd5a57',
        courseCode: 'ANTHRO 3393',
        shortName: 'Anthropology 3393F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3393F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206e47f7e10015fd5a57',
      },
      {
        _id: '60cc206e47f7e10015fd5a59',
        courseCode: 'ANTHRO 3395',
        shortName: 'Anthropology 3395F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3395F/G - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206e47f7e10015fd5a59',
      },
      {
        _id: '60cc206e47f7e10015fd5a5b',
        courseCode: 'ANTHRO 3396',
        shortName: 'Anthropology 3396A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3396A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206e47f7e10015fd5a5b',
      },
      {
        _id: '60cc206f47f7e10015fd5a5d',
        courseCode: 'ANTHRO 3397',
        shortName: 'Anthropology 3397A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3397A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206f47f7e10015fd5a5d',
      },
      {
        _id: '60cc206f47f7e10015fd5a5f',
        courseCode: 'ANTHRO 3398',
        shortName: 'Anthropology 3398A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName: 'Anthropology 3398A/B - SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206f47f7e10015fd5a5f',
      },
      {
        _id: '60cc206f47f7e10015fd5a61',
        courseCode: 'ANTHRO 4401',
        shortName:
          'Anthropology 4401F/G - ANTHROPOLOGICAL THINKING AND PRACTICE',
        courseName:
          'Anthropology 4401F/G - ANTHROPOLOGICAL THINKING AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206f47f7e10015fd5a61',
      },
      {
        _id: '60cc206f47f7e10015fd5a63',
        courseCode: 'ANTHRO 4407',
        shortName:
          'Anthropology 4407F/G - DIGITAL ARCHAEOLOGY AND DIGITAL HERITAGE',
        courseName:
          'Anthropology 4407F/G - DIGITAL ARCHAEOLOGY AND DIGITAL HERITAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206f47f7e10015fd5a63',
      },
      {
        _id: '60cc206f47f7e10015fd5a65',
        courseCode: 'ANTHRO 4408',
        shortName: 'Anthropology 4408F/G - PALEOPATHOLOGY AND PALEODIET',
        courseName: 'Anthropology 4408F/G - PALEOPATHOLOGY AND PALEODIET',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc206f47f7e10015fd5a65',
      },
      {
        _id: '60cc207047f7e10015fd5a67',
        courseCode: 'ANTHRO 4409',
        shortName: 'Anthropology 4409F/G - ANTHROPOLOGY OF ETHICS AND MORALITY',
        courseName:
          'Anthropology 4409F/G - ANTHROPOLOGY OF ETHICS AND MORALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207047f7e10015fd5a67',
      },
      {
        _id: '60cc207047f7e10015fd5a69',
        courseCode: 'ANTHRO 4412',
        shortName: 'Anthropology 4412F/G - LANGUAGE AND POWER',
        courseName: 'Anthropology 4412F/G - LANGUAGE AND POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207047f7e10015fd5a69',
      },
      {
        _id: '60cc207047f7e10015fd5a6b',
        courseCode: 'ANTHRO 4422',
        shortName: 'Anthropology 4422F/G - ACTIVITY AND ENERGETICS IN THE PAST',
        courseName:
          'Anthropology 4422F/G - ACTIVITY AND ENERGETICS IN THE PAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207047f7e10015fd5a6b',
      },
      {
        _id: '60cc207047f7e10015fd5a6d',
        courseCode: 'ANTHRO 4424',
        shortName:
          'Anthropology 4424F/G - ANALYTICAL TECHNIQUES IN ARCHAEOLOGY AND BIOLOGICAL ANTHROPOLOGY',
        courseName:
          'Anthropology 4424F/G - ANALYTICAL TECHNIQUES IN ARCHAEOLOGY AND BIOLOGICAL ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207047f7e10015fd5a6d',
      },
      {
        _id: '60cc207147f7e10015fd5a6f',
        courseCode: 'ANTHRO 4426',
        shortName: 'Anthropology 4426F/G - MORTUARY ARCHAEOLOGY',
        courseName: 'Anthropology 4426F/G - MORTUARY ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207147f7e10015fd5a6f',
      },
      {
        _id: '60cc207147f7e10015fd5a71',
        courseCode: 'ANTHRO 4429',
        shortName: 'Anthropology 4429F/G - PRINCIPLES OF APPLED ARCHAEOLOGY',
        courseName: 'Anthropology 4429F/G - PRINCIPLES OF APPLED ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207147f7e10015fd5a71',
      },
      {
        _id: '60cc207147f7e10015fd5a73',
        courseCode: 'ANTHRO 4430',
        shortName:
          'Anthropology 4430F/G - SUPERVISED READINGS/RESEARCH IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4430F/G - SUPERVISED READINGS/RESEARCH IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207147f7e10015fd5a73',
      },
      {
        _id: '60cc207147f7e10015fd5a75',
        courseCode: 'ANTHRO 4431',
        shortName:
          'Anthropology 4431F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4431F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207147f7e10015fd5a75',
      },
      {
        _id: '60cc207147f7e10015fd5a77',
        courseCode: 'ANTHRO 4470',
        shortName: 'Anthropology 4470A/B - RESEARCH PRACTICUM IN ANTHROPOLOGY',
        courseName: 'Anthropology 4470A/B - RESEARCH PRACTICUM IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207147f7e10015fd5a77',
      },
      {
        _id: '60cc207247f7e10015fd5a79',
        courseCode: 'ANTHRO 4493',
        shortName:
          'Anthropology 4493F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4493F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207247f7e10015fd5a79',
      },
      {
        _id: '60cc207247f7e10015fd5a7b',
        courseCode: 'ANTHRO 4494',
        shortName:
          'Anthropology 4494F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4494F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207247f7e10015fd5a7b',
      },
      {
        _id: '60cc207247f7e10015fd5a7d',
        courseCode: 'ANTHRO 4495',
        shortName:
          'Anthropology 4495F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4495F/G - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207247f7e10015fd5a7d',
      },
      {
        _id: '60cc207247f7e10015fd5a7f',
        courseCode: 'ANTHRO 4496',
        shortName:
          'Anthropology 4496A/B - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4496A/B - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207247f7e10015fd5a7f',
      },
      {
        _id: '60cc207347f7e10015fd5a81',
        courseCode: 'ANTHRO 4497',
        shortName:
          'Anthropology 4497A/B - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4497A/B - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207347f7e10015fd5a81',
      },
      {
        _id: '60cc207347f7e10015fd5a83',
        courseCode: 'ANTHRO 4498',
        shortName:
          'Anthropology 4498A/B - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        courseName:
          'Anthropology 4498A/B - ADVANCED SPECIAL TOPICS IN ANTHROPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207347f7e10015fd5a83',
      },
      {
        _id: '60cc207347f7e10015fd5a85',
        courseCode: 'APPLMATH 1201',
        shortName:
          'Applied Mathematics 1201A/B - CALCULUS AND PROBABILITY WITH BIOLOGICAL APPLICATIONS',
        courseName:
          'Applied Mathematics 1201A/B - CALCULUS AND PROBABILITY WITH BIOLOGICAL APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207347f7e10015fd5a85',
      },
      {
        _id: '60cc207347f7e10015fd5a87',
        courseCode: 'APPLMATH 1999',
        shortName:
          'Applied Mathematics 1999F/G - INTRODUCTION TO EXPERIMENTAL MATHEMATICS',
        courseName:
          'Applied Mathematics 1999F/G - INTRODUCTION TO EXPERIMENTAL MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207347f7e10015fd5a87',
      },
      {
        _id: '60cc207447f7e10015fd5a89',
        courseCode: 'APPLMATH 2402',
        shortName:
          'Applied Mathematics 2402A - ORDINARY DIFFERENTIAL EQUATIONS',
        courseName:
          'Applied Mathematics 2402A - ORDINARY DIFFERENTIAL EQUATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207447f7e10015fd5a89',
      },
      {
        _id: '60cc207447f7e10015fd5a8b',
        courseCode: 'APPLMATH 2811',
        shortName: 'Applied Mathematics 2811B - LINEAR ALGEBRA II',
        courseName: 'Applied Mathematics 2811B - LINEAR ALGEBRA II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207447f7e10015fd5a8b',
      },
      {
        _id: '60cc207447f7e10015fd5a8d',
        courseCode: 'APPLMATH 2814',
        shortName: 'Applied Mathematics 2814F/G - NUMERICAL ANALYSIS',
        courseName: 'Applied Mathematics 2814F/G - NUMERICAL ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207447f7e10015fd5a8d',
      },
      {
        _id: '60cc207447f7e10015fd5a8f',
        courseCode: 'APPLMATH 3151',
        shortName: 'Applied Mathematics 3151A/B - CLASSICAL MECHANICS I',
        courseName: 'Applied Mathematics 3151A/B - CLASSICAL MECHANICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207447f7e10015fd5a8f',
      },
      {
        _id: '60cc207447f7e10015fd5a91',
        courseCode: 'APPLMATH 3413',
        shortName:
          'Applied Mathematics 3413A/B - ADVANCED APPLIED MATHEMATICS FOR MECHANICAL ENGINEERING',
        courseName:
          'Applied Mathematics 3413A/B - ADVANCED APPLIED MATHEMATICS FOR MECHANICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207447f7e10015fd5a91',
      },
      {
        _id: '60cc207547f7e10015fd5a93',
        courseCode: 'APPLMATH 3611',
        shortName:
          'Applied Mathematics 3611F/G - INTRODUCTION TO OBJECT ORIENTED SCIENTIFIC PROGRAMMING',
        courseName:
          'Applied Mathematics 3611F/G - INTRODUCTION TO OBJECT ORIENTED SCIENTIFIC PROGRAMMING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207547f7e10015fd5a93',
      },
      {
        _id: '60cc207547f7e10015fd5a95',
        courseCode: 'APPLMATH 3615',
        shortName: 'Applied Mathematics 3615A/B - MATHEMATICAL BIOLOGY',
        courseName: 'Applied Mathematics 3615A/B - MATHEMATICAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207547f7e10015fd5a95',
      },
      {
        _id: '60cc207547f7e10015fd5a97',
        courseCode: 'APPLMATH 3811',
        shortName:
          'Applied Mathematics 3811A/B - COMPLEX VARIABLES WITH APPLICATIONS',
        courseName:
          'Applied Mathematics 3811A/B - COMPLEX VARIABLES WITH APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207547f7e10015fd5a97',
      },
      {
        _id: '60cc207547f7e10015fd5a99',
        courseCode: 'APPLMATH 3813',
        shortName:
          'Applied Mathematics 3813A/B - NONLINEAR ORDINARY DIFFERENTIAL EQUATIONS AND CHAOS',
        courseName:
          'Applied Mathematics 3813A/B - NONLINEAR ORDINARY DIFFERENTIAL EQUATIONS AND CHAOS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207547f7e10015fd5a99',
      },
      {
        _id: '60cc207647f7e10015fd5a9b',
        courseCode: 'APPLMATH 3815',
        shortName:
          'Applied Mathematics 3815A/B - PARTIAL DIFFERENTIAL EQUATIONS I',
        courseName:
          'Applied Mathematics 3815A/B - PARTIAL DIFFERENTIAL EQUATIONS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207647f7e10015fd5a9b',
      },
      {
        _id: '60cc207647f7e10015fd5a9d',
        courseCode: 'APPLMATH 3911',
        shortName: 'Applied Mathematics 3911F/G - MODELLING AND SIMULATION',
        courseName: 'Applied Mathematics 3911F/G - MODELLING AND SIMULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207647f7e10015fd5a9d',
      },
      {
        _id: '60cc207647f7e10015fd5a9f',
        courseCode: 'APPLMATH 4251',
        shortName: 'Applied Mathematics 4251A - QUANTUM MECHANICS II',
        courseName: 'Applied Mathematics 4251A - QUANTUM MECHANICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207647f7e10015fd5a9f',
      },
      {
        _id: '60cc207647f7e10015fd5aa1',
        courseCode: 'APPLMATH 4264',
        shortName:
          'Applied Mathematics 4264A/B - INTRODUCTION TO NEURAL NETWORKS',
        courseName:
          'Applied Mathematics 4264A/B - INTRODUCTION TO NEURAL NETWORKS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207647f7e10015fd5aa1',
      },
      {
        _id: '60cc207747f7e10015fd5aa3',
        courseCode: 'APPLMATH 4351',
        shortName: 'Applied Mathematics 4351A - ELECTROMAGNETIC THEORY II',
        courseName: 'Applied Mathematics 4351A - ELECTROMAGNETIC THEORY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207747f7e10015fd5aa3',
      },
      {
        _id: '60cc207747f7e10015fd5aa5',
        courseCode: 'APPLMATH 4551',
        shortName:
          'Applied Mathematics 4551A/B - INTRODUCTION TO ELEMENTARY PARTICLES',
        courseName:
          'Applied Mathematics 4551A/B - INTRODUCTION TO ELEMENTARY PARTICLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207747f7e10015fd5aa5',
      },
      {
        _id: '60cc207747f7e10015fd5aa7',
        courseCode: 'APPLMATH 4613',
        shortName: 'Applied Mathematics 4613A/B - FINITE ELEMENT METHODS',
        courseName: 'Applied Mathematics 4613A/B - FINITE ELEMENT METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207747f7e10015fd5aa7',
      },
      {
        _id: '60cc207747f7e10015fd5aa9',
        courseCode: 'APPLMATH 4615',
        shortName:
          'Applied Mathematics 4615F/G - INTRODUCTION TO APPLIED COMPUTER ALGEBRA',
        courseName:
          'Applied Mathematics 4615F/G - INTRODUCTION TO APPLIED COMPUTER ALGEBRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207747f7e10015fd5aa9',
      },
      {
        _id: '60cc207747f7e10015fd5aab',
        courseCode: 'APPLMATH 4815',
        shortName:
          'Applied Mathematics 4815A/B - PARTIAL DIFFERENTIAL EQUATIONS II',
        courseName:
          'Applied Mathematics 4815A/B - PARTIAL DIFFERENTIAL EQUATIONS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207747f7e10015fd5aab',
      },
      {
        _id: '60cc207847f7e10015fd5aad',
        courseCode: 'APPLMATH 4999',
        shortName: 'Applied Mathematics 4999Z - PROJECT',
        courseName: 'Applied Mathematics 4999Z - PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207847f7e10015fd5aad',
      },
      {
        _id: '60cc207847f7e10015fd5aaf',
        courseCode: 'ARABIC 1030',
        shortName: 'Arabic 1030 - ARABIC FOR BEGINNERS',
        courseName: 'Arabic 1030 - ARABIC FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207847f7e10015fd5aaf',
      },
      {
        _id: '60cc207847f7e10015fd5ab1',
        courseCode: 'ARABIC 1035',
        shortName: 'Arabic 1035 - BEGINNER ARABIC FOR HERITAGE SPEAKERS',
        courseName: 'Arabic 1035 - BEGINNER ARABIC FOR HERITAGE SPEAKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207847f7e10015fd5ab1',
      },
      {
        _id: '60cc207847f7e10015fd5ab3',
        courseCode: 'ARABIC 1070',
        shortName: 'Arabic 1070A/B - QURANIC ARABIC FOR BEGINNERS',
        courseName: 'Arabic 1070A/B - QURANIC ARABIC FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207847f7e10015fd5ab3',
      },
      {
        _id: '60cc207947f7e10015fd5ab5',
        courseCode: 'ARABIC 2050',
        shortName: 'Arabic 2050A/B - ARABIC SPEAKING SKILLS',
        courseName: 'Arabic 2050A/B - ARABIC SPEAKING SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207947f7e10015fd5ab5',
      },
      {
        _id: '60cc207947f7e10015fd5ab7',
        courseCode: 'ARABIC 2080',
        shortName: 'Arabic 2080A/B - INTERMEDIATE QURANIC ARABIC',
        courseName: 'Arabic 2080A/B - INTERMEDIATE QURANIC ARABIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207947f7e10015fd5ab7',
      },
      {
        _id: '60cc207947f7e10015fd5ab9',
        courseCode: 'ARABIC 2250',
        shortName: 'Arabic 2250 - INTERMEDIATE ARABIC',
        courseName: 'Arabic 2250 - INTERMEDIATE ARABIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207947f7e10015fd5ab9',
      },
      {
        _id: '60cc207a47f7e10015fd5abb',
        courseCode: 'ARABIC 3260',
        shortName: "Arabic 3260A/B - READINGS FROM THE QUR'AN",
        courseName: "Arabic 3260A/B - READINGS FROM THE QUR'AN",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207a47f7e10015fd5abb',
      },
      {
        _id: '60cc207a47f7e10015fd5abd',
        courseCode: 'ARABIC 3300',
        shortName: 'Arabic 3300 - ADVANCED ARABIC',
        courseName: 'Arabic 3300 - ADVANCED ARABIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207a47f7e10015fd5abd',
      },
      {
        _id: '60cc207a47f7e10015fd5abf',
        courseCode: 'AH 1640',
        shortName:
          'Art History 1640 - A HISTORY OF ART AND VISUAL CULTURE: ANCIENT TO MODERN',
        courseName:
          'Art History 1640 - A HISTORY OF ART AND VISUAL CULTURE: ANCIENT TO MODERN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207a47f7e10015fd5abf',
      },
      {
        _id: '60cc207a47f7e10015fd5ac1',
        courseCode: 'AH 1641',
        shortName:
          'Art History 1641A/B - ART HISTORY AND VISUAL CULTURE: ANCIENT THROUGH RENAISSANCE',
        courseName:
          'Art History 1641A/B - ART HISTORY AND VISUAL CULTURE: ANCIENT THROUGH RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207a47f7e10015fd5ac1',
      },
      {
        _id: '60cc207b47f7e10015fd5ac3',
        courseCode: 'AH 1642',
        shortName:
          'Art History 1642A/B - ART HISTORY AND VISUAL CULTURE: BAROQUE TO CONTEMPORARY',
        courseName:
          'Art History 1642A/B - ART HISTORY AND VISUAL CULTURE: BAROQUE TO CONTEMPORARY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207b47f7e10015fd5ac3',
      },
      {
        _id: '60cc207b47f7e10015fd5ac5',
        courseCode: 'AH 1644',
        shortName: 'Art History 1644A/B - ART, SCIENCE AND TECHNOLOGY',
        courseName: 'Art History 1644A/B - ART, SCIENCE AND TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207b47f7e10015fd5ac5',
      },
      {
        _id: '60cc207b47f7e10015fd5ac7',
        courseCode: 'AH 1646',
        shortName: 'Art History 1646A/B - ART, MEDIA AND POPULAR CULTURE',
        courseName: 'Art History 1646A/B - ART, MEDIA AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207b47f7e10015fd5ac7',
      },
      {
        _id: '60cc207b47f7e10015fd5ac9',
        courseCode: 'AH 1648',
        shortName: 'Art History 1648A/B - COLLECTING ART AND CULTURE',
        courseName: 'Art History 1648A/B - COLLECTING ART AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207b47f7e10015fd5ac9',
      },
      {
        _id: '60cc207c47f7e10015fd5acb',
        courseCode: 'AH 2508',
        shortName: 'Art History 2508F/G - SEXUALITY IN MODERN VISUAL CULTURE',
        courseName: 'Art History 2508F/G - SEXUALITY IN MODERN VISUAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207c47f7e10015fd5acb',
      },
      {
        _id: '60cc207c47f7e10015fd5acd',
        courseCode: 'AH 2576',
        shortName:
          'Art History 2576F/G - WHAT (NOT) TO WEAR: SPECIAL TOPICS IN FASHION, TEXTILES AND ART',
        courseName:
          'Art History 2576F/G - WHAT (NOT) TO WEAR: SPECIAL TOPICS IN FASHION, TEXTILES AND ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207c47f7e10015fd5acd',
      },
      {
        _id: '60cc207c47f7e10015fd5acf',
        courseCode: 'AH 2590',
        shortName: 'Art History 2590F/G - SPECIAL TOPICS IN VISUAL ARTS',
        courseName: 'Art History 2590F/G - SPECIAL TOPICS IN VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207c47f7e10015fd5acf',
      },
      {
        _id: '60cc207c47f7e10015fd5ad1',
        courseCode: 'AH 2591',
        shortName: 'Art History 2591E - SPECIAL TOPICS IN VISUAL ARTS',
        courseName: 'Art History 2591E - SPECIAL TOPICS IN VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207c47f7e10015fd5ad1',
      },
      {
        _id: '60cc207c47f7e10015fd5ad3',
        courseCode: 'AH 2600',
        shortName:
          'Art History 2600F/G - THEORIES AND PRACTICES OF ART HISTORY AND VISUAL CULTURE',
        courseName:
          'Art History 2600F/G - THEORIES AND PRACTICES OF ART HISTORY AND VISUAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207c47f7e10015fd5ad3',
      },
      {
        _id: '60cc207d47f7e10015fd5ad5',
        courseCode: 'AH 2613',
        shortName: 'Art History 2613E - GREEK ART',
        courseName: 'Art History 2613E - GREEK ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207d47f7e10015fd5ad5',
      },
      {
        _id: '60cc207d47f7e10015fd5ad7',
        courseCode: 'AH 2620',
        shortName: 'Art History 2620F/G - NORTHERN RENAISSANCE ART',
        courseName: 'Art History 2620F/G - NORTHERN RENAISSANCE ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207d47f7e10015fd5ad7',
      },
      {
        _id: '60cc207d47f7e10015fd5ad9',
        courseCode: 'AH 2622',
        shortName: 'Art History 2622F/G - ITALIAN RENAISSANCE ART',
        courseName: 'Art History 2622F/G - ITALIAN RENAISSANCE ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207d47f7e10015fd5ad9',
      },
      {
        _id: '60cc207d47f7e10015fd5adb',
        courseCode: 'AH 2626',
        shortName: 'Art History 2626F/G - BAROQUE AND ROCOCO ART IN EUROPE',
        courseName: 'Art History 2626F/G - BAROQUE AND ROCOCO ART IN EUROPE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207d47f7e10015fd5adb',
      },
      {
        _id: '60cc207e47f7e10015fd5add',
        courseCode: 'AH 2630',
        shortName:
          'Art History 2630F/G - PRE-CONTACT AMERICAN ART AND ARCHITECTURE',
        courseName:
          'Art History 2630F/G - PRE-CONTACT AMERICAN ART AND ARCHITECTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207e47f7e10015fd5add',
      },
      {
        _id: '60cc207e47f7e10015fd5adf',
        courseCode: 'AH 2632',
        shortName: 'Art History 2632F/G - CANADIAN ART',
        courseName: 'Art History 2632F/G - CANADIAN ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207e47f7e10015fd5adf',
      },
      {
        _id: '60cc207e47f7e10015fd5ae1',
        courseCode: 'AH 2636',
        shortName:
          'Art History 2636F/G - BAROQUE IN EUROPE AND THE IBERIAN TERRITORIES',
        courseName:
          'Art History 2636F/G - BAROQUE IN EUROPE AND THE IBERIAN TERRITORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207e47f7e10015fd5ae1',
      },
      {
        _id: '60cc207e47f7e10015fd5ae3',
        courseCode: 'AH 2640',
        shortName: 'Art History 2640F/G - ROMANTICISM TO POST-IMPRESSIONISM',
        courseName: 'Art History 2640F/G - ROMANTICISM TO POST-IMPRESSIONISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207e47f7e10015fd5ae3',
      },
      {
        _id: '60cc207f47f7e10015fd5ae5',
        courseCode: 'AH 2642',
        shortName: 'Art History 2642F/G - EXPRESSIONISM TO SURREALISM',
        courseName: 'Art History 2642F/G - EXPRESSIONISM TO SURREALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207f47f7e10015fd5ae5',
      },
      {
        _id: '60cc207f47f7e10015fd5ae7',
        courseCode: 'AH 2644',
        shortName: 'Art History 2644F/G - ABSTRACT TO NEO-EXPRESSIONISM',
        courseName: 'Art History 2644F/G - ABSTRACT TO NEO-EXPRESSIONISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207f47f7e10015fd5ae7',
      },
      {
        _id: '60cc207f47f7e10015fd5ae9',
        courseCode: 'AH 2646',
        shortName: 'Art History 2646F/G - CONTEMPORARY ART',
        courseName: 'Art History 2646F/G - CONTEMPORARY ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207f47f7e10015fd5ae9',
      },
      {
        _id: '60cc207f47f7e10015fd5aeb',
        courseCode: 'AH 2650',
        shortName: 'Art History 2650F/G - HISTORY OF PHOTOGRAPHY',
        courseName: 'Art History 2650F/G - HISTORY OF PHOTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207f47f7e10015fd5aeb',
      },
      {
        _id: '60cc207f47f7e10015fd5aed',
        courseCode: 'AH 2660',
        shortName: 'Art History 2660F/G - HISTORY OF THE MOVING IMAGE',
        courseName: 'Art History 2660F/G - HISTORY OF THE MOVING IMAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc207f47f7e10015fd5aed',
      },
      {
        _id: '60cc208047f7e10015fd5aef',
        courseCode: 'AH 2662',
        shortName: 'Art History 2662F/G - ART AND MASS MEDIA',
        courseName: 'Art History 2662F/G - ART AND MASS MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208047f7e10015fd5aef',
      },
      {
        _id: '60cc208047f7e10015fd5af1',
        courseCode: 'AH 2670',
        shortName: 'Art History 2670F/G - HISTORY OF ARCHITECTURE AND URBANISM',
        courseName:
          'Art History 2670F/G - HISTORY OF ARCHITECTURE AND URBANISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208047f7e10015fd5af1',
      },
      {
        _id: '60cc208047f7e10015fd5af3',
        courseCode: 'AH 2676',
        shortName: 'Art History 2676F/G - INTRODUCTION TO DESIGN',
        courseName: 'Art History 2676F/G - INTRODUCTION TO DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208047f7e10015fd5af3',
      },
      {
        _id: '60cc208047f7e10015fd5af5',
        courseCode: 'AH 2690',
        shortName: 'Art History 2690F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 2690F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208047f7e10015fd5af5',
      },
      {
        _id: '60cc208147f7e10015fd5af7',
        courseCode: 'AH 2691',
        shortName: 'Art History 2691E - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 2691E - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208147f7e10015fd5af7',
      },
      {
        _id: '60cc208147f7e10015fd5af9',
        courseCode: 'AH 2692',
        shortName: 'Art History 2692F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 2692F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208147f7e10015fd5af9',
      },
      {
        _id: '60cc208147f7e10015fd5afb',
        courseCode: 'AH 2693',
        shortName: 'Art History 2693E - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 2693E - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208147f7e10015fd5afb',
      },
      {
        _id: '60cc208147f7e10015fd5afd',
        courseCode: 'AH 2694',
        shortName: 'Art History 2694A/B - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 2694A/B - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208147f7e10015fd5afd',
      },
      {
        _id: '60cc208247f7e10015fd5aff',
        courseCode: 'AH 3502',
        shortName: 'Art History 3502F/G - ART HISTORY AND STUDIO IN DIALOGUE',
        courseName: 'Art History 3502F/G - ART HISTORY AND STUDIO IN DIALOGUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208247f7e10015fd5aff',
      },
      {
        _id: '60cc208247f7e10015fd5b01',
        courseCode: 'AH 3620',
        shortName:
          'Art History 3620F/G - RACE AND GENDER IN THE PRE-MODERN WORLD',
        courseName:
          'Art History 3620F/G - RACE AND GENDER IN THE PRE-MODERN WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208247f7e10015fd5b01',
      },
      {
        _id: '60cc208247f7e10015fd5b03',
        courseCode: 'AH 3630',
        shortName:
          'Art History 3630F/G - TOPICS IN INDIGENOUS ARTS OF THE AMERICAS',
        courseName:
          'Art History 3630F/G - TOPICS IN INDIGENOUS ARTS OF THE AMERICAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208247f7e10015fd5b03',
      },
      {
        _id: '60cc208247f7e10015fd5b05',
        courseCode: 'AH 3642',
        shortName: 'Art History 3642F/G - COLD WAR ART AND POLITICS',
        courseName: 'Art History 3642F/G - COLD WAR ART AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208247f7e10015fd5b05',
      },
      {
        _id: '60cc208347f7e10015fd5b07',
        courseCode: 'AH 3644',
        shortName: 'Art History 3644F/G - DADA AND NEO-DADA',
        courseName: 'Art History 3644F/G - DADA AND NEO-DADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208347f7e10015fd5b07',
      },
      {
        _id: '60cc208347f7e10015fd5b09',
        courseCode: 'AH 3650',
        shortName: 'Art History 3650F/G - TOPICS IN PHOTOGRAPHY',
        courseName: 'Art History 3650F/G - TOPICS IN PHOTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208347f7e10015fd5b09',
      },
      {
        _id: '60cc208347f7e10015fd5b0b',
        courseCode: 'AH 3660',
        shortName: 'Art History 3660F/G - HOLLYWOOD AND ART',
        courseName: 'Art History 3660F/G - HOLLYWOOD AND ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208347f7e10015fd5b0b',
      },
      {
        _id: '60cc208347f7e10015fd5b0d',
        courseCode: 'AH 3670',
        shortName: 'Art History 3670F/G - TOPICS IN ARCHITECTURE AND URBANISM',
        courseName: 'Art History 3670F/G - TOPICS IN ARCHITECTURE AND URBANISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208347f7e10015fd5b0d',
      },
      {
        _id: '60cc208447f7e10015fd5b0f',
        courseCode: 'AH 3674',
        shortName: 'Art History 3674F/G - SUSTAINABILITY AND ART',
        courseName: 'Art History 3674F/G - SUSTAINABILITY AND ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208447f7e10015fd5b0f',
      },
      {
        _id: '60cc208447f7e10015fd5b11',
        courseCode: 'AH 3676',
        shortName: 'Art History 3676F/G - LESSONS BY DESIGN',
        courseName: 'Art History 3676F/G - LESSONS BY DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208447f7e10015fd5b11',
      },
      {
        _id: '60cc208447f7e10015fd5b13',
        courseCode: 'AH 3686',
        shortName: 'Art History 3686F/G - PROJECT-BASED INTERNSHIP',
        courseName: 'Art History 3686F/G - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208447f7e10015fd5b13',
      },
      {
        _id: '60cc208447f7e10015fd5b15',
        courseCode: 'AH 3689',
        shortName: 'Art History 3689E - PROJECT-BASED INTERNSHIP',
        courseName: 'Art History 3689E - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208447f7e10015fd5b15',
      },
      {
        _id: '60cc208547f7e10015fd5b17',
        courseCode: 'AH 3690',
        shortName: 'Art History 3690F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 3690F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208547f7e10015fd5b17',
      },
      {
        _id: '60cc208547f7e10015fd5b19',
        courseCode: 'AH 3691',
        shortName: 'Art History 3691E - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 3691E - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208547f7e10015fd5b19',
      },
      {
        _id: '60cc208547f7e10015fd5b1b',
        courseCode: 'AH 3692',
        shortName: 'Art History 3692F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 3692F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208547f7e10015fd5b1b',
      },
      {
        _id: '60cc208547f7e10015fd5b1d',
        courseCode: 'AH 3694',
        shortName: 'Art History 3694F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 3694F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208547f7e10015fd5b1d',
      },
      {
        _id: '60cc208647f7e10015fd5b1f',
        courseCode: 'AH 4600',
        shortName: 'Art History 4600F/G/Z - ART HISTORY HONOURS SEMINAR',
        courseName: 'Art History 4600F/G/Z - ART HISTORY HONOURS SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208647f7e10015fd5b1f',
      },
      {
        _id: '60cc208647f7e10015fd5b21',
        courseCode: 'AH 4620',
        shortName: 'Art History 4620F/G - SEMINAR IN EARLY MODERN ART',
        courseName: 'Art History 4620F/G - SEMINAR IN EARLY MODERN ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208647f7e10015fd5b21',
      },
      {
        _id: '60cc208647f7e10015fd5b23',
        courseCode: 'AH 4622',
        shortName:
          'Art History 4622F/G - SEMINAR IN RACE & GENDER IN PRE-MODERN ART',
        courseName:
          'Art History 4622F/G - SEMINAR IN RACE & GENDER IN PRE-MODERN ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208647f7e10015fd5b23',
      },
      {
        _id: '60cc208647f7e10015fd5b25',
        courseCode: 'AH 4630',
        shortName: 'Art History 4630F/G - SEMINAR IN INDIGENOUS AMERICAN ART',
        courseName: 'Art History 4630F/G - SEMINAR IN INDIGENOUS AMERICAN ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208647f7e10015fd5b25',
      },
      {
        _id: '60cc208747f7e10015fd5b27',
        courseCode: 'AH 4636',
        shortName: 'Art History 4636F/G - SEMINAR IN THE ART OF THE AMERICAS',
        courseName: 'Art History 4636F/G - SEMINAR IN THE ART OF THE AMERICAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208747f7e10015fd5b27',
      },
      {
        _id: '60cc208747f7e10015fd5b29',
        courseCode: 'AH 4640',
        shortName: 'Art History 4640F/G - SEMINAR IN MODERN/CONTEMPORARY ART',
        courseName: 'Art History 4640F/G - SEMINAR IN MODERN/CONTEMPORARY ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208747f7e10015fd5b29',
      },
      {
        _id: '60cc208747f7e10015fd5b2b',
        courseCode: 'AH 4650',
        shortName: 'Art History 4650F/G - SEMINAR IN PHOTOGRAPHY',
        courseName: 'Art History 4650F/G - SEMINAR IN PHOTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208747f7e10015fd5b2b',
      },
      {
        _id: '60cc208747f7e10015fd5b2d',
        courseCode: 'AH 4660',
        shortName: 'Art History 4660F/G - SEMINAR IN FILM OR THE MOVING IMAGE',
        courseName: 'Art History 4660F/G - SEMINAR IN FILM OR THE MOVING IMAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208747f7e10015fd5b2d',
      },
      {
        _id: '60cc208747f7e10015fd5b2f',
        courseCode: 'AH 4670',
        shortName:
          'Art History 4670F/G - SEMINAR IN ARCHITECTURE AND URBANISM OR DESIGN',
        courseName:
          'Art History 4670F/G - SEMINAR IN ARCHITECTURE AND URBANISM OR DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208747f7e10015fd5b2f',
      },
      {
        _id: '60cc208847f7e10015fd5b31',
        courseCode: 'AH 4682',
        shortName: 'Art History 4682A/B - INTERNSHIP IN ART HISTORY',
        courseName: 'Art History 4682A/B - INTERNSHIP IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208847f7e10015fd5b31',
      },
      {
        _id: '60cc208847f7e10015fd5b33',
        courseCode: 'AH 4686',
        shortName: 'Art History 4686F/G - PROJECT-BASED INTERNSHIP',
        courseName: 'Art History 4686F/G - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208847f7e10015fd5b33',
      },
      {
        _id: '60cc208847f7e10015fd5b35',
        courseCode: 'AH 4689',
        shortName: 'Art History 4689E - PROJECT-BASED INTERNSHIP',
        courseName: 'Art History 4689E - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208847f7e10015fd5b35',
      },
      {
        _id: '60cc208847f7e10015fd5b37',
        courseCode: 'AH 4690',
        shortName: 'Art History 4690F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 4690F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208847f7e10015fd5b37',
      },
      {
        _id: '60cc208947f7e10015fd5b39',
        courseCode: 'AH 4692',
        shortName: 'Art History 4692F/G - SPECIAL TOPICS IN ART HISTORY',
        courseName: 'Art History 4692F/G - SPECIAL TOPICS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208947f7e10015fd5b39',
      },
      {
        _id: '60cc208947f7e10015fd5b3b',
        courseCode: 'AH 4696',
        shortName: 'Art History 4696F/G - INDEPENDENT PROJECTS IN ART HISTORY',
        courseName: 'Art History 4696F/G - INDEPENDENT PROJECTS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208947f7e10015fd5b3b',
      },
      {
        _id: '60cc208947f7e10015fd5b3d',
        courseCode: 'AH 4698',
        shortName: 'Art History 4698F/G - INDEPENDENT PROJECTS IN ART HISTORY',
        courseName: 'Art History 4698F/G - INDEPENDENT PROJECTS IN ART HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208947f7e10015fd5b3d',
      },
      {
        _id: '60cc208947f7e10015fd5b3f',
        courseCode: 'ARTHUM 1000',
        shortName: 'Arts and Humanities 1000E - SPECIAL TOPICS',
        courseName: 'Arts and Humanities 1000E - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208947f7e10015fd5b3f',
      },
      {
        _id: '60cc208a47f7e10015fd5b41',
        courseCode: 'ARTHUM 1020',
        shortName:
          'Arts and Humanities 1020E - INTRODUCTION TO THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 1020E - INTRODUCTION TO THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208a47f7e10015fd5b41',
      },
      {
        _id: '60cc208a47f7e10015fd5b43',
        courseCode: 'ARTHUM 2200',
        shortName:
          'Arts and Humanities 2200E - CRITICAL THEORIES IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 2200E - CRITICAL THEORIES IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208a47f7e10015fd5b43',
      },
      {
        _id: '60cc208a47f7e10015fd5b45',
        courseCode: 'ARTHUM 2220',
        shortName:
          'Arts and Humanities 2220F/G - EFFECTIVE COMMUNICATION IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 2220F/G - EFFECTIVE COMMUNICATION IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208a47f7e10015fd5b45',
      },
      {
        _id: '60cc208a47f7e10015fd5b47',
        courseCode: 'ARTHUM 2230',
        shortName:
          'Arts and Humanities 2230F/G - DIGITAL TOOLS, DIGITAL LITERACIES',
        courseName:
          'Arts and Humanities 2230F/G - DIGITAL TOOLS, DIGITAL LITERACIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208a47f7e10015fd5b47',
      },
      {
        _id: '60cc208a47f7e10015fd5b49',
        courseCode: 'ARTHUM 2290',
        shortName:
          'Arts and Humanities 2290F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 2290F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208a47f7e10015fd5b49',
      },
      {
        _id: '60cc208b47f7e10015fd5b4b',
        courseCode: 'ARTHUM 2291',
        shortName:
          'Arts and Humanities 2291F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 2291F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208b47f7e10015fd5b4b',
      },
      {
        _id: '60cc208b47f7e10015fd5b4d',
        courseCode: 'ARTHUM 2292',
        shortName:
          'Arts and Humanities 2292F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 2292F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208b47f7e10015fd5b4d',
      },
      {
        _id: '60cc208b47f7e10015fd5b4f',
        courseCode: 'ARTHUM 2293',
        shortName:
          'Arts and Humanities 2293F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 2293F/G - RESEARCH TOPICS IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208b47f7e10015fd5b4f',
      },
      {
        _id: '60cc208c47f7e10015fd5b51',
        courseCode: 'ARTHUM 3000',
        shortName: 'Arts and Humanities 3000A/B/Y - INTERNSHIP',
        courseName: 'Arts and Humanities 3000A/B/Y - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208c47f7e10015fd5b51',
      },
      {
        _id: '60cc208c47f7e10015fd5b53',
        courseCode: 'ARTHUM 3001',
        shortName: 'Arts and Humanities 3001A/B - PROFESSIONAL INTERNSHIP',
        courseName: 'Arts and Humanities 3001A/B - PROFESSIONAL INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208c47f7e10015fd5b53',
      },
      {
        _id: '60cc208c47f7e10015fd5b55',
        courseCode: 'ARTHUM 3380',
        shortName:
          'Arts and Humanities 3380Y - INTRODUCTION TO PROFESSIONAL AND COMMUNITY\nPRACTICES',
        courseName:
          'Arts and Humanities 3380Y - INTRODUCTION TO PROFESSIONAL AND COMMUNITY\nPRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208c47f7e10015fd5b55',
      },
      {
        _id: '60cc208c47f7e10015fd5b57',
        courseCode: 'ARTHUM 3390',
        shortName:
          'Arts and Humanities 3390F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        courseName:
          'Arts and Humanities 3390F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208c47f7e10015fd5b57',
      },
      {
        _id: '60cc208c47f7e10015fd5b59',
        courseCode: 'ARTHUM 3391',
        shortName:
          'Arts and Humanities 3391F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        courseName:
          'Arts and Humanities 3391F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208c47f7e10015fd5b59',
      },
      {
        _id: '60cc208d47f7e10015fd5b5b',
        courseCode: 'ARTHUM 3392',
        shortName:
          'Arts and Humanities 3392F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        courseName:
          'Arts and Humanities 3392F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208d47f7e10015fd5b5b',
      },
      {
        _id: '60cc208d47f7e10015fd5b5d',
        courseCode: 'ARTHUM 3393',
        shortName:
          'Arts and Humanities 3393F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        courseName:
          'Arts and Humanities 3393F/G - SPECIAL TOPICS IN INTERDISCIPLINARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208d47f7e10015fd5b5d',
      },
      {
        _id: '60cc208d47f7e10015fd5b5f',
        courseCode: 'ARTHUM 4410',
        shortName: 'Arts and Humanities 4410E - INTEGRATED SEMINAR',
        courseName: 'Arts and Humanities 4410E - INTEGRATED SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208d47f7e10015fd5b5f',
      },
      {
        _id: '60cc208e47f7e10015fd5b61',
        courseCode: 'ARTHUM 4490',
        shortName:
          'Arts and Humanities 4490F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 4490F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208e47f7e10015fd5b61',
      },
      {
        _id: '60cc208e47f7e10015fd5b63',
        courseCode: 'ARTHUM 4491',
        shortName:
          'Arts and Humanities 4491F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 4491F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208e47f7e10015fd5b63',
      },
      {
        _id: '60cc208e47f7e10015fd5b65',
        courseCode: 'ARTHUM 4492',
        shortName:
          'Arts and Humanities 4492F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 4492F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208e47f7e10015fd5b65',
      },
      {
        _id: '60cc208e47f7e10015fd5b67',
        courseCode: 'ARTHUM 4493',
        shortName:
          'Arts and Humanities 4493F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 4493F/G - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208e47f7e10015fd5b67',
      },
      {
        _id: '60cc208f47f7e10015fd5b69',
        courseCode: 'ARTHUM 4494',
        shortName:
          'Arts and Humanities 4494W/X - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        courseName:
          'Arts and Humanities 4494W/X - EXPERIENTIAL LEARNING IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208f47f7e10015fd5b69',
      },
      {
        _id: '60cc208f47f7e10015fd5b6b',
        courseCode: 'ASTRONOM 1011',
        shortName: 'Astronomy 1011A/B - ESSENTIALS OF MODERN ASTRONOMY',
        courseName: 'Astronomy 1011A/B - ESSENTIALS OF MODERN ASTRONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208f47f7e10015fd5b6b',
      },
      {
        _id: '60cc208f47f7e10015fd5b6d',
        courseCode: 'ASTRONOM 1021',
        shortName: 'Astronomy 1021 - GENERAL ASTRONOMY',
        courseName: 'Astronomy 1021 - GENERAL ASTRONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208f47f7e10015fd5b6d',
      },
      {
        _id: '60cc208f47f7e10015fd5b6f',
        courseCode: 'ASTRONOM 2020',
        shortName: 'Astronomy 2020F/G - TWO-EYED SEEING AND ASTRONOMY',
        courseName: 'Astronomy 2020F/G - TWO-EYED SEEING AND ASTRONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc208f47f7e10015fd5b6f',
      },
      {
        _id: '60cc209047f7e10015fd5b71',
        courseCode: 'ASTRONOM 2021',
        shortName: 'Astronomy 2021A/B - THE SEARCH FOR LIFE IN THE UNIVERSE',
        courseName: 'Astronomy 2021A/B - THE SEARCH FOR LIFE IN THE UNIVERSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209047f7e10015fd5b71',
      },
      {
        _id: '60cc209047f7e10015fd5b73',
        courseCode: 'ASTRONOM 2022',
        shortName: 'Astronomy 2022A/B - THE ORIGIN OF THE UNIVERSE',
        courseName: 'Astronomy 2022A/B - THE ORIGIN OF THE UNIVERSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209047f7e10015fd5b73',
      },
      {
        _id: '60cc209047f7e10015fd5b75',
        courseCode: 'ASTRONOM 2201',
        shortName: 'Astronomy 2201A/B - PLANETARY SYSTEMS',
        courseName: 'Astronomy 2201A/B - PLANETARY SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209047f7e10015fd5b75',
      },
      {
        _id: '60cc209047f7e10015fd5b77',
        courseCode: 'ASTRONOM 2232',
        shortName: 'Astronomy 2232F/G - SUN, EARTH AND PLANETS',
        courseName: 'Astronomy 2232F/G - SUN, EARTH AND PLANETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209047f7e10015fd5b77',
      },
      {
        _id: '60cc209147f7e10015fd5b79',
        courseCode: 'ASTRONOM 2801',
        shortName: 'Astronomy 2801A/B - OBSERVING THE STARS',
        courseName: 'Astronomy 2801A/B - OBSERVING THE STARS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209147f7e10015fd5b79',
      },
      {
        _id: '60cc209147f7e10015fd5b7b',
        courseCode: 'ASTRONOM 3302',
        shortName: 'Astronomy 3302A/B - ASTROPHYSICS INTERSTELLAR SPACE',
        courseName: 'Astronomy 3302A/B - ASTROPHYSICS INTERSTELLAR SPACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209147f7e10015fd5b7b',
      },
      {
        _id: '60cc209247f7e10015fd5b7d',
        courseCode: 'ASTRONOM 3303',
        shortName: 'Astronomy 3303A/B - GALAXIES',
        courseName: 'Astronomy 3303A/B - GALAXIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209247f7e10015fd5b7d',
      },
      {
        _id: '60cc209247f7e10015fd5b7f',
        courseCode: 'ASTRONOM 4101',
        shortName: 'Astronomy 4101A/B - STELLAR ASTROPHYSICS',
        courseName: 'Astronomy 4101A/B - STELLAR ASTROPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209247f7e10015fd5b7f',
      },
      {
        _id: '60cc209247f7e10015fd5b81',
        courseCode: 'ASTRONOM 4602',
        shortName:
          'Astronomy 4602A/B - GRAVITATIONAL ASTROPHYSICS AND COSMOLOGY',
        courseName:
          'Astronomy 4602A/B - GRAVITATIONAL ASTROPHYSICS AND COSMOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209247f7e10015fd5b81',
      },
      {
        _id: '60cc209247f7e10015fd5b83',
        courseCode: 'BIOCHEM 2280',
        shortName: 'Biochemistry 2280A - BIOCHEMISTRY AND MOLECULAR BIOLOGY',
        courseName: 'Biochemistry 2280A - BIOCHEMISTRY AND MOLECULAR BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209247f7e10015fd5b83',
      },
      {
        _id: '60cc209347f7e10015fd5b85',
        courseCode: 'BIOCHEM 2288',
        shortName:
          'Biochemistry 2288A - BIOCHEMISTY AND MOLECULAR BIOLOGY FOR FOODS AND NUTRITION',
        courseName:
          'Biochemistry 2288A - BIOCHEMISTY AND MOLECULAR BIOLOGY FOR FOODS AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209347f7e10015fd5b85',
      },
      {
        _id: '60cc209347f7e10015fd5b87',
        courseCode: 'BIOCHEM 3380',
        shortName: 'Biochemistry 3380G - BIOCHEMISTRY LABORATORY',
        courseName: 'Biochemistry 3380G - BIOCHEMISTRY LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209347f7e10015fd5b87',
      },
      {
        _id: '60cc209347f7e10015fd5b89',
        courseCode: 'BIOCHEM 3381',
        shortName: 'Biochemistry 3381A - BIOLOGICAL MACROMOLECULES',
        courseName: 'Biochemistry 3381A - BIOLOGICAL MACROMOLECULES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209347f7e10015fd5b89',
      },
      {
        _id: '60cc209347f7e10015fd5b8b',
        courseCode: 'BIOCHEM 3382',
        shortName: 'Biochemistry 3382A - BIOCHEMICAL REGULATION',
        courseName: 'Biochemistry 3382A - BIOCHEMICAL REGULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209347f7e10015fd5b8b',
      },
      {
        _id: '60cc209447f7e10015fd5b8d',
        courseCode: 'BIOCHEM 3383',
        shortName:
          'Biochemistry 3383F/G - INTRODUCTION TO BIOCHEMICAL RESEARCH',
        courseName:
          'Biochemistry 3383F/G - INTRODUCTION TO BIOCHEMICAL RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209447f7e10015fd5b8d',
      },
      {
        _id: '60cc209447f7e10015fd5b8f',
        courseCode: 'BIOCHEM 3385',
        shortName: 'Biochemistry 3385B - HUMAN BIOCHEMISTRY',
        courseName: 'Biochemistry 3385B - HUMAN BIOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209447f7e10015fd5b8f',
      },
      {
        _id: '60cc209447f7e10015fd5b91',
        courseCode: 'BIOCHEM 3386',
        shortName: 'Biochemistry 3386B - CLINICAL BIOCHEMISTRY',
        courseName: 'Biochemistry 3386B - CLINICAL BIOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209447f7e10015fd5b91',
      },
      {
        _id: '60cc209447f7e10015fd5b93',
        courseCode: 'BIOCHEM 3390',
        shortName: 'Biochemistry 3390B - ADVANCED METHODS FOR BIOCHEMISTRY',
        courseName: 'Biochemistry 3390B - ADVANCED METHODS FOR BIOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209447f7e10015fd5b93',
      },
      {
        _id: '60cc209547f7e10015fd5b95',
        courseCode: 'BIOCHEM 3392',
        shortName:
          'Biochemistry 3392F/G - SYNTHETIC BIOLOGY: PRINCIPLES AND PRACTICE',
        courseName:
          'Biochemistry 3392F/G - SYNTHETIC BIOLOGY: PRINCIPLES AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209547f7e10015fd5b95',
      },
      {
        _id: '60cc209547f7e10015fd5b97',
        courseCode: 'BIOCHEM 4410',
        shortName: 'Biochemistry 4410A - MOLECULAR BIOLOGY OF DNA AND RNA',
        courseName: 'Biochemistry 4410A - MOLECULAR BIOLOGY OF DNA AND RNA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209547f7e10015fd5b97',
      },
      {
        _id: '60cc209547f7e10015fd5b99',
        courseCode: 'BIOCHEM 4415',
        shortName:
          'Biochemistry 4415B - APPLICATIONS OF SYNTHETIC BIOLOGY AND CHEMICAL GENETICS IN MEDICINE',
        courseName:
          'Biochemistry 4415B - APPLICATIONS OF SYNTHETIC BIOLOGY AND CHEMICAL GENETICS IN MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209547f7e10015fd5b99',
      },
      {
        _id: '60cc209547f7e10015fd5b9b',
        courseCode: 'BIOCHEM 4420',
        shortName: 'Biochemistry 4420A - MOLECULAR BIOLOGY OF PROTEINS',
        courseName: 'Biochemistry 4420A - MOLECULAR BIOLOGY OF PROTEINS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209547f7e10015fd5b9b',
      },
      {
        _id: '60cc209647f7e10015fd5b9d',
        courseCode: 'BIOCHEM 4425',
        shortName: 'Biochemistry 4425B - PROTEOMICS AND PROTEIN BIOTECHNOLOGY',
        courseName: 'Biochemistry 4425B - PROTEOMICS AND PROTEIN BIOTECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209647f7e10015fd5b9d',
      },
      {
        _id: '60cc209647f7e10015fd5b9f',
        courseCode: 'BIOCHEM 4450',
        shortName: 'Biochemistry 4450A - MOLECULAR GENETICS OF HUMAN CANCER',
        courseName: 'Biochemistry 4450A - MOLECULAR GENETICS OF HUMAN CANCER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209647f7e10015fd5b9f',
      },
      {
        _id: '60cc209647f7e10015fd5ba1',
        courseCode: 'BIOCHEM 4455',
        shortName:
          'Biochemistry 4455G - TRANSLATIONAL CONCEPTS IN CANCER BIOLOGY',
        courseName:
          'Biochemistry 4455G - TRANSLATIONAL CONCEPTS IN CANCER BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209647f7e10015fd5ba1',
      },
      {
        _id: '60cc209647f7e10015fd5ba3',
        courseCode: 'BIOCHEM 4483',
        shortName: 'Biochemistry 4483E - RESEARCH  PROJECT AND SEMINAR',
        courseName: 'Biochemistry 4483E - RESEARCH  PROJECT AND SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209647f7e10015fd5ba3',
      },
      {
        _id: '60cc209647f7e10015fd5ba5',
        courseCode: 'BIOCHEM 4486',
        shortName:
          'Biochemistry 4486E - CANCER BIOLOGY RESEARCH PROJECT AND SEMINAR',
        courseName:
          'Biochemistry 4486E - CANCER BIOLOGY RESEARCH PROJECT AND SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209647f7e10015fd5ba5',
      },
      {
        _id: '60cc209747f7e10015fd5ba7',
        courseCode: 'BIOCHEM 4999',
        shortName: 'Biochemistry 4999E - ADVANCED RESEARCH IN BIOCHEMISTRY',
        courseName: 'Biochemistry 4999E - ADVANCED RESEARCH IN BIOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209747f7e10015fd5ba7',
      },
      {
        _id: '60cc209747f7e10015fd5ba9',
        courseCode: 'BIOLOGY 0010',
        shortName: 'Biology 0010 - BIOLOGICAL SYSTEMS',
        courseName: 'Biology 0010 - BIOLOGICAL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209747f7e10015fd5ba9',
      },
      {
        _id: '60cc209747f7e10015fd5bab',
        courseCode: 'BIOLOGY 0011',
        shortName: 'Biology 0011A/B - INTRODUCTORY BIOLOGY I',
        courseName: 'Biology 0011A/B - INTRODUCTORY BIOLOGY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209747f7e10015fd5bab',
      },
      {
        _id: '60cc209747f7e10015fd5bad',
        courseCode: 'BIOLOGY 0012',
        shortName: 'Biology 0012A/B - INTRODUCTORY BIOLOGY II',
        courseName: 'Biology 0012A/B - INTRODUCTORY BIOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209747f7e10015fd5bad',
      },
      {
        _id: '60cc209847f7e10015fd5baf',
        courseCode: 'BIOLOGY 1001',
        shortName: 'Biology 1001A - BIOLOGY FOR SCIENCE I',
        courseName: 'Biology 1001A - BIOLOGY FOR SCIENCE I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 5,
        id: '60cc209847f7e10015fd5baf',
      },
      {
        _id: '60cc209847f7e10015fd5bb1',
        courseCode: 'BIOLOGY 1002',
        shortName: 'Biology 1002B - BIOLOGY FOR SCIENCE II',
        courseName: 'Biology 1002B - BIOLOGY FOR SCIENCE II',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 6,
        id: '60cc209847f7e10015fd5bb1',
      },
      {
        _id: '60cc209847f7e10015fd5bb3',
        courseCode: 'BIOLOGY 1201',
        shortName: 'Biology 1201A - GENERAL BIOLOGY I',
        courseName: 'Biology 1201A - GENERAL BIOLOGY I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 3,
        id: '60cc209847f7e10015fd5bb3',
      },
      {
        _id: '60cc209847f7e10015fd5bb5',
        courseCode: 'BIOLOGY 1202',
        shortName: 'Biology 1202B - GENERAL BIOLOGY II',
        courseName: 'Biology 1202B - GENERAL BIOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209847f7e10015fd5bb5',
      },
      {
        _id: '60cc209947f7e10015fd5bb7',
        courseCode: 'BIOLOGY 1225',
        shortName: 'Biology 1225 - AN INTRODUCTION TO THE BIOLOGY OF ORGANISMS',
        courseName:
          'Biology 1225 - AN INTRODUCTION TO THE BIOLOGY OF ORGANISMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209947f7e10015fd5bb7',
      },
      {
        _id: '60cc209947f7e10015fd5bb9',
        courseCode: 'BIOLOGY 1229',
        shortName: 'Biology 1229A/B - BIOLOGY: THE SECRETS OF LIFE',
        courseName: 'Biology 1229A/B - BIOLOGY: THE SECRETS OF LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209947f7e10015fd5bb9',
      },
      {
        _id: '60cc209947f7e10015fd5bbb',
        courseCode: 'BIOLOGY 1290',
        shortName: 'Biology 1290B - BIOLOGY AND MICROORGANISMS',
        courseName: 'Biology 1290B - BIOLOGY AND MICROORGANISMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209947f7e10015fd5bbb',
      },
      {
        _id: '60cc209947f7e10015fd5bbd',
        courseCode: 'BIOLOGY 2217',
        shortName: 'Biology 2217A/B - ECONOMIC BOTANY',
        courseName: 'Biology 2217A/B - ECONOMIC BOTANY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209947f7e10015fd5bbd',
      },
      {
        _id: '60cc209a47f7e10015fd5bbf',
        courseCode: 'BIOLOGY 2244',
        shortName: 'Biology 2244A/B - STATISTICS FOR SCIENCE',
        courseName: 'Biology 2244A/B - STATISTICS FOR SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209a47f7e10015fd5bbf',
      },
      {
        _id: '60cc209a47f7e10015fd5bc1',
        courseCode: 'BIOLOGY 2290',
        shortName: 'Biology 2290F/G - SCIENTIFIC METHOD IN BIOLOGY',
        courseName: 'Biology 2290F/G - SCIENTIFIC METHOD IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209a47f7e10015fd5bc1',
      },
      {
        _id: '60cc209a47f7e10015fd5bc3',
        courseCode: 'BIOLOGY 2382',
        shortName: 'Biology 2382A/B - CELL BIOLOGY',
        courseName: 'Biology 2382A/B - CELL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209a47f7e10015fd5bc3',
      },
      {
        _id: '60cc209a47f7e10015fd5bc5',
        courseCode: 'BIOLOGY 2471',
        shortName: 'Biology 2471A/B - VERTEBRATE BIOLOGY',
        courseName: 'Biology 2471A/B - VERTEBRATE BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209a47f7e10015fd5bc5',
      },
      {
        _id: '60cc209b47f7e10015fd5bc7',
        courseCode: 'BIOLOGY 2483',
        shortName: 'Biology 2483A/B - ECOLOGY',
        courseName: 'Biology 2483A/B - ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209b47f7e10015fd5bc7',
      },
      {
        _id: '60cc209b47f7e10015fd5bc9',
        courseCode: 'BIOLOGY 2485',
        shortName: 'Biology 2485A/B - ENVIRONMENTAL BIOLOGY',
        courseName: 'Biology 2485A/B - ENVIRONMENTAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209b47f7e10015fd5bc9',
      },
      {
        _id: '60cc209b47f7e10015fd5bcb',
        courseCode: 'BIOLOGY 2581',
        shortName: 'Biology 2581A/B - GENETICS',
        courseName: 'Biology 2581A/B - GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209b47f7e10015fd5bcb',
      },
      {
        _id: '60cc209b47f7e10015fd5bcd',
        courseCode: 'BIOLOGY 2601',
        shortName: 'Biology 2601A/B - ORGANISMAL PHYSIOLOGY',
        courseName: 'Biology 2601A/B - ORGANISMAL PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209b47f7e10015fd5bcd',
      },
      {
        _id: '60cc209b47f7e10015fd5bcf',
        courseCode: 'BIOLOGY 3218',
        shortName: 'Biology 3218F/G - BIOLOGY OF THE FUNGI',
        courseName: 'Biology 3218F/G - BIOLOGY OF THE FUNGI',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209b47f7e10015fd5bcf',
      },
      {
        _id: '60cc209c47f7e10015fd5bd1',
        courseCode: 'BIOLOGY 3220',
        shortName: 'Biology 3220Z - FIELD STUDIES IN BIOLOGY',
        courseName: 'Biology 3220Z - FIELD STUDIES IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209c47f7e10015fd5bd1',
      },
      {
        _id: '60cc209c47f7e10015fd5bd3',
        courseCode: 'BIOLOGY 3222',
        shortName: 'Biology 3222F/G - SELECTED TOPICS',
        courseName: 'Biology 3222F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209c47f7e10015fd5bd3',
      },
      {
        _id: '60cc209c47f7e10015fd5bd5',
        courseCode: 'BIOLOGY 3224',
        shortName: 'Biology 3224F/G - SELECTED TOPICS',
        courseName: 'Biology 3224F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209c47f7e10015fd5bd5',
      },
      {
        _id: '60cc209c47f7e10015fd5bd7',
        courseCode: 'BIOLOGY 3229',
        shortName:
          'Biology 3229F/G - ANIMAL DIVERSITY: ANCESTRAL VERTEBRATES TO JELLYFISH',
        courseName:
          'Biology 3229F/G - ANIMAL DIVERSITY: ANCESTRAL VERTEBRATES TO JELLYFISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209c47f7e10015fd5bd7',
      },
      {
        _id: '60cc209d47f7e10015fd5bd9',
        courseCode: 'BIOLOGY 3230',
        shortName: 'Biology 3230F/G - FIELD RESEARCH IN BIOLOGY',
        courseName: 'Biology 3230F/G - FIELD RESEARCH IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209d47f7e10015fd5bd9',
      },
      {
        _id: '60cc209d47f7e10015fd5bdb',
        courseCode: 'BIOLOGY 3316',
        shortName: 'Biology 3316A/B - ADVANCED CELL BIOLOGY',
        courseName: 'Biology 3316A/B - ADVANCED CELL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209d47f7e10015fd5bdb',
      },
      {
        _id: '60cc209d47f7e10015fd5bdd',
        courseCode: 'BIOLOGY 3326',
        shortName: 'Biology 3326F/G - CELL BIOLOGY LABORATORY',
        courseName: 'Biology 3326F/G - CELL BIOLOGY LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209d47f7e10015fd5bdd',
      },
      {
        _id: '60cc209d47f7e10015fd5bdf',
        courseCode: 'BIOLOGY 3338',
        shortName: 'Biology 3338A/B - DEVELOPMENTAL BIOLOGY',
        courseName: 'Biology 3338A/B - DEVELOPMENTAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209d47f7e10015fd5bdf',
      },
      {
        _id: '60cc209e47f7e10015fd5be1',
        courseCode: 'BIOLOGY 3355',
        shortName: 'Biology 3355A/B - MOLECULAR CELL BIOLOGY OF STRESS',
        courseName: 'Biology 3355A/B - MOLECULAR CELL BIOLOGY OF STRESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209e47f7e10015fd5be1',
      },
      {
        _id: '60cc209e47f7e10015fd5be3',
        courseCode: 'BIOLOGY 3403',
        shortName: 'Biology 3403A/B - FLORA AND VEGETATION OF ONTARIO',
        courseName: 'Biology 3403A/B - FLORA AND VEGETATION OF ONTARIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209e47f7e10015fd5be3',
      },
      {
        _id: '60cc209e47f7e10015fd5be5',
        courseCode: 'BIOLOGY 3404',
        shortName: 'Biology 3404F/G - EVOLUTION OF PLANTS',
        courseName: 'Biology 3404F/G - EVOLUTION OF PLANTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209e47f7e10015fd5be5',
      },
      {
        _id: '60cc209e47f7e10015fd5be7',
        courseCode: 'BIOLOGY 3415',
        shortName: 'Biology 3415F/G - AQUATIC ECOLOGY',
        courseName: 'Biology 3415F/G - AQUATIC ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209e47f7e10015fd5be7',
      },
      {
        _id: '60cc209f47f7e10015fd5be9',
        courseCode: 'BIOLOGY 3435',
        shortName: 'Biology 3435F/G - ANIMAL ECOLOGY',
        courseName: 'Biology 3435F/G - ANIMAL ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209f47f7e10015fd5be9',
      },
      {
        _id: '60cc209f47f7e10015fd5beb',
        courseCode: 'BIOLOGY 3436',
        shortName: 'Biology 3436F/G - ANIMAL BEHAVIOUR',
        courseName: 'Biology 3436F/G - ANIMAL BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209f47f7e10015fd5beb',
      },
      {
        _id: '60cc209f47f7e10015fd5bed',
        courseCode: 'BIOLOGY 3440',
        shortName: 'Biology 3440A/B - ECOLOGY OF POPULATIONS',
        courseName: 'Biology 3440A/B - ECOLOGY OF POPULATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209f47f7e10015fd5bed',
      },
      {
        _id: '60cc209f47f7e10015fd5bef',
        courseCode: 'BIOLOGY 3442',
        shortName: 'Biology 3442F/G - CONSERVATION BIOLOGY',
        courseName: 'Biology 3442F/G - CONSERVATION BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc209f47f7e10015fd5bef',
      },
      {
        _id: '60cc20a047f7e10015fd5bf1',
        courseCode: 'BIOLOGY 3444',
        shortName: 'Biology 3444A/B - MOLECULAR ECOLOGY',
        courseName: 'Biology 3444A/B - MOLECULAR ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a047f7e10015fd5bf1',
      },
      {
        _id: '60cc20a047f7e10015fd5bf3',
        courseCode: 'BIOLOGY 3445',
        shortName: 'Biology 3445F/G - COMMUNITY ECOLOGY',
        courseName: 'Biology 3445F/G - COMMUNITY ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a047f7e10015fd5bf3',
      },
      {
        _id: '60cc20a047f7e10015fd5bf5',
        courseCode: 'BIOLOGY 3446',
        shortName: 'Biology 3446A/B - WILDLIFE ECOLOGY AND MANAGEMENT',
        courseName: 'Biology 3446A/B - WILDLIFE ECOLOGY AND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a047f7e10015fd5bf5',
      },
      {
        _id: '60cc20a047f7e10015fd5bf7',
        courseCode: 'BIOLOGY 3466',
        shortName: 'Biology 3466A/B - EVOLUTIONARY GENETICS',
        courseName: 'Biology 3466A/B - EVOLUTIONARY GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a047f7e10015fd5bf7',
      },
      {
        _id: '60cc20a147f7e10015fd5bf9',
        courseCode: 'BIOLOGY 3467',
        shortName: 'Biology 3467A/B - EVOLUTION AND REPRODUCTION',
        courseName: 'Biology 3467A/B - EVOLUTION AND REPRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a147f7e10015fd5bf9',
      },
      {
        _id: '60cc20a147f7e10015fd5bfb',
        courseCode: 'BIOLOGY 3475',
        shortName: 'Biology 3475A/B - CHEMICAL ECOLOGY',
        courseName: 'Biology 3475A/B - CHEMICAL ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a147f7e10015fd5bfb',
      },
      {
        _id: '60cc20a147f7e10015fd5bfd',
        courseCode: 'BIOLOGY 3484',
        shortName: 'Biology 3484A/B - PATTERNS IN DIVERSITY OF LIFE',
        courseName: 'Biology 3484A/B - PATTERNS IN DIVERSITY OF LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a147f7e10015fd5bfd',
      },
      {
        _id: '60cc20a147f7e10015fd5bff',
        courseCode: 'BIOLOGY 3592',
        shortName: 'Biology 3592A/B - PRINCIPLES OF HUMAN GENETICS',
        courseName: 'Biology 3592A/B - PRINCIPLES OF HUMAN GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a147f7e10015fd5bff',
      },
      {
        _id: '60cc20a247f7e10015fd5c01',
        courseCode: 'BIOLOGY 3593',
        shortName: 'Biology 3593A/B - GENETIC ENGINEERING',
        courseName: 'Biology 3593A/B - GENETIC ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a247f7e10015fd5c01',
      },
      {
        _id: '60cc20a247f7e10015fd5c03',
        courseCode: 'BIOLOGY 3594',
        shortName:
          'Biology 3594A/B - DNA:GENOME ORGANIZATION, MUTAGENESIS AND REPAIR',
        courseName:
          'Biology 3594A/B - DNA:GENOME ORGANIZATION, MUTAGENESIS AND REPAIR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a247f7e10015fd5c03',
      },
      {
        _id: '60cc20a247f7e10015fd5c05',
        courseCode: 'BIOLOGY 3595',
        shortName: 'Biology 3595A/B - ADVANCED GENETICS',
        courseName: 'Biology 3595A/B - ADVANCED GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a247f7e10015fd5c05',
      },
      {
        _id: '60cc20a247f7e10015fd5c07',
        courseCode: 'BIOLOGY 3596',
        shortName: 'Biology 3596A/B - GENOMICS AND BEYOND: A LABORATORY COURSE',
        courseName:
          'Biology 3596A/B - GENOMICS AND BEYOND: A LABORATORY COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a247f7e10015fd5c07',
      },
      {
        _id: '60cc20a247f7e10015fd5c09',
        courseCode: 'BIOLOGY 3597',
        shortName: 'Biology 3597A/B - REGULATION OF GENE EXPRESSION',
        courseName: 'Biology 3597A/B - REGULATION OF GENE EXPRESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a247f7e10015fd5c09',
      },
      {
        _id: '60cc20a347f7e10015fd5c0b',
        courseCode: 'BIOLOGY 3598',
        shortName: 'Biology 3598A/B - BEHAVIOURAL GENETICS',
        courseName: 'Biology 3598A/B - BEHAVIOURAL GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a347f7e10015fd5c0b',
      },
      {
        _id: '60cc20a347f7e10015fd5c0d',
        courseCode: 'BIOLOGY 3601',
        shortName: 'Biology 3601A/B - ANIMAL PHYSIOLOGY I',
        courseName: 'Biology 3601A/B - ANIMAL PHYSIOLOGY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a347f7e10015fd5c0d',
      },
      {
        _id: '60cc20a347f7e10015fd5c0f',
        courseCode: 'BIOLOGY 3602',
        shortName: 'Biology 3602A/B - ANIMAL PHYSIOLOGY II',
        courseName: 'Biology 3602A/B - ANIMAL PHYSIOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a347f7e10015fd5c0f',
      },
      {
        _id: '60cc20a347f7e10015fd5c11',
        courseCode: 'BIOLOGY 3603',
        shortName: 'Biology 3603A/B - ECOPHYSIOLOGY OF PLANTS',
        courseName: 'Biology 3603A/B - ECOPHYSIOLOGY OF PLANTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a347f7e10015fd5c11',
      },
      {
        _id: '60cc20a447f7e10015fd5c13',
        courseCode: 'BIOLOGY 3625',
        shortName: 'Biology 3625F/G - TECHNIQUES IN PHYSIOLOGY & BIOCHEMISTRY',
        courseName: 'Biology 3625F/G - TECHNIQUES IN PHYSIOLOGY & BIOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a447f7e10015fd5c13',
      },
      {
        _id: '60cc20a447f7e10015fd5c15',
        courseCode: 'BIOLOGY 3660',
        shortName: 'Biology 3660A/B - PLANT METABOLISM',
        courseName: 'Biology 3660A/B - PLANT METABOLISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a447f7e10015fd5c15',
      },
      {
        _id: '60cc20a447f7e10015fd5c17',
        courseCode: 'BIOLOGY 4200',
        shortName: 'Biology 4200A/B - SELECTED TOPICS IN BIOLOGY',
        courseName: 'Biology 4200A/B - SELECTED TOPICS IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a447f7e10015fd5c17',
      },
      {
        _id: '60cc20a447f7e10015fd5c19',
        courseCode: 'BIOLOGY 4218',
        shortName: 'Biology 4218A/B - PLANT PATHOLOGY',
        courseName: 'Biology 4218A/B - PLANT PATHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a447f7e10015fd5c19',
      },
      {
        _id: '60cc20a547f7e10015fd5c1b',
        courseCode: 'BIOLOGY 4223',
        shortName: 'Biology 4223F/G - MARINE ENVIRONMENTS',
        courseName: 'Biology 4223F/G - MARINE ENVIRONMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a547f7e10015fd5c1b',
      },
      {
        _id: '60cc20a547f7e10015fd5c1d',
        courseCode: 'BIOLOGY 4230',
        shortName: 'Biology 4230A/B - ECOSYSTEM HEALTH',
        courseName: 'Biology 4230A/B - ECOSYSTEM HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a547f7e10015fd5c1d',
      },
      {
        _id: '60cc20a547f7e10015fd5c1f',
        courseCode: 'BIOLOGY 4257',
        shortName: 'Biology 4257Z - FIELD STUDIES IN BIOLOGY',
        courseName: 'Biology 4257Z - FIELD STUDIES IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a547f7e10015fd5c1f',
      },
      {
        _id: '60cc20a547f7e10015fd5c21',
        courseCode: 'BIOLOGY 4258',
        shortName: 'Biology 4258Z - FIELD STUDIES IN BIOLOGY',
        courseName: 'Biology 4258Z - FIELD STUDIES IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a547f7e10015fd5c21',
      },
      {
        _id: '60cc20a647f7e10015fd5c23',
        courseCode: 'BIOLOGY 4259',
        shortName: 'Biology 4259F/G - RESEARCH HYPOTHESIS TESTING',
        courseName: 'Biology 4259F/G - RESEARCH HYPOTHESIS TESTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a647f7e10015fd5c23',
      },
      {
        _id: '60cc20a647f7e10015fd5c25',
        courseCode: 'BIOLOGY 4260',
        shortName: 'Biology 4260A/B - CELLULAR SYSTEMS BIOLOGY',
        courseName: 'Biology 4260A/B - CELLULAR SYSTEMS BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a647f7e10015fd5c25',
      },
      {
        _id: '60cc20a647f7e10015fd5c27',
        courseCode: 'BIOLOGY 4289',
        shortName: 'Biology 4289A/B - BIOSYSTEMATICS AND PHYLOGENETICS',
        courseName: 'Biology 4289A/B - BIOSYSTEMATICS AND PHYLOGENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a647f7e10015fd5c27',
      },
      {
        _id: '60cc20a647f7e10015fd5c29',
        courseCode: 'BIOLOGY 4300',
        shortName: 'Biology 4300F/G - SELECTED TOPICS IN CELL BIOLOGY',
        courseName: 'Biology 4300F/G - SELECTED TOPICS IN CELL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a647f7e10015fd5c29',
      },
      {
        _id: '60cc20a647f7e10015fd5c2b',
        courseCode: 'BIOLOGY 4338',
        shortName: 'Biology 4338F/G - ADVANCED DEVELOPMENTAL BIOLOGY',
        courseName: 'Biology 4338F/G - ADVANCED DEVELOPMENTAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a647f7e10015fd5c2b',
      },
      {
        _id: '60cc20a747f7e10015fd5c2d',
        courseCode: 'BIOLOGY 4355',
        shortName:
          'Biology 4355F/G - THE BIOLOGY OF AGING: CELLULAR AND MOLECULAR ASPECTS',
        courseName:
          'Biology 4355F/G - THE BIOLOGY OF AGING: CELLULAR AND MOLECULAR ASPECTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a747f7e10015fd5c2d',
      },
      {
        _id: '60cc20a747f7e10015fd5c2f',
        courseCode: 'BIOLOGY 4405',
        shortName: 'Biology 4405A/B - ECOSYSTEM ECOLOGY',
        courseName: 'Biology 4405A/B - ECOSYSTEM ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a747f7e10015fd5c2f',
      },
      {
        _id: '60cc20a747f7e10015fd5c31',
        courseCode: 'BIOLOGY 4410',
        shortName: 'Biology 4410F/G - RESTORATION ECOLOGY',
        courseName: 'Biology 4410F/G - RESTORATION ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a747f7e10015fd5c31',
      },
      {
        _id: '60cc20a747f7e10015fd5c33',
        courseCode: 'BIOLOGY 4412',
        shortName: 'Biology 4412F/G - BIODIVERSITY SCIENCE',
        courseName: 'Biology 4412F/G - BIODIVERSITY SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a747f7e10015fd5c33',
      },
      {
        _id: '60cc20a847f7e10015fd5c35',
        courseCode: 'BIOLOGY 4420',
        shortName:
          'Biology 4420A/B - INSECT BIOLOGY: FROM MORPHOLOGY TO ECOLOGY',
        courseName:
          'Biology 4420A/B - INSECT BIOLOGY: FROM MORPHOLOGY TO ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a847f7e10015fd5c35',
      },
      {
        _id: '60cc20a847f7e10015fd5c37',
        courseCode: 'BIOLOGY 4436',
        shortName: 'Biology 4436F/G - EVOLUTIONARY BEHAVIOURAL ECOLOGY',
        courseName: 'Biology 4436F/G - EVOLUTIONARY BEHAVIOURAL ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a847f7e10015fd5c37',
      },
      {
        _id: '60cc20a847f7e10015fd5c39',
        courseCode: 'BIOLOGY 4441',
        shortName: 'Biology 4441F/G - SPECIAL TOPICS IN EVOLUTION',
        courseName: 'Biology 4441F/G - SPECIAL TOPICS IN EVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a847f7e10015fd5c39',
      },
      {
        _id: '60cc20a847f7e10015fd5c3b',
        courseCode: 'BIOLOGY 4510',
        shortName: 'Biology 4510F/G - SELECTED TOPICS IN GENETICS',
        courseName: 'Biology 4510F/G - SELECTED TOPICS IN GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a847f7e10015fd5c3b',
      },
      {
        _id: '60cc20a947f7e10015fd5c3d',
        courseCode: 'BIOLOGY 4515',
        shortName: 'Biology 4515A/B - GENOME BIOLOGY',
        courseName: 'Biology 4515A/B - GENOME BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a947f7e10015fd5c3d',
      },
      {
        _id: '60cc20a947f7e10015fd5c3f',
        courseCode: 'BIOLOGY 4540',
        shortName: 'Biology 4540F/G - DEVELOPMENTAL GENETICS',
        courseName: 'Biology 4540F/G - DEVELOPMENTAL GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a947f7e10015fd5c3f',
      },
      {
        _id: '60cc20a947f7e10015fd5c41',
        courseCode: 'BIOLOGY 4560',
        shortName: 'Biology 4560A/B - HUMAN MOLECULAR BIOLOGY',
        courseName: 'Biology 4560A/B - HUMAN MOLECULAR BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a947f7e10015fd5c41',
      },
      {
        _id: '60cc20a947f7e10015fd5c43',
        courseCode: 'BIOLOGY 4561',
        shortName: 'Biology 4561F/G - GENES AND GENOMES I',
        courseName: 'Biology 4561F/G - GENES AND GENOMES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a947f7e10015fd5c43',
      },
      {
        _id: '60cc20a947f7e10015fd5c45',
        courseCode: 'BIOLOGY 4562',
        shortName: 'Biology 4562A/B - GENES & GENOMES II',
        courseName: 'Biology 4562A/B - GENES & GENOMES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20a947f7e10015fd5c45',
      },
      {
        _id: '60cc20aa47f7e10015fd5c47',
        courseCode: 'BIOLOGY 4563',
        shortName: 'Biology 4563F/G - GENOME EVOLUTION',
        courseName: 'Biology 4563F/G - GENOME EVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20aa47f7e10015fd5c47',
      },
      {
        _id: '60cc20aa47f7e10015fd5c49',
        courseCode: 'BIOLOGY 4583',
        shortName: 'Biology 4583F/G - MOLECULAR GENETICS LABORATORY',
        courseName: 'Biology 4583F/G - MOLECULAR GENETICS LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20aa47f7e10015fd5c49',
      },
      {
        _id: '60cc20aa47f7e10015fd5c4b',
        courseCode: 'BIOLOGY 4602',
        shortName: 'Biology 4602F/G - THERMAL PHYSIOLOGY',
        courseName: 'Biology 4602F/G - THERMAL PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20aa47f7e10015fd5c4b',
      },
      {
        _id: '60cc20aa47f7e10015fd5c4d',
        courseCode: 'BIOLOGY 4608',
        shortName: 'Biology 4608F/G - ENVIRONMENTAL PLANT PHYSIOLOGY',
        courseName: 'Biology 4608F/G - ENVIRONMENTAL PLANT PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20aa47f7e10015fd5c4d',
      },
      {
        _id: '60cc20ab47f7e10015fd5c4f',
        courseCode: 'BIOLOGY 4611',
        shortName: 'Biology 4611F/G - PHYSIOLOGY OF ANIMAL MIGRATION',
        courseName: 'Biology 4611F/G - PHYSIOLOGY OF ANIMAL MIGRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ab47f7e10015fd5c4f',
      },
      {
        _id: '60cc20ab47f7e10015fd5c51',
        courseCode: 'BIOLOGY 4920',
        shortName: 'Biology 4920F/G/Z - SEMINAR IN BIOLOGY',
        courseName: 'Biology 4920F/G/Z - SEMINAR IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ab47f7e10015fd5c51',
      },
      {
        _id: '60cc20ab47f7e10015fd5c53',
        courseCode: 'BIOLOGY 4944',
        shortName: 'Biology 4944F/G - SEMINAR IN ECOLOGY AND EVOLUTION',
        courseName: 'Biology 4944F/G - SEMINAR IN ECOLOGY AND EVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ab47f7e10015fd5c53',
      },
      {
        _id: '60cc20ab47f7e10015fd5c55',
        courseCode: 'BIOLOGY 4950',
        shortName: 'Biology 4950F/G - SEMINAR IN GENETICS',
        courseName: 'Biology 4950F/G - SEMINAR IN GENETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ab47f7e10015fd5c55',
      },
      {
        _id: '60cc20ac47f7e10015fd5c57',
        courseCode: 'BIOLOGY 4970',
        shortName: 'Biology 4970F/G - INDEPENDENT STUDY IN BIOLOGY',
        courseName: 'Biology 4970F/G - INDEPENDENT STUDY IN BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ac47f7e10015fd5c57',
      },
      {
        _id: '60cc20ac47f7e10015fd5c59',
        courseCode: 'BIOLOGY 4971',
        shortName: 'Biology 4971G/Z - INDEPENDENT STUDY',
        courseName: 'Biology 4971G/Z - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ac47f7e10015fd5c59',
      },
      {
        _id: '60cc20ac47f7e10015fd5c5b',
        courseCode: 'BIOLOGY 4998',
        shortName:
          'Biology 4998E - SYNTHETIC BIOLOGY: APPLICATION OF PRINCIPLES AND PRACTICE',
        courseName:
          'Biology 4998E - SYNTHETIC BIOLOGY: APPLICATION OF PRINCIPLES AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ac47f7e10015fd5c5b',
      },
      {
        _id: '60cc20ad47f7e10015fd5c5d',
        courseCode: 'BIOLOGY 4999',
        shortName: 'Biology 4999E - HONOURS RESEARCH THESIS',
        courseName: 'Biology 4999E - HONOURS RESEARCH THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ad47f7e10015fd5c5d',
      },
      {
        _id: '60cc20ad47f7e10015fd5c5f',
        courseCode: 'BME 3201',
        shortName:
          'Biomedical Engineering 3201A/B - FUNDAMENTALS OF BIOMEDICAL ENGINEERING DESIGN',
        courseName:
          'Biomedical Engineering 3201A/B - FUNDAMENTALS OF BIOMEDICAL ENGINEERING DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ad47f7e10015fd5c5f',
      },
      {
        _id: '60cc20ad47f7e10015fd5c61',
        courseCode: 'BME 3301',
        shortName:
          'Biomedical Engineering 3301A/B - NEURAL SYSTEMS ENGINEERING',
        courseName:
          'Biomedical Engineering 3301A/B - NEURAL SYSTEMS ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ad47f7e10015fd5c61',
      },
      {
        _id: '60cc20ad47f7e10015fd5c63',
        courseCode: 'BME 3303',
        shortName:
          'Biomedical Engineering 3303A/B - CELLULAR SYSTEMS ENGINEERING',
        courseName:
          'Biomedical Engineering 3303A/B - CELLULAR SYSTEMS ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ad47f7e10015fd5c63',
      },
      {
        _id: '60cc20ae47f7e10015fd5c65',
        courseCode: 'BME 4400',
        shortName: 'Biomedical Engineering 4400 - BME RESEARCH PROJECT',
        courseName: 'Biomedical Engineering 4400 - BME RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ae47f7e10015fd5c65',
      },
      {
        _id: '60cc20ae47f7e10015fd5c67',
        courseCode: 'BIOSTATS 3100',
        shortName:
          'Biostatistics 3100A - BIOSTATISTICAL METHODS FOR EPIDEMIOLOGY',
        courseName:
          'Biostatistics 3100A - BIOSTATISTICAL METHODS FOR EPIDEMIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ae47f7e10015fd5c67',
      },
      {
        _id: '60cc20ae47f7e10015fd5c69',
        courseCode: 'BIOSTATS 3110',
        shortName: 'Biostatistics 3110B - MULTIVARIABLE METHODS',
        courseName: 'Biostatistics 3110B - MULTIVARIABLE METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ae47f7e10015fd5c69',
      },
      {
        _id: '60cc20ae47f7e10015fd5c6b',
        courseCode: 'BIOSTATS 4115',
        shortName:
          'Biostatistics 4115B - SURVIVAL ANALYSIS AND ITS APPLICATIONS',
        courseName:
          'Biostatistics 4115B - SURVIVAL ANALYSIS AND ITS APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ae47f7e10015fd5c6b',
      },
      {
        _id: '60cc20ae47f7e10015fd5c6d',
        courseCode: 'BUSINESS 1220',
        shortName: 'Business Administration 1220E - INTRODUCTION TO BUSINESS',
        courseName: 'Business Administration 1220E - INTRODUCTION TO BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 5,
        id: '60cc20ae47f7e10015fd5c6d',
      },
      {
        _id: '60cc20af47f7e10015fd5c6f',
        courseCode: 'BUSINESS 1299',
        shortName: 'Business Administration 1299E - BUSINESS FOR ENGINEERS',
        courseName: 'Business Administration 1299E - BUSINESS FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20af47f7e10015fd5c6f',
      },
      {
        _id: '60cc20af47f7e10015fd5c71',
        courseCode: 'BUSINESS 2257',
        shortName:
          'Business Administration 2257 - ACCOUNTING & BUSINESS ANALYSIS',
        courseName:
          'Business Administration 2257 - ACCOUNTING & BUSINESS ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20af47f7e10015fd5c71',
      },
      {
        _id: '60cc20b047f7e10015fd5c73',
        courseCode: 'BUSINESS 2295',
        shortName:
          'Business Administration 2295F/G - BUSINESS BASICS FOR THE SCIENCES',
        courseName:
          'Business Administration 2295F/G - BUSINESS BASICS FOR THE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b047f7e10015fd5c73',
      },
      {
        _id: '60cc20b047f7e10015fd5c75',
        courseCode: 'BUSINESS 2299',
        shortName: 'Business Administration 2299E - BUSINESS FOR ENGINEERS',
        courseName: 'Business Administration 2299E - BUSINESS FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b047f7e10015fd5c75',
      },
      {
        _id: '60cc20b147f7e10015fd5c77',
        courseCode: 'BUSINESS 3300',
        shortName: 'Business Administration 3300K - STRATEGY',
        courseName: 'Business Administration 3300K - STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b147f7e10015fd5c77',
      },
      {
        _id: '60cc20b147f7e10015fd5c79',
        courseCode: 'BUSINESS 3301',
        shortName: 'Business Administration 3301K - MARKETING',
        courseName: 'Business Administration 3301K - MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b147f7e10015fd5c79',
      },
      {
        _id: '60cc20b147f7e10015fd5c7b',
        courseCode: 'BUSINESS 3302',
        shortName: 'Business Administration 3302K - COMMUNICATIONS',
        courseName: 'Business Administration 3302K - COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b147f7e10015fd5c7b',
      },
      {
        _id: '60cc20b147f7e10015fd5c7d',
        courseCode: 'BUSINESS 3303',
        shortName: 'Business Administration 3303K - FINANCE',
        courseName: 'Business Administration 3303K - FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b147f7e10015fd5c7d',
      },
      {
        _id: '60cc20b247f7e10015fd5c7f',
        courseCode: 'BUSINESS 3304',
        shortName: 'Business Administration 3304K - OPERATIONS',
        courseName: 'Business Administration 3304K - OPERATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b247f7e10015fd5c7f',
      },
      {
        _id: '60cc20b247f7e10015fd5c81',
        courseCode: 'BUSINESS 3307',
        shortName:
          'Business Administration 3307K - MANAGERIAL ACCOUNTING AND CONTROL',
        courseName:
          'Business Administration 3307K - MANAGERIAL ACCOUNTING AND CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b247f7e10015fd5c81',
      },
      {
        _id: '60cc20b247f7e10015fd5c83',
        courseCode: 'BUSINESS 3311',
        shortName:
          'Business Administration 3311K - LEADING PEOPLE IN ORGANIZATIONS',
        courseName:
          'Business Administration 3311K - LEADING PEOPLE IN ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b247f7e10015fd5c83',
      },
      {
        _id: '60cc20b247f7e10015fd5c85',
        courseCode: 'BUSINESS 3316',
        shortName:
          'Business Administration 3316K - DECISION MAKING WITH ANALYTICS',
        courseName:
          'Business Administration 3316K - DECISION MAKING WITH ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b247f7e10015fd5c85',
      },
      {
        _id: '60cc20b247f7e10015fd5c87',
        courseCode: 'BUSINESS 3321',
        shortName:
          'Business Administration 3321K - ACCOUNTING & FINANCIAL FUNDAMENTALS',
        courseName:
          'Business Administration 3321K - ACCOUNTING & FINANCIAL FUNDAMENTALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b247f7e10015fd5c87',
      },
      {
        _id: '60cc20b347f7e10015fd5c89',
        courseCode: 'BUSINESS 3322',
        shortName:
          'Business Administration 3322K - LEVERAGING INFORMATION TECHNOLOGY',
        courseName:
          'Business Administration 3322K - LEVERAGING INFORMATION TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b347f7e10015fd5c89',
      },
      {
        _id: '60cc20b347f7e10015fd5c8b',
        courseCode: 'BUSINESS 3323',
        shortName: 'Business Administration 3323K - LEARNING THROUGH ACTION',
        courseName: 'Business Administration 3323K - LEARNING THROUGH ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b347f7e10015fd5c8b',
      },
      {
        _id: '60cc20b347f7e10015fd5c8d',
        courseCode: 'BUSINESS 4402',
        shortName:
          'Business Administration 4402Q/R/S/T - COMMUNICATIONS & SOCIETY',
        courseName:
          'Business Administration 4402Q/R/S/T - COMMUNICATIONS & SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b347f7e10015fd5c8d',
      },
      {
        _id: '60cc20b347f7e10015fd5c8f',
        courseCode: 'BUSINESS 4408',
        shortName:
          'Business Administration 4408A/B - CROSS CULTURAL MANAGEMENT',
        courseName:
          'Business Administration 4408A/B - CROSS CULTURAL MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b347f7e10015fd5c8f',
      },
      {
        _id: '60cc20b447f7e10015fd5c91',
        courseCode: 'BUSINESS 4412',
        shortName:
          'Business Administration 4412Q/R/S/T - ADVANCED PRESENTATION SKILLS',
        courseName:
          'Business Administration 4412Q/R/S/T - ADVANCED PRESENTATION SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b447f7e10015fd5c91',
      },
      {
        _id: '60cc20b447f7e10015fd5c93',
        courseCode: 'BUSINESS 4413',
        shortName:
          'Business Administration 4413A/B - DERIVATIVES & RISK MANAGEMENT',
        courseName:
          'Business Administration 4413A/B - DERIVATIVES & RISK MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b447f7e10015fd5c93',
      },
      {
        _id: '60cc20b447f7e10015fd5c95',
        courseCode: 'BUSINESS 4417',
        shortName:
          'Business Administration 4417A/B - CORPORATION FINANCIAL REPORTING',
        courseName:
          'Business Administration 4417A/B - CORPORATION FINANCIAL REPORTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b447f7e10015fd5c95',
      },
      {
        _id: '60cc20b447f7e10015fd5c97',
        courseCode: 'BUSINESS 4421',
        shortName:
          'Business Administration 4421A/B - BUSINESS TO BUSINESS MARKETING',
        courseName:
          'Business Administration 4421A/B - BUSINESS TO BUSINESS MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b447f7e10015fd5c97',
      },
      {
        _id: '60cc20b547f7e10015fd5c99',
        courseCode: 'BUSINESS 4427',
        shortName:
          'Business Administration 4427A/B - ADVANCED CORPORATE FINANCIAL REPORTING',
        courseName:
          'Business Administration 4427A/B - ADVANCED CORPORATE FINANCIAL REPORTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b547f7e10015fd5c99',
      },
      {
        _id: '60cc20b547f7e10015fd5c9b',
        courseCode: 'BUSINESS 4431',
        shortName: 'Business Administration 4431A/B - ADVERTISING & PROMOTION',
        courseName: 'Business Administration 4431A/B - ADVERTISING & PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b547f7e10015fd5c9b',
      },
      {
        _id: '60cc20b547f7e10015fd5c9d',
        courseCode: 'BUSINESS 4433',
        shortName: 'Business Administration 4433A/B - PORTFOLIO MANAGEMENT',
        courseName: 'Business Administration 4433A/B - PORTFOLIO MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b547f7e10015fd5c9d',
      },
      {
        _id: '60cc20b547f7e10015fd5c9f',
        courseCode: 'BUSINESS 4434',
        shortName: 'Business Administration 4434A/B - MANAGEMENT OF SERVICES',
        courseName: 'Business Administration 4434A/B - MANAGEMENT OF SERVICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b547f7e10015fd5c9f',
      },
      {
        _id: '60cc20b647f7e10015fd5ca1',
        courseCode: 'BUSINESS 4437',
        shortName: 'Business Administration 4437Q/R/S/T - INCOME TAX PLANNING',
        courseName: 'Business Administration 4437Q/R/S/T - INCOME TAX PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b647f7e10015fd5ca1',
      },
      {
        _id: '60cc20b647f7e10015fd5ca3',
        courseCode: 'BUSINESS 4439',
        shortName: 'Business Administration 4439A/B - ENTREPRENEURIAL FINANCE',
        courseName: 'Business Administration 4439A/B - ENTREPRENEURIAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b647f7e10015fd5ca3',
      },
      {
        _id: '60cc20b647f7e10015fd5ca5',
        courseCode: 'BUSINESS 4441',
        shortName:
          'Business Administration 4441A/B - ENTREPRENEURIAL MARKETING',
        courseName:
          'Business Administration 4441A/B - ENTREPRENEURIAL MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b647f7e10015fd5ca5',
      },
      {
        _id: '60cc20b647f7e10015fd5ca7',
        courseCode: 'BUSINESS 4443',
        shortName: 'Business Administration 4443A/B - VALUE INVESTING',
        courseName: 'Business Administration 4443A/B - VALUE INVESTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b647f7e10015fd5ca7',
      },
      {
        _id: '60cc20b647f7e10015fd5ca9',
        courseCode: 'BUSINESS 4444',
        shortName: 'Business Administration 4444A/B - THE OPERATING MANAGER',
        courseName: 'Business Administration 4444A/B - THE OPERATING MANAGER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b647f7e10015fd5ca9',
      },
      {
        _id: '60cc20b747f7e10015fd5cab',
        courseCode: 'BUSINESS 4447',
        shortName:
          'Business Administration 4447A/B - ACCOUNTING & CONTROL FOR GLOBAL OPERATIONS',
        courseName:
          'Business Administration 4447A/B - ACCOUNTING & CONTROL FOR GLOBAL OPERATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b747f7e10015fd5cab',
      },
      {
        _id: '60cc20b747f7e10015fd5cad',
        courseCode: 'BUSINESS 4449',
        shortName: 'Business Administration 4449Q/R/S/T - BUSINESS LEADERSHIP',
        courseName: 'Business Administration 4449Q/R/S/T - BUSINESS LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b747f7e10015fd5cad',
      },
      {
        _id: '60cc20b747f7e10015fd5caf',
        courseCode: 'BUSINESS 4450',
        shortName: 'Business Administration 4450A/B - BUSINESS LAW',
        courseName: 'Business Administration 4450A/B - BUSINESS LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b747f7e10015fd5caf',
      },
      {
        _id: '60cc20b747f7e10015fd5cb1',
        courseCode: 'BUSINESS 4454',
        shortName: 'Business Administration 4454A/B - OPERATIONS STRATEGY',
        courseName: 'Business Administration 4454A/B - OPERATIONS STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b747f7e10015fd5cb1',
      },
      {
        _id: '60cc20b847f7e10015fd5cb3',
        courseCode: 'BUSINESS 4457',
        shortName: 'Business Administration 4457A/B - PERFORMANCE MANAGEMENT',
        courseName: 'Business Administration 4457A/B - PERFORMANCE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b847f7e10015fd5cb3',
      },
      {
        _id: '60cc20b847f7e10015fd5cb5',
        courseCode: 'BUSINESS 4458',
        shortName: 'Business Administration 4458A/B - LEADING CHANGE',
        courseName: 'Business Administration 4458A/B - LEADING CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b847f7e10015fd5cb5',
      },
      {
        _id: '60cc20b847f7e10015fd5cb7',
        courseCode: 'BUSINESS 4459',
        shortName: 'Business Administration 4459Q/R/S/T - RESEARCH',
        courseName: 'Business Administration 4459Q/R/S/T - RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b847f7e10015fd5cb7',
      },
      {
        _id: '60cc20b847f7e10015fd5cb9',
        courseCode: 'BUSINESS 4461',
        shortName:
          'Business Administration 4461A/B - STRATEGIC MARKET PLANNING',
        courseName:
          'Business Administration 4461A/B - STRATEGIC MARKET PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b847f7e10015fd5cb9',
      },
      {
        _id: '60cc20b947f7e10015fd5cbb',
        courseCode: 'BUSINESS 4463',
        shortName: 'Business Administration 4463A/B - INTERNATIONAL FINANCE',
        courseName: 'Business Administration 4463A/B - INTERNATIONAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b947f7e10015fd5cbb',
      },
      {
        _id: '60cc20b947f7e10015fd5cbd',
        courseCode: 'BUSINESS 4464',
        shortName:
          'Business Administration 4464A/B - PURCHASING & SUPPLY MANAGEMENT',
        courseName:
          'Business Administration 4464A/B - PURCHASING & SUPPLY MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b947f7e10015fd5cbd',
      },
      {
        _id: '60cc20b947f7e10015fd5cbf',
        courseCode: 'BUSINESS 4468',
        shortName:
          'Business Administration 4468A/B - INTERPERSONAL NEGOTIATIONS',
        courseName:
          'Business Administration 4468A/B - INTERPERSONAL NEGOTIATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b947f7e10015fd5cbf',
      },
      {
        _id: '60cc20b947f7e10015fd5cc1',
        courseCode: 'BUSINESS 4469',
        shortName: 'Business Administration 4469A/B - COMPETING WITH ANALYTICS',
        courseName:
          'Business Administration 4469A/B - COMPETING WITH ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20b947f7e10015fd5cc1',
      },
      {
        _id: '60cc20ba47f7e10015fd5cc3',
        courseCode: 'BUSINESS 4477',
        shortName:
          'Business Administration 4477A/B - CORPORATE FINANCIAL REPORTING II',
        courseName:
          'Business Administration 4477A/B - CORPORATE FINANCIAL REPORTING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ba47f7e10015fd5cc3',
      },
      {
        _id: '60cc20ba47f7e10015fd5cc5',
        courseCode: 'BUSINESS 4479',
        shortName: 'Business Administration 4479A/B - TAXATION FOR MANAGERS',
        courseName: 'Business Administration 4479A/B - TAXATION FOR MANAGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ba47f7e10015fd5cc5',
      },
      {
        _id: '60cc20ba47f7e10015fd5cc7',
        courseCode: 'BUSINESS 4480',
        shortName: 'Business Administration 4480A/B - GLOBAL STRATEGY',
        courseName: 'Business Administration 4480A/B - GLOBAL STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ba47f7e10015fd5cc7',
      },
      {
        _id: '60cc20ba47f7e10015fd5cc9',
        courseCode: 'BUSINESS 4486',
        shortName: 'Business Administration 4486A/B - FINANCIAL MODELING',
        courseName: 'Business Administration 4486A/B - FINANCIAL MODELING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ba47f7e10015fd5cc9',
      },
      {
        _id: '60cc20ba47f7e10015fd5ccb',
        courseCode: 'BUSINESS 4489',
        shortName:
          'Business Administration 4489Q/R/S/T - MANAGING THE PROFESSIONAL  SERVICE FIRM',
        courseName:
          'Business Administration 4489Q/R/S/T - MANAGING THE PROFESSIONAL  SERVICE FIRM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ba47f7e10015fd5ccb',
      },
      {
        _id: '60cc20bb47f7e10015fd5ccd',
        courseCode: 'BUSINESS 4492',
        shortName: 'Business Administration 4492 - RESEARCH',
        courseName: 'Business Administration 4492 - RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bb47f7e10015fd5ccd',
      },
      {
        _id: '60cc20bb47f7e10015fd5ccf',
        courseCode: 'BUSINESS 4495',
        shortName:
          'Business Administration 4495A/B - CONSUMER BRANDS MARKETING',
        courseName:
          'Business Administration 4495A/B - CONSUMER BRANDS MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bb47f7e10015fd5ccf',
      },
      {
        _id: '60cc20bb47f7e10015fd5cd1',
        courseCode: 'BUSINESS 4497',
        shortName: 'Business Administration 4497A/B - AUDITING',
        courseName: 'Business Administration 4497A/B - AUDITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bb47f7e10015fd5cd1',
      },
      {
        _id: '60cc20bb47f7e10015fd5cd3',
        courseCode: 'BUSINESS 4498',
        shortName:
          'Business Administration 4498A/B - MANAGING PEOPLE FOR EXCEPTIONAL PERFORMANCE',
        courseName:
          'Business Administration 4498A/B - MANAGING PEOPLE FOR EXCEPTIONAL PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bb47f7e10015fd5cd3',
      },
      {
        _id: '60cc20bc47f7e10015fd5cd5',
        courseCode: 'BUSINESS 4499',
        shortName: 'Business Administration 4499A/B - RESEARCH PROJECT',
        courseName: 'Business Administration 4499A/B - RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bc47f7e10015fd5cd5',
      },
      {
        _id: '60cc20bc47f7e10015fd5cd7',
        courseCode: 'BUSINESS 4500',
        shortName: 'Business Administration 4500A/B - LEARNING FROM LEADERS',
        courseName: 'Business Administration 4500A/B - LEARNING FROM LEADERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bc47f7e10015fd5cd7',
      },
      {
        _id: '60cc20bc47f7e10015fd5cd9',
        courseCode: 'BUSINESS 4501',
        shortName:
          'Business Administration 4501A/B - COMPETING IN AND WITH CHINA',
        courseName:
          'Business Administration 4501A/B - COMPETING IN AND WITH CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bc47f7e10015fd5cd9',
      },
      {
        _id: '60cc20bc47f7e10015fd5cdb',
        courseCode: 'BUSINESS 4503',
        shortName:
          'Business Administration 4503A/B - LEADERSHIP & COMMUNICATIONS',
        courseName:
          'Business Administration 4503A/B - LEADERSHIP & COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bc47f7e10015fd5cdb',
      },
      {
        _id: '60cc20bc47f7e10015fd5cdd',
        courseCode: 'BUSINESS 4505',
        shortName:
          'Business Administration 4505A/B - GLOBAL MACROECONOMICS FOR MANAGERS',
        courseName:
          'Business Administration 4505A/B - GLOBAL MACROECONOMICS FOR MANAGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bc47f7e10015fd5cdd',
      },
      {
        _id: '60cc20bd47f7e10015fd5cdf',
        courseCode: 'BUSINESS 4507',
        shortName:
          'Business Administration 4507A/B - SPECIAL TOPICS IN ACCOUNTING',
        courseName:
          'Business Administration 4507A/B - SPECIAL TOPICS IN ACCOUNTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bd47f7e10015fd5cdf',
      },
      {
        _id: '60cc20bd47f7e10015fd5ce1',
        courseCode: 'BUSINESS 4508',
        shortName:
          'Business Administration 4508A/B - SPECIAL TOPICS IN COMMUNICATIONS',
        courseName:
          'Business Administration 4508A/B - SPECIAL TOPICS IN COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bd47f7e10015fd5ce1',
      },
      {
        _id: '60cc20bd47f7e10015fd5ce3',
        courseCode: 'BUSINESS 4509',
        shortName:
          'Business Administration 4509A/B - SPECIAL TOPICS IN FINANCE',
        courseName:
          'Business Administration 4509A/B - SPECIAL TOPICS IN FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bd47f7e10015fd5ce3',
      },
      {
        _id: '60cc20bd47f7e10015fd5ce5',
        courseCode: 'BUSINESS 4510',
        shortName:
          'Business Administration 4510A/B - SPECIAL TOPICS IN GENERAL MANAGEMENT',
        courseName:
          'Business Administration 4510A/B - SPECIAL TOPICS IN GENERAL MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bd47f7e10015fd5ce5',
      },
      {
        _id: '60cc20be47f7e10015fd5ce7',
        courseCode: 'BUSINESS 4511',
        shortName:
          'Business Administration 4511A/B - SPECIAL TOPICS IN MANAGEMENT SCIENCE',
        courseName:
          'Business Administration 4511A/B - SPECIAL TOPICS IN MANAGEMENT SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20be47f7e10015fd5ce7',
      },
      {
        _id: '60cc20be47f7e10015fd5ce9',
        courseCode: 'BUSINESS 4517',
        shortName: 'Business Administration 4517A/B - END USER MODELLING',
        courseName: 'Business Administration 4517A/B - END USER MODELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20be47f7e10015fd5ce9',
      },
      {
        _id: '60cc20be47f7e10015fd5ceb',
        courseCode: 'BUSINESS 4518',
        shortName: 'Business Administration 4518A/B - PROJECT MANAGEMENT',
        courseName: 'Business Administration 4518A/B - PROJECT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20be47f7e10015fd5ceb',
      },
      {
        _id: '60cc20be47f7e10015fd5ced',
        courseCode: 'BUSINESS 4519',
        shortName:
          'Business Administration 4519A/B - CO-CREATING VALUE IN DEVELOPING MARKETS',
        courseName:
          'Business Administration 4519A/B - CO-CREATING VALUE IN DEVELOPING MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20be47f7e10015fd5ced',
      },
      {
        _id: '60cc20be47f7e10015fd5cef',
        courseCode: 'BUSINESS 4520',
        shortName: 'Business Administration 4520A/B - REVENUE MANAGEMENT',
        courseName: 'Business Administration 4520A/B - REVENUE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20be47f7e10015fd5cef',
      },
      {
        _id: '60cc20bf47f7e10015fd5cf1',
        courseCode: 'BUSINESS 4521',
        shortName: 'Business Administration 4521A/B - SOCIAL INNOVATION',
        courseName: 'Business Administration 4521A/B - SOCIAL INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bf47f7e10015fd5cf1',
      },
      {
        _id: '60cc20bf47f7e10015fd5cf3',
        courseCode: 'BUSINESS 4522',
        shortName:
          'Business Administration 4522A/B - MANAGING THE TRIPLE BOTTOM LINE',
        courseName:
          'Business Administration 4522A/B - MANAGING THE TRIPLE BOTTOM LINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bf47f7e10015fd5cf3',
      },
      {
        _id: '60cc20bf47f7e10015fd5cf5',
        courseCode: 'BUSINESS 4523',
        shortName:
          'Business Administration 4523A/B - BUSINESS, GOVERNMENT AND THE ENVIRONMENT',
        courseName:
          'Business Administration 4523A/B - BUSINESS, GOVERNMENT AND THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bf47f7e10015fd5cf5',
      },
      {
        _id: '60cc20bf47f7e10015fd5cf7',
        courseCode: 'BUSINESS 4524',
        shortName:
          'Business Administration 4524Q/R/S/T - INTERNATIONAL BUSINESS ENVIRONMENT STUDY PROJECT',
        courseName:
          'Business Administration 4524Q/R/S/T - INTERNATIONAL BUSINESS ENVIRONMENT STUDY PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20bf47f7e10015fd5cf7',
      },
      {
        _id: '60cc20c047f7e10015fd5cf9',
        courseCode: 'BUSINESS 4525',
        shortName:
          'Business Administration 4525A/B - INTERNATIONAL BUSINESS ENVIRONMENT STUDY RESEARCH PROJECT',
        courseName:
          'Business Administration 4525A/B - INTERNATIONAL BUSINESS ENVIRONMENT STUDY RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c047f7e10015fd5cf9',
      },
      {
        _id: '60cc20c047f7e10015fd5cfb',
        courseCode: 'BUSINESS 4527',
        shortName:
          'Business Administration 4527A/B - INDEPENDENT INTERNATIONAL BUSINESS STUDY RESEARCH PROJECT',
        courseName:
          'Business Administration 4527A/B - INDEPENDENT INTERNATIONAL BUSINESS STUDY RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c047f7e10015fd5cfb',
      },
      {
        _id: '60cc20c047f7e10015fd5cfd',
        courseCode: 'BUSINESS 4530',
        shortName:
          'Business Administration 4530A/B - COMPETITION & COMPETITOR ANALYSIS',
        courseName:
          'Business Administration 4530A/B - COMPETITION & COMPETITOR ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c047f7e10015fd5cfd',
      },
      {
        _id: '60cc20c047f7e10015fd5cff',
        courseCode: 'BUSINESS 4535',
        shortName:
          'Business Administration 4535A/B - INTERGRATING & IMPLEMENTING MARKETING DECISIONS',
        courseName:
          'Business Administration 4535A/B - INTERGRATING & IMPLEMENTING MARKETING DECISIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c047f7e10015fd5cff',
      },
      {
        _id: '60cc20c147f7e10015fd5d01',
        courseCode: 'BUSINESS 4536',
        shortName: 'Business Administration 4536A/B - CORPORATE GOVERNANCE',
        courseName: 'Business Administration 4536A/B - CORPORATE GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c147f7e10015fd5d01',
      },
      {
        _id: '60cc20c147f7e10015fd5d03',
        courseCode: 'BUSINESS 4537',
        shortName: 'Business Administration 4537A/B - STRATEGY IMPLEMENTATION',
        courseName: 'Business Administration 4537A/B - STRATEGY IMPLEMENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c147f7e10015fd5d03',
      },
      {
        _id: '60cc20c147f7e10015fd5d05',
        courseCode: 'BUSINESS 4538',
        shortName: 'Business Administration 4538A/B - WOMEN IN LEADERSHIP',
        courseName: 'Business Administration 4538A/B - WOMEN IN LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c147f7e10015fd5d05',
      },
      {
        _id: '60cc20c147f7e10015fd5d07',
        courseCode: 'BUSINESS 4539',
        shortName:
          'Business Administration 4539A/B - BUSINESS STRATEGY & SUSTAINABILITY',
        courseName:
          'Business Administration 4539A/B - BUSINESS STRATEGY & SUSTAINABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c147f7e10015fd5d07',
      },
      {
        _id: '60cc20c147f7e10015fd5d09',
        courseCode: 'BUSINESS 4540',
        shortName:
          'Business Administration 4540A/B - SCHULICH MEDICAL SCHOOL HEALTH CARE MANAGEMENT',
        courseName:
          'Business Administration 4540A/B - SCHULICH MEDICAL SCHOOL HEALTH CARE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c147f7e10015fd5d09',
      },
      {
        _id: '60cc20c247f7e10015fd5d0b',
        courseCode: 'BUSINESS 4542',
        shortName:
          'Business Administration 4542A/B - VENTURING IN INTERNATIONAL FIRMS',
        courseName:
          'Business Administration 4542A/B - VENTURING IN INTERNATIONAL FIRMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c247f7e10015fd5d0b',
      },
      {
        _id: '60cc20c247f7e10015fd5d0d',
        courseCode: 'BUSINESS 4543',
        shortName: 'Business Administration 4543A/B - TECHNOLOGY-INNOVATION',
        courseName: 'Business Administration 4543A/B - TECHNOLOGY-INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c247f7e10015fd5d0d',
      },
      {
        _id: '60cc20c247f7e10015fd5d0f',
        courseCode: 'BUSINESS 4544',
        shortName:
          'Business Administration 4544A/B - MANAGING IN PUBLIC & NFP SECTORS',
        courseName:
          'Business Administration 4544A/B - MANAGING IN PUBLIC & NFP SECTORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c247f7e10015fd5d0f',
      },
      {
        _id: '60cc20c247f7e10015fd5d11',
        courseCode: 'BUSINESS 4545',
        shortName:
          'Business Administration 4545A/B - SPORTS & ENTERTAINMENT ANALYTICS',
        courseName:
          'Business Administration 4545A/B - SPORTS & ENTERTAINMENT ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c247f7e10015fd5d11',
      },
      {
        _id: '60cc20c347f7e10015fd5d13',
        courseCode: 'BUSINESS 4547',
        shortName:
          'Business Administration 4547A/B - HEALTH SECTOR LEADERSHIP & INNOVATION',
        courseName:
          'Business Administration 4547A/B - HEALTH SECTOR LEADERSHIP & INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c347f7e10015fd5d13',
      },
      {
        _id: '60cc20c347f7e10015fd5d15',
        courseCode: 'BUSINESS 4548',
        shortName: 'Business Administration 4548A/B - CONSUMER INSIGHTS',
        courseName: 'Business Administration 4548A/B - CONSUMER INSIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c347f7e10015fd5d15',
      },
      {
        _id: '60cc20c347f7e10015fd5d17',
        courseCode: 'BUSINESS 4552',
        shortName: 'Business Administration 4552A/B - BIOTECH STRATEGY',
        courseName: 'Business Administration 4552A/B - BIOTECH STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c347f7e10015fd5d17',
      },
      {
        _id: '60cc20c347f7e10015fd5d19',
        courseCode: 'BUSINESS 4553',
        shortName: 'Business Administration 4553A/B - SOCIAL ENTERPRISE',
        courseName: 'Business Administration 4553A/B - SOCIAL ENTERPRISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c347f7e10015fd5d19',
      },
      {
        _id: '60cc20c447f7e10015fd5d1b',
        courseCode: 'BUSINESS 4554',
        shortName: 'Business Administration 4554A/B - PRIVATE EQUITY',
        courseName: 'Business Administration 4554A/B - PRIVATE EQUITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c447f7e10015fd5d1b',
      },
      {
        _id: '60cc20c447f7e10015fd5d1d',
        courseCode: 'BUSINESS 4555',
        shortName: 'Business Administration 4555A/B - NEW MEDIA MARKETING',
        courseName: 'Business Administration 4555A/B - NEW MEDIA MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c447f7e10015fd5d1d',
      },
      {
        _id: '60cc20c447f7e10015fd5d1f',
        courseCode: 'BUSINESS 4557',
        shortName:
          'Business Administration 4557A/B - BUSINESS GOVT & GLOBALIZATION',
        courseName:
          'Business Administration 4557A/B - BUSINESS GOVT & GLOBALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c447f7e10015fd5d1f',
      },
      {
        _id: '60cc20c447f7e10015fd5d21',
        courseCode: 'BUSINESS 4558',
        shortName: 'Business Administration 4558A/B - NEW VENTURE CREATION',
        courseName: 'Business Administration 4558A/B - NEW VENTURE CREATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c447f7e10015fd5d21',
      },
      {
        _id: '60cc20c447f7e10015fd5d23',
        courseCode: 'BUSINESS 4559',
        shortName:
          'Business Administration 4559A/B - RAISING CAPITAL IN THE FINANCIAL MARKETS',
        courseName:
          'Business Administration 4559A/B - RAISING CAPITAL IN THE FINANCIAL MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c447f7e10015fd5d23',
      },
      {
        _id: '60cc20c547f7e10015fd5d25',
        courseCode: 'BUSINESS 4560',
        shortName: 'Business Administration 4560A/B - STRATEGIC INVESTING',
        courseName: 'Business Administration 4560A/B - STRATEGIC INVESTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c547f7e10015fd5d25',
      },
      {
        _id: '60cc20c547f7e10015fd5d27',
        courseCode: 'BUSINESS 4564',
        shortName: 'Business Administration 4564A/B - DESIGN DRIVEN INNOVATION',
        courseName:
          'Business Administration 4564A/B - DESIGN DRIVEN INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c547f7e10015fd5d27',
      },
      {
        _id: '60cc20c547f7e10015fd5d29',
        courseCode: 'BUSINESS 4565',
        shortName:
          'Business Administration 4565A/B - APPLIED CORPORATE FINANCE',
        courseName:
          'Business Administration 4565A/B - APPLIED CORPORATE FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c547f7e10015fd5d29',
      },
      {
        _id: '60cc20c547f7e10015fd5d2b',
        courseCode: 'BUSINESS 4566',
        shortName:
          'Business Administration 4566A/B - MANAGING HIGH GROWTH COMPANIES',
        courseName:
          'Business Administration 4566A/B - MANAGING HIGH GROWTH COMPANIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c547f7e10015fd5d2b',
      },
      {
        _id: '60cc20c647f7e10015fd5d2d',
        courseCode: 'BUSINESS 4567',
        shortName: 'Business Administration 4567A/B - WEALTH MANAGEMENT',
        courseName: 'Business Administration 4567A/B - WEALTH MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c647f7e10015fd5d2d',
      },
      {
        _id: '60cc20c647f7e10015fd5d2f',
        courseCode: 'BUSINESS 4568',
        shortName:
          'Business Administration 4568A/B - ADVANCED END USER MODELLING',
        courseName:
          'Business Administration 4568A/B - ADVANCED END USER MODELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c647f7e10015fd5d2f',
      },
      {
        _id: '60cc20c647f7e10015fd5d31',
        courseCode: 'BUSINESS 4569',
        shortName: 'Business Administration 4569 - IVEY FIELD PROJECT',
        courseName: 'Business Administration 4569 - IVEY FIELD PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c647f7e10015fd5d31',
      },
      {
        _id: '60cc20c647f7e10015fd5d33',
        courseCode: 'BUSINESS 4570',
        shortName:
          'Business Administration 4570A/B - DESIGN THINKING FOR SUSTAINABLE INNOVATION',
        courseName:
          'Business Administration 4570A/B - DESIGN THINKING FOR SUSTAINABLE INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c647f7e10015fd5d33',
      },
      {
        _id: '60cc20c747f7e10015fd5d35',
        courseCode: 'BUSINESS 4571',
        shortName:
          'Business Administration 4571A/B - LEADERSHIP UNDER FIRE - DEVELOPING CHARACTER',
        courseName:
          'Business Administration 4571A/B - LEADERSHIP UNDER FIRE - DEVELOPING CHARACTER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c747f7e10015fd5d35',
      },
      {
        _id: '60cc20c747f7e10015fd5d37',
        courseCode: 'BUSINESS 4572',
        shortName: 'Business Administration 4572A/B - RETAILING',
        courseName: 'Business Administration 4572A/B - RETAILING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c747f7e10015fd5d37',
      },
      {
        _id: '60cc20c747f7e10015fd5d39',
        courseCode: 'BUSINESS 4573',
        shortName: 'Business Administration 4573A/B - MEASURABLE MARKETING',
        courseName: 'Business Administration 4573A/B - MEASURABLE MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c747f7e10015fd5d39',
      },
      {
        _id: '60cc20c747f7e10015fd5d3b',
        courseCode: 'BUSINESS 4574',
        shortName: 'Business Administration 4574A/B - MERGERS AND ACQUISITIONS',
        courseName:
          'Business Administration 4574A/B - MERGERS AND ACQUISITIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c747f7e10015fd5d3b',
      },
      {
        _id: '60cc20c747f7e10015fd5d3d',
        courseCode: 'BUSINESS 4579',
        shortName: 'Business Administration 4579A/B - DARK SIDE OF CAPITALISM',
        courseName: 'Business Administration 4579A/B - DARK SIDE OF CAPITALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c747f7e10015fd5d3d',
      },
      {
        _id: '60cc20c847f7e10015fd5d3f',
        courseCode: 'BUSINESS 4580',
        shortName: 'Business Administration 4580A/B - REPUTATION MANAGEMENT',
        courseName: 'Business Administration 4580A/B - REPUTATION MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c847f7e10015fd5d3f',
      },
      {
        _id: '60cc20c847f7e10015fd5d41',
        courseCode: 'BUSINESS 4581',
        shortName: 'Business Administration 4581A/B - BUSINESS ETHICS',
        courseName: 'Business Administration 4581A/B - BUSINESS ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c847f7e10015fd5d41',
      },
      {
        _id: '60cc20c847f7e10015fd5d43',
        courseCode: 'BUSINESS 4582',
        shortName:
          'Business Administration 4582A/B - LNC: BUSINESS & PUBLIC POLICY',
        courseName:
          'Business Administration 4582A/B - LNC: BUSINESS & PUBLIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c847f7e10015fd5d43',
      },
      {
        _id: '60cc20c847f7e10015fd5d45',
        courseCode: 'BUSINESS 4583',
        shortName: 'Business Administration 4583A/B - BEHAVIOURAL FINANCE',
        courseName: 'Business Administration 4583A/B - BEHAVIOURAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c847f7e10015fd5d45',
      },
      {
        _id: '60cc20c947f7e10015fd5d47',
        courseCode: 'BUSINESS 4584',
        shortName:
          'Business Administration 4584Q/R/S/T - GIVING VOICE TO LEADERSHIP',
        courseName:
          'Business Administration 4584Q/R/S/T - GIVING VOICE TO LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c947f7e10015fd5d47',
      },
      {
        _id: '60cc20c947f7e10015fd5d49',
        courseCode: 'BUSINESS 4586',
        shortName:
          'Business Administration 4586A/B - MANAGING INTERNATIONAL LAW',
        courseName:
          'Business Administration 4586A/B - MANAGING INTERNATIONAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c947f7e10015fd5d49',
      },
      {
        _id: '60cc20c947f7e10015fd5d4b',
        courseCode: 'BUSINESS 4587',
        shortName:
          'Business Administration 4587Q/R/S/T - MODELING AND ANALYTICS',
        courseName:
          'Business Administration 4587Q/R/S/T - MODELING AND ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c947f7e10015fd5d4b',
      },
      {
        _id: '60cc20c947f7e10015fd5d4d',
        courseCode: 'BUSINESS 4588',
        shortName:
          'Business Administration 4588A/B - C&S - SUSTAINABLE FINANCE',
        courseName:
          'Business Administration 4588A/B - C&S - SUSTAINABLE FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20c947f7e10015fd5d4d',
      },
      {
        _id: '60cc20ca47f7e10015fd5d4f',
        courseCode: 'BUSINESS 4589',
        shortName: 'Business Administration 4589Q/R/S/T - CREATIVE ANALYTICS',
        courseName: 'Business Administration 4589Q/R/S/T - CREATIVE ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ca47f7e10015fd5d4f',
      },
      {
        _id: '60cc20ca47f7e10015fd5d51',
        courseCode: 'BUSINESS 4590',
        shortName:
          'Business Administration 4590A/B - INTRODUCTORY DATA SCIENCE',
        courseName:
          'Business Administration 4590A/B - INTRODUCTORY DATA SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ca47f7e10015fd5d51',
      },
      {
        _id: '60cc20ca47f7e10015fd5d53',
        courseCode: 'BUSINESS 4591',
        shortName: 'Business Administration 4591A/B - BUSINESS AND MARKETS',
        courseName: 'Business Administration 4591A/B - BUSINESS AND MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ca47f7e10015fd5d53',
      },
      {
        _id: '60cc20ca47f7e10015fd5d55',
        courseCode: 'BUSINESS 4592',
        shortName:
          'Business Administration 4592A/B - SPORTS/ENTERTAINMENT MARKETING',
        courseName:
          'Business Administration 4592A/B - SPORTS/ENTERTAINMENT MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ca47f7e10015fd5d55',
      },
      {
        _id: '60cc20ca47f7e10015fd5d57',
        courseCode: 'BUSINESS 4595',
        shortName:
          'Business Administration 4595A/B - FIXED INCOME MARKETS, SECURITIES & ANALYSIS',
        courseName:
          'Business Administration 4595A/B - FIXED INCOME MARKETS, SECURITIES & ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ca47f7e10015fd5d57',
      },
      {
        _id: '60cc20cb47f7e10015fd5d59',
        courseCode: 'BUSINESS 4596',
        shortName:
          'Business Administration 4596A/B - POWER & POLITICS IN ORGANIZATIONS',
        courseName:
          'Business Administration 4596A/B - POWER & POLITICS IN ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cb47f7e10015fd5d59',
      },
      {
        _id: '60cc20cb47f7e10015fd5d5b',
        courseCode: 'BUSINESS 4597',
        shortName: 'Business Administration 4597A/B - ADV COMPETITIVE STRATEGY',
        courseName:
          'Business Administration 4597A/B - ADV COMPETITIVE STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cb47f7e10015fd5d5b',
      },
      {
        _id: '60cc20cb47f7e10015fd5d5d',
        courseCode: 'BUSINESS 4600',
        shortName:
          'Business Administration 4600A/B - END USER DATABASE MANAGEMENT',
        courseName:
          'Business Administration 4600A/B - END USER DATABASE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cb47f7e10015fd5d5d',
      },
      {
        _id: '60cc20cb47f7e10015fd5d5f',
        courseCode: 'BUSINESS 4601',
        shortName: 'Business Administration 4601A/B - CYBERETHICS',
        courseName: 'Business Administration 4601A/B - CYBERETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cb47f7e10015fd5d5f',
      },
      {
        _id: '60cc20cc47f7e10015fd5d61',
        courseCode: 'BUSINESS 4602',
        shortName:
          'Business Administration 4602A/B - SPECIAL TPCS IN INTL BUSINESS',
        courseName:
          'Business Administration 4602A/B - SPECIAL TPCS IN INTL BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cc47f7e10015fd5d61',
      },
      {
        _id: '60cc20cc47f7e10015fd5d63',
        courseCode: 'BUSINESS 4603',
        shortName: 'Business Administration 4603A/B - DATA MANAGEMENT IN EXCEL',
        courseName:
          'Business Administration 4603A/B - DATA MANAGEMENT IN EXCEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cc47f7e10015fd5d63',
      },
      {
        _id: '60cc20cc47f7e10015fd5d65',
        courseCode: 'BUSINESS 4604',
        shortName:
          'Business Administration 4604A/B - SPECIAL TPCS IN BUSINESS STATS',
        courseName:
          'Business Administration 4604A/B - SPECIAL TPCS IN BUSINESS STATS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cc47f7e10015fd5d65',
      },
      {
        _id: '60cc20cc47f7e10015fd5d67',
        courseCode: 'BUSINESS 4605',
        shortName:
          'Business Administration 4605A/B - SPECIAL TOPICS IN ANALYTICS BEST PRACTICES',
        courseName:
          'Business Administration 4605A/B - SPECIAL TOPICS IN ANALYTICS BEST PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cc47f7e10015fd5d67',
      },
      {
        _id: '60cc20cd47f7e10015fd5d69',
        courseCode: 'BUSINESS 4606',
        shortName:
          'Business Administration 4606A/B - SPECIAL TOPICS IN BIG DATA ANALYTICS',
        courseName:
          'Business Administration 4606A/B - SPECIAL TOPICS IN BIG DATA ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cd47f7e10015fd5d69',
      },
      {
        _id: '60cc20cd47f7e10015fd5d6b',
        courseCode: 'BUSINESS 4607',
        shortName:
          'Business Administration 4607A/B - MICROECONOMICS FOR MANAGERS',
        courseName:
          'Business Administration 4607A/B - MICROECONOMICS FOR MANAGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cd47f7e10015fd5d6b',
      },
      {
        _id: '60cc20cd47f7e10015fd5d6d',
        courseCode: 'BUSINESS 4608',
        shortName:
          'Business Administration 4608A/B - C&S- MANAGIN ENERGY, ENVIRONMENT & NATURAL ENVIRONMENT',
        courseName:
          'Business Administration 4608A/B - C&S- MANAGIN ENERGY, ENVIRONMENT & NATURAL ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cd47f7e10015fd5d6d',
      },
      {
        _id: '60cc20cd47f7e10015fd5d6f',
        courseCode: 'BUSINESS 4609',
        shortName: 'Business Administration 4609A/B - PUBLIC POLICY ANALYTICS',
        courseName: 'Business Administration 4609A/B - PUBLIC POLICY ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cd47f7e10015fd5d6f',
      },
      {
        _id: '60cc20ce47f7e10015fd5d71',
        courseCode: 'BUSINESS 4610',
        shortName: 'Business Administration 4610A/B - LEADING FAMILY FIRMS',
        courseName: 'Business Administration 4610A/B - LEADING FAMILY FIRMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ce47f7e10015fd5d71',
      },
      {
        _id: '60cc20ce47f7e10015fd5d73',
        courseCode: 'BUSINESS 4611',
        shortName:
          'Business Administration 4611A/B - START-UPS: TRICKY ISSUES, HUSTLE & GRIT',
        courseName:
          'Business Administration 4611A/B - START-UPS: TRICKY ISSUES, HUSTLE & GRIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ce47f7e10015fd5d73',
      },
      {
        _id: '60cc20ce47f7e10015fd5d75',
        courseCode: 'BUSINESS 4612',
        shortName:
          'Business Administration 4612A/B - CONTEMPORARY ISSUES IN VALUE INVESTING',
        courseName:
          'Business Administration 4612A/B - CONTEMPORARY ISSUES IN VALUE INVESTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ce47f7e10015fd5d75',
      },
      {
        _id: '60cc20ce47f7e10015fd5d77',
        courseCode: 'BUSINESS 4613',
        shortName:
          'Business Administration 4613A/B - FUNDAMENTALS OF  COMMERCIAL REAL ESTATE',
        courseName:
          'Business Administration 4613A/B - FUNDAMENTALS OF  COMMERCIAL REAL ESTATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ce47f7e10015fd5d77',
      },
      {
        _id: '60cc20cf47f7e10015fd5d79',
        courseCode: 'BUSINESS 4614',
        shortName:
          'Business Administration 4614A/B - SOCIAL MEDIA ANALYTICS & DIGITAL MARKETING',
        courseName:
          'Business Administration 4614A/B - SOCIAL MEDIA ANALYTICS & DIGITAL MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cf47f7e10015fd5d79',
      },
      {
        _id: '60cc20cf47f7e10015fd5d7b',
        courseCode: 'BUSINESS 4616',
        shortName:
          'Business Administration 4616A/B - INNOVATION, ENTREPRENEURSHIP, AND ECONOMIC GROWTH IN ISRAEL',
        courseName:
          'Business Administration 4616A/B - INNOVATION, ENTREPRENEURSHIP, AND ECONOMIC GROWTH IN ISRAEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cf47f7e10015fd5d7b',
      },
      {
        _id: '60cc20cf47f7e10015fd5d7e',
        courseCode: 'BUSINESS 4618',
        shortName:
          'Business Administration 4618A/B - DECISION MAKING AND RISK MANAGEMENT',
        courseName:
          'Business Administration 4618A/B - DECISION MAKING AND RISK MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cf47f7e10015fd5d7e',
      },
      {
        _id: '60cc20cf47f7e10015fd5d80',
        courseCode: 'BUSINESS 4619',
        shortName: 'Business Administration 4619A/B - THE PERFORMING LEADER',
        courseName: 'Business Administration 4619A/B - THE PERFORMING LEADER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20cf47f7e10015fd5d80',
      },
      {
        _id: '60cc20d047f7e10015fd5d82',
        courseCode: 'BUSINESS 4620',
        shortName:
          'Business Administration 4620A/B - ASSESSING THE BROADER IMPACT OF BUSINESS',
        courseName:
          'Business Administration 4620A/B - ASSESSING THE BROADER IMPACT OF BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d047f7e10015fd5d82',
      },
      {
        _id: '60cc20d047f7e10015fd5d84',
        courseCode: 'BUSINESS 4621',
        shortName:
          'Business Administration 4621A/B - MANAGING IN THE CREATIVE ECONOMY',
        courseName:
          'Business Administration 4621A/B - MANAGING IN THE CREATIVE ECONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d047f7e10015fd5d84',
      },
      {
        _id: '60cc20d047f7e10015fd5d86',
        courseCode: 'BUSINESS 4622',
        shortName: 'Business Administration 4622A/B - CORPORATE STRATEGY',
        courseName: 'Business Administration 4622A/B - CORPORATE STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d047f7e10015fd5d86',
      },
      {
        _id: '60cc20d047f7e10015fd5d88',
        courseCode: 'BUSINESS 4623',
        shortName:
          'Business Administration 4623A/B - INTERNATIONAL COLLABORATIVE AGREEMENTS',
        courseName:
          'Business Administration 4623A/B - INTERNATIONAL COLLABORATIVE AGREEMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d047f7e10015fd5d88',
      },
      {
        _id: '60cc20d147f7e10015fd5d8a',
        courseCode: 'BUSINESS 4624',
        shortName:
          'Business Administration 4624A/B - MANAGERIAL ACCOUNTING AND CONTROL',
        courseName:
          'Business Administration 4624A/B - MANAGERIAL ACCOUNTING AND CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d147f7e10015fd5d8a',
      },
      {
        _id: '60cc20d147f7e10015fd5d8c',
        courseCode: 'BUSINESS 4625',
        shortName:
          'Business Administration 4625A/B - C&S - DEVELOPING MORE SUSTAINABLE SUPPLY CHAINS',
        courseName:
          'Business Administration 4625A/B - C&S - DEVELOPING MORE SUSTAINABLE SUPPLY CHAINS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d147f7e10015fd5d8c',
      },
      {
        _id: '60cc20d147f7e10015fd5d8e',
        courseCode: 'BUSINESS 4626',
        shortName:
          'Business Administration 4626A/B - SPECIAL TOPICS IN CROSS CULTURAL MANAGEMENT',
        courseName:
          'Business Administration 4626A/B - SPECIAL TOPICS IN CROSS CULTURAL MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d147f7e10015fd5d8e',
      },
      {
        _id: '60cc20d147f7e10015fd5d90',
        courseCode: 'BUSINESS 4627',
        shortName:
          'Business Administration 4627A/B - SPECIAL TOPICS IN PROGRAMMING SKILLS 1',
        courseName:
          'Business Administration 4627A/B - SPECIAL TOPICS IN PROGRAMMING SKILLS 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d147f7e10015fd5d90',
      },
      {
        _id: '60cc20d247f7e10015fd5d92',
        courseCode: 'BUSINESS 4628',
        shortName:
          'Business Administration 4628A/B - FINTECH AND THE DISRUPTION OF BANKING',
        courseName:
          'Business Administration 4628A/B - FINTECH AND THE DISRUPTION OF BANKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d247f7e10015fd5d92',
      },
      {
        _id: '60cc20d247f7e10015fd5d94',
        courseCode: 'BUSINESS 4629',
        shortName: 'Business Administration 4629A/B - SALES FOUNDATIONS',
        courseName: 'Business Administration 4629A/B - SALES FOUNDATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d247f7e10015fd5d94',
      },
      {
        _id: '60cc20d247f7e10015fd5d96',
        courseCode: 'BUSINESS 4630',
        shortName:
          'Business Administration 4630A/B - SPECIAL TOPICS IN DIGITAL TRANSFORMATION',
        courseName:
          'Business Administration 4630A/B - SPECIAL TOPICS IN DIGITAL TRANSFORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d247f7e10015fd5d96',
      },
      {
        _id: '60cc20d247f7e10015fd5d98',
        courseCode: 'BUSINESS 4631',
        shortName:
          'Business Administration 4631A/B - SPECIAL TOPICS IN INNOVATION I',
        courseName:
          'Business Administration 4631A/B - SPECIAL TOPICS IN INNOVATION I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d247f7e10015fd5d98',
      },
      {
        _id: '60cc20d347f7e10015fd5d9a',
        courseCode: 'BUSINESS 4632',
        shortName:
          'Business Administration 4632A/B - SPECIAL TOPICS IN CAUSAL INFERENCE',
        courseName:
          'Business Administration 4632A/B - SPECIAL TOPICS IN CAUSAL INFERENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d347f7e10015fd5d9a',
      },
      {
        _id: '60cc20d347f7e10015fd5d9c',
        courseCode: 'BUSINESS 4633',
        shortName:
          'Business Administration 4633A/B - SPECIAL TOPICS IN DESIGN DRIVEN INNOVATION',
        courseName:
          'Business Administration 4633A/B - SPECIAL TOPICS IN DESIGN DRIVEN INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d347f7e10015fd5d9c',
      },
      {
        _id: '60cc20d347f7e10015fd5d9e',
        courseCode: 'BUSINESS 4634',
        shortName:
          'Business Administration 4634A/B - SPECIAL TOPICS IN COMPETING IN AND WITH CHINA',
        courseName:
          'Business Administration 4634A/B - SPECIAL TOPICS IN COMPETING IN AND WITH CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d347f7e10015fd5d9e',
      },
      {
        _id: '60cc20d347f7e10015fd5da0',
        courseCode: 'BUSINESS 4635',
        shortName:
          'Business Administration 4635A/B - SIMULATION AND RISK ANALYSIS',
        courseName:
          'Business Administration 4635A/B - SIMULATION AND RISK ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d347f7e10015fd5da0',
      },
      {
        _id: '60cc20d347f7e10015fd5da2',
        courseCode: 'BUSINESS 4636',
        shortName: 'Business Administration 4636A/B - PROGRAMMING SKILLS 2',
        courseName: 'Business Administration 4636A/B - PROGRAMMING SKILLS 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d347f7e10015fd5da2',
      },
      {
        _id: '60cc20d447f7e10015fd5da4',
        courseCode: 'BUSINESS 4637',
        shortName:
          'Business Administration 4637A/B - ENTREPRENEURSHIP AND NEW VENTURE',
        courseName:
          'Business Administration 4637A/B - ENTREPRENEURSHIP AND NEW VENTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d447f7e10015fd5da4',
      },
      {
        _id: '60cc20d447f7e10015fd5da6',
        courseCode: 'BUSINESS 4638',
        shortName:
          'Business Administration 4638A/B - DIGITAL PLATFORM INNOVATION',
        courseName:
          'Business Administration 4638A/B - DIGITAL PLATFORM INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d447f7e10015fd5da6',
      },
      {
        _id: '60cc20d447f7e10015fd5da8',
        courseCode: 'BUSINESS 4639',
        shortName: 'Business Administration 4639A/B - INNOVATION 2',
        courseName: 'Business Administration 4639A/B - INNOVATION 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d447f7e10015fd5da8',
      },
      {
        _id: '60cc20d447f7e10015fd5daa',
        courseCode: 'BUSINESS 4640',
        shortName:
          'Business Administration 4640A/B - TOPICS IN DATA MANAGEMENT',
        courseName:
          'Business Administration 4640A/B - TOPICS IN DATA MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d447f7e10015fd5daa',
      },
      {
        _id: '60cc20d547f7e10015fd5dac',
        courseCode: 'BUSINESS 4641',
        shortName:
          'Business Administration 4641A/B - MANAGING RISK IN ORGANIZATIONS',
        courseName:
          'Business Administration 4641A/B - MANAGING RISK IN ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d547f7e10015fd5dac',
      },
      {
        _id: '60cc20d547f7e10015fd5dae',
        courseCode: 'BUSINESS 4642',
        shortName: 'Business Administration 4642A/B - FRONTIER MARKETS',
        courseName: 'Business Administration 4642A/B - FRONTIER MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d547f7e10015fd5dae',
      },
      {
        _id: '60cc20d547f7e10015fd5db0',
        courseCode: 'BUSINESS 4643',
        shortName: 'Business Administration 4643A/B - GLOBAL FINANCIAL MARKETS',
        courseName:
          'Business Administration 4643A/B - GLOBAL FINANCIAL MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d547f7e10015fd5db0',
      },
      {
        _id: '60cc20d547f7e10015fd5db2',
        courseCode: 'BUSINESS 4644',
        shortName: 'Business Administration 4644A/B - LEADING RESPONSIBLY',
        courseName: 'Business Administration 4644A/B - LEADING RESPONSIBLY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d547f7e10015fd5db2',
      },
      {
        _id: '60cc20d547f7e10015fd5db4',
        courseCode: 'BUSINESS 4645',
        shortName:
          'Business Administration 4645A/B - PRICING AND REVENUE ANALYTICS',
        courseName:
          'Business Administration 4645A/B - PRICING AND REVENUE ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d547f7e10015fd5db4',
      },
      {
        _id: '60cc20d647f7e10015fd5db6',
        courseCode: 'BUSINESS 4646',
        shortName: 'Business Administration 4646A/B - SYSTEMS THINKING',
        courseName: 'Business Administration 4646A/B - SYSTEMS THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d647f7e10015fd5db6',
      },
      {
        _id: '60cc20d647f7e10015fd5db8',
        courseCode: 'CALCULUS 1000',
        shortName: 'Calculus 1000A/B - CALCULUS I',
        courseName: 'Calculus 1000A/B - CALCULUS I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 17,
        id: '60cc20d647f7e10015fd5db8',
      },
      {
        _id: '60cc20d747f7e10015fd5dba',
        courseCode: 'CALCULUS 1301',
        shortName: 'Calculus 1301A/B - CALCULUS II',
        courseName: 'Calculus 1301A/B - CALCULUS II',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 2,
        id: '60cc20d747f7e10015fd5dba',
      },
      {
        _id: '60cc20d747f7e10015fd5dbc',
        courseCode: 'CALCULUS 1500',
        shortName:
          'Calculus 1500A/B - CALCULUS I FOR THE MATHEMATICAL SCIENCES',
        courseName:
          'Calculus 1500A/B - CALCULUS I FOR THE MATHEMATICAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d747f7e10015fd5dbc',
      },
      {
        _id: '60cc20d747f7e10015fd5dbe',
        courseCode: 'CALCULUS 1501',
        shortName:
          'Calculus 1501A/B - CALCULUS II FOR MATHEMATICAL AND PHYSICAL SCIENCES',
        courseName:
          'Calculus 1501A/B - CALCULUS II FOR MATHEMATICAL AND PHYSICAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d747f7e10015fd5dbe',
      },
      {
        _id: '60cc20d847f7e10015fd5dc0',
        courseCode: 'CALCULUS 2302',
        shortName: 'Calculus 2302A/B - INTERMEDIATE CALCULUS I',
        courseName: 'Calculus 2302A/B - INTERMEDIATE CALCULUS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d847f7e10015fd5dc0',
      },
      {
        _id: '60cc20d847f7e10015fd5dc2',
        courseCode: 'CALCULUS 2303',
        shortName: 'Calculus 2303A/B - INTERMEDIATE CALCULUS II',
        courseName: 'Calculus 2303A/B - INTERMEDIATE CALCULUS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d847f7e10015fd5dc2',
      },
      {
        _id: '60cc20d847f7e10015fd5dc4',
        courseCode: 'CALCULUS 2402',
        shortName: 'Calculus 2402A/B - CALCULUS WITH ANALYSIS FOR STATISTICS',
        courseName: 'Calculus 2402A/B - CALCULUS WITH ANALYSIS FOR STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d847f7e10015fd5dc4',
      },
      {
        _id: '60cc20d947f7e10015fd5dc6',
        courseCode: 'CALCULUS 2502',
        shortName: 'Calculus 2502A/B - ADVANCED CALCULUS I',
        courseName: 'Calculus 2502A/B - ADVANCED CALCULUS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d947f7e10015fd5dc6',
      },
      {
        _id: '60cc20d947f7e10015fd5dc8',
        courseCode: 'CALCULUS 2503',
        shortName: 'Calculus 2503A/B - ADVANCED CALCULUS II',
        courseName: 'Calculus 2503A/B - ADVANCED CALCULUS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d947f7e10015fd5dc8',
      },
      {
        _id: '60cc20d947f7e10015fd5dca',
        courseCode: 'CANADIAN 2200',
        shortName: 'Canadian Studies 2200E - FIRST NATIONS TO MANY NATIONS',
        courseName: 'Canadian Studies 2200E - FIRST NATIONS TO MANY NATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d947f7e10015fd5dca',
      },
      {
        _id: '60cc20d947f7e10015fd5dcc',
        courseCode: 'CGS 1021',
        shortName:
          'Centre for Global Studies 1021F/G - INTRODUCATION TO GLOBAL CULTURE',
        courseName:
          'Centre for Global Studies 1021F/G - INTRODUCATION TO GLOBAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20d947f7e10015fd5dcc',
      },
      {
        _id: '60cc20da47f7e10015fd5dce',
        courseCode: 'CGS 1022',
        shortName:
          'Centre for Global Studies 1022F/G - INTRODUCTION TO GLOBALIZATION',
        courseName:
          'Centre for Global Studies 1022F/G - INTRODUCTION TO GLOBALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20da47f7e10015fd5dce',
      },
      {
        _id: '60cc20da47f7e10015fd5dd0',
        courseCode: 'CGS 1023',
        shortName:
          'Centre for Global Studies 1023F/G - INTRODUCTION TO GLOBAL DEVELOPMENT',
        courseName:
          'Centre for Global Studies 1023F/G - INTRODUCTION TO GLOBAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20da47f7e10015fd5dd0',
      },
      {
        _id: '60cc20da47f7e10015fd5dd2',
        courseCode: 'CGS 2001',
        shortName:
          'Centre for Global Studies 2001F/G - INTRODUCTION TO GLOBAL DEVELOPMENT',
        courseName:
          'Centre for Global Studies 2001F/G - INTRODUCTION TO GLOBAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20da47f7e10015fd5dd2',
      },
      {
        _id: '60cc20da47f7e10015fd5dd4',
        courseCode: 'CGS 2002',
        shortName:
          'Centre for Global Studies 2002F/G - PROBLEMS OF GLOBAL DEVELOPMENT',
        courseName:
          'Centre for Global Studies 2002F/G - PROBLEMS OF GLOBAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20da47f7e10015fd5dd4',
      },
      {
        _id: '60cc20db47f7e10015fd5dd6',
        courseCode: 'CGS 2003',
        shortName:
          'Centre for Global Studies 2003F/G - DISCOURSES OF GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 2003F/G - DISCOURSES OF GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20db47f7e10015fd5dd6',
      },
      {
        _id: '60cc20db47f7e10015fd5dd8',
        courseCode: 'CGS 2004',
        shortName: 'Centre for Global Studies 2004F/G - CRITIQUE OF CAPITALISM',
        courseName:
          'Centre for Global Studies 2004F/G - CRITIQUE OF CAPITALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20db47f7e10015fd5dd8',
      },
      {
        _id: '60cc20db47f7e10015fd5dda',
        courseCode: 'CGS 3001',
        shortName:
          'Centre for Global Studies 3001F/G - COLLABORATIVE AND PARTICIPATORY METHODOLOGIES',
        courseName:
          'Centre for Global Studies 3001F/G - COLLABORATIVE AND PARTICIPATORY METHODOLOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20db47f7e10015fd5dda',
      },
      {
        _id: '60cc20db47f7e10015fd5ddc',
        courseCode: 'CGS 3005',
        shortName:
          'Centre for Global Studies 3005F/G - THEORISING SUBJECTIVITY AND POWER',
        courseName:
          'Centre for Global Studies 3005F/G - THEORISING SUBJECTIVITY AND POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20db47f7e10015fd5ddc',
      },
      {
        _id: '60cc20db47f7e10015fd5dde',
        courseCode: 'CGS 3006',
        shortName:
          'Centre for Global Studies 3006F/G - CRITICAL & ANTI-OPPRESSIVE METHODOLOGIES',
        courseName:
          'Centre for Global Studies 3006F/G - CRITICAL & ANTI-OPPRESSIVE METHODOLOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20db47f7e10015fd5dde',
      },
      {
        _id: '60cc20dc47f7e10015fd5de0',
        courseCode: 'CGS 3100',
        shortName: 'Centre for Global Studies 3100E - INDEPENDENT RESEARCH',
        courseName: 'Centre for Global Studies 3100E - INDEPENDENT RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dc47f7e10015fd5de0',
      },
      {
        _id: '60cc20dc47f7e10015fd5de2',
        courseCode: 'CGS 3101',
        shortName: 'Centre for Global Studies 3101F/G - DIRECTED STUDIES',
        courseName: 'Centre for Global Studies 3101F/G - DIRECTED STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dc47f7e10015fd5de2',
      },
      {
        _id: '60cc20dc47f7e10015fd5de4',
        courseCode: 'CGS 3201',
        shortName:
          'Centre for Global Studies 3201F/G - THINK GLOBAL, ACT LOCAL',
        courseName:
          'Centre for Global Studies 3201F/G - THINK GLOBAL, ACT LOCAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dc47f7e10015fd5de4',
      },
      {
        _id: '60cc20dd47f7e10015fd5de6',
        courseCode: 'CGS 3202',
        shortName:
          'Centre for Global Studies 3202F/G - COMMUNITY–BASED SEMINAR IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3202F/G - COMMUNITY–BASED SEMINAR IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dd47f7e10015fd5de6',
      },
      {
        _id: '60cc20dd47f7e10015fd5de8',
        courseCode: 'CGS 3203',
        shortName:
          'Centre for Global Studies 3203F/G - GLOBAL STUDIES PARTICIPATORY PROJECT',
        courseName:
          'Centre for Global Studies 3203F/G - GLOBAL STUDIES PARTICIPATORY PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dd47f7e10015fd5de8',
      },
      {
        _id: '60cc20dd47f7e10015fd5dea',
        courseCode: 'CGS 3204',
        shortName:
          'Centre for Global Studies 3204E - GLOBAL STUDIES PARTICIPATORY PROJECT',
        courseName:
          'Centre for Global Studies 3204E - GLOBAL STUDIES PARTICIPATORY PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dd47f7e10015fd5dea',
      },
      {
        _id: '60cc20dd47f7e10015fd5dec',
        courseCode: 'CGS 3205',
        shortName:
          'Centre for Global Studies 3205E - GLOBAL STUDIES INTERNSHIP',
        courseName:
          'Centre for Global Studies 3205E - GLOBAL STUDIES INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20dd47f7e10015fd5dec',
      },
      {
        _id: '60cc20de47f7e10015fd5dee',
        courseCode: 'CGS 3206',
        shortName:
          'Centre for Global Studies 3206F/G - GLOBAL DEVELOPMENT PLACEMENT',
        courseName:
          'Centre for Global Studies 3206F/G - GLOBAL DEVELOPMENT PLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20de47f7e10015fd5dee',
      },
      {
        _id: '60cc20de47f7e10015fd5df0',
        courseCode: 'CGS 3207',
        shortName:
          'Centre for Global Studies 3207E - GLOBAL DEVELOPMENT PLACEMENT',
        courseName:
          'Centre for Global Studies 3207E - GLOBAL DEVELOPMENT PLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20de47f7e10015fd5df0',
      },
      {
        _id: '60cc20de47f7e10015fd5df2',
        courseCode: 'CGS 3509',
        shortName:
          'Centre for Global Studies 3509F/G - INDIGENOUS PEOPLES AND GLOBAL DISPOSSESSION',
        courseName:
          'Centre for Global Studies 3509F/G - INDIGENOUS PEOPLES AND GLOBAL DISPOSSESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20de47f7e10015fd5df2',
      },
      {
        _id: '60cc20de47f7e10015fd5df4',
        courseCode: 'CGS 3512',
        shortName:
          'Centre for Global Studies 3512F/G - ENGAGING GLOBAL HUMAN DISPLACEMENT',
        courseName:
          'Centre for Global Studies 3512F/G - ENGAGING GLOBAL HUMAN DISPLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20de47f7e10015fd5df4',
      },
      {
        _id: '60cc20df47f7e10015fd5df6',
        courseCode: 'CGS 3513',
        shortName:
          'Centre for Global Studies 3513F/G - NON-HEGEMONIC ECONOMIC FORMS AND GLOBAL CAPITALISM',
        courseName:
          'Centre for Global Studies 3513F/G - NON-HEGEMONIC ECONOMIC FORMS AND GLOBAL CAPITALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20df47f7e10015fd5df6',
      },
      {
        _id: '60cc20df47f7e10015fd5df8',
        courseCode: 'CGS 3514',
        shortName:
          'Centre for Global Studies 3514F/G - GLOBAL RESISTANCE MOVEMENTS',
        courseName:
          'Centre for Global Studies 3514F/G - GLOBAL RESISTANCE MOVEMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20df47f7e10015fd5df8',
      },
      {
        _id: '60cc20df47f7e10015fd5dfa',
        courseCode: 'CGS 3515',
        shortName:
          'Centre for Global Studies 3515F/G - GLOBAL CULTURES OF GENDERING AND ORIENTATION',
        courseName:
          'Centre for Global Studies 3515F/G - GLOBAL CULTURES OF GENDERING AND ORIENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20df47f7e10015fd5dfa',
      },
      {
        _id: '60cc20e047f7e10015fd5dfc',
        courseCode: 'CGS 3516',
        shortName:
          'Centre for Global Studies 3516F/G - ECONOMIES OF DEVELOPMENT',
        courseName:
          'Centre for Global Studies 3516F/G - ECONOMIES OF DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e047f7e10015fd5dfc',
      },
      {
        _id: '60cc20e047f7e10015fd5dfe',
        courseCode: 'CGS 3517',
        shortName: 'Centre for Global Studies 3517F/G - DECOLONIALITY',
        courseName: 'Centre for Global Studies 3517F/G - DECOLONIALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e047f7e10015fd5dfe',
      },
      {
        _id: '60cc20e047f7e10015fd5e00',
        courseCode: 'CGS 3518',
        shortName:
          'Centre for Global Studies 3518F/G - NON-STATE PEOPLES AND STATE FORMATIONS',
        courseName:
          'Centre for Global Studies 3518F/G - NON-STATE PEOPLES AND STATE FORMATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e047f7e10015fd5e00',
      },
      {
        _id: '60cc20e047f7e10015fd5e02',
        courseCode: 'CGS 3519',
        shortName:
          'Centre for Global Studies 3519F/G - GLOBAL INEQUALITIES BASED ON SEXUAL DIFFERENCES',
        courseName:
          'Centre for Global Studies 3519F/G - GLOBAL INEQUALITIES BASED ON SEXUAL DIFFERENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e047f7e10015fd5e02',
      },
      {
        _id: '60cc20e147f7e10015fd5e04',
        courseCode: 'CGS 3520',
        shortName:
          'Centre for Global Studies 3520F/G - OVERCOMING MANAGEMENT PARADIGMS IN GLOBAL DEVELOPEMENT',
        courseName:
          'Centre for Global Studies 3520F/G - OVERCOMING MANAGEMENT PARADIGMS IN GLOBAL DEVELOPEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e147f7e10015fd5e04',
      },
      {
        _id: '60cc20e147f7e10015fd5e06',
        courseCode: 'CGS 3521',
        shortName:
          'Centre for Global Studies 3521F/G - NON-STATE SPACES IN TRANSITION',
        courseName:
          'Centre for Global Studies 3521F/G - NON-STATE SPACES IN TRANSITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e147f7e10015fd5e06',
      },
      {
        _id: '60cc20e147f7e10015fd5e08',
        courseCode: 'CGS 3522',
        shortName: 'Centre for Global Studies 3522F/G - GLOBAL MOBILITIES',
        courseName: 'Centre for Global Studies 3522F/G - GLOBAL MOBILITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e147f7e10015fd5e08',
      },
      {
        _id: '60cc20e147f7e10015fd5e0a',
        courseCode: 'CGS 3523',
        shortName:
          'Centre for Global Studies 3523F/G - LAW IN GLOBAL RELATIONS AND LANGUAGE OF POWER',
        courseName:
          'Centre for Global Studies 3523F/G - LAW IN GLOBAL RELATIONS AND LANGUAGE OF POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e147f7e10015fd5e0a',
      },
      {
        _id: '60cc20e247f7e10015fd5e0c',
        courseCode: 'CGS 3524',
        shortName:
          'Centre for Global Studies 3524F/G - POSTCOLONIAL GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3524F/G - POSTCOLONIAL GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e247f7e10015fd5e0c',
      },
      {
        _id: '60cc20e247f7e10015fd5e0e',
        courseCode: 'CGS 3525',
        shortName:
          'Centre for Global Studies 3525F/G - COMMUNITY IN GLOBAL MODERNITY',
        courseName:
          'Centre for Global Studies 3525F/G - COMMUNITY IN GLOBAL MODERNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e247f7e10015fd5e0e',
      },
      {
        _id: '60cc20e247f7e10015fd5e11',
        courseCode: 'CGS 3527',
        shortName:
          'Centre for Global Studies 3527F/G - GLOBALIZED CAPITALIST AGRICULTURE',
        courseName:
          'Centre for Global Studies 3527F/G - GLOBALIZED CAPITALIST AGRICULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e247f7e10015fd5e11',
      },
      {
        _id: '60cc20e347f7e10015fd5e13',
        courseCode: 'CGS 3528',
        shortName:
          'Centre for Global Studies 3528F/G - NON-HEGEMONIC FOOD ECONOMIES',
        courseName:
          'Centre for Global Studies 3528F/G - NON-HEGEMONIC FOOD ECONOMIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e347f7e10015fd5e13',
      },
      {
        _id: '60cc20e347f7e10015fd5e15',
        courseCode: 'CGS 3529',
        shortName: 'Centre for Global Studies 3529F/G - GLOBAL GOVERNMENTALITY',
        courseName:
          'Centre for Global Studies 3529F/G - GLOBAL GOVERNMENTALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e347f7e10015fd5e15',
      },
      {
        _id: '60cc20e347f7e10015fd5e17',
        courseCode: 'CGS 3530',
        shortName: 'Centre for Global Studies 3530F/G - UNGOVERNABLE LIFE',
        courseName: 'Centre for Global Studies 3530F/G - UNGOVERNABLE LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e347f7e10015fd5e17',
      },
      {
        _id: '60cc20e347f7e10015fd5e19',
        courseCode: 'CGS 3531',
        shortName: 'Centre for Global Studies 3531F/G - GLOBAL ENERGY REGIMES',
        courseName: 'Centre for Global Studies 3531F/G - GLOBAL ENERGY REGIMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e347f7e10015fd5e19',
      },
      {
        _id: '60cc20e447f7e10015fd5e1b',
        courseCode: 'CGS 3990',
        shortName:
          'Centre for Global Studies 3990F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3990F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e447f7e10015fd5e1b',
      },
      {
        _id: '60cc20e447f7e10015fd5e1d',
        courseCode: 'CGS 3991',
        shortName:
          'Centre for Global Studies 3991F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3991F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e447f7e10015fd5e1d',
      },
      {
        _id: '60cc20e447f7e10015fd5e1f',
        courseCode: 'CGS 3992',
        shortName:
          'Centre for Global Studies 3992F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3992F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e447f7e10015fd5e1f',
      },
      {
        _id: '60cc20e547f7e10015fd5e21',
        courseCode: 'CGS 3993',
        shortName:
          'Centre for Global Studies 3993F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3993F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e547f7e10015fd5e21',
      },
      {
        _id: '60cc20e547f7e10015fd5e23',
        courseCode: 'CGS 3994',
        shortName:
          'Centre for Global Studies 3994F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3994F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e547f7e10015fd5e23',
      },
      {
        _id: '60cc20e547f7e10015fd5e25',
        courseCode: 'CGS 3995',
        shortName:
          'Centre for Global Studies 3995F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3995F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e547f7e10015fd5e25',
      },
      {
        _id: '60cc20e547f7e10015fd5e27',
        courseCode: 'CGS 3996',
        shortName:
          'Centre for Global Studies 3996F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3996F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e547f7e10015fd5e27',
      },
      {
        _id: '60cc20e647f7e10015fd5e29',
        courseCode: 'CGS 3997',
        shortName:
          'Centre for Global Studies 3997F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3997F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e647f7e10015fd5e29',
      },
      {
        _id: '60cc20e647f7e10015fd5e2b',
        courseCode: 'CGS 3998',
        shortName:
          'Centre for Global Studies 3998F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3998F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e647f7e10015fd5e2b',
      },
      {
        _id: '60cc20e647f7e10015fd5e2d',
        courseCode: 'CGS 3999',
        shortName:
          'Centre for Global Studies 3999F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 3999F/G - SELECTED TOPICS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e647f7e10015fd5e2d',
      },
      {
        _id: '60cc20e647f7e10015fd5e2f',
        courseCode: 'CGS 4010',
        shortName:
          'Centre for Global Studies 4010F/G - HONOURS SEMINAR: POVERTY',
        courseName:
          'Centre for Global Studies 4010F/G - HONOURS SEMINAR: POVERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e647f7e10015fd5e2f',
      },
      {
        _id: '60cc20e747f7e10015fd5e31',
        courseCode: 'CGS 4011',
        shortName:
          'Centre for Global Studies 4011F/G - HONOURS SEMINAR: IDENTITY/DIFFERENCE',
        courseName:
          'Centre for Global Studies 4011F/G - HONOURS SEMINAR: IDENTITY/DIFFERENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e747f7e10015fd5e31',
      },
      {
        _id: '60cc20e747f7e10015fd5e33',
        courseCode: 'CGS 4012',
        shortName:
          'Centre for Global Studies 4012F/G - HONOURS SEMINAR: ETHICS AND RIGHTS',
        courseName:
          'Centre for Global Studies 4012F/G - HONOURS SEMINAR: ETHICS AND RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e747f7e10015fd5e33',
      },
      {
        _id: '60cc20e747f7e10015fd5e35',
        courseCode: 'CGS 4013',
        shortName:
          'Centre for Global Studies 4013F/G - HONOURS SEMINAR: PLACE AND MOVEMENT',
        courseName:
          'Centre for Global Studies 4013F/G - HONOURS SEMINAR: PLACE AND MOVEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e747f7e10015fd5e35',
      },
      {
        _id: '60cc20e747f7e10015fd5e37',
        courseCode: 'CGS 4014',
        shortName:
          'Centre for Global Studies 4014F/G - HONOURS SEMINAR: GENDER',
        courseName:
          'Centre for Global Studies 4014F/G - HONOURS SEMINAR: GENDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e747f7e10015fd5e37',
      },
      {
        _id: '60cc20e847f7e10015fd5e39',
        courseCode: 'CGS 4015',
        shortName:
          'Centre for Global Studies 4015F/G - HONOURS SEMINAR: POWER AND RESISTANCE',
        courseName:
          'Centre for Global Studies 4015F/G - HONOURS SEMINAR: POWER AND RESISTANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e847f7e10015fd5e39',
      },
      {
        _id: '60cc20e847f7e10015fd5e3b',
        courseCode: 'CGS 4016',
        shortName:
          'Centre for Global Studies 4016F/G - HONOURS SEMINAR: GLOBALIZATION',
        courseName:
          'Centre for Global Studies 4016F/G - HONOURS SEMINAR: GLOBALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e847f7e10015fd5e3b',
      },
      {
        _id: '60cc20e847f7e10015fd5e3d',
        courseCode: 'CGS 4017',
        shortName:
          'Centre for Global Studies 4017F/G - HONOURS SEMINAR: NARRATING CULTURE',
        courseName:
          'Centre for Global Studies 4017F/G - HONOURS SEMINAR: NARRATING CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e847f7e10015fd5e3d',
      },
      {
        _id: '60cc20e947f7e10015fd5e3f',
        courseCode: 'CGS 4018',
        shortName:
          'Centre for Global Studies 4018F/G - HONOURS SEMINAR: POSTCOLONIAL CRITIQUE',
        courseName:
          'Centre for Global Studies 4018F/G - HONOURS SEMINAR: POSTCOLONIAL CRITIQUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e947f7e10015fd5e3f',
      },
      {
        _id: '60cc20e947f7e10015fd5e41',
        courseCode: 'CGS 4020',
        shortName:
          'Centre for Global Studies 4020F/G - SELECTED TOPICS IN HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4020F/G - SELECTED TOPICS IN HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e947f7e10015fd5e41',
      },
      {
        _id: '60cc20e947f7e10015fd5e43',
        courseCode: 'CGS 4021',
        shortName:
          'Centre for Global Studies 4021F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4021F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e947f7e10015fd5e43',
      },
      {
        _id: '60cc20e947f7e10015fd5e45',
        courseCode: 'CGS 4022',
        shortName:
          'Centre for Global Studies 4022F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4022F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20e947f7e10015fd5e45',
      },
      {
        _id: '60cc20ea47f7e10015fd5e47',
        courseCode: 'CGS 4023',
        shortName:
          'Centre for Global Studies 4023F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4023F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ea47f7e10015fd5e47',
      },
      {
        _id: '60cc20ea47f7e10015fd5e49',
        courseCode: 'CGS 4024',
        shortName:
          'Centre for Global Studies 4024F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4024F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ea47f7e10015fd5e49',
      },
      {
        _id: '60cc20ea47f7e10015fd5e4b',
        courseCode: 'CGS 4025',
        shortName:
          'Centre for Global Studies 4025F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4025F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ea47f7e10015fd5e4b',
      },
      {
        _id: '60cc20ea47f7e10015fd5e4d',
        courseCode: 'CGS 4026',
        shortName:
          'Centre for Global Studies 4026F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4026F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ea47f7e10015fd5e4d',
      },
      {
        _id: '60cc20eb47f7e10015fd5e4f',
        courseCode: 'CGS 4027',
        shortName:
          'Centre for Global Studies 4027F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4027F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20eb47f7e10015fd5e4f',
      },
      {
        _id: '60cc20eb47f7e10015fd5e51',
        courseCode: 'CGS 4028',
        shortName:
          'Centre for Global Studies 4028F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4028F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20eb47f7e10015fd5e51',
      },
      {
        _id: '60cc20eb47f7e10015fd5e53',
        courseCode: 'CGS 4029',
        shortName:
          'Centre for Global Studies 4029F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        courseName:
          'Centre for Global Studies 4029F/G - SELECTED TOPICS HONOURS SEMINARS IN GLOBAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20eb47f7e10015fd5e53',
      },
      {
        _id: '60cc20eb47f7e10015fd5e55',
        courseCode: 'CGS 4500',
        shortName: 'Centre for Global Studies 4500E - HONOURS THESIS',
        courseName: 'Centre for Global Studies 4500E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20eb47f7e10015fd5e55',
      },
      {
        _id: '60cc20ec47f7e10015fd5e57',
        courseCode: 'CHEMBIO 4500',
        shortName:
          'Chemical Biology 4500E - RESEARCH PROJECT IN CHEMICAL BIOLOGY',
        courseName:
          'Chemical Biology 4500E - RESEARCH PROJECT IN CHEMICAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ec47f7e10015fd5e57',
      },
      {
        _id: '60cc20ec47f7e10015fd5e59',
        courseCode: 'CBE 2206',
        shortName:
          'Chemical and Biochemical Engineering 2206A/B - INTRODUCTORY INDUSTRIAL ORGANIC CHEMISTRY',
        courseName:
          'Chemical and Biochemical Engineering 2206A/B - INTRODUCTORY INDUSTRIAL ORGANIC CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ec47f7e10015fd5e59',
      },
      {
        _id: '60cc20ec47f7e10015fd5e5b',
        courseCode: 'CBE 2207',
        shortName:
          'Chemical and Biochemical Engineering 2207A/B - APPLIED INDUSTRIAL ORGANIC CHEMISTRY',
        courseName:
          'Chemical and Biochemical Engineering 2207A/B - APPLIED INDUSTRIAL ORGANIC CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ec47f7e10015fd5e5b',
      },
      {
        _id: '60cc20ed47f7e10015fd5e5d',
        courseCode: 'CBE 2214',
        shortName:
          'Chemical and Biochemical Engineering 2214A/B - ENGINEERING THERMODYNAMICS',
        courseName:
          'Chemical and Biochemical Engineering 2214A/B - ENGINEERING THERMODYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 8,
        id: '60cc20ed47f7e10015fd5e5d',
      },
      {
        _id: '60cc20ed47f7e10015fd5e5f',
        courseCode: 'CBE 2220',
        shortName:
          'Chemical and Biochemical Engineering 2220A/B - CHEMICAL PROCESS CALCULATIONS',
        courseName:
          'Chemical and Biochemical Engineering 2220A/B - CHEMICAL PROCESS CALCULATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ed47f7e10015fd5e5f',
      },
      {
        _id: '60cc20ed47f7e10015fd5e61',
        courseCode: 'CBE 2221',
        shortName: 'Chemical and Biochemical Engineering 2221A/B - FLUID FLOW',
        courseName: 'Chemical and Biochemical Engineering 2221A/B - FLUID FLOW',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 2,
        id: '60cc20ed47f7e10015fd5e61',
      },
      {
        _id: '60cc20ee47f7e10015fd5e63',
        courseCode: 'CBE 2224',
        shortName:
          'Chemical and Biochemical Engineering 2224A/B - CHEMICAL ENGINEERING THERMODYNAMICS',
        courseName:
          'Chemical and Biochemical Engineering 2224A/B - CHEMICAL ENGINEERING THERMODYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ee47f7e10015fd5e63',
      },
      {
        _id: '60cc20ee47f7e10015fd5e66',
        courseCode: 'CBE 2291',
        shortName:
          'Chemical and Biochemical Engineering 2291A/B - COMPUTATIONAL METHODS FOR ENGINEERS',
        courseName:
          'Chemical and Biochemical Engineering 2291A/B - COMPUTATIONAL METHODS FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 36,
        id: '60cc20ee47f7e10015fd5e66',
      },
      {
        _id: '60cc20ee47f7e10015fd5e68',
        courseCode: 'CBE 3301',
        shortName:
          'Chemical and Biochemical Engineering 3301A/B - BIOCHEMICAL REACTION ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 3301A/B - BIOCHEMICAL REACTION ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ee47f7e10015fd5e68',
      },
      {
        _id: '60cc20ef47f7e10015fd5e6a',
        courseCode: 'CBE 3307',
        shortName:
          'Chemical and Biochemical Engineering 3307A/B - ENERGY AND ENVIRONMENT',
        courseName:
          'Chemical and Biochemical Engineering 3307A/B - ENERGY AND ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ef47f7e10015fd5e6a',
      },
      {
        _id: '60cc20ef47f7e10015fd5e6c',
        courseCode: 'CBE 3310',
        shortName:
          'Chemical and Biochemical Engineering 3310A/B - PROCESS DYNAMICS AND CONTROL',
        courseName:
          'Chemical and Biochemical Engineering 3310A/B - PROCESS DYNAMICS AND CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ef47f7e10015fd5e6c',
      },
      {
        _id: '60cc20ef47f7e10015fd5e6e',
        courseCode: 'CBE 3315',
        shortName:
          'Chemical and Biochemical Engineering 3315A/B - REACTION ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 3315A/B - REACTION ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ef47f7e10015fd5e6e',
      },
      {
        _id: '60cc20ef47f7e10015fd5e71',
        courseCode: 'CBE 3318',
        shortName:
          'Chemical and Biochemical Engineering 3318A/B - INTRODUCTION TO CHEMICAL PROCESS SIMULATION',
        courseName:
          'Chemical and Biochemical Engineering 3318A/B - INTRODUCTION TO CHEMICAL PROCESS SIMULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ef47f7e10015fd5e71',
      },
      {
        _id: '60cc20f047f7e10015fd5e73',
        courseCode: 'CBE 3319',
        shortName:
          'Chemical and Biochemical Engineering 3319A/B - INTRODUCTION TO PLANT DESIGN & SAFETY',
        courseName:
          'Chemical and Biochemical Engineering 3319A/B - INTRODUCTION TO PLANT DESIGN & SAFETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f047f7e10015fd5e73',
      },
      {
        _id: '60cc20f047f7e10015fd5e75',
        courseCode: 'CBE 3320',
        shortName:
          'Chemical and Biochemical Engineering 3320A/B - BIOPROCESS ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 3320A/B - BIOPROCESS ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f047f7e10015fd5e75',
      },
      {
        _id: '60cc20f047f7e10015fd5e77',
        courseCode: 'CBE 3322',
        shortName:
          'Chemical and Biochemical Engineering 3322A/B - HEAT TRANSFER OPERATIONS',
        courseName:
          'Chemical and Biochemical Engineering 3322A/B - HEAT TRANSFER OPERATIONS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 4,
        id: '60cc20f047f7e10015fd5e77',
      },
      {
        _id: '60cc20f047f7e10015fd5e79',
        courseCode: 'CBE 3323',
        shortName:
          'Chemical and Biochemical Engineering 3323A/B - STAGED OPERATIONS',
        courseName:
          'Chemical and Biochemical Engineering 3323A/B - STAGED OPERATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f047f7e10015fd5e79',
      },
      {
        _id: '60cc20f147f7e10015fd5e7b',
        courseCode: 'CBE 3324',
        shortName:
          'Chemical and Biochemical Engineering 3324A/B - MASS TRANSFER OPERATIONS',
        courseName:
          'Chemical and Biochemical Engineering 3324A/B - MASS TRANSFER OPERATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f147f7e10015fd5e7b',
      },
      {
        _id: '60cc20f147f7e10015fd5e7d',
        courseCode: 'CBE 3325',
        shortName:
          'Chemical and Biochemical Engineering 3325A/B - PARTICULATE OPERATIONS',
        courseName:
          'Chemical and Biochemical Engineering 3325A/B - PARTICULATE OPERATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f147f7e10015fd5e7d',
      },
      {
        _id: '60cc20f147f7e10015fd5e7f',
        courseCode: 'CBE 3326',
        shortName:
          'Chemical and Biochemical Engineering 3326A/B/Y - PROCESS ENGINEERING APPLICATIONS',
        courseName:
          'Chemical and Biochemical Engineering 3326A/B/Y - PROCESS ENGINEERING APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f147f7e10015fd5e7f',
      },
      {
        _id: '60cc20f147f7e10015fd5e81',
        courseCode: 'CBE 3330',
        shortName:
          'Chemical and Biochemical Engineering 3330A/B - BIOREACTION & BIOPROCESS ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 3330A/B - BIOREACTION & BIOPROCESS ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f147f7e10015fd5e81',
      },
      {
        _id: '60cc20f247f7e10015fd5e83',
        courseCode: 'CBE 3395',
        shortName:
          'Chemical and Biochemical Engineering 3395Y - CHEMICAL ENGINEERING LAB',
        courseName:
          'Chemical and Biochemical Engineering 3395Y - CHEMICAL ENGINEERING LAB',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f247f7e10015fd5e83',
      },
      {
        _id: '60cc20f247f7e10015fd5e85',
        courseCode: 'CBE 3396',
        shortName:
          'Chemical and Biochemical Engineering 3396Y - BIOCHEMICAL ENGINEERING LABORATORY COURSE',
        courseName:
          'Chemical and Biochemical Engineering 3396Y - BIOCHEMICAL ENGINEERING LABORATORY COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f247f7e10015fd5e85',
      },
      {
        _id: '60cc20f247f7e10015fd5e87',
        courseCode: 'CBE 4403',
        shortName:
          'Chemical and Biochemical Engineering 4403A/B - BIOCHEMICAL SEPARATION PROCESS',
        courseName:
          'Chemical and Biochemical Engineering 4403A/B - BIOCHEMICAL SEPARATION PROCESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f247f7e10015fd5e87',
      },
      {
        _id: '60cc20f247f7e10015fd5e89',
        courseCode: 'CBE 4404',
        shortName:
          'Chemical and Biochemical Engineering 4404A/B - DOWNSTREAM PROCESSING IN PHARMACEUTICAL MANUFACTURING',
        courseName:
          'Chemical and Biochemical Engineering 4404A/B - DOWNSTREAM PROCESSING IN PHARMACEUTICAL MANUFACTURING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f247f7e10015fd5e89',
      },
      {
        _id: '60cc20f347f7e10015fd5e8b',
        courseCode: 'CBE 4405',
        shortName:
          'Chemical and Biochemical Engineering 4405A/B - AIR POLLUTION CONTROL',
        courseName:
          'Chemical and Biochemical Engineering 4405A/B - AIR POLLUTION CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f347f7e10015fd5e8b',
      },
      {
        _id: '60cc20f347f7e10015fd5e8d',
        courseCode: 'CBE 4407',
        shortName:
          'Chemical and Biochemical Engineering 4407A/B - SOLID WASTE TREATMENT',
        courseName:
          'Chemical and Biochemical Engineering 4407A/B - SOLID WASTE TREATMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f347f7e10015fd5e8d',
      },
      {
        _id: '60cc20f347f7e10015fd5e8f',
        courseCode: 'CBE 4409',
        shortName:
          'Chemical and Biochemical Engineering 4409A/B - WASTEWATER TREATMENT',
        courseName:
          'Chemical and Biochemical Engineering 4409A/B - WASTEWATER TREATMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f347f7e10015fd5e8f',
      },
      {
        _id: '60cc20f347f7e10015fd5e91',
        courseCode: 'CBE 4411',
        shortName:
          'Chemical and Biochemical Engineering 4411A/B - ENGINEERING COFFEE',
        courseName:
          'Chemical and Biochemical Engineering 4411A/B - ENGINEERING COFFEE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f347f7e10015fd5e91',
      },
      {
        _id: '60cc20f447f7e10015fd5e93',
        courseCode: 'CBE 4413',
        shortName:
          'Chemical and Biochemical Engineering 4413A/B - SELECTED TOPICS IN CHEMICAL ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 4413A/B - SELECTED TOPICS IN CHEMICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f447f7e10015fd5e93',
      },
      {
        _id: '60cc20f447f7e10015fd5e95',
        courseCode: 'CBE 4415',
        shortName:
          'Chemical and Biochemical Engineering 4415 - CHEMICAL ENGINEERING PROJECT',
        courseName:
          'Chemical and Biochemical Engineering 4415 - CHEMICAL ENGINEERING PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f447f7e10015fd5e95',
      },
      {
        _id: '60cc20f447f7e10015fd5e97',
        courseCode: 'CBE 4416',
        shortName:
          'Chemical and Biochemical Engineering 4416A/B - CARBON FOOTPRINT AND MANAGEMENT',
        courseName:
          'Chemical and Biochemical Engineering 4416A/B - CARBON FOOTPRINT AND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f447f7e10015fd5e97',
      },
      {
        _id: '60cc20f447f7e10015fd5e9a',
        courseCode: 'CBE 4419',
        shortName:
          'Chemical and Biochemical Engineering 4419A/B - THE ENGINEERING OF CHEMICAL REACTORS',
        courseName:
          'Chemical and Biochemical Engineering 4419A/B - THE ENGINEERING OF CHEMICAL REACTORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f447f7e10015fd5e9a',
      },
      {
        _id: '60cc20f547f7e10015fd5e9c',
        courseCode: 'CBE 4420',
        shortName:
          'Chemical and Biochemical Engineering 4420A/B - COMPUTER PROCESS CONTROL',
        courseName:
          'Chemical and Biochemical Engineering 4420A/B - COMPUTER PROCESS CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f547f7e10015fd5e9c',
      },
      {
        _id: '60cc20f547f7e10015fd5e9e',
        courseCode: 'CBE 4421',
        shortName:
          'Chemical and Biochemical Engineering 4421A/B - INTRODUCTION TO BIOMATERIALS ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 4421A/B - INTRODUCTION TO BIOMATERIALS ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f547f7e10015fd5e9e',
      },
      {
        _id: '60cc20f547f7e10015fd5ea0',
        courseCode: 'CBE 4422',
        shortName:
          'Chemical and Biochemical Engineering 4422A/B - NANOBIOTECHNOLOGY',
        courseName:
          'Chemical and Biochemical Engineering 4422A/B - NANOBIOTECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f547f7e10015fd5ea0',
      },
      {
        _id: '60cc20f547f7e10015fd5ea2',
        courseCode: 'CBE 4423',
        shortName:
          'Chemical and Biochemical Engineering 4423A/B - TISSUE ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 4423A/B - TISSUE ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f547f7e10015fd5ea2',
      },
      {
        _id: '60cc20f647f7e10015fd5ea4',
        courseCode: 'CBE 4425',
        shortName:
          'Chemical and Biochemical Engineering 4425 - BIOCHEMICAL AND ENVIRONMENTAL ENGINEERING PROJECT',
        courseName:
          'Chemical and Biochemical Engineering 4425 - BIOCHEMICAL AND ENVIRONMENTAL ENGINEERING PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f647f7e10015fd5ea4',
      },
      {
        _id: '60cc20f647f7e10015fd5ea6',
        courseCode: 'CBE 4428',
        shortName:
          'Chemical and Biochemical Engineering 4428A/B - INTRODUCTION TO NANOENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 4428A/B - INTRODUCTION TO NANOENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f647f7e10015fd5ea6',
      },
      {
        _id: '60cc20f647f7e10015fd5ea8',
        courseCode: 'CBE 4432',
        shortName:
          'Chemical and Biochemical Engineering 4432A/B - ENERGY AND FUELS PRODUCTION SYSTEMS',
        courseName:
          'Chemical and Biochemical Engineering 4432A/B - ENERGY AND FUELS PRODUCTION SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f647f7e10015fd5ea8',
      },
      {
        _id: '60cc20f647f7e10015fd5eaa',
        courseCode: 'CBE 4463',
        shortName:
          'Chemical and Biochemical Engineering 4463A/B - WATER POLLUTION DESIGN',
        courseName:
          'Chemical and Biochemical Engineering 4463A/B - WATER POLLUTION DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f647f7e10015fd5eaa',
      },
      {
        _id: '60cc20f747f7e10015fd5eac',
        courseCode: 'CBE 4485',
        shortName:
          'Chemical and Biochemical Engineering 4485A/B - ENERGY AND SOCIETY',
        courseName:
          'Chemical and Biochemical Engineering 4485A/B - ENERGY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f747f7e10015fd5eac',
      },
      {
        _id: '60cc20f747f7e10015fd5eae',
        courseCode: 'CBE 4493',
        shortName:
          'Chemical and Biochemical Engineering 4493A/B - POLYMER ENGINEERING',
        courseName:
          'Chemical and Biochemical Engineering 4493A/B - POLYMER ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f747f7e10015fd5eae',
      },
      {
        _id: '60cc20f747f7e10015fd5eb0',
        courseCode: 'CBE 4497',
        shortName:
          'Chemical and Biochemical Engineering 4497 - CHEMICAL PROCESS & PLANT DESIGN',
        courseName:
          'Chemical and Biochemical Engineering 4497 - CHEMICAL PROCESS & PLANT DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f747f7e10015fd5eb0',
      },
      {
        _id: '60cc20f747f7e10015fd5eb2',
        courseCode: 'CBE 4498',
        shortName:
          'Chemical and Biochemical Engineering 4498 - BIOCHEMICAL PROCESS AND PLANT DESIGN',
        courseName:
          'Chemical and Biochemical Engineering 4498 - BIOCHEMICAL PROCESS AND PLANT DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f747f7e10015fd5eb2',
      },
      {
        _id: '60cc20f847f7e10015fd5eb4',
        courseCode: 'CBE 4499',
        shortName:
          'Chemical and Biochemical Engineering 4499 - CHEMICAL ENGINEERING DESIGN FOR MEDICAL STUDENTS',
        courseName:
          'Chemical and Biochemical Engineering 4499 - CHEMICAL ENGINEERING DESIGN FOR MEDICAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f847f7e10015fd5eb4',
      },
      {
        _id: '60cc20f847f7e10015fd5eb6',
        courseCode: 'CHEM 0010',
        shortName: 'Chemistry 0010 - INTRODUCTORY CHEMISTRY',
        courseName: 'Chemistry 0010 - INTRODUCTORY CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f847f7e10015fd5eb6',
      },
      {
        _id: '60cc20f847f7e10015fd5eb8',
        courseCode: 'CHEM 0011',
        shortName: 'Chemistry 0011A/B - INTRODUCTORY CHEMISTRY I',
        courseName: 'Chemistry 0011A/B - INTRODUCTORY CHEMISTRY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f847f7e10015fd5eb8',
      },
      {
        _id: '60cc20f847f7e10015fd5eba',
        courseCode: 'CHEM 0012',
        shortName: 'Chemistry 0012A/B - INTRODUCTORY CHEMISTRY II',
        courseName: 'Chemistry 0012A/B - INTRODUCTORY CHEMISTRY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f847f7e10015fd5eba',
      },
      {
        _id: '60cc20f947f7e10015fd5ebc',
        courseCode: 'CHEM 1027',
        shortName: 'Chemistry 1027A/B - EVERYWHERE AND EVERYTHING',
        courseName: 'Chemistry 1027A/B - EVERYWHERE AND EVERYTHING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f947f7e10015fd5ebc',
      },
      {
        _id: '60cc20f947f7e10015fd5ebe',
        courseCode: 'CHEM 1301',
        shortName: 'Chemistry 1301A/B - DISCOVERING CHEMICAL STRUCTURE',
        courseName: 'Chemistry 1301A/B - DISCOVERING CHEMICAL STRUCTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f947f7e10015fd5ebe',
      },
      {
        _id: '60cc20f947f7e10015fd5ec0',
        courseCode: 'CHEM 1302',
        shortName: 'Chemistry 1302A/B - DISCOVERING CHEMICAL ENERGETICS',
        courseName: 'Chemistry 1302A/B - DISCOVERING CHEMICAL ENERGETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f947f7e10015fd5ec0',
      },
      {
        _id: '60cc20f947f7e10015fd5ec2',
        courseCode: 'CHEM 2003',
        shortName:
          'Chemistry 2003A/B - ORGANIC AND BIOLOGICAL CHEMISTRY FOR FOOD SCIENCE',
        courseName:
          'Chemistry 2003A/B - ORGANIC AND BIOLOGICAL CHEMISTRY FOR FOOD SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20f947f7e10015fd5ec2',
      },
      {
        _id: '60cc20fa47f7e10015fd5ec4',
        courseCode: 'CHEM 2210',
        shortName: 'Chemistry 2210A/B - CHEMISTRY OF THE ENVIRONMENT',
        courseName: 'Chemistry 2210A/B - CHEMISTRY OF THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fa47f7e10015fd5ec4',
      },
      {
        _id: '60cc20fa47f7e10015fd5ec6',
        courseCode: 'CHEM 2211',
        shortName: 'Chemistry 2211A/B - INORGANIC ELEMENTS IN LIFE',
        courseName: 'Chemistry 2211A/B - INORGANIC ELEMENTS IN LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fa47f7e10015fd5ec6',
      },
      {
        _id: '60cc20fa47f7e10015fd5ec8',
        courseCode: 'CHEM 2213',
        shortName: 'Chemistry 2213A/B - ORGANIC CHEMISTRY FOR LIFE SCIENCES',
        courseName: 'Chemistry 2213A/B - ORGANIC CHEMISTRY FOR LIFE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fa47f7e10015fd5ec8',
      },
      {
        _id: '60cc20fa47f7e10015fd5eca',
        courseCode: 'CHEM 2214',
        shortName: 'Chemistry 2214A/B - PHYSICAL CHEMISTRY FOR LIFE SCIENCES',
        courseName: 'Chemistry 2214A/B - PHYSICAL CHEMISTRY FOR LIFE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fa47f7e10015fd5eca',
      },
      {
        _id: '60cc20fb47f7e10015fd5ecc',
        courseCode: 'CHEM 2223',
        shortName:
          'Chemistry 2223B - ORGANIC CHEMISTRY OF BIOLOGICAL MOLECULES',
        courseName:
          'Chemistry 2223B - ORGANIC CHEMISTRY OF BIOLOGICAL MOLECULES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fb47f7e10015fd5ecc',
      },
      {
        _id: '60cc20fb47f7e10015fd5ece',
        courseCode: 'CHEM 2271',
        shortName:
          'Chemistry 2271A - STRUCTURE AND BONDING IN INORGANIC CHEMISTRY',
        courseName:
          'Chemistry 2271A - STRUCTURE AND BONDING IN INORGANIC CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fb47f7e10015fd5ece',
      },
      {
        _id: '60cc20fb47f7e10015fd5ed0',
        courseCode: 'CHEM 2272',
        shortName: 'Chemistry 2272F - INTRODUCTORY ANALYTICAL CHEMISTRY',
        courseName: 'Chemistry 2272F - INTRODUCTORY ANALYTICAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fb47f7e10015fd5ed0',
      },
      {
        _id: '60cc20fb47f7e10015fd5ed2',
        courseCode: 'CHEM 2273',
        shortName:
          'Chemistry 2273A - ORGANIC CHEMISTRY I : STRUCTURE AND SPECTROSCOPY',
        courseName:
          'Chemistry 2273A - ORGANIC CHEMISTRY I : STRUCTURE AND SPECTROSCOPY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fb47f7e10015fd5ed2',
      },
      {
        _id: '60cc20fc47f7e10015fd5ed4',
        courseCode: 'CHEM 2281',
        shortName:
          'Chemistry 2281G - INORGANIC CHEMISTRY OF THE MAIN GROUP ELEMENTS',
        courseName:
          'Chemistry 2281G - INORGANIC CHEMISTRY OF THE MAIN GROUP ELEMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fc47f7e10015fd5ed4',
      },
      {
        _id: '60cc20fc47f7e10015fd5ed6',
        courseCode: 'CHEM 2283',
        shortName:
          'Chemistry 2283G - ORGANIC CHEMISTRY II: MECHANISMS AND REACTIVITY',
        courseName:
          'Chemistry 2283G - ORGANIC CHEMISTRY II: MECHANISMS AND REACTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fc47f7e10015fd5ed6',
      },
      {
        _id: '60cc20fc47f7e10015fd5ed8',
        courseCode: 'CHEM 2374',
        shortName: 'Chemistry 2374A - THERMODYNAMICS',
        courseName: 'Chemistry 2374A - THERMODYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fc47f7e10015fd5ed8',
      },
      {
        _id: '60cc20fc47f7e10015fd5eda',
        courseCode: 'CHEM 2384',
        shortName: 'Chemistry 2384B - MICROSCOPIC PHENOMENA',
        courseName: 'Chemistry 2384B - MICROSCOPIC PHENOMENA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fc47f7e10015fd5eda',
      },
      {
        _id: '60cc20fd47f7e10015fd5edc',
        courseCode: 'CHEM 3300',
        shortName: 'Chemistry 3300F/G - COMPUTER METHODS  IN CHEMISTRY',
        courseName: 'Chemistry 3300F/G - COMPUTER METHODS  IN CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fd47f7e10015fd5edc',
      },
      {
        _id: '60cc20fd47f7e10015fd5ede',
        courseCode: 'CHEM 3320',
        shortName: 'Chemistry 3320A/B - POLYMER CHEMISTRY',
        courseName: 'Chemistry 3320A/B - POLYMER CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fd47f7e10015fd5ede',
      },
      {
        _id: '60cc20fd47f7e10015fd5ee0',
        courseCode: 'CHEM 3330',
        shortName: 'Chemistry 3330F/G - INDUSTRIAL CHEMISTRY',
        courseName: 'Chemistry 3330F/G - INDUSTRIAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fd47f7e10015fd5ee0',
      },
      {
        _id: '60cc20fd47f7e10015fd5ee2',
        courseCode: 'CHEM 3364',
        shortName: 'Chemistry 3364A/B - MATERIALS CHEMISTRY',
        courseName: 'Chemistry 3364A/B - MATERIALS CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fd47f7e10015fd5ee2',
      },
      {
        _id: '60cc20fe47f7e10015fd5ee4',
        courseCode: 'CHEM 3370',
        shortName:
          'Chemistry 3370A/B - ORGANIC AND INORGANIC STRUCTURE ELUCIDATION',
        courseName:
          'Chemistry 3370A/B - ORGANIC AND INORGANIC STRUCTURE ELUCIDATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fe47f7e10015fd5ee4',
      },
      {
        _id: '60cc20fe47f7e10015fd5ee6',
        courseCode: 'CHEM 3371',
        shortName: 'Chemistry 3371F - TRANSITION METAL CHEMISTRY',
        courseName: 'Chemistry 3371F - TRANSITION METAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fe47f7e10015fd5ee6',
      },
      {
        _id: '60cc20fe47f7e10015fd5ee8',
        courseCode: 'CHEM 3372',
        shortName: 'Chemistry 3372F/G - INSTRUMENTAL ANALYTICAL CHEMISTRY',
        courseName: 'Chemistry 3372F/G - INSTRUMENTAL ANALYTICAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fe47f7e10015fd5ee8',
      },
      {
        _id: '60cc20fe47f7e10015fd5eea',
        courseCode: 'CHEM 3373',
        shortName:
          'Chemistry 3373F - ORGANIC CHEMISTRY III: REACTIONS AND STRATEGIES FOR SYNTHESIS',
        courseName:
          'Chemistry 3373F - ORGANIC CHEMISTRY III: REACTIONS AND STRATEGIES FOR SYNTHESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20fe47f7e10015fd5eea',
      },
      {
        _id: '60cc20ff47f7e10015fd5eec',
        courseCode: 'CHEM 3374',
        shortName: 'Chemistry 3374A/B - QUANTUM CHEMISTRY AND SPECTROSCOPY',
        courseName: 'Chemistry 3374A/B - QUANTUM CHEMISTRY AND SPECTROSCOPY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ff47f7e10015fd5eec',
      },
      {
        _id: '60cc20ff47f7e10015fd5eee',
        courseCode: 'CHEM 3391',
        shortName: 'Chemistry 3391A/B - BIOINORGANIC CHEMISTRY',
        courseName: 'Chemistry 3391A/B - BIOINORGANIC CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ff47f7e10015fd5eee',
      },
      {
        _id: '60cc20ff47f7e10015fd5ef0',
        courseCode: 'CHEM 3393',
        shortName: 'Chemistry 3393A/B - MEDICINAL CHEMISTRY',
        courseName: 'Chemistry 3393A/B - MEDICINAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ff47f7e10015fd5ef0',
      },
      {
        _id: '60cc20ff47f7e10015fd5ef2',
        courseCode: 'CHEM 4400',
        shortName: 'Chemistry 4400A/B - CURRENT TOPICS IN CHEMISTRY',
        courseName: 'Chemistry 4400A/B - CURRENT TOPICS IN CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc20ff47f7e10015fd5ef2',
      },
      {
        _id: '60cc210047f7e10015fd5ef4',
        courseCode: 'CHEM 4415',
        shortName:
          'Chemistry 4415B - APPLICATIONS OF SYNTHETIC BIOLOGY AND CHEMICAL GENETICS IN MEDICINE',
        courseName:
          'Chemistry 4415B - APPLICATIONS OF SYNTHETIC BIOLOGY AND CHEMICAL GENETICS IN MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210047f7e10015fd5ef4',
      },
      {
        _id: '60cc210047f7e10015fd5ef6',
        courseCode: 'CHEM 4444',
        shortName:
          'Chemistry 4444A/B - STATISTICAL MECHANICS AND MOLECULAR SIMULATIONS',
        courseName:
          'Chemistry 4444A/B - STATISTICAL MECHANICS AND MOLECULAR SIMULATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210047f7e10015fd5ef6',
      },
      {
        _id: '60cc210047f7e10015fd5ef8',
        courseCode: 'CHEM 4471',
        shortName: 'Chemistry 4471A/B - TRANSITION METALS AND CATALYSIS',
        courseName: 'Chemistry 4471A/B - TRANSITION METALS AND CATALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210047f7e10015fd5ef8',
      },
      {
        _id: '60cc210047f7e10015fd5efa',
        courseCode: 'CHEM 4472',
        shortName: 'Chemistry 4472A/B - ADVANCED ANALYTICAL CHEMISTRY',
        courseName: 'Chemistry 4472A/B - ADVANCED ANALYTICAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210047f7e10015fd5efa',
      },
      {
        _id: '60cc210147f7e10015fd5efc',
        courseCode: 'CHEM 4473',
        shortName: 'Chemistry 4473A/B - MODERN CHEMICAL SYNTHESIS',
        courseName: 'Chemistry 4473A/B - MODERN CHEMICAL SYNTHESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210147f7e10015fd5efc',
      },
      {
        _id: '60cc210147f7e10015fd5efe',
        courseCode: 'CHEM 4474',
        shortName:
          'Chemistry 4474A/B - ADVANCED QUANTUM CHEMISTRY AND SPECTROSCOPY',
        courseName:
          'Chemistry 4474A/B - ADVANCED QUANTUM CHEMISTRY AND SPECTROSCOPY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210147f7e10015fd5efe',
      },
      {
        _id: '60cc210147f7e10015fd5f00',
        courseCode: 'CHEM 4481',
        shortName:
          'Chemistry 4481A/B - SOLID-STATE INORGANIC CHEMISTRY AND MATERIALS',
        courseName:
          'Chemistry 4481A/B - SOLID-STATE INORGANIC CHEMISTRY AND MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210147f7e10015fd5f00',
      },
      {
        _id: '60cc210147f7e10015fd5f02',
        courseCode: 'CHEM 4483',
        shortName:
          'Chemistry 4483A/B - ELUCIDATION OF ORGANIC AND BIOORGANIC MECHANISMS',
        courseName:
          'Chemistry 4483A/B - ELUCIDATION OF ORGANIC AND BIOORGANIC MECHANISMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210147f7e10015fd5f02',
      },
      {
        _id: '60cc210147f7e10015fd5f04',
        courseCode: 'CHEM 4491',
        shortName:
          'Chemistry 4491E - CHEMICAL RESEARCH DISCOVERY AND SCIENTIFIC COMMUNICATION',
        courseName:
          'Chemistry 4491E - CHEMICAL RESEARCH DISCOVERY AND SCIENTIFIC COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210147f7e10015fd5f04',
      },
      {
        _id: '60cc210247f7e10015fd5f06',
        courseCode: 'CHEM 4493',
        shortName: 'Chemistry 4493A/B - CHEMISTRY OF BIOLOGICAL MACROMOLECULES',
        courseName:
          'Chemistry 4493A/B - CHEMISTRY OF BIOLOGICAL MACROMOLECULES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210247f7e10015fd5f06',
      },
      {
        _id: '60cc210247f7e10015fd5f08',
        courseCode: 'CHEM 4494',
        shortName: 'Chemistry 4494A/B - BIOPHYSICAL CHEMISTRY',
        courseName: 'Chemistry 4494A/B - BIOPHYSICAL CHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210247f7e10015fd5f08',
      },
      {
        _id: '60cc210247f7e10015fd5f0a',
        courseCode: 'CSI 1025',
        shortName:
          'Childhood and Social Institutions 1025F/G - CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 1025F/G - CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210247f7e10015fd5f0a',
      },
      {
        _id: '60cc210247f7e10015fd5f0c',
        courseCode: 'CSI 2210',
        shortName:
          'Childhood and Social Institutions 2210F/G - CHILDHOOD AND PUBLIC POLICY',
        courseName:
          'Childhood and Social Institutions 2210F/G - CHILDHOOD AND PUBLIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210247f7e10015fd5f0c',
      },
      {
        _id: '60cc210347f7e10015fd5f0e',
        courseCode: 'CSI 2211',
        shortName:
          'Childhood and Social Institutions 2211A/B - CHILDREN AND CRIME',
        courseName:
          'Childhood and Social Institutions 2211A/B - CHILDREN AND CRIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210347f7e10015fd5f0e',
      },
      {
        _id: '60cc210347f7e10015fd5f10',
        courseCode: 'CSI 2212',
        shortName:
          'Childhood and Social Institutions 2212F/G - CHILDHOOD AND THE LAW',
        courseName:
          'Childhood and Social Institutions 2212F/G - CHILDHOOD AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210347f7e10015fd5f10',
      },
      {
        _id: '60cc210347f7e10015fd5f12',
        courseCode: 'CSI 2213',
        shortName:
          'Childhood and Social Institutions 2213F/G - CHILDHOOD SYSTEMS AND LIFEWORLDS',
        courseName:
          'Childhood and Social Institutions 2213F/G - CHILDHOOD SYSTEMS AND LIFEWORLDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210347f7e10015fd5f12',
      },
      {
        _id: '60cc210347f7e10015fd5f14',
        courseCode: 'CSI 2214',
        shortName:
          'Childhood and Social Institutions 2214F/G - INTRODUCTION TO ADVOCACY',
        courseName:
          'Childhood and Social Institutions 2214F/G - INTRODUCTION TO ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210347f7e10015fd5f14',
      },
      {
        _id: '60cc210447f7e10015fd5f16',
        courseCode: 'CSI 2215',
        shortName:
          'Childhood and Social Institutions 2215A/B - REPRESENTATIONS OF CHILDHOOD',
        courseName:
          'Childhood and Social Institutions 2215A/B - REPRESENTATIONS OF CHILDHOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210447f7e10015fd5f16',
      },
      {
        _id: '60cc210447f7e10015fd5f18',
        courseCode: 'CSI 2221',
        shortName:
          'Childhood and Social Institutions 2221A/B - INTERNATIONAL CHILDREN’S RIGHTS IN PRACTICE',
        courseName:
          'Childhood and Social Institutions 2221A/B - INTERNATIONAL CHILDREN’S RIGHTS IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210447f7e10015fd5f18',
      },
      {
        _id: '60cc210447f7e10015fd5f1a',
        courseCode: 'CSI 2231',
        shortName:
          'Childhood and Social Institutions 2231A/B - CHILDHOOD, POVERTY POLICY, AND LAW',
        courseName:
          'Childhood and Social Institutions 2231A/B - CHILDHOOD, POVERTY POLICY, AND LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210447f7e10015fd5f1a',
      },
      {
        _id: '60cc210447f7e10015fd5f1c',
        courseCode: 'CSI 2251',
        shortName:
          "Childhood and Social Institutions 2251F/G - CHILDREN'S RIGHTS IN SCHOOLS",
        courseName:
          "Childhood and Social Institutions 2251F/G - CHILDREN'S RIGHTS IN SCHOOLS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210447f7e10015fd5f1c',
      },
      {
        _id: '60cc210547f7e10015fd5f1e',
        courseCode: 'CSI 2252',
        shortName:
          'Childhood and Social Institutions 2252F/G - WAR ZONE CHILDHOODS',
        courseName:
          'Childhood and Social Institutions 2252F/G - WAR ZONE CHILDHOODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210547f7e10015fd5f1e',
      },
      {
        _id: '60cc210547f7e10015fd5f20',
        courseCode: 'CSI 2257',
        shortName:
          'Childhood and Social Institutions 2257F/G - CHILDREN AND MEDIA',
        courseName:
          'Childhood and Social Institutions 2257F/G - CHILDREN AND MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210547f7e10015fd5f20',
      },
      {
        _id: '60cc210547f7e10015fd5f22',
        courseCode: 'CSI 2291',
        shortName:
          'Childhood and Social Institutions 2291A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 2291A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210547f7e10015fd5f22',
      },
      {
        _id: '60cc210547f7e10015fd5f24',
        courseCode: 'CSI 2292',
        shortName:
          'Childhood and Social Institutions 2292A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 2292A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210547f7e10015fd5f24',
      },
      {
        _id: '60cc210547f7e10015fd5f26',
        courseCode: 'CSI 2293',
        shortName:
          'Childhood and Social Institutions 2293A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 2293A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210547f7e10015fd5f26',
      },
      {
        _id: '60cc210647f7e10015fd5f28',
        courseCode: 'CSI 2294',
        shortName:
          'Childhood and Social Institutions 2294A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 2294A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210647f7e10015fd5f28',
      },
      {
        _id: '60cc210647f7e10015fd5f2a',
        courseCode: 'CSI 2295',
        shortName:
          'Childhood and Social Institutions 2295A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 2295A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210647f7e10015fd5f2a',
      },
      {
        _id: '60cc210647f7e10015fd5f2c',
        courseCode: 'CSI 2296',
        shortName:
          'Childhood and Social Institutions 2296A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 2296A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210647f7e10015fd5f2c',
      },
      {
        _id: '60cc210647f7e10015fd5f2e',
        courseCode: 'CSI 3300',
        shortName:
          'Childhood and Social Institutions 3300F/G - CHILDHOOD, CITIZENSHIP, AND PARTICIPATION',
        courseName:
          'Childhood and Social Institutions 3300F/G - CHILDHOOD, CITIZENSHIP, AND PARTICIPATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210647f7e10015fd5f2e',
      },
      {
        _id: '60cc210747f7e10015fd5f30',
        courseCode: 'CSI 3310',
        shortName:
          'Childhood and Social Institutions 3310F/G - REPRESENTING CHILDHOOD EMPIRICALLY',
        courseName:
          'Childhood and Social Institutions 3310F/G - REPRESENTING CHILDHOOD EMPIRICALLY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210747f7e10015fd5f30',
      },
      {
        _id: '60cc210747f7e10015fd5f32',
        courseCode: 'CSI 3311',
        shortName:
          'Childhood and Social Institutions 3311F/G - RESEARCHING CHILDHOOD',
        courseName:
          'Childhood and Social Institutions 3311F/G - RESEARCHING CHILDHOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210747f7e10015fd5f32',
      },
      {
        _id: '60cc210747f7e10015fd5f35',
        courseCode: 'CSI 3335',
        shortName:
          'Childhood and Social Institutions 3335F/G - HUMAN RIGHTS & CHILD ADVOCACY',
        courseName:
          'Childhood and Social Institutions 3335F/G - HUMAN RIGHTS & CHILD ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210747f7e10015fd5f35',
      },
      {
        _id: '60cc210847f7e10015fd5f37',
        courseCode: 'CSI 3340',
        shortName:
          'Childhood and Social Institutions 3340F/G - CHILDREN IN SEPARATION AND DIVORCE',
        courseName:
          'Childhood and Social Institutions 3340F/G - CHILDREN IN SEPARATION AND DIVORCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210847f7e10015fd5f37',
      },
      {
        _id: '60cc210847f7e10015fd5f39',
        courseCode: 'CSI 3346',
        shortName:
          'Childhood and Social Institutions 3346F/G - CHILD PARTICIPATION IN THE CRIMINAL JUSTICE SYSTEM',
        courseName:
          'Childhood and Social Institutions 3346F/G - CHILD PARTICIPATION IN THE CRIMINAL JUSTICE SYSTEM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210847f7e10015fd5f39',
      },
      {
        _id: '60cc210847f7e10015fd5f3b',
        courseCode: 'CSI 3350',
        shortName:
          'Childhood and Social Institutions 3350F/G - CHILDREN AS ADVOCATES',
        courseName:
          'Childhood and Social Institutions 3350F/G - CHILDREN AS ADVOCATES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210847f7e10015fd5f3b',
      },
      {
        _id: '60cc210847f7e10015fd5f3d',
        courseCode: 'CSI 3351',
        shortName:
          'Childhood and Social Institutions 3351F/G - FACILITATING CHILD ADVOCACY',
        courseName:
          'Childhood and Social Institutions 3351F/G - FACILITATING CHILD ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210847f7e10015fd5f3d',
      },
      {
        _id: '60cc210947f7e10015fd5f3f',
        courseCode: 'CSI 3352',
        shortName:
          'Childhood and Social Institutions 3352F/G - ADVOCACY WITH CHILDREN - CANADA',
        courseName:
          'Childhood and Social Institutions 3352F/G - ADVOCACY WITH CHILDREN - CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210947f7e10015fd5f3f',
      },
      {
        _id: '60cc210947f7e10015fd5f41',
        courseCode: 'CSI 3353',
        shortName:
          'Childhood and Social Institutions 3353F/G - ADVOCACY WITH CHILDREN - INTERNATIONAL',
        courseName:
          'Childhood and Social Institutions 3353F/G - ADVOCACY WITH CHILDREN - INTERNATIONAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210947f7e10015fd5f41',
      },
      {
        _id: '60cc210947f7e10015fd5f43',
        courseCode: 'CSI 3361',
        shortName:
          'Childhood and Social Institutions 3361F/G - CHILDHOOD IN CANADIAN HISTORY',
        courseName:
          'Childhood and Social Institutions 3361F/G - CHILDHOOD IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210947f7e10015fd5f43',
      },
      {
        _id: '60cc210947f7e10015fd5f45',
        courseCode: 'CSI 3364',
        shortName:
          'Childhood and Social Institutions 3364F/G - CHILDHOOD AND GOVERNMENTALITY',
        courseName:
          'Childhood and Social Institutions 3364F/G - CHILDHOOD AND GOVERNMENTALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210947f7e10015fd5f45',
      },
      {
        _id: '60cc210a47f7e10015fd5f47',
        courseCode: 'CSI 3365',
        shortName:
          'Childhood and Social Institutions 3365F/G - CHILDHOOD, EDUCATION POLICY AND LAW',
        courseName:
          'Childhood and Social Institutions 3365F/G - CHILDHOOD, EDUCATION POLICY AND LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210a47f7e10015fd5f47',
      },
      {
        _id: '60cc210a47f7e10015fd5f49',
        courseCode: 'CSI 3370',
        shortName:
          'Childhood and Social Institutions 3370F/G - CHILDHOOD, PEER RELATIONS, AND PLAY',
        courseName:
          'Childhood and Social Institutions 3370F/G - CHILDHOOD, PEER RELATIONS, AND PLAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210a47f7e10015fd5f49',
      },
      {
        _id: '60cc210a47f7e10015fd5f4b',
        courseCode: 'CSI 3380',
        shortName:
          'Childhood and Social Institutions 3380F/G/Z - CHILD PARTICIPATION APPROACHES',
        courseName:
          'Childhood and Social Institutions 3380F/G/Z - CHILD PARTICIPATION APPROACHES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210a47f7e10015fd5f4b',
      },
      {
        _id: '60cc210a47f7e10015fd5f4d',
        courseCode: 'CSI 3391',
        shortName:
          'Childhood and Social Institutions 3391A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 3391A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210a47f7e10015fd5f4d',
      },
      {
        _id: '60cc210b47f7e10015fd5f4f',
        courseCode: 'CSI 3392',
        shortName:
          'Childhood and Social Institutions 3392A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 3392A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210b47f7e10015fd5f4f',
      },
      {
        _id: '60cc210b47f7e10015fd5f51',
        courseCode: 'CSI 3393',
        shortName:
          'Childhood and Social Institutions 3393A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 3393A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210b47f7e10015fd5f51',
      },
      {
        _id: '60cc210b47f7e10015fd5f53',
        courseCode: 'CSI 3394',
        shortName:
          'Childhood and Social Institutions 3394A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 3394A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210b47f7e10015fd5f53',
      },
      {
        _id: '60cc210b47f7e10015fd5f55',
        courseCode: 'CSI 3395',
        shortName:
          'Childhood and Social Institutions 3395A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 3395A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210b47f7e10015fd5f55',
      },
      {
        _id: '60cc210c47f7e10015fd5f57',
        courseCode: 'CSI 3396',
        shortName:
          'Childhood and Social Institutions 3396A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 3396A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210c47f7e10015fd5f57',
      },
      {
        _id: '60cc210c47f7e10015fd5f59',
        courseCode: 'CSI 3398',
        shortName:
          'Childhood and Social Institutions 3398F/G - INDEPENDENT STUDY',
        courseName:
          'Childhood and Social Institutions 3398F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210c47f7e10015fd5f59',
      },
      {
        _id: '60cc210c47f7e10015fd5f5b',
        courseCode: 'CSI 3399',
        shortName:
          'Childhood and Social Institutions 3399F/G - INDEPENDENT STUDY',
        courseName:
          'Childhood and Social Institutions 3399F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210c47f7e10015fd5f5b',
      },
      {
        _id: '60cc210c47f7e10015fd5f5d',
        courseCode: 'CSI 4401',
        shortName:
          'Childhood and Social Institutions 4401F/G - EMERGING CHILDHOOD TOPICS I',
        courseName:
          'Childhood and Social Institutions 4401F/G - EMERGING CHILDHOOD TOPICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210c47f7e10015fd5f5d',
      },
      {
        _id: '60cc210c47f7e10015fd5f5f',
        courseCode: 'CSI 4402',
        shortName:
          'Childhood and Social Institutions 4402F/G - EMERGING CHILDHOOD TOPICS II',
        courseName:
          'Childhood and Social Institutions 4402F/G - EMERGING CHILDHOOD TOPICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210c47f7e10015fd5f5f',
      },
      {
        _id: '60cc210d47f7e10015fd5f61',
        courseCode: 'CSI 4403',
        shortName:
          'Childhood and Social Institutions 4403E - SENIOR PROJECT IN CHILDHOOD',
        courseName:
          'Childhood and Social Institutions 4403E - SENIOR PROJECT IN CHILDHOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210d47f7e10015fd5f61',
      },
      {
        _id: '60cc210d47f7e10015fd5f63',
        courseCode: 'CSI 4410',
        shortName:
          'Childhood and Social Institutions 4410F/G - INDEPENDENT STUDY IN CHILDHOOD & SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4410F/G - INDEPENDENT STUDY IN CHILDHOOD & SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210d47f7e10015fd5f63',
      },
      {
        _id: '60cc210d47f7e10015fd5f65',
        courseCode: 'CSI 4491',
        shortName:
          'Childhood and Social Institutions 4491A - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4491A - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210d47f7e10015fd5f65',
      },
      {
        _id: '60cc210d47f7e10015fd5f67',
        courseCode: 'CSI 4492',
        shortName:
          'Childhood and Social Institutions 4492A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4492A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210d47f7e10015fd5f67',
      },
      {
        _id: '60cc210e47f7e10015fd5f69',
        courseCode: 'CSI 4493',
        shortName:
          'Childhood and Social Institutions 4493A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4493A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210e47f7e10015fd5f69',
      },
      {
        _id: '60cc210e47f7e10015fd5f6b',
        courseCode: 'CSI 4494',
        shortName:
          'Childhood and Social Institutions 4494A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4494A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210e47f7e10015fd5f6b',
      },
      {
        _id: '60cc210e47f7e10015fd5f6d',
        courseCode: 'CSI 4495',
        shortName:
          'Childhood and Social Institutions 4495A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4495A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210e47f7e10015fd5f6d',
      },
      {
        _id: '60cc210e47f7e10015fd5f6f',
        courseCode: 'CSI 4496',
        shortName:
          'Childhood and Social Institutions 4496A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        courseName:
          'Childhood and Social Institutions 4496A/B - SPECIAL TOPICS IN CHILDHOOD AND SOCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210e47f7e10015fd5f6f',
      },
      {
        _id: '60cc210e47f7e10015fd5f71',
        courseCode: 'CSI 4498',
        shortName:
          'Childhood and Social Institutions 4498F/G - INDEPENDENT STUDY',
        courseName:
          'Childhood and Social Institutions 4498F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210e47f7e10015fd5f71',
      },
      {
        _id: '60cc210f47f7e10015fd5f73',
        courseCode: 'CSI 4499',
        shortName:
          'Childhood and Social Institutions 4499F/G - INDEPENDENT STUDY',
        courseName:
          'Childhood and Social Institutions 4499F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210f47f7e10015fd5f73',
      },
      {
        _id: '60cc210f47f7e10015fd5f75',
        courseCode: 'CHINESE 1150',
        shortName: "Chinese 1150 - BEGINNERS' CHINESE I",
        courseName: "Chinese 1150 - BEGINNERS' CHINESE I",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210f47f7e10015fd5f75',
      },
      {
        _id: '60cc210f47f7e10015fd5f77',
        courseCode: 'CHINESE 1151',
        shortName: 'Chinese 1151 - CHINESE I',
        courseName: 'Chinese 1151 - CHINESE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210f47f7e10015fd5f77',
      },
      {
        _id: '60cc210f47f7e10015fd5f79',
        courseCode: 'CHINESE 1152',
        shortName: 'Chinese 1152A/B - CHINESE I',
        courseName: 'Chinese 1152A/B - CHINESE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc210f47f7e10015fd5f79',
      },
      {
        _id: '60cc211047f7e10015fd5f7b',
        courseCode: 'CHINESE 1153',
        shortName: 'Chinese 1153A/B - CHINESE I',
        courseName: 'Chinese 1153A/B - CHINESE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211047f7e10015fd5f7b',
      },
      {
        _id: '60cc211047f7e10015fd5f7d',
        courseCode: 'CHINESE 1650',
        shortName: 'Chinese 1650F/G - PERSPECTIVES ON CHINA',
        courseName: 'Chinese 1650F/G - PERSPECTIVES ON CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211047f7e10015fd5f7d',
      },
      {
        _id: '60cc211047f7e10015fd5f7f',
        courseCode: 'CHINESE 1651',
        shortName: 'Chinese 1651F/G - CHINESE SYMBOLS AND ICONS',
        courseName: 'Chinese 1651F/G - CHINESE SYMBOLS AND ICONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211047f7e10015fd5f7f',
      },
      {
        _id: '60cc211047f7e10015fd5f81',
        courseCode: 'CHINESE 2240',
        shortName: 'Chinese 2240F/G - UNDERSTANDING CHINESE BUSINESS CULTURE 1',
        courseName:
          'Chinese 2240F/G - UNDERSTANDING CHINESE BUSINESS CULTURE 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211047f7e10015fd5f81',
      },
      {
        _id: '60cc211147f7e10015fd5f83',
        courseCode: 'CHINESE 2241',
        shortName: 'Chinese 2241F/G - UNDERSTANDING CHINESE BUSINESS CULTURE 2',
        courseName:
          'Chinese 2241F/G - UNDERSTANDING CHINESE BUSINESS CULTURE 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211147f7e10015fd5f83',
      },
      {
        _id: '60cc211147f7e10015fd5f85',
        courseCode: 'CHINESE 2242',
        shortName:
          'Chinese 2242F/G - REPRESENTATIVE WORKS IN TRADITIONAL CHINESE LITERATURE',
        courseName:
          'Chinese 2242F/G - REPRESENTATIVE WORKS IN TRADITIONAL CHINESE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211147f7e10015fd5f85',
      },
      {
        _id: '60cc211147f7e10015fd5f87',
        courseCode: 'CHINESE 2243',
        shortName:
          'Chinese 2243F/G - THE CHINESE DIASPORA AND ITS LITERARY REPRESENTATION',
        courseName:
          'Chinese 2243F/G - THE CHINESE DIASPORA AND ITS LITERARY REPRESENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211147f7e10015fd5f87',
      },
      {
        _id: '60cc211147f7e10015fd5f89',
        courseCode: 'CHINESE 2244',
        shortName:
          'Chinese 2244F/G - REPRESENTATIVE WORKS IN MODERN CHINESE LITERATURE',
        courseName:
          'Chinese 2244F/G - REPRESENTATIVE WORKS IN MODERN CHINESE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211147f7e10015fd5f89',
      },
      {
        _id: '60cc211247f7e10015fd5f8b',
        courseCode: 'CHINESE 2245',
        shortName: 'Chinese 2245F/G - CINEMA IN THE CHINESE MAINLAND',
        courseName: 'Chinese 2245F/G - CINEMA IN THE CHINESE MAINLAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211247f7e10015fd5f8b',
      },
      {
        _id: '60cc211247f7e10015fd5f8d',
        courseCode: 'CHINESE 2246',
        shortName: 'Chinese 2246F/G - CHINESE CINEMA IN TAIWAN & HONG KONG',
        courseName: 'Chinese 2246F/G - CHINESE CINEMA IN TAIWAN & HONG KONG',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211247f7e10015fd5f8d',
      },
      {
        _id: '60cc211247f7e10015fd5f8f',
        courseCode: 'CHINESE 2247',
        shortName: 'Chinese 2247F/G - CHINESE CITIES & CHINESE CULTURE',
        courseName: 'Chinese 2247F/G - CHINESE CITIES & CHINESE CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211247f7e10015fd5f8f',
      },
      {
        _id: '60cc211247f7e10015fd5f91',
        courseCode: 'CHINESE 2250',
        shortName: "Chinese 2250 - BEGINNERS' CHINESE 2",
        courseName: "Chinese 2250 - BEGINNERS' CHINESE 2",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211247f7e10015fd5f91',
      },
      {
        _id: '60cc211347f7e10015fd5f93',
        courseCode: 'CHINESE 2252',
        shortName: 'Chinese 2252A/B - CHINESE 2',
        courseName: 'Chinese 2252A/B - CHINESE 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211347f7e10015fd5f93',
      },
      {
        _id: '60cc211347f7e10015fd5f95',
        courseCode: 'CHINESE 2253',
        shortName: 'Chinese 2253A/B - CHINESE 2',
        courseName: 'Chinese 2253A/B - CHINESE 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211347f7e10015fd5f95',
      },
      {
        _id: '60cc211347f7e10015fd5f97',
        courseCode: 'CHINESE 2270',
        shortName: 'Chinese 2270F/G - SPECIAL TOPICS',
        courseName: 'Chinese 2270F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211347f7e10015fd5f97',
      },
      {
        _id: '60cc211347f7e10015fd5f99',
        courseCode: 'CHINESE 2271',
        shortName: 'Chinese 2271F/G - SPECIAL TOPICS',
        courseName: 'Chinese 2271F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211347f7e10015fd5f99',
      },
      {
        _id: '60cc211447f7e10015fd5f9b',
        courseCode: 'CHINESE 2601',
        shortName:
          'Chinese 2601A/B - THE POLITICAL AND SCIO-ECONOMIC FOUNDATIONS OF MODERN CHINA',
        courseName:
          'Chinese 2601A/B - THE POLITICAL AND SCIO-ECONOMIC FOUNDATIONS OF MODERN CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211447f7e10015fd5f9b',
      },
      {
        _id: '60cc211447f7e10015fd5f9d',
        courseCode: 'CHINESE 2602',
        shortName: 'Chinese 2602A/B - THE CULTURAL FOUNDATIONS OF MODERN CHINA',
        courseName:
          'Chinese 2602A/B - THE CULTURAL FOUNDATIONS OF MODERN CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211447f7e10015fd5f9d',
      },
      {
        _id: '60cc211447f7e10015fd5f9f',
        courseCode: 'CHINESE 2650',
        shortName: 'Chinese 2650F/G - FICTION AND TRADITIONAL CHINESE SOCIETY',
        courseName: 'Chinese 2650F/G - FICTION AND TRADITIONAL CHINESE SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211447f7e10015fd5f9f',
      },
      {
        _id: '60cc211447f7e10015fd5fa1',
        courseCode: 'CHINESE 2651',
        shortName: 'Chinese 2651F/G - FICTION AND MODERN CHINESE SOCIETY',
        courseName: 'Chinese 2651F/G - FICTION AND MODERN CHINESE SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211447f7e10015fd5fa1',
      },
      {
        _id: '60cc211547f7e10015fd5fa3',
        courseCode: 'CHINESE 3340',
        shortName: 'Chinese 3340A/B - BUSINESS CHINESE I',
        courseName: 'Chinese 3340A/B - BUSINESS CHINESE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211547f7e10015fd5fa3',
      },
      {
        _id: '60cc211547f7e10015fd5fa5',
        courseCode: 'CHINESE 3341',
        shortName: 'Chinese 3341A/B - BUSINESS CHINESE 2',
        courseName: 'Chinese 3341A/B - BUSINESS CHINESE 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211547f7e10015fd5fa5',
      },
      {
        _id: '60cc211547f7e10015fd5fa7',
        courseCode: 'CHINESE 3344',
        shortName: 'Chinese 3344A/B - PROFICIENCY FOR HSK',
        courseName: 'Chinese 3344A/B - PROFICIENCY FOR HSK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211547f7e10015fd5fa7',
      },
      {
        _id: '60cc211547f7e10015fd5fa9',
        courseCode: 'CHINESE 3345',
        shortName:
          'Chinese 3345A/B - IMPROVING COMMUNICATIVE SKILLS IN STANDARD CHINESE',
        courseName:
          'Chinese 3345A/B - IMPROVING COMMUNICATIVE SKILLS IN STANDARD CHINESE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211547f7e10015fd5fa9',
      },
      {
        _id: '60cc211547f7e10015fd5fab',
        courseCode: 'CHINESE 3346',
        shortName: 'Chinese 3346A/B - IMPROVING CHINESE PRONUNCIATION',
        courseName: 'Chinese 3346A/B - IMPROVING CHINESE PRONUNCIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211547f7e10015fd5fab',
      },
      {
        _id: '60cc211647f7e10015fd5fad',
        courseCode: 'CHINESE 3350',
        shortName: 'Chinese 3350 - CHINESE 3',
        courseName: 'Chinese 3350 - CHINESE 3',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211647f7e10015fd5fad',
      },
      {
        _id: '60cc211647f7e10015fd5faf',
        courseCode: 'CHINESE 3352',
        shortName: 'Chinese 3352A/B - CHINESE 3',
        courseName: 'Chinese 3352A/B - CHINESE 3',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211647f7e10015fd5faf',
      },
      {
        _id: '60cc211647f7e10015fd5fb1',
        courseCode: 'CHINESE 3353',
        shortName: 'Chinese 3353A/B - CHINESE 3',
        courseName: 'Chinese 3353A/B - CHINESE 3',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211647f7e10015fd5fb1',
      },
      {
        _id: '60cc211647f7e10015fd5fb3',
        courseCode: 'CHINESE 3370',
        shortName: 'Chinese 3370F/G - SPECIAL TOPICS',
        courseName: 'Chinese 3370F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211647f7e10015fd5fb3',
      },
      {
        _id: '60cc211747f7e10015fd5fb5',
        courseCode: 'CHINESE 3371',
        shortName: 'Chinese 3371F/G - SPECIAL TOPICS',
        courseName: 'Chinese 3371F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211747f7e10015fd5fb5',
      },
      {
        _id: '60cc211747f7e10015fd5fb7',
        courseCode: 'CHINESE 3650',
        shortName: 'Chinese 3650F/G - THE CHINESE SHORT STORY',
        courseName: 'Chinese 3650F/G - THE CHINESE SHORT STORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211747f7e10015fd5fb7',
      },
      {
        _id: '60cc211747f7e10015fd5fb9',
        courseCode: 'CHINESE 3651',
        shortName: 'Chinese 3651F/G - THE CHINESE NOVEL',
        courseName: 'Chinese 3651F/G - THE CHINESE NOVEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211747f7e10015fd5fb9',
      },
      {
        _id: '60cc211747f7e10015fd5fbb',
        courseCode: 'CHINESE 3652',
        shortName:
          'Chinese 3652F/G - CHINESE DISCOURSE ON WOMEN AND GENDER RELATIONS',
        courseName:
          'Chinese 3652F/G - CHINESE DISCOURSE ON WOMEN AND GENDER RELATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211747f7e10015fd5fbb',
      },
      {
        _id: '60cc211747f7e10015fd5fbd',
        courseCode: 'CHINESE 3653',
        shortName:
          'Chinese 3653F/G - WOMEN IN CHINESE SOCIETY: IMAGES IN LITERATURE',
        courseName:
          'Chinese 3653F/G - WOMEN IN CHINESE SOCIETY: IMAGES IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211747f7e10015fd5fbd',
      },
      {
        _id: '60cc211847f7e10015fd5fbf',
        courseCode: 'CHINESE 3654',
        shortName:
          'Chinese 3654F/G - TRAUMA, MEMORY & NARRATIVE IN CHINESE LITERATURE & FILM',
        courseName:
          'Chinese 3654F/G - TRAUMA, MEMORY & NARRATIVE IN CHINESE LITERATURE & FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211847f7e10015fd5fbf',
      },
      {
        _id: '60cc211847f7e10015fd5fc1',
        courseCode: 'CHINESE 3655',
        shortName: 'Chinese 3655F/G - CHINESE THEATRE & CULTURE',
        courseName: 'Chinese 3655F/G - CHINESE THEATRE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211847f7e10015fd5fc1',
      },
      {
        _id: '60cc211847f7e10015fd5fc3',
        courseCode: 'CHINESE 3950',
        shortName: 'Chinese 3950F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3950F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211847f7e10015fd5fc3',
      },
      {
        _id: '60cc211847f7e10015fd5fc5',
        courseCode: 'CHINESE 3951',
        shortName: 'Chinese 3951F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3951F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211847f7e10015fd5fc5',
      },
      {
        _id: '60cc211947f7e10015fd5fc7',
        courseCode: 'CHINESE 3952',
        shortName: 'Chinese 3952F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3952F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211947f7e10015fd5fc7',
      },
      {
        _id: '60cc211947f7e10015fd5fc9',
        courseCode: 'CHINESE 3953',
        shortName: 'Chinese 3953F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3953F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211947f7e10015fd5fc9',
      },
      {
        _id: '60cc211947f7e10015fd5fcb',
        courseCode: 'CHINESE 3954',
        shortName: 'Chinese 3954F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3954F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211947f7e10015fd5fcb',
      },
      {
        _id: '60cc211947f7e10015fd5fcd',
        courseCode: 'CHINESE 3955',
        shortName: 'Chinese 3955F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3955F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211947f7e10015fd5fcd',
      },
      {
        _id: '60cc211a47f7e10015fd5fcf',
        courseCode: 'CHINESE 3956',
        shortName: 'Chinese 3956F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3956F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211a47f7e10015fd5fcf',
      },
      {
        _id: '60cc211a47f7e10015fd5fd1',
        courseCode: 'CHINESE 3957',
        shortName: 'Chinese 3957F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3957F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211a47f7e10015fd5fd1',
      },
      {
        _id: '60cc211a47f7e10015fd5fd3',
        courseCode: 'CHINESE 3958',
        shortName: 'Chinese 3958F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3958F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211a47f7e10015fd5fd3',
      },
      {
        _id: '60cc211a47f7e10015fd5fd5',
        courseCode: 'CHINESE 3959',
        shortName: 'Chinese 3959F/G - SPECIAL TOPIC IN CHINA STUDIES',
        courseName: 'Chinese 3959F/G - SPECIAL TOPIC IN CHINA STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211a47f7e10015fd5fd5',
      },
      {
        _id: '60cc211a47f7e10015fd5fd7',
        courseCode: 'CHINESE 4440',
        shortName: 'Chinese 4440A/B - BUSINESS TRANSLATION 1',
        courseName: 'Chinese 4440A/B - BUSINESS TRANSLATION 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211a47f7e10015fd5fd7',
      },
      {
        _id: '60cc211b47f7e10015fd5fd9',
        courseCode: 'CHINESE 4441',
        shortName: 'Chinese 4441A/B - BUSINESS TRANSLATION 2',
        courseName: 'Chinese 4441A/B - BUSINESS TRANSLATION 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211b47f7e10015fd5fd9',
      },
      {
        _id: '60cc211b47f7e10015fd5fdb',
        courseCode: 'CHINESE 4450',
        shortName: 'Chinese 4450 - CHINESE 4',
        courseName: 'Chinese 4450 - CHINESE 4',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211b47f7e10015fd5fdb',
      },
      {
        _id: '60cc211b47f7e10015fd5fdd',
        courseCode: 'CHINESE 4452',
        shortName: 'Chinese 4452A/B - CHINESE 4',
        courseName: 'Chinese 4452A/B - CHINESE 4',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211b47f7e10015fd5fdd',
      },
      {
        _id: '60cc211b47f7e10015fd5fdf',
        courseCode: 'CHINESE 4453',
        shortName: 'Chinese 4453A/B - CHINESE 4',
        courseName: 'Chinese 4453A/B - CHINESE 4',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211b47f7e10015fd5fdf',
      },
      {
        _id: '60cc211c47f7e10015fd5fe1',
        courseCode: 'CEE 2202',
        shortName:
          'Civil and Environmental Engineering 2202A/B - MECHANICS OF MATERIALS',
        courseName:
          'Civil and Environmental Engineering 2202A/B - MECHANICS OF MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211c47f7e10015fd5fe1',
      },
      {
        _id: '60cc211c47f7e10015fd5fe3',
        courseCode: 'CEE 2217',
        shortName:
          'Civil and Environmental Engineering 2217A/B - INTRODUCTION TO ENVIRONMENTAL ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 2217A/B - INTRODUCTION TO ENVIRONMENTAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211c47f7e10015fd5fe3',
      },
      {
        _id: '60cc211c47f7e10015fd5fe5',
        courseCode: 'CEE 2219',
        shortName:
          'Civil and Environmental Engineering 2219A/B - COMPUTATIONAL TOOLS FOR CIVIL ENGINEERS',
        courseName:
          'Civil and Environmental Engineering 2219A/B - COMPUTATIONAL TOOLS FOR CIVIL ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211c47f7e10015fd5fe5',
      },
      {
        _id: '60cc211c47f7e10015fd5fe7',
        courseCode: 'CEE 2220',
        shortName:
          'Civil and Environmental Engineering 2220A/B - INTRODUCTION TO STRUCTURAL ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 2220A/B - INTRODUCTION TO STRUCTURAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211c47f7e10015fd5fe7',
      },
      {
        _id: '60cc211d47f7e10015fd5fe9',
        courseCode: 'CEE 2221',
        shortName:
          'Civil and Environmental Engineering 2221A/B - STRUCTURAL THEORY & DESIGN',
        courseName:
          'Civil and Environmental Engineering 2221A/B - STRUCTURAL THEORY & DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211d47f7e10015fd5fe9',
      },
      {
        _id: '60cc211d47f7e10015fd5feb',
        courseCode: 'CEE 2224',
        shortName:
          'Civil and Environmental Engineering 2224 - ENGINEERING FLUID MECHANICS',
        courseName:
          'Civil and Environmental Engineering 2224 - ENGINEERING FLUID MECHANICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211d47f7e10015fd5feb',
      },
      {
        _id: '60cc211d47f7e10015fd5fed',
        courseCode: 'CEE 3321',
        shortName:
          'Civil and Environmental Engineering 3321A/B - SOIL MECHANICS & HYDROGEOLOGIC ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 3321A/B - SOIL MECHANICS & HYDROGEOLOGIC ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211d47f7e10015fd5fed',
      },
      {
        _id: '60cc211d47f7e10015fd5fef',
        courseCode: 'CEE 3322',
        shortName:
          'Civil and Environmental Engineering 3322A/B - INTRODUCTION TO GEOTECHNICAL ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 3322A/B - INTRODUCTION TO GEOTECHNICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211d47f7e10015fd5fef',
      },
      {
        _id: '60cc211e47f7e10015fd5ff1',
        courseCode: 'CEE 3324',
        shortName: 'Civil and Environmental Engineering 3324A/B - SURVEYING',
        courseName: 'Civil and Environmental Engineering 3324A/B - SURVEYING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211e47f7e10015fd5ff1',
      },
      {
        _id: '60cc211e47f7e10015fd5ff3',
        courseCode: 'CEE 3327',
        shortName:
          'Civil and Environmental Engineering 3327A/B - INTERNATIONAL DEVELOPMENT FOR ENGINEERS',
        courseName:
          'Civil and Environmental Engineering 3327A/B - INTERNATIONAL DEVELOPMENT FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211e47f7e10015fd5ff3',
      },
      {
        _id: '60cc211e47f7e10015fd5ff5',
        courseCode: 'CEE 3328',
        shortName:
          'Civil and Environmental Engineering 3328A/B - APPROPRIATE TECHNOLOGIES FOR INTERNATIONAL DEVELOPMENT',
        courseName:
          'Civil and Environmental Engineering 3328A/B - APPROPRIATE TECHNOLOGIES FOR INTERNATIONAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211e47f7e10015fd5ff5',
      },
      {
        _id: '60cc211e47f7e10015fd5ff7',
        courseCode: 'CEE 3340',
        shortName:
          'Civil and Environmental Engineering 3340A/B - ANALYSIS OF INDETERMINATE STRUCTURES',
        courseName:
          'Civil and Environmental Engineering 3340A/B - ANALYSIS OF INDETERMINATE STRUCTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211e47f7e10015fd5ff7',
      },
      {
        _id: '60cc211f47f7e10015fd5ffa',
        courseCode: 'CEE 3344',
        shortName:
          'Civil and Environmental Engineering 3344A/B - STRUCTURAL DYNAMICS I',
        courseName:
          'Civil and Environmental Engineering 3344A/B - STRUCTURAL DYNAMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211f47f7e10015fd5ffa',
      },
      {
        _id: '60cc211f47f7e10015fd5ffc',
        courseCode: 'CEE 3346',
        shortName: 'Civil and Environmental Engineering 3346A/B - STEEL DESIGN',
        courseName:
          'Civil and Environmental Engineering 3346A/B - STEEL DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211f47f7e10015fd5ffc',
      },
      {
        _id: '60cc211f47f7e10015fd5ffe',
        courseCode: 'CEE 3347',
        shortName:
          'Civil and Environmental Engineering 3347A/B - REINFORCED CONCRETE DESIGN',
        courseName:
          'Civil and Environmental Engineering 3347A/B - REINFORCED CONCRETE DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211f47f7e10015fd5ffe',
      },
      {
        _id: '60cc211f47f7e10015fd6000',
        courseCode: 'CEE 3348',
        shortName:
          'Civil and Environmental Engineering 3348A/B - PROJECT MANAGEMENT & ENGINEERING CASES',
        courseName:
          'Civil and Environmental Engineering 3348A/B - PROJECT MANAGEMENT & ENGINEERING CASES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc211f47f7e10015fd6000',
      },
      {
        _id: '60cc212047f7e10015fd6002',
        courseCode: 'CEE 3355',
        shortName:
          'Civil and Environmental Engineering 3355A/B - MUNICIPAL ENGINEERING DESIGN',
        courseName:
          'Civil and Environmental Engineering 3355A/B - MUNICIPAL ENGINEERING DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212047f7e10015fd6002',
      },
      {
        _id: '60cc212047f7e10015fd6004',
        courseCode: 'CEE 3358',
        shortName:
          'Civil and Environmental Engineering 3358A/B - REINFORCED & PRESTRESSED CONCRETE DESIGN',
        courseName:
          'Civil and Environmental Engineering 3358A/B - REINFORCED & PRESTRESSED CONCRETE DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212047f7e10015fd6004',
      },
      {
        _id: '60cc212047f7e10015fd6006',
        courseCode: 'CEE 3361',
        shortName:
          'Civil and Environmental Engineering 3361A/B - WATER RESOURCES MANAGEMENT',
        courseName:
          'Civil and Environmental Engineering 3361A/B - WATER RESOURCES MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212047f7e10015fd6006',
      },
      {
        _id: '60cc212047f7e10015fd6008',
        courseCode: 'CEE 3362',
        shortName:
          'Civil and Environmental Engineering 3362A/B - DRINKING WATER QUALITY & TREATMENT',
        courseName:
          'Civil and Environmental Engineering 3362A/B - DRINKING WATER QUALITY & TREATMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212047f7e10015fd6008',
      },
      {
        _id: '60cc212147f7e10015fd600a',
        courseCode: 'CEE 3369',
        shortName:
          'Civil and Environmental Engineering 3369A/B - MATERIALS FOR CIVIL ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 3369A/B - MATERIALS FOR CIVIL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212147f7e10015fd600a',
      },
      {
        _id: '60cc212147f7e10015fd600c',
        courseCode: 'CEE 3386',
        shortName:
          'Civil and Environmental Engineering 3386A/B - NUMERICAL MODELING FOR ENVIRONMENTAL ENGINEERS',
        courseName:
          'Civil and Environmental Engineering 3386A/B - NUMERICAL MODELING FOR ENVIRONMENTAL ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212147f7e10015fd600c',
      },
      {
        _id: '60cc212147f7e10015fd600e',
        courseCode: 'CEE 4401',
        shortName:
          'Civil and Environmental Engineering 4401A/B - PRINCIPLES OF TRANSPORTATION ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 4401A/B - PRINCIPLES OF TRANSPORTATION ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212147f7e10015fd600e',
      },
      {
        _id: '60cc212147f7e10015fd6011',
        courseCode: 'CEE 4405',
        shortName:
          'Civil and Environmental Engineering 4405A/B - AIR POLLUTION',
        courseName:
          'Civil and Environmental Engineering 4405A/B - AIR POLLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212147f7e10015fd6011',
      },
      {
        _id: '60cc212247f7e10015fd6014',
        courseCode: 'CEE 4424',
        shortName:
          'Civil and Environmental Engineering 4424A/B - EARTH STRUCTURES ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 4424A/B - EARTH STRUCTURES ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212247f7e10015fd6014',
      },
      {
        _id: '60cc212247f7e10015fd6016',
        courseCode: 'CEE 4426',
        shortName:
          'Civil and Environmental Engineering 4426A/B - GEOTECHNICAL ENGINEERING DESIGN',
        courseName:
          'Civil and Environmental Engineering 4426A/B - GEOTECHNICAL ENGINEERING DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212247f7e10015fd6016',
      },
      {
        _id: '60cc212247f7e10015fd6018',
        courseCode: 'CEE 4427',
        shortName:
          'Civil and Environmental Engineering 4427A/B - SPECIAL TOPICS IN INTERNATIONAL DEVELOPMENT',
        courseName:
          'Civil and Environmental Engineering 4427A/B - SPECIAL TOPICS IN INTERNATIONAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212247f7e10015fd6018',
      },
      {
        _id: '60cc212347f7e10015fd601a',
        courseCode: 'CEE 4428',
        shortName:
          'Civil and Environmental Engineering 4428A/B - SELECTED TOPICS IN CIVIL ENGINEERING I',
        courseName:
          'Civil and Environmental Engineering 4428A/B - SELECTED TOPICS IN CIVIL ENGINEERING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212347f7e10015fd601a',
      },
      {
        _id: '60cc212347f7e10015fd601c',
        courseCode: 'CEE 4429',
        shortName:
          'Civil and Environmental Engineering 4429A/B - SELECTED TOPICS IN CIVIL ENGINEERING II',
        courseName:
          'Civil and Environmental Engineering 4429A/B - SELECTED TOPICS IN CIVIL ENGINEERING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212347f7e10015fd601c',
      },
      {
        _id: '60cc212347f7e10015fd601e',
        courseCode: 'CEE 4438',
        shortName:
          'Civil and Environmental Engineering 4438A/B - INTRODUCTION TO WOOD DESIGN',
        courseName:
          'Civil and Environmental Engineering 4438A/B - INTRODUCTION TO WOOD DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212347f7e10015fd601e',
      },
      {
        _id: '60cc212347f7e10015fd6020',
        courseCode: 'CEE 4440',
        shortName:
          'Civil and Environmental Engineering 4440 - CIVIL ENGINEERING THESIS',
        courseName:
          'Civil and Environmental Engineering 4440 - CIVIL ENGINEERING THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212347f7e10015fd6020',
      },
      {
        _id: '60cc212347f7e10015fd6022',
        courseCode: 'CEE 4441',
        shortName:
          'Civil and Environmental Engineering 4441 - CIVIL ENGINEERING DESIGN PROJECT',
        courseName:
          'Civil and Environmental Engineering 4441 - CIVIL ENGINEERING DESIGN PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212347f7e10015fd6022',
      },
      {
        _id: '60cc212447f7e10015fd6024',
        courseCode: 'CEE 4458',
        shortName:
          'Civil and Environmental Engineering 4458A/B - RISK ANALYSIS & DECISION MAKING IN ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 4458A/B - RISK ANALYSIS & DECISION MAKING IN ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212447f7e10015fd6024',
      },
      {
        _id: '60cc212447f7e10015fd6026',
        courseCode: 'CEE 4459',
        shortName:
          'Civil and Environmental Engineering 4459A/B - DESIGN OF LATERAL LOAD STRUCTURAL SYSTEMS',
        courseName:
          'Civil and Environmental Engineering 4459A/B - DESIGN OF LATERAL LOAD STRUCTURAL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212447f7e10015fd6026',
      },
      {
        _id: '60cc212447f7e10015fd6028',
        courseCode: 'CEE 4463',
        shortName:
          'Civil and Environmental Engineering 4463A/B - WATERSHED MODELING',
        courseName:
          'Civil and Environmental Engineering 4463A/B - WATERSHED MODELING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212447f7e10015fd6028',
      },
      {
        _id: '60cc212447f7e10015fd602a',
        courseCode: 'CEE 4465',
        shortName:
          'Civil and Environmental Engineering 4465A/B - ENVIRONMENTAL DESIGN FOR WASTE DISPOSAL',
        courseName:
          'Civil and Environmental Engineering 4465A/B - ENVIRONMENTAL DESIGN FOR WASTE DISPOSAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212447f7e10015fd602a',
      },
      {
        _id: '60cc212547f7e10015fd602c',
        courseCode: 'CEE 4476',
        shortName:
          'Civil and Environmental Engineering 4476A/B - ENVIRONMENTAL HYDRAULICS DESIGN',
        courseName:
          'Civil and Environmental Engineering 4476A/B - ENVIRONMENTAL HYDRAULICS DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212547f7e10015fd602c',
      },
      {
        _id: '60cc212547f7e10015fd602e',
        courseCode: 'CEE 4477',
        shortName:
          'Civil and Environmental Engineering 4477A/B - ENVIRONMENTAL APPLICATIONS OF NANOTECHNOLOGY',
        courseName:
          'Civil and Environmental Engineering 4477A/B - ENVIRONMENTAL APPLICATIONS OF NANOTECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212547f7e10015fd602e',
      },
      {
        _id: '60cc212547f7e10015fd6030',
        courseCode: 'CEE 4478',
        shortName:
          'Civil and Environmental Engineering 4478A/B - CASE STUDIES IN CIVIL ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 4478A/B - CASE STUDIES IN CIVIL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212547f7e10015fd6030',
      },
      {
        _id: '60cc212547f7e10015fd6033',
        courseCode: 'CEE 4480',
        shortName:
          'Civil and Environmental Engineering 4480A/B - WIND ENGINEERING',
        courseName:
          'Civil and Environmental Engineering 4480A/B - WIND ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212547f7e10015fd6033',
      },
      {
        _id: '60cc212647f7e10015fd6035',
        courseCode: 'CEE 4485',
        shortName:
          'Civil and Environmental Engineering 4485A/B - CITIES: RESILIENCE AND SUSTAINABILITY',
        courseName:
          'Civil and Environmental Engineering 4485A/B - CITIES: RESILIENCE AND SUSTAINABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212647f7e10015fd6035',
      },
      {
        _id: '60cc212647f7e10015fd6037',
        courseCode: 'CEE 4491',
        shortName:
          'Civil and Environmental Engineering 4491A/B - STRUCTURAL DYNAMICS II',
        courseName:
          'Civil and Environmental Engineering 4491A/B - STRUCTURAL DYNAMICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212647f7e10015fd6037',
      },
      {
        _id: '60cc212647f7e10015fd6039',
        courseCode: 'CLASSICS 1000',
        shortName: 'Classical Studies 1000 - ANCIENT GREECE AND ROME',
        courseName: 'Classical Studies 1000 - ANCIENT GREECE AND ROME',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 1,
        id: '60cc212647f7e10015fd6039',
      },
      {
        _id: '60cc212647f7e10015fd603b',
        courseCode: 'CLASSICS 2200',
        shortName: 'Classical Studies 2200 - CLASSICAL MYTHOLOGY',
        courseName: 'Classical Studies 2200 - CLASSICAL MYTHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212647f7e10015fd603b',
      },
      {
        _id: '60cc212747f7e10015fd603d',
        courseCode: 'CLASSICS 2300',
        shortName:
          'Classical Studies 2300 - SPORT & RECREATION IN THE ANCIENT WORLD',
        courseName:
          'Classical Studies 2300 - SPORT & RECREATION IN THE ANCIENT WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212747f7e10015fd603d',
      },
      {
        _id: '60cc212747f7e10015fd603f',
        courseCode: 'CLASSICS 2301',
        shortName:
          'Classical Studies 2301A/B - CRIME AND PUNISHMENT IN ANCIENT GREECE AND ROME',
        courseName:
          'Classical Studies 2301A/B - CRIME AND PUNISHMENT IN ANCIENT GREECE AND ROME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212747f7e10015fd603f',
      },
      {
        _id: '60cc212747f7e10015fd6041',
        courseCode: 'CLASSICS 2350',
        shortName:
          'Classical Studies 2350A/B - ROMAN CONQUESTS AND CULTURAL IDENTITIES',
        courseName:
          'Classical Studies 2350A/B - ROMAN CONQUESTS AND CULTURAL IDENTITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212747f7e10015fd6041',
      },
      {
        _id: '60cc212747f7e10015fd6043',
        courseCode: 'CLASSICS 2440',
        shortName: 'Classical Studies 2440A/B - ALEXANDER THE GREAT',
        courseName: 'Classical Studies 2440A/B - ALEXANDER THE GREAT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212747f7e10015fd6043',
      },
      {
        _id: '60cc212847f7e10015fd6045',
        courseCode: 'CLASSICS 2480',
        shortName:
          'Classical Studies 2480A/B - ROMAN EMPERORS: THE GOOD, THE BAD, AND THE UGLY',
        courseName:
          'Classical Studies 2480A/B - ROMAN EMPERORS: THE GOOD, THE BAD, AND THE UGLY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212847f7e10015fd6045',
      },
      {
        _id: '60cc212847f7e10015fd6047',
        courseCode: 'CLASSICS 2500',
        shortName:
          'Classical Studies 2500A/B - ANCIENT CITIES IN THE MEDITERRANEAN',
        courseName:
          'Classical Studies 2500A/B - ANCIENT CITIES IN THE MEDITERRANEAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212847f7e10015fd6047',
      },
      {
        _id: '60cc212847f7e10015fd6049',
        courseCode: 'CLASSICS 2525',
        shortName: 'Classical Studies 2525A/B - EGYPTIAN ART AND ARCHITECTURE',
        courseName: 'Classical Studies 2525A/B - EGYPTIAN ART AND ARCHITECTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212847f7e10015fd6049',
      },
      {
        _id: '60cc212847f7e10015fd604b',
        courseCode: 'CLASSICS 2700',
        shortName:
          'Classical Studies 2700A/B - TECHNOLOGY & ENGINEERING IN THE ANCIENT WORLD',
        courseName:
          'Classical Studies 2700A/B - TECHNOLOGY & ENGINEERING IN THE ANCIENT WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212847f7e10015fd604b',
      },
      {
        _id: '60cc212847f7e10015fd604d',
        courseCode: 'CLASSICS 2710',
        shortName: 'Classical Studies 2710A/B - ANCIENT GREEK SCIENCE',
        courseName: 'Classical Studies 2710A/B - ANCIENT GREEK SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212847f7e10015fd604d',
      },
      {
        _id: '60cc212947f7e10015fd604f',
        courseCode: 'CLASSICS 2800',
        shortName:
          'Classical Studies 2800A/B - GREEK AND LATIN ELEMENTS IN ENGLISH',
        courseName:
          'Classical Studies 2800A/B - GREEK AND LATIN ELEMENTS IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212947f7e10015fd604f',
      },
      {
        _id: '60cc212947f7e10015fd6051',
        courseCode: 'CLASSICS 2810',
        shortName: 'Classical Studies 2810A/B - ANCIENT GREECE ON FILM',
        courseName: 'Classical Studies 2810A/B - ANCIENT GREECE ON FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212947f7e10015fd6051',
      },
      {
        _id: '60cc212947f7e10015fd6053',
        courseCode: 'CLASSICS 2840',
        shortName:
          'Classical Studies 2840A/B - CLEOPATRA: HISTORIES, DREAMS, AND DISTORTIONS',
        courseName:
          'Classical Studies 2840A/B - CLEOPATRA: HISTORIES, DREAMS, AND DISTORTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212947f7e10015fd6053',
      },
      {
        _id: '60cc212947f7e10015fd6055',
        courseCode: 'CLASSICS 2900',
        shortName:
          'Classical Studies 2900 - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2900 - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212947f7e10015fd6055',
      },
      {
        _id: '60cc212a47f7e10015fd6057',
        courseCode: 'CLASSICS 2901',
        shortName:
          'Classical Studies 2901 - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2901 - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212a47f7e10015fd6057',
      },
      {
        _id: '60cc212a47f7e10015fd6059',
        courseCode: 'CLASSICS 2902',
        shortName:
          'Classical Studies 2902A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2902A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212a47f7e10015fd6059',
      },
      {
        _id: '60cc212a47f7e10015fd605b',
        courseCode: 'CLASSICS 2903',
        shortName:
          'Classical Studies 2903A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2903A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212a47f7e10015fd605b',
      },
      {
        _id: '60cc212a47f7e10015fd605d',
        courseCode: 'CLASSICS 2904',
        shortName:
          'Classical Studies 2904A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2904A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212a47f7e10015fd605d',
      },
      {
        _id: '60cc212a47f7e10015fd605f',
        courseCode: 'CLASSICS 2905',
        shortName:
          'Classical Studies 2905A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2905A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212a47f7e10015fd605f',
      },
      {
        _id: '60cc212b47f7e10015fd6061',
        courseCode: 'CLASSICS 2906',
        shortName:
          'Classical Studies 2906A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2906A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212b47f7e10015fd6061',
      },
      {
        _id: '60cc212b47f7e10015fd6063',
        courseCode: 'CLASSICS 2907',
        shortName:
          'Classical Studies 2907A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2907A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212b47f7e10015fd6063',
      },
      {
        _id: '60cc212b47f7e10015fd6065',
        courseCode: 'CLASSICS 2908',
        shortName:
          'Classical Studies 2908A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2908A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212b47f7e10015fd6065',
      },
      {
        _id: '60cc212b47f7e10015fd6067',
        courseCode: 'CLASSICS 2909',
        shortName:
          'Classical Studies 2909A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 2909A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212b47f7e10015fd6067',
      },
      {
        _id: '60cc212c47f7e10015fd6069',
        courseCode: 'CLASSICS 3010',
        shortName:
          'Classical Studies 3010F/G - STUDY TOUR TO GREECE: ANCIENT GREEK HISTORY, ARCHAEOLOGY & CULTURE',
        courseName:
          'Classical Studies 3010F/G - STUDY TOUR TO GREECE: ANCIENT GREEK HISTORY, ARCHAEOLOGY & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212c47f7e10015fd6069',
      },
      {
        _id: '60cc212c47f7e10015fd606b',
        courseCode: 'CLASSICS 3050',
        shortName:
          'Classical Studies 3050F/G - STUDY TOUR TO ITALY: ROMAN HISTORY, ARCHAEOLOGY AND CULTURE',
        courseName:
          'Classical Studies 3050F/G - STUDY TOUR TO ITALY: ROMAN HISTORY, ARCHAEOLOGY AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212c47f7e10015fd606b',
      },
      {
        _id: '60cc212c47f7e10015fd606d',
        courseCode: 'CLASSICS 3100',
        shortName: 'Classical Studies 3100E - GREEK AND ROMAN DRAMA',
        courseName: 'Classical Studies 3100E - GREEK AND ROMAN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212c47f7e10015fd606d',
      },
      {
        _id: '60cc212c47f7e10015fd606f',
        courseCode: 'CLASSICS 3102',
        shortName: 'Classical Studies 3102F/G - THE ANCIENT NOVEL',
        courseName: 'Classical Studies 3102F/G - THE ANCIENT NOVEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212c47f7e10015fd606f',
      },
      {
        _id: '60cc212d47f7e10015fd6071',
        courseCode: 'CLASSICS 3110',
        shortName: 'Classical Studies 3110F/G - GREEK EPIC',
        courseName: 'Classical Studies 3110F/G - GREEK EPIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212d47f7e10015fd6071',
      },
      {
        _id: '60cc212d47f7e10015fd6073',
        courseCode: 'CLASSICS 3130',
        shortName: 'Classical Studies 3130F/G - ATHENIAN DRAMA',
        courseName: 'Classical Studies 3130F/G - ATHENIAN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212d47f7e10015fd6073',
      },
      {
        _id: '60cc212d47f7e10015fd6075',
        courseCode: 'CLASSICS 3150',
        shortName: 'Classical Studies 3150F/G - ROMAN EPIC',
        courseName: 'Classical Studies 3150F/G - ROMAN EPIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212d47f7e10015fd6075',
      },
      {
        _id: '60cc212d47f7e10015fd6077',
        courseCode: 'CLASSICS 3151',
        shortName: 'Classical Studies 3151F/G - THE AGE OF AUGUSTUS',
        courseName: 'Classical Studies 3151F/G - THE AGE OF AUGUSTUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212d47f7e10015fd6077',
      },
      {
        _id: '60cc212d47f7e10015fd6079',
        courseCode: 'CLASSICS 3181',
        shortName: 'Classical Studies 3181F/G - THE AGE OF NERO',
        courseName: 'Classical Studies 3181F/G - THE AGE OF NERO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212d47f7e10015fd6079',
      },
      {
        _id: '60cc212e47f7e10015fd607b',
        courseCode: 'CLASSICS 3210',
        shortName: 'Classical Studies 3210F/G - ANCIENT GREEK RELIGION',
        courseName: 'Classical Studies 3210F/G - ANCIENT GREEK RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212e47f7e10015fd607b',
      },
      {
        _id: '60cc212e47f7e10015fd607d',
        courseCode: 'CLASSICS 3211',
        shortName:
          'Classical Studies 3211F/G - ANCIENT  TRICKSTERS, GLOBAL PERSPECTIVES',
        courseName:
          'Classical Studies 3211F/G - ANCIENT  TRICKSTERS, GLOBAL PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212e47f7e10015fd607d',
      },
      {
        _id: '60cc212e47f7e10015fd607f',
        courseCode: 'CLASSICS 3300',
        shortName:
          'Classical Studies 3300F/G - ANCIENT GREEK AND ROMAN SEXUALITY',
        courseName:
          'Classical Studies 3300F/G - ANCIENT GREEK AND ROMAN SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212e47f7e10015fd607f',
      },
      {
        _id: '60cc212e47f7e10015fd6081',
        courseCode: 'CLASSICS 3302',
        shortName: 'Classical Studies 3302E - GREEK AND ROMAN WARFARE',
        courseName: 'Classical Studies 3302E - GREEK AND ROMAN WARFARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212e47f7e10015fd6081',
      },
      {
        _id: '60cc212f47f7e10015fd6083',
        courseCode: 'CLASSICS 3303',
        shortName:
          'Classical Studies 3303F/G - TRAVEL AND GEOGRAPHY IN THE ANCIENT WORLD',
        courseName:
          'Classical Studies 3303F/G - TRAVEL AND GEOGRAPHY IN THE ANCIENT WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212f47f7e10015fd6083',
      },
      {
        _id: '60cc212f47f7e10015fd6085',
        courseCode: 'CLASSICS 3310',
        shortName: 'Classical Studies 3310F/G - WOMEN IN ANCIENT GREECE',
        courseName: 'Classical Studies 3310F/G - WOMEN IN ANCIENT GREECE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc212f47f7e10015fd6085',
      },
      {
        _id: '60cc213147f7e10015fd6087',
        courseCode: 'CLASSICS 3350',
        shortName: 'Classical Studies 3350F/G - WOMEN IN ANCIENT ROME',
        courseName: 'Classical Studies 3350F/G - WOMEN IN ANCIENT ROME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213147f7e10015fd6087',
      },
      {
        _id: '60cc213147f7e10015fd6089',
        courseCode: 'CLASSICS 3410',
        shortName: 'Classical Studies 3410E - GREEK HISTORY',
        courseName: 'Classical Studies 3410E - GREEK HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213147f7e10015fd6089',
      },
      {
        _id: '60cc213147f7e10015fd608b',
        courseCode: 'CLASSICS 3450',
        shortName: 'Classical Studies 3450E - ROMAN HISTORY',
        courseName: 'Classical Studies 3450E - ROMAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213147f7e10015fd608b',
      },
      {
        _id: '60cc213247f7e10015fd608d',
        courseCode: 'CLASSICS 3490',
        shortName: 'Classical Studies 3490F/G - LATE ANTIQUITY',
        courseName: 'Classical Studies 3490F/G - LATE ANTIQUITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213247f7e10015fd608d',
      },
      {
        _id: '60cc213247f7e10015fd608f',
        courseCode: 'CLASSICS 3500',
        shortName: 'Classical Studies 3500F/G - GREEK AND ROMAN PAINTING',
        courseName: 'Classical Studies 3500F/G - GREEK AND ROMAN PAINTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213247f7e10015fd608f',
      },
      {
        _id: '60cc213247f7e10015fd6091',
        courseCode: 'CLASSICS 3515',
        shortName: 'Classical Studies 3515F/G - GREECE AND THE EAST',
        courseName: 'Classical Studies 3515F/G - GREECE AND THE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213247f7e10015fd6091',
      },
      {
        _id: '60cc213247f7e10015fd6093',
        courseCode: 'CLASSICS 3525',
        shortName: 'Classical Studies 3525F/G - AEGEAN BRONZE AGE ARCHAEOLOGY',
        courseName: 'Classical Studies 3525F/G - AEGEAN BRONZE AGE ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213247f7e10015fd6093',
      },
      {
        _id: '60cc213347f7e10015fd6095',
        courseCode: 'CLASSICS 3530',
        shortName: 'Classical Studies 3530E - GREEK ART AND ARCHAEOLOGY',
        courseName: 'Classical Studies 3530E - GREEK ART AND ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213347f7e10015fd6095',
      },
      {
        _id: '60cc213347f7e10015fd6097',
        courseCode: 'CLASSICS 3550',
        shortName: 'Classical Studies 3550E - ARCHAEOLOGY OF ROME AND ITALY',
        courseName: 'Classical Studies 3550E - ARCHAEOLOGY OF ROME AND ITALY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213347f7e10015fd6097',
      },
      {
        _id: '60cc213347f7e10015fd6099',
        courseCode: 'CLASSICS 3555',
        shortName: 'Classical Studies 3555E - ARCHAEOLOGY OF THE ROMAN EMPIRE',
        courseName: 'Classical Studies 3555E - ARCHAEOLOGY OF THE ROMAN EMPIRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213347f7e10015fd6099',
      },
      {
        _id: '60cc213347f7e10015fd609b',
        courseCode: 'CLASSICS 3585',
        shortName: 'Classical Studies 3585F/G - ARCHAEOLOGY OF POMPEII',
        courseName: 'Classical Studies 3585F/G - ARCHAEOLOGY OF POMPEII',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213347f7e10015fd609b',
      },
      {
        _id: '60cc213347f7e10015fd609d',
        courseCode: 'CLASSICS 3612',
        shortName:
          'Classical Studies 3612F/G - GREEK POLITICAL THOUGHT:  PLATO TO ARISTOTLE',
        courseName:
          'Classical Studies 3612F/G - GREEK POLITICAL THOUGHT:  PLATO TO ARISTOTLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213347f7e10015fd609d',
      },
      {
        _id: '60cc213447f7e10015fd609f',
        courseCode: 'CLASSICS 3800',
        shortName: 'Classical Studies 3800F/G - CLASSICS AND POP CULTURE',
        courseName: 'Classical Studies 3800F/G - CLASSICS AND POP CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213447f7e10015fd609f',
      },
      {
        _id: '60cc213447f7e10015fd60a1',
        courseCode: 'CLASSICS 3850',
        shortName:
          'Classical Studies 3850F/G - THE LIFE AND LEGACY OF JULIUS CAESAR',
        courseName:
          'Classical Studies 3850F/G - THE LIFE AND LEGACY OF JULIUS CAESAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213447f7e10015fd60a1',
      },
      {
        _id: '60cc213447f7e10015fd60a3',
        courseCode: 'CLASSICS 3900',
        shortName:
          'Classical Studies 3900E - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3900E - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213447f7e10015fd60a3',
      },
      {
        _id: '60cc213447f7e10015fd60a5',
        courseCode: 'CLASSICS 3901',
        shortName:
          'Classical Studies 3901E - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3901E - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213447f7e10015fd60a5',
      },
      {
        _id: '60cc213547f7e10015fd60a7',
        courseCode: 'CLASSICS 3902',
        shortName:
          'Classical Studies 3902E - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3902E - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213547f7e10015fd60a7',
      },
      {
        _id: '60cc213547f7e10015fd60a9',
        courseCode: 'CLASSICS 3903',
        shortName:
          'Classical Studies 3903F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3903F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213547f7e10015fd60a9',
      },
      {
        _id: '60cc213547f7e10015fd60ab',
        courseCode: 'CLASSICS 3904',
        shortName:
          'Classical Studies 3904F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3904F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213547f7e10015fd60ab',
      },
      {
        _id: '60cc213547f7e10015fd60ad',
        courseCode: 'CLASSICS 3905',
        shortName:
          'Classical Studies 3905F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3905F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213547f7e10015fd60ad',
      },
      {
        _id: '60cc213547f7e10015fd60af',
        courseCode: 'CLASSICS 3906',
        shortName:
          'Classical Studies 3906F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 3906F/G - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213547f7e10015fd60af',
      },
      {
        _id: '60cc213647f7e10015fd60b1',
        courseCode: 'CLASSICS 4410',
        shortName:
          'Classical Studies 4410F/G - SELECTED TOPICS IN GREEK HISTORY',
        courseName:
          'Classical Studies 4410F/G - SELECTED TOPICS IN GREEK HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213647f7e10015fd60b1',
      },
      {
        _id: '60cc213647f7e10015fd60b3',
        courseCode: 'CLASSICS 4450',
        shortName:
          'Classical Studies 4450F/G - SELECTED TOPICS IN ROMAN HISTORY',
        courseName:
          'Classical Studies 4450F/G - SELECTED TOPICS IN ROMAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213647f7e10015fd60b3',
      },
      {
        _id: '60cc213647f7e10015fd60b5',
        courseCode: 'CLASSICS 4500',
        shortName:
          'Classical Studies 4500F/G - ARCHAEOLOGICAL RESEARCH PROJECT',
        courseName:
          'Classical Studies 4500F/G - ARCHAEOLOGICAL RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213647f7e10015fd60b5',
      },
      {
        _id: '60cc213647f7e10015fd60b7',
        courseCode: 'CLASSICS 4510',
        shortName:
          'Classical Studies 4510F/G - SPECIAL TOPICS IN GREEK ARCHAEOLOGY',
        courseName:
          'Classical Studies 4510F/G - SPECIAL TOPICS IN GREEK ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213647f7e10015fd60b7',
      },
      {
        _id: '60cc213747f7e10015fd60b9',
        courseCode: 'CLASSICS 4512',
        shortName:
          'Classical Studies 4512F/G - GREEK ARCHAEOLOGY FIELD PRACTICUM',
        courseName:
          'Classical Studies 4512F/G - GREEK ARCHAEOLOGY FIELD PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213747f7e10015fd60b9',
      },
      {
        _id: '60cc213747f7e10015fd60bb',
        courseCode: 'CLASSICS 4550',
        shortName:
          'Classical Studies 4550F/G - SPECIAL TOPICS IN ROMAN ARCHAEOLOGY',
        courseName:
          'Classical Studies 4550F/G - SPECIAL TOPICS IN ROMAN ARCHAEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213747f7e10015fd60bb',
      },
      {
        _id: '60cc213747f7e10015fd60bd',
        courseCode: 'CLASSICS 4552',
        shortName:
          'Classical Studies 4552F/G - ROMAN ARCHAEOLOGY FIELD PRACTICUM',
        courseName:
          'Classical Studies 4552F/G - ROMAN ARCHAEOLOGY FIELD PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213747f7e10015fd60bd',
      },
      {
        _id: '60cc213747f7e10015fd60bf',
        courseCode: 'CLASSICS 4580',
        shortName: 'Classical Studies 4580F/G - VINDOLANDA FIELD SCHOOL',
        courseName: 'Classical Studies 4580F/G - VINDOLANDA FIELD SCHOOL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213747f7e10015fd60bf',
      },
      {
        _id: '60cc213747f7e10015fd60c1',
        courseCode: 'CLASSICS 4585',
        shortName: 'Classical Studies 4585F/G - VINDOLANDA RESEARCH PROJECT',
        courseName: 'Classical Studies 4585F/G - VINDOLANDA RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213747f7e10015fd60c1',
      },
      {
        _id: '60cc213847f7e10015fd60c3',
        courseCode: 'CLASSICS 4900',
        shortName:
          'Classical Studies 4900A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        courseName:
          'Classical Studies 4900A/B - SPECIAL TOPICS IN CLASSICAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213847f7e10015fd60c3',
      },
      {
        _id: '60cc213847f7e10015fd60c5',
        courseCode: 'CLASSICS 4999',
        shortName: 'Classical Studies 4999E - HONOURS THESIS',
        courseName: 'Classical Studies 4999E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213847f7e10015fd60c5',
      },
      {
        _id: '60cc213847f7e10015fd60c7',
        courseCode: 'COMMSCI 3317',
        shortName:
          'Communication Sciences and Disorders 3317A/B - HUMAN RHYTHMS',
        courseName:
          'Communication Sciences and Disorders 3317A/B - HUMAN RHYTHMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213847f7e10015fd60c7',
      },
      {
        _id: '60cc213847f7e10015fd60c9',
        courseCode: 'COMMSCI 4411',
        shortName:
          'Communication Sciences and Disorders 4411A/B - INTRODUCTION TO SPEECH AND LANGUAGE DISORDERS',
        courseName:
          'Communication Sciences and Disorders 4411A/B - INTRODUCTION TO SPEECH AND LANGUAGE DISORDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213847f7e10015fd60c9',
      },
      {
        _id: '60cc213947f7e10015fd60cb',
        courseCode: 'COMMSCI 4417',
        shortName:
          'Communication Sciences and Disorders 4417A/B - HEARING SCIENCE',
        courseName:
          'Communication Sciences and Disorders 4417A/B - HEARING SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213947f7e10015fd60cb',
      },
      {
        _id: '60cc213947f7e10015fd60cd',
        courseCode: 'COMMSCI 4439',
        shortName:
          'Communication Sciences and Disorders 4439A/B - NORMAL LANGUAGE  ACQUISITION',
        courseName:
          'Communication Sciences and Disorders 4439A/B - NORMAL LANGUAGE  ACQUISITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213947f7e10015fd60cd',
      },
      {
        _id: '60cc213947f7e10015fd60cf',
        courseCode: 'COMPLIT 1010',
        shortName:
          'Comparative Literature and Culture 1010 - FROM EAST TO WEST AND NORTH TO SOUTH',
        courseName:
          'Comparative Literature and Culture 1010 - FROM EAST TO WEST AND NORTH TO SOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213947f7e10015fd60cf',
      },
      {
        _id: '60cc213947f7e10015fd60d1',
        courseCode: 'COMPLIT 1040',
        shortName:
          'Comparative Literature and Culture 1040 - FIRST YEAR SEMINAR: IDEAS AND APPS THAT CHANGED THE WORLD',
        courseName:
          'Comparative Literature and Culture 1040 - FIRST YEAR SEMINAR: IDEAS AND APPS THAT CHANGED THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213947f7e10015fd60d1',
      },
      {
        _id: '60cc213a47f7e10015fd60d4',
        courseCode: 'COMPLIT 1060',
        shortName:
          'Comparative Literature and Culture 1060 - BACK TO THE FUTURE! WORLD VISIONS OF "THINGS TO COME"',
        courseName:
          'Comparative Literature and Culture 1060 - BACK TO THE FUTURE! WORLD VISIONS OF "THINGS TO COME"',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213a47f7e10015fd60d4',
      },
      {
        _id: '60cc213a47f7e10015fd60d6',
        courseCode: 'COMPLIT 2102',
        shortName:
          'Comparative Literature and Culture 2102A/B - UTOPIAS AND VISIONS OF THE FUTURE',
        courseName:
          'Comparative Literature and Culture 2102A/B - UTOPIAS AND VISIONS OF THE FUTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213a47f7e10015fd60d6',
      },
      {
        _id: '60cc213a47f7e10015fd60d8',
        courseCode: 'COMPLIT 2103',
        shortName: 'Comparative Literature and Culture 2103A/B - THE GROTESQUE',
        courseName:
          'Comparative Literature and Culture 2103A/B - THE GROTESQUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213a47f7e10015fd60d8',
      },
      {
        _id: '60cc213a47f7e10015fd60da',
        courseCode: 'COMPLIT 2104',
        shortName:
          "Comparative Literature and Culture 2104A/B - INTERNATIONAL CHILDREN'S LITERATURE",
        courseName:
          "Comparative Literature and Culture 2104A/B - INTERNATIONAL CHILDREN'S LITERATURE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213a47f7e10015fd60da',
      },
      {
        _id: '60cc213b47f7e10015fd60dc',
        courseCode: 'COMPLIT 2105',
        shortName:
          'Comparative Literature and Culture 2105A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2105A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213b47f7e10015fd60dc',
      },
      {
        _id: '60cc213b47f7e10015fd60de',
        courseCode: 'COMPLIT 2106',
        shortName:
          'Comparative Literature and Culture 2106A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2106A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213b47f7e10015fd60de',
      },
      {
        _id: '60cc213b47f7e10015fd60e0',
        courseCode: 'COMPLIT 2107',
        shortName:
          'Comparative Literature and Culture 2107A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2107A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213b47f7e10015fd60e0',
      },
      {
        _id: '60cc213b47f7e10015fd60e2',
        courseCode: 'COMPLIT 2108',
        shortName:
          'Comparative Literature and Culture 2108A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2108A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213b47f7e10015fd60e2',
      },
      {
        _id: '60cc213b47f7e10015fd60e4',
        courseCode: 'COMPLIT 2109',
        shortName:
          'Comparative Literature and Culture 2109A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2109A/B - SPECIAL TOPICS IN COMPARITIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213b47f7e10015fd60e4',
      },
      {
        _id: '60cc213c47f7e10015fd60e6',
        courseCode: 'COMPLIT 2111',
        shortName:
          'Comparative Literature and Culture 2111A/B - STORYTELLING: EAST AND WEST',
        courseName:
          'Comparative Literature and Culture 2111A/B - STORYTELLING: EAST AND WEST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213c47f7e10015fd60e6',
      },
      {
        _id: '60cc213c47f7e10015fd60e8',
        courseCode: 'COMPLIT 2112',
        shortName:
          'Comparative Literature and Culture 2112A/B - THE GRAPHIC NOVEL IN PRINT AND ONLINE AROUND THE WORLD',
        courseName:
          'Comparative Literature and Culture 2112A/B - THE GRAPHIC NOVEL IN PRINT AND ONLINE AROUND THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213c47f7e10015fd60e8',
      },
      {
        _id: '60cc213c47f7e10015fd60ea',
        courseCode: 'COMPLIT 2117',
        shortName:
          'Comparative Literature and Culture 2117A/B - FUNNY MONEY: COMMERCE AND COMEDY',
        courseName:
          'Comparative Literature and Culture 2117A/B - FUNNY MONEY: COMMERCE AND COMEDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213c47f7e10015fd60ea',
      },
      {
        _id: '60cc213c47f7e10015fd60ec',
        courseCode: 'COMPLIT 2119',
        shortName:
          'Comparative Literature and Culture 2119A/B - THE CULTURE OF FASCISM',
        courseName:
          'Comparative Literature and Culture 2119A/B - THE CULTURE OF FASCISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213c47f7e10015fd60ec',
      },
      {
        _id: '60cc213d47f7e10015fd60ee',
        courseCode: 'COMPLIT 2121',
        shortName:
          'Comparative Literature and Culture 2121A/B - SCIENCE AND THE ARTS: PATHS OF INNOVATION',
        courseName:
          'Comparative Literature and Culture 2121A/B - SCIENCE AND THE ARTS: PATHS OF INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213d47f7e10015fd60ee',
      },
      {
        _id: '60cc213d47f7e10015fd60f0',
        courseCode: 'COMPLIT 2122',
        shortName:
          'Comparative Literature and Culture 2122A/B - HUMAN RIGHTS IN LITERATURE AND ART',
        courseName:
          'Comparative Literature and Culture 2122A/B - HUMAN RIGHTS IN LITERATURE AND ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213d47f7e10015fd60f0',
      },
      {
        _id: '60cc213d47f7e10015fd60f2',
        courseCode: 'COMPLIT 2125',
        shortName:
          'Comparative Literature and Culture 2125A/B - BATTLE OF THE SEXES',
        courseName:
          'Comparative Literature and Culture 2125A/B - BATTLE OF THE SEXES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213d47f7e10015fd60f2',
      },
      {
        _id: '60cc213e47f7e10015fd60f5',
        courseCode: 'COMPLIT 2128',
        shortName:
          'Comparative Literature and Culture 2128F/G/Z - BARCELONA: CULTURE THROUGH THE AGES',
        courseName:
          'Comparative Literature and Culture 2128F/G/Z - BARCELONA: CULTURE THROUGH THE AGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213e47f7e10015fd60f5',
      },
      {
        _id: '60cc213e47f7e10015fd60f7',
        courseCode: 'COMPLIT 2129',
        shortName: 'Comparative Literature and Culture 2129A/B - MEXICO CITY',
        courseName: 'Comparative Literature and Culture 2129A/B - MEXICO CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213e47f7e10015fd60f7',
      },
      {
        _id: '60cc213e47f7e10015fd60f9',
        courseCode: 'COMPLIT 2131',
        shortName:
          'Comparative Literature and Culture 2131A/B - ROME: THE ETERNAL CITY',
        courseName:
          'Comparative Literature and Culture 2131A/B - ROME: THE ETERNAL CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213e47f7e10015fd60f9',
      },
      {
        _id: '60cc213e47f7e10015fd60fb',
        courseCode: 'COMPLIT 2132',
        shortName:
          'Comparative Literature and Culture 2132A/B - ITALIAN JOURNEYS',
        courseName:
          'Comparative Literature and Culture 2132A/B - ITALIAN JOURNEYS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213e47f7e10015fd60fb',
      },
      {
        _id: '60cc213f47f7e10015fd60fd',
        courseCode: 'COMPLIT 2133',
        shortName:
          'Comparative Literature and Culture 2133A/B - ITALIAN POPULAR FILMS',
        courseName:
          'Comparative Literature and Culture 2133A/B - ITALIAN POPULAR FILMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213f47f7e10015fd60fd',
      },
      {
        _id: '60cc213f47f7e10015fd60ff',
        courseCode: 'COMPLIT 2134',
        shortName:
          'Comparative Literature and Culture 2134A/B - BOMBAY TO MUMBAI: HINDUISM AND LITERATURE',
        courseName:
          'Comparative Literature and Culture 2134A/B - BOMBAY TO MUMBAI: HINDUISM AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213f47f7e10015fd60ff',
      },
      {
        _id: '60cc213f47f7e10015fd6101',
        courseCode: 'COMPLIT 2135',
        shortName:
          'Comparative Literature and Culture 2135A/B - VIENNA 1900/2000',
        courseName:
          'Comparative Literature and Culture 2135A/B - VIENNA 1900/2000',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc213f47f7e10015fd6101',
      },
      {
        _id: '60cc214047f7e10015fd6103',
        courseCode: 'COMPLIT 2136',
        shortName:
          'Comparative Literature and Culture 2136A/B - BERLIN SNAPSHOTS',
        courseName:
          'Comparative Literature and Culture 2136A/B - BERLIN SNAPSHOTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214047f7e10015fd6103',
      },
      {
        _id: '60cc214047f7e10015fd6105',
        courseCode: 'COMPLIT 2137',
        shortName:
          'Comparative Literature and Culture 2137A/B - GERMAN FAIRY TALES: GRIMM BROTHERS AND OTHERS',
        courseName:
          'Comparative Literature and Culture 2137A/B - GERMAN FAIRY TALES: GRIMM BROTHERS AND OTHERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214047f7e10015fd6105',
      },
      {
        _id: '60cc214047f7e10015fd6107',
        courseCode: 'COMPLIT 2138',
        shortName:
          'Comparative Literature and Culture 2138A/B - GERMAN-JEWISH LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2138A/B - GERMAN-JEWISH LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214047f7e10015fd6107',
      },
      {
        _id: '60cc214047f7e10015fd6109',
        courseCode: 'COMPLIT 2141',
        shortName:
          'Comparative Literature and Culture 2141A/B - FOOD AND HEALTH IN THE MIDDLE AGES',
        courseName:
          'Comparative Literature and Culture 2141A/B - FOOD AND HEALTH IN THE MIDDLE AGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214047f7e10015fd6109',
      },
      {
        _id: '60cc214147f7e10015fd610b',
        courseCode: 'COMPLIT 2142',
        shortName: 'Comparative Literature and Culture 2142A/B - COURTLY LOVE',
        courseName: 'Comparative Literature and Culture 2142A/B - COURTLY LOVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214147f7e10015fd610b',
      },
      {
        _id: '60cc214147f7e10015fd610d',
        courseCode: 'COMPLIT 2145',
        shortName:
          'Comparative Literature and Culture 2145F/G - POPULAR CULTURE IN LATIN AMERICA',
        courseName:
          'Comparative Literature and Culture 2145F/G - POPULAR CULTURE IN LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214147f7e10015fd610d',
      },
      {
        _id: '60cc214147f7e10015fd6110',
        courseCode: 'COMPLIT 2200',
        shortName:
          'Comparative Literature and Culture 2200F/G - EXPLORING COMPARITIVE LITERATURE AND CULTURES',
        courseName:
          'Comparative Literature and Culture 2200F/G - EXPLORING COMPARITIVE LITERATURE AND CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214147f7e10015fd6110',
      },
      {
        _id: '60cc214247f7e10015fd6112',
        courseCode: 'COMPLIT 2291',
        shortName:
          'Comparative Literature and Culture 2291F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2291F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214247f7e10015fd6112',
      },
      {
        _id: '60cc214247f7e10015fd6114',
        courseCode: 'COMPLIT 2292',
        shortName:
          'Comparative Literature and Culture 2292F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2292F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214247f7e10015fd6114',
      },
      {
        _id: '60cc214247f7e10015fd6116',
        courseCode: 'COMPLIT 2293',
        shortName:
          'Comparative Literature and Culture 2293F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2293F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214247f7e10015fd6116',
      },
      {
        _id: '60cc214247f7e10015fd6118',
        courseCode: 'COMPLIT 2294',
        shortName:
          'Comparative Literature and Culture 2294F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2294F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214247f7e10015fd6118',
      },
      {
        _id: '60cc214347f7e10015fd611a',
        courseCode: 'COMPLIT 2296',
        shortName:
          'Comparative Literature and Culture 2296F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 2296F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214347f7e10015fd611a',
      },
      {
        _id: '60cc214347f7e10015fd611c',
        courseCode: 'COMPLIT 2297',
        shortName:
          'Comparative Literature and Culture 2297F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE & CULTURE',
        courseName:
          'Comparative Literature and Culture 2297F/G - SPECIAL TOPIC IN COMPARATIVE LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214347f7e10015fd611c',
      },
      {
        _id: '60cc214347f7e10015fd611f',
        courseCode: 'COMPLIT 2700',
        shortName:
          'Comparative Literature and Culture 2700F/G - WORLD CULTURES, GLOBAL SCREENS',
        courseName:
          'Comparative Literature and Culture 2700F/G - WORLD CULTURES, GLOBAL SCREENS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214347f7e10015fd611f',
      },
      {
        _id: '60cc214447f7e10015fd6121',
        courseCode: 'COMPLIT 3300',
        shortName:
          'Comparative Literature and Culture 3300F/G - LITERACY AND CULTURAL THEORY',
        courseName:
          'Comparative Literature and Culture 3300F/G - LITERACY AND CULTURAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214447f7e10015fd6121',
      },
      {
        _id: '60cc214447f7e10015fd6123',
        courseCode: 'COMPLIT 3301',
        shortName:
          'Comparative Literature and Culture 3301F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3301F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214447f7e10015fd6123',
      },
      {
        _id: '60cc214447f7e10015fd6125',
        courseCode: 'COMPLIT 3302',
        shortName:
          'Comparative Literature and Culture 3302F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3302F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214447f7e10015fd6125',
      },
      {
        _id: '60cc214447f7e10015fd6127',
        courseCode: 'COMPLIT 3340',
        shortName:
          'Comparative Literature and Culture 3340F/G - MEDIEVAL LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3340F/G - MEDIEVAL LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214447f7e10015fd6127',
      },
      {
        _id: '60cc214547f7e10015fd6129',
        courseCode: 'COMPLIT 3341',
        shortName:
          'Comparative Literature and Culture 3341F/G - RENAISSANCE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3341F/G - RENAISSANCE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214547f7e10015fd6129',
      },
      {
        _id: '60cc214547f7e10015fd612b',
        courseCode: 'COMPLIT 3342',
        shortName:
          'Comparative Literature and Culture 3342F/G - BAROQUE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3342F/G - BAROQUE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214547f7e10015fd612b',
      },
      {
        _id: '60cc214547f7e10015fd612e',
        courseCode: 'COMPLIT 3350',
        shortName:
          'Comparative Literature and Culture 3350F/G - WOMEN FILMMAKERS',
        courseName:
          'Comparative Literature and Culture 3350F/G - WOMEN FILMMAKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214547f7e10015fd612e',
      },
      {
        _id: '60cc214647f7e10015fd6130',
        courseCode: 'COMPLIT 3351',
        shortName:
          'Comparative Literature and Culture 3351F/G - INTERMEDIALITY: WHERE LITERATURE AND OTHER MEDIA MEET',
        courseName:
          'Comparative Literature and Culture 3351F/G - INTERMEDIALITY: WHERE LITERATURE AND OTHER MEDIA MEET',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214647f7e10015fd6130',
      },
      {
        _id: '60cc214647f7e10015fd6132',
        courseCode: 'COMPLIT 3352',
        shortName:
          'Comparative Literature and Culture 3352F/G - INTERCULTURAL PERFORMANCE',
        courseName:
          'Comparative Literature and Culture 3352F/G - INTERCULTURAL PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214647f7e10015fd6132',
      },
      {
        _id: '60cc214647f7e10015fd6134',
        courseCode: 'COMPLIT 3353',
        shortName:
          'Comparative Literature and Culture 3353F/G - SPECTATORSHIP UP TO THE DIGITAL AGE',
        courseName:
          'Comparative Literature and Culture 3353F/G - SPECTATORSHIP UP TO THE DIGITAL AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214647f7e10015fd6134',
      },
      {
        _id: '60cc214647f7e10015fd6136',
        courseCode: 'COMPLIT 3354',
        shortName:
          'Comparative Literature and Culture 3354F/G - CROSSING BORDERS: EUROPE LOOKS (AND WRITES) EAST',
        courseName:
          'Comparative Literature and Culture 3354F/G - CROSSING BORDERS: EUROPE LOOKS (AND WRITES) EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214647f7e10015fd6136',
      },
      {
        _id: '60cc214747f7e10015fd6138',
        courseCode: 'COMPLIT 3355',
        shortName:
          'Comparative Literature and Culture 3355F/G - THE IRRATIONAL IN 20TH CENTURY LITERATURE AND ART',
        courseName:
          'Comparative Literature and Culture 3355F/G - THE IRRATIONAL IN 20TH CENTURY LITERATURE AND ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214747f7e10015fd6138',
      },
      {
        _id: '60cc214747f7e10015fd613a',
        courseCode: 'COMPLIT 3361',
        shortName:
          'Comparative Literature and Culture 3361F/G - ITALIAN RENAISSANCE EPIC',
        courseName:
          'Comparative Literature and Culture 3361F/G - ITALIAN RENAISSANCE EPIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214747f7e10015fd613a',
      },
      {
        _id: '60cc214747f7e10015fd613c',
        courseCode: 'COMPLIT 3362',
        shortName:
          "Comparative Literature and Culture 3362F/G - FROM COMMEDIA DELL'ARTE TO MODERN THEATRE",
        courseName:
          "Comparative Literature and Culture 3362F/G - FROM COMMEDIA DELL'ARTE TO MODERN THEATRE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214747f7e10015fd613c',
      },
      {
        _id: '60cc214747f7e10015fd613e',
        courseCode: 'COMPLIT 3371',
        shortName:
          'Comparative Literature and Culture 3371F/G - TRAVEL LITERATURE',
        courseName:
          'Comparative Literature and Culture 3371F/G - TRAVEL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214747f7e10015fd613e',
      },
      {
        _id: '60cc214847f7e10015fd6140',
        courseCode: 'COMPLIT 3372',
        shortName:
          'Comparative Literature and Culture 3372F/G - GERMAN CLASSICISM AND ROMANTICISM',
        courseName:
          'Comparative Literature and Culture 3372F/G - GERMAN CLASSICISM AND ROMANTICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214847f7e10015fd6140',
      },
      {
        _id: '60cc214847f7e10015fd6143',
        courseCode: 'COMPLIT 3375',
        shortName:
          'Comparative Literature and Culture 3375F/G - GERMAN THOUGHT AND CULTURE OF PROVOCATION',
        courseName:
          'Comparative Literature and Culture 3375F/G - GERMAN THOUGHT AND CULTURE OF PROVOCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214847f7e10015fd6143',
      },
      {
        _id: '60cc214947f7e10015fd6145',
        courseCode: 'COMPLIT 3380',
        shortName:
          'Comparative Literature and Culture 3380F/G - MEDIEVAL LITERATURE AND MODERN CINEMA',
        courseName:
          'Comparative Literature and Culture 3380F/G - MEDIEVAL LITERATURE AND MODERN CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214947f7e10015fd6145',
      },
      {
        _id: '60cc214947f7e10015fd6147',
        courseCode: 'COMPLIT 3381',
        shortName:
          'Comparative Literature and Culture 3381F/G - HISPANIC VISUAL ARTS AND TEXTS',
        courseName:
          'Comparative Literature and Culture 3381F/G - HISPANIC VISUAL ARTS AND TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214947f7e10015fd6147',
      },
      {
        _id: '60cc214947f7e10015fd6149',
        courseCode: 'COMPLIT 3382',
        shortName:
          'Comparative Literature and Culture 3382F/G - MUSIC, DANCE AND PERFORMANCE IN THE HISPANIC WORLD',
        courseName:
          'Comparative Literature and Culture 3382F/G - MUSIC, DANCE AND PERFORMANCE IN THE HISPANIC WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214947f7e10015fd6149',
      },
      {
        _id: '60cc214947f7e10015fd614b',
        courseCode: 'COMPLIT 3391',
        shortName:
          'Comparative Literature and Culture 3391F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3391F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214947f7e10015fd614b',
      },
      {
        _id: '60cc214a47f7e10015fd614d',
        courseCode: 'COMPLIT 3392',
        shortName:
          'Comparative Literature and Culture 3392F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE & CULTURE',
        courseName:
          'Comparative Literature and Culture 3392F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214a47f7e10015fd614d',
      },
      {
        _id: '60cc214a47f7e10015fd614f',
        courseCode: 'COMPLIT 3393',
        shortName:
          'Comparative Literature and Culture 3393F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE & CULTURE',
        courseName:
          'Comparative Literature and Culture 3393F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214a47f7e10015fd614f',
      },
      {
        _id: '60cc214a47f7e10015fd6151',
        courseCode: 'COMPLIT 3394',
        shortName:
          'Comparative Literature and Culture 3394F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE & CULTURE',
        courseName:
          'Comparative Literature and Culture 3394F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214a47f7e10015fd6151',
      },
      {
        _id: '60cc214b47f7e10015fd6153',
        courseCode: 'COMPLIT 3398',
        shortName:
          'Comparative Literature and Culture 3398F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3398F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214b47f7e10015fd6153',
      },
      {
        _id: '60cc214b47f7e10015fd6155',
        courseCode: 'COMPLIT 3399',
        shortName:
          'Comparative Literature and Culture 3399F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3399F/G - SPECIAL TOPICS IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214b47f7e10015fd6155',
      },
      {
        _id: '60cc214b47f7e10015fd6157',
        courseCode: 'COMPLIT 3600',
        shortName:
          'Comparative Literature and Culture 3600F/G/Z - INTERNSHIP IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 3600F/G/Z - INTERNSHIP IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214b47f7e10015fd6157',
      },
      {
        _id: '60cc214c47f7e10015fd6159',
        courseCode: 'COMPLIT 4401',
        shortName:
          'Comparative Literature and Culture 4401F/G - RESEARCH SEMINAR',
        courseName:
          'Comparative Literature and Culture 4401F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214c47f7e10015fd6159',
      },
      {
        _id: '60cc214c47f7e10015fd615c',
        courseCode: 'COMPLIT 4491',
        shortName:
          'Comparative Literature and Culture 4491F/G - ADVANCED TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 4491F/G - ADVANCED TOPIC IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214c47f7e10015fd615c',
      },
      {
        _id: '60cc214c47f7e10015fd615e',
        courseCode: 'COMPLIT 4493',
        shortName:
          'Comparative Literature and Culture 4493F/G - DIRECTED STUDIES IN COMPARATIVE LITERATURE AND CULTURE',
        courseName:
          'Comparative Literature and Culture 4493F/G - DIRECTED STUDIES IN COMPARATIVE LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214c47f7e10015fd615e',
      },
      {
        _id: '60cc214d47f7e10015fd6160',
        courseCode: 'COMPLIT 4500',
        shortName:
          'Comparative Literature and Culture 4500F/G - SENIOR RESEARCH PROJECT',
        courseName:
          'Comparative Literature and Culture 4500F/G - SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214d47f7e10015fd6160',
      },
      {
        _id: '60cc214d47f7e10015fd6162',
        courseCode: 'COMPSCI 1011',
        shortName:
          'Computer Science 1011A/B - THE INTERNET: BEHIND THE CURTAIN',
        courseName:
          'Computer Science 1011A/B - THE INTERNET: BEHIND THE CURTAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214d47f7e10015fd6162',
      },
      {
        _id: '60cc214d47f7e10015fd6164',
        courseCode: 'COMPSCI 1025',
        shortName: 'Computer Science 1025A/B - COMPUTER SCIENCE FUNDAMENTALS I',
        courseName:
          'Computer Science 1025A/B - COMPUTER SCIENCE FUNDAMENTALS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214d47f7e10015fd6164',
      },
      {
        _id: '60cc214e47f7e10015fd6166',
        courseCode: 'COMPSCI 1026',
        shortName: 'Computer Science 1026A/B - COMPUTER SCIENCE FUNDAMENTALS I',
        courseName:
          'Computer Science 1026A/B - COMPUTER SCIENCE FUNDAMENTALS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214e47f7e10015fd6166',
      },
      {
        _id: '60cc214e47f7e10015fd6168',
        courseCode: 'COMPSCI 1027',
        shortName:
          'Computer Science 1027A/B - COMPUTER SCIENCE FUNDAMENTALS II',
        courseName:
          'Computer Science 1027A/B - COMPUTER SCIENCE FUNDAMENTALS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214e47f7e10015fd6168',
      },
      {
        _id: '60cc214e47f7e10015fd616a',
        courseCode: 'COMPSCI 1032',
        shortName: 'Computer Science 1032A/B - INFORMATION SYSTEMS AND DESIGN',
        courseName: 'Computer Science 1032A/B - INFORMATION SYSTEMS AND DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 6,
        id: '60cc214e47f7e10015fd616a',
      },
      {
        _id: '60cc214f47f7e10015fd616c',
        courseCode: 'COMPSCI 1033',
        shortName: 'Computer Science 1033A/B - MULTIMEDIA AND COMMUNICATION I',
        courseName: 'Computer Science 1033A/B - MULTIMEDIA AND COMMUNICATION I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 2,
        id: '60cc214f47f7e10015fd616c',
      },
      {
        _id: '60cc214f47f7e10015fd616e',
        courseCode: 'COMPSCI 1037',
        shortName:
          'Computer Science 1037A/B - COMPUTER SCIENCE FUNDAMENTALS II',
        courseName:
          'Computer Science 1037A/B - COMPUTER SCIENCE FUNDAMENTALS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214f47f7e10015fd616e',
      },
      {
        _id: '60cc214f47f7e10015fd6170',
        courseCode: 'COMPSCI 1046',
        shortName:
          'Computer Science 1046A/B - APPROACHABLE APPS: AN INTRODUCTION TO PROGRAMMING USING JAVASCRIPT',
        courseName:
          'Computer Science 1046A/B - APPROACHABLE APPS: AN INTRODUCTION TO PROGRAMMING USING JAVASCRIPT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214f47f7e10015fd6170',
      },
      {
        _id: '60cc214f47f7e10015fd6172',
        courseCode: 'COMPSCI 2033',
        shortName: 'Computer Science 2033A/B - MULTIMEDIA AND COMMUNICATION II',
        courseName:
          'Computer Science 2033A/B - MULTIMEDIA AND COMMUNICATION II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc214f47f7e10015fd6172',
      },
      {
        _id: '60cc215047f7e10015fd6174',
        courseCode: 'COMPSCI 2034',
        shortName:
          'Computer Science 2034A/B - DATA ANALYTICS: PRINCIPLES AND TOOLS',
        courseName:
          'Computer Science 2034A/B - DATA ANALYTICS: PRINCIPLES AND TOOLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215047f7e10015fd6174',
      },
      {
        _id: '60cc215047f7e10015fd6176',
        courseCode: 'COMPSCI 2035',
        shortName:
          'Computer Science 2035A/B - DEALING WITH DATA: ANALYSIS AND VISUALIZATION',
        courseName:
          'Computer Science 2035A/B - DEALING WITH DATA: ANALYSIS AND VISUALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215047f7e10015fd6176',
      },
      {
        _id: '60cc215047f7e10015fd6178',
        courseCode: 'COMPSCI 2050',
        shortName:
          'Computer Science 2050F/G - THE EVOLUTION OF COMPUTING AND COMPUTERS',
        courseName:
          'Computer Science 2050F/G - THE EVOLUTION OF COMPUTING AND COMPUTERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215047f7e10015fd6178',
      },
      {
        _id: '60cc215147f7e10015fd617a',
        courseCode: 'COMPSCI 2101',
        shortName:
          'Computer Science 2101A/B - FOUNDATIONS OF PROGRAMMING FOR HIGH PERFORMANCE COMPUTING',
        courseName:
          'Computer Science 2101A/B - FOUNDATIONS OF PROGRAMMING FOR HIGH PERFORMANCE COMPUTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215147f7e10015fd617a',
      },
      {
        _id: '60cc215147f7e10015fd617c',
        courseCode: 'COMPSCI 2120',
        shortName:
          'Computer Science 2120A/B - MODERN SURVIVAL SKILLS I: CODING ESSENTIALS',
        courseName:
          'Computer Science 2120A/B - MODERN SURVIVAL SKILLS I: CODING ESSENTIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215147f7e10015fd617c',
      },
      {
        _id: '60cc215147f7e10015fd617e',
        courseCode: 'COMPSCI 2121',
        shortName:
          'Computer Science 2121A/B - MODERN SURVIVAL SKILLS II: PROBLEM SOLVING THROUGH PROGRAMMING',
        courseName:
          'Computer Science 2121A/B - MODERN SURVIVAL SKILLS II: PROBLEM SOLVING THROUGH PROGRAMMING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215147f7e10015fd617e',
      },
      {
        _id: '60cc215147f7e10015fd6180',
        courseCode: 'COMPSCI 2124',
        shortName:
          'Computer Science 2124A/B - INTRODUCTION TO MEDICAL COMPUTING',
        courseName:
          'Computer Science 2124A/B - INTRODUCTION TO MEDICAL COMPUTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215147f7e10015fd6180',
      },
      {
        _id: '60cc215247f7e10015fd6182',
        courseCode: 'COMPSCI 2125',
        shortName:
          'Computer Science 2125F/G - INTRODUCTION TO MEDICAL COMPUTING',
        courseName:
          'Computer Science 2125F/G - INTRODUCTION TO MEDICAL COMPUTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215247f7e10015fd6182',
      },
      {
        _id: '60cc215247f7e10015fd6184',
        courseCode: 'COMPSCI 2208',
        shortName:
          'Computer Science 2208A/B - INTRODUCTION TO COMPUTER ORGANIZATION AND ARCHITECTURE',
        courseName:
          'Computer Science 2208A/B - INTRODUCTION TO COMPUTER ORGANIZATION AND ARCHITECTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215247f7e10015fd6184',
      },
      {
        _id: '60cc215247f7e10015fd6186',
        courseCode: 'COMPSCI 2209',
        shortName:
          'Computer Science 2209A/B - APPLIED LOGIC FOR COMPUTER SCIENCE',
        courseName:
          'Computer Science 2209A/B - APPLIED LOGIC FOR COMPUTER SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215247f7e10015fd6186',
      },
      {
        _id: '60cc215347f7e10015fd6188',
        courseCode: 'COMPSCI 2210',
        shortName: 'Computer Science 2210A/B - DATA STRUCTURES AND ALGORITHMS',
        courseName: 'Computer Science 2210A/B - DATA STRUCTURES AND ALGORITHMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215347f7e10015fd6188',
      },
      {
        _id: '60cc215347f7e10015fd618a',
        courseCode: 'COMPSCI 2211',
        shortName:
          'Computer Science 2211A/B - SOFTWARE TOOLS AND SYSTEMS  PROGRAMMING',
        courseName:
          'Computer Science 2211A/B - SOFTWARE TOOLS AND SYSTEMS  PROGRAMMING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215347f7e10015fd618a',
      },
      {
        _id: '60cc215347f7e10015fd618c',
        courseCode: 'COMPSCI 2212',
        shortName:
          'Computer Science 2212A/B/Y - INTRODUCTION TO SOFTWARE ENGINEERING',
        courseName:
          'Computer Science 2212A/B/Y - INTRODUCTION TO SOFTWARE ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215347f7e10015fd618c',
      },
      {
        _id: '60cc215447f7e10015fd618e',
        courseCode: 'COMPSCI 2214',
        shortName:
          'Computer Science 2214A/B - DISCRETE STRUCTURES FOR COMPUTING',
        courseName:
          'Computer Science 2214A/B - DISCRETE STRUCTURES FOR COMPUTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215447f7e10015fd618e',
      },
      {
        _id: '60cc215447f7e10015fd6190',
        courseCode: 'COMPSCI 3101',
        shortName:
          'Computer Science 3101A/B - THEORY AND PRACTICE OF HIGH PERFORMANCE COMPUTING',
        courseName:
          'Computer Science 3101A/B - THEORY AND PRACTICE OF HIGH PERFORMANCE COMPUTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215447f7e10015fd6190',
      },
      {
        _id: '60cc215447f7e10015fd6192',
        courseCode: 'COMPSCI 3120',
        shortName:
          'Computer Science 3120A/B - DATABASES FOR INFORMATICS AND ANALYTICS',
        courseName:
          'Computer Science 3120A/B - DATABASES FOR INFORMATICS AND ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215447f7e10015fd6192',
      },
      {
        _id: '60cc215547f7e10015fd6194',
        courseCode: 'COMPSCI 3121',
        shortName:
          'Computer Science 3121A/B - ARTIFICIAL INTELLIGENCE FOR INFORMATICS AND ANALYTICS',
        courseName:
          'Computer Science 3121A/B - ARTIFICIAL INTELLIGENCE FOR INFORMATICS AND ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215547f7e10015fd6194',
      },
      {
        _id: '60cc215547f7e10015fd6196',
        courseCode: 'COMPSCI 3305',
        shortName: 'Computer Science 3305A/B - OPERATING SYSTEMS',
        courseName: 'Computer Science 3305A/B - OPERATING SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 4,
        id: '60cc215547f7e10015fd6196',
      },
      {
        _id: '60cc215547f7e10015fd6198',
        courseCode: 'COMPSCI 3307',
        shortName:
          'Computer Science 3307A/B/Y - OBJECT-ORIENTED DESIGN AND ANALYSIS',
        courseName:
          'Computer Science 3307A/B/Y - OBJECT-ORIENTED DESIGN AND ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215547f7e10015fd6198',
      },
      {
        _id: '60cc215547f7e10015fd619a',
        courseCode: 'COMPSCI 3319',
        shortName: 'Computer Science 3319A/B - DATABASES I',
        courseName: 'Computer Science 3319A/B - DATABASES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215547f7e10015fd619a',
      },
      {
        _id: '60cc215647f7e10015fd619c',
        courseCode: 'COMPSCI 3331',
        shortName:
          'Computer Science 3331A/B - FOUNDATIONS OF COMPUTER SCIENCE I',
        courseName:
          'Computer Science 3331A/B - FOUNDATIONS OF COMPUTER SCIENCE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215647f7e10015fd619c',
      },
      {
        _id: '60cc215647f7e10015fd619e',
        courseCode: 'COMPSCI 3333',
        shortName: 'Computer Science 3333A/B - SELECTED TOPICS',
        courseName: 'Computer Science 3333A/B - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215647f7e10015fd619e',
      },
      {
        _id: '60cc215647f7e10015fd61a0',
        courseCode: 'COMPSCI 3335',
        shortName: 'Computer Science 3335A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 3335A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215647f7e10015fd61a0',
      },
      {
        _id: '60cc215747f7e10015fd61a2',
        courseCode: 'COMPSCI 3336',
        shortName: 'Computer Science 3336A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 3336A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215747f7e10015fd61a2',
      },
      {
        _id: '60cc215747f7e10015fd61a4',
        courseCode: 'COMPSCI 3340',
        shortName: 'Computer Science 3340A/B - ANALYSIS OF ALGORITHMS I',
        courseName: 'Computer Science 3340A/B - ANALYSIS OF ALGORITHMS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215747f7e10015fd61a4',
      },
      {
        _id: '60cc215747f7e10015fd61a6',
        courseCode: 'COMPSCI 3342',
        shortName:
          'Computer Science 3342A/B - ORGANIZATION OF PROGRAMMING LANGUAGES',
        courseName:
          'Computer Science 3342A/B - ORGANIZATION OF PROGRAMMING LANGUAGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215747f7e10015fd61a6',
      },
      {
        _id: '60cc215747f7e10015fd61a8',
        courseCode: 'COMPSCI 3346',
        shortName: 'Computer Science 3346A/B - ARTIFICIAL INTELLIGENCE I',
        courseName: 'Computer Science 3346A/B - ARTIFICIAL INTELLIGENCE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215747f7e10015fd61a8',
      },
      {
        _id: '60cc215847f7e10015fd61aa',
        courseCode: 'COMPSCI 3350',
        shortName: 'Computer Science 3350A/B - COMPUTER ORGANIZATION',
        courseName: 'Computer Science 3350A/B - COMPUTER ORGANIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215847f7e10015fd61aa',
      },
      {
        _id: '60cc215847f7e10015fd61ac',
        courseCode: 'COMPSCI 3357',
        shortName: 'Computer Science 3357A/B - COMPUTER NETWORKS I',
        courseName: 'Computer Science 3357A/B - COMPUTER NETWORKS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215847f7e10015fd61ac',
      },
      {
        _id: '60cc215847f7e10015fd61ae',
        courseCode: 'COMPSCI 3377',
        shortName: 'Computer Science 3377A/B - SOFTWARE PROJECT MANAGEMENT',
        courseName: 'Computer Science 3377A/B - SOFTWARE PROJECT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215847f7e10015fd61ae',
      },
      {
        _id: '60cc215847f7e10015fd61b0',
        courseCode: 'COMPSCI 3380',
        shortName: 'Computer Science 3380F/G/Z - PROJECT',
        courseName: 'Computer Science 3380F/G/Z - PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215847f7e10015fd61b0',
      },
      {
        _id: '60cc215947f7e10015fd61b2',
        courseCode: 'COMPSCI 3388',
        shortName: 'Computer Science 3388A/B - COMPUTER GRAPHICS I',
        courseName: 'Computer Science 3388A/B - COMPUTER GRAPHICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215947f7e10015fd61b2',
      },
      {
        _id: '60cc215947f7e10015fd61b4',
        courseCode: 'COMPSCI 4402',
        shortName:
          'Computer Science 4402A/B - DISTRIBUTED AND PARALLEL SYSTEMS',
        courseName:
          'Computer Science 4402A/B - DISTRIBUTED AND PARALLEL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215947f7e10015fd61b4',
      },
      {
        _id: '60cc215947f7e10015fd61b6',
        courseCode: 'COMPSCI 4411',
        shortName: 'Computer Science 4411A/B - DATABASES II',
        courseName: 'Computer Science 4411A/B - DATABASES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215947f7e10015fd61b6',
      },
      {
        _id: '60cc215947f7e10015fd61b8',
        courseCode: 'COMPSCI 4413',
        shortName: 'Computer Science 4413A/B - CRYPTOGRAPHY AND SECURITY',
        courseName: 'Computer Science 4413A/B - CRYPTOGRAPHY AND SECURITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215947f7e10015fd61b8',
      },
      {
        _id: '60cc215a47f7e10015fd61ba',
        courseCode: 'COMPSCI 4416',
        shortName: 'Computer Science 4416A/B - DATA SCIENCE II',
        courseName: 'Computer Science 4416A/B - DATA SCIENCE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215a47f7e10015fd61ba',
      },
      {
        _id: '60cc215a47f7e10015fd61bc',
        courseCode: 'COMPSCI 4417',
        shortName: 'Computer Science 4417A/B - UNSTRUCTURED DATA',
        courseName: 'Computer Science 4417A/B - UNSTRUCTURED DATA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215a47f7e10015fd61bc',
      },
      {
        _id: '60cc215a47f7e10015fd61be',
        courseCode: 'COMPSCI 4418',
        shortName:
          'Computer Science 4418A/B - INTRODUCTION TO VISUAL ANALYTICS',
        courseName:
          'Computer Science 4418A/B - INTRODUCTION TO VISUAL ANALYTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215a47f7e10015fd61be',
      },
      {
        _id: '60cc215a47f7e10015fd61c0',
        courseCode: 'COMPSCI 4433',
        shortName: 'Computer Science 4433A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 4433A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215a47f7e10015fd61c0',
      },
      {
        _id: '60cc215b47f7e10015fd61c2',
        courseCode: 'COMPSCI 4434',
        shortName: 'Computer Science 4434A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 4434A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215b47f7e10015fd61c2',
      },
      {
        _id: '60cc215b47f7e10015fd61c4',
        courseCode: 'COMPSCI 4435',
        shortName: 'Computer Science 4435A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 4435A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215b47f7e10015fd61c4',
      },
      {
        _id: '60cc215b47f7e10015fd61c6',
        courseCode: 'COMPSCI 4436',
        shortName: 'Computer Science 4436A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 4436A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215b47f7e10015fd61c6',
      },
      {
        _id: '60cc215b47f7e10015fd61c8',
        courseCode: 'COMPSCI 4437',
        shortName: 'Computer Science 4437A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 4437A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215b47f7e10015fd61c8',
      },
      {
        _id: '60cc215c47f7e10015fd61ca',
        courseCode: 'COMPSCI 4438',
        shortName: 'Computer Science 4438A/B/Y - SELECTED TOPICS',
        courseName: 'Computer Science 4438A/B/Y - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215c47f7e10015fd61ca',
      },
      {
        _id: '60cc215c47f7e10015fd61cc',
        courseCode: 'COMPSCI 4442',
        shortName: 'Computer Science 4442A/B - ARTIFICIAL INTELLIGENCE II',
        courseName: 'Computer Science 4442A/B - ARTIFICIAL INTELLIGENCE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215c47f7e10015fd61cc',
      },
      {
        _id: '60cc215c47f7e10015fd61ce',
        courseCode: 'COMPSCI 4445',
        shortName: 'Computer Science 4445A/B - ANALYSIS OF ALGORTIHMS II',
        courseName: 'Computer Science 4445A/B - ANALYSIS OF ALGORTIHMS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215c47f7e10015fd61ce',
      },
      {
        _id: '60cc215d47f7e10015fd61d0',
        courseCode: 'COMPSCI 4447',
        shortName: 'Computer Science 4447A/B - COMPILER THEORY',
        courseName: 'Computer Science 4447A/B - COMPILER THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215d47f7e10015fd61d0',
      },
      {
        _id: '60cc215d47f7e10015fd61d2',
        courseCode: 'COMPSCI 4457',
        shortName: 'Computer Science 4457A/B/Y - COMPUTER NETWORKS II',
        courseName: 'Computer Science 4457A/B/Y - COMPUTER NETWORKS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215d47f7e10015fd61d2',
      },
      {
        _id: '60cc215d47f7e10015fd61d4',
        courseCode: 'COMPSCI 4460',
        shortName: 'Computer Science 4460Z - BIOINFORMATICS THESIS',
        courseName: 'Computer Science 4460Z - BIOINFORMATICS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215d47f7e10015fd61d4',
      },
      {
        _id: '60cc215d47f7e10015fd61d6',
        courseCode: 'COMPSCI 4461',
        shortName:
          'Computer Science 4461A/B - BIOINFORMATICS TOOLS AND APPLICATIONS',
        courseName:
          'Computer Science 4461A/B - BIOINFORMATICS TOOLS AND APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215d47f7e10015fd61d6',
      },
      {
        _id: '60cc215e47f7e10015fd61d8',
        courseCode: 'COMPSCI 4463',
        shortName: 'Computer Science 4463A/B - COMPUTATIONAL BIOLOGY',
        courseName: 'Computer Science 4463A/B - COMPUTATIONAL BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215e47f7e10015fd61d8',
      },
      {
        _id: '60cc215e47f7e10015fd61da',
        courseCode: 'COMPSCI 4470',
        shortName:
          'Computer Science 4470Y - SOFTWARE MAINTENANCE AND CONFIGURATION MANAGMENT',
        courseName:
          'Computer Science 4470Y - SOFTWARE MAINTENANCE AND CONFIGURATION MANAGMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215e47f7e10015fd61da',
      },
      {
        _id: '60cc215e47f7e10015fd61dc',
        courseCode: 'COMPSCI 4471',
        shortName:
          'Computer Science 4471A/B - SOFTWARE DESIGN AND ARCHITECTURE',
        courseName:
          'Computer Science 4471A/B - SOFTWARE DESIGN AND ARCHITECTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215e47f7e10015fd61dc',
      },
      {
        _id: '60cc215e47f7e10015fd61de',
        courseCode: 'COMPSCI 4472',
        shortName:
          'Computer Science 4472A/B - SPECIFICATION TESTING AND QUALITY',
        courseName:
          'Computer Science 4472A/B - SPECIFICATION TESTING AND QUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215e47f7e10015fd61de',
      },
      {
        _id: '60cc215f47f7e10015fd61e0',
        courseCode: 'COMPSCI 4473',
        shortName: 'Computer Science 4473A/B - REQUIREMENT ANALYSIS',
        courseName: 'Computer Science 4473A/B - REQUIREMENT ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215f47f7e10015fd61e0',
      },
      {
        _id: '60cc215f47f7e10015fd61e2',
        courseCode: 'COMPSCI 4474',
        shortName: 'Computer Science 4474A/B - HUMAN-COMPUTER INTERACTION',
        courseName: 'Computer Science 4474A/B - HUMAN-COMPUTER INTERACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215f47f7e10015fd61e2',
      },
      {
        _id: '60cc215f47f7e10015fd61e4',
        courseCode: 'COMPSCI 4475',
        shortName: 'Computer Science 4475A/B - OPEN SOURCE SOFTWARE PROJECT',
        courseName: 'Computer Science 4475A/B - OPEN SOURCE SOFTWARE PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215f47f7e10015fd61e4',
      },
      {
        _id: '60cc215f47f7e10015fd61e6',
        courseCode: 'COMPSCI 4476',
        shortName: 'Computer Science 4476A/B/Y - SUMMER OF CODE',
        courseName: 'Computer Science 4476A/B/Y - SUMMER OF CODE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc215f47f7e10015fd61e6',
      },
      {
        _id: '60cc216047f7e10015fd61e8',
        courseCode: 'COMPSCI 4478',
        shortName: 'Computer Science 4478A/B/Y - MASTER THE MAINFRAME',
        courseName: 'Computer Science 4478A/B/Y - MASTER THE MAINFRAME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216047f7e10015fd61e8',
      },
      {
        _id: '60cc216047f7e10015fd61ea',
        courseCode: 'COMPSCI 4480',
        shortName: 'Computer Science 4480Y - GAME DEVELOPMENT PROJECT',
        courseName: 'Computer Science 4480Y - GAME DEVELOPMENT PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216047f7e10015fd61ea',
      },
      {
        _id: '60cc216047f7e10015fd61ec',
        courseCode: 'COMPSCI 4481',
        shortName: 'Computer Science 4481A/B - IMAGE COMPRESSION',
        courseName: 'Computer Science 4481A/B - IMAGE COMPRESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216047f7e10015fd61ec',
      },
      {
        _id: '60cc216047f7e10015fd61ee',
        courseCode: 'COMPSCI 4482',
        shortName: 'Computer Science 4482A/B - GAME PROGRAMMING',
        courseName: 'Computer Science 4482A/B - GAME PROGRAMMING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216047f7e10015fd61ee',
      },
      {
        _id: '60cc216147f7e10015fd61f0',
        courseCode: 'COMPSCI 4483',
        shortName: 'Computer Science 4483A/B - GAME DESIGN',
        courseName: 'Computer Science 4483A/B - GAME DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216147f7e10015fd61f0',
      },
      {
        _id: '60cc216147f7e10015fd61f2',
        courseCode: 'COMPSCI 4487',
        shortName: 'Computer Science 4487A/B - ALGORITHMS FOR IMAGE ANALYSIS',
        courseName: 'Computer Science 4487A/B - ALGORITHMS FOR IMAGE ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216147f7e10015fd61f2',
      },
      {
        _id: '60cc216147f7e10015fd61f4',
        courseCode: 'COMPSCI 4490',
        shortName: 'Computer Science 4490Z - THESIS',
        courseName: 'Computer Science 4490Z - THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216147f7e10015fd61f4',
      },
      {
        _id: '60cc216147f7e10015fd61f6',
        courseCode: 'CA 1020',
        shortName: 'Creative Arts 1020A/B - INTRODUCTION TO CREATIVE ARTS',
        courseName: 'Creative Arts 1020A/B - INTRODUCTION TO CREATIVE ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216147f7e10015fd61f6',
      },
      {
        _id: '60cc216247f7e10015fd61f8',
        courseCode: 'CA 2100',
        shortName: 'Creative Arts 2100A/B - CREATIVITY IN THE 21ST CENTURY',
        courseName: 'Creative Arts 2100A/B - CREATIVITY IN THE 21ST CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216247f7e10015fd61f8',
      },
      {
        _id: '60cc216247f7e10015fd61fa',
        courseCode: 'CA 2200',
        shortName: 'Creative Arts 2200A/B - SOUND, IMAGE, TEXT I',
        courseName: 'Creative Arts 2200A/B - SOUND, IMAGE, TEXT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216247f7e10015fd61fa',
      },
      {
        _id: '60cc216247f7e10015fd61fc',
        courseCode: 'DANCE 2172',
        shortName: 'Dance 2172A/B - SPECIAL TOPICS IN DANCE',
        courseName: 'Dance 2172A/B - SPECIAL TOPICS IN DANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216247f7e10015fd61fc',
      },
      {
        _id: '60cc216347f7e10015fd61fe',
        courseCode: 'DANCE 2174',
        shortName: 'Dance 2174A/B - DANCE BASICS',
        courseName: 'Dance 2174A/B - DANCE BASICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216347f7e10015fd61fe',
      },
      {
        _id: '60cc216347f7e10015fd6200',
        courseCode: 'DANCE 2270',
        shortName: 'Dance 2270A/B - DANCE IMPROVISATION',
        courseName: 'Dance 2270A/B - DANCE IMPROVISATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216347f7e10015fd6200',
      },
      {
        _id: '60cc216347f7e10015fd6202',
        courseCode: 'DANCE 2274',
        shortName: 'Dance 2274A/B - MOVEMENT MAKING',
        courseName: 'Dance 2274A/B - MOVEMENT MAKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216347f7e10015fd6202',
      },
      {
        _id: '60cc216347f7e10015fd6204',
        courseCode: 'DANCE 2275',
        shortName: 'Dance 2275A/B - INTRO MODERN DANCE TECHNIQUE',
        courseName: 'Dance 2275A/B - INTRO MODERN DANCE TECHNIQUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216347f7e10015fd6204',
      },
      {
        _id: '60cc216447f7e10015fd6206',
        courseCode: 'DANCE 2276',
        shortName: 'Dance 2276A/B - ELEM MODERN DANCE TECH',
        courseName: 'Dance 2276A/B - ELEM MODERN DANCE TECH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216447f7e10015fd6206',
      },
      {
        _id: '60cc216447f7e10015fd6208',
        courseCode: 'DANCE 2375',
        shortName: 'Dance 2375A/B - BALLET FUNDAMENTALS',
        courseName: 'Dance 2375A/B - BALLET FUNDAMENTALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216447f7e10015fd6208',
      },
      {
        _id: '60cc216547f7e10015fd620a',
        courseCode: 'DANCE 2476',
        shortName: 'Dance 2476A/B - INTRODUCTION TO THEATRE DANCE',
        courseName: 'Dance 2476A/B - INTRODUCTION TO THEATRE DANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216547f7e10015fd620a',
      },
      {
        _id: '60cc216547f7e10015fd620c',
        courseCode: 'DANCE 3371',
        shortName: 'Dance 3371A/B - BEGINNING DANCE COMPOSITIONS & PRODUCTION',
        courseName: 'Dance 3371A/B - BEGINNING DANCE COMPOSITIONS & PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216547f7e10015fd620c',
      },
      {
        _id: '60cc216547f7e10015fd620e',
        courseCode: 'DANCE 3372',
        shortName: 'Dance 3372A/B - INTERMED DANCE COMP',
        courseName: 'Dance 3372A/B - INTERMED DANCE COMP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216547f7e10015fd620e',
      },
      {
        _id: '60cc216647f7e10015fd6210',
        courseCode: 'DANCE 4472',
        shortName: 'Dance 4472A/B - SPECIAL TOPICS IN DANCE',
        courseName: 'Dance 4472A/B - SPECIAL TOPICS IN DANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216647f7e10015fd6210',
      },
      {
        _id: '60cc216647f7e10015fd6212',
        courseCode: 'DANCE 4491',
        shortName: 'Dance 4491A/B - INDEPENDENT STUDY IN DANCE',
        courseName: 'Dance 4491A/B - INDEPENDENT STUDY IN DANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216647f7e10015fd6212',
      },
      {
        _id: '60cc216647f7e10015fd6214',
        courseCode: 'DANCE 4492',
        shortName: 'Dance 4492A/B - INDEPENDENT STUDY IN DANCE',
        courseName: 'Dance 4492A/B - INDEPENDENT STUDY IN DANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216647f7e10015fd6214',
      },
      {
        _id: '60cc216647f7e10015fd6216',
        courseCode: 'DATASCI 1000',
        shortName: 'Data Science 1000A/B - DATA SCIENCE CONCEPTS',
        courseName: 'Data Science 1000A/B - DATA SCIENCE CONCEPTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216647f7e10015fd6216',
      },
      {
        _id: '60cc216747f7e10015fd6218',
        courseCode: 'DATASCI 1200',
        shortName: 'Data Science 1200A/B - PROGRAMMING FOR DATA SCIENCE',
        courseName: 'Data Science 1200A/B - PROGRAMMING FOR DATA SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216747f7e10015fd6218',
      },
      {
        _id: '60cc216747f7e10015fd621a',
        courseCode: 'DATASCI 2000',
        shortName: 'Data Science 2000A/B - INTRODUCTION TO DATA SCIENCE',
        courseName: 'Data Science 2000A/B - INTRODUCTION TO DATA SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216747f7e10015fd621a',
      },
      {
        _id: '60cc216747f7e10015fd621c',
        courseCode: 'DATASCI 2100',
        shortName: 'Data Science 2100A - MATHEMATICS FOR DATA SCIENCE',
        courseName: 'Data Science 2100A - MATHEMATICS FOR DATA SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216747f7e10015fd621c',
      },
      {
        _id: '60cc216847f7e10015fd621e',
        courseCode: 'DATASCI 3000',
        shortName: 'Data Science 3000A/B - INTRODUCTION TO MACHINE LEARNING',
        courseName: 'Data Science 3000A/B - INTRODUCTION TO MACHINE LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216847f7e10015fd621e',
      },
      {
        _id: '60cc216847f7e10015fd6220',
        courseCode: 'DIGICOMM 2001',
        shortName: 'Digital Communication 2001A/B - SOCIAL MEDIA',
        courseName: 'Digital Communication 2001A/B - SOCIAL MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216847f7e10015fd6220',
      },
      {
        _id: '60cc216847f7e10015fd6222',
        courseCode: 'DIGICOMM 2002',
        shortName: 'Digital Communication 2002A/B - VIRTUAL WORLDS',
        courseName: 'Digital Communication 2002A/B - VIRTUAL WORLDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216847f7e10015fd6222',
      },
      {
        _id: '60cc216847f7e10015fd6224',
        courseCode: 'DIGICOMM 2200',
        shortName:
          'Digital Communication 2200F/G - SOCIAL NETWORKING: THEORY AND PRACTICE',
        courseName:
          'Digital Communication 2200F/G - SOCIAL NETWORKING: THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216847f7e10015fd6224',
      },
      {
        _id: '60cc216947f7e10015fd6226',
        courseCode: 'DIGICOMM 2203',
        shortName:
          'Digital Communication 2203A/B - INTRODUCTION TO WEB DESIGN & DIGITAL IMAGING',
        courseName:
          'Digital Communication 2203A/B - INTRODUCTION TO WEB DESIGN & DIGITAL IMAGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216947f7e10015fd6226',
      },
      {
        _id: '60cc216947f7e10015fd6228',
        courseCode: 'DIGICOMM 2204',
        shortName:
          'Digital Communication 2204A/B - INTRODUCTION TO GRAPHIC DESIGN',
        courseName:
          'Digital Communication 2204A/B - INTRODUCTION TO GRAPHIC DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216947f7e10015fd6228',
      },
      {
        _id: '60cc216947f7e10015fd622a',
        courseCode: 'DIGICOMM 2305',
        shortName:
          'Digital Communication 2305F/G - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        courseName:
          'Digital Communication 2305F/G - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216947f7e10015fd622a',
      },
      {
        _id: '60cc216947f7e10015fd622c',
        courseCode: 'DIGICOMM 2306',
        shortName:
          'Digital Communication 2306A/B - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        courseName:
          'Digital Communication 2306A/B - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216947f7e10015fd622c',
      },
      {
        _id: '60cc216a47f7e10015fd622e',
        courseCode: 'DIGICOMM 2309',
        shortName:
          'Digital Communication 2309A/B - FUNDAMENTALS OF DIGITAL MARKETING',
        courseName:
          'Digital Communication 2309A/B - FUNDAMENTALS OF DIGITAL MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216a47f7e10015fd622e',
      },
      {
        _id: '60cc216a47f7e10015fd6230',
        courseCode: 'DIGICOMM 2310',
        shortName:
          'Digital Communication 2310A/B - SOCIAL MEDIA, VIRTUAL GAMING AND NETWORKED LIFE',
        courseName:
          'Digital Communication 2310A/B - SOCIAL MEDIA, VIRTUAL GAMING AND NETWORKED LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216a47f7e10015fd6230',
      },
      {
        _id: '60cc216a47f7e10015fd6232',
        courseCode: 'DIGICOMM 2311',
        shortName:
          'Digital Communication 2311F/G - WRITING ACROSS DIGITAL MEDIA PLATFORMS',
        courseName:
          'Digital Communication 2311F/G - WRITING ACROSS DIGITAL MEDIA PLATFORMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216a47f7e10015fd6232',
      },
      {
        _id: '60cc216a47f7e10015fd6234',
        courseCode: 'DIGICOMM 3203',
        shortName: 'Digital Communication 3203F/G - SEARCH AND DISCOVERY',
        courseName: 'Digital Communication 3203F/G - SEARCH AND DISCOVERY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216a47f7e10015fd6234',
      },
      {
        _id: '60cc216b47f7e10015fd6236',
        courseCode: 'DIGICOMM 3204',
        shortName:
          'Digital Communication 3204F/G - SEARCH ENGINES & WEB DATA MINING',
        courseName:
          'Digital Communication 3204F/G - SEARCH ENGINES & WEB DATA MINING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216b47f7e10015fd6236',
      },
      {
        _id: '60cc216b47f7e10015fd6238',
        courseCode: 'DIGICOMM 3205',
        shortName:
          'Digital Communication 3205F/G - NET-WORK: LABOUR AND PROFIT IN WEB 2.0',
        courseName:
          'Digital Communication 3205F/G - NET-WORK: LABOUR AND PROFIT IN WEB 2.0',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216b47f7e10015fd6238',
      },
      {
        _id: '60cc216c47f7e10015fd623b',
        courseCode: 'DIGICOMM 3209',
        shortName:
          'Digital Communication 3209F/G - SOCIAL MEDIA & ORGANIZATIONS',
        courseName:
          'Digital Communication 3209F/G - SOCIAL MEDIA & ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216c47f7e10015fd623b',
      },
      {
        _id: '60cc216c47f7e10015fd623d',
        courseCode: 'DIGICOMM 3210',
        shortName:
          'Digital Communication 3210F/G - VIRTUAL WORLDS: THEORY, PRACTICE AND COMMUNICATIONS',
        courseName:
          'Digital Communication 3210F/G - VIRTUAL WORLDS: THEORY, PRACTICE AND COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216c47f7e10015fd623d',
      },
      {
        _id: '60cc216c47f7e10015fd623f',
        courseCode: 'DIGICOMM 3304',
        shortName:
          'Digital Communication 3304F/G - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        courseName:
          'Digital Communication 3304F/G - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216c47f7e10015fd623f',
      },
      {
        _id: '60cc216d47f7e10015fd6241',
        courseCode: 'DIGICOMM 3305',
        shortName:
          'Digital Communication 3305F/G - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        courseName:
          'Digital Communication 3305F/G - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216d47f7e10015fd6241',
      },
      {
        _id: '60cc216d47f7e10015fd6243',
        courseCode: 'DIGICOMM 3306',
        shortName:
          'Digital Communication 3306A/B - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        courseName:
          'Digital Communication 3306A/B - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216d47f7e10015fd6243',
      },
      {
        _id: '60cc216d47f7e10015fd6245',
        courseCode: 'DIGICOMM 3307',
        shortName:
          'Digital Communication 3307A/B - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        courseName:
          'Digital Communication 3307A/B - SPECIAL TOPICS IN DIGITAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216d47f7e10015fd6245',
      },
      {
        _id: '60cc216d47f7e10015fd6247',
        courseCode: 'DIGIHUM 1011',
        shortName: 'Digital Humanities 1011A/B - PROGRAMMING MY DIGITAL LIFE',
        courseName: 'Digital Humanities 1011A/B - PROGRAMMING MY DIGITAL LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216d47f7e10015fd6247',
      },
      {
        _id: '60cc216e47f7e10015fd6249',
        courseCode: 'DIGIHUM 2120',
        shortName: 'Digital Humanities 2120F/G - DIGITAL CREATIVITY',
        courseName: 'Digital Humanities 2120F/G - DIGITAL CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216e47f7e10015fd6249',
      },
      {
        _id: '60cc216e47f7e10015fd624b',
        courseCode: 'DIGIHUM 2121',
        shortName:
          'Digital Humanities 2121F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2121F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216e47f7e10015fd624b',
      },
      {
        _id: '60cc216e47f7e10015fd624d',
        courseCode: 'DIGIHUM 2122',
        shortName:
          'Digital Humanities 2122F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2122F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216e47f7e10015fd624d',
      },
      {
        _id: '60cc216e47f7e10015fd624f',
        courseCode: 'DIGIHUM 2123',
        shortName:
          'Digital Humanities 2123F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2123F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216e47f7e10015fd624f',
      },
      {
        _id: '60cc216f47f7e10015fd6251',
        courseCode: 'DIGIHUM 2124',
        shortName:
          'Digital Humanities 2124F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2124F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216f47f7e10015fd6251',
      },
      {
        _id: '60cc216f47f7e10015fd6253',
        courseCode: 'DIGIHUM 2125',
        shortName:
          'Digital Humanities 2125F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2125F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216f47f7e10015fd6253',
      },
      {
        _id: '60cc216f47f7e10015fd6255',
        courseCode: 'DIGIHUM 2126',
        shortName: 'Digital Humanities 2126F/G - ETHICS FOR A DIGITAL WORLD',
        courseName: 'Digital Humanities 2126F/G - ETHICS FOR A DIGITAL WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216f47f7e10015fd6255',
      },
      {
        _id: '60cc216f47f7e10015fd6257',
        courseCode: 'DIGIHUM 2127',
        shortName: 'Digital Humanities 2127A/B - CREATIVITY STUDIO',
        courseName: 'Digital Humanities 2127A/B - CREATIVITY STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc216f47f7e10015fd6257',
      },
      {
        _id: '60cc217047f7e10015fd6259',
        courseCode: 'DIGIHUM 2130',
        shortName:
          'Digital Humanities 2130A/B - INTRODUCTION TO DIGITAL HISTORY',
        courseName:
          'Digital Humanities 2130A/B - INTRODUCTION TO DIGITAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217047f7e10015fd6259',
      },
      {
        _id: '60cc217047f7e10015fd625b',
        courseCode: 'DIGIHUM 2144',
        shortName:
          'Digital Humanities 2144A/B - DATA ANALYTICS: PRINCIPLES AND TOOLS',
        courseName:
          'Digital Humanities 2144A/B - DATA ANALYTICS: PRINCIPLES AND TOOLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217047f7e10015fd625b',
      },
      {
        _id: '60cc217047f7e10015fd625d',
        courseCode: 'DIGIHUM 2220',
        shortName:
          'Digital Humanities 2220A/B - COMPUTING AND INFORMATICS FOR THE HUMANITIES I',
        courseName:
          'Digital Humanities 2220A/B - COMPUTING AND INFORMATICS FOR THE HUMANITIES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217047f7e10015fd625d',
      },
      {
        _id: '60cc217047f7e10015fd625f',
        courseCode: 'DIGIHUM 2221',
        shortName:
          'Digital Humanities 2221A/B - MODERN SURVIVAL SKILLS II: PROBLEM SOLVING THROUGH PROGRAMMING',
        courseName:
          'Digital Humanities 2221A/B - MODERN SURVIVAL SKILLS II: PROBLEM SOLVING THROUGH PROGRAMMING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217047f7e10015fd625f',
      },
      {
        _id: '60cc217147f7e10015fd6261',
        courseCode: 'DIGIHUM 2222',
        shortName: 'Digital Humanities 2222A/B - HUMAN-CENTRED DESIGN',
        courseName: 'Digital Humanities 2222A/B - HUMAN-CENTRED DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217147f7e10015fd6261',
      },
      {
        _id: '60cc217147f7e10015fd6263',
        courseCode: 'DIGIHUM 2223',
        shortName: 'Digital Humanities 2223A/B - DIGITAL TRANSFORMATION',
        courseName: 'Digital Humanities 2223A/B - DIGITAL TRANSFORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217147f7e10015fd6263',
      },
      {
        _id: '60cc217147f7e10015fd6265',
        courseCode: 'DIGIHUM 2224',
        shortName: 'Digital Humanities 2224F/G - DIGITAL STRATEGY',
        courseName: 'Digital Humanities 2224F/G - DIGITAL STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217147f7e10015fd6265',
      },
      {
        _id: '60cc217247f7e10015fd6267',
        courseCode: 'DIGIHUM 2301',
        shortName: 'Digital Humanities 2301F/G - DIGITAL FASHION',
        courseName: 'Digital Humanities 2301F/G - DIGITAL FASHION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217247f7e10015fd6267',
      },
      {
        _id: '60cc217247f7e10015fd6269',
        courseCode: 'DIGIHUM 2302',
        shortName: 'Digital Humanities 2302F/G - CONNECTING CULTURES',
        courseName: 'Digital Humanities 2302F/G - CONNECTING CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217247f7e10015fd6269',
      },
      {
        _id: '60cc217247f7e10015fd626b',
        courseCode: 'DIGIHUM 2303',
        shortName:
          'Digital Humanities 2303F/G - CULTURE, IDENTITY AND COMMUNITY IN VIRTUAL WORLDS',
        courseName:
          'Digital Humanities 2303F/G - CULTURE, IDENTITY AND COMMUNITY IN VIRTUAL WORLDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217247f7e10015fd626b',
      },
      {
        _id: '60cc217247f7e10015fd626d',
        courseCode: 'DIGIHUM 2304',
        shortName:
          'Digital Humanities 2304A/B - DATA, A NEW BEST FRIEND FOR THE HUMANIST',
        courseName:
          'Digital Humanities 2304A/B - DATA, A NEW BEST FRIEND FOR THE HUMANIST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217247f7e10015fd626d',
      },
      {
        _id: '60cc217347f7e10015fd626f',
        courseCode: 'DIGIHUM 2921',
        shortName:
          'Digital Humanities 2921F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2921F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217347f7e10015fd626f',
      },
      {
        _id: '60cc217347f7e10015fd6271',
        courseCode: 'DIGIHUM 2922',
        shortName:
          'Digital Humanities 2922F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2922F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217347f7e10015fd6271',
      },
      {
        _id: '60cc217347f7e10015fd6273',
        courseCode: 'DIGIHUM 2923',
        shortName:
          'Digital Humanities 2923F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2923F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217347f7e10015fd6273',
      },
      {
        _id: '60cc217347f7e10015fd6275',
        courseCode: 'DIGIHUM 2924',
        shortName:
          'Digital Humanities 2924F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2924F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217347f7e10015fd6275',
      },
      {
        _id: '60cc217447f7e10015fd6277',
        courseCode: 'DIGIHUM 2925',
        shortName:
          'Digital Humanities 2925F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 2925F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217447f7e10015fd6277',
      },
      {
        _id: '60cc217447f7e10015fd6279',
        courseCode: 'DIGIHUM 3100',
        shortName: 'Digital Humanities 3100A/B - DESIGN-BASED INNOVATION',
        courseName: 'Digital Humanities 3100A/B - DESIGN-BASED INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217447f7e10015fd6279',
      },
      {
        _id: '60cc217447f7e10015fd627b',
        courseCode: 'DIGIHUM 3120',
        shortName: 'Digital Humanities 3120A/B - TRANSMEDIA STORYTELLING',
        courseName: 'Digital Humanities 3120A/B - TRANSMEDIA STORYTELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217447f7e10015fd627b',
      },
      {
        _id: '60cc217547f7e10015fd627d',
        courseCode: 'DIGIHUM 3220',
        shortName: 'Digital Humanities 3220A/B - DATABASES FOR THE HUMANITIES',
        courseName: 'Digital Humanities 3220A/B - DATABASES FOR THE HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217547f7e10015fd627d',
      },
      {
        _id: '60cc217547f7e10015fd627f',
        courseCode: 'DIGIHUM 3221',
        shortName: 'Digital Humanities 3221A/B - DATA VISUALIZATION',
        courseName: 'Digital Humanities 3221A/B - DATA VISUALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217547f7e10015fd627f',
      },
      {
        _id: '60cc217547f7e10015fd6281',
        courseCode: 'DIGIHUM 3304',
        shortName:
          'Digital Humanities 3304F/G - ELECTRONIC TEXTUALITY AND DIGITAL ARCHIVES',
        courseName:
          'Digital Humanities 3304F/G - ELECTRONIC TEXTUALITY AND DIGITAL ARCHIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217547f7e10015fd6281',
      },
      {
        _id: '60cc217547f7e10015fd6283',
        courseCode: 'DIGIHUM 3401',
        shortName: 'Digital Humanities 3401F/G - VISUALIZING CULTURAL OBJECTS',
        courseName: 'Digital Humanities 3401F/G - VISUALIZING CULTURAL OBJECTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217547f7e10015fd6283',
      },
      {
        _id: '60cc217647f7e10015fd6285',
        courseCode: 'DIGIHUM 3402',
        shortName: 'Digital Humanities 3402F/G - EXPERIMENTAL HISTORY',
        courseName: 'Digital Humanities 3402F/G - EXPERIMENTAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217647f7e10015fd6285',
      },
      {
        _id: '60cc217647f7e10015fd6287',
        courseCode: 'DIGIHUM 3501',
        shortName: 'Digital Humanities 3501F/G - ADVANCED SOCIAL NETWORKING',
        courseName: 'Digital Humanities 3501F/G - ADVANCED SOCIAL NETWORKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217647f7e10015fd6287',
      },
      {
        _id: '60cc217647f7e10015fd6289',
        courseCode: 'DIGIHUM 3600',
        shortName:
          'Digital Humanities 3600F/G/Z - INTERNSHIP WITH DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 3600F/G/Z - INTERNSHIP WITH DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217647f7e10015fd6289',
      },
      {
        _id: '60cc217747f7e10015fd628b',
        courseCode: 'DIGIHUM 3902',
        shortName:
          'Digital Humanities 3902F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 3902F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217747f7e10015fd628b',
      },
      {
        _id: '60cc217747f7e10015fd628d',
        courseCode: 'DIGIHUM 3903',
        shortName:
          'Digital Humanities 3903F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 3903F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217747f7e10015fd628d',
      },
      {
        _id: '60cc217747f7e10015fd628f',
        courseCode: 'DIGIHUM 3904',
        shortName:
          'Digital Humanities 3904F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 3904F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217747f7e10015fd628f',
      },
      {
        _id: '60cc217847f7e10015fd6291',
        courseCode: 'DIGIHUM 3905',
        shortName:
          'Digital Humanities 3905F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        courseName:
          'Digital Humanities 3905F/G - SPECIAL TOPICS IN DIGITAL HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217847f7e10015fd6291',
      },
      {
        _id: '60cc217847f7e10015fd6293',
        courseCode: 'DISABST 1010',
        shortName: 'Disability Studies 1010A/B - EXPLORING DISABILITY',
        courseName: 'Disability Studies 1010A/B - EXPLORING DISABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217847f7e10015fd6293',
      },
      {
        _id: '60cc217947f7e10015fd6295',
        courseCode: 'DISABST 2023',
        shortName:
          'Disability Studies 2023A/B - DISABILITY SPORTS & RECREATION',
        courseName:
          'Disability Studies 2023A/B - DISABILITY SPORTS & RECREATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217947f7e10015fd6295',
      },
      {
        _id: '60cc217947f7e10015fd6297',
        courseCode: 'DISABST 2201',
        shortName: 'Disability Studies 2201F/G - RETHINKING DISABILITY',
        courseName: 'Disability Studies 2201F/G - RETHINKING DISABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217947f7e10015fd6297',
      },
      {
        _id: '60cc217947f7e10015fd6299',
        courseCode: 'DISABST 2202',
        shortName:
          'Disability Studies 2202A/B - UNDERSTANDING DISABILITY LABELS',
        courseName:
          'Disability Studies 2202A/B - UNDERSTANDING DISABILITY LABELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217947f7e10015fd6299',
      },
      {
        _id: '60cc217a47f7e10015fd629b',
        courseCode: 'DISABST 2210',
        shortName:
          'Disability Studies 2210A/B - EDUCATION & DISABILITY STUDIES',
        courseName:
          'Disability Studies 2210A/B - EDUCATION & DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217a47f7e10015fd629b',
      },
      {
        _id: '60cc217a47f7e10015fd629d',
        courseCode: 'DISABST 2214',
        shortName:
          'Disability Studies 2214A/B - INSTITUTIONALIZATION OVER TIME',
        courseName:
          'Disability Studies 2214A/B - INSTITUTIONALIZATION OVER TIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217a47f7e10015fd629d',
      },
      {
        _id: '60cc217a47f7e10015fd629f',
        courseCode: 'DISABST 2216',
        shortName:
          'Disability Studies 2216A/B - DISABILITY AND POPULAR CULTURE',
        courseName:
          'Disability Studies 2216A/B - DISABILITY AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217a47f7e10015fd629f',
      },
      {
        _id: '60cc217a47f7e10015fd62a1',
        courseCode: 'DISABST 2218',
        shortName: 'Disability Studies 2218A/B - MADNESS AND MAD STUDIES',
        courseName: 'Disability Studies 2218A/B - MADNESS AND MAD STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217a47f7e10015fd62a1',
      },
      {
        _id: '60cc217b47f7e10015fd62a3',
        courseCode: 'DISABST 2220',
        shortName:
          'Disability Studies 2220A/B - INCLUSION AND ACCESSIBILITY IN CAMPS',
        courseName:
          'Disability Studies 2220A/B - INCLUSION AND ACCESSIBILITY IN CAMPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217b47f7e10015fd62a3',
      },
      {
        _id: '60cc217b47f7e10015fd62a5',
        courseCode: 'DISABST 2224',
        shortName: 'Disability Studies 2224A/B - RESEARCH METHODS',
        courseName: 'Disability Studies 2224A/B - RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217b47f7e10015fd62a5',
      },
      {
        _id: '60cc217c47f7e10015fd62a7',
        courseCode: 'DISABST 2262',
        shortName:
          'Disability Studies 2262F/G - WRITING INSTRUCTION AND DISABILITY STUDIES FRAMEWORKS',
        courseName:
          'Disability Studies 2262F/G - WRITING INSTRUCTION AND DISABILITY STUDIES FRAMEWORKS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217c47f7e10015fd62a7',
      },
      {
        _id: '60cc217c47f7e10015fd62a9',
        courseCode: 'DISABST 2270',
        shortName:
          'Disability Studies 2270F/G - DISABILITY, GENDER, AND SEXUALITY',
        courseName:
          'Disability Studies 2270F/G - DISABILITY, GENDER, AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217c47f7e10015fd62a9',
      },
      {
        _id: '60cc217c47f7e10015fd62ab',
        courseCode: 'DISABST 2272',
        shortName: 'Disability Studies 2272F/G - BIOETHICS AND DISABILITY',
        courseName: 'Disability Studies 2272F/G - BIOETHICS AND DISABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217c47f7e10015fd62ab',
      },
      {
        _id: '60cc217d47f7e10015fd62ad',
        courseCode: 'DISABST 2285',
        shortName: 'Disability Studies 2285E - COMMUNITY-BASED EXPERIENCE',
        courseName: 'Disability Studies 2285E - COMMUNITY-BASED EXPERIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217d47f7e10015fd62ad',
      },
      {
        _id: '60cc217d47f7e10015fd62af',
        courseCode: 'DISABST 2290',
        shortName:
          'Disability Studies 2290A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2290A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217d47f7e10015fd62af',
      },
      {
        _id: '60cc217d47f7e10015fd62b1',
        courseCode: 'DISABST 2291',
        shortName:
          'Disability Studies 2291A/B - SPECIAL  TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2291A/B - SPECIAL  TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217d47f7e10015fd62b1',
      },
      {
        _id: '60cc217d47f7e10015fd62b3',
        courseCode: 'DISABST 2292',
        shortName:
          'Disability Studies 2292A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2292A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217d47f7e10015fd62b3',
      },
      {
        _id: '60cc217e47f7e10015fd62b5',
        courseCode: 'DISABST 2293',
        shortName:
          'Disability Studies 2293A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2293A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217e47f7e10015fd62b5',
      },
      {
        _id: '60cc217e47f7e10015fd62b7',
        courseCode: 'DISABST 2294',
        shortName:
          'Disability Studies 2294A/B - SPEC IAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2294A/B - SPEC IAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217e47f7e10015fd62b7',
      },
      {
        _id: '60cc217e47f7e10015fd62b9',
        courseCode: 'DISABST 2295',
        shortName:
          'Disability Studies 2295A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2295A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217e47f7e10015fd62b9',
      },
      {
        _id: '60cc217f47f7e10015fd62bb',
        courseCode: 'DISABST 2296',
        shortName:
          'Disability Studies 2296A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 2296A/B - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217f47f7e10015fd62bb',
      },
      {
        _id: '60cc217f47f7e10015fd62bd',
        courseCode: 'DISABST 3311',
        shortName: 'Disability Studies 3311F/G - CHANGING CONTEXTS & PRACTICES',
        courseName:
          'Disability Studies 3311F/G - CHANGING CONTEXTS & PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217f47f7e10015fd62bd',
      },
      {
        _id: '60cc217f47f7e10015fd62bf',
        courseCode: 'DISABST 3312',
        shortName:
          'Disability Studies 3312F/G - DIVERSE DISABILITY STUDIES PERSPECTIVES',
        courseName:
          'Disability Studies 3312F/G - DIVERSE DISABILITY STUDIES PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217f47f7e10015fd62bf',
      },
      {
        _id: '60cc217f47f7e10015fd62c1',
        courseCode: 'DISABST 3316',
        shortName: 'Disability Studies 3316F/G - MEMES AND DREAMS',
        courseName: 'Disability Studies 3316F/G - MEMES AND DREAMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc217f47f7e10015fd62c1',
      },
      {
        _id: '60cc218047f7e10015fd62c3',
        courseCode: 'DISABST 3320',
        shortName:
          'Disability Studies 3320F/G - NARRATIVES OF DISABILITY & CARE',
        courseName:
          'Disability Studies 3320F/G - NARRATIVES OF DISABILITY & CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218047f7e10015fd62c3',
      },
      {
        _id: '60cc218047f7e10015fd62c5',
        courseCode: 'DISABST 3321',
        shortName: 'Disability Studies 3321F/G - APPLIED HEALTH PROFESSIONS',
        courseName: 'Disability Studies 3321F/G - APPLIED HEALTH PROFESSIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218047f7e10015fd62c5',
      },
      {
        _id: '60cc218047f7e10015fd62c7',
        courseCode: 'DISABST 3325',
        shortName: 'Disability Studies 3325F/G - LAW AND BIOETHICS',
        courseName: 'Disability Studies 3325F/G - LAW AND BIOETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218047f7e10015fd62c7',
      },
      {
        _id: '60cc218147f7e10015fd62c9',
        courseCode: 'DISABST 3326',
        shortName: 'Disability Studies 3326F/G - DISABILITY RIGHTS AND LAW',
        courseName: 'Disability Studies 3326F/G - DISABILITY RIGHTS AND LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218147f7e10015fd62c9',
      },
      {
        _id: '60cc218147f7e10015fd62cb',
        courseCode: 'DISABST 3339',
        shortName: 'Disability Studies 3339F/G - DISABILITY CROSS-CULTURALLY',
        courseName: 'Disability Studies 3339F/G - DISABILITY CROSS-CULTURALLY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218147f7e10015fd62cb',
      },
      {
        _id: '60cc218147f7e10015fd62cd',
        courseCode: 'DISABST 3390',
        shortName:
          'Disability Studies 3390F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3390F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218147f7e10015fd62cd',
      },
      {
        _id: '60cc218147f7e10015fd62cf',
        courseCode: 'DISABST 3391',
        shortName:
          'Disability Studies 3391F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3391F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218147f7e10015fd62cf',
      },
      {
        _id: '60cc218247f7e10015fd62d1',
        courseCode: 'DISABST 3392',
        shortName:
          'Disability Studies 3392F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3392F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218247f7e10015fd62d1',
      },
      {
        _id: '60cc218247f7e10015fd62d3',
        courseCode: 'DISABST 3393',
        shortName:
          'Disability Studies 3393F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3393F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218247f7e10015fd62d3',
      },
      {
        _id: '60cc218247f7e10015fd62d5',
        courseCode: 'DISABST 3394',
        shortName:
          'Disability Studies 3394F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3394F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218247f7e10015fd62d5',
      },
      {
        _id: '60cc218347f7e10015fd62d7',
        courseCode: 'DISABST 3395',
        shortName:
          'Disability Studies 3395F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3395F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218347f7e10015fd62d7',
      },
      {
        _id: '60cc218347f7e10015fd62d9',
        courseCode: 'DISABST 3396',
        shortName:
          'Disability Studies 3396F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3396F/G - SPECIAL TOPICS IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218347f7e10015fd62d9',
      },
      {
        _id: '60cc218347f7e10015fd62db',
        courseCode: 'DISABST 3399',
        shortName:
          'Disability Studies 3399F/G - INDEPENDENT STUDY IN DISABILITY STUDIES',
        courseName:
          'Disability Studies 3399F/G - INDEPENDENT STUDY IN DISABILITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218347f7e10015fd62db',
      },
      {
        _id: '60cc218447f7e10015fd62dd',
        courseCode: 'DISABST 4401',
        shortName: 'Disability Studies 4401F/G - CAPSTONE COURSE',
        courseName: 'Disability Studies 4401F/G - CAPSTONE COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218447f7e10015fd62dd',
      },
      {
        _id: '60cc218447f7e10015fd62df',
        courseCode: 'DISABST 4499',
        shortName: 'Disability Studies 4499F/G - INDEPENDENT ST DISAB STUDIES',
        courseName: 'Disability Studies 4499F/G - INDEPENDENT ST DISAB STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218447f7e10015fd62df',
      },
      {
        _id: '60cc218447f7e10015fd62e1',
        courseCode: 'EARTHSCI 1022',
        shortName: 'Earth Sciences 1022A/B - EARTH ROCKS!',
        courseName: 'Earth Sciences 1022A/B - EARTH ROCKS!',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218447f7e10015fd62e1',
      },
      {
        _id: '60cc218447f7e10015fd62e3',
        courseCode: 'EARTHSCI 1023',
        shortName: 'Earth Sciences 1023A/B - PLANET EARTH: SHAKEN AND STIRRED',
        courseName: 'Earth Sciences 1023A/B - PLANET EARTH: SHAKEN AND STIRRED',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218447f7e10015fd62e3',
      },
      {
        _id: '60cc218547f7e10015fd62e5',
        courseCode: 'EARTHSCI 1070',
        shortName:
          'Earth Sciences 1070A/B - INTRODUCTION TO THE GEOLOGY AND RESOURCES OF EARTH',
        courseName:
          'Earth Sciences 1070A/B - INTRODUCTION TO THE GEOLOGY AND RESOURCES OF EARTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218547f7e10015fd62e5',
      },
      {
        _id: '60cc218547f7e10015fd62e7',
        courseCode: 'EARTHSCI 1081',
        shortName:
          'Earth Sciences 1081A/B - EARTH, ENVIRONMENT AND SUSTAINABILITY',
        courseName:
          'Earth Sciences 1081A/B - EARTH, ENVIRONMENT AND SUSTAINABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218547f7e10015fd62e7',
      },
      {
        _id: '60cc218547f7e10015fd62e9',
        courseCode: 'EARTHSCI 1083',
        shortName: 'Earth Sciences 1083F/G - LIFE ON PLANET EARTH',
        courseName: 'Earth Sciences 1083F/G - LIFE ON PLANET EARTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218547f7e10015fd62e9',
      },
      {
        _id: '60cc218547f7e10015fd62eb',
        courseCode: 'EARTHSCI 1086',
        shortName:
          'Earth Sciences 1086F/G - ORIGIN AND GEOLOGY OF THE SOLAR SYSTEM',
        courseName:
          'Earth Sciences 1086F/G - ORIGIN AND GEOLOGY OF THE SOLAR SYSTEM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218547f7e10015fd62eb',
      },
      {
        _id: '60cc218647f7e10015fd62ed',
        courseCode: 'EARTHSCI 1089',
        shortName: 'Earth Sciences 1089F/G - EARTH, ART AND CULTURE',
        courseName: 'Earth Sciences 1089F/G - EARTH, ART AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218647f7e10015fd62ed',
      },
      {
        _id: '60cc218647f7e10015fd62ef',
        courseCode: 'EARTHSCI 2123',
        shortName: 'Earth Sciences 2123F/G - THE DYNAMIC EARTH',
        courseName: 'Earth Sciences 2123F/G - THE DYNAMIC EARTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218647f7e10015fd62ef',
      },
      {
        _id: '60cc218647f7e10015fd62f1',
        courseCode: 'EARTHSCI 2130',
        shortName:
          'Earth Sciences 2130Y - FIELD GEOGRAPHY AND GEOLOGY OF SOUTHWESTERN ONTARIO',
        courseName:
          'Earth Sciences 2130Y - FIELD GEOGRAPHY AND GEOLOGY OF SOUTHWESTERN ONTARIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218647f7e10015fd62f1',
      },
      {
        _id: '60cc218647f7e10015fd62f3',
        courseCode: 'EARTHSCI 2200',
        shortName:
          'Earth Sciences 2200A/B - PLATE TECTONIC THEORY, ENVIRONMENTS AND PRODUCTS',
        courseName:
          'Earth Sciences 2200A/B - PLATE TECTONIC THEORY, ENVIRONMENTS AND PRODUCTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218647f7e10015fd62f3',
      },
      {
        _id: '60cc218747f7e10015fd62f5',
        courseCode: 'EARTHSCI 2201',
        shortName: 'Earth Sciences 2201A/B - STRUCTURAL GEOLOGY',
        courseName: 'Earth Sciences 2201A/B - STRUCTURAL GEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218747f7e10015fd62f5',
      },
      {
        _id: '60cc218747f7e10015fd62f7',
        courseCode: 'EARTHSCI 2206',
        shortName:
          'Earth Sciences 2206A/B - MINERAL SYSTEMS, CRYSTALLOGRAPHY AND OPTICS',
        courseName:
          'Earth Sciences 2206A/B - MINERAL SYSTEMS, CRYSTALLOGRAPHY AND OPTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218747f7e10015fd62f7',
      },
      {
        _id: '60cc218747f7e10015fd62f9',
        courseCode: 'EARTHSCI 2220',
        shortName:
          'Earth Sciences 2220A/B - ENVIRONMENTAL AND EXPLORATION GEOPHYSICS I',
        courseName:
          'Earth Sciences 2220A/B - ENVIRONMENTAL AND EXPLORATION GEOPHYSICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218747f7e10015fd62f9',
      },
      {
        _id: '60cc218747f7e10015fd62fb',
        courseCode: 'EARTHSCI 2222',
        shortName: 'Earth Sciences 2222A/B - ANALYTICS FOR EARTH SCIENCE',
        courseName: 'Earth Sciences 2222A/B - ANALYTICS FOR EARTH SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218747f7e10015fd62fb',
      },
      {
        _id: '60cc218847f7e10015fd62fd',
        courseCode: 'EARTHSCI 2230',
        shortName: 'Earth Sciences 2230A/B - INTRODUCTION TO GEOCHEMISTRY',
        courseName: 'Earth Sciences 2230A/B - INTRODUCTION TO GEOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218847f7e10015fd62fd',
      },
      {
        _id: '60cc218847f7e10015fd62ff',
        courseCode: 'EARTHSCI 2232',
        shortName: 'Earth Sciences 2232F/G - EXPLORING THE PLANETS',
        courseName: 'Earth Sciences 2232F/G - EXPLORING THE PLANETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218847f7e10015fd62ff',
      },
      {
        _id: '60cc218847f7e10015fd6301',
        courseCode: 'EARTHSCI 2240',
        shortName:
          'Earth Sciences 2240F/G - CATASTROPHIC EVENTS IN EARTH HISTORY',
        courseName:
          'Earth Sciences 2240F/G - CATASTROPHIC EVENTS IN EARTH HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218847f7e10015fd6301',
      },
      {
        _id: '60cc218847f7e10015fd6303',
        courseCode: 'EARTHSCI 2241',
        shortName: 'Earth Sciences 2241A/B - HAZARDOUS EARTH',
        courseName: 'Earth Sciences 2241A/B - HAZARDOUS EARTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218847f7e10015fd6303',
      },
      {
        _id: '60cc218947f7e10015fd6305',
        courseCode: 'EARTHSCI 2250',
        shortName:
          'Earth Sciences 2250Y - INTRODUCTORY FIELD MAPPING TECHNIQUES',
        courseName:
          'Earth Sciences 2250Y - INTRODUCTORY FIELD MAPPING TECHNIQUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218947f7e10015fd6305',
      },
      {
        _id: '60cc218947f7e10015fd6307',
        courseCode: 'EARTHSCI 2260',
        shortName:
          'Earth Sciences 2260A/B - STRATIGRAPHY AND SEDIMENTOLOGY: FROM BEDS TO BASINS',
        courseName:
          'Earth Sciences 2260A/B - STRATIGRAPHY AND SEDIMENTOLOGY: FROM BEDS TO BASINS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218947f7e10015fd6307',
      },
      {
        _id: '60cc218947f7e10015fd6309',
        courseCode: 'EARTHSCI 2265',
        shortName: 'Earth Sciences 2265A/B - PALEOBIOLOGY AND PALEOECOLOGY',
        courseName: 'Earth Sciences 2265A/B - PALEOBIOLOGY AND PALEOECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218947f7e10015fd6309',
      },
      {
        _id: '60cc218947f7e10015fd630b',
        courseCode: 'EARTHSCI 2266',
        shortName:
          'Earth Sciences 2266F/G - DINOSAUR AND OTHER VERTEBRATE EVOLUTION',
        courseName:
          'Earth Sciences 2266F/G - DINOSAUR AND OTHER VERTEBRATE EVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218947f7e10015fd630b',
      },
      {
        _id: '60cc218a47f7e10015fd630d',
        courseCode: 'EARTHSCI 2281',
        shortName: 'Earth Sciences 2281A/B - GEOLOGY FOR ENGINEERS',
        courseName: 'Earth Sciences 2281A/B - GEOLOGY FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218a47f7e10015fd630d',
      },
      {
        _id: '60cc218a47f7e10015fd630f',
        courseCode: 'EARTHSCI 3001',
        shortName: 'Earth Sciences 3001A/B - ASTROBIOLOGY',
        courseName: 'Earth Sciences 3001A/B - ASTROBIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218a47f7e10015fd630f',
      },
      {
        _id: '60cc218a47f7e10015fd6311',
        courseCode: 'EARTHSCI 3310',
        shortName:
          'Earth Sciences 3310A/B - STRUCTURE AND CHEMISTRY OF MINERALS AND MATERIALS',
        courseName:
          'Earth Sciences 3310A/B - STRUCTURE AND CHEMISTRY OF MINERALS AND MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218a47f7e10015fd6311',
      },
      {
        _id: '60cc218a47f7e10015fd6313',
        courseCode: 'EARTHSCI 3312',
        shortName:
          'Earth Sciences 3312A/B - ORIGIN OF METEORITES AND PLANETARY MATERIALS',
        courseName:
          'Earth Sciences 3312A/B - ORIGIN OF METEORITES AND PLANETARY MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218a47f7e10015fd6313',
      },
      {
        _id: '60cc218b47f7e10015fd6315',
        courseCode: 'EARTHSCI 3313',
        shortName: 'Earth Sciences 3313A/B - IGNEOUS PETROLOGY',
        courseName: 'Earth Sciences 3313A/B - IGNEOUS PETROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218b47f7e10015fd6315',
      },
      {
        _id: '60cc218b47f7e10015fd6317',
        courseCode: 'EARTHSCI 3314',
        shortName: 'Earth Sciences 3314A/B - SEDIMENTARY PETROLOGY',
        courseName: 'Earth Sciences 3314A/B - SEDIMENTARY PETROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218b47f7e10015fd6317',
      },
      {
        _id: '60cc218b47f7e10015fd6319',
        courseCode: 'EARTHSCI 3315',
        shortName: 'Earth Sciences 3315A/B - METAMORPHIC PETROLOGY',
        courseName: 'Earth Sciences 3315A/B - METAMORPHIC PETROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218b47f7e10015fd6319',
      },
      {
        _id: '60cc218b47f7e10015fd631b',
        courseCode: 'EARTHSCI 3320',
        shortName:
          'Earth Sciences 3320A/B - ENVIRONMENTAL AND EXPLORATION GEOPHYSICS II',
        courseName:
          'Earth Sciences 3320A/B - ENVIRONMENTAL AND EXPLORATION GEOPHYSICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218b47f7e10015fd631b',
      },
      {
        _id: '60cc218c47f7e10015fd631d',
        courseCode: 'EARTHSCI 3321',
        shortName: 'Earth Sciences 3321A/B - PHYSICS OF THE EARTH I',
        courseName: 'Earth Sciences 3321A/B - PHYSICS OF THE EARTH I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218c47f7e10015fd631d',
      },
      {
        _id: '60cc218c47f7e10015fd631f',
        courseCode: 'EARTHSCI 3323',
        shortName:
          'Earth Sciences 3323A/B - INTRODUCTION TO  GEODESY AND REMOTE SENSING',
        courseName:
          'Earth Sciences 3323A/B - INTRODUCTION TO  GEODESY AND REMOTE SENSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218c47f7e10015fd631f',
      },
      {
        _id: '60cc218c47f7e10015fd6321',
        courseCode: 'EARTHSCI 3340',
        shortName: 'Earth Sciences 3340A/B - WATERSHED HYDROLOGY',
        courseName: 'Earth Sciences 3340A/B - WATERSHED HYDROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218c47f7e10015fd6321',
      },
      {
        _id: '60cc218c47f7e10015fd6323',
        courseCode: 'EARTHSCI 3341',
        shortName: 'Earth Sciences 3341A/B - WATERS AND GEOCHEMICAL CYCLES',
        courseName: 'Earth Sciences 3341A/B - WATERS AND GEOCHEMICAL CYCLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218c47f7e10015fd6323',
      },
      {
        _id: '60cc218d47f7e10015fd6325',
        courseCode: 'EARTHSCI 3350',
        shortName: 'Earth Sciences 3350Y - ADVANCED FIELD MAPPING TECHNIQUES',
        courseName: 'Earth Sciences 3350Y - ADVANCED FIELD MAPPING TECHNIQUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218d47f7e10015fd6325',
      },
      {
        _id: '60cc218d47f7e10015fd6327',
        courseCode: 'EARTHSCI 3369',
        shortName: 'Earth Sciences 3369A/B - GEOMICROBIOLOGY',
        courseName: 'Earth Sciences 3369A/B - GEOMICROBIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218d47f7e10015fd6327',
      },
      {
        _id: '60cc218d47f7e10015fd6329',
        courseCode: 'EARTHSCI 3370',
        shortName: 'Earth Sciences 3370A/B - METALLOGENY I: ORE PETROLOGY',
        courseName: 'Earth Sciences 3370A/B - METALLOGENY I: ORE PETROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218d47f7e10015fd6329',
      },
      {
        _id: '60cc218d47f7e10015fd632b',
        courseCode: 'EARTHSCI 3371',
        shortName:
          'Earth Sciences 3371A/B - METALLOGENY II: ORE DEPOSITS MODELS',
        courseName:
          'Earth Sciences 3371A/B - METALLOGENY II: ORE DEPOSITS MODELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218d47f7e10015fd632b',
      },
      {
        _id: '60cc218e47f7e10015fd632d',
        courseCode: 'EARTHSCI 3372',
        shortName: 'Earth Sciences 3372A/B - INTRODUCTION TO PETROLEUM SYSTEMS',
        courseName:
          'Earth Sciences 3372A/B - INTRODUCTION TO PETROLEUM SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218e47f7e10015fd632d',
      },
      {
        _id: '60cc218e47f7e10015fd632f',
        courseCode: 'EARTHSCI 4001',
        shortName: 'Earth Sciences 4001Y - PLANETARY SCIENCE FIELD SCHOOL',
        courseName: 'Earth Sciences 4001Y - PLANETARY SCIENCE FIELD SCHOOL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218e47f7e10015fd632f',
      },
      {
        _id: '60cc218e47f7e10015fd6331',
        courseCode: 'EARTHSCI 4420',
        shortName:
          'Earth Sciences 4420A/B - GEOPHYSICAL FORWARD AND INVERSE MODELLING METHODS',
        courseName:
          'Earth Sciences 4420A/B - GEOPHYSICAL FORWARD AND INVERSE MODELLING METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218e47f7e10015fd6331',
      },
      {
        _id: '60cc218e47f7e10015fd6333',
        courseCode: 'EARTHSCI 4421',
        shortName: 'Earth Sciences 4421A/B - PHYSICS OF THE EARTH II',
        courseName: 'Earth Sciences 4421A/B - PHYSICS OF THE EARTH II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218e47f7e10015fd6333',
      },
      {
        _id: '60cc218f47f7e10015fd6335',
        courseCode: 'EARTHSCI 4423',
        shortName: 'Earth Sciences 4423A/B - APPLIED SEISMOLOGY',
        courseName: 'Earth Sciences 4423A/B - APPLIED SEISMOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218f47f7e10015fd6335',
      },
      {
        _id: '60cc218f47f7e10015fd6337',
        courseCode: 'EARTHSCI 4424',
        shortName: 'Earth Sciences 4424A/B - MINERAL PHYSICS',
        courseName: 'Earth Sciences 4424A/B - MINERAL PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218f47f7e10015fd6337',
      },
      {
        _id: '60cc218f47f7e10015fd6339',
        courseCode: 'EARTHSCI 4431',
        shortName:
          'Earth Sciences 4431A/B - ISOTOPE GEOCHEMISTRY IN EARTH AND ENVIRONMENTAL SCIENCE',
        courseName:
          'Earth Sciences 4431A/B - ISOTOPE GEOCHEMISTRY IN EARTH AND ENVIRONMENTAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218f47f7e10015fd6339',
      },
      {
        _id: '60cc218f47f7e10015fd633b',
        courseCode: 'EARTHSCI 4432',
        shortName: 'Earth Sciences 4432A/B - MINERAL DEPOSIT GEOCHEMISTRY',
        courseName: 'Earth Sciences 4432A/B - MINERAL DEPOSIT GEOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc218f47f7e10015fd633b',
      },
      {
        _id: '60cc219047f7e10015fd633d',
        courseCode: 'EARTHSCI 4435',
        shortName: 'Earth Sciences 4435A/B - PLANETARY SURFACE PROCESSES',
        courseName: 'Earth Sciences 4435A/B - PLANETARY SURFACE PROCESSES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219047f7e10015fd633d',
      },
      {
        _id: '60cc219047f7e10015fd633f',
        courseCode: 'EARTHSCI 4440',
        shortName:
          'Earth Sciences 4440A/B - HYDROGEOLOGY: PRINCIPLES, PROCESSES, AND PROFESSIONAL PRACTICE',
        courseName:
          'Earth Sciences 4440A/B - HYDROGEOLOGY: PRINCIPLES, PROCESSES, AND PROFESSIONAL PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219047f7e10015fd633f',
      },
      {
        _id: '60cc219047f7e10015fd6341',
        courseCode: 'EARTHSCI 4450',
        shortName: 'Earth Sciences 4450Y - REGIONAL FIELD GEOLOGY',
        courseName: 'Earth Sciences 4450Y - REGIONAL FIELD GEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219047f7e10015fd6341',
      },
      {
        _id: '60cc219047f7e10015fd6343',
        courseCode: 'EARTHSCI 4451',
        shortName: 'Earth Sciences 4451Z - GEOPHYSICAL FIELD TECHNIQUES',
        courseName: 'Earth Sciences 4451Z - GEOPHYSICAL FIELD TECHNIQUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219047f7e10015fd6343',
      },
      {
        _id: '60cc219147f7e10015fd6345',
        courseCode: 'EARTHSCI 4452',
        shortName:
          'Earth Sciences 4452Z - INTERNATIONAL GEOSCIENCE FIELD EXPERIENCE',
        courseName:
          'Earth Sciences 4452Z - INTERNATIONAL GEOSCIENCE FIELD EXPERIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219147f7e10015fd6345',
      },
      {
        _id: '60cc219147f7e10015fd6347',
        courseCode: 'EARTHSCI 4458',
        shortName: 'Earth Sciences 4458A/B - SELECTED TOPICS IN EARTH SCIENCE',
        courseName: 'Earth Sciences 4458A/B - SELECTED TOPICS IN EARTH SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219147f7e10015fd6347',
      },
      {
        _id: '60cc219147f7e10015fd6349',
        courseCode: 'EARTHSCI 4459',
        shortName: 'Earth Sciences 4459A/B - SELECTED TOPICS IN EARTH SCIENCE',
        courseName: 'Earth Sciences 4459A/B - SELECTED TOPICS IN EARTH SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219147f7e10015fd6349',
      },
      {
        _id: '60cc219147f7e10015fd634b',
        courseCode: 'EARTHSCI 4460',
        shortName:
          'Earth Sciences 4460A/B - SEDIMENTOLOGY OF CLASTIC AND CARBONATE ROCKS',
        courseName:
          'Earth Sciences 4460A/B - SEDIMENTOLOGY OF CLASTIC AND CARBONATE ROCKS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219147f7e10015fd634b',
      },
      {
        _id: '60cc219147f7e10015fd634d',
        courseCode: 'EARTHSCI 4461',
        shortName: 'Earth Sciences 4461A/B - ADVANCED PALEONTOLOGY',
        courseName: 'Earth Sciences 4461A/B - ADVANCED PALEONTOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219147f7e10015fd634d',
      },
      {
        _id: '60cc219247f7e10015fd634f',
        courseCode: 'EARTHSCI 4462',
        shortName: 'Earth Sciences 4462A/B - GLACIAL AND QUATERNARY GEOLOGY',
        courseName: 'Earth Sciences 4462A/B - GLACIAL AND QUATERNARY GEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219247f7e10015fd634f',
      },
      {
        _id: '60cc219247f7e10015fd6351',
        courseCode: 'EARTHSCI 4470',
        shortName:
          'Earth Sciences 4470A/B - GLOBAL METALLOGENIC CYCLES IN CRUSTAL EVOLUTION',
        courseName:
          'Earth Sciences 4470A/B - GLOBAL METALLOGENIC CYCLES IN CRUSTAL EVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219247f7e10015fd6351',
      },
      {
        _id: '60cc219247f7e10015fd6353',
        courseCode: 'EARTHSCI 4472',
        shortName: 'Earth Sciences 4472A/B - APPLIED PETROLEUM ASSESSMENT',
        courseName: 'Earth Sciences 4472A/B - APPLIED PETROLEUM ASSESSMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219247f7e10015fd6353',
      },
      {
        _id: '60cc219247f7e10015fd6355',
        courseCode: 'EARTHSCI 4490',
        shortName: 'Earth Sciences 4490E - SENIOR THESIS',
        courseName: 'Earth Sciences 4490E - SENIOR THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219247f7e10015fd6355',
      },
      {
        _id: '60cc219347f7e10015fd6357',
        courseCode: 'EARTHSCI 4600',
        shortName: 'Earth Sciences 4600Y - PLANETARY GEOLOGY SEMINAR',
        courseName: 'Earth Sciences 4600Y - PLANETARY GEOLOGY SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219347f7e10015fd6357',
      },
      {
        _id: '60cc219347f7e10015fd6359',
        courseCode: 'EARTHSCI 4606',
        shortName:
          'Earth Sciences 4606Y - REMOTE SENSING AND IMAGE ANALYSIS FOR EARTH AND PLANETARY SCIENCE',
        courseName:
          'Earth Sciences 4606Y - REMOTE SENSING AND IMAGE ANALYSIS FOR EARTH AND PLANETARY SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219347f7e10015fd6359',
      },
      {
        _id: '60cc219347f7e10015fd635b',
        courseCode: 'ECONOMIC 0011',
        shortName: 'Economics 0011A/B - INTRODUCTION MICROECONOMICS',
        courseName: 'Economics 0011A/B - INTRODUCTION MICROECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219347f7e10015fd635b',
      },
      {
        _id: '60cc219347f7e10015fd635d',
        courseCode: 'ECONOMIC 0012',
        shortName: 'Economics 0012A/B - INTRODUCTION TO MACROECONOMICS',
        courseName: 'Economics 0012A/B - INTRODUCTION TO MACROECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219347f7e10015fd635d',
      },
      {
        _id: '60cc219447f7e10015fd635f',
        courseCode: 'ECONOMIC 1021',
        shortName: 'Economics 1021A/B - PRINCIPLES OF MICROECONOMICS',
        courseName: 'Economics 1021A/B - PRINCIPLES OF MICROECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 57,
        id: '60cc219447f7e10015fd635f',
      },
      {
        _id: '60cc219447f7e10015fd6361',
        courseCode: 'ECONOMIC 1022',
        shortName: 'Economics 1022A/B - PRINCIPLES OF MACROECONOMICS',
        courseName: 'Economics 1022A/B - PRINCIPLES OF MACROECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 41,
        id: '60cc219447f7e10015fd6361',
      },
      {
        _id: '60cc219547f7e10015fd6363',
        courseCode: 'ECONOMIC 2001',
        shortName:
          'Economics 2001A/B - ECONOMICS FOR ENGINEERING AND SCIENCE STUDENTS',
        courseName:
          'Economics 2001A/B - ECONOMICS FOR ENGINEERING AND SCIENCE STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219547f7e10015fd6363',
      },
      {
        _id: '60cc219547f7e10015fd6365',
        courseCode: 'ECONOMIC 2111',
        shortName: 'Economics 2111F/G - ECONOMIC HISTORY OF CANADA I',
        courseName: 'Economics 2111F/G - ECONOMIC HISTORY OF CANADA I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219547f7e10015fd6365',
      },
      {
        _id: '60cc219547f7e10015fd6367',
        courseCode: 'ECONOMIC 2112',
        shortName: 'Economics 2112F/G - ECONOMIC HISTORY OF CANADA II',
        courseName: 'Economics 2112F/G - ECONOMIC HISTORY OF CANADA II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219547f7e10015fd6367',
      },
      {
        _id: '60cc219647f7e10015fd6369',
        courseCode: 'ECONOMIC 2114',
        shortName: 'Economics 2114F/G - ASIAN ECONOMICS',
        courseName: 'Economics 2114F/G - ASIAN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219647f7e10015fd6369',
      },
      {
        _id: '60cc219647f7e10015fd636b',
        courseCode: 'ECONOMIC 2120',
        shortName: 'Economics 2120A/B - FINANCIAL ECONOMICS OF THE FIRM',
        courseName: 'Economics 2120A/B - FINANCIAL ECONOMICS OF THE FIRM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219647f7e10015fd636b',
      },
      {
        _id: '60cc219647f7e10015fd636d',
        courseCode: 'ECONOMIC 2121',
        shortName: 'Economics 2121A/B - THE FIRM AND FINANCIAL MARKETS',
        courseName: 'Economics 2121A/B - THE FIRM AND FINANCIAL MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219647f7e10015fd636d',
      },
      {
        _id: '60cc219647f7e10015fd636f',
        courseCode: 'ECONOMIC 2122',
        shortName: 'Economics 2122A/B - ECONOMETRICS I',
        courseName: 'Economics 2122A/B - ECONOMETRICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219647f7e10015fd636f',
      },
      {
        _id: '60cc219747f7e10015fd6371',
        courseCode: 'ECONOMIC 2123',
        shortName: 'Economics 2123A/B - ECONOMETRICS II',
        courseName: 'Economics 2123A/B - ECONOMETRICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219747f7e10015fd6371',
      },
      {
        _id: '60cc219747f7e10015fd6373',
        courseCode: 'ECONOMIC 2124',
        shortName: 'Economics 2124A/B - ECONOMIC DEVELOPMENT I',
        courseName: 'Economics 2124A/B - ECONOMIC DEVELOPMENT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219747f7e10015fd6373',
      },
      {
        _id: '60cc219847f7e10015fd6375',
        courseCode: 'ECONOMIC 2125',
        shortName: 'Economics 2125A/B - ECONOMIC DEVELOPMENT II',
        courseName: 'Economics 2125A/B - ECONOMIC DEVELOPMENT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219847f7e10015fd6375',
      },
      {
        _id: '60cc219847f7e10015fd6377',
        courseCode: 'ECONOMIC 2128',
        shortName: 'Economics 2128A/B - ECONOMICS OF CHINA',
        courseName: 'Economics 2128A/B - ECONOMICS OF CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219847f7e10015fd6377',
      },
      {
        _id: '60cc219947f7e10015fd6379',
        courseCode: 'ECONOMIC 2129',
        shortName: 'Economics 2129A/B - MANAGERIAL ECONOMICS',
        courseName: 'Economics 2129A/B - MANAGERIAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219947f7e10015fd6379',
      },
      {
        _id: '60cc219947f7e10015fd637b',
        courseCode: 'ECONOMIC 2138',
        shortName: 'Economics 2138F/G - MICROECONOMIC POLICY',
        courseName: 'Economics 2138F/G - MICROECONOMIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219947f7e10015fd637b',
      },
      {
        _id: '60cc219947f7e10015fd637d',
        courseCode: 'ECONOMIC 2139',
        shortName: 'Economics 2139F/G - MACROECONOMIC POLICY',
        courseName: 'Economics 2139F/G - MACROECONOMIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219947f7e10015fd637d',
      },
      {
        _id: '60cc219a47f7e10015fd637f',
        courseCode: 'ECONOMIC 2140',
        shortName: 'Economics 2140E - POLICY SEMINAR',
        courseName: 'Economics 2140E - POLICY SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219a47f7e10015fd637f',
      },
      {
        _id: '60cc219a47f7e10015fd6381',
        courseCode: 'ECONOMIC 2141',
        shortName: 'Economics 2141A/B - PRINCIPLES OF MATHEMATICAL ECONOMICS I',
        courseName:
          'Economics 2141A/B - PRINCIPLES OF MATHEMATICAL ECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219a47f7e10015fd6381',
      },
      {
        _id: '60cc219a47f7e10015fd6383',
        courseCode: 'ECONOMIC 2145',
        shortName: 'Economics 2145 - SPECIAL TOPICS',
        courseName: 'Economics 2145 - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219a47f7e10015fd6383',
      },
      {
        _id: '60cc219b47f7e10015fd6385',
        courseCode: 'ECONOMIC 2148',
        shortName: 'Economics 2148 - SPECIAL TOPICS',
        courseName: 'Economics 2148 - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219b47f7e10015fd6385',
      },
      {
        _id: '60cc219b47f7e10015fd6387',
        courseCode: 'ECONOMIC 2150',
        shortName: 'Economics 2150A/B - INTERMEDIATE MICROECONOMIC THEORY I',
        courseName: 'Economics 2150A/B - INTERMEDIATE MICROECONOMIC THEORY I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 43,
        id: '60cc219b47f7e10015fd6387',
      },
      {
        _id: '60cc219b47f7e10015fd6389',
        courseCode: 'ECONOMIC 2151',
        shortName: 'Economics 2151A/B - INTERMEDIATE MICROECONOMIC THEORY II',
        courseName: 'Economics 2151A/B - INTERMEDIATE MICROECONOMIC THEORY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219b47f7e10015fd6389',
      },
      {
        _id: '60cc219c47f7e10015fd638b',
        courseCode: 'ECONOMIC 2152',
        shortName:
          'Economics 2152A/B - INTERMEDIATE MACROECONOMIC THEORY AND POLICY I',
        courseName:
          'Economics 2152A/B - INTERMEDIATE MACROECONOMIC THEORY AND POLICY I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 10,
        id: '60cc219c47f7e10015fd638b',
      },
      {
        _id: '60cc219c47f7e10015fd638d',
        courseCode: 'ECONOMIC 2153',
        shortName:
          'Economics 2153A/B - INTERMEDIATE MACROECONOMIC THEORY AND POLICY II',
        courseName:
          'Economics 2153A/B - INTERMEDIATE MACROECONOMIC THEORY AND POLICY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219c47f7e10015fd638d',
      },
      {
        _id: '60cc219d47f7e10015fd638f',
        courseCode: 'ECONOMIC 2154',
        shortName: 'Economics 2154A/B - MONEY',
        courseName: 'Economics 2154A/B - MONEY',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 1,
        id: '60cc219d47f7e10015fd638f',
      },
      {
        _id: '60cc219d47f7e10015fd6391',
        courseCode: 'ECONOMIC 2155',
        shortName: 'Economics 2155A/B - LABOUR ECONOMICS',
        courseName: 'Economics 2155A/B - LABOUR ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219d47f7e10015fd6391',
      },
      {
        _id: '60cc219e47f7e10015fd6393',
        courseCode: 'ECONOMIC 2156',
        shortName: 'Economics 2156A/B - ECONOMICS OF TRADE UNIONS AND LABOUR',
        courseName: 'Economics 2156A/B - ECONOMICS OF TRADE UNIONS AND LABOUR',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 29,
        id: '60cc219e47f7e10015fd6393',
      },
      {
        _id: '60cc219e47f7e10015fd6395',
        courseCode: 'ECONOMIC 2157',
        shortName: 'Economics 2157F/G - URBAN ECONOMICS',
        courseName: 'Economics 2157F/G - URBAN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219e47f7e10015fd6395',
      },
      {
        _id: '60cc219e47f7e10015fd6397',
        courseCode: 'ECONOMIC 2159',
        shortName: 'Economics 2159A/B - PUBLIC FINANCE - EXPENDITURE',
        courseName: 'Economics 2159A/B - PUBLIC FINANCE - EXPENDITURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219e47f7e10015fd6397',
      },
      {
        _id: '60cc219f47f7e10015fd6399',
        courseCode: 'ECONOMIC 2160',
        shortName: 'Economics 2160A/B - PUBLIC FINANCE - REVENUE',
        courseName: 'Economics 2160A/B - PUBLIC FINANCE - REVENUE',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 2,
        id: '60cc219f47f7e10015fd6399',
      },
      {
        _id: '60cc219f47f7e10015fd639b',
        courseCode: 'ECONOMIC 2161',
        shortName: 'Economics 2161F/G - INCOME DISTRIBUTION AND POVERTY',
        courseName: 'Economics 2161F/G - INCOME DISTRIBUTION AND POVERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc219f47f7e10015fd639b',
      },
      {
        _id: '60cc21a047f7e10015fd639d',
        courseCode: 'ECONOMIC 2162',
        shortName: 'Economics 2162A/B - COMPARATIVE INTNERNATIONAL BUSINESS',
        courseName: 'Economics 2162A/B - COMPARATIVE INTNERNATIONAL BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a047f7e10015fd639d',
      },
      {
        _id: '60cc21a047f7e10015fd639f',
        courseCode: 'ECONOMIC 2163',
        shortName: 'Economics 2163A/B - INTERNATIONAL TRADE',
        courseName: 'Economics 2163A/B - INTERNATIONAL TRADE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a047f7e10015fd639f',
      },
      {
        _id: '60cc21a147f7e10015fd63a1',
        courseCode: 'ECONOMIC 2164',
        shortName: 'Economics 2164A/B - INTERNATIONAL FINANCE',
        courseName: 'Economics 2164A/B - INTERNATIONAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a147f7e10015fd63a1',
      },
      {
        _id: '60cc21a147f7e10015fd63a3',
        courseCode: 'ECONOMIC 2165',
        shortName: 'Economics 2165F/G - INDUSTRIAL ORGANIZATION',
        courseName: 'Economics 2165F/G - INDUSTRIAL ORGANIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a147f7e10015fd63a3',
      },
      {
        _id: '60cc21a247f7e10015fd63a5',
        courseCode: 'ECONOMIC 2166',
        shortName: 'Economics 2166F/G - ECONOMICS OF AIR TRANSPORTATION',
        courseName: 'Economics 2166F/G - ECONOMICS OF AIR TRANSPORTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a247f7e10015fd63a5',
      },
      {
        _id: '60cc21a247f7e10015fd63a7',
        courseCode: 'ECONOMIC 2167',
        shortName: 'Economics 2167A/B - INTERNATIONAL ECONOMICS',
        courseName: 'Economics 2167A/B - INTERNATIONAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a247f7e10015fd63a7',
      },
      {
        _id: '60cc21a247f7e10015fd63a9',
        courseCode: 'ECONOMIC 2169',
        shortName: 'Economics 2169F/G - ECONOMICS OF HEALTH CARE',
        courseName: 'Economics 2169F/G - ECONOMICS OF HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a247f7e10015fd63a9',
      },
      {
        _id: '60cc21a247f7e10015fd63ab',
        courseCode: 'ECONOMIC 2170',
        shortName: 'Economics 2170A/B - ENVIRONMENTAL ECONOMICS',
        courseName: 'Economics 2170A/B - ENVIRONMENTAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a247f7e10015fd63ab',
      },
      {
        _id: '60cc21a347f7e10015fd63ad',
        courseCode: 'ECONOMIC 2172',
        shortName:
          'Economics 2172A/B - NATURAL RESOURCE AND ENVIRONMENTAL ECONOMICS',
        courseName:
          'Economics 2172A/B - NATURAL RESOURCE AND ENVIRONMENTAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a347f7e10015fd63ad',
      },
      {
        _id: '60cc21a347f7e10015fd63af',
        courseCode: 'ECONOMIC 2173',
        shortName: 'Economics 2173F/G - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2173F/G - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a347f7e10015fd63af',
      },
      {
        _id: '60cc21a347f7e10015fd63b1',
        courseCode: 'ECONOMIC 2174',
        shortName: 'Economics 2174F/G - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2174F/G - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a347f7e10015fd63b1',
      },
      {
        _id: '60cc21a347f7e10015fd63b3',
        courseCode: 'ECONOMIC 2175',
        shortName: 'Economics 2175F/G - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2175F/G - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a347f7e10015fd63b3',
      },
      {
        _id: '60cc21a447f7e10015fd63b5',
        courseCode: 'ECONOMIC 2176',
        shortName:
          'Economics 2176A/B - ECONOMIC ANALYSIS OF LAW: PROPERTY RIGHTS AND CONTRACTS',
        courseName:
          'Economics 2176A/B - ECONOMIC ANALYSIS OF LAW: PROPERTY RIGHTS AND CONTRACTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a447f7e10015fd63b5',
      },
      {
        _id: '60cc21a447f7e10015fd63b7',
        courseCode: 'ECONOMIC 2178',
        shortName: 'Economics 2178A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2178A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a447f7e10015fd63b7',
      },
      {
        _id: '60cc21a447f7e10015fd63b9',
        courseCode: 'ECONOMIC 2179',
        shortName: 'Economics 2179A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2179A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a447f7e10015fd63b9',
      },
      {
        _id: '60cc21a447f7e10015fd63bb',
        courseCode: 'ECONOMIC 2181',
        shortName: 'Economics 2181A/B - FOUNDATIONS OF FINANCIAL ECONOMICS',
        courseName: 'Economics 2181A/B - FOUNDATIONS OF FINANCIAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a447f7e10015fd63bb',
      },
      {
        _id: '60cc21a447f7e10015fd63bd',
        courseCode: 'ECONOMIC 2182',
        shortName: 'Economics 2182A/B - ECONOMICS OF SPORTS',
        courseName: 'Economics 2182A/B - ECONOMICS OF SPORTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a447f7e10015fd63bd',
      },
      {
        _id: '60cc21a547f7e10015fd63bf',
        courseCode: 'ECONOMIC 2184',
        shortName: 'Economics 2184A/B - COST-BENEFIT ANALYSIS',
        courseName: 'Economics 2184A/B - COST-BENEFIT ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a547f7e10015fd63bf',
      },
      {
        _id: '60cc21a547f7e10015fd63c1',
        courseCode: 'ECONOMIC 2186',
        shortName: 'Economics 2186A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2186A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a547f7e10015fd63c1',
      },
      {
        _id: '60cc21a547f7e10015fd63c3',
        courseCode: 'ECONOMIC 2187',
        shortName: 'Economics 2187F/G - SPECIAL TOPICS IN ECONOMICS I',
        courseName: 'Economics 2187F/G - SPECIAL TOPICS IN ECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a547f7e10015fd63c3',
      },
      {
        _id: '60cc21a647f7e10015fd63c5',
        courseCode: 'ECONOMIC 2189',
        shortName:
          'Economics 2189A/B - SPECIAL TOPICS IN ECONOMICS AND ECONOMIC POLICY',
        courseName:
          'Economics 2189A/B - SPECIAL TOPICS IN ECONOMICS AND ECONOMIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a647f7e10015fd63c5',
      },
      {
        _id: '60cc21a647f7e10015fd63c7',
        courseCode: 'ECONOMIC 2191',
        shortName: 'Economics 2191A/B - RISK & FINANCIAL INSTITUTIONS',
        courseName: 'Economics 2191A/B - RISK & FINANCIAL INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a647f7e10015fd63c7',
      },
      {
        _id: '60cc21a647f7e10015fd63c9',
        courseCode: 'ECONOMIC 2192',
        shortName: 'Economics 2192A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2192A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a647f7e10015fd63c9',
      },
      {
        _id: '60cc21a647f7e10015fd63cb',
        courseCode: 'ECONOMIC 2193',
        shortName: 'Economics 2193A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2193A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a647f7e10015fd63cb',
      },
      {
        _id: '60cc21a747f7e10015fd63cd',
        courseCode: 'ECONOMIC 2194',
        shortName: 'Economics 2194A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2194A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a747f7e10015fd63cd',
      },
      {
        _id: '60cc21a747f7e10015fd63cf',
        courseCode: 'ECONOMIC 2195',
        shortName: 'Economics 2195A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 2195A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a747f7e10015fd63cf',
      },
      {
        _id: '60cc21a747f7e10015fd63d1',
        courseCode: 'ECONOMIC 2198',
        shortName: 'Economics 2198F/G - SPECIAL TOPICS',
        courseName: 'Economics 2198F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a747f7e10015fd63d1',
      },
      {
        _id: '60cc21a747f7e10015fd63d3',
        courseCode: 'ECONOMIC 2199',
        shortName: 'Economics 2199F/G - SPECIAL TOPICS',
        courseName: 'Economics 2199F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a747f7e10015fd63d3',
      },
      {
        _id: '60cc21a847f7e10015fd63d5',
        courseCode: 'ECONOMIC 2210',
        shortName: 'Economics 2210A/B - PRINCIPLES OF MATHEMATICAL ECONOMICS I',
        courseName:
          'Economics 2210A/B - PRINCIPLES OF MATHEMATICAL ECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a847f7e10015fd63d5',
      },
      {
        _id: '60cc21a847f7e10015fd63d7',
        courseCode: 'ECONOMIC 2220',
        shortName: 'Economics 2220A/B - INTERMEDIATE MACROECONOMICS I',
        courseName: 'Economics 2220A/B - INTERMEDIATE MACROECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a847f7e10015fd63d7',
      },
      {
        _id: '60cc21a947f7e10015fd63d9',
        courseCode: 'ECONOMIC 2221',
        shortName: 'Economics 2221A/B - INTERMEDIATE MACROECONOMICS II',
        courseName: 'Economics 2221A/B - INTERMEDIATE MACROECONOMICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21a947f7e10015fd63d9',
      },
      {
        _id: '60cc21a947f7e10015fd63db',
        courseCode: 'ECONOMIC 2222',
        shortName: 'Economics 2222A/B - ECONOMETRICS I',
        courseName: 'Economics 2222A/B - ECONOMETRICS I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 20,
        id: '60cc21a947f7e10015fd63db',
      },
      {
        _id: '60cc21aa47f7e10015fd63dd',
        courseCode: 'ECONOMIC 2223',
        shortName: 'Economics 2223A/B - ECONOMETRICS II',
        courseName: 'Economics 2223A/B - ECONOMETRICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21aa47f7e10015fd63dd',
      },
      {
        _id: '60cc21aa47f7e10015fd63df',
        courseCode: 'ECONOMIC 2260',
        shortName: 'Economics 2260A/B - INTERMEDIATE MICROECONOMICS I',
        courseName: 'Economics 2260A/B - INTERMEDIATE MICROECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21aa47f7e10015fd63df',
      },
      {
        _id: '60cc21ab47f7e10015fd63e1',
        courseCode: 'ECONOMIC 2261',
        shortName: 'Economics 2261A/B - INTERMEDIATE MICROECONOMICS II',
        courseName: 'Economics 2261A/B - INTERMEDIATE MICROECONOMICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ab47f7e10015fd63e1',
      },
      {
        _id: '60cc21ab47f7e10015fd63e3',
        courseCode: 'ECONOMIC 2288',
        shortName: 'Economics 2288F/G - ECONOMIC POLICY I',
        courseName: 'Economics 2288F/G - ECONOMIC POLICY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ab47f7e10015fd63e3',
      },
      {
        _id: '60cc21ab47f7e10015fd63e5',
        courseCode: 'ECONOMIC 2289',
        shortName: 'Economics 2289F/G - ECONOMIC POLICY II',
        courseName: 'Economics 2289F/G - ECONOMIC POLICY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ab47f7e10015fd63e5',
      },
      {
        _id: '60cc21ab47f7e10015fd63e7',
        courseCode: 'ECONOMIC 2300',
        shortName: 'Economics 2300A/B - INVESTMENT AND FINANCIAL MARKETS',
        courseName: 'Economics 2300A/B - INVESTMENT AND FINANCIAL MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ab47f7e10015fd63e7',
      },
      {
        _id: '60cc21ac47f7e10015fd63e9',
        courseCode: 'ECONOMIC 3309',
        shortName: 'Economics 3309F/G - ECONOMIC THOUGHT',
        courseName: 'Economics 3309F/G - ECONOMIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ac47f7e10015fd63e9',
      },
      {
        _id: '60cc21ac47f7e10015fd63eb',
        courseCode: 'ECONOMIC 3310',
        shortName: 'Economics 3310A/B - ADVANCED MATHEMATICAL ECONOMICS',
        courseName: 'Economics 3310A/B - ADVANCED MATHEMATICAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ac47f7e10015fd63eb',
      },
      {
        _id: '60cc21ac47f7e10015fd63ed',
        courseCode: 'ECONOMIC 3311',
        shortName: 'Economics 3311F/G - ECONOMICS OF CHINA',
        courseName: 'Economics 3311F/G - ECONOMICS OF CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ac47f7e10015fd63ed',
      },
      {
        _id: '60cc21ad47f7e10015fd63ef',
        courseCode: 'ECONOMIC 3313',
        shortName: 'Economics 3313E - RESEARCH METHODS',
        courseName: 'Economics 3313E - RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ad47f7e10015fd63ef',
      },
      {
        _id: '60cc21ad47f7e10015fd63f1',
        courseCode: 'ECONOMIC 3314',
        shortName: 'Economics 3314A/B - ECONOMICS OF THE PACIFIC RIM',
        courseName: 'Economics 3314A/B - ECONOMICS OF THE PACIFIC RIM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ad47f7e10015fd63f1',
      },
      {
        _id: '60cc21ad47f7e10015fd63f3',
        courseCode: 'ECONOMIC 3317',
        shortName: 'Economics 3317A/B - MODERN ASIAN ECONOMIES',
        courseName: 'Economics 3317A/B - MODERN ASIAN ECONOMIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ad47f7e10015fd63f3',
      },
      {
        _id: '60cc21ad47f7e10015fd63f5',
        courseCode: 'ECONOMIC 3318',
        shortName:
          'Economics 3318F/G - MAKING A LIVING IN THE MIDDLE AGES: FARMERS, GUILD ARTISANS AND MERCHANTS',
        courseName:
          'Economics 3318F/G - MAKING A LIVING IN THE MIDDLE AGES: FARMERS, GUILD ARTISANS AND MERCHANTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ad47f7e10015fd63f5',
      },
      {
        _id: '60cc21ae47f7e10015fd63f7',
        courseCode: 'ECONOMIC 3319',
        shortName: 'Economics 3319F/G - CANADIAN ECONOMIC POLICY',
        courseName: 'Economics 3319F/G - CANADIAN ECONOMIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ae47f7e10015fd63f7',
      },
      {
        _id: '60cc21ae47f7e10015fd63f9',
        courseCode: 'ECONOMIC 3320',
        shortName: 'Economics 3320A/B - ADVANCED MACROECONOMICS I',
        courseName: 'Economics 3320A/B - ADVANCED MACROECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ae47f7e10015fd63f9',
      },
      {
        _id: '60cc21af47f7e10015fd63fb',
        courseCode: 'ECONOMIC 3321',
        shortName: 'Economics 3321A/B - ADVANCED MACROECONOMICS II',
        courseName: 'Economics 3321A/B - ADVANCED MACROECONOMICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21af47f7e10015fd63fb',
      },
      {
        _id: '60cc21af47f7e10015fd63fd',
        courseCode: 'ECONOMIC 3323',
        shortName: 'Economics 3323F/G - QUANTITATIVE ECONOMIC MODELING',
        courseName: 'Economics 3323F/G - QUANTITATIVE ECONOMIC MODELING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21af47f7e10015fd63fd',
      },
      {
        _id: '60cc21af47f7e10015fd63ff',
        courseCode: 'ECONOMIC 3324',
        shortName: 'Economics 3324F/G - ECONOMIC DEVELOPMENT I',
        courseName: 'Economics 3324F/G - ECONOMIC DEVELOPMENT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21af47f7e10015fd63ff',
      },
      {
        _id: '60cc21af47f7e10015fd6401',
        courseCode: 'ECONOMIC 3325',
        shortName: 'Economics 3325A/B - ECONOMIC DEVELOPMENT II',
        courseName: 'Economics 3325A/B - ECONOMIC DEVELOPMENT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21af47f7e10015fd6401',
      },
      {
        _id: '60cc21af47f7e10015fd6403',
        courseCode: 'ECONOMIC 3328',
        shortName: 'Economics 3328A/B - PUBLIC FINANCE: EXPENDITURE',
        courseName: 'Economics 3328A/B - PUBLIC FINANCE: EXPENDITURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21af47f7e10015fd6403',
      },
      {
        _id: '60cc21b047f7e10015fd6405',
        courseCode: 'ECONOMIC 3329',
        shortName: 'Economics 3329A/B - PUBLIC FINANCE: TAXATION',
        courseName: 'Economics 3329A/B - PUBLIC FINANCE: TAXATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b047f7e10015fd6405',
      },
      {
        _id: '60cc21b147f7e10015fd6407',
        courseCode: 'ECONOMIC 3330',
        shortName: 'Economics 3330A/B - ADVANCED MONETARY THEORY',
        courseName: 'Economics 3330A/B - ADVANCED MONETARY THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b147f7e10015fd6407',
      },
      {
        _id: '60cc21b147f7e10015fd6409',
        courseCode: 'ECONOMIC 3332',
        shortName: 'Economics 3332A/B - FINANCIAL ECONOMICS',
        courseName: 'Economics 3332A/B - FINANCIAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b147f7e10015fd6409',
      },
      {
        _id: '60cc21b147f7e10015fd640b',
        courseCode: 'ECONOMIC 3340',
        shortName: 'Economics 3340F/G - TRADE POLICY ISSUES',
        courseName: 'Economics 3340F/G - TRADE POLICY ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b147f7e10015fd640b',
      },
      {
        _id: '60cc21b147f7e10015fd640d',
        courseCode: 'ECONOMIC 3342',
        shortName: 'Economics 3342F/G - CANADIAN ECONOMIC HISTORY',
        courseName: 'Economics 3342F/G - CANADIAN ECONOMIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b147f7e10015fd640d',
      },
      {
        _id: '60cc21b147f7e10015fd640f',
        courseCode: 'ECONOMIC 3343',
        shortName: 'Economics 3343F/G - ECONOMIC DEVELOPMENT STUDIES',
        courseName: 'Economics 3343F/G - ECONOMIC DEVELOPMENT STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b147f7e10015fd640f',
      },
      {
        _id: '60cc21b247f7e10015fd6411',
        courseCode: 'ECONOMIC 3344',
        shortName: 'Economics 3344A/B - LABOUR ECONOMICS I',
        courseName: 'Economics 3344A/B - LABOUR ECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b247f7e10015fd6411',
      },
      {
        _id: '60cc21b247f7e10015fd6413',
        courseCode: 'ECONOMIC 3345',
        shortName: 'Economics 3345A/B - LABOR ECONOMICS II',
        courseName: 'Economics 3345A/B - LABOR ECONOMICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b247f7e10015fd6413',
      },
      {
        _id: '60cc21b347f7e10015fd6415',
        courseCode: 'ECONOMIC 3346',
        shortName: 'Economics 3346A/B - FINANCIAL MARKETS',
        courseName: 'Economics 3346A/B - FINANCIAL MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b347f7e10015fd6415',
      },
      {
        _id: '60cc21b347f7e10015fd6417',
        courseCode: 'ECONOMIC 3350',
        shortName: 'Economics 3350F/G - ECONOMICS OF HUMAN BEHAVIOUR',
        courseName: 'Economics 3350F/G - ECONOMICS OF HUMAN BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b347f7e10015fd6417',
      },
      {
        _id: '60cc21b347f7e10015fd6419',
        courseCode: 'ECONOMIC 3352',
        shortName: 'Economics 3352A/B - INTERNATIONAL TRADE',
        courseName: 'Economics 3352A/B - INTERNATIONAL TRADE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b347f7e10015fd6419',
      },
      {
        _id: '60cc21b447f7e10015fd641b',
        courseCode: 'ECONOMIC 3353',
        shortName: 'Economics 3353A/B - INTERNATIONAL FINANCE',
        courseName: 'Economics 3353A/B - INTERNATIONAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b447f7e10015fd641b',
      },
      {
        _id: '60cc21b447f7e10015fd641d',
        courseCode: 'ECONOMIC 3356',
        shortName: 'Economics 3356A/B - INTERNATIONAL FINANCIAL MARKETS',
        courseName: 'Economics 3356A/B - INTERNATIONAL FINANCIAL MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b447f7e10015fd641d',
      },
      {
        _id: '60cc21b447f7e10015fd641f',
        courseCode: 'ECONOMIC 3357',
        shortName: 'Economics 3357A/B - INDUSTRIAL ECONOMICS',
        courseName: 'Economics 3357A/B - INDUSTRIAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b447f7e10015fd641f',
      },
      {
        _id: '60cc21b447f7e10015fd6421',
        courseCode: 'ECONOMIC 3362',
        shortName: 'Economics 3362A/B - EMPIRICAL INDUSTRIAL ORGANIZATION',
        courseName: 'Economics 3362A/B - EMPIRICAL INDUSTRIAL ORGANIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b447f7e10015fd6421',
      },
      {
        _id: '60cc21b547f7e10015fd6423',
        courseCode: 'ECONOMIC 3363',
        shortName: 'Economics 3363F/G - POLITICAL ECONOMY I',
        courseName: 'Economics 3363F/G - POLITICAL ECONOMY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b547f7e10015fd6423',
      },
      {
        _id: '60cc21b547f7e10015fd6425',
        courseCode: 'ECONOMIC 3364',
        shortName: 'Economics 3364F/G - INDUSTRIAL ORGANIZATION I',
        courseName: 'Economics 3364F/G - INDUSTRIAL ORGANIZATION I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b547f7e10015fd6425',
      },
      {
        _id: '60cc21b547f7e10015fd6427',
        courseCode: 'ECONOMIC 3366',
        shortName: 'Economics 3366A/B - ECONOMICS AND LAW I',
        courseName: 'Economics 3366A/B - ECONOMICS AND LAW I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b547f7e10015fd6427',
      },
      {
        _id: '60cc21b547f7e10015fd6429',
        courseCode: 'ECONOMIC 3370',
        shortName: 'Economics 3370A/B - INTERNATIONAL FINANCE',
        courseName: 'Economics 3370A/B - INTERNATIONAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b547f7e10015fd6429',
      },
      {
        _id: '60cc21b647f7e10015fd642b',
        courseCode: 'ECONOMIC 3371',
        shortName: 'Economics 3371A/B - MONETARY ECONOMICS',
        courseName: 'Economics 3371A/B - MONETARY ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b647f7e10015fd642b',
      },
      {
        _id: '60cc21b647f7e10015fd642d',
        courseCode: 'ECONOMIC 3374',
        shortName:
          'Economics 3374A/B - NATURAL RESOURCE AND ENVIRONMENTAL ECONOMICS',
        courseName:
          'Economics 3374A/B - NATURAL RESOURCE AND ENVIRONMENTAL ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b647f7e10015fd642d',
      },
      {
        _id: '60cc21b647f7e10015fd642f',
        courseCode: 'ECONOMIC 3381',
        shortName: 'Economics 3381A/B - ADVANCED MICROECONOMICS 1',
        courseName: 'Economics 3381A/B - ADVANCED MICROECONOMICS 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b647f7e10015fd642f',
      },
      {
        _id: '60cc21b747f7e10015fd6431',
        courseCode: 'ECONOMIC 3382',
        shortName: 'Economics 3382A/B - ADVANCED MICROECONOMICS I',
        courseName: 'Economics 3382A/B - ADVANCED MICROECONOMICS I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 225,
        id: '60cc21b747f7e10015fd6431',
      },
      {
        _id: '60cc21b747f7e10015fd6433',
        courseCode: 'ECONOMIC 3386',
        shortName: 'Economics 3386A/B - TIME SERIES ANALYSIS AND FORECASTING',
        courseName: 'Economics 3386A/B - TIME SERIES ANALYSIS AND FORECASTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b747f7e10015fd6433',
      },
      {
        _id: '60cc21b747f7e10015fd6435',
        courseCode: 'ECONOMIC 3388',
        shortName: 'Economics 3388A/B - APPLIED ECONOMETRICS I',
        courseName: 'Economics 3388A/B - APPLIED ECONOMETRICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b747f7e10015fd6435',
      },
      {
        _id: '60cc21b747f7e10015fd6437',
        courseCode: 'ECONOMIC 3389',
        shortName: 'Economics 3389A/B - APPLIED ECONOMETRICS II',
        courseName: 'Economics 3389A/B - APPLIED ECONOMETRICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b747f7e10015fd6437',
      },
      {
        _id: '60cc21b847f7e10015fd6439',
        courseCode: 'ECONOMIC 3390',
        shortName:
          'Economics 3390F/G - SPECIAL TOPICS IN ECONOMICS AND ECONOMIC POLICY',
        courseName:
          'Economics 3390F/G - SPECIAL TOPICS IN ECONOMICS AND ECONOMIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b847f7e10015fd6439',
      },
      {
        _id: '60cc21b847f7e10015fd643b',
        courseCode: 'ECONOMIC 3391',
        shortName:
          'Economics 3391A/B - SPECIAL TOPICS IN ECONOMICS: MICROECONOMICS',
        courseName:
          'Economics 3391A/B - SPECIAL TOPICS IN ECONOMICS: MICROECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b847f7e10015fd643b',
      },
      {
        _id: '60cc21b847f7e10015fd643d',
        courseCode: 'ECONOMIC 3392',
        shortName: 'Economics 3392A/B - SPECIAL TOPICS-MACROECONOMICS',
        courseName: 'Economics 3392A/B - SPECIAL TOPICS-MACROECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b847f7e10015fd643d',
      },
      {
        _id: '60cc21b847f7e10015fd643f',
        courseCode: 'ECONOMIC 3393',
        shortName: 'Economics 3393A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 3393A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b847f7e10015fd643f',
      },
      {
        _id: '60cc21b847f7e10015fd6441',
        courseCode: 'ECONOMIC 3394',
        shortName: 'Economics 3394A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 3394A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b847f7e10015fd6441',
      },
      {
        _id: '60cc21b947f7e10015fd6443',
        courseCode: 'ECONOMIC 3395',
        shortName: 'Economics 3395A/B - SPECIAL TOPICS IN ECONOMICS III',
        courseName: 'Economics 3395A/B - SPECIAL TOPICS IN ECONOMICS III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b947f7e10015fd6443',
      },
      {
        _id: '60cc21b947f7e10015fd6445',
        courseCode: 'ECONOMIC 3396',
        shortName: 'Economics 3396A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 3396A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b947f7e10015fd6445',
      },
      {
        _id: '60cc21b947f7e10015fd6447',
        courseCode: 'ECONOMIC 3397',
        shortName: 'Economics 3397A/B - SPECIAL TOPICS IN ECONOMICS',
        courseName: 'Economics 3397A/B - SPECIAL TOPICS IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b947f7e10015fd6447',
      },
      {
        _id: '60cc21b947f7e10015fd6449',
        courseCode: 'ECONOMIC 3399',
        shortName:
          'Economics 3399F/G - SPECIAL TOPICS IN ECONOMICS AND ECONOMIC POLICY',
        courseName:
          'Economics 3399F/G - SPECIAL TOPICS IN ECONOMICS AND ECONOMIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21b947f7e10015fd6449',
      },
      {
        _id: '60cc21ba47f7e10015fd644b',
        courseCode: 'ECONOMIC 4400',
        shortName: 'Economics 4400E - SENIOR RESEARCH SEMINAR IN ECONOMICS',
        courseName: 'Economics 4400E - SENIOR RESEARCH SEMINAR IN ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ba47f7e10015fd644b',
      },
      {
        _id: '60cc21ba47f7e10015fd644d',
        courseCode: 'ECONOMIC 4405',
        shortName: 'Economics 4405E - SENIOR THESIS',
        courseName: 'Economics 4405E - SENIOR THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ba47f7e10015fd644d',
      },
      {
        _id: '60cc21ba47f7e10015fd644f',
        courseCode: 'ECONOMIC 4490',
        shortName: 'Economics 4490F/G - SPECIAL TOPICS',
        courseName: 'Economics 4490F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ba47f7e10015fd644f',
      },
      {
        _id: '60cc21bb47f7e10015fd6451',
        courseCode: 'ECONOMIC 4491',
        shortName: 'Economics 4491F/G - SPECIAL TOPICS',
        courseName: 'Economics 4491F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bb47f7e10015fd6451',
      },
      {
        _id: '60cc21bb47f7e10015fd6453',
        courseCode: 'ECONOMIC 4492',
        shortName: 'Economics 4492F/G - SPECIAL TOPICS',
        courseName: 'Economics 4492F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bb47f7e10015fd6453',
      },
      {
        _id: '60cc21bb47f7e10015fd6455',
        courseCode: 'ECONOMIC 4493',
        shortName: 'Economics 4493F/G - SPECIAL TOPICS',
        courseName: 'Economics 4493F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bb47f7e10015fd6455',
      },
      {
        _id: '60cc21bb47f7e10015fd6457',
        courseCode: 'ECONOMIC 4494',
        shortName: 'Economics 4494F/G - SPECIAL TOPICS',
        courseName: 'Economics 4494F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bb47f7e10015fd6457',
      },
      {
        _id: '60cc21bb47f7e10015fd6459',
        courseCode: 'ECONOMIC 4495',
        shortName: 'Economics 4495A/B - SPECIAL TOPICS IN ECONOMICS IV',
        courseName: 'Economics 4495A/B - SPECIAL TOPICS IN ECONOMICS IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bb47f7e10015fd6459',
      },
      {
        _id: '60cc21bc47f7e10015fd645b',
        courseCode: 'ECONOMIC 4496',
        shortName: 'Economics 4496F/G - SPECIAL TOPICS IN ECONOMICS V',
        courseName: 'Economics 4496F/G - SPECIAL TOPICS IN ECONOMICS V',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bc47f7e10015fd645b',
      },
      {
        _id: '60cc21bc47f7e10015fd645d',
        courseCode: 'EDUC 2200',
        shortName: 'Education 2200E - PERSPECTIVES ON SCHOOLING',
        courseName: 'Education 2200E - PERSPECTIVES ON SCHOOLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bc47f7e10015fd645d',
      },
      {
        _id: '60cc21bc47f7e10015fd645f',
        courseCode: 'EDUC 2222',
        shortName: 'Education 2222A/B - EDUCATION AND THE SCIENCES',
        courseName: 'Education 2222A/B - EDUCATION AND THE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bc47f7e10015fd645f',
      },
      {
        _id: '60cc21bd47f7e10015fd6461',
        courseCode: 'EDUC 2225',
        shortName:
          'Education 2225 - SUPPORTING YOUNG CHILDREN TO PLAY MATHEMATICS AND LITERACY',
        courseName:
          'Education 2225 - SUPPORTING YOUNG CHILDREN TO PLAY MATHEMATICS AND LITERACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bd47f7e10015fd6461',
      },
      {
        _id: '60cc21bd47f7e10015fd6463',
        courseCode: 'EDUC 4403',
        shortName: 'Education 4403A/B - CLASSROOM LEARNING THEORY AND PRACTICE',
        courseName:
          'Education 4403A/B - CLASSROOM LEARNING THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bd47f7e10015fd6463',
      },
      {
        _id: '60cc21bd47f7e10015fd6465',
        courseCode: 'EDUC 4405',
        shortName: 'Education 4405B - TEACHING & LEARNING IN CONTEXT',
        courseName: 'Education 4405B - TEACHING & LEARNING IN CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bd47f7e10015fd6465',
      },
      {
        _id: '60cc21bd47f7e10015fd6467',
        courseCode: 'ECE 2205',
        shortName:
          'Electrical and Computer Engineering 2205A/B - ELECTRIC CIRCUITS',
        courseName:
          'Electrical and Computer Engineering 2205A/B - ELECTRIC CIRCUITS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bd47f7e10015fd6467',
      },
      {
        _id: '60cc21be47f7e10015fd6469',
        courseCode: 'ECE 2208',
        shortName:
          'Electrical and Computer Engineering 2208A/B - ELECTRICAL MEASUREMENT & INSTRUMENTATION',
        courseName:
          'Electrical and Computer Engineering 2208A/B - ELECTRICAL MEASUREMENT & INSTRUMENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21be47f7e10015fd6469',
      },
      {
        _id: '60cc21be47f7e10015fd646b',
        courseCode: 'ECE 2231',
        shortName:
          'Electrical and Computer Engineering 2231A/B - INTRODUCTION TO ELECTRONICS',
        courseName:
          'Electrical and Computer Engineering 2231A/B - INTRODUCTION TO ELECTRONICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21be47f7e10015fd646b',
      },
      {
        _id: '60cc21be47f7e10015fd646d',
        courseCode: 'ECE 2233',
        shortName:
          'Electrical and Computer Engineering 2233A/B - CIRCUITS AND SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 2233A/B - CIRCUITS AND SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21be47f7e10015fd646d',
      },
      {
        _id: '60cc21be47f7e10015fd646f',
        courseCode: 'ECE 2236',
        shortName:
          'Electrical and Computer Engineering 2236A/B - MAGNETIC CIRCUITS & TRANSMISSION LINES',
        courseName:
          'Electrical and Computer Engineering 2236A/B - MAGNETIC CIRCUITS & TRANSMISSION LINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21be47f7e10015fd646f',
      },
      {
        _id: '60cc21bf47f7e10015fd6471',
        courseCode: 'ECE 2238',
        shortName:
          'Electrical and Computer Engineering 2238A/B - INTRODUCTION TO ELECTRICAL ENGINEERING',
        courseName:
          'Electrical and Computer Engineering 2238A/B - INTRODUCTION TO ELECTRICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bf47f7e10015fd6471',
      },
      {
        _id: '60cc21bf47f7e10015fd6473',
        courseCode: 'ECE 2240',
        shortName:
          'Electrical and Computer Engineering 2240A/B - ELECTRICAL LABORATORY I',
        courseName:
          'Electrical and Computer Engineering 2240A/B - ELECTRICAL LABORATORY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bf47f7e10015fd6473',
      },
      {
        _id: '60cc21bf47f7e10015fd6475',
        courseCode: 'ECE 2241',
        shortName:
          'Electrical and Computer Engineering 2241A/B - ELECTRICAL LABORATORY II',
        courseName:
          'Electrical and Computer Engineering 2241A/B - ELECTRICAL LABORATORY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21bf47f7e10015fd6475',
      },
      {
        _id: '60cc21c047f7e10015fd6477',
        courseCode: 'ECE 2242',
        shortName:
          'Electrical and Computer Engineering 2242A/B - PRINCIPLES OF DESIGN IN ELECTRICAL ENGINEERING',
        courseName:
          'Electrical and Computer Engineering 2242A/B - PRINCIPLES OF DESIGN IN ELECTRICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c047f7e10015fd6477',
      },
      {
        _id: '60cc21c047f7e10015fd6479',
        courseCode: 'ECE 2274',
        shortName:
          'Electrical and Computer Engineering 2274A/B - ELECTRIC CIRCUITS AND ELECTROMECHANICS',
        courseName:
          'Electrical and Computer Engineering 2274A/B - ELECTRIC CIRCUITS AND ELECTROMECHANICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c047f7e10015fd6479',
      },
      {
        _id: '60cc21c047f7e10015fd647b',
        courseCode: 'ECE 2277',
        shortName:
          'Electrical and Computer Engineering 2277A/B - DIGITAL LOGIC SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 2277A/B - DIGITAL LOGIC SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c047f7e10015fd647b',
      },
      {
        _id: '60cc21c047f7e10015fd647d',
        courseCode: 'ECE 3330',
        shortName:
          'Electrical and Computer Engineering 3330A/B - CONTROL SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 3330A/B - CONTROL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c047f7e10015fd647d',
      },
      {
        _id: '60cc21c147f7e10015fd647f',
        courseCode: 'ECE 3331',
        shortName:
          'Electrical and Computer Engineering 3331A/B - INTRODUCATION TO SIGNAL PROCESSING',
        courseName:
          'Electrical and Computer Engineering 3331A/B - INTRODUCATION TO SIGNAL PROCESSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c147f7e10015fd647f',
      },
      {
        _id: '60cc21c147f7e10015fd6481',
        courseCode: 'ECE 3332',
        shortName:
          'Electrical and Computer Engineering 3332A/B - ELECTRIC MACHINES',
        courseName:
          'Electrical and Computer Engineering 3332A/B - ELECTRIC MACHINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c147f7e10015fd6481',
      },
      {
        _id: '60cc21c147f7e10015fd6483',
        courseCode: 'ECE 3333',
        shortName:
          'Electrical and Computer Engineering 3333A/B - ELECTRIC POWER SYSTEMS I',
        courseName:
          'Electrical and Computer Engineering 3333A/B - ELECTRIC POWER SYSTEMS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c147f7e10015fd6483',
      },
      {
        _id: '60cc21c147f7e10015fd6485',
        courseCode: 'ECE 3336',
        shortName:
          'Electrical and Computer Engineering 3336A/B - ELECTROMAGNETIC THEORY',
        courseName:
          'Electrical and Computer Engineering 3336A/B - ELECTROMAGNETIC THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c147f7e10015fd6485',
      },
      {
        _id: '60cc21c247f7e10015fd6487',
        courseCode: 'ECE 3337',
        shortName:
          'Electrical and Computer Engineering 3337A/B - ELECTRONIC CIRCUITS',
        courseName:
          'Electrical and Computer Engineering 3337A/B - ELECTRONIC CIRCUITS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c247f7e10015fd6487',
      },
      {
        _id: '60cc21c247f7e10015fd6489',
        courseCode: 'ECE 3349',
        shortName:
          'Electrical and Computer Engineering 3349A/B - INTRODUCTION OF VLSI',
        courseName:
          'Electrical and Computer Engineering 3349A/B - INTRODUCTION OF VLSI',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c247f7e10015fd6489',
      },
      {
        _id: '60cc21c247f7e10015fd648b',
        courseCode: 'ECE 3370',
        shortName:
          'Electrical and Computer Engineering 3370A/B - ANALOG COMMUNICATIONS THEORY',
        courseName:
          'Electrical and Computer Engineering 3370A/B - ANALOG COMMUNICATIONS THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c247f7e10015fd648b',
      },
      {
        _id: '60cc21c247f7e10015fd648d',
        courseCode: 'ECE 3374',
        shortName:
          'Electrical and Computer Engineering 3374A/B - ELECTRONICS FOR MECHANICAL ENGINEERING',
        courseName:
          'Electrical and Computer Engineering 3374A/B - ELECTRONICS FOR MECHANICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c247f7e10015fd648d',
      },
      {
        _id: '60cc21c347f7e10015fd648f',
        courseCode: 'ECE 3375',
        shortName:
          'Electrical and Computer Engineering 3375A/B - MICROPROCESSORS & MICROCOMPUTERS',
        courseName:
          'Electrical and Computer Engineering 3375A/B - MICROPROCESSORS & MICROCOMPUTERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c347f7e10015fd648f',
      },
      {
        _id: '60cc21c347f7e10015fd6491',
        courseCode: 'ECE 3380',
        shortName:
          'Electrical and Computer Engineering 3380A/B - ADVANCED DIGITAL SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 3380A/B - ADVANCED DIGITAL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c347f7e10015fd6491',
      },
      {
        _id: '60cc21c347f7e10015fd6493',
        courseCode: 'ECE 3389',
        shortName:
          'Electrical and Computer Engineering 3389A/B - COMPUTER SYSTEM DESIGN',
        courseName:
          'Electrical and Computer Engineering 3389A/B - COMPUTER SYSTEM DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c347f7e10015fd6493',
      },
      {
        _id: '60cc21c347f7e10015fd6495',
        courseCode: 'ECE 3390',
        shortName:
          'Electrical and Computer Engineering 3390A/B - HARDWARE/SOFTWARE CO-DESIGN',
        courseName:
          'Electrical and Computer Engineering 3390A/B - HARDWARE/SOFTWARE CO-DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c347f7e10015fd6495',
      },
      {
        _id: '60cc21c447f7e10015fd6498',
        courseCode: 'ECE 4415',
        shortName:
          'Electrical and Computer Engineering 4415 - COMPUTER ENGINEERING DESIGN PROJECT',
        courseName:
          'Electrical and Computer Engineering 4415 - COMPUTER ENGINEERING DESIGN PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c447f7e10015fd6498',
      },
      {
        _id: '60cc21c447f7e10015fd649a',
        courseCode: 'ECE 4416',
        shortName:
          'Electrical and Computer Engineering 4416 - ELECTRICAL/COMPUTER DESIGN PROJECT',
        courseName:
          'Electrical and Computer Engineering 4416 - ELECTRICAL/COMPUTER DESIGN PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c447f7e10015fd649a',
      },
      {
        _id: '60cc21c447f7e10015fd649c',
        courseCode: 'ECE 4429',
        shortName:
          'Electrical and Computer Engineering 4429A/B - ADVANCED DIGITAL SIGNAL PROCESSING',
        courseName:
          'Electrical and Computer Engineering 4429A/B - ADVANCED DIGITAL SIGNAL PROCESSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c447f7e10015fd649c',
      },
      {
        _id: '60cc21c447f7e10015fd649e',
        courseCode: 'ECE 4430',
        shortName:
          'Electrical and Computer Engineering 4430A/B - SELECTED TOPICS IN ELECTRICAL ENGINEERING I',
        courseName:
          'Electrical and Computer Engineering 4430A/B - SELECTED TOPICS IN ELECTRICAL ENGINEERING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c447f7e10015fd649e',
      },
      {
        _id: '60cc21c547f7e10015fd64a0',
        courseCode: 'ECE 4431',
        shortName:
          'Electrical and Computer Engineering 4431A/B - SELECTED TOPICS IN ELECTRICAL ENGINEERING II',
        courseName:
          'Electrical and Computer Engineering 4431A/B - SELECTED TOPICS IN ELECTRICAL ENGINEERING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c547f7e10015fd64a0',
      },
      {
        _id: '60cc21c547f7e10015fd64a2',
        courseCode: 'ECE 4432',
        shortName:
          'Electrical and Computer Engineering 4432A/B - RADIATION AND PROPAGATION',
        courseName:
          'Electrical and Computer Engineering 4432A/B - RADIATION AND PROPAGATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c547f7e10015fd64a2',
      },
      {
        _id: '60cc21c547f7e10015fd64a4',
        courseCode: 'ECE 4433',
        shortName:
          'Electrical and Computer Engineering 4433A/B - DIGITAL COMMUNICATIONS SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 4433A/B - DIGITAL COMMUNICATIONS SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c547f7e10015fd64a4',
      },
      {
        _id: '60cc21c547f7e10015fd64a6',
        courseCode: 'ECE 4436',
        shortName:
          'Electrical and Computer Engineering 4436A/B - NETWORKING: PRINCIPLES, PROTOCOLS AND ARCHITECTURE',
        courseName:
          'Electrical and Computer Engineering 4436A/B - NETWORKING: PRINCIPLES, PROTOCOLS AND ARCHITECTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c547f7e10015fd64a6',
      },
      {
        _id: '60cc21c647f7e10015fd64a8',
        courseCode: 'ECE 4437',
        shortName:
          'Electrical and Computer Engineering 4437A/B - COMMUNICATIONS THEORY',
        courseName:
          'Electrical and Computer Engineering 4437A/B - COMMUNICATIONS THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c647f7e10015fd64a8',
      },
      {
        _id: '60cc21c647f7e10015fd64aa',
        courseCode: 'ECE 4438',
        shortName:
          'Electrical and Computer Engineering 4438A/B - ADVANCED IMAGE PROCESSING AND ANALYSIS',
        courseName:
          'Electrical and Computer Engineering 4438A/B - ADVANCED IMAGE PROCESSING AND ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c647f7e10015fd64aa',
      },
      {
        _id: '60cc21c647f7e10015fd64ac',
        courseCode: 'ECE 4439',
        shortName:
          'Electrical and Computer Engineering 4439A/B - CONVENTIONAL, RENEWABLE AND NUCLEAR ENERGY',
        courseName:
          'Electrical and Computer Engineering 4439A/B - CONVENTIONAL, RENEWABLE AND NUCLEAR ENERGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c647f7e10015fd64ac',
      },
      {
        _id: '60cc21c647f7e10015fd64ae',
        courseCode: 'ECE 4445',
        shortName:
          'Electrical and Computer Engineering 4445A/B - INTRODUCTION TO DIGITAL IMAGE PROCESSING',
        courseName:
          'Electrical and Computer Engineering 4445A/B - INTRODUCTION TO DIGITAL IMAGE PROCESSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c647f7e10015fd64ae',
      },
      {
        _id: '60cc21c747f7e10015fd64b0',
        courseCode: 'ECE 4451',
        shortName:
          'Electrical and Computer Engineering 4451A/B - ADVANCED TOPICS IN WIRELESS COMMUNICATIONS',
        courseName:
          'Electrical and Computer Engineering 4451A/B - ADVANCED TOPICS IN WIRELESS COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c747f7e10015fd64b0',
      },
      {
        _id: '60cc21c747f7e10015fd64b2',
        courseCode: 'ECE 4455',
        shortName:
          'Electrical and Computer Engineering 4455A/B - BIOMEDICAL SYSTEMS ANALYSIS',
        courseName:
          'Electrical and Computer Engineering 4455A/B - BIOMEDICAL SYSTEMS ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c747f7e10015fd64b2',
      },
      {
        _id: '60cc21c747f7e10015fd64b4',
        courseCode: 'ECE 4456',
        shortName:
          'Electrical and Computer Engineering 4456A/B - POWER SYSTEM PROTECTION',
        courseName:
          'Electrical and Computer Engineering 4456A/B - POWER SYSTEM PROTECTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c747f7e10015fd64b4',
      },
      {
        _id: '60cc21c747f7e10015fd64b6',
        courseCode: 'ECE 4457',
        shortName:
          'Electrical and Computer Engineering 4457A/B - POWER ELECTRONICS',
        courseName:
          'Electrical and Computer Engineering 4457A/B - POWER ELECTRONICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c747f7e10015fd64b6',
      },
      {
        _id: '60cc21c747f7e10015fd64b8',
        courseCode: 'ECE 4460',
        shortName:
          'Electrical and Computer Engineering 4460A/B - REAL-TIME AND EMBEDDED SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 4460A/B - REAL-TIME AND EMBEDDED SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c747f7e10015fd64b8',
      },
      {
        _id: '60cc21c847f7e10015fd64ba',
        courseCode: 'ECE 4464',
        shortName:
          'Electrical and Computer Engineering 4464A/B - ELECTRIC POWER SYSTEMS II',
        courseName:
          'Electrical and Computer Engineering 4464A/B - ELECTRIC POWER SYSTEMS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c847f7e10015fd64ba',
      },
      {
        _id: '60cc21c847f7e10015fd64bc',
        courseCode: 'ECE 4468',
        shortName:
          'Electrical and Computer Engineering 4468A/B - SYSTEMS OPTIMIZATION',
        courseName:
          'Electrical and Computer Engineering 4468A/B - SYSTEMS OPTIMIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c847f7e10015fd64bc',
      },
      {
        _id: '60cc21c847f7e10015fd64be',
        courseCode: 'ECE 4469',
        shortName:
          'Electrical and Computer Engineering 4469A/B - APPLIED CONTROL SYSTEMS',
        courseName:
          'Electrical and Computer Engineering 4469A/B - APPLIED CONTROL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c847f7e10015fd64be',
      },
      {
        _id: '60cc21c847f7e10015fd64c0',
        courseCode: 'ELI 3000',
        shortName:
          'Engineering Leadership and Innovation 3000A/B - MANAGING THE INNOVATION PROCESS',
        courseName:
          'Engineering Leadership and Innovation 3000A/B - MANAGING THE INNOVATION PROCESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c847f7e10015fd64c0',
      },
      {
        _id: '60cc21c947f7e10015fd64c2',
        courseCode: 'ELI 3200',
        shortName:
          'Engineering Leadership and Innovation 3200A/B - NEW VENTURE CREATION',
        courseName:
          'Engineering Leadership and Innovation 3200A/B - NEW VENTURE CREATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c947f7e10015fd64c2',
      },
      {
        _id: '60cc21c947f7e10015fd64c4',
        courseCode: 'ELI 4100',
        shortName:
          'Engineering Leadership and Innovation 4100A/B - ENGINEERING LEADERSHIP',
        courseName:
          'Engineering Leadership and Innovation 4100A/B - ENGINEERING LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21c947f7e10015fd64c4',
      },
      {
        _id: '60cc21ca47f7e10015fd64c7',
        courseCode: 'ELI 4200',
        shortName:
          'Engineering Leadership and Innovation 4200A/B - THE ENTREPRENEURIAL ENVIRONMENT',
        courseName:
          'Engineering Leadership and Innovation 4200A/B - THE ENTREPRENEURIAL ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ca47f7e10015fd64c7',
      },
      {
        _id: '60cc21ca47f7e10015fd64c9',
        courseCode: 'ENGSCI 1021',
        shortName:
          'Engineering Science 1021A/B - PROPERTIES OF MATERIALS IN ENGINEERING',
        courseName:
          'Engineering Science 1021A/B - PROPERTIES OF MATERIALS IN ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ca47f7e10015fd64c9',
      },
      {
        _id: '60cc21cb47f7e10015fd64cb',
        courseCode: 'ENGSCI 1022',
        shortName: 'Engineering Science 1022A/B/Y - ENGINEERING STATICS',
        courseName: 'Engineering Science 1022A/B/Y - ENGINEERING STATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cb47f7e10015fd64cb',
      },
      {
        _id: '60cc21cb47f7e10015fd64cd',
        courseCode: 'ENGSCI 1036',
        shortName:
          'Engineering Science 1036A/B - PROGRAMMING FUNDAMENTALS FOR ENGINEERS',
        courseName:
          'Engineering Science 1036A/B - PROGRAMMING FUNDAMENTALS FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cb47f7e10015fd64cd',
      },
      {
        _id: '60cc21cb47f7e10015fd64cf',
        courseCode: 'ENGSCI 1050',
        shortName:
          'Engineering Science 1050 - FOUNDATIONS OF ENGINEERING PRACTICE',
        courseName:
          'Engineering Science 1050 - FOUNDATIONS OF ENGINEERING PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cb47f7e10015fd64cf',
      },
      {
        _id: '60cc21cb47f7e10015fd64d1',
        courseCode: 'ENGSCI 1150',
        shortName: 'Engineering Science 1150 - ENGINEERING SUMMER CO-OP',
        courseName: 'Engineering Science 1150 - ENGINEERING SUMMER CO-OP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cb47f7e10015fd64d1',
      },
      {
        _id: '60cc21cc47f7e10015fd64d3',
        courseCode: 'ENGSCI 2250',
        shortName: 'Engineering Science 2250 - ENGINEERING SUMMER CO-OP',
        courseName: 'Engineering Science 2250 - ENGINEERING SUMMER CO-OP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cc47f7e10015fd64d3',
      },
      {
        _id: '60cc21cc47f7e10015fd64d5',
        courseCode: 'ENGSCI 2274',
        shortName:
          'Engineering Science 2274A/B - MECHANICAL & MATERIALS ENGINEERING EXTERNSHIP PROGRAM I',
        courseName:
          'Engineering Science 2274A/B - MECHANICAL & MATERIALS ENGINEERING EXTERNSHIP PROGRAM I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cc47f7e10015fd64d5',
      },
      {
        _id: '60cc21cc47f7e10015fd64d7',
        courseCode: 'ENGSCI 2275',
        shortName: 'Engineering Science 2275A/B - MME EXTERNSHIP PROGRAM II',
        courseName: 'Engineering Science 2275A/B - MME EXTERNSHIP PROGRAM II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cc47f7e10015fd64d7',
      },
      {
        _id: '60cc21cd47f7e10015fd64d9',
        courseCode: 'ENGSCI 2276',
        shortName:
          'Engineering Science 2276 - CHEMICAL & BIOCHEMICAL ENGINEERING EXTERNSHIP PROGRAM',
        courseName:
          'Engineering Science 2276 - CHEMICAL & BIOCHEMICAL ENGINEERING EXTERNSHIP PROGRAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cd47f7e10015fd64d9',
      },
      {
        _id: '60cc21cd47f7e10015fd64db',
        courseCode: 'ENGSCI 3310',
        shortName:
          'Engineering Science 3310A/B - ENGINEERING IN A GLOBAL CONTEXT',
        courseName:
          'Engineering Science 3310A/B - ENGINEERING IN A GLOBAL CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cd47f7e10015fd64db',
      },
      {
        _id: '60cc21cd47f7e10015fd64dd',
        courseCode: 'ENGSCI 3350',
        shortName: 'Engineering Science 3350 - ENGINEERING SUMMER CO-OP',
        courseName: 'Engineering Science 3350 - ENGINEERING SUMMER CO-OP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cd47f7e10015fd64dd',
      },
      {
        _id: '60cc21ce47f7e10015fd64df',
        courseCode: 'ENGSCI 3351',
        shortName: 'Engineering Science 3351 - ENGINEERING INTERNSHIP',
        courseName: 'Engineering Science 3351 - ENGINEERING INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ce47f7e10015fd64df',
      },
      {
        _id: '60cc21ce47f7e10015fd64e1',
        courseCode: 'ENGSCI 3352',
        shortName: 'Engineering Science 3352 - ENGINEERING INTERNSHIP',
        courseName: 'Engineering Science 3352 - ENGINEERING INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ce47f7e10015fd64e1',
      },
      {
        _id: '60cc21cf47f7e10015fd64e3',
        courseCode: 'ENGSCI 3353',
        shortName: 'Engineering Science 3353 - ENGINEERING INTERNSHIP',
        courseName: 'Engineering Science 3353 - ENGINEERING INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cf47f7e10015fd64e3',
      },
      {
        _id: '60cc21cf47f7e10015fd64e5',
        courseCode: 'ENGSCI 3354',
        shortName: 'Engineering Science 3354 - ENGINEERING INTERNSHIP',
        courseName: 'Engineering Science 3354 - ENGINEERING INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21cf47f7e10015fd64e5',
      },
      {
        _id: '60cc21d047f7e10015fd64e7',
        courseCode: 'ENGSCI 3390',
        shortName:
          'Engineering Science 3390 - SUMMER COMMUNITY DEVELOPMENT PLACEMENT',
        courseName:
          'Engineering Science 3390 - SUMMER COMMUNITY DEVELOPMENT PLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d047f7e10015fd64e7',
      },
      {
        _id: '60cc21d047f7e10015fd64e9',
        courseCode: 'ENGSCI 4450',
        shortName: 'Engineering Science 4450 - ENGINEERING SUMMER CO-OP',
        courseName: 'Engineering Science 4450 - ENGINEERING SUMMER CO-OP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d047f7e10015fd64e9',
      },
      {
        _id: '60cc21d047f7e10015fd64eb',
        courseCode: 'ENGSCI 4466',
        shortName:
          'Engineering Science 4466A/B - TECHNOLOGICAL ENTREPRENEURSHIP & INNOVATION',
        courseName:
          'Engineering Science 4466A/B - TECHNOLOGICAL ENTREPRENEURSHIP & INNOVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d047f7e10015fd64eb',
      },
      {
        _id: '60cc21d147f7e10015fd64ed',
        courseCode: 'ENGSCI 4500',
        shortName: 'Engineering Science 4500 - GLOBAL EXTERNSHIP FOR ENGINEERS',
        courseName:
          'Engineering Science 4500 - GLOBAL EXTERNSHIP FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d147f7e10015fd64ed',
      },
      {
        _id: '60cc21d147f7e10015fd64ef',
        courseCode: 'ENGLISH 0005',
        shortName: 'English 0005W/X - ENGLISH FOR ACADEMIC PURPOSES',
        courseName: 'English 0005W/X - ENGLISH FOR ACADEMIC PURPOSES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d147f7e10015fd64ef',
      },
      {
        _id: '60cc21d147f7e10015fd64f1',
        courseCode: 'ENGLISH 0010',
        shortName: 'English 0010 - LITERATURE AND COMPOSITION',
        courseName: 'English 0010 - LITERATURE AND COMPOSITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d147f7e10015fd64f1',
      },
      {
        _id: '60cc21d247f7e10015fd64f3',
        courseCode: 'ENGLISH 0011',
        shortName: 'English 0011A/B - POETRY AND DRAMA',
        courseName: 'English 0011A/B - POETRY AND DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d247f7e10015fd64f3',
      },
      {
        _id: '60cc21d247f7e10015fd64f5',
        courseCode: 'ENGLISH 0012',
        shortName: 'English 0012A/B - SHORT STORIES AND NOVELS',
        courseName: 'English 0012A/B - SHORT STORIES AND NOVELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d247f7e10015fd64f5',
      },
      {
        _id: '60cc21d247f7e10015fd64f7',
        courseCode: 'ENGLISH 1010',
        shortName: 'English 1010F/G - THIS UNIVERSITY',
        courseName: 'English 1010F/G - THIS UNIVERSITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d247f7e10015fd64f7',
      },
      {
        _id: '60cc21d347f7e10015fd64f9',
        courseCode: 'ENGLISH 1020',
        shortName: 'English 1020E - UNDERSTANDING LITERATURE TODAY',
        courseName: 'English 1020E - UNDERSTANDING LITERATURE TODAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d347f7e10015fd64f9',
      },
      {
        _id: '60cc21d447f7e10015fd64fb',
        courseCode: 'ENGLISH 1022',
        shortName:
          'English 1022E - ENRICHED INTRODUCTION TO ENGLISH LITERATURE',
        courseName:
          'English 1022E - ENRICHED INTRODUCTION TO ENGLISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d447f7e10015fd64fb',
      },
      {
        _id: '60cc21d447f7e10015fd64fd',
        courseCode: 'ENGLISH 1024',
        shortName:
          'English 1024E - FORMS OF FICTION: INTRODUCTION TO NARRATIVE',
        courseName:
          'English 1024E - FORMS OF FICTION: INTRODUCTION TO NARRATIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d447f7e10015fd64fd',
      },
      {
        _id: '60cc21d547f7e10015fd64ff',
        courseCode: 'ENGLISH 1027',
        shortName:
          "English 1027F/G - THE STORYTELLER'S ART I: INTRODUCTION TO NARRATIVE",
        courseName:
          "English 1027F/G - THE STORYTELLER'S ART I: INTRODUCTION TO NARRATIVE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d547f7e10015fd64ff',
      },
      {
        _id: '60cc21d647f7e10015fd6501',
        courseCode: 'ENGLISH 1028',
        shortName:
          "English 1028F/G - THE STORYTELLER'S ART II: TOPICS IN NARRATIVE",
        courseName:
          "English 1028F/G - THE STORYTELLER'S ART II: TOPICS IN NARRATIVE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d647f7e10015fd6501',
      },
      {
        _id: '60cc21d647f7e10015fd6503',
        courseCode: 'ENGLISH 1035',
        shortName:
          'English 1035E - INTRODUCTION TO LITERATURE: READING, INTERPRETATION, ARGUMENT',
        courseName:
          'English 1035E - INTRODUCTION TO LITERATURE: READING, INTERPRETATION, ARGUMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d647f7e10015fd6503',
      },
      {
        _id: '60cc21d747f7e10015fd6505',
        courseCode: 'ENGLISH 1036',
        shortName: 'English 1036E - FORMS OF NARRATIVE: FROM EPIC TO FILM',
        courseName: 'English 1036E - FORMS OF NARRATIVE: FROM EPIC TO FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d747f7e10015fd6505',
      },
      {
        _id: '60cc21d747f7e10015fd6507',
        courseCode: 'ENGLISH 1042',
        shortName: 'English 1042E - LITERATURE AND CULTURAL REPRESENTATIONS',
        courseName: 'English 1042E - LITERATURE AND CULTURAL REPRESENTATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d747f7e10015fd6507',
      },
      {
        _id: '60cc21d747f7e10015fd6509',
        courseCode: 'ENGLISH 1120',
        shortName:
          'English 1120E - REPRESENTING VIOLENCE: AN INTRODUCTION TO THE STUDY OF ENGLISH LITERATURE',
        courseName:
          'English 1120E - REPRESENTING VIOLENCE: AN INTRODUCTION TO THE STUDY OF ENGLISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d747f7e10015fd6509',
      },
      {
        _id: '60cc21d747f7e10015fd650b',
        courseCode: 'ENGLISH 1901',
        shortName:
          'English 1901E - FOUNDATIONS IN WESTERN THOUGHT AND CIVILIZATION',
        courseName:
          'English 1901E - FOUNDATIONS IN WESTERN THOUGHT AND CIVILIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d747f7e10015fd650b',
      },
      {
        _id: '60cc21d847f7e10015fd650d',
        courseCode: 'ENGLISH 2017',
        shortName: 'English 2017 - READING POPULAR CULTURE',
        courseName: 'English 2017 - READING POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d847f7e10015fd650d',
      },
      {
        _id: '60cc21d847f7e10015fd650f',
        courseCode: 'ENGLISH 2018',
        shortName: 'English 2018A/B - THE CULTURE OF LEADERSHIP I',
        courseName: 'English 2018A/B - THE CULTURE OF LEADERSHIP I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d847f7e10015fd650f',
      },
      {
        _id: '60cc21d847f7e10015fd6511',
        courseCode: 'ENGLISH 2019',
        shortName: 'English 2019A/B - THE CULTURE OF LEADERSHIP II',
        courseName: 'English 2019A/B - THE CULTURE OF LEADERSHIP II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d847f7e10015fd6511',
      },
      {
        _id: '60cc21d947f7e10015fd6513',
        courseCode: 'ENGLISH 2021',
        shortName: 'English 2021F/G - SCIENCE FICTION AND RELIGION',
        courseName: 'English 2021F/G - SCIENCE FICTION AND RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d947f7e10015fd6513',
      },
      {
        _id: '60cc21d947f7e10015fd6515',
        courseCode: 'ENGLISH 2022',
        shortName: 'English 2022 - GREAT BOOKS',
        courseName: 'English 2022 - GREAT BOOKS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21d947f7e10015fd6515',
      },
      {
        _id: '60cc21da47f7e10015fd6517',
        courseCode: 'ENGLISH 2023',
        shortName: 'English 2023A/B - J.R.R. TOLKIEN, C.S. LEWIS AND FRIENDS',
        courseName: 'English 2023A/B - J.R.R. TOLKIEN, C.S. LEWIS AND FRIENDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21da47f7e10015fd6517',
      },
      {
        _id: '60cc21da47f7e10015fd6519',
        courseCode: 'ENGLISH 2024',
        shortName:
          'English 2024A/B - THE BLAZING WORLD TO THE HUNGER GAMES: GIRLS, WOMEN, UTOPIA AND DYSTOPIA',
        courseName:
          'English 2024A/B - THE BLAZING WORLD TO THE HUNGER GAMES: GIRLS, WOMEN, UTOPIA AND DYSTOPIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21da47f7e10015fd6519',
      },
      {
        _id: '60cc21da47f7e10015fd651b',
        courseCode: 'ENGLISH 2027',
        shortName: 'English 2027F/G - LITERATURE AND FILM',
        courseName: 'English 2027F/G - LITERATURE AND FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21da47f7e10015fd651b',
      },
      {
        _id: '60cc21da47f7e10015fd651d',
        courseCode: 'ENGLISH 2028',
        shortName: 'English 2028F/G - TOPICS IN LITERATURE AND FILM',
        courseName: 'English 2028F/G - TOPICS IN LITERATURE AND FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21da47f7e10015fd651d',
      },
      {
        _id: '60cc21db47f7e10015fd651f',
        courseCode: 'ENGLISH 2029',
        shortName: 'English 2029F/G - SPECIAL TOPICS',
        courseName: 'English 2029F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21db47f7e10015fd651f',
      },
      {
        _id: '60cc21db47f7e10015fd6521',
        courseCode: 'ENGLISH 2033',
        shortName: "English 2033E - CHILDREN'S LITERATURE",
        courseName: "English 2033E - CHILDREN'S LITERATURE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21db47f7e10015fd6521',
      },
      {
        _id: '60cc21dc47f7e10015fd6523',
        courseCode: 'ENGLISH 2038',
        shortName: 'English 2038E - CANADIAN LIT AND CULTURE',
        courseName: 'English 2038E - CANADIAN LIT AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21dc47f7e10015fd6523',
      },
      {
        _id: '60cc21dc47f7e10015fd6525',
        courseCode: 'ENGLISH 2041',
        shortName: 'English 2041F/G - SPECIAL TOPICS IN DRAMA',
        courseName: 'English 2041F/G - SPECIAL TOPICS IN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21dc47f7e10015fd6525',
      },
      {
        _id: '60cc21dc47f7e10015fd6527',
        courseCode: 'ENGLISH 2058',
        shortName: 'English 2058F/G - SPECIAL TOPICS',
        courseName: 'English 2058F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21dc47f7e10015fd6527',
      },
      {
        _id: '60cc21dc47f7e10015fd6529',
        courseCode: 'ENGLISH 2060',
        shortName: 'English 2060E - CONTEMPORARY CANADIAN LITERATURE',
        courseName: 'English 2060E - CONTEMPORARY CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21dc47f7e10015fd6529',
      },
      {
        _id: '60cc21dd47f7e10015fd652b',
        courseCode: 'ENGLISH 2061',
        shortName: 'English 2061F/G - SPECIAL TOPICS',
        courseName: 'English 2061F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21dd47f7e10015fd652b',
      },
      {
        _id: '60cc21dd47f7e10015fd652d',
        courseCode: 'ENGLISH 2071',
        shortName: 'English 2071F/G - SPECULATIVE FICTION: SCIENCE FICTION',
        courseName: 'English 2071F/G - SPECULATIVE FICTION: SCIENCE FICTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21dd47f7e10015fd652d',
      },
      {
        _id: '60cc21de47f7e10015fd652f',
        courseCode: 'ENGLISH 2072',
        shortName: 'English 2072F/G - SPECULATIVE FICTION: FANTASY',
        courseName: 'English 2072F/G - SPECULATIVE FICTION: FANTASY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21de47f7e10015fd652f',
      },
      {
        _id: '60cc21de47f7e10015fd6531',
        courseCode: 'ENGLISH 2073',
        shortName:
          'English 2073F/G - SPECULATIVE FICTION: UTOPIAS AND DYSTOPIAS',
        courseName:
          'English 2073F/G - SPECULATIVE FICTION: UTOPIAS AND DYSTOPIAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21de47f7e10015fd6531',
      },
      {
        _id: '60cc21df47f7e10015fd6533',
        courseCode: 'ENGLISH 2074',
        shortName: 'English 2074F/G - MYSTERY AND DETECTIVE FICTION',
        courseName: 'English 2074F/G - MYSTERY AND DETECTIVE FICTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21df47f7e10015fd6533',
      },
      {
        _id: '60cc21df47f7e10015fd6535',
        courseCode: 'ENGLISH 2075',
        shortName:
          'English 2075F/G - CULTURES OF BLOOD: THE CONTEMPORARY GOTHIC',
        courseName:
          'English 2075F/G - CULTURES OF BLOOD: THE CONTEMPORARY GOTHIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21df47f7e10015fd6535',
      },
      {
        _id: '60cc21df47f7e10015fd6537',
        courseCode: 'ENGLISH 2076',
        shortName:
          'English 2076F/G - MEDIEVAL HEROES, VILLAINS AND OTHER OUTSIDERS',
        courseName:
          'English 2076F/G - MEDIEVAL HEROES, VILLAINS AND OTHER OUTSIDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21df47f7e10015fd6537',
      },
      {
        _id: '60cc21df47f7e10015fd6539',
        courseCode: 'ENGLISH 2080',
        shortName: 'English 2080F/G - SPECIAL TOPICS',
        courseName: 'English 2080F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21df47f7e10015fd6539',
      },
      {
        _id: '60cc21e047f7e10015fd653b',
        courseCode: 'ENGLISH 2090',
        shortName: 'English 2090E - SPECIAL TOPICS',
        courseName: 'English 2090E - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e047f7e10015fd653b',
      },
      {
        _id: '60cc21e047f7e10015fd653d',
        courseCode: 'ENGLISH 2091',
        shortName: 'English 2091F/G - SPECIAL TOPICS',
        courseName: 'English 2091F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e047f7e10015fd653d',
      },
      {
        _id: '60cc21e147f7e10015fd653f',
        courseCode: 'ENGLISH 2092',
        shortName: 'English 2092F/G - SPECIAL TOPICS IN POPULAR LITERATURE',
        courseName: 'English 2092F/G - SPECIAL TOPICS IN POPULAR LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e147f7e10015fd653f',
      },
      {
        _id: '60cc21e147f7e10015fd6541',
        courseCode: 'ENGLISH 2093',
        shortName: 'English 2093 - SPECIAL TOPICS',
        courseName: 'English 2093 - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e147f7e10015fd6541',
      },
      {
        _id: '60cc21e147f7e10015fd6543',
        courseCode: 'ENGLISH 2094',
        shortName: 'English 2094A/B - SPECIAL TOPICS',
        courseName: 'English 2094A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e147f7e10015fd6543',
      },
      {
        _id: '60cc21e247f7e10015fd6545',
        courseCode: 'ENGLISH 2095',
        shortName: 'English 2095A/B - SPECIAL TOPICS',
        courseName: 'English 2095A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e247f7e10015fd6545',
      },
      {
        _id: '60cc21e247f7e10015fd6547',
        courseCode: 'ENGLISH 2096',
        shortName: 'English 2096F/G - SPECIAL TOPICS IN POPULAR LITERATURE',
        courseName: 'English 2096F/G - SPECIAL TOPICS IN POPULAR LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e247f7e10015fd6547',
      },
      {
        _id: '60cc21e247f7e10015fd6549',
        courseCode: 'ENGLISH 2097',
        shortName: 'English 2097A/B - THE MADNESS OF CREATIVITY',
        courseName: 'English 2097A/B - THE MADNESS OF CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e247f7e10015fd6549',
      },
      {
        _id: '60cc21e347f7e10015fd654b',
        courseCode: 'ENGLISH 2099',
        shortName:
          'English 2099F/G - THE ALICE MUNRO CHAIR IN CREATIVITY: THE CREATIVE MOMENT',
        courseName:
          'English 2099F/G - THE ALICE MUNRO CHAIR IN CREATIVITY: THE CREATIVE MOMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e347f7e10015fd654b',
      },
      {
        _id: '60cc21e347f7e10015fd654d',
        courseCode: 'ENGLISH 2100',
        shortName: 'English 2100F/G - CRIME WRITING SINCE THE 18TH CENTURY',
        courseName: 'English 2100F/G - CRIME WRITING SINCE THE 18TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e347f7e10015fd654d',
      },
      {
        _id: '60cc21e347f7e10015fd654f',
        courseCode: 'ENGLISH 2101',
        shortName: 'English 2101 - GLOBAL LITERATURES  IN ENGLISH SURVEY',
        courseName: 'English 2101 - GLOBAL LITERATURES  IN ENGLISH SURVEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e347f7e10015fd654f',
      },
      {
        _id: '60cc21e347f7e10015fd6551',
        courseCode: 'ENGLISH 2102',
        shortName: 'English 2102 - TWENTIETH-CENTURY DRAMA',
        courseName: 'English 2102 - TWENTIETH-CENTURY DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e347f7e10015fd6551',
      },
      {
        _id: '60cc21e447f7e10015fd6553',
        courseCode: 'ENGLISH 2110',
        shortName: 'English 2110E - THE NOVEL',
        courseName: 'English 2110E - THE NOVEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e447f7e10015fd6553',
      },
      {
        _id: '60cc21e447f7e10015fd6555',
        courseCode: 'ENGLISH 2164',
        shortName: 'English 2164E - HUMAN RIGHTS AND CREATIVE PRACTICES',
        courseName: 'English 2164E - HUMAN RIGHTS AND CREATIVE PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e447f7e10015fd6555',
      },
      {
        _id: '60cc21e447f7e10015fd6557',
        courseCode: 'ENGLISH 2180',
        shortName: 'English 2180F/G - SPORT IN LITERATURE',
        courseName: 'English 2180F/G - SPORT IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e447f7e10015fd6557',
      },
      {
        _id: '60cc21e547f7e10015fd6559',
        courseCode: 'ENGLISH 2190',
        shortName: 'English 2190F/G - SPECIAL TOPICS IN ENGLISH',
        courseName: 'English 2190F/G - SPECIAL TOPICS IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e547f7e10015fd6559',
      },
      {
        _id: '60cc21e547f7e10015fd655b',
        courseCode: 'ENGLISH 2191',
        shortName: 'English 2191F/G - SPECIAL TOPICS IN ENGLISH',
        courseName: 'English 2191F/G - SPECIAL TOPICS IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e547f7e10015fd655b',
      },
      {
        _id: '60cc21e547f7e10015fd655d',
        courseCode: 'ENGLISH 2200',
        shortName: 'English 2200F/G - HISTORY OF THEORY AND CRITICISM',
        courseName: 'English 2200F/G - HISTORY OF THEORY AND CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e547f7e10015fd655d',
      },
      {
        _id: '60cc21e647f7e10015fd655f',
        courseCode: 'ENGLISH 2201',
        shortName: 'English 2201F/G - CONTEMPORARY THEORY AND CRITICISM',
        courseName: 'English 2201F/G - CONTEMPORARY THEORY AND CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e647f7e10015fd655f',
      },
      {
        _id: '60cc21e647f7e10015fd6561',
        courseCode: 'ENGLISH 2202',
        shortName: 'English 2202F/G - STUDIES IN POETICS',
        courseName: 'English 2202F/G - STUDIES IN POETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e647f7e10015fd6561',
      },
      {
        _id: '60cc21e747f7e10015fd6563',
        courseCode: 'ENGLISH 2203',
        shortName: 'English 2203F/G - STUDIES IN NARRATIVE THEORY',
        courseName: 'English 2203F/G - STUDIES IN NARRATIVE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e747f7e10015fd6563',
      },
      {
        _id: '60cc21e747f7e10015fd6565',
        courseCode: 'ENGLISH 2228',
        shortName: 'English 2228F/G - LITERARY AND CULTURAL TEXTS: PRE-1700',
        courseName: 'English 2228F/G - LITERARY AND CULTURAL TEXTS: PRE-1700',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e747f7e10015fd6565',
      },
      {
        _id: '60cc21e847f7e10015fd6567',
        courseCode: 'ENGLISH 2229',
        shortName: 'English 2229F/G - LITERARY AND CULTURAL TEXTS: 1700-1900',
        courseName: 'English 2229F/G - LITERARY AND CULTURAL TEXTS: 1700-1900',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e847f7e10015fd6567',
      },
      {
        _id: '60cc21e847f7e10015fd6569',
        courseCode: 'ENGLISH 2230',
        shortName: 'English 2230F/G - STUDIES IN POETICS',
        courseName: 'English 2230F/G - STUDIES IN POETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e847f7e10015fd6569',
      },
      {
        _id: '60cc21e847f7e10015fd656b',
        courseCode: 'ENGLISH 2232',
        shortName: 'English 2232F/G - POETICS AND CREATIVITY',
        courseName: 'English 2232F/G - POETICS AND CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e847f7e10015fd656b',
      },
      {
        _id: '60cc21e947f7e10015fd656d',
        courseCode: 'ENGLISH 2235',
        shortName:
          'English 2235A/B - LITERARY THEORY: FROM PLATO TO THE NEW CRITICISM',
        courseName:
          'English 2235A/B - LITERARY THEORY: FROM PLATO TO THE NEW CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e947f7e10015fd656d',
      },
      {
        _id: '60cc21e947f7e10015fd656f',
        courseCode: 'ENGLISH 2236',
        shortName: 'English 2236F/G - LITERARY THEORY SINCE NEW CRITICISM',
        courseName: 'English 2236F/G - LITERARY THEORY SINCE NEW CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e947f7e10015fd656f',
      },
      {
        _id: '60cc21e947f7e10015fd6571',
        courseCode: 'ENGLISH 2239',
        shortName: 'English 2239E - SURVEY OF NOTED CATHOLIC AUTHORS',
        courseName: 'English 2239E - SURVEY OF NOTED CATHOLIC AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e947f7e10015fd6571',
      },
      {
        _id: '60cc21e947f7e10015fd6573',
        courseCode: 'ENGLISH 2262',
        shortName:
          'English 2262F/G - WATER AND CIVILIZATION: LITERATURE, ECOLOGY, ACTIVISM',
        courseName:
          'English 2262F/G - WATER AND CIVILIZATION: LITERATURE, ECOLOGY, ACTIVISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21e947f7e10015fd6573',
      },
      {
        _id: '60cc21ea47f7e10015fd6575',
        courseCode: 'ENGLISH 2263',
        shortName: 'English 2263E - EXISTENTIALIST LITERATURE',
        courseName: 'English 2263E - EXISTENTIALIST LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ea47f7e10015fd6575',
      },
      {
        _id: '60cc21ea47f7e10015fd6577',
        courseCode: 'ENGLISH 2265',
        shortName: 'English 2265F/G - INTRODUCTION TO THEORY',
        courseName: 'English 2265F/G - INTRODUCTION TO THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ea47f7e10015fd6577',
      },
      {
        _id: '60cc21ea47f7e10015fd6579',
        courseCode: 'ENGLISH 2299',
        shortName: 'English 2299F/G - CRITICAL PRACTICE',
        courseName: 'English 2299F/G - CRITICAL PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ea47f7e10015fd6579',
      },
      {
        _id: '60cc21ea47f7e10015fd657b',
        courseCode: 'ENGLISH 2301',
        shortName: 'English 2301E - BRITISH LITERATURE SURVEY',
        courseName: 'English 2301E - BRITISH LITERATURE SURVEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ea47f7e10015fd657b',
      },
      {
        _id: '60cc21eb47f7e10015fd657d',
        courseCode: 'ENGLISH 2302',
        shortName:
          'English 2302F/G - LITERATURE OF THE 16TH CENTURY: MAJOR AUTHORS',
        courseName:
          'English 2302F/G - LITERATURE OF THE 16TH CENTURY: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21eb47f7e10015fd657d',
      },
      {
        _id: '60cc21eb47f7e10015fd657f',
        courseCode: 'ENGLISH 2303',
        shortName: 'English 2303F/G - TOPICS IN LITERATURE OF THE 16TH CENTURY',
        courseName:
          'English 2303F/G - TOPICS IN LITERATURE OF THE 16TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21eb47f7e10015fd657f',
      },
      {
        _id: '60cc21eb47f7e10015fd6581',
        courseCode: 'ENGLISH 2305',
        shortName:
          'English 2305F/G - LITERATURE OF THE 17TH CENTURY: MAJOR AUTHORS',
        courseName:
          'English 2305F/G - LITERATURE OF THE 17TH CENTURY: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21eb47f7e10015fd6581',
      },
      {
        _id: '60cc21ec47f7e10015fd6583',
        courseCode: 'ENGLISH 2306',
        shortName: 'English 2306F/G - TOPICS IN 17TH CENTURY LITERATURE',
        courseName: 'English 2306F/G - TOPICS IN 17TH CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ec47f7e10015fd6583',
      },
      {
        _id: '60cc21ec47f7e10015fd6585',
        courseCode: 'ENGLISH 2316',
        shortName: 'English 2316F/G - TOPICS IN 18TH CENTURY LITERATURE',
        courseName: 'English 2316F/G - TOPICS IN 18TH CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ec47f7e10015fd6585',
      },
      {
        _id: '60cc21ec47f7e10015fd6587',
        courseCode: 'ENGLISH 2322',
        shortName: 'English 2322F/G - ROMANTIC LITERATURE: MAJOR AUTHORS',
        courseName: 'English 2322F/G - ROMANTIC LITERATURE: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ec47f7e10015fd6587',
      },
      {
        _id: '60cc21ed47f7e10015fd6589',
        courseCode: 'ENGLISH 2323',
        shortName: 'English 2323F/G - TOPICS IN ROMANTIC LITERATURE',
        courseName: 'English 2323F/G - TOPICS IN ROMANTIC LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ed47f7e10015fd6589',
      },
      {
        _id: '60cc21ed47f7e10015fd658b',
        courseCode: 'ENGLISH 2324',
        shortName: 'English 2324E - ENGLISH LITERATURE OF VICTORIAN PERIOD',
        courseName: 'English 2324E - ENGLISH LITERATURE OF VICTORIAN PERIOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ed47f7e10015fd658b',
      },
      {
        _id: '60cc21ed47f7e10015fd658d',
        courseCode: 'ENGLISH 2325',
        shortName: 'English 2325F/G - VICTORIAN LITERATURE (SURVEY)',
        courseName: 'English 2325F/G - VICTORIAN LITERATURE (SURVEY)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ed47f7e10015fd658d',
      },
      {
        _id: '60cc21ed47f7e10015fd658f',
        courseCode: 'ENGLISH 2326',
        shortName: 'English 2326F/G - TOPICS IN VICTORIAN LITERATURE',
        courseName: 'English 2326F/G - TOPICS IN VICTORIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ed47f7e10015fd658f',
      },
      {
        _id: '60cc21ee47f7e10015fd6591',
        courseCode: 'ENGLISH 2331',
        shortName: 'English 2331E - BRITISH LITERATURE FROM 1890-1945',
        courseName: 'English 2331E - BRITISH LITERATURE FROM 1890-1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ee47f7e10015fd6591',
      },
      {
        _id: '60cc21ee47f7e10015fd6593',
        courseCode: 'ENGLISH 2332',
        shortName:
          'English 2332F/G - BRITISH LITERATURE 1890-1945: MAJOR AUTHORS',
        courseName:
          'English 2332F/G - BRITISH LITERATURE 1890-1945: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ee47f7e10015fd6593',
      },
      {
        _id: '60cc21ee47f7e10015fd6595',
        courseCode: 'ENGLISH 2334',
        shortName: 'English 2334E - BRITISH LITERATURE SINCE 1945',
        courseName: 'English 2334E - BRITISH LITERATURE SINCE 1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ee47f7e10015fd6595',
      },
      {
        _id: '60cc21ee47f7e10015fd6597',
        courseCode: 'ENGLISH 2335',
        shortName:
          'English 2335F/G - BRITISH LITERATURE SINCE 1945: MAJOR AUTHORS',
        courseName:
          'English 2335F/G - BRITISH LITERATURE SINCE 1945: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ee47f7e10015fd6597',
      },
      {
        _id: '60cc21ef47f7e10015fd6599',
        courseCode: 'ENGLISH 2336',
        shortName:
          'English 2336F/G - TOPICS IN BRITISH LITERATURE 1945 TO THE PRESENT',
        courseName:
          'English 2336F/G - TOPICS IN BRITISH LITERATURE 1945 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ef47f7e10015fd6599',
      },
      {
        _id: '60cc21ef47f7e10015fd659b',
        courseCode: 'ENGLISH 2337',
        shortName: 'English 2337F/G - J.R.R. TOLKIEN, C.S. LEWIS AND FRIENDS',
        courseName: 'English 2337F/G - J.R.R. TOLKIEN, C.S. LEWIS AND FRIENDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ef47f7e10015fd659b',
      },
      {
        _id: '60cc21ef47f7e10015fd659d',
        courseCode: 'ENGLISH 2341',
        shortName:
          'English 2341E - AMERICAN LITERATURE FROM COLONIAL PERIOD TO 1890',
        courseName:
          'English 2341E - AMERICAN LITERATURE FROM COLONIAL PERIOD TO 1890',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ef47f7e10015fd659d',
      },
      {
        _id: '60cc21ef47f7e10015fd659f',
        courseCode: 'ENGLISH 2342',
        shortName:
          'English 2342F/G - AMERICAN LITERATURE TO 1890: MAJOR AUTHORS',
        courseName:
          'English 2342F/G - AMERICAN LITERATURE TO 1890: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ef47f7e10015fd659f',
      },
      {
        _id: '60cc21f047f7e10015fd65a1',
        courseCode: 'ENGLISH 2343',
        shortName: 'English 2343F/G - TOPICS IN AMERICAN LITERATURE TO 1890',
        courseName: 'English 2343F/G - TOPICS IN AMERICAN LITERATURE TO 1890',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f047f7e10015fd65a1',
      },
      {
        _id: '60cc21f047f7e10015fd65a3',
        courseCode: 'ENGLISH 2344',
        shortName: 'English 2344E - AMERICAN LITERATURE, 1890 TO THE PRESENT',
        courseName: 'English 2344E - AMERICAN LITERATURE, 1890 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f047f7e10015fd65a3',
      },
      {
        _id: '60cc21f047f7e10015fd65a5',
        courseCode: 'ENGLISH 2345',
        shortName:
          'English 2345F/G - AMERICAN LITERATURE SINCE 1890: MAJOR AUTHORS',
        courseName:
          'English 2345F/G - AMERICAN LITERATURE SINCE 1890: MAJOR AUTHORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f047f7e10015fd65a5',
      },
      {
        _id: '60cc21f147f7e10015fd65a7',
        courseCode: 'ENGLISH 2346',
        shortName:
          'English 2346F/G - TOPICS IN AMERICAN LITERATURE: 1890 TO THE PRESENT',
        courseName:
          'English 2346F/G - TOPICS IN AMERICAN LITERATURE: 1890 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f147f7e10015fd65a7',
      },
      {
        _id: '60cc21f147f7e10015fd65a9',
        courseCode: 'ENGLISH 2354',
        shortName: 'English 2354E - CANADIAN LITERATURE IN ENGLISH SINCE 1914',
        courseName: 'English 2354E - CANADIAN LITERATURE IN ENGLISH SINCE 1914',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f147f7e10015fd65a9',
      },
      {
        _id: '60cc21f147f7e10015fd65ab',
        courseCode: 'ENGLISH 2356',
        shortName:
          'English 2356F/G - TOPICS IN 20TH CENTURY CANADIAN LITERATURE IN ENGLISH',
        courseName:
          'English 2356F/G - TOPICS IN 20TH CENTURY CANADIAN LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f147f7e10015fd65ab',
      },
      {
        _id: '60cc21f147f7e10015fd65ad',
        courseCode: 'ENGLISH 2357',
        shortName:
          'English 2357F/G - MONSTERS, GHOSTS AND DEMONS IN THE NINETEENTH CENTURY',
        courseName:
          'English 2357F/G - MONSTERS, GHOSTS AND DEMONS IN THE NINETEENTH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f147f7e10015fd65ad',
      },
      {
        _id: '60cc21f247f7e10015fd65af',
        courseCode: 'ENGLISH 2361',
        shortName: 'English 2361E - WORLD LITERATURE WRITTEN IN ENGLISH',
        courseName: 'English 2361E - WORLD LITERATURE WRITTEN IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f247f7e10015fd65af',
      },
      {
        _id: '60cc21f247f7e10015fd65b1',
        courseCode: 'ENGLISH 2362',
        shortName: 'English 2362F/G - POST-COLONIAL WRITING',
        courseName: 'English 2362F/G - POST-COLONIAL WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f247f7e10015fd65b1',
      },
      {
        _id: '60cc21f247f7e10015fd65b3',
        courseCode: 'ENGLISH 2363',
        shortName:
          'English 2363F/G - TOPICS IN WORLD LITERATURE WRITTEN IN ENGLISH',
        courseName:
          'English 2363F/G - TOPICS IN WORLD LITERATURE WRITTEN IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f247f7e10015fd65b3',
      },
      {
        _id: '60cc21f247f7e10015fd65b5',
        courseCode: 'ENGLISH 2371',
        shortName: 'English 2371E - SHAKESPEARE AND HIS CONTEMPORARIES',
        courseName: 'English 2371E - SHAKESPEARE AND HIS CONTEMPORARIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f247f7e10015fd65b5',
      },
      {
        _id: '60cc21f347f7e10015fd65b7',
        courseCode: 'ENGLISH 2372',
        shortName: 'English 2372F/G - SHAKESPEARE',
        courseName: 'English 2372F/G - SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f347f7e10015fd65b7',
      },
      {
        _id: '60cc21f347f7e10015fd65b9',
        courseCode: 'ENGLISH 2373',
        shortName: 'English 2373F/G - TOPICS IN SHAKESPEARE',
        courseName: 'English 2373F/G - TOPICS IN SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f347f7e10015fd65b9',
      },
      {
        _id: '60cc21f347f7e10015fd65bb',
        courseCode: 'ENGLISH 2375',
        shortName: 'English 2375F/G - JACOBEAN DRAMA,  EXCLUSING SHAKESPEARE',
        courseName: 'English 2375F/G - JACOBEAN DRAMA,  EXCLUSING SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f347f7e10015fd65bb',
      },
      {
        _id: '60cc21f347f7e10015fd65bd',
        courseCode: 'ENGLISH 2399',
        shortName:
          'English 2399E - BRITISH LITERATURE SURVEY: CREATIVITY OPTION',
        courseName:
          'English 2399E - BRITISH LITERATURE SURVEY: CREATIVITY OPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f347f7e10015fd65bd',
      },
      {
        _id: '60cc21f447f7e10015fd65bf',
        courseCode: 'ENGLISH 2400',
        shortName: 'English 2400E - DRAMATIC FORMS/GENRES',
        courseName: 'English 2400E - DRAMATIC FORMS/GENRES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f447f7e10015fd65bf',
      },
      {
        _id: '60cc21f447f7e10015fd65c1',
        courseCode: 'ENGLISH 2401',
        shortName: 'English 2401E - AMERICAN LITERATURE SURVEY',
        courseName: 'English 2401E - AMERICAN LITERATURE SURVEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f447f7e10015fd65c1',
      },
      {
        _id: '60cc21f547f7e10015fd65c3',
        courseCode: 'ENGLISH 2430',
        shortName: 'English 2430E - SHAKESPEARE AND DRAMA',
        courseName: 'English 2430E - SHAKESPEARE AND DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f547f7e10015fd65c3',
      },
      {
        _id: '60cc21f547f7e10015fd65c5',
        courseCode: 'ENGLISH 2450',
        shortName: 'English 2450E - MODERN DRAMA',
        courseName: 'English 2450E - MODERN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f547f7e10015fd65c5',
      },
      {
        _id: '60cc21f547f7e10015fd65c7',
        courseCode: 'ENGLISH 2460',
        shortName: 'English 2460F/G - AMERICAN DRAMA',
        courseName: 'English 2460F/G - AMERICAN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f547f7e10015fd65c7',
      },
      {
        _id: '60cc21f547f7e10015fd65c9',
        courseCode: 'ENGLISH 2470',
        shortName: 'English 2470F/G - CANADIAN DRAMA',
        courseName: 'English 2470F/G - CANADIAN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f547f7e10015fd65c9',
      },
      {
        _id: '60cc21f647f7e10015fd65cb',
        courseCode: 'ENGLISH 2499',
        shortName:
          'English 2499E - AMERICAN LITERATURE SURVEY: CREATIVITY OPTION',
        courseName:
          'English 2499E - AMERICAN LITERATURE SURVEY: CREATIVITY OPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f647f7e10015fd65cb',
      },
      {
        _id: '60cc21f647f7e10015fd65cd',
        courseCode: 'ENGLISH 2501',
        shortName: 'English 2501E - CANADIAN LITERATURE SURVEY',
        courseName: 'English 2501E - CANADIAN LITERATURE SURVEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f647f7e10015fd65cd',
      },
      {
        _id: '60cc21f647f7e10015fd65cf',
        courseCode: 'ENGLISH 2510',
        shortName: 'English 2510F/G - TOPICS IN POETRY',
        courseName: 'English 2510F/G - TOPICS IN POETRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f647f7e10015fd65cf',
      },
      {
        _id: '60cc21f747f7e10015fd65d1',
        courseCode: 'ENGLISH 2525',
        shortName: 'English 2525F/G - SPECULATIVE FICTION: SPECIAL TOPICS',
        courseName: 'English 2525F/G - SPECULATIVE FICTION: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f747f7e10015fd65d1',
      },
      {
        _id: '60cc21f747f7e10015fd65d3',
        courseCode: 'ENGLISH 2535',
        shortName: 'English 2535F/G - SPECULATIVE FICTION: SPECIAL TOPICS',
        courseName: 'English 2535F/G - SPECULATIVE FICTION: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f747f7e10015fd65d3',
      },
      {
        _id: '60cc21f747f7e10015fd65d5',
        courseCode: 'ENGLISH 2550',
        shortName:
          'English 2550F/G - SPECIAL TOPICS IN ENGLISH AND CULTURAL STUDIES',
        courseName:
          'English 2550F/G - SPECIAL TOPICS IN ENGLISH AND CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f747f7e10015fd65d5',
      },
      {
        _id: '60cc21f747f7e10015fd65d7',
        courseCode: 'ENGLISH 2551',
        shortName:
          'English 2551F/G - SPECIAL TOPICS IN ENGLISH AND CULTURAL STUDIES',
        courseName:
          'English 2551F/G - SPECIAL TOPICS IN ENGLISH AND CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f747f7e10015fd65d7',
      },
      {
        _id: '60cc21f847f7e10015fd65d9',
        courseCode: 'ENGLISH 2600',
        shortName: 'English 2600F/G - LITERATURE OF THE BIBLE',
        courseName: 'English 2600F/G - LITERATURE OF THE BIBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f847f7e10015fd65d9',
      },
      {
        _id: '60cc21f847f7e10015fd65db',
        courseCode: 'ENGLISH 2601',
        shortName: 'English 2601E - GLOBAL LITERATURES IN ENGLISH SURVEY',
        courseName: 'English 2601E - GLOBAL LITERATURES IN ENGLISH SURVEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f847f7e10015fd65db',
      },
      {
        _id: '60cc21f947f7e10015fd65dd',
        courseCode: 'ENGLISH 2692',
        shortName:
          'English 2692E - BIBLICAL AND CLASSICAL BACKGROUNDS TO ENGLISH LITERATURE',
        courseName:
          'English 2692E - BIBLICAL AND CLASSICAL BACKGROUNDS TO ENGLISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f947f7e10015fd65dd',
      },
      {
        _id: '60cc21f947f7e10015fd65df',
        courseCode: 'ENGLISH 2695',
        shortName:
          'English 2695E - BACKGROUNDS AND FOUNDATIONS IN ENGLISH STUDIES',
        courseName:
          'English 2695E - BACKGROUNDS AND FOUNDATIONS IN ENGLISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f947f7e10015fd65df',
      },
      {
        _id: '60cc21f947f7e10015fd65e1',
        courseCode: 'ENGLISH 2696',
        shortName: 'English 2696E - FOUNDATIONS IN WESTERN LITERATURE',
        courseName: 'English 2696E - FOUNDATIONS IN WESTERN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21f947f7e10015fd65e1',
      },
      {
        _id: '60cc21fa47f7e10015fd65e3',
        courseCode: 'ENGLISH 2705',
        shortName: 'English 2705F/G - WOMEN AND LITERATURE: SPECIAL TOPICS',
        courseName: 'English 2705F/G - WOMEN AND LITERATURE: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fa47f7e10015fd65e3',
      },
      {
        _id: '60cc21fa47f7e10015fd65e5',
        courseCode: 'ENGLISH 2715',
        shortName: 'English 2715F/G - WOMEN & LITERATURE: SPECIAL TOPICS',
        courseName: 'English 2715F/G - WOMEN & LITERATURE: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fa47f7e10015fd65e5',
      },
      {
        _id: '60cc21fa47f7e10015fd65e7',
        courseCode: 'ENGLISH 2730',
        shortName:
          "English 2730F/G - CHILDREN'S CULTURE & LITERATURE: 1700-1914",
        courseName:
          "English 2730F/G - CHILDREN'S CULTURE & LITERATURE: 1700-1914",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fa47f7e10015fd65e7',
      },
      {
        _id: '60cc21fb47f7e10015fd65e9',
        courseCode: 'ENGLISH 2731',
        shortName: 'English 2731F/G - A HISTORY OF THE BOOK',
        courseName: 'English 2731F/G - A HISTORY OF THE BOOK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fb47f7e10015fd65e9',
      },
      {
        _id: '60cc21fb47f7e10015fd65eb',
        courseCode: 'ENGLISH 2732',
        shortName: 'English 2732F/G - THE BOOK: IN FOCUS',
        courseName: 'English 2732F/G - THE BOOK: IN FOCUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fb47f7e10015fd65eb',
      },
      {
        _id: '60cc21fc47f7e10015fd65ed',
        courseCode: 'ENGLISH 2735',
        shortName:
          "English 2735F/G - CHILDREN'S CULTURE & LITERATURE: THE 1950'S TO THE PRESENT",
        courseName:
          "English 2735F/G - CHILDREN'S CULTURE & LITERATURE: THE 1950'S TO THE PRESENT",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fc47f7e10015fd65ed',
      },
      {
        _id: '60cc21fc47f7e10015fd65ef',
        courseCode: 'ENGLISH 2824',
        shortName: 'English 2824F/G - SPECIAL TOPICS IN CULTURAL STUDIES',
        courseName: 'English 2824F/G - SPECIAL TOPICS IN CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fc47f7e10015fd65ef',
      },
      {
        _id: '60cc21fd47f7e10015fd65f1',
        courseCode: 'ENGLISH 2825',
        shortName: 'English 2825E - SPECIAL TOPICS IN CULTURAL STUDIES',
        courseName: 'English 2825E - SPECIAL TOPICS IN CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fd47f7e10015fd65f1',
      },
      {
        _id: '60cc21fd47f7e10015fd65f3',
        courseCode: 'ENGLISH 2830',
        shortName:
          'English 2830F/G - NATURE AND CULTURE IN NORTH AMERICAN LITERATURE',
        courseName:
          'English 2830F/G - NATURE AND CULTURE IN NORTH AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fd47f7e10015fd65f3',
      },
      {
        _id: '60cc21fe47f7e10015fd65f5',
        courseCode: 'ENGLISH 2877',
        shortName:
          'English 2877F/G - FROM THE BLAZING WORLD TO THE HUNGER GAMES: GIRLS, WOMEN, UTOPIA AND DYSTOPIA',
        courseName:
          'English 2877F/G - FROM THE BLAZING WORLD TO THE HUNGER GAMES: GIRLS, WOMEN, UTOPIA AND DYSTOPIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fe47f7e10015fd65f5',
      },
      {
        _id: '60cc21fe47f7e10015fd65f7',
        courseCode: 'ENGLISH 3114',
        shortName: 'English 3114E - MEDIEVAL LITERATURE',
        courseName: 'English 3114E - MEDIEVAL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fe47f7e10015fd65f7',
      },
      {
        _id: '60cc21fe47f7e10015fd65f9',
        courseCode: 'ENGLISH 3168',
        shortName:
          'English 3168E - NINETEENTH-CENTURY LITERATURE: CREATIVITY OPTION',
        courseName:
          'English 3168E - NINETEENTH-CENTURY LITERATURE: CREATIVITY OPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21fe47f7e10015fd65f9',
      },
      {
        _id: '60cc21ff47f7e10015fd65fb',
        courseCode: 'ENGLISH 3200',
        shortName: 'English 3200F/G - FEMINIST LITERARY THEORY',
        courseName: 'English 3200F/G - FEMINIST LITERARY THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ff47f7e10015fd65fb',
      },
      {
        _id: '60cc21ff47f7e10015fd65fd',
        courseCode: 'ENGLISH 3201',
        shortName: 'English 3201F/G - INTRODUCTION TO CULTURAL STUDIES',
        courseName: 'English 3201F/G - INTRODUCTION TO CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc21ff47f7e10015fd65fd',
      },
      {
        _id: '60cc220047f7e10015fd65ff',
        courseCode: 'ENGLISH 3202',
        shortName:
          'English 3202F/G - NATIONAL AND GLOBAL PERSPECTIVES ON CULTURAL STUDIES',
        courseName:
          'English 3202F/G - NATIONAL AND GLOBAL PERSPECTIVES ON CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220047f7e10015fd65ff',
      },
      {
        _id: '60cc220047f7e10015fd6601',
        courseCode: 'ENGLISH 3203',
        shortName: 'English 3203F/G - HUMAN, ALL TOO HUMAN',
        courseName: 'English 3203F/G - HUMAN, ALL TOO HUMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220047f7e10015fd6601',
      },
      {
        _id: '60cc220147f7e10015fd6603',
        courseCode: 'ENGLISH 3204',
        shortName: 'English 3204F/G - CRITICAL RACE THEORY',
        courseName: 'English 3204F/G - CRITICAL RACE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220147f7e10015fd6603',
      },
      {
        _id: '60cc220147f7e10015fd6605',
        courseCode: 'ENGLISH 3206',
        shortName: 'English 3206F/G - DIGITAL NARRATIVES',
        courseName: 'English 3206F/G - DIGITAL NARRATIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220147f7e10015fd6605',
      },
      {
        _id: '60cc220147f7e10015fd6607',
        courseCode: 'ENGLISH 3209',
        shortName: 'English 3209F/G - TOPICS IN THEORY',
        courseName: 'English 3209F/G - TOPICS IN THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220147f7e10015fd6607',
      },
      {
        _id: '60cc220247f7e10015fd6609',
        courseCode: 'ENGLISH 3210',
        shortName: 'English 3210F/G - TOPICS IN CULTURAL STUDIES',
        courseName: 'English 3210F/G - TOPICS IN CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220247f7e10015fd6609',
      },
      {
        _id: '60cc220247f7e10015fd660b',
        courseCode: 'ENGLISH 3261',
        shortName:
          'English 3261F/G - PSYCHOLOGY AND LITERATURE: TORTURED MINDS, TWISTED TEXTS',
        courseName:
          'English 3261F/G - PSYCHOLOGY AND LITERATURE: TORTURED MINDS, TWISTED TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220247f7e10015fd660b',
      },
      {
        _id: '60cc220347f7e10015fd660d',
        courseCode: 'ENGLISH 3265',
        shortName: 'English 3265F/G - ADVANCED THEORY',
        courseName: 'English 3265F/G - ADVANCED THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220347f7e10015fd660d',
      },
      {
        _id: '60cc220347f7e10015fd660f',
        courseCode: 'ENGLISH 3273',
        shortName:
          'English 3273E - SHAKESPEAREAN DRAMA: FROM PAGE TO STAGE, FROM SCREEN TO STREAM',
        courseName:
          'English 3273E - SHAKESPEAREAN DRAMA: FROM PAGE TO STAGE, FROM SCREEN TO STREAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220347f7e10015fd660f',
      },
      {
        _id: '60cc220347f7e10015fd6611',
        courseCode: 'ENGLISH 3300',
        shortName: 'English 3300 - HISTORY OF ENGLISH LANGUAGE',
        courseName: 'English 3300 - HISTORY OF ENGLISH LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220347f7e10015fd6611',
      },
      {
        _id: '60cc220447f7e10015fd6613',
        courseCode: 'ENGLISH 3310',
        shortName: 'English 3310 - OLD ENGLISH LANGUAGE AND LITERATURE',
        courseName: 'English 3310 - OLD ENGLISH LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220447f7e10015fd6613',
      },
      {
        _id: '60cc220447f7e10015fd6615',
        courseCode: 'ENGLISH 3315',
        shortName:
          'English 3315E - DISENCHANTED CHAUCER: AUTHORITY AND LITERATURE IN MEDIEVAL ENGLAND',
        courseName:
          'English 3315E - DISENCHANTED CHAUCER: AUTHORITY AND LITERATURE IN MEDIEVAL ENGLAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220447f7e10015fd6615',
      },
      {
        _id: '60cc220447f7e10015fd6617',
        courseCode: 'ENGLISH 3316',
        shortName: 'English 3316E - LOVE IN THE MIDDLE AGES',
        courseName: 'English 3316E - LOVE IN THE MIDDLE AGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220447f7e10015fd6617',
      },
      {
        _id: '60cc220547f7e10015fd6619',
        courseCode: 'ENGLISH 3318',
        shortName: 'English 3318E - MIDDLE ENGLISH LITERATURE',
        courseName: 'English 3318E - MIDDLE ENGLISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220547f7e10015fd6619',
      },
      {
        _id: '60cc220547f7e10015fd661b',
        courseCode: 'ENGLISH 3319',
        shortName:
          'English 3319F/G - TOPICS IN MEDIEVAL LANGUAGE AND LITERATURE',
        courseName:
          'English 3319F/G - TOPICS IN MEDIEVAL LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220547f7e10015fd661b',
      },
      {
        _id: '60cc220547f7e10015fd661d',
        courseCode: 'ENGLISH 3320',
        shortName: 'English 3320F/G - DESIRE IN THE RENAISSANCE',
        courseName: 'English 3320F/G - DESIRE IN THE RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220547f7e10015fd661d',
      },
      {
        _id: '60cc220647f7e10015fd661f',
        courseCode: 'ENGLISH 3321',
        shortName: 'English 3321F/G - PARADISE LOST',
        courseName: 'English 3321F/G - PARADISE LOST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220647f7e10015fd661f',
      },
      {
        _id: '60cc220647f7e10015fd6621',
        courseCode: 'ENGLISH 3322',
        shortName:
          'English 3322F/G - WITCHCRAFT, MAGIC AND SCIENCE IN RENAISSANCE ENGLISH LITERATURE',
        courseName:
          'English 3322F/G - WITCHCRAFT, MAGIC AND SCIENCE IN RENAISSANCE ENGLISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220647f7e10015fd6621',
      },
      {
        _id: '60cc220647f7e10015fd6623',
        courseCode: 'ENGLISH 3323',
        shortName: 'English 3323F/G - DRAMA AFTER SHAKESPEARE',
        courseName: 'English 3323F/G - DRAMA AFTER SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220647f7e10015fd6623',
      },
      {
        _id: '60cc220647f7e10015fd6625',
        courseCode: 'ENGLISH 3326',
        shortName: 'English 3326F/G - DEATH IN THE RENAISSANCE',
        courseName: 'English 3326F/G - DEATH IN THE RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220647f7e10015fd6625',
      },
      {
        _id: '60cc220747f7e10015fd6627',
        courseCode: 'ENGLISH 3327',
        shortName: 'English 3327A/B - REMEDIATED SHAKESPEARE',
        courseName: 'English 3327A/B - REMEDIATED SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220747f7e10015fd6627',
      },
      {
        _id: '60cc220747f7e10015fd6629',
        courseCode: 'ENGLISH 3328',
        shortName: 'English 3328E - RENAISSANCE LITERATURE',
        courseName: 'English 3328E - RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220747f7e10015fd6629',
      },
      {
        _id: '60cc220747f7e10015fd662b',
        courseCode: 'ENGLISH 3329',
        shortName: 'English 3329F/G - TOPICS IN RENAISSANCE LITERATURE',
        courseName: 'English 3329F/G - TOPICS IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220747f7e10015fd662b',
      },
      {
        _id: '60cc220847f7e10015fd662d',
        courseCode: 'ENGLISH 3330',
        shortName: 'English 3330E - SHAKESPEARE',
        courseName: 'English 3330E - SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220847f7e10015fd662d',
      },
      {
        _id: '60cc220847f7e10015fd662f',
        courseCode: 'ENGLISH 3331',
        shortName: 'English 3331F/G - ADAPTING SHAKESPEARE',
        courseName: 'English 3331F/G - ADAPTING SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220847f7e10015fd662f',
      },
      {
        _id: '60cc220947f7e10015fd6631',
        courseCode: 'ENGLISH 3332',
        shortName: 'English 3332F/G - MONEY IN RENAISSANCE DRAMA',
        courseName: 'English 3332F/G - MONEY IN RENAISSANCE DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220947f7e10015fd6631',
      },
      {
        _id: '60cc220947f7e10015fd6633',
        courseCode: 'ENGLISH 3333',
        shortName:
          'English 3333E - EXPERIENTIAL ENGAGEMENT WITH LITERATURE & CULTURE',
        courseName:
          'English 3333E - EXPERIENTIAL ENGAGEMENT WITH LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220947f7e10015fd6633',
      },
      {
        _id: '60cc220947f7e10015fd6635',
        courseCode: 'ENGLISH 3337',
        shortName: 'English 3337E - SHAKESPEARE AND THE DRAMA OF HIS AGE',
        courseName: 'English 3337E - SHAKESPEARE AND THE DRAMA OF HIS AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220947f7e10015fd6635',
      },
      {
        _id: '60cc220a47f7e10015fd6637',
        courseCode: 'ENGLISH 3338',
        shortName: 'English 3338E - RENAISSANCE DRAMA',
        courseName: 'English 3338E - RENAISSANCE DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220a47f7e10015fd6637',
      },
      {
        _id: '60cc220a47f7e10015fd6639',
        courseCode: 'ENGLISH 3339',
        shortName: 'English 3339F/G - TOPICS IN RENAISSANCE LITERATURE',
        courseName: 'English 3339F/G - TOPICS IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220a47f7e10015fd6639',
      },
      {
        _id: '60cc220a47f7e10015fd663b',
        courseCode: 'ENGLISH 3340',
        shortName: 'English 3340F/G - EIGHTEENTH-CENTURY NARRATIVES OF EMPIRE',
        courseName: 'English 3340F/G - EIGHTEENTH-CENTURY NARRATIVES OF EMPIRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220a47f7e10015fd663b',
      },
      {
        _id: '60cc220a47f7e10015fd663d',
        courseCode: 'ENGLISH 3341',
        shortName:
          'English 3341F/G - SEX, DEATH, AND PHILOSOPHY: LIBERTINISM AND EIGHTEENTH-CENTURY BRITISH LITERATURE',
        courseName:
          'English 3341F/G - SEX, DEATH, AND PHILOSOPHY: LIBERTINISM AND EIGHTEENTH-CENTURY BRITISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220a47f7e10015fd663d',
      },
      {
        _id: '60cc220b47f7e10015fd663f',
        courseCode: 'ENGLISH 3342',
        shortName:
          'English 3342F/G - BODY, SOUL AND PERSON IN THE EIGHTEENTH CENTURY',
        courseName:
          'English 3342F/G - BODY, SOUL AND PERSON IN THE EIGHTEENTH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220b47f7e10015fd663f',
      },
      {
        _id: '60cc220b47f7e10015fd6641',
        courseCode: 'ENGLISH 3343',
        shortName: 'English 3343F/G - THE ETHICS OF EIGHTEENTH-CENTURY SATIRE',
        courseName: 'English 3343F/G - THE ETHICS OF EIGHTEENTH-CENTURY SATIRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220b47f7e10015fd6641',
      },
      {
        _id: '60cc220b47f7e10015fd6643',
        courseCode: 'ENGLISH 3345',
        shortName:
          'English 3345E - TOPICS IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 3345E - TOPICS IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220b47f7e10015fd6643',
      },
      {
        _id: '60cc220c47f7e10015fd6645',
        courseCode: 'ENGLISH 3347',
        shortName:
          'English 3347E - RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 3347E - RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220c47f7e10015fd6645',
      },
      {
        _id: '60cc220c47f7e10015fd6647',
        courseCode: 'ENGLISH 3348',
        shortName:
          'English 3348F/G - TOPICS IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 3348F/G - TOPICS IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220c47f7e10015fd6647',
      },
      {
        _id: '60cc220d47f7e10015fd6649',
        courseCode: 'ENGLISH 3349',
        shortName:
          'English 3349F/G - TOPICS IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 3349F/G - TOPICS IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220d47f7e10015fd6649',
      },
      {
        _id: '60cc220d47f7e10015fd664b',
        courseCode: 'ENGLISH 3350',
        shortName:
          'English 3350E - THE NINETEENTH-CENTURY NOVEL: AUSTEN TO HARDY',
        courseName:
          'English 3350E - THE NINETEENTH-CENTURY NOVEL: AUSTEN TO HARDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220d47f7e10015fd664b',
      },
      {
        _id: '60cc220e47f7e10015fd664d',
        courseCode: 'ENGLISH 3351',
        shortName: 'English 3351F/G - ROMANTIC REVOLUTIONS',
        courseName: 'English 3351F/G - ROMANTIC REVOLUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220e47f7e10015fd664d',
      },
      {
        _id: '60cc220e47f7e10015fd6650',
        courseCode: 'ENGLISH 3353',
        shortName:
          'English 3353F/G - THE WOMAN QUESTION: NINETEENTH-CENTURY WOMAN WRITERS',
        courseName:
          'English 3353F/G - THE WOMAN QUESTION: NINETEENTH-CENTURY WOMAN WRITERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220e47f7e10015fd6650',
      },
      {
        _id: '60cc220e47f7e10015fd6652',
        courseCode: 'ENGLISH 3355',
        shortName: 'English 3355E - TOPICS IN NINETEENTH-CENTURY LITERATURE',
        courseName: 'English 3355E - TOPICS IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220e47f7e10015fd6652',
      },
      {
        _id: '60cc220e47f7e10015fd6654',
        courseCode: 'ENGLISH 3359',
        shortName: 'English 3359F/G - TOPICS IN NINETEENTH-CENTURY LITERATURE',
        courseName: 'English 3359F/G - TOPICS IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220e47f7e10015fd6654',
      },
      {
        _id: '60cc220f47f7e10015fd6656',
        courseCode: 'ENGLISH 3361',
        shortName:
          'English 3361F/G - SHERLOCK HOLMES AND THE FICTION OF DETECTION',
        courseName:
          'English 3361F/G - SHERLOCK HOLMES AND THE FICTION OF DETECTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220f47f7e10015fd6656',
      },
      {
        _id: '60cc220f47f7e10015fd6658',
        courseCode: 'ENGLISH 3362',
        shortName:
          'English 3362F/G - ENDLESS FORMS: LIFE SCIENCES AND NINETEENTH-CENTURY LITERATURE',
        courseName:
          'English 3362F/G - ENDLESS FORMS: LIFE SCIENCES AND NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220f47f7e10015fd6658',
      },
      {
        _id: '60cc220f47f7e10015fd665a',
        courseCode: 'ENGLISH 3365',
        shortName: 'English 3365F/G - DIALOGUES WITH FRANKENSTEIN (1818)',
        courseName: 'English 3365F/G - DIALOGUES WITH FRANKENSTEIN (1818)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc220f47f7e10015fd665a',
      },
      {
        _id: '60cc221047f7e10015fd665c',
        courseCode: 'ENGLISH 3366',
        shortName: 'English 3366F/G - JANE AUSTEN',
        courseName: 'English 3366F/G - JANE AUSTEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221047f7e10015fd665c',
      },
      {
        _id: '60cc221047f7e10015fd665e',
        courseCode: 'ENGLISH 3368',
        shortName: 'English 3368E - NINETEENTH CENTURY LITERATURE',
        courseName: 'English 3368E - NINETEENTH CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221047f7e10015fd665e',
      },
      {
        _id: '60cc221047f7e10015fd6660',
        courseCode: 'ENGLISH 3369',
        shortName: 'English 3369F/G - TOPICS IN NINETEENTH-CENTURY LITERATURE',
        courseName: 'English 3369F/G - TOPICS IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221047f7e10015fd6660',
      },
      {
        _id: '60cc221147f7e10015fd6662',
        courseCode: 'ENGLISH 3370',
        shortName:
          'English 3370F/G - MODERNISM AND THE BIRTH OF THE AVANT-GARDE',
        courseName:
          'English 3370F/G - MODERNISM AND THE BIRTH OF THE AVANT-GARDE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221147f7e10015fd6662',
      },
      {
        _id: '60cc221147f7e10015fd6664',
        courseCode: 'ENGLISH 3371',
        shortName: 'English 3371F/G - CONTEMPORARY EXPERIMENTAL LITERATURE',
        courseName: 'English 3371F/G - CONTEMPORARY EXPERIMENTAL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221147f7e10015fd6664',
      },
      {
        _id: '60cc221147f7e10015fd6666',
        courseCode: 'ENGLISH 3372',
        shortName: 'English 3372F/G - DRAMA OF THE IRISH LITERARY REVIVAL',
        courseName: 'English 3372F/G - DRAMA OF THE IRISH LITERARY REVIVAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221147f7e10015fd6666',
      },
      {
        _id: '60cc221247f7e10015fd6668',
        courseCode: 'ENGLISH 3373',
        shortName: 'English 3373F/G - THE POETRY OF NOSTALGIA',
        courseName: 'English 3373F/G - THE POETRY OF NOSTALGIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221247f7e10015fd6668',
      },
      {
        _id: '60cc221247f7e10015fd666a',
        courseCode: 'ENGLISH 3374',
        shortName: 'English 3374F/G - DECADENCE AND ITS DISCONTENTS',
        courseName: 'English 3374F/G - DECADENCE AND ITS DISCONTENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221247f7e10015fd666a',
      },
      {
        _id: '60cc221247f7e10015fd666c',
        courseCode: 'ENGLISH 3376',
        shortName:
          'English 3376E - TOPICS IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 3376E - TOPICS IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221247f7e10015fd666c',
      },
      {
        _id: '60cc221247f7e10015fd666e',
        courseCode: 'ENGLISH 3377',
        shortName:
          'English 3377E - TWENTIETH-CENTURY BIRITISH AND IRISH LITERATURE',
        courseName:
          'English 3377E - TWENTIETH-CENTURY BIRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221247f7e10015fd666e',
      },
      {
        _id: '60cc221347f7e10015fd6670',
        courseCode: 'ENGLISH 3378',
        shortName:
          'English 3378F/G - TOPICS IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 3378F/G - TOPICS IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221347f7e10015fd6670',
      },
      {
        _id: '60cc221347f7e10015fd6672',
        courseCode: 'ENGLISH 3379',
        shortName:
          'English 3379F/G - TOPICS IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 3379F/G - TOPICS IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221347f7e10015fd6672',
      },
      {
        _id: '60cc221447f7e10015fd6674',
        courseCode: 'ENGLISH 3440',
        shortName:
          'English 3440F/G - WHAT IS AN AMERICAN? EARLY AMERICAN LITERATURE',
        courseName:
          'English 3440F/G - WHAT IS AN AMERICAN? EARLY AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221447f7e10015fd6674',
      },
      {
        _id: '60cc221447f7e10015fd6676',
        courseCode: 'ENGLISH 3449',
        shortName: 'English 3449F/G - TOPICS IN EARLY AMERICAN LITERATURE',
        courseName: 'English 3449F/G - TOPICS IN EARLY AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221447f7e10015fd6676',
      },
      {
        _id: '60cc221447f7e10015fd6678',
        courseCode: 'ENGLISH 3470',
        shortName: 'English 3470F/G - AMERICAN CULT CLASSICS',
        courseName: 'English 3470F/G - AMERICAN CULT CLASSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221447f7e10015fd6678',
      },
      {
        _id: '60cc221547f7e10015fd667a',
        courseCode: 'ENGLISH 3471',
        shortName:
          'English 3471F/G - BALLOTS AND BULLETS: US LITERATURE AND CIVIL RIGHTS',
        courseName:
          'English 3471F/G - BALLOTS AND BULLETS: US LITERATURE AND CIVIL RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221547f7e10015fd667a',
      },
      {
        _id: '60cc221547f7e10015fd667c',
        courseCode: 'ENGLISH 3479',
        shortName: 'English 3479F/G - TOPICS IN AMERICAN LITERATURE',
        courseName: 'English 3479F/G - TOPICS IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221547f7e10015fd667c',
      },
      {
        _id: '60cc221547f7e10015fd667e',
        courseCode: 'ENGLISH 3480',
        shortName: 'English 3480F/G - READING AMERICA NOW',
        courseName: 'English 3480F/G - READING AMERICA NOW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221547f7e10015fd667e',
      },
      {
        _id: '60cc221647f7e10015fd6680',
        courseCode: 'ENGLISH 3490',
        shortName: 'English 3490F/G - AMERICAN DRAMA',
        courseName: 'English 3490F/G - AMERICAN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221647f7e10015fd6680',
      },
      {
        _id: '60cc221647f7e10015fd6682',
        courseCode: 'ENGLISH 3498',
        shortName: 'English 3498E - TOPICS IN AMERICAN LITERATURE',
        courseName: 'English 3498E - TOPICS IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221647f7e10015fd6682',
      },
      {
        _id: '60cc221647f7e10015fd6684',
        courseCode: 'ENGLISH 3499',
        shortName: 'English 3499F/G - TOPICS IN AMERICAN LITERATURE',
        courseName: 'English 3499F/G - TOPICS IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221647f7e10015fd6684',
      },
      {
        _id: '60cc221747f7e10015fd6686',
        courseCode: 'ENGLISH 3556',
        shortName: 'English 3556E - TWENTIETH-CENTURY DRAMA',
        courseName: 'English 3556E - TWENTIETH-CENTURY DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221747f7e10015fd6686',
      },
      {
        _id: '60cc221747f7e10015fd6688',
        courseCode: 'ENGLISH 3571',
        shortName: 'English 3571F/G - BE/LONGING: GLOBAL LITERATURE IN CANADA',
        courseName: 'English 3571F/G - BE/LONGING: GLOBAL LITERATURE IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221747f7e10015fd6688',
      },
      {
        _id: '60cc221847f7e10015fd668a',
        courseCode: 'ENGLISH 3572',
        shortName: 'English 3572F/G - CANADIAN LITERATURE AND MULTICULTURALISM',
        courseName:
          'English 3572F/G - CANADIAN LITERATURE AND MULTICULTURALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221847f7e10015fd668a',
      },
      {
        _id: '60cc221847f7e10015fd668c',
        courseCode: 'ENGLISH 3579',
        shortName: 'English 3579F/G - TOPICS IN CANADIAN LITERATURE',
        courseName: 'English 3579F/G - TOPICS IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221847f7e10015fd668c',
      },
      {
        _id: '60cc221847f7e10015fd668e',
        courseCode: 'ENGLISH 3580',
        shortName:
          'English 3580F/G - CANADIAN LITERATURE: CREATIVITY AND THE LOCAL',
        courseName:
          'English 3580F/G - CANADIAN LITERATURE: CREATIVITY AND THE LOCAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221847f7e10015fd668e',
      },
      {
        _id: '60cc221947f7e10015fd6690',
        courseCode: 'ENGLISH 3581',
        shortName: 'English 3581F/G - TORONTO: CULTURE AND PERFORMANCE',
        courseName: 'English 3581F/G - TORONTO: CULTURE AND PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221947f7e10015fd6690',
      },
      {
        _id: '60cc221947f7e10015fd6692',
        courseCode: 'ENGLISH 3599',
        shortName: 'English 3599F/G - TOPICS IN CANADIAN LITERATURE',
        courseName: 'English 3599F/G - TOPICS IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221947f7e10015fd6692',
      },
      {
        _id: '60cc221947f7e10015fd6694',
        courseCode: 'ENGLISH 3664',
        shortName: 'English 3664E - AMERICAN LITERATURE',
        courseName: 'English 3664E - AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221947f7e10015fd6694',
      },
      {
        _id: '60cc221947f7e10015fd6696',
        courseCode: 'ENGLISH 3669',
        shortName: 'English 3669E - AMERICAN WOMEN WRITERS:1800 TO PRESENT',
        courseName: 'English 3669E - AMERICAN WOMEN WRITERS:1800 TO PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221947f7e10015fd6696',
      },
      {
        _id: '60cc221a47f7e10015fd6698',
        courseCode: 'ENGLISH 3670',
        shortName: 'English 3670F/G - GLOBAL INDIGENOUS LITERATURES',
        courseName: 'English 3670F/G - GLOBAL INDIGENOUS LITERATURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221a47f7e10015fd6698',
      },
      {
        _id: '60cc221a47f7e10015fd669a',
        courseCode: 'ENGLISH 3671',
        shortName:
          'English 3671F/G - TESTIMONY, TRAUMA AND REVITALIZATION IN INDIGENOUS WRITINGS',
        courseName:
          'English 3671F/G - TESTIMONY, TRAUMA AND REVITALIZATION IN INDIGENOUS WRITINGS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221a47f7e10015fd669a',
      },
      {
        _id: '60cc221a47f7e10015fd669c',
        courseCode: 'ENGLISH 3678',
        shortName:
          'English 3678F/G - CULTURES OF AFRICAN QUEER REPRESENTATIONS',
        courseName:
          'English 3678F/G - CULTURES OF AFRICAN QUEER REPRESENTATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221a47f7e10015fd669c',
      },
      {
        _id: '60cc221a47f7e10015fd669e',
        courseCode: 'ENGLISH 3679',
        shortName: 'English 3679F/G - TOPICS IN POSTCOLONIAL LITERATURE',
        courseName: 'English 3679F/G - TOPICS IN POSTCOLONIAL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221a47f7e10015fd669e',
      },
      {
        _id: '60cc221b47f7e10015fd66a0',
        courseCode: 'ENGLISH 3680',
        shortName: 'English 3680F/G - INDIGENOUS LITERATURES OF TURTLE ISLAND',
        courseName: 'English 3680F/G - INDIGENOUS LITERATURES OF TURTLE ISLAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221b47f7e10015fd66a0',
      },
      {
        _id: '60cc221b47f7e10015fd66a2',
        courseCode: 'ENGLISH 3698',
        shortName: 'English 3698F/G - TOPICS IN GLOBAL LITERATURE',
        courseName: 'English 3698F/G - TOPICS IN GLOBAL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221b47f7e10015fd66a2',
      },
      {
        _id: '60cc221c47f7e10015fd66a4',
        courseCode: 'ENGLISH 3699',
        shortName: 'English 3699F/G - TOPICS IN GLOBAL LITERATURE',
        courseName: 'English 3699F/G - TOPICS IN GLOBAL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221c47f7e10015fd66a4',
      },
      {
        _id: '60cc221c47f7e10015fd66a6',
        courseCode: 'ENGLISH 3700',
        shortName: 'English 3700E - WOMEN & LITERATURE: SPECIAL TOPICS',
        courseName: 'English 3700E - WOMEN & LITERATURE: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221c47f7e10015fd66a6',
      },
      {
        _id: '60cc221c47f7e10015fd66a8',
        courseCode: 'ENGLISH 3701',
        shortName:
          'English 3701F/G - BIBLICAL WOMEN IN CONTEMPORARY ART AND LITERATURE',
        courseName:
          'English 3701F/G - BIBLICAL WOMEN IN CONTEMPORARY ART AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221c47f7e10015fd66a8',
      },
      {
        _id: '60cc221c47f7e10015fd66aa',
        courseCode: 'ENGLISH 3702',
        shortName: 'English 3702E - CONSUMING WOMEN',
        courseName: 'English 3702E - CONSUMING WOMEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221c47f7e10015fd66aa',
      },
      {
        _id: '60cc221d47f7e10015fd66ac',
        courseCode: 'ENGLISH 3715',
        shortName: 'English 3715F/G - WOMEN AND LITERATURE: SPECIAL TOPICS',
        courseName: 'English 3715F/G - WOMEN AND LITERATURE: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221d47f7e10015fd66ac',
      },
      {
        _id: '60cc221d47f7e10015fd66ae',
        courseCode: 'ENGLISH 3725',
        shortName: 'English 3725F/G - THE FAMILY IN LITERATURE',
        courseName: 'English 3725F/G - THE FAMILY IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221d47f7e10015fd66ae',
      },
      {
        _id: '60cc221d47f7e10015fd66b0',
        courseCode: 'ENGLISH 3771',
        shortName: 'English 3771E - GENRES/FORMS/MODES',
        courseName: 'English 3771E - GENRES/FORMS/MODES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221d47f7e10015fd66b0',
      },
      {
        _id: '60cc221d47f7e10015fd66b2',
        courseCode: 'ENGLISH 3774',
        shortName: 'English 3774E - CANADIAN LITERATURE',
        courseName: 'English 3774E - CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221d47f7e10015fd66b2',
      },
      {
        _id: '60cc221e47f7e10015fd66b4',
        courseCode: 'ENGLISH 3775',
        shortName: 'English 3775E - TOPICS IN CANADIAN LITERATURE',
        courseName: 'English 3775E - TOPICS IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221e47f7e10015fd66b4',
      },
      {
        _id: '60cc221e47f7e10015fd66b6',
        courseCode: 'ENGLISH 3776',
        shortName: 'English 3776F/G - CANADIAN DRAMA',
        courseName: 'English 3776F/G - CANADIAN DRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221e47f7e10015fd66b6',
      },
      {
        _id: '60cc221e47f7e10015fd66b8',
        courseCode: 'ENGLISH 3778',
        shortName:
          'English 3778F/G - MODERN DRAMA AND THE THEATRE OF THE ABSURD',
        courseName:
          'English 3778F/G - MODERN DRAMA AND THE THEATRE OF THE ABSURD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221e47f7e10015fd66b8',
      },
      {
        _id: '60cc221f47f7e10015fd66ba',
        courseCode: 'ENGLISH 3825',
        shortName:
          'English 3825E - SPECIAL TOPICS IN ENGLISH & CULTURAL STUDIES',
        courseName:
          'English 3825E - SPECIAL TOPICS IN ENGLISH & CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221f47f7e10015fd66ba',
      },
      {
        _id: '60cc221f47f7e10015fd66bc',
        courseCode: 'ENGLISH 3884',
        shortName: 'English 3884E - POSTCOLONIAL LITERATURE',
        courseName: 'English 3884E - POSTCOLONIAL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221f47f7e10015fd66bc',
      },
      {
        _id: '60cc221f47f7e10015fd66be',
        courseCode: 'ENGLISH 3888',
        shortName: 'English 3888E - ADVANCED STUDIES IN THEORY AND CRITICISM',
        courseName: 'English 3888E - ADVANCED STUDIES IN THEORY AND CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221f47f7e10015fd66be',
      },
      {
        _id: '60cc221f47f7e10015fd66c0',
        courseCode: 'ENGLISH 3890',
        shortName: 'English 3890F/G - WOMEN & LITERATURE: SPECIAL TOPICS',
        courseName: 'English 3890F/G - WOMEN & LITERATURE: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc221f47f7e10015fd66c0',
      },
      {
        _id: '60cc222047f7e10015fd66c2',
        courseCode: 'ENGLISH 3891',
        shortName: 'English 3891F/G - TOPICS IN CULTURAL STUDIES',
        courseName: 'English 3891F/G - TOPICS IN CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222047f7e10015fd66c2',
      },
      {
        _id: '60cc222047f7e10015fd66c4',
        courseCode: 'ENGLISH 3899',
        shortName:
          'English 3899F/G - THE ALICE MUNRO CHAIR IN CREATIVITY: TOPICS IN CREATIVITY',
        courseName:
          'English 3899F/G - THE ALICE MUNRO CHAIR IN CREATIVITY: TOPICS IN CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222047f7e10015fd66c4',
      },
      {
        _id: '60cc222047f7e10015fd66c6',
        courseCode: 'ENGLISH 3900',
        shortName: 'English 3900F/G - SPECIAL TOPICS',
        courseName: 'English 3900F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222047f7e10015fd66c6',
      },
      {
        _id: '60cc222047f7e10015fd66c8',
        courseCode: 'ENGLISH 3910',
        shortName: 'English 3910F/G - SPECIAL TOPICS',
        courseName: 'English 3910F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222047f7e10015fd66c8',
      },
      {
        _id: '60cc222147f7e10015fd66ca',
        courseCode: 'ENGLISH 3911',
        shortName: 'English 3911F/G - SPECIAL TOPICS',
        courseName: 'English 3911F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222147f7e10015fd66ca',
      },
      {
        _id: '60cc222147f7e10015fd66cc',
        courseCode: 'ENGLISH 3913',
        shortName: 'English 3913F/G - SPECIAL TOPICS',
        courseName: 'English 3913F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222147f7e10015fd66cc',
      },
      {
        _id: '60cc222147f7e10015fd66ce',
        courseCode: 'ENGLISH 3914',
        shortName: 'English 3914F/G - SPECIAL TOPICS',
        courseName: 'English 3914F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222147f7e10015fd66ce',
      },
      {
        _id: '60cc222147f7e10015fd66d0',
        courseCode: 'ENGLISH 3915',
        shortName: 'English 3915E - SPECIAL TOPICS',
        courseName: 'English 3915E - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222147f7e10015fd66d0',
      },
      {
        _id: '60cc222247f7e10015fd66d2',
        courseCode: 'ENGLISH 3991',
        shortName: 'English 3991F/G - SEXUALITY & LITERATURE: SPECIAL TOPICS',
        courseName: 'English 3991F/G - SEXUALITY & LITERATURE: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222247f7e10015fd66d2',
      },
      {
        _id: '60cc222247f7e10015fd66d4',
        courseCode: 'ENGLISH 3995',
        shortName: 'English 3995F/G - SEMINAR IN LITERATURE AND CREATIVITY',
        courseName: 'English 3995F/G - SEMINAR IN LITERATURE AND CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222247f7e10015fd66d4',
      },
      {
        _id: '60cc222247f7e10015fd66d6',
        courseCode: 'ENGLISH 3996',
        shortName: 'English 3996F/G - SEMINAR IN LITERATURE AND CREATIVITY',
        courseName: 'English 3996F/G - SEMINAR IN LITERATURE AND CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222247f7e10015fd66d6',
      },
      {
        _id: '60cc222347f7e10015fd66d8',
        courseCode: 'ENGLISH 3998',
        shortName: 'English 3998E - CREATIVE WRITING WORKSHOP',
        courseName: 'English 3998E - CREATIVE WRITING WORKSHOP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222347f7e10015fd66d8',
      },
      {
        _id: '60cc222347f7e10015fd66da',
        courseCode: 'ENGLISH 3999',
        shortName: 'English 3999F/G - CREATIVE WRITING',
        courseName: 'English 3999F/G - CREATIVE WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222347f7e10015fd66da',
      },
      {
        _id: '60cc222447f7e10015fd66dc',
        courseCode: 'ENGLISH 4070',
        shortName: 'English 4070E - DIRECTED READINGS IN LITERATURE',
        courseName: 'English 4070E - DIRECTED READINGS IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222447f7e10015fd66dc',
      },
      {
        _id: '60cc222447f7e10015fd66de',
        courseCode: 'ENGLISH 4080',
        shortName: 'English 4080F/G - DIRECTED READINGS IN LITERATURE',
        courseName: 'English 4080F/G - DIRECTED READINGS IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222447f7e10015fd66de',
      },
      {
        _id: '60cc222447f7e10015fd66e0',
        courseCode: 'ENGLISH 4200',
        shortName: 'English 4200E - SEMINAR IN THEORY AND CRITICISM',
        courseName: 'English 4200E - SEMINAR IN THEORY AND CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222447f7e10015fd66e0',
      },
      {
        _id: '60cc222447f7e10015fd66e2',
        courseCode: 'ENGLISH 4201',
        shortName: 'English 4201F/G - SEMINAR IN THEORY AND CRITICISM',
        courseName: 'English 4201F/G - SEMINAR IN THEORY AND CRITICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222447f7e10015fd66e2',
      },
      {
        _id: '60cc222547f7e10015fd66e4',
        courseCode: 'ENGLISH 4290',
        shortName: 'English 4290F/G - SEMINAR IN THE HISTORY OF THE BOOK',
        courseName: 'English 4290F/G - SEMINAR IN THE HISTORY OF THE BOOK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222547f7e10015fd66e4',
      },
      {
        _id: '60cc222547f7e10015fd66e6',
        courseCode: 'ENGLISH 4310',
        shortName: 'English 4310 - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        courseName:
          'English 4310 - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222547f7e10015fd66e6',
      },
      {
        _id: '60cc222547f7e10015fd66e8',
        courseCode: 'ENGLISH 4311',
        shortName:
          'English 4311E - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        courseName:
          'English 4311E - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222547f7e10015fd66e8',
      },
      {
        _id: '60cc222647f7e10015fd66ea',
        courseCode: 'ENGLISH 4312',
        shortName:
          'English 4312F/G - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        courseName:
          'English 4312F/G - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222647f7e10015fd66ea',
      },
      {
        _id: '60cc222647f7e10015fd66ec',
        courseCode: 'ENGLISH 4313',
        shortName:
          'English 4313A/B - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        courseName:
          'English 4313A/B - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222647f7e10015fd66ec',
      },
      {
        _id: '60cc222647f7e10015fd66ee',
        courseCode: 'ENGLISH 4314',
        shortName:
          'English 4314F/G - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        courseName:
          'English 4314F/G - SEMINAR IN MEDIEVAL LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222647f7e10015fd66ee',
      },
      {
        _id: '60cc222747f7e10015fd66f0',
        courseCode: 'ENGLISH 4320',
        shortName: 'English 4320F/G - SEMINAR IN RENAISSANCE LITERATURE',
        courseName: 'English 4320F/G - SEMINAR IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222747f7e10015fd66f0',
      },
      {
        _id: '60cc222747f7e10015fd66f2',
        courseCode: 'ENGLISH 4321',
        shortName: 'English 4321F/G - SEMINAR IN RENAISSANCE LITERATURE',
        courseName: 'English 4321F/G - SEMINAR IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222747f7e10015fd66f2',
      },
      {
        _id: '60cc222847f7e10015fd66f4',
        courseCode: 'ENGLISH 4329',
        shortName: 'English 4329E - SEMINAR IN RENAISSANCE LITERATURE',
        courseName: 'English 4329E - SEMINAR IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222847f7e10015fd66f4',
      },
      {
        _id: '60cc222947f7e10015fd66f6',
        courseCode: 'ENGLISH 4330',
        shortName: 'English 4330F/G - SEMINAR IN RENAISSANCE LITERATURE',
        courseName: 'English 4330F/G - SEMINAR IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222947f7e10015fd66f6',
      },
      {
        _id: '60cc222947f7e10015fd66f8',
        courseCode: 'ENGLISH 4339',
        shortName: 'English 4339E - SEMINAR IN RENAISSANCE LITERATURE',
        courseName: 'English 4339E - SEMINAR IN RENAISSANCE LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222947f7e10015fd66f8',
      },
      {
        _id: '60cc222a47f7e10015fd66fa',
        courseCode: 'ENGLISH 4340',
        shortName:
          'English 4340F/G - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 4340F/G - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222a47f7e10015fd66fa',
      },
      {
        _id: '60cc222a47f7e10015fd66fc',
        courseCode: 'ENGLISH 4341',
        shortName:
          'English 4341F/G - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 4341F/G - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222a47f7e10015fd66fc',
      },
      {
        _id: '60cc222a47f7e10015fd66fe',
        courseCode: 'ENGLISH 4342',
        shortName:
          'English 4342F/G - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 4342F/G - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222a47f7e10015fd66fe',
      },
      {
        _id: '60cc222b47f7e10015fd6700',
        courseCode: 'ENGLISH 4349',
        shortName:
          'English 4349E - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        courseName:
          'English 4349E - SEMINAR IN RESTORATION AND EIGHTEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222b47f7e10015fd6700',
      },
      {
        _id: '60cc222b47f7e10015fd6702',
        courseCode: 'ENGLISH 4350',
        shortName: 'English 4350F/G - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        courseName:
          'English 4350F/G - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222b47f7e10015fd6702',
      },
      {
        _id: '60cc222c47f7e10015fd6704',
        courseCode: 'ENGLISH 4351',
        shortName: 'English 4351F/G - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        courseName:
          'English 4351F/G - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222c47f7e10015fd6704',
      },
      {
        _id: '60cc222d47f7e10015fd6706',
        courseCode: 'ENGLISH 4359',
        shortName: 'English 4359E - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        courseName: 'English 4359E - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222d47f7e10015fd6706',
      },
      {
        _id: '60cc222d47f7e10015fd6708',
        courseCode: 'ENGLISH 4360',
        shortName: 'English 4360F/G - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        courseName:
          'English 4360F/G - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222d47f7e10015fd6708',
      },
      {
        _id: '60cc222e47f7e10015fd670a',
        courseCode: 'ENGLISH 4369',
        shortName: 'English 4369E - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        courseName: 'English 4369E - SEMINAR IN NINETEENTH-CENTURY LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222e47f7e10015fd670a',
      },
      {
        _id: '60cc222e47f7e10015fd670c',
        courseCode: 'ENGLISH 4370',
        shortName:
          'English 4370F/G - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4370F/G - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222e47f7e10015fd670c',
      },
      {
        _id: '60cc222f47f7e10015fd670e',
        courseCode: 'ENGLISH 4371',
        shortName:
          'English 4371F/G - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4371F/G - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222f47f7e10015fd670e',
      },
      {
        _id: '60cc222f47f7e10015fd6710',
        courseCode: 'ENGLISH 4372',
        shortName:
          'English 4372F/G - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4372F/G - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc222f47f7e10015fd6710',
      },
      {
        _id: '60cc223047f7e10015fd6712',
        courseCode: 'ENGLISH 4379',
        shortName:
          'English 4379E - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4379E - SEMINAR IN TWENTIETH-CENTURY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223047f7e10015fd6712',
      },
      {
        _id: '60cc223147f7e10015fd6714',
        courseCode: 'ENGLISH 4380',
        shortName:
          'English 4380F/G - SEMINAR IN CONTEMPORARY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4380F/G - SEMINAR IN CONTEMPORARY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223147f7e10015fd6714',
      },
      {
        _id: '60cc223147f7e10015fd6716',
        courseCode: 'ENGLISH 4381',
        shortName:
          'English 4381F/G - SEMINAR: CONTEMPORARY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4381F/G - SEMINAR: CONTEMPORARY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223147f7e10015fd6716',
      },
      {
        _id: '60cc223147f7e10015fd6718',
        courseCode: 'ENGLISH 4382',
        shortName:
          'English 4382F/G - SEMINAR IN CONTEMPORARY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4382F/G - SEMINAR IN CONTEMPORARY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223147f7e10015fd6718',
      },
      {
        _id: '60cc223147f7e10015fd671a',
        courseCode: 'ENGLISH 4389',
        shortName:
          'English 4389E - SEMINAR IN CONTEMPORARY BRITISH AND IRISH LITERATURE',
        courseName:
          'English 4389E - SEMINAR IN CONTEMPORARY BRITISH AND IRISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223147f7e10015fd671a',
      },
      {
        _id: '60cc223247f7e10015fd671c',
        courseCode: 'ENGLISH 4450',
        shortName: 'English 4450F/G - SEMINAR IN EARLY AMERICAN LITERATURE',
        courseName: 'English 4450F/G - SEMINAR IN EARLY AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223247f7e10015fd671c',
      },
      {
        _id: '60cc223247f7e10015fd671e',
        courseCode: 'ENGLISH 4459',
        shortName: 'English 4459E - SEMINAR IN EARLY AMERICAN LITERATURE',
        courseName: 'English 4459E - SEMINAR IN EARLY AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223247f7e10015fd671e',
      },
      {
        _id: '60cc223247f7e10015fd6720',
        courseCode: 'ENGLISH 4470',
        shortName: 'English 4470F/G - SEMINAR IN AMERICAN LITERATURE',
        courseName: 'English 4470F/G - SEMINAR IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223247f7e10015fd6720',
      },
      {
        _id: '60cc223247f7e10015fd6722',
        courseCode: 'ENGLISH 4471',
        shortName: 'English 4471F/G - SEMINAR IN AMERICAN LITERATURE',
        courseName: 'English 4471F/G - SEMINAR IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223247f7e10015fd6722',
      },
      {
        _id: '60cc223347f7e10015fd6724',
        courseCode: 'ENGLISH 4472',
        shortName: 'English 4472F/G - SEMINAR IN AMERICAN LITERATURE',
        courseName: 'English 4472F/G - SEMINAR IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223347f7e10015fd6724',
      },
      {
        _id: '60cc223447f7e10015fd6726',
        courseCode: 'ENGLISH 4479',
        shortName: 'English 4479E - SEMINAR IN AMERICAN LITERATURE',
        courseName: 'English 4479E - SEMINAR IN AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223447f7e10015fd6726',
      },
      {
        _id: '60cc223447f7e10015fd6728',
        courseCode: 'ENGLISH 4550',
        shortName:
          'English 4550F/G - SEMINAR IN PRE-CONFEDERATION CANADIAN LITERATURE',
        courseName:
          'English 4550F/G - SEMINAR IN PRE-CONFEDERATION CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223447f7e10015fd6728',
      },
      {
        _id: '60cc223447f7e10015fd672a',
        courseCode: 'ENGLISH 4559',
        shortName:
          'English 4559E - SEMINAR IN PRE-CONFEDERATION CANADIAN LITERATURE',
        courseName:
          'English 4559E - SEMINAR IN PRE-CONFEDERATION CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223447f7e10015fd672a',
      },
      {
        _id: '60cc223447f7e10015fd672c',
        courseCode: 'ENGLISH 4570',
        shortName: 'English 4570F/G - SEMINAR IN CANADIAN LITERATURE',
        courseName: 'English 4570F/G - SEMINAR IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223447f7e10015fd672c',
      },
      {
        _id: '60cc223547f7e10015fd672e',
        courseCode: 'ENGLISH 4571',
        shortName: 'English 4571F/G - SEMINAR IN CANADIAN LITERATURE',
        courseName: 'English 4571F/G - SEMINAR IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223547f7e10015fd672e',
      },
      {
        _id: '60cc223547f7e10015fd6730',
        courseCode: 'ENGLISH 4572',
        shortName: 'English 4572F/G - SEMINAR IN CANADIAN LITERATURE',
        courseName: 'English 4572F/G - SEMINAR IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223547f7e10015fd6730',
      },
      {
        _id: '60cc223647f7e10015fd6732',
        courseCode: 'ENGLISH 4579',
        shortName: 'English 4579E - SEMINAR IN CANADIAN LITERATURE',
        courseName: 'English 4579E - SEMINAR IN CANADIAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223647f7e10015fd6732',
      },
      {
        _id: '60cc223647f7e10015fd6734',
        courseCode: 'ENGLISH 4650',
        shortName:
          'English 4650F/G - SEMINAR IN EARLY GLOBAL LITERATURE IN ENGLISH',
        courseName:
          'English 4650F/G - SEMINAR IN EARLY GLOBAL LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223647f7e10015fd6734',
      },
      {
        _id: '60cc223747f7e10015fd6736',
        courseCode: 'ENGLISH 4659',
        shortName:
          'English 4659E - SEMINAR IN EARLY GLOBAL LITERATURE IN ENGLISH',
        courseName:
          'English 4659E - SEMINAR IN EARLY GLOBAL LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223747f7e10015fd6736',
      },
      {
        _id: '60cc223747f7e10015fd6738',
        courseCode: 'ENGLISH 4670',
        shortName: 'English 4670F/G - SEMINAR IN GLOBAL LITERATURE IN ENGLISH',
        courseName: 'English 4670F/G - SEMINAR IN GLOBAL LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223747f7e10015fd6738',
      },
      {
        _id: '60cc223747f7e10015fd673a',
        courseCode: 'ENGLISH 4671',
        shortName: 'English 4671F/G - SEMINAR IN GLOBAL LITERATURE IN ENGLISH',
        courseName: 'English 4671F/G - SEMINAR IN GLOBAL LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223747f7e10015fd673a',
      },
      {
        _id: '60cc223747f7e10015fd673c',
        courseCode: 'ENGLISH 4679',
        shortName: 'English 4679E - SEMINAR IN GLOBAL LITERATURE IN ENGLISH',
        courseName: 'English 4679E - SEMINAR IN GLOBAL LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223747f7e10015fd673c',
      },
      {
        _id: '60cc223847f7e10015fd673e',
        courseCode: 'ENGLISH 4770',
        shortName: 'English 4770E - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4770E - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223847f7e10015fd673e',
      },
      {
        _id: '60cc223847f7e10015fd6740',
        courseCode: 'ENGLISH 4771',
        shortName: 'English 4771F/G - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4771F/G - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223847f7e10015fd6740',
      },
      {
        _id: '60cc223847f7e10015fd6742',
        courseCode: 'ENGLISH 4781',
        shortName: 'English 4781F/G - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4781F/G - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223847f7e10015fd6742',
      },
      {
        _id: '60cc223947f7e10015fd6744',
        courseCode: 'ENGLISH 4791',
        shortName: 'English 4791F/G - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4791F/G - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223947f7e10015fd6744',
      },
      {
        _id: '60cc223947f7e10015fd6746',
        courseCode: 'ENGLISH 4850',
        shortName: 'English 4850E - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4850E - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223947f7e10015fd6746',
      },
      {
        _id: '60cc223947f7e10015fd6748',
        courseCode: 'ENGLISH 4851',
        shortName: 'English 4851F/G - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4851F/G - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223947f7e10015fd6748',
      },
      {
        _id: '60cc223a47f7e10015fd674a',
        courseCode: 'ENGLISH 4870',
        shortName: 'English 4870E - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4870E - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223a47f7e10015fd674a',
      },
      {
        _id: '60cc223a47f7e10015fd674c',
        courseCode: 'ENGLISH 4871',
        shortName: 'English 4871F/G - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4871F/G - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223a47f7e10015fd674c',
      },
      {
        _id: '60cc223b47f7e10015fd674e',
        courseCode: 'ENGLISH 4880',
        shortName: 'English 4880E - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4880E - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223b47f7e10015fd674e',
      },
      {
        _id: '60cc223b47f7e10015fd6750',
        courseCode: 'ENGLISH 4881',
        shortName: 'English 4881F/G - SEMINAR IN LITERARY STUDIES',
        courseName: 'English 4881F/G - SEMINAR IN LITERARY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223b47f7e10015fd6750',
      },
      {
        _id: '60cc223c47f7e10015fd6752',
        courseCode: 'ENGLISH 4899',
        shortName:
          'English 4899F/G - THE ALICE MUNRO CHAIR IN CREATIVITY SEMINAR: CREATIVE WRITING WORKSHOP',
        courseName:
          'English 4899F/G - THE ALICE MUNRO CHAIR IN CREATIVITY SEMINAR: CREATIVE WRITING WORKSHOP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223c47f7e10015fd6752',
      },
      {
        _id: '60cc223c47f7e10015fd6754',
        courseCode: 'ENGLISH 4998',
        shortName: 'English 4998E - CREATIVE WRITING SEMINAR',
        courseName: 'English 4998E - CREATIVE WRITING SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223c47f7e10015fd6754',
      },
      {
        _id: '60cc223d47f7e10015fd6756',
        courseCode: 'ENGLISH 4999',
        shortName: 'English 4999E - THESIS',
        courseName: 'English 4999E - THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223d47f7e10015fd6756',
      },
      {
        _id: '60cc223d47f7e10015fd6758',
        courseCode: 'ENVIRSCI 1021',
        shortName:
          'Environmental Science 1021F/G - ENVIRONMENTAL SCIENCE AND SUSTAINABILITY',
        courseName:
          'Environmental Science 1021F/G - ENVIRONMENTAL SCIENCE AND SUSTAINABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223d47f7e10015fd6758',
      },
      {
        _id: '60cc223e47f7e10015fd675a',
        courseCode: 'ENVIRSCI 2200',
        shortName:
          'Environmental Science 2200Z - ENVIRONMENT AND SUSTAINABILITY ON THE ISLAND OF\nIRELAND',
        courseName:
          'Environmental Science 2200Z - ENVIRONMENT AND SUSTAINABILITY ON THE ISLAND OF\nIRELAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223e47f7e10015fd675a',
      },
      {
        _id: '60cc223e47f7e10015fd675c',
        courseCode: 'ENVIRSCI 3300',
        shortName:
          'Environmental Science 3300F/G - NATURAL SCIENCE OF ENVIRONMENTAL PROBLEMS',
        courseName:
          'Environmental Science 3300F/G - NATURAL SCIENCE OF ENVIRONMENTAL PROBLEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223e47f7e10015fd675c',
      },
      {
        _id: '60cc223e47f7e10015fd675e',
        courseCode: 'ENVIRSCI 3350',
        shortName:
          'Environmental Science 3350F/G - RESEARCH TECHNIQUES IN ENVIRONMENTAL SCIENCE',
        courseName:
          'Environmental Science 3350F/G - RESEARCH TECHNIQUES IN ENVIRONMENTAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223e47f7e10015fd675e',
      },
      {
        _id: '60cc223e47f7e10015fd6760',
        courseCode: 'ENVIRSCI 4949',
        shortName:
          'Environmental Science 4949F/G - SELECTED TOPICS IN ENVIRONMENTAL SCIENCE',
        courseName:
          'Environmental Science 4949F/G - SELECTED TOPICS IN ENVIRONMENTAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223e47f7e10015fd6760',
      },
      {
        _id: '60cc223f47f7e10015fd6762',
        courseCode: 'ENVIRSCI 4970',
        shortName:
          'Environmental Science 4970F/G - INDEPENDENT STUDY IN ENVIRONMENTAL SCIENCE',
        courseName:
          'Environmental Science 4970F/G - INDEPENDENT STUDY IN ENVIRONMENTAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223f47f7e10015fd6762',
      },
      {
        _id: '60cc223f47f7e10015fd6764',
        courseCode: 'ENVIRSCI 4999',
        shortName: 'Environmental Science 4999E - HONOURS RESEARCH PROJECT',
        courseName: 'Environmental Science 4999E - HONOURS RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223f47f7e10015fd6764',
      },
      {
        _id: '60cc223f47f7e10015fd6766',
        courseCode: 'EPID 2200',
        shortName: 'Epidemiology 2200A/B - INTRODUCTION TO EPIDEMIOLOGY',
        courseName: 'Epidemiology 2200A/B - INTRODUCTION TO EPIDEMIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223f47f7e10015fd6766',
      },
      {
        _id: '60cc223f47f7e10015fd6768',
        courseCode: 'EPID 3200',
        shortName: 'Epidemiology 3200A - EPIDEMIOLOGY II',
        courseName: 'Epidemiology 3200A - EPIDEMIOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc223f47f7e10015fd6768',
      },
      {
        _id: '60cc224047f7e10015fd676a',
        courseCode: 'EPID 3210',
        shortName: 'Epidemiology 3210B - INTERMEDIATE EPIDEMIOLOGY',
        courseName: 'Epidemiology 3210B - INTERMEDIATE EPIDEMIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224047f7e10015fd676a',
      },
      {
        _id: '60cc224047f7e10015fd676c',
        courseCode: 'EPID 3315',
        shortName: 'Epidemiology 3315B - EPIDEMIOLOGY OF MAJOR DISEASES',
        courseName: 'Epidemiology 3315B - EPIDEMIOLOGY OF MAJOR DISEASES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224047f7e10015fd676c',
      },
      {
        _id: '60cc224047f7e10015fd676e',
        courseCode: 'EPID 3330',
        shortName: 'Epidemiology 3330F/G - SYSTEMIC REVIEWS AND META-ANALYSIS',
        courseName: 'Epidemiology 3330F/G - SYSTEMIC REVIEWS AND META-ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224047f7e10015fd676e',
      },
      {
        _id: '60cc224147f7e10015fd6770',
        courseCode: 'EPID 4310',
        shortName: 'Epidemiology 4310A - PUBLIC HEALTH',
        courseName: 'Epidemiology 4310A - PUBLIC HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224147f7e10015fd6770',
      },
      {
        _id: '60cc224147f7e10015fd6772',
        courseCode: 'EPID 4320',
        shortName: 'Epidemiology 4320A - CLINICAL EPIDEMIOLOGY',
        courseName: 'Epidemiology 4320A - CLINICAL EPIDEMIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224147f7e10015fd6772',
      },
      {
        _id: '60cc224147f7e10015fd6774',
        courseCode: 'EPID 4500',
        shortName: 'Epidemiology 4500B - HEALTH SERVICES RESEARCH METHODS',
        courseName: 'Epidemiology 4500B - HEALTH SERVICES RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224147f7e10015fd6774',
      },
      {
        _id: '60cc224147f7e10015fd6776',
        courseCode: 'EPID 4600',
        shortName: 'Epidemiology 4600A - INTRODUCTION TO HEALTH ECONOMICS',
        courseName: 'Epidemiology 4600A - INTRODUCTION TO HEALTH ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224147f7e10015fd6776',
      },
      {
        _id: '60cc224247f7e10015fd6778',
        courseCode: 'EPID 4615',
        shortName: 'Epidemiology 4615B - HEALTH ECONOMIC EVALUATION',
        courseName: 'Epidemiology 4615B - HEALTH ECONOMIC EVALUATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224247f7e10015fd6778',
      },
      {
        _id: '60cc224247f7e10015fd677a',
        courseCode: 'EPID 4715',
        shortName: 'Epidemiology 4715B - DESIGN & ANALYSIS OF CLINICAL TRIALS',
        courseName: 'Epidemiology 4715B - DESIGN & ANALYSIS OF CLINICAL TRIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224247f7e10015fd677a',
      },
      {
        _id: '60cc224247f7e10015fd677c',
        courseCode: 'EPIDEMIO 4900',
        shortName:
          'Epidemiology and Biostatistics 4900E - RESEARCH PROJECT AND SEMINAR',
        courseName:
          'Epidemiology and Biostatistics 4900E - RESEARCH PROJECT AND SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224247f7e10015fd677c',
      },
      {
        _id: '60cc224247f7e10015fd677e',
        courseCode: 'FIMS 3999',
        shortName:
          'Faculty of Information and Media Studies 3999A/B/Y - INTERNSHIP',
        courseName:
          'Faculty of Information and Media Studies 3999A/B/Y - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224247f7e10015fd677e',
      },
      {
        _id: '60cc224347f7e10015fd6780',
        courseCode: 'FAMLYSTU 1010',
        shortName:
          'Family Studies and Human Development 1010A/B - INTRODUCTION TO FAMILIES IN CANADA',
        courseName:
          'Family Studies and Human Development 1010A/B - INTRODUCTION TO FAMILIES IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224347f7e10015fd6780',
      },
      {
        _id: '60cc224347f7e10015fd6782',
        courseCode: 'FAMLYSTU 1015',
        shortName:
          'Family Studies and Human Development 1015A/B - CLOSE RELATIONSHIPS',
        courseName:
          'Family Studies and Human Development 1015A/B - CLOSE RELATIONSHIPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224347f7e10015fd6782',
      },
      {
        _id: '60cc224347f7e10015fd6784',
        courseCode: 'FAMLYSTU 1020',
        shortName:
          'Family Studies and Human Development 1020 - INTRODUCTION TO FAMILY STUDIES',
        courseName:
          'Family Studies and Human Development 1020 - INTRODUCTION TO FAMILY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224347f7e10015fd6784',
      },
      {
        _id: '60cc224447f7e10015fd6786',
        courseCode: 'FAMLYSTU 2220',
        shortName:
          'Family Studies and Human Development 2220A/B - IMMIGRANT AND REFUGEE FAMILIES',
        courseName:
          'Family Studies and Human Development 2220A/B - IMMIGRANT AND REFUGEE FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224447f7e10015fd6786',
      },
      {
        _id: '60cc224447f7e10015fd6788',
        courseCode: 'FAMLYSTU 2226',
        shortName: 'Family Studies and Human Development 2226A/B - PARENTING',
        courseName: 'Family Studies and Human Development 2226A/B - PARENTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224447f7e10015fd6788',
      },
      {
        _id: '60cc224447f7e10015fd678a',
        courseCode: 'FAMLYSTU 2235',
        shortName:
          'Family Studies and Human Development 2235 - INTRODUCTION TO COUNSELLING',
        courseName:
          'Family Studies and Human Development 2235 - INTRODUCTION TO COUNSELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224447f7e10015fd678a',
      },
      {
        _id: '60cc224447f7e10015fd678c',
        courseCode: 'FAMLYSTU 2240',
        shortName:
          'Family Studies and Human Development 2240A/B - SPECIAL TOPICS IN FAMILY STUDIES',
        courseName:
          'Family Studies and Human Development 2240A/B - SPECIAL TOPICS IN FAMILY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224447f7e10015fd678c',
      },
      {
        _id: '60cc224547f7e10015fd678e',
        courseCode: 'FAMLYSTU 2250',
        shortName:
          'Family Studies and Human Development 2250A/B - FAMILY POLICY IN CANADA',
        courseName:
          'Family Studies and Human Development 2250A/B - FAMILY POLICY IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224547f7e10015fd678e',
      },
      {
        _id: '60cc224547f7e10015fd6790',
        courseCode: 'FAMLYSTU 2252',
        shortName:
          'Family Studies and Human Development 2252A/B - COMPARATIVE FAMILY POLICY',
        courseName:
          'Family Studies and Human Development 2252A/B - COMPARATIVE FAMILY POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224547f7e10015fd6790',
      },
      {
        _id: '60cc224547f7e10015fd6792',
        courseCode: 'FAMLYSTU 2260',
        shortName:
          'Family Studies and Human Development 2260A/B - MIDDLE CHILDHOOD AND ADOLESCENCE',
        courseName:
          'Family Studies and Human Development 2260A/B - MIDDLE CHILDHOOD AND ADOLESCENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224547f7e10015fd6792',
      },
      {
        _id: '60cc224547f7e10015fd6794',
        courseCode: 'FAMLYSTU 2265',
        shortName:
          'Family Studies and Human Development 2265A/B - MIDLIFE AND THE ELDER YEARS',
        courseName:
          'Family Studies and Human Development 2265A/B - MIDLIFE AND THE ELDER YEARS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224547f7e10015fd6794',
      },
      {
        _id: '60cc224647f7e10015fd6796',
        courseCode: 'FAMLYSTU 2300',
        shortName:
          'Family Studies and Human Development 2300F/G - UNDERSTANDING FAMILY RESEARCH',
        courseName:
          'Family Studies and Human Development 2300F/G - UNDERSTANDING FAMILY RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224647f7e10015fd6796',
      },
      {
        _id: '60cc224647f7e10015fd6798',
        courseCode: 'FAMLYSTU 3230',
        shortName:
          'Family Studies and Human Development 3230A/B - QUALITATIVE RESEARCH IN FAMILY STUDIES',
        courseName:
          'Family Studies and Human Development 3230A/B - QUALITATIVE RESEARCH IN FAMILY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224647f7e10015fd6798',
      },
      {
        _id: '60cc224647f7e10015fd679a',
        courseCode: 'FAMLYSTU 3300',
        shortName:
          'Family Studies and Human Development 3300A/B - FAMILY LIFE EDUCATION',
        courseName:
          'Family Studies and Human Development 3300A/B - FAMILY LIFE EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224647f7e10015fd679a',
      },
      {
        _id: '60cc224747f7e10015fd679c',
        courseCode: 'FAMLYSTU 3305',
        shortName:
          'Family Studies and Human Development 3305F/G - FAMILY NEEDS AND RESOURCES',
        courseName:
          'Family Studies and Human Development 3305F/G - FAMILY NEEDS AND RESOURCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224747f7e10015fd679c',
      },
      {
        _id: '60cc224747f7e10015fd679e',
        courseCode: 'FAMLYSTU 3310',
        shortName:
          'Family Studies and Human Development 3310A/B - WORK/LIFE BALANCE: CHOICES AND CHALLENGES',
        courseName:
          'Family Studies and Human Development 3310A/B - WORK/LIFE BALANCE: CHOICES AND CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224747f7e10015fd679e',
      },
      {
        _id: '60cc224747f7e10015fd67a0',
        courseCode: 'FAMLYSTU 3320',
        shortName:
          'Family Studies and Human Development 3320A/B - FAMILY PRACTICES IN A GLOBAL WORLD',
        courseName:
          'Family Studies and Human Development 3320A/B - FAMILY PRACTICES IN A GLOBAL WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224747f7e10015fd67a0',
      },
      {
        _id: '60cc224747f7e10015fd67a2',
        courseCode: 'FAMLYSTU 3325',
        shortName:
          'Family Studies and Human Development 3325A/B - DIVERSITY AND THE CANADIAN FAMILY',
        courseName:
          'Family Studies and Human Development 3325A/B - DIVERSITY AND THE CANADIAN FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224747f7e10015fd67a2',
      },
      {
        _id: '60cc224847f7e10015fd67a4',
        courseCode: 'FAMLYSTU 3340',
        shortName:
          'Family Studies and Human Development 3340A/B - FAMLY RESILIENCE',
        courseName:
          'Family Studies and Human Development 3340A/B - FAMLY RESILIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224847f7e10015fd67a4',
      },
      {
        _id: '60cc224847f7e10015fd67a6',
        courseCode: 'FAMLYSTU 3345',
        shortName:
          'Family Studies and Human Development 3345A/B - FAMILY VIOLENCE',
        courseName:
          'Family Studies and Human Development 3345A/B - FAMILY VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224847f7e10015fd67a6',
      },
      {
        _id: '60cc224847f7e10015fd67a8',
        courseCode: 'FAMLYSTU 3350',
        shortName:
          'Family Studies and Human Development 3350A/B - SPECIAL TOPICS IN FAMILY STUDIES',
        courseName:
          'Family Studies and Human Development 3350A/B - SPECIAL TOPICS IN FAMILY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224847f7e10015fd67a8',
      },
      {
        _id: '60cc224847f7e10015fd67aa',
        courseCode: 'FAMLYSTU 4220',
        shortName:
          'Family Studies and Human Development 4220A/B - THEORETICAL PERSPECTIVES IN FAMILY STUDIES RESEARCH',
        courseName:
          'Family Studies and Human Development 4220A/B - THEORETICAL PERSPECTIVES IN FAMILY STUDIES RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224847f7e10015fd67aa',
      },
      {
        _id: '60cc224947f7e10015fd67ac',
        courseCode: 'FAMLYSTU 4402',
        shortName:
          'Family Studies and Human Development 4402A/B - SELECTED TOPICS IN FAMILY STUDIES',
        courseName:
          'Family Studies and Human Development 4402A/B - SELECTED TOPICS IN FAMILY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224947f7e10015fd67ac',
      },
      {
        _id: '60cc224947f7e10015fd67ae',
        courseCode: 'FAMLYSTU 4403',
        shortName:
          'Family Studies and Human Development 4403 - PRACTICUM IN FAMILY STUDIES AND HUMAN DEVELOPMENT',
        courseName:
          'Family Studies and Human Development 4403 - PRACTICUM IN FAMILY STUDIES AND HUMAN DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224947f7e10015fd67ae',
      },
      {
        _id: '60cc224947f7e10015fd67b0',
        courseCode: 'FAMLYSTU 4405',
        shortName:
          'Family Studies and Human Development 4405E - HONOURS THESIS',
        courseName:
          'Family Studies and Human Development 4405E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224947f7e10015fd67b0',
      },
      {
        _id: '60cc224a47f7e10015fd67b4',
        courseCode: 'FAMLYSTU 4455',
        shortName:
          'Family Studies and Human Development 4455A/B - INDEPENDENT STUDY',
        courseName:
          'Family Studies and Human Development 4455A/B - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224a47f7e10015fd67b4',
      },
      {
        _id: '60cc224a47f7e10015fd67b6',
        courseCode: 'FILM 1020',
        shortName: 'Film Studies 1020E - AN INTRODUCTION TO FILM',
        courseName: 'Film Studies 1020E - AN INTRODUCTION TO FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224a47f7e10015fd67b6',
      },
      {
        _id: '60cc224a47f7e10015fd67b8',
        courseCode: 'FILM 1022',
        shortName: 'Film Studies 1022 - INTRODUCTION TO FILM STUDIES',
        courseName: 'Film Studies 1022 - INTRODUCTION TO FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224a47f7e10015fd67b8',
      },
      {
        _id: '60cc224b47f7e10015fd67ba',
        courseCode: 'FILM 2153',
        shortName: 'Film Studies 2153A/B - AMERICAN TELEVISION AND CULTURE',
        courseName: 'Film Studies 2153A/B - AMERICAN TELEVISION AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224b47f7e10015fd67ba',
      },
      {
        _id: '60cc224b47f7e10015fd67bc',
        courseCode: 'FILM 2158',
        shortName: 'Film Studies 2158F/G - CONTEMPORARY CANADIAN CINEMA',
        courseName: 'Film Studies 2158F/G - CONTEMPORARY CANADIAN CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224b47f7e10015fd67bc',
      },
      {
        _id: '60cc224b47f7e10015fd67be',
        courseCode: 'FILM 2159',
        shortName: 'Film Studies 2159A/B - DISNEY',
        courseName: 'Film Studies 2159A/B - DISNEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224b47f7e10015fd67be',
      },
      {
        _id: '60cc224b47f7e10015fd67c0',
        courseCode: 'FILM 2160',
        shortName:
          'Film Studies 2160F/G - MINISTRY OF FEAR: MEDIA AND PROPAGANDA IN THE THIRD REICH',
        courseName:
          'Film Studies 2160F/G - MINISTRY OF FEAR: MEDIA AND PROPAGANDA IN THE THIRD REICH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224b47f7e10015fd67c0',
      },
      {
        _id: '60cc224c47f7e10015fd67c2',
        courseCode: 'FILM 2162',
        shortName: 'Film Studies 2162A/B - CINEMAS OF DISASTER',
        courseName: 'Film Studies 2162A/B - CINEMAS OF DISASTER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224c47f7e10015fd67c2',
      },
      {
        _id: '60cc224c47f7e10015fd67c4',
        courseCode: 'FILM 2164',
        shortName: 'Film Studies 2164A/B - ANIMATION/ANIME',
        courseName: 'Film Studies 2164A/B - ANIMATION/ANIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224c47f7e10015fd67c4',
      },
      {
        _id: '60cc224c47f7e10015fd67c6',
        courseCode: 'FILM 2166',
        shortName: 'Film Studies 2166A/B - ZOMBIE FILM',
        courseName: 'Film Studies 2166A/B - ZOMBIE FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224c47f7e10015fd67c6',
      },
      {
        _id: '60cc224c47f7e10015fd67c8',
        courseCode: 'FILM 2190',
        shortName: 'Film Studies 2190E - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2190E - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224c47f7e10015fd67c8',
      },
      {
        _id: '60cc224d47f7e10015fd67ca',
        courseCode: 'FILM 2191',
        shortName: 'Film Studies 2191F/G - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2191F/G - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224d47f7e10015fd67ca',
      },
      {
        _id: '60cc224d47f7e10015fd67cc',
        courseCode: 'FILM 2192',
        shortName: 'Film Studies 2192F/G - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2192F/G - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224d47f7e10015fd67cc',
      },
      {
        _id: '60cc224d47f7e10015fd67ce',
        courseCode: 'FILM 2193',
        shortName: 'Film Studies 2193 - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2193 - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224d47f7e10015fd67ce',
      },
      {
        _id: '60cc224d47f7e10015fd67d0',
        courseCode: 'FILM 2194',
        shortName: 'Film Studies 2194A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2194A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224d47f7e10015fd67d0',
      },
      {
        _id: '60cc224e47f7e10015fd67d2',
        courseCode: 'FILM 2195',
        shortName: 'Film Studies 2195A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2195A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224e47f7e10015fd67d2',
      },
      {
        _id: '60cc224e47f7e10015fd67d4',
        courseCode: 'FILM 2196',
        shortName: 'Film Studies 2196A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2196A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224e47f7e10015fd67d4',
      },
      {
        _id: '60cc224e47f7e10015fd67d6',
        courseCode: 'FILM 2197',
        shortName: 'Film Studies 2197A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2197A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224e47f7e10015fd67d6',
      },
      {
        _id: '60cc224e47f7e10015fd67d8',
        courseCode: 'FILM 2198',
        shortName: 'Film Studies 2198A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 2198A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224e47f7e10015fd67d8',
      },
      {
        _id: '60cc224e47f7e10015fd67da',
        courseCode: 'FILM 2230',
        shortName:
          'Film Studies 2230F/G - CRITICAL READING AND WRITING IN FILM STUDIES',
        courseName:
          'Film Studies 2230F/G - CRITICAL READING AND WRITING IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224e47f7e10015fd67da',
      },
      {
        _id: '60cc224f47f7e10015fd67dc',
        courseCode: 'FILM 2242',
        shortName: 'Film Studies 2242F/G - NATIONAL CINEMAS: SPECIAL TOPICS',
        courseName: 'Film Studies 2242F/G - NATIONAL CINEMAS: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224f47f7e10015fd67dc',
      },
      {
        _id: '60cc224f47f7e10015fd67de',
        courseCode: 'FILM 2244',
        shortName: 'Film Studies 2244E - NATIONAL CINEMAS: SPECIAL TOPICS',
        courseName: 'Film Studies 2244E - NATIONAL CINEMAS: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224f47f7e10015fd67de',
      },
      {
        _id: '60cc224f47f7e10015fd67e0',
        courseCode: 'FILM 2252',
        shortName: 'Film Studies 2252F/G - WORLD CINEMA',
        courseName: 'Film Studies 2252F/G - WORLD CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224f47f7e10015fd67e0',
      },
      {
        _id: '60cc224f47f7e10015fd67e2',
        courseCode: 'FILM 2254',
        shortName: 'Film Studies 2254F/G - CLASSICAL HOLLYWOOD CINEMA',
        courseName: 'Film Studies 2254F/G - CLASSICAL HOLLYWOOD CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc224f47f7e10015fd67e2',
      },
      {
        _id: '60cc225047f7e10015fd67e4',
        courseCode: 'FILM 2258',
        shortName:
          'Film Studies 2258F/G - CANADIAN CINEMA: DOCUMENTS, STORYTELLING, EXPERIMENTS',
        courseName:
          'Film Studies 2258F/G - CANADIAN CINEMA: DOCUMENTS, STORYTELLING, EXPERIMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225047f7e10015fd67e4',
      },
      {
        _id: '60cc225047f7e10015fd67e6',
        courseCode: 'FILM 2295',
        shortName:
          'Film Studies 2295F/G - FILM DIRECTORS/AUTEURS: SPECIAL TOPICS',
        courseName:
          'Film Studies 2295F/G - FILM DIRECTORS/AUTEURS: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225047f7e10015fd67e6',
      },
      {
        _id: '60cc225047f7e10015fd67e8',
        courseCode: 'FILM 3309',
        shortName: 'Film Studies 3309F/G - FILM AND POPULAR CULTURE',
        courseName: 'Film Studies 3309F/G - FILM AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225047f7e10015fd67e8',
      },
      {
        _id: '60cc225047f7e10015fd67ea',
        courseCode: 'FILM 3310',
        shortName: 'Film Studies 3310E - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3310E - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225047f7e10015fd67ea',
      },
      {
        _id: '60cc225147f7e10015fd67ec',
        courseCode: 'FILM 3311',
        shortName: 'Film Studies 3311F/G - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3311F/G - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225147f7e10015fd67ec',
      },
      {
        _id: '60cc225147f7e10015fd67ee',
        courseCode: 'FILM 3312',
        shortName: 'Film Studies 3312F/G - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3312F/G - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225147f7e10015fd67ee',
      },
      {
        _id: '60cc225147f7e10015fd67f0',
        courseCode: 'FILM 3315',
        shortName: 'Film Studies 3315F/G - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3315F/G - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225147f7e10015fd67f0',
      },
      {
        _id: '60cc225147f7e10015fd67f2',
        courseCode: 'FILM 3316',
        shortName: 'Film Studies 3316F/G - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3316F/G - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225147f7e10015fd67f2',
      },
      {
        _id: '60cc225247f7e10015fd67f4',
        courseCode: 'FILM 3317',
        shortName: 'Film Studies 3317 - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3317 - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225247f7e10015fd67f4',
      },
      {
        _id: '60cc225247f7e10015fd67f6',
        courseCode: 'FILM 3318',
        shortName: 'Film Studies 3318A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3318A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225247f7e10015fd67f6',
      },
      {
        _id: '60cc225247f7e10015fd67f8',
        courseCode: 'FILM 3319',
        shortName: 'Film Studies 3319A/B - SPECIAL TOPICS IN FILM STUDIES',
        courseName: 'Film Studies 3319A/B - SPECIAL TOPICS IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225247f7e10015fd67f8',
      },
      {
        _id: '60cc225247f7e10015fd67fa',
        courseCode: 'FILM 3330',
        shortName: 'Film Studies 3330F/G - NATIONAL AND TRANSNATIONAL CINEMAS',
        courseName: 'Film Studies 3330F/G - NATIONAL AND TRANSNATIONAL CINEMAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225247f7e10015fd67fa',
      },
      {
        _id: '60cc225347f7e10015fd67fc',
        courseCode: 'FILM 3335',
        shortName: 'Film Studies 3335F/G - CONTEMPORARY GERMAN CINEMA',
        courseName: 'Film Studies 3335F/G - CONTEMPORARY GERMAN CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225347f7e10015fd67fc',
      },
      {
        _id: '60cc225347f7e10015fd67fe',
        courseCode: 'FILM 3340',
        shortName: 'Film Studies 3340F/G - JAPANESE NATIONAL CINEMA',
        courseName: 'Film Studies 3340F/G - JAPANESE NATIONAL CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225347f7e10015fd67fe',
      },
      {
        _id: '60cc225347f7e10015fd6800',
        courseCode: 'FILM 3342',
        shortName:
          'Film Studies 3342F/G - POST-CLASSICAL HOLLYWOOD CINEMA (1960-PRESENT)',
        courseName:
          'Film Studies 3342F/G - POST-CLASSICAL HOLLYWOOD CINEMA (1960-PRESENT)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225347f7e10015fd6800',
      },
      {
        _id: '60cc225347f7e10015fd6802',
        courseCode: 'FILM 3352',
        shortName: 'Film Studies 3352F/G - QUEER CINEMA',
        courseName: 'Film Studies 3352F/G - QUEER CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225347f7e10015fd6802',
      },
      {
        _id: '60cc225447f7e10015fd6804',
        courseCode: 'FILM 3355',
        shortName:
          'Film Studies 3355F/G - ADVANCED STUDIES IN GENDER AND SEXUALITY',
        courseName:
          'Film Studies 3355F/G - ADVANCED STUDIES IN GENDER AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225447f7e10015fd6804',
      },
      {
        _id: '60cc225447f7e10015fd6806',
        courseCode: 'FILM 3356',
        shortName: 'Film Studies 3356F/G - AVANT-GARDE CINEMA',
        courseName: 'Film Studies 3356F/G - AVANT-GARDE CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225447f7e10015fd6806',
      },
      {
        _id: '60cc225447f7e10015fd6808',
        courseCode: 'FILM 3357',
        shortName: 'Film Studies 3357F/G - SCIENCE FICTION CINEMA',
        courseName: 'Film Studies 3357F/G - SCIENCE FICTION CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225447f7e10015fd6808',
      },
      {
        _id: '60cc225447f7e10015fd680a',
        courseCode: 'FILM 3359',
        shortName: 'Film Studies 3359F/G - FAMILY VIEWING: MELODRAMA',
        courseName: 'Film Studies 3359F/G - FAMILY VIEWING: MELODRAMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225447f7e10015fd680a',
      },
      {
        _id: '60cc225447f7e10015fd680c',
        courseCode: 'FILM 3360',
        shortName: 'Film Studies 3360F/G - TOPICS IN FILM GENRE',
        courseName: 'Film Studies 3360F/G - TOPICS IN FILM GENRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225447f7e10015fd680c',
      },
      {
        _id: '60cc225547f7e10015fd680e',
        courseCode: 'FILM 3361',
        shortName: 'Film Studies 3361F/G - STARDOM',
        courseName: 'Film Studies 3361F/G - STARDOM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225547f7e10015fd680e',
      },
      {
        _id: '60cc225547f7e10015fd6810',
        courseCode: 'FILM 3362',
        shortName: 'Film Studies 3362F/G - THE MUSICAL',
        courseName: 'Film Studies 3362F/G - THE MUSICAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225547f7e10015fd6810',
      },
      {
        _id: '60cc225547f7e10015fd6812',
        courseCode: 'FILM 3363',
        shortName: 'Film Studies 3363F/G - SCREENING RACE',
        courseName: 'Film Studies 3363F/G - SCREENING RACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225547f7e10015fd6812',
      },
      {
        _id: '60cc225547f7e10015fd6814',
        courseCode: 'FILM 3364',
        shortName: 'Film Studies 3364F/G - SCREENING THE VIETNAM WAR',
        courseName: 'Film Studies 3364F/G - SCREENING THE VIETNAM WAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225547f7e10015fd6814',
      },
      {
        _id: '60cc225647f7e10015fd6816',
        courseCode: 'FILM 3365',
        shortName: 'Film Studies 3365F/G - QUALITY TELEVISION IN THE U.S.',
        courseName: 'Film Studies 3365F/G - QUALITY TELEVISION IN THE U.S.',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225647f7e10015fd6816',
      },
      {
        _id: '60cc225647f7e10015fd6818',
        courseCode: 'FILM 3366',
        shortName: 'Film Studies 3366F/G - FILM NOIR AND THE CRIME GENRE',
        courseName: 'Film Studies 3366F/G - FILM NOIR AND THE CRIME GENRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225647f7e10015fd6818',
      },
      {
        _id: '60cc225647f7e10015fd681a',
        courseCode: 'FILM 3368',
        shortName: 'Film Studies 3368F/G - FILM PRODUCTION',
        courseName: 'Film Studies 3368F/G - FILM PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225647f7e10015fd681a',
      },
      {
        _id: '60cc225647f7e10015fd681c',
        courseCode: 'FILM 3371',
        shortName: 'Film Studies 3371F/G - FILM THEORY',
        courseName: 'Film Studies 3371F/G - FILM THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225647f7e10015fd681c',
      },
      {
        _id: '60cc225747f7e10015fd681e',
        courseCode: 'FILM 3372',
        shortName: 'Film Studies 3372F/G - ADVANCED FILM PRODUCTION',
        courseName: 'Film Studies 3372F/G - ADVANCED FILM PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225747f7e10015fd681e',
      },
      {
        _id: '60cc225747f7e10015fd6820',
        courseCode: 'FILM 3373',
        shortName: 'Film Studies 3373F/G - REFRAMING NATIONAL CINEMAS',
        courseName: 'Film Studies 3373F/G - REFRAMING NATIONAL CINEMAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225747f7e10015fd6820',
      },
      {
        _id: '60cc225747f7e10015fd6822',
        courseCode: 'FILM 3374',
        shortName: 'Film Studies 3374F/G - DOCUMENTARY FILM',
        courseName: 'Film Studies 3374F/G - DOCUMENTARY FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225747f7e10015fd6822',
      },
      {
        _id: '60cc225747f7e10015fd6824',
        courseCode: 'FILM 3375',
        shortName: 'Film Studies 3375F/G - JAPANESE NEW WAVE',
        courseName: 'Film Studies 3375F/G - JAPANESE NEW WAVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225747f7e10015fd6824',
      },
      {
        _id: '60cc225847f7e10015fd6826',
        courseCode: 'FILM 3377',
        shortName: 'Film Studies 3377F/G - HAUNTED SCREEN: EARLY GERMAN CINEMA',
        courseName:
          'Film Studies 3377F/G - HAUNTED SCREEN: EARLY GERMAN CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225847f7e10015fd6826',
      },
      {
        _id: '60cc225847f7e10015fd6828',
        courseCode: 'FILM 3395',
        shortName:
          'Film Studies 3395F/G - FILM DIRECTORS/AUTEURS: SPECIAL TOPICS',
        courseName:
          'Film Studies 3395F/G - FILM DIRECTORS/AUTEURS: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225847f7e10015fd6828',
      },
      {
        _id: '60cc225847f7e10015fd682a',
        courseCode: 'FILM 3397',
        shortName:
          'Film Studies 3397F/G - BERLIN TO HOLLYWOOD: GERMAN EXILE CINEMA',
        courseName:
          'Film Studies 3397F/G - BERLIN TO HOLLYWOOD: GERMAN EXILE CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225847f7e10015fd682a',
      },
      {
        _id: '60cc225847f7e10015fd682c',
        courseCode: 'FILM 4409',
        shortName: 'Film Studies 4409E - UNDERGRADUATE THESIS',
        courseName: 'Film Studies 4409E - UNDERGRADUATE THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225847f7e10015fd682c',
      },
      {
        _id: '60cc225947f7e10015fd682e',
        courseCode: 'FILM 4470',
        shortName: 'Film Studies 4470F/G - SEMINAR IN FILM AESTHETICS',
        courseName: 'Film Studies 4470F/G - SEMINAR IN FILM AESTHETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225947f7e10015fd682e',
      },
      {
        _id: '60cc225947f7e10015fd6830',
        courseCode: 'FILM 4472',
        shortName: 'Film Studies 4472F/G - SEMINAR IN FILM THEORY',
        courseName: 'Film Studies 4472F/G - SEMINAR IN FILM THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225947f7e10015fd6830',
      },
      {
        _id: '60cc225947f7e10015fd6832',
        courseCode: 'FILM 4474',
        shortName: 'Film Studies 4474F/G - SEMINAR IN FILM STUDIES',
        courseName: 'Film Studies 4474F/G - SEMINAR IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225947f7e10015fd6832',
      },
      {
        _id: '60cc225947f7e10015fd6834',
        courseCode: 'FILM 4490',
        shortName: 'Film Studies 4490F/G - SEMINAR IN FILM STUDIES',
        courseName: 'Film Studies 4490F/G - SEMINAR IN FILM STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225947f7e10015fd6834',
      },
      {
        _id: '60cc225947f7e10015fd6836',
        courseCode: 'FILM 4495',
        shortName: 'Film Studies 4495F/G - FILM ACADEMIC INTERNSHIP',
        courseName: 'Film Studies 4495F/G - FILM ACADEMIC INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225947f7e10015fd6836',
      },
      {
        _id: '60cc225a47f7e10015fd6838',
        courseCode: 'FINMOD 2555',
        shortName: 'Financial Modelling 2555A/B - CORPORATE FINANCE',
        courseName: 'Financial Modelling 2555A/B - CORPORATE FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225a47f7e10015fd6838',
      },
      {
        _id: '60cc225a47f7e10015fd683a',
        courseCode: 'FINMOD 2557',
        shortName:
          'Financial Modelling 2557A/B - FINANCIAL MARKETS AND INVESTMENTS',
        courseName:
          'Financial Modelling 2557A/B - FINANCIAL MARKETS AND INVESTMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225a47f7e10015fd683a',
      },
      {
        _id: '60cc225a47f7e10015fd683c',
        courseCode: 'FINMOD 3520',
        shortName: 'Financial Modelling 3520A/B - FINANCIAL MODELLING I',
        courseName: 'Financial Modelling 3520A/B - FINANCIAL MODELLING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225a47f7e10015fd683c',
      },
      {
        _id: '60cc225a47f7e10015fd683e',
        courseCode: 'FINMOD 3613',
        shortName:
          'Financial Modelling 3613A/B - MATHEMATICS OF FINANCIAL OPTIONS',
        courseName:
          'Financial Modelling 3613A/B - MATHEMATICS OF FINANCIAL OPTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225a47f7e10015fd683e',
      },
      {
        _id: '60cc225b47f7e10015fd6840',
        courseCode: 'FINMOD 3817',
        shortName:
          'Financial Modelling 3817A/B - OPTIMIZATION METHODS FOR FINANCIAL MODELLING',
        courseName:
          'Financial Modelling 3817A/B - OPTIMIZATION METHODS FOR FINANCIAL MODELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225b47f7e10015fd6840',
      },
      {
        _id: '60cc225b47f7e10015fd6842',
        courseCode: 'FINMOD 4521',
        shortName: 'Financial Modelling 4521A/B - ADVANCED FINANCIAL MODELLING',
        courseName:
          'Financial Modelling 4521A/B - ADVANCED FINANCIAL MODELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225b47f7e10015fd6842',
      },
      {
        _id: '60cc225b47f7e10015fd6844',
        courseCode: 'FINMOD 4998',
        shortName:
          'Financial Modelling 4998F/G/Z - PROJECT IN FINANCIAL MODELLING',
        courseName:
          'Financial Modelling 4998F/G/Z - PROJECT IN FINANCIAL MODELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225b47f7e10015fd6844',
      },
      {
        _id: '60cc225b47f7e10015fd6846',
        courseCode: 'FOODNUTR 0010',
        shortName:
          'Foods and Nutrition 0010 - INTRODUCTION TO FOODS & NUTRITION',
        courseName:
          'Foods and Nutrition 0010 - INTRODUCTION TO FOODS & NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225b47f7e10015fd6846',
      },
      {
        _id: '60cc225b47f7e10015fd6848',
        courseCode: 'FOODNUTR 0011',
        shortName:
          'Foods and Nutrition 0011A/B - INTRODUCTION TO FOODS AND NUTRITION: HEALTHY EATING',
        courseName:
          'Foods and Nutrition 0011A/B - INTRODUCTION TO FOODS AND NUTRITION: HEALTHY EATING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225b47f7e10015fd6848',
      },
      {
        _id: '60cc225c47f7e10015fd684a',
        courseCode: 'FOODNUTR 0012',
        shortName:
          'Foods and Nutrition 0012A/B - INTRODUCTION TO FOODS AND NUTRITION: LIFE CYCLE NUTRITION',
        courseName:
          'Foods and Nutrition 0012A/B - INTRODUCTION TO FOODS AND NUTRITION: LIFE CYCLE NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225c47f7e10015fd684a',
      },
      {
        _id: '60cc225c47f7e10015fd684c',
        courseCode: 'FOODNUTR 1070',
        shortName: 'Foods and Nutrition 1070A/B - INTRODUCTORY HUMAN NUTRITION',
        courseName:
          'Foods and Nutrition 1070A/B - INTRODUCTORY HUMAN NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225c47f7e10015fd684c',
      },
      {
        _id: '60cc225c47f7e10015fd684e',
        courseCode: 'FOODNUTR 1241',
        shortName: 'Foods and Nutrition 1241A/B - LIFECYCLE NUTRITION',
        courseName: 'Foods and Nutrition 1241A/B - LIFECYCLE NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225c47f7e10015fd684e',
      },
      {
        _id: '60cc225c47f7e10015fd6850',
        courseCode: 'FOODNUTR 2070',
        shortName:
          'Foods and Nutrition 2070A/B - FUNDAMENTALS OF HUMAN NUTRITION',
        courseName:
          'Foods and Nutrition 2070A/B - FUNDAMENTALS OF HUMAN NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225c47f7e10015fd6850',
      },
      {
        _id: '60cc225d47f7e10015fd6852',
        courseCode: 'FOODNUTR 2130',
        shortName: 'Foods and Nutrition 2130 - CULINARY NUTRITION',
        courseName: 'Foods and Nutrition 2130 - CULINARY NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225d47f7e10015fd6852',
      },
      {
        _id: '60cc225d47f7e10015fd6854',
        courseCode: 'FOODNUTR 2132',
        shortName: 'Foods and Nutrition 2132A/B - INTRODUCTION TO FOODS',
        courseName: 'Foods and Nutrition 2132A/B - INTRODUCTION TO FOODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225d47f7e10015fd6854',
      },
      {
        _id: '60cc225d47f7e10015fd6856',
        courseCode: 'FOODNUTR 2230',
        shortName: 'Foods and Nutrition 2230A/B - INTEGRATED HUMAN NUTRITION',
        courseName: 'Foods and Nutrition 2230A/B - INTEGRATED HUMAN NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225d47f7e10015fd6856',
      },
      {
        _id: '60cc225d47f7e10015fd6858',
        courseCode: 'FOODNUTR 2232',
        shortName: 'Foods and Nutrition 2232 - PRINCIPLES OF FOOD SCIENCE',
        courseName: 'Foods and Nutrition 2232 - PRINCIPLES OF FOOD SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225d47f7e10015fd6858',
      },
      {
        _id: '60cc225d47f7e10015fd685a',
        courseCode: 'FOODNUTR 2241',
        shortName:
          'Foods and Nutrition 2241A/B - NUTRITION THROUGHOUT THE HUMAN LIFE CYCLE',
        courseName:
          'Foods and Nutrition 2241A/B - NUTRITION THROUGHOUT THE HUMAN LIFE CYCLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225d47f7e10015fd685a',
      },
      {
        _id: '60cc225e47f7e10015fd685c',
        courseCode: 'FOODNUTR 2245',
        shortName:
          'Foods and Nutrition 2245A/B - NUTRITION: A LIFESPAN APPROACH',
        courseName:
          'Foods and Nutrition 2245A/B - NUTRITION: A LIFESPAN APPROACH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225e47f7e10015fd685c',
      },
      {
        _id: '60cc225e47f7e10015fd685e',
        courseCode: 'FOODNUTR 2266',
        shortName:
          'Foods and Nutrition 2266F/G - NUTRITION EDUCATION AND COMMUNICATION',
        courseName:
          'Foods and Nutrition 2266F/G - NUTRITION EDUCATION AND COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225e47f7e10015fd685e',
      },
      {
        _id: '60cc225e47f7e10015fd6860',
        courseCode: 'FOODNUTR 2449',
        shortName:
          'Foods and Nutrition 2449A/B - MANAGEMENT FOR FOOD AND NUTRITION',
        courseName:
          'Foods and Nutrition 2449A/B - MANAGEMENT FOR FOOD AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225e47f7e10015fd6860',
      },
      {
        _id: '60cc225e47f7e10015fd6862',
        courseCode: 'FOODNUTR 3310',
        shortName: 'Foods and Nutrition 3310A/B - FOOD PRODUCT DEVELOPMENT',
        courseName: 'Foods and Nutrition 3310A/B - FOOD PRODUCT DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225e47f7e10015fd6862',
      },
      {
        _id: '60cc225f47f7e10015fd6864',
        courseCode: 'FOODNUTR 3339',
        shortName: 'Foods and Nutrition 3339A/B - EXERCISE NUTRITION',
        courseName: 'Foods and Nutrition 3339A/B - EXERCISE NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225f47f7e10015fd6864',
      },
      {
        _id: '60cc225f47f7e10015fd6866',
        courseCode: 'FOODNUTR 3342',
        shortName: 'Foods and Nutrition 3342A/B - ADVANCED FOOD SCIENCE',
        courseName: 'Foods and Nutrition 3342A/B - ADVANCED FOOD SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225f47f7e10015fd6866',
      },
      {
        _id: '60cc225f47f7e10015fd6868',
        courseCode: 'FOODNUTR 3344',
        shortName: 'Foods and Nutrition 3344A/B - NUTRITIONAL ASSESSMENT',
        courseName: 'Foods and Nutrition 3344A/B - NUTRITIONAL ASSESSMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225f47f7e10015fd6868',
      },
      {
        _id: '60cc225f47f7e10015fd686a',
        courseCode: 'FOODNUTR 3348',
        shortName: 'Foods and Nutrition 3348A/B - FOOD PRODUCTION MANAGEMENT I',
        courseName:
          'Foods and Nutrition 3348A/B - FOOD PRODUCTION MANAGEMENT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225f47f7e10015fd686a',
      },
      {
        _id: '60cc225f47f7e10015fd686c',
        courseCode: 'FOODNUTR 3351',
        shortName: 'Foods and Nutrition 3351A/B - CLINICAL NUTRITION I',
        courseName: 'Foods and Nutrition 3351A/B - CLINICAL NUTRITION I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc225f47f7e10015fd686c',
      },
      {
        _id: '60cc226047f7e10015fd686e',
        courseCode: 'FOODNUTR 3355',
        shortName:
          'Foods and Nutrition 3355A/B - AGRICULTURE AND FOOD SYSTEMS: CRITICAL CONVERSATIONS',
        courseName:
          'Foods and Nutrition 3355A/B - AGRICULTURE AND FOOD SYSTEMS: CRITICAL CONVERSATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226047f7e10015fd686e',
      },
      {
        _id: '60cc226047f7e10015fd6870',
        courseCode: 'FOODNUTR 3361',
        shortName:
          'Foods and Nutrition 3361F/G - FUNDAMENTALS OF COMMUNITY NUTRITION',
        courseName:
          'Foods and Nutrition 3361F/G - FUNDAMENTALS OF COMMUNITY NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226047f7e10015fd6870',
      },
      {
        _id: '60cc226047f7e10015fd6872',
        courseCode: 'FOODNUTR 3364',
        shortName: 'Foods and Nutrition 3364A/B - NUTRITION, AGING AND HEALTH',
        courseName: 'Foods and Nutrition 3364A/B - NUTRITION, AGING AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226047f7e10015fd6872',
      },
      {
        _id: '60cc226047f7e10015fd6874',
        courseCode: 'FOODNUTR 3373',
        shortName:
          'Foods and Nutrition 3373A/B - NUTRITION AND PHYSICAL ACTIVITY',
        courseName:
          'Foods and Nutrition 3373A/B - NUTRITION AND PHYSICAL ACTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226047f7e10015fd6874',
      },
      {
        _id: '60cc226147f7e10015fd6876',
        courseCode: 'FOODNUTR 3380',
        shortName:
          'Foods and Nutrition 3380A/B - POLICY DEVELOPMENT AND ADVOCACY',
        courseName:
          'Foods and Nutrition 3380A/B - POLICY DEVELOPMENT AND ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226147f7e10015fd6876',
      },
      {
        _id: '60cc226147f7e10015fd6878',
        courseCode: 'FOODNUTR 3390',
        shortName:
          'Foods and Nutrition 3390W/X - RESEARCH METHODS AND STATISTICS FOR FOOD AND NUTRITION',
        courseName:
          'Foods and Nutrition 3390W/X - RESEARCH METHODS AND STATISTICS FOR FOOD AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226147f7e10015fd6878',
      },
      {
        _id: '60cc226147f7e10015fd687a',
        courseCode: 'FOODNUTR 3400',
        shortName: 'Foods and Nutrition 3400A/B - CULTURE AND FOOD',
        courseName: 'Foods and Nutrition 3400A/B - CULTURE AND FOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226147f7e10015fd687a',
      },
      {
        _id: '60cc226147f7e10015fd687c',
        courseCode: 'FOODNUTR 3450',
        shortName: 'Foods and Nutrition 3450F/G - FOOD FOR THOUGHT',
        courseName: 'Foods and Nutrition 3450F/G - FOOD FOR THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226147f7e10015fd687c',
      },
      {
        _id: '60cc226247f7e10015fd687e',
        courseCode: 'FOODNUTR 3460',
        shortName: 'Foods and Nutrition 3460F/G - FOOD AND CONFLICT',
        courseName: 'Foods and Nutrition 3460F/G - FOOD AND CONFLICT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226247f7e10015fd687e',
      },
      {
        _id: '60cc226247f7e10015fd6880',
        courseCode: 'FOODNUTR 3480',
        shortName: 'Foods and Nutrition 3480F/G - INDEPENDENT STUDY',
        courseName: 'Foods and Nutrition 3480F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226247f7e10015fd6880',
      },
      {
        _id: '60cc226247f7e10015fd6882',
        courseCode: 'FOODNUTR 3481',
        shortName: 'Foods and Nutrition 3481F/G - INDEPENDENT STUDY',
        courseName: 'Foods and Nutrition 3481F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226247f7e10015fd6882',
      },
      {
        _id: '60cc226247f7e10015fd6884',
        courseCode: 'FOODNUTR 4410',
        shortName:
          'Foods and Nutrition 4410A/B - GLOBAL POLICIES IN FOOD SAFETY',
        courseName:
          'Foods and Nutrition 4410A/B - GLOBAL POLICIES IN FOOD SAFETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226247f7e10015fd6884',
      },
      {
        _id: '60cc226347f7e10015fd6886',
        courseCode: 'FOODNUTR 4411',
        shortName: 'Foods and Nutrition 4411F/G - SENIOR RESEARCH PROJECT',
        courseName: 'Foods and Nutrition 4411F/G - SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226347f7e10015fd6886',
      },
      {
        _id: '60cc226347f7e10015fd6888',
        courseCode: 'FOODNUTR 4420',
        shortName: 'Foods and Nutrition 4420A/B - SENSORY EVALUATION OF FOODS',
        courseName: 'Foods and Nutrition 4420A/B - SENSORY EVALUATION OF FOODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226347f7e10015fd6888',
      },
      {
        _id: '60cc226347f7e10015fd688a',
        courseCode: 'FOODNUTR 4422',
        shortName:
          'Foods and Nutrition 4422A/B - FINANCIAL MANAGEMENT AND CONTROL FOR FOODS AND NUTRITION',
        courseName:
          'Foods and Nutrition 4422A/B - FINANCIAL MANAGEMENT AND CONTROL FOR FOODS AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226347f7e10015fd688a',
      },
      {
        _id: '60cc226347f7e10015fd688c',
        courseCode: 'FOODNUTR 4429',
        shortName:
          'Foods and Nutrition 4429A/B - ADVANCED MANAGEMENT FOR FOODS AND NUTRITION',
        courseName:
          'Foods and Nutrition 4429A/B - ADVANCED MANAGEMENT FOR FOODS AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226347f7e10015fd688c',
      },
      {
        _id: '60cc226347f7e10015fd688e',
        courseCode: 'FOODNUTR 4435',
        shortName: 'Foods and Nutrition 4435A/B - FOOD ANALYSIS',
        courseName: 'Foods and Nutrition 4435A/B - FOOD ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226347f7e10015fd688e',
      },
      {
        _id: '60cc226447f7e10015fd6890',
        courseCode: 'FOODNUTR 4440',
        shortName:
          'Foods and Nutrition 4440A/B - CURRENT ISSUES IN FOOD SCIENCE AND TECHNOLOGY',
        courseName:
          'Foods and Nutrition 4440A/B - CURRENT ISSUES IN FOOD SCIENCE AND TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226447f7e10015fd6890',
      },
      {
        _id: '60cc226447f7e10015fd6892',
        courseCode: 'FOODNUTR 4449',
        shortName:
          'Foods and Nutrition 4449A/B - FOOD PRODUCTION MANAGEMENT II',
        courseName:
          'Foods and Nutrition 4449A/B - FOOD PRODUCTION MANAGEMENT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226447f7e10015fd6892',
      },
      {
        _id: '60cc226447f7e10015fd6894',
        courseCode: 'FOODNUTR 4453',
        shortName: 'Foods and Nutrition 4453A/B - CLINICAL NUTRITION II',
        courseName: 'Foods and Nutrition 4453A/B - CLINICAL NUTRITION II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226447f7e10015fd6894',
      },
      {
        _id: '60cc226447f7e10015fd6896',
        courseCode: 'FOODNUTR 4455',
        shortName: 'Foods and Nutrition 4455A/B - CLINICAL NUTRITION III',
        courseName: 'Foods and Nutrition 4455A/B - CLINICAL NUTRITION III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226447f7e10015fd6896',
      },
      {
        _id: '60cc226547f7e10015fd6898',
        courseCode: 'FOODNUTR 4458',
        shortName:
          'Foods and Nutrition 4458A/B - EQUIPMENT SELECTION AND FOOD FACILITY LAYOUT',
        courseName:
          'Foods and Nutrition 4458A/B - EQUIPMENT SELECTION AND FOOD FACILITY LAYOUT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226547f7e10015fd6898',
      },
      {
        _id: '60cc226547f7e10015fd689a',
        courseCode: 'FOODNUTR 4460',
        shortName: 'Foods and Nutrition 4460A/B - NUTRITION COUNSELLING',
        courseName: 'Foods and Nutrition 4460A/B - NUTRITION COUNSELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226547f7e10015fd689a',
      },
      {
        _id: '60cc226547f7e10015fd689c',
        courseCode: 'FOODNUTR 4462',
        shortName: 'Foods and Nutrition 4462A/B - COMMUNITY NUTRITION II',
        courseName: 'Foods and Nutrition 4462A/B - COMMUNITY NUTRITION II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226547f7e10015fd689c',
      },
      {
        _id: '60cc226547f7e10015fd689e',
        courseCode: 'FOODNUTR 4471',
        shortName:
          'Foods and Nutrition 4471A/B - NUTRITION AND METABOLIC PROCESSES',
        courseName:
          'Foods and Nutrition 4471A/B - NUTRITION AND METABOLIC PROCESSES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226547f7e10015fd689e',
      },
      {
        _id: '60cc226647f7e10015fd68a0',
        courseCode: 'FOODNUTR 4477',
        shortName:
          'Foods and Nutrition 4477A/B - EXERCISE, NUTRITION & WELLNESS',
        courseName:
          'Foods and Nutrition 4477A/B - EXERCISE, NUTRITION & WELLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226647f7e10015fd68a0',
      },
      {
        _id: '60cc226647f7e10015fd68a2',
        courseCode: 'FOODNUTR 4480',
        shortName: 'Foods and Nutrition 4480F/G - SPECIAL TOPICS',
        courseName: 'Foods and Nutrition 4480F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226647f7e10015fd68a2',
      },
      {
        _id: '60cc226647f7e10015fd68a4',
        courseCode: 'FOODNUTR 4481',
        shortName: 'Foods and Nutrition 4481F/G - SPECIAL TOPICS',
        courseName: 'Foods and Nutrition 4481F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226647f7e10015fd68a4',
      },
      {
        _id: '60cc226647f7e10015fd68a6',
        courseCode: 'FOODNUTR 4488',
        shortName:
          'Foods and Nutrition 4488W/X - JAPANESE AND CANADIAN FOOD AND NUTRITION CULTURES AND PROFESSIONS',
        courseName:
          'Foods and Nutrition 4488W/X - JAPANESE AND CANADIAN FOOD AND NUTRITION CULTURES AND PROFESSIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226647f7e10015fd68a6',
      },
      {
        _id: '60cc226747f7e10015fd68a8',
        courseCode: 'FOODNUTR 4901',
        shortName: 'Foods and Nutrition 4901Y - DIETETIC SIMULATION PRACTICE',
        courseName: 'Foods and Nutrition 4901Y - DIETETIC SIMULATION PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226747f7e10015fd68a8',
      },
      {
        _id: '60cc226747f7e10015fd68aa',
        courseCode: 'FOODNUTR 4902',
        shortName:
          'Foods and Nutrition 4902Y - REGULATORY, LEGAL AND  ETHICAL ISSUES OF DIETETIC PRACTICE',
        courseName:
          'Foods and Nutrition 4902Y - REGULATORY, LEGAL AND  ETHICAL ISSUES OF DIETETIC PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226747f7e10015fd68aa',
      },
      {
        _id: '60cc226747f7e10015fd68ac',
        courseCode: 'FOODNUTR 4903',
        shortName:
          'Foods and Nutrition 4903Y - PRACTICE-BASED DIETETIC RESEARCH',
        courseName:
          'Foods and Nutrition 4903Y - PRACTICE-BASED DIETETIC RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226747f7e10015fd68ac',
      },
      {
        _id: '60cc226747f7e10015fd68ae',
        courseCode: 'FOODNUTR 4904',
        shortName: 'Foods and Nutrition 4904Y - PRACTICUM',
        courseName: 'Foods and Nutrition 4904Y - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226747f7e10015fd68ae',
      },
      {
        _id: '60cc226847f7e10015fd68b0',
        courseCode: 'FRENCH 1001',
        shortName: 'French 1001A/B - UNLOCKING PARIS',
        courseName: 'French 1001A/B - UNLOCKING PARIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226847f7e10015fd68b0',
      },
      {
        _id: '60cc226847f7e10015fd68b2',
        courseCode: 'FRENCH 1002',
        shortName: 'French 1002 - INTENSIVE FRENCH',
        courseName: 'French 1002 - INTENSIVE FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226847f7e10015fd68b2',
      },
      {
        _id: '60cc226847f7e10015fd68b4',
        courseCode: 'FRENCH 1003',
        shortName: 'French 1003A/B - INTENSIVE FRENCH',
        courseName: 'French 1003A/B - INTENSIVE FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226847f7e10015fd68b4',
      },
      {
        _id: '60cc226947f7e10015fd68b6',
        courseCode: 'FRENCH 1004',
        shortName: 'French 1004 - FRENCH FUNDAMENTALS',
        courseName: 'French 1004 - FRENCH FUNDAMENTALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226947f7e10015fd68b6',
      },
      {
        _id: '60cc226947f7e10015fd68b8',
        courseCode: 'FRENCH 1010',
        shortName: 'French 1010 - INTERMEDIATE FRENCH',
        courseName: 'French 1010 - INTERMEDIATE FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226947f7e10015fd68b8',
      },
      {
        _id: '60cc226a47f7e10015fd68ba',
        courseCode: 'FRENCH 1011',
        shortName: 'French 1011A/B - INTERMEDIATE FRENCH',
        courseName: 'French 1011A/B - INTERMEDIATE FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226a47f7e10015fd68ba',
      },
      {
        _id: '60cc226b47f7e10015fd68bc',
        courseCode: 'FRENCH 1102',
        shortName: 'French 1102A/B - ORAL FRENCH I',
        courseName: 'French 1102A/B - ORAL FRENCH I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226b47f7e10015fd68bc',
      },
      {
        _id: '60cc226b47f7e10015fd68be',
        courseCode: 'FRENCH 1103',
        shortName: 'French 1103A/B - ORAL FRENCH II',
        courseName: 'French 1103A/B - ORAL FRENCH II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226b47f7e10015fd68be',
      },
      {
        _id: '60cc226b47f7e10015fd68c0',
        courseCode: 'FRENCH 1900',
        shortName: 'French 1900E - FRENCH STUDIES: AN INTRODUCTION',
        courseName: 'French 1900E - FRENCH STUDIES: AN INTRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226b47f7e10015fd68c0',
      },
      {
        _id: '60cc226c47f7e10015fd68c2',
        courseCode: 'FRENCH 1910',
        shortName: 'French 1910 - UNIVERSITY FRENCH (LEVEL 1)',
        courseName: 'French 1910 - UNIVERSITY FRENCH (LEVEL 1)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226c47f7e10015fd68c2',
      },
      {
        _id: '60cc226c47f7e10015fd68c4',
        courseCode: 'FRENCH 1999',
        shortName: 'French 1999 - INTRODUCTION TO FRENCH STUDIES',
        courseName: 'French 1999 - INTRODUCTION TO FRENCH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226c47f7e10015fd68c4',
      },
      {
        _id: '60cc226d47f7e10015fd68c6',
        courseCode: 'FRENCH 2101',
        shortName: 'French 2101 - LANGUAGE (GENERAL LEVEL II)',
        courseName: 'French 2101 - LANGUAGE (GENERAL LEVEL II)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226d47f7e10015fd68c6',
      },
      {
        _id: '60cc226d47f7e10015fd68c8',
        courseCode: 'FRENCH 2102',
        shortName: 'French 2102A/B - ORAL FRENCH III',
        courseName: 'French 2102A/B - ORAL FRENCH III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226d47f7e10015fd68c8',
      },
      {
        _id: '60cc226d47f7e10015fd68ca',
        courseCode: 'FRENCH 2104',
        shortName: 'French 2104A/B - ORAL FRENCH IV',
        courseName: 'French 2104A/B - ORAL FRENCH IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226d47f7e10015fd68ca',
      },
      {
        _id: '60cc226d47f7e10015fd68cc',
        courseCode: 'FRENCH 2107',
        shortName: 'French 2107A/B - IMPROVING SPOKEN FRENCH',
        courseName: 'French 2107A/B - IMPROVING SPOKEN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226d47f7e10015fd68cc',
      },
      {
        _id: '60cc226e47f7e10015fd68ce',
        courseCode: 'FRENCH 2108',
        shortName: 'French 2108A/B - IMPROVING SPOKEN FRENCH',
        courseName: 'French 2108A/B - IMPROVING SPOKEN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226e47f7e10015fd68ce',
      },
      {
        _id: '60cc226e47f7e10015fd68d0',
        courseCode: 'FRENCH 2113',
        shortName:
          'French 2113F - SPECIAL TOPICS IN FRANCOPHONE CULTURAL STUDIES',
        courseName:
          'French 2113F - SPECIAL TOPICS IN FRANCOPHONE CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226e47f7e10015fd68d0',
      },
      {
        _id: '60cc226e47f7e10015fd68d2',
        courseCode: 'FRENCH 2114',
        shortName:
          'French 2114G - SPECIAL TOPICS IN FRANCOPHONE CULTURAL STUDIES',
        courseName:
          'French 2114G - SPECIAL TOPICS IN FRANCOPHONE CULTURAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226e47f7e10015fd68d2',
      },
      {
        _id: '60cc226e47f7e10015fd68d4',
        courseCode: 'FRENCH 2115',
        shortName: 'French 2115F - SPECIAL TOPICS IN LANGUAGE STUDIES',
        courseName: 'French 2115F - SPECIAL TOPICS IN LANGUAGE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226e47f7e10015fd68d4',
      },
      {
        _id: '60cc226f47f7e10015fd68d6',
        courseCode: 'FRENCH 2116',
        shortName: 'French 2116G - SPECIAL TOPICS IN LANGUAGE STUDIES',
        courseName: 'French 2116G - SPECIAL TOPICS IN LANGUAGE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226f47f7e10015fd68d6',
      },
      {
        _id: '60cc226f47f7e10015fd68d8',
        courseCode: 'FRENCH 2170',
        shortName:
          'French 2170A/B - THE NEUROLINGUISTIC APPROACH TO SECOND-LANGUAGE ACQUISITION',
        courseName:
          'French 2170A/B - THE NEUROLINGUISTIC APPROACH TO SECOND-LANGUAGE ACQUISITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226f47f7e10015fd68d8',
      },
      {
        _id: '60cc226f47f7e10015fd68da',
        courseCode: 'FRENCH 2205',
        shortName: 'French 2205A/B - JOB-SEEKING IN FRENCH',
        courseName: 'French 2205A/B - JOB-SEEKING IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226f47f7e10015fd68da',
      },
      {
        _id: '60cc226f47f7e10015fd68dc',
        courseCode: 'FRENCH 2206',
        shortName: 'French 2206A/B - FRENCH AT WORK',
        courseName: 'French 2206A/B - FRENCH AT WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc226f47f7e10015fd68dc',
      },
      {
        _id: '60cc227047f7e10015fd68de',
        courseCode: 'FRENCH 2207',
        shortName: 'French 2207A/B - FRENCH FOR PUBLIC ADMINISTRATION',
        courseName: 'French 2207A/B - FRENCH FOR PUBLIC ADMINISTRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227047f7e10015fd68de',
      },
      {
        _id: '60cc227047f7e10015fd68e0',
        courseCode: 'FRENCH 2208',
        shortName: 'French 2208A/B - FRENCH FOR HEALTHCARE',
        courseName: 'French 2208A/B - FRENCH FOR HEALTHCARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227047f7e10015fd68e0',
      },
      {
        _id: '60cc227047f7e10015fd68e2',
        courseCode: 'FRENCH 2209',
        shortName: 'French 2209A/B - FRENCH FOR THE MEDIA',
        courseName: 'French 2209A/B - FRENCH FOR THE MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227047f7e10015fd68e2',
      },
      {
        _id: '60cc227047f7e10015fd68e4',
        courseCode: 'FRENCH 2211',
        shortName: 'French 2211F/G - HOLOCAUST AND LITERATURE',
        courseName: 'French 2211F/G - HOLOCAUST AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227047f7e10015fd68e4',
      },
      {
        _id: '60cc227147f7e10015fd68e6',
        courseCode: 'FRENCH 2212',
        shortName: 'French 2212F/G - SEX AND SEDUCTION IN THE FRENCH NOVEL',
        courseName: 'French 2212F/G - SEX AND SEDUCTION IN THE FRENCH NOVEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227147f7e10015fd68e6',
      },
      {
        _id: '60cc227147f7e10015fd68e8',
        courseCode: 'FRENCH 2213',
        shortName: 'French 2213E - FOUNDATIONS IN WESTERN LITERATURE',
        courseName: 'French 2213E - FOUNDATIONS IN WESTERN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227147f7e10015fd68e8',
      },
      {
        _id: '60cc227147f7e10015fd68ea',
        courseCode: 'FRENCH 2300',
        shortName: 'French 2300 - INTENSIVE TRANSLATION I',
        courseName: 'French 2300 - INTENSIVE TRANSLATION I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227147f7e10015fd68ea',
      },
      {
        _id: '60cc227247f7e10015fd68ec',
        courseCode: 'FRENCH 2305',
        shortName: 'French 2305A/B - TRANSLATION: COMPARATIVE STYLISTICS',
        courseName: 'French 2305A/B - TRANSLATION: COMPARATIVE STYLISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227247f7e10015fd68ec',
      },
      {
        _id: '60cc227247f7e10015fd68ee',
        courseCode: 'FRENCH 2306',
        shortName: 'French 2306A/B - TRANSLATION: THEMES AND VERSIONS',
        courseName: 'French 2306A/B - TRANSLATION: THEMES AND VERSIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227247f7e10015fd68ee',
      },
      {
        _id: '60cc227247f7e10015fd68f0',
        courseCode: 'FRENCH 2403',
        shortName:
          'French 2403A/B - FRENCH AND FRANCOPHONE CULTURES:  THE ART AND CULTURE OF QUEBEC',
        courseName:
          'French 2403A/B - FRENCH AND FRANCOPHONE CULTURES:  THE ART AND CULTURE OF QUEBEC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227247f7e10015fd68f0',
      },
      {
        _id: '60cc227347f7e10015fd68f2',
        courseCode: 'FRENCH 2404',
        shortName:
          'French 2404A/B - FRENCH AND FRANCOPHONE CULTURES:  FRANCE:  ENLIGHTENMENT TO POSTMODERNITY',
        courseName:
          'French 2404A/B - FRENCH AND FRANCOPHONE CULTURES:  FRANCE:  ENLIGHTENMENT TO POSTMODERNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227347f7e10015fd68f2',
      },
      {
        _id: '60cc227347f7e10015fd68f4',
        courseCode: 'FRENCH 2405',
        shortName:
          'French 2405A/B - FRENCH AND FRANCOPHONE CULTURES: HISTORY AND POLITICS OF QUEBEC',
        courseName:
          'French 2405A/B - FRENCH AND FRANCOPHONE CULTURES: HISTORY AND POLITICS OF QUEBEC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227347f7e10015fd68f4',
      },
      {
        _id: '60cc227347f7e10015fd68f6',
        courseCode: 'FRENCH 2406',
        shortName:
          'French 2406A/B - FRENCH AND FRANCOPHONE CULTURES:  FRANCE: FROM LASCAUX TO LOUIS XIV',
        courseName:
          'French 2406A/B - FRENCH AND FRANCOPHONE CULTURES:  FRANCE: FROM LASCAUX TO LOUIS XIV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227347f7e10015fd68f6',
      },
      {
        _id: '60cc227347f7e10015fd68f8',
        courseCode: 'FRENCH 2407',
        shortName:
          'French 2407A/B - FRENCH AND FRANCOPHONE CULTURES: LA FRANCOPHONIE',
        courseName:
          'French 2407A/B - FRENCH AND FRANCOPHONE CULTURES: LA FRANCOPHONIE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227347f7e10015fd68f8',
      },
      {
        _id: '60cc227447f7e10015fd68fa',
        courseCode: 'FRENCH 2408',
        shortName:
          'French 2408A/B - FRENCH AND FRANCOPHONE CULTURES: SPECIAL TOPICS',
        courseName:
          'French 2408A/B - FRENCH AND FRANCOPHONE CULTURES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227447f7e10015fd68fa',
      },
      {
        _id: '60cc227447f7e10015fd68fc',
        courseCode: 'FRENCH 2410',
        shortName: 'French 2410E - INTENSIVE FRENCH-CANADIAN CIVILIZATION',
        courseName: 'French 2410E - INTENSIVE FRENCH-CANADIAN CIVILIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227447f7e10015fd68fc',
      },
      {
        _id: '60cc227447f7e10015fd68fe',
        courseCode: 'FRENCH 2600',
        shortName: 'French 2600E - INTRODUCTION TO FRENCH LITERATURE',
        courseName: 'French 2600E - INTRODUCTION TO FRENCH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227447f7e10015fd68fe',
      },
      {
        _id: '60cc227547f7e10015fd6900',
        courseCode: 'FRENCH 2605',
        shortName:
          'French 2605F/G - READING LITERATURE IN FRENCH: MIDDLE AGES TO ROMANTIICISM',
        courseName:
          'French 2605F/G - READING LITERATURE IN FRENCH: MIDDLE AGES TO ROMANTIICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227547f7e10015fd6900',
      },
      {
        _id: '60cc227647f7e10015fd6902',
        courseCode: 'FRENCH 2606',
        shortName:
          'French 2606F/G - READING LITERATURE IN FRENCH: MODERNITY TO POSTMODERNITY',
        courseName:
          'French 2606F/G - READING LITERATURE IN FRENCH: MODERNITY TO POSTMODERNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227647f7e10015fd6902',
      },
      {
        _id: '60cc227647f7e10015fd6904',
        courseCode: 'FRENCH 2805',
        shortName:
          'French 2805A/B - GENERAL LINGUISTICS IN FRENCH: SOUND PATTERNS',
        courseName:
          'French 2805A/B - GENERAL LINGUISTICS IN FRENCH: SOUND PATTERNS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227647f7e10015fd6904',
      },
      {
        _id: '60cc227747f7e10015fd6906',
        courseCode: 'FRENCH 2806',
        shortName:
          'French 2806A/B - GENERAL LINGUISTICS IN FRENCH: FROM SOUND TO MEANING',
        courseName:
          'French 2806A/B - GENERAL LINGUISTICS IN FRENCH: FROM SOUND TO MEANING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227747f7e10015fd6906',
      },
      {
        _id: '60cc227747f7e10015fd6908',
        courseCode: 'FRENCH 2900',
        shortName: 'French 2900 - LANGUAGE (ADVANCED LEVEL II)',
        courseName: 'French 2900 - LANGUAGE (ADVANCED LEVEL II)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227747f7e10015fd6908',
      },
      {
        _id: '60cc227847f7e10015fd690a',
        courseCode: 'FRENCH 2905',
        shortName: 'French 2905A/B - LANGUAGE AND READING',
        courseName: 'French 2905A/B - LANGUAGE AND READING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227847f7e10015fd690a',
      },
      {
        _id: '60cc227847f7e10015fd690c',
        courseCode: 'FRENCH 2906',
        shortName: 'French 2906A/B - LANGUAGE AND EXPRESSION',
        courseName: 'French 2906A/B - LANGUAGE AND EXPRESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227847f7e10015fd690c',
      },
      {
        _id: '60cc227947f7e10015fd690e',
        courseCode: 'FRENCH 2907',
        shortName: 'French 2907A/B - WRITING WORKSHOP IN FRENCH',
        courseName: 'French 2907A/B - WRITING WORKSHOP IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227947f7e10015fd690e',
      },
      {
        _id: '60cc227947f7e10015fd6910',
        courseCode: 'FRENCH 3100',
        shortName: 'French 3100A/B - SPECIAL TOPICS IN LANG AND LITERTURE',
        courseName: 'French 3100A/B - SPECIAL TOPICS IN LANG AND LITERTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227947f7e10015fd6910',
      },
      {
        _id: '60cc227a47f7e10015fd6912',
        courseCode: 'FRENCH 3101',
        shortName: 'French 3101A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        courseName:
          'French 3101A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227a47f7e10015fd6912',
      },
      {
        _id: '60cc227b47f7e10015fd6914',
        courseCode: 'FRENCH 3102',
        shortName: 'French 3102A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        courseName:
          'French 3102A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227b47f7e10015fd6914',
      },
      {
        _id: '60cc227b47f7e10015fd6916',
        courseCode: 'FRENCH 3103',
        shortName: 'French 3103A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        courseName:
          'French 3103A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227b47f7e10015fd6916',
      },
      {
        _id: '60cc227c47f7e10015fd6918',
        courseCode: 'FRENCH 3104',
        shortName: 'French 3104A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        courseName:
          'French 3104A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227c47f7e10015fd6918',
      },
      {
        _id: '60cc227d47f7e10015fd691a',
        courseCode: 'FRENCH 3105',
        shortName: 'French 3105A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        courseName:
          'French 3105A/B - SPECIAL TOPICS IN LANGUAGE AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227d47f7e10015fd691a',
      },
      {
        _id: '60cc227e47f7e10015fd691c',
        courseCode: 'FRENCH 3140',
        shortName: 'French 3140B - RWANDA: CULTURE, SOCIETY AND RECONSTRUCTION',
        courseName:
          'French 3140B - RWANDA: CULTURE, SOCIETY AND RECONSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227e47f7e10015fd691c',
      },
      {
        _id: '60cc227e47f7e10015fd691e',
        courseCode: 'FRENCH 3150',
        shortName:
          'French 3150A/B - FRENCH CULTURE: EXPERIENTIAL LEARNING IN THE SOUTH OF FRANCE',
        courseName:
          'French 3150A/B - FRENCH CULTURE: EXPERIENTIAL LEARNING IN THE SOUTH OF FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227e47f7e10015fd691e',
      },
      {
        _id: '60cc227e47f7e10015fd6920',
        courseCode: 'FRENCH 3200',
        shortName: 'French 3200 - BUSINESS FRENCH',
        courseName: 'French 3200 - BUSINESS FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227e47f7e10015fd6920',
      },
      {
        _id: '60cc227e47f7e10015fd6922',
        courseCode: 'FRENCH 3201',
        shortName: 'French 3201E - BUSINESS FRENCH WRITING',
        courseName: 'French 3201E - BUSINESS FRENCH WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227e47f7e10015fd6922',
      },
      {
        _id: '60cc227f47f7e10015fd6924',
        courseCode: 'FRENCH 3205',
        shortName: 'French 3205A/B - BUSINESS COMMUNICATION IN FRENCH',
        courseName: 'French 3205A/B - BUSINESS COMMUNICATION IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227f47f7e10015fd6924',
      },
      {
        _id: '60cc227f47f7e10015fd6926',
        courseCode: 'FRENCH 3206',
        shortName: 'French 3206A/B - FRENCH FOR LIBERAL ARTS PROFESSIONS',
        courseName: 'French 3206A/B - FRENCH FOR LIBERAL ARTS PROFESSIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227f47f7e10015fd6926',
      },
      {
        _id: '60cc227f47f7e10015fd6928',
        courseCode: 'FRENCH 3265',
        shortName:
          'French 3265A/B - BRIDGING UNIVERSITY & FRENCH SECOND LANGUAGE CLASSROOMS',
        courseName:
          'French 3265A/B - BRIDGING UNIVERSITY & FRENCH SECOND LANGUAGE CLASSROOMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227f47f7e10015fd6928',
      },
      {
        _id: '60cc227f47f7e10015fd692a',
        courseCode: 'FRENCH 3300',
        shortName: 'French 3300 - INTENSIVE TRANSLATION II',
        courseName: 'French 3300 - INTENSIVE TRANSLATION II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc227f47f7e10015fd692a',
      },
      {
        _id: '60cc228047f7e10015fd692c',
        courseCode: 'FRENCH 3301',
        shortName: 'French 3301A/B - CONTEMPORARY LANGUAGE TRANSLATION',
        courseName: 'French 3301A/B - CONTEMPORARY LANGUAGE TRANSLATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228047f7e10015fd692c',
      },
      {
        _id: '60cc228047f7e10015fd692e',
        courseCode: 'FRENCH 3305',
        shortName: 'French 3305A/B - TRANSLATION PRACTICE',
        courseName: 'French 3305A/B - TRANSLATION PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228047f7e10015fd692e',
      },
      {
        _id: '60cc228147f7e10015fd6930',
        courseCode: 'FRENCH 3306',
        shortName:
          'French 3306A/B - SPECIAL TOPICS IN TRANSLATION: THEORY AND TECHNIQUES',
        courseName:
          'French 3306A/B - SPECIAL TOPICS IN TRANSLATION: THEORY AND TECHNIQUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228147f7e10015fd6930',
      },
      {
        _id: '60cc228147f7e10015fd6932',
        courseCode: 'FRENCH 3307',
        shortName:
          'French 3307A/B - SPECIAL TOPICS IN TRANSLATION: DECEPTIVE COGNATES AND LEXICAL PITFALLS',
        courseName:
          'French 3307A/B - SPECIAL TOPICS IN TRANSLATION: DECEPTIVE COGNATES AND LEXICAL PITFALLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228147f7e10015fd6932',
      },
      {
        _id: '60cc228147f7e10015fd6934',
        courseCode: 'FRENCH 3308',
        shortName:
          'French 3308A/B - SPECIAL TOPICS IN TRANSLATION: MACHINE TRANSLATION',
        courseName:
          'French 3308A/B - SPECIAL TOPICS IN TRANSLATION: MACHINE TRANSLATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228147f7e10015fd6934',
      },
      {
        _id: '60cc228147f7e10015fd6936',
        courseCode: 'FRENCH 3309',
        shortName:
          'French 3309A/B - SPECIAL TOPICS IN TRANSLATION: LITERATURE AND FILM',
        courseName:
          'French 3309A/B - SPECIAL TOPICS IN TRANSLATION: LITERATURE AND FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228147f7e10015fd6936',
      },
      {
        _id: '60cc228147f7e10015fd6938',
        courseCode: 'FRENCH 3405',
        shortName: 'French 3405A/B - LIVING CULTURE - SPECIAL TOPICS',
        courseName: 'French 3405A/B - LIVING CULTURE - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228147f7e10015fd6938',
      },
      {
        _id: '60cc228247f7e10015fd693a',
        courseCode: 'FRENCH 3406',
        shortName: 'French 3406A/B - LIVING CULTURE - SPECIAL TOPICS',
        courseName: 'French 3406A/B - LIVING CULTURE - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228247f7e10015fd693a',
      },
      {
        _id: '60cc228247f7e10015fd693c',
        courseCode: 'FRENCH 3407',
        shortName: 'French 3407A/B - LIVING CULTURE - SPECIAL TOPICS',
        courseName: 'French 3407A/B - LIVING CULTURE - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228247f7e10015fd693c',
      },
      {
        _id: '60cc228247f7e10015fd693e',
        courseCode: 'FRENCH 3408',
        shortName: 'French 3408A/B - LIVING CULTURE - SPECIAL TOPICS',
        courseName: 'French 3408A/B - LIVING CULTURE - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228247f7e10015fd693e',
      },
      {
        _id: '60cc228347f7e10015fd6940',
        courseCode: 'FRENCH 3540',
        shortName:
          'French 3540F/G/Z - CULTURE AND LITERATURE IN SOCIETY: THE MIDDLES AGES IN FRANCE',
        courseName:
          'French 3540F/G/Z - CULTURE AND LITERATURE IN SOCIETY: THE MIDDLES AGES IN FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228347f7e10015fd6940',
      },
      {
        _id: '60cc228347f7e10015fd6942',
        courseCode: 'FRENCH 3541',
        shortName:
          'French 3541F/G/Z - CULTURE AND LITERATURE IN SOCIETY:  THE MIDDLES AGES IN FRANCE',
        courseName:
          'French 3541F/G/Z - CULTURE AND LITERATURE IN SOCIETY:  THE MIDDLES AGES IN FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228347f7e10015fd6942',
      },
      {
        _id: '60cc228447f7e10015fd6944',
        courseCode: 'FRENCH 3542',
        shortName:
          'French 3542F/G/Z - CULTURE AND LITERATURE IN SOCIETY: THE MIDDLES AGES IN FRANCE',
        courseName:
          'French 3542F/G/Z - CULTURE AND LITERATURE IN SOCIETY: THE MIDDLES AGES IN FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228447f7e10015fd6944',
      },
      {
        _id: '60cc228447f7e10015fd6946',
        courseCode: 'FRENCH 3560',
        shortName:
          'French 3560F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRENCH RENAISSANCE',
        courseName:
          'French 3560F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRENCH RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228447f7e10015fd6946',
      },
      {
        _id: '60cc228547f7e10015fd6948',
        courseCode: 'FRENCH 3561',
        shortName:
          'French 3561F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRENCH RENAISSANCE',
        courseName:
          'French 3561F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRENCH RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228547f7e10015fd6948',
      },
      {
        _id: '60cc228647f7e10015fd694a',
        courseCode: 'FRENCH 3562',
        shortName:
          'French 3562F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRENCH RENAISSANCE',
        courseName:
          'French 3562F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRENCH RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228647f7e10015fd694a',
      },
      {
        _id: '60cc228647f7e10015fd694c',
        courseCode: 'FRENCH 3570',
        shortName:
          'French 3570F/G/Z - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 17TH CENTURY',
        courseName:
          'French 3570F/G/Z - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 17TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228647f7e10015fd694c',
      },
      {
        _id: '60cc228747f7e10015fd694e',
        courseCode: 'FRENCH 3571',
        shortName:
          'French 3571F/G/Z - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 17TH CENTURY',
        courseName:
          'French 3571F/G/Z - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 17TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228747f7e10015fd694e',
      },
      {
        _id: '60cc228747f7e10015fd6950',
        courseCode: 'FRENCH 3572',
        shortName:
          'French 3572F/G/Z - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 17TH CENTURY',
        courseName:
          'French 3572F/G/Z - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 17TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228747f7e10015fd6950',
      },
      {
        _id: '60cc228847f7e10015fd6952',
        courseCode: 'FRENCH 3580',
        shortName:
          'French 3580F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 18TH CENTURY',
        courseName:
          'French 3580F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 18TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228847f7e10015fd6952',
      },
      {
        _id: '60cc228847f7e10015fd6954',
        courseCode: 'FRENCH 3581',
        shortName:
          'French 3581F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 18TH CENTURY',
        courseName:
          'French 3581F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 18TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228847f7e10015fd6954',
      },
      {
        _id: '60cc228947f7e10015fd6956',
        courseCode: 'FRENCH 3582',
        shortName:
          'French 3582F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 18TH CENTURY',
        courseName:
          'French 3582F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 18TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228947f7e10015fd6956',
      },
      {
        _id: '60cc228947f7e10015fd6958',
        courseCode: 'FRENCH 3600',
        shortName:
          'French 3600F/G - CULTURE AND LITERATURE IN SOCIETY: 20TH-CENTURY FRANCE',
        courseName:
          'French 3600F/G - CULTURE AND LITERATURE IN SOCIETY: 20TH-CENTURY FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228947f7e10015fd6958',
      },
      {
        _id: '60cc228a47f7e10015fd695a',
        courseCode: 'FRENCH 3601',
        shortName:
          'French 3601F/G - CULTURE AND LITERATURE IN SOCIETY: 20TH-CENTURY FRANCE',
        courseName:
          'French 3601F/G - CULTURE AND LITERATURE IN SOCIETY: 20TH-CENTURY FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228a47f7e10015fd695a',
      },
      {
        _id: '60cc228b47f7e10015fd695c',
        courseCode: 'FRENCH 3602',
        shortName:
          'French 3602F/G - CULTURE AND LITERATURE IN SOCIETY: 20TH-CENTURY FRANCE',
        courseName:
          'French 3602F/G - CULTURE AND LITERATURE IN SOCIETY: 20TH-CENTURY FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228b47f7e10015fd695c',
      },
      {
        _id: '60cc228b47f7e10015fd695e',
        courseCode: 'FRENCH 3610',
        shortName:
          'French 3610F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 3610F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228b47f7e10015fd695e',
      },
      {
        _id: '60cc228c47f7e10015fd6960',
        courseCode: 'FRENCH 3611',
        shortName:
          'French 3611F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 3611F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228c47f7e10015fd6960',
      },
      {
        _id: '60cc228c47f7e10015fd6962',
        courseCode: 'FRENCH 3612',
        shortName:
          'French 3612F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 3612F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228c47f7e10015fd6962',
      },
      {
        _id: '60cc228c47f7e10015fd6964',
        courseCode: 'FRENCH 3613',
        shortName:
          'French 3613F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 3613F/G - AESTHETIC FORMS & PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228c47f7e10015fd6964',
      },
      {
        _id: '60cc228c47f7e10015fd6966',
        courseCode: 'FRENCH 3690',
        shortName:
          'French 3690F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 19TH CENTURY',
        courseName:
          'French 3690F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 19TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228c47f7e10015fd6966',
      },
      {
        _id: '60cc228d47f7e10015fd6968',
        courseCode: 'FRENCH 3691',
        shortName:
          'French 3691F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 19TH CENTURY',
        courseName:
          'French 3691F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 19TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228d47f7e10015fd6968',
      },
      {
        _id: '60cc228d47f7e10015fd696a',
        courseCode: 'FRENCH 3692',
        shortName:
          'French 3692F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 19TH CENTURY',
        courseName:
          'French 3692F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCE IN THE 19TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228d47f7e10015fd696a',
      },
      {
        _id: '60cc228e47f7e10015fd696c',
        courseCode: 'FRENCH 3700',
        shortName:
          'French 3700F/G - MAKING SENSE OF LITERATURE: APPROACHES TO LITERARY READING',
        courseName:
          'French 3700F/G - MAKING SENSE OF LITERATURE: APPROACHES TO LITERARY READING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228e47f7e10015fd696c',
      },
      {
        _id: '60cc228f47f7e10015fd696e',
        courseCode: 'FRENCH 3701',
        shortName:
          'French 3701F/G - MAKING SENSE OF LITERATURE: APPROACHES TO LITERARY READING',
        courseName:
          'French 3701F/G - MAKING SENSE OF LITERATURE: APPROACHES TO LITERARY READING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228f47f7e10015fd696e',
      },
      {
        _id: '60cc228f47f7e10015fd6970',
        courseCode: 'FRENCH 3702',
        shortName:
          'French 3702F/G - MAKING SENSE OF LITERATURE: APPROACHES TO LITERARY READING',
        courseName:
          'French 3702F/G - MAKING SENSE OF LITERATURE: APPROACHES TO LITERARY READING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228f47f7e10015fd6970',
      },
      {
        _id: '60cc228f47f7e10015fd6972',
        courseCode: 'FRENCH 3720',
        shortName:
          'French 3720F/G - CULTURE AND LITERATURE IN SOCIETY: QUEBEC AND FRENCH-CANADA',
        courseName:
          'French 3720F/G - CULTURE AND LITERATURE IN SOCIETY: QUEBEC AND FRENCH-CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc228f47f7e10015fd6972',
      },
      {
        _id: '60cc229047f7e10015fd6974',
        courseCode: 'FRENCH 3721',
        shortName:
          'French 3721F/G - CULTURE AND LITERATURE IN SOCIETY: QUEBEC AND FRENCH-CANADA',
        courseName:
          'French 3721F/G - CULTURE AND LITERATURE IN SOCIETY: QUEBEC AND FRENCH-CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229047f7e10015fd6974',
      },
      {
        _id: '60cc229147f7e10015fd6976',
        courseCode: 'FRENCH 3722',
        shortName:
          'French 3722F/G - CULTURE AND LITERATURE IN SOCIETY: QUEBEC AND FRENCH-CANADA',
        courseName:
          'French 3722F/G - CULTURE AND LITERATURE IN SOCIETY: QUEBEC AND FRENCH-CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229147f7e10015fd6976',
      },
      {
        _id: '60cc229147f7e10015fd6978',
        courseCode: 'FRENCH 3730',
        shortName:
          "French 3730F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCOPHONE WOMEN'S LITERATURE",
        courseName:
          "French 3730F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCOPHONE WOMEN'S LITERATURE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229147f7e10015fd6978',
      },
      {
        _id: '60cc229247f7e10015fd697a',
        courseCode: 'FRENCH 3731',
        shortName:
          "French 3731F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCOPHONE WOMEN'S LITERATURE",
        courseName:
          "French 3731F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCOPHONE WOMEN'S LITERATURE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229247f7e10015fd697a',
      },
      {
        _id: '60cc229247f7e10015fd697c',
        courseCode: 'FRENCH 3732',
        shortName:
          "French 3732F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCOPHONE WOMEN'S LITERATURE",
        courseName:
          "French 3732F/G - CULTURE AND LITERATURE IN SOCIETY: FRANCOPHONE WOMEN'S LITERATURE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229247f7e10015fd697c',
      },
      {
        _id: '60cc229247f7e10015fd697e',
        courseCode: 'FRENCH 3750',
        shortName:
          'French 3750F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRANCOPHONE WORLD',
        courseName:
          'French 3750F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRANCOPHONE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229247f7e10015fd697e',
      },
      {
        _id: '60cc229347f7e10015fd6980',
        courseCode: 'FRENCH 3751',
        shortName:
          'French 3751F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRANCOPHONE WORLD',
        courseName:
          'French 3751F/G - CULTURE AND LITERATURE IN SOCIETY: THE FRANCOPHONE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229347f7e10015fd6980',
      },
      {
        _id: '60cc229347f7e10015fd6982',
        courseCode: 'FRENCH 3752',
        shortName: 'French 3752F/G - TOPICS IN FRANCOPHONE LITERATURE AND FILM',
        courseName:
          'French 3752F/G - TOPICS IN FRANCOPHONE LITERATURE AND FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229347f7e10015fd6982',
      },
      {
        _id: '60cc229347f7e10015fd6984',
        courseCode: 'FRENCH 3753',
        shortName:
          'French 3753F/G - FRENCH & FRANCOPHONE LITERATURE WRITTEN FOR CHILDREN',
        courseName:
          'French 3753F/G - FRENCH & FRANCOPHONE LITERATURE WRITTEN FOR CHILDREN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229347f7e10015fd6984',
      },
      {
        _id: '60cc229347f7e10015fd6986',
        courseCode: 'FRENCH 3760',
        shortName:
          'French 3760F/G - FRENCH-LANGUAGE INDIGENOUS LITERATURE AND CULTURE IN CANADA',
        courseName:
          'French 3760F/G - FRENCH-LANGUAGE INDIGENOUS LITERATURE AND CULTURE IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229347f7e10015fd6986',
      },
      {
        _id: '60cc229447f7e10015fd6988',
        courseCode: 'FRENCH 3810',
        shortName: 'French 3810A/B - SYNTAX IN FRENCH',
        courseName: 'French 3810A/B - SYNTAX IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229447f7e10015fd6988',
      },
      {
        _id: '60cc229447f7e10015fd698a',
        courseCode: 'FRENCH 3830',
        shortName: 'French 3830A/B - PHONETICS AND PHONOLOGY IN FRENCH',
        courseName: 'French 3830A/B - PHONETICS AND PHONOLOGY IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229447f7e10015fd698a',
      },
      {
        _id: '60cc229447f7e10015fd698c',
        courseCode: 'FRENCH 3870',
        shortName: 'French 3870A/B - HISTORY OF THE FRENCH LANGUAGE',
        courseName: 'French 3870A/B - HISTORY OF THE FRENCH LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229447f7e10015fd698c',
      },
      {
        _id: '60cc229447f7e10015fd698e',
        courseCode: 'FRENCH 3890',
        shortName: 'French 3890A/B - FRENCH APPLIED LINGUISTICS',
        courseName: 'French 3890A/B - FRENCH APPLIED LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229447f7e10015fd698e',
      },
      {
        _id: '60cc229547f7e10015fd6990',
        courseCode: 'FRENCH 3894',
        shortName: 'French 3894A/B - FRENCH PRONUNCIATION 1',
        courseName: 'French 3894A/B - FRENCH PRONUNCIATION 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229547f7e10015fd6990',
      },
      {
        _id: '60cc229547f7e10015fd6992',
        courseCode: 'FRENCH 3900',
        shortName: 'French 3900 - LANGUAGE (ADVANCED LEVEL III)',
        courseName: 'French 3900 - LANGUAGE (ADVANCED LEVEL III)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229547f7e10015fd6992',
      },
      {
        _id: '60cc229647f7e10015fd6994',
        courseCode: 'FRENCH 3905',
        shortName: 'French 3905A/B - READING TEXTS',
        courseName: 'French 3905A/B - READING TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229647f7e10015fd6994',
      },
      {
        _id: '60cc229647f7e10015fd6996',
        courseCode: 'FRENCH 3906',
        shortName: 'French 3906A/B - SPEAKING TEXTS',
        courseName: 'French 3906A/B - SPEAKING TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229647f7e10015fd6996',
      },
      {
        _id: '60cc229647f7e10015fd6998',
        courseCode: 'FRENCH 3907',
        shortName: 'French 3907A/B - WRITING TEXTS',
        courseName: 'French 3907A/B - WRITING TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229647f7e10015fd6998',
      },
      {
        _id: '60cc229647f7e10015fd699a',
        courseCode: 'FRENCH 3908',
        shortName: 'French 3908A/B - INTENSIVE GRAMMAR REVIEW',
        courseName: 'French 3908A/B - INTENSIVE GRAMMAR REVIEW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229647f7e10015fd699a',
      },
      {
        _id: '60cc229647f7e10015fd699c',
        courseCode: 'FRENCH 4040',
        shortName: 'French 4040A/B - OLD FRENCH LANGUAGE',
        courseName: 'French 4040A/B - OLD FRENCH LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229647f7e10015fd699c',
      },
      {
        _id: '60cc229747f7e10015fd699e',
        courseCode: 'FRENCH 4100',
        shortName: 'French 4100F/G - RESEARCH PAPER',
        courseName: 'French 4100F/G - RESEARCH PAPER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229747f7e10015fd699e',
      },
      {
        _id: '60cc229747f7e10015fd69a0',
        courseCode: 'FRENCH 4101',
        shortName:
          'French 4101F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4101F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229747f7e10015fd69a0',
      },
      {
        _id: '60cc229847f7e10015fd69a2',
        courseCode: 'FRENCH 4102',
        shortName:
          'French 4102F/G/Z - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4102F/G/Z - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229847f7e10015fd69a2',
      },
      {
        _id: '60cc229847f7e10015fd69a4',
        courseCode: 'FRENCH 4103',
        shortName:
          'French 4103F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4103F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229847f7e10015fd69a4',
      },
      {
        _id: '60cc229847f7e10015fd69a6',
        courseCode: 'FRENCH 4104',
        shortName:
          'French 4104F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4104F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229847f7e10015fd69a6',
      },
      {
        _id: '60cc229947f7e10015fd69a8',
        courseCode: 'FRENCH 4105',
        shortName:
          'French 4105F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4105F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229947f7e10015fd69a8',
      },
      {
        _id: '60cc229947f7e10015fd69aa',
        courseCode: 'FRENCH 4106',
        shortName:
          'French 4106E - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4106E - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229947f7e10015fd69aa',
      },
      {
        _id: '60cc229947f7e10015fd69ac',
        courseCode: 'FRENCH 4107',
        shortName:
          'French 4107A/B - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4107A/B - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229947f7e10015fd69ac',
      },
      {
        _id: '60cc229947f7e10015fd69ae',
        courseCode: 'FRENCH 4108',
        shortName:
          'French 4108F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        courseName:
          'French 4108F/G - ESTHETIC FORMS AND PRACTICES: SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229947f7e10015fd69ae',
      },
      {
        _id: '60cc229a47f7e10015fd69b0',
        courseCode: 'FRENCH 4110',
        shortName: 'French 4110F/G - SENIOR SEMINAR',
        courseName: 'French 4110F/G - SENIOR SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229a47f7e10015fd69b0',
      },
      {
        _id: '60cc229a47f7e10015fd69b2',
        courseCode: 'FRENCH 4111',
        shortName: 'French 4111F/G - SENIOR SEMINAR',
        courseName: 'French 4111F/G - SENIOR SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229a47f7e10015fd69b2',
      },
      {
        _id: '60cc229a47f7e10015fd69b4',
        courseCode: 'FRENCH 4112',
        shortName: 'French 4112F/G - SENIOR SEMINAR',
        courseName: 'French 4112F/G - SENIOR SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229a47f7e10015fd69b4',
      },
      {
        _id: '60cc229a47f7e10015fd69b6',
        courseCode: 'FRENCH 4113',
        shortName: 'French 4113F/G - SENIOR SEMINAR IN FRENCH LITERATURE',
        courseName: 'French 4113F/G - SENIOR SEMINAR IN FRENCH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229a47f7e10015fd69b6',
      },
      {
        _id: '60cc229b47f7e10015fd69b8',
        courseCode: 'FRENCH 4114',
        shortName: 'French 4114F/G - SENIOR SEMINAR IN FRENCH LITERATURE',
        courseName: 'French 4114F/G - SENIOR SEMINAR IN FRENCH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229b47f7e10015fd69b8',
      },
      {
        _id: '60cc229b47f7e10015fd69ba',
        courseCode: 'FRENCH 4300',
        shortName: 'French 4300 - INTENSIVE TRANSLATION III',
        courseName: 'French 4300 - INTENSIVE TRANSLATION III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229b47f7e10015fd69ba',
      },
      {
        _id: '60cc229b47f7e10015fd69bc',
        courseCode: 'FRENCH 4600',
        shortName: 'French 4600E - RESEARCH METHODS AND INDEPENDENT PAPER',
        courseName: 'French 4600E - RESEARCH METHODS AND INDEPENDENT PAPER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229b47f7e10015fd69bc',
      },
      {
        _id: '60cc229c47f7e10015fd69be',
        courseCode: 'FRENCH 4811',
        shortName: 'French 4811F/G - ADVANCED SYNTAX IN FRENCH',
        courseName: 'French 4811F/G - ADVANCED SYNTAX IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229c47f7e10015fd69be',
      },
      {
        _id: '60cc229c47f7e10015fd69c0',
        courseCode: 'FRENCH 4821',
        shortName: 'French 4821F/G - MORPHOLOGY IN FRENCH',
        courseName: 'French 4821F/G - MORPHOLOGY IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229c47f7e10015fd69c0',
      },
      {
        _id: '60cc229d47f7e10015fd69c2',
        courseCode: 'FRENCH 4830',
        shortName:
          'French 4830F/G - ADVANCED PHONETICS AND PHONOLOGY IN FRENCH',
        courseName:
          'French 4830F/G - ADVANCED PHONETICS AND PHONOLOGY IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229d47f7e10015fd69c2',
      },
      {
        _id: '60cc229d47f7e10015fd69c4',
        courseCode: 'FRENCH 4841',
        shortName: 'French 4841F/G - SEMANTICS IN FRENCH',
        courseName: 'French 4841F/G - SEMANTICS IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229d47f7e10015fd69c4',
      },
      {
        _id: '60cc229d47f7e10015fd69c6',
        courseCode: 'FRENCH 4850',
        shortName: 'French 4850F/G - SOCIOLINGUISTICS IN FRENCH',
        courseName: 'French 4850F/G - SOCIOLINGUISTICS IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229d47f7e10015fd69c6',
      },
      {
        _id: '60cc229d47f7e10015fd69c8',
        courseCode: 'FRENCH 4881',
        shortName: 'French 4881F/G - ADVANCED LINGUISTICS IN FRENCH',
        courseName: 'French 4881F/G - ADVANCED LINGUISTICS IN FRENCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229d47f7e10015fd69c8',
      },
      {
        _id: '60cc229d47f7e10015fd69ca',
        courseCode: 'FRENCH 4890',
        shortName: 'French 4890A/B - FRENCH APPLIED LINGUISTICS II',
        courseName: 'French 4890A/B - FRENCH APPLIED LINGUISTICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229d47f7e10015fd69ca',
      },
      {
        _id: '60cc229e47f7e10015fd69cc',
        courseCode: 'FRENCH 4900',
        shortName:
          'French 4900A/B - LANGUAGE (ADVANCED LEVEL IV, SPECIAL TOPIC)',
        courseName:
          'French 4900A/B - LANGUAGE (ADVANCED LEVEL IV, SPECIAL TOPIC)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229e47f7e10015fd69cc',
      },
      {
        _id: '60cc229e47f7e10015fd69ce',
        courseCode: 'FRENCH 4901',
        shortName:
          'French 4901A/B - LANGUAGE (ADVANCED  LEVEL IV, SPECIAL TOPIC)',
        courseName:
          'French 4901A/B - LANGUAGE (ADVANCED  LEVEL IV, SPECIAL TOPIC)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229e47f7e10015fd69ce',
      },
      {
        _id: '60cc229f47f7e10015fd69d0',
        courseCode: 'FRENCH 4902',
        shortName: 'French 4902A/B - ADVANCED FRENCH LANGUAGE',
        courseName: 'French 4902A/B - ADVANCED FRENCH LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229f47f7e10015fd69d0',
      },
      {
        _id: '60cc229f47f7e10015fd69d2',
        courseCode: 'FRENCH 4903',
        shortName: 'French 4903A/B - ADVANCED FRENCH COMMUNICATION',
        courseName: 'French 4903A/B - ADVANCED FRENCH COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229f47f7e10015fd69d2',
      },
      {
        _id: '60cc229f47f7e10015fd69d4',
        courseCode: 'GSWS 1020',
        shortName:
          "Gender, Sexuality, and Women’s Studies 1020E - INTRODUCTION TO GENDER AND WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 1020E - INTRODUCTION TO GENDER AND WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc229f47f7e10015fd69d4',
      },
      {
        _id: '60cc22a047f7e10015fd69d6',
        courseCode: 'GSWS 1021',
        shortName:
          'Gender, Sexuality, and Women’s Studies 1021F/G - INTRODUCTION TO SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 1021F/G - INTRODUCTION TO SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a047f7e10015fd69d6',
      },
      {
        _id: '60cc22a047f7e10015fd69d8',
        courseCode: 'GSWS 1022',
        shortName:
          'Gender, Sexuality, and Women’s Studies 1022F/G - GENDER, JUSTICE, CHANGE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 1022F/G - GENDER, JUSTICE, CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a047f7e10015fd69d8',
      },
      {
        _id: '60cc22a047f7e10015fd69db',
        courseCode: 'GSWS 1024',
        shortName:
          'Gender, Sexuality, and Women’s Studies 1024F/G - INTRODUCTION TO EQUITY, DIVERSITY, AND HUMAN RIGHTS',
        courseName:
          'Gender, Sexuality, and Women’s Studies 1024F/G - INTRODUCTION TO EQUITY, DIVERSITY, AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a047f7e10015fd69db',
      },
      {
        _id: '60cc22a147f7e10015fd69dd',
        courseCode: 'GSWS 2139',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2139A/B - SOCIAL HISTORY OF WOMEN IN CANADA',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2139A/B - SOCIAL HISTORY OF WOMEN IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a147f7e10015fd69dd',
      },
      {
        _id: '60cc22a147f7e10015fd69e0',
        courseCode: 'GSWS 2159',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2159A/B - SPECIAL TOPICS ON WOMEN AND THE CREATIVE ARTS',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2159A/B - SPECIAL TOPICS ON WOMEN AND THE CREATIVE ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a147f7e10015fd69e0',
      },
      {
        _id: '60cc22a147f7e10015fd69e2',
        courseCode: 'GSWS 2160',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2160A/B - INTIMATE RELATIONS: SEX, GENDER AND LOVE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2160A/B - INTIMATE RELATIONS: SEX, GENDER AND LOVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a147f7e10015fd69e2',
      },
      {
        _id: '60cc22a147f7e10015fd69e4',
        courseCode: 'GSWS 2161',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2161A/B - WOMEN AND POPULAR CULTURE: GARBO TO GAGA',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2161A/B - WOMEN AND POPULAR CULTURE: GARBO TO GAGA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a147f7e10015fd69e4',
      },
      {
        _id: '60cc22a247f7e10015fd69e6',
        courseCode: 'GSWS 2162',
        shortName: 'Gender, Sexuality, and Women’s Studies 2162A/B - THE BODY',
        courseName: 'Gender, Sexuality, and Women’s Studies 2162A/B - THE BODY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a247f7e10015fd69e6',
      },
      {
        _id: '60cc22a247f7e10015fd69e9',
        courseCode: 'GSWS 2164',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2164A/B - GENDER AND FASHION',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2164A/B - GENDER AND FASHION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a247f7e10015fd69e9',
      },
      {
        _id: '60cc22a247f7e10015fd69eb',
        courseCode: 'GSWS 2165',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2165A/B - GENDER, MIGRATION AND CLIMATE CHANGE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2165A/B - GENDER, MIGRATION AND CLIMATE CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a247f7e10015fd69eb',
      },
      {
        _id: '60cc22a247f7e10015fd69ed',
        courseCode: 'GSWS 2166',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2166A/B - D-I-Y: GENDERS FOR THE 21ST CENTURY',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2166A/B - D-I-Y: GENDERS FOR THE 21ST CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a247f7e10015fd69ed',
      },
      {
        _id: '60cc22a347f7e10015fd69ef',
        courseCode: 'GSWS 2167',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2167A/B - QUEER(ING) POPULAR CULTURE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2167A/B - QUEER(ING) POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a347f7e10015fd69ef',
      },
      {
        _id: '60cc22a347f7e10015fd69f1',
        courseCode: 'GSWS 2200',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2200E - ADVANCED INTERDISCIPLINARY SURVEY OF WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2200E - ADVANCED INTERDISCIPLINARY SURVEY OF WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a347f7e10015fd69f1',
      },
      {
        _id: '60cc22a347f7e10015fd69f3',
        courseCode: 'GSWS 2203',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2203F/G - FUTURE SEX: SEX AND SCIENCE FICTION',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2203F/G - FUTURE SEX: SEX AND SCIENCE FICTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a347f7e10015fd69f3',
      },
      {
        _id: '60cc22a347f7e10015fd69f5',
        courseCode: 'GSWS 2205',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2205F/G - MAKING MEN: CRITICAL STUDIES IN MASCULINITY',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2205F/G - MAKING MEN: CRITICAL STUDIES IN MASCULINITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a347f7e10015fd69f5',
      },
      {
        _id: '60cc22a447f7e10015fd69f8',
        courseCode: 'GSWS 2211',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2211F/G - SPECIAL TOPICS IN WOMEN'S WRITING",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2211F/G - SPECIAL TOPICS IN WOMEN'S WRITING",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a447f7e10015fd69f8',
      },
      {
        _id: '60cc22a447f7e10015fd69fa',
        courseCode: 'GSWS 2212',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2212F/G - GENDER, BODIES, WORK, VALUE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2212F/G - GENDER, BODIES, WORK, VALUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a447f7e10015fd69fa',
      },
      {
        _id: '60cc22a447f7e10015fd69fc',
        courseCode: 'GSWS 2220',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2220E - FEMINIST THEORY AND PRACTICE ACROSS THE DISCIPLINES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2220E - FEMINIST THEORY AND PRACTICE ACROSS THE DISCIPLINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a447f7e10015fd69fc',
      },
      {
        _id: '60cc22a447f7e10015fd69fe',
        courseCode: 'GSWS 2223',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2223F/G - GENDER, SEXUALITY AND DISABILITY',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2223F/G - GENDER, SEXUALITY AND DISABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a447f7e10015fd69fe',
      },
      {
        _id: '60cc22a547f7e10015fd6a00',
        courseCode: 'GSWS 2225',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2225F/G - INTRO TO GIRLHOOD STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2225F/G - INTRO TO GIRLHOOD STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a547f7e10015fd6a00',
      },
      {
        _id: '60cc22a547f7e10015fd6a02',
        courseCode: 'GSWS 2233',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2233F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2233F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a547f7e10015fd6a02',
      },
      {
        _id: '60cc22a547f7e10015fd6a04',
        courseCode: 'GSWS 2240',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2240F/G - FOUNDATIONS OF FEMINIST THOUGHT',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2240F/G - FOUNDATIONS OF FEMINIST THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a547f7e10015fd6a04',
      },
      {
        _id: '60cc22a547f7e10015fd6a06',
        courseCode: 'GSWS 2243',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2243F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2243F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a547f7e10015fd6a06',
      },
      {
        _id: '60cc22a647f7e10015fd6a08',
        courseCode: 'GSWS 2244',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2244 - WOMEN AND HEALTH',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2244 - WOMEN AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a647f7e10015fd6a08',
      },
      {
        _id: '60cc22a647f7e10015fd6a0a',
        courseCode: 'GSWS 2246',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2246A/B - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2246A/B - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a647f7e10015fd6a0a',
      },
      {
        _id: '60cc22a647f7e10015fd6a0c',
        courseCode: 'GSWS 2247',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2247A/B - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2247A/B - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a647f7e10015fd6a0c',
      },
      {
        _id: '60cc22a647f7e10015fd6a0e',
        courseCode: 'GSWS 2251',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2251F/G - SPECIAL TOPICS IN WOMEN AND WORK',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2251F/G - SPECIAL TOPICS IN WOMEN AND WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a647f7e10015fd6a0e',
      },
      {
        _id: '60cc22a747f7e10015fd6a10',
        courseCode: 'GSWS 2252',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2252F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2252F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a747f7e10015fd6a10',
      },
      {
        _id: '60cc22a747f7e10015fd6a12',
        courseCode: 'GSWS 2253',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2253E - QUEER SUBJECTS',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2253E - QUEER SUBJECTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a747f7e10015fd6a12',
      },
      {
        _id: '60cc22a747f7e10015fd6a14',
        courseCode: 'GSWS 2254',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2254E - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2254E - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a747f7e10015fd6a14',
      },
      {
        _id: '60cc22a747f7e10015fd6a16',
        courseCode: 'GSWS 2257',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2257E - FEMINIST THEORY AND PRACTICE IN THE SOCIAL SCIENCES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2257E - FEMINIST THEORY AND PRACTICE IN THE SOCIAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a747f7e10015fd6a16',
      },
      {
        _id: '60cc22a747f7e10015fd6a18',
        courseCode: 'GSWS 2259',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2259F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2259F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a747f7e10015fd6a18',
      },
      {
        _id: '60cc22a847f7e10015fd6a1a',
        courseCode: 'GSWS 2260',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2260 - WOMEN, LAW AND SOCIAL CHANGE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2260 - WOMEN, LAW AND SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a847f7e10015fd6a1a',
      },
      {
        _id: '60cc22a847f7e10015fd6a1c',
        courseCode: 'GSWS 2262',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2262E - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2262E - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a847f7e10015fd6a1c',
      },
      {
        _id: '60cc22a847f7e10015fd6a1e',
        courseCode: 'GSWS 2263',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2263F/G - INTERSECTIONS: RACE, CLASS, AND SEXUALITY',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2263F/G - INTERSECTIONS: RACE, CLASS, AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a847f7e10015fd6a1e',
      },
      {
        _id: '60cc22a847f7e10015fd6a20',
        courseCode: 'GSWS 2264',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2264F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2264F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a847f7e10015fd6a20',
      },
      {
        _id: '60cc22a947f7e10015fd6a22',
        courseCode: 'GSWS 2265',
        shortName:
          "Gender, Sexuality, and Women’s Studies 2265F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 2265F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a947f7e10015fd6a22',
      },
      {
        _id: '60cc22a947f7e10015fd6a24',
        courseCode: 'GSWS 2270',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2270A/B - WOMEN, LAW AND SOCIAL CHANGE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2270A/B - WOMEN, LAW AND SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a947f7e10015fd6a24',
      },
      {
        _id: '60cc22a947f7e10015fd6a26',
        courseCode: 'GSWS 2273',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2273E - SEXUAL SUBJECTS',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2273E - SEXUAL SUBJECTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a947f7e10015fd6a26',
      },
      {
        _id: '60cc22a947f7e10015fd6a28',
        courseCode: 'GSWS 2274',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2274F/G - INTRODUCTION TO TRANSGENDER STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2274F/G - INTRODUCTION TO TRANSGENDER STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22a947f7e10015fd6a28',
      },
      {
        _id: '60cc22aa47f7e10015fd6a2a',
        courseCode: 'GSWS 2275',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2275F/G - HETEROSEXUALITIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2275F/G - HETEROSEXUALITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22aa47f7e10015fd6a2a',
      },
      {
        _id: '60cc22aa47f7e10015fd6a2c',
        courseCode: 'GSWS 2283',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2283F/G - DESIRING WOMEN',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2283F/G - DESIRING WOMEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22aa47f7e10015fd6a2c',
      },
      {
        _id: '60cc22aa47f7e10015fd6a2e',
        courseCode: 'GSWS 2817',
        shortName:
          'Gender, Sexuality, and Women’s Studies 2817F/G - QUEER NORTH AMERICAN HISTORIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 2817F/G - QUEER NORTH AMERICAN HISTORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22aa47f7e10015fd6a2e',
      },
      {
        _id: '60cc22aa47f7e10015fd6a30',
        courseCode: 'GSWS 3133',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3133F/G - LESBIAN LIVES AND CULTURES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3133F/G - LESBIAN LIVES AND CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22aa47f7e10015fd6a30',
      },
      {
        _id: '60cc22ab47f7e10015fd6a32',
        courseCode: 'GSWS 3153',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3153F/G - BAD GIRLS: DISSIDENT WOMEN AND POPULAR CULTURE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3153F/G - BAD GIRLS: DISSIDENT WOMEN AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ab47f7e10015fd6a32',
      },
      {
        _id: '60cc22ab47f7e10015fd6a34',
        courseCode: 'GSWS 3163',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3163F/G - CONTEMPORARY QUEER TOPICS',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3163F/G - CONTEMPORARY QUEER TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ab47f7e10015fd6a34',
      },
      {
        _id: '60cc22ab47f7e10015fd6a36',
        courseCode: 'GSWS 3173',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3173F/G - INTRODUCTION TO QUEER THEORY',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3173F/G - INTRODUCTION TO QUEER THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ab47f7e10015fd6a36',
      },
      {
        _id: '60cc22ab47f7e10015fd6a38',
        courseCode: 'GSWS 3305',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3305F/G - GENDER, SEXUALITY AND CULTURAL RESISTANCE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3305F/G - GENDER, SEXUALITY AND CULTURAL RESISTANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ab47f7e10015fd6a38',
      },
      {
        _id: '60cc22ab47f7e10015fd6a3a',
        courseCode: 'GSWS 3311',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3311F/G - SPECIAL TOPICS IN WOMEN'S WRITING",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3311F/G - SPECIAL TOPICS IN WOMEN'S WRITING",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ab47f7e10015fd6a3a',
      },
      {
        _id: '60cc22ac47f7e10015fd6a3c',
        courseCode: 'GSWS 3312',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3312F/G - GENDER AND THE ENVIRONMENT',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3312F/G - GENDER AND THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ac47f7e10015fd6a3c',
      },
      {
        _id: '60cc22ac47f7e10015fd6a3e',
        courseCode: 'GSWS 3315',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3315F/G - SPEC TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3315F/G - SPEC TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ac47f7e10015fd6a3e',
      },
      {
        _id: '60cc22ac47f7e10015fd6a40',
        courseCode: 'GSWS 3316',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3316F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3316F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ac47f7e10015fd6a40',
      },
      {
        _id: '60cc22ad47f7e10015fd6a44',
        courseCode: 'GSWS 3324',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3324F/G - CONTEMPORARY TOPICS IN CRITICAL RACE STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3324F/G - CONTEMPORARY TOPICS IN CRITICAL RACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ad47f7e10015fd6a44',
      },
      {
        _id: '60cc22ad47f7e10015fd6a46',
        courseCode: 'GSWS 3330',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3330F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3330F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ad47f7e10015fd6a46',
      },
      {
        _id: '60cc22ad47f7e10015fd6a48',
        courseCode: 'GSWS 3331',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3331F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3331F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ad47f7e10015fd6a48',
      },
      {
        _id: '60cc22ae47f7e10015fd6a4a',
        courseCode: 'GSWS 3333',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3333F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3333F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ae47f7e10015fd6a4a',
      },
      {
        _id: '60cc22ae47f7e10015fd6a4c',
        courseCode: 'GSWS 3343',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3343F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3343F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ae47f7e10015fd6a4c',
      },
      {
        _id: '60cc22ae47f7e10015fd6a4e',
        courseCode: 'GSWS 3345',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3345F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3345F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ae47f7e10015fd6a4e',
      },
      {
        _id: '60cc22ae47f7e10015fd6a50',
        courseCode: 'GSWS 3350',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3350F/G - FEMINISM ACROSS BORDERS',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3350F/G - FEMINISM ACROSS BORDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ae47f7e10015fd6a50',
      },
      {
        _id: '60cc22af47f7e10015fd6a52',
        courseCode: 'GSWS 3355',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3355E - ADVANCED TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3355E - ADVANCED TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22af47f7e10015fd6a52',
      },
      {
        _id: '60cc22af47f7e10015fd6a54',
        courseCode: 'GSWS 3356',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3356F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3356F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22af47f7e10015fd6a54',
      },
      {
        _id: '60cc22af47f7e10015fd6a56',
        courseCode: 'GSWS 3357',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3357F/G - ADVANCED TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3357F/G - ADVANCED TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22af47f7e10015fd6a56',
      },
      {
        _id: '60cc22b047f7e10015fd6a58',
        courseCode: 'GSWS 3358',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3358F/G - ADVANCED TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3358F/G - ADVANCED TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b047f7e10015fd6a58',
      },
      {
        _id: '60cc22b047f7e10015fd6a5a',
        courseCode: 'GSWS 3359',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3359F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3359F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b047f7e10015fd6a5a',
      },
      {
        _id: '60cc22b047f7e10015fd6a5c',
        courseCode: 'GSWS 3362',
        shortName:
          "Gender, Sexuality, and Women’s Studies 3362F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 3362F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b047f7e10015fd6a5c',
      },
      {
        _id: '60cc22b047f7e10015fd6a5e',
        courseCode: 'GSWS 3363',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3363F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3363F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b047f7e10015fd6a5e',
      },
      {
        _id: '60cc22b147f7e10015fd6a60',
        courseCode: 'GSWS 3373',
        shortName:
          'Gender, Sexuality, and Women’s Studies 3373F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 3373F/G - FEMINIST TOPICS IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b147f7e10015fd6a60',
      },
      {
        _id: '60cc22b147f7e10015fd6a62',
        courseCode: 'GSWS 4455',
        shortName:
          "Gender, Sexuality, and Women’s Studies 4455E - HONOURS THESIS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 4455E - HONOURS THESIS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b147f7e10015fd6a62',
      },
      {
        _id: '60cc22b147f7e10015fd6a64',
        courseCode: 'GSWS 4456',
        shortName:
          'Gender, Sexuality, and Women’s Studies 4456F/G - ADVANCED SEMINAR IN FEMINIST THEORY & PRACTICE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 4456F/G - ADVANCED SEMINAR IN FEMINIST THEORY & PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b147f7e10015fd6a64',
      },
      {
        _id: '60cc22b147f7e10015fd6a66',
        courseCode: 'GSWS 4458',
        shortName:
          'Gender, Sexuality, and Women’s Studies 4458F/G - ADVANCED SEMINAR IN FEMINIST THEORY AND PRACTICE',
        courseName:
          'Gender, Sexuality, and Women’s Studies 4458F/G - ADVANCED SEMINAR IN FEMINIST THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b147f7e10015fd6a66',
      },
      {
        _id: '60cc22b247f7e10015fd6a68',
        courseCode: 'GSWS 4459',
        shortName:
          "Gender, Sexuality, and Women’s Studies 4459F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 4459F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b247f7e10015fd6a68',
      },
      {
        _id: '60cc22b247f7e10015fd6a6a',
        courseCode: 'GSWS 4460',
        shortName:
          "Gender, Sexuality, and Women’s Studies 4460F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 4460F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b247f7e10015fd6a6a',
      },
      {
        _id: '60cc22b247f7e10015fd6a6c',
        courseCode: 'GSWS 4461',
        shortName:
          "Gender, Sexuality, and Women’s Studies 4461F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 4461F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b247f7e10015fd6a6c',
      },
      {
        _id: '60cc22b347f7e10015fd6a6e',
        courseCode: 'GSWS 4463',
        shortName:
          'Gender, Sexuality, and Women’s Studies 4463F/G - ADVANCED SEMINAR IN SEXUALITY STUDIES',
        courseName:
          'Gender, Sexuality, and Women’s Studies 4463F/G - ADVANCED SEMINAR IN SEXUALITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b347f7e10015fd6a6e',
      },
      {
        _id: '60cc22b347f7e10015fd6a70',
        courseCode: 'GSWS 4464',
        shortName:
          "Gender, Sexuality, and Women’s Studies 4464F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 4464F/G - SPECIAL TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b347f7e10015fd6a70',
      },
      {
        _id: '60cc22b347f7e10015fd6a72',
        courseCode: 'GSWS 4465',
        shortName:
          "Gender, Sexuality, and Women’s Studies 4465E - ADVANCED TOPICS IN WOMEN'S STUDIES",
        courseName:
          "Gender, Sexuality, and Women’s Studies 4465E - ADVANCED TOPICS IN WOMEN'S STUDIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b347f7e10015fd6a72',
      },
      {
        _id: '60cc22b447f7e10015fd6a74',
        courseCode: 'GSWS 4607',
        shortName:
          'Gender, Sexuality, and Women’s Studies 4607F/G - HISTORY OF WOMEN AND GENDER RELATIONS IN AFRICA',
        courseName:
          'Gender, Sexuality, and Women’s Studies 4607F/G - HISTORY OF WOMEN AND GENDER RELATIONS IN AFRICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b447f7e10015fd6a74',
      },
      {
        _id: '60cc22b447f7e10015fd6a76',
        courseCode: 'GEOGRAPH 0012',
        shortName: 'Geography 0012 - INTRODUCTION TO WORLD GEOGRAPHY',
        courseName: 'Geography 0012 - INTRODUCTION TO WORLD GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b447f7e10015fd6a76',
      },
      {
        _id: '60cc22b447f7e10015fd6a78',
        courseCode: 'GEOGRAPH 1100',
        shortName: 'Geography 1100 - FUNDAMENTALS OF GEOGRAPHY',
        courseName: 'Geography 1100 - FUNDAMENTALS OF GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b447f7e10015fd6a78',
      },
      {
        _id: '60cc22b547f7e10015fd6a7a',
        courseCode: 'GEOGRAPH 1200',
        shortName: 'Geography 1200A/B - THE CLIMATE EMERGENCY',
        courseName: 'Geography 1200A/B - THE CLIMATE EMERGENCY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b547f7e10015fd6a7a',
      },
      {
        _id: '60cc22b547f7e10015fd6a7c',
        courseCode: 'GEOGRAPH 1300',
        shortName:
          'Geography 1300A/B - INTRODUCTION TO THE PHYSICAL ENVIRONMENT',
        courseName:
          'Geography 1300A/B - INTRODUCTION TO THE PHYSICAL ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b547f7e10015fd6a7c',
      },
      {
        _id: '60cc22b547f7e10015fd6a7e',
        courseCode: 'GEOGRAPH 1400',
        shortName: 'Geography 1400F/G - INTRODUCTION TO THE HUMAN ENVIRONMENT',
        courseName: 'Geography 1400F/G - INTRODUCTION TO THE HUMAN ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b547f7e10015fd6a7e',
      },
      {
        _id: '60cc22b547f7e10015fd6a80',
        courseCode: 'GEOGRAPH 1500',
        shortName: 'Geography 1500F/G - ENVIRONMENT AND DEVELOPMENT CHALLENGES',
        courseName:
          'Geography 1500F/G - ENVIRONMENT AND DEVELOPMENT CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b547f7e10015fd6a80',
      },
      {
        _id: '60cc22b647f7e10015fd6a82',
        courseCode: 'GEOGRAPH 2010',
        shortName: 'Geography 2010A/B - GEOGRAPHY OF CANADA',
        courseName: 'Geography 2010A/B - GEOGRAPHY OF CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b647f7e10015fd6a82',
      },
      {
        _id: '60cc22b647f7e10015fd6a84',
        courseCode: 'GEOGRAPH 2011',
        shortName: 'Geography 2011A/B - ONTARIO AND THE GREAT LAKES',
        courseName: 'Geography 2011A/B - ONTARIO AND THE GREAT LAKES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b647f7e10015fd6a84',
      },
      {
        _id: '60cc22b647f7e10015fd6a86',
        courseCode: 'GEOGRAPH 2030',
        shortName: 'Geography 2030A/B - AFRICA SOUTH OF THE SAHARA',
        courseName: 'Geography 2030A/B - AFRICA SOUTH OF THE SAHARA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b647f7e10015fd6a86',
      },
      {
        _id: '60cc22b647f7e10015fd6a88',
        courseCode: 'GEOGRAPH 2041',
        shortName: 'Geography 2041A/B - GEOGRAPHY OF CHINA',
        courseName: 'Geography 2041A/B - GEOGRAPHY OF CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b647f7e10015fd6a88',
      },
      {
        _id: '60cc22b747f7e10015fd6a8a',
        courseCode: 'GEOGRAPH 2060',
        shortName: 'Geography 2060A/B - WORLD CITIES',
        courseName: 'Geography 2060A/B - WORLD CITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b747f7e10015fd6a8a',
      },
      {
        _id: '60cc22b747f7e10015fd6a8c',
        courseCode: 'GEOGRAPH 2071',
        shortName: 'Geography 2071F/G - WORLD RIVERS',
        courseName: 'Geography 2071F/G - WORLD RIVERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b747f7e10015fd6a8c',
      },
      {
        _id: '60cc22b747f7e10015fd6a8e',
        courseCode: 'GEOGRAPH 2090',
        shortName: 'Geography 2090A/B - SPACE EXPLORATION',
        courseName: 'Geography 2090A/B - SPACE EXPLORATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b747f7e10015fd6a8e',
      },
      {
        _id: '60cc22b747f7e10015fd6a90',
        courseCode: 'GEOGRAPH 2130',
        shortName:
          'Geography 2130Y - FIELD GEOGRAPHY AND GEOLOGY OF SOUTHWESTERN ONTARIO',
        courseName:
          'Geography 2130Y - FIELD GEOGRAPHY AND GEOLOGY OF SOUTHWESTERN ONTARIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b747f7e10015fd6a90',
      },
      {
        _id: '60cc22b847f7e10015fd6a92',
        courseCode: 'GEOGRAPH 2131',
        shortName: 'Geography 2131A/B - THE NATURAL ENVIRONMENT',
        courseName: 'Geography 2131A/B - THE NATURAL ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b847f7e10015fd6a92',
      },
      {
        _id: '60cc22b847f7e10015fd6a94',
        courseCode: 'GEOGRAPH 2132',
        shortName: 'Geography 2132A/B - DIGITAL EARTH',
        courseName: 'Geography 2132A/B - DIGITAL EARTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b847f7e10015fd6a94',
      },
      {
        _id: '60cc22b847f7e10015fd6a96',
        courseCode: 'GEOGRAPH 2133',
        shortName: 'Geography 2133A/B - CLIMATE CHANGE',
        courseName: 'Geography 2133A/B - CLIMATE CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b847f7e10015fd6a96',
      },
      {
        _id: '60cc22b847f7e10015fd6a98',
        courseCode: 'GEOGRAPH 2142',
        shortName: 'Geography 2142A/B - GEOPOLITICS',
        courseName: 'Geography 2142A/B - GEOPOLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b847f7e10015fd6a98',
      },
      {
        _id: '60cc22b947f7e10015fd6a9a',
        courseCode: 'GEOGRAPH 2143',
        shortName:
          'Geography 2143A/B - FOUNDATIONS OF THE GEOGRAPHY OF WORLD BUSINESS',
        courseName:
          'Geography 2143A/B - FOUNDATIONS OF THE GEOGRAPHY OF WORLD BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b947f7e10015fd6a9a',
      },
      {
        _id: '60cc22b947f7e10015fd6a9c',
        courseCode: 'GEOGRAPH 2144',
        shortName: 'Geography 2144A/B - GEOGRAPHY OF TOURISM',
        courseName: 'Geography 2144A/B - GEOGRAPHY OF TOURISM',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 12,
        id: '60cc22b947f7e10015fd6a9c',
      },
      {
        _id: '60cc22b947f7e10015fd6a9e',
        courseCode: 'GEOGRAPH 2152',
        shortName: 'Geography 2152F/G - GEOGRAPHY OF HAZARDS',
        courseName: 'Geography 2152F/G - GEOGRAPHY OF HAZARDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b947f7e10015fd6a9e',
      },
      {
        _id: '60cc22b947f7e10015fd6aa0',
        courseCode: 'GEOGRAPH 2153',
        shortName: 'Geography 2153A/B - ENVIRONMENT, ECONOMY AND SOCIETY',
        courseName: 'Geography 2153A/B - ENVIRONMENT, ECONOMY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b947f7e10015fd6aa0',
      },
      {
        _id: '60cc22b947f7e10015fd6aa2',
        courseCode: 'GEOGRAPH 2156',
        shortName: 'Geography 2156A/B - ANIMAL GEOGRAPHIES',
        courseName: 'Geography 2156A/B - ANIMAL GEOGRAPHIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22b947f7e10015fd6aa2',
      },
      {
        _id: '60cc22ba47f7e10015fd6aa4',
        courseCode: 'GEOGRAPH 2160',
        shortName: 'Geography 2160A/B - HEALTHY CITIES',
        courseName: 'Geography 2160A/B - HEALTHY CITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ba47f7e10015fd6aa4',
      },
      {
        _id: '60cc22ba47f7e10015fd6aa6',
        courseCode: 'GEOGRAPH 2162',
        shortName: 'Geography 2162A/B - PLANNING SUSTAINABLE CITIES',
        courseName: 'Geography 2162A/B - PLANNING SUSTAINABLE CITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ba47f7e10015fd6aa6',
      },
      {
        _id: '60cc22ba47f7e10015fd6aa8',
        courseCode: 'GEOGRAPH 2210',
        shortName:
          'Geography 2210A/B - INTRODUCTION TO STATISTICS FOR GEOGRAPHERS',
        courseName:
          'Geography 2210A/B - INTRODUCTION TO STATISTICS FOR GEOGRAPHERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ba47f7e10015fd6aa8',
      },
      {
        _id: '60cc22ba47f7e10015fd6aaa',
        courseCode: 'GEOGRAPH 2220',
        shortName: 'Geography 2220A/B - GEOGRAPHIC INFORMATION SCIENCE I',
        courseName: 'Geography 2220A/B - GEOGRAPHIC INFORMATION SCIENCE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ba47f7e10015fd6aaa',
      },
      {
        _id: '60cc22bb47f7e10015fd6aac',
        courseCode: 'GEOGRAPH 2230',
        shortName: 'Geography 2230A/B - REMOTE SENSING',
        courseName: 'Geography 2230A/B - REMOTE SENSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bb47f7e10015fd6aac',
      },
      {
        _id: '60cc22bb47f7e10015fd6aae',
        courseCode: 'GEOGRAPH 2240',
        shortName: 'Geography 2240A/B - INTRODUCTORY CARTOGRAPHY',
        courseName: 'Geography 2240A/B - INTRODUCTORY CARTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bb47f7e10015fd6aae',
      },
      {
        _id: '60cc22bb47f7e10015fd6ab0',
        courseCode: 'GEOGRAPH 2310',
        shortName: 'Geography 2310A/B - WEATHER AND CLIMATE',
        courseName: 'Geography 2310A/B - WEATHER AND CLIMATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bb47f7e10015fd6ab0',
      },
      {
        _id: '60cc22bb47f7e10015fd6ab2',
        courseCode: 'GEOGRAPH 2320',
        shortName: 'Geography 2320A/B - INTRODUCTORY BIOGEOGRAPHY',
        courseName: 'Geography 2320A/B - INTRODUCTORY BIOGEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bb47f7e10015fd6ab2',
      },
      {
        _id: '60cc22bc47f7e10015fd6ab4',
        courseCode: 'GEOGRAPH 2330',
        shortName: 'Geography 2330A/B - GEOMORPHOLOGY AND HYDROLOGY',
        courseName: 'Geography 2330A/B - GEOMORPHOLOGY AND HYDROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bc47f7e10015fd6ab4',
      },
      {
        _id: '60cc22bc47f7e10015fd6ab6',
        courseCode: 'GEOGRAPH 2410',
        shortName: 'Geography 2410A/B - SOCIAL GEOGRAPHY',
        courseName: 'Geography 2410A/B - SOCIAL GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bc47f7e10015fd6ab6',
      },
      {
        _id: '60cc22bc47f7e10015fd6ab8',
        courseCode: 'GEOGRAPH 2411',
        shortName: 'Geography 2411F/G - INDIGENOUS ENVIRONMENTS',
        courseName: 'Geography 2411F/G - INDIGENOUS ENVIRONMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bc47f7e10015fd6ab8',
      },
      {
        _id: '60cc22bc47f7e10015fd6aba',
        courseCode: 'GEOGRAPH 2420',
        shortName: 'Geography 2420A/B - ECONOMIC GEOGRAPHY',
        courseName: 'Geography 2420A/B - ECONOMIC GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bc47f7e10015fd6aba',
      },
      {
        _id: '60cc22bd47f7e10015fd6abc',
        courseCode: 'GEOGRAPH 2430',
        shortName: 'Geography 2430A/B - PUBLIC HEALTH AND ENVIRONMENT',
        courseName: 'Geography 2430A/B - PUBLIC HEALTH AND ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bd47f7e10015fd6abc',
      },
      {
        _id: '60cc22bd47f7e10015fd6abe',
        courseCode: 'GEOGRAPH 2460',
        shortName: 'Geography 2460F/G - INTRODUCTION TO URBAN DEVELOPMENT',
        courseName: 'Geography 2460F/G - INTRODUCTION TO URBAN DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bd47f7e10015fd6abe',
      },
      {
        _id: '60cc22bd47f7e10015fd6ac0',
        courseCode: 'GEOGRAPH 3000',
        shortName: 'Geography 3000Y - FIELD METHODS AND PRACTICES',
        courseName: 'Geography 3000Y - FIELD METHODS AND PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bd47f7e10015fd6ac0',
      },
      {
        _id: '60cc22bd47f7e10015fd6ac2',
        courseCode: 'GEOGRAPH 3001',
        shortName: 'Geography 3001F/G - COMMUNITY-BASED METHODS AND PRACTICES',
        courseName: 'Geography 3001F/G - COMMUNITY-BASED METHODS AND PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bd47f7e10015fd6ac2',
      },
      {
        _id: '60cc22bd47f7e10015fd6ac4',
        courseCode: 'GEOGRAPH 3210',
        shortName: 'Geography 3210A/B - GEOCOMPUTATION',
        courseName: 'Geography 3210A/B - GEOCOMPUTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bd47f7e10015fd6ac4',
      },
      {
        _id: '60cc22be47f7e10015fd6ac6',
        courseCode: 'GEOGRAPH 3211',
        shortName: 'Geography 3211A/B - SPATIAL STATISTICS',
        courseName: 'Geography 3211A/B - SPATIAL STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22be47f7e10015fd6ac6',
      },
      {
        _id: '60cc22be47f7e10015fd6ac8',
        courseCode: 'GEOGRAPH 3222',
        shortName: 'Geography 3222A/B - GEOGRAPHIC INFORMATION SCIENCE II',
        courseName: 'Geography 3222A/B - GEOGRAPHIC INFORMATION SCIENCE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22be47f7e10015fd6ac8',
      },
      {
        _id: '60cc22be47f7e10015fd6aca',
        courseCode: 'GEOGRAPH 3223',
        shortName: 'Geography 3223A/B - DECISION-MAKING WITH GIS',
        courseName: 'Geography 3223A/B - DECISION-MAKING WITH GIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22be47f7e10015fd6aca',
      },
      {
        _id: '60cc22be47f7e10015fd6acc',
        courseCode: 'GEOGRAPH 3224',
        shortName: 'Geography 3224A/B - SPATIAL ECOLOGY AND GIS',
        courseName: 'Geography 3224A/B - SPATIAL ECOLOGY AND GIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22be47f7e10015fd6acc',
      },
      {
        _id: '60cc22bf47f7e10015fd6ace',
        courseCode: 'GEOGRAPH 3225',
        shortName: 'Geography 3225A/B - TRANSPORTATION GEOGRAPHY AND GIS',
        courseName: 'Geography 3225A/B - TRANSPORTATION GEOGRAPHY AND GIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bf47f7e10015fd6ace',
      },
      {
        _id: '60cc22bf47f7e10015fd6ad0',
        courseCode: 'GEOGRAPH 3226',
        shortName: 'Geography 3226A/B - URBAN DATA SCIENCE',
        courseName: 'Geography 3226A/B - URBAN DATA SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bf47f7e10015fd6ad0',
      },
      {
        _id: '60cc22bf47f7e10015fd6ad2',
        courseCode: 'GEOGRAPH 3231',
        shortName: 'Geography 3231A/B - ADVANCED TOPICS IN REMOTE SENSING',
        courseName: 'Geography 3231A/B - ADVANCED TOPICS IN REMOTE SENSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bf47f7e10015fd6ad2',
      },
      {
        _id: '60cc22bf47f7e10015fd6ad4',
        courseCode: 'GEOGRAPH 3250',
        shortName:
          'Geography 3250A/B - SOCIAL SCIENCE RESEARCH METHODS IN GEOGRAPHY',
        courseName:
          'Geography 3250A/B - SOCIAL SCIENCE RESEARCH METHODS IN GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bf47f7e10015fd6ad4',
      },
      {
        _id: '60cc22bf47f7e10015fd6ad6',
        courseCode: 'GEOGRAPH 3311',
        shortName: 'Geography 3311A/B - MICROMETEOROLOGY',
        courseName: 'Geography 3311A/B - MICROMETEOROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22bf47f7e10015fd6ad6',
      },
      {
        _id: '60cc22c047f7e10015fd6ad8',
        courseCode: 'GEOGRAPH 3334',
        shortName: 'Geography 3334A/B - GEOMORPHOLOGY OF RIVER CHANNELS',
        courseName: 'Geography 3334A/B - GEOMORPHOLOGY OF RIVER CHANNELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c047f7e10015fd6ad8',
      },
      {
        _id: '60cc22c047f7e10015fd6ada',
        courseCode: 'GEOGRAPH 3341',
        shortName: 'Geography 3341A/B - HYDROLOGY',
        courseName: 'Geography 3341A/B - HYDROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c047f7e10015fd6ada',
      },
      {
        _id: '60cc22c047f7e10015fd6adc',
        courseCode: 'GEOGRAPH 3343',
        shortName: 'Geography 3343A/B - RIVER ECOSYSTEMS',
        courseName: 'Geography 3343A/B - RIVER ECOSYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c047f7e10015fd6adc',
      },
      {
        _id: '60cc22c047f7e10015fd6ade',
        courseCode: 'GEOGRAPH 3350',
        shortName: 'Geography 3350A/B - ENVIRONMENTAL CHANGE',
        courseName: 'Geography 3350A/B - ENVIRONMENTAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c047f7e10015fd6ade',
      },
      {
        _id: '60cc22c147f7e10015fd6ae0',
        courseCode: 'GEOGRAPH 3352',
        shortName:
          'Geography 3352A/B - PALEOLIMNOLOGY AND GLOBAL ENVIRONMENTAL CHANGE',
        courseName:
          'Geography 3352A/B - PALEOLIMNOLOGY AND GLOBAL ENVIRONMENTAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c147f7e10015fd6ae0',
      },
      {
        _id: '60cc22c147f7e10015fd6ae2',
        courseCode: 'GEOGRAPH 3411',
        shortName: 'Geography 3411A/B - CULTURAL GEOGRAPHY',
        courseName: 'Geography 3411A/B - CULTURAL GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c147f7e10015fd6ae2',
      },
      {
        _id: '60cc22c147f7e10015fd6ae4',
        courseCode: 'GEOGRAPH 3414',
        shortName:
          'Geography 3414A/B - DIGITAL TECHNOLOGY, SOCIETY, AND ENVIRONMENT',
        courseName:
          'Geography 3414A/B - DIGITAL TECHNOLOGY, SOCIETY, AND ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c147f7e10015fd6ae4',
      },
      {
        _id: '60cc22c147f7e10015fd6ae6',
        courseCode: 'GEOGRAPH 3416',
        shortName: 'Geography 3416F/G - URBAN CULTURE OF PUBLIC SPACES',
        courseName: 'Geography 3416F/G - URBAN CULTURE OF PUBLIC SPACES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c147f7e10015fd6ae6',
      },
      {
        _id: '60cc22c247f7e10015fd6ae8',
        courseCode: 'GEOGRAPH 3421',
        shortName: 'Geography 3421A/B - LOCATION THEORY AND ANALYSIS',
        courseName: 'Geography 3421A/B - LOCATION THEORY AND ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c247f7e10015fd6ae8',
      },
      {
        _id: '60cc22c247f7e10015fd6aea',
        courseCode: 'GEOGRAPH 3431',
        shortName: 'Geography 3431A/B - GEOGRAPHY OF HEALTH AND HEALTH CARE',
        courseName: 'Geography 3431A/B - GEOGRAPHY OF HEALTH AND HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c247f7e10015fd6aea',
      },
      {
        _id: '60cc22c247f7e10015fd6aec',
        courseCode: 'GEOGRAPH 3432',
        shortName: 'Geography 3432A/B - ENVIRONMENTAL HAZARDS AND HUMAN HEALTH',
        courseName:
          'Geography 3432A/B - ENVIRONMENTAL HAZARDS AND HUMAN HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c247f7e10015fd6aec',
      },
      {
        _id: '60cc22c247f7e10015fd6aee',
        courseCode: 'GEOGRAPH 3441',
        shortName: 'Geography 3441F/G - CONSERVATION AND DEVELOPMENT',
        courseName: 'Geography 3441F/G - CONSERVATION AND DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c247f7e10015fd6aee',
      },
      {
        _id: '60cc22c247f7e10015fd6af0',
        courseCode: 'GEOGRAPH 3443',
        shortName: 'Geography 3443F/G - ENERGY AND POWER',
        courseName: 'Geography 3443F/G - ENERGY AND POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c247f7e10015fd6af0',
      },
      {
        _id: '60cc22c347f7e10015fd6af2',
        courseCode: 'GEOGRAPH 3445',
        shortName: 'Geography 3445F/G - GLOBAL AGRICULTURE AND FOOD SYSTEMS',
        courseName: 'Geography 3445F/G - GLOBAL AGRICULTURE AND FOOD SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c347f7e10015fd6af2',
      },
      {
        _id: '60cc22c347f7e10015fd6af5',
        courseCode: 'GEOGRAPH 3461',
        shortName: 'Geography 3461F/G - LAND USE AND DEVELOPMENT ISSUES',
        courseName: 'Geography 3461F/G - LAND USE AND DEVELOPMENT ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c347f7e10015fd6af5',
      },
      {
        _id: '60cc22c347f7e10015fd6af7',
        courseCode: 'GEOGRAPH 3462',
        shortName: 'Geography 3462F/G - LAND USE PLANNING',
        courseName: 'Geography 3462F/G - LAND USE PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c347f7e10015fd6af7',
      },
      {
        _id: '60cc22c447f7e10015fd6af9',
        courseCode: 'GEOGRAPH 3463',
        shortName: 'Geography 3463F/G - HOUSING',
        courseName: 'Geography 3463F/G - HOUSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c447f7e10015fd6af9',
      },
      {
        _id: '60cc22c447f7e10015fd6afb',
        courseCode: 'GEOGRAPH 3464',
        shortName:
          'Geography 3464F/G - FINANCIAL FEASIBILITY OF URBAN DEVELOPMENTS',
        courseName:
          'Geography 3464F/G - FINANCIAL FEASIBILITY OF URBAN DEVELOPMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c447f7e10015fd6afb',
      },
      {
        _id: '60cc22c447f7e10015fd6afd',
        courseCode: 'GEOGRAPH 3465',
        shortName: 'Geography 3465F/G - URBAN ECONOMIC DEVELOPMENT AND POLICY',
        courseName: 'Geography 3465F/G - URBAN ECONOMIC DEVELOPMENT AND POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c447f7e10015fd6afd',
      },
      {
        _id: '60cc22c447f7e10015fd6aff',
        courseCode: 'GEOGRAPH 3900',
        shortName: 'Geography 3900 - INTERNSHIP',
        courseName: 'Geography 3900 - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c447f7e10015fd6aff',
      },
      {
        _id: '60cc22c447f7e10015fd6b01',
        courseCode: 'GEOGRAPH 3901',
        shortName: 'Geography 3901A/B - SELECTED TOPICS IN GEOGRAPHY',
        courseName: 'Geography 3901A/B - SELECTED TOPICS IN GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c447f7e10015fd6b01',
      },
      {
        _id: '60cc22c547f7e10015fd6b03',
        courseCode: 'GEOGRAPH 3902',
        shortName: 'Geography 3902A/B - SELECTED TOPICS IN GEOGRAPHY',
        courseName: 'Geography 3902A/B - SELECTED TOPICS IN GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c547f7e10015fd6b03',
      },
      {
        _id: '60cc22c547f7e10015fd6b05',
        courseCode: 'GEOGRAPH 4000',
        shortName: 'Geography 4000A/B - THE NATURE AND PHILOSOPHY OF GEOGRAPHY',
        courseName:
          'Geography 4000A/B - THE NATURE AND PHILOSOPHY OF GEOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c547f7e10015fd6b05',
      },
      {
        _id: '60cc22c547f7e10015fd6b07',
        courseCode: 'GEOGRAPH 4220',
        shortName: 'Geography 4220A/B - THE GEOWEB AND GEOMEDIA',
        courseName: 'Geography 4220A/B - THE GEOWEB AND GEOMEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c547f7e10015fd6b07',
      },
      {
        _id: '60cc22c547f7e10015fd6b09',
        courseCode: 'GEOGRAPH 4450',
        shortName: 'Geography 4450F/G - CLIMATE CHANGE AND COLLECTIVE ACTION',
        courseName: 'Geography 4450F/G - CLIMATE CHANGE AND COLLECTIVE ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c547f7e10015fd6b09',
      },
      {
        _id: '60cc22c647f7e10015fd6b0b',
        courseCode: 'GEOGRAPH 4460',
        shortName: 'Geography 4460F/G - REAL ESTATE AND THE CITIES',
        courseName: 'Geography 4460F/G - REAL ESTATE AND THE CITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c647f7e10015fd6b0b',
      },
      {
        _id: '60cc22c647f7e10015fd6b0d',
        courseCode: 'GEOGRAPH 4900',
        shortName: 'Geography 4900E - THESIS',
        courseName: 'Geography 4900E - THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c647f7e10015fd6b0d',
      },
      {
        _id: '60cc22c647f7e10015fd6b0f',
        courseCode: 'GEOGRAPH 4901',
        shortName: 'Geography 4901E - THESIS (SCIENCE)',
        courseName: 'Geography 4901E - THESIS (SCIENCE)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c647f7e10015fd6b0f',
      },
      {
        _id: '60cc22c647f7e10015fd6b11',
        courseCode: 'GERMAN 1030',
        shortName: 'German 1030 - GERMAN FOR BEGINNERS',
        courseName: 'German 1030 - GERMAN FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c647f7e10015fd6b11',
      },
      {
        _id: '60cc22c647f7e10015fd6b13',
        courseCode: 'GERMAN 2141',
        shortName: 'German 2141A/B - GERMAN-JEWISH LITERATURE',
        courseName: 'German 2141A/B - GERMAN-JEWISH LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c647f7e10015fd6b13',
      },
      {
        _id: '60cc22c747f7e10015fd6b15',
        courseCode: 'GERMAN 2160',
        shortName:
          'German 2160F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2160F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c747f7e10015fd6b15',
      },
      {
        _id: '60cc22c747f7e10015fd6b17',
        courseCode: 'GERMAN 2161',
        shortName:
          'German 2161F/G - SPECIAL TOPICS IN GERNAN LANGUAGE AND CULTURE',
        courseName:
          'German 2161F/G - SPECIAL TOPICS IN GERNAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c747f7e10015fd6b17',
      },
      {
        _id: '60cc22c747f7e10015fd6b19',
        courseCode: 'GERMAN 2200',
        shortName: 'German 2200 - INTERMEDIATE GERMAN',
        courseName: 'German 2200 - INTERMEDIATE GERMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c747f7e10015fd6b19',
      },
      {
        _id: '60cc22c747f7e10015fd6b1b',
        courseCode: 'GERMAN 2215',
        shortName: 'German 2215F/G - EXPLORING GERMAN CULTURES',
        courseName: 'German 2215F/G - EXPLORING GERMAN CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c747f7e10015fd6b1b',
      },
      {
        _id: '60cc22c847f7e10015fd6b1d',
        courseCode: 'GERMAN 2220',
        shortName: 'German 2220A/B - GERMAN CONVERSATION',
        courseName: 'German 2220A/B - GERMAN CONVERSATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c847f7e10015fd6b1d',
      },
      {
        _id: '60cc22c847f7e10015fd6b1f',
        courseCode: 'GERMAN 2251',
        shortName:
          'German 2251F/G - GERMAN FAIRY TALES: GRIMM BROTHERS AND OTHERS',
        courseName:
          'German 2251F/G - GERMAN FAIRY TALES: GRIMM BROTHERS AND OTHERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c847f7e10015fd6b1f',
      },
      {
        _id: '60cc22c847f7e10015fd6b21',
        courseCode: 'GERMAN 2255',
        shortName: 'German 2255F/G - VIENNA 1900/2000',
        courseName: 'German 2255F/G - VIENNA 1900/2000',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c847f7e10015fd6b21',
      },
      {
        _id: '60cc22c847f7e10015fd6b23',
        courseCode: 'GERMAN 2256',
        shortName: 'German 2256F/G - BERLIN SNAPSHOTS',
        courseName: 'German 2256F/G - BERLIN SNAPSHOTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c847f7e10015fd6b23',
      },
      {
        _id: '60cc22c847f7e10015fd6b25',
        courseCode: 'GERMAN 2260',
        shortName:
          'German 2260F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2260F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c847f7e10015fd6b25',
      },
      {
        _id: '60cc22c947f7e10015fd6b27',
        courseCode: 'GERMAN 2261',
        shortName:
          'German 2261F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2261F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c947f7e10015fd6b27',
      },
      {
        _id: '60cc22c947f7e10015fd6b29',
        courseCode: 'GERMAN 2270',
        shortName:
          'German 2270A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2270A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c947f7e10015fd6b29',
      },
      {
        _id: '60cc22c947f7e10015fd6b2b',
        courseCode: 'GERMAN 2271',
        shortName:
          'German 2271A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2271A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c947f7e10015fd6b2b',
      },
      {
        _id: '60cc22c947f7e10015fd6b2d',
        courseCode: 'GERMAN 2272',
        shortName:
          'German 2272A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2272A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22c947f7e10015fd6b2d',
      },
      {
        _id: '60cc22ca47f7e10015fd6b2f',
        courseCode: 'GERMAN 2273',
        shortName:
          'German 2273A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 2273A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ca47f7e10015fd6b2f',
      },
      {
        _id: '60cc22ca47f7e10015fd6b31',
        courseCode: 'GERMAN 2500',
        shortName:
          'German 2500F/G - BRIDGING CLASSROOM AND COMMUNITY: LANGUAGES AND CULTURES IN ACTION',
        courseName:
          'German 2500F/G - BRIDGING CLASSROOM AND COMMUNITY: LANGUAGES AND CULTURES IN ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ca47f7e10015fd6b31',
      },
      {
        _id: '60cc22ca47f7e10015fd6b33',
        courseCode: 'GERMAN 3305',
        shortName: 'German 3305 - ADVANCED GERMAN',
        courseName: 'German 3305 - ADVANCED GERMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ca47f7e10015fd6b33',
      },
      {
        _id: '60cc22ca47f7e10015fd6b35',
        courseCode: 'GERMAN 3321',
        shortName: 'German 3321A/B - GERMAN CINEMA: CULTURE AND CONVERSATION',
        courseName: 'German 3321A/B - GERMAN CINEMA: CULTURE AND CONVERSATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ca47f7e10015fd6b35',
      },
      {
        _id: '60cc22ca47f7e10015fd6b37',
        courseCode: 'GERMAN 3323',
        shortName: 'German 3323A/B - ADVANCED GERMAN CONVERSATION',
        courseName: 'German 3323A/B - ADVANCED GERMAN CONVERSATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ca47f7e10015fd6b37',
      },
      {
        _id: '60cc22cb47f7e10015fd6b39',
        courseCode: 'GERMAN 3330',
        shortName:
          'German 3330F/G - MODERNITY AND MODERNISM IN GERMAN LITERATURE AND CULTURE',
        courseName:
          'German 3330F/G - MODERNITY AND MODERNISM IN GERMAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cb47f7e10015fd6b39',
      },
      {
        _id: '60cc22cb47f7e10015fd6b3b',
        courseCode: 'GERMAN 3331',
        shortName:
          'German 3331F/G - UNCANNY GERMAN STORIES: FROM THE EXTRAORDINARY TO THE SUPERNATURAL',
        courseName:
          'German 3331F/G - UNCANNY GERMAN STORIES: FROM THE EXTRAORDINARY TO THE SUPERNATURAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cb47f7e10015fd6b3b',
      },
      {
        _id: '60cc22cb47f7e10015fd6b3d',
        courseCode: 'GERMAN 3341',
        shortName: 'German 3341F/G - LITERATURE AND CULTURE OF THE MIDDLE AGES',
        courseName:
          'German 3341F/G - LITERATURE AND CULTURE OF THE MIDDLE AGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cb47f7e10015fd6b3d',
      },
      {
        _id: '60cc22cb47f7e10015fd6b3f',
        courseCode: 'GERMAN 3342',
        shortName: 'German 3342F/G - GERMAN CLASSICISM AND ROMANTICISM',
        courseName: 'German 3342F/G - GERMAN CLASSICISM AND ROMANTICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cb47f7e10015fd6b3f',
      },
      {
        _id: '60cc22cc47f7e10015fd6b41',
        courseCode: 'GERMAN 3344',
        shortName:
          'German 3344F/G - GERMAN THOUGHT AND THE CULTURE OF PROVOCATION',
        courseName:
          'German 3344F/G - GERMAN THOUGHT AND THE CULTURE OF PROVOCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cc47f7e10015fd6b41',
      },
      {
        _id: '60cc22cc47f7e10015fd6b43',
        courseCode: 'GERMAN 3345',
        shortName:
          'German 3345F/G - NATURE AND ENVIRONMENT IN GERMAN LITERATURE, THOUGHT AND CULTURE',
        courseName:
          'German 3345F/G - NATURE AND ENVIRONMENT IN GERMAN LITERATURE, THOUGHT AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cc47f7e10015fd6b43',
      },
      {
        _id: '60cc22cc47f7e10015fd6b45',
        courseCode: 'GERMAN 3357',
        shortName: 'German 3357F/G - GERMAN TRAVEL LITERATURE',
        courseName: 'German 3357F/G - GERMAN TRAVEL LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cc47f7e10015fd6b45',
      },
      {
        _id: '60cc22cc47f7e10015fd6b47',
        courseCode: 'GERMAN 3360',
        shortName:
          'German 3360F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3360F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cc47f7e10015fd6b47',
      },
      {
        _id: '60cc22cd47f7e10015fd6b49',
        courseCode: 'GERMAN 3361',
        shortName:
          'German 3361F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3361F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cd47f7e10015fd6b49',
      },
      {
        _id: '60cc22cd47f7e10015fd6b4b',
        courseCode: 'GERMAN 3362',
        shortName:
          'German 3362F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3362F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cd47f7e10015fd6b4b',
      },
      {
        _id: '60cc22cd47f7e10015fd6b4d',
        courseCode: 'GERMAN 3363',
        shortName:
          'German 3363F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3363F/G - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cd47f7e10015fd6b4d',
      },
      {
        _id: '60cc22cd47f7e10015fd6b4f',
        courseCode: 'GERMAN 3370',
        shortName:
          'German 3370A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3370A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cd47f7e10015fd6b4f',
      },
      {
        _id: '60cc22ce47f7e10015fd6b51',
        courseCode: 'GERMAN 3371',
        shortName:
          'German 3371A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3371A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ce47f7e10015fd6b51',
      },
      {
        _id: '60cc22ce47f7e10015fd6b53',
        courseCode: 'GERMAN 3372',
        shortName:
          'German 3372A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3372A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ce47f7e10015fd6b53',
      },
      {
        _id: '60cc22ce47f7e10015fd6b55',
        courseCode: 'GERMAN 3373',
        shortName:
          'German 3373A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        courseName:
          'German 3373A/B - SPECIAL TOPICS IN GERMAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ce47f7e10015fd6b55',
      },
      {
        _id: '60cc22ce47f7e10015fd6b57',
        courseCode: 'GERMAN 3600',
        shortName: 'German 3600F/G/Z - INTERNSHIP IN GERMAN',
        courseName: 'German 3600F/G/Z - INTERNSHIP IN GERMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ce47f7e10015fd6b57',
      },
      {
        _id: '60cc22ce47f7e10015fd6b59',
        courseCode: 'GERMAN 4400',
        shortName: 'German 4400A/B - INTENSIVE TRANSLATION',
        courseName: 'German 4400A/B - INTENSIVE TRANSLATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ce47f7e10015fd6b59',
      },
      {
        _id: '60cc22cf47f7e10015fd6b5b',
        courseCode: 'GERMAN 4410',
        shortName: 'German 4410E - HONOURS THESIS',
        courseName: 'German 4410E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cf47f7e10015fd6b5b',
      },
      {
        _id: '60cc22cf47f7e10015fd6b5d',
        courseCode: 'GERMAN 4500',
        shortName: 'German 4500F/G - SENIOR RESEARCH PROJECT',
        courseName: 'German 4500F/G - SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cf47f7e10015fd6b5d',
      },
      {
        _id: '60cc22cf47f7e10015fd6b5f',
        courseCode: 'GGB 1002',
        shortName: 'Global Great Books 1002F/G - THE PURSUIT OF HAPPINESS',
        courseName: 'Global Great Books 1002F/G - THE PURSUIT OF HAPPINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cf47f7e10015fd6b5f',
      },
      {
        _id: '60cc22cf47f7e10015fd6b61',
        courseCode: 'GGB 1200',
        shortName: 'Global Great Books 1200F/G - WAR AND PEACE',
        courseName: 'Global Great Books 1200F/G - WAR AND PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22cf47f7e10015fd6b61',
      },
      {
        _id: '60cc22d047f7e10015fd6b63',
        courseCode: 'GGB 2000',
        shortName:
          'Global Great Books 2000F/G - HUMAN NATURE AND THE MEANING OF LIFE',
        courseName:
          'Global Great Books 2000F/G - HUMAN NATURE AND THE MEANING OF LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d047f7e10015fd6b63',
      },
      {
        _id: '60cc22d047f7e10015fd6b65',
        courseCode: 'GGB 2001',
        shortName: 'Global Great Books 2001F/G - FRIENDS AND LOVERS',
        courseName: 'Global Great Books 2001F/G - FRIENDS AND LOVERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d047f7e10015fd6b65',
      },
      {
        _id: '60cc22d047f7e10015fd6b67',
        courseCode: 'GGB 3000',
        shortName: 'Global Great Books 3000F/G - JUSTICE',
        courseName: 'Global Great Books 3000F/G - JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d047f7e10015fd6b67',
      },
      {
        _id: '60cc22d047f7e10015fd6b69',
        courseCode: 'GGB 3001',
        shortName: 'Global Great Books 3001F/G - NATURE AND TECHNOLOGY',
        courseName: 'Global Great Books 3001F/G - NATURE AND TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d047f7e10015fd6b69',
      },
      {
        _id: '60cc22d047f7e10015fd6b6b',
        courseCode: 'GGB 3002',
        shortName: 'Global Great Books 3002F/G - THE BEAUTIFUL AND SUBLIME',
        courseName: 'Global Great Books 3002F/G - THE BEAUTIFUL AND SUBLIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d047f7e10015fd6b6b',
      },
      {
        _id: '60cc22d147f7e10015fd6b6d',
        courseCode: 'GGB 3003',
        shortName: 'Global Great Books 3003F/G - REASON AND REVELATION',
        courseName: 'Global Great Books 3003F/G - REASON AND REVELATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d147f7e10015fd6b6d',
      },
      {
        _id: '60cc22d147f7e10015fd6b6f',
        courseCode: 'GGB 4000',
        shortName: 'Global Great Books 4000F/G - CAPSTONE',
        courseName: 'Global Great Books 4000F/G - CAPSTONE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d147f7e10015fd6b6f',
      },
      {
        _id: '60cc22d147f7e10015fd6b71',
        courseCode: 'GLE 2001',
        shortName:
          'Governance, Leadership and Ethics 2001F/G - INTRODUCTION TO GOVERNANCE',
        courseName:
          'Governance, Leadership and Ethics 2001F/G - INTRODUCTION TO GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d147f7e10015fd6b71',
      },
      {
        _id: '60cc22d147f7e10015fd6b73',
        courseCode: 'GLE 2002',
        shortName:
          'Governance, Leadership and Ethics 2002F/G - ETHICS AND VALUE THEORY',
        courseName:
          'Governance, Leadership and Ethics 2002F/G - ETHICS AND VALUE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d147f7e10015fd6b73',
      },
      {
        _id: '60cc22d247f7e10015fd6b75',
        courseCode: 'GLE 2003',
        shortName:
          'Governance, Leadership and Ethics 2003F/G - INTRODUCTION TO LEADERSHIP',
        courseName:
          'Governance, Leadership and Ethics 2003F/G - INTRODUCTION TO LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d247f7e10015fd6b75',
      },
      {
        _id: '60cc22d247f7e10015fd6b77',
        courseCode: 'GLE 3001',
        shortName:
          'Governance, Leadership and Ethics 3001F/G - SPECIAL TOPICS IN GOVERNANCE',
        courseName:
          'Governance, Leadership and Ethics 3001F/G - SPECIAL TOPICS IN GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d247f7e10015fd6b77',
      },
      {
        _id: '60cc22d247f7e10015fd6b79',
        courseCode: 'GLE 3003',
        shortName:
          'Governance, Leadership and Ethics 3003F/G - SPECIAL TOPICS IN GOVERNANCE LEADERSHIP',
        courseName:
          'Governance, Leadership and Ethics 3003F/G - SPECIAL TOPICS IN GOVERNANCE LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d247f7e10015fd6b79',
      },
      {
        _id: '60cc22d247f7e10015fd6b7b',
        courseCode: 'GLE 4001',
        shortName:
          'Governance, Leadership and Ethics 4001E - THE CAPSTONE COURSE',
        courseName:
          'Governance, Leadership and Ethics 4001E - THE CAPSTONE COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d247f7e10015fd6b7b',
      },
      {
        _id: '60cc22d247f7e10015fd6b7d',
        courseCode: 'GREEK 1000',
        shortName: 'Greek 1000 - INTRODUCTORY ANCIENT GREEK',
        courseName: 'Greek 1000 - INTRODUCTORY ANCIENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d247f7e10015fd6b7d',
      },
      {
        _id: '60cc22d347f7e10015fd6b7f',
        courseCode: 'GREEK 1103',
        shortName: 'Greek 1103A/B - INTRODUCTION TO BIBLICAL GREEK I',
        courseName: 'Greek 1103A/B - INTRODUCTION TO BIBLICAL GREEK I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d347f7e10015fd6b7f',
      },
      {
        _id: '60cc22d347f7e10015fd6b81',
        courseCode: 'GREEK 1104',
        shortName: 'Greek 1104A/B - INTRO TO BIBLICAL GREEK II',
        courseName: 'Greek 1104A/B - INTRO TO BIBLICAL GREEK II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d347f7e10015fd6b81',
      },
      {
        _id: '60cc22d347f7e10015fd6b83',
        courseCode: 'GREEK 2000',
        shortName: 'Greek 2000 - ADVANCED ANCIENT GREEK',
        courseName: 'Greek 2000 - ADVANCED ANCIENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d347f7e10015fd6b83',
      },
      {
        _id: '60cc22d347f7e10015fd6b85',
        courseCode: 'GREEK 3100',
        shortName: 'Greek 3100A/B - ANCIENT GREEK PROSE SELECTIONS',
        courseName: 'Greek 3100A/B - ANCIENT GREEK PROSE SELECTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d347f7e10015fd6b85',
      },
      {
        _id: '60cc22d447f7e10015fd6b87',
        courseCode: 'GREEK 3200',
        shortName: 'Greek 3200A/B - ANCIENT GREEK POETRY SELECTIONS',
        courseName: 'Greek 3200A/B - ANCIENT GREEK POETRY SELECTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d447f7e10015fd6b87',
      },
      {
        _id: '60cc22d447f7e10015fd6b89',
        courseCode: 'GREEK 4903',
        shortName: 'Greek 4903A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        courseName: 'Greek 4903A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d447f7e10015fd6b89',
      },
      {
        _id: '60cc22d447f7e10015fd6b8b',
        courseCode: 'GREEK 4904',
        shortName: 'Greek 4904A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        courseName: 'Greek 4904A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d447f7e10015fd6b8b',
      },
      {
        _id: '60cc22d447f7e10015fd6b8d',
        courseCode: 'GREEK 4905',
        shortName: 'Greek 4905A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        courseName: 'Greek 4905A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d447f7e10015fd6b8d',
      },
      {
        _id: '60cc22d447f7e10015fd6b8f',
        courseCode: 'GREEK 4906',
        shortName: 'Greek 4906A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        courseName: 'Greek 4906A/B - SPECIAL TOPICS IN ANCIENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d447f7e10015fd6b8f',
      },
      {
        _id: '60cc22d547f7e10015fd6b91',
        courseCode: 'GPE 2213',
        shortName: 'Green Process Engineering 2213A/B - GREEN CHEMISTRY I',
        courseName: 'Green Process Engineering 2213A/B - GREEN CHEMISTRY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d547f7e10015fd6b91',
      },
      {
        _id: '60cc22d547f7e10015fd6b93',
        courseCode: 'GPE 2214',
        shortName:
          'Green Process Engineering 2214A/B - GREEN CHEMISTRY FOR INDUSTRIAL PROCESSES',
        courseName:
          'Green Process Engineering 2214A/B - GREEN CHEMISTRY FOR INDUSTRIAL PROCESSES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d547f7e10015fd6b93',
      },
      {
        _id: '60cc22d547f7e10015fd6b95',
        courseCode: 'GPE 3315',
        shortName:
          'Green Process Engineering 3315A/B - REACTION ENGINEERING WITH GREEN ENGINEERING APPLICATIONS',
        courseName:
          'Green Process Engineering 3315A/B - REACTION ENGINEERING WITH GREEN ENGINEERING APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d547f7e10015fd6b95',
      },
      {
        _id: '60cc22d547f7e10015fd6b97',
        courseCode: 'GPE 3382',
        shortName:
          'Green Process Engineering 3382A/B - FUNDAMENTALS OF GREEN PROCESS ENGINEERING AND SAFETY',
        courseName:
          'Green Process Engineering 3382A/B - FUNDAMENTALS OF GREEN PROCESS ENGINEERING AND SAFETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d547f7e10015fd6b97',
      },
      {
        _id: '60cc22d647f7e10015fd6b99',
        courseCode: 'GPE 3384',
        shortName:
          'Green Process Engineering 3384A/B - SUSTAINABLE ENERGY, SOLAR AND FUEL CELLS',
        courseName:
          'Green Process Engineering 3384A/B - SUSTAINABLE ENERGY, SOLAR AND FUEL CELLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d647f7e10015fd6b99',
      },
      {
        _id: '60cc22d647f7e10015fd6b9b',
        courseCode: 'GPE 3386',
        shortName:
          'Green Process Engineering 3386A/B - SUSTAINABLE ENG LIFE CYCLE ANALYSIS & CASE STUDIES',
        courseName:
          'Green Process Engineering 3386A/B - SUSTAINABLE ENG LIFE CYCLE ANALYSIS & CASE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d647f7e10015fd6b9b',
      },
      {
        _id: '60cc22d647f7e10015fd6b9d',
        courseCode: 'GPE 3395',
        shortName:
          'Green Process Engineering 3395Y - GREEN PROCESS ENGINEERING LABORATORY COURSE',
        courseName:
          'Green Process Engineering 3395Y - GREEN PROCESS ENGINEERING LABORATORY COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d647f7e10015fd6b9d',
      },
      {
        _id: '60cc22d647f7e10015fd6b9f',
        courseCode: 'GPE 4415',
        shortName:
          'Green Process Engineering 4415 - GREEN PROCESS ENGINEERING PROJECT',
        courseName:
          'Green Process Engineering 4415 - GREEN PROCESS ENGINEERING PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d647f7e10015fd6b9f',
      },
      {
        _id: '60cc22d747f7e10015fd6ba1',
        courseCode: 'GPE 4484',
        shortName:
          'Green Process Engineering 4484A/B - GREEN FUELS AND CHEMICALS',
        courseName:
          'Green Process Engineering 4484A/B - GREEN FUELS AND CHEMICALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d747f7e10015fd6ba1',
      },
      {
        _id: '60cc22d747f7e10015fd6ba3',
        courseCode: 'GPE 4497',
        shortName: 'Green Process Engineering 4497 - GREEN PROCESS DESIGN',
        courseName: 'Green Process Engineering 4497 - GREEN PROCESS DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d747f7e10015fd6ba3',
      },
      {
        _id: '60cc22d747f7e10015fd6ba5',
        courseCode: 'HEALTSCI 1001',
        shortName: 'Health Sciences 1001A/B - PERSONAL DETERMINANTS OF HEALTH',
        courseName: 'Health Sciences 1001A/B - PERSONAL DETERMINANTS OF HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d747f7e10015fd6ba5',
      },
      {
        _id: '60cc22d747f7e10015fd6ba7',
        courseCode: 'HEALTSCI 1002',
        shortName: 'Health Sciences 1002A/B - SOCIAL DETERMINANTS OF HEALTH',
        courseName: 'Health Sciences 1002A/B - SOCIAL DETERMINANTS OF HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d747f7e10015fd6ba7',
      },
      {
        _id: '60cc22d747f7e10015fd6ba9',
        courseCode: 'HEALTSCI 1110',
        shortName:
          'Health Sciences 1110A/B - PERSONAL AND SOCIAL DETERMINANTS OF RESILIENCE AND WELLBEING',
        courseName:
          'Health Sciences 1110A/B - PERSONAL AND SOCIAL DETERMINANTS OF RESILIENCE AND WELLBEING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d747f7e10015fd6ba9',
      },
      {
        _id: '60cc22d847f7e10015fd6bab',
        courseCode: 'HEALTSCI 2000',
        shortName: 'Health Sciences 2000A/B - HIGHWAY TO HEALTH',
        courseName: 'Health Sciences 2000A/B - HIGHWAY TO HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d847f7e10015fd6bab',
      },
      {
        _id: '60cc22d847f7e10015fd6bad',
        courseCode: 'HEALTSCI 2045',
        shortName: 'Health Sciences 2045A/B - EMERGING TRENDS IN HEALTH CARE',
        courseName: 'Health Sciences 2045A/B - EMERGING TRENDS IN HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d847f7e10015fd6bad',
      },
      {
        _id: '60cc22d847f7e10015fd6baf',
        courseCode: 'HEALTSCI 2090',
        shortName:
          'Health Sciences 2090A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 2090A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d847f7e10015fd6baf',
      },
      {
        _id: '60cc22d847f7e10015fd6bb1',
        courseCode: 'HEALTSCI 2110',
        shortName:
          'Health Sciences 2110A/B - RESILIENCE AND THE CREATION OF WELLBEING',
        courseName:
          'Health Sciences 2110A/B - RESILIENCE AND THE CREATION OF WELLBEING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d847f7e10015fd6bb1',
      },
      {
        _id: '60cc22d947f7e10015fd6bb3',
        courseCode: 'HEALTSCI 2244',
        shortName: 'Health Sciences 2244 - WOMEN AND HEALTH',
        courseName: 'Health Sciences 2244 - WOMEN AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d947f7e10015fd6bb3',
      },
      {
        _id: '60cc22d947f7e10015fd6bb5',
        courseCode: 'HEALTSCI 2250',
        shortName: 'Health Sciences 2250A/B - HEALTH PROMOTION',
        courseName: 'Health Sciences 2250A/B - HEALTH PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d947f7e10015fd6bb5',
      },
      {
        _id: '60cc22d947f7e10015fd6bb7',
        courseCode: 'HEALTSCI 2300',
        shortName: 'Health Sciences 2300A/B - FUNCTIONAL HUMAN GROSS ANATOMY',
        courseName: 'Health Sciences 2300A/B - FUNCTIONAL HUMAN GROSS ANATOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d947f7e10015fd6bb7',
      },
      {
        _id: '60cc22d947f7e10015fd6bb9',
        courseCode: 'HEALTSCI 2330',
        shortName:
          'Health Sciences 2330A/B - FUNCTIONAL HUMAN GROSS ANATOMY FOR NURSING STUDENTS',
        courseName:
          'Health Sciences 2330A/B - FUNCTIONAL HUMAN GROSS ANATOMY FOR NURSING STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22d947f7e10015fd6bb9',
      },
      {
        _id: '60cc22da47f7e10015fd6bbb',
        courseCode: 'HEALTSCI 2610',
        shortName:
          'Health Sciences 2610F/G - INTRODUCTION TO ETHICS AND HEALTH',
        courseName:
          'Health Sciences 2610F/G - INTRODUCTION TO ETHICS AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22da47f7e10015fd6bbb',
      },
      {
        _id: '60cc22da47f7e10015fd6bbd',
        courseCode: 'HEALTSCI 2700',
        shortName:
          'Health Sciences 2700A/B - HEALTH ISSUES IN CHILDHOOD AND ADOLESCENCE',
        courseName:
          'Health Sciences 2700A/B - HEALTH ISSUES IN CHILDHOOD AND ADOLESCENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22da47f7e10015fd6bbd',
      },
      {
        _id: '60cc22da47f7e10015fd6bbf',
        courseCode: 'HEALTSCI 2711',
        shortName: 'Health Sciences 2711A/B - HEALTH ISSUES IN AGING',
        courseName: 'Health Sciences 2711A/B - HEALTH ISSUES IN AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22da47f7e10015fd6bbf',
      },
      {
        _id: '60cc22da47f7e10015fd6bc1',
        courseCode: 'HEALTSCI 2801',
        shortName:
          'Health Sciences 2801A/B - RESEARCH METHODS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 2801A/B - RESEARCH METHODS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22da47f7e10015fd6bc1',
      },
      {
        _id: '60cc22db47f7e10015fd6bc3',
        courseCode: 'HEALTSCI 3002',
        shortName: 'Health Sciences 3002A/B - HEALTH OCCUPATIONS',
        courseName: 'Health Sciences 3002A/B - HEALTH OCCUPATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22db47f7e10015fd6bc3',
      },
      {
        _id: '60cc22db47f7e10015fd6bc5',
        courseCode: 'HEALTSCI 3010',
        shortName:
          'Health Sciences 3010F/G - INTRODUCTION TO RURAL COMMUNITIES',
        courseName:
          'Health Sciences 3010F/G - INTRODUCTION TO RURAL COMMUNITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22db47f7e10015fd6bc5',
      },
      {
        _id: '60cc22db47f7e10015fd6bc7',
        courseCode: 'HEALTSCI 3025',
        shortName: 'Health Sciences 3025A/B - INTEGRATIVE HEALTH',
        courseName: 'Health Sciences 3025A/B - INTEGRATIVE HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22db47f7e10015fd6bc7',
      },
      {
        _id: '60cc22db47f7e10015fd6bc9',
        courseCode: 'HEALTSCI 3040',
        shortName: 'Health Sciences 3040A/B - HEALTH MANAGEMENT',
        courseName: 'Health Sciences 3040A/B - HEALTH MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22db47f7e10015fd6bc9',
      },
      {
        _id: '60cc22db47f7e10015fd6bcb',
        courseCode: 'HEALTSCI 3042',
        shortName:
          'Health Sciences 3042A/B - CROSS SECTOR HEALTH PARTNERSHIP MODELS',
        courseName:
          'Health Sciences 3042A/B - CROSS SECTOR HEALTH PARTNERSHIP MODELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22db47f7e10015fd6bcb',
      },
      {
        _id: '60cc22dc47f7e10015fd6bcd',
        courseCode: 'HEALTSCI 3050',
        shortName: 'Health Sciences 3050A/B - HEALTH RELATED QUALITY OF LIFE',
        courseName: 'Health Sciences 3050A/B - HEALTH RELATED QUALITY OF LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dc47f7e10015fd6bcd',
      },
      {
        _id: '60cc22dc47f7e10015fd6bcf',
        courseCode: 'HEALTSCI 3052',
        shortName: 'Health Sciences 3052A/B - BRIEF HISTORY OF DRUG USE',
        courseName: 'Health Sciences 3052A/B - BRIEF HISTORY OF DRUG USE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dc47f7e10015fd6bcf',
      },
      {
        _id: '60cc22dc47f7e10015fd6bd1',
        courseCode: 'HEALTSCI 3071',
        shortName:
          'Health Sciences 3071A/B - DETERMINANTS OF HEALTH AND DISEASE',
        courseName:
          'Health Sciences 3071A/B - DETERMINANTS OF HEALTH AND DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dc47f7e10015fd6bd1',
      },
      {
        _id: '60cc22dc47f7e10015fd6bd3',
        courseCode: 'HEALTSCI 3080',
        shortName:
          'Health Sciences 3080F/G - HEALTH PROMOTION AND CARING IN RURAL CONTEXTS',
        courseName:
          'Health Sciences 3080F/G - HEALTH PROMOTION AND CARING IN RURAL CONTEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dc47f7e10015fd6bd3',
      },
      {
        _id: '60cc22dd47f7e10015fd6bd5',
        courseCode: 'HEALTSCI 3090',
        shortName:
          'Health Sciences 3090A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 3090A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dd47f7e10015fd6bd5',
      },
      {
        _id: '60cc22dd47f7e10015fd6bd7',
        courseCode: 'HEALTSCI 3091',
        shortName:
          'Health Sciences 3091A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 3091A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dd47f7e10015fd6bd7',
      },
      {
        _id: '60cc22dd47f7e10015fd6bd9',
        courseCode: 'HEALTSCI 3092',
        shortName:
          'Health Sciences 3092A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 3092A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dd47f7e10015fd6bd9',
      },
      {
        _id: '60cc22dd47f7e10015fd6bdb',
        courseCode: 'HEALTSCI 3093',
        shortName: 'Health Sciences 3093F/G - SPECIAL TOPICS IN HEATH SCIENCES',
        courseName:
          'Health Sciences 3093F/G - SPECIAL TOPICS IN HEATH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22dd47f7e10015fd6bdb',
      },
      {
        _id: '60cc22de47f7e10015fd6bdd',
        courseCode: 'HEALTSCI 3094',
        shortName: 'Health Sciences 3094F/G - SPECIAL TOPICS',
        courseName: 'Health Sciences 3094F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22de47f7e10015fd6bdd',
      },
      {
        _id: '60cc22de47f7e10015fd6bdf',
        courseCode: 'HEALTSCI 3101',
        shortName: 'Health Sciences 3101A/B - HEALTHCARE LAW',
        courseName: 'Health Sciences 3101A/B - HEALTHCARE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22de47f7e10015fd6bdf',
      },
      {
        _id: '60cc22de47f7e10015fd6be1',
        courseCode: 'HEALTSCI 3141',
        shortName: 'Health Sciences 3141Y - HEALTH ADVOCACY',
        courseName: 'Health Sciences 3141Y - HEALTH ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22de47f7e10015fd6be1',
      },
      {
        _id: '60cc22de47f7e10015fd6be3',
        courseCode: 'HEALTSCI 3190',
        shortName:
          'Health Sciences 3190F/G - INTRODUCTION TO INTERPROFESSIONAL HEALTH EDUCATION',
        courseName:
          'Health Sciences 3190F/G - INTRODUCTION TO INTERPROFESSIONAL HEALTH EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22de47f7e10015fd6be3',
      },
      {
        _id: '60cc22de47f7e10015fd6be5',
        courseCode: 'HEALTSCI 3210',
        shortName: 'Health Sciences 3210A/B - HEALTH COMMUNICATION',
        courseName: 'Health Sciences 3210A/B - HEALTH COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22de47f7e10015fd6be5',
      },
      {
        _id: '60cc22df47f7e10015fd6be7',
        courseCode: 'HEALTSCI 3240',
        shortName: 'Health Sciences 3240A/B - ENVIRONMENTAL HEALTH PROMOTION',
        courseName: 'Health Sciences 3240A/B - ENVIRONMENTAL HEALTH PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22df47f7e10015fd6be7',
      },
      {
        _id: '60cc22df47f7e10015fd6be9',
        courseCode: 'HEALTSCI 3250',
        shortName: 'Health Sciences 3250F/G - GLOBAL HEALTH PROMOTION',
        courseName: 'Health Sciences 3250F/G - GLOBAL HEALTH PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22df47f7e10015fd6be9',
      },
      {
        _id: '60cc22df47f7e10015fd6beb',
        courseCode: 'HEALTSCI 3251',
        shortName:
          'Health Sciences 3251F/G - GLOBAL HEALTH PROMOTION PRACTICUM',
        courseName:
          'Health Sciences 3251F/G - GLOBAL HEALTH PROMOTION PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22df47f7e10015fd6beb',
      },
      {
        _id: '60cc22df47f7e10015fd6bed',
        courseCode: 'HEALTSCI 3262',
        shortName:
          'Health Sciences 3262F/G - MENTAL ILLNESS AND HEALING ACROSS CULTURES',
        courseName:
          'Health Sciences 3262F/G - MENTAL ILLNESS AND HEALING ACROSS CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22df47f7e10015fd6bed',
      },
      {
        _id: '60cc22e047f7e10015fd6bef',
        courseCode: 'HEALTSCI 3300',
        shortName:
          'Health Sciences 3300A/B - ANATOMY OF THE HUMAN BODY: A DESCRIPTION OF SYSTEMIC STRUCTURE & FUNCTION',
        courseName:
          'Health Sciences 3300A/B - ANATOMY OF THE HUMAN BODY: A DESCRIPTION OF SYSTEMIC STRUCTURE & FUNCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e047f7e10015fd6bef',
      },
      {
        _id: '60cc22e047f7e10015fd6bf1',
        courseCode: 'HEALTSCI 3400',
        shortName: 'Health Sciences 3400A/B - HEALTH POLICY',
        courseName: 'Health Sciences 3400A/B - HEALTH POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e047f7e10015fd6bf1',
      },
      {
        _id: '60cc22e047f7e10015fd6bf3',
        courseCode: 'HEALTSCI 3624',
        shortName:
          'Health Sciences 3624A/B - SOCIAL DETERMINANTS OF MENTAL HEALTH',
        courseName:
          'Health Sciences 3624A/B - SOCIAL DETERMINANTS OF MENTAL HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e047f7e10015fd6bf3',
      },
      {
        _id: '60cc22e047f7e10015fd6bf5',
        courseCode: 'HEALTSCI 3630',
        shortName: 'Health Sciences 3630F/G - SEXUALITY, GENDER, AND HEALTH',
        courseName: 'Health Sciences 3630F/G - SEXUALITY, GENDER, AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e047f7e10015fd6bf5',
      },
      {
        _id: '60cc22e147f7e10015fd6bf7',
        courseCode: 'HEALTSCI 3701',
        shortName: 'Health Sciences 3701A/B - THE AGING BODY',
        courseName: 'Health Sciences 3701A/B - THE AGING BODY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e147f7e10015fd6bf7',
      },
      {
        _id: '60cc22e147f7e10015fd6bf9',
        courseCode: 'HEALTSCI 3702',
        shortName: 'Health Sciences 3702A/B - THE AGING MIND',
        courseName: 'Health Sciences 3702A/B - THE AGING MIND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e147f7e10015fd6bf9',
      },
      {
        _id: '60cc22e147f7e10015fd6bfb',
        courseCode: 'HEALTSCI 3704',
        shortName: 'Health Sciences 3704A/B - POPULATION AGING AND HEALTH',
        courseName: 'Health Sciences 3704A/B - POPULATION AGING AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e147f7e10015fd6bfb',
      },
      {
        _id: '60cc22e147f7e10015fd6bfd',
        courseCode: 'HEALTSCI 3721',
        shortName:
          'Health Sciences 3721A/B - AGING GLOBALLY: LESSONS FROM SCANDINAVIA',
        courseName:
          'Health Sciences 3721A/B - AGING GLOBALLY: LESSONS FROM SCANDINAVIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e147f7e10015fd6bfd',
      },
      {
        _id: '60cc22e247f7e10015fd6bff',
        courseCode: 'HEALTSCI 3801',
        shortName:
          'Health Sciences 3801A/B - MEASUREMENT AND ANALYSIS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 3801A/B - MEASUREMENT AND ANALYSIS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e247f7e10015fd6bff',
      },
      {
        _id: '60cc22e247f7e10015fd6c01',
        courseCode: 'HEALTSCI 3811',
        shortName:
          'Health Sciences 3811F/G - INTRODUCTION TO QUALITATIVE HEALTH RESEARCH',
        courseName:
          'Health Sciences 3811F/G - INTRODUCTION TO QUALITATIVE HEALTH RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e247f7e10015fd6c01',
      },
      {
        _id: '60cc22e247f7e10015fd6c03',
        courseCode: 'HEALTSCI 3820',
        shortName: 'Health Sciences 3820A/B - PERSONAL CONSTRUCTIONS OF HEALTH',
        courseName:
          'Health Sciences 3820A/B - PERSONAL CONSTRUCTIONS OF HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e247f7e10015fd6c03',
      },
      {
        _id: '60cc22e247f7e10015fd6c05',
        courseCode: 'HEALTSCI 3840',
        shortName:
          'Health Sciences 3840A/B - INTRODUCTION TO HEALTH ECONOMIC EVALUATION METHODS',
        courseName:
          'Health Sciences 3840A/B - INTRODUCTION TO HEALTH ECONOMIC EVALUATION METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e247f7e10015fd6c05',
      },
      {
        _id: '60cc22e347f7e10015fd6c07',
        courseCode: 'HEALTSCI 3910',
        shortName:
          'Health Sciences 3910F/G - FUNDAMENTALS OF ACADEMIC COMMUNICATION IN THE HEALTH SCIENCES',
        courseName:
          'Health Sciences 3910F/G - FUNDAMENTALS OF ACADEMIC COMMUNICATION IN THE HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e347f7e10015fd6c07',
      },
      {
        _id: '60cc22e347f7e10015fd6c09',
        courseCode: 'HEALTSCI 3991',
        shortName: 'Health Sciences 3991 - INTERNSHIP WORK TERM (8 MONTHS)',
        courseName: 'Health Sciences 3991 - INTERNSHIP WORK TERM (8 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e347f7e10015fd6c09',
      },
      {
        _id: '60cc22e347f7e10015fd6c0b',
        courseCode: 'HEALTSCI 3992',
        shortName: 'Health Sciences 3992 - INTERNSHIP WORK TERM (9-12 MONTHS)',
        courseName: 'Health Sciences 3992 - INTERNSHIP WORK TERM (9-12 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e347f7e10015fd6c0b',
      },
      {
        _id: '60cc22e447f7e10015fd6c0d',
        courseCode: 'HEALTSCI 3993',
        shortName: 'Health Sciences 3993 - INTERNSHIP WORK TERM (13-16 MONTHS)',
        courseName:
          'Health Sciences 3993 - INTERNSHIP WORK TERM (13-16 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e447f7e10015fd6c0d',
      },
      {
        _id: '60cc22e447f7e10015fd6c0f',
        courseCode: 'HEALTSCI 3994',
        shortName: 'Health Sciences 3994 - INTERNSHIP WORK TERM (8 MONTHS)',
        courseName: 'Health Sciences 3994 - INTERNSHIP WORK TERM (8 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e447f7e10015fd6c0f',
      },
      {
        _id: '60cc22e447f7e10015fd6c11',
        courseCode: 'HEALTSCI 3995',
        shortName: 'Health Sciences 3995A/B - HEALTH SCIENCES IN PRACTICE',
        courseName: 'Health Sciences 3995A/B - HEALTH SCIENCES IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e447f7e10015fd6c11',
      },
      {
        _id: '60cc22e447f7e10015fd6c13',
        courseCode: 'HEALTSCI 4044',
        shortName:
          'Health Sciences 4044A/B - INTERNATIONAL HEALTH SYSTEM COMPARISONS',
        courseName:
          'Health Sciences 4044A/B - INTERNATIONAL HEALTH SYSTEM COMPARISONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e447f7e10015fd6c13',
      },
      {
        _id: '60cc22e447f7e10015fd6c15',
        courseCode: 'HEALTSCI 4051',
        shortName:
          'Health Sciences 4051F/G - MENTAL HEALTH: WELL-BEING AND RECOVERY',
        courseName:
          'Health Sciences 4051F/G - MENTAL HEALTH: WELL-BEING AND RECOVERY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e447f7e10015fd6c15',
      },
      {
        _id: '60cc22e547f7e10015fd6c17',
        courseCode: 'HEALTSCI 4074',
        shortName:
          'Health Sciences 4074A/B - PHYSICAL ACTIVITY AND EXERCISE IN OLDER ADULTS',
        courseName:
          'Health Sciences 4074A/B - PHYSICAL ACTIVITY AND EXERCISE IN OLDER ADULTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e547f7e10015fd6c17',
      },
      {
        _id: '60cc22e547f7e10015fd6c19',
        courseCode: 'HEALTSCI 4090',
        shortName:
          'Health Sciences 4090A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 4090A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e547f7e10015fd6c19',
      },
      {
        _id: '60cc22e547f7e10015fd6c1b',
        courseCode: 'HEALTSCI 4091',
        shortName:
          'Health Sciences 4091A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 4091A/B - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e547f7e10015fd6c1b',
      },
      {
        _id: '60cc22e547f7e10015fd6c1d',
        courseCode: 'HEALTSCI 4092',
        shortName:
          'Health Sciences 4092F/G - SPECIAL TOPICS IN HEALTH SCIENCES',
        courseName:
          'Health Sciences 4092F/G - SPECIAL TOPICS IN HEALTH SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e547f7e10015fd6c1d',
      },
      {
        _id: '60cc22e647f7e10015fd6c1f',
        courseCode: 'HEALTSCI 4093',
        shortName: 'Health Sciences 4093F/G - SPECIAL TOPICS',
        courseName: 'Health Sciences 4093F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e647f7e10015fd6c1f',
      },
      {
        _id: '60cc22e647f7e10015fd6c21',
        courseCode: 'HEALTSCI 4120',
        shortName: 'Health Sciences 4120A/B - SOCIAL MEDIA AND HEALTH',
        courseName: 'Health Sciences 4120A/B - SOCIAL MEDIA AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e647f7e10015fd6c21',
      },
      {
        _id: '60cc22e647f7e10015fd6c23',
        courseCode: 'HEALTSCI 4200',
        shortName: 'Health Sciences 4200F/G - ADVANCED HEALTH PROMOTION',
        courseName: 'Health Sciences 4200F/G - ADVANCED HEALTH PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e647f7e10015fd6c23',
      },
      {
        _id: '60cc22e647f7e10015fd6c25',
        courseCode: 'HEALTSCI 4202',
        shortName: 'Health Sciences 4202A/B - HEALTH BEHAVIOR',
        courseName: 'Health Sciences 4202A/B - HEALTH BEHAVIOR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e647f7e10015fd6c25',
      },
      {
        _id: '60cc22e747f7e10015fd6c27',
        courseCode: 'HEALTSCI 4205',
        shortName: 'Health Sciences 4205A/B - HEALTH PROMOTION IN PRACTICE',
        courseName: 'Health Sciences 4205A/B - HEALTH PROMOTION IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e747f7e10015fd6c27',
      },
      {
        _id: '60cc22e747f7e10015fd6c29',
        courseCode: 'HEALTSCI 4208',
        shortName: 'Health Sciences 4208A/B - UNDERSTANDING STRESS',
        courseName: 'Health Sciences 4208A/B - UNDERSTANDING STRESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e747f7e10015fd6c29',
      },
      {
        _id: '60cc22e747f7e10015fd6c2b',
        courseCode: 'HEALTSCI 4220',
        shortName:
          'Health Sciences 4220F/G - HEALTH AMONG MARGINALIZED POPULATIONS',
        courseName:
          'Health Sciences 4220F/G - HEALTH AMONG MARGINALIZED POPULATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e747f7e10015fd6c2b',
      },
      {
        _id: '60cc22e747f7e10015fd6c2d',
        courseCode: 'HEALTSCI 4240',
        shortName: 'Health Sciences 4240F/G - KNOWLEDGE TRANSLATION IN HEALTH',
        courseName: 'Health Sciences 4240F/G - KNOWLEDGE TRANSLATION IN HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e747f7e10015fd6c2d',
      },
      {
        _id: '60cc22e847f7e10015fd6c2f',
        courseCode: 'HEALTSCI 4250',
        shortName: 'Health Sciences 4250A/B - POPULATION HEALTH INTERVENTIONS',
        courseName: 'Health Sciences 4250A/B - POPULATION HEALTH INTERVENTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e847f7e10015fd6c2f',
      },
      {
        _id: '60cc22e847f7e10015fd6c31',
        courseCode: 'HEALTSCI 4320',
        shortName:
          'Health Sciences 4320A/B - HUMAN EMBRYOLOGY, DEVELOPMENT AND DISEASE',
        courseName:
          'Health Sciences 4320A/B - HUMAN EMBRYOLOGY, DEVELOPMENT AND DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e847f7e10015fd6c31',
      },
      {
        _id: '60cc22e847f7e10015fd6c33',
        courseCode: 'HEALTSCI 4351',
        shortName: 'Health Sciences 4351A/B - DISEASE MECHANISMS',
        courseName: 'Health Sciences 4351A/B - DISEASE MECHANISMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e847f7e10015fd6c33',
      },
      {
        _id: '60cc22e847f7e10015fd6c35',
        courseCode: 'HEALTSCI 4400',
        shortName: 'Health Sciences 4400A/B - ADVANCED HEALTH POLICY',
        courseName: 'Health Sciences 4400A/B - ADVANCED HEALTH POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e847f7e10015fd6c35',
      },
      {
        _id: '60cc22e947f7e10015fd6c37',
        courseCode: 'HEALTSCI 4410',
        shortName: 'Health Sciences 4410A/B - FUTURE OF HEALTH CARE',
        courseName: 'Health Sciences 4410A/B - FUTURE OF HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e947f7e10015fd6c37',
      },
      {
        _id: '60cc22e947f7e10015fd6c39',
        courseCode: 'HEALTSCI 4420',
        shortName: 'Health Sciences 4420A/B - INNOVATION IN CARE DELIVERY',
        courseName: 'Health Sciences 4420A/B - INNOVATION IN CARE DELIVERY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e947f7e10015fd6c39',
      },
      {
        _id: '60cc22e947f7e10015fd6c3b',
        courseCode: 'HEALTSCI 4480',
        shortName: 'Health Sciences 4480A/B - HEALTH SYSTEM CHANGE MANAGEMENT',
        courseName: 'Health Sciences 4480A/B - HEALTH SYSTEM CHANGE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e947f7e10015fd6c3b',
      },
      {
        _id: '60cc22e947f7e10015fd6c3d',
        courseCode: 'HEALTSCI 4490',
        shortName: 'Health Sciences 4490A/B - HEALTH INNOVATION AND LEADERSHIP',
        courseName:
          'Health Sciences 4490A/B - HEALTH INNOVATION AND LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22e947f7e10015fd6c3d',
      },
      {
        _id: '60cc22ea47f7e10015fd6c3f',
        courseCode: 'HEALTSCI 4505',
        shortName:
          'Health Sciences 4505F/G - GLOBAL HEALTH: INEQUALITY, THERAPEUTIC CITIZENSHIP, AND THE DESIRE TO HELP',
        courseName:
          'Health Sciences 4505F/G - GLOBAL HEALTH: INEQUALITY, THERAPEUTIC CITIZENSHIP, AND THE DESIRE TO HELP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ea47f7e10015fd6c3f',
      },
      {
        _id: '60cc22ea47f7e10015fd6c41',
        courseCode: 'HEALTSCI 4610',
        shortName: 'Health Sciences 4610F/G - PROFESSIONAL ETHICS',
        courseName: 'Health Sciences 4610F/G - PROFESSIONAL ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ea47f7e10015fd6c41',
      },
      {
        _id: '60cc22ea47f7e10015fd6c43',
        courseCode: 'HEALTSCI 4615',
        shortName: 'Health Sciences 4615F/G - HISTORY AND ETHICS OF FOOD',
        courseName: 'Health Sciences 4615F/G - HISTORY AND ETHICS OF FOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ea47f7e10015fd6c43',
      },
      {
        _id: '60cc22ea47f7e10015fd6c45',
        courseCode: 'HEALTSCI 4620',
        shortName: 'Health Sciences 4620F/G - FOUNDATIONS OF MENTAL HEALTH',
        courseName: 'Health Sciences 4620F/G - FOUNDATIONS OF MENTAL HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ea47f7e10015fd6c45',
      },
      {
        _id: '60cc22eb47f7e10015fd6c47',
        courseCode: 'HEALTSCI 4705',
        shortName: 'Health Sciences 4705A/B - AGING AND COMMUNITY HEALTH',
        courseName: 'Health Sciences 4705A/B - AGING AND COMMUNITY HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22eb47f7e10015fd6c47',
      },
      {
        _id: '60cc22eb47f7e10015fd6c49',
        courseCode: 'HEALTSCI 4710',
        shortName:
          'Health Sciences 4710A/B - PALLIATIVE & END OF LIFE CARE: CRITICAL ISSUES',
        courseName:
          'Health Sciences 4710A/B - PALLIATIVE & END OF LIFE CARE: CRITICAL ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22eb47f7e10015fd6c49',
      },
      {
        _id: '60cc22eb47f7e10015fd6c4b',
        courseCode: 'HEALTSCI 4711',
        shortName: 'Health Sciences 4711A/B - GERONTOLOGY IN PRACTICE',
        courseName: 'Health Sciences 4711A/B - GERONTOLOGY IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22eb47f7e10015fd6c4b',
      },
      {
        _id: '60cc22eb47f7e10015fd6c4d',
        courseCode: 'HEALTSCI 4721',
        shortName:
          'Health Sciences 4721A/B - AGING AND MARGINALIZED POPULATION',
        courseName:
          'Health Sciences 4721A/B - AGING AND MARGINALIZED POPULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22eb47f7e10015fd6c4d',
      },
      {
        _id: '60cc22ec47f7e10015fd6c4f',
        courseCode: 'HEALTSCI 4740',
        shortName:
          'Health Sciences 4740A/B - CHRONIC DISEASE PREVENTION AND MANAGEMENT',
        courseName:
          'Health Sciences 4740A/B - CHRONIC DISEASE PREVENTION AND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ec47f7e10015fd6c4f',
      },
      {
        _id: '60cc22ec47f7e10015fd6c51',
        courseCode: 'HEALTSCI 4990',
        shortName: 'Health Sciences 4990F - INDEPENDENT STUDY',
        courseName: 'Health Sciences 4990F - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ec47f7e10015fd6c51',
      },
      {
        _id: '60cc22ec47f7e10015fd6c53',
        courseCode: 'HEALTSCI 4991',
        shortName: 'Health Sciences 4991G - INDEPENDENT STUDY',
        courseName: 'Health Sciences 4991G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ec47f7e10015fd6c53',
      },
      {
        _id: '60cc22ed47f7e10015fd6c55',
        courseCode: 'HEALTSCI 4995',
        shortName: 'Health Sciences 4995F/G - PRACTICUM',
        courseName: 'Health Sciences 4995F/G - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ed47f7e10015fd6c55',
      },
      {
        _id: '60cc22ed47f7e10015fd6c57',
        courseCode: 'HEALTSCI 4996',
        shortName: 'Health Sciences 4996F/G - PRACTICUM',
        courseName: 'Health Sciences 4996F/G - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ed47f7e10015fd6c57',
      },
      {
        _id: '60cc22ed47f7e10015fd6c59',
        courseCode: 'HEBREW 1020',
        shortName: 'Hebrew 1020 - INTRODUCTION TO THE MODERN HEBREW LANGUAGE',
        courseName: 'Hebrew 1020 - INTRODUCTION TO THE MODERN HEBREW LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ed47f7e10015fd6c59',
      },
      {
        _id: '60cc22ee47f7e10015fd6c5b',
        courseCode: 'HEBREW 1021',
        shortName:
          'Hebrew 1021A/B - INTRODUCTION TO THE MODERN HEBREW LANGUAGE',
        courseName:
          'Hebrew 1021A/B - INTRODUCTION TO THE MODERN HEBREW LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ee47f7e10015fd6c5b',
      },
      {
        _id: '60cc22ee47f7e10015fd6c5d',
        courseCode: 'HEBREW 1030',
        shortName: 'Hebrew 1030 - HEBREW 1',
        courseName: 'Hebrew 1030 - HEBREW 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ee47f7e10015fd6c5d',
      },
      {
        _id: '60cc22ee47f7e10015fd6c5f',
        courseCode: 'HEBREW 1040',
        shortName: 'Hebrew 1040A/B - INTRODUCTION TO BIBLICAL HEBREW',
        courseName: 'Hebrew 1040A/B - INTRODUCTION TO BIBLICAL HEBREW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ee47f7e10015fd6c5f',
      },
      {
        _id: '60cc22ee47f7e10015fd6c61',
        courseCode: 'HEBREW 1041',
        shortName: 'Hebrew 1041A/B - INTRODUCTION TO BIBLICAL HEBREW II',
        courseName: 'Hebrew 1041A/B - INTRODUCTION TO BIBLICAL HEBREW II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ee47f7e10015fd6c61',
      },
      {
        _id: '60cc22ef47f7e10015fd6c63',
        courseCode: 'HEBREW 2200',
        shortName: 'Hebrew 2200 - HEBREW 2',
        courseName: 'Hebrew 2200 - HEBREW 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ef47f7e10015fd6c63',
      },
      {
        _id: '60cc22ef47f7e10015fd6c65',
        courseCode: 'HEBREW 2240',
        shortName: 'Hebrew 2240A/B - INTERMEDIATE BIBLICAL HEBREW: PROSE',
        courseName: 'Hebrew 2240A/B - INTERMEDIATE BIBLICAL HEBREW: PROSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ef47f7e10015fd6c65',
      },
      {
        _id: '60cc22ef47f7e10015fd6c67',
        courseCode: 'HEBREW 2241',
        shortName: 'Hebrew 2241A/B - INTERMEDIATE BIBLICAL HEBREW: POETRY',
        courseName: 'Hebrew 2241A/B - INTERMEDIATE BIBLICAL HEBREW: POETRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ef47f7e10015fd6c67',
      },
      {
        _id: '60cc22ef47f7e10015fd6c69',
        courseCode: 'HEBREW 3300',
        shortName: 'Hebrew 3300 - ADVANCED MODERN HEBREW LANGUAGE',
        courseName: 'Hebrew 3300 - ADVANCED MODERN HEBREW LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ef47f7e10015fd6c69',
      },
      {
        _id: '60cc22f047f7e10015fd6c6b',
        courseCode: 'HINDI 1030',
        shortName: 'Hindi 1030 - HINDI FOR BEGINNERS',
        courseName: 'Hindi 1030 - HINDI FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f047f7e10015fd6c6b',
      },
      {
        _id: '60cc22f047f7e10015fd6c6d',
        courseCode: 'HINDI 1035',
        shortName: 'Hindi 1035 - BEGINNER HINDI FOR HERITAGE SPEAKERS',
        courseName: 'Hindi 1035 - BEGINNER HINDI FOR HERITAGE SPEAKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f047f7e10015fd6c6d',
      },
      {
        _id: '60cc22f047f7e10015fd6c6f',
        courseCode: 'HISTORY 0011',
        shortName:
          'History 0011 - HOW WE GOT HERE: THE TWENTIETH-CENTURY WORLD',
        courseName:
          'History 0011 - HOW WE GOT HERE: THE TWENTIETH-CENTURY WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f047f7e10015fd6c6f',
      },
      {
        _id: '60cc22f047f7e10015fd6c71',
        courseCode: 'HISTORY 0014',
        shortName: 'History 0014 - HISTORY OF CANADA AND THE UNITED STATES',
        courseName: 'History 0014 - HISTORY OF CANADA AND THE UNITED STATES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f047f7e10015fd6c71',
      },
      {
        _id: '60cc22f047f7e10015fd6c73',
        courseCode: 'HISTORY 0020',
        shortName: 'History 0020A/B - THE EARLY TWENTIETH-CENTURY',
        courseName: 'History 0020A/B - THE EARLY TWENTIETH-CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f047f7e10015fd6c73',
      },
      {
        _id: '60cc22f147f7e10015fd6c75',
        courseCode: 'HISTORY 0021',
        shortName: 'History 0021A/B - THE COLD WAR TO THE PRESENT',
        courseName: 'History 0021A/B - THE COLD WAR TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f147f7e10015fd6c75',
      },
      {
        _id: '60cc22f147f7e10015fd6c77',
        courseCode: 'HISTORY 1201',
        shortName: 'History 1201E - CANADA: A SURVEY',
        courseName: 'History 1201E - CANADA: A SURVEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f147f7e10015fd6c77',
      },
      {
        _id: '60cc22f247f7e10015fd6c79',
        courseCode: 'HISTORY 1202',
        shortName:
          'History 1202F/G - CONFLICTS AND CONTROVERSIES IN CANADIAN HISTORY',
        courseName:
          'History 1202F/G - CONFLICTS AND CONTROVERSIES IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f247f7e10015fd6c79',
      },
      {
        _id: '60cc22f247f7e10015fd6c7b',
        courseCode: 'HISTORY 1203',
        shortName: 'History 1203F/G - MAKING CANADA THROUGH PLACE AND PAST',
        courseName: 'History 1203F/G - MAKING CANADA THROUGH PLACE AND PAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f247f7e10015fd6c7b',
      },
      {
        _id: '60cc22f247f7e10015fd6c7d',
        courseCode: 'HISTORY 1401',
        shortName:
          'History 1401E - MODERN EUROPE, 1715 TO THE PRESENT: CONFLICT AND TRANSFORMATION',
        courseName:
          'History 1401E - MODERN EUROPE, 1715 TO THE PRESENT: CONFLICT AND TRANSFORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f247f7e10015fd6c7d',
      },
      {
        _id: '60cc22f347f7e10015fd6c7f',
        courseCode: 'HISTORY 1404',
        shortName:
          'History 1404E - HITLER, STALIN AND MUSSOLINI: THE TOTALITARIAN AGE',
        courseName:
          'History 1404E - HITLER, STALIN AND MUSSOLINI: THE TOTALITARIAN AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f347f7e10015fd6c7f',
      },
      {
        _id: '60cc22f347f7e10015fd6c81',
        courseCode: 'HISTORY 1601',
        shortName: 'History 1601E - SURVEY OF EAST ASIAN HISTORY',
        courseName: 'History 1601E - SURVEY OF EAST ASIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f347f7e10015fd6c81',
      },
      {
        _id: '60cc22f347f7e10015fd6c83',
        courseCode: 'HISTORY 1606',
        shortName:
          'History 1606F/G - CHINA: CURRENT ISSUES IN HISTORICAL CONTEXT',
        courseName:
          'History 1606F/G - CHINA: CURRENT ISSUES IN HISTORICAL CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f347f7e10015fd6c83',
      },
      {
        _id: '60cc22f447f7e10015fd6c85',
        courseCode: 'HISTORY 1800',
        shortName: "History 1800F/G - THE PEOPLE'S HISTORIES",
        courseName: "History 1800F/G - THE PEOPLE'S HISTORIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f447f7e10015fd6c85',
      },
      {
        _id: '60cc22f447f7e10015fd6c87',
        courseCode: 'HISTORY 1801',
        shortName:
          'History 1801E - BLOOD, SWEAT & GOLD: CONTROVERSIES IN GLOBAL HISTORY',
        courseName:
          'History 1801E - BLOOD, SWEAT & GOLD: CONTROVERSIES IN GLOBAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f447f7e10015fd6c87',
      },
      {
        _id: '60cc22f447f7e10015fd6c89',
        courseCode: 'HISTORY 1802',
        shortName: 'History 1802E - HISTORIES FROM BELOW',
        courseName: 'History 1802E - HISTORIES FROM BELOW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f447f7e10015fd6c89',
      },
      {
        _id: '60cc22f447f7e10015fd6c8b',
        courseCode: 'HISTORY 1807',
        shortName: 'History 1807 - THE HISTORY OF BUSINESS',
        courseName: 'History 1807 - THE HISTORY OF BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f447f7e10015fd6c8b',
      },
      {
        _id: '60cc22f547f7e10015fd6c8d',
        courseCode: 'HISTORY 1808',
        shortName: 'History 1808F/G - A FEW WORDS THAT CHANGED THE WORLD',
        courseName: 'History 1808F/G - A FEW WORDS THAT CHANGED THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f547f7e10015fd6c8d',
      },
      {
        _id: '60cc22f547f7e10015fd6c8f',
        courseCode: 'HISTORY 1810',
        shortName: 'History 1810E - WARS THAT CHANGED THE WORLD',
        courseName: 'History 1810E - WARS THAT CHANGED THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f547f7e10015fd6c8f',
      },
      {
        _id: '60cc22f547f7e10015fd6c91',
        courseCode: 'HISTORY 1812',
        shortName: 'History 1812F/G - REVOLUTIONS IN WORLD HISTORY',
        courseName: 'History 1812F/G - REVOLUTIONS IN WORLD HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f547f7e10015fd6c91',
      },
      {
        _id: '60cc22f547f7e10015fd6c93',
        courseCode: 'HISTORY 1813',
        shortName: 'History 1813F/G - WARS OF RELIGION IN WORLD HISTORY',
        courseName: 'History 1813F/G - WARS OF RELIGION IN WORLD HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f547f7e10015fd6c93',
      },
      {
        _id: '60cc22f647f7e10015fd6c95',
        courseCode: 'HISTORY 1814',
        shortName: 'History 1814F/G - HISTORY, LAW AND SOCIETY',
        courseName: 'History 1814F/G - HISTORY, LAW AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f647f7e10015fd6c95',
      },
      {
        _id: '60cc22f647f7e10015fd6c97',
        courseCode: 'HISTORY 1815',
        shortName: 'History 1815F/G - HISTORIES OF LOVE',
        courseName: 'History 1815F/G - HISTORIES OF LOVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f647f7e10015fd6c97',
      },
      {
        _id: '60cc22f647f7e10015fd6c99',
        courseCode: 'HISTORY 1816',
        shortName: 'History 1816F/G - HISTORIES OF VIOLENCE',
        courseName: 'History 1816F/G - HISTORIES OF VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f647f7e10015fd6c99',
      },
      {
        _id: '60cc22f647f7e10015fd6c9b',
        courseCode: 'HISTORY 1817',
        shortName: 'History 1817F/G - HISTORY IN THE HEADLINES',
        courseName: 'History 1817F/G - HISTORY IN THE HEADLINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f647f7e10015fd6c9b',
      },
      {
        _id: '60cc22f747f7e10015fd6c9d',
        courseCode: 'HISTORY 1818',
        shortName:
          'History 1818F/G - TREASURE: OBJECTS OF DESIRE IN GLOBAL HISTORY',
        courseName:
          'History 1818F/G - TREASURE: OBJECTS OF DESIRE IN GLOBAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f747f7e10015fd6c9d',
      },
      {
        _id: '60cc22f747f7e10015fd6c9f',
        courseCode: 'HISTORY 1820',
        shortName: 'History 1820F/G - SELECTED INTRODUCTORY THEMES IN HISTORY',
        courseName: 'History 1820F/G - SELECTED INTRODUCTORY THEMES IN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f747f7e10015fd6c9f',
      },
      {
        _id: '60cc22f747f7e10015fd6ca1',
        courseCode: 'HISTORY 1830',
        shortName: 'History 1830F/G - FIRST-YEAR HISTORY SEMINAR',
        courseName: 'History 1830F/G - FIRST-YEAR HISTORY SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f747f7e10015fd6ca1',
      },
      {
        _id: '60cc22f747f7e10015fd6ca3',
        courseCode: 'HISTORY 1901',
        shortName:
          'History 1901E - FOUNDATIONS IN WESTERN THOUGHT AND CIVILIZATION',
        courseName:
          'History 1901E - FOUNDATIONS IN WESTERN THOUGHT AND CIVILIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f747f7e10015fd6ca3',
      },
      {
        _id: '60cc22f747f7e10015fd6ca5',
        courseCode: 'HISTORY 2103',
        shortName:
          'History 2103 - LIFE AND DEATH IN RENAISSANCE AND REFORMATION EUROPE',
        courseName:
          'History 2103 - LIFE AND DEATH IN RENAISSANCE AND REFORMATION EUROPE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f747f7e10015fd6ca5',
      },
      {
        _id: '60cc22f847f7e10015fd6ca7',
        courseCode: 'HISTORY 2108',
        shortName:
          'History 2108F/G - THE EUROPEAN WITCHCRAZE: CRIME AND DEVIANCE IN THE EARLY MODERN WORLD',
        courseName:
          'History 2108F/G - THE EUROPEAN WITCHCRAZE: CRIME AND DEVIANCE IN THE EARLY MODERN WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f847f7e10015fd6ca7',
      },
      {
        _id: '60cc22f847f7e10015fd6ca9',
        courseCode: 'HISTORY 2110',
        shortName: 'History 2110A/B - FRAGILE FREEDOMS? HUMAN RIGHTS IN CANADA',
        courseName:
          'History 2110A/B - FRAGILE FREEDOMS? HUMAN RIGHTS IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f847f7e10015fd6ca9',
      },
      {
        _id: '60cc22f847f7e10015fd6cab',
        courseCode: 'HISTORY 2120',
        shortName:
          'History 2120A/B - NORTHERN ENTERPRISE: CANADIAN BUSINESS AND LABOUR HISTORY',
        courseName:
          'History 2120A/B - NORTHERN ENTERPRISE: CANADIAN BUSINESS AND LABOUR HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f847f7e10015fd6cab',
      },
      {
        _id: '60cc22f847f7e10015fd6cad',
        courseCode: 'HISTORY 2122',
        shortName: 'History 2122A/B - BUILDING MODERN CANADA, 1845-1945',
        courseName: 'History 2122A/B - BUILDING MODERN CANADA, 1845-1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f847f7e10015fd6cad',
      },
      {
        _id: '60cc22f947f7e10015fd6caf',
        courseCode: 'HISTORY 2123',
        shortName: 'History 2123 - TOPICS IN CANADIAN ENVIRONMENTAL HISTORY',
        courseName: 'History 2123 - TOPICS IN CANADIAN ENVIRONMENTAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f947f7e10015fd6caf',
      },
      {
        _id: '60cc22f947f7e10015fd6cb1',
        courseCode: 'HISTORY 2124',
        shortName:
          'History 2124A/B - SOUNDS, SIGHTS & BITS: EXPLORATIONS IN 20TH CENTURY CANADIAN POPULAR CULTURE',
        courseName:
          'History 2124A/B - SOUNDS, SIGHTS & BITS: EXPLORATIONS IN 20TH CENTURY CANADIAN POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f947f7e10015fd6cb1',
      },
      {
        _id: '60cc22f947f7e10015fd6cb3',
        courseCode: 'HISTORY 2125',
        shortName:
          'History 2125F/G - NORTHERN ENTERPRISE: CANADIAN BUSINESS AND LABOR HISTORY',
        courseName:
          'History 2125F/G - NORTHERN ENTERPRISE: CANADIAN BUSINESS AND LABOR HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f947f7e10015fd6cb3',
      },
      {
        _id: '60cc22f947f7e10015fd6cb5',
        courseCode: 'HISTORY 2126',
        shortName: 'History 2126A/B - EDITORIAL CARTOONS IN CANADIAN HISTORY',
        courseName: 'History 2126A/B - EDITORIAL CARTOONS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22f947f7e10015fd6cb5',
      },
      {
        _id: '60cc22fa47f7e10015fd6cb7',
        courseCode: 'HISTORY 2127',
        shortName: 'History 2127F/G - IN SEARCH OF CANADA: POSTWAR TO PRESENT',
        courseName: 'History 2127F/G - IN SEARCH OF CANADA: POSTWAR TO PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fa47f7e10015fd6cb7',
      },
      {
        _id: '60cc22fa47f7e10015fd6cb9',
        courseCode: 'HISTORY 2128',
        shortName: 'History 2128A/B - IN SEARCH OF CANADA: POSTWAR TO PRESENT',
        courseName: 'History 2128A/B - IN SEARCH OF CANADA: POSTWAR TO PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fa47f7e10015fd6cb9',
      },
      {
        _id: '60cc22fa47f7e10015fd6cbb',
        courseCode: 'HISTORY 2131',
        shortName: 'History 2131A/B - THE PRESIDENCY IN AMERICAN HISTORY',
        courseName: 'History 2131A/B - THE PRESIDENCY IN AMERICAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fa47f7e10015fd6cbb',
      },
      {
        _id: '60cc22fb47f7e10015fd6cbe',
        courseCode: 'HISTORY 2133',
        shortName:
          'History 2133 - CULTS, TERROR AND EXTREMISM IN 20TH CENTURY AMERICA',
        courseName:
          'History 2133 - CULTS, TERROR AND EXTREMISM IN 20TH CENTURY AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fb47f7e10015fd6cbe',
      },
      {
        _id: '60cc22fb47f7e10015fd6cc0',
        courseCode: 'HISTORY 2134',
        shortName:
          "History 2134A/B - \"TALKIN' BOUT MY GENERATION\": YOUTH, REBELLION, AND ROCK 'N' ROLL",
        courseName:
          "History 2134A/B - \"TALKIN' BOUT MY GENERATION\": YOUTH, REBELLION, AND ROCK 'N' ROLL",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fb47f7e10015fd6cc0',
      },
      {
        _id: '60cc22fb47f7e10015fd6cc2',
        courseCode: 'HISTORY 2135',
        shortName:
          'History 2135A/B - AFRICAN-AMERICAN HISTORY: RACISM AND THE STRUGGLE FOR FREEDOM IN AMERICA',
        courseName:
          'History 2135A/B - AFRICAN-AMERICAN HISTORY: RACISM AND THE STRUGGLE FOR FREEDOM IN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fb47f7e10015fd6cc2',
      },
      {
        _id: '60cc22fb47f7e10015fd6cc4',
        courseCode: 'HISTORY 2137',
        shortName:
          'History 2137A/B - DRAFT DODGERS, HIPPIES, AND BLACK PANTHERS: THE U.S. IN THE 1960S',
        courseName:
          'History 2137A/B - DRAFT DODGERS, HIPPIES, AND BLACK PANTHERS: THE U.S. IN THE 1960S',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fb47f7e10015fd6cc4',
      },
      {
        _id: '60cc22fc47f7e10015fd6cc6',
        courseCode: 'HISTORY 2140',
        shortName:
          'History 2140 - WOMEN IN CANADIAN HISTORY:  CHANGING ROLES AND DIVERSE SOCIAL REALITIES',
        courseName:
          'History 2140 - WOMEN IN CANADIAN HISTORY:  CHANGING ROLES AND DIVERSE SOCIAL REALITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fc47f7e10015fd6cc6',
      },
      {
        _id: '60cc22fc47f7e10015fd6cc8',
        courseCode: 'HISTORY 2147',
        shortName: 'History 2147A/B - NAZI GERMANY',
        courseName: 'History 2147A/B - NAZI GERMANY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fc47f7e10015fd6cc8',
      },
      {
        _id: '60cc22fc47f7e10015fd6cca',
        courseCode: 'HISTORY 2148',
        shortName:
          'History 2148A/B - POLICE WORK AND FORENSICS IN VICTORIAN BRITAIN',
        courseName:
          'History 2148A/B - POLICE WORK AND FORENSICS IN VICTORIAN BRITAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fc47f7e10015fd6cca',
      },
      {
        _id: '60cc22fc47f7e10015fd6ccc',
        courseCode: 'HISTORY 2156',
        shortName: 'History 2156F/G - FOOD AND CONFLICT',
        courseName: 'History 2156F/G - FOOD AND CONFLICT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fc47f7e10015fd6ccc',
      },
      {
        _id: '60cc22fd47f7e10015fd6cce',
        courseCode: 'HISTORY 2158',
        shortName: 'History 2158A/B - FOOD IN WORLD HISTORY',
        courseName: 'History 2158A/B - FOOD IN WORLD HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fd47f7e10015fd6cce',
      },
      {
        _id: '60cc22fd47f7e10015fd6cd0',
        courseCode: 'HISTORY 2159',
        shortName:
          'History 2159A/B - MIDWIVES, MADONNAS, WITCHES AND WHORES: WOMEN IN EARLY MODERN EUROPE, 1500 - 1700',
        courseName:
          'History 2159A/B - MIDWIVES, MADONNAS, WITCHES AND WHORES: WOMEN IN EARLY MODERN EUROPE, 1500 - 1700',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fd47f7e10015fd6cd0',
      },
      {
        _id: '60cc22fd47f7e10015fd6cd2',
        courseCode: 'HISTORY 2162',
        shortName:
          'History 2162A/B - A BASIC INTRODUCTION TO THE HISTORY OF THE MIDDLE EAST',
        courseName:
          'History 2162A/B - A BASIC INTRODUCTION TO THE HISTORY OF THE MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fd47f7e10015fd6cd2',
      },
      {
        _id: '60cc22fd47f7e10015fd6cd4',
        courseCode: 'HISTORY 2163',
        shortName:
          'History 2163A/B - KOREAN SOCIAL AND CULTURAL HISTORY BEFORE 1900',
        courseName:
          'History 2163A/B - KOREAN SOCIAL AND CULTURAL HISTORY BEFORE 1900',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fd47f7e10015fd6cd4',
      },
      {
        _id: '60cc22fe47f7e10015fd6cd6',
        courseCode: 'HISTORY 2164',
        shortName: 'History 2164A/B - COMMUNIST CHINA FROM MAO TO THE PRESENT',
        courseName: 'History 2164A/B - COMMUNIST CHINA FROM MAO TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fe47f7e10015fd6cd6',
      },
      {
        _id: '60cc22fe47f7e10015fd6cd8',
        courseCode: 'HISTORY 2165',
        shortName:
          'History 2165A/B - KOREAN SOCIAL AND CULTURAL HISTORY SINCE 1900',
        courseName:
          'History 2165A/B - KOREAN SOCIAL AND CULTURAL HISTORY SINCE 1900',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fe47f7e10015fd6cd8',
      },
      {
        _id: '60cc22fe47f7e10015fd6cda',
        courseCode: 'HISTORY 2166',
        shortName:
          'History 2166A/B - KOREAN WAVE AND BEYOND: SOUTH KOREAN POPULAR CULTURE',
        courseName:
          'History 2166A/B - KOREAN WAVE AND BEYOND: SOUTH KOREAN POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fe47f7e10015fd6cda',
      },
      {
        _id: '60cc22fe47f7e10015fd6cdc',
        courseCode: 'HISTORY 2167',
        shortName:
          'History 2167A/B - SALT, SILK AND SILVER: ECONOMIC HISTORY OF EARLY MODERN EAST ASIA',
        courseName:
          'History 2167A/B - SALT, SILK AND SILVER: ECONOMIC HISTORY OF EARLY MODERN EAST ASIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fe47f7e10015fd6cdc',
      },
      {
        _id: '60cc22fe47f7e10015fd6cde',
        courseCode: 'HISTORY 2168',
        shortName:
          'History 2168A/B - TACKLING NATURE: THE ENVIRONMENT IN CANADIAN HISTORY',
        courseName:
          'History 2168A/B - TACKLING NATURE: THE ENVIRONMENT IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22fe47f7e10015fd6cde',
      },
      {
        _id: '60cc22ff47f7e10015fd6ce0',
        courseCode: 'HISTORY 2170',
        shortName: 'History 2170A/B - PARKS, PEOPLE, AND POWER',
        courseName: 'History 2170A/B - PARKS, PEOPLE, AND POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ff47f7e10015fd6ce0',
      },
      {
        _id: '60cc22ff47f7e10015fd6ce2',
        courseCode: 'HISTORY 2171',
        shortName:
          'History 2171A/B - GREED IS GOOD: THE HISTORY OF MODERN CAPITALISM',
        courseName:
          'History 2171A/B - GREED IS GOOD: THE HISTORY OF MODERN CAPITALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ff47f7e10015fd6ce2',
      },
      {
        _id: '60cc22ff47f7e10015fd6ce4',
        courseCode: 'HISTORY 2172',
        shortName: 'History 2172A/B - THE HISTORY OF SPORT',
        courseName: 'History 2172A/B - THE HISTORY OF SPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ff47f7e10015fd6ce4',
      },
      {
        _id: '60cc22ff47f7e10015fd6ce6',
        courseCode: 'HISTORY 2175',
        shortName: "History 2175 - NORTH AMERICA'S WARS",
        courseName: "History 2175 - NORTH AMERICA'S WARS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc22ff47f7e10015fd6ce6',
      },
      {
        _id: '60cc230047f7e10015fd6ce8',
        courseCode: 'HISTORY 2177',
        shortName: 'History 2177A/B - THE TWO WORLD WARS',
        courseName: 'History 2177A/B - THE TWO WORLD WARS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230047f7e10015fd6ce8',
      },
      {
        _id: '60cc230047f7e10015fd6cea',
        courseCode: 'HISTORY 2179',
        shortName: 'History 2179 - THE TWO WORLD WARS',
        courseName: 'History 2179 - THE TWO WORLD WARS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230047f7e10015fd6cea',
      },
      {
        _id: '60cc230047f7e10015fd6cec',
        courseCode: 'HISTORY 2180',
        shortName: 'History 2180 - THE HISTORY OF DEATH',
        courseName: 'History 2180 - THE HISTORY OF DEATH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230047f7e10015fd6cec',
      },
      {
        _id: '60cc230147f7e10015fd6cee',
        courseCode: 'HISTORY 2181',
        shortName:
          'History 2181A/B - SEXUAL HISTORY: EXPRESSION, REGULATION AND RIGHTS IN THE WEST SINCE 1800',
        courseName:
          'History 2181A/B - SEXUAL HISTORY: EXPRESSION, REGULATION AND RIGHTS IN THE WEST SINCE 1800',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230147f7e10015fd6cee',
      },
      {
        _id: '60cc230147f7e10015fd6cf0',
        courseCode: 'HISTORY 2182',
        shortName: 'History 2182A/B - SOCIAL HISTORY OF WOMEN IN CANADA',
        courseName: 'History 2182A/B - SOCIAL HISTORY OF WOMEN IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230147f7e10015fd6cf0',
      },
      {
        _id: '60cc230147f7e10015fd6cf2',
        courseCode: 'HISTORY 2184',
        shortName:
          'History 2184 - WOMEN, MEN AND TECHNOLOGY: A HISTORICAL PERSPECTIVE',
        courseName:
          'History 2184 - WOMEN, MEN AND TECHNOLOGY: A HISTORICAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230147f7e10015fd6cf2',
      },
      {
        _id: '60cc230147f7e10015fd6cf4',
        courseCode: 'HISTORY 2186',
        shortName:
          'History 2186A/B - ZOMBIE APOCALYPSE: PANIC AND PARANOIA FROM THE BLACK DEATH TO Y2K',
        courseName:
          'History 2186A/B - ZOMBIE APOCALYPSE: PANIC AND PARANOIA FROM THE BLACK DEATH TO Y2K',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230147f7e10015fd6cf4',
      },
      {
        _id: '60cc230247f7e10015fd6cf6',
        courseCode: 'HISTORY 2187',
        shortName:
          'History 2187 - POWER TO THE PEOPLES: A HISTORY OF RIGHTS IN NORTH AMERICA',
        courseName:
          'History 2187 - POWER TO THE PEOPLES: A HISTORY OF RIGHTS IN NORTH AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230247f7e10015fd6cf6',
      },
      {
        _id: '60cc230247f7e10015fd6cf8',
        courseCode: 'HISTORY 2188',
        shortName:
          "History 2188A/B - PIRATES & PIRACY ON THE WORLD'S SEAS AND IN THE PUBLIC IMAGINATION",
        courseName:
          "History 2188A/B - PIRATES & PIRACY ON THE WORLD'S SEAS AND IN THE PUBLIC IMAGINATION",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230247f7e10015fd6cf8',
      },
      {
        _id: '60cc230247f7e10015fd6cfa',
        courseCode: 'HISTORY 2189',
        shortName: 'History 2189A/B - HISTORY AT THE MOVIES',
        courseName: 'History 2189A/B - HISTORY AT THE MOVIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230247f7e10015fd6cfa',
      },
      {
        _id: '60cc230247f7e10015fd6cfc',
        courseCode: 'HISTORY 2190',
        shortName: 'History 2190A/B - CRIME IN NORTH AMERICAN SOCIETY',
        courseName: 'History 2190A/B - CRIME IN NORTH AMERICAN SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230247f7e10015fd6cfc',
      },
      {
        _id: '60cc230247f7e10015fd6cfe',
        courseCode: 'HISTORY 2192',
        shortName:
          'History 2192A/B - BEER: THE BUSINESS, SOCIAL AND CULTURAL HISTORY OF A GLOBAL BEVERAGE',
        courseName:
          'History 2192A/B - BEER: THE BUSINESS, SOCIAL AND CULTURAL HISTORY OF A GLOBAL BEVERAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230247f7e10015fd6cfe',
      },
      {
        _id: '60cc230347f7e10015fd6d00',
        courseCode: 'HISTORY 2193',
        shortName: 'History 2193A/B - CONSPIRACY THEORIES IN AMERICAN HISTORY',
        courseName: 'History 2193A/B - CONSPIRACY THEORIES IN AMERICAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230347f7e10015fd6d00',
      },
      {
        _id: '60cc230347f7e10015fd6d02',
        courseCode: 'HISTORY 2194',
        shortName:
          'History 2194A/B - AMERICA AT BAT:  US HISTORY THROUGH BASEBALL',
        courseName:
          'History 2194A/B - AMERICA AT BAT:  US HISTORY THROUGH BASEBALL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230347f7e10015fd6d02',
      },
      {
        _id: '60cc230347f7e10015fd6d04',
        courseCode: 'HISTORY 2195',
        shortName: 'History 2195A/B - EPIDEMICS IN NORTH AMERICA',
        courseName: 'History 2195A/B - EPIDEMICS IN NORTH AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230347f7e10015fd6d04',
      },
      {
        _id: '60cc230347f7e10015fd6d06',
        courseCode: 'HISTORY 2197',
        shortName: 'History 2197A/B - ORIGINS OF GLOBAL CAPITALISM',
        courseName: 'History 2197A/B - ORIGINS OF GLOBAL CAPITALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230347f7e10015fd6d06',
      },
      {
        _id: '60cc230447f7e10015fd6d08',
        courseCode: 'HISTORY 2198',
        shortName: 'History 2198A/B - SELECTED TOPICS COURSE',
        courseName: 'History 2198A/B - SELECTED TOPICS COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230447f7e10015fd6d08',
      },
      {
        _id: '60cc230447f7e10015fd6d0a',
        courseCode: 'HISTORY 2201',
        shortName:
          "History 2201E - CANADA'S PAST: A CRITICAL HISTORY FROM THE ORIGINS TO THE PRESENT",
        courseName:
          "History 2201E - CANADA'S PAST: A CRITICAL HISTORY FROM THE ORIGINS TO THE PRESENT",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230447f7e10015fd6d0a',
      },
      {
        _id: '60cc230447f7e10015fd6d0c',
        courseCode: 'HISTORY 2202',
        shortName:
          'History 2202 - NATIONAL DREAMS AND DIVERGENT REALITIES: CANADIAN HISTORY SINCE THE 1860S',
        courseName:
          'History 2202 - NATIONAL DREAMS AND DIVERGENT REALITIES: CANADIAN HISTORY SINCE THE 1860S',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230447f7e10015fd6d0c',
      },
      {
        _id: '60cc230547f7e10015fd6d0e',
        courseCode: 'HISTORY 2203',
        shortName: 'History 2203E - PRE-CONFEDERATION CANADA',
        courseName: 'History 2203E - PRE-CONFEDERATION CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230547f7e10015fd6d0e',
      },
      {
        _id: '60cc230547f7e10015fd6d10',
        courseCode: 'HISTORY 2204',
        shortName:
          'History 2204F/G - CRISES AND CONFEDERATION: THE MAKING OF MODERN CANADA',
        courseName:
          'History 2204F/G - CRISES AND CONFEDERATION: THE MAKING OF MODERN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230547f7e10015fd6d10',
      },
      {
        _id: '60cc230547f7e10015fd6d12',
        courseCode: 'HISTORY 2205',
        shortName: 'History 2205E - CANADA: FROM CONFEDERATION TO THE PRESENT',
        courseName: 'History 2205E - CANADA: FROM CONFEDERATION TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230547f7e10015fd6d12',
      },
      {
        _id: '60cc230647f7e10015fd6d14',
        courseCode: 'HISTORY 2206',
        shortName: "History 2206 - CANADA'S CONTESTED PAST",
        courseName: "History 2206 - CANADA'S CONTESTED PAST",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230647f7e10015fd6d14',
      },
      {
        _id: '60cc230647f7e10015fd6d16',
        courseCode: 'HISTORY 2209',
        shortName: 'History 2209E - THE FIRST NATIONS IN CANADIAN HISTORY',
        courseName: 'History 2209E - THE FIRST NATIONS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230647f7e10015fd6d16',
      },
      {
        _id: '60cc230647f7e10015fd6d18',
        courseCode: 'HISTORY 2211',
        shortName: 'History 2211E - TOPICS IN CANADIAN ENVIRONMENTAL HISTORY',
        courseName: 'History 2211E - TOPICS IN CANADIAN ENVIRONMENTAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230647f7e10015fd6d18',
      },
      {
        _id: '60cc230647f7e10015fd6d1a',
        courseCode: 'HISTORY 2226',
        shortName:
          'History 2226F/G - EDITORIAL CARTOONS IN CANADIAN HISTORY AND SOCIETY',
        courseName:
          'History 2226F/G - EDITORIAL CARTOONS IN CANADIAN HISTORY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230647f7e10015fd6d1a',
      },
      {
        _id: '60cc230747f7e10015fd6d1c',
        courseCode: 'HISTORY 2268',
        shortName: 'History 2268F/G - NATURE AND CULTURE IN CANADIAN HISTORY',
        courseName: 'History 2268F/G - NATURE AND CULTURE IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230747f7e10015fd6d1c',
      },
      {
        _id: '60cc230747f7e10015fd6d1e',
        courseCode: 'HISTORY 2270',
        shortName: 'History 2270F/G - PARKS, PEOPLE, AND POWER IN PERSPECTIVE',
        courseName: 'History 2270F/G - PARKS, PEOPLE, AND POWER IN PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230747f7e10015fd6d1e',
      },
      {
        _id: '60cc230747f7e10015fd6d20',
        courseCode: 'HISTORY 2291',
        shortName: 'History 2291E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2291E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230747f7e10015fd6d20',
      },
      {
        _id: '60cc230747f7e10015fd6d22',
        courseCode: 'HISTORY 2292',
        shortName: 'History 2292E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2292E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230747f7e10015fd6d22',
      },
      {
        _id: '60cc230847f7e10015fd6d24',
        courseCode: 'HISTORY 2293',
        shortName: 'History 2293E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2293E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230847f7e10015fd6d24',
      },
      {
        _id: '60cc230847f7e10015fd6d26',
        courseCode: 'HISTORY 2294',
        shortName: 'History 2294E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2294E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230847f7e10015fd6d26',
      },
      {
        _id: '60cc230947f7e10015fd6d28',
        courseCode: 'HISTORY 2295',
        shortName: 'History 2295E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2295E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230947f7e10015fd6d28',
      },
      {
        _id: '60cc230947f7e10015fd6d2a',
        courseCode: 'HISTORY 2296',
        shortName: 'History 2296F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2296F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230947f7e10015fd6d2a',
      },
      {
        _id: '60cc230a47f7e10015fd6d2c',
        courseCode: 'HISTORY 2297',
        shortName: 'History 2297F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2297F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230a47f7e10015fd6d2c',
      },
      {
        _id: '60cc230a47f7e10015fd6d2e',
        courseCode: 'HISTORY 2298',
        shortName: 'History 2298F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2298F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230a47f7e10015fd6d2e',
      },
      {
        _id: '60cc230a47f7e10015fd6d30',
        courseCode: 'HISTORY 2299',
        shortName: 'History 2299F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 2299F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230a47f7e10015fd6d30',
      },
      {
        _id: '60cc230b47f7e10015fd6d32',
        courseCode: 'HISTORY 2301',
        shortName:
          'History 2301E - THE UNITED STATES, COLONIAL PERIOD TO THE PRESENT',
        courseName:
          'History 2301E - THE UNITED STATES, COLONIAL PERIOD TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230b47f7e10015fd6d32',
      },
      {
        _id: '60cc230b47f7e10015fd6d34',
        courseCode: 'HISTORY 2302',
        shortName:
          'History 2302F/G - AMERICAN MODERN: THE UNITED STATES IN THE TWENTIETH CENTURY',
        courseName:
          'History 2302F/G - AMERICAN MODERN: THE UNITED STATES IN THE TWENTIETH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230b47f7e10015fd6d34',
      },
      {
        _id: '60cc230c47f7e10015fd6d36',
        courseCode: 'HISTORY 2310',
        shortName:
          'History 2310F/G - AMERICAN NIGHTMARE: AN INTRODUCTION TO AMERICAN STUDIES',
        courseName:
          'History 2310F/G - AMERICAN NIGHTMARE: AN INTRODUCTION TO AMERICAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230c47f7e10015fd6d36',
      },
      {
        _id: '60cc230c47f7e10015fd6d38',
        courseCode: 'HISTORY 2311',
        shortName: 'History 2311F/G - THE UNITED STATES: 1865 TO THE PRESENT',
        courseName: 'History 2311F/G - THE UNITED STATES: 1865 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230c47f7e10015fd6d38',
      },
      {
        _id: '60cc230c47f7e10015fd6d3a',
        courseCode: 'HISTORY 2312',
        shortName:
          'History 2312F/G - THE UNITED STATES: THE BEGINNINGS TO 1865',
        courseName:
          'History 2312F/G - THE UNITED STATES: THE BEGINNINGS TO 1865',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230c47f7e10015fd6d3a',
      },
      {
        _id: '60cc230c47f7e10015fd6d3c',
        courseCode: 'HISTORY 2391',
        shortName: 'History 2391E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2391E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230c47f7e10015fd6d3c',
      },
      {
        _id: '60cc230d47f7e10015fd6d3e',
        courseCode: 'HISTORY 2392',
        shortName: 'History 2392E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2392E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230d47f7e10015fd6d3e',
      },
      {
        _id: '60cc230d47f7e10015fd6d40',
        courseCode: 'HISTORY 2393',
        shortName: 'History 2393E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2393E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230d47f7e10015fd6d40',
      },
      {
        _id: '60cc230e47f7e10015fd6d42',
        courseCode: 'HISTORY 2394',
        shortName: 'History 2394E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2394E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230e47f7e10015fd6d42',
      },
      {
        _id: '60cc230e47f7e10015fd6d44',
        courseCode: 'HISTORY 2395',
        shortName: 'History 2395E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2395E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230e47f7e10015fd6d44',
      },
      {
        _id: '60cc230f47f7e10015fd6d46',
        courseCode: 'HISTORY 2396',
        shortName: 'History 2396F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2396F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230f47f7e10015fd6d46',
      },
      {
        _id: '60cc230f47f7e10015fd6d48',
        courseCode: 'HISTORY 2397',
        shortName: 'History 2397F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2397F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc230f47f7e10015fd6d48',
      },
      {
        _id: '60cc231047f7e10015fd6d4a',
        courseCode: 'HISTORY 2398',
        shortName: 'History 2398F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2398F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231047f7e10015fd6d4a',
      },
      {
        _id: '60cc231047f7e10015fd6d4c',
        courseCode: 'HISTORY 2399',
        shortName: 'History 2399F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 2399F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231047f7e10015fd6d4c',
      },
      {
        _id: '60cc231047f7e10015fd6d4e',
        courseCode: 'HISTORY 2401',
        shortName: 'History 2401E - MEDIEVAL EUROPE',
        courseName: 'History 2401E - MEDIEVAL EUROPE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231047f7e10015fd6d4e',
      },
      {
        _id: '60cc231147f7e10015fd6d50',
        courseCode: 'HISTORY 2403',
        shortName:
          'History 2403E - EUROPE AND ENGLAND IN THE 16TH AND17TH CENTURIES',
        courseName:
          'History 2403E - EUROPE AND ENGLAND IN THE 16TH AND17TH CENTURIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231147f7e10015fd6d50',
      },
      {
        _id: '60cc231147f7e10015fd6d52',
        courseCode: 'HISTORY 2404',
        shortName:
          'History 2404E - EUROPE, 1789-1918: AN ERA OF REVOLUTIONARY CHANGE',
        courseName:
          'History 2404E - EUROPE, 1789-1918: AN ERA OF REVOLUTIONARY CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231147f7e10015fd6d52',
      },
      {
        _id: '60cc231247f7e10015fd6d54',
        courseCode: 'HISTORY 2408',
        shortName:
          'History 2408F/G - THE EUROPEAN WITCHCRAZE: CRIME AND DEVIANCE IN THE EARLY MODERN WORLD',
        courseName:
          'History 2408F/G - THE EUROPEAN WITCHCRAZE: CRIME AND DEVIANCE IN THE EARLY MODERN WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231247f7e10015fd6d54',
      },
      {
        _id: '60cc231247f7e10015fd6d56',
        courseCode: 'HISTORY 2413',
        shortName: 'History 2413E - EUROPE AND THE PARADOXES OF MODERNITY',
        courseName: 'History 2413E - EUROPE AND THE PARADOXES OF MODERNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231247f7e10015fd6d56',
      },
      {
        _id: '60cc231247f7e10015fd6d58',
        courseCode: 'HISTORY 2414',
        shortName: 'History 2414F/G - EVERY DAY LIFE IN 20TH CENTURY BRITAIN',
        courseName: 'History 2414F/G - EVERY DAY LIFE IN 20TH CENTURY BRITAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231247f7e10015fd6d58',
      },
      {
        _id: '60cc231247f7e10015fd6d5a',
        courseCode: 'HISTORY 2423',
        shortName: 'History 2423F/G - RUSSIA AND UKRAINE: PAST AND PRESENT',
        courseName: 'History 2423F/G - RUSSIA AND UKRAINE: PAST AND PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231247f7e10015fd6d5a',
      },
      {
        _id: '60cc231247f7e10015fd6d5c',
        courseCode: 'HISTORY 2427',
        shortName: 'History 2427F/G - HISTORY OF RUSSIA',
        courseName: 'History 2427F/G - HISTORY OF RUSSIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231247f7e10015fd6d5c',
      },
      {
        _id: '60cc231347f7e10015fd6d5e',
        courseCode: 'HISTORY 2459',
        shortName:
          'History 2459F/G - MIDWIVES, MADONNAS, WITCHES AND WHORES: WOMEN IN EARLY MODERN EUROPE, 1500-1700',
        courseName:
          'History 2459F/G - MIDWIVES, MADONNAS, WITCHES AND WHORES: WOMEN IN EARLY MODERN EUROPE, 1500-1700',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231347f7e10015fd6d5e',
      },
      {
        _id: '60cc231347f7e10015fd6d60',
        courseCode: 'HISTORY 2491',
        shortName: 'History 2491E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2491E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231347f7e10015fd6d60',
      },
      {
        _id: '60cc231447f7e10015fd6d62',
        courseCode: 'HISTORY 2492',
        shortName: 'History 2492E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2492E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231447f7e10015fd6d62',
      },
      {
        _id: '60cc231447f7e10015fd6d64',
        courseCode: 'HISTORY 2493',
        shortName: 'History 2493E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2493E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231447f7e10015fd6d64',
      },
      {
        _id: '60cc231547f7e10015fd6d66',
        courseCode: 'HISTORY 2494',
        shortName: 'History 2494E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2494E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231547f7e10015fd6d66',
      },
      {
        _id: '60cc231547f7e10015fd6d68',
        courseCode: 'HISTORY 2495',
        shortName: 'History 2495E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2495E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231547f7e10015fd6d68',
      },
      {
        _id: '60cc231547f7e10015fd6d6a',
        courseCode: 'HISTORY 2496',
        shortName: 'History 2496F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2496F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231547f7e10015fd6d6a',
      },
      {
        _id: '60cc231647f7e10015fd6d6c',
        courseCode: 'HISTORY 2497',
        shortName: 'History 2497F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2497F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231647f7e10015fd6d6c',
      },
      {
        _id: '60cc231747f7e10015fd6d6e',
        courseCode: 'HISTORY 2498',
        shortName: 'History 2498F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2498F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231747f7e10015fd6d6e',
      },
      {
        _id: '60cc231747f7e10015fd6d70',
        courseCode: 'HISTORY 2499',
        shortName: 'History 2499F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 2499F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231747f7e10015fd6d70',
      },
      {
        _id: '60cc231847f7e10015fd6d72',
        courseCode: 'HISTORY 2501',
        shortName: 'History 2501E - HISTORY OF LATIN AMERICA',
        courseName: 'History 2501E - HISTORY OF LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231847f7e10015fd6d72',
      },
      {
        _id: '60cc231847f7e10015fd6d74',
        courseCode: 'HISTORY 2591',
        shortName:
          'History 2591E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2591E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231847f7e10015fd6d74',
      },
      {
        _id: '60cc231847f7e10015fd6d76',
        courseCode: 'HISTORY 2592',
        shortName:
          'History 2592E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2592E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231847f7e10015fd6d76',
      },
      {
        _id: '60cc231947f7e10015fd6d78',
        courseCode: 'HISTORY 2593',
        shortName:
          'History 2593E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2593E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231947f7e10015fd6d78',
      },
      {
        _id: '60cc231947f7e10015fd6d7a',
        courseCode: 'HISTORY 2594',
        shortName:
          'History 2594E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2594E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231947f7e10015fd6d7a',
      },
      {
        _id: '60cc231947f7e10015fd6d7c',
        courseCode: 'HISTORY 2595',
        shortName:
          'History 2595E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2595E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231947f7e10015fd6d7c',
      },
      {
        _id: '60cc231a47f7e10015fd6d7e',
        courseCode: 'HISTORY 2596',
        shortName:
          'History 2596F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2596F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231a47f7e10015fd6d7e',
      },
      {
        _id: '60cc231a47f7e10015fd6d80',
        courseCode: 'HISTORY 2597',
        shortName:
          'History 2597F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2597F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231a47f7e10015fd6d80',
      },
      {
        _id: '60cc231b47f7e10015fd6d82',
        courseCode: 'HISTORY 2598',
        shortName:
          'History 2598F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2598F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231b47f7e10015fd6d82',
      },
      {
        _id: '60cc231b47f7e10015fd6d84',
        courseCode: 'HISTORY 2599',
        shortName:
          'History 2599F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 2599F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231b47f7e10015fd6d84',
      },
      {
        _id: '60cc231b47f7e10015fd6d86',
        courseCode: 'HISTORY 2601',
        shortName: 'History 2601E - HISTORY OF MODERN CHINA',
        courseName: 'History 2601E - HISTORY OF MODERN CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231b47f7e10015fd6d86',
      },
      {
        _id: '60cc231c47f7e10015fd6d88',
        courseCode: 'HISTORY 2602',
        shortName: 'History 2602F/G - PRE-COLONIAL AFRICA',
        courseName: 'History 2602F/G - PRE-COLONIAL AFRICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231c47f7e10015fd6d88',
      },
      {
        _id: '60cc231c47f7e10015fd6d8a',
        courseCode: 'HISTORY 2603',
        shortName: 'History 2603E - CHINA: TRADITION AND TRANSFORMATION',
        courseName: 'History 2603E - CHINA: TRADITION AND TRANSFORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231c47f7e10015fd6d8a',
      },
      {
        _id: '60cc231c47f7e10015fd6d8c',
        courseCode: 'HISTORY 2604',
        shortName: 'History 2604F/G - EUROPEAN IMPERIALISM IN AFRICA 1830-1994',
        courseName:
          'History 2604F/G - EUROPEAN IMPERIALISM IN AFRICA 1830-1994',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231c47f7e10015fd6d8c',
      },
      {
        _id: '60cc231c47f7e10015fd6d8e',
        courseCode: 'HISTORY 2605',
        shortName: 'History 2605E - SURVEY OF JAPANESE HISTORY',
        courseName: 'History 2605E - SURVEY OF JAPANESE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231c47f7e10015fd6d8e',
      },
      {
        _id: '60cc231d47f7e10015fd6d90',
        courseCode: 'HISTORY 2606',
        shortName: 'History 2606E - THE MAKING OF THE MODERN MIDDLE EAST',
        courseName: 'History 2606E - THE MAKING OF THE MODERN MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231d47f7e10015fd6d90',
      },
      {
        _id: '60cc231d47f7e10015fd6d92',
        courseCode: 'HISTORY 2607',
        shortName: 'History 2607F/G - HISTORY & CIVILIZATION OF MEDIEVAL ISLAM',
        courseName:
          'History 2607F/G - HISTORY & CIVILIZATION OF MEDIEVAL ISLAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231d47f7e10015fd6d92',
      },
      {
        _id: '60cc231d47f7e10015fd6d94',
        courseCode: 'HISTORY 2608',
        shortName: 'History 2608F/G - THE HISTORY OF THE MODERN MIDDLE EAST',
        courseName: 'History 2608F/G - THE HISTORY OF THE MODERN MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231d47f7e10015fd6d94',
      },
      {
        _id: '60cc231d47f7e10015fd6d96',
        courseCode: 'HISTORY 2610',
        shortName: 'History 2610F/G - WOMEN IN EAST ASIA',
        courseName: 'History 2610F/G - WOMEN IN EAST ASIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231d47f7e10015fd6d96',
      },
      {
        _id: '60cc231d47f7e10015fd6d98',
        courseCode: 'HISTORY 2611',
        shortName: 'History 2611E - SURVEY OF KOREAN HISTORY',
        courseName: 'History 2611E - SURVEY OF KOREAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231d47f7e10015fd6d98',
      },
      {
        _id: '60cc231e47f7e10015fd6d9a',
        courseCode: 'HISTORY 2615',
        shortName: "History 2615F/G - CHINESE WOMEN'S BIOGRAPHY",
        courseName: "History 2615F/G - CHINESE WOMEN'S BIOGRAPHY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231e47f7e10015fd6d9a',
      },
      {
        _id: '60cc231e47f7e10015fd6d9c',
        courseCode: 'HISTORY 2650',
        shortName:
          'History 2650E - PEPPERS, PIRATES AND PRIESTS: EAST ASIA IN WORLD HISTORY BEFORE 1800',
        courseName:
          'History 2650E - PEPPERS, PIRATES AND PRIESTS: EAST ASIA IN WORLD HISTORY BEFORE 1800',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231e47f7e10015fd6d9c',
      },
      {
        _id: '60cc231e47f7e10015fd6d9e',
        courseCode: 'HISTORY 2691',
        shortName:
          'History 2691E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2691E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231e47f7e10015fd6d9e',
      },
      {
        _id: '60cc231f47f7e10015fd6da0',
        courseCode: 'HISTORY 2692',
        shortName:
          'History 2692E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2692E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231f47f7e10015fd6da0',
      },
      {
        _id: '60cc231f47f7e10015fd6da2',
        courseCode: 'HISTORY 2693',
        shortName:
          'History 2693E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2693E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231f47f7e10015fd6da2',
      },
      {
        _id: '60cc231f47f7e10015fd6da4',
        courseCode: 'HISTORY 2694',
        shortName:
          'History 2694E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2694E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc231f47f7e10015fd6da4',
      },
      {
        _id: '60cc232047f7e10015fd6da6',
        courseCode: 'HISTORY 2695',
        shortName:
          'History 2695E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2695E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232047f7e10015fd6da6',
      },
      {
        _id: '60cc232047f7e10015fd6da8',
        courseCode: 'HISTORY 2696',
        shortName:
          'History 2696F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2696F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232047f7e10015fd6da8',
      },
      {
        _id: '60cc232147f7e10015fd6daa',
        courseCode: 'HISTORY 2697',
        shortName:
          'History 2697F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2697F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232147f7e10015fd6daa',
      },
      {
        _id: '60cc232147f7e10015fd6dac',
        courseCode: 'HISTORY 2698',
        shortName:
          'History 2698F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2698F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232147f7e10015fd6dac',
      },
      {
        _id: '60cc232147f7e10015fd6dae',
        courseCode: 'HISTORY 2699',
        shortName:
          'History 2699F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 2699F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232147f7e10015fd6dae',
      },
      {
        _id: '60cc232247f7e10015fd6db0',
        courseCode: 'HISTORY 2701',
        shortName: 'History 2701E - PATTERNS AND PERSPECTIVES IN WORLD HISTORY',
        courseName:
          'History 2701E - PATTERNS AND PERSPECTIVES IN WORLD HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232247f7e10015fd6db0',
      },
      {
        _id: '60cc232247f7e10015fd6db2',
        courseCode: 'HISTORY 2702',
        shortName: 'History 2702E - TEN DAYS THAT SHOOK THE WORLD',
        courseName: 'History 2702E - TEN DAYS THAT SHOOK THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232247f7e10015fd6db2',
      },
      {
        _id: '60cc232247f7e10015fd6db4',
        courseCode: 'HISTORY 2705',
        shortName:
          'History 2705E - THE WESTERN TRADITION IN INTERNATIONAL RELATIONS THEORY AND PRACTICE',
        courseName:
          'History 2705E - THE WESTERN TRADITION IN INTERNATIONAL RELATIONS THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232247f7e10015fd6db4',
      },
      {
        _id: '60cc232247f7e10015fd6db6',
        courseCode: 'HISTORY 2706',
        shortName: 'History 2706E - YOUTH CULTURES IN THE TWENTIETH CENTURY',
        courseName: 'History 2706E - YOUTH CULTURES IN THE TWENTIETH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232247f7e10015fd6db6',
      },
      {
        _id: '60cc232347f7e10015fd6db8',
        courseCode: 'HISTORY 2707',
        shortName:
          'History 2707F/G - CRIME AND PUNISHMENT IN NINETEENTH-CENTURY US, CANADA, AND UK',
        courseName:
          'History 2707F/G - CRIME AND PUNISHMENT IN NINETEENTH-CENTURY US, CANADA, AND UK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232347f7e10015fd6db8',
      },
      {
        _id: '60cc232347f7e10015fd6dba',
        courseCode: 'HISTORY 2708',
        shortName:
          'History 2708F/G - CRIME AND PUNISHMENT 20TH-CENTURY US, CANADA, AND UK',
        courseName:
          'History 2708F/G - CRIME AND PUNISHMENT 20TH-CENTURY US, CANADA, AND UK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232347f7e10015fd6dba',
      },
      {
        _id: '60cc232347f7e10015fd6dbc',
        courseCode: 'HISTORY 2709',
        shortName:
          'History 2709F/G - RACE, RIGHTS, AND REVOLUTION: THE ATLANTIC WORLD IN THE EIGHTEENTH CENTURY',
        courseName:
          'History 2709F/G - RACE, RIGHTS, AND REVOLUTION: THE ATLANTIC WORLD IN THE EIGHTEENTH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232347f7e10015fd6dbc',
      },
      {
        _id: '60cc232347f7e10015fd6dbe',
        courseCode: 'HISTORY 2710',
        shortName:
          'History 2710F/G - RED, WHITE, BLACK, ET BLANCS: THE AMERICAS TO 1867',
        courseName:
          'History 2710F/G - RED, WHITE, BLACK, ET BLANCS: THE AMERICAS TO 1867',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232347f7e10015fd6dbe',
      },
      {
        _id: '60cc232347f7e10015fd6dc0',
        courseCode: 'HISTORY 2712',
        shortName:
          'History 2712F/G - TYRANTS: HISTORICAL PROFILES IN OPPRESSION AND RESISTANCE',
        courseName:
          'History 2712F/G - TYRANTS: HISTORICAL PROFILES IN OPPRESSION AND RESISTANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232347f7e10015fd6dc0',
      },
      {
        _id: '60cc232447f7e10015fd6dc2',
        courseCode: 'HISTORY 2713',
        shortName:
          'History 2713F/G - REPUBLIC OF LETTERS: ENLIGHTENMENT IN ATLANTIC WORLD',
        courseName:
          'History 2713F/G - REPUBLIC OF LETTERS: ENLIGHTENMENT IN ATLANTIC WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232447f7e10015fd6dc2',
      },
      {
        _id: '60cc232447f7e10015fd6dc4',
        courseCode: 'HISTORY 2791',
        shortName:
          'History 2791E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2791E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232447f7e10015fd6dc4',
      },
      {
        _id: '60cc232547f7e10015fd6dc6',
        courseCode: 'HISTORY 2792',
        shortName:
          'History 2792E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2792E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232547f7e10015fd6dc6',
      },
      {
        _id: '60cc232547f7e10015fd6dc8',
        courseCode: 'HISTORY 2793',
        shortName:
          'History 2793E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2793E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232547f7e10015fd6dc8',
      },
      {
        _id: '60cc232647f7e10015fd6dca',
        courseCode: 'HISTORY 2794',
        shortName:
          'History 2794E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2794E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232647f7e10015fd6dca',
      },
      {
        _id: '60cc232647f7e10015fd6dcc',
        courseCode: 'HISTORY 2795',
        shortName:
          'History 2795E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2795E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232647f7e10015fd6dcc',
      },
      {
        _id: '60cc232647f7e10015fd6dce',
        courseCode: 'HISTORY 2796',
        shortName:
          'History 2796F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2796F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232647f7e10015fd6dce',
      },
      {
        _id: '60cc232747f7e10015fd6dd0',
        courseCode: 'HISTORY 2797',
        shortName:
          'History 2797F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2797F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232747f7e10015fd6dd0',
      },
      {
        _id: '60cc232747f7e10015fd6dd2',
        courseCode: 'HISTORY 2798',
        shortName:
          'History 2798F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2798F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232747f7e10015fd6dd2',
      },
      {
        _id: '60cc232847f7e10015fd6dd4',
        courseCode: 'HISTORY 2799',
        shortName:
          'History 2799F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 2799F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232847f7e10015fd6dd4',
      },
      {
        _id: '60cc232847f7e10015fd6dd6',
        courseCode: 'HISTORY 2800',
        shortName: 'History 2800F/G - THE PRACTICE AND PROFESSION OF HISTORY',
        courseName: 'History 2800F/G - THE PRACTICE AND PROFESSION OF HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232847f7e10015fd6dd6',
      },
      {
        _id: '60cc232947f7e10015fd6dd8',
        courseCode: 'HISTORY 2802',
        shortName:
          'History 2802E - WOMEN, MEN AND TECHNOLOGY: A HISTORICAL PERSPECTIVE',
        courseName:
          'History 2802E - WOMEN, MEN AND TECHNOLOGY: A HISTORICAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232947f7e10015fd6dd8',
      },
      {
        _id: '60cc232947f7e10015fd6dda',
        courseCode: 'HISTORY 2803',
        shortName: 'History 2803E - WOMEN IN HISTORY',
        courseName: 'History 2803E - WOMEN IN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232947f7e10015fd6dda',
      },
      {
        _id: '60cc232947f7e10015fd6ddc',
        courseCode: 'HISTORY 2804',
        shortName: "History 2804E - SPECIAL TOPICS IN WOMEN'S HISTORY",
        courseName: "History 2804E - SPECIAL TOPICS IN WOMEN'S HISTORY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232947f7e10015fd6ddc',
      },
      {
        _id: '60cc232947f7e10015fd6dde',
        courseCode: 'HISTORY 2806',
        shortName: "History 2806F/G - SPECIAL TOPICS IN WOMEN'S HISTORY",
        courseName: "History 2806F/G - SPECIAL TOPICS IN WOMEN'S HISTORY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232947f7e10015fd6dde',
      },
      {
        _id: '60cc232947f7e10015fd6de0',
        courseCode: 'HISTORY 2807',
        shortName:
          'History 2807F/G - ENTREPRENEURSHIP IN THE UNITED STATES AND CANADA SINCE 1800',
        courseName:
          'History 2807F/G - ENTREPRENEURSHIP IN THE UNITED STATES AND CANADA SINCE 1800',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232947f7e10015fd6de0',
      },
      {
        _id: '60cc232a47f7e10015fd6de2',
        courseCode: 'HISTORY 2808',
        shortName: 'History 2808F/G - THE PHILOSOPHY OF HISTORY',
        courseName: 'History 2808F/G - THE PHILOSOPHY OF HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232a47f7e10015fd6de2',
      },
      {
        _id: '60cc232a47f7e10015fd6de4',
        courseCode: 'HISTORY 2810',
        shortName: 'History 2810F/G - GENDER AND MODERN EUROPE, 1789-1989',
        courseName: 'History 2810F/G - GENDER AND MODERN EUROPE, 1789-1989',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232a47f7e10015fd6de4',
      },
      {
        _id: '60cc232a47f7e10015fd6de6',
        courseCode: 'HISTORY 2811',
        shortName: 'History 2811F/G - HISTORIANS, COMMUNITIES AND THE PAST',
        courseName: 'History 2811F/G - HISTORIANS, COMMUNITIES AND THE PAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232a47f7e10015fd6de6',
      },
      {
        _id: '60cc232a47f7e10015fd6de8',
        courseCode: 'HISTORY 2812',
        shortName:
          'History 2812E - PLAGUE, POX AND FLU: DISEASE IN GLOBAL HISTORY',
        courseName:
          'History 2812E - PLAGUE, POX AND FLU: DISEASE IN GLOBAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232a47f7e10015fd6de8',
      },
      {
        _id: '60cc232b47f7e10015fd6dea',
        courseCode: 'HISTORY 2813',
        shortName:
          "History 2813F/G - MAKING WAVES: WOMEN'S ACTIVISM IN THE ATLANTIC WORLD",
        courseName:
          "History 2813F/G - MAKING WAVES: WOMEN'S ACTIVISM IN THE ATLANTIC WORLD",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232b47f7e10015fd6dea',
      },
      {
        _id: '60cc232b47f7e10015fd6dec',
        courseCode: 'HISTORY 2814',
        shortName: 'History 2814F/G - THE HISTORY OF AVIATION',
        courseName: 'History 2814F/G - THE HISTORY OF AVIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232b47f7e10015fd6dec',
      },
      {
        _id: '60cc232b47f7e10015fd6dee',
        courseCode: 'HISTORY 2817',
        shortName: 'History 2817F/G - QUEER NORTH AMERICAN HISTORIES',
        courseName: 'History 2817F/G - QUEER NORTH AMERICAN HISTORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232b47f7e10015fd6dee',
      },
      {
        _id: '60cc232b47f7e10015fd6df0',
        courseCode: 'HISTORY 2818',
        shortName:
          'History 2818F/G - PLAGUE AND DEATH FROM ANTIQUITY TO THE 18TH CENTURY',
        courseName:
          'History 2818F/G - PLAGUE AND DEATH FROM ANTIQUITY TO THE 18TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232b47f7e10015fd6df0',
      },
      {
        _id: '60cc232b47f7e10015fd6df2',
        courseCode: 'HISTORY 2819',
        shortName: 'History 2819F/G - HOW EPIDEMICS CHANGE THE WAY WE LIVE',
        courseName: 'History 2819F/G - HOW EPIDEMICS CHANGE THE WAY WE LIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232b47f7e10015fd6df2',
      },
      {
        _id: '60cc232c47f7e10015fd6df4',
        courseCode: 'HISTORY 2821',
        shortName:
          'History 2821F/G - JEWISH HISTORY FROM THE ORIGINS OF THE ISRAELITES TO 1492 CE',
        courseName:
          'History 2821F/G - JEWISH HISTORY FROM THE ORIGINS OF THE ISRAELITES TO 1492 CE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232c47f7e10015fd6df4',
      },
      {
        _id: '60cc232c47f7e10015fd6df6',
        courseCode: 'HISTORY 2822',
        shortName:
          'History 2822F/G - JEWISH HISTORY FROM THE SPANISH EXPULSION TO THE MODERN ERA',
        courseName:
          'History 2822F/G - JEWISH HISTORY FROM THE SPANISH EXPULSION TO THE MODERN ERA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232c47f7e10015fd6df6',
      },
      {
        _id: '60cc232c47f7e10015fd6df8',
        courseCode: 'HISTORY 2830',
        shortName:
          'History 2830F/G - NATURE AND CULTURE IN NORTH AMERICAN LITERATURE',
        courseName:
          'History 2830F/G - NATURE AND CULTURE IN NORTH AMERICAN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232c47f7e10015fd6df8',
      },
      {
        _id: '60cc232c47f7e10015fd6dfa',
        courseCode: 'HISTORY 2886',
        shortName:
          'History 2886F/G - FAKES, FRAUDS AND FAIRS: THE HISTORY OF MUSEUMS',
        courseName:
          'History 2886F/G - FAKES, FRAUDS AND FAIRS: THE HISTORY OF MUSEUMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232c47f7e10015fd6dfa',
      },
      {
        _id: '60cc232d47f7e10015fd6dfc',
        courseCode: 'HISTORY 2888',
        shortName: 'History 2888F/G - A HISTORY OF ANIMALS',
        courseName: 'History 2888F/G - A HISTORY OF ANIMALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232d47f7e10015fd6dfc',
      },
      {
        _id: '60cc232d47f7e10015fd6dfe',
        courseCode: 'HISTORY 2890',
        shortName: 'History 2890F/G - BIOGRAPHY',
        courseName: 'History 2890F/G - BIOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232d47f7e10015fd6dfe',
      },
      {
        _id: '60cc232d47f7e10015fd6e00',
        courseCode: 'HISTORY 2891',
        shortName:
          'History 2891E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2891E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232d47f7e10015fd6e00',
      },
      {
        _id: '60cc232e47f7e10015fd6e02',
        courseCode: 'HISTORY 2892',
        shortName:
          'History 2892E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2892E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232e47f7e10015fd6e02',
      },
      {
        _id: '60cc232e47f7e10015fd6e04',
        courseCode: 'HISTORY 2893',
        shortName:
          'History 2893E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2893E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232e47f7e10015fd6e04',
      },
      {
        _id: '60cc232f47f7e10015fd6e06',
        courseCode: 'HISTORY 2894',
        shortName:
          'History 2894E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2894E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232f47f7e10015fd6e06',
      },
      {
        _id: '60cc232f47f7e10015fd6e08',
        courseCode: 'HISTORY 2895',
        shortName:
          'History 2895E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2895E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232f47f7e10015fd6e08',
      },
      {
        _id: '60cc232f47f7e10015fd6e0a',
        courseCode: 'HISTORY 2896',
        shortName:
          'History 2896F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2896F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc232f47f7e10015fd6e0a',
      },
      {
        _id: '60cc233047f7e10015fd6e0c',
        courseCode: 'HISTORY 2897',
        shortName:
          'History 2897F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2897F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233047f7e10015fd6e0c',
      },
      {
        _id: '60cc233147f7e10015fd6e0e',
        courseCode: 'HISTORY 2898',
        shortName:
          'History 2898F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2898F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233147f7e10015fd6e0e',
      },
      {
        _id: '60cc233147f7e10015fd6e10',
        courseCode: 'HISTORY 2899',
        shortName:
          'History 2899F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 2899F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233147f7e10015fd6e10',
      },
      {
        _id: '60cc233247f7e10015fd6e12',
        courseCode: 'HISTORY 3201',
        shortName: 'History 3201E - FIRST PEOPLES AND COLONIALISM IN CANADA',
        courseName: 'History 3201E - FIRST PEOPLES AND COLONIALISM IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233247f7e10015fd6e12',
      },
      {
        _id: '60cc233247f7e10015fd6e14',
        courseCode: 'HISTORY 3204',
        shortName: 'History 3204F/G - FRENCH CANADA',
        courseName: 'History 3204F/G - FRENCH CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233247f7e10015fd6e14',
      },
      {
        _id: '60cc233247f7e10015fd6e16',
        courseCode: 'HISTORY 3205',
        shortName:
          'History 3205E - IDENTITIES IN CONFLICT: CANADIAN SOCIAL HISTORY SINCE 1800',
        courseName:
          'History 3205E - IDENTITIES IN CONFLICT: CANADIAN SOCIAL HISTORY SINCE 1800',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233247f7e10015fd6e16',
      },
      {
        _id: '60cc233247f7e10015fd6e18',
        courseCode: 'HISTORY 3206',
        shortName:
          'History 3206F/G - IDENTITIES IN CONFLICT: CANADIAN SOCIAL HISTORY SINCE 1800',
        courseName:
          'History 3206F/G - IDENTITIES IN CONFLICT: CANADIAN SOCIAL HISTORY SINCE 1800',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233247f7e10015fd6e18',
      },
      {
        _id: '60cc233347f7e10015fd6e1a',
        courseCode: 'HISTORY 3207',
        shortName: 'History 3207E - THE HISTORY OF CANADIAN CULTURE',
        courseName: 'History 3207E - THE HISTORY OF CANADIAN CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233347f7e10015fd6e1a',
      },
      {
        _id: '60cc233347f7e10015fd6e1c',
        courseCode: 'HISTORY 3208',
        shortName: 'History 3208F/G - LIFE, LOVE & DEATH IN EARLY CANADA',
        courseName: 'History 3208F/G - LIFE, LOVE & DEATH IN EARLY CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233347f7e10015fd6e1c',
      },
      {
        _id: '60cc233347f7e10015fd6e1e',
        courseCode: 'HISTORY 3209',
        shortName: 'History 3209E - TOPICS IN THE HISTORY OF WOMEN IN CANADA',
        courseName: 'History 3209E - TOPICS IN THE HISTORY OF WOMEN IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233347f7e10015fd6e1e',
      },
      {
        _id: '60cc233347f7e10015fd6e20',
        courseCode: 'HISTORY 3217',
        shortName: 'History 3217E - ENVIRONMENTAL HISTORY OF CANADA',
        courseName: 'History 3217E - ENVIRONMENTAL HISTORY OF CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233347f7e10015fd6e20',
      },
      {
        _id: '60cc233447f7e10015fd6e22',
        courseCode: 'HISTORY 3218',
        shortName:
          'History 3218E - MOVERS AND SHAKERS: SOCIAL MOVEMENTS IN CANADIAN HISTORY',
        courseName:
          'History 3218E - MOVERS AND SHAKERS: SOCIAL MOVEMENTS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233447f7e10015fd6e22',
      },
      {
        _id: '60cc233447f7e10015fd6e24',
        courseCode: 'HISTORY 3219',
        shortName: 'History 3219E - THE HISTORY OF PUBLIC VIOLENCE',
        courseName: 'History 3219E - THE HISTORY OF PUBLIC VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233447f7e10015fd6e24',
      },
      {
        _id: '60cc233447f7e10015fd6e26',
        courseCode: 'HISTORY 3221',
        shortName: 'History 3221E - TOPICS IN ONTARIO HISTORY',
        courseName: 'History 3221E - TOPICS IN ONTARIO HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233447f7e10015fd6e26',
      },
      {
        _id: '60cc233447f7e10015fd6e28',
        courseCode: 'HISTORY 3226',
        shortName: 'History 3226F/G - CANADIAN POLITICAL LEADERSHIP',
        courseName: 'History 3226F/G - CANADIAN POLITICAL LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233447f7e10015fd6e28',
      },
      {
        _id: '60cc233547f7e10015fd6e2a',
        courseCode: 'HISTORY 3227',
        shortName: 'History 3227F/G - POLITICAL PROTEST IN CANADIAN HISTORY',
        courseName: 'History 3227F/G - POLITICAL PROTEST IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233547f7e10015fd6e2a',
      },
      {
        _id: '60cc233547f7e10015fd6e2c',
        courseCode: 'HISTORY 3231',
        shortName: 'History 3231F/G - YOURS TO DISCOVER: A HISTORY OF ONTARIO',
        courseName: 'History 3231F/G - YOURS TO DISCOVER: A HISTORY OF ONTARIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233547f7e10015fd6e2c',
      },
      {
        _id: '60cc233547f7e10015fd6e2e',
        courseCode: 'HISTORY 3261',
        shortName: 'History 3261F/G - CANADIAN CHILDHOOD AND YOUTH',
        courseName: 'History 3261F/G - CANADIAN CHILDHOOD AND YOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233547f7e10015fd6e2e',
      },
      {
        _id: '60cc233547f7e10015fd6e30',
        courseCode: 'HISTORY 3285',
        shortName:
          'History 3285E - CANADA IN THE WORLD - STUDIES IN FOREIGN POLICY',
        courseName:
          'History 3285E - CANADA IN THE WORLD - STUDIES IN FOREIGN POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233547f7e10015fd6e30',
      },
      {
        _id: '60cc233647f7e10015fd6e32',
        courseCode: 'HISTORY 3291',
        shortName: 'History 3291E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3291E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233647f7e10015fd6e32',
      },
      {
        _id: '60cc233647f7e10015fd6e34',
        courseCode: 'HISTORY 3293',
        shortName: 'History 3293E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3293E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233647f7e10015fd6e34',
      },
      {
        _id: '60cc233747f7e10015fd6e36',
        courseCode: 'HISTORY 3294',
        shortName: 'History 3294E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3294E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233747f7e10015fd6e36',
      },
      {
        _id: '60cc233747f7e10015fd6e38',
        courseCode: 'HISTORY 3295',
        shortName: 'History 3295E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3295E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233747f7e10015fd6e38',
      },
      {
        _id: '60cc233847f7e10015fd6e3a',
        courseCode: 'HISTORY 3296',
        shortName: 'History 3296F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3296F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233847f7e10015fd6e3a',
      },
      {
        _id: '60cc233847f7e10015fd6e3c',
        courseCode: 'HISTORY 3297',
        shortName: 'History 3297F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3297F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233847f7e10015fd6e3c',
      },
      {
        _id: '60cc233947f7e10015fd6e3e',
        courseCode: 'HISTORY 3298',
        shortName: 'History 3298F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3298F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233947f7e10015fd6e3e',
      },
      {
        _id: '60cc233947f7e10015fd6e40',
        courseCode: 'HISTORY 3299',
        shortName: 'History 3299F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 3299F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233947f7e10015fd6e40',
      },
      {
        _id: '60cc233a47f7e10015fd6e42',
        courseCode: 'HISTORY 3301',
        shortName: 'History 3301E - COLONIAL BRITISH AMERICA',
        courseName: 'History 3301E - COLONIAL BRITISH AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233a47f7e10015fd6e42',
      },
      {
        _id: '60cc233a47f7e10015fd6e44',
        courseCode: 'HISTORY 3305',
        shortName: 'History 3305E - THE UNITED STATES, 1783-1916',
        courseName: 'History 3305E - THE UNITED STATES, 1783-1916',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233a47f7e10015fd6e44',
      },
      {
        _id: '60cc233a47f7e10015fd6e46',
        courseCode: 'HISTORY 3306',
        shortName:
          'History 3306F/G - THE UNITED STATES IN THE NINETEENTH CENTURY',
        courseName:
          'History 3306F/G - THE UNITED STATES IN THE NINETEENTH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233a47f7e10015fd6e46',
      },
      {
        _id: '60cc233b47f7e10015fd6e48',
        courseCode: 'HISTORY 3307',
        shortName: 'History 3307E - AMERICAN POPULAR CULTURE',
        courseName: 'History 3307E - AMERICAN POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233b47f7e10015fd6e48',
      },
      {
        _id: '60cc233b47f7e10015fd6e4a',
        courseCode: 'HISTORY 3308',
        shortName:
          'History 3308E - CONSPIRACIES, DENIERS AND TRUTHERS: CONSPIRACY THEORIES IN AMERICAN HISTORY',
        courseName:
          'History 3308E - CONSPIRACIES, DENIERS AND TRUTHERS: CONSPIRACY THEORIES IN AMERICAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233b47f7e10015fd6e4a',
      },
      {
        _id: '60cc233b47f7e10015fd6e4c',
        courseCode: 'HISTORY 3309',
        shortName: 'History 3309E - THE AMERICAN SOUTH',
        courseName: 'History 3309E - THE AMERICAN SOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233b47f7e10015fd6e4c',
      },
      {
        _id: '60cc233b47f7e10015fd6e4e',
        courseCode: 'HISTORY 3310',
        shortName:
          'History 3310F/G - ADVANCED AMERICAN STUDIES: BEING AMERICAN',
        courseName:
          'History 3310F/G - ADVANCED AMERICAN STUDIES: BEING AMERICAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233b47f7e10015fd6e4e',
      },
      {
        _id: '60cc233c47f7e10015fd6e50',
        courseCode: 'HISTORY 3311',
        shortName:
          'History 3311F/G - SLAVERY AND FREEDOM: AFRICAN-AMERICAN HISTORY, 1600-1896',
        courseName:
          'History 3311F/G - SLAVERY AND FREEDOM: AFRICAN-AMERICAN HISTORY, 1600-1896',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233c47f7e10015fd6e50',
      },
      {
        _id: '60cc233c47f7e10015fd6e52',
        courseCode: 'HISTORY 3313',
        shortName:
          'History 3313F/G - THE MOVEMENT: CIVIL RIGHTS AND AFRICAN-AMERICAN HISTORY IN THE 20TH CENTURY',
        courseName:
          'History 3313F/G - THE MOVEMENT: CIVIL RIGHTS AND AFRICAN-AMERICAN HISTORY IN THE 20TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233c47f7e10015fd6e52',
      },
      {
        _id: '60cc233c47f7e10015fd6e54',
        courseCode: 'HISTORY 3314',
        shortName:
          'History 3314F/G - CALIFORNIA CALLING: HISTORIES OF THE “GOLDEN STATE” IN TEXT, SOUND AND IMAGE',
        courseName:
          'History 3314F/G - CALIFORNIA CALLING: HISTORIES OF THE “GOLDEN STATE” IN TEXT, SOUND AND IMAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233c47f7e10015fd6e54',
      },
      {
        _id: '60cc233c47f7e10015fd6e56',
        courseCode: 'HISTORY 3319',
        shortName:
          'History 3319E - FOREIGN POLICY OF THE UNITED STATES SINCE 1775',
        courseName:
          'History 3319E - FOREIGN POLICY OF THE UNITED STATES SINCE 1775',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233c47f7e10015fd6e56',
      },
      {
        _id: '60cc233d47f7e10015fd6e58',
        courseCode: 'HISTORY 3320',
        shortName:
          'History 3320E - GLOBAL AMERICA: THE UNITED STATES IN WORLD AFFAIRS, 1700 TO THE PRESENT',
        courseName:
          'History 3320E - GLOBAL AMERICA: THE UNITED STATES IN WORLD AFFAIRS, 1700 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233d47f7e10015fd6e58',
      },
      {
        _id: '60cc233d47f7e10015fd6e5a',
        courseCode: 'HISTORY 3326',
        shortName:
          'History 3326F/G - ATOMIC AMERICA:THE UNITED STATES DURING THE 1950S',
        courseName:
          'History 3326F/G - ATOMIC AMERICA:THE UNITED STATES DURING THE 1950S',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233d47f7e10015fd6e5a',
      },
      {
        _id: '60cc233d47f7e10015fd6e5c',
        courseCode: 'HISTORY 3327',
        shortName:
          'History 3327F/G - THE REBELLIOUS DECADE: CULTURE AND CONFLICT IN THE U.S. IN THE 1960S',
        courseName:
          'History 3327F/G - THE REBELLIOUS DECADE: CULTURE AND CONFLICT IN THE U.S. IN THE 1960S',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233d47f7e10015fd6e5c',
      },
      {
        _id: '60cc233d47f7e10015fd6e5e',
        courseCode: 'HISTORY 3391',
        shortName: 'History 3391E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3391E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233d47f7e10015fd6e5e',
      },
      {
        _id: '60cc233e47f7e10015fd6e60',
        courseCode: 'HISTORY 3392',
        shortName: 'History 3392E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3392E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233e47f7e10015fd6e60',
      },
      {
        _id: '60cc233e47f7e10015fd6e62',
        courseCode: 'HISTORY 3393',
        shortName: 'History 3393E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3393E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233e47f7e10015fd6e62',
      },
      {
        _id: '60cc233f47f7e10015fd6e64',
        courseCode: 'HISTORY 3394',
        shortName: 'History 3394E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3394E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233f47f7e10015fd6e64',
      },
      {
        _id: '60cc233f47f7e10015fd6e66',
        courseCode: 'HISTORY 3395',
        shortName: 'History 3395E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3395E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc233f47f7e10015fd6e66',
      },
      {
        _id: '60cc234047f7e10015fd6e68',
        courseCode: 'HISTORY 3396',
        shortName: 'History 3396F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3396F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234047f7e10015fd6e68',
      },
      {
        _id: '60cc234047f7e10015fd6e6a',
        courseCode: 'HISTORY 3397',
        shortName: 'History 3397F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3397F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234047f7e10015fd6e6a',
      },
      {
        _id: '60cc234147f7e10015fd6e6c',
        courseCode: 'HISTORY 3398',
        shortName: 'History 3398F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3398F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234147f7e10015fd6e6c',
      },
      {
        _id: '60cc234147f7e10015fd6e6e',
        courseCode: 'HISTORY 3399',
        shortName: 'History 3399F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 3399F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234147f7e10015fd6e6e',
      },
      {
        _id: '60cc234247f7e10015fd6e70',
        courseCode: 'HISTORY 3401',
        shortName: 'History 3401E - THE EUROPEAN RENAISSANCE',
        courseName: 'History 3401E - THE EUROPEAN RENAISSANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234247f7e10015fd6e70',
      },
      {
        _id: '60cc234247f7e10015fd6e72',
        courseCode: 'HISTORY 3402',
        shortName:
          'History 3402F/G - HOMER TO ST. AUGUSTINE: CLASSIC TEXTS AND DEBATES IN WESTERN CULTURE (I)',
        courseName:
          'History 3402F/G - HOMER TO ST. AUGUSTINE: CLASSIC TEXTS AND DEBATES IN WESTERN CULTURE (I)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234247f7e10015fd6e72',
      },
      {
        _id: '60cc234247f7e10015fd6e74',
        courseCode: 'HISTORY 3404',
        shortName:
          'History 3404F/G - MONTESQUIEU TO MILL: CLASSIC TEXTS AND DEBATES IN WESTERN CULTURE (II)',
        courseName:
          'History 3404F/G - MONTESQUIEU TO MILL: CLASSIC TEXTS AND DEBATES IN WESTERN CULTURE (II)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234247f7e10015fd6e74',
      },
      {
        _id: '60cc234247f7e10015fd6e76',
        courseCode: 'HISTORY 3406',
        shortName:
          'History 3406F/G - COSH BOYS & KLEPTOS: CRIME AND GENDER IN MODERN BRITAIN',
        courseName:
          'History 3406F/G - COSH BOYS & KLEPTOS: CRIME AND GENDER IN MODERN BRITAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234247f7e10015fd6e76',
      },
      {
        _id: '60cc234247f7e10015fd6e78',
        courseCode: 'HISTORY 3409',
        shortName: 'History 3409E - EUROPE AND THE SEA, 1400-1945',
        courseName: 'History 3409E - EUROPE AND THE SEA, 1400-1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234247f7e10015fd6e78',
      },
      {
        _id: '60cc234347f7e10015fd6e7a',
        courseCode: 'HISTORY 3410',
        shortName:
          'History 3410F/G - THE BRITISH EMPIRE: SEX, BOOKS AND VIOLENCE',
        courseName:
          'History 3410F/G - THE BRITISH EMPIRE: SEX, BOOKS AND VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234347f7e10015fd6e7a',
      },
      {
        _id: '60cc234347f7e10015fd6e7c',
        courseCode: 'HISTORY 3411',
        shortName:
          "History 3411E - THE BRITISH ISLES FROM 1688: GLORIOUS REVOLUTION TO 'BROKEN BRITAIN'",
        courseName:
          "History 3411E - THE BRITISH ISLES FROM 1688: GLORIOUS REVOLUTION TO 'BROKEN BRITAIN'",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234347f7e10015fd6e7c',
      },
      {
        _id: '60cc234447f7e10015fd6e7e',
        courseCode: 'HISTORY 3412',
        shortName:
          "History 3412F/G - BRITAIN'S SAILORS, SOLDIERS AND EMPIRE: 1689 TO 1902",
        courseName:
          "History 3412F/G - BRITAIN'S SAILORS, SOLDIERS AND EMPIRE: 1689 TO 1902",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234447f7e10015fd6e7e',
      },
      {
        _id: '60cc234447f7e10015fd6e80',
        courseCode: 'HISTORY 3414',
        shortName:
          'History 3414F/G - THE VICTORIAN WORLDVIEW: NINETEENTH-CENTURY BRITAIN',
        courseName:
          'History 3414F/G - THE VICTORIAN WORLDVIEW: NINETEENTH-CENTURY BRITAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234447f7e10015fd6e80',
      },
      {
        _id: '60cc234447f7e10015fd6e82',
        courseCode: 'HISTORY 3415',
        shortName: 'History 3415E - MODERN GERMANY, 1815 TO THE PRESENT',
        courseName: 'History 3415E - MODERN GERMANY, 1815 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234447f7e10015fd6e82',
      },
      {
        _id: '60cc234447f7e10015fd6e84',
        courseCode: 'HISTORY 3416',
        shortName: 'History 3416F/G - THE HOLOCAUST',
        courseName: 'History 3416F/G - THE HOLOCAUST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234447f7e10015fd6e84',
      },
      {
        _id: '60cc234447f7e10015fd6e86',
        courseCode: 'HISTORY 3418',
        shortName:
          'History 3418F/G - REPRESENTATIONS OF THE PAST IN EASTERN EUROPE AND RUSSIA',
        courseName:
          'History 3418F/G - REPRESENTATIONS OF THE PAST IN EASTERN EUROPE AND RUSSIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234447f7e10015fd6e86',
      },
      {
        _id: '60cc234647f7e10015fd6e88',
        courseCode: 'HISTORY 3420',
        shortName: 'History 3420F/G - THE SOVIET EXPERIMENT',
        courseName: 'History 3420F/G - THE SOVIET EXPERIMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234647f7e10015fd6e88',
      },
      {
        _id: '60cc234647f7e10015fd6e8a',
        courseCode: 'HISTORY 3424',
        shortName: 'History 3424E - QUEEN VICTORIA AND THE VICTORIANS',
        courseName: 'History 3424E - QUEEN VICTORIA AND THE VICTORIANS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234647f7e10015fd6e8a',
      },
      {
        _id: '60cc234647f7e10015fd6e8c',
        courseCode: 'HISTORY 3428',
        shortName: 'History 3428F/G - MODERN GERMANY, 1871 TO THE PRESENT',
        courseName: 'History 3428F/G - MODERN GERMANY, 1871 TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234647f7e10015fd6e8c',
      },
      {
        _id: '60cc234647f7e10015fd6e8e',
        courseCode: 'HISTORY 3432',
        shortName: 'History 3432F/G - VICTORIAN BRITAIN',
        courseName: 'History 3432F/G - VICTORIAN BRITAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234647f7e10015fd6e8e',
      },
      {
        _id: '60cc234747f7e10015fd6e90',
        courseCode: 'HISTORY 3440',
        shortName: 'History 3440E - THE VIRGIN QUEEN',
        courseName: 'History 3440E - THE VIRGIN QUEEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234747f7e10015fd6e90',
      },
      {
        _id: '60cc234747f7e10015fd6e92',
        courseCode: 'HISTORY 3491',
        shortName: 'History 3491E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3491E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234747f7e10015fd6e92',
      },
      {
        _id: '60cc234847f7e10015fd6e94',
        courseCode: 'HISTORY 3492',
        shortName: 'History 3492E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3492E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234847f7e10015fd6e94',
      },
      {
        _id: '60cc234847f7e10015fd6e96',
        courseCode: 'HISTORY 3493',
        shortName: 'History 3493E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3493E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234847f7e10015fd6e96',
      },
      {
        _id: '60cc234847f7e10015fd6e98',
        courseCode: 'HISTORY 3494',
        shortName: 'History 3494E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3494E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234847f7e10015fd6e98',
      },
      {
        _id: '60cc234947f7e10015fd6e9a',
        courseCode: 'HISTORY 3495',
        shortName: 'History 3495E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3495E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234947f7e10015fd6e9a',
      },
      {
        _id: '60cc234947f7e10015fd6e9c',
        courseCode: 'HISTORY 3496',
        shortName: 'History 3496F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3496F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234947f7e10015fd6e9c',
      },
      {
        _id: '60cc234a47f7e10015fd6e9e',
        courseCode: 'HISTORY 3497',
        shortName: 'History 3497F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3497F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234a47f7e10015fd6e9e',
      },
      {
        _id: '60cc234a47f7e10015fd6ea0',
        courseCode: 'HISTORY 3498',
        shortName: 'History 3498F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3498F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234a47f7e10015fd6ea0',
      },
      {
        _id: '60cc234b47f7e10015fd6ea2',
        courseCode: 'HISTORY 3499',
        shortName: 'History 3499F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 3499F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234b47f7e10015fd6ea2',
      },
      {
        _id: '60cc234b47f7e10015fd6ea4',
        courseCode: 'HISTORY 3505',
        shortName: 'History 3505F/G - POPULAR CULTURE IN LATIN AMERICA',
        courseName: 'History 3505F/G - POPULAR CULTURE IN LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234b47f7e10015fd6ea4',
      },
      {
        _id: '60cc234c47f7e10015fd6ea6',
        courseCode: 'HISTORY 3506',
        shortName:
          'History 3506F/G - THE TURBULENT HISTORY OF MODERN LATIN AMERICA',
        courseName:
          'History 3506F/G - THE TURBULENT HISTORY OF MODERN LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234c47f7e10015fd6ea6',
      },
      {
        _id: '60cc234c47f7e10015fd6ea8',
        courseCode: 'HISTORY 3507',
        shortName: 'History 3507F/G - RELIGION IN LATIN AMERICA',
        courseName: 'History 3507F/G - RELIGION IN LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234c47f7e10015fd6ea8',
      },
      {
        _id: '60cc234c47f7e10015fd6eaa',
        courseCode: 'HISTORY 3591',
        shortName:
          'History 3591E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3591E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234c47f7e10015fd6eaa',
      },
      {
        _id: '60cc234c47f7e10015fd6eac',
        courseCode: 'HISTORY 3592',
        shortName:
          'History 3592E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3592E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234c47f7e10015fd6eac',
      },
      {
        _id: '60cc234d47f7e10015fd6eae',
        courseCode: 'HISTORY 3593',
        shortName:
          'History 3593E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3593E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234d47f7e10015fd6eae',
      },
      {
        _id: '60cc234d47f7e10015fd6eb0',
        courseCode: 'HISTORY 3594',
        shortName:
          'History 3594E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3594E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234d47f7e10015fd6eb0',
      },
      {
        _id: '60cc234e47f7e10015fd6eb2',
        courseCode: 'HISTORY 3595',
        shortName:
          'History 3595E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3595E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234e47f7e10015fd6eb2',
      },
      {
        _id: '60cc234e47f7e10015fd6eb4',
        courseCode: 'HISTORY 3596',
        shortName:
          'History 3596F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3596F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234e47f7e10015fd6eb4',
      },
      {
        _id: '60cc234f47f7e10015fd6eb6',
        courseCode: 'HISTORY 3597',
        shortName:
          'History 3597F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3597F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234f47f7e10015fd6eb6',
      },
      {
        _id: '60cc234f47f7e10015fd6eb8',
        courseCode: 'HISTORY 3598',
        shortName:
          'History 3598F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3598F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234f47f7e10015fd6eb8',
      },
      {
        _id: '60cc234f47f7e10015fd6eba',
        courseCode: 'HISTORY 3599',
        shortName:
          'History 3599F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 3599F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc234f47f7e10015fd6eba',
      },
      {
        _id: '60cc235047f7e10015fd6ebc',
        courseCode: 'HISTORY 3601',
        shortName: 'History 3601E - CHINESE NATIONALISM IN HISTORY',
        courseName: 'History 3601E - CHINESE NATIONALISM IN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235047f7e10015fd6ebc',
      },
      {
        _id: '60cc235047f7e10015fd6ebe',
        courseCode: 'HISTORY 3602',
        shortName: 'History 3602F/G - CRUSADERS AND MUSLIMS IN THE HOLY LAND',
        courseName: 'History 3602F/G - CRUSADERS AND MUSLIMS IN THE HOLY LAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235047f7e10015fd6ebe',
      },
      {
        _id: '60cc235047f7e10015fd6ec0',
        courseCode: 'HISTORY 3604',
        shortName:
          'History 3604F/G - TOKUGAWA JAPAN: SAMURAI, PEASANTS, TOWNSFOLK, AND OUTSIDERS',
        courseName:
          'History 3604F/G - TOKUGAWA JAPAN: SAMURAI, PEASANTS, TOWNSFOLK, AND OUTSIDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235047f7e10015fd6ec0',
      },
      {
        _id: '60cc235047f7e10015fd6ec2',
        courseCode: 'HISTORY 3605',
        shortName:
          'History 3605E - CRUSADERS AND MOSLEMS IN THE TWELFTH CENTURY',
        courseName:
          'History 3605E - CRUSADERS AND MOSLEMS IN THE TWELFTH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235047f7e10015fd6ec2',
      },
      {
        _id: '60cc235147f7e10015fd6ec4',
        courseCode: 'HISTORY 3606',
        shortName:
          'History 3606F/G - JAPAN AND ITS EMPIRE, 1868-1945: A RISING AND SETTING SUN',
        courseName:
          'History 3606F/G - JAPAN AND ITS EMPIRE, 1868-1945: A RISING AND SETTING SUN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235147f7e10015fd6ec4',
      },
      {
        _id: '60cc235147f7e10015fd6ec6',
        courseCode: 'HISTORY 3609',
        shortName: 'History 3609F/G - JAPAN SINCE 1945',
        courseName: 'History 3609F/G - JAPAN SINCE 1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235147f7e10015fd6ec6',
      },
      {
        _id: '60cc235147f7e10015fd6ec8',
        courseCode: 'HISTORY 3613',
        shortName: 'History 3613F/G - THE KOREAS SINCE 1945',
        courseName: 'History 3613F/G - THE KOREAS SINCE 1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235147f7e10015fd6ec8',
      },
      {
        _id: '60cc235147f7e10015fd6eca',
        courseCode: 'HISTORY 3615',
        shortName: 'History 3615F/G - CRIME ANDPUNISHMENT IN IMPERIAL CHINA',
        courseName: 'History 3615F/G - CRIME ANDPUNISHMENT IN IMPERIAL CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235147f7e10015fd6eca',
      },
      {
        _id: '60cc235247f7e10015fd6ecc',
        courseCode: 'HISTORY 3616',
        shortName:
          'History 3616F/G - GRAND IMPERIALISM: THE ASIA-PACIFIC IN THE LONG 19TH CENTURY',
        courseName:
          'History 3616F/G - GRAND IMPERIALISM: THE ASIA-PACIFIC IN THE LONG 19TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235247f7e10015fd6ecc',
      },
      {
        _id: '60cc235247f7e10015fd6ece',
        courseCode: 'HISTORY 3617',
        shortName: 'History 3617F/G - THE ASIA-PACIFIC IN THE TWO WORLD WARS',
        courseName: 'History 3617F/G - THE ASIA-PACIFIC IN THE TWO WORLD WARS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235247f7e10015fd6ece',
      },
      {
        _id: '60cc235247f7e10015fd6ed0',
        courseCode: 'HISTORY 3620',
        shortName: 'History 3620F/G - EARLY MODERN KOREA',
        courseName: 'History 3620F/G - EARLY MODERN KOREA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235247f7e10015fd6ed0',
      },
      {
        _id: '60cc235247f7e10015fd6ed2',
        courseCode: 'HISTORY 3621',
        shortName: 'History 3621F/G - LATE MEDIEVAL KOREA',
        courseName: 'History 3621F/G - LATE MEDIEVAL KOREA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235247f7e10015fd6ed2',
      },
      {
        _id: '60cc235247f7e10015fd6ed4',
        courseCode: 'HISTORY 3636',
        shortName:
          'History 3636F/G - STATE AND MARKET IN EARLY MODERN EAST ASIA',
        courseName:
          'History 3636F/G - STATE AND MARKET IN EARLY MODERN EAST ASIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235247f7e10015fd6ed4',
      },
      {
        _id: '60cc235347f7e10015fd6ed6',
        courseCode: 'HISTORY 3691',
        shortName:
          'History 3691E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3691E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235347f7e10015fd6ed6',
      },
      {
        _id: '60cc235347f7e10015fd6ed8',
        courseCode: 'HISTORY 3692',
        shortName:
          'History 3692E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3692E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235347f7e10015fd6ed8',
      },
      {
        _id: '60cc235447f7e10015fd6eda',
        courseCode: 'HISTORY 3693',
        shortName:
          'History 3693E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3693E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235447f7e10015fd6eda',
      },
      {
        _id: '60cc235447f7e10015fd6edc',
        courseCode: 'HISTORY 3694',
        shortName:
          'History 3694E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3694E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235447f7e10015fd6edc',
      },
      {
        _id: '60cc235447f7e10015fd6ede',
        courseCode: 'HISTORY 3695',
        shortName:
          'History 3695E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3695E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235447f7e10015fd6ede',
      },
      {
        _id: '60cc235547f7e10015fd6ee0',
        courseCode: 'HISTORY 3696',
        shortName:
          'History 3696F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3696F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235547f7e10015fd6ee0',
      },
      {
        _id: '60cc235647f7e10015fd6ee2',
        courseCode: 'HISTORY 3697',
        shortName:
          'History 3697F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3697F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235647f7e10015fd6ee2',
      },
      {
        _id: '60cc235647f7e10015fd6ee4',
        courseCode: 'HISTORY 3698',
        shortName:
          'History 3698F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3698F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235647f7e10015fd6ee4',
      },
      {
        _id: '60cc235747f7e10015fd6ee6',
        courseCode: 'HISTORY 3699',
        shortName:
          'History 3699F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 3699F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235747f7e10015fd6ee6',
      },
      {
        _id: '60cc235747f7e10015fd6ee8',
        courseCode: 'HISTORY 3702',
        shortName:
          'History 3702F/G - SLAVERY, RESISTANCE AND  EMANCIPATION IN THE CARIBBEAN',
        courseName:
          'History 3702F/G - SLAVERY, RESISTANCE AND  EMANCIPATION IN THE CARIBBEAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235747f7e10015fd6ee8',
      },
      {
        _id: '60cc235747f7e10015fd6eea',
        courseCode: 'HISTORY 3705',
        shortName: 'History 3705E - CURRENT CRISES IN HISTORICAL PERSPECTIVE',
        courseName: 'History 3705E - CURRENT CRISES IN HISTORICAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235747f7e10015fd6eea',
      },
      {
        _id: '60cc235747f7e10015fd6eec',
        courseCode: 'HISTORY 3706',
        shortName: 'History 3706E - POLITICAL ASSASSINATIONS, 1900-2000',
        courseName: 'History 3706E - POLITICAL ASSASSINATIONS, 1900-2000',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235747f7e10015fd6eec',
      },
      {
        _id: '60cc235847f7e10015fd6eee',
        courseCode: 'HISTORY 3710',
        shortName:
          'History 3710F/G - THE WORLD WARS IN HISTORY, MEMORY AND RECONCILIATION',
        courseName:
          'History 3710F/G - THE WORLD WARS IN HISTORY, MEMORY AND RECONCILIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235847f7e10015fd6eee',
      },
      {
        _id: '60cc235847f7e10015fd6ef0',
        courseCode: 'HISTORY 3711',
        shortName: 'History 3711F/G - STATE, SOCIETY AND MASS VIOLENCE',
        courseName: 'History 3711F/G - STATE, SOCIETY AND MASS VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235847f7e10015fd6ef0',
      },
      {
        _id: '60cc235847f7e10015fd6ef2',
        courseCode: 'HISTORY 3714',
        shortName: 'History 3714F/G - SOCIAL BASES OF FASCISM',
        courseName: 'History 3714F/G - SOCIAL BASES OF FASCISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235847f7e10015fd6ef2',
      },
      {
        _id: '60cc235847f7e10015fd6ef4',
        courseCode: 'HISTORY 3715',
        shortName:
          'History 3715F/G - THE PURSUIT OF PEACE IN THE TRANSATLANTIC WORLD, 1815-1991',
        courseName:
          'History 3715F/G - THE PURSUIT OF PEACE IN THE TRANSATLANTIC WORLD, 1815-1991',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235847f7e10015fd6ef4',
      },
      {
        _id: '60cc235947f7e10015fd6ef6',
        courseCode: 'HISTORY 3717',
        shortName: 'History 3717F/G - THE GLOBAL COLD WAR',
        courseName: 'History 3717F/G - THE GLOBAL COLD WAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235947f7e10015fd6ef6',
      },
      {
        _id: '60cc235947f7e10015fd6ef8',
        courseCode: 'HISTORY 3719',
        shortName: 'History 3719E - THE NORTH AMERICAN CITY',
        courseName: 'History 3719E - THE NORTH AMERICAN CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235947f7e10015fd6ef8',
      },
      {
        _id: '60cc235947f7e10015fd6efa',
        courseCode: 'HISTORY 3721',
        shortName: 'History 3721F/G - CLIMATE OF THE PAST, PRESENT AND FUTURE',
        courseName: 'History 3721F/G - CLIMATE OF THE PAST, PRESENT AND FUTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235947f7e10015fd6efa',
      },
      {
        _id: '60cc235947f7e10015fd6efc',
        courseCode: 'HISTORY 3722',
        shortName:
          "History 3722F/G - 'KILLING FIELDS': GENOCIDE IN MODERN HISTORY",
        courseName:
          "History 3722F/G - 'KILLING FIELDS': GENOCIDE IN MODERN HISTORY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235947f7e10015fd6efc',
      },
      {
        _id: '60cc235947f7e10015fd6efe',
        courseCode: 'HISTORY 3723',
        shortName:
          'History 3723F/G - THE ANTHROPOCENE: HISTORY OF A HUMAN PLANET',
        courseName:
          'History 3723F/G - THE ANTHROPOCENE: HISTORY OF A HUMAN PLANET',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235947f7e10015fd6efe',
      },
      {
        _id: '60cc235a47f7e10015fd6f00',
        courseCode: 'HISTORY 3791',
        shortName:
          'History 3791E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3791E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235a47f7e10015fd6f00',
      },
      {
        _id: '60cc235a47f7e10015fd6f02',
        courseCode: 'HISTORY 3792',
        shortName:
          'History 3792E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3792E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235a47f7e10015fd6f02',
      },
      {
        _id: '60cc235b47f7e10015fd6f04',
        courseCode: 'HISTORY 3794',
        shortName:
          'History 3794E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3794E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235b47f7e10015fd6f04',
      },
      {
        _id: '60cc235b47f7e10015fd6f06',
        courseCode: 'HISTORY 3795',
        shortName:
          'History 3795E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3795E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235b47f7e10015fd6f06',
      },
      {
        _id: '60cc235c47f7e10015fd6f08',
        courseCode: 'HISTORY 3797',
        shortName:
          'History 3797F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3797F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235c47f7e10015fd6f08',
      },
      {
        _id: '60cc235c47f7e10015fd6f0a',
        courseCode: 'HISTORY 3798',
        shortName:
          'History 3798F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3798F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235c47f7e10015fd6f0a',
      },
      {
        _id: '60cc235d47f7e10015fd6f0c',
        courseCode: 'HISTORY 3799',
        shortName:
          'History 3799F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 3799F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235d47f7e10015fd6f0c',
      },
      {
        _id: '60cc235d47f7e10015fd6f0e',
        courseCode: 'HISTORY 3801',
        shortName: "History 3801E - THE HISTORIAN'S CRAFT",
        courseName: "History 3801E - THE HISTORIAN'S CRAFT",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235d47f7e10015fd6f0e',
      },
      {
        _id: '60cc235e47f7e10015fd6f10',
        courseCode: 'HISTORY 3802',
        shortName:
          'History 3802F/G - CATHOLIC SOCIAL THOUGHT: HISTORICAL AND CONTEMPORARY PERSPECTIVES',
        courseName:
          'History 3802F/G - CATHOLIC SOCIAL THOUGHT: HISTORICAL AND CONTEMPORARY PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235e47f7e10015fd6f10',
      },
      {
        _id: '60cc235e47f7e10015fd6f12',
        courseCode: 'HISTORY 3803',
        shortName: 'History 3803F/G - TORONTO’S HISTORIES',
        courseName: 'History 3803F/G - TORONTO’S HISTORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235e47f7e10015fd6f12',
      },
      {
        _id: '60cc235e47f7e10015fd6f14',
        courseCode: 'HISTORY 3804',
        shortName: "History 3804E - SPECIAL TOPICS IN WOMEN'S HISTORY",
        courseName: "History 3804E - SPECIAL TOPICS IN WOMEN'S HISTORY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235e47f7e10015fd6f14',
      },
      {
        _id: '60cc235e47f7e10015fd6f16',
        courseCode: 'HISTORY 3805',
        shortName:
          'History 3805E - THE FAMILY IN THE NORTH ATLANTIC WORLD SINCE 1500',
        courseName:
          'History 3805E - THE FAMILY IN THE NORTH ATLANTIC WORLD SINCE 1500',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235e47f7e10015fd6f16',
      },
      {
        _id: '60cc235f47f7e10015fd6f18',
        courseCode: 'HISTORY 3806',
        shortName: "History 3806F/G - SPECIAL TOPICS IN WOMEN'S HISTORY",
        courseName: "History 3806F/G - SPECIAL TOPICS IN WOMEN'S HISTORY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235f47f7e10015fd6f18',
      },
      {
        _id: '60cc235f47f7e10015fd6f1a',
        courseCode: 'HISTORY 3807',
        shortName:
          'History 3807E - THE JEWISH IMMIGRANT EXPERIENCE IN NORTH AMERICA, 1880-1945',
        courseName:
          'History 3807E - THE JEWISH IMMIGRANT EXPERIENCE IN NORTH AMERICA, 1880-1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235f47f7e10015fd6f1a',
      },
      {
        _id: '60cc235f47f7e10015fd6f1c',
        courseCode: 'HISTORY 3808',
        shortName: 'History 3808F/G - JEWISH LIFE IN NORTH AMERICA SINCE 1880',
        courseName: 'History 3808F/G - JEWISH LIFE IN NORTH AMERICA SINCE 1880',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235f47f7e10015fd6f1c',
      },
      {
        _id: '60cc235f47f7e10015fd6f1e',
        courseCode: 'HISTORY 3809',
        shortName: 'History 3809F/G - HISTORY, ETHICS AND PUBLIC POLICY',
        courseName: 'History 3809F/G - HISTORY, ETHICS AND PUBLIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc235f47f7e10015fd6f1e',
      },
      {
        _id: '60cc236047f7e10015fd6f20',
        courseCode: 'HISTORY 3812',
        shortName:
          'History 3812F/G - HISTORICAL FANTASIES: PSEUDOHISTORY AND POPULAR DISTORTIONS OF THE PAST',
        courseName:
          'History 3812F/G - HISTORICAL FANTASIES: PSEUDOHISTORY AND POPULAR DISTORTIONS OF THE PAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236047f7e10015fd6f20',
      },
      {
        _id: '60cc236047f7e10015fd6f22',
        courseCode: 'HISTORY 3813',
        shortName: 'History 3813E - PUBLIC HISTORY',
        courseName: 'History 3813E - PUBLIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236047f7e10015fd6f22',
      },
      {
        _id: '60cc236047f7e10015fd6f25',
        courseCode: 'HISTORY 3816',
        shortName: 'History 3816F/G - INTRO TO DIGITAL HISTORY',
        courseName: 'History 3816F/G - INTRO TO DIGITAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236047f7e10015fd6f25',
      },
      {
        _id: '60cc236147f7e10015fd6f27',
        courseCode: 'HISTORY 3823',
        shortName: 'History 3823F/G - GLOBAL TWENTY-FIRST CENTURY HISTORY',
        courseName: 'History 3823F/G - GLOBAL TWENTY-FIRST CENTURY HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236147f7e10015fd6f27',
      },
      {
        _id: '60cc236147f7e10015fd6f29',
        courseCode: 'HISTORY 3830',
        shortName:
          'History 3830F/G - NATURE, CULTURE, AND LITERATURE IN NORTH AMERICA',
        courseName:
          'History 3830F/G - NATURE, CULTURE, AND LITERATURE IN NORTH AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236147f7e10015fd6f29',
      },
      {
        _id: '60cc236147f7e10015fd6f2b',
        courseCode: 'HISTORY 3850',
        shortName: 'History 3850F/G - CAPITALISM AND THE WORKING CLASS',
        courseName: 'History 3850F/G - CAPITALISM AND THE WORKING CLASS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236147f7e10015fd6f2b',
      },
      {
        _id: '60cc236147f7e10015fd6f2d',
        courseCode: 'HISTORY 3851',
        shortName: 'History 3851F/G - CAPITALISM AND THE LAW',
        courseName: 'History 3851F/G - CAPITALISM AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236147f7e10015fd6f2d',
      },
      {
        _id: '60cc236147f7e10015fd6f2f',
        courseCode: 'HISTORY 3860',
        shortName: 'History 3860E - RELIGION AND THE HOLOCAUST',
        courseName: 'History 3860E - RELIGION AND THE HOLOCAUST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236147f7e10015fd6f2f',
      },
      {
        _id: '60cc236247f7e10015fd6f31',
        courseCode: 'HISTORY 3891',
        shortName:
          'History 3891E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3891E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236247f7e10015fd6f31',
      },
      {
        _id: '60cc236247f7e10015fd6f33',
        courseCode: 'HISTORY 3892',
        shortName:
          'History 3892E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3892E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236247f7e10015fd6f33',
      },
      {
        _id: '60cc236347f7e10015fd6f35',
        courseCode: 'HISTORY 3893',
        shortName:
          'History 3893E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3893E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236347f7e10015fd6f35',
      },
      {
        _id: '60cc236347f7e10015fd6f37',
        courseCode: 'HISTORY 3894',
        shortName:
          'History 3894E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3894E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236347f7e10015fd6f37',
      },
      {
        _id: '60cc236447f7e10015fd6f39',
        courseCode: 'HISTORY 3895',
        shortName:
          'History 3895E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3895E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236447f7e10015fd6f39',
      },
      {
        _id: '60cc236447f7e10015fd6f3b',
        courseCode: 'HISTORY 3896',
        shortName:
          'History 3896F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3896F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236447f7e10015fd6f3b',
      },
      {
        _id: '60cc236547f7e10015fd6f3d',
        courseCode: 'HISTORY 3897',
        shortName:
          'History 3897F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3897F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236547f7e10015fd6f3d',
      },
      {
        _id: '60cc236547f7e10015fd6f3f',
        courseCode: 'HISTORY 3898',
        shortName:
          'History 3898F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3898F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236547f7e10015fd6f3f',
      },
      {
        _id: '60cc236647f7e10015fd6f41',
        courseCode: 'HISTORY 3899',
        shortName:
          'History 3899F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 3899F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236647f7e10015fd6f41',
      },
      {
        _id: '60cc236647f7e10015fd6f43',
        courseCode: 'HISTORY 3900',
        shortName: 'History 3900 - INTERNSHIP',
        courseName: 'History 3900 - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236647f7e10015fd6f43',
      },
      {
        _id: '60cc236647f7e10015fd6f45',
        courseCode: 'HISTORY 3901',
        shortName: 'History 3901F/G - WORKPLACE LEARNING',
        courseName: 'History 3901F/G - WORKPLACE LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236647f7e10015fd6f45',
      },
      {
        _id: '60cc236747f7e10015fd6f47',
        courseCode: 'HISTORY 3902',
        shortName: 'History 3902F/G - WORKPLACE LEARNING',
        courseName: 'History 3902F/G - WORKPLACE LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236747f7e10015fd6f47',
      },
      {
        _id: '60cc236747f7e10015fd6f49',
        courseCode: 'HISTORY 3903',
        shortName: 'History 3903F/G - WORKPLACE LEARNING',
        courseName: 'History 3903F/G - WORKPLACE LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236747f7e10015fd6f49',
      },
      {
        _id: '60cc236747f7e10015fd6f4b',
        courseCode: 'HISTORY 4201',
        shortName: 'History 4201E - CANADA IN THE WORLD WARS',
        courseName: 'History 4201E - CANADA IN THE WORLD WARS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236747f7e10015fd6f4b',
      },
      {
        _id: '60cc236747f7e10015fd6f4d',
        courseCode: 'HISTORY 4202',
        shortName:
          'History 4202F/G - CONFRONTING COLONIALISM: LAND, LITERACIES AND LEARNING',
        courseName:
          'History 4202F/G - CONFRONTING COLONIALISM: LAND, LITERACIES AND LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236747f7e10015fd6f4d',
      },
      {
        _id: '60cc236747f7e10015fd6f4f',
        courseCode: 'HISTORY 4204',
        shortName: 'History 4204F/G - RIGHTING WRONGS: HUMAN RIGHTS IN CANADA',
        courseName: 'History 4204F/G - RIGHTING WRONGS: HUMAN RIGHTS IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236747f7e10015fd6f4f',
      },
      {
        _id: '60cc236847f7e10015fd6f51',
        courseCode: 'HISTORY 4208',
        shortName:
          'History 4208F/G - CANADA AND THE AGE OF CONFLICT, 1896-1945',
        courseName:
          'History 4208F/G - CANADA AND THE AGE OF CONFLICT, 1896-1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236847f7e10015fd6f51',
      },
      {
        _id: '60cc236847f7e10015fd6f53',
        courseCode: 'HISTORY 4209',
        shortName: 'History 4209E - CANADA IN THE FIRST WORLD WAR',
        courseName: 'History 4209E - CANADA IN THE FIRST WORLD WAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236847f7e10015fd6f53',
      },
      {
        _id: '60cc236847f7e10015fd6f55',
        courseCode: 'HISTORY 4210',
        shortName: 'History 4210E - CANADA IN THE SECOND WORLD WAR',
        courseName: 'History 4210E - CANADA IN THE SECOND WORLD WAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236847f7e10015fd6f55',
      },
      {
        _id: '60cc236847f7e10015fd6f57',
        courseCode: 'HISTORY 4213',
        shortName: 'History 4213F/G - LIFE, LOVE & DEATH IN EARLY CANADA',
        courseName: 'History 4213F/G - LIFE, LOVE & DEATH IN EARLY CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236847f7e10015fd6f57',
      },
      {
        _id: '60cc236947f7e10015fd6f59',
        courseCode: 'HISTORY 4220',
        shortName:
          'History 4220E - CANADIAN IMMIGRATION HISTORY: THE POLITICS, THE POLICIES AND THE PEOPLE',
        courseName:
          'History 4220E - CANADIAN IMMIGRATION HISTORY: THE POLITICS, THE POLICIES AND THE PEOPLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236947f7e10015fd6f59',
      },
      {
        _id: '60cc236947f7e10015fd6f5b',
        courseCode: 'HISTORY 4291',
        shortName: 'History 4291E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4291E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236947f7e10015fd6f5b',
      },
      {
        _id: '60cc236947f7e10015fd6f5d',
        courseCode: 'HISTORY 4292',
        shortName: 'History 4292E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4292E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236947f7e10015fd6f5d',
      },
      {
        _id: '60cc236a47f7e10015fd6f5f',
        courseCode: 'HISTORY 4293',
        shortName: 'History 4293E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4293E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236a47f7e10015fd6f5f',
      },
      {
        _id: '60cc236a47f7e10015fd6f61',
        courseCode: 'HISTORY 4294',
        shortName: 'History 4294E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4294E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236a47f7e10015fd6f61',
      },
      {
        _id: '60cc236b47f7e10015fd6f63',
        courseCode: 'HISTORY 4295',
        shortName: 'History 4295E - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4295E - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236b47f7e10015fd6f63',
      },
      {
        _id: '60cc236b47f7e10015fd6f65',
        courseCode: 'HISTORY 4296',
        shortName: 'History 4296F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4296F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236b47f7e10015fd6f65',
      },
      {
        _id: '60cc236c47f7e10015fd6f67',
        courseCode: 'HISTORY 4297',
        shortName: 'History 4297F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4297F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236c47f7e10015fd6f67',
      },
      {
        _id: '60cc236c47f7e10015fd6f69',
        courseCode: 'HISTORY 4298',
        shortName: 'History 4298F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4298F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236c47f7e10015fd6f69',
      },
      {
        _id: '60cc236d47f7e10015fd6f6b',
        courseCode: 'HISTORY 4299',
        shortName: 'History 4299F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        courseName: 'History 4299F/G - SELECTED TOPICS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236d47f7e10015fd6f6b',
      },
      {
        _id: '60cc236d47f7e10015fd6f6d',
        courseCode: 'HISTORY 4300',
        shortName: 'History 4300E - TERRORISM USA',
        courseName: 'History 4300E - TERRORISM USA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236d47f7e10015fd6f6d',
      },
      {
        _id: '60cc236d47f7e10015fd6f6f',
        courseCode: 'HISTORY 4301',
        shortName: 'History 4301E - THE UNITED STATES IN THE TWENTIETH CENTURY',
        courseName:
          'History 4301E - THE UNITED STATES IN THE TWENTIETH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236d47f7e10015fd6f6f',
      },
      {
        _id: '60cc236e47f7e10015fd6f71',
        courseCode: 'HISTORY 4303',
        shortName: 'History 4303E - LIBERTY IN AMERICA, 1607-2001',
        courseName: 'History 4303E - LIBERTY IN AMERICA, 1607-2001',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236e47f7e10015fd6f71',
      },
      {
        _id: '60cc236e47f7e10015fd6f73',
        courseCode: 'HISTORY 4306',
        shortName: 'History 4306E - COLD WAR AMERICA',
        courseName: 'History 4306E - COLD WAR AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236e47f7e10015fd6f73',
      },
      {
        _id: '60cc236e47f7e10015fd6f75',
        courseCode: 'HISTORY 4307',
        shortName:
          'History 4307F/G - AMERICAN DREAMS: RADICALS AND REFORMERS FROM A "CITY ON A HILL" TO HERLAND',
        courseName:
          'History 4307F/G - AMERICAN DREAMS: RADICALS AND REFORMERS FROM A "CITY ON A HILL" TO HERLAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236e47f7e10015fd6f75',
      },
      {
        _id: '60cc236e47f7e10015fd6f77',
        courseCode: 'HISTORY 4308',
        shortName:
          'History 4308F/G - IRON-JAWED ANGELS: WOMEN, POWER, AND THE VOTE IN AMERICAN HISTORY',
        courseName:
          'History 4308F/G - IRON-JAWED ANGELS: WOMEN, POWER, AND THE VOTE IN AMERICAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236e47f7e10015fd6f77',
      },
      {
        _id: '60cc236f47f7e10015fd6f79',
        courseCode: 'HISTORY 4315',
        shortName:
          'History 4315F/G - AMERICAN SLAVERY, GLOBAL CONTEXT: PROFIT, IDENTITY, AND POWER IN THE MODERN ERA',
        courseName:
          'History 4315F/G - AMERICAN SLAVERY, GLOBAL CONTEXT: PROFIT, IDENTITY, AND POWER IN THE MODERN ERA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236f47f7e10015fd6f79',
      },
      {
        _id: '60cc236f47f7e10015fd6f7b',
        courseCode: 'HISTORY 4351',
        shortName: 'History 4351F/G - AMERICAN CAPTIVITY NARRATIVES',
        courseName: 'History 4351F/G - AMERICAN CAPTIVITY NARRATIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236f47f7e10015fd6f7b',
      },
      {
        _id: '60cc236f47f7e10015fd6f7d',
        courseCode: 'HISTORY 4391',
        shortName: 'History 4391E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4391E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc236f47f7e10015fd6f7d',
      },
      {
        _id: '60cc237047f7e10015fd6f7f',
        courseCode: 'HISTORY 4392',
        shortName: 'History 4392E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4392E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237047f7e10015fd6f7f',
      },
      {
        _id: '60cc237047f7e10015fd6f81',
        courseCode: 'HISTORY 4393',
        shortName: 'History 4393E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4393E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237047f7e10015fd6f81',
      },
      {
        _id: '60cc237147f7e10015fd6f83',
        courseCode: 'HISTORY 4394',
        shortName: 'History 4394E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4394E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237147f7e10015fd6f83',
      },
      {
        _id: '60cc237147f7e10015fd6f85',
        courseCode: 'HISTORY 4395',
        shortName: 'History 4395E - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4395E - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237147f7e10015fd6f85',
      },
      {
        _id: '60cc237147f7e10015fd6f87',
        courseCode: 'HISTORY 4396',
        shortName: 'History 4396F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4396F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237147f7e10015fd6f87',
      },
      {
        _id: '60cc237247f7e10015fd6f89',
        courseCode: 'HISTORY 4397',
        shortName: 'History 4397F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4397F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237247f7e10015fd6f89',
      },
      {
        _id: '60cc237247f7e10015fd6f8b',
        courseCode: 'HISTORY 4398',
        shortName: 'History 4398F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4398F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237247f7e10015fd6f8b',
      },
      {
        _id: '60cc237347f7e10015fd6f8d',
        courseCode: 'HISTORY 4399',
        shortName: 'History 4399F/G - SELECTED TOPICS IN US HISTORY',
        courseName: 'History 4399F/G - SELECTED TOPICS IN US HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237347f7e10015fd6f8d',
      },
      {
        _id: '60cc237347f7e10015fd6f8f',
        courseCode: 'HISTORY 4409',
        shortName: 'History 4409E - FRENCH REVOLUTION',
        courseName: 'History 4409E - FRENCH REVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237347f7e10015fd6f8f',
      },
      {
        _id: '60cc237347f7e10015fd6f91',
        courseCode: 'HISTORY 4410',
        shortName: 'History 4410F/G - CRIME AND SOCIETY IN ENGLAND, 1800-1900',
        courseName: 'History 4410F/G - CRIME AND SOCIETY IN ENGLAND, 1800-1900',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237347f7e10015fd6f91',
      },
      {
        _id: '60cc237447f7e10015fd6f93',
        courseCode: 'HISTORY 4411',
        shortName: 'History 4411E - CRIME AND SOCIETY IN ENGLAND, 1660-1900',
        courseName: 'History 4411E - CRIME AND SOCIETY IN ENGLAND, 1660-1900',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237447f7e10015fd6f93',
      },
      {
        _id: '60cc237447f7e10015fd6f95',
        courseCode: 'HISTORY 4412',
        shortName:
          'History 4412E - THE TUDORS AND THE STUARTS: EARLY MODERN ENGLAND',
        courseName:
          'History 4412E - THE TUDORS AND THE STUARTS: EARLY MODERN ENGLAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237447f7e10015fd6f95',
      },
      {
        _id: '60cc237447f7e10015fd6f97',
        courseCode: 'HISTORY 4413',
        shortName:
          'History 4413E - AGE OF EXTREMES: FASCISM, COMMUNISM AND AUTHORITARIANISM IN THE TWENTIETH CENTURY',
        courseName:
          'History 4413E - AGE OF EXTREMES: FASCISM, COMMUNISM AND AUTHORITARIANISM IN THE TWENTIETH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237447f7e10015fd6f97',
      },
      {
        _id: '60cc237447f7e10015fd6f99',
        courseCode: 'HISTORY 4414',
        shortName:
          'History 4414F/G - SECRETS, SPIES AND SURVEILLANCE IN MODERN BRITISH HISTORY',
        courseName:
          'History 4414F/G - SECRETS, SPIES AND SURVEILLANCE IN MODERN BRITISH HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237447f7e10015fd6f99',
      },
      {
        _id: '60cc237447f7e10015fd6f9b',
        courseCode: 'HISTORY 4420',
        shortName: 'History 4420F/G - VICTORIAN BRITAIN',
        courseName: 'History 4420F/G - VICTORIAN BRITAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237447f7e10015fd6f9b',
      },
      {
        _id: '60cc237547f7e10015fd6f9d',
        courseCode: 'HISTORY 4422',
        shortName:
          'History 4422F/G - LONDON UK: CRIME AND DISORDER IN THE 20TH CENTURY',
        courseName:
          'History 4422F/G - LONDON UK: CRIME AND DISORDER IN THE 20TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237547f7e10015fd6f9d',
      },
      {
        _id: '60cc237547f7e10015fd6f9f',
        courseCode: 'HISTORY 4423',
        shortName: 'History 4423E - VICTORIAN BRITAIN AND EMPIRE',
        courseName: 'History 4423E - VICTORIAN BRITAIN AND EMPIRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237547f7e10015fd6f9f',
      },
      {
        _id: '60cc237547f7e10015fd6fa1',
        courseCode: 'HISTORY 4426',
        shortName: 'History 4426E - THE CATHOLIC CHURCH AND THE HOLOCAUST',
        courseName: 'History 4426E - THE CATHOLIC CHURCH AND THE HOLOCAUST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237547f7e10015fd6fa1',
      },
      {
        _id: '60cc237547f7e10015fd6fa3',
        courseCode: 'HISTORY 4491',
        shortName: 'History 4491E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4491E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237547f7e10015fd6fa3',
      },
      {
        _id: '60cc237647f7e10015fd6fa5',
        courseCode: 'HISTORY 4492',
        shortName: 'History 4492E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4492E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237647f7e10015fd6fa5',
      },
      {
        _id: '60cc237647f7e10015fd6fa7',
        courseCode: 'HISTORY 4493',
        shortName: 'History 4493E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4493E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237647f7e10015fd6fa7',
      },
      {
        _id: '60cc237747f7e10015fd6fa9',
        courseCode: 'HISTORY 4494',
        shortName: 'History 4494E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4494E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237747f7e10015fd6fa9',
      },
      {
        _id: '60cc237847f7e10015fd6fab',
        courseCode: 'HISTORY 4495',
        shortName: 'History 4495E - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4495E - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237847f7e10015fd6fab',
      },
      {
        _id: '60cc237847f7e10015fd6fad',
        courseCode: 'HISTORY 4496',
        shortName: 'History 4496F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4496F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237847f7e10015fd6fad',
      },
      {
        _id: '60cc237947f7e10015fd6faf',
        courseCode: 'HISTORY 4497',
        shortName: 'History 4497F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4497F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237947f7e10015fd6faf',
      },
      {
        _id: '60cc237947f7e10015fd6fb1',
        courseCode: 'HISTORY 4498',
        shortName: 'History 4498F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4498F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237947f7e10015fd6fb1',
      },
      {
        _id: '60cc237a47f7e10015fd6fb3',
        courseCode: 'HISTORY 4499',
        shortName: 'History 4499F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        courseName: 'History 4499F/G - SELECTED TOPICS IN EUROPEAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237a47f7e10015fd6fb3',
      },
      {
        _id: '60cc237a47f7e10015fd6fb5',
        courseCode: 'HISTORY 4502',
        shortName:
          'History 4502F/G - THE BLACK ATLANTIC: SLAVERY IN THE AMERICAS AND AFRICA',
        courseName:
          'History 4502F/G - THE BLACK ATLANTIC: SLAVERY IN THE AMERICAS AND AFRICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237a47f7e10015fd6fb5',
      },
      {
        _id: '60cc237a47f7e10015fd6fb7',
        courseCode: 'HISTORY 4503',
        shortName:
          'History 4503F/G - THREE WORLDS COLLIDE: COLONIAL LATIN AMER SOCIETIES',
        courseName:
          'History 4503F/G - THREE WORLDS COLLIDE: COLONIAL LATIN AMER SOCIETIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237a47f7e10015fd6fb7',
      },
      {
        _id: '60cc237a47f7e10015fd6fb9',
        courseCode: 'HISTORY 4504',
        shortName:
          'History 4504F/G - THE HISTORY OF HUMAN RIGHTS IN LATIN AMERICA',
        courseName:
          'History 4504F/G - THE HISTORY OF HUMAN RIGHTS IN LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237a47f7e10015fd6fb9',
      },
      {
        _id: '60cc237b47f7e10015fd6fbb',
        courseCode: 'HISTORY 4591',
        shortName:
          'History 4591E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4591E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237b47f7e10015fd6fbb',
      },
      {
        _id: '60cc237b47f7e10015fd6fbd',
        courseCode: 'HISTORY 4592',
        shortName:
          'History 4592E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4592E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237b47f7e10015fd6fbd',
      },
      {
        _id: '60cc237b47f7e10015fd6fbf',
        courseCode: 'HISTORY 4593',
        shortName:
          'History 4593E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4593E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237b47f7e10015fd6fbf',
      },
      {
        _id: '60cc237c47f7e10015fd6fc1',
        courseCode: 'HISTORY 4594',
        shortName:
          'History 4594E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4594E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237c47f7e10015fd6fc1',
      },
      {
        _id: '60cc237c47f7e10015fd6fc3',
        courseCode: 'HISTORY 4595',
        shortName:
          'History 4595E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4595E - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237c47f7e10015fd6fc3',
      },
      {
        _id: '60cc237d47f7e10015fd6fc5',
        courseCode: 'HISTORY 4596',
        shortName:
          'History 4596F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4596F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237d47f7e10015fd6fc5',
      },
      {
        _id: '60cc237d47f7e10015fd6fc7',
        courseCode: 'HISTORY 4597',
        shortName:
          'History 4597F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4597F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237d47f7e10015fd6fc7',
      },
      {
        _id: '60cc237d47f7e10015fd6fc9',
        courseCode: 'HISTORY 4598',
        shortName:
          'History 4598F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4598F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237d47f7e10015fd6fc9',
      },
      {
        _id: '60cc237e47f7e10015fd6fcb',
        courseCode: 'HISTORY 4599',
        shortName:
          'History 4599F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        courseName:
          'History 4599F/G - SELECTED TOPICS IN LATIN AMERICAN OR PACIFIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237e47f7e10015fd6fcb',
      },
      {
        _id: '60cc237e47f7e10015fd6fcd',
        courseCode: 'HISTORY 4603',
        shortName:
          'History 4603F/G - SILK ROADS AND SPICE ROUTES: ANCIENT AND MEDIEVAL ASIA AND WORLD CONTACTS',
        courseName:
          'History 4603F/G - SILK ROADS AND SPICE ROUTES: ANCIENT AND MEDIEVAL ASIA AND WORLD CONTACTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237e47f7e10015fd6fcd',
      },
      {
        _id: '60cc237e47f7e10015fd6fcf',
        courseCode: 'HISTORY 4605',
        shortName: 'History 4605E - WAR AND MEMORY IN MODERN EAST ASIA',
        courseName: 'History 4605E - WAR AND MEMORY IN MODERN EAST ASIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237e47f7e10015fd6fcf',
      },
      {
        _id: '60cc237e47f7e10015fd6fd1',
        courseCode: 'HISTORY 4606',
        shortName: 'History 4606F/G - SEX, LAW, AND SOCIETY IN IMPERIAL CHINA',
        courseName: 'History 4606F/G - SEX, LAW, AND SOCIETY IN IMPERIAL CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237e47f7e10015fd6fd1',
      },
      {
        _id: '60cc237f47f7e10015fd6fd3',
        courseCode: 'HISTORY 4607',
        shortName:
          'History 4607F/G - HISTORY OF WOMEN AND GENDER RELATIONS IN AFRICA',
        courseName:
          'History 4607F/G - HISTORY OF WOMEN AND GENDER RELATIONS IN AFRICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237f47f7e10015fd6fd3',
      },
      {
        _id: '60cc237f47f7e10015fd6fd5',
        courseCode: 'HISTORY 4611',
        shortName:
          'History 4611F/G - FOREIGN RELATIONS OF THE PEOPLE’S REPUBLIC OF CHINA',
        courseName:
          'History 4611F/G - FOREIGN RELATIONS OF THE PEOPLE’S REPUBLIC OF CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237f47f7e10015fd6fd5',
      },
      {
        _id: '60cc237f47f7e10015fd6fd7',
        courseCode: 'HISTORY 4691',
        shortName:
          'History 4691E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4691E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237f47f7e10015fd6fd7',
      },
      {
        _id: '60cc237f47f7e10015fd6fd9',
        courseCode: 'HISTORY 4692',
        shortName:
          'History 4692E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4692E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc237f47f7e10015fd6fd9',
      },
      {
        _id: '60cc238047f7e10015fd6fdb',
        courseCode: 'HISTORY 4693',
        shortName:
          'History 4693E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4693E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238047f7e10015fd6fdb',
      },
      {
        _id: '60cc238047f7e10015fd6fdd',
        courseCode: 'HISTORY 4694',
        shortName:
          'History 4694E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4694E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238047f7e10015fd6fdd',
      },
      {
        _id: '60cc238147f7e10015fd6fdf',
        courseCode: 'HISTORY 4695',
        shortName:
          'History 4695E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4695E - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238147f7e10015fd6fdf',
      },
      {
        _id: '60cc238147f7e10015fd6fe1',
        courseCode: 'HISTORY 4696',
        shortName:
          'History 4696F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4696F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238147f7e10015fd6fe1',
      },
      {
        _id: '60cc238147f7e10015fd6fe3',
        courseCode: 'HISTORY 4697',
        shortName:
          'History 4697F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4697F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238147f7e10015fd6fe3',
      },
      {
        _id: '60cc238247f7e10015fd6fe5',
        courseCode: 'HISTORY 4698',
        shortName:
          'History 4698F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4698F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238247f7e10015fd6fe5',
      },
      {
        _id: '60cc238247f7e10015fd6fe7',
        courseCode: 'HISTORY 4699',
        shortName:
          'History 4699F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        courseName:
          'History 4699F/G - SELECTED TOPICS IN ASIAN, AFRICAN OR MIDDLE EASTERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238247f7e10015fd6fe7',
      },
      {
        _id: '60cc238347f7e10015fd6fe9',
        courseCode: 'HISTORY 4701',
        shortName: 'History 4701E - CANADA AND THE UNITED STATES',
        courseName: 'History 4701E - CANADA AND THE UNITED STATES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238347f7e10015fd6fe9',
      },
      {
        _id: '60cc238347f7e10015fd6feb',
        courseCode: 'HISTORY 4702',
        shortName: 'History 4702F/G - EUROPEAN IMPERIALISM',
        courseName: 'History 4702F/G - EUROPEAN IMPERIALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238347f7e10015fd6feb',
      },
      {
        _id: '60cc238347f7e10015fd6fed',
        courseCode: 'HISTORY 4703',
        shortName: 'History 4703F/G - CANADA AND THE UNITED STATES',
        courseName: 'History 4703F/G - CANADA AND THE UNITED STATES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238347f7e10015fd6fed',
      },
      {
        _id: '60cc238347f7e10015fd6fef',
        courseCode: 'HISTORY 4704',
        shortName: 'History 4704E - ENVIRONMENTAL LEADERSHIP',
        courseName: 'History 4704E - ENVIRONMENTAL LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238347f7e10015fd6fef',
      },
      {
        _id: '60cc238447f7e10015fd6ff1',
        courseCode: 'HISTORY 4705',
        shortName: 'History 4705E - ENVIRONMENTAL HISTORY',
        courseName: 'History 4705E - ENVIRONMENTAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238447f7e10015fd6ff1',
      },
      {
        _id: '60cc238447f7e10015fd6ff3',
        courseCode: 'HISTORY 4706',
        shortName: 'History 4706E - FASCISM, NAZISM AND STALINISM COMPARED',
        courseName: 'History 4706E - FASCISM, NAZISM AND STALINISM COMPARED',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238447f7e10015fd6ff3',
      },
      {
        _id: '60cc238447f7e10015fd6ff5',
        courseCode: 'HISTORY 4708',
        shortName:
          "History 4708F/G - HISTORIES OF THE CIRCUMPOLAR NORTHS: 'THE LAST IMAGINARY PLACES'",
        courseName:
          "History 4708F/G - HISTORIES OF THE CIRCUMPOLAR NORTHS: 'THE LAST IMAGINARY PLACES'",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238447f7e10015fd6ff5',
      },
      {
        _id: '60cc238547f7e10015fd6ff7',
        courseCode: 'HISTORY 4709',
        shortName:
          'History 4709E - THE FIRST WORLD WAR: A REVOLUTIONARY EXPERIENCE',
        courseName:
          'History 4709E - THE FIRST WORLD WAR: A REVOLUTIONARY EXPERIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238547f7e10015fd6ff7',
      },
      {
        _id: '60cc238547f7e10015fd6ff9',
        courseCode: 'HISTORY 4710',
        shortName:
          'History 4710F/G - THE WORLD WARS IN HISTORY, MEMORY AND RECONCILIATION',
        courseName:
          'History 4710F/G - THE WORLD WARS IN HISTORY, MEMORY AND RECONCILIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238547f7e10015fd6ff9',
      },
      {
        _id: '60cc238547f7e10015fd6ffb',
        courseCode: 'HISTORY 4711',
        shortName:
          'History 4711F/G - THE FIRST WORLD WAR: A REVOLUTIONARY EXPERIENCE',
        courseName:
          'History 4711F/G - THE FIRST WORLD WAR: A REVOLUTIONARY EXPERIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238547f7e10015fd6ffb',
      },
      {
        _id: '60cc238547f7e10015fd6ffd',
        courseCode: 'HISTORY 4712',
        shortName:
          'History 4712F/G - THE FIRST WORLD WAR: A REVOLUTIONARY RESEARCH EXPERIENCE',
        courseName:
          'History 4712F/G - THE FIRST WORLD WAR: A REVOLUTIONARY RESEARCH EXPERIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238547f7e10015fd6ffd',
      },
      {
        _id: '60cc238647f7e10015fd6fff',
        courseCode: 'HISTORY 4713',
        shortName: 'History 4713F/G - SPIRITUALISM, SEANCES AND THE OCCULT',
        courseName: 'History 4713F/G - SPIRITUALISM, SEANCES AND THE OCCULT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238647f7e10015fd6fff',
      },
      {
        _id: '60cc238647f7e10015fd7001',
        courseCode: 'HISTORY 4791',
        shortName:
          'History 4791E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4791E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238647f7e10015fd7001',
      },
      {
        _id: '60cc238647f7e10015fd7003',
        courseCode: 'HISTORY 4792',
        shortName:
          'History 4792E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4792E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238647f7e10015fd7003',
      },
      {
        _id: '60cc238747f7e10015fd7005',
        courseCode: 'HISTORY 4793',
        shortName:
          'History 4793E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4793E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238747f7e10015fd7005',
      },
      {
        _id: '60cc238747f7e10015fd7007',
        courseCode: 'HISTORY 4794',
        shortName:
          'History 4794E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4794E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238747f7e10015fd7007',
      },
      {
        _id: '60cc238847f7e10015fd7009',
        courseCode: 'HISTORY 4795',
        shortName:
          'History 4795E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4795E - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238847f7e10015fd7009',
      },
      {
        _id: '60cc238847f7e10015fd700b',
        courseCode: 'HISTORY 4796',
        shortName:
          'History 4796F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4796F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238847f7e10015fd700b',
      },
      {
        _id: '60cc238947f7e10015fd700d',
        courseCode: 'HISTORY 4797',
        shortName:
          'History 4797F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4797F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238947f7e10015fd700d',
      },
      {
        _id: '60cc238947f7e10015fd700f',
        courseCode: 'HISTORY 4798',
        shortName:
          'History 4798F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4798F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238947f7e10015fd700f',
      },
      {
        _id: '60cc238a47f7e10015fd7011',
        courseCode: 'HISTORY 4799',
        shortName:
          'History 4799F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        courseName:
          'History 4799F/G - SELECTED TOPICS IN INTERNATIONAL OR COMPARATIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238a47f7e10015fd7011',
      },
      {
        _id: '60cc238a47f7e10015fd7013',
        courseCode: 'HISTORY 4801',
        shortName: 'History 4801E - ETHNOHISTORY',
        courseName: 'History 4801E - ETHNOHISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238a47f7e10015fd7013',
      },
      {
        _id: '60cc238a47f7e10015fd7015',
        courseCode: 'HISTORY 4802',
        shortName: 'History 4802F/G - MASCULINITY AND MODERN HISTORY',
        courseName: 'History 4802F/G - MASCULINITY AND MODERN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238a47f7e10015fd7015',
      },
      {
        _id: '60cc238b47f7e10015fd7017',
        courseCode: 'HISTORY 4803',
        shortName: 'History 4803E - TOPICS IN GENDER HISTORY',
        courseName: 'History 4803E - TOPICS IN GENDER HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238b47f7e10015fd7017',
      },
      {
        _id: '60cc238b47f7e10015fd7019',
        courseCode: 'HISTORY 4805',
        shortName: 'History 4805E - WARFARE',
        courseName: 'History 4805E - WARFARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238b47f7e10015fd7019',
      },
      {
        _id: '60cc238b47f7e10015fd701b',
        courseCode: 'HISTORY 4808',
        shortName: 'History 4808A/B - EXPERIENTIAL LEARNING',
        courseName: 'History 4808A/B - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238b47f7e10015fd701b',
      },
      {
        _id: '60cc238b47f7e10015fd701d',
        courseCode: 'HISTORY 4809',
        shortName: 'History 4809E - THE AUTOMOBILE AND MODERN CULTURE',
        courseName: 'History 4809E - THE AUTOMOBILE AND MODERN CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238b47f7e10015fd701d',
      },
      {
        _id: '60cc238b47f7e10015fd701f',
        courseCode: 'HISTORY 4810',
        shortName:
          'History 4810F/G - ENGAGING WITH THE PAST: PRACTICING ACTIVE HISTORY',
        courseName:
          'History 4810F/G - ENGAGING WITH THE PAST: PRACTICING ACTIVE HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238b47f7e10015fd701f',
      },
      {
        _id: '60cc238c47f7e10015fd7021',
        courseCode: 'HISTORY 4814',
        shortName: 'History 4814F/G - LETTERPRESS STUDIO',
        courseName: 'History 4814F/G - LETTERPRESS STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238c47f7e10015fd7021',
      },
      {
        _id: '60cc238c47f7e10015fd7023',
        courseCode: 'HISTORY 4816',
        shortName: 'History 4816A/B - DIGITAL RESEARCH METHODS',
        courseName: 'History 4816A/B - DIGITAL RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238c47f7e10015fd7023',
      },
      {
        _id: '60cc238c47f7e10015fd7025',
        courseCode: 'HISTORY 4821',
        shortName: 'History 4821F/G - SPY VS. SPY',
        courseName: 'History 4821F/G - SPY VS. SPY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238c47f7e10015fd7025',
      },
      {
        _id: '60cc238c47f7e10015fd7027',
        courseCode: 'HISTORY 4860',
        shortName: 'History 4860F/G - THE FRENCH REVOLUTION AND HUMAN RIGHTS',
        courseName: 'History 4860F/G - THE FRENCH REVOLUTION AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238c47f7e10015fd7027',
      },
      {
        _id: '60cc238d47f7e10015fd7029',
        courseCode: 'HISTORY 4861',
        shortName: 'History 4861F/G - THE HOLOCAUST AND HUMAN RIGHTS',
        courseName: 'History 4861F/G - THE HOLOCAUST AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238d47f7e10015fd7029',
      },
      {
        _id: '60cc238d47f7e10015fd702b',
        courseCode: 'HISTORY 4891',
        shortName:
          'History 4891E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4891E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238d47f7e10015fd702b',
      },
      {
        _id: '60cc238d47f7e10015fd702d',
        courseCode: 'HISTORY 4892',
        shortName:
          'History 4892E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4892E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238d47f7e10015fd702d',
      },
      {
        _id: '60cc238e47f7e10015fd702f',
        courseCode: 'HISTORY 4893',
        shortName:
          'History 4893E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4893E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238e47f7e10015fd702f',
      },
      {
        _id: '60cc238e47f7e10015fd7031',
        courseCode: 'HISTORY 4894',
        shortName:
          'History 4894E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4894E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238e47f7e10015fd7031',
      },
      {
        _id: '60cc238f47f7e10015fd7033',
        courseCode: 'HISTORY 4895',
        shortName:
          'History 4895E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4895E - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238f47f7e10015fd7033',
      },
      {
        _id: '60cc238f47f7e10015fd7035',
        courseCode: 'HISTORY 4896',
        shortName:
          'History 4896F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4896F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc238f47f7e10015fd7035',
      },
      {
        _id: '60cc239047f7e10015fd7037',
        courseCode: 'HISTORY 4897',
        shortName:
          'History 4897F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4897F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239047f7e10015fd7037',
      },
      {
        _id: '60cc239047f7e10015fd7039',
        courseCode: 'HISTORY 4898',
        shortName:
          'History 4898F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        courseName:
          'History 4898F/G - SELECTED TOPICS IN HISTORIOGRAPHY OR THEMATIC OR METHODOLOGICAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239047f7e10015fd7039',
      },
      {
        _id: '60cc239047f7e10015fd703b',
        courseCode: 'HISTORY 4901',
        shortName: 'History 4901E - DIRECTED READINGS IN HISTORY',
        courseName: 'History 4901E - DIRECTED READINGS IN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239047f7e10015fd703b',
      },
      {
        _id: '60cc239147f7e10015fd703d',
        courseCode: 'HISTORY 4902',
        shortName: 'History 4902F/G - DIRECTED READINGS IN HISTORY',
        courseName: 'History 4902F/G - DIRECTED READINGS IN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239147f7e10015fd703d',
      },
      {
        _id: '60cc239147f7e10015fd703f',
        courseCode: 'HISTORY 4903',
        shortName: 'History 4903E - SENIOR THESIS',
        courseName: 'History 4903E - SENIOR THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239147f7e10015fd703f',
      },
      {
        _id: '60cc239247f7e10015fd7041',
        courseCode: 'HISTORY 4927',
        shortName: 'History 4927E - SELECTED TOPICS',
        courseName: 'History 4927E - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239247f7e10015fd7041',
      },
      {
        _id: '60cc239247f7e10015fd7043',
        courseCode: 'HISTORY 4928',
        shortName: 'History 4928E - SELECTED TOPICS',
        courseName: 'History 4928E - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239247f7e10015fd7043',
      },
      {
        _id: '60cc239247f7e10015fd7045',
        courseCode: 'HISTORY 4929',
        shortName: 'History 4929E - SELECTED TOPICS',
        courseName: 'History 4929E - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239247f7e10015fd7045',
      },
      {
        _id: '60cc239247f7e10015fd7047',
        courseCode: 'HISTSCI 2151',
        shortName: 'History of Science 2151F/G - GREEK AND ROMAN MEDICINE',
        courseName: 'History of Science 2151F/G - GREEK AND ROMAN MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239247f7e10015fd7047',
      },
      {
        _id: '60cc239347f7e10015fd7049',
        courseCode: 'HISTSCI 2200',
        shortName: 'History of Science 2200E - HISTORY OF SCIENTIFIC THOUGHT',
        courseName: 'History of Science 2200E - HISTORY OF SCIENTIFIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239347f7e10015fd7049',
      },
      {
        _id: '60cc239347f7e10015fd704b',
        courseCode: 'HISTSCI 2220',
        shortName:
          'History of Science 2220 - INTRODUCTION TO THE HISTORY OF MEDICINE',
        courseName:
          'History of Science 2220 - INTRODUCTION TO THE HISTORY OF MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239347f7e10015fd704b',
      },
      {
        _id: '60cc239347f7e10015fd704d',
        courseCode: 'HISTSCI 4420',
        shortName:
          'History of Science 4420F/G - SPECIAL TOPICS IN THE HISTORY OF MEDICINE',
        courseName:
          'History of Science 4420F/G - SPECIAL TOPICS IN THE HISTORY OF MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239347f7e10015fd704d',
      },
      {
        _id: '60cc239447f7e10015fd704f',
        courseCode: 'HUMANECO 2222',
        shortName: 'Human Ecology 2222A/B - PROFESSIONAL PERSPECTIVES',
        courseName: 'Human Ecology 2222A/B - PROFESSIONAL PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239447f7e10015fd704f',
      },
      {
        _id: '60cc239447f7e10015fd7051',
        courseCode: 'HUMANECO 2266',
        shortName: 'Human Ecology 2266F/G - COMMUNICATIONS',
        courseName: 'Human Ecology 2266F/G - COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239447f7e10015fd7051',
      },
      {
        _id: '60cc239447f7e10015fd7053',
        courseCode: 'HUMANECO 3033',
        shortName: 'Human Ecology 3033A/B - DESIGN FOR HUMAN NEEDS',
        courseName: 'Human Ecology 3033A/B - DESIGN FOR HUMAN NEEDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239447f7e10015fd7053',
      },
      {
        _id: '60cc239447f7e10015fd7055',
        courseCode: 'HUMANECO 3338',
        shortName: 'Human Ecology 3338A/B - ISSUES IN HOUSING',
        courseName: 'Human Ecology 3338A/B - ISSUES IN HOUSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239447f7e10015fd7055',
      },
      {
        _id: '60cc239447f7e10015fd7057',
        courseCode: 'HUMANECO 3343',
        shortName:
          'Human Ecology 3343A/B - CONSUMER ECONOMICS AND RESOURCE MANAGEMENT',
        courseName:
          'Human Ecology 3343A/B - CONSUMER ECONOMICS AND RESOURCE MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239447f7e10015fd7057',
      },
      {
        _id: '60cc239547f7e10015fd7059',
        courseCode: 'HUMANECO 3349',
        shortName: 'Human Ecology 3349A/B - PRINCIPLES OF MANAGEMENT',
        courseName: 'Human Ecology 3349A/B - PRINCIPLES OF MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239547f7e10015fd7059',
      },
      {
        _id: '60cc239547f7e10015fd705b',
        courseCode: 'HUMANECO 4402',
        shortName: 'Human Ecology 4402A/B - SUPERVISED RESEARCH',
        courseName: 'Human Ecology 4402A/B - SUPERVISED RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239547f7e10015fd705b',
      },
      {
        _id: '60cc239547f7e10015fd705d',
        courseCode: 'HUMANECO 4411',
        shortName: 'Human Ecology 4411F/G - RESEARCH METHODOLOGY',
        courseName: 'Human Ecology 4411F/G - RESEARCH METHODOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239547f7e10015fd705d',
      },
      {
        _id: '60cc239547f7e10015fd705f',
        courseCode: 'HUMANRS 2800',
        shortName: 'Human Rights Studies 2800E - INTRODUCTION TO HUMAN RIGHTS',
        courseName: 'Human Rights Studies 2800E - INTRODUCTION TO HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239547f7e10015fd705f',
      },
      {
        _id: '60cc239647f7e10015fd7061',
        courseCode: 'HUMANRS 2900',
        shortName: 'Human Rights Studies 2900F/G - RIGHTS IN CANADA',
        courseName: 'Human Rights Studies 2900F/G - RIGHTS IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239647f7e10015fd7061',
      },
      {
        _id: '60cc239647f7e10015fd7063',
        courseCode: 'HUMANRS 2908',
        shortName: 'Human Rights Studies 2908F/G - SPECIAL TOPICS',
        courseName: 'Human Rights Studies 2908F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239647f7e10015fd7063',
      },
      {
        _id: '60cc239647f7e10015fd7065',
        courseCode: 'HUMANRS 2909',
        shortName: 'Human Rights Studies 2909F/G - SPECIAL TOPICS',
        courseName: 'Human Rights Studies 2909F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239647f7e10015fd7065',
      },
      {
        _id: '60cc239647f7e10015fd7067',
        courseCode: 'HUMANRS 3908',
        shortName: 'Human Rights Studies 3908F/G - SPECIAL TOPICS',
        courseName: 'Human Rights Studies 3908F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239647f7e10015fd7067',
      },
      {
        _id: '60cc239747f7e10015fd7069',
        courseCode: 'HUMANRS 3909',
        shortName: 'Human Rights Studies 3909F/G - SPECIAL TOPICS',
        courseName: 'Human Rights Studies 3909F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239747f7e10015fd7069',
      },
      {
        _id: '60cc239747f7e10015fd706b',
        courseCode: 'HUMANRS 4900',
        shortName: 'Human Rights Studies 4900F/G - CAPSTONE SEMINAR',
        courseName: 'Human Rights Studies 4900F/G - CAPSTONE SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239747f7e10015fd706b',
      },
      {
        _id: '60cc239747f7e10015fd706d',
        courseCode: 'HUMANRS 4901',
        shortName: 'Human Rights Studies 4901F/G - INDEPENDENT STUDY',
        courseName: 'Human Rights Studies 4901F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239747f7e10015fd706d',
      },
      {
        _id: '60cc239747f7e10015fd706f',
        courseCode: 'HUMANIT 2110',
        shortName: 'Humanities 2110F/G - INTRODUCTION TO THE WORLD OF ISLAM',
        courseName: 'Humanities 2110F/G - INTRODUCTION TO THE WORLD OF ISLAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239747f7e10015fd706f',
      },
      {
        _id: '60cc239747f7e10015fd7071',
        courseCode: 'HUMANIT 2111',
        shortName:
          'Humanities 2111F/G - INTRODUCTION TO THE WORLD OF ISLAM: CONTEMPORARY ISSUES',
        courseName:
          'Humanities 2111F/G - INTRODUCTION TO THE WORLD OF ISLAM: CONTEMPORARY ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239747f7e10015fd7071',
      },
      {
        _id: '60cc239847f7e10015fd7073',
        courseCode: 'HUMANIT 3001',
        shortName:
          'Humanities 3001A/B - EXPERIENTIAL LEARNING FOR THE HUMANITIES',
        courseName:
          'Humanities 3001A/B - EXPERIENTIAL LEARNING FOR THE HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239847f7e10015fd7073',
      },
      {
        _id: '60cc239847f7e10015fd7075',
        courseCode: 'INDIGSTU 1020',
        shortName:
          'Indigenous Studies 1020E - INTRODUCTION TO INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 1020E - INTRODUCTION TO INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239847f7e10015fd7075',
      },
      {
        _id: '60cc239847f7e10015fd7077',
        courseCode: 'INDIGSTU 2103',
        shortName:
          'Indigenous Studies 2103F/G - LUNAAPE LANGUAGE, CULTURE AND HISTORY',
        courseName:
          'Indigenous Studies 2103F/G - LUNAAPE LANGUAGE, CULTURE AND HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239847f7e10015fd7077',
      },
      {
        _id: '60cc239847f7e10015fd7079',
        courseCode: 'INDIGSTU 2104',
        shortName: 'Indigenous Studies 2104 - INTRODUCTORY MOHAWK LANGUAGE',
        courseName: 'Indigenous Studies 2104 - INTRODUCTORY MOHAWK LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239847f7e10015fd7079',
      },
      {
        _id: '60cc239947f7e10015fd707b',
        courseCode: 'INDIGSTU 2113',
        shortName: 'Indigenous Studies 2113 - ALGONQUIAN LANGUAGE AND CULTURE',
        courseName: 'Indigenous Studies 2113 - ALGONQUIAN LANGUAGE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239947f7e10015fd707b',
      },
      {
        _id: '60cc239947f7e10015fd707d',
        courseCode: 'INDIGSTU 2120',
        shortName: 'Indigenous Studies 2120 - INTRODUCTION TO ONEIDA LANGUAGE',
        courseName: 'Indigenous Studies 2120 - INTRODUCTION TO ONEIDA LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239947f7e10015fd707d',
      },
      {
        _id: '60cc239947f7e10015fd707f',
        courseCode: 'INDIGSTU 2203',
        shortName:
          'Indigenous Studies 2203F/G - INDIGENOUS PEOPLES, GLOBALIZATION, AND THE ENVIRONMENT',
        courseName:
          'Indigenous Studies 2203F/G - INDIGENOUS PEOPLES, GLOBALIZATION, AND THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239947f7e10015fd707f',
      },
      {
        _id: '60cc239947f7e10015fd7081',
        courseCode: 'INDIGSTU 2204',
        shortName:
          'Indigenous Studies 2204E - SELECTED TOPICS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 2204E - SELECTED TOPICS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239947f7e10015fd7081',
      },
      {
        _id: '60cc239a47f7e10015fd7083',
        courseCode: 'INDIGSTU 2205',
        shortName:
          'Indigenous Studies 2205E - SELECTED TOPICS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 2205E - SELECTED TOPICS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239a47f7e10015fd7083',
      },
      {
        _id: '60cc239a47f7e10015fd7085',
        courseCode: 'INDIGSTU 2211',
        shortName: 'Indigenous Studies 2211F/G - CULTURES OF THE CARIBBEAN',
        courseName: 'Indigenous Studies 2211F/G - CULTURES OF THE CARIBBEAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239a47f7e10015fd7085',
      },
      {
        _id: '60cc239a47f7e10015fd7087',
        courseCode: 'INDIGSTU 2212',
        shortName: 'Indigenous Studies 2212F/G - CULTURES OF THE PACIFIC',
        courseName: 'Indigenous Studies 2212F/G - CULTURES OF THE PACIFIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239a47f7e10015fd7087',
      },
      {
        _id: '60cc239a47f7e10015fd7089',
        courseCode: 'INDIGSTU 2213',
        shortName:
          'Indigenous Studies 2213F/G - HISTORICAL ISSUES: FROM PRE-CONTACT TO THE 1969 WHITE PAPER',
        courseName:
          'Indigenous Studies 2213F/G - HISTORICAL ISSUES: FROM PRE-CONTACT TO THE 1969 WHITE PAPER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239a47f7e10015fd7089',
      },
      {
        _id: '60cc239b47f7e10015fd708b',
        courseCode: 'INDIGSTU 2216',
        shortName:
          'Indigenous Studies 2216F/G - ANTHROPOLOGICAL PERSPECTIVES ON LATIN AMERICA',
        courseName:
          'Indigenous Studies 2216F/G - ANTHROPOLOGICAL PERSPECTIVES ON LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239b47f7e10015fd708b',
      },
      {
        _id: '60cc239b47f7e10015fd708e',
        courseCode: 'INDIGSTU 2233',
        shortName:
          'Indigenous Studies 2233F/G - ARCHAEOLOGY OF ONTARIO AND THE GREAT LAKES',
        courseName:
          'Indigenous Studies 2233F/G - ARCHAEOLOGY OF ONTARIO AND THE GREAT LAKES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239b47f7e10015fd708e',
      },
      {
        _id: '60cc239b47f7e10015fd7090',
        courseCode: 'INDIGSTU 2234',
        shortName: 'Indigenous Studies 2234F/G - ANDEAN PREHISTORY',
        courseName: 'Indigenous Studies 2234F/G - ANDEAN PREHISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239b47f7e10015fd7090',
      },
      {
        _id: '60cc239c47f7e10015fd7092',
        courseCode: 'INDIGSTU 2236',
        shortName:
          'Indigenous Studies 2236A/B - INTRODUCTION TO NORTH AMERICAN INDIGENOUS SPIRITUALITY',
        courseName:
          'Indigenous Studies 2236A/B - INTRODUCTION TO NORTH AMERICAN INDIGENOUS SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239c47f7e10015fd7092',
      },
      {
        _id: '60cc239c47f7e10015fd7094',
        courseCode: 'INDIGSTU 2251',
        shortName:
          'Indigenous Studies 2251F/G - SELECTED TOPICS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 2251F/G - SELECTED TOPICS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239c47f7e10015fd7094',
      },
      {
        _id: '60cc239c47f7e10015fd7096',
        courseCode: 'INDIGSTU 2253',
        shortName:
          'Indigenous Studies 2253A/B - ENDANGERED LANGUAGES AND REVITALIZATION',
        courseName:
          'Indigenous Studies 2253A/B - ENDANGERED LANGUAGES AND REVITALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239c47f7e10015fd7096',
      },
      {
        _id: '60cc239c47f7e10015fd7098',
        courseCode: 'INDIGSTU 2501',
        shortName: 'Indigenous Studies 2501F/G - IROQUOIAN ARTS',
        courseName: 'Indigenous Studies 2501F/G - IROQUOIAN ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239c47f7e10015fd7098',
      },
      {
        _id: '60cc239d47f7e10015fd709a',
        courseCode: 'INDIGSTU 2531',
        shortName:
          'Indigenous Studies 2531F/G - WOMEN IN FIRST NATIONS CULTURES',
        courseName:
          'Indigenous Studies 2531F/G - WOMEN IN FIRST NATIONS CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239d47f7e10015fd709a',
      },
      {
        _id: '60cc239d47f7e10015fd709c',
        courseCode: 'INDIGSTU 2601',
        shortName: 'Indigenous Studies 2601F/G - INDIGENOUS ENVIRONMENTS',
        courseName: 'Indigenous Studies 2601F/G - INDIGENOUS ENVIRONMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239d47f7e10015fd709c',
      },
      {
        _id: '60cc239d47f7e10015fd709e',
        courseCode: 'INDIGSTU 2700',
        shortName:
          'Indigenous Studies 2700A/B - MOHAWK METAPHOR: WHAT WE SAY – WHAT WE MEAN',
        courseName:
          'Indigenous Studies 2700A/B - MOHAWK METAPHOR: WHAT WE SAY – WHAT WE MEAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239d47f7e10015fd709e',
      },
      {
        _id: '60cc239d47f7e10015fd70a0',
        courseCode: 'INDIGSTU 2901',
        shortName:
          'Indigenous Studies 2901E - THE FIRST NATIONS IN CANADIAN HISTORY',
        courseName:
          'Indigenous Studies 2901E - THE FIRST NATIONS IN CANADIAN HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239d47f7e10015fd70a0',
      },
      {
        _id: '60cc239e47f7e10015fd70a2',
        courseCode: 'INDIGSTU 2919',
        shortName:
          'Indigenous Studies 2919F/G - THE IROQUOIANS: THEIR HISTORY AND CULTURE',
        courseName:
          'Indigenous Studies 2919F/G - THE IROQUOIANS: THEIR HISTORY AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239e47f7e10015fd70a2',
      },
      {
        _id: '60cc239e47f7e10015fd70a4',
        courseCode: 'INDIGSTU 3001',
        shortName:
          'Indigenous Studies 3001F/G - SPECIAL TOPICS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 3001F/G - SPECIAL TOPICS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239e47f7e10015fd70a4',
      },
      {
        _id: '60cc239e47f7e10015fd70a6',
        courseCode: 'INDIGSTU 3002',
        shortName:
          'Indigenous Studies 3002E - SPECIAL TOPICS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 3002E - SPECIAL TOPICS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239e47f7e10015fd70a6',
      },
      {
        _id: '60cc239e47f7e10015fd70a8',
        courseCode: 'INDIGSTU 3011',
        shortName:
          'Indigenous Studies 3011F/G - SUPERVISED READINGS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 3011F/G - SUPERVISED READINGS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239e47f7e10015fd70a8',
      },
      {
        _id: '60cc239e47f7e10015fd70aa',
        courseCode: 'INDIGSTU 3104',
        shortName: 'Indigenous Studies 3104 - INTERMEDIATE MOHAWK LANGUAGE',
        courseName: 'Indigenous Studies 3104 - INTERMEDIATE MOHAWK LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239e47f7e10015fd70aa',
      },
      {
        _id: '60cc239f47f7e10015fd70ac',
        courseCode: 'INDIGSTU 3140',
        shortName:
          'Indigenous Studies 3140F/G - INDIGENOUS KNOWLEDGE AND TRADITIONS',
        courseName:
          'Indigenous Studies 3140F/G - INDIGENOUS KNOWLEDGE AND TRADITIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239f47f7e10015fd70ac',
      },
      {
        _id: '60cc239f47f7e10015fd70ae',
        courseCode: 'INDIGSTU 3142',
        shortName:
          'Indigenous Studies 3142F/G - DOING RESEARCH WITH INDIGENOUS COMMUNITIES (IN A GOOD WAY)',
        courseName:
          'Indigenous Studies 3142F/G - DOING RESEARCH WITH INDIGENOUS COMMUNITIES (IN A GOOD WAY)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239f47f7e10015fd70ae',
      },
      {
        _id: '60cc239f47f7e10015fd70b0',
        courseCode: 'INDIGSTU 3306',
        shortName: 'Indigenous Studies 3306A/B - LANGUAGE REVITALIZATION',
        courseName: 'Indigenous Studies 3306A/B - LANGUAGE REVITALIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239f47f7e10015fd70b0',
      },
      {
        _id: '60cc239f47f7e10015fd70b2',
        courseCode: 'INDIGSTU 3722',
        shortName:
          'Indigenous Studies 3722F/G - INDIGENOUS POLITICAL AND LEGAL ISSUES',
        courseName:
          'Indigenous Studies 3722F/G - INDIGENOUS POLITICAL AND LEGAL ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc239f47f7e10015fd70b2',
      },
      {
        _id: '60cc23a047f7e10015fd70b4',
        courseCode: 'INDIGSTU 3880',
        shortName:
          'Indigenous Studies 3880F/G - INDIGENOUS LITERATURES OF TURTLE ISLAND',
        courseName:
          'Indigenous Studies 3880F/G - INDIGENOUS LITERATURES OF TURTLE ISLAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a047f7e10015fd70b4',
      },
      {
        _id: '60cc23a047f7e10015fd70b6',
        courseCode: 'INDIGSTU 4001',
        shortName:
          'Indigenous Studies 4001F/G - ADVANCED SPECIAL TOPICS IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 4001F/G - ADVANCED SPECIAL TOPICS IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a047f7e10015fd70b6',
      },
      {
        _id: '60cc23a047f7e10015fd70b8',
        courseCode: 'INDIGSTU 4011',
        shortName: 'Indigenous Studies 4011F/G - SUPERVISED READING',
        courseName: 'Indigenous Studies 4011F/G - SUPERVISED READING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a047f7e10015fd70b8',
      },
      {
        _id: '60cc23a047f7e10015fd70ba',
        courseCode: 'INDIGSTU 4022',
        shortName:
          'Indigenous Studies 4022E - FIELD SCHOOL IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 4022E - FIELD SCHOOL IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a047f7e10015fd70ba',
      },
      {
        _id: '60cc23a147f7e10015fd70bc',
        courseCode: 'INDIGSTU 4023',
        shortName:
          'Indigenous Studies 4023F/G - COMMUNITY-BASED RESEARCH IN INDIGENOUS STUDIES',
        courseName:
          'Indigenous Studies 4023F/G - COMMUNITY-BASED RESEARCH IN INDIGENOUS STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a147f7e10015fd70bc',
      },
      {
        _id: '60cc23a147f7e10015fd70be',
        courseCode: 'INDIGSTU 4903',
        shortName:
          'Indigenous Studies 4903F/G - INDIGENOUS RESEARCH AND METHODOLOGIES',
        courseName:
          'Indigenous Studies 4903F/G - INDIGENOUS RESEARCH AND METHODOLOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a147f7e10015fd70be',
      },
      {
        _id: '60cc23a147f7e10015fd70c0',
        courseCode: 'IE 2297',
        shortName:
          'Integrated Engineering 2297A/B - INTEGRATED SYSTEM ENGINEERING AND DESIGN',
        courseName:
          'Integrated Engineering 2297A/B - INTEGRATED SYSTEM ENGINEERING AND DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a147f7e10015fd70c0',
      },
      {
        _id: '60cc23a147f7e10015fd70c2',
        courseCode: 'IE 4499',
        shortName:
          'Integrated Engineering 4499 - INTERDISCIPLINARY ENGINEERING DESIGN PROJECT',
        courseName:
          'Integrated Engineering 4499 - INTERDISCIPLINARY ENGINEERING DESIGN PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a147f7e10015fd70c2',
      },
      {
        _id: '60cc23a247f7e10015fd70c4',
        courseCode: 'INTEGSCI 1000',
        shortName:
          'Integrated Science 1000Z - EVOLUTION OF THE SCIENTIFIC PROCESS',
        courseName:
          'Integrated Science 1000Z - EVOLUTION OF THE SCIENTIFIC PROCESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a247f7e10015fd70c4',
      },
      {
        _id: '60cc23a247f7e10015fd70c6',
        courseCode: 'INTEGSCI 1001',
        shortName:
          'Integrated Science 1001X - EXPLORING THE LANDSCAPE OF SCIENCE',
        courseName:
          'Integrated Science 1001X - EXPLORING THE LANDSCAPE OF SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a247f7e10015fd70c6',
      },
      {
        _id: '60cc23a247f7e10015fd70c8',
        courseCode: 'INTEGSCI 2001',
        shortName:
          'Integrated Science 2001F - SUSTAINABILITY AND THE ENVIRONMENT',
        courseName:
          'Integrated Science 2001F - SUSTAINABILITY AND THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a247f7e10015fd70c8',
      },
      {
        _id: '60cc23a247f7e10015fd70ca',
        courseCode: 'INTEGSCI 2002',
        shortName:
          'Integrated Science 2002B - BIG DATA AND MATHEMATICAL MODELLING',
        courseName:
          'Integrated Science 2002B - BIG DATA AND MATHEMATICAL MODELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a247f7e10015fd70ca',
      },
      {
        _id: '60cc23a247f7e10015fd70cc',
        courseCode: 'INTEGSCI 3001',
        shortName: 'Integrated Science 3001F/G - MATERIALS AND BIOMATERIALS',
        courseName: 'Integrated Science 3001F/G - MATERIALS AND BIOMATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a247f7e10015fd70cc',
      },
      {
        _id: '60cc23a347f7e10015fd70ce',
        courseCode: 'INTEGSCI 3002',
        shortName: 'Integrated Science 3002A/B - SCIENCE IN THE COMMUNITY',
        courseName: 'Integrated Science 3002A/B - SCIENCE IN THE COMMUNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a347f7e10015fd70ce',
      },
      {
        _id: '60cc23a347f7e10015fd70d0',
        courseCode: 'INTEGSCI 4001',
        shortName: 'Integrated Science 4001Y - PEER MENTORING AND LEADERSHIP',
        courseName: 'Integrated Science 4001Y - PEER MENTORING AND LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a347f7e10015fd70d0',
      },
      {
        _id: '60cc23a347f7e10015fd70d2',
        courseCode: 'INTEGSCI 4999',
        shortName: 'Integrated Science 4999E - INTEGRATED RESEARCH PROJECT',
        courseName: 'Integrated Science 4999E - INTEGRATED RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a347f7e10015fd70d2',
      },
      {
        _id: '60cc23a447f7e10015fd70d7',
        courseCode: 'ICC 3600',
        shortName:
          'Intercultural Communications 3600F/G/Z - IMMERSED IN THE EXPERIENCE: LANGUAGE AND CULTURE ABROAD',
        courseName:
          'Intercultural Communications 3600F/G/Z - IMMERSED IN THE EXPERIENCE: LANGUAGE AND CULTURE ABROAD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a447f7e10015fd70d7',
      },
      {
        _id: '60cc23a447f7e10015fd70da',
        courseCode: 'INTERDIS 0010',
        shortName:
          'Interdisciplinary Studies 0010A/B - SUCCESS IN THE UNIVERSITY ENVIRONMENT',
        courseName:
          'Interdisciplinary Studies 0010A/B - SUCCESS IN THE UNIVERSITY ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a447f7e10015fd70da',
      },
      {
        _id: '60cc23a447f7e10015fd70dc',
        courseCode: 'INTERDIS 0015',
        shortName:
          'Interdisciplinary Studies 0015 - SUCCESS IN THE UNIVERSITY ENVIRONMENT',
        courseName:
          'Interdisciplinary Studies 0015 - SUCCESS IN THE UNIVERSITY ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a447f7e10015fd70dc',
      },
      {
        _id: '60cc23a547f7e10015fd70de',
        courseCode: 'INTERDIS 1000',
        shortName:
          'Interdisciplinary Studies 1000F/G - INTRO TO GLOBAL CHALLENGES',
        courseName:
          'Interdisciplinary Studies 1000F/G - INTRO TO GLOBAL CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a547f7e10015fd70de',
      },
      {
        _id: '60cc23a547f7e10015fd70e0',
        courseCode: 'INTERDIS 1001',
        shortName:
          'Interdisciplinary Studies 1001E - FIRST YEAR SEMINAR: INTERDISCIPLINARY PERSPECTIVES',
        courseName:
          'Interdisciplinary Studies 1001E - FIRST YEAR SEMINAR: INTERDISCIPLINARY PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a547f7e10015fd70e0',
      },
      {
        _id: '60cc23a547f7e10015fd70e2',
        courseCode: 'INTERDIS 1015',
        shortName:
          'Interdisciplinary Studies 1015F/G - INTERDISCIPLINARY PERSPECTIVES',
        courseName:
          'Interdisciplinary Studies 1015F/G - INTERDISCIPLINARY PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a547f7e10015fd70e2',
      },
      {
        _id: '60cc23a547f7e10015fd70e4',
        courseCode: 'INTERDIS 1021',
        shortName:
          'Interdisciplinary Studies 1021E - INQUIRY, INTERPRETATION, RHETORIC',
        courseName:
          'Interdisciplinary Studies 1021E - INQUIRY, INTERPRETATION, RHETORIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a547f7e10015fd70e4',
      },
      {
        _id: '60cc23a647f7e10015fd70e6',
        courseCode: 'INTERDIS 1100',
        shortName:
          "Interdisciplinary Studies 1100E - KING'S ONE - URGENT QUESTIONS",
        courseName:
          "Interdisciplinary Studies 1100E - KING'S ONE - URGENT QUESTIONS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a647f7e10015fd70e6',
      },
      {
        _id: '60cc23a647f7e10015fd70e8',
        courseCode: 'INTERDIS 1101',
        shortName: 'Interdisciplinary Studies 1101F/G - APPROACHES TO RESEARCH',
        courseName:
          'Interdisciplinary Studies 1101F/G - APPROACHES TO RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a647f7e10015fd70e8',
      },
      {
        _id: '60cc23a647f7e10015fd70ea',
        courseCode: 'INTERDIS 1102',
        shortName:
          'Interdisciplinary Studies 1102F/G - BUILDING A RESEARCH PROJECT',
        courseName:
          'Interdisciplinary Studies 1102F/G - BUILDING A RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a647f7e10015fd70ea',
      },
      {
        _id: '60cc23a647f7e10015fd70ec',
        courseCode: 'INTERDIS 1103',
        shortName:
          'Interdisciplinary Studies 1103F/G - THE PURSUIT OF HAPPINESS',
        courseName:
          'Interdisciplinary Studies 1103F/G - THE PURSUIT OF HAPPINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a647f7e10015fd70ec',
      },
      {
        _id: '60cc23a747f7e10015fd70ee',
        courseCode: 'INTERDIS 1200',
        shortName:
          'Interdisciplinary Studies 1200A/B - BRESCIA BOLD: LIVING, LEARNING, LEADING',
        courseName:
          'Interdisciplinary Studies 1200A/B - BRESCIA BOLD: LIVING, LEARNING, LEADING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a747f7e10015fd70ee',
      },
      {
        _id: '60cc23a747f7e10015fd70f0',
        courseCode: 'INTERDIS 2100',
        shortName: 'Interdisciplinary Studies 2100A/B - WHAT IS CANADA?',
        courseName: 'Interdisciplinary Studies 2100A/B - WHAT IS CANADA?',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a747f7e10015fd70f0',
      },
      {
        _id: '60cc23a747f7e10015fd70f2',
        courseCode: 'INTERDIS 2115',
        shortName:
          'Interdisciplinary Studies 2115F/G - INTERDISCIPLINARY PERSPECTIVE II',
        courseName:
          'Interdisciplinary Studies 2115F/G - INTERDISCIPLINARY PERSPECTIVE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a747f7e10015fd70f2',
      },
      {
        _id: '60cc23a747f7e10015fd70f4',
        courseCode: 'INTERDIS 2200',
        shortName: 'Interdisciplinary Studies 2200E - ORIGINS OF IDEAS',
        courseName: 'Interdisciplinary Studies 2200E - ORIGINS OF IDEAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a747f7e10015fd70f4',
      },
      {
        _id: '60cc23a747f7e10015fd70f6',
        courseCode: 'INTERDIS 2201',
        shortName: 'Interdisciplinary Studies 2201F/G - RESEARCH PLACEMENT',
        courseName: 'Interdisciplinary Studies 2201F/G - RESEARCH PLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a747f7e10015fd70f6',
      },
      {
        _id: '60cc23a847f7e10015fd70f8',
        courseCode: 'INTERDIS 2210',
        shortName:
          'Interdisciplinary Studies 2210F/G - STUDIES IN COMMUNITY-BASED LEARNING',
        courseName:
          'Interdisciplinary Studies 2210F/G - STUDIES IN COMMUNITY-BASED LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a847f7e10015fd70f8',
      },
      {
        _id: '60cc23a847f7e10015fd70fa',
        courseCode: 'INTERDIS 2220',
        shortName:
          'Interdisciplinary Studies 2220F/G - PERSPECTIVES ON READING',
        courseName:
          'Interdisciplinary Studies 2220F/G - PERSPECTIVES ON READING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a847f7e10015fd70fa',
      },
      {
        _id: '60cc23a847f7e10015fd70fc',
        courseCode: 'INTERDIS 2230',
        shortName:
          'Interdisciplinary Studies 2230F/G - PERSPECTIVES ON COMMUNICATION',
        courseName:
          'Interdisciplinary Studies 2230F/G - PERSPECTIVES ON COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a847f7e10015fd70fc',
      },
      {
        _id: '60cc23a847f7e10015fd70fe',
        courseCode: 'INTERDIS 2252',
        shortName:
          'Interdisciplinary Studies 2252F/G - INTRODUCTION TO INTERDISCIPLINARY QUALITATIVE RESEARCH METHODS',
        courseName:
          'Interdisciplinary Studies 2252F/G - INTRODUCTION TO INTERDISCIPLINARY QUALITATIVE RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a847f7e10015fd70fe',
      },
      {
        _id: '60cc23a947f7e10015fd7100',
        courseCode: 'INTERDIS 3301',
        shortName:
          'Interdisciplinary Studies 3301F/G/Z - MAJOR RESEARCH PROJECT',
        courseName:
          'Interdisciplinary Studies 3301F/G/Z - MAJOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a947f7e10015fd7100',
      },
      {
        _id: '60cc23a947f7e10015fd7102',
        courseCode: 'INTERDIS 3330',
        shortName:
          'Interdisciplinary Studies 3330 - COMMUNITY AND SOCIETY IN CONTEMPORARY CUBA',
        courseName:
          'Interdisciplinary Studies 3330 - COMMUNITY AND SOCIETY IN CONTEMPORARY CUBA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a947f7e10015fd7102',
      },
      {
        _id: '60cc23a947f7e10015fd7104',
        courseCode: 'INTERDIS 3495',
        shortName: 'Interdisciplinary Studies 3495A/B/Y - INTERNSHIP',
        courseName: 'Interdisciplinary Studies 3495A/B/Y - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a947f7e10015fd7104',
      },
      {
        _id: '60cc23a947f7e10015fd7106',
        courseCode: 'INTERDIS 3500',
        shortName: 'Interdisciplinary Studies 3500A/B - PROGRAM EVALUATION',
        courseName: 'Interdisciplinary Studies 3500A/B - PROGRAM EVALUATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23a947f7e10015fd7106',
      },
      {
        _id: '60cc23aa47f7e10015fd7108',
        courseCode: 'INTERDIS 3991',
        shortName:
          'Interdisciplinary Studies 3991A/B/Y - INTERNSHIP IN THE SOCIAL SCIENCES',
        courseName:
          'Interdisciplinary Studies 3991A/B/Y - INTERNSHIP IN THE SOCIAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23aa47f7e10015fd7108',
      },
      {
        _id: '60cc23aa47f7e10015fd710a',
        courseCode: 'INTERDIS 3992',
        shortName:
          'Interdisciplinary Studies 3992A/B/Y - INTERNSHIP IN THE ARTS AND HUMANITIES',
        courseName:
          'Interdisciplinary Studies 3992A/B/Y - INTERNSHIP IN THE ARTS AND HUMANITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23aa47f7e10015fd710a',
      },
      {
        _id: '60cc23aa47f7e10015fd710c',
        courseCode: 'INTERDIS 4410',
        shortName:
          'Interdisciplinary Studies 4410F/G - RESPONDING TO THE EXPERIENCES OF COMMUNITY-BASED LEARNING',
        courseName:
          'Interdisciplinary Studies 4410F/G - RESPONDING TO THE EXPERIENCES OF COMMUNITY-BASED LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23aa47f7e10015fd710c',
      },
      {
        _id: '60cc23aa47f7e10015fd710e',
        courseCode: 'INTERDIS 4900',
        shortName: 'Interdisciplinary Studies 4900E - RESEARCH COMMUNICATION',
        courseName: 'Interdisciplinary Studies 4900E - RESEARCH COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23aa47f7e10015fd710e',
      },
      {
        _id: '60cc23ab47f7e10015fd7110',
        courseCode: 'INTREL 2703',
        shortName:
          'International Relations 2703F/G - HISTORY OF THE GLOBAL ORDER SINCE 1945',
        courseName:
          'International Relations 2703F/G - HISTORY OF THE GLOBAL ORDER SINCE 1945',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ab47f7e10015fd7110',
      },
      {
        _id: '60cc23ab47f7e10015fd7112',
        courseCode: 'INTREL 2704',
        shortName:
          'International Relations 2704F/G - CURRENT AFFAIRS IN WORLD POLITICS',
        courseName:
          'International Relations 2704F/G - CURRENT AFFAIRS IN WORLD POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ab47f7e10015fd7112',
      },
      {
        _id: '60cc23ab47f7e10015fd7114',
        courseCode: 'INTREL 4703',
        shortName:
          'International Relations 4703F/G - CONTEMPORARY CHALLENGES IN THE GLOBAL COMMUNITY I',
        courseName:
          'International Relations 4703F/G - CONTEMPORARY CHALLENGES IN THE GLOBAL COMMUNITY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ab47f7e10015fd7114',
      },
      {
        _id: '60cc23ab47f7e10015fd7116',
        courseCode: 'INTREL 4704',
        shortName:
          'International Relations 4704F/G - CONTEMPORARY CHALLENGES IN THE GLOBAL COMMUNITY II',
        courseName:
          'International Relations 4704F/G - CONTEMPORARY CHALLENGES IN THE GLOBAL COMMUNITY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ab47f7e10015fd7116',
      },
      {
        _id: '60cc23ab47f7e10015fd7118',
        courseCode: 'ITALIAN 1030',
        shortName: 'Italian 1030 - ITALIAN FOR BEGINNERS',
        courseName: 'Italian 1030 - ITALIAN FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ab47f7e10015fd7118',
      },
      {
        _id: '60cc23ac47f7e10015fd711a',
        courseCode: 'ITALIAN 1033',
        shortName:
          'Italian 1033 - ITALIAN FOR BEGINNERS AND OUR ITALIAN-CANADIAN STORIES',
        courseName:
          'Italian 1033 - ITALIAN FOR BEGINNERS AND OUR ITALIAN-CANADIAN STORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ac47f7e10015fd711a',
      },
      {
        _id: '60cc23ac47f7e10015fd711c',
        courseCode: 'ITALIAN 1045',
        shortName: 'Italian 1045A/B - ITALIAN FOR TRAVELERS 1',
        courseName: 'Italian 1045A/B - ITALIAN FOR TRAVELERS 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ac47f7e10015fd711c',
      },
      {
        _id: '60cc23ac47f7e10015fd711e',
        courseCode: 'ITALIAN 1046',
        shortName: 'Italian 1046A/B - ITALIAN FOR TRAVELERS 2',
        courseName: 'Italian 1046A/B - ITALIAN FOR TRAVELERS 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ac47f7e10015fd711e',
      },
      {
        _id: '60cc23ac47f7e10015fd7120',
        courseCode: 'ITALIAN 2100',
        shortName: 'Italian 2100A/B - STORIES OF ITALIAN CANADIANS',
        courseName: 'Italian 2100A/B - STORIES OF ITALIAN CANADIANS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ac47f7e10015fd7120',
      },
      {
        _id: '60cc23ad47f7e10015fd7122',
        courseCode: 'ITALIAN 2200',
        shortName: 'Italian 2200 - INTERMEDIATE ITALIAN',
        courseName: 'Italian 2200 - INTERMEDIATE ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ad47f7e10015fd7122',
      },
      {
        _id: '60cc23ad47f7e10015fd7124',
        courseCode: 'ITALIAN 2202',
        shortName: 'Italian 2202X - INTERMEDIATE ITALIAN IN ITALY',
        courseName: 'Italian 2202X - INTERMEDIATE ITALIAN IN ITALY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ad47f7e10015fd7124',
      },
      {
        _id: '60cc23ad47f7e10015fd7126',
        courseCode: 'ITALIAN 2215',
        shortName: 'Italian 2215F/G - EXPLORING ITALIAN CULTURE',
        courseName: 'Italian 2215F/G - EXPLORING ITALIAN CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ad47f7e10015fd7126',
      },
      {
        _id: '60cc23ae47f7e10015fd7128',
        courseCode: 'ITALIAN 2220',
        shortName: 'Italian 2220A/B - ITALIAN CONVERSATION',
        courseName: 'Italian 2220A/B - ITALIAN CONVERSATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ae47f7e10015fd7128',
      },
      {
        _id: '60cc23ae47f7e10015fd712a',
        courseCode: 'ITALIAN 2222',
        shortName:
          'Italian 2222A/B - MADE IN ITALY: BUSINESS, FASHION, SPORT AND THE ARTS',
        courseName:
          'Italian 2222A/B - MADE IN ITALY: BUSINESS, FASHION, SPORT AND THE ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ae47f7e10015fd712a',
      },
      {
        _id: '60cc23ae47f7e10015fd712c',
        courseCode: 'ITALIAN 2240',
        shortName: 'Italian 2240F/G - ITALIAN JOURNEYS',
        courseName: 'Italian 2240F/G - ITALIAN JOURNEYS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ae47f7e10015fd712c',
      },
      {
        _id: '60cc23ae47f7e10015fd712e',
        courseCode: 'ITALIAN 2241',
        shortName: 'Italian 2241F/G - ITALIAN POPULAR CINEMA',
        courseName: 'Italian 2241F/G - ITALIAN POPULAR CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ae47f7e10015fd712e',
      },
      {
        _id: '60cc23ae47f7e10015fd7130',
        courseCode: 'ITALIAN 2242',
        shortName: 'Italian 2242F/G - ROME: THE ETERNAL CITY',
        courseName: 'Italian 2242F/G - ROME: THE ETERNAL CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ae47f7e10015fd7130',
      },
      {
        _id: '60cc23af47f7e10015fd7132',
        courseCode: 'ITALIAN 2243',
        shortName: 'Italian 2243A/B - ITALIAN OPERA: WORDS IN MUSIC',
        courseName: 'Italian 2243A/B - ITALIAN OPERA: WORDS IN MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23af47f7e10015fd7132',
      },
      {
        _id: '60cc23af47f7e10015fd7134',
        courseCode: 'ITALIAN 2260',
        shortName:
          'Italian 2260F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        courseName:
          'Italian 2260F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23af47f7e10015fd7134',
      },
      {
        _id: '60cc23af47f7e10015fd7136',
        courseCode: 'ITALIAN 2261',
        shortName:
          'Italian 2261F/G - SPECIAL TOPICS IN  ITALIAN LITERATURE & CULTURE',
        courseName:
          'Italian 2261F/G - SPECIAL TOPICS IN  ITALIAN LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23af47f7e10015fd7136',
      },
      {
        _id: '60cc23af47f7e10015fd7138',
        courseCode: 'ITALIAN 2262',
        shortName:
          'Italian 2262F/G - SPECIAL TOPICS IN ITALIAN LITERATURE & CULTURE',
        courseName:
          'Italian 2262F/G - SPECIAL TOPICS IN ITALIAN LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23af47f7e10015fd7138',
      },
      {
        _id: '60cc23b047f7e10015fd713a',
        courseCode: 'ITALIAN 2267',
        shortName:
          'Italian 2267F/G - SPECIAL TOPICS IN ITALIAN LITERATURE & CULTURE',
        courseName:
          'Italian 2267F/G - SPECIAL TOPICS IN ITALIAN LITERATURE & CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b047f7e10015fd713a',
      },
      {
        _id: '60cc23b047f7e10015fd713c',
        courseCode: 'ITALIAN 2280',
        shortName: 'Italian 2280A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 2280A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b047f7e10015fd713c',
      },
      {
        _id: '60cc23b047f7e10015fd713e',
        courseCode: 'ITALIAN 2281',
        shortName: 'Italian 2281A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 2281A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b047f7e10015fd713e',
      },
      {
        _id: '60cc23b047f7e10015fd7140',
        courseCode: 'ITALIAN 2282',
        shortName: 'Italian 2282A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 2282A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b047f7e10015fd7140',
      },
      {
        _id: '60cc23b047f7e10015fd7142',
        courseCode: 'ITALIAN 2283',
        shortName: 'Italian 2283A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 2283A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b047f7e10015fd7142',
      },
      {
        _id: '60cc23b147f7e10015fd7144',
        courseCode: 'ITALIAN 2284',
        shortName: 'Italian 2284A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 2284A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b147f7e10015fd7144',
      },
      {
        _id: '60cc23b147f7e10015fd7146',
        courseCode: 'ITALIAN 2500',
        shortName:
          'Italian 2500F/G - BRIDGING CLASSROOM AND COMMUNITY: LANGUAGES AND CULTURES IN ACTION',
        courseName:
          'Italian 2500F/G - BRIDGING CLASSROOM AND COMMUNITY: LANGUAGES AND CULTURES IN ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b147f7e10015fd7146',
      },
      {
        _id: '60cc23b147f7e10015fd7148',
        courseCode: 'ITALIAN 3040',
        shortName:
          'Italian 3040A/B - STUDIES AT RONDINE CITADEL OF PEACE IN ITALY',
        courseName:
          'Italian 3040A/B - STUDIES AT RONDINE CITADEL OF PEACE IN ITALY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b147f7e10015fd7148',
      },
      {
        _id: '60cc23b147f7e10015fd714a',
        courseCode: 'ITALIAN 3300',
        shortName: 'Italian 3300 - ADVANCED ITALIAN',
        courseName: 'Italian 3300 - ADVANCED ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b147f7e10015fd714a',
      },
      {
        _id: '60cc23b247f7e10015fd714c',
        courseCode: 'ITALIAN 3320',
        shortName: 'Italian 3320A/B - ADVANCED ITALIAN CONVERSATIONS',
        courseName: 'Italian 3320A/B - ADVANCED ITALIAN CONVERSATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b247f7e10015fd714c',
      },
      {
        _id: '60cc23b247f7e10015fd714e',
        courseCode: 'ITALIAN 3328',
        shortName:
          'Italian 3328F/G - MASTERPIECES OF CONTEMPORARY ITALIAN LITERTURE',
        courseName:
          'Italian 3328F/G - MASTERPIECES OF CONTEMPORARY ITALIAN LITERTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b247f7e10015fd714e',
      },
      {
        _id: '60cc23b247f7e10015fd7150',
        courseCode: 'ITALIAN 3338',
        shortName: 'Italian 3338F/G - BOOKS ON THE BIG SCREEN',
        courseName: 'Italian 3338F/G - BOOKS ON THE BIG SCREEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b247f7e10015fd7150',
      },
      {
        _id: '60cc23b247f7e10015fd7152',
        courseCode: 'ITALIAN 3350',
        shortName:
          'Italian 3350F/G - MASTERPIECES OF ITALIAN LITERATURE: FROM THE MIDDLE AGES TO ROMANTICISM',
        courseName:
          'Italian 3350F/G - MASTERPIECES OF ITALIAN LITERATURE: FROM THE MIDDLE AGES TO ROMANTICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b247f7e10015fd7152',
      },
      {
        _id: '60cc23b347f7e10015fd7154',
        courseCode: 'ITALIAN 3352',
        shortName:
          "Italian 3352F/G - FROM COMMEDIA DELL'ARTE TO MODERN THEATRE",
        courseName:
          "Italian 3352F/G - FROM COMMEDIA DELL'ARTE TO MODERN THEATRE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b347f7e10015fd7154',
      },
      {
        _id: '60cc23b347f7e10015fd7156',
        courseCode: 'ITALIAN 3360',
        shortName:
          'Italian 3360F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        courseName:
          'Italian 3360F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b347f7e10015fd7156',
      },
      {
        _id: '60cc23b347f7e10015fd7158',
        courseCode: 'ITALIAN 3361',
        shortName:
          'Italian 3361F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        courseName:
          'Italian 3361F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b347f7e10015fd7158',
      },
      {
        _id: '60cc23b347f7e10015fd715a',
        courseCode: 'ITALIAN 3362',
        shortName:
          'Italian 3362F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        courseName:
          'Italian 3362F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b347f7e10015fd715a',
      },
      {
        _id: '60cc23b347f7e10015fd715c',
        courseCode: 'ITALIAN 3363',
        shortName:
          'Italian 3363F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        courseName:
          'Italian 3363F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b347f7e10015fd715c',
      },
      {
        _id: '60cc23b447f7e10015fd715e',
        courseCode: 'ITALIAN 3380',
        shortName: 'Italian 3380A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 3380A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b447f7e10015fd715e',
      },
      {
        _id: '60cc23b447f7e10015fd7160',
        courseCode: 'ITALIAN 3381',
        shortName: 'Italian 3381A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 3381A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b447f7e10015fd7160',
      },
      {
        _id: '60cc23b447f7e10015fd7162',
        courseCode: 'ITALIAN 3382',
        shortName: 'Italian 3382A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 3382A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b447f7e10015fd7162',
      },
      {
        _id: '60cc23b447f7e10015fd7164',
        courseCode: 'ITALIAN 3600',
        shortName: 'Italian 3600F/G/Z - INTERNSHIP IN ITALIAN',
        courseName: 'Italian 3600F/G/Z - INTERNSHIP IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b447f7e10015fd7164',
      },
      {
        _id: '60cc23b547f7e10015fd7166',
        courseCode: 'ITALIAN 4400',
        shortName:
          'Italian 4400A/B - FOURTH-YEAR ITALIAN:  LANGUAGE, HISTORY AND CULTURE',
        courseName:
          'Italian 4400A/B - FOURTH-YEAR ITALIAN:  LANGUAGE, HISTORY AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b547f7e10015fd7166',
      },
      {
        _id: '60cc23b547f7e10015fd7168',
        courseCode: 'ITALIAN 4401',
        shortName: 'Italian 4401F/G - RESEARCH SEMINAR',
        courseName: 'Italian 4401F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b547f7e10015fd7168',
      },
      {
        _id: '60cc23b547f7e10015fd716a',
        courseCode: 'ITALIAN 4402',
        shortName: 'Italian 4402F/G - RESEARCH SEMINAR',
        courseName: 'Italian 4402F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b547f7e10015fd716a',
      },
      {
        _id: '60cc23b547f7e10015fd716c',
        courseCode: 'ITALIAN 4403',
        shortName: 'Italian 4403F/G - RESEARCH SEMINAR',
        courseName: 'Italian 4403F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b547f7e10015fd716c',
      },
      {
        _id: '60cc23b647f7e10015fd716e',
        courseCode: 'ITALIAN 4404',
        shortName: 'Italian 4404F/G - RESEARCH SEMINAR',
        courseName: 'Italian 4404F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b647f7e10015fd716e',
      },
      {
        _id: '60cc23b647f7e10015fd7170',
        courseCode: 'ITALIAN 4405',
        shortName: 'Italian 4405F/G - RESEARCH SEMINAR',
        courseName: 'Italian 4405F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b647f7e10015fd7170',
      },
      {
        _id: '60cc23b647f7e10015fd7172',
        courseCode: 'ITALIAN 4406',
        shortName: 'Italian 4406F/G - RESEARCH SEMINAR',
        courseName: 'Italian 4406F/G - RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b647f7e10015fd7172',
      },
      {
        _id: '60cc23b647f7e10015fd7174',
        courseCode: 'ITALIAN 4430',
        shortName:
          'Italian 4430F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        courseName:
          'Italian 4430F/G - SPECIAL TOPICS IN ITALIAN LITERATURE AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b647f7e10015fd7174',
      },
      {
        _id: '60cc23b647f7e10015fd7176',
        courseCode: 'ITALIAN 4480',
        shortName: 'Italian 4480A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 4480A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b647f7e10015fd7176',
      },
      {
        _id: '60cc23b747f7e10015fd7178',
        courseCode: 'ITALIAN 4481',
        shortName: 'Italian 4481A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 4481A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b747f7e10015fd7178',
      },
      {
        _id: '60cc23b747f7e10015fd717a',
        courseCode: 'ITALIAN 4482',
        shortName: 'Italian 4482A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 4482A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b747f7e10015fd717a',
      },
      {
        _id: '60cc23b747f7e10015fd717c',
        courseCode: 'ITALIAN 4483',
        shortName: 'Italian 4483A/B - SPECIAL TOPICS IN ITALIAN',
        courseName: 'Italian 4483A/B - SPECIAL TOPICS IN ITALIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b747f7e10015fd717c',
      },
      {
        _id: '60cc23b747f7e10015fd717e',
        courseCode: 'ITALIAN 4490',
        shortName: 'Italian 4490F/G - INDEPENDENT STUDY',
        courseName: 'Italian 4490F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b747f7e10015fd717e',
      },
      {
        _id: '60cc23b847f7e10015fd7180',
        courseCode: 'ITALIAN 4491',
        shortName: 'Italian 4491F/G - INDEPENDENT STUDY',
        courseName: 'Italian 4491F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b847f7e10015fd7180',
      },
      {
        _id: '60cc23b847f7e10015fd7182',
        courseCode: 'ITALIAN 4492',
        shortName: 'Italian 4492F/G - INDEPENDENT STUDY',
        courseName: 'Italian 4492F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b847f7e10015fd7182',
      },
      {
        _id: '60cc23b847f7e10015fd7184',
        courseCode: 'ITALIAN 4493',
        shortName: 'Italian 4493F/G - INDEPENDENT STUDY',
        courseName: 'Italian 4493F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b847f7e10015fd7184',
      },
      {
        _id: '60cc23b847f7e10015fd7186',
        courseCode: 'ITALIAN 4494',
        shortName: 'Italian 4494F/G - INDEPENDENT STUDY',
        courseName: 'Italian 4494F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b847f7e10015fd7186',
      },
      {
        _id: '60cc23b847f7e10015fd7188',
        courseCode: 'ITALIAN 4500',
        shortName: 'Italian 4500F/G - SENIOR RESEARCH PROJECT',
        courseName: 'Italian 4500F/G - SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b847f7e10015fd7188',
      },
      {
        _id: '60cc23b947f7e10015fd718a',
        courseCode: 'ITALST 2136',
        shortName:
          'Italian Studies 2136 - ITALIAN CIVILIZATION AND LITERATURE IN ENGLISH',
        courseName:
          'Italian Studies 2136 - ITALIAN CIVILIZATION AND LITERATURE IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b947f7e10015fd718a',
      },
      {
        _id: '60cc23b947f7e10015fd718c',
        courseCode: 'JAPANESE 1036',
        shortName: 'Japanese 1036 - JAPANESE FOR BEGINNERS',
        courseName: 'Japanese 1036 - JAPANESE FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b947f7e10015fd718c',
      },
      {
        _id: '60cc23b947f7e10015fd718e',
        courseCode: 'JAPANESE 1050',
        shortName: 'Japanese 1050 - JAPANESE I',
        courseName: 'Japanese 1050 - JAPANESE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b947f7e10015fd718e',
      },
      {
        _id: '60cc23b947f7e10015fd7190',
        courseCode: 'JAPANESE 1051',
        shortName: 'Japanese 1051A/B - JAPANESE FOR BEGINNERS 1',
        courseName: 'Japanese 1051A/B - JAPANESE FOR BEGINNERS 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23b947f7e10015fd7190',
      },
      {
        _id: '60cc23ba47f7e10015fd7192',
        courseCode: 'JAPANESE 1052',
        shortName: 'Japanese 1052A/B - JAPANESE FOR BEGINNERS 2',
        courseName: 'Japanese 1052A/B - JAPANESE FOR BEGINNERS 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ba47f7e10015fd7192',
      },
      {
        _id: '60cc23ba47f7e10015fd7194',
        courseCode: 'JAPANESE 1650',
        shortName: 'Japanese 1650F/G - PERSPECTIVES ON JAPAN',
        courseName: 'Japanese 1650F/G - PERSPECTIVES ON JAPAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ba47f7e10015fd7194',
      },
      {
        _id: '60cc23ba47f7e10015fd7196',
        courseCode: 'JAPANESE 2250',
        shortName: 'Japanese 2250 - JAPANESE 2',
        courseName: 'Japanese 2250 - JAPANESE 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ba47f7e10015fd7196',
      },
      {
        _id: '60cc23ba47f7e10015fd7198',
        courseCode: 'JAPANESE 2251',
        shortName: 'Japanese 2251A/B - JAPANESE II-A',
        courseName: 'Japanese 2251A/B - JAPANESE II-A',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ba47f7e10015fd7198',
      },
      {
        _id: '60cc23bb47f7e10015fd719a',
        courseCode: 'JAPANESE 2252',
        shortName: 'Japanese 2252A/B - JAPANESE II-B',
        courseName: 'Japanese 2252A/B - JAPANESE II-B',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bb47f7e10015fd719a',
      },
      {
        _id: '60cc23bb47f7e10015fd719c',
        courseCode: 'JAPANESE 2260',
        shortName: 'Japanese 2260 - INTERMEDIATE JAPANESE',
        courseName: 'Japanese 2260 - INTERMEDIATE JAPANESE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bb47f7e10015fd719c',
      },
      {
        _id: '60cc23bb47f7e10015fd719e',
        courseCode: 'JAPANESE 2270',
        shortName: 'Japanese 2270F/G - SPECIAL TOPICS',
        courseName: 'Japanese 2270F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bb47f7e10015fd719e',
      },
      {
        _id: '60cc23bb47f7e10015fd71a0',
        courseCode: 'JAPANESE 2271',
        shortName: 'Japanese 2271F/G - SPECIAL TOPICS',
        courseName: 'Japanese 2271F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bb47f7e10015fd71a0',
      },
      {
        _id: '60cc23bb47f7e10015fd71a2',
        courseCode: 'JAPANESE 2601',
        shortName:
          'Japanese 2601A/B - THE CULTURAL FOUNDATIONS OF MODERN JAPAN',
        courseName:
          'Japanese 2601A/B - THE CULTURAL FOUNDATIONS OF MODERN JAPAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bb47f7e10015fd71a2',
      },
      {
        _id: '60cc23bc47f7e10015fd71a4',
        courseCode: 'JAPANESE 2750',
        shortName: 'Japanese 2750A/B - AN INTRODUCTION TO JAPANESE LINGUISTICS',
        courseName:
          'Japanese 2750A/B - AN INTRODUCTION TO JAPANESE LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bc47f7e10015fd71a4',
      },
      {
        _id: '60cc23bc47f7e10015fd71a6',
        courseCode: 'JAPANESE 3350',
        shortName: 'Japanese 3350 - JAPANESE 3',
        courseName: 'Japanese 3350 - JAPANESE 3',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bc47f7e10015fd71a6',
      },
      {
        _id: '60cc23bc47f7e10015fd71a8',
        courseCode: 'JAPANESE 3351',
        shortName: 'Japanese 3351A/B - JAPANESE III-A',
        courseName: 'Japanese 3351A/B - JAPANESE III-A',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bc47f7e10015fd71a8',
      },
      {
        _id: '60cc23bc47f7e10015fd71aa',
        courseCode: 'JAPANESE 3352',
        shortName: 'Japanese 3352A/B - JAPANESE III-B',
        courseName: 'Japanese 3352A/B - JAPANESE III-B',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bc47f7e10015fd71aa',
      },
      {
        _id: '60cc23bd47f7e10015fd71ac',
        courseCode: 'JAPANESE 3360',
        shortName:
          'Japanese 3360A/B - INTERMEDIATE LEVEL CONVERSATIONAL JAPANESE I',
        courseName:
          'Japanese 3360A/B - INTERMEDIATE LEVEL CONVERSATIONAL JAPANESE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bd47f7e10015fd71ac',
      },
      {
        _id: '60cc23bd47f7e10015fd71ae',
        courseCode: 'JAPANESE 3361',
        shortName:
          'Japanese 3361A/B - INTERMEDIATE LEVEL CONVERSATIONAL JAPANESE II',
        courseName:
          'Japanese 3361A/B - INTERMEDIATE LEVEL CONVERSATIONAL JAPANESE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bd47f7e10015fd71ae',
      },
      {
        _id: '60cc23bd47f7e10015fd71b0',
        courseCode: 'JAPANESE 3370',
        shortName: 'Japanese 3370F/G - SPECIAL TOPICS',
        courseName: 'Japanese 3370F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bd47f7e10015fd71b0',
      },
      {
        _id: '60cc23bd47f7e10015fd71b2',
        courseCode: 'JAPANESE 3371',
        shortName: 'Japanese 3371F/G - SPECIAL TOPICS',
        courseName: 'Japanese 3371F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bd47f7e10015fd71b2',
      },
      {
        _id: '60cc23bd47f7e10015fd71b4',
        courseCode: 'JAPANESE 3650',
        shortName: 'Japanese 3650F/G - JAPAN THROUGH FILM',
        courseName: 'Japanese 3650F/G - JAPAN THROUGH FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bd47f7e10015fd71b4',
      },
      {
        _id: '60cc23be47f7e10015fd71b6',
        courseCode: 'JAPANESE 3651',
        shortName: 'Japanese 3651F/G - JAPAN THROUGH ANIME',
        courseName: 'Japanese 3651F/G - JAPAN THROUGH ANIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23be47f7e10015fd71b6',
      },
      {
        _id: '60cc23be47f7e10015fd71b8',
        courseCode: 'JAPANESE 3680',
        shortName:
          'Japanese 3680F/G - JAPAN THROUGH FOOD: TALES OF CULTURAL CONTACT',
        courseName:
          'Japanese 3680F/G - JAPAN THROUGH FOOD: TALES OF CULTURAL CONTACT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23be47f7e10015fd71b8',
      },
      {
        _id: '60cc23be47f7e10015fd71ba',
        courseCode: 'JAPANESE 3690',
        shortName:
          'Japanese 3690F/G - EXPERIENTIAL APPROACHES TO FOREIGN LANGUAGE PEDAGOGY: A CASE WITH JAPANESE',
        courseName:
          'Japanese 3690F/G - EXPERIENTIAL APPROACHES TO FOREIGN LANGUAGE PEDAGOGY: A CASE WITH JAPANESE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23be47f7e10015fd71ba',
      },
      {
        _id: '60cc23be47f7e10015fd71bc',
        courseCode: 'JAPANESE 3750',
        shortName: 'Japanese 3750F/G - SENIOR RESEARCH SEMINAR',
        courseName: 'Japanese 3750F/G - SENIOR RESEARCH SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23be47f7e10015fd71bc',
      },
      {
        _id: '60cc23bf47f7e10015fd71be',
        courseCode: 'JAPANESE 3751',
        shortName: 'Japanese 3751F/G - SENIOR RESEARCH SEMINAR II',
        courseName: 'Japanese 3751F/G - SENIOR RESEARCH SEMINAR II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bf47f7e10015fd71be',
      },
      {
        _id: '60cc23bf47f7e10015fd71c0',
        courseCode: 'JAPANESE 3950',
        shortName: 'Japanese 3950F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3950F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bf47f7e10015fd71c0',
      },
      {
        _id: '60cc23bf47f7e10015fd71c2',
        courseCode: 'JAPANESE 3951',
        shortName: 'Japanese 3951F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3951F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bf47f7e10015fd71c2',
      },
      {
        _id: '60cc23bf47f7e10015fd71c4',
        courseCode: 'JAPANESE 3952',
        shortName: 'Japanese 3952F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3952F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23bf47f7e10015fd71c4',
      },
      {
        _id: '60cc23c047f7e10015fd71c6',
        courseCode: 'JAPANESE 3953',
        shortName: 'Japanese 3953F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3953F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c047f7e10015fd71c6',
      },
      {
        _id: '60cc23c047f7e10015fd71c8',
        courseCode: 'JAPANESE 3954',
        shortName: 'Japanese 3954F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3954F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c047f7e10015fd71c8',
      },
      {
        _id: '60cc23c047f7e10015fd71ca',
        courseCode: 'JAPANESE 3955',
        shortName: 'Japanese 3955F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3955F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c047f7e10015fd71ca',
      },
      {
        _id: '60cc23c047f7e10015fd71cc',
        courseCode: 'JAPANESE 3956',
        shortName: 'Japanese 3956F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3956F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c047f7e10015fd71cc',
      },
      {
        _id: '60cc23c147f7e10015fd71ce',
        courseCode: 'JAPANESE 3957',
        shortName: 'Japanese 3957F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3957F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c147f7e10015fd71ce',
      },
      {
        _id: '60cc23c147f7e10015fd71d0',
        courseCode: 'JAPANESE 3958',
        shortName: 'Japanese 3958F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3958F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c147f7e10015fd71d0',
      },
      {
        _id: '60cc23c147f7e10015fd71d2',
        courseCode: 'JAPANESE 3959',
        shortName: 'Japanese 3959F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        courseName: 'Japanese 3959F/G - SPECIAL TOPIC IN JAPAN STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c147f7e10015fd71d2',
      },
      {
        _id: '60cc23c147f7e10015fd71d4',
        courseCode: 'JAPANESE 4450',
        shortName: 'Japanese 4450 - JAPANESE 4',
        courseName: 'Japanese 4450 - JAPANESE 4',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c147f7e10015fd71d4',
      },
      {
        _id: '60cc23c247f7e10015fd71d6',
        courseCode: 'JAPANESE 4451',
        shortName: 'Japanese 4451A/B - JAPANESE IV-A',
        courseName: 'Japanese 4451A/B - JAPANESE IV-A',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c247f7e10015fd71d6',
      },
      {
        _id: '60cc23c247f7e10015fd71d8',
        courseCode: 'JAPANESE 4452',
        shortName: 'Japanese 4452A/B - JAPANESE IV-B',
        courseName: 'Japanese 4452A/B - JAPANESE IV-B',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c247f7e10015fd71d8',
      },
      {
        _id: '60cc23c247f7e10015fd71da',
        courseCode: 'JEWISH 1250',
        shortName:
          'Jewish Studies 1250F/G - INTRODUCTION TO MODERN JEWISH THOUGHT',
        courseName:
          'Jewish Studies 1250F/G - INTRODUCTION TO MODERN JEWISH THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c247f7e10015fd71da',
      },
      {
        _id: '60cc23c247f7e10015fd71dc',
        courseCode: 'JEWISH 1370',
        shortName: 'Jewish Studies 1370F/G - THE PROBLEM OF ANTI-SEMITISM',
        courseName: 'Jewish Studies 1370F/G - THE PROBLEM OF ANTI-SEMITISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c247f7e10015fd71dc',
      },
      {
        _id: '60cc23c247f7e10015fd71de',
        courseCode: 'JEWISH 2345',
        shortName:
          'Jewish Studies 2345F/G - BIBLICAL STORYTELLING AND ADAPTATION',
        courseName:
          'Jewish Studies 2345F/G - BIBLICAL STORYTELLING AND ADAPTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c247f7e10015fd71de',
      },
      {
        _id: '60cc23c347f7e10015fd71e0',
        courseCode: 'JEWISH 2346',
        shortName: 'Jewish Studies 2346F/G - ANCIENT WISDOM',
        courseName: 'Jewish Studies 2346F/G - ANCIENT WISDOM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c347f7e10015fd71e0',
      },
      {
        _id: '60cc23c347f7e10015fd71e2',
        courseCode: 'JEWISH 2347',
        shortName: 'Jewish Studies 2347F/G - LIVING THE BIBLE: LAW AND ETHICS',
        courseName: 'Jewish Studies 2347F/G - LIVING THE BIBLE: LAW AND ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c347f7e10015fd71e2',
      },
      {
        _id: '60cc23c347f7e10015fd71e4',
        courseCode: 'JEWISH 2790',
        shortName: 'Jewish Studies 2790F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 2790F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c347f7e10015fd71e4',
      },
      {
        _id: '60cc23c347f7e10015fd71e6',
        courseCode: 'JEWISH 2791',
        shortName: 'Jewish Studies 2791F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 2791F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c347f7e10015fd71e6',
      },
      {
        _id: '60cc23c447f7e10015fd71e8',
        courseCode: 'JEWISH 2792',
        shortName: 'Jewish Studies 2792F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 2792F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c447f7e10015fd71e8',
      },
      {
        _id: '60cc23c447f7e10015fd71ea',
        courseCode: 'JEWISH 3790',
        shortName: 'Jewish Studies 3790F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 3790F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c447f7e10015fd71ea',
      },
      {
        _id: '60cc23c447f7e10015fd71ec',
        courseCode: 'JEWISH 3791',
        shortName: 'Jewish Studies 3791F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 3791F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c447f7e10015fd71ec',
      },
      {
        _id: '60cc23c447f7e10015fd71ee',
        courseCode: 'JEWISH 3792',
        shortName: 'Jewish Studies 3792F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 3792F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c447f7e10015fd71ee',
      },
      {
        _id: '60cc23c447f7e10015fd71f0',
        courseCode: 'JEWISH 4790',
        shortName: 'Jewish Studies 4790F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 4790F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c447f7e10015fd71f0',
      },
      {
        _id: '60cc23c547f7e10015fd71f2',
        courseCode: 'JEWISH 4791',
        shortName: 'Jewish Studies 4791F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 4791F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c547f7e10015fd71f2',
      },
      {
        _id: '60cc23c547f7e10015fd71f4',
        courseCode: 'JEWISH 4792',
        shortName: 'Jewish Studies 4792F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        courseName: 'Jewish Studies 4792F/G - SPECIAL TOPICS IN JEWISH STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c547f7e10015fd71f4',
      },
      {
        _id: '60cc23c547f7e10015fd71f6',
        courseCode: 'KINESIOL 1070',
        shortName: 'Kinesiology 1070A/B - PSYCHOLOGY OF HUMAN MOVEMENT SCIENCE',
        courseName:
          'Kinesiology 1070A/B - PSYCHOLOGY OF HUMAN MOVEMENT SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c547f7e10015fd71f6',
      },
      {
        _id: '60cc23c547f7e10015fd71f8',
        courseCode: 'KINESIOL 1080',
        shortName:
          'Kinesiology 1080A/B - INTRODUCTION TO PSYCHOMOTOR BEHAVIOUR',
        courseName:
          'Kinesiology 1080A/B - INTRODUCTION TO PSYCHOMOTOR BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c547f7e10015fd71f8',
      },
      {
        _id: '60cc23c647f7e10015fd71fa',
        courseCode: 'KINESIOL 2000',
        shortName: 'Kinesiology 2000A/B - PHYSICAL ACTIVITY AND HEALTH',
        courseName: 'Kinesiology 2000A/B - PHYSICAL ACTIVITY AND HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c647f7e10015fd71fa',
      },
      {
        _id: '60cc23c647f7e10015fd71fc',
        courseCode: 'KINESIOL 2032',
        shortName:
          'Kinesiology 2032A/B - RESEARCH DESIGN IN HUMAN MOVEMENT SCIENCE',
        courseName:
          'Kinesiology 2032A/B - RESEARCH DESIGN IN HUMAN MOVEMENT SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c647f7e10015fd71fc',
      },
      {
        _id: '60cc23c647f7e10015fd71fe',
        courseCode: 'KINESIOL 2222',
        shortName: 'Kinesiology 2222A/B - FUNCTIONAL HUMAN GROSS ANATOMY',
        courseName: 'Kinesiology 2222A/B - FUNCTIONAL HUMAN GROSS ANATOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c647f7e10015fd71fe',
      },
      {
        _id: '60cc23c647f7e10015fd7200',
        courseCode: 'KINESIOL 2230',
        shortName: 'Kinesiology 2230A/B - INTRODUCTORY EXERCISE PHYSIOLOGY',
        courseName: 'Kinesiology 2230A/B - INTRODUCTORY EXERCISE PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c647f7e10015fd7200',
      },
      {
        _id: '60cc23c647f7e10015fd7202',
        courseCode: 'KINESIOL 2236',
        shortName: 'Kinesiology 2236A/B - INTRODUCTION TO ATHLETIC INJURIES',
        courseName: 'Kinesiology 2236A/B - INTRODUCTION TO ATHLETIC INJURIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c647f7e10015fd7202',
      },
      {
        _id: '60cc23c747f7e10015fd7204',
        courseCode: 'KINESIOL 2241',
        shortName: 'Kinesiology 2241A/B - BIOMECHANICS',
        courseName: 'Kinesiology 2241A/B - BIOMECHANICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c747f7e10015fd7204',
      },
      {
        _id: '60cc23c747f7e10015fd7206',
        courseCode: 'KINESIOL 2250',
        shortName:
          'Kinesiology 2250A/B - SOCIAL FOUNDATIONS OF SPORT AND PHYSICAL ACTIVITY',
        courseName:
          'Kinesiology 2250A/B - SOCIAL FOUNDATIONS OF SPORT AND PHYSICAL ACTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c747f7e10015fd7206',
      },
      {
        _id: '60cc23c747f7e10015fd7208',
        courseCode: 'KINESIOL 2263',
        shortName: 'Kinesiology 2263F/G - CANADIAN SPORT HISTORY',
        courseName: 'Kinesiology 2263F/G - CANADIAN SPORT HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c747f7e10015fd7208',
      },
      {
        _id: '60cc23c747f7e10015fd720a',
        courseCode: 'KINESIOL 2270',
        shortName: 'Kinesiology 2270F/G - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 2270F/G - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c747f7e10015fd720a',
      },
      {
        _id: '60cc23c847f7e10015fd720c',
        courseCode: 'KINESIOL 2271',
        shortName: 'Kinesiology 2271A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 2271A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c847f7e10015fd720c',
      },
      {
        _id: '60cc23c847f7e10015fd720e',
        courseCode: 'KINESIOL 2272',
        shortName: 'Kinesiology 2272A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 2272A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c847f7e10015fd720e',
      },
      {
        _id: '60cc23c847f7e10015fd7210',
        courseCode: 'KINESIOL 2276',
        shortName: 'Kinesiology 2276F/G - PSYCHOLOGY OF EXERCISE',
        courseName: 'Kinesiology 2276F/G - PSYCHOLOGY OF EXERCISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c847f7e10015fd7210',
      },
      {
        _id: '60cc23c847f7e10015fd7212',
        courseCode: 'KINESIOL 2292',
        shortName:
          'Kinesiology 2292F/G - CRITICAL THINKING AND ETHICS IN KINESIOLOGY',
        courseName:
          'Kinesiology 2292F/G - CRITICAL THINKING AND ETHICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c847f7e10015fd7212',
      },
      {
        _id: '60cc23c947f7e10015fd7214',
        courseCode: 'KINESIOL 2298',
        shortName:
          'Kinesiology 2298A/B - INTRODUCTION TO MANAGEMENT IN KINESIOLOGY',
        courseName:
          'Kinesiology 2298A/B - INTRODUCTION TO MANAGEMENT IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c947f7e10015fd7214',
      },
      {
        _id: '60cc23c947f7e10015fd7216',
        courseCode: 'KINESIOL 2950',
        shortName: 'Kinesiology 2950Q/R/S/T - SPECIAL TOPIC IN SPORT SKILLS',
        courseName: 'Kinesiology 2950Q/R/S/T - SPECIAL TOPIC IN SPORT SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c947f7e10015fd7216',
      },
      {
        _id: '60cc23c947f7e10015fd7218',
        courseCode: 'KINESIOL 2951',
        shortName: 'Kinesiology 2951Q/R/S/T - SPECIAL TOPIC IN SPORT SKILLS',
        courseName: 'Kinesiology 2951Q/R/S/T - SPECIAL TOPIC IN SPORT SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c947f7e10015fd7218',
      },
      {
        _id: '60cc23c947f7e10015fd721a',
        courseCode: 'KINESIOL 2980',
        shortName: 'Kinesiology 2980A/B - SPC TOPICS IN MOVEMENT SKILLS',
        courseName: 'Kinesiology 2980A/B - SPC TOPICS IN MOVEMENT SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23c947f7e10015fd721a',
      },
      {
        _id: '60cc23ca47f7e10015fd721c',
        courseCode: 'KINESIOL 2981',
        shortName: 'Kinesiology 2981A/B - SPECIAL TOPICS IN MOVEMENT SKILLS',
        courseName: 'Kinesiology 2981A/B - SPECIAL TOPICS IN MOVEMENT SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ca47f7e10015fd721c',
      },
      {
        _id: '60cc23ca47f7e10015fd721e',
        courseCode: 'KINESIOL 2991',
        shortName: 'Kinesiology 2991A/B - COACHING AND SPORT LEADERSHIP',
        courseName: 'Kinesiology 2991A/B - COACHING AND SPORT LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ca47f7e10015fd721e',
      },
      {
        _id: '60cc23ca47f7e10015fd7220',
        courseCode: 'KINESIOL 2992',
        shortName:
          'Kinesiology 2992A/B/Y - FITNESS ASSESSMENT AND STRENGTH TRAINING',
        courseName:
          'Kinesiology 2992A/B/Y - FITNESS ASSESSMENT AND STRENGTH TRAINING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ca47f7e10015fd7220',
      },
      {
        _id: '60cc23ca47f7e10015fd7222',
        courseCode: 'KINESIOL 2993',
        shortName:
          'Kinesiology 2993A/B/Y - LIFESTYLE, INDIVIDUAL, FITNESS & EXERCISE',
        courseName:
          'Kinesiology 2993A/B/Y - LIFESTYLE, INDIVIDUAL, FITNESS & EXERCISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ca47f7e10015fd7222',
      },
      {
        _id: '60cc23ca47f7e10015fd7224',
        courseCode: 'KINESIOL 2994',
        shortName:
          'Kinesiology 2994A/B/Y - SPECIFIC POPULATIONS HEALTHY ENGAGEMENT REHABILITATION & EXERCISE',
        courseName:
          'Kinesiology 2994A/B/Y - SPECIFIC POPULATIONS HEALTHY ENGAGEMENT REHABILITATION & EXERCISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ca47f7e10015fd7224',
      },
      {
        _id: '60cc23cb47f7e10015fd7226',
        courseCode: 'KINESIOL 3222',
        shortName:
          'Kinesiology 3222A/B - ANATOMY OF THE HUMAN BODY: A DESCRIPTION OF SYSTEMIC STRUCTURE AND FUNCTION',
        courseName:
          'Kinesiology 3222A/B - ANATOMY OF THE HUMAN BODY: A DESCRIPTION OF SYSTEMIC STRUCTURE AND FUNCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cb47f7e10015fd7226',
      },
      {
        _id: '60cc23cb47f7e10015fd7228',
        courseCode: 'KINESIOL 3325',
        shortName: 'Kinesiology 3325A/B - INTEGRATIVE HEALTH',
        courseName: 'Kinesiology 3325A/B - INTEGRATIVE HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cb47f7e10015fd7228',
      },
      {
        _id: '60cc23cb47f7e10015fd722a',
        courseCode: 'KINESIOL 3330',
        shortName: 'Kinesiology 3330F/G - LABORATORY IN EXERCISE PHYSIOLOGY',
        courseName: 'Kinesiology 3330F/G - LABORATORY IN EXERCISE PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cb47f7e10015fd722a',
      },
      {
        _id: '60cc23cb47f7e10015fd722c',
        courseCode: 'KINESIOL 3335',
        shortName: 'Kinesiology 3335 - LEADERSHIP IN PHYSICAL ACTIVITY',
        courseName: 'Kinesiology 3335 - LEADERSHIP IN PHYSICAL ACTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cb47f7e10015fd722c',
      },
      {
        _id: '60cc23cc47f7e10015fd722e',
        courseCode: 'KINESIOL 3336',
        shortName:
          'Kinesiology 3336A/B - AN INTRODUCTION TO THE PRACTICAL ASPECTS OF ATHLETIC INJURIES',
        courseName:
          'Kinesiology 3336A/B - AN INTRODUCTION TO THE PRACTICAL ASPECTS OF ATHLETIC INJURIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cc47f7e10015fd722e',
      },
      {
        _id: '60cc23cc47f7e10015fd7230',
        courseCode: 'KINESIOL 3337',
        shortName: 'Kinesiology 3337A/B - PHYSIOLOGY OF FITNESS APPRAISAL',
        courseName: 'Kinesiology 3337A/B - PHYSIOLOGY OF FITNESS APPRAISAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cc47f7e10015fd7230',
      },
      {
        _id: '60cc23cc47f7e10015fd7232',
        courseCode: 'KINESIOL 3339',
        shortName: 'Kinesiology 3339A/B - EXERCISE NUTRITION',
        courseName: 'Kinesiology 3339A/B - EXERCISE NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cc47f7e10015fd7232',
      },
      {
        _id: '60cc23cc47f7e10015fd7234',
        courseCode: 'KINESIOL 3341',
        shortName:
          'Kinesiology 3341A/B - BIOMECHANICAL ANALYSIS OF PHYSICAL ACTIVITY',
        courseName:
          'Kinesiology 3341A/B - BIOMECHANICAL ANALYSIS OF PHYSICAL ACTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cc47f7e10015fd7234',
      },
      {
        _id: '60cc23cd47f7e10015fd7236',
        courseCode: 'KINESIOL 3343',
        shortName:
          'Kinesiology 3343A/B - BIOMECHANICAL ANALYSIS OF DISCRETE SPORT SKILLS',
        courseName:
          'Kinesiology 3343A/B - BIOMECHANICAL ANALYSIS OF DISCRETE SPORT SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cd47f7e10015fd7236',
      },
      {
        _id: '60cc23cd47f7e10015fd7238',
        courseCode: 'KINESIOL 3347',
        shortName:
          'Kinesiology 3347A/B - PHYSICAL GROWTH AND MOTOR DEVELOPMENT',
        courseName:
          'Kinesiology 3347A/B - PHYSICAL GROWTH AND MOTOR DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cd47f7e10015fd7238',
      },
      {
        _id: '60cc23cd47f7e10015fd723a',
        courseCode: 'KINESIOL 3353',
        shortName:
          'Kinesiology 3353A/B - BIOMECHANICAL ANALYSIS OF HUMAN LOCOMOTION',
        courseName:
          'Kinesiology 3353A/B - BIOMECHANICAL ANALYSIS OF HUMAN LOCOMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cd47f7e10015fd723a',
      },
      {
        _id: '60cc23cd47f7e10015fd723c',
        courseCode: 'KINESIOL 3360',
        shortName: 'Kinesiology 3360A/B - EXERCISE BIOCHEMISTRY',
        courseName: 'Kinesiology 3360A/B - EXERCISE BIOCHEMISTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cd47f7e10015fd723c',
      },
      {
        _id: '60cc23ce47f7e10015fd723e',
        courseCode: 'KINESIOL 3362',
        shortName: 'Kinesiology 3362F/G - OLYMPIC ISSUES FOR MODERN TIMES',
        courseName: 'Kinesiology 3362F/G - OLYMPIC ISSUES FOR MODERN TIMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ce47f7e10015fd723e',
      },
      {
        _id: '60cc23ce47f7e10015fd7240',
        courseCode: 'KINESIOL 3370',
        shortName: 'Kinesiology 3370F/G - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 3370F/G - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ce47f7e10015fd7240',
      },
      {
        _id: '60cc23ce47f7e10015fd7242',
        courseCode: 'KINESIOL 3371',
        shortName: 'Kinesiology 3371A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 3371A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ce47f7e10015fd7242',
      },
      {
        _id: '60cc23ce47f7e10015fd7244',
        courseCode: 'KINESIOL 3372',
        shortName: 'Kinesiology 3372B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 3372B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ce47f7e10015fd7244',
      },
      {
        _id: '60cc23cf47f7e10015fd7246',
        courseCode: 'KINESIOL 3377',
        shortName: 'Kinesiology 3377F/G - THE CANADIAN SPORT SYSTEM',
        courseName: 'Kinesiology 3377F/G - THE CANADIAN SPORT SYSTEM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cf47f7e10015fd7246',
      },
      {
        _id: '60cc23cf47f7e10015fd7248',
        courseCode: 'KINESIOL 3378',
        shortName: 'Kinesiology 3378F/G - SPORT IN LITERATURE',
        courseName: 'Kinesiology 3378F/G - SPORT IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cf47f7e10015fd7248',
      },
      {
        _id: '60cc23cf47f7e10015fd724a',
        courseCode: 'KINESIOL 3388',
        shortName: 'Kinesiology 3388A/B - THE PSYCHOLOGY OF SPORT',
        courseName: 'Kinesiology 3388A/B - THE PSYCHOLOGY OF SPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cf47f7e10015fd724a',
      },
      {
        _id: '60cc23cf47f7e10015fd724c',
        courseCode: 'KINESIOL 3398',
        shortName:
          'Kinesiology 3398F/G - MANAGING PEOPLE IN SPORT AND RECREATION ORGANIZATIONS',
        courseName:
          'Kinesiology 3398F/G - MANAGING PEOPLE IN SPORT AND RECREATION ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cf47f7e10015fd724c',
      },
      {
        _id: '60cc23cf47f7e10015fd724e',
        courseCode: 'KINESIOL 3399',
        shortName: 'Kinesiology 3399F/G - SPORT MARKETING',
        courseName: 'Kinesiology 3399F/G - SPORT MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23cf47f7e10015fd724e',
      },
      {
        _id: '60cc23d047f7e10015fd7250',
        courseCode: 'KINESIOL 3402',
        shortName: 'Kinesiology 3402A/B - INTRODUCTION TO CLINICAL KINESIOLOGY',
        courseName:
          'Kinesiology 3402A/B - INTRODUCTION TO CLINICAL KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d047f7e10015fd7250',
      },
      {
        _id: '60cc23d047f7e10015fd7252',
        courseCode: 'KINESIOL 3412',
        shortName:
          'Kinesiology 3412A/B - EXERCISE FOR SPECIFIC POPULATIONS: CHRONIC DISEASE',
        courseName:
          'Kinesiology 3412A/B - EXERCISE FOR SPECIFIC POPULATIONS: CHRONIC DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d047f7e10015fd7252',
      },
      {
        _id: '60cc23d047f7e10015fd7254',
        courseCode: 'KINESIOL 3457',
        shortName: 'Kinesiology 3457A/B - COGNITIVE ERGONOMICS',
        courseName: 'Kinesiology 3457A/B - COGNITIVE ERGONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d047f7e10015fd7254',
      },
      {
        _id: '60cc23d047f7e10015fd7256',
        courseCode: 'KINESIOL 3463',
        shortName: 'Kinesiology 3463F/G - SPORT, THE BODY AND CULTURE',
        courseName: 'Kinesiology 3463F/G - SPORT, THE BODY AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d047f7e10015fd7256',
      },
      {
        _id: '60cc23d147f7e10015fd7258',
        courseCode: 'KINESIOL 3474',
        shortName:
          'Kinesiology 3474A/B - PSYCHOLOGICAL INTERVENTIONS IN EXERCISE, SPORT AND INJURY REHABILITATION',
        courseName:
          'Kinesiology 3474A/B - PSYCHOLOGICAL INTERVENTIONS IN EXERCISE, SPORT AND INJURY REHABILITATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d147f7e10015fd7258',
      },
      {
        _id: '60cc23d147f7e10015fd725a',
        courseCode: 'KINESIOL 3476',
        shortName: 'Kinesiology 3476F/G - EXERCISE AND HEALTH BEHAVIOUR CHANGE',
        courseName:
          'Kinesiology 3476F/G - EXERCISE AND HEALTH BEHAVIOUR CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d147f7e10015fd725a',
      },
      {
        _id: '60cc23d147f7e10015fd725c',
        courseCode: 'KINESIOL 3480',
        shortName: 'Kinesiology 3480A/B - MOVEMENT NEUROSCIENCE',
        courseName: 'Kinesiology 3480A/B - MOVEMENT NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d147f7e10015fd725c',
      },
      {
        _id: '60cc23d147f7e10015fd725e',
        courseCode: 'KINESIOL 3490',
        shortName: 'Kinesiology 3490F/G - PROFESSIONAL ETHICS IN KINESIOLOGY',
        courseName: 'Kinesiology 3490F/G - PROFESSIONAL ETHICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d147f7e10015fd725e',
      },
      {
        _id: '60cc23d247f7e10015fd7260',
        courseCode: 'KINESIOL 3495',
        shortName:
          'Kinesiology 3495A/B - PRACTICAL ASPECTS OF STRENGTH AND CONDITIONING FOR HIGH PERFORMANCE',
        courseName:
          'Kinesiology 3495A/B - PRACTICAL ASPECTS OF STRENGTH AND CONDITIONING FOR HIGH PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d247f7e10015fd7260',
      },
      {
        _id: '60cc23d247f7e10015fd7262',
        courseCode: 'KINESIOL 3510',
        shortName: 'Kinesiology 3510F/G - SPORT FOR DEVELOPMENT AND PEACE',
        courseName: 'Kinesiology 3510F/G - SPORT FOR DEVELOPMENT AND PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d247f7e10015fd7262',
      },
      {
        _id: '60cc23d247f7e10015fd7264',
        courseCode: 'KINESIOL 3515',
        shortName: 'Kinesiology 3515F/G - SPORT AND HEALTH POLICY',
        courseName: 'Kinesiology 3515F/G - SPORT AND HEALTH POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d247f7e10015fd7264',
      },
      {
        _id: '60cc23d247f7e10015fd7266',
        courseCode: 'KINESIOL 3550',
        shortName: 'Kinesiology 3550A/B - KINESIOLOGY AT WORK',
        courseName: 'Kinesiology 3550A/B - KINESIOLOGY AT WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d247f7e10015fd7266',
      },
      {
        _id: '60cc23d347f7e10015fd7268',
        courseCode: 'KINESIOL 3891',
        shortName: 'Kinesiology 3891 - INTERNSHIP WORK TERM (8 MONTHS)',
        courseName: 'Kinesiology 3891 - INTERNSHIP WORK TERM (8 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d347f7e10015fd7268',
      },
      {
        _id: '60cc23d347f7e10015fd726a',
        courseCode: 'KINESIOL 3892',
        shortName: 'Kinesiology 3892 - INTERNSHIP WORK TERM (9-12 MONTHS)',
        courseName: 'Kinesiology 3892 - INTERNSHIP WORK TERM (9-12 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d347f7e10015fd726a',
      },
      {
        _id: '60cc23d347f7e10015fd726c',
        courseCode: 'KINESIOL 3893',
        shortName: 'Kinesiology 3893 - INTERNSHIP WORK TERM (13-16 MONTHS)',
        courseName: 'Kinesiology 3893 - INTERNSHIP WORK TERM (13-16 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d347f7e10015fd726c',
      },
      {
        _id: '60cc23d347f7e10015fd726e',
        courseCode: 'KINESIOL 3894',
        shortName: 'Kinesiology 3894 - INTERNSHIP WORK TERM (8 MONTHS)',
        courseName: 'Kinesiology 3894 - INTERNSHIP WORK TERM (8 MONTHS)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d347f7e10015fd726e',
      },
      {
        _id: '60cc23d447f7e10015fd7270',
        courseCode: 'KINESIOL 3895',
        shortName:
          'Kinesiology 3895A/B - PROFESSIONALISM & LEADERSHIP IN PRACTICE',
        courseName:
          'Kinesiology 3895A/B - PROFESSIONALISM & LEADERSHIP IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d447f7e10015fd7270',
      },
      {
        _id: '60cc23d447f7e10015fd7272',
        courseCode: 'KINESIOL 3962',
        shortName: 'Kinesiology 3962A/B - BIKE TRIP IN FRANCE',
        courseName: 'Kinesiology 3962A/B - BIKE TRIP IN FRANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d447f7e10015fd7272',
      },
      {
        _id: '60cc23d447f7e10015fd7274',
        courseCode: 'KINESIOL 4276',
        shortName: 'Kinesiology 4276F/G - PSYCHOLOGY OF BODY AND MOVEMENT',
        courseName: 'Kinesiology 4276F/G - PSYCHOLOGY OF BODY AND MOVEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d447f7e10015fd7274',
      },
      {
        _id: '60cc23d447f7e10015fd7276',
        courseCode: 'KINESIOL 4360',
        shortName:
          'Kinesiology 4360A/B - CARDIORESPIRATORY SUPPORT OF EXERCISE METABOLISM',
        courseName:
          'Kinesiology 4360A/B - CARDIORESPIRATORY SUPPORT OF EXERCISE METABOLISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d447f7e10015fd7276',
      },
      {
        _id: '60cc23d447f7e10015fd7278',
        courseCode: 'KINESIOL 4412',
        shortName:
          'Kinesiology 4412A/B - EXERCISE FOR SPECIFIC POPULATIONS: IMPAIRMENT AND AGING',
        courseName:
          'Kinesiology 4412A/B - EXERCISE FOR SPECIFIC POPULATIONS: IMPAIRMENT AND AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d447f7e10015fd7278',
      },
      {
        _id: '60cc23d547f7e10015fd727a',
        courseCode: 'KINESIOL 4420',
        shortName:
          'Kinesiology 4420A/B - IMPACT OF EXERCISE DURING PREGNANCY ON CHRONIC DISEASE RISK',
        courseName:
          'Kinesiology 4420A/B - IMPACT OF EXERCISE DURING PREGNANCY ON CHRONIC DISEASE RISK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d547f7e10015fd727a',
      },
      {
        _id: '60cc23d547f7e10015fd727c',
        courseCode: 'KINESIOL 4422',
        shortName:
          'Kinesiology 4422A/B - ENTREPRENEURSHIP AND TECHNOLOGY IN CLINICAL KINESIOLOGY',
        courseName:
          'Kinesiology 4422A/B - ENTREPRENEURSHIP AND TECHNOLOGY IN CLINICAL KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d547f7e10015fd727c',
      },
      {
        _id: '60cc23d547f7e10015fd727e',
        courseCode: 'KINESIOL 4430',
        shortName: 'Kinesiology 4430F/G - NEUROMUSCULAR PHYSIOLOGY',
        courseName: 'Kinesiology 4430F/G - NEUROMUSCULAR PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d547f7e10015fd727e',
      },
      {
        _id: '60cc23d547f7e10015fd7280',
        courseCode: 'KINESIOL 4432',
        shortName: 'Kinesiology 4432A/B - PHYSIOLOGY OF EXERCISE',
        courseName: 'Kinesiology 4432A/B - PHYSIOLOGY OF EXERCISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d547f7e10015fd7280',
      },
      {
        _id: '60cc23d647f7e10015fd7282',
        courseCode: 'KINESIOL 4433',
        shortName: 'Kinesiology 4433A/B - PHYSIOLOGY OF EXERCISE TRAINING',
        courseName: 'Kinesiology 4433A/B - PHYSIOLOGY OF EXERCISE TRAINING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d647f7e10015fd7282',
      },
      {
        _id: '60cc23d647f7e10015fd7284',
        courseCode: 'KINESIOL 4434',
        shortName:
          'Kinesiology 4434A/B - PHYSIOLOGICAL FUNCTION AND PHYSICAL ACTIVITY IN AGING',
        courseName:
          'Kinesiology 4434A/B - PHYSIOLOGICAL FUNCTION AND PHYSICAL ACTIVITY IN AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d647f7e10015fd7284',
      },
      {
        _id: '60cc23d647f7e10015fd7286',
        courseCode: 'KINESIOL 4437',
        shortName: 'Kinesiology 4437A/B - MEDICAL ISSUES IN EXERCISE AND SPORT',
        courseName:
          'Kinesiology 4437A/B - MEDICAL ISSUES IN EXERCISE AND SPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d647f7e10015fd7286',
      },
      {
        _id: '60cc23d647f7e10015fd7288',
        courseCode: 'KINESIOL 4457',
        shortName: 'Kinesiology 4457A/B - ERGONOMICS AND AGING',
        courseName: 'Kinesiology 4457A/B - ERGONOMICS AND AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d647f7e10015fd7288',
      },
      {
        _id: '60cc23d747f7e10015fd728a',
        courseCode: 'KINESIOL 4459',
        shortName: 'Kinesiology 4459A/B - SPORT AND THE LAW',
        courseName: 'Kinesiology 4459A/B - SPORT AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d747f7e10015fd728a',
      },
      {
        _id: '60cc23d747f7e10015fd728c',
        courseCode: 'KINESIOL 4460',
        shortName: 'Kinesiology 4460F/G - COMPARATIVE AND INTERNATIONAL SPORT',
        courseName: 'Kinesiology 4460F/G - COMPARATIVE AND INTERNATIONAL SPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d747f7e10015fd728c',
      },
      {
        _id: '60cc23d747f7e10015fd728e',
        courseCode: 'KINESIOL 4465',
        shortName: 'Kinesiology 4465F/G - SOCIAL THEORY OF SPORT AND EXERCISE',
        courseName: 'Kinesiology 4465F/G - SOCIAL THEORY OF SPORT AND EXERCISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d747f7e10015fd728e',
      },
      {
        _id: '60cc23d747f7e10015fd7290',
        courseCode: 'KINESIOL 4469',
        shortName: 'Kinesiology 4469F/G - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 4469F/G - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d747f7e10015fd7290',
      },
      {
        _id: '60cc23d847f7e10015fd7292',
        courseCode: 'KINESIOL 4470',
        shortName: 'Kinesiology 4470F/G - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 4470F/G - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d847f7e10015fd7292',
      },
      {
        _id: '60cc23d847f7e10015fd7294',
        courseCode: 'KINESIOL 4471',
        shortName: 'Kinesiology 4471A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 4471A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d847f7e10015fd7294',
      },
      {
        _id: '60cc23d847f7e10015fd7296',
        courseCode: 'KINESIOL 4472',
        shortName: 'Kinesiology 4472A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 4472A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d847f7e10015fd7296',
      },
      {
        _id: '60cc23d847f7e10015fd7298',
        courseCode: 'KINESIOL 4473',
        shortName: 'Kinesiology 4473A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 4473A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d847f7e10015fd7298',
      },
      {
        _id: '60cc23d947f7e10015fd729a',
        courseCode: 'KINESIOL 4474',
        shortName:
          'Kinesiology 4474A/B - PHYSICAL ACTIVITY AND EXERCISE IN OLDER ADULTS',
        courseName:
          'Kinesiology 4474A/B - PHYSICAL ACTIVITY AND EXERCISE IN OLDER ADULTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d947f7e10015fd729a',
      },
      {
        _id: '60cc23d947f7e10015fd729c',
        courseCode: 'KINESIOL 4475',
        shortName: 'Kinesiology 4475A/B - SPECIAL TOPICS IN KINESIOLOGY',
        courseName: 'Kinesiology 4475A/B - SPECIAL TOPICS IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d947f7e10015fd729c',
      },
      {
        _id: '60cc23d947f7e10015fd729e',
        courseCode: 'KINESIOL 4477',
        shortName: 'Kinesiology 4477A/B - EXERCISE, NUTRITION & WELLNESS',
        courseName: 'Kinesiology 4477A/B - EXERCISE, NUTRITION & WELLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d947f7e10015fd729e',
      },
      {
        _id: '60cc23d947f7e10015fd72a0',
        courseCode: 'KINESIOL 4480',
        shortName: 'Kinesiology 4480A/B - EXERCISE NEUROSCIENCE',
        courseName: 'Kinesiology 4480A/B - EXERCISE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23d947f7e10015fd72a0',
      },
      {
        _id: '60cc23da47f7e10015fd72a2',
        courseCode: 'KINESIOL 4489',
        shortName: 'Kinesiology 4489A/B - INTERNATIONAL SPORT MANAGEMENT',
        courseName: 'Kinesiology 4489A/B - INTERNATIONAL SPORT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23da47f7e10015fd72a2',
      },
      {
        _id: '60cc23da47f7e10015fd72a4',
        courseCode: 'KINESIOL 4490',
        shortName: 'Kinesiology 4490E - SENIOR RESEARCH PROJECT',
        courseName: 'Kinesiology 4490E - SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23da47f7e10015fd72a4',
      },
      {
        _id: '60cc23da47f7e10015fd72a6',
        courseCode: 'KINESIOL 4491',
        shortName: 'Kinesiology 4491F/G - INDEPENDENT STUDY IN KINESIOLOGY',
        courseName: 'Kinesiology 4491F/G - INDEPENDENT STUDY IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23da47f7e10015fd72a6',
      },
      {
        _id: '60cc23da47f7e10015fd72a8',
        courseCode: 'KINESIOL 4492',
        shortName: 'Kinesiology 4492F/G - INDEPENDENT STUDY IN KINESIOLOGY',
        courseName: 'Kinesiology 4492F/G - INDEPENDENT STUDY IN KINESIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23da47f7e10015fd72a8',
      },
      {
        _id: '60cc23db47f7e10015fd72aa',
        courseCode: 'KINESIOL 4495',
        shortName:
          'Kinesiology 4495 - FIELD EXPERIENCE IN STRENGTH AND CONDITIONING',
        courseName:
          'Kinesiology 4495 - FIELD EXPERIENCE IN STRENGTH AND CONDITIONING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23db47f7e10015fd72aa',
      },
      {
        _id: '60cc23db47f7e10015fd72ac',
        courseCode: 'KINESIOL 4498',
        shortName: 'Kinesiology 4498A/B - SPORT MANAGEMENT PRACTICUM',
        courseName: 'Kinesiology 4498A/B - SPORT MANAGEMENT PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23db47f7e10015fd72ac',
      },
      {
        _id: '60cc23db47f7e10015fd72ae',
        courseCode: 'KINESIOL 4520',
        shortName: 'Kinesiology 4520A/B - CLINICAL BIOMECHANICS',
        courseName: 'Kinesiology 4520A/B - CLINICAL BIOMECHANICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23db47f7e10015fd72ae',
      },
      {
        _id: '60cc23db47f7e10015fd72b0',
        courseCode: 'KINESIOL 4560',
        shortName:
          'Kinesiology 4560A/B - ADVANCED TOPICS IN MUSCULOSKELETAL REHABILITATION',
        courseName:
          'Kinesiology 4560A/B - ADVANCED TOPICS IN MUSCULOSKELETAL REHABILITATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23db47f7e10015fd72b0',
      },
      {
        _id: '60cc23dc47f7e10015fd72b2',
        courseCode: 'KINESIOL 4585',
        shortName: 'Kinesiology 4585 - ATHLETIC INJURIES PRACTICUM',
        courseName: 'Kinesiology 4585 - ATHLETIC INJURIES PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dc47f7e10015fd72b2',
      },
      {
        _id: '60cc23dc47f7e10015fd72b4',
        courseCode: 'KINESIOL 4590',
        shortName: 'Kinesiology 4590 - CLINICAL  KINESIOLOGY PLACEMENT',
        courseName: 'Kinesiology 4590 - CLINICAL  KINESIOLOGY PLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dc47f7e10015fd72b4',
      },
      {
        _id: '60cc23dc47f7e10015fd72b6',
        courseCode: 'KINESIOL 4995',
        shortName: 'Kinesiology 4995F/G - PRACTICUM',
        courseName: 'Kinesiology 4995F/G - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dc47f7e10015fd72b6',
      },
      {
        _id: '60cc23dc47f7e10015fd72b8',
        courseCode: 'KINESIOL 4996',
        shortName: 'Kinesiology 4996F/G - PRACTICUM',
        courseName: 'Kinesiology 4996F/G - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dc47f7e10015fd72b8',
      },
      {
        _id: '60cc23dd47f7e10015fd72ba',
        courseCode: 'KOREAN 1030',
        shortName: 'Korean 1030 - KOREAN FOR BEGINNERS',
        courseName: 'Korean 1030 - KOREAN FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dd47f7e10015fd72ba',
      },
      {
        _id: '60cc23dd47f7e10015fd72bc',
        courseCode: 'KOREAN 1035',
        shortName: 'Korean 1035 - BEGINNER KOREAN FOR HERITAGE SPEAKERS',
        courseName: 'Korean 1035 - BEGINNER KOREAN FOR HERITAGE SPEAKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dd47f7e10015fd72bc',
      },
      {
        _id: '60cc23dd47f7e10015fd72be',
        courseCode: 'KOREAN 2200',
        shortName: 'Korean 2200 - INTERMEDIATE KOREAN',
        courseName: 'Korean 2200 - INTERMEDIATE KOREAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dd47f7e10015fd72be',
      },
      {
        _id: '60cc23dd47f7e10015fd72c0',
        courseCode: 'LATIN 1000',
        shortName: 'Latin 1000 - INTRODUCTORY LATIN',
        courseName: 'Latin 1000 - INTRODUCTORY LATIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23dd47f7e10015fd72c0',
      },
      {
        _id: '60cc23de47f7e10015fd72c2',
        courseCode: 'LATIN 2000',
        shortName: 'Latin 2000 - ADVANCED LATIN',
        courseName: 'Latin 2000 - ADVANCED LATIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23de47f7e10015fd72c2',
      },
      {
        _id: '60cc23de47f7e10015fd72c4',
        courseCode: 'LATIN 3100',
        shortName: 'Latin 3100A/B - LATIN PROSE SELECTIONS',
        courseName: 'Latin 3100A/B - LATIN PROSE SELECTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23de47f7e10015fd72c4',
      },
      {
        _id: '60cc23de47f7e10015fd72c6',
        courseCode: 'LATIN 3200',
        shortName: 'Latin 3200A/B - LATIN POETRY SELECTIONS',
        courseName: 'Latin 3200A/B - LATIN POETRY SELECTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23de47f7e10015fd72c6',
      },
      {
        _id: '60cc23de47f7e10015fd72c8',
        courseCode: 'LATIN 4903',
        shortName: 'Latin 4903A/B - SPECIAL TOPICS IN LATIN',
        courseName: 'Latin 4903A/B - SPECIAL TOPICS IN LATIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23de47f7e10015fd72c8',
      },
      {
        _id: '60cc23df47f7e10015fd72ca',
        courseCode: 'LATIN 4904',
        shortName: 'Latin 4904A/B - SPECIAL TOPICS IN LATIN',
        courseName: 'Latin 4904A/B - SPECIAL TOPICS IN LATIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23df47f7e10015fd72ca',
      },
      {
        _id: '60cc23df47f7e10015fd72cc',
        courseCode: 'LATIN 4905',
        shortName: 'Latin 4905A/B - SPECIAL TOPICS IN LATIN',
        courseName: 'Latin 4905A/B - SPECIAL TOPICS IN LATIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23df47f7e10015fd72cc',
      },
      {
        _id: '60cc23df47f7e10015fd72ce',
        courseCode: 'LATIN 4906',
        shortName: 'Latin 4906A/B - SPECIAL TOPICS IN LATIN',
        courseName: 'Latin 4906A/B - SPECIAL TOPICS IN LATIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23df47f7e10015fd72ce',
      },
      {
        _id: '60cc23df47f7e10015fd72d0',
        courseCode: 'LAW 2101',
        shortName: 'Law 2101 - INTRODUCTION TO LAW',
        courseName: 'Law 2101 - INTRODUCTION TO LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23df47f7e10015fd72d0',
      },
      {
        _id: '60cc23e047f7e10015fd72d2',
        courseCode: 'LAW 2201',
        shortName: 'Law 2201A/B - INTRODUCTION TO CANADIAN PUBLIC LAW',
        courseName: 'Law 2201A/B - INTRODUCTION TO CANADIAN PUBLIC LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e047f7e10015fd72d2',
      },
      {
        _id: '60cc23e047f7e10015fd72d4',
        courseCode: 'LAW 3101',
        shortName: 'Law 3101A/B - HEALTHCARE LAW',
        courseName: 'Law 3101A/B - HEALTHCARE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e047f7e10015fd72d4',
      },
      {
        _id: '60cc23e047f7e10015fd72d6',
        courseCode: 'LAW 5110',
        shortName: 'Law 5110 - CONSTITUTIONAL LAW',
        courseName: 'Law 5110 - CONSTITUTIONAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e047f7e10015fd72d6',
      },
      {
        _id: '60cc23e047f7e10015fd72d8',
        courseCode: 'LAW 5115',
        shortName: 'Law 5115 - CONTRACTS',
        courseName: 'Law 5115 - CONTRACTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e047f7e10015fd72d8',
      },
      {
        _id: '60cc23e147f7e10015fd72da',
        courseCode: 'LAW 5120',
        shortName: 'Law 5120 - CRIMINAL LAW',
        courseName: 'Law 5120 - CRIMINAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e147f7e10015fd72da',
      },
      {
        _id: '60cc23e147f7e10015fd72dc',
        courseCode: 'LAW 5135',
        shortName: 'Law 5135 - LEGAL RESEARCH, WRITING & ADVOCACY',
        courseName: 'Law 5135 - LEGAL RESEARCH, WRITING & ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e147f7e10015fd72dc',
      },
      {
        _id: '60cc23e147f7e10015fd72de',
        courseCode: 'LAW 5136',
        shortName: 'Law 5136A - LEGAL RESEARCH, WRITING & ADVOCACY',
        courseName: 'Law 5136A - LEGAL RESEARCH, WRITING & ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e147f7e10015fd72de',
      },
      {
        _id: '60cc23e147f7e10015fd72e0',
        courseCode: 'LAW 5140',
        shortName: 'Law 5140 - PROPERTY',
        courseName: 'Law 5140 - PROPERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e147f7e10015fd72e0',
      },
      {
        _id: '60cc23e147f7e10015fd72e2',
        courseCode: 'LAW 5145',
        shortName: 'Law 5145 - TORTS',
        courseName: 'Law 5145 - TORTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e147f7e10015fd72e2',
      },
      {
        _id: '60cc23e247f7e10015fd72e4',
        courseCode: 'LAW 5150',
        shortName: 'Law 5150A/C/D - LEGAL ETHICS & PROFESSIONALISM',
        courseName: 'Law 5150A/C/D - LEGAL ETHICS & PROFESSIONALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e247f7e10015fd72e4',
      },
      {
        _id: '60cc23e247f7e10015fd72e6',
        courseCode: 'LAW 5155',
        shortName: 'Law 5155 - ORIENTATION TO LAW & THE LEGAL SYSTEM',
        courseName: 'Law 5155 - ORIENTATION TO LAW & THE LEGAL SYSTEM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e247f7e10015fd72e6',
      },
      {
        _id: '60cc23e247f7e10015fd72e8',
        courseCode: 'LAW 5200',
        shortName: 'Law 5200A/C/D - ADMINISTRATIVE LAW',
        courseName: 'Law 5200A/C/D - ADMINISTRATIVE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e247f7e10015fd72e8',
      },
      {
        _id: '60cc23e347f7e10015fd72ea',
        courseCode: 'LAW 5205',
        shortName: 'Law 5205A/D - CIVIL PROCEDURE',
        courseName: 'Law 5205A/D - CIVIL PROCEDURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e347f7e10015fd72ea',
      },
      {
        _id: '60cc23e347f7e10015fd72ec',
        courseCode: 'LAW 5210',
        shortName: 'Law 5210A/C/D - CORPORATE LAW',
        courseName: 'Law 5210A/C/D - CORPORATE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e347f7e10015fd72ec',
      },
      {
        _id: '60cc23e347f7e10015fd72ee',
        courseCode: 'LAW 5215',
        shortName: 'Law 5215A/C/D - EVIDENCE',
        courseName: 'Law 5215A/C/D - EVIDENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e347f7e10015fd72ee',
      },
      {
        _id: '60cc23e347f7e10015fd72f0',
        courseCode: 'LAW 5220',
        shortName: 'Law 5220A/D - INCOME TAXATION',
        courseName: 'Law 5220A/D - INCOME TAXATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e347f7e10015fd72f0',
      },
      {
        _id: '60cc23e347f7e10015fd72f2',
        courseCode: 'LAW 5225',
        shortName: 'Law 5225A/D - PUBLIC INTERNATIONAL LAW',
        courseName: 'Law 5225A/D - PUBLIC INTERNATIONAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e347f7e10015fd72f2',
      },
      {
        _id: '60cc23e447f7e10015fd72f4',
        courseCode: 'LAW 5230',
        shortName: 'Law 5230A/C/D - TRUSTS',
        courseName: 'Law 5230A/C/D - TRUSTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e447f7e10015fd72f4',
      },
      {
        _id: '60cc23e447f7e10015fd72f6',
        courseCode: 'LAW 5304',
        shortName: 'Law 5304A/C/D - ENVIRONMENTAL LAW',
        courseName: 'Law 5304A/C/D - ENVIRONMENTAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e447f7e10015fd72f6',
      },
      {
        _id: '60cc23e447f7e10015fd72f8',
        courseCode: 'LAW 5310',
        shortName: 'Law 5310A/C/D - HEALTHCARE LAW & POLICY',
        courseName: 'Law 5310A/C/D - HEALTHCARE LAW & POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e447f7e10015fd72f8',
      },
      {
        _id: '60cc23e447f7e10015fd72fa',
        courseCode: 'LAW 5315',
        shortName: 'Law 5315A/D - LAW & SOCIAL WELFARE',
        courseName: 'Law 5315A/D - LAW & SOCIAL WELFARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e447f7e10015fd72fa',
      },
      {
        _id: '60cc23e547f7e10015fd72fc',
        courseCode: 'LAW 5322',
        shortName: 'Law 5322A/D - MUNICIPAL LAW',
        courseName: 'Law 5322A/D - MUNICIPAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e547f7e10015fd72fc',
      },
      {
        _id: '60cc23e547f7e10015fd72fe',
        courseCode: 'LAW 5325',
        shortName: 'Law 5325A/C/D - ABORIGINAL LAW',
        courseName: 'Law 5325A/C/D - ABORIGINAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e547f7e10015fd72fe',
      },
      {
        _id: '60cc23e547f7e10015fd7300',
        courseCode: 'LAW 5330',
        shortName:
          'Law 5330A/C/D - ADVANCED CONSTITUTIONAL LAW-CHARTER OF RIGHTS',
        courseName:
          'Law 5330A/C/D - ADVANCED CONSTITUTIONAL LAW-CHARTER OF RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e547f7e10015fd7300',
      },
      {
        _id: '60cc23e547f7e10015fd7302',
        courseCode: 'LAW 5335',
        shortName: 'Law 5335A - ADVANCED CONSTITUTIONAL LAW-FEDERALISM',
        courseName: 'Law 5335A - ADVANCED CONSTITUTIONAL LAW-FEDERALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e547f7e10015fd7302',
      },
      {
        _id: '60cc23e647f7e10015fd7304',
        courseCode: 'LAW 5336',
        shortName: 'Law 5336A/C/D - CONSTITUTIONAL THEORY',
        courseName: 'Law 5336A/C/D - CONSTITUTIONAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e647f7e10015fd7304',
      },
      {
        _id: '60cc23e647f7e10015fd7306',
        courseCode: 'LAW 5340',
        shortName: 'Law 5340A/D - CANADIAN HUMAN RIGHTS LAW',
        courseName: 'Law 5340A/D - CANADIAN HUMAN RIGHTS LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e647f7e10015fd7306',
      },
      {
        _id: '60cc23e647f7e10015fd7308',
        courseCode: 'LAW 5350',
        shortName: 'Law 5350A/D - MEDIA LAW',
        courseName: 'Law 5350A/D - MEDIA LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e647f7e10015fd7308',
      },
      {
        _id: '60cc23e647f7e10015fd730a',
        courseCode: 'LAW 5355',
        shortName: 'Law 5355A/C/D - SEX DISCRIMINATION AND THE LAW',
        courseName: 'Law 5355A/C/D - SEX DISCRIMINATION AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e647f7e10015fd730a',
      },
      {
        _id: '60cc23e747f7e10015fd730c',
        courseCode: 'LAW 5360',
        shortName: 'Law 5360A/C/D - ADVANCED CRIMINAL LAW',
        courseName: 'Law 5360A/C/D - ADVANCED CRIMINAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e747f7e10015fd730c',
      },
      {
        _id: '60cc23e747f7e10015fd730e',
        courseCode: 'LAW 5365',
        shortName: 'Law 5365A/C/D - CRIMINAL LAW EXTERNSHIP',
        courseName: 'Law 5365A/C/D - CRIMINAL LAW EXTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e747f7e10015fd730e',
      },
      {
        _id: '60cc23e747f7e10015fd7310',
        courseCode: 'LAW 5370',
        shortName: 'Law 5370A - CRIMINAL LAW ADVOCACY',
        courseName: 'Law 5370A - CRIMINAL LAW ADVOCACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e747f7e10015fd7310',
      },
      {
        _id: '60cc23e747f7e10015fd7312',
        courseCode: 'LAW 5375',
        shortName: 'Law 5375A/C - CRIMINAL PROCEDURE',
        courseName: 'Law 5375A/C - CRIMINAL PROCEDURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e747f7e10015fd7312',
      },
      {
        _id: '60cc23e747f7e10015fd7314',
        courseCode: 'LAW 5377',
        shortName: 'Law 5377A/C/D - ONTARIO COURT OF JUSTICE PLACEMENT PROGRAM',
        courseName:
          'Law 5377A/C/D - ONTARIO COURT OF JUSTICE PLACEMENT PROGRAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e747f7e10015fd7314',
      },
      {
        _id: '60cc23e847f7e10015fd7316',
        courseCode: 'LAW 5378',
        shortName: 'Law 5378A/C/D - REGULATORY OFFENCES',
        courseName: 'Law 5378A/C/D - REGULATORY OFFENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e847f7e10015fd7316',
      },
      {
        _id: '60cc23e847f7e10015fd7318',
        courseCode: 'LAW 5380',
        shortName: 'Law 5380A/C/D - SENTENCING',
        courseName: 'Law 5380A/C/D - SENTENCING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e847f7e10015fd7318',
      },
      {
        _id: '60cc23e847f7e10015fd731a',
        courseCode: 'LAW 5405',
        shortName: 'Law 5405A/C/D - SEMINAR IN ADVANCED FAMILY LAW',
        courseName: 'Law 5405A/C/D - SEMINAR IN ADVANCED FAMILY LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e847f7e10015fd731a',
      },
      {
        _id: '60cc23e847f7e10015fd731c',
        courseCode: 'LAW 5410',
        shortName: "Law 5410A/C/D - CHILDREN'S LAW",
        courseName: "Law 5410A/C/D - CHILDREN'S LAW",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e847f7e10015fd731c',
      },
      {
        _id: '60cc23e947f7e10015fd731e',
        courseCode: 'LAW 5415',
        shortName: 'Law 5415A/C/D - FAMILY LAW',
        courseName: 'Law 5415A/C/D - FAMILY LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e947f7e10015fd731e',
      },
      {
        _id: '60cc23e947f7e10015fd7320',
        courseCode: 'LAW 5425',
        shortName: 'Law 5425A/D - IMMIGRATION & REFUGEE LAW',
        courseName: 'Law 5425A/D - IMMIGRATION & REFUGEE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e947f7e10015fd7320',
      },
      {
        _id: '60cc23e947f7e10015fd7322',
        courseCode: 'LAW 5427',
        shortName: 'Law 5427A/C/D - INTERNATIONAL ENVIRONMENTAL LAW',
        courseName: 'Law 5427A/C/D - INTERNATIONAL ENVIRONMENTAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e947f7e10015fd7322',
      },
      {
        _id: '60cc23e947f7e10015fd7324',
        courseCode: 'LAW 5429',
        shortName: 'Law 5429A/C/D - INTERNATIONAL CRIMINAL LAW',
        courseName: 'Law 5429A/C/D - INTERNATIONAL CRIMINAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23e947f7e10015fd7324',
      },
      {
        _id: '60cc23ea47f7e10015fd7326',
        courseCode: 'LAW 5430',
        shortName: 'Law 5430A/C/D - INTERNATIONAL HUMAN RIGHTS',
        courseName: 'Law 5430A/C/D - INTERNATIONAL HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ea47f7e10015fd7326',
      },
      {
        _id: '60cc23ea47f7e10015fd7328',
        courseCode: 'LAW 5432',
        shortName: 'Law 5432A/C/D - INTERNATIONAL ORGANIZATIONS',
        courseName: 'Law 5432A/C/D - INTERNATIONAL ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ea47f7e10015fd7328',
      },
      {
        _id: '60cc23ea47f7e10015fd732a',
        courseCode: 'LAW 5435',
        shortName: 'Law 5435A/D - INTERNATIONAL TAX',
        courseName: 'Law 5435A/D - INTERNATIONAL TAX',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ea47f7e10015fd732a',
      },
      {
        _id: '60cc23ea47f7e10015fd732c',
        courseCode: 'LAW 5440',
        shortName: 'Law 5440A/C/D - TAX PLANNING',
        courseName: 'Law 5440A/C/D - TAX PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ea47f7e10015fd732c',
      },
      {
        _id: '60cc23ea47f7e10015fd732e',
        courseCode: 'LAW 5445',
        shortName: 'Law 5445A/D - TAX POLICY',
        courseName: 'Law 5445A/D - TAX POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ea47f7e10015fd732e',
      },
      {
        _id: '60cc23eb47f7e10015fd7330',
        courseCode: 'LAW 5455',
        shortName: "Law 5455D - TAX'N CORPORATIONS & SHAREHOLDERS",
        courseName: "Law 5455D - TAX'N CORPORATIONS & SHAREHOLDERS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23eb47f7e10015fd7330',
      },
      {
        _id: '60cc23eb47f7e10015fd7332',
        courseCode: 'LAW 5500',
        shortName: 'Law 5500A/D - BANKING LAW',
        courseName: 'Law 5500A/D - BANKING LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23eb47f7e10015fd7332',
      },
      {
        _id: '60cc23eb47f7e10015fd7334',
        courseCode: 'LAW 5505',
        shortName: 'Law 5505A/C/D - BANKRUPTCY & INSOLVENCY LAW',
        courseName: 'Law 5505A/C/D - BANKRUPTCY & INSOLVENCY LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23eb47f7e10015fd7334',
      },
      {
        _id: '60cc23eb47f7e10015fd7336',
        courseCode: 'LAW 5510',
        shortName: 'Law 5510A/D - COMMERCIAL LAW',
        courseName: 'Law 5510A/D - COMMERCIAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23eb47f7e10015fd7336',
      },
      {
        _id: '60cc23ec47f7e10015fd7338',
        courseCode: 'LAW 5511',
        shortName: 'Law 5511A/D - ENTERTAINMENT LAW',
        courseName: 'Law 5511A/D - ENTERTAINMENT LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ec47f7e10015fd7338',
      },
      {
        _id: '60cc23ec47f7e10015fd733a',
        courseCode: 'LAW 5512',
        shortName: 'Law 5512A/D - FRANCHISE LAW',
        courseName: 'Law 5512A/D - FRANCHISE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ec47f7e10015fd733a',
      },
      {
        _id: '60cc23ec47f7e10015fd733c',
        courseCode: 'LAW 5513',
        shortName: 'Law 5513A/D - THE ART OF THE DEAL',
        courseName: 'Law 5513A/D - THE ART OF THE DEAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ec47f7e10015fd733c',
      },
      {
        _id: '60cc23ec47f7e10015fd733e',
        courseCode: 'LAW 5515',
        shortName: 'Law 5515A/C/D - ADV RESTRUCTURING & INSOLVENCY LAW',
        courseName: 'Law 5515A/C/D - ADV RESTRUCTURING & INSOLVENCY LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ec47f7e10015fd733e',
      },
      {
        _id: '60cc23ed47f7e10015fd7340',
        courseCode: 'LAW 5517',
        shortName: 'Law 5517A/C/D - SHAREHOLDER REMEDIES',
        courseName: 'Law 5517A/C/D - SHAREHOLDER REMEDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ed47f7e10015fd7340',
      },
      {
        _id: '60cc23ed47f7e10015fd7342',
        courseCode: 'LAW 5518',
        shortName: 'Law 5518A/D - ACCESS TO JUSTICE',
        courseName: 'Law 5518A/D - ACCESS TO JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ed47f7e10015fd7342',
      },
      {
        _id: '60cc23ed47f7e10015fd7344',
        courseCode: 'LAW 5525',
        shortName: 'Law 5525A - SECURED TRANSACTIONS',
        courseName: 'Law 5525A - SECURED TRANSACTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ed47f7e10015fd7344',
      },
      {
        _id: '60cc23ed47f7e10015fd7346',
        courseCode: 'LAW 5528',
        shortName: 'Law 5528A/D - ADVANCED CONTRACT ISSUES',
        courseName: 'Law 5528A/D - ADVANCED CONTRACT ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ed47f7e10015fd7346',
      },
      {
        _id: '60cc23ee47f7e10015fd7348',
        courseCode: 'LAW 5530',
        shortName: 'Law 5530A/C/D - ADVANCED TORTS',
        courseName: 'Law 5530A/C/D - ADVANCED TORTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ee47f7e10015fd7348',
      },
      {
        _id: '60cc23ee47f7e10015fd734a',
        courseCode: 'LAW 5535',
        shortName: 'Law 5535A/C/D - RESTITUTION & UNJUST ENRICHMENT',
        courseName: 'Law 5535A/C/D - RESTITUTION & UNJUST ENRICHMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ee47f7e10015fd734a',
      },
      {
        _id: '60cc23ee47f7e10015fd734c',
        courseCode: 'LAW 5538',
        shortName: 'Law 5538A/D - BUSINESS AND LAW SCHOLARSHIP SEMINAR',
        courseName: 'Law 5538A/D - BUSINESS AND LAW SCHOLARSHIP SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ee47f7e10015fd734c',
      },
      {
        _id: '60cc23ee47f7e10015fd734e',
        courseCode: 'LAW 5540',
        shortName: 'Law 5540A/C/D - ADVANCED CORPORATE LAW',
        courseName: 'Law 5540A/C/D - ADVANCED CORPORATE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ee47f7e10015fd734e',
      },
      {
        _id: '60cc23ee47f7e10015fd7350',
        courseCode: 'LAW 5545',
        shortName: 'Law 5545A/C - CASE STUDIES IN BUSINESS LAW',
        courseName: 'Law 5545A/C - CASE STUDIES IN BUSINESS LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ee47f7e10015fd7350',
      },
      {
        _id: '60cc23ef47f7e10015fd7352',
        courseCode: 'LAW 5550',
        shortName: 'Law 5550A/D - COMPETITION LAW',
        courseName: 'Law 5550A/D - COMPETITION LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ef47f7e10015fd7352',
      },
      {
        _id: '60cc23ef47f7e10015fd7354',
        courseCode: 'LAW 5555',
        shortName: 'Law 5555A/C/D - CORPORATE FINANCE',
        courseName: 'Law 5555A/C/D - CORPORATE FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ef47f7e10015fd7354',
      },
      {
        _id: '60cc23ef47f7e10015fd7356',
        courseCode: 'LAW 5556',
        shortName:
          'Law 5556A/C/D - MERGERS, ACQUISITIONS & OTHER CHANGES TO CORPORATE CONTROL',
        courseName:
          'Law 5556A/C/D - MERGERS, ACQUISITIONS & OTHER CHANGES TO CORPORATE CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ef47f7e10015fd7356',
      },
      {
        _id: '60cc23ef47f7e10015fd7358',
        courseCode: 'LAW 5558',
        shortName: 'Law 5558A/C/D - SECURITIES LAW: CONCEPTS AND PRACTICE',
        courseName: 'Law 5558A/C/D - SECURITIES LAW: CONCEPTS AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ef47f7e10015fd7358',
      },
      {
        _id: '60cc23f047f7e10015fd735a',
        courseCode: 'LAW 5559',
        shortName: 'Law 5559A/C/D - SECURITIES REGULATION',
        courseName: 'Law 5559A/C/D - SECURITIES REGULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f047f7e10015fd735a',
      },
      {
        _id: '60cc23f047f7e10015fd735c',
        courseCode: 'LAW 5561',
        shortName: 'Law 5561A/C/D - CORPORATE SOCIAL RESPONSIBILITY',
        courseName: 'Law 5561A/C/D - CORPORATE SOCIAL RESPONSIBILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f047f7e10015fd735c',
      },
      {
        _id: '60cc23f047f7e10015fd735e',
        courseCode: 'LAW 5562',
        shortName: 'Law 5562A/D - MINING LAW AND SUSTAINABILITY',
        courseName: 'Law 5562A/D - MINING LAW AND SUSTAINABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f047f7e10015fd735e',
      },
      {
        _id: '60cc23f047f7e10015fd7360',
        courseCode: 'LAW 5563',
        shortName: "Law 5563A/D - MINING FINANCE SPEAKERS' SERIES",
        courseName: "Law 5563A/D - MINING FINANCE SPEAKERS' SERIES",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f047f7e10015fd7360',
      },
      {
        _id: '60cc23f147f7e10015fd7362',
        courseCode: 'LAW 5565',
        shortName: 'Law 5565A/C/D - INSURANCE LAW',
        courseName: 'Law 5565A/C/D - INSURANCE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f147f7e10015fd7362',
      },
      {
        _id: '60cc23f147f7e10015fd7364',
        courseCode: 'LAW 5568',
        shortName: 'Law 5568A/D - YOUTH CRIMINAL LAW',
        courseName: 'Law 5568A/D - YOUTH CRIMINAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f147f7e10015fd7364',
      },
      {
        _id: '60cc23f147f7e10015fd7366',
        courseCode: 'LAW 5600',
        shortName: 'Law 5600A/C/D - ADVANCED INTELLECTUAL PROPERTY',
        courseName: 'Law 5600A/C/D - ADVANCED INTELLECTUAL PROPERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f147f7e10015fd7366',
      },
      {
        _id: '60cc23f147f7e10015fd7368',
        courseCode: 'LAW 5601',
        shortName:
          'Law 5601A/C/D - WORLDISCOVERIES INTELLECTUAL PROPERTY INTERNSHIP',
        courseName:
          'Law 5601A/C/D - WORLDISCOVERIES INTELLECTUAL PROPERTY INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f147f7e10015fd7368',
      },
      {
        _id: '60cc23f247f7e10015fd736a',
        courseCode: 'LAW 5610',
        shortName: 'Law 5610A/C/D - ADVANCED PATENT LAW',
        courseName: 'Law 5610A/C/D - ADVANCED PATENT LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f247f7e10015fd736a',
      },
      {
        _id: '60cc23f247f7e10015fd736c',
        courseCode: 'LAW 5613',
        shortName: 'Law 5613A/C/D - CAPSTONE IN BUSINESS LAW',
        courseName: 'Law 5613A/C/D - CAPSTONE IN BUSINESS LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f247f7e10015fd736c',
      },
      {
        _id: '60cc23f247f7e10015fd736e',
        courseCode: 'LAW 5614',
        shortName: 'Law 5614A/C/D - CAPSTONE IN CRIMINAL LAW',
        courseName: 'Law 5614A/C/D - CAPSTONE IN CRIMINAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f247f7e10015fd736e',
      },
      {
        _id: '60cc23f247f7e10015fd7370',
        courseCode: 'LAW 5615',
        shortName: 'Law 5615A/C/D - BIOTECHNOLOGY LAW',
        courseName: 'Law 5615A/C/D - BIOTECHNOLOGY LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f247f7e10015fd7370',
      },
      {
        _id: '60cc23f247f7e10015fd7372',
        courseCode: 'LAW 5616',
        shortName: 'Law 5616A/C/D - CAPSTONE IN LITIGATION',
        courseName: 'Law 5616A/C/D - CAPSTONE IN LITIGATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f247f7e10015fd7372',
      },
      {
        _id: '60cc23f347f7e10015fd7374',
        courseCode: 'LAW 5617',
        shortName: 'Law 5617A/D - PHARMACEUTICAL & HEALTH REGULATION',
        courseName: 'Law 5617A/D - PHARMACEUTICAL & HEALTH REGULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f347f7e10015fd7374',
      },
      {
        _id: '60cc23f347f7e10015fd7376',
        courseCode: 'LAW 5625',
        shortName: 'Law 5625A/D - INTELLECTUAL PROPERTY LAW',
        courseName: 'Law 5625A/D - INTELLECTUAL PROPERTY LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f347f7e10015fd7376',
      },
      {
        _id: '60cc23f347f7e10015fd7378',
        courseCode: 'LAW 5627',
        shortName: 'Law 5627A/D - COMPARATIVE COPYRIGHT LAW',
        courseName: 'Law 5627A/D - COMPARATIVE COPYRIGHT LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f347f7e10015fd7378',
      },
      {
        _id: '60cc23f347f7e10015fd737a',
        courseCode: 'LAW 5630',
        shortName:
          'Law 5630A/C/D - INTERNATIONAL PROTECTION OF INTELLECTUAL PROPERTY',
        courseName:
          'Law 5630A/C/D - INTERNATIONAL PROTECTION OF INTELLECTUAL PROPERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f347f7e10015fd737a',
      },
      {
        _id: '60cc23f447f7e10015fd737c',
        courseCode: 'LAW 5635',
        shortName: 'Law 5635A/C/D - INTERNATIONAL TRADE LAW',
        courseName: 'Law 5635A/C/D - INTERNATIONAL TRADE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f447f7e10015fd737c',
      },
      {
        _id: '60cc23f447f7e10015fd737e',
        courseCode: 'LAW 5636',
        shortName: 'Law 5636A/D - IMMIGRATION AND REFUGEE LAW',
        courseName: 'Law 5636A/D - IMMIGRATION AND REFUGEE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f447f7e10015fd737e',
      },
      {
        _id: '60cc23f447f7e10015fd7380',
        courseCode: 'LAW 5640',
        shortName: 'Law 5640A/C/D - INERNATIONAL BUSINESS TRANSACTIONS',
        courseName: 'Law 5640A/C/D - INERNATIONAL BUSINESS TRANSACTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f447f7e10015fd7380',
      },
      {
        _id: '60cc23f447f7e10015fd7382',
        courseCode: 'LAW 5644',
        shortName:
          'Law 5644A/D - CAPSTONE IN INTELLECTUAL PROPERTY, INFORMATION AND TECHNOLOGY',
        courseName:
          'Law 5644A/D - CAPSTONE IN INTELLECTUAL PROPERTY, INFORMATION AND TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f447f7e10015fd7382',
      },
      {
        _id: '60cc23f547f7e10015fd7384',
        courseCode: 'LAW 5645',
        shortName: 'Law 5645A/C/D - ADVANCED LABOUR LAW',
        courseName: 'Law 5645A/C/D - ADVANCED LABOUR LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f547f7e10015fd7384',
      },
      {
        _id: '60cc23f547f7e10015fd7386',
        courseCode: 'LAW 5646',
        shortName: 'Law 5646A/D - ADVANCED LABOUR LAW SPEAKERS SERIES',
        courseName: 'Law 5646A/D - ADVANCED LABOUR LAW SPEAKERS SERIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f547f7e10015fd7386',
      },
      {
        _id: '60cc23f547f7e10015fd7388',
        courseCode: 'LAW 5650',
        shortName: 'Law 5650A/D - EMPLOYMENT LAW',
        courseName: 'Law 5650A/D - EMPLOYMENT LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f547f7e10015fd7388',
      },
      {
        _id: '60cc23f547f7e10015fd738a',
        courseCode: 'LAW 5655',
        shortName: 'Law 5655A/C/D - LABOUR LAW',
        courseName: 'Law 5655A/C/D - LABOUR LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f547f7e10015fd738a',
      },
      {
        _id: '60cc23f647f7e10015fd738c',
        courseCode: 'LAW 5660',
        shortName: 'Law 5660A/D - PENSIONS AND BENEFITS',
        courseName: 'Law 5660A/D - PENSIONS AND BENEFITS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f647f7e10015fd738c',
      },
      {
        _id: '60cc23f647f7e10015fd738e',
        courseCode: 'LAW 5665',
        shortName: 'Law 5665A/C/D - CONFLICT OF LAW',
        courseName: 'Law 5665A/C/D - CONFLICT OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f647f7e10015fd738e',
      },
      {
        _id: '60cc23f647f7e10015fd7390',
        courseCode: 'LAW 5670',
        shortName: 'Law 5670A/C/D - INTERNATIONAL COMMERCIAL LITIGATION',
        courseName: 'Law 5670A/C/D - INTERNATIONAL COMMERCIAL LITIGATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f647f7e10015fd7390',
      },
      {
        _id: '60cc23f647f7e10015fd7392',
        courseCode: 'LAW 5673',
        shortName: 'Law 5673D - FAMILY LAW NEGOTIATION COMPETITION',
        courseName: 'Law 5673D - FAMILY LAW NEGOTIATION COMPETITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f647f7e10015fd7392',
      },
      {
        _id: '60cc23f647f7e10015fd7394',
        courseCode: 'LAW 5675',
        shortName: 'Law 5675A/C/D - ESTATE PLANNING',
        courseName: 'Law 5675A/C/D - ESTATE PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f647f7e10015fd7394',
      },
      {
        _id: '60cc23f747f7e10015fd7396',
        courseCode: 'LAW 5680',
        shortName: 'Law 5680A/C/D - REAL ESTATE LAW',
        courseName: 'Law 5680A/C/D - REAL ESTATE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f747f7e10015fd7396',
      },
      {
        _id: '60cc23f747f7e10015fd7398',
        courseCode: 'LAW 5682',
        shortName: 'Law 5682A/D - PLANNING LAW',
        courseName: 'Law 5682A/D - PLANNING LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f747f7e10015fd7398',
      },
      {
        _id: '60cc23f747f7e10015fd739a',
        courseCode: 'LAW 5685',
        shortName: 'Law 5685A/D - WILLS',
        courseName: 'Law 5685A/D - WILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f747f7e10015fd739a',
      },
      {
        _id: '60cc23f747f7e10015fd739c',
        courseCode: 'LAW 5690',
        shortName: 'Law 5690A/C/D - LAW AND SPORT',
        courseName: 'Law 5690A/C/D - LAW AND SPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f747f7e10015fd739c',
      },
      {
        _id: '60cc23f847f7e10015fd739e',
        courseCode: 'LAW 5700',
        shortName: 'Law 5700A/C/D - LABOUR ARBITRATION & PROCEDURE',
        courseName: 'Law 5700A/C/D - LABOUR ARBITRATION & PROCEDURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f847f7e10015fd739e',
      },
      {
        _id: '60cc23f847f7e10015fd73a0',
        courseCode: 'LAW 5702',
        shortName: 'Law 5702A/D - CLASS ACTIONS',
        courseName: 'Law 5702A/D - CLASS ACTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f847f7e10015fd73a0',
      },
      {
        _id: '60cc23f847f7e10015fd73a2',
        courseCode: 'LAW 5705',
        shortName: 'Law 5705A/D - DISPUTE SETTLEMENT',
        courseName: 'Law 5705A/D - DISPUTE SETTLEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f847f7e10015fd73a2',
      },
      {
        _id: '60cc23f847f7e10015fd73a4',
        courseCode: 'LAW 5710',
        shortName: 'Law 5710A/C/D - NEGOTIATION AND MEDIATION',
        courseName: 'Law 5710A/C/D - NEGOTIATION AND MEDIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f847f7e10015fd73a4',
      },
      {
        _id: '60cc23f947f7e10015fd73a6',
        courseCode: 'LAW 5711',
        shortName: 'Law 5711A/D - NEGOTIATION & INTERNATIONAL CONFLICT',
        courseName: 'Law 5711A/D - NEGOTIATION & INTERNATIONAL CONFLICT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f947f7e10015fd73a6',
      },
      {
        _id: '60cc23f947f7e10015fd73a8',
        courseCode: 'LAW 5712',
        shortName: 'Law 5712A/C/D - KAWASKIMHON TALKING CIRCLE',
        courseName: 'Law 5712A/C/D - KAWASKIMHON TALKING CIRCLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f947f7e10015fd73a8',
      },
      {
        _id: '60cc23f947f7e10015fd73aa',
        courseCode: 'LAW 5715',
        shortName: 'Law 5715A/C - APPELLATE ADVOCACY COMPETITION',
        courseName: 'Law 5715A/C - APPELLATE ADVOCACY COMPETITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f947f7e10015fd73aa',
      },
      {
        _id: '60cc23f947f7e10015fd73ac',
        courseCode: 'LAW 5716',
        shortName: 'Law 5716A/C/D - JESSUP ADVOCACY COMPETITION',
        courseName: 'Law 5716A/C/D - JESSUP ADVOCACY COMPETITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23f947f7e10015fd73ac',
      },
      {
        _id: '60cc23fa47f7e10015fd73ae',
        courseCode: 'LAW 5720',
        shortName: 'Law 5720C - LABOUR ARBITRATION COMPETITION',
        courseName: 'Law 5720C - LABOUR ARBITRATION COMPETITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fa47f7e10015fd73ae',
      },
      {
        _id: '60cc23fa47f7e10015fd73b0',
        courseCode: 'LAW 5725',
        shortName: 'Law 5725C - TRIAL ADVOCACY COMPETITION',
        courseName: 'Law 5725C - TRIAL ADVOCACY COMPETITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fa47f7e10015fd73b0',
      },
      {
        _id: '60cc23fa47f7e10015fd73b2',
        courseCode: 'LAW 5730',
        shortName: 'Law 5730A/C/D - ADVANCED LEGAL ETHICS AND PROFESSIONALISM',
        courseName: 'Law 5730A/C/D - ADVANCED LEGAL ETHICS AND PROFESSIONALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fa47f7e10015fd73b2',
      },
      {
        _id: '60cc23fa47f7e10015fd73b4',
        courseCode: 'LAW 5735',
        shortName: 'Law 5735A/D - FRANCAIS JURIDIQUE',
        courseName: 'Law 5735A/D - FRANCAIS JURIDIQUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fa47f7e10015fd73b4',
      },
      {
        _id: '60cc23fa47f7e10015fd73b6',
        courseCode: 'LAW 5737',
        shortName: 'Law 5737 - WSTRN JOURNAL OF LEGAL STUDIES',
        courseName: 'Law 5737 - WSTRN JOURNAL OF LEGAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fa47f7e10015fd73b6',
      },
      {
        _id: '60cc23fb47f7e10015fd73b8',
        courseCode: 'LAW 5740',
        shortName: 'Law 5740A/D - LITERATURE AND LAW',
        courseName: 'Law 5740A/D - LITERATURE AND LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fb47f7e10015fd73b8',
      },
      {
        _id: '60cc23fb47f7e10015fd73ba',
        courseCode: 'LAW 5745',
        shortName: 'Law 5745A/C/D - CANADIAN LEGAL HISTORY',
        courseName: 'Law 5745A/C/D - CANADIAN LEGAL HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fb47f7e10015fd73ba',
      },
      {
        _id: '60cc23fb47f7e10015fd73bc',
        courseCode: 'LAW 5747',
        shortName: 'Law 5747A/D - LAW IN THE AFTERMATH OF WAR',
        courseName: 'Law 5747A/D - LAW IN THE AFTERMATH OF WAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fb47f7e10015fd73bc',
      },
      {
        _id: '60cc23fb47f7e10015fd73be',
        courseCode: 'LAW 5760',
        shortName: 'Law 5760A/D - JURISPRUDENCE I',
        courseName: 'Law 5760A/D - JURISPRUDENCE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fb47f7e10015fd73be',
      },
      {
        _id: '60cc23fc47f7e10015fd73c0',
        courseCode: 'LAW 5775',
        shortName: 'Law 5775A/D - STATUTORY INTERPRETATION',
        courseName: 'Law 5775A/D - STATUTORY INTERPRETATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fc47f7e10015fd73c0',
      },
      {
        _id: '60cc23fc47f7e10015fd73c2',
        courseCode: 'LAW 5780',
        shortName: 'Law 5780A/D - ADVANCED EVIDENCE',
        courseName: 'Law 5780A/D - ADVANCED EVIDENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fc47f7e10015fd73c2',
      },
      {
        _id: '60cc23fc47f7e10015fd73c4',
        courseCode: 'LAW 5785',
        shortName: 'Law 5785 - ADVANCED LITIGATION PRACTICE',
        courseName: 'Law 5785 - ADVANCED LITIGATION PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fc47f7e10015fd73c4',
      },
      {
        _id: '60cc23fc47f7e10015fd73c6',
        courseCode: 'LAW 5790',
        shortName: 'Law 5790A/C/D - LITIGATION PRACTICE',
        courseName: 'Law 5790A/C/D - LITIGATION PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fc47f7e10015fd73c6',
      },
      {
        _id: '60cc23fd47f7e10015fd73c8',
        courseCode: 'LAW 5794',
        shortName: 'Law 5794A/D - REMEDIES',
        courseName: 'Law 5794A/D - REMEDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fd47f7e10015fd73c8',
      },
      {
        _id: '60cc23fd47f7e10015fd73ca',
        courseCode: 'LAW 5798',
        shortName: 'Law 5798A/D - THE READING & STUDY PROGRAM',
        courseName: 'Law 5798A/D - THE READING & STUDY PROGRAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fd47f7e10015fd73ca',
      },
      {
        _id: '60cc23fd47f7e10015fd73cc',
        courseCode: 'LS 0010',
        shortName: 'Leadership Studies 0010A/B - INTRODUCTION TO LEADERSHIP',
        courseName: 'Leadership Studies 0010A/B - INTRODUCTION TO LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fd47f7e10015fd73cc',
      },
      {
        _id: '60cc23fe47f7e10015fd73ce',
        courseCode: 'LS 1032',
        shortName:
          'Leadership Studies 1032A/B - EXPLORING LEADERSHIP I: FOCUS ON THE LEADER',
        courseName:
          'Leadership Studies 1032A/B - EXPLORING LEADERSHIP I: FOCUS ON THE LEADER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fe47f7e10015fd73ce',
      },
      {
        _id: '60cc23fe47f7e10015fd73d0',
        courseCode: 'LS 1033',
        shortName:
          'Leadership Studies 1033A/B - EXPLORING LEADERSHIP II: FOCUS ON RELATIONSHIPS',
        courseName:
          'Leadership Studies 1033A/B - EXPLORING LEADERSHIP II: FOCUS ON RELATIONSHIPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fe47f7e10015fd73d0',
      },
      {
        _id: '60cc23fe47f7e10015fd73d2',
        courseCode: 'LS 1035',
        shortName:
          'Leadership Studies 1035A/B - LEADERSHIP FOR FOODS AND NUTRITION',
        courseName:
          'Leadership Studies 1035A/B - LEADERSHIP FOR FOODS AND NUTRITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fe47f7e10015fd73d2',
      },
      {
        _id: '60cc23fe47f7e10015fd73d4',
        courseCode: 'LS 2000',
        shortName: 'Leadership Studies 2000A/B - PRIMER ON LEADERSHIP',
        courseName: 'Leadership Studies 2000A/B - PRIMER ON LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23fe47f7e10015fd73d4',
      },
      {
        _id: '60cc23ff47f7e10015fd73d6',
        courseCode: 'LS 2210',
        shortName:
          'Leadership Studies 2210F/G - ASCENDING THE ABYSS: LEADING ETHICALLY IN DARK TIMES',
        courseName:
          'Leadership Studies 2210F/G - ASCENDING THE ABYSS: LEADING ETHICALLY IN DARK TIMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ff47f7e10015fd73d6',
      },
      {
        _id: '60cc23ff47f7e10015fd73d8',
        courseCode: 'LS 2232',
        shortName: 'Leadership Studies 2232A/B - BASIC LEADERSHIP STUDIES',
        courseName: 'Leadership Studies 2232A/B - BASIC LEADERSHIP STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ff47f7e10015fd73d8',
      },
      {
        _id: '60cc23ff47f7e10015fd73da',
        courseCode: 'LS 2233',
        shortName: 'Leadership Studies 2233A/B - WOMEN AND LEADERSHIP',
        courseName: 'Leadership Studies 2233A/B - WOMEN AND LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ff47f7e10015fd73da',
      },
      {
        _id: '60cc23ff47f7e10015fd73dc',
        courseCode: 'LS 2234',
        shortName:
          'Leadership Studies 2234A/B - UNDERSTANDING LEADERSHIP RESEARCH',
        courseName:
          'Leadership Studies 2234A/B - UNDERSTANDING LEADERSHIP RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ff47f7e10015fd73dc',
      },
      {
        _id: '60cc23ff47f7e10015fd73de',
        courseCode: 'LS 2240',
        shortName:
          'Leadership Studies 2240F/G - SPECIAL TOPICS IN LEADERSHIP STUDIES',
        courseName:
          'Leadership Studies 2240F/G - SPECIAL TOPICS IN LEADERSHIP STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc23ff47f7e10015fd73de',
      },
      {
        _id: '60cc240047f7e10015fd73e0',
        courseCode: 'LS 2330',
        shortName: 'Leadership Studies 2330A/B - LEADING CHANGE',
        courseName: 'Leadership Studies 2330A/B - LEADING CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240047f7e10015fd73e0',
      },
      {
        _id: '60cc240047f7e10015fd73e2',
        courseCode: 'LS 3330',
        shortName:
          'Leadership Studies 3330F/G - LEADING CHANGE IN ORGANIZATIONS',
        courseName:
          'Leadership Studies 3330F/G - LEADING CHANGE IN ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240047f7e10015fd73e2',
      },
      {
        _id: '60cc240047f7e10015fd73e4',
        courseCode: 'LS 3331',
        shortName: 'Leadership Studies 3331F/G - ADVANCED LEADERSHIP',
        courseName: 'Leadership Studies 3331F/G - ADVANCED LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240047f7e10015fd73e4',
      },
      {
        _id: '60cc240047f7e10015fd73e6',
        courseCode: 'LS 3333',
        shortName: 'Leadership Studies 3333A/B - LEADERSHIP DEVELOPMENT',
        courseName: 'Leadership Studies 3333A/B - LEADERSHIP DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240047f7e10015fd73e6',
      },
      {
        _id: '60cc240147f7e10015fd73e8',
        courseCode: 'LS 3334',
        shortName: 'Leadership Studies 3334F/G - LEADERSHIP IN CONTEXT',
        courseName: 'Leadership Studies 3334F/G - LEADERSHIP IN CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240147f7e10015fd73e8',
      },
      {
        _id: '60cc240147f7e10015fd73ea',
        courseCode: 'LS 3339',
        shortName:
          'Leadership Studies 3339A/B - SPECIAL TOPICS IN LEADERSHIP STUDIES',
        courseName:
          'Leadership Studies 3339A/B - SPECIAL TOPICS IN LEADERSHIP STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240147f7e10015fd73ea',
      },
      {
        _id: '60cc240147f7e10015fd73ec',
        courseCode: 'LS 3340',
        shortName:
          'Leadership Studies 3340A/B - SPECIAL TOPICS IN LEADERSHIP STUDIES',
        courseName:
          'Leadership Studies 3340A/B - SPECIAL TOPICS IN LEADERSHIP STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240147f7e10015fd73ec',
      },
      {
        _id: '60cc240147f7e10015fd73ee',
        courseCode: 'LS 4330',
        shortName:
          'Leadership Studies 4330A/B - LEADING CHANGE IN SOCIETY: EQUITY, DIVERSITY, AND INCLUSION',
        courseName:
          'Leadership Studies 4330A/B - LEADING CHANGE IN SOCIETY: EQUITY, DIVERSITY, AND INCLUSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240147f7e10015fd73ee',
      },
      {
        _id: '60cc240247f7e10015fd73f0',
        courseCode: 'LS 4333',
        shortName:
          'Leadership Studies 4333A/B - DEVELOPING LEADERSHIP FOR ORGANIZATIONS',
        courseName:
          'Leadership Studies 4333A/B - DEVELOPING LEADERSHIP FOR ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240247f7e10015fd73f0',
      },
      {
        _id: '60cc240247f7e10015fd73f2',
        courseCode: 'LS 4430',
        shortName:
          'Leadership Studies 4430F/G - INDEPENDENT STUDY IN LEADERSHIP STUDIES',
        courseName:
          'Leadership Studies 4430F/G - INDEPENDENT STUDY IN LEADERSHIP STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240247f7e10015fd73f2',
      },
      {
        _id: '60cc240247f7e10015fd73f4',
        courseCode: 'LS 4431',
        shortName:
          'Leadership Studies 4431F/G - LEADERSHIP IN THE 21ST CENTURY',
        courseName:
          'Leadership Studies 4431F/G - LEADERSHIP IN THE 21ST CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240247f7e10015fd73f4',
      },
      {
        _id: '60cc240247f7e10015fd73f6',
        courseCode: 'LS 4432',
        shortName: 'Leadership Studies 4432A/B - LEADERSHIP PRACTICUM',
        courseName: 'Leadership Studies 4432A/B - LEADERSHIP PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240247f7e10015fd73f6',
      },
      {
        _id: '60cc240347f7e10015fd73f8',
        courseCode: 'LS 4435',
        shortName: 'Leadership Studies 4435 - LEADERSHIP INTERNSHIP',
        courseName: 'Leadership Studies 4435 - LEADERSHIP INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240347f7e10015fd73f8',
      },
      {
        _id: '60cc240347f7e10015fd73fa',
        courseCode: 'LS 4436',
        shortName: 'Leadership Studies 4436 - INTERNSHIP WORK TERM',
        courseName: 'Leadership Studies 4436 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240347f7e10015fd73fa',
      },
      {
        _id: '60cc240347f7e10015fd73fc',
        courseCode: 'LS 4437',
        shortName: 'Leadership Studies 4437 - INTERNSHIP WORK TERM',
        courseName: 'Leadership Studies 4437 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240347f7e10015fd73fc',
      },
      {
        _id: '60cc240347f7e10015fd73fe',
        courseCode: 'LS 4438',
        shortName: 'Leadership Studies 4438 - INTERNSHIP WORK TERM',
        courseName: 'Leadership Studies 4438 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240347f7e10015fd73fe',
      },
      {
        _id: '60cc240347f7e10015fd7400',
        courseCode: 'LS 4440',
        shortName:
          'Leadership Studies 4440 - INDEPENDENT STUDY IN LEADERSHIP STUDIES',
        courseName:
          'Leadership Studies 4440 - INDEPENDENT STUDY IN LEADERSHIP STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240347f7e10015fd7400',
      },
      {
        _id: '60cc240447f7e10015fd7402',
        courseCode: 'LINGUIST 1028',
        shortName:
          'Linguistics 1028A/B - LINGUISTICS: APPLICATIONS & EXTENSIONS',
        courseName:
          'Linguistics 1028A/B - LINGUISTICS: APPLICATIONS & EXTENSIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240447f7e10015fd7402',
      },
      {
        _id: '60cc240447f7e10015fd7404',
        courseCode: 'LINGUIST 2242',
        shortName: 'Linguistics 2242A/B - PHONETICS',
        courseName: 'Linguistics 2242A/B - PHONETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240447f7e10015fd7404',
      },
      {
        _id: '60cc240447f7e10015fd7406',
        courseCode: 'LINGUIST 2244',
        shortName: 'Linguistics 2244A/B - SECOND LANGUAGE ACQUISITION',
        courseName: 'Linguistics 2244A/B - SECOND LANGUAGE ACQUISITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240447f7e10015fd7406',
      },
      {
        _id: '60cc240447f7e10015fd7408',
        courseCode: 'LINGUIST 2247',
        shortName: 'Linguistics 2247A/B - PHONOLOGICAL ANALYSIS',
        courseName: 'Linguistics 2247A/B - PHONOLOGICAL ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240447f7e10015fd7408',
      },
      {
        _id: '60cc240547f7e10015fd740a',
        courseCode: 'LINGUIST 2248',
        shortName: 'Linguistics 2248A/B - SYNTACTIC ANALYSIS',
        courseName: 'Linguistics 2248A/B - SYNTACTIC ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240547f7e10015fd740a',
      },
      {
        _id: '60cc240547f7e10015fd740c',
        courseCode: 'LINGUIST 2288',
        shortName: 'Linguistics 2288A/B - INTRODUCTORY GENERAL LINGUISTICS',
        courseName: 'Linguistics 2288A/B - INTRODUCTORY GENERAL LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240547f7e10015fd740c',
      },
      {
        _id: '60cc240547f7e10015fd740e',
        courseCode: 'LINGUIST 3100',
        shortName: 'Linguistics 3100A/B - LINGUISTICS AND LANGUAGE IMPAIRMENTS',
        courseName:
          'Linguistics 3100A/B - LINGUISTICS AND LANGUAGE IMPAIRMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240547f7e10015fd740e',
      },
      {
        _id: '60cc240547f7e10015fd7410',
        courseCode: 'LINGUIST 3102',
        shortName: 'Linguistics 3102A/B - LINGUISTICS ANALYSIS WITH COMPUTERS',
        courseName: 'Linguistics 3102A/B - LINGUISTICS ANALYSIS WITH COMPUTERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240547f7e10015fd7410',
      },
      {
        _id: '60cc240647f7e10015fd7412',
        courseCode: 'LINGUIST 3340',
        shortName: 'Linguistics 3340A/B - SPECIAL TOPICS IN LINGUISTICS',
        courseName: 'Linguistics 3340A/B - SPECIAL TOPICS IN LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240647f7e10015fd7412',
      },
      {
        _id: '60cc240647f7e10015fd7414',
        courseCode: 'LINGUIST 3390',
        shortName:
          'Linguistics 3390A/B - SUPERVISED READING/RESEARCH IN LINGUISITICS',
        courseName:
          'Linguistics 3390A/B - SUPERVISED READING/RESEARCH IN LINGUISITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240647f7e10015fd7414',
      },
      {
        _id: '60cc240647f7e10015fd7416',
        courseCode: 'LINGUIST 4247',
        shortName: 'Linguistics 4247A/B - ADVANCED PHONOLOGICAL ANALYSIS',
        courseName: 'Linguistics 4247A/B - ADVANCED PHONOLOGICAL ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240647f7e10015fd7416',
      },
      {
        _id: '60cc240647f7e10015fd7418',
        courseCode: 'LINGUIST 4248',
        shortName: 'Linguistics 4248A/B - ADVANCED SYNTACTIC ANALYSIS',
        courseName: 'Linguistics 4248A/B - ADVANCED SYNTACTIC ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240647f7e10015fd7418',
      },
      {
        _id: '60cc240747f7e10015fd741a',
        courseCode: 'LINGUIST 4490',
        shortName:
          'Linguistics 4490F/G - ADVANCED SUPERVISED READING/RESEARCH IN LINGUISTICS',
        courseName:
          'Linguistics 4490F/G - ADVANCED SUPERVISED READING/RESEARCH IN LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240747f7e10015fd741a',
      },
      {
        _id: '60cc240847f7e10015fd7421',
        courseCode: 'MOS 1022',
        shortName:
          'Management and Organizational Studies 1022F/G - INTRODUCTION TO AVIATION',
        courseName:
          'Management and Organizational Studies 1022F/G - INTRODUCTION TO AVIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240847f7e10015fd7421',
      },
      {
        _id: '60cc240847f7e10015fd7423',
        courseCode: 'MOS 1023',
        shortName:
          'Management and Organizational Studies 1023A/B - INTRODUCTION TO ACCOUNTING AND FINANCE',
        courseName:
          'Management and Organizational Studies 1023A/B - INTRODUCTION TO ACCOUNTING AND FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 10,
        id: '60cc240847f7e10015fd7423',
      },
      {
        _id: '60cc240847f7e10015fd7425',
        courseCode: 'MOS 1025',
        shortName:
          'Management and Organizational Studies 1025A/B - NONPROFIT ORGANIZATIONS AND THEIR ENVIRONMENT',
        courseName:
          'Management and Organizational Studies 1025A/B - NONPROFIT ORGANIZATIONS AND THEIR ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240847f7e10015fd7425',
      },
      {
        _id: '60cc240947f7e10015fd7427',
        courseCode: 'MOS 1033',
        shortName:
          'Management and Organizational Studies 1033A/B - INFORMATION TECHNOLOGY IN THE COMMERCIAL ENVIRONMENT',
        courseName:
          'Management and Organizational Studies 1033A/B - INFORMATION TECHNOLOGY IN THE COMMERCIAL ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240947f7e10015fd7427',
      },
      {
        _id: '60cc240947f7e10015fd7429',
        courseCode: 'MOS 2155',
        shortName:
          'Management and Organizational Studies 2155A/B - ORGANIZNAL HUMAN RLTNS',
        courseName:
          'Management and Organizational Studies 2155A/B - ORGANIZNAL HUMAN RLTNS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240947f7e10015fd7429',
      },
      {
        _id: '60cc240947f7e10015fd742b',
        courseCode: 'MOS 2180',
        shortName:
          'Management and Organizational Studies 2180 - ORGANIZATIONAL BEHAVIOUR',
        courseName:
          'Management and Organizational Studies 2180 - ORGANIZATIONAL BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240947f7e10015fd742b',
      },
      {
        _id: '60cc240a47f7e10015fd742d',
        courseCode: 'MOS 2181',
        shortName:
          'Management and Organizational Studies 2181A/B - ORGANIZATIONAL BEHAVIOR',
        courseName:
          'Management and Organizational Studies 2181A/B - ORGANIZATIONAL BEHAVIOR',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 38,
        id: '60cc240a47f7e10015fd742d',
      },
      {
        _id: '60cc240a47f7e10015fd742f',
        courseCode: 'MOS 2182',
        shortName:
          'Management and Organizational Studies 2182F/G - EQUITY, DIVERSITY, AND INCLUSION IN ORGANIZATIONS',
        courseName:
          'Management and Organizational Studies 2182F/G - EQUITY, DIVERSITY, AND INCLUSION IN ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240a47f7e10015fd742f',
      },
      {
        _id: '60cc240b47f7e10015fd7435',
        courseCode: 'MOS 2199',
        shortName:
          'Management and Organizational Studies 2199Y - PROFESSIONAL PILOT ACADEMIC INTERNSHIP I',
        courseName:
          'Management and Organizational Studies 2199Y - PROFESSIONAL PILOT ACADEMIC INTERNSHIP I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240b47f7e10015fd7435',
      },
      {
        _id: '60cc240b47f7e10015fd7437',
        courseCode: 'MOS 2205',
        shortName:
          'Management and Organizational Studies 2205F/G - BUSINESS COMMUNICATIONS',
        courseName:
          'Management and Organizational Studies 2205F/G - BUSINESS COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240b47f7e10015fd7437',
      },
      {
        _id: '60cc240c47f7e10015fd7439',
        courseCode: 'MOS 2220',
        shortName:
          'Management and Organizational Studies 2220F/G - CROSS-CULTURAL COMMERCIAL RELATIONSHIPS',
        courseName:
          'Management and Organizational Studies 2220F/G - CROSS-CULTURAL COMMERCIAL RELATIONSHIPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240c47f7e10015fd7439',
      },
      {
        _id: '60cc240c47f7e10015fd743b',
        courseCode: 'MOS 2227',
        shortName:
          'Management and Organizational Studies 2227A/B - INTRODUCTION TO FINANCIAL ACCOUNTING',
        courseName:
          'Management and Organizational Studies 2227A/B - INTRODUCTION TO FINANCIAL ACCOUNTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240c47f7e10015fd743b',
      },
      {
        _id: '60cc240c47f7e10015fd743d',
        courseCode: 'MOS 2228',
        shortName:
          'Management and Organizational Studies 2228A/B - INTRODUCTION TO MANAGERIAL ACCOUNTING',
        courseName:
          'Management and Organizational Studies 2228A/B - INTRODUCTION TO MANAGERIAL ACCOUNTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240c47f7e10015fd743d',
      },
      {
        _id: '60cc240d47f7e10015fd743f',
        courseCode: 'MOS 2229',
        shortName:
          'Management and Organizational Studies 2229W/X - INTRODUCTION TO FINANCIAL AND MANAGERIAL ACCOUNTING',
        courseName:
          'Management and Organizational Studies 2229W/X - INTRODUCTION TO FINANCIAL AND MANAGERIAL ACCOUNTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240d47f7e10015fd743f',
      },
      {
        _id: '60cc240d47f7e10015fd7441',
        courseCode: 'MOS 2242',
        shortName: 'Management and Organizational Studies 2242A/B - STATISTICS',
        courseName:
          'Management and Organizational Studies 2242A/B - STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240d47f7e10015fd7441',
      },
      {
        _id: '60cc240d47f7e10015fd7443',
        courseCode: 'MOS 2255',
        shortName:
          'Management and Organizational Studies 2255F/G - ENTREPRENEURIAL THINKING',
        courseName:
          'Management and Organizational Studies 2255F/G - ENTREPRENEURIAL THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240d47f7e10015fd7443',
      },
      {
        _id: '60cc240e47f7e10015fd7445',
        courseCode: 'MOS 2275',
        shortName:
          'Management and Organizational Studies 2275A/B - BUSINESS LAW',
        courseName:
          'Management and Organizational Studies 2275A/B - BUSINESS LAW',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 82,
        id: '60cc240e47f7e10015fd7445',
      },
      {
        _id: '60cc240e47f7e10015fd7447',
        courseCode: 'MOS 2277',
        shortName:
          'Management and Organizational Studies 2277A/B - PERSONAL FINANCIAL PLANNING',
        courseName:
          'Management and Organizational Studies 2277A/B - PERSONAL FINANCIAL PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240e47f7e10015fd7447',
      },
      {
        _id: '60cc240f47f7e10015fd7449',
        courseCode: 'MOS 2280',
        shortName:
          'Management and Organizational Studies 2280F/G - ORGANIZATIONAL BEHAVIOUR: THEORETICAL FOUNDATION',
        courseName:
          'Management and Organizational Studies 2280F/G - ORGANIZATIONAL BEHAVIOUR: THEORETICAL FOUNDATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240f47f7e10015fd7449',
      },
      {
        _id: '60cc240f47f7e10015fd744b',
        courseCode: 'MOS 2285',
        shortName:
          'Management and Organizational Studies 2285A/B - GLOBAL BUSINESS ENVIRONMENT',
        courseName:
          'Management and Organizational Studies 2285A/B - GLOBAL BUSINESS ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc240f47f7e10015fd744b',
      },
      {
        _id: '60cc241147f7e10015fd745a',
        courseCode: 'MOS 2310',
        shortName: 'Management and Organizational Studies 2310A/B - FINANCE',
        courseName: 'Management and Organizational Studies 2310A/B - FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 38,
        id: '60cc241147f7e10015fd745a',
      },
      {
        _id: '60cc241247f7e10015fd745c',
        courseCode: 'MOS 2320',
        shortName: 'Management and Organizational Studies 2320A/B - MARKETING',
        courseName: 'Management and Organizational Studies 2320A/B - MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 8,
        id: '60cc241247f7e10015fd745c',
      },
      {
        _id: '60cc241247f7e10015fd745e',
        courseCode: 'MOS 3199',
        shortName:
          'Management and Organizational Studies 3199Y - PROFESSIONAL PILOT ACADEMIC INTERNSHIP II',
        courseName:
          'Management and Organizational Studies 3199Y - PROFESSIONAL PILOT ACADEMIC INTERNSHIP II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241247f7e10015fd745e',
      },
      {
        _id: '60cc241247f7e10015fd7460',
        courseCode: 'MOS 3250',
        shortName:
          'Management and Organizational Studies 3250A/B - STARTING A BUSINESS OR SOCIAL ENTERPRISE',
        courseName:
          'Management and Organizational Studies 3250A/B - STARTING A BUSINESS OR SOCIAL ENTERPRISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241247f7e10015fd7460',
      },
      {
        _id: '60cc241247f7e10015fd7462',
        courseCode: 'MOS 3260',
        shortName:
          'Management and Organizational Studies 3260A/B - NONPROFIT FINANCIAL MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3260A/B - NONPROFIT FINANCIAL MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241247f7e10015fd7462',
      },
      {
        _id: '60cc241347f7e10015fd7464',
        courseCode: 'MOS 3280',
        shortName:
          'Management and Organizational Studies 3280F/G - EVIDENCE BASED MANAGEMENT IN HUMAN RESOURCES',
        courseName:
          'Management and Organizational Studies 3280F/G - EVIDENCE BASED MANAGEMENT IN HUMAN RESOURCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241347f7e10015fd7464',
      },
      {
        _id: '60cc241347f7e10015fd7466',
        courseCode: 'MOS 3305',
        shortName:
          'Management and Organizational Studies 3305A/B - HUMAN FACTORS IN AVIATION',
        courseName:
          'Management and Organizational Studies 3305A/B - HUMAN FACTORS IN AVIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241347f7e10015fd7466',
      },
      {
        _id: '60cc241347f7e10015fd7468',
        courseCode: 'MOS 3306',
        shortName:
          'Management and Organizational Studies 3306A/B - AVIATION LAW',
        courseName:
          'Management and Organizational Studies 3306A/B - AVIATION LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241347f7e10015fd7468',
      },
      {
        _id: '60cc241347f7e10015fd746a',
        courseCode: 'MOS 3310',
        shortName:
          'Management and Organizational Studies 3310A/B - FINANCE FOR MANAGEMENT AND ORGANIZATIONAL STUDIES',
        courseName:
          'Management and Organizational Studies 3310A/B - FINANCE FOR MANAGEMENT AND ORGANIZATIONAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241347f7e10015fd746a',
      },
      {
        _id: '60cc241447f7e10015fd746c',
        courseCode: 'MOS 3311',
        shortName:
          'Management and Organizational Studies 3311A/B - ADVANCED FINANCE',
        courseName:
          'Management and Organizational Studies 3311A/B - ADVANCED FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241447f7e10015fd746c',
      },
      {
        _id: '60cc241447f7e10015fd746e',
        courseCode: 'MOS 3312',
        shortName:
          'Management and Organizational Studies 3312A/B - DERIVATIVE SECURITIES MARKETS',
        courseName:
          'Management and Organizational Studies 3312A/B - DERIVATIVE SECURITIES MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241447f7e10015fd746e',
      },
      {
        _id: '60cc241447f7e10015fd7470',
        courseCode: 'MOS 3313',
        shortName:
          'Management and Organizational Studies 3313A/B - FINANCIAL MARKETS AND INSTITUTIONS',
        courseName:
          'Management and Organizational Studies 3313A/B - FINANCIAL MARKETS AND INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241447f7e10015fd7470',
      },
      {
        _id: '60cc241547f7e10015fd7473',
        courseCode: 'MOS 3316',
        shortName:
          'Management and Organizational Studies 3316A/B - INVESTMENT MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3316A/B - INVESTMENT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241547f7e10015fd7473',
      },
      {
        _id: '60cc241547f7e10015fd7475',
        courseCode: 'MOS 3320',
        shortName:
          'Management and Organizational Studies 3320A/B - MARKETING FOR MANAGEMENT AND ORGANIZATIONAL STUDIES',
        courseName:
          'Management and Organizational Studies 3320A/B - MARKETING FOR MANAGEMENT AND ORGANIZATIONAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241547f7e10015fd7475',
      },
      {
        _id: '60cc241647f7e10015fd7477',
        courseCode: 'MOS 3321',
        shortName:
          'Management and Organizational Studies 3321F/G - CONSUMER BEHAVIOR',
        courseName:
          'Management and Organizational Studies 3321F/G - CONSUMER BEHAVIOR',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 16,
        id: '60cc241647f7e10015fd7477',
      },
      {
        _id: '60cc241647f7e10015fd7479',
        courseCode: 'MOS 3322',
        shortName:
          'Management and Organizational Studies 3322F/G - INTEGRATED MARKETING COMMUNICATIONS',
        courseName:
          'Management and Organizational Studies 3322F/G - INTEGRATED MARKETING COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241647f7e10015fd7479',
      },
      {
        _id: '60cc241747f7e10015fd747b',
        courseCode: 'MOS 3323',
        shortName:
          'Management and Organizational Studies 3323A/B - DIGITAL MARKETING',
        courseName:
          'Management and Organizational Studies 3323A/B - DIGITAL MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241747f7e10015fd747b',
      },
      {
        _id: '60cc241747f7e10015fd747d',
        courseCode: 'MOS 3325',
        shortName:
          'Management and Organizational Studies 3325A/B - E-COMMERCE STRATEGY',
        courseName:
          'Management and Organizational Studies 3325A/B - E-COMMERCE STRATEGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241747f7e10015fd747d',
      },
      {
        _id: '60cc241747f7e10015fd747f',
        courseCode: 'MOS 3330',
        shortName:
          'Management and Organizational Studies 3330A/B - OPERATIONS MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3330A/B - OPERATIONS MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241747f7e10015fd747f',
      },
      {
        _id: '60cc241847f7e10015fd7481',
        courseCode: 'MOS 3331',
        shortName:
          'Management and Organizational Studies 3331A/B - PROJECT MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3331A/B - PROJECT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241847f7e10015fd7481',
      },
      {
        _id: '60cc241947f7e10015fd7483',
        courseCode: 'MOS 3332',
        shortName:
          'Management and Organizational Studies 3332A/B - GLOBAL SUPPLY CHAIN MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3332A/B - GLOBAL SUPPLY CHAIN MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241947f7e10015fd7483',
      },
      {
        _id: '60cc241a47f7e10015fd7485',
        courseCode: 'MOS 3341',
        shortName:
          'Management and Organizational Studies 3341F/G - RESEARCH METHODS IN HUMAN RESOURCES',
        courseName:
          'Management and Organizational Studies 3341F/G - RESEARCH METHODS IN HUMAN RESOURCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241a47f7e10015fd7485',
      },
      {
        _id: '60cc241a47f7e10015fd7487',
        courseCode: 'MOS 3342',
        shortName:
          'Management and Organizational Studies 3342A/B - COMPENSATION AND BENEFITS MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3342A/B - COMPENSATION AND BENEFITS MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241a47f7e10015fd7487',
      },
      {
        _id: '60cc241a47f7e10015fd7489',
        courseCode: 'MOS 3343',
        shortName:
          'Management and Organizational Studies 3343A/B - TRAINING AND DEVELOPMENT',
        courseName:
          'Management and Organizational Studies 3343A/B - TRAINING AND DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241a47f7e10015fd7489',
      },
      {
        _id: '60cc241b47f7e10015fd748b',
        courseCode: 'MOS 3344',
        shortName:
          'Management and Organizational Studies 3344A/B - OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT',
        courseName:
          'Management and Organizational Studies 3344A/B - OCCUPATIONAL HEALTH AND SAFETY MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241b47f7e10015fd748b',
      },
      {
        _id: '60cc241b47f7e10015fd748d',
        courseCode: 'MOS 3350',
        shortName:
          'Management and Organizational Studies 3350A/B - LEADERSHIP IN BUSINESS',
        courseName:
          'Management and Organizational Studies 3350A/B - LEADERSHIP IN BUSINESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241b47f7e10015fd748d',
      },
      {
        _id: '60cc241b47f7e10015fd748f',
        courseCode: 'MOS 3352',
        shortName:
          'Management and Organizational Studies 3352F/G - INDUSTRIAL RELATIONS AND LABOR STUDIES',
        courseName:
          'Management and Organizational Studies 3352F/G - INDUSTRIAL RELATIONS AND LABOR STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241b47f7e10015fd748f',
      },
      {
        _id: '60cc241c47f7e10015fd7492',
        courseCode: 'MOS 3355',
        shortName:
          'Management and Organizational Studies 3355F/G - WOMEN IN THE CANADIAN WORKPLACE',
        courseName:
          'Management and Organizational Studies 3355F/G - WOMEN IN THE CANADIAN WORKPLACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241c47f7e10015fd7492',
      },
      {
        _id: '60cc241d47f7e10015fd7496',
        courseCode: 'MOS 3360',
        shortName:
          'Management and Organizational Studies 3360A/B - INTERMEDIATE ACCOUNTING I',
        courseName:
          'Management and Organizational Studies 3360A/B - INTERMEDIATE ACCOUNTING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241d47f7e10015fd7496',
      },
      {
        _id: '60cc241d47f7e10015fd7498',
        courseCode: 'MOS 3361',
        shortName:
          'Management and Organizational Studies 3361A/B - INTERMEDIATE ACCOUNTING II',
        courseName:
          'Management and Organizational Studies 3361A/B - INTERMEDIATE ACCOUNTING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241d47f7e10015fd7498',
      },
      {
        _id: '60cc241e47f7e10015fd749a',
        courseCode: 'MOS 3362',
        shortName:
          'Management and Organizational Studies 3362A/B - INTRODUCTION TO TAXATION IN CANADA',
        courseName:
          'Management and Organizational Studies 3362A/B - INTRODUCTION TO TAXATION IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241e47f7e10015fd749a',
      },
      {
        _id: '60cc241f47f7e10015fd749c',
        courseCode: 'MOS 3363',
        shortName:
          'Management and Organizational Studies 3363A/B - INTRODUCTION TO AUDITING',
        courseName:
          'Management and Organizational Studies 3363A/B - INTRODUCTION TO AUDITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241f47f7e10015fd749c',
      },
      {
        _id: '60cc241f47f7e10015fd749e',
        courseCode: 'MOS 3367',
        shortName:
          'Management and Organizational Studies 3367A/B - INTRODUCTION TO FRAUD EXAMINATION',
        courseName:
          'Management and Organizational Studies 3367A/B - INTRODUCTION TO FRAUD EXAMINATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241f47f7e10015fd749e',
      },
      {
        _id: '60cc241f47f7e10015fd74a0',
        courseCode: 'MOS 3370',
        shortName:
          'Management and Organizational Studies 3370A/B - MANAGEMENT ACCOUNTING',
        courseName:
          'Management and Organizational Studies 3370A/B - MANAGEMENT ACCOUNTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc241f47f7e10015fd74a0',
      },
      {
        _id: '60cc242047f7e10015fd74a2',
        courseCode: 'MOS 3383',
        shortName:
          'Management and Organizational Studies 3383A/B - STRATEGIC HUMAN RESOURCES PLANNING',
        courseName:
          'Management and Organizational Studies 3383A/B - STRATEGIC HUMAN RESOURCES PLANNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242047f7e10015fd74a2',
      },
      {
        _id: '60cc242047f7e10015fd74a4',
        courseCode: 'MOS 3384',
        shortName:
          'Management and Organizational Studies 3384A/B - PERSONNEL RECRUITMENT AND SELECTION',
        courseName:
          'Management and Organizational Studies 3384A/B - PERSONNEL RECRUITMENT AND SELECTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242047f7e10015fd74a4',
      },
      {
        _id: '60cc242147f7e10015fd74a6',
        courseCode: 'MOS 3385',
        shortName:
          'Management and Organizational Studies 3385A/B - ESSENTIALS OF HUMAN RESOURCES FOR NON HR STUDENTS',
        courseName:
          'Management and Organizational Studies 3385A/B - ESSENTIALS OF HUMAN RESOURCES FOR NON HR STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242147f7e10015fd74a6',
      },
      {
        _id: '60cc242147f7e10015fd74a8',
        courseCode: 'MOS 3388',
        shortName:
          'Management and Organizational Studies 3388A/B - ORGANIZATIONAL GOVERNANCE',
        courseName:
          'Management and Organizational Studies 3388A/B - ORGANIZATIONAL GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242147f7e10015fd74a8',
      },
      {
        _id: '60cc242347f7e10015fd74b3',
        courseCode: 'MOS 3401',
        shortName:
          'Management and Organizational Studies 3401F/G - CORPORATE SOCIAL RESPONSIBILITY',
        courseName:
          'Management and Organizational Studies 3401F/G - CORPORATE SOCIAL RESPONSIBILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242347f7e10015fd74b3',
      },
      {
        _id: '60cc242347f7e10015fd74b5',
        courseCode: 'MOS 3420',
        shortName:
          'Management and Organizational Studies 3420F/G - MARKETING RESEARCH',
        courseName:
          'Management and Organizational Studies 3420F/G - MARKETING RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242347f7e10015fd74b5',
      },
      {
        _id: '60cc242347f7e10015fd74b7',
        courseCode: 'MOS 3470',
        shortName:
          'Management and Organizational Studies 3470F/G - APPLIED MARKETING RESEARCH',
        courseName:
          'Management and Organizational Studies 3470F/G - APPLIED MARKETING RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242347f7e10015fd74b7',
      },
      {
        _id: '60cc242447f7e10015fd74b9',
        courseCode: 'MOS 3475',
        shortName:
          'Management and Organizational Studies 3475A/B - CONTRACT LAW',
        courseName:
          'Management and Organizational Studies 3475A/B - CONTRACT LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242447f7e10015fd74b9',
      },
      {
        _id: '60cc242447f7e10015fd74bb',
        courseCode: 'MOS 3490',
        shortName:
          'Management and Organizational Studies 3490 - INTERNSHIP: WORK TERM',
        courseName:
          'Management and Organizational Studies 3490 - INTERNSHIP: WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242447f7e10015fd74bb',
      },
      {
        _id: '60cc242447f7e10015fd74bd',
        courseCode: 'MOS 3491',
        shortName:
          'Management and Organizational Studies 3491 - INTERNSHIP: WORK TERM',
        courseName:
          'Management and Organizational Studies 3491 - INTERNSHIP: WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242447f7e10015fd74bd',
      },
      {
        _id: '60cc242547f7e10015fd74bf',
        courseCode: 'MOS 3492',
        shortName:
          'Management and Organizational Studies 3492 - INTERNSHIP: WORK TERM',
        courseName:
          'Management and Organizational Studies 3492 - INTERNSHIP: WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242547f7e10015fd74bf',
      },
      {
        _id: '60cc242647f7e10015fd74c1',
        courseCode: 'MOS 3493',
        shortName:
          'Management and Organizational Studies 3493 - INTERNSHIP: WORK TERM',
        courseName:
          'Management and Organizational Studies 3493 - INTERNSHIP: WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242647f7e10015fd74c1',
      },
      {
        _id: '60cc242647f7e10015fd74c3',
        courseCode: 'MOS 3494',
        shortName: 'Management and Organizational Studies 3494 - INTERNSHIP',
        courseName: 'Management and Organizational Studies 3494 - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242647f7e10015fd74c3',
      },
      {
        _id: '60cc242747f7e10015fd74c5',
        courseCode: 'MOS 3495',
        shortName:
          'Management and Organizational Studies 3495 - INTERNSHIP: WORK TERM EXTENSION',
        courseName:
          'Management and Organizational Studies 3495 - INTERNSHIP: WORK TERM EXTENSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242747f7e10015fd74c5',
      },
      {
        _id: '60cc242847f7e10015fd74c7',
        courseCode: 'MOS 4199',
        shortName:
          'Management and Organizational Studies 4199Y - PROFESSIONAL PILOT ACADEMIC INTERNSHIP III',
        courseName:
          'Management and Organizational Studies 4199Y - PROFESSIONAL PILOT ACADEMIC INTERNSHIP III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242847f7e10015fd74c7',
      },
      {
        _id: '60cc242847f7e10015fd74c9',
        courseCode: 'MOS 4310',
        shortName:
          'Management and Organizational Studies 4310A/B - ADVANCED CORPORATE FINANCE',
        courseName:
          'Management and Organizational Studies 4310A/B - ADVANCED CORPORATE FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242847f7e10015fd74c9',
      },
      {
        _id: '60cc242847f7e10015fd74cb',
        courseCode: 'MOS 4312',
        shortName:
          'Management and Organizational Studies 4312A/B - DERIVATIVES',
        courseName:
          'Management and Organizational Studies 4312A/B - DERIVATIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242847f7e10015fd74cb',
      },
      {
        _id: '60cc242947f7e10015fd74ce',
        courseCode: 'MOS 4314',
        shortName:
          'Management and Organizational Studies 4314F/G - REAL ESTATE AND THE CITY',
        courseName:
          'Management and Organizational Studies 4314F/G - REAL ESTATE AND THE CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242947f7e10015fd74ce',
      },
      {
        _id: '60cc242947f7e10015fd74d0',
        courseCode: 'MOS 4315',
        shortName:
          'Management and Organizational Studies 4315A/B - INVESTING STRATEGIES AND BEHAVIOURAL FINANCE',
        courseName:
          'Management and Organizational Studies 4315A/B - INVESTING STRATEGIES AND BEHAVIOURAL FINANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242947f7e10015fd74d0',
      },
      {
        _id: '60cc242947f7e10015fd74d2',
        courseCode: 'MOS 4375',
        shortName:
          'Management and Organizational Studies 4375A/B - EMPLOYMENT LAW',
        courseName:
          'Management and Organizational Studies 4375A/B - EMPLOYMENT LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242947f7e10015fd74d2',
      },
      {
        _id: '60cc242947f7e10015fd74d4',
        courseCode: 'MOS 4400',
        shortName:
          'Management and Organizational Studies 4400A/B - FIELD PROJECT',
        courseName:
          'Management and Organizational Studies 4400A/B - FIELD PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242947f7e10015fd74d4',
      },
      {
        _id: '60cc242a47f7e10015fd74d6',
        courseCode: 'MOS 4404',
        shortName:
          'Management and Organizational Studies 4404A/B - INTERNATIONAL ENTERPRISE',
        courseName:
          'Management and Organizational Studies 4404A/B - INTERNATIONAL ENTERPRISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242a47f7e10015fd74d6',
      },
      {
        _id: '60cc242a47f7e10015fd74d8',
        courseCode: 'MOS 4405',
        shortName:
          'Management and Organizational Studies 4405F/G - AIRPORT PLANNING AND MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4405F/G - AIRPORT PLANNING AND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242a47f7e10015fd74d8',
      },
      {
        _id: '60cc242a47f7e10015fd74da',
        courseCode: 'MOS 4406',
        shortName:
          'Management and Organizational Studies 4406F/G - AIRLINE OPERATION AND MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4406F/G - AIRLINE OPERATION AND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242a47f7e10015fd74da',
      },
      {
        _id: '60cc242a47f7e10015fd74dc',
        courseCode: 'MOS 4409',
        shortName:
          'Management and Organizational Studies 4409F/G - AVIATION SAFETY',
        courseName:
          'Management and Organizational Studies 4409F/G - AVIATION SAFETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242a47f7e10015fd74dc',
      },
      {
        _id: '60cc242a47f7e10015fd74de',
        courseCode: 'MOS 4410',
        shortName:
          'Management and Organizational Studies 4410A/B - STRATEGIC MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4410A/B - STRATEGIC MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242a47f7e10015fd74de',
      },
      {
        _id: '60cc242b47f7e10015fd74e0',
        courseCode: 'MOS 4411',
        shortName:
          'Management and Organizational Studies 4411A/B - NEW PRODUCT MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4411A/B - NEW PRODUCT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242b47f7e10015fd74e0',
      },
      {
        _id: '60cc242b47f7e10015fd74e2',
        courseCode: 'MOS 4415',
        shortName:
          'Management and Organizational Studies 4415A/B - BRAND MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4415A/B - BRAND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242b47f7e10015fd74e2',
      },
      {
        _id: '60cc242c47f7e10015fd74e4',
        courseCode: 'MOS 4421',
        shortName:
          'Management and Organizational Studies 4421A/B - ENTREPRENEURSHIP',
        courseName:
          'Management and Organizational Studies 4421A/B - ENTREPRENEURSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242c47f7e10015fd74e4',
      },
      {
        _id: '60cc242c47f7e10015fd74e6',
        courseCode: 'MOS 4422',
        shortName:
          'Management and Organizational Studies 4422F/G - CORPORATE GOVERNANCE',
        courseName:
          'Management and Organizational Studies 4422F/G - CORPORATE GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242c47f7e10015fd74e6',
      },
      {
        _id: '60cc242c47f7e10015fd74e8',
        courseCode: 'MOS 4423',
        shortName:
          'Management and Organizational Studies 4423F/G - SEMINAR IN CONSUMER RESEARCH',
        courseName:
          'Management and Organizational Studies 4423F/G - SEMINAR IN CONSUMER RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242c47f7e10015fd74e8',
      },
      {
        _id: '60cc242c47f7e10015fd74ea',
        courseCode: 'MOS 4424',
        shortName:
          'Management and Organizational Studies 4424A/B - CONSUMER BRAND MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4424A/B - CONSUMER BRAND MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242c47f7e10015fd74ea',
      },
      {
        _id: '60cc242d47f7e10015fd74ec',
        courseCode: 'MOS 4425',
        shortName:
          'Management and Organizational Studies 4425A/B - NONPROFIT MARKETING AND FUNDRAISING',
        courseName:
          'Management and Organizational Studies 4425A/B - NONPROFIT MARKETING AND FUNDRAISING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242d47f7e10015fd74ec',
      },
      {
        _id: '60cc242d47f7e10015fd74ee',
        courseCode: 'MOS 4426',
        shortName:
          'Management and Organizational Studies 4426A/B - STRATEGIC NONPROFIT MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4426A/B - STRATEGIC NONPROFIT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242d47f7e10015fd74ee',
      },
      {
        _id: '60cc242d47f7e10015fd74f0',
        courseCode: 'MOS 4427',
        shortName:
          'Management and Organizational Studies 4427A/B - FOOD MARKETING',
        courseName:
          'Management and Organizational Studies 4427A/B - FOOD MARKETING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242d47f7e10015fd74f0',
      },
      {
        _id: '60cc242d47f7e10015fd74f2',
        courseCode: 'MOS 4462',
        shortName:
          'Management and Organizational Studies 4462A/B - ADVANCED ISSUES IN CANADIAN TAXATION',
        courseName:
          'Management and Organizational Studies 4462A/B - ADVANCED ISSUES IN CANADIAN TAXATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242d47f7e10015fd74f2',
      },
      {
        _id: '60cc242e47f7e10015fd74f4',
        courseCode: 'MOS 4463',
        shortName:
          'Management and Organizational Studies 4463A/B - ADVANCED AUDIT',
        courseName:
          'Management and Organizational Studies 4463A/B - ADVANCED AUDIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242e47f7e10015fd74f4',
      },
      {
        _id: '60cc242e47f7e10015fd74f6',
        courseCode: 'MOS 4464',
        shortName:
          'Management and Organizational Studies 4464A/B - INFORMATION SYSTEMS AUDIT',
        courseName:
          'Management and Organizational Studies 4464A/B - INFORMATION SYSTEMS AUDIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242e47f7e10015fd74f6',
      },
      {
        _id: '60cc242e47f7e10015fd74f8',
        courseCode: 'MOS 4465',
        shortName:
          'Management and Organizational Studies 4465A/B - ADVANCED ACCOUNTING',
        courseName:
          'Management and Organizational Studies 4465A/B - ADVANCED ACCOUNTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242e47f7e10015fd74f8',
      },
      {
        _id: '60cc242f47f7e10015fd74fa',
        courseCode: 'MOS 4466',
        shortName:
          'Management and Organizational Studies 4466A/B - ACCOUNTING THEORY',
        courseName:
          'Management and Organizational Studies 4466A/B - ACCOUNTING THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242f47f7e10015fd74fa',
      },
      {
        _id: '60cc242f47f7e10015fd74fc',
        courseCode: 'MOS 4467',
        shortName:
          'Management and Organizational Studies 4467A/B - PROFESSIONALISM AND ETHICS',
        courseName:
          'Management and Organizational Studies 4467A/B - PROFESSIONALISM AND ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc242f47f7e10015fd74fc',
      },
      {
        _id: '60cc243047f7e10015fd74fe',
        courseCode: 'MOS 4468',
        shortName:
          'Management and Organizational Studies 4468A/B - ENTERPRISE RISK MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4468A/B - ENTERPRISE RISK MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243047f7e10015fd74fe',
      },
      {
        _id: '60cc243047f7e10015fd7500',
        courseCode: 'MOS 4469',
        shortName:
          'Management and Organizational Studies 4469A/B - THE LEGAL REGULATION OF CORPORATE GOVERNANCE',
        courseName:
          'Management and Organizational Studies 4469A/B - THE LEGAL REGULATION OF CORPORATE GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243047f7e10015fd7500',
      },
      {
        _id: '60cc243047f7e10015fd7502',
        courseCode: 'MOS 4470',
        shortName:
          'Management and Organizational Studies 4470A/B - ORGANIZATION AND MANAGEMENT THEORY',
        courseName:
          'Management and Organizational Studies 4470A/B - ORGANIZATION AND MANAGEMENT THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243047f7e10015fd7502',
      },
      {
        _id: '60cc243147f7e10015fd7504',
        courseCode: 'MOS 4471',
        shortName:
          'Management and Organizational Studies 4471A/B - MANAGEMENT CONTROL SYSTEMS',
        courseName:
          'Management and Organizational Studies 4471A/B - MANAGEMENT CONTROL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243147f7e10015fd7504',
      },
      {
        _id: '60cc243147f7e10015fd7506',
        courseCode: 'MOS 4472',
        shortName:
          'Management and Organizational Studies 4472A/B - ACCOUNTING INFORMATION SYSTEMS',
        courseName:
          'Management and Organizational Studies 4472A/B - ACCOUNTING INFORMATION SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243147f7e10015fd7506',
      },
      {
        _id: '60cc243247f7e10015fd7508',
        courseCode: 'MOS 4485',
        shortName:
          'Management and Organizational Studies 4485F/G - HUMAN RESOURCES ADMINISTRATION FOR HR STUDENTS',
        courseName:
          'Management and Organizational Studies 4485F/G - HUMAN RESOURCES ADMINISTRATION FOR HR STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243247f7e10015fd7508',
      },
      {
        _id: '60cc243247f7e10015fd750a',
        courseCode: 'MOS 4488',
        shortName:
          'Management and Organizational Studies 4488A/B - MANAGEMENT AND ORGANIZATIONAL CONSULTING',
        courseName:
          'Management and Organizational Studies 4488A/B - MANAGEMENT AND ORGANIZATIONAL CONSULTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243247f7e10015fd750a',
      },
      {
        _id: '60cc243247f7e10015fd750c',
        courseCode: 'MOS 4489',
        shortName:
          'Management and Organizational Studies 4489F/G - STRATEGIC FORESIGHT AND FUTURING',
        courseName:
          'Management and Organizational Studies 4489F/G - STRATEGIC FORESIGHT AND FUTURING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243247f7e10015fd750c',
      },
      {
        _id: '60cc243447f7e10015fd7516',
        courseCode: 'MOS 4499',
        shortName:
          'Management and Organizational Studies 4499Y - PROFESSIONAL PERSPECTIVES IN MANAGEMENT',
        courseName:
          'Management and Organizational Studies 4499Y - PROFESSIONAL PERSPECTIVES IN MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243447f7e10015fd7516',
      },
      {
        _id: '60cc243447f7e10015fd7518',
        courseCode: 'MOS 4500',
        shortName:
          'Management and Organizational Studies 4500Y - MOS WORK PLACEMENT',
        courseName:
          'Management and Organizational Studies 4500Y - MOS WORK PLACEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243447f7e10015fd7518',
      },
      {
        _id: '60cc243547f7e10015fd751d',
        courseCode: 'MATH 0105',
        shortName: 'Mathematics 0105A/B - PRECALCULUS MATHEMATICS',
        courseName: 'Mathematics 0105A/B - PRECALCULUS MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243547f7e10015fd751d',
      },
      {
        _id: '60cc243647f7e10015fd751f',
        courseCode: 'MATH 0109',
        shortName: 'Mathematics 0109A/B - PREPARATORY MATHEMATICS',
        courseName: 'Mathematics 0109A/B - PREPARATORY MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243647f7e10015fd751f',
      },
      {
        _id: '60cc243647f7e10015fd7521',
        courseCode: 'MATH 0110',
        shortName: 'Mathematics 0110A/B - INTRODUCTORY CALCULUS',
        courseName: 'Mathematics 0110A/B - INTRODUCTORY CALCULUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243647f7e10015fd7521',
      },
      {
        _id: '60cc243747f7e10015fd7523',
        courseCode: 'MATH 1120',
        shortName: 'Mathematics 1120A/B - FUNDAMENTAL CONCEPTS IN MATHEMATICS',
        courseName: 'Mathematics 1120A/B - FUNDAMENTAL CONCEPTS IN MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243747f7e10015fd7523',
      },
      {
        _id: '60cc243747f7e10015fd7525',
        courseCode: 'MATH 1225',
        shortName: 'Mathematics 1225A/B - METHODS OF CALCULUS',
        courseName: 'Mathematics 1225A/B - METHODS OF CALCULUS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 12,
        id: '60cc243747f7e10015fd7525',
      },
      {
        _id: '60cc243847f7e10015fd7527',
        courseCode: 'MATH 1228',
        shortName: 'Mathematics 1228A/B - METHODS OF FINITE MATHEMATICS',
        courseName: 'Mathematics 1228A/B - METHODS OF FINITE MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 12,
        id: '60cc243847f7e10015fd7527',
      },
      {
        _id: '60cc243947f7e10015fd7529',
        courseCode: 'MATH 1229',
        shortName: 'Mathematics 1229A/B - METHODS OF MATRIX ALGEBRA',
        courseName: 'Mathematics 1229A/B - METHODS OF MATRIX ALGEBRA',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 20,
        id: '60cc243947f7e10015fd7529',
      },
      {
        _id: '60cc243947f7e10015fd752b',
        courseCode: 'MATH 1230',
        shortName: 'Mathematics 1230A/B - CALCULUS FOR SOCIAL SCIENCES',
        courseName: 'Mathematics 1230A/B - CALCULUS FOR SOCIAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243947f7e10015fd752b',
      },
      {
        _id: '60cc243a47f7e10015fd752d',
        courseCode: 'MATH 1600',
        shortName: 'Mathematics 1600A/B - LINEAR ALGEBRA I',
        courseName: 'Mathematics 1600A/B - LINEAR ALGEBRA I',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 2,
        id: '60cc243a47f7e10015fd752d',
      },
      {
        _id: '60cc243a47f7e10015fd752f',
        courseCode: 'MATH 2120',
        shortName: 'Mathematics 2120A/B - INTERMEDIATE LINEAR ALGEBRA',
        courseName: 'Mathematics 2120A/B - INTERMEDIATE LINEAR ALGEBRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243a47f7e10015fd752f',
      },
      {
        _id: '60cc243a47f7e10015fd7531',
        courseCode: 'MATH 2122',
        shortName: 'Mathematics 2122A/B - REAL ANALYSIS I',
        courseName: 'Mathematics 2122A/B - REAL ANALYSIS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243a47f7e10015fd7531',
      },
      {
        _id: '60cc243b47f7e10015fd7533',
        courseCode: 'MATH 2124',
        shortName:
          'Mathematics 2124A/B - INTRODUCTION TO MATHEMATICAL PROBLEMS',
        courseName:
          'Mathematics 2124A/B - INTRODUCTION TO MATHEMATICAL PROBLEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243b47f7e10015fd7533',
      },
      {
        _id: '60cc243b47f7e10015fd7535',
        courseCode: 'MATH 2151',
        shortName: 'Mathematics 2151A/B - DISCRETE STRUCTURES FOR ENGINEERING',
        courseName: 'Mathematics 2151A/B - DISCRETE STRUCTURES FOR ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243b47f7e10015fd7535',
      },
      {
        _id: '60cc243b47f7e10015fd7537',
        courseCode: 'MATH 2155',
        shortName: 'Mathematics 2155F/G - MATHEMATICAL STRUCTURES',
        courseName: 'Mathematics 2155F/G - MATHEMATICAL STRUCTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243b47f7e10015fd7537',
      },
      {
        _id: '60cc243b47f7e10015fd7539',
        courseCode: 'MATH 2156',
        shortName: 'Mathematics 2156A/B - MATHEMATICAL STRUCTURES II',
        courseName: 'Mathematics 2156A/B - MATHEMATICAL STRUCTURES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243b47f7e10015fd7539',
      },
      {
        _id: '60cc243c47f7e10015fd753b',
        courseCode: 'MATH 2211',
        shortName: 'Mathematics 2211A/B - LINEAR ALGEBRA',
        courseName: 'Mathematics 2211A/B - LINEAR ALGEBRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243c47f7e10015fd753b',
      },
      {
        _id: '60cc243c47f7e10015fd753d',
        courseCode: 'MATH 2251',
        shortName:
          'Mathematics 2251F/G - CONCEPTUAL DEVELOPMENT OF MATHEMATICS',
        courseName:
          'Mathematics 2251F/G - CONCEPTUAL DEVELOPMENT OF MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243c47f7e10015fd753d',
      },
      {
        _id: '60cc243c47f7e10015fd753f',
        courseCode: 'MATH 3020',
        shortName: 'Mathematics 3020A/B - INTRODUCTION TO ABSTRACT ALGEBRA',
        courseName: 'Mathematics 3020A/B - INTRODUCTION TO ABSTRACT ALGEBRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243c47f7e10015fd753f',
      },
      {
        _id: '60cc243d47f7e10015fd7541',
        courseCode: 'MATH 3120',
        shortName: 'Mathematics 3120A/B - GROUP THEORY',
        courseName: 'Mathematics 3120A/B - GROUP THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243d47f7e10015fd7541',
      },
      {
        _id: '60cc243d47f7e10015fd7543',
        courseCode: 'MATH 3121',
        shortName: 'Mathematics 3121A/B - ADVANCED LINEAR ALGEBRA',
        courseName: 'Mathematics 3121A/B - ADVANCED LINEAR ALGEBRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243d47f7e10015fd7543',
      },
      {
        _id: '60cc243d47f7e10015fd7545',
        courseCode: 'MATH 3122',
        shortName: 'Mathematics 3122A/B - REAL ANALYSIS II',
        courseName: 'Mathematics 3122A/B - REAL ANALYSIS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243d47f7e10015fd7545',
      },
      {
        _id: '60cc243d47f7e10015fd7547',
        courseCode: 'MATH 3123',
        shortName: 'Mathematics 3123A/B - DIFFERENTIAL EQUATIONS',
        courseName: 'Mathematics 3123A/B - DIFFERENTIAL EQUATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243d47f7e10015fd7547',
      },
      {
        _id: '60cc243d47f7e10015fd7549',
        courseCode: 'MATH 3124',
        shortName: 'Mathematics 3124A/B - COMPLEX  ANALYSIS I',
        courseName: 'Mathematics 3124A/B - COMPLEX  ANALYSIS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243d47f7e10015fd7549',
      },
      {
        _id: '60cc243e47f7e10015fd754b',
        courseCode: 'MATH 3150',
        shortName: 'Mathematics 3150A/B - ELEMENTARY NUMBER THEORY I',
        courseName: 'Mathematics 3150A/B - ELEMENTARY NUMBER THEORY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243e47f7e10015fd754b',
      },
      {
        _id: '60cc243e47f7e10015fd754d',
        courseCode: 'MATH 3151',
        shortName: 'Mathematics 3151A/B - ELEMENTARY NUMBER THEORY II',
        courseName: 'Mathematics 3151A/B - ELEMENTARY NUMBER THEORY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243e47f7e10015fd754d',
      },
      {
        _id: '60cc243e47f7e10015fd754f',
        courseCode: 'MATH 3152',
        shortName: 'Mathematics 3152A/B - COMBINATORIAL MATH',
        courseName: 'Mathematics 3152A/B - COMBINATORIAL MATH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243e47f7e10015fd754f',
      },
      {
        _id: '60cc243e47f7e10015fd7551',
        courseCode: 'MATH 3153',
        shortName: 'Mathematics 3153A/B - DISCRETE OPTIMIZATION',
        courseName: 'Mathematics 3153A/B - DISCRETE OPTIMIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243e47f7e10015fd7551',
      },
      {
        _id: '60cc243f47f7e10015fd7553',
        courseCode: 'MATH 3154',
        shortName: 'Mathematics 3154A/B - INTRODUCTION TO ALGEBRAIC CURVES',
        courseName: 'Mathematics 3154A/B - INTRODUCTION TO ALGEBRAIC CURVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243f47f7e10015fd7553',
      },
      {
        _id: '60cc243f47f7e10015fd7555',
        courseCode: 'MATH 3157',
        shortName: 'Mathematics 3157A/B - INTRODUCTION TO GAME THEORY',
        courseName: 'Mathematics 3157A/B - INTRODUCTION TO GAME THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243f47f7e10015fd7555',
      },
      {
        _id: '60cc243f47f7e10015fd7557',
        courseCode: 'MATH 3159',
        shortName: 'Mathematics 3159A/B - INTRODUCTION TO CRYPTOGRAPHY',
        courseName: 'Mathematics 3159A/B - INTRODUCTION TO CRYPTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243f47f7e10015fd7557',
      },
      {
        _id: '60cc243f47f7e10015fd7559',
        courseCode: 'MATH 3958',
        shortName: 'Mathematics 3958A/B - SPECIAL TOPICS IN MATHEMATICS',
        courseName: 'Mathematics 3958A/B - SPECIAL TOPICS IN MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc243f47f7e10015fd7559',
      },
      {
        _id: '60cc244047f7e10015fd755b',
        courseCode: 'MATH 3959',
        shortName: 'Mathematics 3959A/B - SPECIAL TOPICS IN MATHEMATICS',
        courseName: 'Mathematics 3959A/B - SPECIAL TOPICS IN MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244047f7e10015fd755b',
      },
      {
        _id: '60cc244047f7e10015fd755d',
        courseCode: 'MATH 4120',
        shortName: 'Mathematics 4120A/B - FIELD THEORY',
        courseName: 'Mathematics 4120A/B - FIELD THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244047f7e10015fd755d',
      },
      {
        _id: '60cc244047f7e10015fd755f',
        courseCode: 'MATH 4121',
        shortName: 'Mathematics 4121A/B - TOPOLOGY',
        courseName: 'Mathematics 4121A/B - TOPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244047f7e10015fd755f',
      },
      {
        _id: '60cc244047f7e10015fd7561',
        courseCode: 'MATH 4122',
        shortName: 'Mathematics 4122A/B - INTRODUCTION TO MEASURE THEORY',
        courseName: 'Mathematics 4122A/B - INTRODUCTION TO MEASURE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244047f7e10015fd7561',
      },
      {
        _id: '60cc244147f7e10015fd7563',
        courseCode: 'MATH 4123',
        shortName: 'Mathematics 4123A/B - RINGS AND MODULES',
        courseName: 'Mathematics 4123A/B - RINGS AND MODULES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244147f7e10015fd7563',
      },
      {
        _id: '60cc244147f7e10015fd7565',
        courseCode: 'MATH 4151',
        shortName: 'Mathematics 4151A/B - ALGEBRAIC NUMBER THEORY',
        courseName: 'Mathematics 4151A/B - ALGEBRAIC NUMBER THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244147f7e10015fd7565',
      },
      {
        _id: '60cc244147f7e10015fd7567',
        courseCode: 'MATH 4152',
        shortName: 'Mathematics 4152A/B - ALGEBRAIC TOPOLOGY',
        courseName: 'Mathematics 4152A/B - ALGEBRAIC TOPOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244147f7e10015fd7567',
      },
      {
        _id: '60cc244147f7e10015fd7569',
        courseCode: 'MATH 4153',
        shortName: 'Mathematics 4153A/B - ALGEBRAIC GEOMETRY',
        courseName: 'Mathematics 4153A/B - ALGEBRAIC GEOMETRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244147f7e10015fd7569',
      },
      {
        _id: '60cc244147f7e10015fd756b',
        courseCode: 'MATH 4154',
        shortName: 'Mathematics 4154A/B - FUNCTIONAL ANALYSIS',
        courseName: 'Mathematics 4154A/B - FUNCTIONAL ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244147f7e10015fd756b',
      },
      {
        _id: '60cc244247f7e10015fd756d',
        courseCode: 'MATH 4155',
        shortName: 'Mathematics 4155A/B - CALCULUS ON MANIFOLDS',
        courseName: 'Mathematics 4155A/B - CALCULUS ON MANIFOLDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244247f7e10015fd756d',
      },
      {
        _id: '60cc244247f7e10015fd756f',
        courseCode: 'MATH 4156',
        shortName: 'Mathematics 4156A/B - COMPLEX ANALYSIS II',
        courseName: 'Mathematics 4156A/B - COMPLEX ANALYSIS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244247f7e10015fd756f',
      },
      {
        _id: '60cc244247f7e10015fd7571',
        courseCode: 'MATH 4158',
        shortName: 'Mathematics 4158A/B/Y - FOUNDATIONS OF MATHEMATICS',
        courseName: 'Mathematics 4158A/B/Y - FOUNDATIONS OF MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244247f7e10015fd7571',
      },
      {
        _id: '60cc244247f7e10015fd7573',
        courseCode: 'MATH 4958',
        shortName: 'Mathematics 4958A/B - SPECIAL TOPICS IN MATHEMATICS',
        courseName: 'Mathematics 4958A/B - SPECIAL TOPICS IN MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244247f7e10015fd7573',
      },
      {
        _id: '60cc244347f7e10015fd7575',
        courseCode: 'MATH 4959',
        shortName: 'Mathematics 4959A/B - SPECIAL TOPICS IN MATHEMATICS',
        courseName: 'Mathematics 4959A/B - SPECIAL TOPICS IN MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244347f7e10015fd7575',
      },
      {
        _id: '60cc244347f7e10015fd7577',
        courseCode: 'MME 2200',
        shortName:
          'Mechanical and Materials Engineering 2200Q/R/S/T - ENGINEERING SHOP SAFETY TRAINING',
        courseName:
          'Mechanical and Materials Engineering 2200Q/R/S/T - ENGINEERING SHOP SAFETY TRAINING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244347f7e10015fd7577',
      },
      {
        _id: '60cc244347f7e10015fd7579',
        courseCode: 'MME 2202',
        shortName:
          'Mechanical and Materials Engineering 2202A/B - MECHANICS OF MATERIALS',
        courseName:
          'Mechanical and Materials Engineering 2202A/B - MECHANICS OF MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244347f7e10015fd7579',
      },
      {
        _id: '60cc244347f7e10015fd757b',
        courseCode: 'MME 2204',
        shortName:
          'Mechanical and Materials Engineering 2204A/B - THERMODYNAMICS I',
        courseName:
          'Mechanical and Materials Engineering 2204A/B - THERMODYNAMICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244347f7e10015fd757b',
      },
      {
        _id: '60cc244347f7e10015fd757d',
        courseCode: 'MME 2213',
        shortName:
          'Mechanical and Materials Engineering 2213A/B - ENGINEERING DYNAMICS',
        courseName:
          'Mechanical and Materials Engineering 2213A/B - ENGINEERING DYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244347f7e10015fd757d',
      },
      {
        _id: '60cc244447f7e10015fd757f',
        courseCode: 'MME 2221',
        shortName:
          'Mechanical and Materials Engineering 2221A/B - COMPUTATIONAL METHODS FOR MECHANICAL ENGINEERS',
        courseName:
          'Mechanical and Materials Engineering 2221A/B - COMPUTATIONAL METHODS FOR MECHANICAL ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244447f7e10015fd757f',
      },
      {
        _id: '60cc244447f7e10015fd7581',
        courseCode: 'MME 2234',
        shortName:
          'Mechanical and Materials Engineering 2234A/B - HEAT TRANSFER AND DYNAMICS',
        courseName:
          'Mechanical and Materials Engineering 2234A/B - HEAT TRANSFER AND DYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244447f7e10015fd7581',
      },
      {
        _id: '60cc244447f7e10015fd7583',
        courseCode: 'MME 2259',
        shortName:
          'Mechanical and Materials Engineering 2259A/B - PRODUCT DESIGN & DEVELOPMENT',
        courseName:
          'Mechanical and Materials Engineering 2259A/B - PRODUCT DESIGN & DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244447f7e10015fd7583',
      },
      {
        _id: '60cc244447f7e10015fd7585',
        courseCode: 'MME 2260',
        shortName:
          'Mechanical and Materials Engineering 2260A/B - INDUSTRIAL MATERIALS',
        courseName:
          'Mechanical and Materials Engineering 2260A/B - INDUSTRIAL MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244447f7e10015fd7585',
      },
      {
        _id: '60cc244547f7e10015fd7587',
        courseCode: 'MME 2273',
        shortName:
          'Mechanical and Materials Engineering 2273A/B - INTRODUCTION TO FLUID MECHANICS AND HEAT TRANSFER',
        courseName:
          'Mechanical and Materials Engineering 2273A/B - INTRODUCTION TO FLUID MECHANICS AND HEAT TRANSFER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244547f7e10015fd7587',
      },
      {
        _id: '60cc244547f7e10015fd7589',
        courseCode: 'MME 2285',
        shortName:
          'Mechanical and Materials Engineering 2285A/B - ENGINEERING EXPERIMENTATION',
        courseName:
          'Mechanical and Materials Engineering 2285A/B - ENGINEERING EXPERIMENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244547f7e10015fd7589',
      },
      {
        _id: '60cc244547f7e10015fd758b',
        courseCode: 'MME 3303',
        shortName:
          'Mechanical and Materials Engineering 3303A/B - FLUID MECHANICS II',
        courseName:
          'Mechanical and Materials Engineering 3303A/B - FLUID MECHANICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244547f7e10015fd758b',
      },
      {
        _id: '60cc244547f7e10015fd758d',
        courseCode: 'MME 3307',
        shortName:
          'Mechanical and Materials Engineering 3307A/B - HEAT TRANSFER II',
        courseName:
          'Mechanical and Materials Engineering 3307A/B - HEAT TRANSFER II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244547f7e10015fd758d',
      },
      {
        _id: '60cc244647f7e10015fd758f',
        courseCode: 'MME 3325',
        shortName:
          'Mechanical and Materials Engineering 3325A/B - MECHANICAL VIBRATIONS',
        courseName:
          'Mechanical and Materials Engineering 3325A/B - MECHANICAL VIBRATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244647f7e10015fd758f',
      },
      {
        _id: '60cc244647f7e10015fd7591',
        courseCode: 'MME 3334',
        shortName:
          'Mechanical and Materials Engineering 3334A/B - THERMODYNAMICS II',
        courseName:
          'Mechanical and Materials Engineering 3334A/B - THERMODYNAMICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244647f7e10015fd7591',
      },
      {
        _id: '60cc244647f7e10015fd7593',
        courseCode: 'MME 3350',
        shortName:
          'Mechanical and Materials Engineering 3350A/B - SYSTEM MODELING & CONTROL',
        courseName:
          'Mechanical and Materials Engineering 3350A/B - SYSTEM MODELING & CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244647f7e10015fd7593',
      },
      {
        _id: '60cc244647f7e10015fd7595',
        courseCode: 'MME 3360',
        shortName:
          'Mechanical and Materials Engineering 3360A/B - FINITE ELEMENT METHODS IN MECHANICAL ENGINEERING',
        courseName:
          'Mechanical and Materials Engineering 3360A/B - FINITE ELEMENT METHODS IN MECHANICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244647f7e10015fd7595',
      },
      {
        _id: '60cc244647f7e10015fd7597',
        courseCode: 'MME 3379',
        shortName:
          'Mechanical and Materials Engineering 3379A/B - MATERIALS SELECTION',
        courseName:
          'Mechanical and Materials Engineering 3379A/B - MATERIALS SELECTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244647f7e10015fd7597',
      },
      {
        _id: '60cc244747f7e10015fd7599',
        courseCode: 'MME 3380',
        shortName:
          'Mechanical and Materials Engineering 3380A/B - MECHANICAL COMPONENTS DESIGN',
        courseName:
          'Mechanical and Materials Engineering 3380A/B - MECHANICAL COMPONENTS DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244747f7e10015fd7599',
      },
      {
        _id: '60cc244747f7e10015fd759b',
        courseCode: 'MME 3381',
        shortName:
          'Mechanical and Materials Engineering 3381A/B - KINEMATICS & DYNAMICS OF MACHINES',
        courseName:
          'Mechanical and Materials Engineering 3381A/B - KINEMATICS & DYNAMICS OF MACHINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244747f7e10015fd759b',
      },
      {
        _id: '60cc244747f7e10015fd759d',
        courseCode: 'MME 4410',
        shortName:
          'Mechanical and Materials Engineering 4410 - MECHANICAL & MATERIALS ENGINEERING THESIS',
        courseName:
          'Mechanical and Materials Engineering 4410 - MECHANICAL & MATERIALS ENGINEERING THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244747f7e10015fd759d',
      },
      {
        _id: '60cc244747f7e10015fd759f',
        courseCode: 'MME 4423',
        shortName:
          'Mechanical and Materials Engineering 4423A/B - INTERNAL COMBUSTION ENGINES',
        courseName:
          'Mechanical and Materials Engineering 4423A/B - INTERNAL COMBUSTION ENGINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244747f7e10015fd759f',
      },
      {
        _id: '60cc244847f7e10015fd75a1',
        courseCode: 'MME 4424',
        shortName:
          'Mechanical and Materials Engineering 4424A/B - MECHANICAL PROPERTIES OF MATERIALS',
        courseName:
          'Mechanical and Materials Engineering 4424A/B - MECHANICAL PROPERTIES OF MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244847f7e10015fd75a1',
      },
      {
        _id: '60cc244947f7e10015fd75a3',
        courseCode: 'MME 4425',
        shortName:
          'Mechanical and Materials Engineering 4425A/B - MECHANICAL VIBRATIONS',
        courseName:
          'Mechanical and Materials Engineering 4425A/B - MECHANICAL VIBRATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244947f7e10015fd75a3',
      },
      {
        _id: '60cc244947f7e10015fd75a5',
        courseCode: 'MME 4427',
        shortName:
          'Mechanical and Materials Engineering 4427A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING III',
        courseName:
          'Mechanical and Materials Engineering 4427A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244947f7e10015fd75a5',
      },
      {
        _id: '60cc244a47f7e10015fd75a7',
        courseCode: 'MME 4428',
        shortName:
          'Mechanical and Materials Engineering 4428A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING IV',
        courseName:
          'Mechanical and Materials Engineering 4428A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244a47f7e10015fd75a7',
      },
      {
        _id: '60cc244b47f7e10015fd75a9',
        courseCode: 'MME 4429',
        shortName:
          'Mechanical and Materials Engineering 4429A/B - NUCLEAR ENGINEERING',
        courseName:
          'Mechanical and Materials Engineering 4429A/B - NUCLEAR ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244b47f7e10015fd75a9',
      },
      {
        _id: '60cc244b47f7e10015fd75ab',
        courseCode: 'MME 4435',
        shortName:
          'Mechanical and Materials Engineering 4435A/B - PRESSURE VESSEL DESIGN',
        courseName:
          'Mechanical and Materials Engineering 4435A/B - PRESSURE VESSEL DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244b47f7e10015fd75ab',
      },
      {
        _id: '60cc244c47f7e10015fd75ad',
        courseCode: 'MME 4437',
        shortName:
          'Mechanical and Materials Engineering 4437A/B - ADVANCED CAE: SIMULATION',
        courseName:
          'Mechanical and Materials Engineering 4437A/B - ADVANCED CAE: SIMULATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244c47f7e10015fd75ad',
      },
      {
        _id: '60cc244d47f7e10015fd75af',
        courseCode: 'MME 4446',
        shortName:
          'Mechanical and Materials Engineering 4446A/B - COMPOSITE MATERIALS',
        courseName:
          'Mechanical and Materials Engineering 4446A/B - COMPOSITE MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244d47f7e10015fd75af',
      },
      {
        _id: '60cc244e47f7e10015fd75b1',
        courseCode: 'MME 4450',
        shortName:
          'Mechanical and Materials Engineering 4450A/B - CONTROL SYSTEMS: THEORY & PRACTICE',
        courseName:
          'Mechanical and Materials Engineering 4450A/B - CONTROL SYSTEMS: THEORY & PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244e47f7e10015fd75b1',
      },
      {
        _id: '60cc244f47f7e10015fd75b3',
        courseCode: 'MME 4452',
        shortName:
          'Mechanical and Materials Engineering 4452A/B - ROBOTICS & MANUFACTURING AUTOMATION',
        courseName:
          'Mechanical and Materials Engineering 4452A/B - ROBOTICS & MANUFACTURING AUTOMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc244f47f7e10015fd75b3',
      },
      {
        _id: '60cc245147f7e10015fd75b5',
        courseCode: 'MME 4453',
        shortName:
          'Mechanical and Materials Engineering 4453A/B - CORROSION AND WEAR',
        courseName:
          'Mechanical and Materials Engineering 4453A/B - CORROSION AND WEAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245147f7e10015fd75b5',
      },
      {
        _id: '60cc245247f7e10015fd75b7',
        courseCode: 'MME 4459',
        shortName:
          'Mechanical and Materials Engineering 4459A/B - ADVANCED CAE: MANUFACTURING TECHNOLOGIES',
        courseName:
          'Mechanical and Materials Engineering 4459A/B - ADVANCED CAE: MANUFACTURING TECHNOLOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245247f7e10015fd75b7',
      },
      {
        _id: '60cc245347f7e10015fd75b9',
        courseCode: 'MME 4460',
        shortName:
          'Mechanical and Materials Engineering 4460A/B - HEATING, VENTILATING & AIR CONDITIONING II',
        courseName:
          'Mechanical and Materials Engineering 4460A/B - HEATING, VENTILATING & AIR CONDITIONING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245347f7e10015fd75b9',
      },
      {
        _id: '60cc245447f7e10015fd75bb',
        courseCode: 'MME 4469',
        shortName:
          'Mechanical and Materials Engineering 4469A/B - BIOMECHANICS OF THE MUSCULOSKELETAL SYSTEM',
        courseName:
          'Mechanical and Materials Engineering 4469A/B - BIOMECHANICS OF THE MUSCULOSKELETAL SYSTEM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245447f7e10015fd75bb',
      },
      {
        _id: '60cc245547f7e10015fd75bd',
        courseCode: 'MME 4470',
        shortName:
          'Mechanical and Materials Engineering 4470A/B - MEDICAL AND ASSISTIVE DEVICES',
        courseName:
          'Mechanical and Materials Engineering 4470A/B - MEDICAL AND ASSISTIVE DEVICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245547f7e10015fd75bd',
      },
      {
        _id: '60cc245647f7e10015fd75bf',
        courseCode: 'MME 4473',
        shortName:
          'Mechanical and Materials Engineering 4473A/B - COMPUTER INTEGRATED MANUFACTURING',
        courseName:
          'Mechanical and Materials Engineering 4473A/B - COMPUTER INTEGRATED MANUFACTURING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245647f7e10015fd75bf',
      },
      {
        _id: '60cc245747f7e10015fd75c1',
        courseCode: 'MME 4474',
        shortName:
          'Mechanical and Materials Engineering 4474A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING I',
        courseName:
          'Mechanical and Materials Engineering 4474A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245747f7e10015fd75c1',
      },
      {
        _id: '60cc245847f7e10015fd75c3',
        courseCode: 'MME 4475',
        shortName:
          'Mechanical and Materials Engineering 4475A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING II',
        courseName:
          'Mechanical and Materials Engineering 4475A/B - SELECTED TOPICS IN MECHANICAL ENGINEERING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245847f7e10015fd75c3',
      },
      {
        _id: '60cc245a47f7e10015fd75c5',
        courseCode: 'MME 4480',
        shortName:
          'Mechanical and Materials Engineering 4480A/B - ADVANCED CAE: REVERSE ENGINEERING',
        courseName:
          'Mechanical and Materials Engineering 4480A/B - ADVANCED CAE: REVERSE ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245a47f7e10015fd75c5',
      },
      {
        _id: '60cc245c47f7e10015fd75c7',
        courseCode: 'MME 4482',
        shortName:
          'Mechanical and Materials Engineering 4482A/B - FUNDAMENTALS OF MEMS',
        courseName:
          'Mechanical and Materials Engineering 4482A/B - FUNDAMENTALS OF MEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245c47f7e10015fd75c7',
      },
      {
        _id: '60cc245c47f7e10015fd75c9',
        courseCode: 'MME 4483',
        shortName:
          'Mechanical and Materials Engineering 4483A/B - HEATING, VENTILATING & AIR CONDITIONING',
        courseName:
          'Mechanical and Materials Engineering 4483A/B - HEATING, VENTILATING & AIR CONDITIONING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245c47f7e10015fd75c9',
      },
      {
        _id: '60cc245d47f7e10015fd75cb',
        courseCode: 'MME 4485',
        shortName:
          'Mechanical and Materials Engineering 4485A/B - FLUID MACHINERY',
        courseName:
          'Mechanical and Materials Engineering 4485A/B - FLUID MACHINERY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245d47f7e10015fd75cb',
      },
      {
        _id: '60cc245d47f7e10015fd75cd',
        courseCode: 'MME 4487',
        shortName:
          'Mechanical and Materials Engineering 4487A/B - MECHATRONIC SYSTEM DESIGN',
        courseName:
          'Mechanical and Materials Engineering 4487A/B - MECHATRONIC SYSTEM DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245d47f7e10015fd75cd',
      },
      {
        _id: '60cc245e47f7e10015fd75d0',
        courseCode: 'MME 4492',
        shortName:
          'Mechanical and Materials Engineering 4492A/B - PRODUCTION MANAGEMENT FOR ENGINEERS',
        courseName:
          'Mechanical and Materials Engineering 4492A/B - PRODUCTION MANAGEMENT FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245e47f7e10015fd75d0',
      },
      {
        _id: '60cc245e47f7e10015fd75d2',
        courseCode: 'MME 4499',
        shortName:
          'Mechanical and Materials Engineering 4499 - MECHANICAL ENGINEERING DESIGN PROJECT',
        courseName:
          'Mechanical and Materials Engineering 4499 - MECHANICAL ENGINEERING DESIGN PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245e47f7e10015fd75d2',
      },
      {
        _id: '60cc245f47f7e10015fd75d4',
        courseCode: 'MSE 2200',
        shortName:
          'Mechatronic Systems Engineering 2200Q/R/S/T - ENGINEERING SHOP SAFETY TRAINING',
        courseName:
          'Mechatronic Systems Engineering 2200Q/R/S/T - ENGINEERING SHOP SAFETY TRAINING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245f47f7e10015fd75d4',
      },
      {
        _id: '60cc245f47f7e10015fd75d6',
        courseCode: 'MSE 2201',
        shortName:
          'Mechatronic Systems Engineering 2201A/B - INTRODUCTION TO ELECTRICAL INSTRUMENTATION',
        courseName:
          'Mechatronic Systems Engineering 2201A/B - INTRODUCTION TO ELECTRICAL INSTRUMENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245f47f7e10015fd75d6',
      },
      {
        _id: '60cc245f47f7e10015fd75d8',
        courseCode: 'MSE 2202',
        shortName:
          'Mechatronic Systems Engineering 2202A/B - INTRO TO MECHATRONIC DESIGN',
        courseName:
          'Mechatronic Systems Engineering 2202A/B - INTRO TO MECHATRONIC DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc245f47f7e10015fd75d8',
      },
      {
        _id: '60cc246047f7e10015fd75da',
        courseCode: 'MSE 2212',
        shortName:
          'Mechatronic Systems Engineering 2212A/B - MECHANICS OF MATERIALS',
        courseName:
          'Mechatronic Systems Engineering 2212A/B - MECHANICS OF MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246047f7e10015fd75da',
      },
      {
        _id: '60cc246047f7e10015fd75dc',
        courseCode: 'MSE 2213',
        shortName:
          'Mechatronic Systems Engineering 2213A/B - ENGINEERING DYNAMICS',
        courseName:
          'Mechatronic Systems Engineering 2213A/B - ENGINEERING DYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246047f7e10015fd75dc',
      },
      {
        _id: '60cc246147f7e10015fd75de',
        courseCode: 'MSE 2214',
        shortName: 'Mechatronic Systems Engineering 2214A/B - THERMODYNAMICS',
        courseName: 'Mechatronic Systems Engineering 2214A/B - THERMODYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246147f7e10015fd75de',
      },
      {
        _id: '60cc246147f7e10015fd75e0',
        courseCode: 'MSE 2233',
        shortName:
          'Mechatronic Systems Engineering 2233A/B - CIRCUITS AND SYSTEMS',
        courseName:
          'Mechatronic Systems Engineering 2233A/B - CIRCUITS AND SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246147f7e10015fd75e0',
      },
      {
        _id: '60cc246247f7e10015fd75e2',
        courseCode: 'MSE 2273',
        shortName:
          'Mechatronic Systems Engineering 2273A/B - INTRODUCTION TO FLUID MECHANICS AND HEAT TRANSFER',
        courseName:
          'Mechatronic Systems Engineering 2273A/B - INTRODUCTION TO FLUID MECHANICS AND HEAT TRANSFER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246247f7e10015fd75e2',
      },
      {
        _id: '60cc246347f7e10015fd75e4',
        courseCode: 'MSE 3301',
        shortName:
          'Mechatronic Systems Engineering 3301A/B - MATERIALS SELECTION AND MANUFACTURING PROCESSES',
        courseName:
          'Mechatronic Systems Engineering 3301A/B - MATERIALS SELECTION AND MANUFACTURING PROCESSES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246347f7e10015fd75e4',
      },
      {
        _id: '60cc246447f7e10015fd75e6',
        courseCode: 'MSE 3302',
        shortName:
          'Mechatronic Systems Engineering 3302A/B - SENSORS & ACTUATORS',
        courseName:
          'Mechatronic Systems Engineering 3302A/B - SENSORS & ACTUATORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246447f7e10015fd75e6',
      },
      {
        _id: '60cc246447f7e10015fd75e8',
        courseCode: 'MSE 3310',
        shortName:
          'Mechatronic Systems Engineering 3310A/B - ELECTRIC MOTORS AND DRIVES',
        courseName:
          'Mechatronic Systems Engineering 3310A/B - ELECTRIC MOTORS AND DRIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246447f7e10015fd75e8',
      },
      {
        _id: '60cc246447f7e10015fd75ea',
        courseCode: 'MSE 3360',
        shortName:
          'Mechatronic Systems Engineering 3360A/B - FINITE ELEMENT METHODS FOR MECHATRONIC SYSTEMS ENGINEERING',
        courseName:
          'Mechatronic Systems Engineering 3360A/B - FINITE ELEMENT METHODS FOR MECHATRONIC SYSTEMS ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246447f7e10015fd75ea',
      },
      {
        _id: '60cc246547f7e10015fd75ec',
        courseCode: 'MSE 3380',
        shortName:
          'Mechatronic Systems Engineering 3380A/B - MECHANICAL COMPONENTS DESIGN FOR MECHATRONIC SYSTEMS',
        courseName:
          'Mechatronic Systems Engineering 3380A/B - MECHANICAL COMPONENTS DESIGN FOR MECHATRONIC SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246547f7e10015fd75ec',
      },
      {
        _id: '60cc246547f7e10015fd75ee',
        courseCode: 'MSE 3381',
        shortName:
          'Mechatronic Systems Engineering 3381A/B - KINETICS & DYNAMICS OF MACHINES',
        courseName:
          'Mechatronic Systems Engineering 3381A/B - KINETICS & DYNAMICS OF MACHINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246547f7e10015fd75ee',
      },
      {
        _id: '60cc246647f7e10015fd75f0',
        courseCode: 'MSE 4401',
        shortName:
          'Mechatronic Systems Engineering 4401A/B - ROBOTIC MANIPULATORS',
        courseName:
          'Mechatronic Systems Engineering 4401A/B - ROBOTIC MANIPULATORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246647f7e10015fd75f0',
      },
      {
        _id: '60cc246647f7e10015fd75f2',
        courseCode: 'MSE 4499',
        shortName:
          'Mechatronic Systems Engineering 4499 - MECHATRONIC DESIGN PROJECT',
        courseName:
          'Mechatronic Systems Engineering 4499 - MECHATRONIC DESIGN PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246647f7e10015fd75f2',
      },
      {
        _id: '60cc246647f7e10015fd75f4',
        courseCode: 'MIT 1020',
        shortName:
          'Media, Information and Technoculture 1020E - INTRODUCTION TO MEDIA, INFORMATION, AND TECHNOCULTURE',
        courseName:
          'Media, Information and Technoculture 1020E - INTRODUCTION TO MEDIA, INFORMATION, AND TECHNOCULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246647f7e10015fd75f4',
      },
      {
        _id: '60cc246747f7e10015fd75f8',
        courseCode: 'MIT 1025',
        shortName:
          'Media, Information and Technoculture 1025F/G - FIRST YEAR FOUNDATIONS',
        courseName:
          'Media, Information and Technoculture 1025F/G - FIRST YEAR FOUNDATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246747f7e10015fd75f8',
      },
      {
        _id: '60cc246847f7e10015fd75fa',
        courseCode: 'MIT 1050',
        shortName:
          'Media, Information and Technoculture 1050A/B - NAVIGATING OUR MEDIA LANDSCAPE: #MEDIACULTURE',
        courseName:
          'Media, Information and Technoculture 1050A/B - NAVIGATING OUR MEDIA LANDSCAPE: #MEDIACULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246847f7e10015fd75fa',
      },
      {
        _id: '60cc246947f7e10015fd75fc',
        courseCode: 'MIT 1070',
        shortName:
          'Media, Information and Technoculture 1070A/B - FIRST YEAR FOUNDATIONS IN MEDIA PRODUCTION',
        courseName:
          'Media, Information and Technoculture 1070A/B - FIRST YEAR FOUNDATIONS IN MEDIA PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246947f7e10015fd75fc',
      },
      {
        _id: '60cc246947f7e10015fd75fe',
        courseCode: 'MIT 2000',
        shortName:
          'Media, Information and Technoculture 2000F/G - THE HISTORY OF COMMUNICATION',
        courseName:
          'Media, Information and Technoculture 2000F/G - THE HISTORY OF COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246947f7e10015fd75fe',
      },
      {
        _id: '60cc246a47f7e10015fd7600',
        courseCode: 'MIT 2025',
        shortName:
          'Media, Information and Technoculture 2025A/B - RESEARCH METHODS FOR THE DIGITAL AGE',
        courseName:
          'Media, Information and Technoculture 2025A/B - RESEARCH METHODS FOR THE DIGITAL AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246a47f7e10015fd7600',
      },
      {
        _id: '60cc246a47f7e10015fd7602',
        courseCode: 'MIT 2100',
        shortName:
          'Media, Information and Technoculture 2100F/G - POLITICAL ECONOMY OF MEDIA',
        courseName:
          'Media, Information and Technoculture 2100F/G - POLITICAL ECONOMY OF MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246a47f7e10015fd7602',
      },
      {
        _id: '60cc246a47f7e10015fd7604',
        courseCode: 'MIT 2149',
        shortName:
          'Media, Information and Technoculture 2149F/G - HEALTH, MEDIA AND INFORMATION',
        courseName:
          'Media, Information and Technoculture 2149F/G - HEALTH, MEDIA AND INFORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246a47f7e10015fd7604',
      },
      {
        _id: '60cc246b47f7e10015fd7606',
        courseCode: 'MIT 2150',
        shortName:
          'Media, Information and Technoculture 2150F/G - MEDIA, REPRESENTATION, AND IDENTITY',
        courseName:
          'Media, Information and Technoculture 2150F/G - MEDIA, REPRESENTATION, AND IDENTITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246b47f7e10015fd7606',
      },
      {
        _id: '60cc246b47f7e10015fd7608',
        courseCode: 'MIT 2151',
        shortName:
          'Media, Information and Technoculture 2151A/B - CONSUMER SOCIETY: ADVERTISING AND PROMOTIONAL CULTURE',
        courseName:
          'Media, Information and Technoculture 2151A/B - CONSUMER SOCIETY: ADVERTISING AND PROMOTIONAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246b47f7e10015fd7608',
      },
      {
        _id: '60cc246c47f7e10015fd760a',
        courseCode: 'MIT 2152',
        shortName:
          'Media, Information and Technoculture 2152F/G - MEDIA AND SOCIAL MOVEMENTS',
        courseName:
          'Media, Information and Technoculture 2152F/G - MEDIA AND SOCIAL MOVEMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246c47f7e10015fd760a',
      },
      {
        _id: '60cc246c47f7e10015fd760c',
        courseCode: 'MIT 2153',
        shortName:
          'Media, Information and Technoculture 2153A/B - ENTERTAINMENT INDUSTRIES AND POPULAR CULTURE',
        courseName:
          'Media, Information and Technoculture 2153A/B - ENTERTAINMENT INDUSTRIES AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246c47f7e10015fd760c',
      },
      {
        _id: '60cc246c47f7e10015fd760e',
        courseCode: 'MIT 2154',
        shortName:
          'Media, Information and Technoculture 2154F/G - JOURNALISM PRACTICES AND PRINCIPLES',
        courseName:
          'Media, Information and Technoculture 2154F/G - JOURNALISM PRACTICES AND PRINCIPLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246c47f7e10015fd760e',
      },
      {
        _id: '60cc246d47f7e10015fd7610',
        courseCode: 'MIT 2155',
        shortName:
          'Media, Information and Technoculture 2155A/B - SOCIAL MEDIA, VIRTUAL GAMING AND NETWORKED LIFE',
        courseName:
          'Media, Information and Technoculture 2155A/B - SOCIAL MEDIA, VIRTUAL GAMING AND NETWORKED LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246d47f7e10015fd7610',
      },
      {
        _id: '60cc246d47f7e10015fd7612',
        courseCode: 'MIT 2156',
        shortName:
          'Media, Information and Technoculture 2156A/B - MEDIA LAW AND ETHICS',
        courseName:
          'Media, Information and Technoculture 2156A/B - MEDIA LAW AND ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246d47f7e10015fd7612',
      },
      {
        _id: '60cc246d47f7e10015fd7614',
        courseCode: 'MIT 2157',
        shortName:
          'Media, Information and Technoculture 2157A/B - PLANET GOOGLE: BIG DATA AND INFORMATION SOCIETY',
        courseName:
          'Media, Information and Technoculture 2157A/B - PLANET GOOGLE: BIG DATA AND INFORMATION SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246d47f7e10015fd7614',
      },
      {
        _id: '60cc246e47f7e10015fd7616',
        courseCode: 'MIT 2158',
        shortName:
          'Media, Information and Technoculture 2158F/G - FOUNDATIONS OF GLOBAL COMMUNICATONS',
        courseName:
          'Media, Information and Technoculture 2158F/G - FOUNDATIONS OF GLOBAL COMMUNICATONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246e47f7e10015fd7616',
      },
      {
        _id: '60cc246e47f7e10015fd7618',
        courseCode: 'MIT 2159',
        shortName:
          'Media, Information and Technoculture 2159A/B - INTRODUCTION TO POPULAR MUSIC STUDIES',
        courseName:
          'Media, Information and Technoculture 2159A/B - INTRODUCTION TO POPULAR MUSIC STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc246e47f7e10015fd7618',
      },
      {
        _id: '60cc247447f7e10015fd762a',
        courseCode: 'MIT 2176',
        shortName:
          'Media, Information and Technoculture 2176F/G - WAR FOR WAR: MEDIATION, MILITARIZATION AND CONFLICT',
        courseName:
          'Media, Information and Technoculture 2176F/G - WAR FOR WAR: MEDIATION, MILITARIZATION AND CONFLICT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247447f7e10015fd762a',
      },
      {
        _id: '60cc247a47f7e10015fd763c',
        courseCode: 'MIT 2200',
        shortName:
          'Media, Information and Technoculture 2200F/G - MAPPING MEDIA AND CULTURAL THEORY',
        courseName:
          'Media, Information and Technoculture 2200F/G - MAPPING MEDIA AND CULTURAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247a47f7e10015fd763c',
      },
      {
        _id: '60cc247b47f7e10015fd763f',
        courseCode: 'MIT 2550',
        shortName:
          'Media, Information and Technoculture 2550A/B - DIGITAL MUSIC: AN INTRODUCTION',
        courseName:
          'Media, Information and Technoculture 2550A/B - DIGITAL MUSIC: AN INTRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247b47f7e10015fd763f',
      },
      {
        _id: '60cc247b47f7e10015fd7641',
        courseCode: 'MIT 2570',
        shortName:
          'Media, Information and Technoculture 2570A/B - INTRODUCTION TO  DIGITAL IMAGING AND WEB SITE DESIGN',
        courseName:
          'Media, Information and Technoculture 2570A/B - INTRODUCTION TO  DIGITAL IMAGING AND WEB SITE DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247b47f7e10015fd7641',
      },
      {
        _id: '60cc247b47f7e10015fd7643',
        courseCode: 'MIT 2600',
        shortName:
          'Media, Information and Technoculture 2600A/B - INTRODUCTION TO GRAPHIC DESIGN',
        courseName:
          'Media, Information and Technoculture 2600A/B - INTRODUCTION TO GRAPHIC DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247b47f7e10015fd7643',
      },
      {
        _id: '60cc247c47f7e10015fd7645',
        courseCode: 'MIT 2601',
        shortName:
          'Media, Information and Technoculture 2601A/B - INTRODUCTION TO VIDEO THEORY AND PRODUCTION',
        courseName:
          'Media, Information and Technoculture 2601A/B - INTRODUCTION TO VIDEO THEORY AND PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247c47f7e10015fd7645',
      },
      {
        _id: '60cc247c47f7e10015fd7648',
        courseCode: 'MIT 3000',
        shortName:
          'Media, Information and Technoculture 3000A/B - DESIGNING AND CRITIQUING RESEARCH METHODS',
        courseName:
          'Media, Information and Technoculture 3000A/B - DESIGNING AND CRITIQUING RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247c47f7e10015fd7648',
      },
      {
        _id: '60cc247d47f7e10015fd764a',
        courseCode: 'MIT 3010',
        shortName:
          'Media, Information and Technoculture 3010E - DIRECTED READINGS IN MIT',
        courseName:
          'Media, Information and Technoculture 3010E - DIRECTED READINGS IN MIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247d47f7e10015fd764a',
      },
      {
        _id: '60cc247d47f7e10015fd764c',
        courseCode: 'MIT 3011',
        shortName:
          'Media, Information and Technoculture 3011F/G - DIRECTED READINGS IN MIT',
        courseName:
          'Media, Information and Technoculture 3011F/G - DIRECTED READINGS IN MIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247d47f7e10015fd764c',
      },
      {
        _id: '60cc247e47f7e10015fd764e',
        courseCode: 'MIT 3100',
        shortName:
          'Media, Information and Technoculture 3100F/G - INFORMATION IN THE PUBLIC SPHERE',
        courseName:
          'Media, Information and Technoculture 3100F/G - INFORMATION IN THE PUBLIC SPHERE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247e47f7e10015fd764e',
      },
      {
        _id: '60cc247e47f7e10015fd7650',
        courseCode: 'MIT 3110',
        shortName:
          'Media, Information and Technoculture 3110F/G - GLOBAL POLITICAL ECONOMY OF COMMUNICATION',
        courseName:
          'Media, Information and Technoculture 3110F/G - GLOBAL POLITICAL ECONOMY OF COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247e47f7e10015fd7650',
      },
      {
        _id: '60cc247f47f7e10015fd7653',
        courseCode: 'MIT 3132',
        shortName:
          'Media, Information and Technoculture 3132F/G - THE POLITICAL ECONOMY OF THE ENTERTAINMENT INDUSTRIES',
        courseName:
          'Media, Information and Technoculture 3132F/G - THE POLITICAL ECONOMY OF THE ENTERTAINMENT INDUSTRIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247f47f7e10015fd7653',
      },
      {
        _id: '60cc247f47f7e10015fd7656',
        courseCode: 'MIT 3200',
        shortName:
          'Media, Information and Technoculture 3200F/G - REBELS AND ROGUES: OUTLAWS IN CULTURE AND THEORY',
        courseName:
          'Media, Information and Technoculture 3200F/G - REBELS AND ROGUES: OUTLAWS IN CULTURE AND THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247f47f7e10015fd7656',
      },
      {
        _id: '60cc247f47f7e10015fd7658',
        courseCode: 'MIT 3201',
        shortName:
          'Media, Information and Technoculture 3201F/G - SEXUALITY AND THE MEDIA',
        courseName:
          'Media, Information and Technoculture 3201F/G - SEXUALITY AND THE MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc247f47f7e10015fd7658',
      },
      {
        _id: '60cc248047f7e10015fd765a',
        courseCode: 'MIT 3202',
        shortName:
          'Media, Information and Technoculture 3202F/G - MEDIA AND MENTAL HEALTH',
        courseName:
          'Media, Information and Technoculture 3202F/G - MEDIA AND MENTAL HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248047f7e10015fd765a',
      },
      {
        _id: '60cc248047f7e10015fd765c',
        courseCode: 'MIT 3205',
        shortName:
          'Media, Information and Technoculture 3205F/G - PUBLIC RELATIONS: A CRITICAL EXAMINATION',
        courseName:
          'Media, Information and Technoculture 3205F/G - PUBLIC RELATIONS: A CRITICAL EXAMINATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248047f7e10015fd765c',
      },
      {
        _id: '60cc248047f7e10015fd765e',
        courseCode: 'MIT 3206',
        shortName:
          'Media, Information and Technoculture 3206F/G - CRITICAL PERSPECTIVES IN ADVERTISING',
        courseName:
          'Media, Information and Technoculture 3206F/G - CRITICAL PERSPECTIVES IN ADVERTISING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248047f7e10015fd765e',
      },
      {
        _id: '60cc248047f7e10015fd7660',
        courseCode: 'MIT 3207',
        shortName:
          'Media, Information and Technoculture 3207F/G - CHILDREN, ADVERTISING & CONSUMER CULTURE',
        courseName:
          'Media, Information and Technoculture 3207F/G - CHILDREN, ADVERTISING & CONSUMER CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248047f7e10015fd7660',
      },
      {
        _id: '60cc248147f7e10015fd7662',
        courseCode: 'MIT 3208',
        shortName:
          "Media, Information and Technoculture 3208F/G - WOMEN'S TELEVISION:  HISTORY, GENDER, FEMINISM",
        courseName:
          "Media, Information and Technoculture 3208F/G - WOMEN'S TELEVISION:  HISTORY, GENDER, FEMINISM",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248147f7e10015fd7662',
      },
      {
        _id: '60cc248147f7e10015fd7664',
        courseCode: 'MIT 3210',
        shortName:
          'Media, Information and Technoculture 3210F/G - MEDIA REPRESENTATIONS OF WOMEN',
        courseName:
          'Media, Information and Technoculture 3210F/G - MEDIA REPRESENTATIONS OF WOMEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248147f7e10015fd7664',
      },
      {
        _id: '60cc248147f7e10015fd7666',
        courseCode: 'MIT 3211',
        shortName:
          'Media, Information and Technoculture 3211F/G - THE CULTURE OF CELEBRITY',
        courseName:
          'Media, Information and Technoculture 3211F/G - THE CULTURE OF CELEBRITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248147f7e10015fd7666',
      },
      {
        _id: '60cc248147f7e10015fd7668',
        courseCode: 'MIT 3212',
        shortName:
          'Media, Information and Technoculture 3212F/G - MEDIA MASCULINITIES 1945-1975',
        courseName:
          'Media, Information and Technoculture 3212F/G - MEDIA MASCULINITIES 1945-1975',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248147f7e10015fd7668',
      },
      {
        _id: '60cc248247f7e10015fd766a',
        courseCode: 'MIT 3213',
        shortName:
          'Media, Information and Technoculture 3213F/G - MEDIA AND AUDIENCES',
        courseName:
          'Media, Information and Technoculture 3213F/G - MEDIA AND AUDIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248247f7e10015fd766a',
      },
      {
        _id: '60cc248247f7e10015fd766d',
        courseCode: 'MIT 3216',
        shortName:
          'Media, Information and Technoculture 3216F/G - THE CULTURE OF CONSUMPTION',
        courseName:
          'Media, Information and Technoculture 3216F/G - THE CULTURE OF CONSUMPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248247f7e10015fd766d',
      },
      {
        _id: '60cc248247f7e10015fd766f',
        courseCode: 'MIT 3217',
        shortName:
          'Media, Information and Technoculture 3217F/G - PUBLIC OPINION',
        courseName:
          'Media, Information and Technoculture 3217F/G - PUBLIC OPINION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248247f7e10015fd766f',
      },
      {
        _id: '60cc248347f7e10015fd7671',
        courseCode: 'MIT 3218',
        shortName:
          'Media, Information and Technoculture 3218F/G - INVASION USA: AMERICAN NUCLEAR CULTURE',
        courseName:
          'Media, Information and Technoculture 3218F/G - INVASION USA: AMERICAN NUCLEAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248347f7e10015fd7671',
      },
      {
        _id: '60cc248347f7e10015fd7673',
        courseCode: 'MIT 3219',
        shortName:
          'Media, Information and Technoculture 3219F/G - MEDIA CONVERGENCE',
        courseName:
          'Media, Information and Technoculture 3219F/G - MEDIA CONVERGENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248347f7e10015fd7673',
      },
      {
        _id: '60cc248347f7e10015fd7675',
        courseCode: 'MIT 3221',
        shortName:
          'Media, Information and Technoculture 3221F/G - PHOTOGRAPHY, POLITICS, HISTORY',
        courseName:
          'Media, Information and Technoculture 3221F/G - PHOTOGRAPHY, POLITICS, HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248347f7e10015fd7675',
      },
      {
        _id: '60cc248347f7e10015fd7677',
        courseCode: 'MIT 3222',
        shortName:
          'Media, Information and Technoculture 3222A/B - RADIO AND TELEVISION AS ENTERTAINMENT MEDIA',
        courseName:
          'Media, Information and Technoculture 3222A/B - RADIO AND TELEVISION AS ENTERTAINMENT MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248347f7e10015fd7677',
      },
      {
        _id: '60cc248447f7e10015fd7679',
        courseCode: 'MIT 3223',
        shortName:
          'Media, Information and Technoculture 3223F/G - TALES OF TINSELTOWN',
        courseName:
          'Media, Information and Technoculture 3223F/G - TALES OF TINSELTOWN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248447f7e10015fd7679',
      },
      {
        _id: '60cc248447f7e10015fd767b',
        courseCode: 'MIT 3224',
        shortName:
          'Media, Information and Technoculture 3224F/G - CRASH LANDSCAPES',
        courseName:
          'Media, Information and Technoculture 3224F/G - CRASH LANDSCAPES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248447f7e10015fd767b',
      },
      {
        _id: '60cc248447f7e10015fd767d',
        courseCode: 'MIT 3225',
        shortName:
          'Media, Information and Technoculture 3225F/G - PROPAGANDA IN PRINT AND VISUAL CULTURE',
        courseName:
          'Media, Information and Technoculture 3225F/G - PROPAGANDA IN PRINT AND VISUAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248447f7e10015fd767d',
      },
      {
        _id: '60cc248447f7e10015fd767f',
        courseCode: 'MIT 3226',
        shortName:
          'Media, Information and Technoculture 3226F/G - COMMUNICATION THROUGH MEME',
        courseName:
          'Media, Information and Technoculture 3226F/G - COMMUNICATION THROUGH MEME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248447f7e10015fd767f',
      },
      {
        _id: '60cc248547f7e10015fd7682',
        courseCode: 'MIT 3325',
        shortName:
          "Media, Information and Technoculture 3325F/G - ADVERTISING'S CENTURY",
        courseName:
          "Media, Information and Technoculture 3325F/G - ADVERTISING'S CENTURY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248547f7e10015fd7682',
      },
      {
        _id: '60cc248547f7e10015fd7684',
        courseCode: 'MIT 3351',
        shortName:
          'Media, Information and Technoculture 3351F/G - POP MUSIC AND/AS CULTURAL LABOUR',
        courseName:
          'Media, Information and Technoculture 3351F/G - POP MUSIC AND/AS CULTURAL LABOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248547f7e10015fd7684',
      },
      {
        _id: '60cc248547f7e10015fd7686',
        courseCode: 'MIT 3353',
        shortName:
          'Media, Information and Technoculture 3353F/G - POPULAR MUSIC AND IDENTITY',
        courseName:
          'Media, Information and Technoculture 3353F/G - POPULAR MUSIC AND IDENTITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248547f7e10015fd7686',
      },
      {
        _id: '60cc248547f7e10015fd7688',
        courseCode: 'MIT 3354',
        shortName:
          'Media, Information and Technoculture 3354F/G - THE BIRTH OF NEW MUSIC MEDIA',
        courseName:
          'Media, Information and Technoculture 3354F/G - THE BIRTH OF NEW MUSIC MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248547f7e10015fd7688',
      },
      {
        _id: '60cc248647f7e10015fd768c',
        courseCode: 'MIT 3373',
        shortName:
          'Media, Information and Technoculture 3373F/G - SOCIAL MEDIA & ORGANIZATIONS',
        courseName:
          'Media, Information and Technoculture 3373F/G - SOCIAL MEDIA & ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248647f7e10015fd768c',
      },
      {
        _id: '60cc248647f7e10015fd768e',
        courseCode: 'MIT 3375',
        shortName:
          'Media, Information and Technoculture 3375F/G - SOCIAL NETWORKING: THEORY & PRACTICE',
        courseName:
          'Media, Information and Technoculture 3375F/G - SOCIAL NETWORKING: THEORY & PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248647f7e10015fd768e',
      },
      {
        _id: '60cc248747f7e10015fd7690',
        courseCode: 'MIT 3376',
        shortName:
          'Media, Information and Technoculture 3376F/G - CYBER-LIFE: COMMUNICATION IN THE DIGITAL AGE',
        courseName:
          'Media, Information and Technoculture 3376F/G - CYBER-LIFE: COMMUNICATION IN THE DIGITAL AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248747f7e10015fd7690',
      },
      {
        _id: '60cc248747f7e10015fd7692',
        courseCode: 'MIT 3377',
        shortName:
          'Media, Information and Technoculture 3377F/G - DIGITAL INTIMACY',
        courseName:
          'Media, Information and Technoculture 3377F/G - DIGITAL INTIMACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248747f7e10015fd7692',
      },
      {
        _id: '60cc248747f7e10015fd7694',
        courseCode: 'MIT 3379',
        shortName:
          'Media, Information and Technoculture 3379F/G - ENTERTAINMENT AND THE LAW',
        courseName:
          'Media, Information and Technoculture 3379F/G - ENTERTAINMENT AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248747f7e10015fd7694',
      },
      {
        _id: '60cc248c47f7e10015fd76b0',
        courseCode: 'MIT 3500',
        shortName:
          'Media, Information and Technoculture 3500F/G - MEDIA AND INFORMATION TECHNOLOGY POLICY',
        courseName:
          'Media, Information and Technoculture 3500F/G - MEDIA AND INFORMATION TECHNOLOGY POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248c47f7e10015fd76b0',
      },
      {
        _id: '60cc248f47f7e10015fd76c2',
        courseCode: 'MIT 3776',
        shortName:
          'Media, Information and Technoculture 3776A/B - PODCASTING AND AUDIO JOURNALISM',
        courseName:
          'Media, Information and Technoculture 3776A/B - PODCASTING AND AUDIO JOURNALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248f47f7e10015fd76c2',
      },
      {
        _id: '60cc248f47f7e10015fd76c4',
        courseCode: 'MIT 3777',
        shortName:
          'Media, Information and Technoculture 3777A/B - MOBILE JOURNALISM',
        courseName:
          'Media, Information and Technoculture 3777A/B - MOBILE JOURNALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248f47f7e10015fd76c4',
      },
      {
        _id: '60cc248f47f7e10015fd76c6',
        courseCode: 'MIT 3778',
        shortName:
          'Media, Information and Technoculture 3778A/B - MAKING: THEORY AND PRACTICE',
        courseName:
          'Media, Information and Technoculture 3778A/B - MAKING: THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc248f47f7e10015fd76c6',
      },
      {
        _id: '60cc249047f7e10015fd76c8',
        courseCode: 'MIT 3829',
        shortName:
          'Media, Information and Technoculture 3829F/G - WRITING FOR MAGAZINES',
        courseName:
          'Media, Information and Technoculture 3829F/G - WRITING FOR MAGAZINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249047f7e10015fd76c8',
      },
      {
        _id: '60cc249047f7e10015fd76ca',
        courseCode: 'MIT 3832',
        shortName:
          'Media, Information and Technoculture 3832F/G - DOCUMENTARY MEDIA',
        courseName:
          'Media, Information and Technoculture 3832F/G - DOCUMENTARY MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249047f7e10015fd76ca',
      },
      {
        _id: '60cc249047f7e10015fd76cc',
        courseCode: 'MIT 3833',
        shortName:
          'Media, Information and Technoculture 3833F/G - ARTS & ENTERTAINMENT JOURNALISM',
        courseName:
          'Media, Information and Technoculture 3833F/G - ARTS & ENTERTAINMENT JOURNALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249047f7e10015fd76cc',
      },
      {
        _id: '60cc249147f7e10015fd76ce',
        courseCode: 'MIT 3835',
        shortName:
          'Media, Information and Technoculture 3835F/G - BLOGS, COLUMNS, AND OP EDS: MAKING OPINIONS MATTER',
        courseName:
          'Media, Information and Technoculture 3835F/G - BLOGS, COLUMNS, AND OP EDS: MAKING OPINIONS MATTER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249147f7e10015fd76ce',
      },
      {
        _id: '60cc249447f7e10015fd76e1',
        courseCode: 'MIT 3902',
        shortName:
          'Media, Information and Technoculture 3902F/G - ALTERNATIVE MEDIA',
        courseName:
          'Media, Information and Technoculture 3902F/G - ALTERNATIVE MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249447f7e10015fd76e1',
      },
      {
        _id: '60cc249447f7e10015fd76e3',
        courseCode: 'MIT 3931',
        shortName:
          'Media, Information and Technoculture 3931F/G - CENTURY OF GENOCIDEhours',
        courseName:
          'Media, Information and Technoculture 3931F/G - CENTURY OF GENOCIDEhours',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249447f7e10015fd76e3',
      },
      {
        _id: '60cc249547f7e10015fd76e5',
        courseCode: 'MIT 3932',
        shortName:
          'Media, Information and Technoculture 3932F/G - POLITICS & REPRESENTATIONS OF FOOD',
        courseName:
          'Media, Information and Technoculture 3932F/G - POLITICS & REPRESENTATIONS OF FOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249547f7e10015fd76e5',
      },
      {
        _id: '60cc249547f7e10015fd76e8',
        courseCode: 'MIT 3935',
        shortName:
          'Media, Information and Technoculture 3935F/G - RACE, CLASS, AND SOCIAL POWER',
        courseName:
          'Media, Information and Technoculture 3935F/G - RACE, CLASS, AND SOCIAL POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249547f7e10015fd76e8',
      },
      {
        _id: '60cc249547f7e10015fd76ea',
        courseCode: 'MIT 3936',
        shortName:
          'Media, Information and Technoculture 3936F/G - INTERNATIONAL MEDIA AND SOCIAL CHANGE',
        courseName:
          'Media, Information and Technoculture 3936F/G - INTERNATIONAL MEDIA AND SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249547f7e10015fd76ea',
      },
      {
        _id: '60cc249647f7e10015fd76ec',
        courseCode: 'MIT 3937',
        shortName:
          'Media, Information and Technoculture 3937F/G - THE RIFT: SETTLER-COLONIALISM AND SOCIAL ECOLOGY',
        courseName:
          'Media, Information and Technoculture 3937F/G - THE RIFT: SETTLER-COLONIALISM AND SOCIAL ECOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249647f7e10015fd76ec',
      },
      {
        _id: '60cc249647f7e10015fd76ee',
        courseCode: 'MIT 3939',
        shortName:
          'Media, Information and Technoculture 3939F/G - INDIGENOUS PERSPECTIVES IN THE MEDIA',
        courseName:
          'Media, Information and Technoculture 3939F/G - INDIGENOUS PERSPECTIVES IN THE MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249647f7e10015fd76ee',
      },
      {
        _id: '60cc249647f7e10015fd76f0',
        courseCode: 'MIT 3950',
        shortName:
          'Media, Information and Technoculture 3950E - MPI DIRECTED READINGS',
        courseName:
          'Media, Information and Technoculture 3950E - MPI DIRECTED READINGS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249647f7e10015fd76f0',
      },
      {
        _id: '60cc249647f7e10015fd76f2',
        courseCode: 'MIT 3951',
        shortName:
          'Media, Information and Technoculture 3951F/G - MPI DIRECTED READINGS',
        courseName:
          'Media, Information and Technoculture 3951F/G - MPI DIRECTED READINGS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249647f7e10015fd76f2',
      },
      {
        _id: '60cc249747f7e10015fd76f8',
        courseCode: 'MIT 4010',
        shortName:
          'Media, Information and Technoculture 4010E - ADVANCED DIRECTED READINGS IN MIT',
        courseName:
          'Media, Information and Technoculture 4010E - ADVANCED DIRECTED READINGS IN MIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249747f7e10015fd76f8',
      },
      {
        _id: '60cc249747f7e10015fd76fa',
        courseCode: 'MIT 4011',
        shortName:
          'Media, Information and Technoculture 4011F/G - ADVANCED DIRECTED READINGS IN MIT',
        courseName:
          'Media, Information and Technoculture 4011F/G - ADVANCED DIRECTED READINGS IN MIT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249747f7e10015fd76fa',
      },
      {
        _id: '60cc249a47f7e10015fd770a',
        courseCode: 'MIT 4999',
        shortName:
          'Media, Information and Technoculture 4999F/G - MEDIA AND THE  PUBLIC INTEREST PRACTICUM',
        courseName:
          'Media, Information and Technoculture 4999F/G - MEDIA AND THE  PUBLIC INTEREST PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249a47f7e10015fd770a',
      },
      {
        _id: '60cc249a47f7e10015fd770c',
        courseCode: 'MBI 3100',
        shortName:
          'Medical Bioinformatics 3100A/B - INTRODUCTION TO MEDICAL BIOINFORMATICS',
        courseName:
          'Medical Bioinformatics 3100A/B - INTRODUCTION TO MEDICAL BIOINFORMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249a47f7e10015fd770c',
      },
      {
        _id: '60cc249a47f7e10015fd770e',
        courseCode: 'MBI 4750',
        shortName:
          'Medical Bioinformatics 4750F/G - BIOINFORMATICS OF INFECTIOUS DISEASE',
        courseName:
          'Medical Bioinformatics 4750F/G - BIOINFORMATICS OF INFECTIOUS DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249a47f7e10015fd770e',
      },
      {
        _id: '60cc249b47f7e10015fd7710',
        courseCode: 'MBI 4850',
        shortName: 'Medical Bioinformatics 4850G - GENOMIC DATA ANALYSIS',
        courseName: 'Medical Bioinformatics 4850G - GENOMIC DATA ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249b47f7e10015fd7710',
      },
      {
        _id: '60cc249b47f7e10015fd7712',
        courseCode: 'MBI 4980',
        shortName:
          'Medical Bioinformatics 4980E - SEMINAR AND RESEARCH PROJECT',
        courseName:
          'Medical Bioinformatics 4980E - SEMINAR AND RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249b47f7e10015fd7712',
      },
      {
        _id: '60cc249b47f7e10015fd7714',
        courseCode: 'MEDBIO 2500',
        shortName:
          'Medical Biophysics 2500A/B - INTRODUCTION TO BIOPHYSICS FOR ADVANCING MEDICINE',
        courseName:
          'Medical Biophysics 2500A/B - INTRODUCTION TO BIOPHYSICS FOR ADVANCING MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249b47f7e10015fd7714',
      },
      {
        _id: '60cc249b47f7e10015fd7716',
        courseCode: 'MEDBIO 3330',
        shortName:
          'Medical Biophysics 3330F/G - HUMAN BIOMECHANICS WITH BIOMEDICAL APPLICATIONS',
        courseName:
          'Medical Biophysics 3330F/G - HUMAN BIOMECHANICS WITH BIOMEDICAL APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249b47f7e10015fd7716',
      },
      {
        _id: '60cc249c47f7e10015fd7718',
        courseCode: 'MEDBIO 3501',
        shortName: 'Medical Biophysics 3501A - BIOPHYSICS OF TRANSPORT SYSTEMS',
        courseName:
          'Medical Biophysics 3501A - BIOPHYSICS OF TRANSPORT SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249c47f7e10015fd7718',
      },
      {
        _id: '60cc249c47f7e10015fd771a',
        courseCode: 'MEDBIO 3503',
        shortName: 'Medical Biophysics 3503G - FUNDAMENTALS OF DIGITAL IMAGING',
        courseName:
          'Medical Biophysics 3503G - FUNDAMENTALS OF DIGITAL IMAGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249c47f7e10015fd771a',
      },
      {
        _id: '60cc249c47f7e10015fd771c',
        courseCode: 'MEDBIO 3505',
        shortName:
          'Medical Biophysics 3505F - MATHEMATICAL TRANSFORM APPLICATIONS IN MEDICAL BIOPHYSICS',
        courseName:
          'Medical Biophysics 3505F - MATHEMATICAL TRANSFORM APPLICATIONS IN MEDICAL BIOPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249c47f7e10015fd771c',
      },
      {
        _id: '60cc249c47f7e10015fd771e',
        courseCode: 'MEDBIO 3507',
        shortName:
          'Medical Biophysics 3507G - ANALYSIS OF OXYGEN TRANSPORT IN BIOLOGICAL SYSTEMS',
        courseName:
          'Medical Biophysics 3507G - ANALYSIS OF OXYGEN TRANSPORT IN BIOLOGICAL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249c47f7e10015fd771e',
      },
      {
        _id: '60cc249d47f7e10015fd7720',
        courseCode: 'MEDBIO 3518',
        shortName:
          'Medical Biophysics 3518B - INTRODUCTION TO MOLECULAR IMAGING',
        courseName:
          'Medical Biophysics 3518B - INTRODUCTION TO MOLECULAR IMAGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249d47f7e10015fd7720',
      },
      {
        _id: '60cc249d47f7e10015fd7722',
        courseCode: 'MEDBIO 3645',
        shortName:
          'Medical Biophysics 3645A/B - INTRODUCTION TO BIOMEDICAL OPTICS',
        courseName:
          'Medical Biophysics 3645A/B - INTRODUCTION TO BIOMEDICAL OPTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249d47f7e10015fd7722',
      },
      {
        _id: '60cc249d47f7e10015fd7724',
        courseCode: 'MEDBIO 3970',
        shortName: 'Medical Biophysics 3970Z - GENERAL BIOPHYSICS LABORATORY',
        courseName: 'Medical Biophysics 3970Z - GENERAL BIOPHYSICS LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249d47f7e10015fd7724',
      },
      {
        _id: '60cc249d47f7e10015fd7726',
        courseCode: 'MEDBIO 4445',
        shortName: 'Medical Biophysics 4445A/B - DIGITAL IMAGING PROCESSING',
        courseName: 'Medical Biophysics 4445A/B - DIGITAL IMAGING PROCESSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249d47f7e10015fd7726',
      },
      {
        _id: '60cc249e47f7e10015fd7728',
        courseCode: 'MEDBIO 4455',
        shortName: 'Medical Biophysics 4455A/B - BIOLOGICAL CONTROL SYSTEMS',
        courseName: 'Medical Biophysics 4455A/B - BIOLOGICAL CONTROL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249e47f7e10015fd7728',
      },
      {
        _id: '60cc249e47f7e10015fd772a',
        courseCode: 'MEDBIO 4467',
        shortName:
          'Medical Biophysics 4467A/B - RADIATION BIOLOGY WITH BIOMEDICAL APPLICATIONS',
        courseName:
          'Medical Biophysics 4467A/B - RADIATION BIOLOGY WITH BIOMEDICAL APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249e47f7e10015fd772a',
      },
      {
        _id: '60cc249e47f7e10015fd772c',
        courseCode: 'MEDBIO 4475',
        shortName: 'Medical Biophysics 4475A/B - MEDICAL IMAGING',
        courseName: 'Medical Biophysics 4475A/B - MEDICAL IMAGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249e47f7e10015fd772c',
      },
      {
        _id: '60cc249e47f7e10015fd772e',
        courseCode: 'MEDBIO 4518',
        shortName: 'Medical Biophysics 4518B - ADVANCED MOLECULAR IMAGING',
        courseName: 'Medical Biophysics 4518B - ADVANCED MOLECULAR IMAGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249e47f7e10015fd772e',
      },
      {
        _id: '60cc249e47f7e10015fd7730',
        courseCode: 'MEDBIO 4535',
        shortName: 'Medical Biophysics 4535A/B - HEMODYNAMICS',
        courseName: 'Medical Biophysics 4535A/B - HEMODYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249e47f7e10015fd7730',
      },
      {
        _id: '60cc249f47f7e10015fd7732',
        courseCode: 'MEDBIO 4600',
        shortName:
          'Medical Biophysics 4600A/B - SPECIAL TOPICS IN MEDICAL BIOPHYSICS',
        courseName:
          'Medical Biophysics 4600A/B - SPECIAL TOPICS IN MEDICAL BIOPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249f47f7e10015fd7732',
      },
      {
        _id: '60cc249f47f7e10015fd7734',
        courseCode: 'MEDBIO 4700',
        shortName:
          'Medical Biophysics 4700B - CASE STUDIES IN MEDICAL BIOPHYSICS',
        courseName:
          'Medical Biophysics 4700B - CASE STUDIES IN MEDICAL BIOPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249f47f7e10015fd7734',
      },
      {
        _id: '60cc249f47f7e10015fd7736',
        courseCode: 'MEDBIO 4710',
        shortName:
          'Medical Biophysics 4710A/B - BIOPHOTONICS IN MEDICINE AND LIFE SCIENCES',
        courseName:
          'Medical Biophysics 4710A/B - BIOPHOTONICS IN MEDICINE AND LIFE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249f47f7e10015fd7736',
      },
      {
        _id: '60cc249f47f7e10015fd7738',
        courseCode: 'MEDBIO 4970',
        shortName: 'Medical Biophysics 4970E - RESEARCH PROJECT IN BIOPHYSICS',
        courseName: 'Medical Biophysics 4970E - RESEARCH PROJECT IN BIOPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc249f47f7e10015fd7738',
      },
      {
        _id: '60cc24a047f7e10015fd773a',
        courseCode: 'MEDBIO 4971',
        shortName:
          'Medical Biophysics 4971E - RESEARCH PROJECT IN BIOPHYSICS (CLINICAL PHYSICS CONCENTRATION)',
        courseName:
          'Medical Biophysics 4971E - RESEARCH PROJECT IN BIOPHYSICS (CLINICAL PHYSICS CONCENTRATION)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a047f7e10015fd773a',
      },
      {
        _id: '60cc24a047f7e10015fd773c',
        courseCode: 'MEDHINFO 4100',
        shortName: 'Medical Health Informatics 4100F - HEALTH INFORMATICS',
        courseName: 'Medical Health Informatics 4100F - HEALTH INFORMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a047f7e10015fd773c',
      },
      {
        _id: '60cc24a047f7e10015fd773e',
        courseCode: 'MEDHINFO 4110',
        shortName:
          'Medical Health Informatics 4110G - HEALTH INFORMATION MANAGEMENT',
        courseName:
          'Medical Health Informatics 4110G - HEALTH INFORMATION MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a047f7e10015fd773e',
      },
      {
        _id: '60cc24a047f7e10015fd7740',
        courseCode: 'MEDHINFO 4850',
        shortName: 'Medical Health Informatics 4850G - GENOMIC DATA ANALYSIS',
        courseName: 'Medical Health Informatics 4850G - GENOMIC DATA ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a047f7e10015fd7740',
      },
      {
        _id: '60cc24a147f7e10015fd7742',
        courseCode: 'MEDHINFO 4980',
        shortName:
          'Medical Health Informatics 4980E - SEMINAR AND RESEARCH PROJECT',
        courseName:
          'Medical Health Informatics 4980E - SEMINAR AND RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a147f7e10015fd7742',
      },
      {
        _id: '60cc24a147f7e10015fd7744',
        courseCode: 'MEDSCIEN 3990',
        shortName:
          'Medical Sciences 3990E - INTERDISCIPLINARY MEDICAL SCIENCES LABORATORY',
        courseName:
          'Medical Sciences 3990E - INTERDISCIPLINARY MEDICAL SCIENCES LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a147f7e10015fd7744',
      },
      {
        _id: '60cc24a147f7e10015fd7746',
        courseCode: 'MEDSCIEN 3991',
        shortName: 'Medical Sciences 3991F - MEDICAL SCIENCES LABORATORY',
        courseName: 'Medical Sciences 3991F - MEDICAL SCIENCES LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a147f7e10015fd7746',
      },
      {
        _id: '60cc24a147f7e10015fd7748',
        courseCode: 'MEDSCIEN 3999',
        shortName: 'Medical Sciences 3999A/B/Y - EXPERIENTIAL LEARNING',
        courseName: 'Medical Sciences 3999A/B/Y - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a147f7e10015fd7748',
      },
      {
        _id: '60cc24a247f7e10015fd774b',
        courseCode: 'MEDSCIEN 4200',
        shortName: 'Medical Sciences 4200A/B - INFLAMMATION IN DISEASES',
        courseName: 'Medical Sciences 4200A/B - INFLAMMATION IN DISEASES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a247f7e10015fd774b',
      },
      {
        _id: '60cc24a247f7e10015fd774d',
        courseCode: 'MEDSCIEN 4300',
        shortName:
          'Medical Sciences 4300A/B - ADDRESSING HEALTHCARE MISCONCEPTIONS USING SCIENTIFIC INQUIRY',
        courseName:
          'Medical Sciences 4300A/B - ADDRESSING HEALTHCARE MISCONCEPTIONS USING SCIENTIFIC INQUIRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a247f7e10015fd774d',
      },
      {
        _id: '60cc24a247f7e10015fd774f',
        courseCode: 'MEDSCIEN 4930',
        shortName:
          'Medical Sciences 4930F - INTERDISCIPLINARY THINKING AND RESEARCH LITERACY IN MEDICAL SCIENCES',
        courseName:
          'Medical Sciences 4930F - INTERDISCIPLINARY THINKING AND RESEARCH LITERACY IN MEDICAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a247f7e10015fd774f',
      },
      {
        _id: '60cc24a247f7e10015fd7751',
        courseCode: 'MEDSCIEN 4931',
        shortName:
          'Medical Sciences 4931F/G - SELECTED TOPICS IN MEDICAL SCIENCES',
        courseName:
          'Medical Sciences 4931F/G - SELECTED TOPICS IN MEDICAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a247f7e10015fd7751',
      },
      {
        _id: '60cc24a347f7e10015fd7755',
        courseCode: 'MEDIEVAL 1022',
        shortName: 'Medieval Studies 1022 - INTRODUCTION TO MEDIEVAL STUDIES',
        courseName: 'Medieval Studies 1022 - INTRODUCTION TO MEDIEVAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a347f7e10015fd7755',
      },
      {
        _id: '60cc24a347f7e10015fd7757',
        courseCode: 'MEDIEVAL 1025',
        shortName:
          'Medieval Studies 1025A/B - INTRODUCTION TO THE MEDIEVAL WORLD',
        courseName:
          'Medieval Studies 1025A/B - INTRODUCTION TO THE MEDIEVAL WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a347f7e10015fd7757',
      },
      {
        _id: '60cc24a347f7e10015fd7759',
        courseCode: 'MEDIEVAL 1026',
        shortName:
          'Medieval Studies 1026A/B - INTRODUCTION TO MEDIEVAL CULTURES AND LITERATURES',
        courseName:
          'Medieval Studies 1026A/B - INTRODUCTION TO MEDIEVAL CULTURES AND LITERATURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a347f7e10015fd7759',
      },
      {
        _id: '60cc24a447f7e10015fd775b',
        courseCode: 'MEDIEVAL 3022',
        shortName:
          'Medieval Studies 3022F/G - INTRODUCTION TO MEDIEVAL MANUSCRIPTS',
        courseName:
          'Medieval Studies 3022F/G - INTRODUCTION TO MEDIEVAL MANUSCRIPTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a447f7e10015fd775b',
      },
      {
        _id: '60cc24a447f7e10015fd775d',
        courseCode: 'MEDIEVAL 3320',
        shortName:
          'Medieval Studies 3320F/G - DIRECTED READINGS IN MEDIEVAL STUDIES',
        courseName:
          'Medieval Studies 3320F/G - DIRECTED READINGS IN MEDIEVAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a447f7e10015fd775d',
      },
      {
        _id: '60cc24a447f7e10015fd775f',
        courseCode: 'MEDIEVAL 4320',
        shortName: 'Medieval Studies 4320F/G - INDEPENDENT STUDY SEMINAR',
        courseName: 'Medieval Studies 4320F/G - INDEPENDENT STUDY SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a447f7e10015fd775f',
      },
      {
        _id: '60cc24a447f7e10015fd7761',
        courseCode: 'MICROIMM 2500',
        shortName:
          'Microbiology and Immunology 2500A/B - BIOLOGY OF INFECTION AND IMMUNITY',
        courseName:
          'Microbiology and Immunology 2500A/B - BIOLOGY OF INFECTION AND IMMUNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a447f7e10015fd7761',
      },
      {
        _id: '60cc24a547f7e10015fd7763',
        courseCode: 'MICROIMM 3100',
        shortName: 'Microbiology and Immunology 3100A - MICROBIOLOGY',
        courseName: 'Microbiology and Immunology 3100A - MICROBIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a547f7e10015fd7763',
      },
      {
        _id: '60cc24a547f7e10015fd7765',
        courseCode: 'MICROIMM 3300',
        shortName: 'Microbiology and Immunology 3300B - IMMUNOLOGY',
        courseName: 'Microbiology and Immunology 3300B - IMMUNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a547f7e10015fd7765',
      },
      {
        _id: '60cc24a547f7e10015fd7767',
        courseCode: 'MICROIMM 3500',
        shortName:
          'Microbiology and Immunology 3500B - BIOLOGICAL AND SOCIAL DETERMINANTS OF INFECTIOUS DISEASE',
        courseName:
          'Microbiology and Immunology 3500B - BIOLOGICAL AND SOCIAL DETERMINANTS OF INFECTIOUS DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a547f7e10015fd7767',
      },
      {
        _id: '60cc24a547f7e10015fd7769',
        courseCode: 'MICROIMM 3610',
        shortName:
          'Microbiology and Immunology 3610F - MICROBIOLOGY LABORATORY',
        courseName:
          'Microbiology and Immunology 3610F - MICROBIOLOGY LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a547f7e10015fd7769',
      },
      {
        _id: '60cc24a647f7e10015fd776b',
        courseCode: 'MICROIMM 3620',
        shortName: 'Microbiology and Immunology 3620G - IMMUNOLOGY LABORATORY',
        courseName: 'Microbiology and Immunology 3620G - IMMUNOLOGY LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a647f7e10015fd776b',
      },
      {
        _id: '60cc24a647f7e10015fd776d',
        courseCode: 'MICROIMM 4100',
        shortName: 'Microbiology and Immunology 4100A - BACTERIAL PATHOGENESIS',
        courseName:
          'Microbiology and Immunology 4100A - BACTERIAL PATHOGENESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a647f7e10015fd776d',
      },
      {
        _id: '60cc24a647f7e10015fd776f',
        courseCode: 'MICROIMM 4200',
        shortName: 'Microbiology and Immunology 4200B - MOLECULAR VIROLOGY',
        courseName: 'Microbiology and Immunology 4200B - MOLECULAR VIROLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a647f7e10015fd776f',
      },
      {
        _id: '60cc24a647f7e10015fd7771',
        courseCode: 'MICROIMM 4300',
        shortName: 'Microbiology and Immunology 4300A - CLINICAL IMMUNOLOGY',
        courseName: 'Microbiology and Immunology 4300A - CLINICAL IMMUNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a647f7e10015fd7771',
      },
      {
        _id: '60cc24a747f7e10015fd7773',
        courseCode: 'MICROIMM 4750',
        shortName:
          'Microbiology and Immunology 4750F/G - BIOINFORMATICS OF INFECTIOUS DISEASE',
        courseName:
          'Microbiology and Immunology 4750F/G - BIOINFORMATICS OF INFECTIOUS DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a747f7e10015fd7773',
      },
      {
        _id: '60cc24a747f7e10015fd7775',
        courseCode: 'MICROIMM 4970',
        shortName:
          'Microbiology and Immunology 4970E - RESEARCH PROJECT AND SEMINAR',
        courseName:
          'Microbiology and Immunology 4970E - RESEARCH PROJECT AND SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a747f7e10015fd7775',
      },
      {
        _id: '60cc24a747f7e10015fd7777',
        courseCode: 'MCS 2610',
        shortName:
          'Museum and Curatorial Studies 2610F/G - THE GREATEST SHOWS ON EARTH',
        courseName:
          'Museum and Curatorial Studies 2610F/G - THE GREATEST SHOWS ON EARTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a747f7e10015fd7777',
      },
      {
        _id: '60cc24a747f7e10015fd7779',
        courseCode: 'MCS 2620',
        shortName:
          'Museum and Curatorial Studies 2620F/G - INTRODUCTION TO GALLERY, MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 2620F/G - INTRODUCTION TO GALLERY, MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a747f7e10015fd7779',
      },
      {
        _id: '60cc24a747f7e10015fd777b',
        courseCode: 'MCS 2690',
        shortName:
          'Museum and Curatorial Studies 2690F/G - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 2690F/G - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a747f7e10015fd777b',
      },
      {
        _id: '60cc24a847f7e10015fd777d',
        courseCode: 'MCS 2691',
        shortName:
          'Museum and Curatorial Studies 2691E - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 2691E - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a847f7e10015fd777d',
      },
      {
        _id: '60cc24a847f7e10015fd777f',
        courseCode: 'MCS 3610',
        shortName:
          'Museum and Curatorial Studies 3610F/G - CONTROVERSIES AND CONTESTATIONS: MUSEUMS',
        courseName:
          'Museum and Curatorial Studies 3610F/G - CONTROVERSIES AND CONTESTATIONS: MUSEUMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a847f7e10015fd777f',
      },
      {
        _id: '60cc24a847f7e10015fd7781',
        courseCode: 'MCS 3620',
        shortName:
          'Museum and Curatorial Studies 3620A/B/Y - INTRODUCTION TO EXHIBITION DESIGN & MUSEUM MANAGEMENT',
        courseName:
          'Museum and Curatorial Studies 3620A/B/Y - INTRODUCTION TO EXHIBITION DESIGN & MUSEUM MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a847f7e10015fd7781',
      },
      {
        _id: '60cc24a847f7e10015fd7783',
        courseCode: 'MCS 3642',
        shortName:
          'Museum and Curatorial Studies 3642F/G - COLD WAR ART AND POLITICS',
        courseName:
          'Museum and Curatorial Studies 3642F/G - COLD WAR ART AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a847f7e10015fd7783',
      },
      {
        _id: '60cc24a947f7e10015fd7785',
        courseCode: 'MCS 3660',
        shortName:
          'Museum and Curatorial Studies 3660A/B/Y - DIGITAL TOOLS FOR ARTS PROFESSIONALS',
        courseName:
          'Museum and Curatorial Studies 3660A/B/Y - DIGITAL TOOLS FOR ARTS PROFESSIONALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a947f7e10015fd7785',
      },
      {
        _id: '60cc24a947f7e10015fd7787',
        courseCode: 'MCS 3686',
        shortName:
          'Museum and Curatorial Studies 3686F/G - PROJECT-BASED INTERNSHIP',
        courseName:
          'Museum and Curatorial Studies 3686F/G - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a947f7e10015fd7787',
      },
      {
        _id: '60cc24a947f7e10015fd7789',
        courseCode: 'MCS 3689',
        shortName:
          'Museum and Curatorial Studies 3689E - PROJECT-BASED INTERNSHIP',
        courseName:
          'Museum and Curatorial Studies 3689E - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a947f7e10015fd7789',
      },
      {
        _id: '60cc24a947f7e10015fd778b',
        courseCode: 'MCS 3690',
        shortName:
          'Museum and Curatorial Studies 3690F/G - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 3690F/G - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24a947f7e10015fd778b',
      },
      {
        _id: '60cc24aa47f7e10015fd778d',
        courseCode: 'MCS 3691',
        shortName:
          'Museum and Curatorial Studies 3691E - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 3691E - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24aa47f7e10015fd778d',
      },
      {
        _id: '60cc24aa47f7e10015fd778f',
        courseCode: 'MCS 3692',
        shortName:
          'Museum and Curatorial Studies 3692F/G - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 3692F/G - SPECIAL TOPICS IN MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24aa47f7e10015fd778f',
      },
      {
        _id: '60cc24aa47f7e10015fd7791',
        courseCode: 'MCS 4605',
        shortName:
          'Museum and Curatorial Studies 4605E - MUSEUM AND CURATORIAL PRACTICUM',
        courseName:
          'Museum and Curatorial Studies 4605E - MUSEUM AND CURATORIAL PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24aa47f7e10015fd7791',
      },
      {
        _id: '60cc24aa47f7e10015fd7793',
        courseCode: 'MCS 4610',
        shortName:
          'Museum and Curatorial Studies 4610F/G - SEMINAR IN MUSEUM & CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 4610F/G - SEMINAR IN MUSEUM & CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24aa47f7e10015fd7793',
      },
      {
        _id: '60cc24ab47f7e10015fd7795',
        courseCode: 'MCS 4684',
        shortName:
          'Museum and Curatorial Studies 4684A/B/Y - PRACTICUM INTERNSHIP IN THE VISUAL ARTS',
        courseName:
          'Museum and Curatorial Studies 4684A/B/Y - PRACTICUM INTERNSHIP IN THE VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ab47f7e10015fd7795',
      },
      {
        _id: '60cc24ab47f7e10015fd7797',
        courseCode: 'MCS 4685',
        shortName:
          'Museum and Curatorial Studies 4685E - INTERNSHIP IN THE VISUAL ARTS',
        courseName:
          'Museum and Curatorial Studies 4685E - INTERNSHIP IN THE VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ab47f7e10015fd7797',
      },
      {
        _id: '60cc24ab47f7e10015fd7799',
        courseCode: 'MCS 4686',
        shortName:
          'Museum and Curatorial Studies 4686F/G - PROJECT-BASED INTERNSHIP',
        courseName:
          'Museum and Curatorial Studies 4686F/G - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ab47f7e10015fd7799',
      },
      {
        _id: '60cc24ab47f7e10015fd779b',
        courseCode: 'MCS 4689',
        shortName:
          'Museum and Curatorial Studies 4689E - PROJECT-BASED INTERNSHIP',
        courseName:
          'Museum and Curatorial Studies 4689E - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ab47f7e10015fd779b',
      },
      {
        _id: '60cc24ab47f7e10015fd779d',
        courseCode: 'MCS 4690',
        shortName:
          'Museum and Curatorial Studies 4690F/G - SPECIAL TOPICS IN MUSEUM AND CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 4690F/G - SPECIAL TOPICS IN MUSEUM AND CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ab47f7e10015fd779d',
      },
      {
        _id: '60cc24ac47f7e10015fd779f',
        courseCode: 'MCS 4696',
        shortName:
          'Museum and Curatorial Studies 4696F/G - INDEPENDENT PROJECTS IN MUSEUM AND CURATORIAL STUDIES',
        courseName:
          'Museum and Curatorial Studies 4696F/G - INDEPENDENT PROJECTS IN MUSEUM AND CURATORIAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ac47f7e10015fd779f',
      },
      {
        _id: '60cc24ac47f7e10015fd77a1',
        courseCode: 'MUSIC 0601',
        shortName: 'Music 0601A - THEORETICAL STUDIES: FOUNDATIONS',
        courseName: 'Music 0601A - THEORETICAL STUDIES: FOUNDATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ac47f7e10015fd77a1',
      },
      {
        _id: '60cc24ac47f7e10015fd77a3',
        courseCode: 'MUSIC 0914',
        shortName: 'Music 0914 - PIANO REQUIREMENT',
        courseName: 'Music 0914 - PIANO REQUIREMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ac47f7e10015fd77a3',
      },
      {
        _id: '60cc24ac47f7e10015fd77a5',
        courseCode: 'MUSIC 0918',
        shortName: 'Music 0918 - MUSIC ENSEMBLE',
        courseName: 'Music 0918 - MUSIC ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ac47f7e10015fd77a5',
      },
      {
        _id: '60cc24ad47f7e10015fd77a7',
        courseCode: 'MUSIC 1102',
        shortName: 'Music 1102A/B - LISTENING TO MUSIC',
        courseName: 'Music 1102A/B - LISTENING TO MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ad47f7e10015fd77a7',
      },
      {
        _id: '60cc24ad47f7e10015fd77a9',
        courseCode: 'MUSIC 1122',
        shortName: 'Music 1122A/B - FUNDAMENTALS OF MUSIC THEORY',
        courseName: 'Music 1122A/B - FUNDAMENTALS OF MUSIC THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ad47f7e10015fd77a9',
      },
      {
        _id: '60cc24ad47f7e10015fd77ab',
        courseCode: 'MUSIC 1170',
        shortName: 'Music 1170A/B/Y - INTRODUCTION TO MUSICS OF THE WORLD',
        courseName: 'Music 1170A/B/Y - INTRODUCTION TO MUSICS OF THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ad47f7e10015fd77ab',
      },
      {
        _id: '60cc24ad47f7e10015fd77ad',
        courseCode: 'MUSIC 1629',
        shortName: 'Music 1629A/B - INTRODUCTION TO COMPOSITION',
        courseName: 'Music 1629A/B - INTRODUCTION TO COMPOSITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ad47f7e10015fd77ad',
      },
      {
        _id: '60cc24ae47f7e10015fd77af',
        courseCode: 'MUSIC 1639',
        shortName: 'Music 1639U - GENERAL INTEGRATED MUSICIANSHIP I',
        courseName: 'Music 1639U - GENERAL INTEGRATED MUSICIANSHIP I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ae47f7e10015fd77af',
      },
      {
        _id: '60cc24ae47f7e10015fd77b1',
        courseCode: 'MUSIC 1641',
        shortName: 'Music 1641U - GENERAL INTEGRATED MUSICIANSHIP II',
        courseName: 'Music 1641U - GENERAL INTEGRATED MUSICIANSHIP II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ae47f7e10015fd77b1',
      },
      {
        _id: '60cc24ae47f7e10015fd77b3',
        courseCode: 'MUSIC 1649',
        shortName: 'Music 1649A/B - STUDIES IN THEORY I',
        courseName: 'Music 1649A/B - STUDIES IN THEORY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ae47f7e10015fd77b3',
      },
      {
        _id: '60cc24ae47f7e10015fd77b5',
        courseCode: 'MUSIC 1651',
        shortName: 'Music 1651A/B - STUDIES IN THEORY II',
        courseName: 'Music 1651A/B - STUDIES IN THEORY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ae47f7e10015fd77b5',
      },
      {
        _id: '60cc24af47f7e10015fd77b7',
        courseCode: 'MUSIC 1695',
        shortName:
          'Music 1695A/B/Y - INTRODUCTION TO ELECTROACOUSTIC MUSIC SYSTEMS',
        courseName:
          'Music 1695A/B/Y - INTRODUCTION TO ELECTROACOUSTIC MUSIC SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24af47f7e10015fd77b7',
      },
      {
        _id: '60cc24af47f7e10015fd77b9',
        courseCode: 'MUSIC 1710',
        shortName: 'Music 1710F/G - MUSIC HISTORY: INTRODUCTION',
        courseName: 'Music 1710F/G - MUSIC HISTORY: INTRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24af47f7e10015fd77b9',
      },
      {
        _id: '60cc24af47f7e10015fd77bb',
        courseCode: 'MUSIC 1730',
        shortName: 'Music 1730A/B - INTRODUCTION TO POPULAR MUSIC AND CULTURE',
        courseName: 'Music 1730A/B - INTRODUCTION TO POPULAR MUSIC AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24af47f7e10015fd77bb',
      },
      {
        _id: '60cc24af47f7e10015fd77bd',
        courseCode: 'MUSIC 1750',
        shortName: 'Music 1750F/G - MUSIC HISTORY: C.900 TO C.1750',
        courseName: 'Music 1750F/G - MUSIC HISTORY: C.900 TO C.1750',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24af47f7e10015fd77bd',
      },
      {
        _id: '60cc24af47f7e10015fd77bf',
        courseCode: 'MUSIC 1802',
        shortName: 'Music 1802A/B - TEACHING AND LEARNING MUSIC',
        courseName: 'Music 1802A/B - TEACHING AND LEARNING MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24af47f7e10015fd77bf',
      },
      {
        _id: '60cc24b047f7e10015fd77c1',
        courseCode: 'MUSIC 1901',
        shortName: 'Music 1901 - ORCHESTRA',
        courseName: 'Music 1901 - ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b047f7e10015fd77c1',
      },
      {
        _id: '60cc24b047f7e10015fd77c3',
        courseCode: 'MUSIC 1902',
        shortName: 'Music 1902 - WIND ENSEMBLE',
        courseName: 'Music 1902 - WIND ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b047f7e10015fd77c3',
      },
      {
        _id: '60cc24b047f7e10015fd77c5',
        courseCode: 'MUSIC 1903',
        shortName: 'Music 1903 - SYMPHONIC BAND',
        courseName: 'Music 1903 - SYMPHONIC BAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b047f7e10015fd77c5',
      },
      {
        _id: '60cc24b047f7e10015fd77c7',
        courseCode: 'MUSIC 1904',
        shortName: 'Music 1904 - JAZZ ENSEMBLE',
        courseName: 'Music 1904 - JAZZ ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b047f7e10015fd77c7',
      },
      {
        _id: '60cc24b147f7e10015fd77c9',
        courseCode: 'MUSIC 1905',
        shortName: 'Music 1905 - EARLY MUSIC  ENSEMBLE',
        courseName: 'Music 1905 - EARLY MUSIC  ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b147f7e10015fd77c9',
      },
      {
        _id: '60cc24b147f7e10015fd77cb',
        courseCode: 'MUSIC 1906',
        shortName: 'Music 1906 - ACCOMPANYING',
        courseName: 'Music 1906 - ACCOMPANYING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b147f7e10015fd77cb',
      },
      {
        _id: '60cc24b147f7e10015fd77cd',
        courseCode: 'MUSIC 1910',
        shortName: 'Music 1910 - WESTERN SINGERS',
        courseName: 'Music 1910 - WESTERN SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b147f7e10015fd77cd',
      },
      {
        _id: '60cc24b147f7e10015fd77cf',
        courseCode: 'MUSIC 1911',
        shortName: 'Music 1911 - LES CHORISTES',
        courseName: 'Music 1911 - LES CHORISTES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b147f7e10015fd77cf',
      },
      {
        _id: '60cc24b247f7e10015fd77d1',
        courseCode: 'MUSIC 1912',
        shortName: 'Music 1912 - ST CECILIA SINGERS',
        courseName: 'Music 1912 - ST CECILIA SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b247f7e10015fd77d1',
      },
      {
        _id: '60cc24b247f7e10015fd77d3',
        courseCode: 'MUSIC 1913',
        shortName: 'Music 1913 - CHORALE',
        courseName: 'Music 1913 - CHORALE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b247f7e10015fd77d3',
      },
      {
        _id: '60cc24b247f7e10015fd77d5',
        courseCode: 'MUSIC 1920',
        shortName: 'Music 1920 - APPLIED PRINCIPAL INSTRUMENT I',
        courseName: 'Music 1920 - APPLIED PRINCIPAL INSTRUMENT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b247f7e10015fd77d5',
      },
      {
        _id: '60cc24b247f7e10015fd77d7',
        courseCode: 'MUSIC 1925',
        shortName: 'Music 1925 - ELECTIVE APPLIED STUDY I',
        courseName: 'Music 1925 - ELECTIVE APPLIED STUDY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b247f7e10015fd77d7',
      },
      {
        _id: '60cc24b347f7e10015fd77da',
        courseCode: 'MUSIC 1954',
        shortName: 'Music 1954A/B/Y - PERCUSSION ENSEMBLE',
        courseName: 'Music 1954A/B/Y - PERCUSSION ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b347f7e10015fd77da',
      },
      {
        _id: '60cc24b347f7e10015fd77dc',
        courseCode: 'MUSIC 2104',
        shortName: 'Music 2104A/B - FOUNDATIONS IN SINGING',
        courseName: 'Music 2104A/B - FOUNDATIONS IN SINGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b347f7e10015fd77dc',
      },
      {
        _id: '60cc24b347f7e10015fd77de',
        courseCode: 'MUSIC 2171',
        shortName: 'Music 2171A/B/Y - INTRO TO ETHNOMUSICOLOGY',
        courseName: 'Music 2171A/B/Y - INTRO TO ETHNOMUSICOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b347f7e10015fd77de',
      },
      {
        _id: '60cc24b347f7e10015fd77e0',
        courseCode: 'MUSIC 2629',
        shortName: 'Music 2629 - COMPOSITION I',
        courseName: 'Music 2629 - COMPOSITION I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b347f7e10015fd77e0',
      },
      {
        _id: '60cc24b347f7e10015fd77e2',
        courseCode: 'MUSIC 2639',
        shortName: 'Music 2639U - GENERAL INTEGRATED MUSICIANSHIP III',
        courseName: 'Music 2639U - GENERAL INTEGRATED MUSICIANSHIP III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b347f7e10015fd77e2',
      },
      {
        _id: '60cc24b447f7e10015fd77e4',
        courseCode: 'MUSIC 2641',
        shortName: 'Music 2641U - GENERAL INTEGRATED MUSICIANSHIP IV',
        courseName: 'Music 2641U - GENERAL INTEGRATED MUSICIANSHIP IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b447f7e10015fd77e4',
      },
      {
        _id: '60cc24b447f7e10015fd77e6',
        courseCode: 'MUSIC 2651',
        shortName: 'Music 2651A/B - STUDIES IN THEORY III',
        courseName: 'Music 2651A/B - STUDIES IN THEORY III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b447f7e10015fd77e6',
      },
      {
        _id: '60cc24b447f7e10015fd77e8',
        courseCode: 'MUSIC 2671',
        shortName: 'Music 2671F/G - MUSIC ACROSS CULTURES',
        courseName: 'Music 2671F/G - MUSIC ACROSS CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b447f7e10015fd77e8',
      },
      {
        _id: '60cc24b447f7e10015fd77ea',
        courseCode: 'MUSIC 2695',
        shortName:
          'Music 2695A/B - INTRODUCTION TO ELECTROACOUSTIC MUSIC COMPOSITION',
        courseName:
          'Music 2695A/B - INTRODUCTION TO ELECTROACOUSTIC MUSIC COMPOSITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b447f7e10015fd77ea',
      },
      {
        _id: '60cc24b547f7e10015fd77ec',
        courseCode: 'MUSIC 2700',
        shortName: 'Music 2700A/B - POST WORLD WAR II POPULAR MUSIC',
        courseName: 'Music 2700A/B - POST WORLD WAR II POPULAR MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b547f7e10015fd77ec',
      },
      {
        _id: '60cc24b547f7e10015fd77ee',
        courseCode: 'MUSIC 2701',
        shortName: 'Music 2701A/B - MUSICAL THEATRE',
        courseName: 'Music 2701A/B - MUSICAL THEATRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b547f7e10015fd77ee',
      },
      {
        _id: '60cc24b547f7e10015fd77f0',
        courseCode: 'MUSIC 2702',
        shortName: 'Music 2702A/B - INTRODUCTION TO JAZZ',
        courseName: 'Music 2702A/B - INTRODUCTION TO JAZZ',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b547f7e10015fd77f0',
      },
      {
        _id: '60cc24b547f7e10015fd77f2',
        courseCode: 'MUSIC 2708',
        shortName: 'Music 2708A/B/Y - SPECIAL TOPICS IN MUSIC HISTORY',
        courseName: 'Music 2708A/B/Y - SPECIAL TOPICS IN MUSIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b547f7e10015fd77f2',
      },
      {
        _id: '60cc24b647f7e10015fd77f4',
        courseCode: 'MUSIC 2709',
        shortName: 'Music 2709A/B/Y - SPECIAL TOPICS IN MUSIC HISTORY',
        courseName: 'Music 2709A/B/Y - SPECIAL TOPICS IN MUSIC HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b647f7e10015fd77f4',
      },
      {
        _id: '60cc24b647f7e10015fd77f6',
        courseCode: 'MUSIC 2734',
        shortName: 'Music 2734A/B - HIT RECORDS: LYRICS, STYLES, MIXING',
        courseName: 'Music 2734A/B - HIT RECORDS: LYRICS, STYLES, MIXING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b647f7e10015fd77f6',
      },
      {
        _id: '60cc24b647f7e10015fd77f8',
        courseCode: 'MUSIC 2736',
        shortName: 'Music 2736A/B - INTRODUCTION TO DESKTOP MUSIC PRODUCTION',
        courseName: 'Music 2736A/B - INTRODUCTION TO DESKTOP MUSIC PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b647f7e10015fd77f8',
      },
      {
        _id: '60cc24b647f7e10015fd77fa',
        courseCode: 'MUSIC 2750',
        shortName: 'Music 2750F/G - MUSIC HISTORY: C. 1750 TO PRESENT',
        courseName: 'Music 2750F/G - MUSIC HISTORY: C. 1750 TO PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b647f7e10015fd77fa',
      },
      {
        _id: '60cc24b647f7e10015fd77fc',
        courseCode: 'MUSIC 2831',
        shortName: 'Music 2831A/B - CHORAL CONDUCTING: FOUNDATIONS',
        courseName: 'Music 2831A/B - CHORAL CONDUCTING: FOUNDATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b647f7e10015fd77fc',
      },
      {
        _id: '60cc24b747f7e10015fd77fe',
        courseCode: 'MUSIC 2832',
        shortName: 'Music 2832A/B - CHORAL TECHNIQUES',
        courseName: 'Music 2832A/B - CHORAL TECHNIQUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b747f7e10015fd77fe',
      },
      {
        _id: '60cc24b747f7e10015fd7800',
        courseCode: 'MUSIC 2863',
        shortName: 'Music 2863Q/R/S/T - GROUP SAXOPHONE INSTRUCTION',
        courseName: 'Music 2863Q/R/S/T - GROUP SAXOPHONE INSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b747f7e10015fd7800',
      },
      {
        _id: '60cc24b747f7e10015fd7802',
        courseCode: 'MUSIC 2865',
        shortName: 'Music 2865Q/R/S/T - GROUP WOODWIND INSTRUCTION 1',
        courseName: 'Music 2865Q/R/S/T - GROUP WOODWIND INSTRUCTION 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b747f7e10015fd7802',
      },
      {
        _id: '60cc24b747f7e10015fd7804',
        courseCode: 'MUSIC 2866',
        shortName: 'Music 2866Q/R/S/T - GROUP WOODWIND INSTRUCTION 2',
        courseName: 'Music 2866Q/R/S/T - GROUP WOODWIND INSTRUCTION 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b747f7e10015fd7804',
      },
      {
        _id: '60cc24b847f7e10015fd7806',
        courseCode: 'MUSIC 2873',
        shortName: 'Music 2873Q/R/S/T - GROUP PERCUSSION INSTRUCTION',
        courseName: 'Music 2873Q/R/S/T - GROUP PERCUSSION INSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b847f7e10015fd7806',
      },
      {
        _id: '60cc24b847f7e10015fd7808',
        courseCode: 'MUSIC 2875',
        shortName: 'Music 2875Q/R/S/T - GROUP BRASS INSTRUCTION 1',
        courseName: 'Music 2875Q/R/S/T - GROUP BRASS INSTRUCTION 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b847f7e10015fd7808',
      },
      {
        _id: '60cc24b847f7e10015fd780a',
        courseCode: 'MUSIC 2876',
        shortName: 'Music 2876Q/R/S/T - GROUP BRASS INSTRUCTION 2',
        courseName: 'Music 2876Q/R/S/T - GROUP BRASS INSTRUCTION 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b847f7e10015fd780a',
      },
      {
        _id: '60cc24b847f7e10015fd780c',
        courseCode: 'MUSIC 2880',
        shortName: 'Music 2880Q/R/S/T - GROUP GUITAR INSTRUCTION',
        courseName: 'Music 2880Q/R/S/T - GROUP GUITAR INSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b847f7e10015fd780c',
      },
      {
        _id: '60cc24b947f7e10015fd780e',
        courseCode: 'MUSIC 2885',
        shortName: 'Music 2885Q/R/S/T - GROUP STRINGS INSTRUCTION 1',
        courseName: 'Music 2885Q/R/S/T - GROUP STRINGS INSTRUCTION 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b947f7e10015fd780e',
      },
      {
        _id: '60cc24b947f7e10015fd7810',
        courseCode: 'MUSIC 2886',
        shortName: 'Music 2886Q/R/S/T - GROUP STRINGS INSTRUCTION 2',
        courseName: 'Music 2886Q/R/S/T - GROUP STRINGS INSTRUCTION 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b947f7e10015fd7810',
      },
      {
        _id: '60cc24b947f7e10015fd7812',
        courseCode: 'MUSIC 2890',
        shortName: 'Music 2890U - SPECIAL TOPICS',
        courseName: 'Music 2890U - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b947f7e10015fd7812',
      },
      {
        _id: '60cc24b947f7e10015fd7814',
        courseCode: 'MUSIC 2891',
        shortName: 'Music 2891U - SPECIAL TOPICS',
        courseName: 'Music 2891U - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24b947f7e10015fd7814',
      },
      {
        _id: '60cc24ba47f7e10015fd7816',
        courseCode: 'MUSIC 2901',
        shortName: 'Music 2901 - ORCHESTRA',
        courseName: 'Music 2901 - ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ba47f7e10015fd7816',
      },
      {
        _id: '60cc24ba47f7e10015fd7818',
        courseCode: 'MUSIC 2902',
        shortName: 'Music 2902 - WIND ENSEMBLE',
        courseName: 'Music 2902 - WIND ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ba47f7e10015fd7818',
      },
      {
        _id: '60cc24ba47f7e10015fd781a',
        courseCode: 'MUSIC 2903',
        shortName: 'Music 2903 - SYMPHONIC BAND',
        courseName: 'Music 2903 - SYMPHONIC BAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ba47f7e10015fd781a',
      },
      {
        _id: '60cc24ba47f7e10015fd781c',
        courseCode: 'MUSIC 2904',
        shortName: 'Music 2904 - JAZZ ENSEMBLE',
        courseName: 'Music 2904 - JAZZ ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ba47f7e10015fd781c',
      },
      {
        _id: '60cc24bb47f7e10015fd781e',
        courseCode: 'MUSIC 2905',
        shortName: 'Music 2905 - EARLY MUSIC ENSEMBLE',
        courseName: 'Music 2905 - EARLY MUSIC ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bb47f7e10015fd781e',
      },
      {
        _id: '60cc24bb47f7e10015fd7820',
        courseCode: 'MUSIC 2906',
        shortName: 'Music 2906 - ACCOMPANYING',
        courseName: 'Music 2906 - ACCOMPANYING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bb47f7e10015fd7820',
      },
      {
        _id: '60cc24bb47f7e10015fd7822',
        courseCode: 'MUSIC 2910',
        shortName: 'Music 2910 - WESTERN SINGERS',
        courseName: 'Music 2910 - WESTERN SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bb47f7e10015fd7822',
      },
      {
        _id: '60cc24bb47f7e10015fd7824',
        courseCode: 'MUSIC 2911',
        shortName: 'Music 2911 - LES CHORISTES',
        courseName: 'Music 2911 - LES CHORISTES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bb47f7e10015fd7824',
      },
      {
        _id: '60cc24bc47f7e10015fd7826',
        courseCode: 'MUSIC 2912',
        shortName: 'Music 2912 - ST CECILIA SINGERS',
        courseName: 'Music 2912 - ST CECILIA SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bc47f7e10015fd7826',
      },
      {
        _id: '60cc24bc47f7e10015fd7828',
        courseCode: 'MUSIC 2913',
        shortName: 'Music 2913 - CHORALE',
        courseName: 'Music 2913 - CHORALE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bc47f7e10015fd7828',
      },
      {
        _id: '60cc24bc47f7e10015fd782a',
        courseCode: 'MUSIC 2920',
        shortName: 'Music 2920 - APPLIED STUDY II',
        courseName: 'Music 2920 - APPLIED STUDY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bc47f7e10015fd782a',
      },
      {
        _id: '60cc24bc47f7e10015fd782c',
        courseCode: 'MUSIC 2921',
        shortName: 'Music 2921 - APPLIED STUDY II (PERF.)',
        courseName: 'Music 2921 - APPLIED STUDY II (PERF.)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bc47f7e10015fd782c',
      },
      {
        _id: '60cc24bd47f7e10015fd782e',
        courseCode: 'MUSIC 2922',
        shortName:
          'Music 2922 - APPLIED PRINCIPAL INSTRUMENT (THREE YEAR ARTIST DIPLOMA)',
        courseName:
          'Music 2922 - APPLIED PRINCIPAL INSTRUMENT (THREE YEAR ARTIST DIPLOMA)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bd47f7e10015fd782e',
      },
      {
        _id: '60cc24bd47f7e10015fd7830',
        courseCode: 'MUSIC 2925',
        shortName: 'Music 2925 - ELECTIVE APPLIED STUDY II',
        courseName: 'Music 2925 - ELECTIVE APPLIED STUDY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bd47f7e10015fd7830',
      },
      {
        _id: '60cc24bd47f7e10015fd7832',
        courseCode: 'MUSIC 2942',
        shortName:
          'Music 2942A/B/Y - LYRIC DICTION FOR PERFORMERS OF VOCAL MUSIC PART 1',
        courseName:
          'Music 2942A/B/Y - LYRIC DICTION FOR PERFORMERS OF VOCAL MUSIC PART 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bd47f7e10015fd7832',
      },
      {
        _id: '60cc24be47f7e10015fd7834',
        courseCode: 'MUSIC 2948',
        shortName: 'Music 2948A/B - SPECIAL TOPICS',
        courseName: 'Music 2948A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24be47f7e10015fd7834',
      },
      {
        _id: '60cc24be47f7e10015fd7836',
        courseCode: 'MUSIC 2949',
        shortName: 'Music 2949A/B - SPECIAL TOPICS',
        courseName: 'Music 2949A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24be47f7e10015fd7836',
      },
      {
        _id: '60cc24be47f7e10015fd7838',
        courseCode: 'MUSIC 2950',
        shortName: 'Music 2950 - SPECIAL TOPICS',
        courseName: 'Music 2950 - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24be47f7e10015fd7838',
      },
      {
        _id: '60cc24be47f7e10015fd783a',
        courseCode: 'MUSIC 2954',
        shortName: 'Music 2954A/B/Y - PERCUSSION ENSEMBLE',
        courseName: 'Music 2954A/B/Y - PERCUSSION ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24be47f7e10015fd783a',
      },
      {
        _id: '60cc24bf47f7e10015fd783c',
        courseCode: 'MUSIC 2970',
        shortName: 'Music 2970 - OPERA WORKSHOP I',
        courseName: 'Music 2970 - OPERA WORKSHOP I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bf47f7e10015fd783c',
      },
      {
        _id: '60cc24bf47f7e10015fd783e',
        courseCode: 'MUSIC 2971',
        shortName: 'Music 2971A - EARLY MUSIC STUDIO',
        courseName: 'Music 2971A - EARLY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bf47f7e10015fd783e',
      },
      {
        _id: '60cc24bf47f7e10015fd7840',
        courseCode: 'MUSIC 2972',
        shortName: 'Music 2972B - EARLY MUSIC STUDIO',
        courseName: 'Music 2972B - EARLY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bf47f7e10015fd7840',
      },
      {
        _id: '60cc24bf47f7e10015fd7842',
        courseCode: 'MUSIC 2973',
        shortName: 'Music 2973A - CHAMBER ORCHESTRA',
        courseName: 'Music 2973A - CHAMBER ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24bf47f7e10015fd7842',
      },
      {
        _id: '60cc24c047f7e10015fd7844',
        courseCode: 'MUSIC 2974',
        shortName: 'Music 2974B - CHAMBER ORCHESTRA',
        courseName: 'Music 2974B - CHAMBER ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c047f7e10015fd7844',
      },
      {
        _id: '60cc24c047f7e10015fd7846',
        courseCode: 'MUSIC 2975',
        shortName: 'Music 2975Y - APPLIED CHAMBER MUSIC',
        courseName: 'Music 2975Y - APPLIED CHAMBER MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c047f7e10015fd7846',
      },
      {
        _id: '60cc24c047f7e10015fd7848',
        courseCode: 'MUSIC 2977',
        shortName: 'Music 2977 - VOCAL ARTS INTENSIVE I',
        courseName: 'Music 2977 - VOCAL ARTS INTENSIVE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c047f7e10015fd7848',
      },
      {
        _id: '60cc24c147f7e10015fd784a',
        courseCode: 'MUSIC 3190',
        shortName: 'Music 3190A/B/Y - INTERNSHIP',
        courseName: 'Music 3190A/B/Y - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c147f7e10015fd784a',
      },
      {
        _id: '60cc24c147f7e10015fd784c',
        courseCode: 'MUSIC 3199',
        shortName: 'Music 3199A/B - EXPERIENTIAL LEARNING',
        courseName: 'Music 3199A/B - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c147f7e10015fd784c',
      },
      {
        _id: '60cc24c147f7e10015fd784e',
        courseCode: 'MUSIC 3601',
        shortName: 'Music 3601A/B - SCHENKERIAN ANALYSIS',
        courseName: 'Music 3601A/B - SCHENKERIAN ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c147f7e10015fd784e',
      },
      {
        _id: '60cc24c147f7e10015fd7850',
        courseCode: 'MUSIC 3602',
        shortName: 'Music 3602A/B - CHROMATIC HARMONY',
        courseName: 'Music 3602A/B - CHROMATIC HARMONY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c147f7e10015fd7850',
      },
      {
        _id: '60cc24c247f7e10015fd7852',
        courseCode: 'MUSIC 3603',
        shortName: 'Music 3603A/B - ANALYSIS:BAR./CLASSICAL',
        courseName: 'Music 3603A/B - ANALYSIS:BAR./CLASSICAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c247f7e10015fd7852',
      },
      {
        _id: '60cc24c247f7e10015fd7854',
        courseCode: 'MUSIC 3604',
        shortName: 'Music 3604A/B - ANALYSIS: ROMANTIC MUSIC',
        courseName: 'Music 3604A/B - ANALYSIS: ROMANTIC MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c247f7e10015fd7854',
      },
      {
        _id: '60cc24c247f7e10015fd7856',
        courseCode: 'MUSIC 3610',
        shortName: 'Music 3610A/B - MODAL COUNTERPOINT I',
        courseName: 'Music 3610A/B - MODAL COUNTERPOINT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c247f7e10015fd7856',
      },
      {
        _id: '60cc24c347f7e10015fd7858',
        courseCode: 'MUSIC 3611',
        shortName: 'Music 3611A/B - MODAL COUNTERPOINT II',
        courseName: 'Music 3611A/B - MODAL COUNTERPOINT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c347f7e10015fd7858',
      },
      {
        _id: '60cc24c347f7e10015fd785a',
        courseCode: 'MUSIC 3620',
        shortName: 'Music 3620A/B - TONAL COUNTERPOINT I',
        courseName: 'Music 3620A/B - TONAL COUNTERPOINT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c347f7e10015fd785a',
      },
      {
        _id: '60cc24c347f7e10015fd785c',
        courseCode: 'MUSIC 3621',
        shortName: 'Music 3621A/B - TONAL COUNTERPOINT II',
        courseName: 'Music 3621A/B - TONAL COUNTERPOINT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c347f7e10015fd785c',
      },
      {
        _id: '60cc24c347f7e10015fd785e',
        courseCode: 'MUSIC 3629',
        shortName: 'Music 3629 - COMPOSITION II',
        courseName: 'Music 3629 - COMPOSITION II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c347f7e10015fd785e',
      },
      {
        _id: '60cc24c447f7e10015fd7860',
        courseCode: 'MUSIC 3630',
        shortName: 'Music 3630A/B - CHORAL ARRANGING',
        courseName: 'Music 3630A/B - CHORAL ARRANGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c447f7e10015fd7860',
      },
      {
        _id: '60cc24c447f7e10015fd7862',
        courseCode: 'MUSIC 3641',
        shortName:
          'Music 3641A/B/Y - SCORE PREPARATION AND INSTRUMENTAL ARRANGING',
        courseName:
          'Music 3641A/B/Y - SCORE PREPARATION AND INSTRUMENTAL ARRANGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c447f7e10015fd7862',
      },
      {
        _id: '60cc24c447f7e10015fd7864',
        courseCode: 'MUSIC 3649',
        shortName: 'Music 3649A/B - STUDIES IN THEORY IV',
        courseName: 'Music 3649A/B - STUDIES IN THEORY IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c447f7e10015fd7864',
      },
      {
        _id: '60cc24c447f7e10015fd7866',
        courseCode: 'MUSIC 3650',
        shortName: 'Music 3650A/B - STUDIES IN THEORY V',
        courseName: 'Music 3650A/B - STUDIES IN THEORY V',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c447f7e10015fd7866',
      },
      {
        _id: '60cc24c547f7e10015fd7868',
        courseCode: 'MUSIC 3680',
        shortName: 'Music 3680A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3680A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c547f7e10015fd7868',
      },
      {
        _id: '60cc24c547f7e10015fd786a',
        courseCode: 'MUSIC 3696',
        shortName: 'Music 3696A/B/Y - ELECTROACOUSTIC MUSIC COMPOSITION',
        courseName: 'Music 3696A/B/Y - ELECTROACOUSTIC MUSIC COMPOSITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c547f7e10015fd786a',
      },
      {
        _id: '60cc24c547f7e10015fd786c',
        courseCode: 'MUSIC 3700',
        shortName: 'Music 3700A/B - TOPICS IN MEDIEVAL MUSIC',
        courseName: 'Music 3700A/B - TOPICS IN MEDIEVAL MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c547f7e10015fd786c',
      },
      {
        _id: '60cc24c547f7e10015fd786e',
        courseCode: 'MUSIC 3701',
        shortName: 'Music 3701A/B - TOPICS IN RENAISSANCE MUSIC',
        courseName: 'Music 3701A/B - TOPICS IN RENAISSANCE MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c547f7e10015fd786e',
      },
      {
        _id: '60cc24c547f7e10015fd7870',
        courseCode: 'MUSIC 3702',
        shortName: 'Music 3702A/B - TOPICS IN BAROQUE MUSIC',
        courseName: 'Music 3702A/B - TOPICS IN BAROQUE MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c547f7e10015fd7870',
      },
      {
        _id: '60cc24c647f7e10015fd7872',
        courseCode: 'MUSIC 3703',
        shortName: 'Music 3703A/B - TOPICS IN CLASSICAL MUSIC',
        courseName: 'Music 3703A/B - TOPICS IN CLASSICAL MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c647f7e10015fd7872',
      },
      {
        _id: '60cc24c647f7e10015fd7874',
        courseCode: 'MUSIC 3704',
        shortName: 'Music 3704A/B - TOPICS IN ROMANTIC MUSIC',
        courseName: 'Music 3704A/B - TOPICS IN ROMANTIC MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c647f7e10015fd7874',
      },
      {
        _id: '60cc24c647f7e10015fd7876',
        courseCode: 'MUSIC 3705',
        shortName: 'Music 3705A/B - TOPICS IN EARLY TWENTIETH CENTURY MUSIC',
        courseName: 'Music 3705A/B - TOPICS IN EARLY TWENTIETH CENTURY MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c647f7e10015fd7876',
      },
      {
        _id: '60cc24c747f7e10015fd7878',
        courseCode: 'MUSIC 3706',
        shortName: 'Music 3706A/B - TOPICS IN LATE 20TH CENTURY MUSIC',
        courseName: 'Music 3706A/B - TOPICS IN LATE 20TH CENTURY MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c747f7e10015fd7878',
      },
      {
        _id: '60cc24c747f7e10015fd787a',
        courseCode: 'MUSIC 3710',
        shortName: 'Music 3710A/B/Y - ISSUES IN PERFORMANCE PRACTICES I',
        courseName: 'Music 3710A/B/Y - ISSUES IN PERFORMANCE PRACTICES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c747f7e10015fd787a',
      },
      {
        _id: '60cc24c747f7e10015fd787c',
        courseCode: 'MUSIC 3711',
        shortName: 'Music 3711A/B/Y - ISSUES IN PERFORMING PRACTICES II',
        courseName: 'Music 3711A/B/Y - ISSUES IN PERFORMING PRACTICES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c747f7e10015fd787c',
      },
      {
        _id: '60cc24c747f7e10015fd787e',
        courseCode: 'MUSIC 3712',
        shortName: 'Music 3712A/B - BEL CANTO FROM HANDEL TO ROSSINI',
        courseName: 'Music 3712A/B - BEL CANTO FROM HANDEL TO ROSSINI',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c747f7e10015fd787e',
      },
      {
        _id: '60cc24c847f7e10015fd7880',
        courseCode: 'MUSIC 3713',
        shortName: 'Music 3713A/B/Y - TOPICS IN ETHNOMUSICOLOGY',
        courseName: 'Music 3713A/B/Y - TOPICS IN ETHNOMUSICOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c847f7e10015fd7880',
      },
      {
        _id: '60cc24c847f7e10015fd7882',
        courseCode: 'MUSIC 3730',
        shortName: 'Music 3730A/B - POPULAR MUSIC: GENRE STUDIES',
        courseName: 'Music 3730A/B - POPULAR MUSIC: GENRE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c847f7e10015fd7882',
      },
      {
        _id: '60cc24c847f7e10015fd7884',
        courseCode: 'MUSIC 3733',
        shortName: 'Music 3733A/B - TOPICS IN JAZZ I',
        courseName: 'Music 3733A/B - TOPICS IN JAZZ I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c847f7e10015fd7884',
      },
      {
        _id: '60cc24c847f7e10015fd7886',
        courseCode: 'MUSIC 3734',
        shortName: 'Music 3734A/B - DIGITAL AUDIO RECORDING',
        courseName: 'Music 3734A/B - DIGITAL AUDIO RECORDING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c847f7e10015fd7886',
      },
      {
        _id: '60cc24c947f7e10015fd7888',
        courseCode: 'MUSIC 3735',
        shortName: 'Music 3735A/B - POPULAR MUSIC SONGWRITING',
        courseName: 'Music 3735A/B - POPULAR MUSIC SONGWRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c947f7e10015fd7888',
      },
      {
        _id: '60cc24c947f7e10015fd788a',
        courseCode: 'MUSIC 3736',
        shortName:
          'Music 3736A/B - VOCAL PRE-PRODUCTION, TRACKING, AND EDITING',
        courseName:
          'Music 3736A/B - VOCAL PRE-PRODUCTION, TRACKING, AND EDITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c947f7e10015fd788a',
      },
      {
        _id: '60cc24c947f7e10015fd788c',
        courseCode: 'MUSIC 3737',
        shortName: 'Music 3737A/B - TOPICS IN THE POPULAR MUSIC INDUSTRY',
        courseName: 'Music 3737A/B - TOPICS IN THE POPULAR MUSIC INDUSTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c947f7e10015fd788c',
      },
      {
        _id: '60cc24c947f7e10015fd788e',
        courseCode: 'MUSIC 3738',
        shortName: 'Music 3738A/B - DIGITAL PRODUCTION & ENGINEERING',
        courseName: 'Music 3738A/B - DIGITAL PRODUCTION & ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24c947f7e10015fd788e',
      },
      {
        _id: '60cc24ca47f7e10015fd7890',
        courseCode: 'MUSIC 3739',
        shortName: 'Music 3739A/B - DIGITAL MIXING & MASTERING',
        courseName: 'Music 3739A/B - DIGITAL MIXING & MASTERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ca47f7e10015fd7890',
      },
      {
        _id: '60cc24ca47f7e10015fd7892',
        courseCode: 'MUSIC 3750',
        shortName: 'Music 3750A/B - OPERA TO C. 1830',
        courseName: 'Music 3750A/B - OPERA TO C. 1830',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ca47f7e10015fd7892',
      },
      {
        _id: '60cc24ca47f7e10015fd7894',
        courseCode: 'MUSIC 3751',
        shortName: 'Music 3751A/B - TOPICS IN ROMANTIC OPERA',
        courseName: 'Music 3751A/B - TOPICS IN ROMANTIC OPERA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ca47f7e10015fd7894',
      },
      {
        _id: '60cc24ca47f7e10015fd7896',
        courseCode: 'MUSIC 3752',
        shortName: 'Music 3752A/B - TOPICS IN 20TH CENTURY OPERA',
        courseName: 'Music 3752A/B - TOPICS IN 20TH CENTURY OPERA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ca47f7e10015fd7896',
      },
      {
        _id: '60cc24cb47f7e10015fd7898',
        courseCode: 'MUSIC 3753',
        shortName: 'Music 3753A/B - THE SYMPHONY 1750-1850',
        courseName: 'Music 3753A/B - THE SYMPHONY 1750-1850',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cb47f7e10015fd7898',
      },
      {
        _id: '60cc24cb47f7e10015fd789a',
        courseCode: 'MUSIC 3754',
        shortName: 'Music 3754A/B - THE SYMPHONY 1850 TO PRESENT',
        courseName: 'Music 3754A/B - THE SYMPHONY 1850 TO PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cb47f7e10015fd789a',
      },
      {
        _id: '60cc24cb47f7e10015fd789c',
        courseCode: 'MUSIC 3755',
        shortName: 'Music 3755A/B - CHAMBER MUSIC',
        courseName: 'Music 3755A/B - CHAMBER MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cb47f7e10015fd789c',
      },
      {
        _id: '60cc24cb47f7e10015fd789e',
        courseCode: 'MUSIC 3760',
        shortName: 'Music 3760A/B - TOPICS IN CANADIAN MUSIC',
        courseName: 'Music 3760A/B - TOPICS IN CANADIAN MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cb47f7e10015fd789e',
      },
      {
        _id: '60cc24cc47f7e10015fd78a0',
        courseCode: 'MUSIC 3762',
        shortName: 'Music 3762A/B - MUSIC AND POLITICS',
        courseName: 'Music 3762A/B - MUSIC AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cc47f7e10015fd78a0',
      },
      {
        _id: '60cc24cc47f7e10015fd78a2',
        courseCode: 'MUSIC 3771',
        shortName: 'Music 3771A/B - SPECIAL TOPICS',
        courseName: 'Music 3771A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cc47f7e10015fd78a2',
      },
      {
        _id: '60cc24cc47f7e10015fd78a4',
        courseCode: 'MUSIC 3773',
        shortName: 'Music 3773A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3773A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cc47f7e10015fd78a4',
      },
      {
        _id: '60cc24cc47f7e10015fd78a6',
        courseCode: 'MUSIC 3780',
        shortName:
          'Music 3780A/B - DIRECTED STUDY IN MUSIC HISTORY OR POPULAR MUSIC',
        courseName:
          'Music 3780A/B - DIRECTED STUDY IN MUSIC HISTORY OR POPULAR MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cc47f7e10015fd78a6',
      },
      {
        _id: '60cc24cd47f7e10015fd78a8',
        courseCode: 'MUSIC 3782',
        shortName:
          'Music 3782A/B - DIRECTED STUDY IN MUSIC ADMINISTRATIVE STUDIES',
        courseName:
          'Music 3782A/B - DIRECTED STUDY IN MUSIC ADMINISTRATIVE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cd47f7e10015fd78a8',
      },
      {
        _id: '60cc24cd47f7e10015fd78aa',
        courseCode: 'MUSIC 3810',
        shortName: 'Music 3810A/B - PSYCHOLOGY, LEARNING & MUSIC',
        courseName: 'Music 3810A/B - PSYCHOLOGY, LEARNING & MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cd47f7e10015fd78aa',
      },
      {
        _id: '60cc24cd47f7e10015fd78ac',
        courseCode: 'MUSIC 3812',
        shortName: 'Music 3812A/B/Y - MUSIC EDUCATION IN COMMUNITY',
        courseName: 'Music 3812A/B/Y - MUSIC EDUCATION IN COMMUNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cd47f7e10015fd78ac',
      },
      {
        _id: '60cc24ce47f7e10015fd78ae',
        courseCode: 'MUSIC 3832',
        shortName: 'Music 3832A/B/Y - CHORAL LITERATURE AND TECHNIQUE',
        courseName: 'Music 3832A/B/Y - CHORAL LITERATURE AND TECHNIQUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ce47f7e10015fd78ae',
      },
      {
        _id: '60cc24ce47f7e10015fd78b0',
        courseCode: 'MUSIC 3841',
        shortName: 'Music 3841A/B - INSTRUMENTAL CONDUCTING',
        courseName: 'Music 3841A/B - INSTRUMENTAL CONDUCTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ce47f7e10015fd78b0',
      },
      {
        _id: '60cc24ce47f7e10015fd78b2',
        courseCode: 'MUSIC 3852',
        shortName: 'Music 3852A/B/Y - MUSIC EDUCATION IN ELEMENTARY GRADES',
        courseName: 'Music 3852A/B/Y - MUSIC EDUCATION IN ELEMENTARY GRADES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ce47f7e10015fd78b2',
      },
      {
        _id: '60cc24cf47f7e10015fd78b4',
        courseCode: 'MUSIC 3854',
        shortName: 'Music 3854A/B - THE MADNESS OF CREATIVITY',
        courseName: 'Music 3854A/B - THE MADNESS OF CREATIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cf47f7e10015fd78b4',
      },
      {
        _id: '60cc24cf47f7e10015fd78b6',
        courseCode: 'MUSIC 3855',
        shortName: 'Music 3855A/B - MUSIC, EDUCATION AND CULTURE',
        courseName: 'Music 3855A/B - MUSIC, EDUCATION AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cf47f7e10015fd78b6',
      },
      {
        _id: '60cc24cf47f7e10015fd78b8',
        courseCode: 'MUSIC 3860',
        shortName: 'Music 3860A/B - SPECIAL TOPICS',
        courseName: 'Music 3860A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24cf47f7e10015fd78b8',
      },
      {
        _id: '60cc24d047f7e10015fd78ba',
        courseCode: 'MUSIC 3861',
        shortName: 'Music 3861A/B - SPECIAL TOPICS',
        courseName: 'Music 3861A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d047f7e10015fd78ba',
      },
      {
        _id: '60cc24d047f7e10015fd78bc',
        courseCode: 'MUSIC 3862',
        shortName: 'Music 3862A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3862A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d047f7e10015fd78bc',
      },
      {
        _id: '60cc24d047f7e10015fd78be',
        courseCode: 'MUSIC 3863',
        shortName: 'Music 3863A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3863A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d047f7e10015fd78be',
      },
      {
        _id: '60cc24d047f7e10015fd78c0',
        courseCode: 'MUSIC 3864',
        shortName: 'Music 3864A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3864A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d047f7e10015fd78c0',
      },
      {
        _id: '60cc24d147f7e10015fd78c2',
        courseCode: 'MUSIC 3870',
        shortName: 'Music 3870A/B - COMPUTER APPL.MUS.ED I',
        courseName: 'Music 3870A/B - COMPUTER APPL.MUS.ED I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d147f7e10015fd78c2',
      },
      {
        _id: '60cc24d147f7e10015fd78c4',
        courseCode: 'MUSIC 3901',
        shortName: 'Music 3901 - ORCHESTRA',
        courseName: 'Music 3901 - ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d147f7e10015fd78c4',
      },
      {
        _id: '60cc24d147f7e10015fd78c6',
        courseCode: 'MUSIC 3902',
        shortName: 'Music 3902 - WIND ENSEMBLE',
        courseName: 'Music 3902 - WIND ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d147f7e10015fd78c6',
      },
      {
        _id: '60cc24d147f7e10015fd78c8',
        courseCode: 'MUSIC 3903',
        shortName: 'Music 3903 - SYMPHONIC BAND',
        courseName: 'Music 3903 - SYMPHONIC BAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d147f7e10015fd78c8',
      },
      {
        _id: '60cc24d247f7e10015fd78ca',
        courseCode: 'MUSIC 3904',
        shortName: 'Music 3904 - JAZZ ENSEMBLE',
        courseName: 'Music 3904 - JAZZ ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d247f7e10015fd78ca',
      },
      {
        _id: '60cc24d247f7e10015fd78cc',
        courseCode: 'MUSIC 3905',
        shortName: 'Music 3905 - EARLY MUSIC ENSEMBLE',
        courseName: 'Music 3905 - EARLY MUSIC ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d247f7e10015fd78cc',
      },
      {
        _id: '60cc24d247f7e10015fd78ce',
        courseCode: 'MUSIC 3906',
        shortName: 'Music 3906 - ACCOMPANYING',
        courseName: 'Music 3906 - ACCOMPANYING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d247f7e10015fd78ce',
      },
      {
        _id: '60cc24d247f7e10015fd78d0',
        courseCode: 'MUSIC 3910',
        shortName: 'Music 3910 - WESTERN SINGERS',
        courseName: 'Music 3910 - WESTERN SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d247f7e10015fd78d0',
      },
      {
        _id: '60cc24d347f7e10015fd78d2',
        courseCode: 'MUSIC 3911',
        shortName: 'Music 3911 - LES CHORISTES',
        courseName: 'Music 3911 - LES CHORISTES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d347f7e10015fd78d2',
      },
      {
        _id: '60cc24d347f7e10015fd78d4',
        courseCode: 'MUSIC 3912',
        shortName: 'Music 3912 - ST CECILIA SINGERS',
        courseName: 'Music 3912 - ST CECILIA SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d347f7e10015fd78d4',
      },
      {
        _id: '60cc24d347f7e10015fd78d6',
        courseCode: 'MUSIC 3913',
        shortName: 'Music 3913 - CHORALE',
        courseName: 'Music 3913 - CHORALE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d347f7e10015fd78d6',
      },
      {
        _id: '60cc24d347f7e10015fd78d8',
        courseCode: 'MUSIC 3920',
        shortName: 'Music 3920 - APPLIED STUDY III',
        courseName: 'Music 3920 - APPLIED STUDY III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d347f7e10015fd78d8',
      },
      {
        _id: '60cc24d447f7e10015fd78da',
        courseCode: 'MUSIC 3921',
        shortName: 'Music 3921 - APPLIED STUDY III (PERFORMANCE)',
        courseName: 'Music 3921 - APPLIED STUDY III (PERFORMANCE)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d447f7e10015fd78da',
      },
      {
        _id: '60cc24d447f7e10015fd78dc',
        courseCode: 'MUSIC 3922',
        shortName:
          'Music 3922 - APPLIED PRINCIPAL INSTRUMENT (THREE YEAR ARTIST DIPLOMA)',
        courseName:
          'Music 3922 - APPLIED PRINCIPAL INSTRUMENT (THREE YEAR ARTIST DIPLOMA)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d447f7e10015fd78dc',
      },
      {
        _id: '60cc24d447f7e10015fd78de',
        courseCode: 'MUSIC 3924',
        shortName: 'Music 3924Y - APPLIED PRINCIPAL INSTRUMENT III',
        courseName: 'Music 3924Y - APPLIED PRINCIPAL INSTRUMENT III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d447f7e10015fd78de',
      },
      {
        _id: '60cc24d447f7e10015fd78e0',
        courseCode: 'MUSIC 3925',
        shortName: 'Music 3925 - ELECTIVE APPLIED STUDY III',
        courseName: 'Music 3925 - ELECTIVE APPLIED STUDY III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d447f7e10015fd78e0',
      },
      {
        _id: '60cc24d547f7e10015fd78e2',
        courseCode: 'MUSIC 3929',
        shortName: 'Music 3929 - ADVANCED ELECTIVE APPLIED STUDY III',
        courseName: 'Music 3929 - ADVANCED ELECTIVE APPLIED STUDY III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d547f7e10015fd78e2',
      },
      {
        _id: '60cc24d547f7e10015fd78e4',
        courseCode: 'MUSIC 3930',
        shortName: 'Music 3930A/B/Y - RECITAL',
        courseName: 'Music 3930A/B/Y - RECITAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d547f7e10015fd78e4',
      },
      {
        _id: '60cc24d547f7e10015fd78e6',
        courseCode: 'MUSIC 3942',
        shortName:
          'Music 3942A/B/Y - LYRIC DICTION FOR PERFORMERS OF VOCAL MUSIC PART 2',
        courseName:
          'Music 3942A/B/Y - LYRIC DICTION FOR PERFORMERS OF VOCAL MUSIC PART 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d547f7e10015fd78e6',
      },
      {
        _id: '60cc24d547f7e10015fd78e8',
        courseCode: 'MUSIC 3946',
        shortName: 'Music 3946A/B - SPECIAL TOPICS',
        courseName: 'Music 3946A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d547f7e10015fd78e8',
      },
      {
        _id: '60cc24d547f7e10015fd78ea',
        courseCode: 'MUSIC 3947',
        shortName: 'Music 3947A/B - SPECIAL TOPICS',
        courseName: 'Music 3947A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d547f7e10015fd78ea',
      },
      {
        _id: '60cc24d647f7e10015fd78ec',
        courseCode: 'MUSIC 3948',
        shortName: 'Music 3948A/B - SPECIAL TOPICS',
        courseName: 'Music 3948A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d647f7e10015fd78ec',
      },
      {
        _id: '60cc24d647f7e10015fd78ee',
        courseCode: 'MUSIC 3949',
        shortName: 'Music 3949A/B - SPECIAL TOPICS',
        courseName: 'Music 3949A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d647f7e10015fd78ee',
      },
      {
        _id: '60cc24d647f7e10015fd78f0',
        courseCode: 'MUSIC 3950',
        shortName: 'Music 3950 - SPECIAL TOPICS',
        courseName: 'Music 3950 - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d647f7e10015fd78f0',
      },
      {
        _id: '60cc24d647f7e10015fd78f2',
        courseCode: 'MUSIC 3951',
        shortName: 'Music 3951A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3951A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d647f7e10015fd78f2',
      },
      {
        _id: '60cc24d747f7e10015fd78f4',
        courseCode: 'MUSIC 3952',
        shortName: 'Music 3952A/B/Y - SPECIAL TOPICS',
        courseName: 'Music 3952A/B/Y - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d747f7e10015fd78f4',
      },
      {
        _id: '60cc24d747f7e10015fd78f6',
        courseCode: 'MUSIC 3953',
        shortName:
          'Music 3953A/B/Y - THE HARPSICHORD: PLAYING TECHNIQUE AND ACCOMPANIMENT PRACTICES',
        courseName:
          'Music 3953A/B/Y - THE HARPSICHORD: PLAYING TECHNIQUE AND ACCOMPANIMENT PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d747f7e10015fd78f6',
      },
      {
        _id: '60cc24d747f7e10015fd78f8',
        courseCode: 'MUSIC 3954',
        shortName: 'Music 3954A/B/Y - PERCUSSION ENSEMBLE',
        courseName: 'Music 3954A/B/Y - PERCUSSION ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d747f7e10015fd78f8',
      },
      {
        _id: '60cc24d747f7e10015fd78fa',
        courseCode: 'MUSIC 3958',
        shortName: 'Music 3958 - PIANO LITERATURE',
        courseName: 'Music 3958 - PIANO LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d747f7e10015fd78fa',
      },
      {
        _id: '60cc24d847f7e10015fd78fc',
        courseCode: 'MUSIC 3960',
        shortName: 'Music 3960A/B/Y - THE 21ST-CENTURY MUSICIAN',
        courseName: 'Music 3960A/B/Y - THE 21ST-CENTURY MUSICIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d847f7e10015fd78fc',
      },
      {
        _id: '60cc24d847f7e10015fd78fe',
        courseCode: 'MUSIC 3970',
        shortName: 'Music 3970 - OPERA WORKSHOP I',
        courseName: 'Music 3970 - OPERA WORKSHOP I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d847f7e10015fd78fe',
      },
      {
        _id: '60cc24d847f7e10015fd7900',
        courseCode: 'MUSIC 3971',
        shortName: 'Music 3971A - EARLY MUSIC STUDIO',
        courseName: 'Music 3971A - EARLY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d847f7e10015fd7900',
      },
      {
        _id: '60cc24d847f7e10015fd7902',
        courseCode: 'MUSIC 3972',
        shortName: 'Music 3972B - EARLY MUSIC STUDIO',
        courseName: 'Music 3972B - EARLY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d847f7e10015fd7902',
      },
      {
        _id: '60cc24d947f7e10015fd7904',
        courseCode: 'MUSIC 3973',
        shortName: 'Music 3973A - CHAMBER ORCHESTRA',
        courseName: 'Music 3973A - CHAMBER ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d947f7e10015fd7904',
      },
      {
        _id: '60cc24d947f7e10015fd7906',
        courseCode: 'MUSIC 3974',
        shortName: 'Music 3974B - CHAMBER ORCHESTRA',
        courseName: 'Music 3974B - CHAMBER ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d947f7e10015fd7906',
      },
      {
        _id: '60cc24d947f7e10015fd7908',
        courseCode: 'MUSIC 3975',
        shortName: 'Music 3975Y - APPLIED CHAMBER MUSIC',
        courseName: 'Music 3975Y - APPLIED CHAMBER MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d947f7e10015fd7908',
      },
      {
        _id: '60cc24d947f7e10015fd790a',
        courseCode: 'MUSIC 3976',
        shortName: 'Music 3976A/B/Y - CONTEMPORARY MUSIC STUDIO',
        courseName: 'Music 3976A/B/Y - CONTEMPORARY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24d947f7e10015fd790a',
      },
      {
        _id: '60cc24da47f7e10015fd790c',
        courseCode: 'MUSIC 3977',
        shortName: 'Music 3977 - VOCAL ARTS INTENSIVE II',
        courseName: 'Music 3977 - VOCAL ARTS INTENSIVE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24da47f7e10015fd790c',
      },
      {
        _id: '60cc24da47f7e10015fd790e',
        courseCode: 'MUSIC 4629',
        shortName: 'Music 4629 - COMPOSITION III',
        courseName: 'Music 4629 - COMPOSITION III',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24da47f7e10015fd790e',
      },
      {
        _id: '60cc24da47f7e10015fd7910',
        courseCode: 'MUSIC 4671',
        shortName: 'Music 4671A/B/Y - INTRODUCTION TO MUSIC RESEARCH',
        courseName: 'Music 4671A/B/Y - INTRODUCTION TO MUSIC RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24da47f7e10015fd7910',
      },
      {
        _id: '60cc24da47f7e10015fd7912',
        courseCode: 'MUSIC 4720',
        shortName:
          'Music 4720A/B/Y - ADVANCED SEMINAR IN MUSIC ADMINISTRATION STUDIES',
        courseName:
          'Music 4720A/B/Y - ADVANCED SEMINAR IN MUSIC ADMINISTRATION STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24da47f7e10015fd7912',
      },
      {
        _id: '60cc24db47f7e10015fd7914',
        courseCode: 'MUSIC 4735',
        shortName: 'Music 4735A/B - POPULAR  MUSIC SONGWRITING II',
        courseName: 'Music 4735A/B - POPULAR  MUSIC SONGWRITING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24db47f7e10015fd7914',
      },
      {
        _id: '60cc24db47f7e10015fd7916',
        courseCode: 'MUSIC 4740',
        shortName:
          'Music 4740A/B/Y - ADVANCED PROJECT IN POPULAR MUSIC PRODUCTION',
        courseName:
          'Music 4740A/B/Y - ADVANCED PROJECT IN POPULAR MUSIC PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24db47f7e10015fd7916',
      },
      {
        _id: '60cc24db47f7e10015fd7918',
        courseCode: 'MUSIC 4791',
        shortName: 'Music 4791A/B/Y - THE NOTATIONS OF EARLY MUSIC',
        courseName: 'Music 4791A/B/Y - THE NOTATIONS OF EARLY MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24db47f7e10015fd7918',
      },
      {
        _id: '60cc24db47f7e10015fd791a',
        courseCode: 'MUSIC 4811',
        shortName: 'Music 4811A/B - PHILOSOPHY OF MUSIC EDUCATION',
        courseName: 'Music 4811A/B - PHILOSOPHY OF MUSIC EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24db47f7e10015fd791a',
      },
      {
        _id: '60cc24db47f7e10015fd791c',
        courseCode: 'MUSIC 4840',
        shortName: 'Music 4840A/B - INSTRUMENTAL ENSEMBLE PEDAGOGY',
        courseName: 'Music 4840A/B - INSTRUMENTAL ENSEMBLE PEDAGOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24db47f7e10015fd791c',
      },
      {
        _id: '60cc24dc47f7e10015fd791e',
        courseCode: 'MUSIC 4841',
        shortName: 'Music 4841A/B - INSTRUMENTAL LITERATURE AND TECHNIQUES',
        courseName: 'Music 4841A/B - INSTRUMENTAL LITERATURE AND TECHNIQUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dc47f7e10015fd791e',
      },
      {
        _id: '60cc24dc47f7e10015fd7920',
        courseCode: 'MUSIC 4850',
        shortName: 'Music 4850A/B/Y - JAZZ PEDAGOGY',
        courseName: 'Music 4850A/B/Y - JAZZ PEDAGOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dc47f7e10015fd7920',
      },
      {
        _id: '60cc24dc47f7e10015fd7922',
        courseCode: 'MUSIC 4858',
        shortName: 'Music 4858A/B - PROGRESSIVE MUSIC PEDAGOGIES',
        courseName: 'Music 4858A/B - PROGRESSIVE MUSIC PEDAGOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dc47f7e10015fd7922',
      },
      {
        _id: '60cc24dc47f7e10015fd7924',
        courseCode: 'MUSIC 4901',
        shortName: 'Music 4901 - ORCHESTRA',
        courseName: 'Music 4901 - ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dc47f7e10015fd7924',
      },
      {
        _id: '60cc24dd47f7e10015fd7926',
        courseCode: 'MUSIC 4902',
        shortName: 'Music 4902 - WIND ENSEMBLE',
        courseName: 'Music 4902 - WIND ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dd47f7e10015fd7926',
      },
      {
        _id: '60cc24dd47f7e10015fd7928',
        courseCode: 'MUSIC 4903',
        shortName: 'Music 4903 - SYMPHONIC BAND',
        courseName: 'Music 4903 - SYMPHONIC BAND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dd47f7e10015fd7928',
      },
      {
        _id: '60cc24dd47f7e10015fd792a',
        courseCode: 'MUSIC 4904',
        shortName: 'Music 4904 - JAZZ ENSEMBLE',
        courseName: 'Music 4904 - JAZZ ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dd47f7e10015fd792a',
      },
      {
        _id: '60cc24dd47f7e10015fd792c',
        courseCode: 'MUSIC 4905',
        shortName: 'Music 4905 - EARLY MUSIC ENSEMBLE',
        courseName: 'Music 4905 - EARLY MUSIC ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24dd47f7e10015fd792c',
      },
      {
        _id: '60cc24de47f7e10015fd792e',
        courseCode: 'MUSIC 4906',
        shortName: 'Music 4906 - ACCOMPANYING',
        courseName: 'Music 4906 - ACCOMPANYING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24de47f7e10015fd792e',
      },
      {
        _id: '60cc24de47f7e10015fd7930',
        courseCode: 'MUSIC 4907',
        shortName: 'Music 4907 - ENSEMBLE',
        courseName: 'Music 4907 - ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24de47f7e10015fd7930',
      },
      {
        _id: '60cc24de47f7e10015fd7932',
        courseCode: 'MUSIC 4910',
        shortName: 'Music 4910 - WESTERN SINGERS',
        courseName: 'Music 4910 - WESTERN SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24de47f7e10015fd7932',
      },
      {
        _id: '60cc24de47f7e10015fd7934',
        courseCode: 'MUSIC 4911',
        shortName: 'Music 4911 - LES CHORISTES',
        courseName: 'Music 4911 - LES CHORISTES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24de47f7e10015fd7934',
      },
      {
        _id: '60cc24df47f7e10015fd7936',
        courseCode: 'MUSIC 4912',
        shortName: 'Music 4912 - ST CECILIA SINGERS',
        courseName: 'Music 4912 - ST CECILIA SINGERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24df47f7e10015fd7936',
      },
      {
        _id: '60cc24df47f7e10015fd7938',
        courseCode: 'MUSIC 4913',
        shortName: 'Music 4913 - CHORALE',
        courseName: 'Music 4913 - CHORALE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24df47f7e10015fd7938',
      },
      {
        _id: '60cc24df47f7e10015fd793a',
        courseCode: 'MUSIC 4920',
        shortName: 'Music 4920 - APPLIED STUDY IV',
        courseName: 'Music 4920 - APPLIED STUDY IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24df47f7e10015fd793a',
      },
      {
        _id: '60cc24df47f7e10015fd793c',
        courseCode: 'MUSIC 4921',
        shortName: 'Music 4921 - APPLIED STUDY IV (PERFORMANCE)',
        courseName: 'Music 4921 - APPLIED STUDY IV (PERFORMANCE)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24df47f7e10015fd793c',
      },
      {
        _id: '60cc24e047f7e10015fd793e',
        courseCode: 'MUSIC 4922',
        shortName: 'Music 4922 - APPLIED PRINCIPAL INSTRUMENT',
        courseName: 'Music 4922 - APPLIED PRINCIPAL INSTRUMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e047f7e10015fd793e',
      },
      {
        _id: '60cc24e047f7e10015fd7940',
        courseCode: 'MUSIC 4923',
        shortName: 'Music 4923 - APPLIED PRINCIPAL INSTRUMENT',
        courseName: 'Music 4923 - APPLIED PRINCIPAL INSTRUMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e047f7e10015fd7940',
      },
      {
        _id: '60cc24e047f7e10015fd7942',
        courseCode: 'MUSIC 4924',
        shortName: 'Music 4924Y - APPLIED PRINCIPAL INSTRUMENT IV',
        courseName: 'Music 4924Y - APPLIED PRINCIPAL INSTRUMENT IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e047f7e10015fd7942',
      },
      {
        _id: '60cc24e047f7e10015fd7944',
        courseCode: 'MUSIC 4925',
        shortName: 'Music 4925 - ELECTIVE APPLIED STUDY IV',
        courseName: 'Music 4925 - ELECTIVE APPLIED STUDY IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e047f7e10015fd7944',
      },
      {
        _id: '60cc24e147f7e10015fd7946',
        courseCode: 'MUSIC 4929',
        shortName: 'Music 4929 - ADVANCED ELECTIVE APPLIED STUDY IV',
        courseName: 'Music 4929 - ADVANCED ELECTIVE APPLIED STUDY IV',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e147f7e10015fd7946',
      },
      {
        _id: '60cc24e147f7e10015fd7948',
        courseCode: 'MUSIC 4930',
        shortName: 'Music 4930A/B/Y - RECITAL',
        courseName: 'Music 4930A/B/Y - RECITAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e147f7e10015fd7948',
      },
      {
        _id: '60cc24e147f7e10015fd794a',
        courseCode: 'MUSIC 4931',
        shortName: 'Music 4931A/B/Y - RECITAL',
        courseName: 'Music 4931A/B/Y - RECITAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e147f7e10015fd794a',
      },
      {
        _id: '60cc24e147f7e10015fd794c',
        courseCode: 'MUSIC 4932',
        shortName: 'Music 4932A/B/Y - RECITAL',
        courseName: 'Music 4932A/B/Y - RECITAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e147f7e10015fd794c',
      },
      {
        _id: '60cc24e247f7e10015fd794e',
        courseCode: 'MUSIC 4945',
        shortName: 'Music 4945 - VOCAL REPERTORY',
        courseName: 'Music 4945 - VOCAL REPERTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e247f7e10015fd794e',
      },
      {
        _id: '60cc24e247f7e10015fd7950',
        courseCode: 'MUSIC 4948',
        shortName: 'Music 4948A/B - SPECIAL TOPICS',
        courseName: 'Music 4948A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e247f7e10015fd7950',
      },
      {
        _id: '60cc24e247f7e10015fd7952',
        courseCode: 'MUSIC 4949',
        shortName: 'Music 4949A/B - SPECIAL TOPICS',
        courseName: 'Music 4949A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e247f7e10015fd7952',
      },
      {
        _id: '60cc24e247f7e10015fd7954',
        courseCode: 'MUSIC 4950',
        shortName: 'Music 4950 - SPECIAL TOPICS',
        courseName: 'Music 4950 - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e247f7e10015fd7954',
      },
      {
        _id: '60cc24e347f7e10015fd7956',
        courseCode: 'MUSIC 4954',
        shortName: 'Music 4954A/B/Y - PERCUSSION ENSEMBLE',
        courseName: 'Music 4954A/B/Y - PERCUSSION ENSEMBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e347f7e10015fd7956',
      },
      {
        _id: '60cc24e347f7e10015fd7958',
        courseCode: 'MUSIC 4959',
        shortName: 'Music 4959Y - PIANO PEDAGOGY',
        courseName: 'Music 4959Y - PIANO PEDAGOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e347f7e10015fd7958',
      },
      {
        _id: '60cc24e347f7e10015fd795a',
        courseCode: 'MUSIC 4961',
        shortName: 'Music 4961Y - VOCAL PEDAGOGY',
        courseName: 'Music 4961Y - VOCAL PEDAGOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e347f7e10015fd795a',
      },
      {
        _id: '60cc24e347f7e10015fd795c',
        courseCode: 'MUSIC 4964',
        shortName: 'Music 4964A/B - BRASS PEDAGOGY',
        courseName: 'Music 4964A/B - BRASS PEDAGOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e347f7e10015fd795c',
      },
      {
        _id: '60cc24e447f7e10015fd795e',
        courseCode: 'MUSIC 4966',
        shortName: 'Music 4966A/B/Y - CLARINET LITERATURE & PEDAGOGY',
        courseName: 'Music 4966A/B/Y - CLARINET LITERATURE & PEDAGOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e447f7e10015fd795e',
      },
      {
        _id: '60cc24e447f7e10015fd7960',
        courseCode: 'MUSIC 4970',
        shortName: 'Music 4970 - OPERA WORKSHOP',
        courseName: 'Music 4970 - OPERA WORKSHOP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e447f7e10015fd7960',
      },
      {
        _id: '60cc24e447f7e10015fd7962',
        courseCode: 'MUSIC 4971',
        shortName: 'Music 4971A - EARLY MUSIC STUDIO',
        courseName: 'Music 4971A - EARLY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e447f7e10015fd7962',
      },
      {
        _id: '60cc24e447f7e10015fd7964',
        courseCode: 'MUSIC 4972',
        shortName: 'Music 4972B - EARLY MUSIC STUDIO',
        courseName: 'Music 4972B - EARLY MUSIC STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e447f7e10015fd7964',
      },
      {
        _id: '60cc24e547f7e10015fd7966',
        courseCode: 'MUSIC 4973',
        shortName: 'Music 4973A - CHAMBER ORCHESTRA',
        courseName: 'Music 4973A - CHAMBER ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e547f7e10015fd7966',
      },
      {
        _id: '60cc24e547f7e10015fd7968',
        courseCode: 'MUSIC 4974',
        shortName: 'Music 4974B - CHAMBER ORCHESTRA',
        courseName: 'Music 4974B - CHAMBER ORCHESTRA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e547f7e10015fd7968',
      },
      {
        _id: '60cc24e547f7e10015fd796a',
        courseCode: 'MUSIC 4975',
        shortName: 'Music 4975Y - APPLIED CHAMBER MUSIC II',
        courseName: 'Music 4975Y - APPLIED CHAMBER MUSIC II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e547f7e10015fd796a',
      },
      {
        _id: '60cc24e547f7e10015fd796c',
        courseCode: 'MUSIC 4976',
        shortName:
          'Music 4976A/B/Y - COLLABORATIVE MUSICIANSHP FOR PIANO & ORCHESTRAL INSTRUMENTS',
        courseName:
          'Music 4976A/B/Y - COLLABORATIVE MUSICIANSHP FOR PIANO & ORCHESTRAL INSTRUMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e547f7e10015fd796c',
      },
      {
        _id: '60cc24e647f7e10015fd796e',
        courseCode: 'NEURO 2000',
        shortName: 'Neuroscience 2000 - INTRODUCTION TO NEUROSCIENCE',
        courseName: 'Neuroscience 2000 - INTRODUCTION TO NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e647f7e10015fd796e',
      },
      {
        _id: '60cc24e647f7e10015fd7970',
        courseCode: 'NEURO 3000',
        shortName: 'Neuroscience 3000F/G - CURRENT TOPICS IN NEUROSCIENCE',
        courseName: 'Neuroscience 3000F/G - CURRENT TOPICS IN NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e647f7e10015fd7970',
      },
      {
        _id: '60cc24e647f7e10015fd7972',
        courseCode: 'NEURO 4000',
        shortName: 'Neuroscience 4000E - HONOURS THESIS',
        courseName: 'Neuroscience 4000E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e647f7e10015fd7972',
      },
      {
        _id: '60cc24e647f7e10015fd7974',
        courseCode: 'NMM 1411',
        shortName:
          'Numerical and Mathematical Methods 1411A/B - LINEAR ALGEBRA WITH NUMERICAL ANALYSIS FOR ENGINEERING',
        courseName:
          'Numerical and Mathematical Methods 1411A/B - LINEAR ALGEBRA WITH NUMERICAL ANALYSIS FOR ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e647f7e10015fd7974',
      },
      {
        _id: '60cc24e747f7e10015fd7976',
        courseCode: 'NMM 1412',
        shortName:
          'Numerical and Mathematical Methods 1412A/B - CALCULUS FOR ENGINEERS I',
        courseName:
          'Numerical and Mathematical Methods 1412A/B - CALCULUS FOR ENGINEERS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e747f7e10015fd7976',
      },
      {
        _id: '60cc24e747f7e10015fd7978',
        courseCode: 'NMM 1414',
        shortName:
          'Numerical and Mathematical Methods 1414A/B - CALCULUS FOR ENGINEERS II',
        courseName:
          'Numerical and Mathematical Methods 1414A/B - CALCULUS FOR ENGINEERS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e747f7e10015fd7978',
      },
      {
        _id: '60cc24e747f7e10015fd797a',
        courseCode: 'NMM 2270',
        shortName:
          'Numerical and Mathematical Methods 2270A/B - APPLIED MATHEMATICS FOR ENGINEERING II',
        courseName:
          'Numerical and Mathematical Methods 2270A/B - APPLIED MATHEMATICS FOR ENGINEERING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e747f7e10015fd797a',
      },
      {
        _id: '60cc24e847f7e10015fd797e',
        courseCode: 'NMM 3415',
        shortName:
          'Numerical and Mathematical Methods 3415A/B - ADVANCED APPLIED MATHEMATICS FOR ELECTRICAL ENGINEERING',
        courseName:
          'Numerical and Mathematical Methods 3415A/B - ADVANCED APPLIED MATHEMATICS FOR ELECTRICAL ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e847f7e10015fd797e',
      },
      {
        _id: '60cc24e847f7e10015fd7980',
        courseCode: 'NMM 4617',
        shortName:
          'Numerical and Mathematical Methods 4617A/B - NUMERICAL SOLUTIONS OF PARTIAL DIFFERENTIAL EQUATIONS',
        courseName:
          'Numerical and Mathematical Methods 4617A/B - NUMERICAL SOLUTIONS OF PARTIAL DIFFERENTIAL EQUATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e847f7e10015fd7980',
      },
      {
        _id: '60cc24e847f7e10015fd7982',
        courseCode: 'NMM 4817',
        shortName:
          'Numerical and Mathematical Methods 4817A/B - METHODS OF APPLIED MATHEMATICS',
        courseName:
          'Numerical and Mathematical Methods 4817A/B - METHODS OF APPLIED MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e847f7e10015fd7982',
      },
      {
        _id: '60cc24e847f7e10015fd7984',
        courseCode: 'NURSING 1060',
        shortName:
          'Nursing 1060A/B - FOUNDATIONAL CONCEPTS OF PROFESSIONAL NURSING I',
        courseName:
          'Nursing 1060A/B - FOUNDATIONAL CONCEPTS OF PROFESSIONAL NURSING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e847f7e10015fd7984',
      },
      {
        _id: '60cc24e947f7e10015fd7986',
        courseCode: 'NURSING 1070',
        shortName:
          'Nursing 1070A/B - HEALTH PROMOTION AND CARING FOR SELF, COLLEAGUES AND CLIENTS I',
        courseName:
          'Nursing 1070A/B - HEALTH PROMOTION AND CARING FOR SELF, COLLEAGUES AND CLIENTS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e947f7e10015fd7986',
      },
      {
        _id: '60cc24e947f7e10015fd7988',
        courseCode: 'NURSING 1080',
        shortName: 'Nursing 1080A/B - HOLISTIC HEALTH ASSESSMENT I',
        courseName: 'Nursing 1080A/B - HOLISTIC HEALTH ASSESSMENT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e947f7e10015fd7988',
      },
      {
        _id: '60cc24e947f7e10015fd798a',
        courseCode: 'NURSING 1160',
        shortName:
          'Nursing 1160A/B - FOUNDATIONAL CONCEPTS OF PROFESSIONAL NURSING II',
        courseName:
          'Nursing 1160A/B - FOUNDATIONAL CONCEPTS OF PROFESSIONAL NURSING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e947f7e10015fd798a',
      },
      {
        _id: '60cc24e947f7e10015fd798c',
        courseCode: 'NURSING 1170',
        shortName:
          'Nursing 1170A/B - HEALTH PROMOTION AND CARING FOR SELF, COLLEAGUES AND CLIENTS II',
        courseName:
          'Nursing 1170A/B - HEALTH PROMOTION AND CARING FOR SELF, COLLEAGUES AND CLIENTS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24e947f7e10015fd798c',
      },
      {
        _id: '60cc24ea47f7e10015fd798e',
        courseCode: 'NURSING 1180',
        shortName: 'Nursing 1180A/B - HOLISTIC HEALTH ASSESSMENT II',
        courseName: 'Nursing 1180A/B - HOLISTIC HEALTH ASSESSMENT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ea47f7e10015fd798e',
      },
      {
        _id: '60cc24ea47f7e10015fd7990',
        courseCode: 'NURSING 1200',
        shortName: 'Nursing 1200W/X - FOUNDATIONS OF NURSING PRACTICE',
        courseName: 'Nursing 1200W/X - FOUNDATIONS OF NURSING PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ea47f7e10015fd7990',
      },
      {
        _id: '60cc24ea47f7e10015fd7992',
        courseCode: 'NURSING 1220',
        shortName: 'Nursing 1220W/X - HEALTH ASSESSMENT AND HEALTH PROMOTION',
        courseName: 'Nursing 1220W/X - HEALTH ASSESSMENT AND HEALTH PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ea47f7e10015fd7992',
      },
      {
        _id: '60cc24ea47f7e10015fd7994',
        courseCode: 'NURSING 1225',
        shortName: 'Nursing 1225A/B - HEALTH ASSESSMENT',
        courseName: 'Nursing 1225A/B - HEALTH ASSESSMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ea47f7e10015fd7994',
      },
      {
        _id: '60cc24eb47f7e10015fd7996',
        courseCode: 'NURSING 1330',
        shortName: 'Nursing 1330A/B - HUMAN ANATOMY FOR NURSING STUDENTS',
        courseName: 'Nursing 1330A/B - HUMAN ANATOMY FOR NURSING STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24eb47f7e10015fd7996',
      },
      {
        _id: '60cc24eb47f7e10015fd7998',
        courseCode: 'NURSING 2010',
        shortName: 'Nursing 2010A/B - SPECIAL TOPICS IN NURSING',
        courseName: 'Nursing 2010A/B - SPECIAL TOPICS IN NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24eb47f7e10015fd7998',
      },
      {
        _id: '60cc24eb47f7e10015fd799a',
        courseCode: 'NURSING 2220',
        shortName:
          'Nursing 2220A/B - HEALTH PROMOTION & CARING: FAMILIES AND COMMUNITIES',
        courseName:
          'Nursing 2220A/B - HEALTH PROMOTION & CARING: FAMILIES AND COMMUNITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24eb47f7e10015fd799a',
      },
      {
        _id: '60cc24eb47f7e10015fd799c',
        courseCode: 'NURSING 2221',
        shortName:
          'Nursing 2221A/B - PROFESSIONAL PRACTICE: FAMILIES AND COMMUNITIES',
        courseName:
          'Nursing 2221A/B - PROFESSIONAL PRACTICE: FAMILIES AND COMMUNITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24eb47f7e10015fd799c',
      },
      {
        _id: '60cc24ec47f7e10015fd799e',
        courseCode: 'NURSING 2230',
        shortName:
          'Nursing 2230A/B - HEALTH PROMOTION & CARING: SUPPORTING HEALTH',
        courseName:
          'Nursing 2230A/B - HEALTH PROMOTION & CARING: SUPPORTING HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ec47f7e10015fd799e',
      },
      {
        _id: '60cc24ec47f7e10015fd79a0',
        courseCode: 'NURSING 2231',
        shortName: 'Nursing 2231A/B - PROFESSIONAL PRACTICE: SUPPORTING HEALTH',
        courseName:
          'Nursing 2231A/B - PROFESSIONAL PRACTICE: SUPPORTING HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ec47f7e10015fd79a0',
      },
      {
        _id: '60cc24ec47f7e10015fd79a2',
        courseCode: 'NURSING 2240',
        shortName:
          'Nursing 2240F/G - INTRODUCTION TO HEALTH INFORMATICS WITHIN NURSING',
        courseName:
          'Nursing 2240F/G - INTRODUCTION TO HEALTH INFORMATICS WITHIN NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ec47f7e10015fd79a2',
      },
      {
        _id: '60cc24ec47f7e10015fd79a4',
        courseCode: 'NURSING 2250',
        shortName: 'Nursing 2250A/B - WAYS OF KNOWING: RESEARCH',
        courseName: 'Nursing 2250A/B - WAYS OF KNOWING: RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ec47f7e10015fd79a4',
      },
      {
        _id: '60cc24ed47f7e10015fd79a6',
        courseCode: 'NURSING 2400',
        shortName: 'Nursing 2400A/B - FAMILY AND COMMUNITY HEALTH',
        courseName: 'Nursing 2400A/B - FAMILY AND COMMUNITY HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ed47f7e10015fd79a6',
      },
      {
        _id: '60cc24ed47f7e10015fd79a8',
        courseCode: 'NURSING 2500',
        shortName:
          'Nursing 2500Q/R/S/T - PROFESSIONAL PRACTICE: MEDICATION ADMINISTRATION',
        courseName:
          'Nursing 2500Q/R/S/T - PROFESSIONAL PRACTICE: MEDICATION ADMINISTRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ed47f7e10015fd79a8',
      },
      {
        _id: '60cc24ed47f7e10015fd79aa',
        courseCode: 'NURSING 2600',
        shortName: 'Nursing 2600A/B - MENTAL HEALTH CARE',
        courseName: 'Nursing 2600A/B - MENTAL HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ed47f7e10015fd79aa',
      },
      {
        _id: '60cc24ed47f7e10015fd79ac',
        courseCode: 'NURSING 2660',
        shortName: 'Nursing 2660Q/R/S/T - PROFESSIONAL PRACTICE: MENTAL HEALTH',
        courseName:
          'Nursing 2660Q/R/S/T - PROFESSIONAL PRACTICE: MENTAL HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ed47f7e10015fd79ac',
      },
      {
        _id: '60cc24ee47f7e10015fd79ae',
        courseCode: 'NURSING 3010',
        shortName: 'Nursing 3010A/B - SPECIAL TOPICS IN NURSING',
        courseName: 'Nursing 3010A/B - SPECIAL TOPICS IN NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ee47f7e10015fd79ae',
      },
      {
        _id: '60cc24ee47f7e10015fd79b0',
        courseCode: 'NURSING 3310',
        shortName: 'Nursing 3310A/B - HEALTH IN A GLOBAL CONTEXT',
        courseName: 'Nursing 3310A/B - HEALTH IN A GLOBAL CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ee47f7e10015fd79b0',
      },
      {
        _id: '60cc24ee47f7e10015fd79b2',
        courseCode: 'NURSING 3311',
        shortName: 'Nursing 3311A/B - PROFESSIONAL PRACTICE IN GLOBAL CONTEXT',
        courseName: 'Nursing 3311A/B - PROFESSIONAL PRACTICE IN GLOBAL CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ee47f7e10015fd79b2',
      },
      {
        _id: '60cc24ef47f7e10015fd79b4',
        courseCode: 'NURSING 3324',
        shortName: 'Nursing 3324A/B - COMMUNITY HEALTH PROMOTION',
        courseName: 'Nursing 3324A/B - COMMUNITY HEALTH PROMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ef47f7e10015fd79b4',
      },
      {
        _id: '60cc24ef47f7e10015fd79b6',
        courseCode: 'NURSING 3325',
        shortName:
          'Nursing 3325W/X - PROFESSIONAL PRACTICE III: COMMUNITY HEALTH',
        courseName:
          'Nursing 3325W/X - PROFESSIONAL PRACTICE III: COMMUNITY HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ef47f7e10015fd79b6',
      },
      {
        _id: '60cc24ef47f7e10015fd79b8',
        courseCode: 'NURSING 3340',
        shortName: 'Nursing 3340A/B - WAYS OF KNOWING: DATA ANALYSIS',
        courseName: 'Nursing 3340A/B - WAYS OF KNOWING: DATA ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ef47f7e10015fd79b8',
      },
      {
        _id: '60cc24ef47f7e10015fd79ba',
        courseCode: 'NURSING 3380',
        shortName:
          'Nursing 3380F/G - HEALTH PROMOTION AND CARING IN RURAL CONTEXTS',
        courseName:
          'Nursing 3380F/G - HEALTH PROMOTION AND CARING IN RURAL CONTEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ef47f7e10015fd79ba',
      },
      {
        _id: '60cc24ef47f7e10015fd79bc',
        courseCode: 'NURSING 3400',
        shortName: 'Nursing 3400A/B - APPROACHES TO MENTAL HEALTH CARE',
        courseName: 'Nursing 3400A/B - APPROACHES TO MENTAL HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ef47f7e10015fd79bc',
      },
      {
        _id: '60cc24f047f7e10015fd79be',
        courseCode: 'NURSING 3410',
        shortName:
          'Nursing 3410A/B - GERONTOLOGY: CARE OF THE OLDER ADULT AND THEIR FAMILIES',
        courseName:
          'Nursing 3410A/B - GERONTOLOGY: CARE OF THE OLDER ADULT AND THEIR FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f047f7e10015fd79be',
      },
      {
        _id: '60cc24f047f7e10015fd79c0',
        courseCode: 'NURSING 3420',
        shortName: 'Nursing 3420A/B - PRINCIPLES OF PUBLIC HEALTH',
        courseName: 'Nursing 3420A/B - PRINCIPLES OF PUBLIC HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f047f7e10015fd79c0',
      },
      {
        _id: '60cc24f047f7e10015fd79c2',
        courseCode: 'NURSING 3430',
        shortName:
          'Nursing 3430A/B - PERINATAL HEALTH - THROUGH THE CHILD BEARING CONTINUUM',
        courseName:
          'Nursing 3430A/B - PERINATAL HEALTH - THROUGH THE CHILD BEARING CONTINUUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f047f7e10015fd79c2',
      },
      {
        _id: '60cc24f047f7e10015fd79c4',
        courseCode: 'NURSING 3440',
        shortName:
          'Nursing 3440A/B - ONCOLOGY - CARE OF INDIVIDUALS AND FAMILIES THROUGH THE CANCER CARE CONTINUUM',
        courseName:
          'Nursing 3440A/B - ONCOLOGY - CARE OF INDIVIDUALS AND FAMILIES THROUGH THE CANCER CARE CONTINUUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f047f7e10015fd79c4',
      },
      {
        _id: '60cc24f147f7e10015fd79c6',
        courseCode: 'NURSING 3450',
        shortName:
          'Nursing 3450A/B - THE ROLE OF THE HOME CARE NURSE WITHIN PATIENT/CAREGIVER ENVIRONMENTS',
        courseName:
          'Nursing 3450A/B - THE ROLE OF THE HOME CARE NURSE WITHIN PATIENT/CAREGIVER ENVIRONMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f147f7e10015fd79c6',
      },
      {
        _id: '60cc24f147f7e10015fd79c8',
        courseCode: 'NURSING 3456',
        shortName:
          'Nursing 3456A/B - INDIGENOUS HEALTH: THE INFLUENCE OF POLICY AND PRACTICES',
        courseName:
          'Nursing 3456A/B - INDIGENOUS HEALTH: THE INFLUENCE OF POLICY AND PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f147f7e10015fd79c8',
      },
      {
        _id: '60cc24f147f7e10015fd79ca',
        courseCode: 'NURSING 3460',
        shortName:
          'Nursing 3460A/B - DEVELOPMENT OF NURSING PRACTICE WITHIN PATIENT CAREGIVER ENVIRONMENTS',
        courseName:
          'Nursing 3460A/B - DEVELOPMENT OF NURSING PRACTICE WITHIN PATIENT CAREGIVER ENVIRONMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f147f7e10015fd79ca',
      },
      {
        _id: '60cc24f147f7e10015fd79cc',
        courseCode: 'NURSING 3470',
        shortName:
          'Nursing 3470A/B - MANAGEMENT OF CARE WITHIN HOME CARE PRACTICE',
        courseName:
          'Nursing 3470A/B - MANAGEMENT OF CARE WITHIN HOME CARE PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f147f7e10015fd79cc',
      },
      {
        _id: '60cc24f247f7e10015fd79ce',
        courseCode: 'NURSING 3480',
        shortName:
          'Nursing 3480A/B - PEDIATRICS: CARE OF CHILDREN AND THEIR FAMILIES',
        courseName:
          'Nursing 3480A/B - PEDIATRICS: CARE OF CHILDREN AND THEIR FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f247f7e10015fd79ce',
      },
      {
        _id: '60cc24f247f7e10015fd79d0',
        courseCode: 'NURSING 3600',
        shortName: 'Nursing 3600W/X - CLIENTS WITH HEALTH CHALLENGES',
        courseName: 'Nursing 3600W/X - CLIENTS WITH HEALTH CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f247f7e10015fd79d0',
      },
      {
        _id: '60cc24f247f7e10015fd79d2',
        courseCode: 'NURSING 3700',
        shortName:
          'Nursing 3700Q/R/S/T - PROFESSIONAL PRACTICE I: CLIENTS WITH HEALTH CHALLENGES',
        courseName:
          'Nursing 3700Q/R/S/T - PROFESSIONAL PRACTICE I: CLIENTS WITH HEALTH CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f247f7e10015fd79d2',
      },
      {
        _id: '60cc24f247f7e10015fd79d4',
        courseCode: 'NURSING 3800',
        shortName:
          'Nursing 3800Q/R/S/T - PROFESSIONAL PRACTICE II: CLIENTS WITH HEALTH CHALLENGES',
        courseName:
          'Nursing 3800Q/R/S/T - PROFESSIONAL PRACTICE II: CLIENTS WITH HEALTH CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f247f7e10015fd79d4',
      },
      {
        _id: '60cc24f347f7e10015fd79d6',
        courseCode: 'NURSING 3820',
        shortName:
          'Nursing 3820A/B - MICROBIOLOGY AND IMMUNOLOGY FOR NURSING STUDENTS',
        courseName:
          'Nursing 3820A/B - MICROBIOLOGY AND IMMUNOLOGY FOR NURSING STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f347f7e10015fd79d6',
      },
      {
        _id: '60cc24f347f7e10015fd79d8',
        courseCode: 'NURSING 3900',
        shortName:
          'Nursing 3900A/B - PROFESSIONAL PRACTICE III: CLIENTS WITH HEALTH CHALLENGES',
        courseName:
          'Nursing 3900A/B - PROFESSIONAL PRACTICE III: CLIENTS WITH HEALTH CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f347f7e10015fd79d8',
      },
      {
        _id: '60cc24f347f7e10015fd79da',
        courseCode: 'NURSING 3910',
        shortName:
          'Nursing 3910A/B - HEALTH PROMOTION & CARING: CLIENTS WITH HEALTH CHALLENGES I',
        courseName:
          'Nursing 3910A/B - HEALTH PROMOTION & CARING: CLIENTS WITH HEALTH CHALLENGES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f347f7e10015fd79da',
      },
      {
        _id: '60cc24f347f7e10015fd79dc',
        courseCode: 'NURSING 3911',
        shortName:
          'Nursing 3911A/B - PROFESSIONAL PRACTICE: CLIENTS WITH HEALTH CHALLENGES I',
        courseName:
          'Nursing 3911A/B - PROFESSIONAL PRACTICE: CLIENTS WITH HEALTH CHALLENGES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f347f7e10015fd79dc',
      },
      {
        _id: '60cc24f447f7e10015fd79de',
        courseCode: 'NURSING 3920',
        shortName:
          'Nursing 3920A/B - HEALTH PROMOTION & CARING: CLIENTS WITH HEALTH CHALLENGES II',
        courseName:
          'Nursing 3920A/B - HEALTH PROMOTION & CARING: CLIENTS WITH HEALTH CHALLENGES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f447f7e10015fd79de',
      },
      {
        _id: '60cc24f447f7e10015fd79e0',
        courseCode: 'NURSING 3921',
        shortName:
          'Nursing 3921A/B - PROFESSIONAL PRACTICE: CLIENTS WITH HEALTH CHALLENGES II',
        courseName:
          'Nursing 3921A/B - PROFESSIONAL PRACTICE: CLIENTS WITH HEALTH CHALLENGES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f447f7e10015fd79e0',
      },
      {
        _id: '60cc24f447f7e10015fd79e2',
        courseCode: 'NURSING 4010',
        shortName: 'Nursing 4010A/B - SPECIAL TOPICS IN NURSING',
        courseName: 'Nursing 4010A/B - SPECIAL TOPICS IN NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f447f7e10015fd79e2',
      },
      {
        _id: '60cc24f447f7e10015fd79e4',
        courseCode: 'NURSING 4140',
        shortName: 'Nursing 4140W/X - SPECIAL TOPICS IN NURSING',
        courseName: 'Nursing 4140W/X - SPECIAL TOPICS IN NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f447f7e10015fd79e4',
      },
      {
        _id: '60cc24f547f7e10015fd79e6',
        courseCode: 'NURSING 4320',
        shortName:
          'Nursing 4320A/B - PROFESSIONAL ETHICAL AND LEGAL OBLIGATIONS: A CRITICAL APPRAISAL',
        courseName:
          'Nursing 4320A/B - PROFESSIONAL ETHICAL AND LEGAL OBLIGATIONS: A CRITICAL APPRAISAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f547f7e10015fd79e6',
      },
      {
        _id: '60cc24f547f7e10015fd79e8',
        courseCode: 'NURSING 4400',
        shortName:
          'Nursing 4400A/B - ADVANCED CONCEPTS FOR PROFESSIONAL PRACTICE',
        courseName:
          'Nursing 4400A/B - ADVANCED CONCEPTS FOR PROFESSIONAL PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f547f7e10015fd79e8',
      },
      {
        _id: '60cc24f547f7e10015fd79ea',
        courseCode: 'NURSING 4403',
        shortName: 'Nursing 4403A - ADVANCED HEALTH ASSESSMENT AND DIAGNOSIS I',
        courseName:
          'Nursing 4403A - ADVANCED HEALTH ASSESSMENT AND DIAGNOSIS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f547f7e10015fd79ea',
      },
      {
        _id: '60cc24f547f7e10015fd79ec',
        courseCode: 'NURSING 4404',
        shortName:
          'Nursing 4404B - ADVANCED HEALTH ASSESSMENT AND DIAGNOSIS II',
        courseName:
          'Nursing 4404B - ADVANCED HEALTH ASSESSMENT AND DIAGNOSIS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f547f7e10015fd79ec',
      },
      {
        _id: '60cc24f547f7e10015fd79ee',
        courseCode: 'NURSING 4405',
        shortName: 'Nursing 4405A - THERAPEUTICS IN PRIMARY HEALTH CARE I',
        courseName: 'Nursing 4405A - THERAPEUTICS IN PRIMARY HEALTH CARE I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f547f7e10015fd79ee',
      },
      {
        _id: '60cc24f647f7e10015fd79f0',
        courseCode: 'NURSING 4406',
        shortName: 'Nursing 4406B - THERAPEUTCS IN PRIMARY HEALTH CARE II',
        courseName: 'Nursing 4406B - THERAPEUTCS IN PRIMARY HEALTH CARE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f647f7e10015fd79f0',
      },
      {
        _id: '60cc24f647f7e10015fd79f2',
        courseCode: 'NURSING 4410',
        shortName:
          'Nursing 4410A/B - PROFESSIONAL PRACTICE: CLIENTS WITH COMPLEX HEALTH CHALLENGES',
        courseName:
          'Nursing 4410A/B - PROFESSIONAL PRACTICE: CLIENTS WITH COMPLEX HEALTH CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f647f7e10015fd79f2',
      },
      {
        _id: '60cc24f647f7e10015fd79f4',
        courseCode: 'NURSING 4440',
        shortName:
          'Nursing 4440A/B - FUTURE DIRECTIONS FOR NURSING & HEALTH CARE',
        courseName:
          'Nursing 4440A/B - FUTURE DIRECTIONS FOR NURSING & HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f647f7e10015fd79f4',
      },
      {
        _id: '60cc24f647f7e10015fd79f6',
        courseCode: 'NURSING 4461',
        shortName:
          'Nursing 4461W/X - PROFESSIONAL PRACTICE: INTEGRATIVE PRACTICUM',
        courseName:
          'Nursing 4461W/X - PROFESSIONAL PRACTICE: INTEGRATIVE PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f647f7e10015fd79f6',
      },
      {
        _id: '60cc24f747f7e10015fd79f8',
        courseCode: 'NURSING 4490',
        shortName: 'Nursing 4490Y - PATHOPHYSIOLOGY FOR NURSE PRACTITIONER',
        courseName: 'Nursing 4490Y - PATHOPHYSIOLOGY FOR NURSE PRACTITIONER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f747f7e10015fd79f8',
      },
      {
        _id: '60cc24f747f7e10015fd79fa',
        courseCode: 'NURSING 4491',
        shortName: 'Nursing 4491Y - NURSE PRACT ROLES AND RESPONSIBILITIE',
        courseName: 'Nursing 4491Y - NURSE PRACT ROLES AND RESPONSIBILITIE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f747f7e10015fd79fa',
      },
      {
        _id: '60cc24f747f7e10015fd79fc',
        courseCode: 'NURSING 4497',
        shortName: 'Nursing 4497 - INTEGRATIVE PRACTICUM',
        courseName: 'Nursing 4497 - INTEGRATIVE PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f747f7e10015fd79fc',
      },
      {
        _id: '60cc24f747f7e10015fd79fe',
        courseCode: 'NURSING 4500',
        shortName: 'Nursing 4500W/X - OPERATING ROOM NURSING',
        courseName: 'Nursing 4500W/X - OPERATING ROOM NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f747f7e10015fd79fe',
      },
      {
        _id: '60cc24f847f7e10015fd7a00',
        courseCode: 'ONEHEALT 3300',
        shortName: 'One Health 3300A/B - FOUNDATIONS IN ONE HEALTH',
        courseName: 'One Health 3300A/B - FOUNDATIONS IN ONE HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f847f7e10015fd7a00',
      },
      {
        _id: '60cc24f847f7e10015fd7a02',
        courseCode: 'ONEHEALT 3600',
        shortName: 'One Health 3600A/B - ONE HEALTH IN ACTION',
        courseName: 'One Health 3600A/B - ONE HEALTH IN ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f847f7e10015fd7a02',
      },
      {
        _id: '60cc24f847f7e10015fd7a04',
        courseCode: 'ONEHEALT 4100',
        shortName:
          'One Health 4100F/G - ANIMAL HEALTH, HUMAN HEALTH, AND COMPARATIVE PATHOLOGY',
        courseName:
          'One Health 4100F/G - ANIMAL HEALTH, HUMAN HEALTH, AND COMPARATIVE PATHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f847f7e10015fd7a04',
      },
      {
        _id: '60cc24f847f7e10015fd7a06',
        courseCode: 'ONEHEALT 4980',
        shortName: 'One Health 4980E - SEMINAR & RESEARCH PROJECT',
        courseName: 'One Health 4980E - SEMINAR & RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f847f7e10015fd7a06',
      },
      {
        _id: '60cc24f947f7e10015fd7a08',
        courseCode: 'PATHOL 2420',
        shortName: 'Pathology 2420A - PATHOLOGY FOR NURSING STUDENTS',
        courseName: 'Pathology 2420A - PATHOLOGY FOR NURSING STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f947f7e10015fd7a08',
      },
      {
        _id: '60cc24f947f7e10015fd7a0a',
        courseCode: 'PATHOL 3500',
        shortName: 'Pathology 3500 - INTRODUCTION TO HUMAN PATHOLOGY',
        courseName: 'Pathology 3500 - INTRODUCTION TO HUMAN PATHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f947f7e10015fd7a0a',
      },
      {
        _id: '60cc24f947f7e10015fd7a0c',
        courseCode: 'PATHOL 3700',
        shortName:
          'Pathology 3700F/G - MODERN APPROACHES IN BIOMEDICAL AND PATHOLOGY RESEARCH',
        courseName:
          'Pathology 3700F/G - MODERN APPROACHES IN BIOMEDICAL AND PATHOLOGY RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f947f7e10015fd7a0c',
      },
      {
        _id: '60cc24f947f7e10015fd7a0e',
        courseCode: 'PATHOL 4200',
        shortName:
          'Pathology 4200A/B - CURRENT CONCEPTS IN THE PATHOGENESIS OF HUMAN DISEASES',
        courseName:
          'Pathology 4200A/B - CURRENT CONCEPTS IN THE PATHOGENESIS OF HUMAN DISEASES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24f947f7e10015fd7a0e',
      },
      {
        _id: '60cc24fa47f7e10015fd7a10',
        courseCode: 'PATHOL 4400',
        shortName: 'Pathology 4400A/B - ENVIRONMENTAL PATHOLOGY',
        courseName: 'Pathology 4400A/B - ENVIRONMENTAL PATHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fa47f7e10015fd7a10',
      },
      {
        _id: '60cc24fa47f7e10015fd7a12',
        courseCode: 'PATHOL 4425',
        shortName: 'Pathology 4425A/B - ADVANCED CLINICAL HISTOLOGY',
        courseName: 'Pathology 4425A/B - ADVANCED CLINICAL HISTOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fa47f7e10015fd7a12',
      },
      {
        _id: '60cc24fa47f7e10015fd7a14',
        courseCode: 'PATHOL 4450',
        shortName: 'Pathology 4450A - MOLECULAR GENETICS OF HUMAN CANCER',
        courseName: 'Pathology 4450A - MOLECULAR GENETICS OF HUMAN CANCER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fa47f7e10015fd7a14',
      },
      {
        _id: '60cc24fa47f7e10015fd7a16',
        courseCode: 'PATHOL 4500',
        shortName: 'Pathology 4500B - INTRODUCTION TO FORENSIC SCIENCES',
        courseName: 'Pathology 4500B - INTRODUCTION TO FORENSIC SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fa47f7e10015fd7a16',
      },
      {
        _id: '60cc24fb47f7e10015fd7a18',
        courseCode: 'PATHOL 4980',
        shortName: 'Pathology 4980E - SEMINAR AND RESEARCH PROJECT',
        courseName: 'Pathology 4980E - SEMINAR AND RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fb47f7e10015fd7a18',
      },
      {
        _id: '60cc24fb47f7e10015fd7a1a',
        courseCode: 'PERSIAN 1030',
        shortName: 'Persian 1030 - PERSIAN (FARSI) FOR BEGINNERS',
        courseName: 'Persian 1030 - PERSIAN (FARSI) FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fb47f7e10015fd7a1a',
      },
      {
        _id: '60cc24fb47f7e10015fd7a1c',
        courseCode: 'PERSIAN 1035',
        shortName: 'Persian 1035 - BEGINNER PERSIAN FOR HERITAGE SPEAKERS',
        courseName: 'Persian 1035 - BEGINNER PERSIAN FOR HERITAGE SPEAKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fb47f7e10015fd7a1c',
      },
      {
        _id: '60cc24fb47f7e10015fd7a1e',
        courseCode: 'PERSIAN 2200',
        shortName: 'Persian 2200 - INTERMEDIATE PERSIAN',
        courseName: 'Persian 2200 - INTERMEDIATE PERSIAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fb47f7e10015fd7a1e',
      },
      {
        _id: '60cc24fc47f7e10015fd7a20',
        courseCode: 'PHARM 2060',
        shortName:
          'Pharmacology 2060A/B - INTRODUCTORY PHARMACOLOGY AND THERAPEUTICS',
        courseName:
          'Pharmacology 2060A/B - INTRODUCTORY PHARMACOLOGY AND THERAPEUTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fc47f7e10015fd7a20',
      },
      {
        _id: '60cc24fc47f7e10015fd7a22',
        courseCode: 'PHARM 3620',
        shortName:
          'Pharmacology 3620 - HUMAN PHARMACOLOGY AND THERAPEUTIC PRINCIPLES',
        courseName:
          'Pharmacology 3620 - HUMAN PHARMACOLOGY AND THERAPEUTIC PRINCIPLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fc47f7e10015fd7a22',
      },
      {
        _id: '60cc24fc47f7e10015fd7a24',
        courseCode: 'PHARM 4320',
        shortName: 'Pharmacology 4320A/B - CARDIOVASCULAR PHARMACOLOGY',
        courseName: 'Pharmacology 4320A/B - CARDIOVASCULAR PHARMACOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fc47f7e10015fd7a24',
      },
      {
        _id: '60cc24fc47f7e10015fd7a26',
        courseCode: 'PHARM 4340',
        shortName:
          'Pharmacology 4340A/B - GENE EXPRESSION PATHWAYS IN DRUG DISCOVERY',
        courseName:
          'Pharmacology 4340A/B - GENE EXPRESSION PATHWAYS IN DRUG DISCOVERY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fc47f7e10015fd7a26',
      },
      {
        _id: '60cc24fd47f7e10015fd7a28',
        courseCode: 'PHARM 4350',
        shortName: 'Pharmacology 4350A/B - CLINICAL PHARMACOLOGY',
        courseName: 'Pharmacology 4350A/B - CLINICAL PHARMACOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fd47f7e10015fd7a28',
      },
      {
        _id: '60cc24fd47f7e10015fd7a2a',
        courseCode: 'PHARM 4360',
        shortName: 'Pharmacology 4360A/B - MECHANISMS OF CANCER CHEMOTHERAPY',
        courseName: 'Pharmacology 4360A/B - MECHANISMS OF CANCER CHEMOTHERAPY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fd47f7e10015fd7a2a',
      },
      {
        _id: '60cc24fd47f7e10015fd7a2c',
        courseCode: 'PHARM 4370',
        shortName: 'Pharmacology 4370A/B - THE PHARMACOLOGY OF DRUGS OF ABUSE',
        courseName: 'Pharmacology 4370A/B - THE PHARMACOLOGY OF DRUGS OF ABUSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fd47f7e10015fd7a2c',
      },
      {
        _id: '60cc24fd47f7e10015fd7a2e',
        courseCode: 'PHARM 4380',
        shortName: 'Pharmacology 4380A/B - NEUROPHARMACOLOGY',
        courseName: 'Pharmacology 4380A/B - NEUROPHARMACOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fd47f7e10015fd7a2e',
      },
      {
        _id: '60cc24fd47f7e10015fd7a30',
        courseCode: 'PHARM 4430',
        shortName:
          'Pharmacology 4430A/B - PHARMACOLOGY AND TOXICOLOGY OF NATURAL SOURCED MEDICINES',
        courseName:
          'Pharmacology 4430A/B - PHARMACOLOGY AND TOXICOLOGY OF NATURAL SOURCED MEDICINES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fd47f7e10015fd7a30',
      },
      {
        _id: '60cc24fe47f7e10015fd7a32',
        courseCode: 'PHARM 4540',
        shortName:
          'Pharmacology 4540A/B - FROM GENES TO THERAPIES:  TARGETED STRATEGIES IN MEDICINE',
        courseName:
          'Pharmacology 4540A/B - FROM GENES TO THERAPIES:  TARGETED STRATEGIES IN MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fe47f7e10015fd7a32',
      },
      {
        _id: '60cc24fe47f7e10015fd7a34',
        courseCode: 'PHARM 4620',
        shortName:
          'Pharmacology 4620A/B - MOLECULAR AND STRUCTURAL BASIS OF DRUG ACTION',
        courseName:
          'Pharmacology 4620A/B - MOLECULAR AND STRUCTURAL BASIS OF DRUG ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fe47f7e10015fd7a34',
      },
      {
        _id: '60cc24fe47f7e10015fd7a36',
        courseCode: 'PHARM 4660',
        shortName: 'Pharmacology 4660A/B - HUMAN TOXICOLOGY',
        courseName: 'Pharmacology 4660A/B - HUMAN TOXICOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fe47f7e10015fd7a36',
      },
      {
        _id: '60cc24fe47f7e10015fd7a38',
        courseCode: 'PHILOSOP 1000',
        shortName:
          'Philosophy 1000E - INTRODUCTION TO PHILOSOPHY & CRITICAL THINKING',
        courseName:
          'Philosophy 1000E - INTRODUCTION TO PHILOSOPHY & CRITICAL THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24fe47f7e10015fd7a38',
      },
      {
        _id: '60cc24ff47f7e10015fd7a3a',
        courseCode: 'PHILOSOP 1020',
        shortName: 'Philosophy 1020 - INTRODUCTION TO PHILOSOPHY',
        courseName: 'Philosophy 1020 - INTRODUCTION TO PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ff47f7e10015fd7a3a',
      },
      {
        _id: '60cc24ff47f7e10015fd7a3c',
        courseCode: 'PHILOSOP 1022',
        shortName: 'Philosophy 1022E - ADVANCED INTRODUCTION TO PHILOSOPHY',
        courseName: 'Philosophy 1022E - ADVANCED INTRODUCTION TO PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ff47f7e10015fd7a3c',
      },
      {
        _id: '60cc24ff47f7e10015fd7a3e',
        courseCode: 'PHILOSOP 1030',
        shortName:
          'Philosophy 1030A/B - UNDERSTANDING SCIENCE: HOW SCIENCE RELATES TO THE NATURAL AND SOCIAL WORLD',
        courseName:
          'Philosophy 1030A/B - UNDERSTANDING SCIENCE: HOW SCIENCE RELATES TO THE NATURAL AND SOCIAL WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ff47f7e10015fd7a3e',
      },
      {
        _id: '60cc24ff47f7e10015fd7a40',
        courseCode: 'PHILOSOP 1040',
        shortName: 'Philosophy 1040F/G - ETHICS, LAW, AND POLITICS',
        courseName: 'Philosophy 1040F/G - ETHICS, LAW, AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc24ff47f7e10015fd7a40',
      },
      {
        _id: '60cc250047f7e10015fd7a42',
        courseCode: 'PHILOSOP 1050',
        shortName: 'Philosophy 1050F/G - NON-WESTERN "PHILOSOPHIES"',
        courseName: 'Philosophy 1050F/G - NON-WESTERN "PHILOSOPHIES"',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250047f7e10015fd7a42',
      },
      {
        _id: '60cc250047f7e10015fd7a44',
        courseCode: 'PHILOSOP 1100',
        shortName:
          'Philosophy 1100E - PHILOSOPHY FROM ANTIQUITY TO THE 20TH CENTURY',
        courseName:
          'Philosophy 1100E - PHILOSOPHY FROM ANTIQUITY TO THE 20TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250047f7e10015fd7a44',
      },
      {
        _id: '60cc250047f7e10015fd7a46',
        courseCode: 'PHILOSOP 1120',
        shortName: 'Philosophy 1120F/G - POWER, SOCIAL POLITICS, AND CULTURE',
        courseName: 'Philosophy 1120F/G - POWER, SOCIAL POLITICS, AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250047f7e10015fd7a46',
      },
      {
        _id: '60cc250147f7e10015fd7a48',
        courseCode: 'PHILOSOP 1130',
        shortName: 'Philosophy 1130F/G - BIG IDEAS',
        courseName: 'Philosophy 1130F/G - BIG IDEAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250147f7e10015fd7a48',
      },
      {
        _id: '60cc250147f7e10015fd7a4a',
        courseCode: 'PHILOSOP 1150',
        shortName:
          'Philosophy 1150E - INTRODUCTION TO THE WESTERN INTELLECTUAL TRADITION',
        courseName:
          'Philosophy 1150E - INTRODUCTION TO THE WESTERN INTELLECTUAL TRADITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250147f7e10015fd7a4a',
      },
      {
        _id: '60cc250147f7e10015fd7a4c',
        courseCode: 'PHILOSOP 1160',
        shortName: 'Philosophy 1160F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 1160F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250147f7e10015fd7a4c',
      },
      {
        _id: '60cc250247f7e10015fd7a4e',
        courseCode: 'PHILOSOP 1162',
        shortName: 'Philosophy 1162F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 1162F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250247f7e10015fd7a4e',
      },
      {
        _id: '60cc250247f7e10015fd7a50',
        courseCode: 'PHILOSOP 1200',
        shortName: 'Philosophy 1200 - REASONING AND CRITICAL THINKING',
        courseName: 'Philosophy 1200 - REASONING AND CRITICAL THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250247f7e10015fd7a50',
      },
      {
        _id: '60cc250247f7e10015fd7a52',
        courseCode: 'PHILOSOP 1230',
        shortName: 'Philosophy 1230A/B - REASONING AND CRITICAL THINKING',
        courseName: 'Philosophy 1230A/B - REASONING AND CRITICAL THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250247f7e10015fd7a52',
      },
      {
        _id: '60cc250347f7e10015fd7a54',
        courseCode: 'PHILOSOP 1250',
        shortName: 'Philosophy 1250F/G - RIGHT AND WRONG',
        courseName: 'Philosophy 1250F/G - RIGHT AND WRONG',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250347f7e10015fd7a54',
      },
      {
        _id: '60cc250347f7e10015fd7a56',
        courseCode: 'PHILOSOP 1300',
        shortName: 'Philosophy 1300E - INTRODUCTORY PHILOSOPHY',
        courseName: 'Philosophy 1300E - INTRODUCTORY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250347f7e10015fd7a56',
      },
      {
        _id: '60cc250347f7e10015fd7a58',
        courseCode: 'PHILOSOP 1305',
        shortName: 'Philosophy 1305F/G - QUESTIONS OF THE DAY',
        courseName: 'Philosophy 1305F/G - QUESTIONS OF THE DAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250347f7e10015fd7a58',
      },
      {
        _id: '60cc250447f7e10015fd7a5a',
        courseCode: 'PHILOSOP 1340',
        shortName: 'Philosophy 1340F/G - PHILOSOPHY THROUGH FILM',
        courseName: 'Philosophy 1340F/G - PHILOSOPHY THROUGH FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250447f7e10015fd7a5a',
      },
      {
        _id: '60cc250447f7e10015fd7a5c',
        courseCode: 'PHILOSOP 1350',
        shortName:
          'Philosophy 1350F/G - INTRODUCTION TO PHILOSOPHY (REALITY AND WHAT WE CAN KNOW OF IT)',
        courseName:
          'Philosophy 1350F/G - INTRODUCTION TO PHILOSOPHY (REALITY AND WHAT WE CAN KNOW OF IT)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250447f7e10015fd7a5c',
      },
      {
        _id: '60cc250447f7e10015fd7a5e',
        courseCode: 'PHILOSOP 1360',
        shortName: 'Philosophy 1360E - THE GREAT HUMAN QUESTIONS WE ALL ASK',
        courseName: 'Philosophy 1360E - THE GREAT HUMAN QUESTIONS WE ALL ASK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250447f7e10015fd7a5e',
      },
      {
        _id: '60cc250547f7e10015fd7a60',
        courseCode: 'PHILOSOP 1361',
        shortName:
          'Philosophy 1361E - FINDING HAPPINESS: A JOURNEY THROUGH PHILOSOPHY',
        courseName:
          'Philosophy 1361E - FINDING HAPPINESS: A JOURNEY THROUGH PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250547f7e10015fd7a60',
      },
      {
        _id: '60cc250547f7e10015fd7a62',
        courseCode: 'PHILOSOP 1363',
        shortName: 'Philosophy 1363 - CULTURE AND BEING HUMAN',
        courseName: 'Philosophy 1363 - CULTURE AND BEING HUMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250547f7e10015fd7a62',
      },
      {
        _id: '60cc250547f7e10015fd7a64',
        courseCode: 'PHILOSOP 1370',
        shortName: 'Philosophy 1370A/B - FORM, THOUGHT, COMMUNICATION',
        courseName: 'Philosophy 1370A/B - FORM, THOUGHT, COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250547f7e10015fd7a64',
      },
      {
        _id: '60cc250547f7e10015fd7a66',
        courseCode: 'PHILOSOP 1900',
        shortName: 'Philosophy 1900E - CRITICAL THINKING FOR UNIVERSITY',
        courseName: 'Philosophy 1900E - CRITICAL THINKING FOR UNIVERSITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250547f7e10015fd7a66',
      },
      {
        _id: '60cc250647f7e10015fd7a68',
        courseCode: 'PHILOSOP 1901',
        shortName:
          'Philosophy 1901E - FOUNDATIONS IN WESTERN THOUGHT AND CIVILIZATION',
        courseName:
          'Philosophy 1901E - FOUNDATIONS IN WESTERN THOUGHT AND CIVILIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250647f7e10015fd7a68',
      },
      {
        _id: '60cc250647f7e10015fd7a6a',
        courseCode: 'PHILOSOP 2014',
        shortName: 'Philosophy 2014 - THOMISTIC PHILOSOPHY',
        courseName: 'Philosophy 2014 - THOMISTIC PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250647f7e10015fd7a6a',
      },
      {
        _id: '60cc250647f7e10015fd7a6c',
        courseCode: 'PHILOSOP 2020',
        shortName: 'Philosophy 2020 - BASIC LOGIC',
        courseName: 'Philosophy 2020 - BASIC LOGIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250647f7e10015fd7a6c',
      },
      {
        _id: '60cc250647f7e10015fd7a6e',
        courseCode: 'PHILOSOP 2021',
        shortName: 'Philosophy 2021A/B - OPPOSITIONS AND PARADOXES',
        courseName: 'Philosophy 2021A/B - OPPOSITIONS AND PARADOXES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250647f7e10015fd7a6e',
      },
      {
        _id: '60cc250747f7e10015fd7a70',
        courseCode: 'PHILOSOP 2022',
        shortName: 'Philosophy 2022 - ARISTOTLEIAN LOGIC',
        courseName: 'Philosophy 2022 - ARISTOTLEIAN LOGIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250747f7e10015fd7a70',
      },
      {
        _id: '60cc250747f7e10015fd7a72',
        courseCode: 'PHILOSOP 2026',
        shortName: 'Philosophy 2026F/G - PHILOSOPHY OF ECONOMICS',
        courseName: 'Philosophy 2026F/G - PHILOSOPHY OF ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250747f7e10015fd7a72',
      },
      {
        _id: '60cc250747f7e10015fd7a74',
        courseCode: 'PHILOSOP 2032',
        shortName: 'Philosophy 2032F/G - EINSTEIN FOR EVERYONE',
        courseName: 'Philosophy 2032F/G - EINSTEIN FOR EVERYONE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250747f7e10015fd7a74',
      },
      {
        _id: '60cc250747f7e10015fd7a76',
        courseCode: 'PHILOSOP 2033',
        shortName:
          'Philosophy 2033A/B - INTRODUCTION TO ENVIRONMENTAL PHILOSOPHY',
        courseName:
          'Philosophy 2033A/B - INTRODUCTION TO ENVIRONMENTAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250747f7e10015fd7a76',
      },
      {
        _id: '60cc250847f7e10015fd7a78',
        courseCode: 'PHILOSOP 2035',
        shortName: 'Philosophy 2035F/G - NATURE, ECOLOGY AND THE FUTURE',
        courseName: 'Philosophy 2035F/G - NATURE, ECOLOGY AND THE FUTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250847f7e10015fd7a78',
      },
      {
        _id: '60cc250847f7e10015fd7a7a',
        courseCode: 'PHILOSOP 2037',
        shortName:
          'Philosophy 2037F/G - PHILOSOPHY AND ARTIFICIAL INTELLIGENCE',
        courseName:
          'Philosophy 2037F/G - PHILOSOPHY AND ARTIFICIAL INTELLIGENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250847f7e10015fd7a7a',
      },
      {
        _id: '60cc250847f7e10015fd7a7c',
        courseCode: 'PHILOSOP 2044',
        shortName:
          'Philosophy 2044F/G - INTRODUCTION TO THE PHILOSOPHY OF PSYCHIATRY',
        courseName:
          'Philosophy 2044F/G - INTRODUCTION TO THE PHILOSOPHY OF PSYCHIATRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250847f7e10015fd7a7c',
      },
      {
        _id: '60cc250847f7e10015fd7a7e',
        courseCode: 'PHILOSOP 2050',
        shortName: 'Philosophy 2050F/G - THE SCIENTIFIC SEARCH FOR THE MIND',
        courseName: 'Philosophy 2050F/G - THE SCIENTIFIC SEARCH FOR THE MIND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250847f7e10015fd7a7e',
      },
      {
        _id: '60cc250947f7e10015fd7a80',
        courseCode: 'PHILOSOP 2061',
        shortName:
          'Philosophy 2061F/G - SCIENCE VS. RELIGION: THE EPISTEMOLOGICAL CONFLICT',
        courseName:
          'Philosophy 2061F/G - SCIENCE VS. RELIGION: THE EPISTEMOLOGICAL CONFLICT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250947f7e10015fd7a80',
      },
      {
        _id: '60cc250947f7e10015fd7a82',
        courseCode: 'PHILOSOP 2062',
        shortName: 'Philosophy 2062F/G - POWER, PRIVILEGE, AND OPPRESSION',
        courseName: 'Philosophy 2062F/G - POWER, PRIVILEGE, AND OPPRESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250947f7e10015fd7a82',
      },
      {
        _id: '60cc250947f7e10015fd7a84',
        courseCode: 'PHILOSOP 2063',
        shortName: 'Philosophy 2063E - PHILOSOPHY OF RELIGION',
        courseName: 'Philosophy 2063E - PHILOSOPHY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250947f7e10015fd7a84',
      },
      {
        _id: '60cc250947f7e10015fd7a86',
        courseCode: 'PHILOSOP 2065',
        shortName: 'Philosophy 2065F/G - EVIL',
        courseName: 'Philosophy 2065F/G - EVIL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250947f7e10015fd7a86',
      },
      {
        _id: '60cc250a47f7e10015fd7a88',
        courseCode: 'PHILOSOP 2070',
        shortName: 'Philosophy 2070E - ETHICS AND SOCIETY',
        courseName: 'Philosophy 2070E - ETHICS AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250a47f7e10015fd7a88',
      },
      {
        _id: '60cc250a47f7e10015fd7a8a',
        courseCode: 'PHILOSOP 2073',
        shortName: 'Philosophy 2073F/G - DEATH',
        courseName: 'Philosophy 2073F/G - DEATH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250a47f7e10015fd7a8a',
      },
      {
        _id: '60cc250a47f7e10015fd7a8c',
        courseCode: 'PHILOSOP 2074',
        shortName: 'Philosophy 2074F/G - BUSINESS ETHICS',
        courseName: 'Philosophy 2074F/G - BUSINESS ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250a47f7e10015fd7a8c',
      },
      {
        _id: '60cc250b47f7e10015fd7a8e',
        courseCode: 'PHILOSOP 2075',
        shortName: 'Philosophy 2075F/G - BUSINESS ETHICS IN A GLOBAL CONTEXT',
        courseName: 'Philosophy 2075F/G - BUSINESS ETHICS IN A GLOBAL CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250b47f7e10015fd7a8e',
      },
      {
        _id: '60cc250b47f7e10015fd7a90',
        courseCode: 'PHILOSOP 2076',
        shortName:
          'Philosophy 2076F/G - PHILOSOPHY OF SEX, LOVE AND FRIENDSHIP',
        courseName:
          'Philosophy 2076F/G - PHILOSOPHY OF SEX, LOVE AND FRIENDSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250b47f7e10015fd7a90',
      },
      {
        _id: '60cc250b47f7e10015fd7a92',
        courseCode: 'PHILOSOP 2077',
        shortName: 'Philosophy 2077F/G - GENDER AND SEXUALITY',
        courseName: 'Philosophy 2077F/G - GENDER AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250b47f7e10015fd7a92',
      },
      {
        _id: '60cc250c47f7e10015fd7a94',
        courseCode: 'PHILOSOP 2078',
        shortName: 'Philosophy 2078F/G - ETHICS FOR A DIGITAL WORLD',
        courseName: 'Philosophy 2078F/G - ETHICS FOR A DIGITAL WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250c47f7e10015fd7a94',
      },
      {
        _id: '60cc250c47f7e10015fd7a96',
        courseCode: 'PHILOSOP 2079',
        shortName: 'Philosophy 2079F/G - SPORTS ETHICS',
        courseName: 'Philosophy 2079F/G - SPORTS ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250c47f7e10015fd7a96',
      },
      {
        _id: '60cc250c47f7e10015fd7a98',
        courseCode: 'PHILOSOP 2080',
        shortName: 'Philosophy 2080 - PHILOSOPHY OF LAW',
        courseName: 'Philosophy 2080 - PHILOSOPHY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250c47f7e10015fd7a98',
      },
      {
        _id: '60cc250c47f7e10015fd7a9a',
        courseCode: 'PHILOSOP 2082',
        shortName:
          'Philosophy 2082F/G - INTRODUCTION TO THE PHILOSOPHY OF FOOD',
        courseName:
          'Philosophy 2082F/G - INTRODUCTION TO THE PHILOSOPHY OF FOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250c47f7e10015fd7a9a',
      },
      {
        _id: '60cc250d47f7e10015fd7a9c',
        courseCode: 'PHILOSOP 2083',
        shortName: 'Philosophy 2083F/G - TERRORISM',
        courseName: 'Philosophy 2083F/G - TERRORISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250d47f7e10015fd7a9c',
      },
      {
        _id: '60cc250d47f7e10015fd7a9e',
        courseCode: 'PHILOSOP 2084',
        shortName: 'Philosophy 2084A/B - THE MEANING OF LIFE',
        courseName: 'Philosophy 2084A/B - THE MEANING OF LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250d47f7e10015fd7a9e',
      },
      {
        _id: '60cc250d47f7e10015fd7aa0',
        courseCode: 'PHILOSOP 2091',
        shortName: 'Philosophy 2091F/G - PHILOSOPHY IN LITERATURE',
        courseName: 'Philosophy 2091F/G - PHILOSOPHY IN LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250d47f7e10015fd7aa0',
      },
      {
        _id: '60cc250d47f7e10015fd7aa2',
        courseCode: 'PHILOSOP 2092',
        shortName: 'Philosophy 2092F/G - PHILOSOPHY AND POP CULTURE',
        courseName: 'Philosophy 2092F/G - PHILOSOPHY AND POP CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250d47f7e10015fd7aa2',
      },
      {
        _id: '60cc250d47f7e10015fd7aa4',
        courseCode: 'PHILOSOP 2111',
        shortName: 'Philosophy 2111F/G - ASIAN PHILOSOPHY',
        courseName: 'Philosophy 2111F/G - ASIAN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250d47f7e10015fd7aa4',
      },
      {
        _id: '60cc250e47f7e10015fd7aa6',
        courseCode: 'PHILOSOP 2112',
        shortName: 'Philosophy 2112F/G - CHINESE PHILOSOPHY',
        courseName: 'Philosophy 2112F/G - CHINESE PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250e47f7e10015fd7aa6',
      },
      {
        _id: '60cc250e47f7e10015fd7aa8',
        courseCode: 'PHILOSOP 2130',
        shortName: 'Philosophy 2130A/B - INTRODUCTION TO EXISTENTIALISM',
        courseName: 'Philosophy 2130A/B - INTRODUCTION TO EXISTENTIALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250e47f7e10015fd7aa8',
      },
      {
        _id: '60cc250e47f7e10015fd7aaa',
        courseCode: 'PHILOSOP 2200',
        shortName: 'Philosophy 2200F/G - ANCIENT PHILOSOPHY',
        courseName: 'Philosophy 2200F/G - ANCIENT PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250e47f7e10015fd7aaa',
      },
      {
        _id: '60cc250e47f7e10015fd7aac',
        courseCode: 'PHILOSOP 2201',
        shortName: 'Philosophy 2201F/G - INTRODUCTION TO MEDIEVAL PHILOSOPHY',
        courseName: 'Philosophy 2201F/G - INTRODUCTION TO MEDIEVAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250e47f7e10015fd7aac',
      },
      {
        _id: '60cc250f47f7e10015fd7aae',
        courseCode: 'PHILOSOP 2202',
        shortName: 'Philosophy 2202F/G - EARLY MODERN PHILOSOPHY',
        courseName: 'Philosophy 2202F/G - EARLY MODERN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250f47f7e10015fd7aae',
      },
      {
        _id: '60cc250f47f7e10015fd7ab0',
        courseCode: 'PHILOSOP 2203',
        shortName: 'Philosophy 2203 - HISTORY OF SCIENTIFIC THOUGHT',
        courseName: 'Philosophy 2203 - HISTORY OF SCIENTIFIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc250f47f7e10015fd7ab0',
      },
      {
        _id: '60cc251047f7e10015fd7ab2',
        courseCode: 'PHILOSOP 2205',
        shortName: 'Philosophy 2205W/X - ACIENT AND MEDIEVAL PHILOSOPHY',
        courseName: 'Philosophy 2205W/X - ACIENT AND MEDIEVAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251047f7e10015fd7ab2',
      },
      {
        _id: '60cc251047f7e10015fd7ab4',
        courseCode: 'PHILOSOP 2206',
        shortName: 'Philosophy 2206W/X - MODERN AND CONTEMPORARY PHILOSOPHY',
        courseName: 'Philosophy 2206W/X - MODERN AND CONTEMPORARY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251047f7e10015fd7ab4',
      },
      {
        _id: '60cc251047f7e10015fd7ab6',
        courseCode: 'PHILOSOP 2208',
        shortName:
          'Philosophy 2208E - INTRODUCTION TO SOCIAL AND POLITICAL THOUGHT',
        courseName:
          'Philosophy 2208E - INTRODUCTION TO SOCIAL AND POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251047f7e10015fd7ab6',
      },
      {
        _id: '60cc251047f7e10015fd7ab8',
        courseCode: 'PHILOSOP 2214',
        shortName: 'Philosophy 2214 - THOMISTIC PHILOSOPHY I',
        courseName: 'Philosophy 2214 - THOMISTIC PHILOSOPHY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251047f7e10015fd7ab8',
      },
      {
        _id: '60cc251147f7e10015fd7aba',
        courseCode: 'PHILOSOP 2219',
        shortName: 'Philosophy 2219E - CONTEMPORARY PHILOSOPHY',
        courseName: 'Philosophy 2219E - CONTEMPORARY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251147f7e10015fd7aba',
      },
      {
        _id: '60cc251147f7e10015fd7abc',
        courseCode: 'PHILOSOP 2222',
        shortName: 'Philosophy 2222E - ARISTOTELIAN LOGIC',
        courseName: 'Philosophy 2222E - ARISTOTELIAN LOGIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251147f7e10015fd7abc',
      },
      {
        _id: '60cc251147f7e10015fd7abe',
        courseCode: 'PHILOSOP 2225',
        shortName: 'Philosophy 2225F/G - INTRODUCTION TO CHINESE PHILOSOPHY',
        courseName: 'Philosophy 2225F/G - INTRODUCTION TO CHINESE PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251147f7e10015fd7abe',
      },
      {
        _id: '60cc251147f7e10015fd7ac0',
        courseCode: 'PHILOSOP 2226',
        shortName: 'Philosophy 2226F/G - INTRODUCTION TO JAPANESE PHILOSOPHY',
        courseName: 'Philosophy 2226F/G - INTRODUCTION TO JAPANESE PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251147f7e10015fd7ac0',
      },
      {
        _id: '60cc251247f7e10015fd7ac2',
        courseCode: 'PHILOSOP 2227',
        shortName: 'Philosophy 2227F/G - INTRODUCTION TO INDIGENOUS PHILOSOPHY',
        courseName:
          'Philosophy 2227F/G - INTRODUCTION TO INDIGENOUS PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251247f7e10015fd7ac2',
      },
      {
        _id: '60cc251247f7e10015fd7ac4',
        courseCode: 'PHILOSOP 2230',
        shortName: 'Philosophy 2230F/G - PHILOSOPHY AND THE ARTS',
        courseName: 'Philosophy 2230F/G - PHILOSOPHY AND THE ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251247f7e10015fd7ac4',
      },
      {
        _id: '60cc251247f7e10015fd7ac6',
        courseCode: 'PHILOSOP 2235',
        shortName:
          'Philosophy 2235F/G - THE REEL AND THE REAL: PHILOSOPHY OF FILM',
        courseName:
          'Philosophy 2235F/G - THE REEL AND THE REAL: PHILOSOPHY OF FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251247f7e10015fd7ac6',
      },
      {
        _id: '60cc251247f7e10015fd7ac8',
        courseCode: 'PHILOSOP 2240',
        shortName: 'Philosophy 2240F/G - COMPARATIVE PHILOSOPHY I',
        courseName: 'Philosophy 2240F/G - COMPARATIVE PHILOSOPHY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251247f7e10015fd7ac8',
      },
      {
        _id: '60cc251247f7e10015fd7aca',
        courseCode: 'PHILOSOP 2241',
        shortName: 'Philosophy 2241F/G - COMPARATIVE PHILOSOPHY II',
        courseName: 'Philosophy 2241F/G - COMPARATIVE PHILOSOPHY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251247f7e10015fd7aca',
      },
      {
        _id: '60cc251347f7e10015fd7acc',
        courseCode: 'PHILOSOP 2242',
        shortName: 'Philosophy 2242F/G - ENVIRONMENTAL ETHICS AND JUSTICE',
        courseName: 'Philosophy 2242F/G - ENVIRONMENTAL ETHICS AND JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251347f7e10015fd7acc',
      },
      {
        _id: '60cc251347f7e10015fd7ace',
        courseCode: 'PHILOSOP 2243',
        shortName: 'Philosophy 2243F/G - ETHICS IN SCIENCE AND TECHNOLOGY',
        courseName: 'Philosophy 2243F/G - ETHICS IN SCIENCE AND TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251347f7e10015fd7ace',
      },
      {
        _id: '60cc251347f7e10015fd7ad0',
        courseCode: 'PHILOSOP 2244',
        shortName: 'Philosophy 2244F/G - GLOBAL SOCIO-POLITICAL ETHICS',
        courseName: 'Philosophy 2244F/G - GLOBAL SOCIO-POLITICAL ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251347f7e10015fd7ad0',
      },
      {
        _id: '60cc251347f7e10015fd7ad2',
        courseCode: 'PHILOSOP 2245',
        shortName: 'Philosophy 2245F/G - DATA SCIENCE ETHICS',
        courseName: 'Philosophy 2245F/G - DATA SCIENCE ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251347f7e10015fd7ad2',
      },
      {
        _id: '60cc251447f7e10015fd7ad4',
        courseCode: 'PHILOSOP 2250',
        shortName: 'Philosophy 2250 - INTRODUCTION TO LOGIC',
        courseName: 'Philosophy 2250 - INTRODUCTION TO LOGIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251447f7e10015fd7ad4',
      },
      {
        _id: '60cc251447f7e10015fd7ad6',
        courseCode: 'PHILOSOP 2251',
        shortName: 'Philosophy 2251F/G - CONCEPTUAL DEVELOPMENT OF MATHEMATICS',
        courseName:
          'Philosophy 2251F/G - CONCEPTUAL DEVELOPMENT OF MATHEMATICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251447f7e10015fd7ad6',
      },
      {
        _id: '60cc251447f7e10015fd7ad8',
        courseCode: 'PHILOSOP 2252',
        shortName: 'Philosophy 2252W/X - INTRODUCTION TO LOGIC (ACCELERATED)',
        courseName: 'Philosophy 2252W/X - INTRODUCTION TO LOGIC (ACCELERATED)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251447f7e10015fd7ad8',
      },
      {
        _id: '60cc251447f7e10015fd7ada',
        courseCode: 'PHILOSOP 2253',
        shortName: 'Philosophy 2253A/B - INTRODUCTION TO DECISION ANALYSIS',
        courseName: 'Philosophy 2253A/B - INTRODUCTION TO DECISION ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251447f7e10015fd7ada',
      },
      {
        _id: '60cc251547f7e10015fd7adc',
        courseCode: 'PHILOSOP 2254',
        shortName: 'Philosophy 2254A/B - INTRODUCTION TO LOGIC',
        courseName: 'Philosophy 2254A/B - INTRODUCTION TO LOGIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251547f7e10015fd7adc',
      },
      {
        _id: '60cc251547f7e10015fd7ade',
        courseCode: 'PHILOSOP 2260',
        shortName:
          'Philosophy 2260F/G - INTRODUCTION TO THE PHILOSOPHY OF LANGUAGE',
        courseName:
          'Philosophy 2260F/G - INTRODUCTION TO THE PHILOSOPHY OF LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251547f7e10015fd7ade',
      },
      {
        _id: '60cc251547f7e10015fd7ae0',
        courseCode: 'PHILOSOP 2263',
        shortName: 'Philosophy 2263F/G - PHILOSOPHY OF RISK',
        courseName: 'Philosophy 2263F/G - PHILOSOPHY OF RISK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251547f7e10015fd7ae0',
      },
      {
        _id: '60cc251647f7e10015fd7ae2',
        courseCode: 'PHILOSOP 2264',
        shortName: 'Philosophy 2264F/G - PHILOSOPHY OF MONEY',
        courseName: 'Philosophy 2264F/G - PHILOSOPHY OF MONEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251647f7e10015fd7ae2',
      },
      {
        _id: '60cc251647f7e10015fd7ae4',
        courseCode: 'PHILOSOP 2265',
        shortName: 'Philosophy 2265A/B - TALKING PHILOSOPHY',
        courseName: 'Philosophy 2265A/B - TALKING PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251647f7e10015fd7ae4',
      },
      {
        _id: '60cc251647f7e10015fd7ae6',
        courseCode: 'PHILOSOP 2268',
        shortName: 'Philosophy 2268F/G - CYBERETHICS IN CYBERSPACE',
        courseName: 'Philosophy 2268F/G - CYBERETHICS IN CYBERSPACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251647f7e10015fd7ae6',
      },
      {
        _id: '60cc251647f7e10015fd7ae8',
        courseCode: 'PHILOSOP 2270',
        shortName: 'Philosophy 2270 - CANADIAN JUDICIAL THINKING',
        courseName: 'Philosophy 2270 - CANADIAN JUDICIAL THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251647f7e10015fd7ae8',
      },
      {
        _id: '60cc251647f7e10015fd7aea',
        courseCode: 'PHILOSOP 2272',
        shortName: 'Philosophy 2272F/G - BIOETHICS AND DISABILITY',
        courseName: 'Philosophy 2272F/G - BIOETHICS AND DISABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251647f7e10015fd7aea',
      },
      {
        _id: '60cc251747f7e10015fd7aec',
        courseCode: 'PHILOSOP 2293',
        shortName:
          'Philosophy 2293A/B - CONDITIONS FOR ANALYTICAL THINKING AND DECISION-MAKING',
        courseName:
          'Philosophy 2293A/B - CONDITIONS FOR ANALYTICAL THINKING AND DECISION-MAKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251747f7e10015fd7aec',
      },
      {
        _id: '60cc251747f7e10015fd7aee',
        courseCode: 'PHILOSOP 2300',
        shortName: 'Philosophy 2300F/G - PHILOSOPHY OF SCIENCE',
        courseName: 'Philosophy 2300F/G - PHILOSOPHY OF SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251747f7e10015fd7aee',
      },
      {
        _id: '60cc251747f7e10015fd7af0',
        courseCode: 'PHILOSOP 2310',
        shortName: 'Philosophy 2310F/G - PHILOSOPHY OF MODERN PHYSICS',
        courseName: 'Philosophy 2310F/G - PHILOSOPHY OF MODERN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251747f7e10015fd7af0',
      },
      {
        _id: '60cc251847f7e10015fd7af2',
        courseCode: 'PHILOSOP 2320',
        shortName: 'Philosophy 2320F/G - PHILOSOPHY FOR INTEGRATED SCIENCE',
        courseName: 'Philosophy 2320F/G - PHILOSOPHY FOR INTEGRATED SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251847f7e10015fd7af2',
      },
      {
        _id: '60cc251847f7e10015fd7af4',
        courseCode: 'PHILOSOP 2350',
        shortName: 'Philosophy 2350F/G - THE DARWINIAN REVOLUTION',
        courseName: 'Philosophy 2350F/G - THE DARWINIAN REVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251847f7e10015fd7af4',
      },
      {
        _id: '60cc251847f7e10015fd7af6',
        courseCode: 'PHILOSOP 2355',
        shortName:
          'Philosophy 2355F/G - SUSTAINABILITY: A PHILOSOPHICAL PERSPECTIVE',
        courseName:
          'Philosophy 2355F/G - SUSTAINABILITY: A PHILOSOPHICAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251847f7e10015fd7af6',
      },
      {
        _id: '60cc251847f7e10015fd7af8',
        courseCode: 'PHILOSOP 2356',
        shortName: 'Philosophy 2356F/G - PHILOSOPHY AND CLIMATE CHANGE',
        courseName: 'Philosophy 2356F/G - PHILOSOPHY AND CLIMATE CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251847f7e10015fd7af8',
      },
      {
        _id: '60cc251847f7e10015fd7afa',
        courseCode: 'PHILOSOP 2370',
        shortName: 'Philosophy 2370F/G - SCIENCE, TECHNOLOGY AND SOCIETY',
        courseName: 'Philosophy 2370F/G - SCIENCE, TECHNOLOGY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251847f7e10015fd7afa',
      },
      {
        _id: '60cc251947f7e10015fd7afc',
        courseCode: 'PHILOSOP 2400',
        shortName: 'Philosophy 2400F/G - INTRODUCTION TO PHILOSOPHY OF MIND',
        courseName: 'Philosophy 2400F/G - INTRODUCTION TO PHILOSOPHY OF MIND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251947f7e10015fd7afc',
      },
      {
        _id: '60cc251947f7e10015fd7afe',
        courseCode: 'PHILOSOP 2410',
        shortName:
          'Philosophy 2410F/G - ISSUES IN THE PHILOSOPHY OF THE EMOTIONS',
        courseName:
          'Philosophy 2410F/G - ISSUES IN THE PHILOSOPHY OF THE EMOTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251947f7e10015fd7afe',
      },
      {
        _id: '60cc251947f7e10015fd7b00',
        courseCode: 'PHILOSOP 2500',
        shortName:
          'Philosophy 2500F/G - INTRODUCTION TO THE THEORY OF KNOWLEDGE',
        courseName:
          'Philosophy 2500F/G - INTRODUCTION TO THE THEORY OF KNOWLEDGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251947f7e10015fd7b00',
      },
      {
        _id: '60cc251a47f7e10015fd7b02',
        courseCode: 'PHILOSOP 2550',
        shortName: 'Philosophy 2550F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2550F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251a47f7e10015fd7b02',
      },
      {
        _id: '60cc251a47f7e10015fd7b04',
        courseCode: 'PHILOSOP 2552',
        shortName: 'Philosophy 2552F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2552F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251a47f7e10015fd7b04',
      },
      {
        _id: '60cc251a47f7e10015fd7b06',
        courseCode: 'PHILOSOP 2553',
        shortName: 'Philosophy 2553F/G - FORERUNNERS OF EXISTENTIALISM',
        courseName: 'Philosophy 2553F/G - FORERUNNERS OF EXISTENTIALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251a47f7e10015fd7b06',
      },
      {
        _id: '60cc251b47f7e10015fd7b08',
        courseCode: 'PHILOSOP 2555',
        shortName:
          'Philosophy 2555F/G - PHENOMENOLOGY AND EXISTENTIAL PHILOSOPHY',
        courseName:
          'Philosophy 2555F/G - PHENOMENOLOGY AND EXISTENTIAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251b47f7e10015fd7b08',
      },
      {
        _id: '60cc251b47f7e10015fd7b0a',
        courseCode: 'PHILOSOP 2557',
        shortName: 'Philosophy 2557F/G - EXISTENTIALISM',
        courseName: 'Philosophy 2557F/G - EXISTENTIALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251b47f7e10015fd7b0a',
      },
      {
        _id: '60cc251b47f7e10015fd7b0c',
        courseCode: 'PHILOSOP 2601',
        shortName: 'Philosophy 2601F/G - METAPHYSICS',
        courseName: 'Philosophy 2601F/G - METAPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251b47f7e10015fd7b0c',
      },
      {
        _id: '60cc251b47f7e10015fd7b0e',
        courseCode: 'PHILOSOP 2630',
        shortName: 'Philosophy 2630F/G - FEMINIST PHILOSOPHY',
        courseName: 'Philosophy 2630F/G - FEMINIST PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251b47f7e10015fd7b0e',
      },
      {
        _id: '60cc251c47f7e10015fd7b10',
        courseCode: 'PHILOSOP 2660',
        shortName: 'Philosophy 2660E - PHILOSOPHY OF RELIGION',
        courseName: 'Philosophy 2660E - PHILOSOPHY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251c47f7e10015fd7b10',
      },
      {
        _id: '60cc251c47f7e10015fd7b12',
        courseCode: 'PHILOSOP 2661',
        shortName: 'Philosophy 2661F/G - PHILOSOPHY OF RELIGION',
        courseName: 'Philosophy 2661F/G - PHILOSOPHY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251c47f7e10015fd7b12',
      },
      {
        _id: '60cc251d47f7e10015fd7b14',
        courseCode: 'PHILOSOP 2662',
        shortName: 'Philosophy 2662F/G - TOPICS IN THE PHILOSOPHY OF RELIGION',
        courseName: 'Philosophy 2662F/G - TOPICS IN THE PHILOSOPHY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251d47f7e10015fd7b14',
      },
      {
        _id: '60cc251d47f7e10015fd7b16',
        courseCode: 'PHILOSOP 2664',
        shortName: 'Philosophy 2664F/G - INTRODUCTION TO BUDDHIST PHILOSOPHY',
        courseName: 'Philosophy 2664F/G - INTRODUCTION TO BUDDHIST PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251d47f7e10015fd7b16',
      },
      {
        _id: '60cc251d47f7e10015fd7b19',
        courseCode: 'PHILOSOP 2666',
        shortName: 'Philosophy 2666F/G - THE MEANING OF LIFE',
        courseName: 'Philosophy 2666F/G - THE MEANING OF LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251d47f7e10015fd7b19',
      },
      {
        _id: '60cc251e47f7e10015fd7b1b',
        courseCode: 'PHILOSOP 2667',
        shortName:
          'Philosophy 2667F/G - INTRODUCTION TO JEWISH PHILOSOPHY 2: EARLY MODERN TO CONTEMPORARY',
        courseName:
          'Philosophy 2667F/G - INTRODUCTION TO JEWISH PHILOSOPHY 2: EARLY MODERN TO CONTEMPORARY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251e47f7e10015fd7b1b',
      },
      {
        _id: '60cc251e47f7e10015fd7b1d',
        courseCode: 'PHILOSOP 2668',
        shortName: 'Philosophy 2668F/G - INTRODUCTION TO ISLAMIC THOUGHT',
        courseName: 'Philosophy 2668F/G - INTRODUCTION TO ISLAMIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251e47f7e10015fd7b1d',
      },
      {
        _id: '60cc251e47f7e10015fd7b1f',
        courseCode: 'PHILOSOP 2669',
        shortName: 'Philosophy 2669F/G - INTRODUCTION TO CATHOLIC THOUGHT',
        courseName: 'Philosophy 2669F/G - INTRODUCTION TO CATHOLIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251e47f7e10015fd7b1f',
      },
      {
        _id: '60cc251e47f7e10015fd7b21',
        courseCode: 'PHILOSOP 2670',
        shortName: 'Philosophy 2670F/G - PHILOSOPHY OF HISTORY',
        courseName: 'Philosophy 2670F/G - PHILOSOPHY OF HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251e47f7e10015fd7b21',
      },
      {
        _id: '60cc251f47f7e10015fd7b23',
        courseCode: 'PHILOSOP 2700',
        shortName:
          'Philosophy 2700F/G - INTRODUCTION TO ETHICS AND VALUE THEORY',
        courseName:
          'Philosophy 2700F/G - INTRODUCTION TO ETHICS AND VALUE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251f47f7e10015fd7b23',
      },
      {
        _id: '60cc251f47f7e10015fd7b25',
        courseCode: 'PHILOSOP 2710',
        shortName: 'Philosophy 2710F/G - REPRODUCTIVE ETHICS',
        courseName: 'Philosophy 2710F/G - REPRODUCTIVE ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251f47f7e10015fd7b25',
      },
      {
        _id: '60cc251f47f7e10015fd7b27',
        courseCode: 'PHILOSOP 2715',
        shortName: 'Philosophy 2715F/G - HEALTH CARE ETHICS',
        courseName: 'Philosophy 2715F/G - HEALTH CARE ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc251f47f7e10015fd7b27',
      },
      {
        _id: '60cc252047f7e10015fd7b29',
        courseCode: 'PHILOSOP 2720',
        shortName:
          'Philosophy 2720F/G - THE ETHICS OF PROFESSIONAL RELATIONSHIPS',
        courseName:
          'Philosophy 2720F/G - THE ETHICS OF PROFESSIONAL RELATIONSHIPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252047f7e10015fd7b29',
      },
      {
        _id: '60cc252047f7e10015fd7b2b',
        courseCode: 'PHILOSOP 2730',
        shortName: 'Philosophy 2730F/G - MEDIA ETHICS',
        courseName: 'Philosophy 2730F/G - MEDIA ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252047f7e10015fd7b2b',
      },
      {
        _id: '60cc252047f7e10015fd7b2d',
        courseCode: 'PHILOSOP 2750',
        shortName: 'Philosophy 2750F/G - ETHICS IN ACTION',
        courseName: 'Philosophy 2750F/G - ETHICS IN ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252047f7e10015fd7b2d',
      },
      {
        _id: '60cc252047f7e10015fd7b2f',
        courseCode: 'PHILOSOP 2760',
        shortName: 'Philosophy 2760F/G - CIVIL DISOBEDIENCE AND SOCIAL PROTEST',
        courseName:
          'Philosophy 2760F/G - CIVIL DISOBEDIENCE AND SOCIAL PROTEST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252047f7e10015fd7b2f',
      },
      {
        _id: '60cc252147f7e10015fd7b31',
        courseCode: 'PHILOSOP 2800',
        shortName: 'Philosophy 2800F/G - THE HISTORY OF POLITICAL PHILOSOPHY',
        courseName: 'Philosophy 2800F/G - THE HISTORY OF POLITICAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252147f7e10015fd7b31',
      },
      {
        _id: '60cc252147f7e10015fd7b33',
        courseCode: 'PHILOSOP 2801',
        shortName: 'Philosophy 2801F/G - CONTEMPORARY POLITICAL PHILOSOPHY',
        courseName: 'Philosophy 2801F/G - CONTEMPORARY POLITICAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252147f7e10015fd7b33',
      },
      {
        _id: '60cc252247f7e10015fd7b35',
        courseCode: 'PHILOSOP 2810',
        shortName: 'Philosophy 2810F/G - GLOBAL JUSTICE AND HUMAN RIGHTS',
        courseName: 'Philosophy 2810F/G - GLOBAL JUSTICE AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252247f7e10015fd7b35',
      },
      {
        _id: '60cc252247f7e10015fd7b37',
        courseCode: 'PHILOSOP 2812',
        shortName: 'Philosophy 2812F/G - CULTURES, VALUES, AND HUMAN RIGHTS',
        courseName: 'Philosophy 2812F/G - CULTURES, VALUES, AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252247f7e10015fd7b37',
      },
      {
        _id: '60cc252247f7e10015fd7b39',
        courseCode: 'PHILOSOP 2813',
        shortName: 'Philosophy 2813F/G - NATIONALISM AND POLITICAL MORALITY',
        courseName: 'Philosophy 2813F/G - NATIONALISM AND POLITICAL MORALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252247f7e10015fd7b39',
      },
      {
        _id: '60cc252247f7e10015fd7b3b',
        courseCode: 'PHILOSOP 2820',
        shortName: 'Philosophy 2820F/G - PHILOSOPHY OF WAR AND PEACE',
        courseName: 'Philosophy 2820F/G - PHILOSOPHY OF WAR AND PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252247f7e10015fd7b3b',
      },
      {
        _id: '60cc252247f7e10015fd7b3d',
        courseCode: 'PHILOSOP 2821',
        shortName: 'Philosophy 2821F/G - PHILOSOPHY OF LAW',
        courseName: 'Philosophy 2821F/G - PHILOSOPHY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252247f7e10015fd7b3d',
      },
      {
        _id: '60cc252347f7e10015fd7b3f',
        courseCode: 'PHILOSOP 2822',
        shortName: 'Philosophy 2822F/G - TOPICS IN PHILOSOPHY OF LAW',
        courseName: 'Philosophy 2822F/G - TOPICS IN PHILOSOPHY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252347f7e10015fd7b3f',
      },
      {
        _id: '60cc252347f7e10015fd7b41',
        courseCode: 'PHILOSOP 2991',
        shortName: 'Philosophy 2991A/B - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2991A/B - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252347f7e10015fd7b41',
      },
      {
        _id: '60cc252447f7e10015fd7b43',
        courseCode: 'PHILOSOP 2992',
        shortName: 'Philosophy 2992A/B - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2992A/B - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252447f7e10015fd7b43',
      },
      {
        _id: '60cc252447f7e10015fd7b45',
        courseCode: 'PHILOSOP 2993',
        shortName: 'Philosophy 2993A/B - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2993A/B - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252447f7e10015fd7b45',
      },
      {
        _id: '60cc252447f7e10015fd7b47',
        courseCode: 'PHILOSOP 2994',
        shortName: 'Philosophy 2994E - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2994E - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252447f7e10015fd7b47',
      },
      {
        _id: '60cc252447f7e10015fd7b49',
        courseCode: 'PHILOSOP 2996',
        shortName: 'Philosophy 2996F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2996F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252447f7e10015fd7b49',
      },
      {
        _id: '60cc252547f7e10015fd7b4b',
        courseCode: 'PHILOSOP 2997',
        shortName: 'Philosophy 2997F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2997F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252547f7e10015fd7b4b',
      },
      {
        _id: '60cc252647f7e10015fd7b4d',
        courseCode: 'PHILOSOP 2998',
        shortName: 'Philosophy 2998F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2998F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252647f7e10015fd7b4d',
      },
      {
        _id: '60cc252647f7e10015fd7b4f',
        courseCode: 'PHILOSOP 2999',
        shortName: 'Philosophy 2999F/G - SPECIAL TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 2999F/G - SPECIAL TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252647f7e10015fd7b4f',
      },
      {
        _id: '60cc252747f7e10015fd7b51',
        courseCode: 'PHILOSOP 3001',
        shortName: 'Philosophy 3001F/G - TOPICS IN ASIAN PHILOSOPHY',
        courseName: 'Philosophy 3001F/G - TOPICS IN ASIAN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252747f7e10015fd7b51',
      },
      {
        _id: '60cc252747f7e10015fd7b53',
        courseCode: 'PHILOSOP 3003',
        shortName: 'Philosophy 3003F/G - PLATO',
        courseName: 'Philosophy 3003F/G - PLATO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252747f7e10015fd7b53',
      },
      {
        _id: '60cc252847f7e10015fd7b55',
        courseCode: 'PHILOSOP 3006',
        shortName: 'Philosophy 3006F/G - ARISTOTLE',
        courseName: 'Philosophy 3006F/G - ARISTOTLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252847f7e10015fd7b55',
      },
      {
        _id: '60cc252847f7e10015fd7b57',
        courseCode: 'PHILOSOP 3007',
        shortName: 'Philosophy 3007F/G - TOPICS IN ANCIENT PHILOSOPHY',
        courseName: 'Philosophy 3007F/G - TOPICS IN ANCIENT PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252847f7e10015fd7b57',
      },
      {
        _id: '60cc252847f7e10015fd7b59',
        courseCode: 'PHILOSOP 3008',
        shortName: 'Philosophy 3008F/G - TOPICS IN ANCIENT PHILOSOPHY',
        courseName: 'Philosophy 3008F/G - TOPICS IN ANCIENT PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252847f7e10015fd7b59',
      },
      {
        _id: '60cc252947f7e10015fd7b5b',
        courseCode: 'PHILOSOP 3012',
        shortName: 'Philosophy 3012F/G - MEDIEVAL PHILOSOPHY',
        courseName: 'Philosophy 3012F/G - MEDIEVAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252947f7e10015fd7b5b',
      },
      {
        _id: '60cc252947f7e10015fd7b5d',
        courseCode: 'PHILOSOP 3013',
        shortName: 'Philosophy 3013E - THOMISTIC PHILOSOPHY II',
        courseName: 'Philosophy 3013E - THOMISTIC PHILOSOPHY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252947f7e10015fd7b5d',
      },
      {
        _id: '60cc252947f7e10015fd7b5f',
        courseCode: 'PHILOSOP 3014',
        shortName: 'Philosophy 3014F/G - LATER MEDIEVAL PHILOSOPHY',
        courseName: 'Philosophy 3014F/G - LATER MEDIEVAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252947f7e10015fd7b5f',
      },
      {
        _id: '60cc252947f7e10015fd7b61',
        courseCode: 'PHILOSOP 3020',
        shortName:
          'Philosophy 3020F/G - RENAISSANCE AND REFORMATION PHILOSOPHY',
        courseName:
          'Philosophy 3020F/G - RENAISSANCE AND REFORMATION PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252947f7e10015fd7b61',
      },
      {
        _id: '60cc252a47f7e10015fd7b63',
        courseCode: 'PHILOSOP 3022',
        shortName: 'Philosophy 3022F/G - CARTESIANISM AND ITS CRITICS',
        courseName: 'Philosophy 3022F/G - CARTESIANISM AND ITS CRITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252a47f7e10015fd7b63',
      },
      {
        _id: '60cc252a47f7e10015fd7b65',
        courseCode: 'PHILOSOP 3023',
        shortName: 'Philosophy 3023F/G - SPINOZA',
        courseName: 'Philosophy 3023F/G - SPINOZA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252a47f7e10015fd7b65',
      },
      {
        _id: '60cc252a47f7e10015fd7b67',
        courseCode: 'PHILOSOP 3024',
        shortName: 'Philosophy 3024F/G - LEIBNIZ',
        courseName: 'Philosophy 3024F/G - LEIBNIZ',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252a47f7e10015fd7b67',
      },
      {
        _id: '60cc252b47f7e10015fd7b69',
        courseCode: 'PHILOSOP 3025',
        shortName: 'Philosophy 3025F/G - MILL AND MARX',
        courseName: 'Philosophy 3025F/G - MILL AND MARX',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252b47f7e10015fd7b69',
      },
      {
        _id: '60cc252b47f7e10015fd7b6b',
        courseCode: 'PHILOSOP 3026',
        shortName: 'Philosophy 3026F/G - LOCKE',
        courseName: 'Philosophy 3026F/G - LOCKE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252b47f7e10015fd7b6b',
      },
      {
        _id: '60cc252b47f7e10015fd7b6d',
        courseCode: 'PHILOSOP 3027',
        shortName: 'Philosophy 3027F/G - BERKELEY',
        courseName: 'Philosophy 3027F/G - BERKELEY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252b47f7e10015fd7b6d',
      },
      {
        _id: '60cc252c47f7e10015fd7b6f',
        courseCode: 'PHILOSOP 3028',
        shortName: 'Philosophy 3028F/G - HUME',
        courseName: 'Philosophy 3028F/G - HUME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252c47f7e10015fd7b6f',
      },
      {
        _id: '60cc252c47f7e10015fd7b71',
        courseCode: 'PHILOSOP 3029',
        shortName: 'Philosophy 3029F/G - THOMAS REID',
        courseName: 'Philosophy 3029F/G - THOMAS REID',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252c47f7e10015fd7b71',
      },
      {
        _id: '60cc252d47f7e10015fd7b73',
        courseCode: 'PHILOSOP 3030',
        shortName: 'Philosophy 3030F/G - NIETZSCHE',
        courseName: 'Philosophy 3030F/G - NIETZSCHE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252d47f7e10015fd7b73',
      },
      {
        _id: '60cc252d47f7e10015fd7b75',
        courseCode: 'PHILOSOP 3031',
        shortName: 'Philosophy 3031F/G - WOMEN IN EARLY MODERN PHILOSOPHY',
        courseName: 'Philosophy 3031F/G - WOMEN IN EARLY MODERN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252d47f7e10015fd7b75',
      },
      {
        _id: '60cc252d47f7e10015fd7b77',
        courseCode: 'PHILOSOP 3032',
        shortName: 'Philosophy 3032F/G - THE RATIONALIST TRADITION',
        courseName: 'Philosophy 3032F/G - THE RATIONALIST TRADITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252d47f7e10015fd7b77',
      },
      {
        _id: '60cc252d47f7e10015fd7b79',
        courseCode: 'PHILOSOP 3033',
        shortName: 'Philosophy 3033F/G - THE EMPIRICIST TRADITION',
        courseName: 'Philosophy 3033F/G - THE EMPIRICIST TRADITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252d47f7e10015fd7b79',
      },
      {
        _id: '60cc252e47f7e10015fd7b7b',
        courseCode: 'PHILOSOP 3034',
        shortName: 'Philosophy 3034F/G - 19TH CENTURY EUROPEAN PHILOSOPHY',
        courseName: 'Philosophy 3034F/G - 19TH CENTURY EUROPEAN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252e47f7e10015fd7b7b',
      },
      {
        _id: '60cc252e47f7e10015fd7b7d',
        courseCode: 'PHILOSOP 3040',
        shortName: 'Philosophy 3040F/G - ORIGINS OF ANALYTIC PHILOSOPHY',
        courseName: 'Philosophy 3040F/G - ORIGINS OF ANALYTIC PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252e47f7e10015fd7b7d',
      },
      {
        _id: '60cc252e47f7e10015fd7b7f',
        courseCode: 'PHILOSOP 3048',
        shortName: 'Philosophy 3048F/G - FROM HEGEL TO MARX',
        courseName: 'Philosophy 3048F/G - FROM HEGEL TO MARX',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252e47f7e10015fd7b7f',
      },
      {
        _id: '60cc252f47f7e10015fd7b81',
        courseCode: 'PHILOSOP 3050',
        shortName: 'Philosophy 3050F/G - RECENT CONTINENTAL PHILOSOPHY',
        courseName: 'Philosophy 3050F/G - RECENT CONTINENTAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252f47f7e10015fd7b81',
      },
      {
        _id: '60cc252f47f7e10015fd7b83',
        courseCode: 'PHILOSOP 3070',
        shortName: 'Philosophy 3070F/G - AUGUSTINE',
        courseName: 'Philosophy 3070F/G - AUGUSTINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252f47f7e10015fd7b83',
      },
      {
        _id: '60cc252f47f7e10015fd7b85',
        courseCode: 'PHILOSOP 3071',
        shortName: 'Philosophy 3071F/G - CONFUCIAN THOUGHT',
        courseName: 'Philosophy 3071F/G - CONFUCIAN THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252f47f7e10015fd7b85',
      },
      {
        _id: '60cc252f47f7e10015fd7b87',
        courseCode: 'PHILOSOP 3072',
        shortName:
          'Philosophy 3072F/G - BERNARD LONERGAN ON RELIGION AND CULTURE',
        courseName:
          'Philosophy 3072F/G - BERNARD LONERGAN ON RELIGION AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc252f47f7e10015fd7b87',
      },
      {
        _id: '60cc253047f7e10015fd7b89',
        courseCode: 'PHILOSOP 3075',
        shortName: 'Philosophy 3075F/G - TOPICS IN EARLY MODERN PHILOSOPHY',
        courseName: 'Philosophy 3075F/G - TOPICS IN EARLY MODERN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253047f7e10015fd7b89',
      },
      {
        _id: '60cc253047f7e10015fd7b8b',
        courseCode: 'PHILOSOP 3085',
        shortName: 'Philosophy 3085F/G - TOPICS IN MODERN PHILOSOPHY',
        courseName: 'Philosophy 3085F/G - TOPICS IN MODERN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253047f7e10015fd7b8b',
      },
      {
        _id: '60cc253047f7e10015fd7b8d',
        courseCode: 'PHILOSOP 3086',
        shortName: 'Philosophy 3086F/G - TOPICS IN MODERN PHILOSOPHY',
        courseName: 'Philosophy 3086F/G - TOPICS IN MODERN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253047f7e10015fd7b8d',
      },
      {
        _id: '60cc253047f7e10015fd7b8f',
        courseCode: 'PHILOSOP 3110',
        shortName: 'Philosophy 3110F/G - TOPICS IN THE HISTORY OF LOGIC',
        courseName: 'Philosophy 3110F/G - TOPICS IN THE HISTORY OF LOGIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253047f7e10015fd7b8f',
      },
      {
        _id: '60cc253147f7e10015fd7b91',
        courseCode: 'PHILOSOP 3170',
        shortName: 'Philosophy 3170F/G - TOPICS IN THE HISTORY OF ETHICS',
        courseName: 'Philosophy 3170F/G - TOPICS IN THE HISTORY OF ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253147f7e10015fd7b91',
      },
      {
        _id: '60cc253147f7e10015fd7b93',
        courseCode: 'PHILOSOP 3180',
        shortName:
          'Philosophy 3180F/G - TOPICS IN THE HISTORY OF POLITCAL AND LEGAL PHILOSOPHY',
        courseName:
          'Philosophy 3180F/G - TOPICS IN THE HISTORY OF POLITCAL AND LEGAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253147f7e10015fd7b93',
      },
      {
        _id: '60cc253147f7e10015fd7b95',
        courseCode: 'PHILOSOP 3201',
        shortName: 'Philosophy 3201A/B - SPECIAL TOPICS IN LOGICAL THEORY',
        courseName: 'Philosophy 3201A/B - SPECIAL TOPICS IN LOGICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253147f7e10015fd7b95',
      },
      {
        _id: '60cc253247f7e10015fd7b97',
        courseCode: 'PHILOSOP 3220',
        shortName: 'Philosophy 3220F/G - BIAS, RATIONALITY, AND OBJECTIVITY',
        courseName: 'Philosophy 3220F/G - BIAS, RATIONALITY, AND OBJECTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253247f7e10015fd7b97',
      },
      {
        _id: '60cc253247f7e10015fd7b99',
        courseCode: 'PHILOSOP 3230',
        shortName: 'Philosophy 3230E - EVIDENCE, PROBABILITY, AND FACT-FINDING',
        courseName:
          'Philosophy 3230E - EVIDENCE, PROBABILITY, AND FACT-FINDING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253247f7e10015fd7b99',
      },
      {
        _id: '60cc253247f7e10015fd7b9b',
        courseCode: 'PHILOSOP 3250',
        shortName: 'Philosophy 3250F/G - EXPERIMENTAL PHILOSOPHY',
        courseName: 'Philosophy 3250F/G - EXPERIMENTAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253247f7e10015fd7b9b',
      },
      {
        _id: '60cc253247f7e10015fd7b9d',
        courseCode: 'PHILOSOP 3260',
        shortName: 'Philosophy 3260F/G - THEORIES OF MEANING',
        courseName: 'Philosophy 3260F/G - THEORIES OF MEANING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253247f7e10015fd7b9d',
      },
      {
        _id: '60cc253347f7e10015fd7b9f',
        courseCode: 'PHILOSOP 3270',
        shortName: 'Philosophy 3270F/G - PHILOSOPHY AND LINGUISTICS',
        courseName: 'Philosophy 3270F/G - PHILOSOPHY AND LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253347f7e10015fd7b9f',
      },
      {
        _id: '60cc253347f7e10015fd7ba1',
        courseCode: 'PHILOSOP 3310',
        shortName:
          'Philosophy 3310F/G - THE PHYSICS AND PHILOSOPHY OF TIME AND CHANCE',
        courseName:
          'Philosophy 3310F/G - THE PHYSICS AND PHILOSOPHY OF TIME AND CHANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253347f7e10015fd7ba1',
      },
      {
        _id: '60cc253347f7e10015fd7ba3',
        courseCode: 'PHILOSOP 3320',
        shortName:
          'Philosophy 3320F/G - PHILOSPHICAL FOUNDATIONS OF QUANTUM MECHANICS',
        courseName:
          'Philosophy 3320F/G - PHILOSPHICAL FOUNDATIONS OF QUANTUM MECHANICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253347f7e10015fd7ba3',
      },
      {
        _id: '60cc253447f7e10015fd7ba5',
        courseCode: 'PHILOSOP 3325',
        shortName: 'Philosophy 3325F/G - SPECIAL TOPICS IN CHINESE PHILOSOPHY',
        courseName: 'Philosophy 3325F/G - SPECIAL TOPICS IN CHINESE PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253447f7e10015fd7ba5',
      },
      {
        _id: '60cc253447f7e10015fd7ba7',
        courseCode: 'PHILOSOP 3326',
        shortName: 'Philosophy 3326F/G - SPECIAL TOPICS IN JAPANESE PHILOSOPHY',
        courseName:
          'Philosophy 3326F/G - SPECIAL TOPICS IN JAPANESE PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253447f7e10015fd7ba7',
      },
      {
        _id: '60cc253447f7e10015fd7ba9',
        courseCode: 'PHILOSOP 3327',
        shortName:
          'Philosophy 3327F/G - SPECIAL TOPICS IN INDIGENOUS PHILOSOPHY',
        courseName:
          'Philosophy 3327F/G - SPECIAL TOPICS IN INDIGENOUS PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253447f7e10015fd7ba9',
      },
      {
        _id: '60cc253447f7e10015fd7bab',
        courseCode: 'PHILOSOP 3328',
        shortName: 'Philosophy 3328F/G - SPECIAL TOPICS IN ISLAMIC PHILOSOPHY',
        courseName: 'Philosophy 3328F/G - SPECIAL TOPICS IN ISLAMIC PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253447f7e10015fd7bab',
      },
      {
        _id: '60cc253547f7e10015fd7bad',
        courseCode: 'PHILOSOP 3330',
        shortName:
          'Philosophy 3330F/G - PHILOSOPHICAL FOUNDATIONS OF SPACETIME THEORIES',
        courseName:
          'Philosophy 3330F/G - PHILOSOPHICAL FOUNDATIONS OF SPACETIME THEORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253547f7e10015fd7bad',
      },
      {
        _id: '60cc253547f7e10015fd7baf',
        courseCode: 'PHILOSOP 3340',
        shortName:
          'Philosophy 3340F/G - PHILOSOPHICAL ISSUES IN EVOLUTIONARY BIOLOGY',
        courseName:
          'Philosophy 3340F/G - PHILOSOPHICAL ISSUES IN EVOLUTIONARY BIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253547f7e10015fd7baf',
      },
      {
        _id: '60cc253547f7e10015fd7bb1',
        courseCode: 'PHILOSOP 3341',
        shortName: 'Philosophy 3341F/G - PHILOSOPHY OF BIOLOGY FOR BIOLOGISTS',
        courseName: 'Philosophy 3341F/G - PHILOSOPHY OF BIOLOGY FOR BIOLOGISTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253547f7e10015fd7bb1',
      },
      {
        _id: '60cc253547f7e10015fd7bb3',
        courseCode: 'PHILOSOP 3343',
        shortName: 'Philosophy 3343F/G - SPECIAL TOPICS IN ETHICS',
        courseName: 'Philosophy 3343F/G - SPECIAL TOPICS IN ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253547f7e10015fd7bb3',
      },
      {
        _id: '60cc253647f7e10015fd7bb5',
        courseCode: 'PHILOSOP 3410',
        shortName: 'Philosophy 3410F/G - PHILOSOPHY OF MIND',
        courseName: 'Philosophy 3410F/G - PHILOSOPHY OF MIND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253647f7e10015fd7bb5',
      },
      {
        _id: '60cc253647f7e10015fd7bb7',
        courseCode: 'PHILOSOP 3420',
        shortName: 'Philosophy 3420F/G - PHILOSOPHY OF PSYCHOLOGY',
        courseName: 'Philosophy 3420F/G - PHILOSOPHY OF PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253647f7e10015fd7bb7',
      },
      {
        _id: '60cc253647f7e10015fd7bb9',
        courseCode: 'PHILOSOP 3430',
        shortName:
          'Philosophy 3430F/G - INTRODUCTION TO PHILOSOPHICAL ISSUES IN COGNITIVE SCIENCE',
        courseName:
          'Philosophy 3430F/G - INTRODUCTION TO PHILOSOPHICAL ISSUES IN COGNITIVE SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253647f7e10015fd7bb9',
      },
      {
        _id: '60cc253647f7e10015fd7bbb',
        courseCode: 'PHILOSOP 3435',
        shortName: 'Philosophy 3435F/G - MORAL PSYCHOLOGY',
        courseName: 'Philosophy 3435F/G - MORAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253647f7e10015fd7bbb',
      },
      {
        _id: '60cc253747f7e10015fd7bbd',
        courseCode: 'PHILOSOP 3440',
        shortName: 'Philosophy 3440F/G - PHILOSOPHY OF PERCEPTION',
        courseName: 'Philosophy 3440F/G - PHILOSOPHY OF PERCEPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253747f7e10015fd7bbd',
      },
      {
        _id: '60cc253747f7e10015fd7bbf',
        courseCode: 'PHILOSOP 3450',
        shortName: 'Philosophy 3450F/G - PHILOSOPHY OF NEUROSCIENE',
        courseName: 'Philosophy 3450F/G - PHILOSOPHY OF NEUROSCIENE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253747f7e10015fd7bbf',
      },
      {
        _id: '60cc253747f7e10015fd7bc1',
        courseCode: 'PHILOSOP 3501',
        shortName: 'Philosophy 3501F/G - EPISTEMOLOGY',
        courseName: 'Philosophy 3501F/G - EPISTEMOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253747f7e10015fd7bc1',
      },
      {
        _id: '60cc253847f7e10015fd7bc3',
        courseCode: 'PHILOSOP 3510',
        shortName: 'Philosophy 3510F/G - TRUTH AND PARADOX',
        courseName: 'Philosophy 3510F/G - TRUTH AND PARADOX',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253847f7e10015fd7bc3',
      },
      {
        _id: '60cc253847f7e10015fd7bc5',
        courseCode: 'PHILOSOP 3555',
        shortName: 'Philosophy 3555F/G - CONTINENTAL PHILOSOPHY',
        courseName: 'Philosophy 3555F/G - CONTINENTAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253847f7e10015fd7bc5',
      },
      {
        _id: '60cc253847f7e10015fd7bc7',
        courseCode: 'PHILOSOP 3560',
        shortName:
          'Philosophy 3560F/G - HUMAN RIGHTS IN SOCIAL POLITICAL THOUGHT',
        courseName:
          'Philosophy 3560F/G - HUMAN RIGHTS IN SOCIAL POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253847f7e10015fd7bc7',
      },
      {
        _id: '60cc253847f7e10015fd7bc9',
        courseCode: 'PHILOSOP 3561',
        shortName:
          'Philosophy 3561F/G - HUMAN RIGHTS IN SOCIAL POLITICAL THOUGHT',
        courseName:
          'Philosophy 3561F/G - HUMAN RIGHTS IN SOCIAL POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253847f7e10015fd7bc9',
      },
      {
        _id: '60cc253847f7e10015fd7bcb',
        courseCode: 'PHILOSOP 3562',
        shortName:
          'Philosophy 3562F/G - HUMAN RIGHTS IN SOCIAL POLITICAL THOUGHT',
        courseName:
          'Philosophy 3562F/G - HUMAN RIGHTS IN SOCIAL POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253847f7e10015fd7bcb',
      },
      {
        _id: '60cc253947f7e10015fd7bcd',
        courseCode: 'PHILOSOP 3601',
        shortName: 'Philosophy 3601F/G - METAPHYSICS',
        courseName: 'Philosophy 3601F/G - METAPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253947f7e10015fd7bcd',
      },
      {
        _id: '60cc253947f7e10015fd7bcf',
        courseCode: 'PHILOSOP 3620',
        shortName: 'Philosophy 3620F/G - CAUSALITY',
        courseName: 'Philosophy 3620F/G - CAUSALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253947f7e10015fd7bcf',
      },
      {
        _id: '60cc253947f7e10015fd7bd1',
        courseCode: 'PHILOSOP 3625',
        shortName: 'Philosophy 3625F/G - CAUSALITY AND FREEDOM OF  THE WILL',
        courseName: 'Philosophy 3625F/G - CAUSALITY AND FREEDOM OF  THE WILL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253947f7e10015fd7bd1',
      },
      {
        _id: '60cc253a47f7e10015fd7bd3',
        courseCode: 'PHILOSOP 3645',
        shortName: 'Philosophy 3645F/G - GROUP AGENCY',
        courseName: 'Philosophy 3645F/G - GROUP AGENCY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253a47f7e10015fd7bd3',
      },
      {
        _id: '60cc253a47f7e10015fd7bd5',
        courseCode: 'PHILOSOP 3670',
        shortName:
          'Philosophy 3670F/G - PHILOSOPHY OF EXISTENTIALISM: ATHEISTIC AND RELIGIOUS APPROACHES',
        courseName:
          'Philosophy 3670F/G - PHILOSOPHY OF EXISTENTIALISM: ATHEISTIC AND RELIGIOUS APPROACHES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253a47f7e10015fd7bd5',
      },
      {
        _id: '60cc253a47f7e10015fd7bd7',
        courseCode: 'PHILOSOP 3673',
        shortName: 'Philosophy 3673F/G - THE PROBLEM OF LOVE',
        courseName: 'Philosophy 3673F/G - THE PROBLEM OF LOVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253a47f7e10015fd7bd7',
      },
      {
        _id: '60cc253a47f7e10015fd7bd9',
        courseCode: 'PHILOSOP 3674',
        shortName: 'Philosophy 3674F/G - PHILOSOPHICAL THOUGHT OF JOHN PAUL II',
        courseName:
          'Philosophy 3674F/G - PHILOSOPHICAL THOUGHT OF JOHN PAUL II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253a47f7e10015fd7bd9',
      },
      {
        _id: '60cc253a47f7e10015fd7bdb',
        courseCode: 'PHILOSOP 3691',
        shortName:
          'Philosophy 3691F/G - SPECIAL TOPICS IN THE PHILOSOPHY OF RELIGION',
        courseName:
          'Philosophy 3691F/G - SPECIAL TOPICS IN THE PHILOSOPHY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253a47f7e10015fd7bdb',
      },
      {
        _id: '60cc253b47f7e10015fd7bdd',
        courseCode: 'PHILOSOP 3692',
        shortName:
          'Philosophy 3692F/G - SPECIAL TOPICS IN THE PHILOSOPY OF RELIGION',
        courseName:
          'Philosophy 3692F/G - SPECIAL TOPICS IN THE PHILOSOPY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253b47f7e10015fd7bdd',
      },
      {
        _id: '60cc253b47f7e10015fd7bdf',
        courseCode: 'PHILOSOP 3700',
        shortName: 'Philosophy 3700E - ETHICS',
        courseName: 'Philosophy 3700E - ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253b47f7e10015fd7bdf',
      },
      {
        _id: '60cc253b47f7e10015fd7be1',
        courseCode: 'PHILOSOP 3710',
        shortName: 'Philosophy 3710F/G - METAETHICS',
        courseName: 'Philosophy 3710F/G - METAETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253b47f7e10015fd7be1',
      },
      {
        _id: '60cc253c47f7e10015fd7be3',
        courseCode: 'PHILOSOP 3720',
        shortName: 'Philosophy 3720F/G - NORMATIVE ETHICS',
        courseName: 'Philosophy 3720F/G - NORMATIVE ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253c47f7e10015fd7be3',
      },
      {
        _id: '60cc253c47f7e10015fd7be5',
        courseCode: 'PHILOSOP 3730',
        shortName: 'Philosophy 3730F/G - RESEARCH ETHICS',
        courseName: 'Philosophy 3730F/G - RESEARCH ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253c47f7e10015fd7be5',
      },
      {
        _id: '60cc253c47f7e10015fd7be7',
        courseCode: 'PHILOSOP 3750',
        shortName: 'Philosophy 3750F/G - EMBODIMENT',
        courseName: 'Philosophy 3750F/G - EMBODIMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253c47f7e10015fd7be7',
      },
      {
        _id: '60cc253c47f7e10015fd7be9',
        courseCode: 'PHILOSOP 3810',
        shortName: 'Philosophy 3810F/G - JUSTICE',
        courseName: 'Philosophy 3810F/G - JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253c47f7e10015fd7be9',
      },
      {
        _id: '60cc253d47f7e10015fd7beb',
        courseCode: 'PHILOSOP 3820',
        shortName: 'Philosophy 3820F/G - GLOBALIZATION AND THEORIES OF JUSTICE',
        courseName:
          'Philosophy 3820F/G - GLOBALIZATION AND THEORIES OF JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253d47f7e10015fd7beb',
      },
      {
        _id: '60cc253d47f7e10015fd7bed',
        courseCode: 'PHILOSOP 3830',
        shortName: 'Philosophy 3830F/G - ADVANCED PHILOSOPHY OF FOOD',
        courseName: 'Philosophy 3830F/G - ADVANCED PHILOSOPHY OF FOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253d47f7e10015fd7bed',
      },
      {
        _id: '60cc253d47f7e10015fd7bef',
        courseCode: 'PHILOSOP 3840',
        shortName: 'Philosophy 3840F/G - CASE STUDIES IN BUSINESS ETHICS',
        courseName: 'Philosophy 3840F/G - CASE STUDIES IN BUSINESS ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253d47f7e10015fd7bef',
      },
      {
        _id: '60cc253e47f7e10015fd7bf1',
        courseCode: 'PHILOSOP 3850',
        shortName: 'Philosophy 3850F/G - CLIMATE JUSTICE',
        courseName: 'Philosophy 3850F/G - CLIMATE JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253e47f7e10015fd7bf1',
      },
      {
        _id: '60cc253e47f7e10015fd7bf3',
        courseCode: 'PHILOSOP 3880',
        shortName: 'Philosophy 3880F/G - SOCIAL CONSTRUCTION OF INSTITUTIONS',
        courseName: 'Philosophy 3880F/G - SOCIAL CONSTRUCTION OF INSTITUTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253e47f7e10015fd7bf3',
      },
      {
        _id: '60cc253e47f7e10015fd7bf5',
        courseCode: 'PHILOSOP 3885',
        shortName:
          'Philosophy 3885F/G - ADVANCED TOPICS IN SOCIAL POLITICAL THOUGHT',
        courseName:
          'Philosophy 3885F/G - ADVANCED TOPICS IN SOCIAL POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253e47f7e10015fd7bf5',
      },
      {
        _id: '60cc253e47f7e10015fd7bf7',
        courseCode: 'PHILOSOP 3886',
        shortName:
          'Philosophy 3886F/G - ADVANCED TOPICS IN SOCIAL POLITICAL THOUGHT',
        courseName:
          'Philosophy 3886F/G - ADVANCED TOPICS IN SOCIAL POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253e47f7e10015fd7bf7',
      },
      {
        _id: '60cc253f47f7e10015fd7bf9',
        courseCode: 'PHILOSOP 3910',
        shortName: 'Philosophy 3910F/G - AESTHETICS',
        courseName: 'Philosophy 3910F/G - AESTHETICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253f47f7e10015fd7bf9',
      },
      {
        _id: '60cc253f47f7e10015fd7bfb',
        courseCode: 'PHILOSOP 3990',
        shortName: 'Philosophy 3990A/B - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 3990A/B - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253f47f7e10015fd7bfb',
      },
      {
        _id: '60cc253f47f7e10015fd7bfd',
        courseCode: 'PHILOSOP 3991',
        shortName: 'Philosophy 3991F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 3991F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253f47f7e10015fd7bfd',
      },
      {
        _id: '60cc253f47f7e10015fd7bff',
        courseCode: 'PHILOSOP 3992',
        shortName: 'Philosophy 3992F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 3992F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc253f47f7e10015fd7bff',
      },
      {
        _id: '60cc254047f7e10015fd7c01',
        courseCode: 'PHILOSOP 3993',
        shortName: 'Philosophy 3993F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 3993F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254047f7e10015fd7c01',
      },
      {
        _id: '60cc254047f7e10015fd7c03',
        courseCode: 'PHILOSOP 3994',
        shortName: 'Philosophy 3994F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 3994F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254047f7e10015fd7c03',
      },
      {
        _id: '60cc254047f7e10015fd7c05',
        courseCode: 'PHILOSOP 3995',
        shortName: 'Philosophy 3995A/B - TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 3995A/B - TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254047f7e10015fd7c05',
      },
      {
        _id: '60cc254047f7e10015fd7c07',
        courseCode: 'PHILOSOP 3996',
        shortName: 'Philosophy 3996F/G - TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 3996F/G - TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254047f7e10015fd7c07',
      },
      {
        _id: '60cc254147f7e10015fd7c09',
        courseCode: 'PHILOSOP 3997',
        shortName: 'Philosophy 3997F/G - TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 3997F/G - TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254147f7e10015fd7c09',
      },
      {
        _id: '60cc254147f7e10015fd7c0b',
        courseCode: 'PHILOSOP 3998',
        shortName: 'Philosophy 3998E - TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 3998E - TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254147f7e10015fd7c0b',
      },
      {
        _id: '60cc254247f7e10015fd7c0d',
        courseCode: 'PHILOSOP 4005',
        shortName:
          'Philosophy 4005F/G - SPECIAL TOPICS ON PLATO & EARLY GREEK PHILOSOPHY',
        courseName:
          'Philosophy 4005F/G - SPECIAL TOPICS ON PLATO & EARLY GREEK PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254247f7e10015fd7c0d',
      },
      {
        _id: '60cc254247f7e10015fd7c0f',
        courseCode: 'PHILOSOP 4007',
        shortName: 'Philosophy 4007F/G - SEMINAR IN ANCIENT PHILOSOPHY',
        courseName: 'Philosophy 4007F/G - SEMINAR IN ANCIENT PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254247f7e10015fd7c0f',
      },
      {
        _id: '60cc254247f7e10015fd7c11',
        courseCode: 'PHILOSOP 4015',
        shortName:
          'Philosophy 4015F/G - SPECIAL TOPICS ON ARISTOTLE AND HELLENISTIC AND ROMAN PHILOSOPHY',
        courseName:
          'Philosophy 4015F/G - SPECIAL TOPICS ON ARISTOTLE AND HELLENISTIC AND ROMAN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254247f7e10015fd7c11',
      },
      {
        _id: '60cc254347f7e10015fd7c13',
        courseCode: 'PHILOSOP 4016',
        shortName:
          'Philosophy 4016F/G - SPECIAL TOPICS ON ARISTOTLE AND HELLENISTIC AND ROMAN PHILOSOPHY',
        courseName:
          'Philosophy 4016F/G - SPECIAL TOPICS ON ARISTOTLE AND HELLENISTIC AND ROMAN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254347f7e10015fd7c13',
      },
      {
        _id: '60cc254347f7e10015fd7c15',
        courseCode: 'PHILOSOP 4017',
        shortName: 'Philosophy 4017F/G - SEMINAR IN ANCIENT PHILOSOPHY',
        courseName: 'Philosophy 4017F/G - SEMINAR IN ANCIENT PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254347f7e10015fd7c15',
      },
      {
        _id: '60cc254347f7e10015fd7c17',
        courseCode: 'PHILOSOP 4023',
        shortName:
          'Philosophy 4023F/G - SPECIAL TOPICS IN EARLY MEDIEVAL PHILOSOPHY',
        courseName:
          'Philosophy 4023F/G - SPECIAL TOPICS IN EARLY MEDIEVAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254347f7e10015fd7c17',
      },
      {
        _id: '60cc254347f7e10015fd7c19',
        courseCode: 'PHILOSOP 4035',
        shortName:
          'Philosophy 4035F/G - SPECIAL TOPICS IN 17TH CENTURY PHILOSOPHY',
        courseName:
          'Philosophy 4035F/G - SPECIAL TOPICS IN 17TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254347f7e10015fd7c19',
      },
      {
        _id: '60cc254447f7e10015fd7c1b',
        courseCode: 'PHILOSOP 4036',
        shortName:
          'Philosophy 4036F/G - SPECIAL TOPICS IN 17TH CENTURY PHILOSOPHY',
        courseName:
          'Philosophy 4036F/G - SPECIAL TOPICS IN 17TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254447f7e10015fd7c1b',
      },
      {
        _id: '60cc254447f7e10015fd7c1d',
        courseCode: 'PHILOSOP 4037',
        shortName: 'Philosophy 4037F/G - SEMINAR IN RATIONALISM',
        courseName: 'Philosophy 4037F/G - SEMINAR IN RATIONALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254447f7e10015fd7c1d',
      },
      {
        _id: '60cc254447f7e10015fd7c1f',
        courseCode: 'PHILOSOP 4045',
        shortName:
          'Philosophy 4045F/G - SPECIAL TOPICS IN 18TH CENTURY PHILOSOPHY',
        courseName:
          'Philosophy 4045F/G - SPECIAL TOPICS IN 18TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254447f7e10015fd7c1f',
      },
      {
        _id: '60cc254447f7e10015fd7c21',
        courseCode: 'PHILOSOP 4046',
        shortName:
          'Philosophy 4046F/G - SPECIAL TOPICS IN 18TH CENTURY PHILOSOPHY',
        courseName:
          'Philosophy 4046F/G - SPECIAL TOPICS IN 18TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254447f7e10015fd7c21',
      },
      {
        _id: '60cc254547f7e10015fd7c23',
        courseCode: 'PHILOSOP 4047',
        shortName: 'Philosophy 4047F/G - SEMINAR IN EMPIRICISM',
        courseName: 'Philosophy 4047F/G - SEMINAR IN EMPIRICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254547f7e10015fd7c23',
      },
      {
        _id: '60cc254547f7e10015fd7c25',
        courseCode: 'PHILOSOP 4050',
        shortName: "Philosophy 4050F/G - SEMINAR IN KANT'S FIRST CRITIQUE",
        courseName: "Philosophy 4050F/G - SEMINAR IN KANT'S FIRST CRITIQUE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254547f7e10015fd7c25',
      },
      {
        _id: '60cc254547f7e10015fd7c27',
        courseCode: 'PHILOSOP 4050',
        shortName: 'Philosophy 4050F/G - SEMINAR ON KANT',
        courseName: 'Philosophy 4050F/G - SEMINAR ON KANT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254547f7e10015fd7c27',
      },
      {
        _id: '60cc254547f7e10015fd7c29',
        courseCode: 'PHILOSOP 4051',
        shortName:
          "Philosophy 4051F/G - SEMINAR ON KANT'S PRACTICAL PHILOSOPHY",
        courseName:
          "Philosophy 4051F/G - SEMINAR ON KANT'S PRACTICAL PHILOSOPHY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254547f7e10015fd7c29',
      },
      {
        _id: '60cc254647f7e10015fd7c2b',
        courseCode: 'PHILOSOP 4052',
        shortName:
          "Philosophy 4052F/G - SPECIAL TOPICS ON KANT'S THEORETICAL PHILOSOPHY",
        courseName:
          "Philosophy 4052F/G - SPECIAL TOPICS ON KANT'S THEORETICAL PHILOSOPHY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254647f7e10015fd7c2b',
      },
      {
        _id: '60cc254647f7e10015fd7c2d',
        courseCode: 'PHILOSOP 4054',
        shortName:
          "Philosophy 4054F/G - SPECIAL TOPICS IN KANT'S PRACTICAL PHILOSOPHY",
        courseName:
          "Philosophy 4054F/G - SPECIAL TOPICS IN KANT'S PRACTICAL PHILOSOPHY",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254647f7e10015fd7c2d',
      },
      {
        _id: '60cc254647f7e10015fd7c2f',
        courseCode: 'PHILOSOP 4055',
        shortName:
          'Philosophy 4055F/G - SPECIAL TOPICS IN PRACTICAL PHILOSOPHY',
        courseName:
          'Philosophy 4055F/G - SPECIAL TOPICS IN PRACTICAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254647f7e10015fd7c2f',
      },
      {
        _id: '60cc254647f7e10015fd7c31',
        courseCode: 'PHILOSOP 4056',
        shortName:
          'Philosophy 4056F/G - SPECIAL TOPICS IN 19TH CENTURY PHILOSOPHY',
        courseName:
          'Philosophy 4056F/G - SPECIAL TOPICS IN 19TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254647f7e10015fd7c31',
      },
      {
        _id: '60cc254747f7e10015fd7c33',
        courseCode: 'PHILOSOP 4058',
        shortName: 'Philosophy 4058F/G - SEMINAR ON HEGEL',
        courseName: 'Philosophy 4058F/G - SEMINAR ON HEGEL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254747f7e10015fd7c33',
      },
      {
        _id: '60cc254747f7e10015fd7c35',
        courseCode: 'PHILOSOP 4059',
        shortName: 'Philosophy 4059F/G - SEMINAR ON NIETZSCHE',
        courseName: 'Philosophy 4059F/G - SEMINAR ON NIETZSCHE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254747f7e10015fd7c35',
      },
      {
        _id: '60cc254747f7e10015fd7c37',
        courseCode: 'PHILOSOP 4060',
        shortName: 'Philosophy 4060F/G - ADVANCED SEMINAR ON PLATO',
        courseName: 'Philosophy 4060F/G - ADVANCED SEMINAR ON PLATO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254747f7e10015fd7c37',
      },
      {
        _id: '60cc254747f7e10015fd7c39',
        courseCode: 'PHILOSOP 4061',
        shortName: 'Philosophy 4061F/G - ADVANCED SEMINAR IN ARISTOTLE',
        courseName: 'Philosophy 4061F/G - ADVANCED SEMINAR IN ARISTOTLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254747f7e10015fd7c39',
      },
      {
        _id: '60cc254847f7e10015fd7c3b',
        courseCode: 'PHILOSOP 4065',
        shortName: 'Philosophy 4065F/G - SEMINAR IN 19TH CENTURY PHILOSOPHY',
        courseName: 'Philosophy 4065F/G - SEMINAR IN 19TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254847f7e10015fd7c3b',
      },
      {
        _id: '60cc254847f7e10015fd7c3d',
        courseCode: 'PHILOSOP 4066',
        shortName: 'Philosophy 4066F/G - SEMINAR IN 19TH CENTURY PHILOSOPHY',
        courseName: 'Philosophy 4066F/G - SEMINAR IN 19TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254847f7e10015fd7c3d',
      },
      {
        _id: '60cc254847f7e10015fd7c3f',
        courseCode: 'PHILOSOP 4071',
        shortName: 'Philosophy 4071F/G - ADVANCED TOPICS IN ETHICS',
        courseName: 'Philosophy 4071F/G - ADVANCED TOPICS IN ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254847f7e10015fd7c3f',
      },
      {
        _id: '60cc254947f7e10015fd7c41',
        courseCode: 'PHILOSOP 4072',
        shortName: 'Philosophy 4072F/G - ADVANCED TOPICS IN ETHICS',
        courseName: 'Philosophy 4072F/G - ADVANCED TOPICS IN ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254947f7e10015fd7c41',
      },
      {
        _id: '60cc254947f7e10015fd7c43',
        courseCode: 'PHILOSOP 4075',
        shortName: 'Philosophy 4075F/G - SEMINAR IN 20TH CENTURY PHILOSOPHY',
        courseName: 'Philosophy 4075F/G - SEMINAR IN 20TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254947f7e10015fd7c43',
      },
      {
        _id: '60cc254947f7e10015fd7c45',
        courseCode: 'PHILOSOP 4078',
        shortName: 'Philosophy 4078F/G - SEMINAR IN 20TH CENTURY PHILOSOPHY',
        courseName: 'Philosophy 4078F/G - SEMINAR IN 20TH CENTURY PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254947f7e10015fd7c45',
      },
      {
        _id: '60cc254947f7e10015fd7c47',
        courseCode: 'PHILOSOP 4080',
        shortName: 'Philosophy 4080E - CONTEMPORARY ANALYTIC PHILOSOPHY',
        courseName: 'Philosophy 4080E - CONTEMPORARY ANALYTIC PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254947f7e10015fd7c47',
      },
      {
        _id: '60cc254a47f7e10015fd7c49',
        courseCode: 'PHILOSOP 4090',
        shortName: 'Philosophy 4090F/G - CONTINENTAL PHILOSOPHY',
        courseName: 'Philosophy 4090F/G - CONTINENTAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254a47f7e10015fd7c49',
      },
      {
        _id: '60cc254a47f7e10015fd7c4b',
        courseCode: 'PHILOSOP 4091',
        shortName: 'Philosophy 4091F/G - TOPICS IN CONTINENTAL PHILOSOPHY',
        courseName: 'Philosophy 4091F/G - TOPICS IN CONTINENTAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254a47f7e10015fd7c4b',
      },
      {
        _id: '60cc254a47f7e10015fd7c4d',
        courseCode: 'PHILOSOP 4094',
        shortName:
          'Philosophy 4094F/G - CONTEMPORARY CONTINENTAL SOCIAL AND POLITICAL THOUGHT',
        courseName:
          'Philosophy 4094F/G - CONTEMPORARY CONTINENTAL SOCIAL AND POLITICAL THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254a47f7e10015fd7c4d',
      },
      {
        _id: '60cc254a47f7e10015fd7c4f',
        courseCode: 'PHILOSOP 4095',
        shortName: 'Philosophy 4095F/G - HEIDEGGER',
        courseName: 'Philosophy 4095F/G - HEIDEGGER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254a47f7e10015fd7c4f',
      },
      {
        _id: '60cc254b47f7e10015fd7c51',
        courseCode: 'PHILOSOP 4096',
        shortName: 'Philosophy 4096F/G - SEMINAR IN ANCIENT PHILOSOPHY',
        courseName: 'Philosophy 4096F/G - SEMINAR IN ANCIENT PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254b47f7e10015fd7c51',
      },
      {
        _id: '60cc254b47f7e10015fd7c53',
        courseCode: 'PHILOSOP 4098',
        shortName:
          "Philosophy 4098F/G - ADVANCED SEMINAR ON MARCUS AURELIUS' MEDITATIONS",
        courseName:
          "Philosophy 4098F/G - ADVANCED SEMINAR ON MARCUS AURELIUS' MEDITATIONS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254b47f7e10015fd7c53',
      },
      {
        _id: '60cc254b47f7e10015fd7c55',
        courseCode: 'PHILOSOP 4107',
        shortName: 'Philosophy 4107F/G - HISTORY OF THE PHILOSOPHY OF RACE',
        courseName: 'Philosophy 4107F/G - HISTORY OF THE PHILOSOPHY OF RACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254b47f7e10015fd7c55',
      },
      {
        _id: '60cc254b47f7e10015fd7c57',
        courseCode: 'PHILOSOP 4108',
        shortName: 'Philosophy 4108F/G - PROBLEMS IN THE HISTORY OF PHILOSOPHY',
        courseName:
          'Philosophy 4108F/G - PROBLEMS IN THE HISTORY OF PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254b47f7e10015fd7c57',
      },
      {
        _id: '60cc254c47f7e10015fd7c59',
        courseCode: 'PHILOSOP 4109',
        shortName: 'Philosophy 4109F/G - PROBLEMS IN THE HISTORY OF PHILOSOPHY',
        courseName:
          'Philosophy 4109F/G - PROBLEMS IN THE HISTORY OF PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254c47f7e10015fd7c59',
      },
      {
        _id: '60cc254c47f7e10015fd7c5b',
        courseCode: 'PHILOSOP 4151',
        shortName: 'Philosophy 4151F/G - SEMINAR ON KANT',
        courseName: 'Philosophy 4151F/G - SEMINAR ON KANT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254c47f7e10015fd7c5b',
      },
      {
        _id: '60cc254c47f7e10015fd7c5d',
        courseCode: 'PHILOSOP 4210',
        shortName: 'Philosophy 4210F/G - PROBLEMS IN PHILOSOPHY OF LANGUAGE',
        courseName: 'Philosophy 4210F/G - PROBLEMS IN PHILOSOPHY OF LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254c47f7e10015fd7c5d',
      },
      {
        _id: '60cc254c47f7e10015fd7c5f',
        courseCode: 'PHILOSOP 4310',
        shortName: 'Philosophy 4310F/G - PROBLEMS IN THE PHILOSOPHY OF SCIENCE',
        courseName:
          'Philosophy 4310F/G - PROBLEMS IN THE PHILOSOPHY OF SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254c47f7e10015fd7c5f',
      },
      {
        _id: '60cc254d47f7e10015fd7c61',
        courseCode: 'PHILOSOP 4311',
        shortName: 'Philosophy 4311F/G - PROBLEMS IN THE PHILOSOPHY OF SCIENCE',
        courseName:
          'Philosophy 4311F/G - PROBLEMS IN THE PHILOSOPHY OF SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254d47f7e10015fd7c61',
      },
      {
        _id: '60cc254d47f7e10015fd7c63',
        courseCode: 'PHILOSOP 4331',
        shortName: 'Philosophy 4331F/G - WOMEN AND SCIENCE',
        courseName: 'Philosophy 4331F/G - WOMEN AND SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254d47f7e10015fd7c63',
      },
      {
        _id: '60cc254d47f7e10015fd7c65',
        courseCode: 'PHILOSOP 4410',
        shortName: 'Philosophy 4410F/G - PROBLEMS IN PHILOSOPHY OF MIND',
        courseName: 'Philosophy 4410F/G - PROBLEMS IN PHILOSOPHY OF MIND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254d47f7e10015fd7c65',
      },
      {
        _id: '60cc254d47f7e10015fd7c67',
        courseCode: 'PHILOSOP 4510',
        shortName: 'Philosophy 4510F/G - PROBLEMS IN EPISTEMOLOGY',
        courseName: 'Philosophy 4510F/G - PROBLEMS IN EPISTEMOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254d47f7e10015fd7c67',
      },
      {
        _id: '60cc254e47f7e10015fd7c69',
        courseCode: 'PHILOSOP 4520',
        shortName: 'Philosophy 4520E - EPISTEMOLOGY',
        courseName: 'Philosophy 4520E - EPISTEMOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254e47f7e10015fd7c69',
      },
      {
        _id: '60cc254e47f7e10015fd7c6b',
        courseCode: 'PHILOSOP 4530',
        shortName:
          'Philosophy 4530F/G - TOPICS IN FEMINIST THEORIES OF KNOWLEDGE',
        courseName:
          'Philosophy 4530F/G - TOPICS IN FEMINIST THEORIES OF KNOWLEDGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254e47f7e10015fd7c6b',
      },
      {
        _id: '60cc254e47f7e10015fd7c6d',
        courseCode: 'PHILOSOP 4570',
        shortName: 'Philosophy 4570F/G - PHENOMENOLOGY',
        courseName: 'Philosophy 4570F/G - PHENOMENOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254e47f7e10015fd7c6d',
      },
      {
        _id: '60cc254e47f7e10015fd7c6f',
        courseCode: 'PHILOSOP 4610',
        shortName: 'Philosophy 4610F/G - PROBLEMS IN METAPHYSICS',
        courseName: 'Philosophy 4610F/G - PROBLEMS IN METAPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254e47f7e10015fd7c6f',
      },
      {
        _id: '60cc254e47f7e10015fd7c71',
        courseCode: 'PHILOSOP 4620',
        shortName: 'Philosophy 4620E - METAPHYSICS',
        courseName: 'Philosophy 4620E - METAPHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254e47f7e10015fd7c71',
      },
      {
        _id: '60cc254f47f7e10015fd7c73',
        courseCode: 'PHILOSOP 4710',
        shortName: 'Philosophy 4710F/G - ADVANCED TOPICS IN PRACTICAL ETHICS',
        courseName: 'Philosophy 4710F/G - ADVANCED TOPICS IN PRACTICAL ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254f47f7e10015fd7c73',
      },
      {
        _id: '60cc254f47f7e10015fd7c75',
        courseCode: 'PHILOSOP 4730',
        shortName:
          'Philosophy 4730F/G - TOPICS IN FEMINIST ETHICS AND SOCIAL/POLITICAL PHILOSOPHY',
        courseName:
          'Philosophy 4730F/G - TOPICS IN FEMINIST ETHICS AND SOCIAL/POLITICAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254f47f7e10015fd7c75',
      },
      {
        _id: '60cc254f47f7e10015fd7c77',
        courseCode: 'PHILOSOP 4750',
        shortName:
          'Philosophy 4750F/G - TOPICS IN PHILOSOPHY OF GENDER AND SEXUALITY',
        courseName:
          'Philosophy 4750F/G - TOPICS IN PHILOSOPHY OF GENDER AND SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254f47f7e10015fd7c77',
      },
      {
        _id: '60cc254f47f7e10015fd7c79',
        courseCode: 'PHILOSOP 4751',
        shortName: 'Philosophy 4751F/G - GENDER AND RACE',
        courseName: 'Philosophy 4751F/G - GENDER AND RACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc254f47f7e10015fd7c79',
      },
      {
        _id: '60cc255047f7e10015fd7c7b',
        courseCode: 'PHILOSOP 4810',
        shortName:
          'Philosophy 4810F/G - ADVANCED TOPICS IN POLITICAL PHILOSOPHY',
        courseName:
          'Philosophy 4810F/G - ADVANCED TOPICS IN POLITICAL PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255047f7e10015fd7c7b',
      },
      {
        _id: '60cc255047f7e10015fd7c7d',
        courseCode: 'PHILOSOP 4820',
        shortName: 'Philosophy 4820F/G - THEORIES OF PROPERTY',
        courseName: 'Philosophy 4820F/G - THEORIES OF PROPERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255047f7e10015fd7c7d',
      },
      {
        _id: '60cc255047f7e10015fd7c7f',
        courseCode: 'PHILOSOP 4830',
        shortName:
          'Philosophy 4830F/G - CONTEMPORARY ISSUES IN THE PHILOSOPHY OF PROPERTY',
        courseName:
          'Philosophy 4830F/G - CONTEMPORARY ISSUES IN THE PHILOSOPHY OF PROPERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255047f7e10015fd7c7f',
      },
      {
        _id: '60cc255047f7e10015fd7c81',
        courseCode: 'PHILOSOP 4850',
        shortName: 'Philosophy 4850F/G - PHILOSOPHY OF LAW',
        courseName: 'Philosophy 4850F/G - PHILOSOPHY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255047f7e10015fd7c81',
      },
      {
        _id: '60cc255147f7e10015fd7c83',
        courseCode: 'PHILOSOP 4851',
        shortName: 'Philosophy 4851F/G - PHILOSOPHY OF LAW',
        courseName: 'Philosophy 4851F/G - PHILOSOPHY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255147f7e10015fd7c83',
      },
      {
        _id: '60cc255147f7e10015fd7c85',
        courseCode: 'PHILOSOP 4852',
        shortName:
          'Philosophy 4852F/G - PHILOSOPHY OF LAW: FREE SPEECH ON CAMPUS',
        courseName:
          'Philosophy 4852F/G - PHILOSOPHY OF LAW: FREE SPEECH ON CAMPUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255147f7e10015fd7c85',
      },
      {
        _id: '60cc255147f7e10015fd7c87',
        courseCode: 'PHILOSOP 4855',
        shortName:
          'Philosophy 4855F/G - TRADITIONAL AND CONTEMPORARY NATURAL LAW',
        courseName:
          'Philosophy 4855F/G - TRADITIONAL AND CONTEMPORARY NATURAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255147f7e10015fd7c87',
      },
      {
        _id: '60cc255247f7e10015fd7c89',
        courseCode: 'PHILOSOP 4900',
        shortName: 'Philosophy 4900F/G - HONOURS CAPSTONE: SEMINAR',
        courseName: 'Philosophy 4900F/G - HONOURS CAPSTONE: SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255247f7e10015fd7c89',
      },
      {
        _id: '60cc255247f7e10015fd7c8b',
        courseCode: 'PHILOSOP 4901',
        shortName:
          'Philosophy 4901F/G - HONOURS CAPSTONE: COMMUNITY ENGAGED LEARNING',
        courseName:
          'Philosophy 4901F/G - HONOURS CAPSTONE: COMMUNITY ENGAGED LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255247f7e10015fd7c8b',
      },
      {
        _id: '60cc255247f7e10015fd7c8d',
        courseCode: 'PHILOSOP 4990',
        shortName: 'Philosophy 4990A/B - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 4990A/B - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255247f7e10015fd7c8d',
      },
      {
        _id: '60cc255247f7e10015fd7c8f',
        courseCode: 'PHILOSOP 4991',
        shortName: 'Philosophy 4991F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 4991F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255247f7e10015fd7c8f',
      },
      {
        _id: '60cc255347f7e10015fd7c91',
        courseCode: 'PHILOSOP 4992',
        shortName: 'Philosophy 4992F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 4992F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255347f7e10015fd7c91',
      },
      {
        _id: '60cc255347f7e10015fd7c93',
        courseCode: 'PHILOSOP 4994',
        shortName: 'Philosophy 4994F/G - PROBLEMS IN PHILOSOPHY',
        courseName: 'Philosophy 4994F/G - PROBLEMS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255347f7e10015fd7c93',
      },
      {
        _id: '60cc255347f7e10015fd7c95',
        courseCode: 'PHILOSOP 4995',
        shortName: 'Philosophy 4995A/B - TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 4995A/B - TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255347f7e10015fd7c95',
      },
      {
        _id: '60cc255447f7e10015fd7c97',
        courseCode: 'PHILOSOP 4996',
        shortName: 'Philosophy 4996F/F/G - ADVANCED TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 4996F/F/G - ADVANCED TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255447f7e10015fd7c97',
      },
      {
        _id: '60cc255447f7e10015fd7c99',
        courseCode: 'PHILOSOP 4996',
        shortName: 'Philosophy 4996F/G - ADVANCED TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 4996F/G - ADVANCED TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255447f7e10015fd7c99',
      },
      {
        _id: '60cc255447f7e10015fd7c9b',
        courseCode: 'PHILOSOP 4997',
        shortName: 'Philosophy 4997F/G - ADVANCED TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 4997F/G - ADVANCED TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255447f7e10015fd7c9b',
      },
      {
        _id: '60cc255547f7e10015fd7c9d',
        courseCode: 'PHILOSOP 4998',
        shortName: 'Philosophy 4998E - ADVANCED TOPICS IN PHILOSOPHY',
        courseName: 'Philosophy 4998E - ADVANCED TOPICS IN PHILOSOPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255547f7e10015fd7c9d',
      },
      {
        _id: '60cc255547f7e10015fd7c9f',
        courseCode: 'PHYSICS 0010',
        shortName: 'Physics 0010 - INTRODUCTION TO PHYSICS',
        courseName: 'Physics 0010 - INTRODUCTION TO PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255547f7e10015fd7c9f',
      },
      {
        _id: '60cc255647f7e10015fd7ca1',
        courseCode: 'PHYSICS 0011',
        shortName: 'Physics 0011A/B - INTRODUCTORY PHYSICS I',
        courseName: 'Physics 0011A/B - INTRODUCTORY PHYSICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255647f7e10015fd7ca1',
      },
      {
        _id: '60cc255647f7e10015fd7ca3',
        courseCode: 'PHYSICS 0012',
        shortName: 'Physics 0012A/B - INTRODUCTORY PHYSICS II',
        courseName: 'Physics 0012A/B - INTRODUCTORY PHYSICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255647f7e10015fd7ca3',
      },
      {
        _id: '60cc255647f7e10015fd7ca5',
        courseCode: 'PHYSICS 1021',
        shortName: 'Physics 1021 - CONCEPTUAL PHYSICS FOR NON-SCIENTISTS',
        courseName: 'Physics 1021 - CONCEPTUAL PHYSICS FOR NON-SCIENTISTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255647f7e10015fd7ca5',
      },
      {
        _id: '60cc255747f7e10015fd7ca7',
        courseCode: 'PHYSICS 1101',
        shortName: 'Physics 1101A/B - INTRODUCTION TO PHYSICS I',
        courseName: 'Physics 1101A/B - INTRODUCTION TO PHYSICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255747f7e10015fd7ca7',
      },
      {
        _id: '60cc255747f7e10015fd7ca9',
        courseCode: 'PHYSICS 1102',
        shortName: 'Physics 1102A/B - INTRODUCTION TO PHYSICS II',
        courseName: 'Physics 1102A/B - INTRODUCTION TO PHYSICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255747f7e10015fd7ca9',
      },
      {
        _id: '60cc255747f7e10015fd7cab',
        courseCode: 'PHYSICS 1201',
        shortName: 'Physics 1201A/B - PHYSICS FOR THE SCIENCES I',
        courseName: 'Physics 1201A/B - PHYSICS FOR THE SCIENCES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255747f7e10015fd7cab',
      },
      {
        _id: '60cc255747f7e10015fd7cad',
        courseCode: 'PHYSICS 1202',
        shortName: 'Physics 1202A/B - PHYSICS FOR THE SCIENCES II',
        courseName: 'Physics 1202A/B - PHYSICS FOR THE SCIENCES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255747f7e10015fd7cad',
      },
      {
        _id: '60cc255847f7e10015fd7caf',
        courseCode: 'PHYSICS 1401',
        shortName: 'Physics 1401A/B - PHYSICS FOR ENGINEERING STUDENTS I',
        courseName: 'Physics 1401A/B - PHYSICS FOR ENGINEERING STUDENTS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255847f7e10015fd7caf',
      },
      {
        _id: '60cc255847f7e10015fd7cb1',
        courseCode: 'PHYSICS 1402',
        shortName: 'Physics 1402A/B - PHYSICS FOR ENGINEERING STUDENTS II',
        courseName: 'Physics 1402A/B - PHYSICS FOR ENGINEERING STUDENTS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255847f7e10015fd7cb1',
      },
      {
        _id: '60cc255847f7e10015fd7cb3',
        courseCode: 'PHYSICS 1501',
        shortName: 'Physics 1501A/B - ENRICHED INTRODUCTORY PHYSICS I',
        courseName: 'Physics 1501A/B - ENRICHED INTRODUCTORY PHYSICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255847f7e10015fd7cb3',
      },
      {
        _id: '60cc255947f7e10015fd7cb5',
        courseCode: 'PHYSICS 1502',
        shortName: 'Physics 1502A/B - ENRICHED INTRODUCTORY PHYSICS II',
        courseName: 'Physics 1502A/B - ENRICHED INTRODUCTORY PHYSICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255947f7e10015fd7cb5',
      },
      {
        _id: '60cc255947f7e10015fd7cb7',
        courseCode: 'PHYSICS 2065',
        shortName:
          'Physics 2065A/B - GOING FASTER AND FARTHER: THE SCIENCE OF THE SPORTING ENVIRONMENT',
        courseName:
          'Physics 2065A/B - GOING FASTER AND FARTHER: THE SCIENCE OF THE SPORTING ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255947f7e10015fd7cb7',
      },
      {
        _id: '60cc255947f7e10015fd7cb9',
        courseCode: 'PHYSICS 2070',
        shortName: "Physics 2070A/B - UNDERSTANDING EARTH'S ATMOSPHERE",
        courseName: "Physics 2070A/B - UNDERSTANDING EARTH'S ATMOSPHERE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255947f7e10015fd7cb9',
      },
      {
        _id: '60cc255947f7e10015fd7cbb',
        courseCode: 'PHYSICS 2101',
        shortName: 'Physics 2101A/B - INTERMEDIATE PHYSICS',
        courseName: 'Physics 2101A/B - INTERMEDIATE PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255947f7e10015fd7cbb',
      },
      {
        _id: '60cc255a47f7e10015fd7cbd',
        courseCode: 'PHYSICS 2102',
        shortName: 'Physics 2102A/B - INTRODUCTION TO MODERN PHYSICS',
        courseName: 'Physics 2102A/B - INTRODUCTION TO MODERN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255a47f7e10015fd7cbd',
      },
      {
        _id: '60cc255a47f7e10015fd7cbf',
        courseCode: 'PHYSICS 2110',
        shortName: 'Physics 2110A/B - OSCILLATIONS AND WAVES',
        courseName: 'Physics 2110A/B - OSCILLATIONS AND WAVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255a47f7e10015fd7cbf',
      },
      {
        _id: '60cc255a47f7e10015fd7cc1',
        courseCode: 'PHYSICS 2810',
        shortName: 'Physics 2810A/B - PHYSICAL PROPERTIES OF MATERIALS',
        courseName: 'Physics 2810A/B - PHYSICAL PROPERTIES OF MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255a47f7e10015fd7cc1',
      },
      {
        _id: '60cc255a47f7e10015fd7cc3',
        courseCode: 'PHYSICS 2910',
        shortName: 'Physics 2910F/G - INTRODUCTION TO PHYSICAL MEASUREMENT',
        courseName: 'Physics 2910F/G - INTRODUCTION TO PHYSICAL MEASUREMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255a47f7e10015fd7cc3',
      },
      {
        _id: '60cc255b47f7e10015fd7cc5',
        courseCode: 'PHYSICS 2930',
        shortName: 'Physics 2930A/B - SPECIAL TOPICS IN PHYSICS',
        courseName: 'Physics 2930A/B - SPECIAL TOPICS IN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255b47f7e10015fd7cc5',
      },
      {
        _id: '60cc255b47f7e10015fd7cc7',
        courseCode: 'PHYSICS 2950',
        shortName: 'Physics 2950Y - YEAR TWO SEMINAR',
        courseName: 'Physics 2950Y - YEAR TWO SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255b47f7e10015fd7cc7',
      },
      {
        _id: '60cc255b47f7e10015fd7cc9',
        courseCode: 'PHYSICS 3151',
        shortName: 'Physics 3151A/B - CLASSICAL MECHANICS I',
        courseName: 'Physics 3151A/B - CLASSICAL MECHANICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255b47f7e10015fd7cc9',
      },
      {
        _id: '60cc255c47f7e10015fd7ccb',
        courseCode: 'PHYSICS 3200',
        shortName: 'Physics 3200A/B - QUANTUM MECHANICS 1',
        courseName: 'Physics 3200A/B - QUANTUM MECHANICS 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255c47f7e10015fd7ccb',
      },
      {
        _id: '60cc255c47f7e10015fd7ccd',
        courseCode: 'PHYSICS 3300',
        shortName: 'Physics 3300A/B - ELECTROMAGNETIC THEORY I',
        courseName: 'Physics 3300A/B - ELECTROMAGNETIC THEORY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255c47f7e10015fd7ccd',
      },
      {
        _id: '60cc255c47f7e10015fd7ccf',
        courseCode: 'PHYSICS 3380',
        shortName: 'Physics 3380A/B - OPTICS AND PHOTONICS',
        courseName: 'Physics 3380A/B - OPTICS AND PHOTONICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255c47f7e10015fd7ccf',
      },
      {
        _id: '60cc255c47f7e10015fd7cd1',
        courseCode: 'PHYSICS 3400',
        shortName: 'Physics 3400A/B - INTRODUCTION TO THERMAL PHYSICS',
        courseName: 'Physics 3400A/B - INTRODUCTION TO THERMAL PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255c47f7e10015fd7cd1',
      },
      {
        _id: '60cc255d47f7e10015fd7cd3',
        courseCode: 'PHYSICS 3900',
        shortName: 'Physics 3900F/G/Z - SENIOR PHYSICS LABORATORY',
        courseName: 'Physics 3900F/G/Z - SENIOR PHYSICS LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255d47f7e10015fd7cd3',
      },
      {
        _id: '60cc255d47f7e10015fd7cd5',
        courseCode: 'PHYSICS 3926',
        shortName: 'Physics 3926F/G - COMPUTER SIMULATIONS IN PHYSICS',
        courseName: 'Physics 3926F/G - COMPUTER SIMULATIONS IN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255d47f7e10015fd7cd5',
      },
      {
        _id: '60cc255d47f7e10015fd7cd7',
        courseCode: 'PHYSICS 3930',
        shortName: 'Physics 3930A/B - SPECIAL TOPICS IN PHYSICS',
        courseName: 'Physics 3930A/B - SPECIAL TOPICS IN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255d47f7e10015fd7cd7',
      },
      {
        _id: '60cc255d47f7e10015fd7cd9',
        courseCode: 'PHYSICS 3950',
        shortName: 'Physics 3950Y - YEAR THREE SEMINAR',
        courseName: 'Physics 3950Y - YEAR THREE SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255d47f7e10015fd7cd9',
      },
      {
        _id: '60cc255e47f7e10015fd7cdb',
        courseCode: 'PHYSICS 4180',
        shortName: 'Physics 4180A/B - PHYSICAL FLUID DYNAMICS',
        courseName: 'Physics 4180A/B - PHYSICAL FLUID DYNAMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255e47f7e10015fd7cdb',
      },
      {
        _id: '60cc255e47f7e10015fd7cdd',
        courseCode: 'PHYSICS 4251',
        shortName: 'Physics 4251A/B - QUANTUM PHYSICS II',
        courseName: 'Physics 4251A/B - QUANTUM PHYSICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255e47f7e10015fd7cdd',
      },
      {
        _id: '60cc255e47f7e10015fd7cdf',
        courseCode: 'PHYSICS 4351',
        shortName: 'Physics 4351A/B - ELECTROMAGNETIC THEORY II',
        courseName: 'Physics 4351A/B - ELECTROMAGNETIC THEORY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255e47f7e10015fd7cdf',
      },
      {
        _id: '60cc255f47f7e10015fd7ce1',
        courseCode: 'PHYSICS 4662',
        shortName: 'Physics 4662A/B - MAGNETIC RESONANCE IMAGING',
        courseName: 'Physics 4662A/B - MAGNETIC RESONANCE IMAGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255f47f7e10015fd7ce1',
      },
      {
        _id: '60cc255f47f7e10015fd7ce3',
        courseCode: 'PHYSICS 4672',
        shortName: 'Physics 4672A/B - RADIOLOGICAL PHYSICS',
        courseName: 'Physics 4672A/B - RADIOLOGICAL PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255f47f7e10015fd7ce3',
      },
      {
        _id: '60cc255f47f7e10015fd7ce5',
        courseCode: 'PHYSICS 4700',
        shortName: 'Physics 4700A/B - ATMOSPHERES',
        courseName: 'Physics 4700A/B - ATMOSPHERES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255f47f7e10015fd7ce5',
      },
      {
        _id: '60cc255f47f7e10015fd7ce7',
        courseCode: 'PHYSICS 4810',
        shortName: 'Physics 4810A/B - ELECTRONIC MATERIALS AND DEVICES',
        courseName: 'Physics 4810A/B - ELECTRONIC MATERIALS AND DEVICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc255f47f7e10015fd7ce7',
      },
      {
        _id: '60cc256047f7e10015fd7ce9',
        courseCode: 'PHYSICS 4850',
        shortName: 'Physics 4850A/B - NANOMATERIALS',
        courseName: 'Physics 4850A/B - NANOMATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256047f7e10015fd7ce9',
      },
      {
        _id: '60cc256047f7e10015fd7ceb',
        courseCode: 'PHYSICS 4910',
        shortName: 'Physics 4910F/G - ADVANCED PHYSICS PROJECT',
        courseName: 'Physics 4910F/G - ADVANCED PHYSICS PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256047f7e10015fd7ceb',
      },
      {
        _id: '60cc256047f7e10015fd7ced',
        courseCode: 'PHYSICS 4930',
        shortName: 'Physics 4930A/B - SPECIAL TOPICS IN PHYSICS',
        courseName: 'Physics 4930A/B - SPECIAL TOPICS IN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256047f7e10015fd7ced',
      },
      {
        _id: '60cc256047f7e10015fd7cef',
        courseCode: 'PHYSICS 4931',
        shortName: 'Physics 4931A/B - SPECIAL TOPICS IN PHYSICS',
        courseName: 'Physics 4931A/B - SPECIAL TOPICS IN PHYSICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256047f7e10015fd7cef',
      },
      {
        _id: '60cc256147f7e10015fd7cf1',
        courseCode: 'PHYSICS 4950',
        shortName: 'Physics 4950Y - YEAR FOUR SEMINAR',
        courseName: 'Physics 4950Y - YEAR FOUR SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256147f7e10015fd7cf1',
      },
      {
        _id: '60cc256147f7e10015fd7cf3',
        courseCode: 'PHYSICS 4999',
        shortName: 'Physics 4999E - HONOURS RESEARCH',
        courseName: 'Physics 4999E - HONOURS RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256147f7e10015fd7cf3',
      },
      {
        _id: '60cc256147f7e10015fd7cf5',
        courseCode: 'PHYSIOL 1020',
        shortName: 'Physiology 1020 - HUMAN PHYSIOLOGY',
        courseName: 'Physiology 1020 - HUMAN PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256147f7e10015fd7cf5',
      },
      {
        _id: '60cc256147f7e10015fd7cf7',
        courseCode: 'PHYSIOL 1021',
        shortName: 'Physiology 1021 - INTRODUCTION TO HUMAN PHYSIOLOGY',
        courseName: 'Physiology 1021 - INTRODUCTION TO HUMAN PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256147f7e10015fd7cf7',
      },
      {
        _id: '60cc256247f7e10015fd7cf9',
        courseCode: 'PHYSIOL 2130',
        shortName: 'Physiology 2130 - HUMAN PHYSIOLOGY',
        courseName: 'Physiology 2130 - HUMAN PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256247f7e10015fd7cf9',
      },
      {
        _id: '60cc256247f7e10015fd7cfb',
        courseCode: 'PHYSIOL 3120',
        shortName: 'Physiology 3120 - HUMAN PHYSIOLOGY',
        courseName: 'Physiology 3120 - HUMAN PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256247f7e10015fd7cfb',
      },
      {
        _id: '60cc256247f7e10015fd7cfd',
        courseCode: 'PHYSIOL 3140',
        shortName: 'Physiology 3140A - CELLULAR PHYSIOLOGY',
        courseName: 'Physiology 3140A - CELLULAR PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256247f7e10015fd7cfd',
      },
      {
        _id: '60cc256247f7e10015fd7cff',
        courseCode: 'PHYSIOL 4200',
        shortName:
          'Physiology 4200A/B - CURRENT TOPICS IN RESPIRATORY HEALTH AND DISEASE',
        courseName:
          'Physiology 4200A/B - CURRENT TOPICS IN RESPIRATORY HEALTH AND DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256247f7e10015fd7cff',
      },
      {
        _id: '60cc256347f7e10015fd7d01',
        courseCode: 'PHYSIOL 4420',
        shortName: 'Physiology 4420A/B - PHYSIOLOGY OF EXERCISE',
        courseName: 'Physiology 4420A/B - PHYSIOLOGY OF EXERCISE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256347f7e10015fd7d01',
      },
      {
        _id: '60cc256347f7e10015fd7d03',
        courseCode: 'PHYSIOL 4510',
        shortName:
          'Physiology 4510A/B - UNDERSTANDING PLURIPOTENCY: THE PHYSIOLOGY OF STEM CELL FATE AND FUNCTION',
        courseName:
          'Physiology 4510A/B - UNDERSTANDING PLURIPOTENCY: THE PHYSIOLOGY OF STEM CELL FATE AND FUNCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256347f7e10015fd7d03',
      },
      {
        _id: '60cc256347f7e10015fd7d05',
        courseCode: 'PHYSIOL 4520',
        shortName: 'Physiology 4520A/B - STEM CELLS AND REGENERATIVE MEDICINE',
        courseName: 'Physiology 4520A/B - STEM CELLS AND REGENERATIVE MEDICINE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256347f7e10015fd7d05',
      },
      {
        _id: '60cc256347f7e10015fd7d07',
        courseCode: 'PHYSIOL 4600',
        shortName: 'Physiology 4600A/B - DISEASES OF ION CHANNELS',
        courseName: 'Physiology 4600A/B - DISEASES OF ION CHANNELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256347f7e10015fd7d07',
      },
      {
        _id: '60cc256447f7e10015fd7d09',
        courseCode: 'PHYSIOL 4610',
        shortName: 'Physiology 4610A/B - CARDIOVASCULAR PHYSIOLOGY',
        courseName: 'Physiology 4610A/B - CARDIOVASCULAR PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256447f7e10015fd7d09',
      },
      {
        _id: '60cc256447f7e10015fd7d0b',
        courseCode: 'PHYSIOL 4620',
        shortName: 'Physiology 4620A/B - REPRODUCTIVE ENDOCRINOLOGY',
        courseName: 'Physiology 4620A/B - REPRODUCTIVE ENDOCRINOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256447f7e10015fd7d0b',
      },
      {
        _id: '60cc256447f7e10015fd7d0d',
        courseCode: 'PHYSIOL 4630',
        shortName: 'Physiology 4630A/B - MOTOR NEUROPHYSIOLOGY',
        courseName: 'Physiology 4630A/B - MOTOR NEUROPHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256447f7e10015fd7d0d',
      },
      {
        _id: '60cc256447f7e10015fd7d0f',
        courseCode: 'PHYSIOL 4650',
        shortName:
          'Physiology 4650A/B - NEUROPHYSIOLOGY OF HOMEOSTASIS AND STRESS',
        courseName:
          'Physiology 4650A/B - NEUROPHYSIOLOGY OF HOMEOSTASIS AND STRESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256447f7e10015fd7d0f',
      },
      {
        _id: '60cc256447f7e10015fd7d11',
        courseCode: 'PHYSIOL 4660',
        shortName: 'Physiology 4660A/B - BODY WATER AND RENAL PHYSIOLOGY',
        courseName: 'Physiology 4660A/B - BODY WATER AND RENAL PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256447f7e10015fd7d11',
      },
      {
        _id: '60cc256547f7e10015fd7d13',
        courseCode: 'PHYSIOL 4680',
        shortName: 'Physiology 4680A/B - CELLULAR/MOLECULAR NEUROBIOLOGY',
        courseName: 'Physiology 4680A/B - CELLULAR/MOLECULAR NEUROBIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256547f7e10015fd7d13',
      },
      {
        _id: '60cc256547f7e10015fd7d15',
        courseCode: 'PHYSIOL 4700',
        shortName: 'Physiology 4700A/B - FETAL PHYSIOLOGY',
        courseName: 'Physiology 4700A/B - FETAL PHYSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256547f7e10015fd7d15',
      },
      {
        _id: '60cc256547f7e10015fd7d17',
        courseCode: 'PHYSIOL 4710',
        shortName: 'Physiology 4710A/B - PHYSIOLOGY OF THE SENSES',
        courseName: 'Physiology 4710A/B - PHYSIOLOGY OF THE SENSES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256547f7e10015fd7d17',
      },
      {
        _id: '60cc256547f7e10015fd7d19',
        courseCode: 'PHYSIOL 4730',
        shortName:
          'Physiology 4730B - CELL SIGNALLING IN TISSUE, INJURY AND REPAIR',
        courseName:
          'Physiology 4730B - CELL SIGNALLING IN TISSUE, INJURY AND REPAIR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256547f7e10015fd7d19',
      },
      {
        _id: '60cc256647f7e10015fd7d1b',
        courseCode: 'PHYSPHRM 3000',
        shortName:
          'Physiology and Pharmacology 3000E - PHYSIOLOGY AND PHARMACOLOGY LABORATORY',
        courseName:
          'Physiology and Pharmacology 3000E - PHYSIOLOGY AND PHARMACOLOGY LABORATORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256647f7e10015fd7d1b',
      },
      {
        _id: '60cc256647f7e10015fd7d1d',
        courseCode: 'PHYSPHRM 4100',
        shortName:
          'Physiology and Pharmacology 4100A/B - DIGESTION AND RELATED METABOLISM',
        courseName:
          'Physiology and Pharmacology 4100A/B - DIGESTION AND RELATED METABOLISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256647f7e10015fd7d1d',
      },
      {
        _id: '60cc256647f7e10015fd7d1f',
        courseCode: 'PHYSPHRM 4440',
        shortName:
          'Physiology and Pharmacology 4440A/B - ANIMAL AND CELL MODELLING OF DEVELOPMENT AND DISEASE',
        courseName:
          'Physiology and Pharmacology 4440A/B - ANIMAL AND CELL MODELLING OF DEVELOPMENT AND DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256647f7e10015fd7d1f',
      },
      {
        _id: '60cc256647f7e10015fd7d21',
        courseCode: 'PHYSPHRM 4530',
        shortName:
          'Physiology and Pharmacology 4530A/B - BIOLOGICAL BASES OF SKELETAL HEALTH AND DISEASE',
        courseName:
          'Physiology and Pharmacology 4530A/B - BIOLOGICAL BASES OF SKELETAL HEALTH AND DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256647f7e10015fd7d21',
      },
      {
        _id: '60cc256747f7e10015fd7d23',
        courseCode: 'PHYSPHRM 4800',
        shortName:
          'Physiology and Pharmacology 4800A/B - SELECTED TOPICS IN PHYSIOLOGY AND PHARMACOLOGY',
        courseName:
          'Physiology and Pharmacology 4800A/B - SELECTED TOPICS IN PHYSIOLOGY AND PHARMACOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256747f7e10015fd7d23',
      },
      {
        _id: '60cc256747f7e10015fd7d25',
        courseCode: 'PHYSPHRM 4980',
        shortName:
          'Physiology and Pharmacology 4980E - SEMINAR AND RESEARCH PROJECT',
        courseName:
          'Physiology and Pharmacology 4980E - SEMINAR AND RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256747f7e10015fd7d25',
      },
      {
        _id: '60cc256747f7e10015fd7d27',
        courseCode: 'PHYSPHRM 4999',
        shortName: 'Physiology and Pharmacology 4999E - ADVANCED RESEARCH',
        courseName: 'Physiology and Pharmacology 4999E - ADVANCED RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256747f7e10015fd7d27',
      },
      {
        _id: '60cc256747f7e10015fd7d29',
        courseCode: 'POLISCI 1020',
        shortName:
          'Political Science 1020E - INTRODUCTION TO POLITICAL SCIENCE',
        courseName:
          'Political Science 1020E - INTRODUCTION TO POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256747f7e10015fd7d29',
      },
      {
        _id: '60cc256847f7e10015fd7d2b',
        courseCode: 'POLISCI 1021',
        shortName: 'Political Science 1021F/G - PEOPLE, POWER AND THE STATE',
        courseName: 'Political Science 1021F/G - PEOPLE, POWER AND THE STATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256847f7e10015fd7d2b',
      },
      {
        _id: '60cc256847f7e10015fd7d2d',
        courseCode: 'POLISCI 1022',
        shortName:
          'Political Science 1022F/G - GLOBAL POLITICS AND CONTEMPORARY (DIS)ORDER',
        courseName:
          'Political Science 1022F/G - GLOBAL POLITICS AND CONTEMPORARY (DIS)ORDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256847f7e10015fd7d2d',
      },
      {
        _id: '60cc256947f7e10015fd7d2f',
        courseCode: 'POLISCI 2101',
        shortName:
          'Political Science 2101A/B - INTRODUCTION TO LAW AND PUBLIC POLICY',
        courseName:
          'Political Science 2101A/B - INTRODUCTION TO LAW AND PUBLIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256947f7e10015fd7d2f',
      },
      {
        _id: '60cc256947f7e10015fd7d31',
        courseCode: 'POLISCI 2102',
        shortName: 'Political Science 2102A/B - CAPITALISM AND DEMOCRACY',
        courseName: 'Political Science 2102A/B - CAPITALISM AND DEMOCRACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256947f7e10015fd7d31',
      },
      {
        _id: '60cc256947f7e10015fd7d33',
        courseCode: 'POLISCI 2103',
        shortName:
          'Political Science 2103A/B - CURRENT ISSUES IN CANADIAN POLITICS',
        courseName:
          'Political Science 2103A/B - CURRENT ISSUES IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256947f7e10015fd7d33',
      },
      {
        _id: '60cc256947f7e10015fd7d35',
        courseCode: 'POLISCI 2104',
        shortName:
          'Political Science 2104 - THE UNITED NATIONS IN THE NEW MILLENIUM',
        courseName:
          'Political Science 2104 - THE UNITED NATIONS IN THE NEW MILLENIUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256947f7e10015fd7d35',
      },
      {
        _id: '60cc256a47f7e10015fd7d37',
        courseCode: 'POLISCI 2130',
        shortName: 'Political Science 2130 - CANADIAN GOVERNMENT POLITICS',
        courseName: 'Political Science 2130 - CANADIAN GOVERNMENT POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256a47f7e10015fd7d37',
      },
      {
        _id: '60cc256a47f7e10015fd7d39',
        courseCode: 'POLISCI 2131',
        shortName: 'Political Science 2131 - INTERNATIONAL POLITICS',
        courseName: 'Political Science 2131 - INTERNATIONAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256a47f7e10015fd7d39',
      },
      {
        _id: '60cc256a47f7e10015fd7d3b',
        courseCode: 'POLISCI 2133',
        shortName:
          'Political Science 2133A/B - PROVINCES IN CANADIAN FEDERATION',
        courseName:
          'Political Science 2133A/B - PROVINCES IN CANADIAN FEDERATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256a47f7e10015fd7d3b',
      },
      {
        _id: '60cc256a47f7e10015fd7d3d',
        courseCode: 'POLISCI 2135',
        shortName: 'Political Science 2135A/B - CANADA ABROAD',
        courseName: 'Political Science 2135A/B - CANADA ABROAD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256a47f7e10015fd7d3d',
      },
      {
        _id: '60cc256b47f7e10015fd7d3f',
        courseCode: 'POLISCI 2137',
        shortName: 'Political Science 2137 - THE POLITICS OF THE ENVIRONMENT',
        courseName: 'Political Science 2137 - THE POLITICS OF THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256b47f7e10015fd7d3f',
      },
      {
        _id: '60cc256b47f7e10015fd7d41',
        courseCode: 'POLISCI 2140',
        shortName:
          'Political Science 2140A/B - GLOBALIZATION: COMPETITION AND DEMOCRATIC GOVERNANCE',
        courseName:
          'Political Science 2140A/B - GLOBALIZATION: COMPETITION AND DEMOCRATIC GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256b47f7e10015fd7d41',
      },
      {
        _id: '60cc256b47f7e10015fd7d43',
        courseCode: 'POLISCI 2141',
        shortName: 'Political Science 2141A/B - GLOBAL VIOLENCE AND  INJUSTICE',
        courseName:
          'Political Science 2141A/B - GLOBAL VIOLENCE AND  INJUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256b47f7e10015fd7d43',
      },
      {
        _id: '60cc256b47f7e10015fd7d45',
        courseCode: 'POLISCI 2142',
        shortName: 'Political Science 2142A/B - POLITICS AND POP CULTURE',
        courseName: 'Political Science 2142A/B - POLITICS AND POP CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256b47f7e10015fd7d45',
      },
      {
        _id: '60cc256c47f7e10015fd7d47',
        courseCode: 'POLISCI 2143',
        shortName:
          'Political Science 2143A/B - POLITICS AND MEDIA IN A POST-TRUTH ERA',
        courseName:
          'Political Science 2143A/B - POLITICS AND MEDIA IN A POST-TRUTH ERA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256c47f7e10015fd7d47',
      },
      {
        _id: '60cc256c47f7e10015fd7d49',
        courseCode: 'POLISCI 2144',
        shortName:
          'Political Science 2144A/B - POLITICO-TAINMENT: TELEVISION & POLITICAL (CON)SCIENCE',
        courseName:
          'Political Science 2144A/B - POLITICO-TAINMENT: TELEVISION & POLITICAL (CON)SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256c47f7e10015fd7d49',
      },
      {
        _id: '60cc256c47f7e10015fd7d4b',
        courseCode: 'POLISCI 2146',
        shortName: 'Political Science 2146 - PUBLIC ADMINISTRATION',
        courseName: 'Political Science 2146 - PUBLIC ADMINISTRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256c47f7e10015fd7d4b',
      },
      {
        _id: '60cc256d47f7e10015fd7d4d',
        courseCode: 'POLISCI 2190',
        shortName:
          'Political Science 2190 - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2190 - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256d47f7e10015fd7d4d',
      },
      {
        _id: '60cc256d47f7e10015fd7d4f',
        courseCode: 'POLISCI 2191',
        shortName:
          'Political Science 2191A/B - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2191A/B - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256d47f7e10015fd7d4f',
      },
      {
        _id: '60cc256d47f7e10015fd7d51',
        courseCode: 'POLISCI 2192',
        shortName:
          'Political Science 2192A/B - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2192A/B - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256d47f7e10015fd7d51',
      },
      {
        _id: '60cc256d47f7e10015fd7d53',
        courseCode: 'POLISCI 2200',
        shortName:
          'Political Science 2200F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2200F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256d47f7e10015fd7d53',
      },
      {
        _id: '60cc256e47f7e10015fd7d55',
        courseCode: 'POLISCI 2201',
        shortName:
          'Political Science 2201F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2201F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256e47f7e10015fd7d55',
      },
      {
        _id: '60cc256e47f7e10015fd7d57',
        courseCode: 'POLISCI 2202',
        shortName:
          'Political Science 2202E - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2202E - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256e47f7e10015fd7d57',
      },
      {
        _id: '60cc256e47f7e10015fd7d59',
        courseCode: 'POLISCI 2203',
        shortName:
          'Political Science 2203F/G - PROBLEMS IN TRANSITIONAL JUSTICE AND POST-CONFLICT RESOLUTION',
        courseName:
          'Political Science 2203F/G - PROBLEMS IN TRANSITIONAL JUSTICE AND POST-CONFLICT RESOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256e47f7e10015fd7d59',
      },
      {
        _id: '60cc256e47f7e10015fd7d5b',
        courseCode: 'POLISCI 2206',
        shortName:
          'Political Science 2206E - SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 2206E - SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256e47f7e10015fd7d5b',
      },
      {
        _id: '60cc256f47f7e10015fd7d5d',
        courseCode: 'POLISCI 2207',
        shortName:
          'Political Science 2207E - SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 2207E - SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256f47f7e10015fd7d5d',
      },
      {
        _id: '60cc256f47f7e10015fd7d5f',
        courseCode: 'POLISCI 2208',
        shortName:
          'Political Science 2208E - SPECIAL TOPICS IN INTERNATIONAL POLITICS',
        courseName:
          'Political Science 2208E - SPECIAL TOPICS IN INTERNATIONAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256f47f7e10015fd7d5f',
      },
      {
        _id: '60cc256f47f7e10015fd7d61',
        courseCode: 'POLISCI 2209',
        shortName:
          'Political Science 2209E - SPECIAL TOPICS IN COMPARITIVE POL',
        courseName:
          'Political Science 2209E - SPECIAL TOPICS IN COMPARITIVE POL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256f47f7e10015fd7d61',
      },
      {
        _id: '60cc256f47f7e10015fd7d63',
        courseCode: 'POLISCI 2210',
        shortName:
          'Political Science 2210E - SPECIAL TOPICS IN COMPARATIVE POLITICS: DEVELOPED COUNTRIES',
        courseName:
          'Political Science 2210E - SPECIAL TOPICS IN COMPARATIVE POLITICS: DEVELOPED COUNTRIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc256f47f7e10015fd7d63',
      },
      {
        _id: '60cc257047f7e10015fd7d65',
        courseCode: 'POLISCI 2211',
        shortName: 'Political Science 2211E - BUSINESS AND GOVERNMENT',
        courseName: 'Political Science 2211E - BUSINESS AND GOVERNMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257047f7e10015fd7d65',
      },
      {
        _id: '60cc257047f7e10015fd7d67',
        courseCode: 'POLISCI 2212',
        shortName:
          'Political Science 2212E - EXTREME LEADERSHIP: LEADERS, CRISES AND CONTROVERSIES IN MODERN POLITICS',
        courseName:
          'Political Science 2212E - EXTREME LEADERSHIP: LEADERS, CRISES AND CONTROVERSIES IN MODERN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257047f7e10015fd7d67',
      },
      {
        _id: '60cc257047f7e10015fd7d69',
        courseCode: 'POLISCI 2215',
        shortName:
          'Political Science 2215F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2215F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257047f7e10015fd7d69',
      },
      {
        _id: '60cc257147f7e10015fd7d6b',
        courseCode: 'POLISCI 2216',
        shortName:
          'Political Science 2216F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2216F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257147f7e10015fd7d6b',
      },
      {
        _id: '60cc257147f7e10015fd7d6d',
        courseCode: 'POLISCI 2217',
        shortName:
          'Political Science 2217F/G - AMERICAN GOVERNMENT AND POLITICS',
        courseName:
          'Political Science 2217F/G - AMERICAN GOVERNMENT AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257147f7e10015fd7d6d',
      },
      {
        _id: '60cc257147f7e10015fd7d6f',
        courseCode: 'POLISCI 2218',
        shortName:
          'Political Science 2218F/G - SOCIAL POLICY AND THE PUBLIC GOOD',
        courseName:
          'Political Science 2218F/G - SOCIAL POLICY AND THE PUBLIC GOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257147f7e10015fd7d6f',
      },
      {
        _id: '60cc257147f7e10015fd7d71',
        courseCode: 'POLISCI 2219',
        shortName: 'Political Science 2219E - THE POLITICS OF HUMAN RIGHTS',
        courseName: 'Political Science 2219E - THE POLITICS OF HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257147f7e10015fd7d71',
      },
      {
        _id: '60cc257247f7e10015fd7d73',
        courseCode: 'POLISCI 2221',
        shortName: 'Political Science 2221F/G - THE CANADIAN POLITY',
        courseName: 'Political Science 2221F/G - THE CANADIAN POLITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257247f7e10015fd7d73',
      },
      {
        _id: '60cc257247f7e10015fd7d75',
        courseCode: 'POLISCI 2222',
        shortName: 'Political Science 2222E - POLITICS AND THE MEDIA IN CANADA',
        courseName:
          'Political Science 2222E - POLITICS AND THE MEDIA IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257247f7e10015fd7d75',
      },
      {
        _id: '60cc257247f7e10015fd7d77',
        courseCode: 'POLISCI 2223',
        shortName: 'Political Science 2223F/G - THE CANADIAN REGIME',
        courseName: 'Political Science 2223F/G - THE CANADIAN REGIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257247f7e10015fd7d77',
      },
      {
        _id: '60cc257247f7e10015fd7d79',
        courseCode: 'POLISCI 2225',
        shortName:
          'Political Science 2225E - DEVELOPING COUNTRIES IN GLOBAL POLITICS',
        courseName:
          'Political Science 2225E - DEVELOPING COUNTRIES IN GLOBAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257247f7e10015fd7d79',
      },
      {
        _id: '60cc257347f7e10015fd7d7b',
        courseCode: 'POLISCI 2230',
        shortName: 'Political Science 2230E - CANADIAN GOVERNMENT AND POLITICS',
        courseName:
          'Political Science 2230E - CANADIAN GOVERNMENT AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257347f7e10015fd7d7b',
      },
      {
        _id: '60cc257347f7e10015fd7d7d',
        courseCode: 'POLISCI 2231',
        shortName: 'Political Science 2231E - INTERNATIONAL RELATIONS',
        courseName: 'Political Science 2231E - INTERNATIONAL RELATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257347f7e10015fd7d7d',
      },
      {
        _id: '60cc257447f7e10015fd7d7f',
        courseCode: 'POLISCI 2231',
        shortName: 'Political Science 2231W/X - INTERNATIONAL RELATIONS',
        courseName: 'Political Science 2231W/X - INTERNATIONAL RELATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257447f7e10015fd7d7f',
      },
      {
        _id: '60cc257447f7e10015fd7d81',
        courseCode: 'POLISCI 2236',
        shortName: 'Political Science 2236E - LOCAL GOVERNMENT',
        courseName: 'Political Science 2236E - LOCAL GOVERNMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257447f7e10015fd7d81',
      },
      {
        _id: '60cc257547f7e10015fd7d83',
        courseCode: 'POLISCI 2237',
        shortName: 'Political Science 2237E - INTRODUCTION TO POLITICAL THEORY',
        courseName:
          'Political Science 2237E - INTRODUCTION TO POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257547f7e10015fd7d83',
      },
      {
        _id: '60cc257547f7e10015fd7d85',
        courseCode: 'POLISCI 2237',
        shortName:
          'Political Science 2237W/X - INTRODUCTION TO POLITICAL THEORY',
        courseName:
          'Political Science 2237W/X - INTRODUCTION TO POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257547f7e10015fd7d85',
      },
      {
        _id: '60cc257547f7e10015fd7d87',
        courseCode: 'POLISCI 2238',
        shortName: 'Political Science 2238F/G - UNITED KINGDOM CONSTITUTION',
        courseName: 'Political Science 2238F/G - UNITED KINGDOM CONSTITUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257547f7e10015fd7d87',
      },
      {
        _id: '60cc257647f7e10015fd7d89',
        courseCode: 'POLISCI 2239',
        shortName: 'Political Science 2239F/G - AMERICAN CONSTITUTIONAL LAW',
        courseName: 'Political Science 2239F/G - AMERICAN CONSTITUTIONAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257647f7e10015fd7d89',
      },
      {
        _id: '60cc257647f7e10015fd7d8b',
        courseCode: 'POLISCI 2244',
        shortName: 'Political Science 2244E - AMERICAN GOVERNMENT AND POLITICS',
        courseName:
          'Political Science 2244E - AMERICAN GOVERNMENT AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257647f7e10015fd7d8b',
      },
      {
        _id: '60cc257647f7e10015fd7d8d',
        courseCode: 'POLISCI 2245',
        shortName:
          'Political Science 2245E - INTRODUCTION TO COMPARATIVE POLITICS',
        courseName:
          'Political Science 2245E - INTRODUCTION TO COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257647f7e10015fd7d8d',
      },
      {
        _id: '60cc257747f7e10015fd7d8f',
        courseCode: 'POLISCI 2246',
        shortName: 'Political Science 2246E - PUBLIC ADMINISTRATION AND POLICY',
        courseName:
          'Political Science 2246E - PUBLIC ADMINISTRATION AND POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257747f7e10015fd7d8f',
      },
      {
        _id: '60cc257747f7e10015fd7d91',
        courseCode: 'POLISCI 2248',
        shortName:
          'Political Science 2248E - WESTERN EUROPEAN POLITICICAL SYSTEMS',
        courseName:
          'Political Science 2248E - WESTERN EUROPEAN POLITICICAL SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257747f7e10015fd7d91',
      },
      {
        _id: '60cc257747f7e10015fd7d93',
        courseCode: 'POLISCI 2255',
        shortName: 'Political Science 2255F/G - WOMEN IN POLITICAL LIFE',
        courseName: 'Political Science 2255F/G - WOMEN IN POLITICAL LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257747f7e10015fd7d93',
      },
      {
        _id: '60cc257847f7e10015fd7d95',
        courseCode: 'POLISCI 2257',
        shortName: 'Political Science 2257 - GLOBAL POLITICAL ECONOMY',
        courseName: 'Political Science 2257 - GLOBAL POLITICAL ECONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257847f7e10015fd7d95',
      },
      {
        _id: '60cc257847f7e10015fd7d97',
        courseCode: 'POLISCI 2270',
        shortName: 'Political Science 2270E - WOMEN AND POLITICS',
        courseName: 'Political Science 2270E - WOMEN AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257847f7e10015fd7d97',
      },
      {
        _id: '60cc257847f7e10015fd7d99',
        courseCode: 'POLISCI 2276',
        shortName:
          'Political Science 2276F/G - THE POLICIES AND POLITICS OF HEALTH CARE',
        courseName:
          'Political Science 2276F/G - THE POLICIES AND POLITICS OF HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257847f7e10015fd7d99',
      },
      {
        _id: '60cc257947f7e10015fd7d9b',
        courseCode: 'POLISCI 2278',
        shortName: 'Political Science 2278F/G - COMPARATIVE FAMILY POLICY',
        courseName: 'Political Science 2278F/G - COMPARATIVE FAMILY POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257947f7e10015fd7d9b',
      },
      {
        _id: '60cc257947f7e10015fd7d9d',
        courseCode: 'POLISCI 2279',
        shortName: 'Political Science 2279F/G - FAMILY POLICY IN CANADA',
        courseName: 'Political Science 2279F/G - FAMILY POLICY IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257947f7e10015fd7d9d',
      },
      {
        _id: '60cc257947f7e10015fd7d9f',
        courseCode: 'POLISCI 2280',
        shortName:
          'Political Science 2280E - THE GOVERNMENT AND POLITICS OF CHINA',
        courseName:
          'Political Science 2280E - THE GOVERNMENT AND POLITICS OF CHINA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257947f7e10015fd7d9f',
      },
      {
        _id: '60cc257947f7e10015fd7da1',
        courseCode: 'POLISCI 2284',
        shortName:
          'Political Science 2284F/G - INSIDE GOVERNMENTS: POLICY MAKING IN CANADA AND THE UNITED STATES',
        courseName:
          'Political Science 2284F/G - INSIDE GOVERNMENTS: POLICY MAKING IN CANADA AND THE UNITED STATES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257947f7e10015fd7da1',
      },
      {
        _id: '60cc257a47f7e10015fd7da3',
        courseCode: 'POLISCI 2289',
        shortName: 'Political Science 2289F/G - POLITICAL GAMES',
        courseName: 'Political Science 2289F/G - POLITICAL GAMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257a47f7e10015fd7da3',
      },
      {
        _id: '60cc257a47f7e10015fd7da5',
        courseCode: 'POLISCI 2290',
        shortName:
          'Political Science 2290E - INTRODUCTION TO POLITICAL LEADERSHIP',
        courseName:
          'Political Science 2290E - INTRODUCTION TO POLITICAL LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257a47f7e10015fd7da5',
      },
      {
        _id: '60cc257a47f7e10015fd7da7',
        courseCode: 'POLISCI 2291',
        shortName:
          'Political Science 2291F/G - TERROR AND THE POLITICS OF FEAR',
        courseName:
          'Political Science 2291F/G - TERROR AND THE POLITICS OF FEAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257a47f7e10015fd7da7',
      },
      {
        _id: '60cc257a47f7e10015fd7da9',
        courseCode: 'POLISCI 2292',
        shortName: 'Political Science 2292F/G - POLITICAL LEADERSHIP',
        courseName: 'Political Science 2292F/G - POLITICAL LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257a47f7e10015fd7da9',
      },
      {
        _id: '60cc257b47f7e10015fd7dab',
        courseCode: 'POLISCI 2293',
        shortName:
          'Political Science 2293F/G - GENOCIDE, FAMINE, AND OTHER MASS ATROCITIES',
        courseName:
          'Political Science 2293F/G - GENOCIDE, FAMINE, AND OTHER MASS ATROCITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257b47f7e10015fd7dab',
      },
      {
        _id: '60cc257b47f7e10015fd7dad',
        courseCode: 'POLISCI 2294',
        shortName:
          'Political Science 2294F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2294F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257b47f7e10015fd7dad',
      },
      {
        _id: '60cc257b47f7e10015fd7daf',
        courseCode: 'POLISCI 2295',
        shortName:
          'Political Science 2295F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2295F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257b47f7e10015fd7daf',
      },
      {
        _id: '60cc257b47f7e10015fd7db1',
        courseCode: 'POLISCI 2297',
        shortName:
          'Political Science 2297F/G - CITIZENSHIP, BORDERS AND THE STATE',
        courseName:
          'Political Science 2297F/G - CITIZENSHIP, BORDERS AND THE STATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257b47f7e10015fd7db1',
      },
      {
        _id: '60cc257c47f7e10015fd7db3',
        courseCode: 'POLISCI 2298',
        shortName:
          'Political Science 2298F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2298F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257c47f7e10015fd7db3',
      },
      {
        _id: '60cc257c47f7e10015fd7db5',
        courseCode: 'POLISCI 2299',
        shortName:
          'Political Science 2299F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2299F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257c47f7e10015fd7db5',
      },
      {
        _id: '60cc257c47f7e10015fd7db7',
        courseCode: 'POLISCI 2325',
        shortName:
          'Political Science 2325F/G - RESEARCH DESIGN IN POLITICAL SCIENCE',
        courseName:
          'Political Science 2325F/G - RESEARCH DESIGN IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257c47f7e10015fd7db7',
      },
      {
        _id: '60cc257c47f7e10015fd7db9',
        courseCode: 'POLISCI 2423',
        shortName:
          'Political Science 2423F/G - RUSSIA AND UKRAINE: PAST AND PRESENT',
        courseName:
          'Political Science 2423F/G - RUSSIA AND UKRAINE: PAST AND PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257c47f7e10015fd7db9',
      },
      {
        _id: '60cc257d47f7e10015fd7dbb',
        courseCode: 'POLISCI 2530',
        shortName:
          'Political Science 2530F/G - FOUNDATIONS OF CANADIAN GOVERNMENT AND POLITICS',
        courseName:
          'Political Science 2530F/G - FOUNDATIONS OF CANADIAN GOVERNMENT AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257d47f7e10015fd7dbb',
      },
      {
        _id: '60cc257d47f7e10015fd7dbd',
        courseCode: 'POLISCI 2531',
        shortName:
          'Political Science 2531F/G - FOUNDATIONS OF INTERNATIONAL RELATIONS',
        courseName:
          'Political Science 2531F/G - FOUNDATIONS OF INTERNATIONAL RELATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257d47f7e10015fd7dbd',
      },
      {
        _id: '60cc257d47f7e10015fd7dbf',
        courseCode: 'POLISCI 2532',
        shortName: 'Political Science 2532F/G - INTERNATIONAL LAW AND ORDER',
        courseName: 'Political Science 2532F/G - INTERNATIONAL LAW AND ORDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257d47f7e10015fd7dbf',
      },
      {
        _id: '60cc257d47f7e10015fd7dc1',
        courseCode: 'POLISCI 2533',
        shortName: 'Political Science 2533F/G - RACIALIZED INJUSTICE IN CANADA',
        courseName:
          'Political Science 2533F/G - RACIALIZED INJUSTICE IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257d47f7e10015fd7dc1',
      },
      {
        _id: '60cc257e47f7e10015fd7dc3',
        courseCode: 'POLISCI 2537',
        shortName:
          'Political Science 2537F/G - FOUNDATIONS OF POLITICAL THEORY',
        courseName:
          'Political Science 2537F/G - FOUNDATIONS OF POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257e47f7e10015fd7dc3',
      },
      {
        _id: '60cc257e47f7e10015fd7dc5',
        courseCode: 'POLISCI 2538',
        shortName:
          'Political Science 2538F/G - ISSUES IN CONTEMPORARY POLITICAL THEORY',
        courseName:
          'Political Science 2538F/G - ISSUES IN CONTEMPORARY POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257e47f7e10015fd7dc5',
      },
      {
        _id: '60cc257e47f7e10015fd7dc7',
        courseCode: 'POLISCI 2544',
        shortName:
          'Political Science 2544F/G - FOUNDATIONS OF AMERICAN GOVERNMENT AND POLITICS',
        courseName:
          'Political Science 2544F/G - FOUNDATIONS OF AMERICAN GOVERNMENT AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257e47f7e10015fd7dc7',
      },
      {
        _id: '60cc257e47f7e10015fd7dc9',
        courseCode: 'POLISCI 2545',
        shortName: 'Political Science 2545F/G - COMPARATIVE POLITICS',
        courseName: 'Political Science 2545F/G - COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257e47f7e10015fd7dc9',
      },
      {
        _id: '60cc257f47f7e10015fd7dcb',
        courseCode: 'POLISCI 2546',
        shortName: 'Political Science 2546F/G - PUBLIC ADMINISTRATION',
        courseName: 'Political Science 2546F/G - PUBLIC ADMINISTRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257f47f7e10015fd7dcb',
      },
      {
        _id: '60cc257f47f7e10015fd7dcd',
        courseCode: 'POLISCI 2547',
        shortName:
          'Political Science 2547F/G - THE POLICY PROCESS IN THEORY AND PRACTICE',
        courseName:
          'Political Science 2547F/G - THE POLICY PROCESS IN THEORY AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257f47f7e10015fd7dcd',
      },
      {
        _id: '60cc257f47f7e10015fd7dcf',
        courseCode: 'POLISCI 3001',
        shortName:
          'Political Science 3001F/G - STUDIES IN TRANSITIONAL JUSTICE AND POST-CONFLICT RECONSTRUCTION',
        courseName:
          'Political Science 3001F/G - STUDIES IN TRANSITIONAL JUSTICE AND POST-CONFLICT RECONSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257f47f7e10015fd7dcf',
      },
      {
        _id: '60cc257f47f7e10015fd7dd1',
        courseCode: 'POLISCI 3200',
        shortName: 'Political Science 3200E - UNDERSTANDING SEPTEMBER 11TH',
        courseName: 'Political Science 3200E - UNDERSTANDING SEPTEMBER 11TH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc257f47f7e10015fd7dd1',
      },
      {
        _id: '60cc258047f7e10015fd7dd3',
        courseCode: 'POLISCI 3201',
        shortName: 'Political Science 3201F/G - ISSUES IN INTERNATIONAL LAW',
        courseName: 'Political Science 3201F/G - ISSUES IN INTERNATIONAL LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258047f7e10015fd7dd3',
      },
      {
        _id: '60cc258047f7e10015fd7dd5',
        courseCode: 'POLISCI 3203',
        shortName: 'Political Science 3203F/G - INTERNATIONAL CRISES',
        courseName: 'Political Science 3203F/G - INTERNATIONAL CRISES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258047f7e10015fd7dd5',
      },
      {
        _id: '60cc258047f7e10015fd7dd7',
        courseCode: 'POLISCI 3205',
        shortName: 'Political Science 3205F/G - AFRICA IN WORLD POLITICS',
        courseName: 'Political Science 3205F/G - AFRICA IN WORLD POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258047f7e10015fd7dd7',
      },
      {
        _id: '60cc258047f7e10015fd7dd9',
        courseCode: 'POLISCI 3206',
        shortName: 'Political Science 3206F/G - POLITICAL RISK ASSESSMENT',
        courseName: 'Political Science 3206F/G - POLITICAL RISK ASSESSMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258047f7e10015fd7dd9',
      },
      {
        _id: '60cc258147f7e10015fd7ddb',
        courseCode: 'POLISCI 3207',
        shortName: 'Political Science 3207F/G - WOMEN, SEX, AND POLITICS',
        courseName: 'Political Science 3207F/G - WOMEN, SEX, AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258147f7e10015fd7ddb',
      },
      {
        _id: '60cc258147f7e10015fd7ddd',
        courseCode: 'POLISCI 3208',
        shortName: 'Political Science 3208F/G - GLOBAL CLIMATE-CHANGE POLITICS',
        courseName:
          'Political Science 3208F/G - GLOBAL CLIMATE-CHANGE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258147f7e10015fd7ddd',
      },
      {
        _id: '60cc258147f7e10015fd7ddf',
        courseCode: 'POLISCI 3209',
        shortName: 'Political Science 3209F/G - FOREIGN POLICY ANALYSIS',
        courseName: 'Political Science 3209F/G - FOREIGN POLICY ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258147f7e10015fd7ddf',
      },
      {
        _id: '60cc258147f7e10015fd7de1',
        courseCode: 'POLISCI 3210',
        shortName: 'Political Science 3210F/G - CANADA-U.S. RELATIONS',
        courseName: 'Political Science 3210F/G - CANADA-U.S. RELATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258147f7e10015fd7de1',
      },
      {
        _id: '60cc258247f7e10015fd7de3',
        courseCode: 'POLISCI 3212',
        shortName: 'Political Science 3212F/G - THEORIES OF HUMAN RIGHTS',
        courseName: 'Political Science 3212F/G - THEORIES OF HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258247f7e10015fd7de3',
      },
      {
        _id: '60cc258247f7e10015fd7de5',
        courseCode: 'POLISCI 3213',
        shortName: 'Political Science 3213F/G - COMPARATIVE AUTHORITARIANISM',
        courseName: 'Political Science 3213F/G - COMPARATIVE AUTHORITARIANISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258247f7e10015fd7de5',
      },
      {
        _id: '60cc258247f7e10015fd7de7',
        courseCode: 'POLISCI 3215',
        shortName: 'Political Science 3215F/G - HISTORY OF POLITICAL THEORY',
        courseName: 'Political Science 3215F/G - HISTORY OF POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258247f7e10015fd7de7',
      },
      {
        _id: '60cc258247f7e10015fd7de9',
        courseCode: 'POLISCI 3301',
        shortName: 'Political Science 3301F/G - (IN)EQUALITY AND VIOLENCE',
        courseName: 'Political Science 3301F/G - (IN)EQUALITY AND VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258247f7e10015fd7de9',
      },
      {
        _id: '60cc258347f7e10015fd7deb',
        courseCode: 'POLISCI 3302',
        shortName: 'Political Science 3302F/G - IMMIGRATION & MULTICULTURALISM',
        courseName:
          'Political Science 3302F/G - IMMIGRATION & MULTICULTURALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258347f7e10015fd7deb',
      },
      {
        _id: '60cc258347f7e10015fd7ded',
        courseCode: 'POLISCI 3303',
        shortName: 'Political Science 3303F/G - INTRODUCTION TO CANADIAN LAW',
        courseName: 'Political Science 3303F/G - INTRODUCTION TO CANADIAN LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258347f7e10015fd7ded',
      },
      {
        _id: '60cc258347f7e10015fd7def',
        courseCode: 'POLISCI 3304',
        shortName: 'Political Science 3304F/G - POLITICAL IDENTITIES',
        courseName: 'Political Science 3304F/G - POLITICAL IDENTITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258347f7e10015fd7def',
      },
      {
        _id: '60cc258447f7e10015fd7df1',
        courseCode: 'POLISCI 3305',
        shortName: 'Political Science 3305F/G - CANADIAN FOREIGN POLICY',
        courseName: 'Political Science 3305F/G - CANADIAN FOREIGN POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258447f7e10015fd7df1',
      },
      {
        _id: '60cc258447f7e10015fd7df3',
        courseCode: 'POLISCI 3306',
        shortName:
          'Political Science 3306F/G - POLITICAL AUTHORITY AND RESISTANCE',
        courseName:
          'Political Science 3306F/G - POLITICAL AUTHORITY AND RESISTANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258447f7e10015fd7df3',
      },
      {
        _id: '60cc258447f7e10015fd7df5',
        courseCode: 'POLISCI 3307',
        shortName:
          'Political Science 3307F/G - POLITICS AND CONTEMPORARY SOCIAL POLICY',
        courseName:
          'Political Science 3307F/G - POLITICS AND CONTEMPORARY SOCIAL POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258447f7e10015fd7df5',
      },
      {
        _id: '60cc258547f7e10015fd7df7',
        courseCode: 'POLISCI 3308',
        shortName: 'Political Science 3308F/G - INFORMAL GLOBAL GOVERNANCE',
        courseName: 'Political Science 3308F/G - INFORMAL GLOBAL GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258547f7e10015fd7df7',
      },
      {
        _id: '60cc258547f7e10015fd7df9',
        courseCode: 'POLISCI 3311',
        shortName:
          'Political Science 3311F/G - CIVIL SOCIETY, COMMUNITY AND DEMOCRACY',
        courseName:
          'Political Science 3311F/G - CIVIL SOCIETY, COMMUNITY AND DEMOCRACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258547f7e10015fd7df9',
      },
      {
        _id: '60cc258547f7e10015fd7dfb',
        courseCode: 'POLISCI 3312',
        shortName: 'Political Science 3312F/G - PROTEST: POLITICS FROM BELOW',
        courseName: 'Political Science 3312F/G - PROTEST: POLITICS FROM BELOW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258547f7e10015fd7dfb',
      },
      {
        _id: '60cc258647f7e10015fd7dfd',
        courseCode: 'POLISCI 3313',
        shortName: 'Political Science 3313E - WEALTH AND POVERTY',
        courseName: 'Political Science 3313E - WEALTH AND POVERTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258647f7e10015fd7dfd',
      },
      {
        _id: '60cc258647f7e10015fd7dff',
        courseCode: 'POLISCI 3314',
        shortName: 'Political Science 3314E - GLOBAL ENVIRONMENTAL GOVERNANCE',
        courseName: 'Political Science 3314E - GLOBAL ENVIRONMENTAL GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258647f7e10015fd7dff',
      },
      {
        _id: '60cc258647f7e10015fd7e01',
        courseCode: 'POLISCI 3315',
        shortName: 'Political Science 3315F/G - SPECIAL TOPICS',
        courseName: 'Political Science 3315F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258647f7e10015fd7e01',
      },
      {
        _id: '60cc258647f7e10015fd7e03',
        courseCode: 'POLISCI 3316',
        shortName: 'Political Science 3316F/G - POLITICAL PARTIES',
        courseName: 'Political Science 3316F/G - POLITICAL PARTIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258647f7e10015fd7e03',
      },
      {
        _id: '60cc258747f7e10015fd7e05',
        courseCode: 'POLISCI 3317',
        shortName:
          'Political Science 3317F/G - INTEREST GROUPS AND SOCIAL MOVEMENTS',
        courseName:
          'Political Science 3317F/G - INTEREST GROUPS AND SOCIAL MOVEMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258747f7e10015fd7e05',
      },
      {
        _id: '60cc258747f7e10015fd7e07',
        courseCode: 'POLISCI 3318',
        shortName:
          'Political Science 3318F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3318F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258747f7e10015fd7e07',
      },
      {
        _id: '60cc258747f7e10015fd7e09',
        courseCode: 'POLISCI 3319',
        shortName: 'Political Science 3319F/G - FREEDOM, ORDER AND CONTROL',
        courseName: 'Political Science 3319F/G - FREEDOM, ORDER AND CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258747f7e10015fd7e09',
      },
      {
        _id: '60cc258847f7e10015fd7e0b',
        courseCode: 'POLISCI 3320',
        shortName:
          'Political Science 3320E - CITY STUDIO: WOMEN IN CIVIC LEADERSHIP',
        courseName:
          'Political Science 3320E - CITY STUDIO: WOMEN IN CIVIC LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258847f7e10015fd7e0b',
      },
      {
        _id: '60cc258847f7e10015fd7e0d',
        courseCode: 'POLISCI 3321',
        shortName: 'Political Science 3321F/G - POLITICS OF INDIA',
        courseName: 'Political Science 3321F/G - POLITICS OF INDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258847f7e10015fd7e0d',
      },
      {
        _id: '60cc258847f7e10015fd7e0f',
        courseCode: 'POLISCI 3322',
        shortName:
          'Political Science 3322F/G - LATIN AMERICA IN GLOBAL PERSPECTIVE',
        courseName:
          'Political Science 3322F/G - LATIN AMERICA IN GLOBAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258847f7e10015fd7e0f',
      },
      {
        _id: '60cc258947f7e10015fd7e11',
        courseCode: 'POLISCI 3324',
        shortName:
          'Political Science 3324F/G - INTRODUCTION TO RESEARCH METHODS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3324F/G - INTRODUCTION TO RESEARCH METHODS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258947f7e10015fd7e11',
      },
      {
        _id: '60cc258947f7e10015fd7e13',
        courseCode: 'POLISCI 3325',
        shortName:
          'Political Science 3325F/G - INTRODUCTION TO QUANTITATIVE METHODS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3325F/G - INTRODUCTION TO QUANTITATIVE METHODS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258947f7e10015fd7e13',
      },
      {
        _id: '60cc258947f7e10015fd7e15',
        courseCode: 'POLISCI 3326',
        shortName: 'Political Science 3326E - CANADIAN-AMERICAN RELATIONS',
        courseName: 'Political Science 3326E - CANADIAN-AMERICAN RELATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258947f7e10015fd7e15',
      },
      {
        _id: '60cc258a47f7e10015fd7e17',
        courseCode: 'POLISCI 3327',
        shortName:
          'Political Science 3327F/G - REPRESENTING DIVERSITY: PATHS TO POWER',
        courseName:
          'Political Science 3327F/G - REPRESENTING DIVERSITY: PATHS TO POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258a47f7e10015fd7e17',
      },
      {
        _id: '60cc258a47f7e10015fd7e19',
        courseCode: 'POLISCI 3329',
        shortName:
          'Political Science 3329F/G - THE POLITICS OF THE MIDDLE EAST',
        courseName:
          'Political Science 3329F/G - THE POLITICS OF THE MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258a47f7e10015fd7e19',
      },
      {
        _id: '60cc258a47f7e10015fd7e1b',
        courseCode: 'POLISCI 3331',
        shortName: 'Political Science 3331F/G - COURTS AND THE CONSTITUTION',
        courseName: 'Political Science 3331F/G - COURTS AND THE CONSTITUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258a47f7e10015fd7e1b',
      },
      {
        _id: '60cc258a47f7e10015fd7e1d',
        courseCode: 'POLISCI 3332',
        shortName:
          'Political Science 3332F/G - LAW, POLITICS, AND THE CANADIAN CHARTER OF RIGHTS AND FREEDOMS',
        courseName:
          'Political Science 3332F/G - LAW, POLITICS, AND THE CANADIAN CHARTER OF RIGHTS AND FREEDOMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258a47f7e10015fd7e1d',
      },
      {
        _id: '60cc258b47f7e10015fd7e1f',
        courseCode: 'POLISCI 3334',
        shortName: 'Political Science 3334E - CONTEMPORARY POLITICAL THEORY',
        courseName: 'Political Science 3334E - CONTEMPORARY POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258b47f7e10015fd7e1f',
      },
      {
        _id: '60cc258b47f7e10015fd7e21',
        courseCode: 'POLISCI 3336',
        shortName: 'Political Science 3336F/G - ELECTIONS AND VOTING',
        courseName: 'Political Science 3336F/G - ELECTIONS AND VOTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258b47f7e10015fd7e21',
      },
      {
        _id: '60cc258b47f7e10015fd7e23',
        courseCode: 'POLISCI 3337',
        shortName: 'Political Science 3337F/G - COMPARATIVE PUBLIC OPINION',
        courseName: 'Political Science 3337F/G - COMPARATIVE PUBLIC OPINION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258b47f7e10015fd7e23',
      },
      {
        _id: '60cc258c47f7e10015fd7e25',
        courseCode: 'POLISCI 3338',
        shortName: 'Political Science 3338F/G - POWER BEHIND THE THRONE',
        courseName: 'Political Science 3338F/G - POWER BEHIND THE THRONE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258c47f7e10015fd7e25',
      },
      {
        _id: '60cc258c47f7e10015fd7e27',
        courseCode: 'POLISCI 3339',
        shortName: 'Political Science 3339E - PUBLIC MANAGEMENT',
        courseName: 'Political Science 3339E - PUBLIC MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258c47f7e10015fd7e27',
      },
      {
        _id: '60cc258c47f7e10015fd7e29',
        courseCode: 'POLISCI 3340',
        shortName:
          'Political Science 3340F/G - THE RISE AND FALL OF COMMUNISM IN THE USSR AND EASTERN EUROPE',
        courseName:
          'Political Science 3340F/G - THE RISE AND FALL OF COMMUNISM IN THE USSR AND EASTERN EUROPE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258c47f7e10015fd7e29',
      },
      {
        _id: '60cc258c47f7e10015fd7e2b',
        courseCode: 'POLISCI 3342',
        shortName:
          'Political Science 3342F/G - POWER AND RESISTANCE: DISRUPTING RESEARCH',
        courseName:
          'Political Science 3342F/G - POWER AND RESISTANCE: DISRUPTING RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258c47f7e10015fd7e2b',
      },
      {
        _id: '60cc258d47f7e10015fd7e2d',
        courseCode: 'POLISCI 3343',
        shortName:
          'Political Science 3343F/G - EUROPEAN UNION: THE POLITICS OF INTEGRATION',
        courseName:
          'Political Science 3343F/G - EUROPEAN UNION: THE POLITICS OF INTEGRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258d47f7e10015fd7e2d',
      },
      {
        _id: '60cc258d47f7e10015fd7e2f',
        courseCode: 'POLISCI 3344',
        shortName:
          'Political Science 3344F/G - WESTERN EUROPEAN POLITICS: STATES, NATIONS AND REGIMES',
        courseName:
          'Political Science 3344F/G - WESTERN EUROPEAN POLITICS: STATES, NATIONS AND REGIMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258d47f7e10015fd7e2f',
      },
      {
        _id: '60cc258d47f7e10015fd7e31',
        courseCode: 'POLISCI 3345',
        shortName: 'Political Science 3345E - INTERNATIONAL LAW & ORGANIZATION',
        courseName:
          'Political Science 3345E - INTERNATIONAL LAW & ORGANIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258d47f7e10015fd7e31',
      },
      {
        _id: '60cc258e47f7e10015fd7e33',
        courseCode: 'POLISCI 3348',
        shortName: 'Political Science 3348F/G - FEDERALISM',
        courseName: 'Political Science 3348F/G - FEDERALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258e47f7e10015fd7e33',
      },
      {
        _id: '60cc258e47f7e10015fd7e35',
        courseCode: 'POLISCI 3349',
        shortName: 'Political Science 3349E - GOVERNING INTERGOVERNMENTALLY',
        courseName: 'Political Science 3349E - GOVERNING INTERGOVERNMENTALLY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258e47f7e10015fd7e35',
      },
      {
        _id: '60cc258f47f7e10015fd7e37',
        courseCode: 'POLISCI 3351',
        shortName: 'Political Science 3351E - THEORIES OF THE STATE',
        courseName: 'Political Science 3351E - THEORIES OF THE STATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258f47f7e10015fd7e37',
      },
      {
        _id: '60cc258f47f7e10015fd7e39',
        courseCode: 'POLISCI 3352',
        shortName: 'Political Science 3352E - ADVANCED INTERNATIONAL POLITICS',
        courseName: 'Political Science 3352E - ADVANCED INTERNATIONAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258f47f7e10015fd7e39',
      },
      {
        _id: '60cc258f47f7e10015fd7e3b',
        courseCode: 'POLISCI 3353',
        shortName:
          'Political Science 3353E - SECURITY STUDIES: THEORY AND GOVERNANCE',
        courseName:
          'Political Science 3353E - SECURITY STUDIES: THEORY AND GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258f47f7e10015fd7e3b',
      },
      {
        _id: '60cc258f47f7e10015fd7e3d',
        courseCode: 'POLISCI 3354',
        shortName: 'Political Science 3354F/G - GENDER AND THE INTERNATIONAL',
        courseName: 'Political Science 3354F/G - GENDER AND THE INTERNATIONAL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc258f47f7e10015fd7e3d',
      },
      {
        _id: '60cc259047f7e10015fd7e3f',
        courseCode: 'POLISCI 3355',
        shortName: 'Political Science 3355E - LAW, STATE AND DEMOCRACY',
        courseName: 'Political Science 3355E - LAW, STATE AND DEMOCRACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259047f7e10015fd7e3f',
      },
      {
        _id: '60cc259047f7e10015fd7e41',
        courseCode: 'POLISCI 3356',
        shortName:
          'Political Science 3356F/G - THE POLITICAL ECONOMY OF GLOBALISATION',
        courseName:
          'Political Science 3356F/G - THE POLITICAL ECONOMY OF GLOBALISATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259047f7e10015fd7e41',
      },
      {
        _id: '60cc259047f7e10015fd7e43',
        courseCode: 'POLISCI 3357',
        shortName: 'Political Science 3357E - INTERNATIONAL POLITICAL ECONOMY',
        courseName: 'Political Science 3357E - INTERNATIONAL POLITICAL ECONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259047f7e10015fd7e43',
      },
      {
        _id: '60cc259147f7e10015fd7e45',
        courseCode: 'POLISCI 3358',
        shortName:
          'Political Science 3358F/G - THE POLITICS OF ECONOMIC DEVELOPMENT',
        courseName:
          'Political Science 3358F/G - THE POLITICS OF ECONOMIC DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259147f7e10015fd7e45',
      },
      {
        _id: '60cc259147f7e10015fd7e47',
        courseCode: 'POLISCI 3359',
        shortName:
          'Political Science 3359F/G - POLITICAL SCIENCE AFRICAN POLITICS',
        courseName:
          'Political Science 3359F/G - POLITICAL SCIENCE AFRICAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259147f7e10015fd7e47',
      },
      {
        _id: '60cc259147f7e10015fd7e49',
        courseCode: 'POLISCI 3360',
        shortName: 'Political Science 3360F/G - MASS MEDIA AND POLITICS',
        courseName: 'Political Science 3360F/G - MASS MEDIA AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259147f7e10015fd7e49',
      },
      {
        _id: '60cc259147f7e10015fd7e4b',
        courseCode: 'POLISCI 3361',
        shortName: 'Political Science 3361F/G - AMERICAN BORDERS & BORDERLANDS',
        courseName:
          'Political Science 3361F/G - AMERICAN BORDERS & BORDERLANDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259147f7e10015fd7e4b',
      },
      {
        _id: '60cc259247f7e10015fd7e4d',
        courseCode: 'POLISCI 3363',
        shortName: 'Political Science 3363F/G - POWER IN THE CITY',
        courseName: 'Political Science 3363F/G - POWER IN THE CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259247f7e10015fd7e4d',
      },
      {
        _id: '60cc259247f7e10015fd7e4f',
        courseCode: 'POLISCI 3364',
        shortName: 'Political Science 3364F/G - ISSUES IN URBAN GOVERNANCE',
        courseName: 'Political Science 3364F/G - ISSUES IN URBAN GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259247f7e10015fd7e4f',
      },
      {
        _id: '60cc259247f7e10015fd7e51',
        courseCode: 'POLISCI 3365',
        shortName:
          'Political Science 3365F/G - ADVANCED TOPICS IN GLOBAL POLITICAL ECONOMY',
        courseName:
          'Political Science 3365F/G - ADVANCED TOPICS IN GLOBAL POLITICAL ECONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259247f7e10015fd7e51',
      },
      {
        _id: '60cc259347f7e10015fd7e53',
        courseCode: 'POLISCI 3366',
        shortName:
          'Political Science 3366E - INTERNATIONAL CONFLICT MANAGEMENT',
        courseName:
          'Political Science 3366E - INTERNATIONAL CONFLICT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259347f7e10015fd7e53',
      },
      {
        _id: '60cc259347f7e10015fd7e55',
        courseCode: 'POLISCI 3367',
        shortName:
          'Political Science 3367F/G - POLITICAL ECONOMY: NORTH AMERICA',
        courseName:
          'Political Science 3367F/G - POLITICAL ECONOMY: NORTH AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259347f7e10015fd7e55',
      },
      {
        _id: '60cc259347f7e10015fd7e57',
        courseCode: 'POLISCI 3368',
        shortName: 'Political Science 3368E - CITY-REGIONS IN A GLOBAL AGE',
        courseName: 'Political Science 3368E - CITY-REGIONS IN A GLOBAL AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259347f7e10015fd7e57',
      },
      {
        _id: '60cc259447f7e10015fd7e59',
        courseCode: 'POLISCI 3369',
        shortName:
          'Political Science 3369F/G - INTERNATIONAL  LAW AND ORGANIZATION',
        courseName:
          'Political Science 3369F/G - INTERNATIONAL  LAW AND ORGANIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259447f7e10015fd7e59',
      },
      {
        _id: '60cc259447f7e10015fd7e5b',
        courseCode: 'POLISCI 3370',
        shortName: 'Political Science 3370F/G - POLITICS OF WESTERN CANADA',
        courseName: 'Political Science 3370F/G - POLITICS OF WESTERN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259447f7e10015fd7e5b',
      },
      {
        _id: '60cc259447f7e10015fd7e5d',
        courseCode: 'POLISCI 3371',
        shortName: 'Political Science 3371F/G - THE POLITICS OF QUEBEC',
        courseName: 'Political Science 3371F/G - THE POLITICS OF QUEBEC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259447f7e10015fd7e5d',
      },
      {
        _id: '60cc259447f7e10015fd7e5f',
        courseCode: 'POLISCI 3372',
        shortName: 'Political Science 3372F/G - THE POLITICS OF ONTARIO',
        courseName: 'Political Science 3372F/G - THE POLITICS OF ONTARIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259447f7e10015fd7e5f',
      },
      {
        _id: '60cc259547f7e10015fd7e61',
        courseCode: 'POLISCI 3374',
        shortName:
          'Political Science 3374E - SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 3374E - SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259547f7e10015fd7e61',
      },
      {
        _id: '60cc259547f7e10015fd7e63',
        courseCode: 'POLISCI 3375',
        shortName:
          'Political Science 3375E - SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 3375E - SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259547f7e10015fd7e63',
      },
      {
        _id: '60cc259547f7e10015fd7e65',
        courseCode: 'POLISCI 3376',
        shortName:
          'Political Science 3376E - SPECIAL TOPICS IN INTERNATIONAL POLITICS',
        courseName:
          'Political Science 3376E - SPECIAL TOPICS IN INTERNATIONAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259547f7e10015fd7e65',
      },
      {
        _id: '60cc259647f7e10015fd7e67',
        courseCode: 'POLISCI 3377',
        shortName:
          'Political Science 3377E - SPECIAL TOPICS IN COMPARATIVE POLITICS',
        courseName:
          'Political Science 3377E - SPECIAL TOPICS IN COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259647f7e10015fd7e67',
      },
      {
        _id: '60cc259647f7e10015fd7e69',
        courseCode: 'POLISCI 3378',
        shortName:
          'Political Science 3378E - SPECIAL TOPICS IN COMPARATIVE POLITICS: DEVELOPED COUNTRIES',
        courseName:
          'Political Science 3378E - SPECIAL TOPICS IN COMPARATIVE POLITICS: DEVELOPED COUNTRIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259647f7e10015fd7e69',
      },
      {
        _id: '60cc259647f7e10015fd7e6b',
        courseCode: 'POLISCI 3379',
        shortName: 'Political Science 3379E - GLOBAL ENVIRONMENTAL POLITICS',
        courseName: 'Political Science 3379E - GLOBAL ENVIRONMENTAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259647f7e10015fd7e6b',
      },
      {
        _id: '60cc259647f7e10015fd7e6d',
        courseCode: 'POLISCI 3380',
        shortName: 'Political Science 3380E - GOVERNANCE IN THE ASIA-PACIFIC',
        courseName: 'Political Science 3380E - GOVERNANCE IN THE ASIA-PACIFIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259647f7e10015fd7e6d',
      },
      {
        _id: '60cc259747f7e10015fd7e6f',
        courseCode: 'POLISCI 3381',
        shortName: 'Political Science 3381F/G - ETHNIC CONFLICT AND RESOLUTION',
        courseName:
          'Political Science 3381F/G - ETHNIC CONFLICT AND RESOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259747f7e10015fd7e6f',
      },
      {
        _id: '60cc259747f7e10015fd7e71',
        courseCode: 'POLISCI 3382',
        shortName:
          "Political Science 3382F/G - CHINA'S INTERNATIONAL RELATIONS",
        courseName:
          "Political Science 3382F/G - CHINA'S INTERNATIONAL RELATIONS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259747f7e10015fd7e71',
      },
      {
        _id: '60cc259747f7e10015fd7e73',
        courseCode: 'POLISCI 3383',
        shortName:
          "Political Science 3383F/G - JAPAN'S INTERNATIONAL RELATIONS",
        courseName:
          "Political Science 3383F/G - JAPAN'S INTERNATIONAL RELATIONS",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259747f7e10015fd7e73',
      },
      {
        _id: '60cc259847f7e10015fd7e75',
        courseCode: 'POLISCI 3384',
        shortName:
          'Political Science 3384F/G - MEDIA AND ELECTIONS: CAMPAIGNS, CANDIDATES AND COVERAGE',
        courseName:
          'Political Science 3384F/G - MEDIA AND ELECTIONS: CAMPAIGNS, CANDIDATES AND COVERAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259847f7e10015fd7e75',
      },
      {
        _id: '60cc259847f7e10015fd7e77',
        courseCode: 'POLISCI 3385',
        shortName:
          'Political Science 3385F/G - POLITICAL COMMUNICATIONS IN CANADA: IMAGE, INFLUENCE, IMPACT',
        courseName:
          'Political Science 3385F/G - POLITICAL COMMUNICATIONS IN CANADA: IMAGE, INFLUENCE, IMPACT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259847f7e10015fd7e77',
      },
      {
        _id: '60cc259847f7e10015fd7e79',
        courseCode: 'POLISCI 3386',
        shortName:
          'Political Science 3386F/G - THE GLOBALISATION OF NEOLIBERALISM',
        courseName:
          'Political Science 3386F/G - THE GLOBALISATION OF NEOLIBERALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259847f7e10015fd7e79',
      },
      {
        _id: '60cc259847f7e10015fd7e7b',
        courseCode: 'POLISCI 3387',
        shortName:
          'Political Science 3387F/G - SURVEILLANCE, SECURITY, AND SOCIETY',
        courseName:
          'Political Science 3387F/G - SURVEILLANCE, SECURITY, AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259847f7e10015fd7e7b',
      },
      {
        _id: '60cc259947f7e10015fd7e7d',
        courseCode: 'POLISCI 3388',
        shortName: 'Political Science 3388E - INTERNATIONAL HUMAN RIGHTS',
        courseName: 'Political Science 3388E - INTERNATIONAL HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259947f7e10015fd7e7d',
      },
      {
        _id: '60cc259947f7e10015fd7e7f',
        courseCode: 'POLISCI 3389',
        shortName: 'Political Science 3389F/G - POLITICS AND POP CULTURE',
        courseName: 'Political Science 3389F/G - POLITICS AND POP CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259947f7e10015fd7e7f',
      },
      {
        _id: '60cc259947f7e10015fd7e81',
        courseCode: 'POLISCI 3390',
        shortName:
          'Political Science 3390F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3390F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259947f7e10015fd7e81',
      },
      {
        _id: '60cc259a47f7e10015fd7e83',
        courseCode: 'POLISCI 3391',
        shortName:
          'Political Science 3391F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3391F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259a47f7e10015fd7e83',
      },
      {
        _id: '60cc259a47f7e10015fd7e85',
        courseCode: 'POLISCI 3392',
        shortName:
          'Political Science 3392E - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3392E - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259a47f7e10015fd7e85',
      },
      {
        _id: '60cc259a47f7e10015fd7e87',
        courseCode: 'POLISCI 3393',
        shortName:
          'Political Science 3393E - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3393E - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259a47f7e10015fd7e87',
      },
      {
        _id: '60cc259b47f7e10015fd7e89',
        courseCode: 'POLISCI 3394',
        shortName:
          'Political Science 3394F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3394F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259b47f7e10015fd7e89',
      },
      {
        _id: '60cc259b47f7e10015fd7e8b',
        courseCode: 'POLISCI 3395',
        shortName:
          'Political Science 3395F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3395F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259b47f7e10015fd7e8b',
      },
      {
        _id: '60cc259b47f7e10015fd7e8d',
        courseCode: 'POLISCI 3396',
        shortName:
          'Political Science 3396F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3396F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259b47f7e10015fd7e8d',
      },
      {
        _id: '60cc259c47f7e10015fd7e8f',
        courseCode: 'POLISCI 3397',
        shortName:
          'Political Science 3397F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3397F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259c47f7e10015fd7e8f',
      },
      {
        _id: '60cc259c47f7e10015fd7e91',
        courseCode: 'POLISCI 3398',
        shortName:
          'Political Science 3398F/G - INDIGENOUS POLITICAL AND LEGAL ISSUES',
        courseName:
          'Political Science 3398F/G - INDIGENOUS POLITICAL AND LEGAL ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259c47f7e10015fd7e91',
      },
      {
        _id: '60cc259c47f7e10015fd7e93',
        courseCode: 'POLISCI 3399',
        shortName: 'Political Science 3399F/G - INDEPENDENT STUDY',
        courseName: 'Political Science 3399F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259c47f7e10015fd7e93',
      },
      {
        _id: '60cc259d47f7e10015fd7e95',
        courseCode: 'POLISCI 3400',
        shortName: 'Political Science 3400F/G - POLITICS OF THE ARCTIC',
        courseName: 'Political Science 3400F/G - POLITICS OF THE ARCTIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259d47f7e10015fd7e95',
      },
      {
        _id: '60cc259d47f7e10015fd7e97',
        courseCode: 'POLISCI 3401',
        shortName:
          'Political Science 3401F/G - SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 3401F/G - SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259d47f7e10015fd7e97',
      },
      {
        _id: '60cc259d47f7e10015fd7e99',
        courseCode: 'POLISCI 3402',
        shortName:
          'Political Science 3402F/G - SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 3402F/G - SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259d47f7e10015fd7e99',
      },
      {
        _id: '60cc259d47f7e10015fd7e9b',
        courseCode: 'POLISCI 3404',
        shortName: 'Political Science 3404F/G - GOVERNMENT MANAGEMENT',
        courseName: 'Political Science 3404F/G - GOVERNMENT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259d47f7e10015fd7e9b',
      },
      {
        _id: '60cc259e47f7e10015fd7e9d',
        courseCode: 'POLISCI 3405',
        shortName: 'Political Science 3405F/G - SPECIAL TOPICS',
        courseName: 'Political Science 3405F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259e47f7e10015fd7e9d',
      },
      {
        _id: '60cc259e47f7e10015fd7e9f',
        courseCode: 'POLISCI 3406',
        shortName: 'Political Science 3406F/G - SPECIAL TOPICS',
        courseName: 'Political Science 3406F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259e47f7e10015fd7e9f',
      },
      {
        _id: '60cc259e47f7e10015fd7ea1',
        courseCode: 'POLISCI 3407',
        shortName: 'Political Science 3407F/G - SPECIAL TOPICS',
        courseName: 'Political Science 3407F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259e47f7e10015fd7ea1',
      },
      {
        _id: '60cc259e47f7e10015fd7ea3',
        courseCode: 'POLISCI 3408',
        shortName: 'Political Science 3408F/G - SPECIAL TOPICS',
        courseName: 'Political Science 3408F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259e47f7e10015fd7ea3',
      },
      {
        _id: '60cc259f47f7e10015fd7ea5',
        courseCode: 'POLISCI 3409',
        shortName: 'Political Science 3409F/G - SPECIAL TOPICS',
        courseName: 'Political Science 3409F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259f47f7e10015fd7ea5',
      },
      {
        _id: '60cc259f47f7e10015fd7ea7',
        courseCode: 'POLISCI 3411',
        shortName:
          'Political Science 3411F/G - SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        courseName:
          'Political Science 3411F/G - SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259f47f7e10015fd7ea7',
      },
      {
        _id: '60cc259f47f7e10015fd7ea9',
        courseCode: 'POLISCI 3412',
        shortName:
          'Political Science 3412F/G - SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        courseName:
          'Political Science 3412F/G - SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259f47f7e10015fd7ea9',
      },
      {
        _id: '60cc259f47f7e10015fd7eab',
        courseCode: 'POLISCI 3421',
        shortName:
          'Political Science 3421F/G - SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 3421F/G - SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc259f47f7e10015fd7eab',
      },
      {
        _id: '60cc25a047f7e10015fd7ead',
        courseCode: 'POLISCI 3422',
        shortName:
          'Political Science 3422F/G - SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 3422F/G - SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a047f7e10015fd7ead',
      },
      {
        _id: '60cc25a047f7e10015fd7eb0',
        courseCode: 'POLISCI 3501',
        shortName:
          'Political Science 3501F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3501F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a047f7e10015fd7eb0',
      },
      {
        _id: '60cc25a047f7e10015fd7eb2',
        courseCode: 'POLISCI 3502',
        shortName:
          'Political Science 3502F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 3502F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a047f7e10015fd7eb2',
      },
      {
        _id: '60cc25a047f7e10015fd7eb4',
        courseCode: 'POLISCI 4201',
        shortName: 'Political Science 4201F/G - THE UN AND GLOBAL GOVERNANCE',
        courseName: 'Political Science 4201F/G - THE UN AND GLOBAL GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a047f7e10015fd7eb4',
      },
      {
        _id: '60cc25a147f7e10015fd7eb6',
        courseCode: 'POLISCI 4203',
        shortName:
          'Political Science 4203F/G - SOCIAL DIVERSITY, GENDER AND THE LAW',
        courseName:
          'Political Science 4203F/G - SOCIAL DIVERSITY, GENDER AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a147f7e10015fd7eb6',
      },
      {
        _id: '60cc25a147f7e10015fd7eb8',
        courseCode: 'POLISCI 4204',
        shortName: 'Political Science 4204F/G - THE POLITICS OF RACE',
        courseName: 'Political Science 4204F/G - THE POLITICS OF RACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a147f7e10015fd7eb8',
      },
      {
        _id: '60cc25a147f7e10015fd7eba',
        courseCode: 'POLISCI 4205',
        shortName:
          'Political Science 4205F/G - COGNITIVE DIMENSIONS OF POLITICS',
        courseName:
          'Political Science 4205F/G - COGNITIVE DIMENSIONS OF POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a147f7e10015fd7eba',
      },
      {
        _id: '60cc25a147f7e10015fd7ebc',
        courseCode: 'POLISCI 4206',
        shortName: 'Political Science 4206F/G - THEORIES OF GLOBAL JUSTICE',
        courseName: 'Political Science 4206F/G - THEORIES OF GLOBAL JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a147f7e10015fd7ebc',
      },
      {
        _id: '60cc25a247f7e10015fd7ebe',
        courseCode: 'POLISCI 4207',
        shortName: 'Political Science 4207F/G - THEORIES OF DEMOCRACY',
        courseName: 'Political Science 4207F/G - THEORIES OF DEMOCRACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a247f7e10015fd7ebe',
      },
      {
        _id: '60cc25a247f7e10015fd7ec0',
        courseCode: 'POLISCI 4208',
        shortName:
          'Political Science 4208F/G - POLITICS AND  MEDIA IN A GLOBALIZED WORLD',
        courseName:
          'Political Science 4208F/G - POLITICS AND  MEDIA IN A GLOBALIZED WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a247f7e10015fd7ec0',
      },
      {
        _id: '60cc25a247f7e10015fd7ec2',
        courseCode: 'POLISCI 4209',
        shortName:
          'Political Science 4209F/G - POLITICAL STRATEGY AND COMMUNICATION',
        courseName:
          'Political Science 4209F/G - POLITICAL STRATEGY AND COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a247f7e10015fd7ec2',
      },
      {
        _id: '60cc25a247f7e10015fd7ec4',
        courseCode: 'POLISCI 4210',
        shortName:
          'Political Science 4210F/G - GLOBALIZATION AND URBAN POLITICS',
        courseName:
          'Political Science 4210F/G - GLOBALIZATION AND URBAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a247f7e10015fd7ec4',
      },
      {
        _id: '60cc25a347f7e10015fd7ec6',
        courseCode: 'POLISCI 4211',
        shortName:
          'Political Science 4211F/G - CHALLENGING THE NATION-STATE: THE POLITICS OF MULTILEVEL GOVERNANCE',
        courseName:
          'Political Science 4211F/G - CHALLENGING THE NATION-STATE: THE POLITICS OF MULTILEVEL GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a347f7e10015fd7ec6',
      },
      {
        _id: '60cc25a347f7e10015fd7ec8',
        courseCode: 'POLISCI 4213',
        shortName: 'Political Science 4213F/G - COMPARATIVE GLOBAL CORRUPTION',
        courseName: 'Political Science 4213F/G - COMPARATIVE GLOBAL CORRUPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a347f7e10015fd7ec8',
      },
      {
        _id: '60cc25a347f7e10015fd7eca',
        courseCode: 'POLISCI 4215',
        shortName: 'Political Science 4215F/G - GLOBAL WELFARE STATES',
        courseName: 'Political Science 4215F/G - GLOBAL WELFARE STATES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a347f7e10015fd7eca',
      },
      {
        _id: '60cc25a347f7e10015fd7ecc',
        courseCode: 'POLISCI 4401',
        shortName: 'Political Science 4401F/G - AMERICAN FOREIGN POLICY',
        courseName: 'Political Science 4401F/G - AMERICAN FOREIGN POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a347f7e10015fd7ecc',
      },
      {
        _id: '60cc25a447f7e10015fd7ece',
        courseCode: 'POLISCI 4402',
        shortName:
          'Political Science 4402F/G - DIPLOMACY AND INTERNATIONAL NEGOTIATIONS',
        courseName:
          'Political Science 4402F/G - DIPLOMACY AND INTERNATIONAL NEGOTIATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a447f7e10015fd7ece',
      },
      {
        _id: '60cc25a447f7e10015fd7ed0',
        courseCode: 'POLISCI 4403',
        shortName: 'Political Science 4403F/G - WAR ON TERROR',
        courseName: 'Political Science 4403F/G - WAR ON TERROR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a447f7e10015fd7ed0',
      },
      {
        _id: '60cc25a447f7e10015fd7ed2',
        courseCode: 'POLISCI 4404',
        shortName:
          'Political Science 4404F/G - GLOBALIZATION AND NATIONAL SOVEREIGNTY',
        courseName:
          'Political Science 4404F/G - GLOBALIZATION AND NATIONAL SOVEREIGNTY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a447f7e10015fd7ed2',
      },
      {
        _id: '60cc25a447f7e10015fd7ed4',
        courseCode: 'POLISCI 4405',
        shortName: 'Political Science 4405E - LEGISLATIVE POLITICS',
        courseName: 'Political Science 4405E - LEGISLATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a447f7e10015fd7ed4',
      },
      {
        _id: '60cc25a547f7e10015fd7ed6',
        courseCode: 'POLISCI 4406',
        shortName: 'Political Science 4406F/G - THE EUROPEAN WELFARE STATE',
        courseName: 'Political Science 4406F/G - THE EUROPEAN WELFARE STATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a547f7e10015fd7ed6',
      },
      {
        _id: '60cc25a547f7e10015fd7ed8',
        courseCode: 'POLISCI 4407',
        shortName: 'Political Science 4407F/G - BODY POLITICS',
        courseName: 'Political Science 4407F/G - BODY POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a547f7e10015fd7ed8',
      },
      {
        _id: '60cc25a547f7e10015fd7eda',
        courseCode: 'POLISCI 4408',
        shortName: 'Political Science 4408F/G - GLOBAL SECURITY',
        courseName: 'Political Science 4408F/G - GLOBAL SECURITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a547f7e10015fd7eda',
      },
      {
        _id: '60cc25a547f7e10015fd7edc',
        courseCode: 'POLISCI 4409',
        shortName:
          'Political Science 4409F/G - THE UNITED STATES AND THE MIDDLE EAST',
        courseName:
          'Political Science 4409F/G - THE UNITED STATES AND THE MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a547f7e10015fd7edc',
      },
      {
        _id: '60cc25a647f7e10015fd7ede',
        courseCode: 'POLISCI 4411',
        shortName: 'Political Science 4411F/G - THREATS TO GLOBAL DEMOCRACY',
        courseName: 'Political Science 4411F/G - THREATS TO GLOBAL DEMOCRACY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a647f7e10015fd7ede',
      },
      {
        _id: '60cc25a647f7e10015fd7ee0',
        courseCode: 'POLISCI 4413',
        shortName: 'Political Science 4413F/G - SPECIAL TOPICS',
        courseName: 'Political Science 4413F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a647f7e10015fd7ee0',
      },
      {
        _id: '60cc25a647f7e10015fd7ee2',
        courseCode: 'POLISCI 4417',
        shortName:
          'Political Science 4417F/G - ADVANCED SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4417F/G - ADVANCED SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a647f7e10015fd7ee2',
      },
      {
        _id: '60cc25a747f7e10015fd7ee4',
        courseCode: 'POLISCI 4418',
        shortName:
          'Political Science 4418F/G - THE POLITICS OF INTERNATIONAL TRADE LAW AND NEGOTIATION',
        courseName:
          'Political Science 4418F/G - THE POLITICS OF INTERNATIONAL TRADE LAW AND NEGOTIATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a747f7e10015fd7ee4',
      },
      {
        _id: '60cc25a747f7e10015fd7ee6',
        courseCode: 'POLISCI 4419',
        shortName:
          'Political Science 4419F/G - ADVANCED SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4419F/G - ADVANCED SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a747f7e10015fd7ee6',
      },
      {
        _id: '60cc25a747f7e10015fd7ee8',
        courseCode: 'POLISCI 4421',
        shortName: 'Political Science 4421F/G - NEGOTIATING PEACE',
        courseName: 'Political Science 4421F/G - NEGOTIATING PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a747f7e10015fd7ee8',
      },
      {
        _id: '60cc25a747f7e10015fd7eea',
        courseCode: 'POLISCI 4423',
        shortName:
          'Political Science 4423F/G - NATIONALISM, REBELLION, AND SEPARATISM',
        courseName:
          'Political Science 4423F/G - NATIONALISM, REBELLION, AND SEPARATISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a747f7e10015fd7eea',
      },
      {
        _id: '60cc25a847f7e10015fd7eec',
        courseCode: 'POLISCI 4424',
        shortName:
          'Political Science 4424F/G - NATIONALIST CONFLICTS AND PATHS TO PEACE',
        courseName:
          'Political Science 4424F/G - NATIONALIST CONFLICTS AND PATHS TO PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a847f7e10015fd7eec',
      },
      {
        _id: '60cc25a847f7e10015fd7eee',
        courseCode: 'POLISCI 4426',
        shortName: 'Political Science 4426F/G - GENOCIDE',
        courseName: 'Political Science 4426F/G - GENOCIDE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a847f7e10015fd7eee',
      },
      {
        _id: '60cc25a847f7e10015fd7ef0',
        courseCode: 'POLISCI 4427',
        shortName: 'Political Science 4427F/G - TRANSITIONAL JUSTICE',
        courseName: 'Political Science 4427F/G - TRANSITIONAL JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a847f7e10015fd7ef0',
      },
      {
        _id: '60cc25a847f7e10015fd7ef2',
        courseCode: 'POLISCI 4428',
        shortName: 'Political Science 4428A/B - EXPERIENTIAL LEARNING',
        courseName: 'Political Science 4428A/B - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a847f7e10015fd7ef2',
      },
      {
        _id: '60cc25a947f7e10015fd7ef4',
        courseCode: 'POLISCI 4429',
        shortName:
          'Political Science 4429E - POLITICE AND THE STATE IN THE UNITED KINGDOM',
        courseName:
          'Political Science 4429E - POLITICE AND THE STATE IN THE UNITED KINGDOM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a947f7e10015fd7ef4',
      },
      {
        _id: '60cc25a947f7e10015fd7ef6',
        courseCode: 'POLISCI 4432',
        shortName: 'Political Science 4432F/G - CULTURE, TECHNOLOGY, POLITICS',
        courseName: 'Political Science 4432F/G - CULTURE, TECHNOLOGY, POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a947f7e10015fd7ef6',
      },
      {
        _id: '60cc25a947f7e10015fd7ef8',
        courseCode: 'POLISCI 4435',
        shortName: 'Political Science 4435F/G - CANADIAN LAW AND PUBLIC POLICY',
        courseName:
          'Political Science 4435F/G - CANADIAN LAW AND PUBLIC POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a947f7e10015fd7ef8',
      },
      {
        _id: '60cc25a947f7e10015fd7efa',
        courseCode: 'POLISCI 4436',
        shortName: 'Political Science 4436F/G - POLITICAL COMMUNICATIONS',
        courseName: 'Political Science 4436F/G - POLITICAL COMMUNICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a947f7e10015fd7efa',
      },
      {
        _id: '60cc25a947f7e10015fd7efc',
        courseCode: 'POLISCI 4438',
        shortName: 'Political Science 4438F/G - POSTMODERN POLITICAL THEORY',
        courseName: 'Political Science 4438F/G - POSTMODERN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25a947f7e10015fd7efc',
      },
      {
        _id: '60cc25aa47f7e10015fd7efe',
        courseCode: 'POLISCI 4439',
        shortName: 'Political Science 4439F/G - ELITES AND POWER',
        courseName: 'Political Science 4439F/G - ELITES AND POWER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25aa47f7e10015fd7efe',
      },
      {
        _id: '60cc25aa47f7e10015fd7f00',
        courseCode: 'POLISCI 4450',
        shortName:
          "Political Science 4450E - UNITED STATES' FOREIGN POLICIY: ECONOMIC AND MILITARY GLOBALIZATION",
        courseName:
          "Political Science 4450E - UNITED STATES' FOREIGN POLICIY: ECONOMIC AND MILITARY GLOBALIZATION",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25aa47f7e10015fd7f00',
      },
      {
        _id: '60cc25aa47f7e10015fd7f02',
        courseCode: 'POLISCI 4452',
        shortName:
          'Political Science 4452F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4452F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25aa47f7e10015fd7f02',
      },
      {
        _id: '60cc25ab47f7e10015fd7f04',
        courseCode: 'POLISCI 4453',
        shortName:
          'Political Science 4453F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4453F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ab47f7e10015fd7f04',
      },
      {
        _id: '60cc25ab47f7e10015fd7f06',
        courseCode: 'POLISCI 4454',
        shortName:
          'Political Science 4454F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4454F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ab47f7e10015fd7f06',
      },
      {
        _id: '60cc25ab47f7e10015fd7f08',
        courseCode: 'POLISCI 4455',
        shortName:
          'Political Science 4455F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4455F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ab47f7e10015fd7f08',
      },
      {
        _id: '60cc25ab47f7e10015fd7f0a',
        courseCode: 'POLISCI 4456',
        shortName:
          'Political Science 4456F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4456F/G - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ab47f7e10015fd7f0a',
      },
      {
        _id: '60cc25ab47f7e10015fd7f0c',
        courseCode: 'POLISCI 4480',
        shortName: 'Political Science 4480E - CRITICAL SECURITY STUDIES',
        courseName: 'Political Science 4480E - CRITICAL SECURITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ab47f7e10015fd7f0c',
      },
      {
        _id: '60cc25ac47f7e10015fd7f0e',
        courseCode: 'POLISCI 4485',
        shortName:
          'Political Science 4485E - SELECTED TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4485E - SELECTED TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ac47f7e10015fd7f0e',
      },
      {
        _id: '60cc25ac47f7e10015fd7f10',
        courseCode: 'POLISCI 4486',
        shortName:
          'Political Science 4486F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4486F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ac47f7e10015fd7f10',
      },
      {
        _id: '60cc25ac47f7e10015fd7f12',
        courseCode: 'POLISCI 4487',
        shortName:
          'Political Science 4487F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        courseName:
          'Political Science 4487F/G - SPECIAL TOPICS IN POLITICAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ac47f7e10015fd7f12',
      },
      {
        _id: '60cc25ac47f7e10015fd7f14',
        courseCode: 'POLISCI 4488',
        shortName: 'Political Science 4488F/G - INDEPENDENT STUDY',
        courseName: 'Political Science 4488F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ac47f7e10015fd7f14',
      },
      {
        _id: '60cc25ad47f7e10015fd7f16',
        courseCode: 'POLISCI 4490',
        shortName: 'Political Science 4490E - THESIS',
        courseName: 'Political Science 4490E - THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ad47f7e10015fd7f16',
      },
      {
        _id: '60cc25ad47f7e10015fd7f18',
        courseCode: 'POLISCI 4493',
        shortName:
          'Political Science 4493E - SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 4493E - SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ad47f7e10015fd7f18',
      },
      {
        _id: '60cc25ad47f7e10015fd7f1a',
        courseCode: 'POLISCI 4494',
        shortName:
          'Political Science 4494E - SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 4494E - SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ad47f7e10015fd7f1a',
      },
      {
        _id: '60cc25ae47f7e10015fd7f1c',
        courseCode: 'POLISCI 4495',
        shortName:
          'Political Science 4495E - SPECIAL TOPICS IN INTERNATIONAL POLITICS',
        courseName:
          'Political Science 4495E - SPECIAL TOPICS IN INTERNATIONAL POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ae47f7e10015fd7f1c',
      },
      {
        _id: '60cc25ae47f7e10015fd7f1e',
        courseCode: 'POLISCI 4496',
        shortName:
          'Political Science 4496E - SPECIAL TOPICS IN COMPARATIVE POLITICS',
        courseName:
          'Political Science 4496E - SPECIAL TOPICS IN COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ae47f7e10015fd7f1e',
      },
      {
        _id: '60cc25ae47f7e10015fd7f20',
        courseCode: 'POLISCI 4497',
        shortName:
          'Political Science 4497E - SPECIAL TOPICS IN COMPARATIVE POLITICS: DEVELOPED COUNTRIES',
        courseName:
          'Political Science 4497E - SPECIAL TOPICS IN COMPARATIVE POLITICS: DEVELOPED COUNTRIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ae47f7e10015fd7f20',
      },
      {
        _id: '60cc25ae47f7e10015fd7f22',
        courseCode: 'POLISCI 4498',
        shortName: 'Political Science 4498F/G - INDEPENDENT STUDY',
        courseName: 'Political Science 4498F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ae47f7e10015fd7f22',
      },
      {
        _id: '60cc25af47f7e10015fd7f24',
        courseCode: 'POLISCI 4499',
        shortName: 'Political Science 4499F/G - INDEPENDENT STUDY',
        courseName: 'Political Science 4499F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25af47f7e10015fd7f24',
      },
      {
        _id: '60cc25af47f7e10015fd7f26',
        courseCode: 'POLISCI 4501',
        shortName:
          'Political Science 4501F/G - ADVANCED SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 4501F/G - ADVANCED SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25af47f7e10015fd7f26',
      },
      {
        _id: '60cc25af47f7e10015fd7f28',
        courseCode: 'POLISCI 4502',
        shortName:
          'Political Science 4502F/G - ADVANCED SPECIAL TOPICS IN CANADIAN POLITICS',
        courseName:
          'Political Science 4502F/G - ADVANCED SPECIAL TOPICS IN CANADIAN POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25af47f7e10015fd7f28',
      },
      {
        _id: '60cc25af47f7e10015fd7f2a',
        courseCode: 'POLISCI 4511',
        shortName:
          'Political Science 4511F/G - ADVANCED SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        courseName:
          'Political Science 4511F/G - ADVANCED SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25af47f7e10015fd7f2a',
      },
      {
        _id: '60cc25b047f7e10015fd7f2c',
        courseCode: 'POLISCI 4512',
        shortName:
          'Political Science 4512F/G - ADVANCED SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        courseName:
          'Political Science 4512F/G - ADVANCED SPECIAL TOPICS IN IR/COMPARATIVE POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b047f7e10015fd7f2c',
      },
      {
        _id: '60cc25b047f7e10015fd7f2e',
        courseCode: 'POLISCI 4521',
        shortName:
          'Political Science 4521F/G - ADVANCED SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 4521F/G - ADVANCED SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b047f7e10015fd7f2e',
      },
      {
        _id: '60cc25b047f7e10015fd7f30',
        courseCode: 'POLISCI 4522',
        shortName:
          'Political Science 4522F/G - ADVANCED SPECIAL TOPICS IN POLITICAL THEORY',
        courseName:
          'Political Science 4522F/G - ADVANCED SPECIAL TOPICS IN POLITICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b047f7e10015fd7f30',
      },
      {
        _id: '60cc25b047f7e10015fd7f32',
        courseCode: 'POLISCI 4901',
        shortName: 'Political Science 4901A/B - ADVANCED LOCAL GOVERNMENT',
        courseName: 'Political Science 4901A/B - ADVANCED LOCAL GOVERNMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b047f7e10015fd7f32',
      },
      {
        _id: '60cc25b047f7e10015fd7f34',
        courseCode: 'POLISCI 4902',
        shortName:
          'Political Science 4902A/B - THE POLICY PROCESS IN LOCAL GOVERNMENT',
        courseName:
          'Political Science 4902A/B - THE POLICY PROCESS IN LOCAL GOVERNMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b047f7e10015fd7f34',
      },
      {
        _id: '60cc25b147f7e10015fd7f36',
        courseCode: 'POLISCI 4903',
        shortName:
          'Political Science 4903A/B - ORGANIZATIONAL BEHAVIOR IN A LOCAL GOVERNMENT ENVIRONMENT',
        courseName:
          'Political Science 4903A/B - ORGANIZATIONAL BEHAVIOR IN A LOCAL GOVERNMENT ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b147f7e10015fd7f36',
      },
      {
        _id: '60cc25b147f7e10015fd7f38',
        courseCode: 'POLISCI 4904',
        shortName: 'Political Science 4904A/B - LOCAL GOVERNMENT MANAGEMENT',
        courseName: 'Political Science 4904A/B - LOCAL GOVERNMENT MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b147f7e10015fd7f38',
      },
      {
        _id: '60cc25b147f7e10015fd7f3a',
        courseCode: 'POLISCI 4931',
        shortName: 'Political Science 4931E - RESEARCH REPORT',
        courseName: 'Political Science 4931E - RESEARCH REPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b147f7e10015fd7f3a',
      },
      {
        _id: '60cc25b147f7e10015fd7f3c',
        courseCode: 'PPE 2200',
        shortName:
          'Politics, Philosophy, and Economics 2200F/G - INTRODUCTION TO POLITICS, PHILOSOPHY AND ECONOMICS',
        courseName:
          'Politics, Philosophy, and Economics 2200F/G - INTRODUCTION TO POLITICS, PHILOSOPHY AND ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b147f7e10015fd7f3c',
      },
      {
        _id: '60cc25b247f7e10015fd7f3e',
        courseCode: 'PPE 4100',
        shortName:
          'Politics, Philosophy, and Economics 4100E - SENIOR SEMINAR IN POLITICS, PHILOSOPHY AND ECONOMICS',
        courseName:
          'Politics, Philosophy, and Economics 4100E - SENIOR SEMINAR IN POLITICS, PHILOSOPHY AND ECONOMICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b247f7e10015fd7f3e',
      },
      {
        _id: '60cc25b247f7e10015fd7f40',
        courseCode: 'PORTUGSE 1030',
        shortName: 'Portuguese 1030 - PORTUGUESE FOR BEGINNERS',
        courseName: 'Portuguese 1030 - PORTUGUESE FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b247f7e10015fd7f40',
      },
      {
        _id: '60cc25b247f7e10015fd7f42',
        courseCode: 'PORTUGSE 2200',
        shortName: 'Portuguese 2200 - INTERMEDIATE PORTUGUESE',
        courseName: 'Portuguese 2200 - INTERMEDIATE PORTUGUESE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b247f7e10015fd7f42',
      },
      {
        _id: '60cc25b247f7e10015fd7f44',
        courseCode: 'PSYCHOL 0010',
        shortName:
          'Psychology 0010A/B - INTRODUCTION TO PSYCHOLOGICAL PRINCIPLES',
        courseName:
          'Psychology 0010A/B - INTRODUCTION TO PSYCHOLOGICAL PRINCIPLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b247f7e10015fd7f44',
      },
      {
        _id: '60cc25b347f7e10015fd7f46',
        courseCode: 'PSYCHOL 0015',
        shortName:
          'Psychology 0015A/B - APPLICATION OF PSYCHOLOGICAL PRINCIPLES',
        courseName:
          'Psychology 0015A/B - APPLICATION OF PSYCHOLOGICAL PRINCIPLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b347f7e10015fd7f46',
      },
      {
        _id: '60cc25b347f7e10015fd7f48',
        courseCode: 'PSYCHOL 1000',
        shortName: 'Psychology 1000 - INTRODUCTION TO  PSYCHOLOGY',
        courseName: 'Psychology 1000 - INTRODUCTION TO  PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b347f7e10015fd7f48',
      },
      {
        _id: '60cc25b347f7e10015fd7f4a',
        courseCode: 'PSYCHOL 1000',
        shortName: 'Psychology 1000W/X - INTRODUCTION TO PSYCHOLOGY',
        courseName: 'Psychology 1000W/X - INTRODUCTION TO PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 32,
        id: '60cc25b347f7e10015fd7f4a',
      },
      {
        _id: '60cc25b447f7e10015fd7f4c',
        courseCode: 'PSYCHOL 1001',
        shortName: 'Psychology 1001E - LABORATORY UPGRADE',
        courseName: 'Psychology 1001E - LABORATORY UPGRADE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b447f7e10015fd7f4c',
      },
      {
        _id: '60cc25b447f7e10015fd7f4e',
        courseCode: 'PSYCHOL 1002',
        shortName: 'Psychology 1002A/B - PSYCHOLOGY AS A NATURAL SCIENCE',
        courseName: 'Psychology 1002A/B - PSYCHOLOGY AS A NATURAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b447f7e10015fd7f4e',
      },
      {
        _id: '60cc25b447f7e10015fd7f50',
        courseCode: 'PSYCHOL 1003',
        shortName: 'Psychology 1003A/B - PSYCHOLOGY AS A SOCIAL SCIENCE',
        courseName: 'Psychology 1003A/B - PSYCHOLOGY AS A SOCIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b447f7e10015fd7f50',
      },
      {
        _id: '60cc25b447f7e10015fd7f52',
        courseCode: 'PSYCHOL 1010',
        shortName:
          'Psychology 1010A/B - INTRODUCTION TO PSYCHOLOGY AS A NATURAL SCIENCE',
        courseName:
          'Psychology 1010A/B - INTRODUCTION TO PSYCHOLOGY AS A NATURAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b447f7e10015fd7f52',
      },
      {
        _id: '60cc25b547f7e10015fd7f54',
        courseCode: 'PSYCHOL 1015',
        shortName:
          'Psychology 1015A/B - INTRODUCTION TO PSYCHOLOGY AS A SOCIAL SCIENCE',
        courseName:
          'Psychology 1015A/B - INTRODUCTION TO PSYCHOLOGY AS A SOCIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b547f7e10015fd7f54',
      },
      {
        _id: '60cc25b547f7e10015fd7f56',
        courseCode: 'PSYCHOL 1100',
        shortName: 'Psychology 1100E - METHOD/GENERAL PSYCHOLOGY',
        courseName: 'Psychology 1100E - METHOD/GENERAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b547f7e10015fd7f56',
      },
      {
        _id: '60cc25b547f7e10015fd7f58',
        courseCode: 'PSYCHOL 2010',
        shortName:
          'Psychology 2010A/B - MIND MATTERS: THOUGHT, MEMORY AND LANGUAGE',
        courseName:
          'Psychology 2010A/B - MIND MATTERS: THOUGHT, MEMORY AND LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b547f7e10015fd7f58',
      },
      {
        _id: '60cc25b547f7e10015fd7f5a',
        courseCode: 'PSYCHOL 2011',
        shortName: 'Psychology 2011A/B - ALTERED STATES OF CONSCIOUSNESS',
        courseName: 'Psychology 2011A/B - ALTERED STATES OF CONSCIOUSNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b547f7e10015fd7f5a',
      },
      {
        _id: '60cc25b647f7e10015fd7f5c',
        courseCode: 'PSYCHOL 2012',
        shortName: 'Psychology 2012F/G - PSYCHOLOGY AND DIVERSITY',
        courseName: 'Psychology 2012F/G - PSYCHOLOGY AND DIVERSITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b647f7e10015fd7f5c',
      },
      {
        _id: '60cc25b647f7e10015fd7f5e',
        courseCode: 'PSYCHOL 2015',
        shortName: 'Psychology 2015A/B - THE PSYCHOLOGY OF PERCEPTION',
        courseName: 'Psychology 2015A/B - THE PSYCHOLOGY OF PERCEPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b647f7e10015fd7f5e',
      },
      {
        _id: '60cc25b647f7e10015fd7f60',
        courseCode: 'PSYCHOL 2020',
        shortName: 'Psychology 2020A/B - DRUGS AND BEHAVIOR',
        courseName: 'Psychology 2020A/B - DRUGS AND BEHAVIOR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b647f7e10015fd7f60',
      },
      {
        _id: '60cc25b747f7e10015fd7f62',
        courseCode: 'PSYCHOL 2021',
        shortName: 'Psychology 2021A/B - ADDICTIVE BEHAVIOURS',
        courseName: 'Psychology 2021A/B - ADDICTIVE BEHAVIOURS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b747f7e10015fd7f62',
      },
      {
        _id: '60cc25b747f7e10015fd7f64',
        courseCode: 'PSYCHOL 2030',
        shortName: 'Psychology 2030A/B - THE MALADJUSTED MIND',
        courseName: 'Psychology 2030A/B - THE MALADJUSTED MIND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b747f7e10015fd7f64',
      },
      {
        _id: '60cc25b747f7e10015fd7f66',
        courseCode: 'PSYCHOL 2031',
        shortName: 'Psychology 2031A/B - PSYCHOLOGY AND LAW',
        courseName: 'Psychology 2031A/B - PSYCHOLOGY AND LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b747f7e10015fd7f66',
      },
      {
        _id: '60cc25b747f7e10015fd7f68',
        courseCode: 'PSYCHOL 2032',
        shortName:
          'Psychology 2032A/B - THE PSYCHOLOGY OF CRIME AND CORRECTIONS',
        courseName:
          'Psychology 2032A/B - THE PSYCHOLOGY OF CRIME AND CORRECTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b747f7e10015fd7f68',
      },
      {
        _id: '60cc25b847f7e10015fd7f6a',
        courseCode: 'PSYCHOL 2035',
        shortName: 'Psychology 2035A/B - UNDERSTANDING YOURSELF AND OTHERS',
        courseName: 'Psychology 2035A/B - UNDERSTANDING YOURSELF AND OTHERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b847f7e10015fd7f6a',
      },
      {
        _id: '60cc25b847f7e10015fd7f6c',
        courseCode: 'PSYCHOL 2036',
        shortName:
          'Psychology 2036A/B - THE PSYCHOLOGY OF PHYSICAL HEALTH AND ILLNESS',
        courseName:
          'Psychology 2036A/B - THE PSYCHOLOGY OF PHYSICAL HEALTH AND ILLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b847f7e10015fd7f6c',
      },
      {
        _id: '60cc25b847f7e10015fd7f6e',
        courseCode: 'PSYCHOL 2037',
        shortName: 'Psychology 2037A/B - PSYCHOLOGY APPLIED TO SPORT',
        courseName: 'Psychology 2037A/B - PSYCHOLOGY APPLIED TO SPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b847f7e10015fd7f6e',
      },
      {
        _id: '60cc25b847f7e10015fd7f70',
        courseCode: 'PSYCHOL 2040',
        shortName: 'Psychology 2040A/B - CHILD DEVELOPMENT',
        courseName: 'Psychology 2040A/B - CHILD DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b847f7e10015fd7f70',
      },
      {
        _id: '60cc25b947f7e10015fd7f72',
        courseCode: 'PSYCHOL 2041',
        shortName: 'Psychology 2041 - THE EXCEPTIONAL CHILD',
        courseName: 'Psychology 2041 - THE EXCEPTIONAL CHILD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b947f7e10015fd7f72',
      },
      {
        _id: '60cc25b947f7e10015fd7f74',
        courseCode: 'PSYCHOL 2042',
        shortName:
          'Psychology 2042A/B - EXCEPTIONAL CHILDREN: BEHAVIORAL DISORDERS',
        courseName:
          'Psychology 2042A/B - EXCEPTIONAL CHILDREN: BEHAVIORAL DISORDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b947f7e10015fd7f74',
      },
      {
        _id: '60cc25b947f7e10015fd7f76',
        courseCode: 'PSYCHOL 2043',
        shortName:
          'Psychology 2043A/B - EXCEPTIONAL CHILDREN: DEVELOPMENTAL DISORDERS',
        courseName:
          'Psychology 2043A/B - EXCEPTIONAL CHILDREN: DEVELOPMENTAL DISORDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25b947f7e10015fd7f76',
      },
      {
        _id: '60cc25ba47f7e10015fd7f78',
        courseCode: 'PSYCHOL 2050',
        shortName: 'Psychology 2050 - HUMAN ADJUSTMENT',
        courseName: 'Psychology 2050 - HUMAN ADJUSTMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ba47f7e10015fd7f78',
      },
      {
        _id: '60cc25ba47f7e10015fd7f7a',
        courseCode: 'PSYCHOL 2054',
        shortName: 'Psychology 2054A/B - PSYCHOLOGY OF EATING',
        courseName: 'Psychology 2054A/B - PSYCHOLOGY OF EATING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ba47f7e10015fd7f7a',
      },
      {
        _id: '60cc25bb47f7e10015fd7f7c',
        courseCode: 'PSYCHOL 2060',
        shortName:
          'Psychology 2060 - THE PSYCHOLOGY OF PEOPLE, WORK AND ORGANIZATIONS',
        courseName:
          'Psychology 2060 - THE PSYCHOLOGY OF PEOPLE, WORK AND ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bb47f7e10015fd7f7c',
      },
      {
        _id: '60cc25bb47f7e10015fd7f7e',
        courseCode: 'PSYCHOL 2061',
        shortName: 'Psychology 2061A/B - PSYCHOLOGY AT WORK',
        courseName: 'Psychology 2061A/B - PSYCHOLOGY AT WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bb47f7e10015fd7f7e',
      },
      {
        _id: '60cc25bb47f7e10015fd7f80',
        courseCode: 'PSYCHOL 2065',
        shortName:
          'Psychology 2065A/B - THE PSYCHOLOGY OF RELIGION AND SPIRITUALITY',
        courseName:
          'Psychology 2065A/B - THE PSYCHOLOGY OF RELIGION AND SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bb47f7e10015fd7f80',
      },
      {
        _id: '60cc25bb47f7e10015fd7f82',
        courseCode: 'PSYCHOL 2070',
        shortName: 'Psychology 2070A/B - SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 2070A/B - SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bb47f7e10015fd7f82',
      },
      {
        _id: '60cc25bc47f7e10015fd7f84',
        courseCode: 'PSYCHOL 2074',
        shortName: 'Psychology 2074A/B - PSYCHOLOGY OF GENDER',
        courseName: 'Psychology 2074A/B - PSYCHOLOGY OF GENDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bc47f7e10015fd7f84',
      },
      {
        _id: '60cc25bc47f7e10015fd7f86',
        courseCode: 'PSYCHOL 2075',
        shortName: 'Psychology 2075 - HUMAN SEXUALITY',
        courseName: 'Psychology 2075 - HUMAN SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bc47f7e10015fd7f86',
      },
      {
        _id: '60cc25bc47f7e10015fd7f88',
        courseCode: 'PSYCHOL 2076',
        shortName: 'Psychology 2076A/B - THE PSYCHOLOGY OF SEX',
        courseName: 'Psychology 2076A/B - THE PSYCHOLOGY OF SEX',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bc47f7e10015fd7f88',
      },
      {
        _id: '60cc25bd47f7e10015fd7f8a',
        courseCode: 'PSYCHOL 2080',
        shortName: 'Psychology 2080A/B - INTRODUCTION TO TEST AND MEASUREMENT',
        courseName: 'Psychology 2080A/B - INTRODUCTION TO TEST AND MEASUREMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bd47f7e10015fd7f8a',
      },
      {
        _id: '60cc25bd47f7e10015fd7f8c',
        courseCode: 'PSYCHOL 2091',
        shortName: 'Psychology 2091A/B - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 2091A/B - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bd47f7e10015fd7f8c',
      },
      {
        _id: '60cc25bd47f7e10015fd7f8e',
        courseCode: 'PSYCHOL 2092',
        shortName: 'Psychology 2092A/B - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 2092A/B - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bd47f7e10015fd7f8e',
      },
      {
        _id: '60cc25be47f7e10015fd7f90',
        courseCode: 'PSYCHOL 2115',
        shortName:
          'Psychology 2115A/B - INTRODUCTION TO  SENSATION AND PERCEPTION',
        courseName:
          'Psychology 2115A/B - INTRODUCTION TO  SENSATION AND PERCEPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25be47f7e10015fd7f90',
      },
      {
        _id: '60cc25be47f7e10015fd7f92',
        courseCode: 'PSYCHOL 2134',
        shortName: 'Psychology 2134A/B - PSYCHOLOGY OF LANGUAGE',
        courseName: 'Psychology 2134A/B - PSYCHOLOGY OF LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25be47f7e10015fd7f92',
      },
      {
        _id: '60cc25be47f7e10015fd7f94',
        courseCode: 'PSYCHOL 2135',
        shortName: 'Psychology 2135A/B - COGNITIVE PSYCHOLOGY',
        courseName: 'Psychology 2135A/B - COGNITIVE PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25be47f7e10015fd7f94',
      },
      {
        _id: '60cc25bf47f7e10015fd7f96',
        courseCode: 'PSYCHOL 2180',
        shortName: 'Psychology 2180E - METHOD AND THEORY IN COGNITION',
        courseName: 'Psychology 2180E - METHOD AND THEORY IN COGNITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bf47f7e10015fd7f96',
      },
      {
        _id: '60cc25bf47f7e10015fd7f98',
        courseCode: 'PSYCHOL 2210',
        shortName: 'Psychology 2210A/B - INTRODUCTION TO ANIMAL COGNITION',
        courseName: 'Psychology 2210A/B - INTRODUCTION TO ANIMAL COGNITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25bf47f7e10015fd7f98',
      },
      {
        _id: '60cc25c047f7e10015fd7f9a',
        courseCode: 'PSYCHOL 2220',
        shortName:
          'Psychology 2220A/B - INTRODUCTION TO BEHAVIOURAL AND COGNITIVE NEUROSCIENCE',
        courseName:
          'Psychology 2220A/B - INTRODUCTION TO BEHAVIOURAL AND COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c047f7e10015fd7f9a',
      },
      {
        _id: '60cc25c047f7e10015fd7f9c',
        courseCode: 'PSYCHOL 2221',
        shortName:
          'Psychology 2221A/B - INTRODUCTION TO BIOLOGICAL BASIS OF BEHAVIOUR',
        courseName:
          'Psychology 2221A/B - INTRODUCTION TO BIOLOGICAL BASIS OF BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c047f7e10015fd7f9c',
      },
      {
        _id: '60cc25c147f7e10015fd7f9e',
        courseCode: 'PSYCHOL 2280',
        shortName:
          'Psychology 2280E - METHOD AND THEORY IN LEARNING AND MOTIVATION',
        courseName:
          'Psychology 2280E - METHOD AND THEORY IN LEARNING AND MOTIVATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c147f7e10015fd7f9e',
      },
      {
        _id: '60cc25c147f7e10015fd7fa0',
        courseCode: 'PSYCHOL 2301',
        shortName: 'Psychology 2301A/B - INTRODUCTION TO CLINICAL PSYCHOLOGY',
        courseName: 'Psychology 2301A/B - INTRODUCTION TO CLINICAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c147f7e10015fd7fa0',
      },
      {
        _id: '60cc25c147f7e10015fd7fa2',
        courseCode: 'PSYCHOL 2310',
        shortName: 'Psychology 2310A/B - ABNORMAL PSYCHOLOGY',
        courseName: 'Psychology 2310A/B - ABNORMAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c147f7e10015fd7fa2',
      },
      {
        _id: '60cc25c147f7e10015fd7fa4',
        courseCode: 'PSYCHOL 2320',
        shortName: 'Psychology 2320A/B - ABNORMAL CHILD PSYCHOLOGY',
        courseName: 'Psychology 2320A/B - ABNORMAL CHILD PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c147f7e10015fd7fa4',
      },
      {
        _id: '60cc25c247f7e10015fd7fa6',
        courseCode: 'PSYCHOL 2330',
        shortName: 'Psychology 2330A/B - INTRODUCTION TO HEALTH PSYCHOLOGY',
        courseName: 'Psychology 2330A/B - INTRODUCTION TO HEALTH PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c247f7e10015fd7fa6',
      },
      {
        _id: '60cc25c247f7e10015fd7fa8',
        courseCode: 'PSYCHOL 2410',
        shortName:
          'Psychology 2410A/B - INTRODUCTION TO DEVELOPMENTAL PSYCHOLOGY',
        courseName:
          'Psychology 2410A/B - INTRODUCTION TO DEVELOPMENTAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c247f7e10015fd7fa8',
      },
      {
        _id: '60cc25c247f7e10015fd7faa',
        courseCode: 'PSYCHOL 2480',
        shortName:
          'Psychology 2480E - DEVELOPMENTAL PSYCHOLOGY: BASIC PRINCIPLES',
        courseName:
          'Psychology 2480E - DEVELOPMENTAL PSYCHOLOGY: BASIC PRINCIPLES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c247f7e10015fd7faa',
      },
      {
        _id: '60cc25c347f7e10015fd7fac',
        courseCode: 'PSYCHOL 2550',
        shortName:
          'Psychology 2550A/B - INTRODUCTION TO  PERSONALITY THEORY AND RESEARCH',
        courseName:
          'Psychology 2550A/B - INTRODUCTION TO  PERSONALITY THEORY AND RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c347f7e10015fd7fac',
      },
      {
        _id: '60cc25c347f7e10015fd7fae',
        courseCode: 'PSYCHOL 2610',
        shortName:
          'Psychology 2610F/G - INTRODUCTION TO  EDUCATIONAL PSYCHOLOGY',
        courseName:
          'Psychology 2610F/G - INTRODUCTION TO  EDUCATIONAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c347f7e10015fd7fae',
      },
      {
        _id: '60cc25c447f7e10015fd7fb0',
        courseCode: 'PSYCHOL 2620',
        shortName:
          'Psychology 2620A/B - INTRODUCTION TO EDUCATIONAL PSYCHOLOGY',
        courseName:
          'Psychology 2620A/B - INTRODUCTION TO EDUCATIONAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c447f7e10015fd7fb0',
      },
      {
        _id: '60cc25c447f7e10015fd7fb2',
        courseCode: 'PSYCHOL 2660',
        shortName:
          'Psychology 2660A/B - INTRODUCTION TO INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
        courseName:
          'Psychology 2660A/B - INTRODUCTION TO INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c447f7e10015fd7fb2',
      },
      {
        _id: '60cc25c447f7e10015fd7fb4',
        courseCode: 'PSYCHOL 2720',
        shortName: 'Psychology 2720A/B - INTRODUCTION TO SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 2720A/B - INTRODUCTION TO SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c447f7e10015fd7fb4',
      },
      {
        _id: '60cc25c547f7e10015fd7fb6',
        courseCode: 'PSYCHOL 2750',
        shortName: 'Psychology 2750E - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 2750E - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c547f7e10015fd7fb6',
      },
      {
        _id: '60cc25c547f7e10015fd7fb8',
        courseCode: 'PSYCHOL 2760',
        shortName: 'Psychology 2760F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 2760F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c547f7e10015fd7fb8',
      },
      {
        _id: '60cc25c547f7e10015fd7fba',
        courseCode: 'PSYCHOL 2780',
        shortName: 'Psychology 2780E - SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 2780E - SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c547f7e10015fd7fba',
      },
      {
        _id: '60cc25c647f7e10015fd7fbc',
        courseCode: 'PSYCHOL 2800',
        shortName: 'Psychology 2800E - RESEARCH METHODS IN PSYCHOLOGY',
        courseName: 'Psychology 2800E - RESEARCH METHODS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c647f7e10015fd7fbc',
      },
      {
        _id: '60cc25c647f7e10015fd7fbe',
        courseCode: 'PSYCHOL 2810',
        shortName: 'Psychology 2810 - STATISTICS FOR PSYCHOLOGY',
        courseName: 'Psychology 2810 - STATISTICS FOR PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c647f7e10015fd7fbe',
      },
      {
        _id: '60cc25c647f7e10015fd7fc0',
        courseCode: 'PSYCHOL 2820',
        shortName:
          'Psychology 2820E - RESEARCH METHODS AND STATISTICAL ANALYSIS IN PSYCHOLOGY',
        courseName:
          'Psychology 2820E - RESEARCH METHODS AND STATISTICAL ANALYSIS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c647f7e10015fd7fc0',
      },
      {
        _id: '60cc25c647f7e10015fd7fc2',
        courseCode: 'PSYCHOL 2830',
        shortName: 'Psychology 2830A/B - RESEARCH IN PSYCHOLOGY',
        courseName: 'Psychology 2830A/B - RESEARCH IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c647f7e10015fd7fc2',
      },
      {
        _id: '60cc25c647f7e10015fd7fc4',
        courseCode: 'PSYCHOL 2840',
        shortName: 'Psychology 2840F/G - RESEARCH METHODS IN PSYCHOLOGY',
        courseName: 'Psychology 2840F/G - RESEARCH METHODS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c647f7e10015fd7fc4',
      },
      {
        _id: '60cc25c747f7e10015fd7fc6',
        courseCode: 'PSYCHOL 2850',
        shortName: 'Psychology 2850A/B - STATISTICS FOR PSYCHOLOGY I',
        courseName: 'Psychology 2850A/B - STATISTICS FOR PSYCHOLOGY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c747f7e10015fd7fc6',
      },
      {
        _id: '60cc25c747f7e10015fd7fc8',
        courseCode: 'PSYCHOL 2851',
        shortName: 'Psychology 2851A/B - STATISTICS FOR PSYCHOLOGY II',
        courseName: 'Psychology 2851A/B - STATISTICS FOR PSYCHOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c747f7e10015fd7fc8',
      },
      {
        _id: '60cc25c747f7e10015fd7fca',
        courseCode: 'PSYCHOL 2855',
        shortName: 'Psychology 2855F/G - RESEARCH METHODS IN PSYCHOLOGY I',
        courseName: 'Psychology 2855F/G - RESEARCH METHODS IN PSYCHOLOGY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c747f7e10015fd7fca',
      },
      {
        _id: '60cc25c847f7e10015fd7fcc',
        courseCode: 'PSYCHOL 2856',
        shortName: 'Psychology 2856F/G - RESEARCH METHODS IN PSYCHOLOGY II',
        courseName: 'Psychology 2856F/G - RESEARCH METHODS IN PSYCHOLOGY II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c847f7e10015fd7fcc',
      },
      {
        _id: '60cc25c847f7e10015fd7fce',
        courseCode: 'PSYCHOL 2990',
        shortName: 'Psychology 2990A/B - APPLICATIONS OF PSYCHOLOGY',
        courseName: 'Psychology 2990A/B - APPLICATIONS OF PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c847f7e10015fd7fce',
      },
      {
        _id: '60cc25c947f7e10015fd7fd0',
        courseCode: 'PSYCHOL 3115',
        shortName: 'Psychology 3115F/G - RESEARCH IN SENSATION AND PERCEPTION',
        courseName: 'Psychology 3115F/G - RESEARCH IN SENSATION AND PERCEPTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c947f7e10015fd7fd0',
      },
      {
        _id: '60cc25c947f7e10015fd7fd2',
        courseCode: 'PSYCHOL 3120',
        shortName:
          'Psychology 3120F/G - ALTERED STATES OF CONSCIOUSNESS: SELECTED TOPICS',
        courseName:
          'Psychology 3120F/G - ALTERED STATES OF CONSCIOUSNESS: SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c947f7e10015fd7fd2',
      },
      {
        _id: '60cc25c947f7e10015fd7fd4',
        courseCode: 'PSYCHOL 3130',
        shortName: 'Psychology 3130A/B - PSYCHOLOGY OF THINKING',
        courseName: 'Psychology 3130A/B - PSYCHOLOGY OF THINKING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c947f7e10015fd7fd4',
      },
      {
        _id: '60cc25c947f7e10015fd7fd6',
        courseCode: 'PSYCHOL 3137',
        shortName: 'Psychology 3137F/G - COGNITION AND AGING',
        courseName: 'Psychology 3137F/G - COGNITION AND AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25c947f7e10015fd7fd6',
      },
      {
        _id: '60cc25ca47f7e10015fd7fd8',
        courseCode: 'PSYCHOL 3138',
        shortName: 'Psychology 3138F/G - HUMAN MEMORY',
        courseName: 'Psychology 3138F/G - HUMAN MEMORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ca47f7e10015fd7fd8',
      },
      {
        _id: '60cc25ca47f7e10015fd7fda',
        courseCode: 'PSYCHOL 3139',
        shortName: 'Psychology 3139A/B - COGNITIVE SCIENCE',
        courseName: 'Psychology 3139A/B - COGNITIVE SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ca47f7e10015fd7fda',
      },
      {
        _id: '60cc25ca47f7e10015fd7fdc',
        courseCode: 'PSYCHOL 3140',
        shortName: 'Psychology 3140F/G - BILINGUALISM',
        courseName: 'Psychology 3140F/G - BILINGUALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ca47f7e10015fd7fdc',
      },
      {
        _id: '60cc25ca47f7e10015fd7fde',
        courseCode: 'PSYCHOL 3141',
        shortName: 'Psychology 3141F/G - LANGUAGE DEVELOPMENT',
        courseName: 'Psychology 3141F/G - LANGUAGE DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ca47f7e10015fd7fde',
      },
      {
        _id: '60cc25cb47f7e10015fd7fe0',
        courseCode: 'PSYCHOL 3143',
        shortName: 'Psychology 3143F/G - READING ABILITY AND DISABILITY',
        courseName: 'Psychology 3143F/G - READING ABILITY AND DISABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cb47f7e10015fd7fe0',
      },
      {
        _id: '60cc25cb47f7e10015fd7fe2',
        courseCode: 'PSYCHOL 3177',
        shortName: 'Psychology 3177F/G - CONSCIOUSNESS',
        courseName: 'Psychology 3177F/G - CONSCIOUSNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cb47f7e10015fd7fe2',
      },
      {
        _id: '60cc25cb47f7e10015fd7fe4',
        courseCode: 'PSYCHOL 3184',
        shortName:
          'Psychology 3184F/G - RESEARCH IN THE PSYCHOLOGY OF LANGUAGE',
        courseName:
          'Psychology 3184F/G - RESEARCH IN THE PSYCHOLOGY OF LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cb47f7e10015fd7fe4',
      },
      {
        _id: '60cc25cb47f7e10015fd7fe6',
        courseCode: 'PSYCHOL 3185',
        shortName: 'Psychology 3185F/G - RESEARCH IN COGNITIVE PSYCHOLOGY',
        courseName: 'Psychology 3185F/G - RESEARCH IN COGNITIVE PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cb47f7e10015fd7fe6',
      },
      {
        _id: '60cc25cc47f7e10015fd7fe8',
        courseCode: 'PSYCHOL 3195',
        shortName:
          'Psychology 3195F/G - SPECIAL TOPICS IN COGNITIVE PSYCHOLOGY',
        courseName:
          'Psychology 3195F/G - SPECIAL TOPICS IN COGNITIVE PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cc47f7e10015fd7fe8',
      },
      {
        _id: '60cc25cc47f7e10015fd7fea',
        courseCode: 'PSYCHOL 3209',
        shortName:
          'Psychology 3209F/G - NEUROSCIENCE OF MOTIVATION AND EMOTION',
        courseName:
          'Psychology 3209F/G - NEUROSCIENCE OF MOTIVATION AND EMOTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cc47f7e10015fd7fea',
      },
      {
        _id: '60cc25cd47f7e10015fd7fec',
        courseCode: 'PSYCHOL 3215',
        shortName:
          'Psychology 3215F/G - SEX DIFFERENCES AND BEHAVIOURAL NEUROSCIENCE',
        courseName:
          'Psychology 3215F/G - SEX DIFFERENCES AND BEHAVIOURAL NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cd47f7e10015fd7fec',
      },
      {
        _id: '60cc25cd47f7e10015fd7fee',
        courseCode: 'PSYCHOL 3221',
        shortName: 'Psychology 3221F/G - ANIMAL BEHAVIOR',
        courseName: 'Psychology 3221F/G - ANIMAL BEHAVIOR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cd47f7e10015fd7fee',
      },
      {
        _id: '60cc25cd47f7e10015fd7ff0',
        courseCode: 'PSYCHOL 3222',
        shortName:
          'Psychology 3222F/G - INTRODUCTION TO NEUROIMAGING RESEARCH IN PSYCHOLOGY',
        courseName:
          'Psychology 3222F/G - INTRODUCTION TO NEUROIMAGING RESEARCH IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cd47f7e10015fd7ff0',
      },
      {
        _id: '60cc25cd47f7e10015fd7ff2',
        courseCode: 'PSYCHOL 3224',
        shortName:
          'Psychology 3224A/B - NEUROPSYCHOLOGY AND COGNITIVE NEUROSCIENCE',
        courseName:
          'Psychology 3224A/B - NEUROPSYCHOLOGY AND COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cd47f7e10015fd7ff2',
      },
      {
        _id: '60cc25ce47f7e10015fd7ff4',
        courseCode: 'PSYCHOL 3225',
        shortName:
          'Psychology 3225A/B - SEX DIFFERENCES IN HUMAN BRAIN AND BEHAVIOUR',
        courseName:
          'Psychology 3225A/B - SEX DIFFERENCES IN HUMAN BRAIN AND BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ce47f7e10015fd7ff4',
      },
      {
        _id: '60cc25ce47f7e10015fd7ff6',
        courseCode: 'PSYCHOL 3226',
        shortName: 'Psychology 3226A/B - HORMONES AND BEHAVIOUR',
        courseName: 'Psychology 3226A/B - HORMONES AND BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ce47f7e10015fd7ff6',
      },
      {
        _id: '60cc25ce47f7e10015fd7ff8',
        courseCode: 'PSYCHOL 3227',
        shortName:
          'Psychology 3227A/B - NEUROPSYCHOLOGY: BRAIN INJURY DIAGNOSIS, TREATMENT & RECOVERY',
        courseName:
          'Psychology 3227A/B - NEUROPSYCHOLOGY: BRAIN INJURY DIAGNOSIS, TREATMENT & RECOVERY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ce47f7e10015fd7ff8',
      },
      {
        _id: '60cc25cf47f7e10015fd7ffa',
        courseCode: 'PSYCHOL 3228',
        shortName:
          'Psychology 3228A/B - EVOLUTION AND PSYCHOLOGY: THE SCIENCE OF HUMAN NATURE',
        courseName:
          'Psychology 3228A/B - EVOLUTION AND PSYCHOLOGY: THE SCIENCE OF HUMAN NATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cf47f7e10015fd7ffa',
      },
      {
        _id: '60cc25cf47f7e10015fd7ffc',
        courseCode: 'PSYCHOL 3229',
        shortName: 'Psychology 3229A/B - EVOLUTION AND HUMAN BEHAVIOUR',
        courseName: 'Psychology 3229A/B - EVOLUTION AND HUMAN BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cf47f7e10015fd7ffc',
      },
      {
        _id: '60cc25cf47f7e10015fd7ffe',
        courseCode: 'PSYCHOL 3230',
        shortName: 'Psychology 3230F/G - COGNITIVE NEUROSCIENCE OF MUSIC',
        courseName: 'Psychology 3230F/G - COGNITIVE NEUROSCIENCE OF MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cf47f7e10015fd7ffe',
      },
      {
        _id: '60cc25cf47f7e10015fd8000',
        courseCode: 'PSYCHOL 3254',
        shortName: 'Psychology 3254A/B - THE BRAIN, FOOD AND EATING',
        courseName: 'Psychology 3254A/B - THE BRAIN, FOOD AND EATING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25cf47f7e10015fd8000',
      },
      {
        _id: '60cc25d047f7e10015fd8002',
        courseCode: 'PSYCHOL 3260',
        shortName: 'Psychology 3260F/G - COGNITIVE NEUROSCIENCE',
        courseName: 'Psychology 3260F/G - COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d047f7e10015fd8002',
      },
      {
        _id: '60cc25d047f7e10015fd8004',
        courseCode: 'PSYCHOL 3285',
        shortName: 'Psychology 3285F/G - RESEARCH IN BEHAVIOURAL NEUROSCIENCE',
        courseName: 'Psychology 3285F/G - RESEARCH IN BEHAVIOURAL NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d047f7e10015fd8004',
      },
      {
        _id: '60cc25d047f7e10015fd8006',
        courseCode: 'PSYCHOL 3295',
        shortName:
          'Psychology 3295F/G - SPECIAL TOPICS IN BEHAVIORAL AND COGNITIVE NEUROSCIENCE',
        courseName:
          'Psychology 3295F/G - SPECIAL TOPICS IN BEHAVIORAL AND COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d047f7e10015fd8006',
      },
      {
        _id: '60cc25d047f7e10015fd8008',
        courseCode: 'PSYCHOL 3301',
        shortName: 'Psychology 3301F/G - CLINICAL PSYCHOLOGY',
        courseName: 'Psychology 3301F/G - CLINICAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d047f7e10015fd8008',
      },
      {
        _id: '60cc25d147f7e10015fd800a',
        courseCode: 'PSYCHOL 3304',
        shortName: 'Psychology 3304G - HUMAN LEARNING. BEHAVIOR MODIFICATION',
        courseName: 'Psychology 3304G - HUMAN LEARNING. BEHAVIOR MODIFICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d147f7e10015fd800a',
      },
      {
        _id: '60cc25d147f7e10015fd800c',
        courseCode: 'PSYCHOL 3310',
        shortName: 'Psychology 3310F/G - ADULT PSYCHOPATHOLOGY',
        courseName: 'Psychology 3310F/G - ADULT PSYCHOPATHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d147f7e10015fd800c',
      },
      {
        _id: '60cc25d147f7e10015fd800e',
        courseCode: 'PSYCHOL 3311',
        shortName: 'Psychology 3311 - ABNORMAL PSYCHOLOGY',
        courseName: 'Psychology 3311 - ABNORMAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d147f7e10015fd800e',
      },
      {
        _id: '60cc25d147f7e10015fd8010',
        courseCode: 'PSYCHOL 3312',
        shortName: 'Psychology 3312F/G - STRESS AND COPING',
        courseName: 'Psychology 3312F/G - STRESS AND COPING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d147f7e10015fd8010',
      },
      {
        _id: '60cc25d247f7e10015fd8012',
        courseCode: 'PSYCHOL 3313',
        shortName: 'Psychology 3313A/B - FUNDAMENTALS OF FORENSIC PSYCHOLOGY',
        courseName: 'Psychology 3313A/B - FUNDAMENTALS OF FORENSIC PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d247f7e10015fd8012',
      },
      {
        _id: '60cc25d247f7e10015fd8014',
        courseCode: 'PSYCHOL 3315',
        shortName: 'Psychology 3315E - ADDICTIONS: THEORY AND RESEARCH',
        courseName: 'Psychology 3315E - ADDICTIONS: THEORY AND RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d247f7e10015fd8014',
      },
      {
        _id: '60cc25d247f7e10015fd8016',
        courseCode: 'PSYCHOL 3316',
        shortName: 'Psychology 3316F/G - PSYCHOLOGICAL TRAUMA',
        courseName: 'Psychology 3316F/G - PSYCHOLOGICAL TRAUMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d247f7e10015fd8016',
      },
      {
        _id: '60cc25d347f7e10015fd8018',
        courseCode: 'PSYCHOL 3317',
        shortName: 'Psychology 3317E - COMMUNITY PSYCHOLOGY',
        courseName: 'Psychology 3317E - COMMUNITY PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d347f7e10015fd8018',
      },
      {
        _id: '60cc25d347f7e10015fd801a',
        courseCode: 'PSYCHOL 3318',
        shortName: 'Psychology 3318F/G - FUNDAMENTALS OF COMMUNITY PSYCHOLOGY',
        courseName: 'Psychology 3318F/G - FUNDAMENTALS OF COMMUNITY PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d347f7e10015fd801a',
      },
      {
        _id: '60cc25d347f7e10015fd801c',
        courseCode: 'PSYCHOL 3320',
        shortName: 'Psychology 3320F/G - CHILD PSYCHOPATHOLOGY',
        courseName: 'Psychology 3320F/G - CHILD PSYCHOPATHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d347f7e10015fd801c',
      },
      {
        _id: '60cc25d347f7e10015fd801e',
        courseCode: 'PSYCHOL 3330',
        shortName: 'Psychology 3330F/G - HEALTH PSYCHOLOGY',
        courseName: 'Psychology 3330F/G - HEALTH PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d347f7e10015fd801e',
      },
      {
        _id: '60cc25d447f7e10015fd8020',
        courseCode: 'PSYCHOL 3337',
        shortName: 'Psychology 3337F/G - MENTAL HEALTH AND PHYSICAL ACTIVITY',
        courseName: 'Psychology 3337F/G - MENTAL HEALTH AND PHYSICAL ACTIVITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d447f7e10015fd8020',
      },
      {
        _id: '60cc25d447f7e10015fd8022',
        courseCode: 'PSYCHOL 3350',
        shortName: 'Psychology 3350F/G - ADOLESCENT RISK BEHAVIOUR',
        courseName: 'Psychology 3350F/G - ADOLESCENT RISK BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d447f7e10015fd8022',
      },
      {
        _id: '60cc25d447f7e10015fd8024',
        courseCode: 'PSYCHOL 3371',
        shortName: 'Psychology 3371F/G - THERAPEUTIC COUNSELLING',
        courseName: 'Psychology 3371F/G - THERAPEUTIC COUNSELLING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d447f7e10015fd8024',
      },
      {
        _id: '60cc25d547f7e10015fd8026',
        courseCode: 'PSYCHOL 3375',
        shortName:
          'Psychology 3375F/G - INTRODUCTION TO COUNSELLING AND PSYCHOTHERAPY',
        courseName:
          'Psychology 3375F/G - INTRODUCTION TO COUNSELLING AND PSYCHOTHERAPY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d547f7e10015fd8026',
      },
      {
        _id: '60cc25d547f7e10015fd8028',
        courseCode: 'PSYCHOL 3390',
        shortName: 'Psychology 3390F/G - SPECIAL TOPICS IN CLINICAL PSYCHOLOGY',
        courseName:
          'Psychology 3390F/G - SPECIAL TOPICS IN CLINICAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d547f7e10015fd8028',
      },
      {
        _id: '60cc25d547f7e10015fd802a',
        courseCode: 'PSYCHOL 3393',
        shortName: 'Psychology 3393F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3393F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d547f7e10015fd802a',
      },
      {
        _id: '60cc25d547f7e10015fd802c',
        courseCode: 'PSYCHOL 3434',
        shortName: 'Psychology 3434E - THE EXCEPTIONAL CHILD',
        courseName: 'Psychology 3434E - THE EXCEPTIONAL CHILD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d547f7e10015fd802c',
      },
      {
        _id: '60cc25d647f7e10015fd802e',
        courseCode: 'PSYCHOL 3440',
        shortName: 'Psychology 3440F/G - DEVELOPMENTAL COGNITIVE NEUROSCIENCE',
        courseName: 'Psychology 3440F/G - DEVELOPMENTAL COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d647f7e10015fd802e',
      },
      {
        _id: '60cc25d647f7e10015fd8030',
        courseCode: 'PSYCHOL 3441',
        shortName:
          'Psychology 3441F/G - FRONTAL CORTEX AND THE DEVELOPMENT OF COGNITIVE CONTROL',
        courseName:
          'Psychology 3441F/G - FRONTAL CORTEX AND THE DEVELOPMENT OF COGNITIVE CONTROL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d647f7e10015fd8030',
      },
      {
        _id: '60cc25d647f7e10015fd8032',
        courseCode: 'PSYCHOL 3442',
        shortName: 'Psychology 3442F/G - MIND, BRAIN AND EDUCATION',
        courseName: 'Psychology 3442F/G - MIND, BRAIN AND EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d647f7e10015fd8032',
      },
      {
        _id: '60cc25d647f7e10015fd8034',
        courseCode: 'PSYCHOL 3443',
        shortName: 'Psychology 3443F/G - DEVELOPMENT OF THE MATHEMATICAL BRAIN',
        courseName:
          'Psychology 3443F/G - DEVELOPMENT OF THE MATHEMATICAL BRAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d647f7e10015fd8034',
      },
      {
        _id: '60cc25d747f7e10015fd8036',
        courseCode: 'PSYCHOL 3444',
        shortName: 'Psychology 3444F/G - DEVELOPMENT OF THE SOCIAL BRAIN',
        courseName: 'Psychology 3444F/G - DEVELOPMENT OF THE SOCIAL BRAIN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d747f7e10015fd8036',
      },
      {
        _id: '60cc25d747f7e10015fd8038',
        courseCode: 'PSYCHOL 3445',
        shortName: 'Psychology 3445F/G - SOCIAL DEVELOPMENT',
        courseName: 'Psychology 3445F/G - SOCIAL DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d747f7e10015fd8038',
      },
      {
        _id: '60cc25d747f7e10015fd803a',
        courseCode: 'PSYCHOL 3450',
        shortName: 'Psychology 3450F/G - DEVELOPMENT DURING INFANCY',
        courseName: 'Psychology 3450F/G - DEVELOPMENT DURING INFANCY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d747f7e10015fd803a',
      },
      {
        _id: '60cc25d747f7e10015fd803c',
        courseCode: 'PSYCHOL 3480',
        shortName: 'Psychology 3480F/G - RESEARCH IN DEVELOPMENTAL PSYCHOLOGY',
        courseName: 'Psychology 3480F/G - RESEARCH IN DEVELOPMENTAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d747f7e10015fd803c',
      },
      {
        _id: '60cc25d847f7e10015fd803e',
        courseCode: 'PSYCHOL 3485',
        shortName:
          'Psychology 3485F/G - RESEARCH IN DEVELOPMENTAL COGNITIVE NEUROSCIENCE',
        courseName:
          'Psychology 3485F/G - RESEARCH IN DEVELOPMENTAL COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d847f7e10015fd803e',
      },
      {
        _id: '60cc25d847f7e10015fd8040',
        courseCode: 'PSYCHOL 3490',
        shortName:
          'Psychology 3490F/G - SPECIAL TOPICS IN DEVELOPMENTAL PSYCHOLOGY',
        courseName:
          'Psychology 3490F/G - SPECIAL TOPICS IN DEVELOPMENTAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d847f7e10015fd8040',
      },
      {
        _id: '60cc25d847f7e10015fd8042',
        courseCode: 'PSYCHOL 3580',
        shortName: 'Psychology 3580F/G - RESEARCH IN PERSONALITY ASSESSMENT',
        courseName: 'Psychology 3580F/G - RESEARCH IN PERSONALITY ASSESSMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d847f7e10015fd8042',
      },
      {
        _id: '60cc25d947f7e10015fd8044',
        courseCode: 'PSYCHOL 3610',
        shortName:
          'Psychology 3610F/G - USING PSYCHOLOGY TO MANAGE AND MEASURE EMPLOYEE WORK PERFORMANCE',
        courseName:
          'Psychology 3610F/G - USING PSYCHOLOGY TO MANAGE AND MEASURE EMPLOYEE WORK PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d947f7e10015fd8044',
      },
      {
        _id: '60cc25d947f7e10015fd8046',
        courseCode: 'PSYCHOL 3690',
        shortName:
          'Psychology 3690F/G - SPECIAL TOPICS IN INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
        courseName:
          'Psychology 3690F/G - SPECIAL TOPICS IN INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d947f7e10015fd8046',
      },
      {
        _id: '60cc25d947f7e10015fd8048',
        courseCode: 'PSYCHOL 3694',
        shortName:
          'Psychology 3694F/G - TEAMS AND WORK GROUPS IN ORGANIZATIONS',
        courseName:
          'Psychology 3694F/G - TEAMS AND WORK GROUPS IN ORGANIZATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d947f7e10015fd8048',
      },
      {
        _id: '60cc25d947f7e10015fd804a',
        courseCode: 'PSYCHOL 3695',
        shortName:
          'Psychology 3695F/G - SPECIAL TOPICS IN EDUCATIONAL PSYCHOLOGY',
        courseName:
          'Psychology 3695F/G - SPECIAL TOPICS IN EDUCATIONAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d947f7e10015fd804a',
      },
      {
        _id: '60cc25d947f7e10015fd804c',
        courseCode: 'PSYCHOL 3696',
        shortName: 'Psychology 3696F/G - DYNAMICS OF MENTORSHIP IN PSYCHOLOGY',
        courseName: 'Psychology 3696F/G - DYNAMICS OF MENTORSHIP IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25d947f7e10015fd804c',
      },
      {
        _id: '60cc25da47f7e10015fd804e',
        courseCode: 'PSYCHOL 3720',
        shortName:
          'Psychology 3720F/G - THE PSYCHOLOGY OF PROSOCIAL AND ANTISOCIAL BEHAVIOUR',
        courseName:
          'Psychology 3720F/G - THE PSYCHOLOGY OF PROSOCIAL AND ANTISOCIAL BEHAVIOUR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25da47f7e10015fd804e',
      },
      {
        _id: '60cc25da47f7e10015fd8050',
        courseCode: 'PSYCHOL 3721',
        shortName: 'Psychology 3721F/G - THE PSYCHOLOGY OF PERSUASION',
        courseName: 'Psychology 3721F/G - THE PSYCHOLOGY OF PERSUASION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25da47f7e10015fd8050',
      },
      {
        _id: '60cc25da47f7e10015fd8052',
        courseCode: 'PSYCHOL 3722',
        shortName: 'Psychology 3722F/G - PERSUASION THEORY AND RESEARCH',
        courseName: 'Psychology 3722F/G - PERSUASION THEORY AND RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25da47f7e10015fd8052',
      },
      {
        _id: '60cc25db47f7e10015fd8054',
        courseCode: 'PSYCHOL 3723',
        shortName: 'Psychology 3723F/G - ATTITUDES AND ATTITUDE CHANGE',
        courseName: 'Psychology 3723F/G - ATTITUDES AND ATTITUDE CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25db47f7e10015fd8054',
      },
      {
        _id: '60cc25db47f7e10015fd8056',
        courseCode: 'PSYCHOL 3724',
        shortName: 'Psychology 3724F/G - THE SCIENCE OF ROMANTIC RELATIONSHIPS',
        courseName:
          'Psychology 3724F/G - THE SCIENCE OF ROMANTIC RELATIONSHIPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25db47f7e10015fd8056',
      },
      {
        _id: '60cc25db47f7e10015fd8058',
        courseCode: 'PSYCHOL 3725',
        shortName: 'Psychology 3725F/G - THE PSYCHOLOGY OF PREJUDICE',
        courseName: 'Psychology 3725F/G - THE PSYCHOLOGY OF PREJUDICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25db47f7e10015fd8058',
      },
      {
        _id: '60cc25db47f7e10015fd805a',
        courseCode: 'PSYCHOL 3726',
        shortName: 'Psychology 3726F/G - PERSONAL RELATIONSHIPS',
        courseName: 'Psychology 3726F/G - PERSONAL RELATIONSHIPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25db47f7e10015fd805a',
      },
      {
        _id: '60cc25dc47f7e10015fd805c',
        courseCode: 'PSYCHOL 3740',
        shortName: 'Psychology 3740F/G - SOCIAL PSYCHOLOGY OF ATTITUDES',
        courseName: 'Psychology 3740F/G - SOCIAL PSYCHOLOGY OF ATTITUDES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dc47f7e10015fd805c',
      },
      {
        _id: '60cc25dc47f7e10015fd805e',
        courseCode: 'PSYCHOL 3760',
        shortName: 'Psychology 3760F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3760F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dc47f7e10015fd805e',
      },
      {
        _id: '60cc25dc47f7e10015fd8060',
        courseCode: 'PSYCHOL 3770',
        shortName: 'Psychology 3770F/G - THE SELF',
        courseName: 'Psychology 3770F/G - THE SELF',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dc47f7e10015fd8060',
      },
      {
        _id: '60cc25dd47f7e10015fd8062',
        courseCode: 'PSYCHOL 3771',
        shortName: 'Psychology 3771F/G - HUMANISTIC PSYCHOLOGY',
        courseName: 'Psychology 3771F/G - HUMANISTIC PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dd47f7e10015fd8062',
      },
      {
        _id: '60cc25dd47f7e10015fd8064',
        courseCode: 'PSYCHOL 3772',
        shortName:
          'Psychology 3772F/G - PSYCHOLOGY OF RELIGION AND SPIRITUALITY: SELECTED TOPICS',
        courseName:
          'Psychology 3772F/G - PSYCHOLOGY OF RELIGION AND SPIRITUALITY: SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dd47f7e10015fd8064',
      },
      {
        _id: '60cc25dd47f7e10015fd8066',
        courseCode: 'PSYCHOL 3774',
        shortName: 'Psychology 3774F/G - PSYCHOLOGICAL PERSPECTIVES OF GENDER',
        courseName: 'Psychology 3774F/G - PSYCHOLOGICAL PERSPECTIVES OF GENDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dd47f7e10015fd8066',
      },
      {
        _id: '60cc25dd47f7e10015fd8068',
        courseCode: 'PSYCHOL 3780',
        shortName: 'Psychology 3780F/G - RESEARCH IN SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 3780F/G - RESEARCH IN SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25dd47f7e10015fd8068',
      },
      {
        _id: '60cc25de47f7e10015fd806a',
        courseCode: 'PSYCHOL 3781',
        shortName:
          'Psychology 3781F/G - SOCIAL RELATIONSHIPS IN CHILDHOOD AND ADOLESCENCE',
        courseName:
          'Psychology 3781F/G - SOCIAL RELATIONSHIPS IN CHILDHOOD AND ADOLESCENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25de47f7e10015fd806a',
      },
      {
        _id: '60cc25de47f7e10015fd806c',
        courseCode: 'PSYCHOL 3790',
        shortName: 'Psychology 3790F/G - SPECIAL TOPICS IN SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 3790F/G - SPECIAL TOPICS IN SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25de47f7e10015fd806c',
      },
      {
        _id: '60cc25de47f7e10015fd806e',
        courseCode: 'PSYCHOL 3800',
        shortName:
          'Psychology 3800F/G - PSYCHOLOGICAL STATISTICS USING COMPUTERS',
        courseName:
          'Psychology 3800F/G - PSYCHOLOGICAL STATISTICS USING COMPUTERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25de47f7e10015fd806e',
      },
      {
        _id: '60cc25de47f7e10015fd8070',
        courseCode: 'PSYCHOL 3814',
        shortName: 'Psychology 3814F/G - ETHICS IN PSYCHOLOGY',
        courseName: 'Psychology 3814F/G - ETHICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25de47f7e10015fd8070',
      },
      {
        _id: '60cc25df47f7e10015fd8072',
        courseCode: 'PSYCHOL 3830',
        shortName: 'Psychology 3830F/G - COMPUTERS & RESEARCH IN PSYCHOLOGY',
        courseName: 'Psychology 3830F/G - COMPUTERS & RESEARCH IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25df47f7e10015fd8072',
      },
      {
        _id: '60cc25df47f7e10015fd8074',
        courseCode: 'PSYCHOL 3840',
        shortName: 'Psychology 3840F/G - SURVEY DESIGN AND CONSTRUCTION',
        courseName: 'Psychology 3840F/G - SURVEY DESIGN AND CONSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25df47f7e10015fd8074',
      },
      {
        _id: '60cc25df47f7e10015fd8076',
        courseCode: 'PSYCHOL 3860',
        shortName: 'Psychology 3860F/G - QUALITATIVE RESEARCH METHODS',
        courseName: 'Psychology 3860F/G - QUALITATIVE RESEARCH METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25df47f7e10015fd8076',
      },
      {
        _id: '60cc25e047f7e10015fd8078',
        courseCode: 'PSYCHOL 3891',
        shortName:
          'Psychology 3891F/G - EXPERIMENTAL RESEARCH METHODS AND ANALYSIS',
        courseName:
          'Psychology 3891F/G - EXPERIMENTAL RESEARCH METHODS AND ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e047f7e10015fd8078',
      },
      {
        _id: '60cc25e047f7e10015fd807a',
        courseCode: 'PSYCHOL 3892',
        shortName:
          'Psychology 3892F/G - CORRELATIONAL AND MULTIVARIATE RESEARCH METHODS AND ANALYSIS',
        courseName:
          'Psychology 3892F/G - CORRELATIONAL AND MULTIVARIATE RESEARCH METHODS AND ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e047f7e10015fd807a',
      },
      {
        _id: '60cc25e047f7e10015fd807c',
        courseCode: 'PSYCHOL 3893',
        shortName: 'Psychology 3893F/G - THEORY IN PSYCHOLOGY',
        courseName: 'Psychology 3893F/G - THEORY IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e047f7e10015fd807c',
      },
      {
        _id: '60cc25e047f7e10015fd807e',
        courseCode: 'PSYCHOL 3895',
        shortName: 'Psychology 3895E - SOCIAL SCIENCE IN THE COMMUNITY',
        courseName: 'Psychology 3895E - SOCIAL SCIENCE IN THE COMMUNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e047f7e10015fd807e',
      },
      {
        _id: '60cc25e147f7e10015fd8080',
        courseCode: 'PSYCHOL 3912',
        shortName: 'Psychology 3912F/G - PSYCHOLOGY AND THE ARTS',
        courseName: 'Psychology 3912F/G - PSYCHOLOGY AND THE ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e147f7e10015fd8080',
      },
      {
        _id: '60cc25e147f7e10015fd8082',
        courseCode: 'PSYCHOL 3950',
        shortName: 'Psychology 3950F/G - HISTORY OF PSYCHOLOGY',
        courseName: 'Psychology 3950F/G - HISTORY OF PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e147f7e10015fd8082',
      },
      {
        _id: '60cc25e247f7e10015fd8084',
        courseCode: 'PSYCHOL 3990',
        shortName: 'Psychology 3990F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3990F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e247f7e10015fd8084',
      },
      {
        _id: '60cc25e247f7e10015fd8086',
        courseCode: 'PSYCHOL 3991',
        shortName: 'Psychology 3991F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3991F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e247f7e10015fd8086',
      },
      {
        _id: '60cc25e247f7e10015fd8088',
        courseCode: 'PSYCHOL 3992',
        shortName: 'Psychology 3992F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3992F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e247f7e10015fd8088',
      },
      {
        _id: '60cc25e347f7e10015fd808a',
        courseCode: 'PSYCHOL 3993',
        shortName: 'Psychology 3993F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3993F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e347f7e10015fd808a',
      },
      {
        _id: '60cc25e347f7e10015fd808c',
        courseCode: 'PSYCHOL 3994',
        shortName: 'Psychology 3994F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3994F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e347f7e10015fd808c',
      },
      {
        _id: '60cc25e347f7e10015fd808e',
        courseCode: 'PSYCHOL 3995',
        shortName: 'Psychology 3995F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 3995F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e347f7e10015fd808e',
      },
      {
        _id: '60cc25e347f7e10015fd8090',
        courseCode: 'PSYCHOL 3996',
        shortName: 'Psychology 3996F - INDEPENDENT STUDY',
        courseName: 'Psychology 3996F - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e347f7e10015fd8090',
      },
      {
        _id: '60cc25e447f7e10015fd8092',
        courseCode: 'PSYCHOL 3997',
        shortName: 'Psychology 3997G - INDEPENDENT STUDY',
        courseName: 'Psychology 3997G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e447f7e10015fd8092',
      },
      {
        _id: '60cc25e447f7e10015fd8094',
        courseCode: 'PSYCHOL 3998',
        shortName: 'Psychology 3998F - INDEPENDENT STUDY',
        courseName: 'Psychology 3998F - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e447f7e10015fd8094',
      },
      {
        _id: '60cc25e447f7e10015fd8096',
        courseCode: 'PSYCHOL 3998',
        shortName: 'Psychology 3998F/G - INDEPENDENT STUDY',
        courseName: 'Psychology 3998F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e447f7e10015fd8096',
      },
      {
        _id: '60cc25e547f7e10015fd8098',
        courseCode: 'PSYCHOL 3999',
        shortName: 'Psychology 3999G - INDEPENDENT STUDY',
        courseName: 'Psychology 3999G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e547f7e10015fd8098',
      },
      {
        _id: '60cc25e547f7e10015fd809a',
        courseCode: 'PSYCHOL 4115',
        shortName: 'Psychology 4115F/G - ATYPICAL SENSORY EXPERIENCE',
        courseName: 'Psychology 4115F/G - ATYPICAL SENSORY EXPERIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e547f7e10015fd809a',
      },
      {
        _id: '60cc25e547f7e10015fd809c',
        courseCode: 'PSYCHOL 4190',
        shortName:
          'Psychology 4190F/G - SPECIAL TOPICS IN SENSATION AND PERCEPTION PSYCHOLOGY',
        courseName:
          'Psychology 4190F/G - SPECIAL TOPICS IN SENSATION AND PERCEPTION PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e547f7e10015fd809c',
      },
      {
        _id: '60cc25e647f7e10015fd809e',
        courseCode: 'PSYCHOL 4195',
        shortName:
          'Psychology 4195F/G - SPECIAL TOPICS IN COGNITIVE PSYCHOLOGY',
        courseName:
          'Psychology 4195F/G - SPECIAL TOPICS IN COGNITIVE PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e647f7e10015fd809e',
      },
      {
        _id: '60cc25e647f7e10015fd80a0',
        courseCode: 'PSYCHOL 4222',
        shortName: 'Psychology 4222F/G - BEHAVIOURAL PHARMACOLOGY',
        courseName: 'Psychology 4222F/G - BEHAVIOURAL PHARMACOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e647f7e10015fd80a0',
      },
      {
        _id: '60cc25e647f7e10015fd80a2',
        courseCode: 'PSYCHOL 4223',
        shortName:
          'Psychology 4223F/G - BRAIN, BEHAVIOR, AND THE IMMUNE SYSTEM',
        courseName:
          'Psychology 4223F/G - BRAIN, BEHAVIOR, AND THE IMMUNE SYSTEM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e647f7e10015fd80a2',
      },
      {
        _id: '60cc25e647f7e10015fd80a4',
        courseCode: 'PSYCHOL 4224',
        shortName: 'Psychology 4224F/G - ANIMAL COGNITION',
        courseName: 'Psychology 4224F/G - ANIMAL COGNITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e647f7e10015fd80a4',
      },
      {
        _id: '60cc25e747f7e10015fd80a6',
        courseCode: 'PSYCHOL 4260',
        shortName: 'Psychology 4260F/G - SENSORIMOTOR NEUROSCIENCE',
        courseName: 'Psychology 4260F/G - SENSORIMOTOR NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e747f7e10015fd80a6',
      },
      {
        _id: '60cc25e747f7e10015fd80a8',
        courseCode: 'PSYCHOL 4290',
        shortName:
          'Psychology 4290F/G - SPECIAL TOPICS IN ANIMAL BEHAVIOUR AND ANIMAL COGNITION',
        courseName:
          'Psychology 4290F/G - SPECIAL TOPICS IN ANIMAL BEHAVIOUR AND ANIMAL COGNITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e747f7e10015fd80a8',
      },
      {
        _id: '60cc25e747f7e10015fd80aa',
        courseCode: 'PSYCHOL 4295',
        shortName:
          'Psychology 4295F/G - SPECIAL TOPICS  IN BEHAVIOURAL AND COGNITIVE NEUROSCIENCE',
        courseName:
          'Psychology 4295F/G - SPECIAL TOPICS  IN BEHAVIOURAL AND COGNITIVE NEUROSCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e747f7e10015fd80aa',
      },
      {
        _id: '60cc25e747f7e10015fd80ac',
        courseCode: 'PSYCHOL 4303',
        shortName: 'Psychology 4303F/G - MENTAL HEALTH AND DEAFNESS',
        courseName: 'Psychology 4303F/G - MENTAL HEALTH AND DEAFNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e747f7e10015fd80ac',
      },
      {
        _id: '60cc25e847f7e10015fd80ae',
        courseCode: 'PSYCHOL 4390',
        shortName: 'Psychology 4390F/G - SPECIAL TOPICS IN CLINICAL PSYCHOLOGY',
        courseName:
          'Psychology 4390F/G - SPECIAL TOPICS IN CLINICAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e847f7e10015fd80ae',
      },
      {
        _id: '60cc25e847f7e10015fd80b0',
        courseCode: 'PSYCHOL 4420',
        shortName: 'Psychology 4420F/G - BULLYING AND VICTIMIZATION',
        courseName: 'Psychology 4420F/G - BULLYING AND VICTIMIZATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e847f7e10015fd80b0',
      },
      {
        _id: '60cc25e847f7e10015fd80b2',
        courseCode: 'PSYCHOL 4490',
        shortName:
          'Psychology 4490F/G - SPECIAL TOPICS IN DEVELOPMENTAL PSYCHOLOGY',
        courseName:
          'Psychology 4490F/G - SPECIAL TOPICS IN DEVELOPMENTAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e847f7e10015fd80b2',
      },
      {
        _id: '60cc25e847f7e10015fd80b4',
        courseCode: 'PSYCHOL 4590',
        shortName: 'Psychology 4590F/G - SPECIAL TOPICS IN PERSONALITY',
        courseName: 'Psychology 4590F/G - SPECIAL TOPICS IN PERSONALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e847f7e10015fd80b4',
      },
      {
        _id: '60cc25e947f7e10015fd80b6',
        courseCode: 'PSYCHOL 4690',
        shortName:
          'Psychology 4690F/G - SPECIAL TOPICS IN INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
        courseName:
          'Psychology 4690F/G - SPECIAL TOPICS IN INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e947f7e10015fd80b6',
      },
      {
        _id: '60cc25e947f7e10015fd80b8',
        courseCode: 'PSYCHOL 4692',
        shortName: 'Psychology 4692E - PRACTICUM',
        courseName: 'Psychology 4692E - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e947f7e10015fd80b8',
      },
      {
        _id: '60cc25e947f7e10015fd80ba',
        courseCode: 'PSYCHOL 4694',
        shortName:
          'Psychology 4694E - PSYCHOLOGY FOR THE COMMON GOOD: CREATING RESEARCH-BASED APPLICATIONS',
        courseName:
          'Psychology 4694E - PSYCHOLOGY FOR THE COMMON GOOD: CREATING RESEARCH-BASED APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e947f7e10015fd80ba',
      },
      {
        _id: '60cc25e947f7e10015fd80bc',
        courseCode: 'PSYCHOL 4698',
        shortName: 'Psychology 4698F/G - INDEPENDENT STUDY',
        courseName: 'Psychology 4698F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25e947f7e10015fd80bc',
      },
      {
        _id: '60cc25ea47f7e10015fd80be',
        courseCode: 'PSYCHOL 4699',
        shortName: 'Psychology 4699E - INDEPENDENT STUDY',
        courseName: 'Psychology 4699E - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ea47f7e10015fd80be',
      },
      {
        _id: '60cc25ea47f7e10015fd80c0',
        courseCode: 'PSYCHOL 4790',
        shortName: 'Psychology 4790F/G - SPECIAL TOPICS IN SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 4790F/G - SPECIAL TOPICS IN SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ea47f7e10015fd80c0',
      },
      {
        _id: '60cc25ea47f7e10015fd80c2',
        courseCode: 'PSYCHOL 4791',
        shortName: 'Psychology 4791F/G - SPECIAL TOPICS IN SOCIAL PSYCHOLOGY',
        courseName: 'Psychology 4791F/G - SPECIAL TOPICS IN SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ea47f7e10015fd80c2',
      },
      {
        _id: '60cc25ea47f7e10015fd80c4',
        courseCode: 'PSYCHOL 4842',
        shortName: 'Psychology 4842E - HONOURS THESIS',
        courseName: 'Psychology 4842E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ea47f7e10015fd80c4',
      },
      {
        _id: '60cc25eb47f7e10015fd80c6',
        courseCode: 'PSYCHOL 4850',
        shortName: 'Psychology 4850E - HONOURS THESIS',
        courseName: 'Psychology 4850E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25eb47f7e10015fd80c6',
      },
      {
        _id: '60cc25eb47f7e10015fd80c8',
        courseCode: 'PSYCHOL 4851',
        shortName: 'Psychology 4851E - HONOURS THESIS (SCIENCE)',
        courseName: 'Psychology 4851E - HONOURS THESIS (SCIENCE)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25eb47f7e10015fd80c8',
      },
      {
        _id: '60cc25eb47f7e10015fd80ca',
        courseCode: 'PSYCHOL 4852',
        shortName: 'Psychology 4852E - HONOURS THESIS',
        courseName: 'Psychology 4852E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25eb47f7e10015fd80ca',
      },
      {
        _id: '60cc25ec47f7e10015fd80cc',
        courseCode: 'PSYCHOL 4880',
        shortName: 'Psychology 4880E - HONOURS THESIS',
        courseName: 'Psychology 4880E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ec47f7e10015fd80cc',
      },
      {
        _id: '60cc25ec47f7e10015fd80ce',
        courseCode: 'PSYCHOL 4891',
        shortName: 'Psychology 4891E - HONOURS THESIS',
        courseName: 'Psychology 4891E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ec47f7e10015fd80ce',
      },
      {
        _id: '60cc25ec47f7e10015fd80d0',
        courseCode: 'PSYCHOL 4990',
        shortName: 'Psychology 4990F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 4990F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ec47f7e10015fd80d0',
      },
      {
        _id: '60cc25ec47f7e10015fd80d2',
        courseCode: 'PSYCHOL 4991',
        shortName: 'Psychology 4991F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 4991F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ec47f7e10015fd80d2',
      },
      {
        _id: '60cc25ed47f7e10015fd80d4',
        courseCode: 'PSYCHOL 4992',
        shortName: 'Psychology 4992F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 4992F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ed47f7e10015fd80d4',
      },
      {
        _id: '60cc25ed47f7e10015fd80d6',
        courseCode: 'PSYCHOL 4993',
        shortName: 'Psychology 4993F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 4993F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ed47f7e10015fd80d6',
      },
      {
        _id: '60cc25ed47f7e10015fd80d8',
        courseCode: 'PSYCHOL 4994',
        shortName: 'Psychology 4994F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        courseName: 'Psychology 4994F/G - SPECIAL TOPICS IN PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ed47f7e10015fd80d8',
      },
      {
        _id: '60cc25ed47f7e10015fd80da',
        courseCode: 'REHABSCI 3060',
        shortName:
          'Rehabilitation Sciences 3060A/B - HEALTH CONDITIONS AND DISEASE',
        courseName:
          'Rehabilitation Sciences 3060A/B - HEALTH CONDITIONS AND DISEASE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ed47f7e10015fd80da',
      },
      {
        _id: '60cc25ee47f7e10015fd80dc',
        courseCode: 'REHABSCI 3061',
        shortName:
          'Rehabilitation Sciences 3061A/B - FOUNDATIONS IN REHABILITATION SCIENCE',
        courseName:
          'Rehabilitation Sciences 3061A/B - FOUNDATIONS IN REHABILITATION SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ee47f7e10015fd80dc',
      },
      {
        _id: '60cc25ee47f7e10015fd80de',
        courseCode: 'REHABSCI 3062',
        shortName:
          'Rehabilitation Sciences 3062A/B - FUNCTIONAL NEUROSCIENCE FOR SPECIAL POPULATIONS',
        courseName:
          'Rehabilitation Sciences 3062A/B - FUNCTIONAL NEUROSCIENCE FOR SPECIAL POPULATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ee47f7e10015fd80de',
      },
      {
        _id: '60cc25ee47f7e10015fd80e0',
        courseCode: 'REHABSCI 3090',
        shortName:
          'Rehabilitation Sciences 3090A/B - SPECIAL TOPICS IN REHABILITATION SCIENCES',
        courseName:
          'Rehabilitation Sciences 3090A/B - SPECIAL TOPICS IN REHABILITATION SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ee47f7e10015fd80e0',
      },
      {
        _id: '60cc25ee47f7e10015fd80e2',
        courseCode: 'REHABSCI 3125',
        shortName:
          'Rehabilitation Sciences 3125A/B - ENABLING HEALTH & WELL-BEING THROUGH OCCUPATION',
        courseName:
          'Rehabilitation Sciences 3125A/B - ENABLING HEALTH & WELL-BEING THROUGH OCCUPATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ee47f7e10015fd80e2',
      },
      {
        _id: '60cc25ef47f7e10015fd80e4',
        courseCode: 'REHABSCI 3360',
        shortName:
          'Rehabilitation Sciences 3360A/B - MUSCULOSKELETAL DISORDERS IN REHABILITATION',
        courseName:
          'Rehabilitation Sciences 3360A/B - MUSCULOSKELETAL DISORDERS IN REHABILITATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ef47f7e10015fd80e4',
      },
      {
        _id: '60cc25ef47f7e10015fd80e6',
        courseCode: 'REHABSCI 3760',
        shortName:
          'Rehabilitation Sciences 3760A/B - REHABILITATION FOR CHILDHOOD DISORDERS',
        courseName:
          'Rehabilitation Sciences 3760A/B - REHABILITATION FOR CHILDHOOD DISORDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ef47f7e10015fd80e6',
      },
      {
        _id: '60cc25ef47f7e10015fd80e8',
        courseCode: 'REHABSCI 4090',
        shortName:
          'Rehabilitation Sciences 4090A/B - SPECIAL TOPICS IN REHABILITATION SCIENCES',
        courseName:
          'Rehabilitation Sciences 4090A/B - SPECIAL TOPICS IN REHABILITATION SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ef47f7e10015fd80e8',
      },
      {
        _id: '60cc25ef47f7e10015fd80ea',
        courseCode: 'REHABSCI 4212',
        shortName:
          'Rehabilitation Sciences 4212A/B - INNOVATIONS IN REHABILITATION',
        courseName:
          'Rehabilitation Sciences 4212A/B - INNOVATIONS IN REHABILITATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ef47f7e10015fd80ea',
      },
      {
        _id: '60cc25f047f7e10015fd80ec',
        courseCode: 'REHABSCI 4605',
        shortName:
          'Rehabilitation Sciences 4605A/B - REHABILITATION PSYCHOLOGY',
        courseName:
          'Rehabilitation Sciences 4605A/B - REHABILITATION PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f047f7e10015fd80ec',
      },
      {
        _id: '60cc25f047f7e10015fd80ee',
        courseCode: 'REHABSCI 4970',
        shortName:
          'Rehabilitation Sciences 4970E - CONCEPTUAL AND PRACTICAL BASIS FOR REHABILITATION PRACTICE',
        courseName:
          'Rehabilitation Sciences 4970E - CONCEPTUAL AND PRACTICAL BASIS FOR REHABILITATION PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f047f7e10015fd80ee',
      },
      {
        _id: '60cc25f047f7e10015fd80f0',
        courseCode: 'RELSTUD 0011',
        shortName: 'Religious Studies 0011 - CONTEMPORARY RELIGIOUS QUESTS',
        courseName: 'Religious Studies 0011 - CONTEMPORARY RELIGIOUS QUESTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f047f7e10015fd80f0',
      },
      {
        _id: '60cc25f147f7e10015fd80f2',
        courseCode: 'RELSTUD 0014',
        shortName:
          'Religious Studies 0014A/B - CONTEMPORARY RELIGIOUS QUESTS: RELIGION AND THE CONTEMPORARY WORLD',
        courseName:
          'Religious Studies 0014A/B - CONTEMPORARY RELIGIOUS QUESTS: RELIGION AND THE CONTEMPORARY WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f147f7e10015fd80f2',
      },
      {
        _id: '60cc25f147f7e10015fd80f4',
        courseCode: 'RELSTUD 0015',
        shortName:
          'Religious Studies 0015A/B - CONTEMPORARY RELIGIOUS QUESTS: RELIGION, CULTURE, VIOLENCE, AND IDENTITY',
        courseName:
          'Religious Studies 0015A/B - CONTEMPORARY RELIGIOUS QUESTS: RELIGION, CULTURE, VIOLENCE, AND IDENTITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f147f7e10015fd80f4',
      },
      {
        _id: '60cc25f147f7e10015fd80f6',
        courseCode: 'RELSTUD 1010',
        shortName:
          'Religious Studies 1010F/G - RELIGION, THEOLOGY AND GLOBAL ISSUES',
        courseName:
          'Religious Studies 1010F/G - RELIGION, THEOLOGY AND GLOBAL ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f147f7e10015fd80f6',
      },
      {
        _id: '60cc25f147f7e10015fd80f8',
        courseCode: 'RELSTUD 1015',
        shortName: 'Religious Studies 1015F/G - RELIGION AND DIFFERENCE',
        courseName: 'Religious Studies 1015F/G - RELIGION AND DIFFERENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f147f7e10015fd80f8',
      },
      {
        _id: '60cc25f247f7e10015fd80fa',
        courseCode: 'RELSTUD 1020',
        shortName: 'Religious Studies 1020A/B - BEAUTY AND BELIEF',
        courseName: 'Religious Studies 1020A/B - BEAUTY AND BELIEF',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f247f7e10015fd80fa',
      },
      {
        _id: '60cc25f247f7e10015fd80fc',
        courseCode: 'RELSTUD 1022',
        shortName: 'Religious Studies 1022A/B - PERPLEXING ISSUES',
        courseName: 'Religious Studies 1022A/B - PERPLEXING ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f247f7e10015fd80fc',
      },
      {
        _id: '60cc25f247f7e10015fd80fe',
        courseCode: 'RELSTUD 1023',
        shortName: 'Religious Studies 1023E - INTRODUCTION TO WORLD RELIGIONS',
        courseName: 'Religious Studies 1023E - INTRODUCTION TO WORLD RELIGIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f247f7e10015fd80fe',
      },
      {
        _id: '60cc25f247f7e10015fd8100',
        courseCode: 'RELSTUD 1026',
        shortName:
          'Religious Studies 1026F/G - INTRODUCTION TO THE OLD TESTAMENT I: RELIGIOUS THEMES',
        courseName:
          'Religious Studies 1026F/G - INTRODUCTION TO THE OLD TESTAMENT I: RELIGIOUS THEMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f247f7e10015fd8100',
      },
      {
        _id: '60cc25f347f7e10015fd8102',
        courseCode: 'RELSTUD 1027',
        shortName:
          'Religious Studies 1027E - INTRODUCTION TO CATHOLIC CHRISTIANITY',
        courseName:
          'Religious Studies 1027E - INTRODUCTION TO CATHOLIC CHRISTIANITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f347f7e10015fd8102',
      },
      {
        _id: '60cc25f347f7e10015fd8104',
        courseCode: 'RELSTUD 1028',
        shortName:
          'Religious Studies 1028F/G - INTRODUCTION TO THE NEW TESTAMENT I: THE GOSPELS',
        courseName:
          'Religious Studies 1028F/G - INTRODUCTION TO THE NEW TESTAMENT I: THE GOSPELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f347f7e10015fd8104',
      },
      {
        _id: '60cc25f347f7e10015fd8106',
        courseCode: 'RELSTUD 1030',
        shortName:
          'Religious Studies 1030A/B - UNDERSTANDING RELIGION: TRENDS AND TABOOS',
        courseName:
          'Religious Studies 1030A/B - UNDERSTANDING RELIGION: TRENDS AND TABOOS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f347f7e10015fd8106',
      },
      {
        _id: '60cc25f347f7e10015fd8108',
        courseCode: 'RELSTUD 1031',
        shortName: 'Religious Studies 1031E - CONTEMPORARY RELIGIOUS QUESTS',
        courseName: 'Religious Studies 1031E - CONTEMPORARY RELIGIOUS QUESTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f347f7e10015fd8108',
      },
      {
        _id: '60cc25f447f7e10015fd810a',
        courseCode: 'RELSTUD 1033',
        shortName:
          'Religious Studies 1033A/B - THE MEANING OF LIFE AT THE MOVIES',
        courseName:
          'Religious Studies 1033A/B - THE MEANING OF LIFE AT THE MOVIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f447f7e10015fd810a',
      },
      {
        _id: '60cc25f447f7e10015fd810c',
        courseCode: 'RELSTUD 1034',
        shortName:
          'Religious Studies 1034F/G - CONTEMPORARY RELIGIOUS QUESTS: RELIGION AND THE CONTEMPORARY WORLD',
        courseName:
          'Religious Studies 1034F/G - CONTEMPORARY RELIGIOUS QUESTS: RELIGION AND THE CONTEMPORARY WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f447f7e10015fd810c',
      },
      {
        _id: '60cc25f447f7e10015fd810e',
        courseCode: 'RELSTUD 1035',
        shortName:
          'Religious Studies 1035F/G - CONTEMPORARY RELIGIOUS QUESTS: RELIGION, CULTURE, VIOLENCE, AND IDENTITY',
        courseName:
          'Religious Studies 1035F/G - CONTEMPORARY RELIGIOUS QUESTS: RELIGION, CULTURE, VIOLENCE, AND IDENTITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f447f7e10015fd810e',
      },
      {
        _id: '60cc25f547f7e10015fd8110',
        courseCode: 'RELSTUD 2103',
        shortName: 'Religious Studies 2103A/B - LOST SCRIPTURES',
        courseName: 'Religious Studies 2103A/B - LOST SCRIPTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f547f7e10015fd8110',
      },
      {
        _id: '60cc25f547f7e10015fd8112',
        courseCode: 'RELSTUD 2112',
        shortName: 'Religious Studies 2112F/G - SPIRITUALITY OLD AND NEW',
        courseName: 'Religious Studies 2112F/G - SPIRITUALITY OLD AND NEW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f547f7e10015fd8112',
      },
      {
        _id: '60cc25f547f7e10015fd8114',
        courseCode: 'RELSTUD 2113',
        shortName:
          'Religious Studies 2113F/G - MODERNITY, POSTMODERNITY AND RELIGION',
        courseName:
          'Religious Studies 2113F/G - MODERNITY, POSTMODERNITY AND RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f547f7e10015fd8114',
      },
      {
        _id: '60cc25f547f7e10015fd8116',
        courseCode: 'RELSTUD 2114',
        shortName:
          'Religious Studies 2114A/B - INTRODUCTION TO THE DEAD SEA SCROLLS',
        courseName:
          'Religious Studies 2114A/B - INTRODUCTION TO THE DEAD SEA SCROLLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f547f7e10015fd8116',
      },
      {
        _id: '60cc25f647f7e10015fd8118',
        courseCode: 'RELSTUD 2120',
        shortName: 'Religious Studies 2120A/B - HUMAN/ANIMAL/SUBHUMAN',
        courseName: 'Religious Studies 2120A/B - HUMAN/ANIMAL/SUBHUMAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f647f7e10015fd8118',
      },
      {
        _id: '60cc25f647f7e10015fd811a',
        courseCode: 'RELSTUD 2122',
        shortName: 'Religious Studies 2122F/G - OLD TESTAMENT II',
        courseName: 'Religious Studies 2122F/G - OLD TESTAMENT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f647f7e10015fd811a',
      },
      {
        _id: '60cc25f647f7e10015fd811c',
        courseCode: 'RELSTUD 2123',
        shortName: 'Religious Studies 2123F/G - NEW TESTAMENT II',
        courseName: 'Religious Studies 2123F/G - NEW TESTAMENT II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f647f7e10015fd811c',
      },
      {
        _id: '60cc25f647f7e10015fd811e',
        courseCode: 'RELSTUD 2124',
        shortName:
          'Religious Studies 2124F/G - INTRODUCTION TO THE NEW TESTAMENT',
        courseName:
          'Religious Studies 2124F/G - INTRODUCTION TO THE NEW TESTAMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f647f7e10015fd811e',
      },
      {
        _id: '60cc25f747f7e10015fd8120',
        courseCode: 'RELSTUD 2125',
        shortName: 'Religious Studies 2125F/G - RELIGION, MORALITY AND SOCIETY',
        courseName:
          'Religious Studies 2125F/G - RELIGION, MORALITY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f747f7e10015fd8120',
      },
      {
        _id: '60cc25f747f7e10015fd8122',
        courseCode: 'RELSTUD 2126',
        shortName:
          'Religious Studies 2126F/G - THE EARLY CHURCH TO THE LATE MIDDLE AGES',
        courseName:
          'Religious Studies 2126F/G - THE EARLY CHURCH TO THE LATE MIDDLE AGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f747f7e10015fd8122',
      },
      {
        _id: '60cc25f747f7e10015fd8124',
        courseCode: 'RELSTUD 2127',
        shortName:
          'Religious Studies 2127F/G - THE LATE MIDDLE AGES TO THE MODERN PERIOD AND CONTEMPORARY WORLD',
        courseName:
          'Religious Studies 2127F/G - THE LATE MIDDLE AGES TO THE MODERN PERIOD AND CONTEMPORARY WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f747f7e10015fd8124',
      },
      {
        _id: '60cc25f747f7e10015fd8126',
        courseCode: 'RELSTUD 2129',
        shortName: 'Religious Studies 2129 - NEW TESTAMENT GREEK',
        courseName: 'Religious Studies 2129 - NEW TESTAMENT GREEK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f747f7e10015fd8126',
      },
      {
        _id: '60cc25f847f7e10015fd8128',
        courseCode: 'RELSTUD 2130',
        shortName: 'Religious Studies 2130 - LIVING RELIGIONS',
        courseName: 'Religious Studies 2130 - LIVING RELIGIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f847f7e10015fd8128',
      },
      {
        _id: '60cc25f847f7e10015fd812a',
        courseCode: 'RELSTUD 2131',
        shortName: 'Religious Studies 2131E - LIVING RELIGIONS OF THE WORLD',
        courseName: 'Religious Studies 2131E - LIVING RELIGIONS OF THE WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f847f7e10015fd812a',
      },
      {
        _id: '60cc25f847f7e10015fd812c',
        courseCode: 'RELSTUD 2134',
        shortName: 'Religious Studies 2134A/B - MODERN MORAL DILEMMAS',
        courseName: 'Religious Studies 2134A/B - MODERN MORAL DILEMMAS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f847f7e10015fd812c',
      },
      {
        _id: '60cc25f947f7e10015fd812e',
        courseCode: 'RELSTUD 2137',
        shortName: 'Religious Studies 2137 - CONTEMPORARY THEOLOGY',
        courseName: 'Religious Studies 2137 - CONTEMPORARY THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f947f7e10015fd812e',
      },
      {
        _id: '60cc25f947f7e10015fd8130',
        courseCode: 'RELSTUD 2139',
        shortName:
          'Religious Studies 2139A/B - LOVE, SEX AND RELATIONSHIPS: A THEOLOGY OF MARRIAGE',
        courseName:
          'Religious Studies 2139A/B - LOVE, SEX AND RELATIONSHIPS: A THEOLOGY OF MARRIAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f947f7e10015fd8130',
      },
      {
        _id: '60cc25f947f7e10015fd8132',
        courseCode: 'RELSTUD 2150',
        shortName: 'Religious Studies 2150 - THEOLOGY OF THE FAMILY',
        courseName: 'Religious Studies 2150 - THEOLOGY OF THE FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f947f7e10015fd8132',
      },
      {
        _id: '60cc25f947f7e10015fd8134',
        courseCode: 'RELSTUD 2151',
        shortName: 'Religious Studies 2151A/B - RELIGION AND FAMILIES',
        courseName: 'Religious Studies 2151A/B - RELIGION AND FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25f947f7e10015fd8134',
      },
      {
        _id: '60cc25fa47f7e10015fd8136',
        courseCode: 'RELSTUD 2159',
        shortName: 'Religious Studies 2159 - SPECIAL TOPICS SEMINAR',
        courseName: 'Religious Studies 2159 - SPECIAL TOPICS SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fa47f7e10015fd8136',
      },
      {
        _id: '60cc25fa47f7e10015fd8138',
        courseCode: 'RELSTUD 2161',
        shortName:
          'Religious Studies 2161A/B - JESUS: FROM NAZARETH TO HOLLYWOOD',
        courseName:
          'Religious Studies 2161A/B - JESUS: FROM NAZARETH TO HOLLYWOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fa47f7e10015fd8138',
      },
      {
        _id: '60cc25fa47f7e10015fd813a',
        courseCode: 'RELSTUD 2162',
        shortName:
          'Religious Studies 2162A/B - LOVE, WINE, AND ECSTASY: THE POETRY OF RUMI',
        courseName:
          'Religious Studies 2162A/B - LOVE, WINE, AND ECSTASY: THE POETRY OF RUMI',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fa47f7e10015fd813a',
      },
      {
        _id: '60cc25fa47f7e10015fd813c',
        courseCode: 'RELSTUD 2163',
        shortName:
          'Religious Studies 2163A/B - "AN EYE FOR AN EYE": BIBLICAL FOUNDATIONS OF CRIME AND PUNISHMENT',
        courseName:
          'Religious Studies 2163A/B - "AN EYE FOR AN EYE": BIBLICAL FOUNDATIONS OF CRIME AND PUNISHMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fa47f7e10015fd813c',
      },
      {
        _id: '60cc25fa47f7e10015fd813e',
        courseCode: 'RELSTUD 2164',
        shortName:
          'Religious Studies 2164F/G - HISTORICAL/CRITICAL INTERPRETATION OF THE OLD TESTAMENT: AN INTRODUCTION',
        courseName:
          'Religious Studies 2164F/G - HISTORICAL/CRITICAL INTERPRETATION OF THE OLD TESTAMENT: AN INTRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fa47f7e10015fd813e',
      },
      {
        _id: '60cc25fb47f7e10015fd8140',
        courseCode: 'RELSTUD 2166',
        shortName: 'Religious Studies 2166A/B - VIOLENCE AND WESTERN RELIGIONS',
        courseName:
          'Religious Studies 2166A/B - VIOLENCE AND WESTERN RELIGIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fb47f7e10015fd8140',
      },
      {
        _id: '60cc25fb47f7e10015fd8142',
        courseCode: 'RELSTUD 2168',
        shortName:
          'Religious Studies 2168A/B - ANGELS, DEMONS AND MAGIC: JEWISH FOLKLORE',
        courseName:
          'Religious Studies 2168A/B - ANGELS, DEMONS AND MAGIC: JEWISH FOLKLORE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fb47f7e10015fd8142',
      },
      {
        _id: '60cc25fb47f7e10015fd8144',
        courseCode: 'RELSTUD 2169',
        shortName:
          'Religious Studies 2169A/B - TAMING THE PASSIONS: SEX AND SEXUALITY IN JUDAISM',
        courseName:
          'Religious Studies 2169A/B - TAMING THE PASSIONS: SEX AND SEXUALITY IN JUDAISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fb47f7e10015fd8144',
      },
      {
        _id: '60cc25fb47f7e10015fd8146',
        courseCode: 'RELSTUD 2180',
        shortName:
          'Religious Studies 2180F/G - SPIRITUAL QUESTS AND POPULAR CULTURE',
        courseName:
          'Religious Studies 2180F/G - SPIRITUAL QUESTS AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fb47f7e10015fd8146',
      },
      {
        _id: '60cc25fc47f7e10015fd8148',
        courseCode: 'RELSTUD 2181',
        shortName: 'Religious Studies 2181A/B - SCIENCE AND RELIGION',
        courseName: 'Religious Studies 2181A/B - SCIENCE AND RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fc47f7e10015fd8148',
      },
      {
        _id: '60cc25fc47f7e10015fd814a',
        courseCode: 'RELSTUD 2191',
        shortName: 'Religious Studies 2191F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 2191F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fc47f7e10015fd814a',
      },
      {
        _id: '60cc25fc47f7e10015fd814c',
        courseCode: 'RELSTUD 2192',
        shortName: 'Religious Studies 2192F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 2192F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fc47f7e10015fd814c',
      },
      {
        _id: '60cc25fd47f7e10015fd814e',
        courseCode: 'RELSTUD 2193',
        shortName: 'Religious Studies 2193A/B - SPECIAL TOPICS',
        courseName: 'Religious Studies 2193A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fd47f7e10015fd814e',
      },
      {
        _id: '60cc25fd47f7e10015fd8150',
        courseCode: 'RELSTUD 2200',
        shortName:
          'Religious Studies 2200F/G - APPROACHES TO THE STUDY OF RELIGION',
        courseName:
          'Religious Studies 2200F/G - APPROACHES TO THE STUDY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fd47f7e10015fd8150',
      },
      {
        _id: '60cc25fd47f7e10015fd8152',
        courseCode: 'RELSTUD 2201',
        shortName:
          'Religious Studies 2201F/G - INTRODUCTION TO THE BIBLE: OLD TESTAMENT',
        courseName:
          'Religious Studies 2201F/G - INTRODUCTION TO THE BIBLE: OLD TESTAMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fd47f7e10015fd8152',
      },
      {
        _id: '60cc25fd47f7e10015fd8154',
        courseCode: 'RELSTUD 2202',
        shortName:
          'Religious Studies 2202F/G - INTRODUCTION TO THE BIBLE: NEW TESTAMENT',
        courseName:
          'Religious Studies 2202F/G - INTRODUCTION TO THE BIBLE: NEW TESTAMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fd47f7e10015fd8154',
      },
      {
        _id: '60cc25fe47f7e10015fd8156',
        courseCode: 'RELSTUD 2203',
        shortName:
          'Religious Studies 2203F/G - INTRODUCTION TO EASTERN RELIGIONS',
        courseName:
          'Religious Studies 2203F/G - INTRODUCTION TO EASTERN RELIGIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fe47f7e10015fd8156',
      },
      {
        _id: '60cc25fe47f7e10015fd8158',
        courseCode: 'RELSTUD 2204',
        shortName:
          'Religious Studies 2204F/G - WORLD RELIGIONS: JUDAISM AND ISLAM',
        courseName:
          'Religious Studies 2204F/G - WORLD RELIGIONS: JUDAISM AND ISLAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fe47f7e10015fd8158',
      },
      {
        _id: '60cc25fe47f7e10015fd815a',
        courseCode: 'RELSTUD 2205',
        shortName:
          'Religious Studies 2205F/G - THE EMERGENCE OF THE CHRISTIAN TRADITION',
        courseName:
          'Religious Studies 2205F/G - THE EMERGENCE OF THE CHRISTIAN TRADITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fe47f7e10015fd815a',
      },
      {
        _id: '60cc25fe47f7e10015fd815c',
        courseCode: 'RELSTUD 2207',
        shortName:
          'Religious Studies 2207E - CHRISTIAN ETHICS: ISSUES AND CHALLENGES',
        courseName:
          'Religious Studies 2207E - CHRISTIAN ETHICS: ISSUES AND CHALLENGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25fe47f7e10015fd815c',
      },
      {
        _id: '60cc25ff47f7e10015fd815e',
        courseCode: 'RELSTUD 2208',
        shortName:
          'Religious Studies 2208U - CATHOLIC EDUCATION IN PRACTICE (1) - SERVICE LEARNING PROJECT',
        courseName:
          'Religious Studies 2208U - CATHOLIC EDUCATION IN PRACTICE (1) - SERVICE LEARNING PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ff47f7e10015fd815e',
      },
      {
        _id: '60cc25ff47f7e10015fd8160',
        courseCode: 'RELSTUD 2210',
        shortName: "Religious Studies 2210F/G - IS JESUS GOD? JOHN'S ANSWER",
        courseName: "Religious Studies 2210F/G - IS JESUS GOD? JOHN'S ANSWER",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ff47f7e10015fd8160',
      },
      {
        _id: '60cc25ff47f7e10015fd8162',
        courseCode: 'RELSTUD 2211',
        shortName: 'Religious Studies 2211F/G - PAUL - FOLLOWER OR FOUNDER?',
        courseName: 'Religious Studies 2211F/G - PAUL - FOLLOWER OR FOUNDER?',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ff47f7e10015fd8162',
      },
      {
        _id: '60cc25ff47f7e10015fd8164',
        courseCode: 'RELSTUD 2212',
        shortName: 'Religious Studies 2212F/G - ONE JESUS, MANY GOSPELS',
        courseName: 'Religious Studies 2212F/G - ONE JESUS, MANY GOSPELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc25ff47f7e10015fd8164',
      },
      {
        _id: '60cc260047f7e10015fd8166',
        courseCode: 'RELSTUD 2213',
        shortName: 'Religious Studies 2213F/G - PROPHECY',
        courseName: 'Religious Studies 2213F/G - PROPHECY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260047f7e10015fd8166',
      },
      {
        _id: '60cc260047f7e10015fd8168',
        courseCode: 'RELSTUD 2214',
        shortName: 'Religious Studies 2214F/G - WISDOM LITERATURE',
        courseName: 'Religious Studies 2214F/G - WISDOM LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260047f7e10015fd8168',
      },
      {
        _id: '60cc260047f7e10015fd816a',
        courseCode: 'RELSTUD 2215',
        shortName: 'Religious Studies 2215F/G - SPECIAL TOPICS (NEW TESTAMENT)',
        courseName:
          'Religious Studies 2215F/G - SPECIAL TOPICS (NEW TESTAMENT)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260047f7e10015fd816a',
      },
      {
        _id: '60cc260147f7e10015fd816c',
        courseCode: 'RELSTUD 2216',
        shortName: 'Religious Studies 2216F/G - WOMEN AND THE BIBLE',
        courseName: 'Religious Studies 2216F/G - WOMEN AND THE BIBLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260147f7e10015fd816c',
      },
      {
        _id: '60cc260147f7e10015fd816e',
        courseCode: 'RELSTUD 2218',
        shortName: 'Religious Studies 2218F/G - INTRODUCTION TO CHRISTIANITY',
        courseName: 'Religious Studies 2218F/G - INTRODUCTION TO CHRISTIANITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260147f7e10015fd816e',
      },
      {
        _id: '60cc260147f7e10015fd8170',
        courseCode: 'RELSTUD 2222',
        shortName: 'Religious Studies 2222F/G - FEMINIST SPIRTUALITY TODAY',
        courseName: 'Religious Studies 2222F/G - FEMINIST SPIRTUALITY TODAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260147f7e10015fd8170',
      },
      {
        _id: '60cc260147f7e10015fd8172',
        courseCode: 'RELSTUD 2223',
        shortName: 'Religious Studies 2223E - THEOLOGY OF MARRIAGE',
        courseName: 'Religious Studies 2223E - THEOLOGY OF MARRIAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260147f7e10015fd8172',
      },
      {
        _id: '60cc260247f7e10015fd8174',
        courseCode: 'RELSTUD 2225',
        shortName: 'Religious Studies 2225F/G - RELIGION, SOCIETY AND MORALITY',
        courseName:
          'Religious Studies 2225F/G - RELIGION, SOCIETY AND MORALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260247f7e10015fd8174',
      },
      {
        _id: '60cc260247f7e10015fd8176',
        courseCode: 'RELSTUD 2226',
        shortName:
          'Religious Studies 2226F/G - INTRODUCTION TO THEMES OF THE OLD TESTAMENT',
        courseName:
          'Religious Studies 2226F/G - INTRODUCTION TO THEMES OF THE OLD TESTAMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260247f7e10015fd8176',
      },
      {
        _id: '60cc260247f7e10015fd8178',
        courseCode: 'RELSTUD 2227',
        shortName:
          'Religious Studies 2227F/G - INTRODUCTION TO THE CANONICAL GOSPELS OF THE NEW TESTAMENT',
        courseName:
          'Religious Studies 2227F/G - INTRODUCTION TO THE CANONICAL GOSPELS OF THE NEW TESTAMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260247f7e10015fd8178',
      },
      {
        _id: '60cc260247f7e10015fd817a',
        courseCode: 'RELSTUD 2228',
        shortName: 'Religious Studies 2228F/G - SPIRITUALITY OLD AND NEW',
        courseName: 'Religious Studies 2228F/G - SPIRITUALITY OLD AND NEW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260247f7e10015fd817a',
      },
      {
        _id: '60cc260347f7e10015fd817c',
        courseCode: 'RELSTUD 2229',
        shortName:
          'Religious Studies 2229F/G - MODERNITY, POST MODERNITY AND RELIGION',
        courseName:
          'Religious Studies 2229F/G - MODERNITY, POST MODERNITY AND RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260347f7e10015fd817c',
      },
      {
        _id: '60cc260347f7e10015fd817e',
        courseCode: 'RELSTUD 2233',
        shortName:
          'Religious Studies 2233 - LIFE & DEATH IN RENAISSANCE & REFORMATION EUROPE',
        courseName:
          'Religious Studies 2233 - LIFE & DEATH IN RENAISSANCE & REFORMATION EUROPE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260347f7e10015fd817e',
      },
      {
        _id: '60cc260347f7e10015fd8180',
        courseCode: 'RELSTUD 2234',
        shortName:
          'Religious Studies 2234F/G - CHRISTIAN SOCIAL TEACHING AND PRACTICE',
        courseName:
          'Religious Studies 2234F/G - CHRISTIAN SOCIAL TEACHING AND PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260347f7e10015fd8180',
      },
      {
        _id: '60cc260347f7e10015fd8182',
        courseCode: 'RELSTUD 2236',
        shortName:
          'Religious Studies 2236A/B - INTRODUCTION TO NORTH AMERICAN INDIGENOUS SPIRITUALITY',
        courseName:
          'Religious Studies 2236A/B - INTRODUCTION TO NORTH AMERICAN INDIGENOUS SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260347f7e10015fd8182',
      },
      {
        _id: '60cc260347f7e10015fd8184',
        courseCode: 'RELSTUD 2237',
        shortName:
          'Religious Studies 2237F/G - IN SEARCH OF THE ONE JESUS ACROSS MANY GOSPELS',
        courseName:
          'Religious Studies 2237F/G - IN SEARCH OF THE ONE JESUS ACROSS MANY GOSPELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260347f7e10015fd8184',
      },
      {
        _id: '60cc260447f7e10015fd8186',
        courseCode: 'RELSTUD 2238',
        shortName: 'Religious Studies 2238F/G - FUNDAMENTAL MORAL THEOLOGY',
        courseName: 'Religious Studies 2238F/G - FUNDAMENTAL MORAL THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260447f7e10015fd8186',
      },
      {
        _id: '60cc260447f7e10015fd8188',
        courseCode: 'RELSTUD 2239',
        shortName: 'Religious Studies 2239F/G - CHRISTIAN BIOETHICS',
        courseName: 'Religious Studies 2239F/G - CHRISTIAN BIOETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260447f7e10015fd8188',
      },
      {
        _id: '60cc260447f7e10015fd818a',
        courseCode: 'RELSTUD 2241',
        shortName: 'Religious Studies 2241F/G - FAITH AND CULTURE',
        courseName: 'Religious Studies 2241F/G - FAITH AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260447f7e10015fd818a',
      },
      {
        _id: '60cc260447f7e10015fd818c',
        courseCode: 'RELSTUD 2250',
        shortName:
          'Religious Studies 2250E - HISTORY OF THE ROMAN CATHOLIC CHURCH',
        courseName:
          'Religious Studies 2250E - HISTORY OF THE ROMAN CATHOLIC CHURCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260447f7e10015fd818c',
      },
      {
        _id: '60cc260547f7e10015fd818e',
        courseCode: 'RELSTUD 2251',
        shortName:
          'Religious Studies 2251F/G - WOMEN IN THE CHRISTIAN TRADITION FROM THE FIRST TO THE TWELFTH CENTURY',
        courseName:
          'Religious Studies 2251F/G - WOMEN IN THE CHRISTIAN TRADITION FROM THE FIRST TO THE TWELFTH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260547f7e10015fd818e',
      },
      {
        _id: '60cc260547f7e10015fd8190',
        courseCode: 'RELSTUD 2252',
        shortName:
          'Religious Studies 2252F/G - WOMEN IN THE CHRISTIAN TRADITION FROM THE TWELFTH CENTURY TO THE PRESENT',
        courseName:
          'Religious Studies 2252F/G - WOMEN IN THE CHRISTIAN TRADITION FROM THE TWELFTH CENTURY TO THE PRESENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260547f7e10015fd8190',
      },
      {
        _id: '60cc260547f7e10015fd8192',
        courseCode: 'RELSTUD 2253',
        shortName:
          'Religious Studies 2253F/G - WOMEN IN THE CHRISTIAN TRADITION AND SOCIETY',
        courseName:
          'Religious Studies 2253F/G - WOMEN IN THE CHRISTIAN TRADITION AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260547f7e10015fd8192',
      },
      {
        _id: '60cc260547f7e10015fd8194',
        courseCode: 'RELSTUD 2254',
        shortName:
          'Religious Studies 2254F/G - BIBLICAL WOMEN IN CONTEMPORARY ART AND LITERATURE',
        courseName:
          'Religious Studies 2254F/G - BIBLICAL WOMEN IN CONTEMPORARY ART AND LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260547f7e10015fd8194',
      },
      {
        _id: '60cc260647f7e10015fd8196',
        courseCode: 'RELSTUD 2255',
        shortName: 'Religious Studies 2255F/G - THE BIBLICAL WISDOM LITERATURE',
        courseName:
          'Religious Studies 2255F/G - THE BIBLICAL WISDOM LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260647f7e10015fd8196',
      },
      {
        _id: '60cc260647f7e10015fd8198',
        courseCode: 'RELSTUD 2256',
        shortName:
          'Religious Studies 2256A/B - RELIGIOUS AND SPIRITUAL DIMENSIONS OF FOOD',
        courseName:
          'Religious Studies 2256A/B - RELIGIOUS AND SPIRITUAL DIMENSIONS OF FOOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260647f7e10015fd8198',
      },
      {
        _id: '60cc260647f7e10015fd819a',
        courseCode: 'RELSTUD 2258',
        shortName:
          'Religious Studies 2258F/G - DEITIES AND DIVAS: RELIGION, ETHICS, AND FASHION',
        courseName:
          'Religious Studies 2258F/G - DEITIES AND DIVAS: RELIGION, ETHICS, AND FASHION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260647f7e10015fd819a',
      },
      {
        _id: '60cc260647f7e10015fd819c',
        courseCode: 'RELSTUD 2260',
        shortName: 'Religious Studies 2260F/G - TOPICS IN JUDAISM',
        courseName: 'Religious Studies 2260F/G - TOPICS IN JUDAISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260647f7e10015fd819c',
      },
      {
        _id: '60cc260747f7e10015fd819e',
        courseCode: 'RELSTUD 2261',
        shortName: 'Religious Studies 2261F/G - TOPICS IN HINDUISM',
        courseName: 'Religious Studies 2261F/G - TOPICS IN HINDUISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260747f7e10015fd819e',
      },
      {
        _id: '60cc260747f7e10015fd81a0',
        courseCode: 'RELSTUD 2262',
        shortName: 'Religious Studies 2262F/G - TOPICS IN ISLAM',
        courseName: 'Religious Studies 2262F/G - TOPICS IN ISLAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260747f7e10015fd81a0',
      },
      {
        _id: '60cc260747f7e10015fd81a2',
        courseCode: 'RELSTUD 2263',
        shortName: 'Religious Studies 2263F/G - TOPICS IN BUDDHISM',
        courseName: 'Religious Studies 2263F/G - TOPICS IN BUDDHISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260747f7e10015fd81a2',
      },
      {
        _id: '60cc260747f7e10015fd81a4',
        courseCode: 'RELSTUD 2264',
        shortName:
          'Religious Studies 2264F/G - ISLAMIC THOUGHT AND SPIRITUALITY',
        courseName:
          'Religious Studies 2264F/G - ISLAMIC THOUGHT AND SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260747f7e10015fd81a4',
      },
      {
        _id: '60cc260847f7e10015fd81a6',
        courseCode: 'RELSTUD 2265',
        shortName: 'Religious Studies 2265E - THEOLOGY OF THE FAMILY',
        courseName: 'Religious Studies 2265E - THEOLOGY OF THE FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260847f7e10015fd81a6',
      },
      {
        _id: '60cc260847f7e10015fd81a8',
        courseCode: 'RELSTUD 2266',
        shortName: 'Religious Studies 2266F/G - RELIGION AND FAMILIES',
        courseName: 'Religious Studies 2266F/G - RELIGION AND FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260847f7e10015fd81a8',
      },
      {
        _id: '60cc260847f7e10015fd81aa',
        courseCode: 'RELSTUD 2267',
        shortName: 'Religious Studies 2267F/G - SPIRITUAL BUT NOT RELIGIOUS?',
        courseName: 'Religious Studies 2267F/G - SPIRITUAL BUT NOT RELIGIOUS?',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260847f7e10015fd81aa',
      },
      {
        _id: '60cc260847f7e10015fd81ac',
        courseCode: 'RELSTUD 2268',
        shortName:
          'Religious Studies 2268F/G - INTRODUCTION TO CATHOLIC THOUGHT',
        courseName:
          'Religious Studies 2268F/G - INTRODUCTION TO CATHOLIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260847f7e10015fd81ac',
      },
      {
        _id: '60cc260947f7e10015fd81ae',
        courseCode: 'RELSTUD 2271',
        shortName: 'Religious Studies 2271F/G - WHY THE CHURCH?',
        courseName: 'Religious Studies 2271F/G - WHY THE CHURCH?',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260947f7e10015fd81ae',
      },
      {
        _id: '60cc260947f7e10015fd81b0',
        courseCode: 'RELSTUD 2272',
        shortName: 'Religious Studies 2272F/G - THE CHURCH AT PRAYER',
        courseName: 'Religious Studies 2272F/G - THE CHURCH AT PRAYER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260947f7e10015fd81b0',
      },
      {
        _id: '60cc260947f7e10015fd81b2',
        courseCode: 'RELSTUD 2276',
        shortName: 'Religious Studies 2276F/G - BUDDHIST MEDITATION',
        courseName: 'Religious Studies 2276F/G - BUDDHIST MEDITATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260947f7e10015fd81b2',
      },
      {
        _id: '60cc260947f7e10015fd81b4',
        courseCode: 'RELSTUD 2277',
        shortName: 'Religious Studies 2277F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 2277F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260947f7e10015fd81b4',
      },
      {
        _id: '60cc260947f7e10015fd81b6',
        courseCode: 'RELSTUD 2280',
        shortName: 'Religious Studies 2280F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 2280F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260947f7e10015fd81b6',
      },
      {
        _id: '60cc260a47f7e10015fd81b8',
        courseCode: 'RELSTUD 2281',
        shortName: 'Religious Studies 2281F/G - INTRODUCTION TO BUDDHISM',
        courseName: 'Religious Studies 2281F/G - INTRODUCTION TO BUDDHISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260a47f7e10015fd81b8',
      },
      {
        _id: '60cc260a47f7e10015fd81ba',
        courseCode: 'RELSTUD 2282',
        shortName:
          'Religious Studies 2282F/G - STUDIES IN SPIRITUALITY AND LEADERSHIP',
        courseName:
          'Religious Studies 2282F/G - STUDIES IN SPIRITUALITY AND LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260a47f7e10015fd81ba',
      },
      {
        _id: '60cc260a47f7e10015fd81bc',
        courseCode: 'RELSTUD 2283',
        shortName: 'Religious Studies 2283F/G - INTRODUCTION TO HINDUSIM',
        courseName: 'Religious Studies 2283F/G - INTRODUCTION TO HINDUSIM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260a47f7e10015fd81bc',
      },
      {
        _id: '60cc260a47f7e10015fd81be',
        courseCode: 'RELSTUD 2284',
        shortName:
          'Religious Studies 2284F/G - INTRODUCTION TO THE RELIGIONS OF CHINA AND JAPAN',
        courseName:
          'Religious Studies 2284F/G - INTRODUCTION TO THE RELIGIONS OF CHINA AND JAPAN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260a47f7e10015fd81be',
      },
      {
        _id: '60cc260b47f7e10015fd81c0',
        courseCode: 'RELSTUD 2285',
        shortName: 'Religious Studies 2285F/G - INTRODUCTION TO ISLAM',
        courseName: 'Religious Studies 2285F/G - INTRODUCTION TO ISLAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260b47f7e10015fd81c0',
      },
      {
        _id: '60cc260b47f7e10015fd81c2',
        courseCode: 'RELSTUD 2286',
        shortName: 'Religious Studies 2286F/G - INTRODUCTION TO JUDAISM',
        courseName: 'Religious Studies 2286F/G - INTRODUCTION TO JUDAISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260b47f7e10015fd81c2',
      },
      {
        _id: '60cc260b47f7e10015fd81c4',
        courseCode: 'RELSTUD 2287',
        shortName:
          'Religious Studies 2287F/G - RELIGIOUS PERSPECTIVE ON MARRIAGE AND FAMILY',
        courseName:
          'Religious Studies 2287F/G - RELIGIOUS PERSPECTIVE ON MARRIAGE AND FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260b47f7e10015fd81c4',
      },
      {
        _id: '60cc260b47f7e10015fd81c6',
        courseCode: 'RELSTUD 2288',
        shortName: 'Religious Studies 2288E - SPIRITUALITY AND LEADERSHIP',
        courseName: 'Religious Studies 2288E - SPIRITUALITY AND LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260b47f7e10015fd81c6',
      },
      {
        _id: '60cc260c47f7e10015fd81c8',
        courseCode: 'RELSTUD 2289',
        shortName: 'Religious Studies 2289F/G - YOGA IN THE INDIAN TRADITION',
        courseName: 'Religious Studies 2289F/G - YOGA IN THE INDIAN TRADITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260c47f7e10015fd81c8',
      },
      {
        _id: '60cc260c47f7e10015fd81ca',
        courseCode: 'RELSTUD 2290',
        shortName: 'Religious Studies 2290F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 2290F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260c47f7e10015fd81ca',
      },
      {
        _id: '60cc260c47f7e10015fd81cc',
        courseCode: 'RELSTUD 2291',
        shortName: 'Religious Studies 2291F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 2291F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260c47f7e10015fd81cc',
      },
      {
        _id: '60cc260d47f7e10015fd81ce',
        courseCode: 'RELSTUD 2292',
        shortName:
          'Religious Studies 2292F/G - SPECIAL TOPICS IN CHINESE RELIGIONS',
        courseName:
          'Religious Studies 2292F/G - SPECIAL TOPICS IN CHINESE RELIGIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260d47f7e10015fd81ce',
      },
      {
        _id: '60cc260d47f7e10015fd81d0',
        courseCode: 'RELSTUD 2294',
        shortName: 'Religious Studies 2294F/G - THE MEANING OF JESUS',
        courseName: 'Religious Studies 2294F/G - THE MEANING OF JESUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260d47f7e10015fd81d0',
      },
      {
        _id: '60cc260d47f7e10015fd81d2',
        courseCode: 'RELSTUD 2295',
        shortName:
          'Religious Studies 2295A/B - HOLY GROUND: SACRED SPACE IN PUBLIC PLACES',
        courseName:
          'Religious Studies 2295A/B - HOLY GROUND: SACRED SPACE IN PUBLIC PLACES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260d47f7e10015fd81d2',
      },
      {
        _id: '60cc260d47f7e10015fd81d4',
        courseCode: 'RELSTUD 2297',
        shortName: 'Religious Studies 2297A/B - FAITH IN SOUND, STONE, & PAINT',
        courseName:
          'Religious Studies 2297A/B - FAITH IN SOUND, STONE, & PAINT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260d47f7e10015fd81d4',
      },
      {
        _id: '60cc260d47f7e10015fd81d6',
        courseCode: 'RELSTUD 2298',
        shortName: 'Religious Studies 2298F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 2298F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260d47f7e10015fd81d6',
      },
      {
        _id: '60cc260e47f7e10015fd81d8',
        courseCode: 'RELSTUD 2299',
        shortName: 'Religious Studies 2299A/B - SPECIAL TOPICS',
        courseName: 'Religious Studies 2299A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260e47f7e10015fd81d8',
      },
      {
        _id: '60cc260e47f7e10015fd81da',
        courseCode: 'RELSTUD 2300',
        shortName: 'Religious Studies 2300A/B - SPECIAL TOPICS',
        courseName: 'Religious Studies 2300A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260e47f7e10015fd81da',
      },
      {
        _id: '60cc260e47f7e10015fd81dc',
        courseCode: 'RELSTUD 2301',
        shortName: 'Religious Studies 2301F/G - INTERPRETING ABRAHAM',
        courseName: 'Religious Studies 2301F/G - INTERPRETING ABRAHAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260e47f7e10015fd81dc',
      },
      {
        _id: '60cc260e47f7e10015fd81de',
        courseCode: 'RELSTUD 2310',
        shortName:
          "Religious Studies 2310F/G - INTERPRETING THE QUR'AN (IN TRANSLATION)",
        courseName:
          "Religious Studies 2310F/G - INTERPRETING THE QUR'AN (IN TRANSLATION)",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260e47f7e10015fd81de',
      },
      {
        _id: '60cc260f47f7e10015fd81e0',
        courseCode: 'RELSTUD 2320',
        shortName:
          'Religious Studies 2320F/G - INTERPRETING THE HEBREW BIBLE (TANAKH, IN TRANSLATION)',
        courseName:
          'Religious Studies 2320F/G - INTERPRETING THE HEBREW BIBLE (TANAKH, IN TRANSLATION)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260f47f7e10015fd81e0',
      },
      {
        _id: '60cc260f47f7e10015fd81e2',
        courseCode: 'RELSTUD 2330',
        shortName:
          'Religious Studies 2330F/G - INTERPRETING THE CHRISTIAN BIBLE (IN TRANSLATION)',
        courseName:
          'Religious Studies 2330F/G - INTERPRETING THE CHRISTIAN BIBLE (IN TRANSLATION)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260f47f7e10015fd81e2',
      },
      {
        _id: '60cc260f47f7e10015fd81e4',
        courseCode: 'RELSTUD 2333',
        shortName: 'Religious Studies 2333A/B - ORIGINS OF RELIGIOUS VIOLENCE',
        courseName: 'Religious Studies 2333A/B - ORIGINS OF RELIGIOUS VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260f47f7e10015fd81e4',
      },
      {
        _id: '60cc260f47f7e10015fd81e6',
        courseCode: 'RELSTUD 2345',
        shortName:
          'Religious Studies 2345F/G - BIBLICAL STORYTELLING AND ADAPTATION',
        courseName:
          'Religious Studies 2345F/G - BIBLICAL STORYTELLING AND ADAPTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc260f47f7e10015fd81e6',
      },
      {
        _id: '60cc261047f7e10015fd81e8',
        courseCode: 'RELSTUD 2346',
        shortName: 'Religious Studies 2346F/G - ANCIENT WISDOM',
        courseName: 'Religious Studies 2346F/G - ANCIENT WISDOM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261047f7e10015fd81e8',
      },
      {
        _id: '60cc261047f7e10015fd81ea',
        courseCode: 'RELSTUD 2347',
        shortName:
          'Religious Studies 2347F/G - LIVING THE BIBLE: LAW AND ETHICS',
        courseName:
          'Religious Studies 2347F/G - LIVING THE BIBLE: LAW AND ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261047f7e10015fd81ea',
      },
      {
        _id: '60cc261047f7e10015fd81ec',
        courseCode: 'RELSTUD 2351',
        shortName: 'Religious Studies 2351F/G - INCULTURATION AND SPIRITUALITY',
        courseName:
          'Religious Studies 2351F/G - INCULTURATION AND SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261047f7e10015fd81ec',
      },
      {
        _id: '60cc261047f7e10015fd81ee',
        courseCode: 'RELSTUD 2352',
        shortName:
          'Religious Studies 2352F/G - ROME AND THE CHRISTIAN TRADITION',
        courseName:
          'Religious Studies 2352F/G - ROME AND THE CHRISTIAN TRADITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261047f7e10015fd81ee',
      },
      {
        _id: '60cc261147f7e10015fd81f0',
        courseCode: 'RELSTUD 2420',
        shortName:
          'Religious Studies 2420A/B - GODS, EMPIRES, KINGS, & REBELS: THE HEBREW BIBLE IN CONTEXT',
        courseName:
          'Religious Studies 2420A/B - GODS, EMPIRES, KINGS, & REBELS: THE HEBREW BIBLE IN CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261147f7e10015fd81f0',
      },
      {
        _id: '60cc261147f7e10015fd81f2',
        courseCode: 'RELSTUD 2480',
        shortName: 'Religious Studies 2480F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 2480F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261147f7e10015fd81f2',
      },
      {
        _id: '60cc261147f7e10015fd81f4',
        courseCode: 'RELSTUD 2481',
        shortName: 'Religious Studies 2481F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 2481F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261147f7e10015fd81f4',
      },
      {
        _id: '60cc261147f7e10015fd81f6',
        courseCode: 'RELSTUD 2500',
        shortName: "Religious Studies 2500F/G - INTRODUCTION TO THE QUR'AN",
        courseName: "Religious Studies 2500F/G - INTRODUCTION TO THE QUR'AN",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261147f7e10015fd81f6',
      },
      {
        _id: '60cc261247f7e10015fd81f8',
        courseCode: 'RELSTUD 2501',
        shortName: 'Religious Studies 2501F/G - ISLAM TODAY',
        courseName: 'Religious Studies 2501F/G - ISLAM TODAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261247f7e10015fd81f8',
      },
      {
        _id: '60cc261247f7e10015fd81fa',
        courseCode: 'RELSTUD 2502',
        shortName: 'Religious Studies 2502F/G - GENDER IN ISLAM',
        courseName: 'Religious Studies 2502F/G - GENDER IN ISLAM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261247f7e10015fd81fa',
      },
      {
        _id: '60cc261247f7e10015fd81fc',
        courseCode: 'RELSTUD 2503',
        shortName:
          'Religious Studies 2503F/G - INTRODUCTION TO ISLAMIC MYSTICISM',
        courseName:
          'Religious Studies 2503F/G - INTRODUCTION TO ISLAMIC MYSTICISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261247f7e10015fd81fc',
      },
      {
        _id: '60cc261247f7e10015fd81fe',
        courseCode: 'RELSTUD 2504',
        shortName: 'Religious Studies 2504F/G - ISLAM AT THE CINEMA',
        courseName: 'Religious Studies 2504F/G - ISLAM AT THE CINEMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261247f7e10015fd81fe',
      },
      {
        _id: '60cc261347f7e10015fd8200',
        courseCode: 'RELSTUD 2505',
        shortName:
          "Religious Studies 2505F/G - STORIES AND FIGURES IN THE BIBLE AND QUR'AN",
        courseName:
          "Religious Studies 2505F/G - STORIES AND FIGURES IN THE BIBLE AND QUR'AN",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261347f7e10015fd8200',
      },
      {
        _id: '60cc261347f7e10015fd8202',
        courseCode: 'RELSTUD 2506',
        shortName:
          'Religious Studies 2506F/G - UNDERSTANDING ISLAMIC FUNDAMENTALISM',
        courseName:
          'Religious Studies 2506F/G - UNDERSTANDING ISLAMIC FUNDAMENTALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261347f7e10015fd8202',
      },
      {
        _id: '60cc261347f7e10015fd8204',
        courseCode: 'RELSTUD 2610',
        shortName:
          'Religious Studies 2610F/G - CHRISTIAN THOUGHT, CULTURE AND PRACTICES',
        courseName:
          'Religious Studies 2610F/G - CHRISTIAN THOUGHT, CULTURE AND PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261347f7e10015fd8204',
      },
      {
        _id: '60cc261347f7e10015fd8206',
        courseCode: 'RELSTUD 2620',
        shortName:
          'Religious Studies 2620F/G - ISLAMIC THOUGHT, CULTURE AND PRACTICES',
        courseName:
          'Religious Studies 2620F/G - ISLAMIC THOUGHT, CULTURE AND PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261347f7e10015fd8206',
      },
      {
        _id: '60cc261347f7e10015fd8208',
        courseCode: 'RELSTUD 2630',
        shortName: 'Religious Studies 2630A/B - MYTH IN THE NEAR EAST',
        courseName: 'Religious Studies 2630A/B - MYTH IN THE NEAR EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261347f7e10015fd8208',
      },
      {
        _id: '60cc261447f7e10015fd820a',
        courseCode: 'RELSTUD 2700',
        shortName:
          'Religious Studies 2700F/G - SOCIOLOGY AND ANTHROPOLOGY OF RELIGION',
        courseName:
          'Religious Studies 2700F/G - SOCIOLOGY AND ANTHROPOLOGY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261447f7e10015fd820a',
      },
      {
        _id: '60cc261447f7e10015fd820c',
        courseCode: 'RELSTUD 2705',
        shortName: 'Religious Studies 2705F/G - THE HISTORY OF RELIGION',
        courseName: 'Religious Studies 2705F/G - THE HISTORY OF RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261447f7e10015fd820c',
      },
      {
        _id: '60cc261447f7e10015fd820e',
        courseCode: 'RELSTUD 2710',
        shortName: 'Religious Studies 2710F/G - RELIGION AND REASON',
        courseName: 'Religious Studies 2710F/G - RELIGION AND REASON',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261447f7e10015fd820e',
      },
      {
        _id: '60cc261447f7e10015fd8210',
        courseCode: 'RELSTUD 2715',
        shortName: 'Religious Studies 2715F/G - BODY, RELIGION AND THE SACRED',
        courseName: 'Religious Studies 2715F/G - BODY, RELIGION AND THE SACRED',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261447f7e10015fd8210',
      },
      {
        _id: '60cc261547f7e10015fd8212',
        courseCode: 'RELSTUD 2725',
        shortName: 'Religious Studies 2725F/G - RITUAL STUDIES',
        courseName: 'Religious Studies 2725F/G - RITUAL STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261547f7e10015fd8212',
      },
      {
        _id: '60cc261547f7e10015fd8214',
        courseCode: 'RELSTUD 2730',
        shortName:
          'Religious Studies 2730F/G - SACRED MATTER: RELGION AND MATERIAL CULTURE',
        courseName:
          'Religious Studies 2730F/G - SACRED MATTER: RELGION AND MATERIAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261547f7e10015fd8214',
      },
      {
        _id: '60cc261547f7e10015fd8216',
        courseCode: 'RELSTUD 3020',
        shortName:
          'Religious Studies 3020F/G - THE EARLY HISTORY OF GOD:  ORIGINS OF MONOTHEISM',
        courseName:
          'Religious Studies 3020F/G - THE EARLY HISTORY OF GOD:  ORIGINS OF MONOTHEISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261547f7e10015fd8216',
      },
      {
        _id: '60cc261547f7e10015fd8218',
        courseCode: 'RELSTUD 3030',
        shortName: 'Religious Studies 3030F/G - ANGRY GOD: THE BIBLE AND WAR',
        courseName: 'Religious Studies 3030F/G - ANGRY GOD: THE BIBLE AND WAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261547f7e10015fd8218',
      },
      {
        _id: '60cc261647f7e10015fd821a',
        courseCode: 'RELSTUD 3070',
        shortName: 'Religious Studies 3070F/G - STUDIES IN THE GOSPELS',
        courseName: 'Religious Studies 3070F/G - STUDIES IN THE GOSPELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261647f7e10015fd821a',
      },
      {
        _id: '60cc261647f7e10015fd821c',
        courseCode: 'RELSTUD 3080',
        shortName:
          'Religious Studies 3080F/G - THE DEATH AND RESURRECTION OF JESUS',
        courseName:
          'Religious Studies 3080F/G - THE DEATH AND RESURRECTION OF JESUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261647f7e10015fd821c',
      },
      {
        _id: '60cc261647f7e10015fd821e',
        courseCode: 'RELSTUD 3090',
        shortName: 'Religious Studies 3090F/G - THE MAJOR LETTERS OF PAUL',
        courseName: 'Religious Studies 3090F/G - THE MAJOR LETTERS OF PAUL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261647f7e10015fd821e',
      },
      {
        _id: '60cc261647f7e10015fd8220',
        courseCode: 'RELSTUD 3100',
        shortName:
          "Religious Studies 3100F/G - STUDIES IN THE QUR'AN, SUNNAH AND HADITH",
        courseName:
          "Religious Studies 3100F/G - STUDIES IN THE QUR'AN, SUNNAH AND HADITH",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261647f7e10015fd8220',
      },
      {
        _id: '60cc261647f7e10015fd8222',
        courseCode: 'RELSTUD 3110',
        shortName: 'Religious Studies 3110F/G - CLASSICAL ISLAMIC THOUGHT',
        courseName: 'Religious Studies 3110F/G - CLASSICAL ISLAMIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261647f7e10015fd8222',
      },
      {
        _id: '60cc261747f7e10015fd8224',
        courseCode: 'RELSTUD 3121',
        shortName: 'Religious Studies 3121F/G - INTRODUCTION TO ISLAMIC ETHICS',
        courseName:
          'Religious Studies 3121F/G - INTRODUCTION TO ISLAMIC ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261747f7e10015fd8224',
      },
      {
        _id: '60cc261747f7e10015fd8226',
        courseCode: 'RELSTUD 3130',
        shortName: 'Religious Studies 3130F/G - ISLAM AND POLITICS',
        courseName: 'Religious Studies 3130F/G - ISLAM AND POLITICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261747f7e10015fd8226',
      },
      {
        _id: '60cc261747f7e10015fd8228',
        courseCode: 'RELSTUD 3131',
        shortName:
          'Religious Studies 3131A/B - THE SPIRITUALITY OF MUSLIM WOMEN',
        courseName:
          'Religious Studies 3131A/B - THE SPIRITUALITY OF MUSLIM WOMEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261747f7e10015fd8228',
      },
      {
        _id: '60cc261747f7e10015fd822a',
        courseCode: 'RELSTUD 3134',
        shortName:
          'Religious Studies 3134A/B - NAVIGATING FREEDOM OF RELIGION & ACCOMMODATION IN CANADIAN SOCIETY',
        courseName:
          'Religious Studies 3134A/B - NAVIGATING FREEDOM OF RELIGION & ACCOMMODATION IN CANADIAN SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261747f7e10015fd822a',
      },
      {
        _id: '60cc261847f7e10015fd822c',
        courseCode: 'RELSTUD 3160',
        shortName:
          'Religious Studies 3160F/G - CHRISTIANITY IN EGYPT AND NORTH AFRICA',
        courseName:
          'Religious Studies 3160F/G - CHRISTIANITY IN EGYPT AND NORTH AFRICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261847f7e10015fd822c',
      },
      {
        _id: '60cc261847f7e10015fd822e',
        courseCode: 'RELSTUD 3165',
        shortName:
          'Religious Studies 3165F/G - CHRISTIANITY AND THE MIDDLE EAST',
        courseName:
          'Religious Studies 3165F/G - CHRISTIANITY AND THE MIDDLE EAST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261847f7e10015fd822e',
      },
      {
        _id: '60cc261847f7e10015fd8230',
        courseCode: 'RELSTUD 3168',
        shortName: 'Religious Studies 3168F/G - CONSTRUCTING JESUS',
        courseName: 'Religious Studies 3168F/G - CONSTRUCTING JESUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261847f7e10015fd8230',
      },
      {
        _id: '60cc261847f7e10015fd8232',
        courseCode: 'RELSTUD 3175',
        shortName:
          'Religious Studies 3175F/G - KABBALAH: JEWISH MYSTICISM FROM MERKAVAH TO MADONNA',
        courseName:
          'Religious Studies 3175F/G - KABBALAH: JEWISH MYSTICISM FROM MERKAVAH TO MADONNA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261847f7e10015fd8232',
      },
      {
        _id: '60cc261947f7e10015fd8234',
        courseCode: 'RELSTUD 3180',
        shortName: 'Religious Studies 3180F/G - CENSORED SCRIPTURES',
        courseName: 'Religious Studies 3180F/G - CENSORED SCRIPTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261947f7e10015fd8234',
      },
      {
        _id: '60cc261947f7e10015fd8236',
        courseCode: 'RELSTUD 3230',
        shortName: 'Religious Studies 3230A/B - THE PARABLES OF JESUS',
        courseName: 'Religious Studies 3230A/B - THE PARABLES OF JESUS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261947f7e10015fd8236',
      },
      {
        _id: '60cc261947f7e10015fd8238',
        courseCode: 'RELSTUD 3249',
        shortName:
          'Religious Studies 3249E - CONTEMPORARY QUESTIONS IN CHRISTIAN THEOLOGY',
        courseName:
          'Religious Studies 3249E - CONTEMPORARY QUESTIONS IN CHRISTIAN THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261947f7e10015fd8238',
      },
      {
        _id: '60cc261947f7e10015fd823a',
        courseCode: 'RELSTUD 3257',
        shortName:
          'Religious Studies 3257F/G - CONTEMPORARY ISSUES IN CHRISTIAN ETHICS',
        courseName:
          'Religious Studies 3257F/G - CONTEMPORARY ISSUES IN CHRISTIAN ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261947f7e10015fd823a',
      },
      {
        _id: '60cc261947f7e10015fd823c',
        courseCode: 'RELSTUD 3300',
        shortName: 'Religious Studies 3300F/G - BASIC ISSUES IN RELIGION',
        courseName: 'Religious Studies 3300F/G - BASIC ISSUES IN RELIGION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261947f7e10015fd823c',
      },
      {
        _id: '60cc261a47f7e10015fd823e',
        courseCode: 'RELSTUD 3301',
        shortName: 'Religious Studies 3301F/G - MODERN CATHOLIC THOUGHT',
        courseName: 'Religious Studies 3301F/G - MODERN CATHOLIC THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261a47f7e10015fd823e',
      },
      {
        _id: '60cc261a47f7e10015fd8240',
        courseCode: 'RELSTUD 3308',
        shortName:
          'Religious Studies 3308U - CATHOLIC EDUCATION IN PRACTICE (2) - SERVICE LEARNING PROJECT',
        courseName:
          'Religious Studies 3308U - CATHOLIC EDUCATION IN PRACTICE (2) - SERVICE LEARNING PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261a47f7e10015fd8240',
      },
      {
        _id: '60cc261a47f7e10015fd8242',
        courseCode: 'RELSTUD 3310',
        shortName: 'Religious Studies 3310F/G - SPECIAL TOPICS',
        courseName: 'Religious Studies 3310F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261a47f7e10015fd8242',
      },
      {
        _id: '60cc261a47f7e10015fd8244',
        courseCode: 'RELSTUD 3350',
        shortName:
          'Religious Studies 3350A/B - EASTERN & ORTHODOX CHURCH HISTORY',
        courseName:
          'Religious Studies 3350A/B - EASTERN & ORTHODOX CHURCH HISTORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261a47f7e10015fd8244',
      },
      {
        _id: '60cc261b47f7e10015fd8246',
        courseCode: 'RELSTUD 3360',
        shortName: 'Religious Studies 3360F/G - ISLAM, JUSTICE AND PEACE',
        courseName: 'Religious Studies 3360F/G - ISLAM, JUSTICE AND PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261b47f7e10015fd8246',
      },
      {
        _id: '60cc261b47f7e10015fd8248',
        courseCode: 'RELSTUD 3400',
        shortName:
          'Religious Studies 3400F/G - THEOLOGY AND RELIGIOUS PLURALISM',
        courseName:
          'Religious Studies 3400F/G - THEOLOGY AND RELIGIOUS PLURALISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261b47f7e10015fd8248',
      },
      {
        _id: '60cc261b47f7e10015fd824a',
        courseCode: 'RELSTUD 3410',
        shortName: 'Religious Studies 3410F/G - ANTI-RELIGIOUS THOUGHT',
        courseName: 'Religious Studies 3410F/G - ANTI-RELIGIOUS THOUGHT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261b47f7e10015fd824a',
      },
      {
        _id: '60cc261b47f7e10015fd824c',
        courseCode: 'RELSTUD 3450',
        shortName: 'Religious Studies 3450F/G - JUDAISM AND THE HOLOCAUST',
        courseName: 'Religious Studies 3450F/G - JUDAISM AND THE HOLOCAUST',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261b47f7e10015fd824c',
      },
      {
        _id: '60cc261c47f7e10015fd824e',
        courseCode: 'RELSTUD 3451',
        shortName:
          'Religious Studies 3451F/G - RELIGION AND CONFLICT RESOLUTION',
        courseName:
          'Religious Studies 3451F/G - RELIGION AND CONFLICT RESOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261c47f7e10015fd824e',
      },
      {
        _id: '60cc261c47f7e10015fd8250',
        courseCode: 'RELSTUD 3452',
        shortName:
          'Religious Studies 3452F/G - GLOBALIZATION, JUSTICE AND SPIRITUALITY',
        courseName:
          'Religious Studies 3452F/G - GLOBALIZATION, JUSTICE AND SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261c47f7e10015fd8250',
      },
      {
        _id: '60cc261c47f7e10015fd8252',
        courseCode: 'RELSTUD 3453',
        shortName: 'Religious Studies 3453F/G - SUFFERING, GRIEF & INJUSTICE',
        courseName: 'Religious Studies 3453F/G - SUFFERING, GRIEF & INJUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261c47f7e10015fd8252',
      },
      {
        _id: '60cc261c47f7e10015fd8254',
        courseCode: 'RELSTUD 3455',
        shortName:
          'Religious Studies 3455F/G - RELIGION, ETHICS AND THE ENVIRONMENT',
        courseName:
          'Religious Studies 3455F/G - RELIGION, ETHICS AND THE ENVIRONMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261c47f7e10015fd8254',
      },
      {
        _id: '60cc261c47f7e10015fd8256',
        courseCode: 'RELSTUD 3457',
        shortName: 'Religious Studies 3457F/G - RELIGION AND BIOMEDICAL ETHICS',
        courseName:
          'Religious Studies 3457F/G - RELIGION AND BIOMEDICAL ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261c47f7e10015fd8256',
      },
      {
        _id: '60cc261d47f7e10015fd8258',
        courseCode: 'RELSTUD 3460',
        shortName:
          'Religious Studies 3460A/B - RELIGION, ETHICS AND GOVERNANCE',
        courseName:
          'Religious Studies 3460A/B - RELIGION, ETHICS AND GOVERNANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261d47f7e10015fd8258',
      },
      {
        _id: '60cc261d47f7e10015fd825a',
        courseCode: 'RELSTUD 3463',
        shortName:
          'Religious Studies 3463F/G - RELIGION, NATURAL LAW AND HUMAN RIGHTS',
        courseName:
          'Religious Studies 3463F/G - RELIGION, NATURAL LAW AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261d47f7e10015fd825a',
      },
      {
        _id: '60cc261d47f7e10015fd825c',
        courseCode: 'RELSTUD 3465',
        shortName:
          'Religious Studies 3465F/G - APOLOGIES, CONFESSIONS, AND FORGIVENESS',
        courseName:
          'Religious Studies 3465F/G - APOLOGIES, CONFESSIONS, AND FORGIVENESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261d47f7e10015fd825c',
      },
      {
        _id: '60cc261d47f7e10015fd825e',
        courseCode: 'RELSTUD 3480',
        shortName: 'Religious Studies 3480F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 3480F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261d47f7e10015fd825e',
      },
      {
        _id: '60cc261e47f7e10015fd8260',
        courseCode: 'RELSTUD 3481',
        shortName: 'Religious Studies 3481F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 3481F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261e47f7e10015fd8260',
      },
      {
        _id: '60cc261e47f7e10015fd8262',
        courseCode: 'RELSTUD 3500',
        shortName:
          'Religious Studies 3500F/G - RELIGION IN ACTION: PROJECTS IN EXPERIENTIAL LEARNING',
        courseName:
          'Religious Studies 3500F/G - RELIGION IN ACTION: PROJECTS IN EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261e47f7e10015fd8262',
      },
      {
        _id: '60cc261e47f7e10015fd8264',
        courseCode: 'RELSTUD 4040',
        shortName: 'Religious Studies 4040F/G - PROPHETIC LITERATURE',
        courseName: 'Religious Studies 4040F/G - PROPHETIC LITERATURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261e47f7e10015fd8264',
      },
      {
        _id: '60cc261e47f7e10015fd8266',
        courseCode: 'RELSTUD 4220',
        shortName: 'Religious Studies 4220E - INDEPENDENT STUDY',
        courseName: 'Religious Studies 4220E - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261e47f7e10015fd8266',
      },
      {
        _id: '60cc261e47f7e10015fd8268',
        courseCode: 'RELSTUD 4400',
        shortName:
          'Religious Studies 4400F/G - MAKING SENSE OF RELIGIOUS DIVERSITY',
        courseName:
          'Religious Studies 4400F/G - MAKING SENSE OF RELIGIOUS DIVERSITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261e47f7e10015fd8268',
      },
      {
        _id: '60cc261f47f7e10015fd826a',
        courseCode: 'RELSTUD 4408',
        shortName: 'Religious Studies 4408Y - CATHOLIC EDUCATION IN PRACTICE',
        courseName: 'Religious Studies 4408Y - CATHOLIC EDUCATION IN PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261f47f7e10015fd826a',
      },
      {
        _id: '60cc261f47f7e10015fd826c',
        courseCode: 'RELSTUD 4410',
        shortName: 'Religious Studies 4410F/G - SEMINAR IN SACRED SCRIPTURE',
        courseName: 'Religious Studies 4410F/G - SEMINAR IN SACRED SCRIPTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261f47f7e10015fd826c',
      },
      {
        _id: '60cc261f47f7e10015fd826e',
        courseCode: 'RELSTUD 4415',
        shortName: 'Religious Studies 4415F/G - BIBLICAL INTERPRETATION TODAY',
        courseName: 'Religious Studies 4415F/G - BIBLICAL INTERPRETATION TODAY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261f47f7e10015fd826e',
      },
      {
        _id: '60cc261f47f7e10015fd8270',
        courseCode: 'RELSTUD 4420',
        shortName:
          'Religious Studies 4420F/G - SEMINAR IN RELIGION AND CULTURE',
        courseName:
          'Religious Studies 4420F/G - SEMINAR IN RELIGION AND CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc261f47f7e10015fd8270',
      },
      {
        _id: '60cc262047f7e10015fd8272',
        courseCode: 'RELSTUD 4430',
        shortName: 'Religious Studies 4430F/G - SEMINAR IN RELIGIOUS ETHICS',
        courseName: 'Religious Studies 4430F/G - SEMINAR IN RELIGIOUS ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262047f7e10015fd8272',
      },
      {
        _id: '60cc262047f7e10015fd8274',
        courseCode: 'RELSTUD 4440',
        shortName: 'Religious Studies 4440F/G - SEMINAR IN SYSTEMATIC THEOLOGY',
        courseName:
          'Religious Studies 4440F/G - SEMINAR IN SYSTEMATIC THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262047f7e10015fd8274',
      },
      {
        _id: '60cc262047f7e10015fd8276',
        courseCode: 'RELSTUD 4460',
        shortName: 'Religious Studies 4460F/G - SEMINAR IN WORLD RELIGIONS',
        courseName: 'Religious Studies 4460F/G - SEMINAR IN WORLD RELIGIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262047f7e10015fd8276',
      },
      {
        _id: '60cc262047f7e10015fd8278',
        courseCode: 'RELSTUD 4480',
        shortName: 'Religious Studies 4480F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 4480F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262047f7e10015fd8278',
      },
      {
        _id: '60cc262147f7e10015fd827a',
        courseCode: 'RELSTUD 4481',
        shortName: 'Religious Studies 4481F/G - INDEPENDENT STUDY',
        courseName: 'Religious Studies 4481F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262147f7e10015fd827a',
      },
      {
        _id: '60cc262147f7e10015fd827c',
        courseCode: 'RUSSIAN 1030',
        shortName: 'Russian 1030 - RUSSIAN FOR BEGINNERS',
        courseName: 'Russian 1030 - RUSSIAN FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262147f7e10015fd827c',
      },
      {
        _id: '60cc262147f7e10015fd827e',
        courseCode: 'SCHOLARS 1100',
        shortName:
          "Scholars Electives 1100F/G - FIRST YEAR  YEAR SCHOLAR'S ELECTIVES SEMINAR COURSE",
        courseName:
          "Scholars Electives 1100F/G - FIRST YEAR  YEAR SCHOLAR'S ELECTIVES SEMINAR COURSE",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262147f7e10015fd827e',
      },
      {
        _id: '60cc262147f7e10015fd8280',
        courseCode: 'SCHOLARS 2200',
        shortName:
          'Scholars Electives 2200E - SUPERVISED INDIVIDUAL RESEARCH 1',
        courseName:
          'Scholars Electives 2200E - SUPERVISED INDIVIDUAL RESEARCH 1',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262147f7e10015fd8280',
      },
      {
        _id: '60cc262247f7e10015fd8282',
        courseCode: 'SCHOLARS 2275',
        shortName:
          'Scholars Electives 2275F/G - EXPERIENTIAL LEARNING FOR LEADERSHIP',
        courseName:
          'Scholars Electives 2275F/G - EXPERIENTIAL LEARNING FOR LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262247f7e10015fd8282',
      },
      {
        _id: '60cc262247f7e10015fd8284',
        courseCode: 'SCHOLARS 3300',
        shortName: 'Scholars Electives 3300Y - DIRECTED READINGS',
        courseName: 'Scholars Electives 3300Y - DIRECTED READINGS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262247f7e10015fd8284',
      },
      {
        _id: '60cc262247f7e10015fd8286',
        courseCode: 'SCHOLARS 3302',
        shortName:
          'Scholars Electives 3302E - INTELLECTUAL ORIGINS AND CONCEPTS: CONTEMPORARY ISSUES',
        courseName:
          'Scholars Electives 3302E - INTELLECTUAL ORIGINS AND CONCEPTS: CONTEMPORARY ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262247f7e10015fd8286',
      },
      {
        _id: '60cc262247f7e10015fd8288',
        courseCode: 'SCHOLARS 3305',
        shortName:
          'Scholars Electives 3305E - SUPERVISED INDIVIDUAL RESEARCH 2',
        courseName:
          'Scholars Electives 3305E - SUPERVISED INDIVIDUAL RESEARCH 2',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262247f7e10015fd8288',
      },
      {
        _id: '60cc262347f7e10015fd828a',
        courseCode: 'SCHOLARS 3375',
        shortName: 'Scholars Electives 3375Y - SOPHIA AS WISDOM',
        courseName: 'Scholars Electives 3375Y - SOPHIA AS WISDOM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262347f7e10015fd828a',
      },
      {
        _id: '60cc262347f7e10015fd828c',
        courseCode: 'SCHOLARS 4400',
        shortName:
          'Scholars Electives 4400Y - CIVIC ENGAGEMENT, PROFESSIONAL DEVELOPMENT AND COMMUNICATION',
        courseName:
          'Scholars Electives 4400Y - CIVIC ENGAGEMENT, PROFESSIONAL DEVELOPMENT AND COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262347f7e10015fd828c',
      },
      {
        _id: '60cc262347f7e10015fd828e',
        courseCode: 'SCHOLARS 4401',
        shortName:
          'Scholars Electives 4401Y - FOURTH YEAR SENIOR RESEARCH PROJECT',
        courseName:
          'Scholars Electives 4401Y - FOURTH YEAR SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262347f7e10015fd828e',
      },
      {
        _id: '60cc262347f7e10015fd8290',
        courseCode: 'SCIENCE 3377',
        shortName: 'Science 3377A/B - PROJECT MANAGEMENT FOR THE SCIENCES',
        courseName: 'Science 3377A/B - PROJECT MANAGEMENT FOR THE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262347f7e10015fd8290',
      },
      {
        _id: '60cc262447f7e10015fd8292',
        courseCode: 'SCIENCE 3391',
        shortName:
          'Science 3391 - INTERNSHIP: PLANNING, PRACTICUM AND PROSPECTS',
        courseName:
          'Science 3391 - INTERNSHIP: PLANNING, PRACTICUM AND PROSPECTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262447f7e10015fd8292',
      },
      {
        _id: '60cc262447f7e10015fd8294',
        courseCode: 'SCIENCE 3393',
        shortName: 'Science 3393 - INTERNSHIP WORK TERM',
        courseName: 'Science 3393 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262447f7e10015fd8294',
      },
      {
        _id: '60cc262447f7e10015fd8296',
        courseCode: 'SCIENCE 3394',
        shortName: 'Science 3394 - INTERNSHIP WORK TERM',
        courseName: 'Science 3394 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262447f7e10015fd8296',
      },
      {
        _id: '60cc262447f7e10015fd8298',
        courseCode: 'SCIENCE 3395',
        shortName: 'Science 3395 - INTERNSHIP WORK TERM',
        courseName: 'Science 3395 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262447f7e10015fd8298',
      },
      {
        _id: '60cc262547f7e10015fd829a',
        courseCode: 'SCIENCE 3396',
        shortName: 'Science 3396 - INTERNSHIP WORK TERM',
        courseName: 'Science 3396 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262547f7e10015fd829a',
      },
      {
        _id: '60cc262547f7e10015fd829c',
        courseCode: 'SCIENCE 3397',
        shortName: 'Science 3397 - INTERNSHIP WORK TERM',
        courseName: 'Science 3397 - INTERNSHIP WORK TERM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262547f7e10015fd829c',
      },
      {
        _id: '60cc262547f7e10015fd829e',
        courseCode: 'SCIENCE 3450',
        shortName: 'Science 3450F/G - UNIVERSITY SCIENCE EDUCATION',
        courseName: 'Science 3450F/G - UNIVERSITY SCIENCE EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262547f7e10015fd829e',
      },
      {
        _id: '60cc262647f7e10015fd82a0',
        courseCode: 'SCIENCE 3999',
        shortName: 'Science 3999A/B/Y - EXPERIENTIAL LEARNING',
        courseName: 'Science 3999A/B/Y - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262647f7e10015fd82a0',
      },
      {
        _id: '60cc262647f7e10015fd82a2',
        courseCode: 'SOCLJUST 1025',
        shortName:
          'Social Justice and Peace Studies 1025F/G - INTRODUCTION TO SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 1025F/G - INTRODUCTION TO SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262647f7e10015fd82a2',
      },
      {
        _id: '60cc262647f7e10015fd82a4',
        courseCode: 'SOCLJUST 1026',
        shortName:
          'Social Justice and Peace Studies 1026F/G - APPROACHES TO SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 1026F/G - APPROACHES TO SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262647f7e10015fd82a4',
      },
      {
        _id: '60cc262647f7e10015fd82a6',
        courseCode: 'SOCLJUST 2190',
        shortName:
          'Social Justice and Peace Studies 2190 - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2190 - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262647f7e10015fd82a6',
      },
      {
        _id: '60cc262747f7e10015fd82a8',
        courseCode: 'SOCLJUST 2195',
        shortName:
          'Social Justice and Peace Studies 2195A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2195A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262747f7e10015fd82a8',
      },
      {
        _id: '60cc262747f7e10015fd82aa',
        courseCode: 'SOCLJUST 2196',
        shortName:
          'Social Justice and Peace Studies 2196A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2196A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262747f7e10015fd82aa',
      },
      {
        _id: '60cc262747f7e10015fd82ac',
        courseCode: 'SOCLJUST 2197',
        shortName:
          'Social Justice and Peace Studies 2197A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2197A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262747f7e10015fd82ac',
      },
      {
        _id: '60cc262747f7e10015fd82ae',
        courseCode: 'SOCLJUST 2198',
        shortName:
          'Social Justice and Peace Studies 2198A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2198A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262747f7e10015fd82ae',
      },
      {
        _id: '60cc262847f7e10015fd82b0',
        courseCode: 'SOCLJUST 2199',
        shortName:
          'Social Justice and Peace Studies 2199A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2199A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262847f7e10015fd82b0',
      },
      {
        _id: '60cc262847f7e10015fd82b2',
        courseCode: 'SOCLJUST 2230',
        shortName:
          'Social Justice and Peace Studies 2230F/G - ECOLOGY, SPIRITUALITY AND JUSTICE',
        courseName:
          'Social Justice and Peace Studies 2230F/G - ECOLOGY, SPIRITUALITY AND JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262847f7e10015fd82b2',
      },
      {
        _id: '60cc262847f7e10015fd82b4',
        courseCode: 'SOCLJUST 2270',
        shortName:
          'Social Justice and Peace Studies 2270A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2270A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262847f7e10015fd82b4',
      },
      {
        _id: '60cc262847f7e10015fd82b6',
        courseCode: 'SOCLJUST 2271',
        shortName:
          'Social Justice and Peace Studies 2271A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2271A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262847f7e10015fd82b6',
      },
      {
        _id: '60cc262947f7e10015fd82b8',
        courseCode: 'SOCLJUST 2272',
        shortName:
          'Social Justice and Peace Studies 2272A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2272A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262947f7e10015fd82b8',
      },
      {
        _id: '60cc262947f7e10015fd82ba',
        courseCode: 'SOCLJUST 2273',
        shortName:
          'Social Justice and Peace Studies 2273A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2273A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262947f7e10015fd82ba',
      },
      {
        _id: '60cc262947f7e10015fd82bc',
        courseCode: 'SOCLJUST 2274',
        shortName:
          'Social Justice and Peace Studies 2274A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2274A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262947f7e10015fd82bc',
      },
      {
        _id: '60cc262a47f7e10015fd82be',
        courseCode: 'SOCLJUST 2275',
        shortName:
          'Social Justice and Peace Studies 2275A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2275A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262a47f7e10015fd82be',
      },
      {
        _id: '60cc262a47f7e10015fd82c0',
        courseCode: 'SOCLJUST 2276',
        shortName:
          'Social Justice and Peace Studies 2276A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2276A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262a47f7e10015fd82c0',
      },
      {
        _id: '60cc262a47f7e10015fd82c2',
        courseCode: 'SOCLJUST 2277',
        shortName:
          'Social Justice and Peace Studies 2277A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2277A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262a47f7e10015fd82c2',
      },
      {
        _id: '60cc262a47f7e10015fd82c4',
        courseCode: 'SOCLJUST 2278',
        shortName:
          'Social Justice and Peace Studies 2278A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 2278A/B - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262a47f7e10015fd82c4',
      },
      {
        _id: '60cc262b47f7e10015fd82c6',
        courseCode: 'SOCLJUST 2290',
        shortName:
          'Social Justice and Peace Studies 2290A/B - CONTEMPORARY FIRST NATIONS ISSUES',
        courseName:
          'Social Justice and Peace Studies 2290A/B - CONTEMPORARY FIRST NATIONS ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262b47f7e10015fd82c6',
      },
      {
        _id: '60cc262b47f7e10015fd82c8',
        courseCode: 'SOCLJUST 2301',
        shortName:
          'Social Justice and Peace Studies 2301A/B - SOCIAL ACTIVISM AND THEORIES OF POLITICAL CHANGE',
        courseName:
          'Social Justice and Peace Studies 2301A/B - SOCIAL ACTIVISM AND THEORIES OF POLITICAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262b47f7e10015fd82c8',
      },
      {
        _id: '60cc262b47f7e10015fd82ca',
        courseCode: 'SOCLJUST 2302',
        shortName:
          'Social Justice and Peace Studies 2302A/B - BUILDING PEACE IN A WORLD OF CONFLICT',
        courseName:
          'Social Justice and Peace Studies 2302A/B - BUILDING PEACE IN A WORLD OF CONFLICT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262b47f7e10015fd82ca',
      },
      {
        _id: '60cc262c47f7e10015fd82cc',
        courseCode: 'SOCLJUST 2303',
        shortName:
          'Social Justice and Peace Studies 2303A/B - HUMANITARIANISM AND GLOBAL JUSTICE',
        courseName:
          'Social Justice and Peace Studies 2303A/B - HUMANITARIANISM AND GLOBAL JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262c47f7e10015fd82cc',
      },
      {
        _id: '60cc262c47f7e10015fd82ce',
        courseCode: 'SOCLJUST 2304',
        shortName:
          'Social Justice and Peace Studies 2304F/G - SOCIAL NETWORK OF POWER AND PRIVILEGE',
        courseName:
          'Social Justice and Peace Studies 2304F/G - SOCIAL NETWORK OF POWER AND PRIVILEGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262c47f7e10015fd82ce',
      },
      {
        _id: '60cc262c47f7e10015fd82d0',
        courseCode: 'SOCLJUST 3210',
        shortName:
          'Social Justice and Peace Studies 3210F/G - GLOBALIZATION AND CITIZENSHIP',
        courseName:
          'Social Justice and Peace Studies 3210F/G - GLOBALIZATION AND CITIZENSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262c47f7e10015fd82d0',
      },
      {
        _id: '60cc262c47f7e10015fd82d2',
        courseCode: 'SOCLJUST 3211',
        shortName:
          'Social Justice and Peace Studies 3211F/G - GLOBAL NETWORKS FOR JUSTICE',
        courseName:
          'Social Justice and Peace Studies 3211F/G - GLOBAL NETWORKS FOR JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262c47f7e10015fd82d2',
      },
      {
        _id: '60cc262d47f7e10015fd82d4',
        courseCode: 'SOCLJUST 3320',
        shortName:
          'Social Justice and Peace Studies 3320E - CITY STUDIO: WOMEN IN CIVIC LEADERSHIP',
        courseName:
          'Social Justice and Peace Studies 3320E - CITY STUDIO: WOMEN IN CIVIC LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262d47f7e10015fd82d4',
      },
      {
        _id: '60cc262d47f7e10015fd82d6',
        courseCode: 'SOCLJUST 3360',
        shortName:
          'Social Justice and Peace Studies 3360F/G - ISLAM, JUSTICE AND PEACE',
        courseName:
          'Social Justice and Peace Studies 3360F/G - ISLAM, JUSTICE AND PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262d47f7e10015fd82d6',
      },
      {
        _id: '60cc262d47f7e10015fd82d8',
        courseCode: 'SOCLJUST 3361',
        shortName:
          'Social Justice and Peace Studies 3361F/G - ETHICS OF WAR AND PEACE',
        courseName:
          'Social Justice and Peace Studies 3361F/G - ETHICS OF WAR AND PEACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262d47f7e10015fd82d8',
      },
      {
        _id: '60cc262d47f7e10015fd82da',
        courseCode: 'SOCLJUST 3362',
        shortName: 'Social Justice and Peace Studies 3362F/G - ECOFEMINISM',
        courseName: 'Social Justice and Peace Studies 3362F/G - ECOFEMINISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262d47f7e10015fd82da',
      },
      {
        _id: '60cc262e47f7e10015fd82dc',
        courseCode: 'SOCLJUST 3365',
        shortName:
          'Social Justice and Peace Studies 3365F/G - ALTERNATIVE DISPUTE RESOLUTION',
        courseName:
          'Social Justice and Peace Studies 3365F/G - ALTERNATIVE DISPUTE RESOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262e47f7e10015fd82dc',
      },
      {
        _id: '60cc262e47f7e10015fd82de',
        courseCode: 'SOCLJUST 3367',
        shortName:
          'Social Justice and Peace Studies 3367F/G - EXILE AND FORCED MIGRATION',
        courseName:
          'Social Justice and Peace Studies 3367F/G - EXILE AND FORCED MIGRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262e47f7e10015fd82de',
      },
      {
        _id: '60cc262e47f7e10015fd82e0',
        courseCode: 'SOCLJUST 3370',
        shortName:
          'Social Justice and Peace Studies 3370F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3370F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262e47f7e10015fd82e0',
      },
      {
        _id: '60cc262f47f7e10015fd82e2',
        courseCode: 'SOCLJUST 3371',
        shortName:
          'Social Justice and Peace Studies 3371F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3371F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262f47f7e10015fd82e2',
      },
      {
        _id: '60cc262f47f7e10015fd82e4',
        courseCode: 'SOCLJUST 3372',
        shortName:
          'Social Justice and Peace Studies 3372F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3372F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262f47f7e10015fd82e4',
      },
      {
        _id: '60cc262f47f7e10015fd82e6',
        courseCode: 'SOCLJUST 3373',
        shortName:
          'Social Justice and Peace Studies 3373F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3373F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262f47f7e10015fd82e6',
      },
      {
        _id: '60cc262f47f7e10015fd82e8',
        courseCode: 'SOCLJUST 3374',
        shortName:
          'Social Justice and Peace Studies 3374F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3374F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc262f47f7e10015fd82e8',
      },
      {
        _id: '60cc263047f7e10015fd82ea',
        courseCode: 'SOCLJUST 3375',
        shortName:
          'Social Justice and Peace Studies 3375F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3375F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263047f7e10015fd82ea',
      },
      {
        _id: '60cc263047f7e10015fd82ec',
        courseCode: 'SOCLJUST 3376',
        shortName:
          'Social Justice and Peace Studies 3376F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3376F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263047f7e10015fd82ec',
      },
      {
        _id: '60cc263047f7e10015fd82ee',
        courseCode: 'SOCLJUST 3377',
        shortName:
          'Social Justice and Peace Studies 3377F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3377F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263047f7e10015fd82ee',
      },
      {
        _id: '60cc263047f7e10015fd82f0',
        courseCode: 'SOCLJUST 3378',
        shortName:
          'Social Justice and Peace Studies 3378F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        courseName:
          'Social Justice and Peace Studies 3378F/G - SPECIAL TOPICS IN SOCIAL JUSTICE AND PEACE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263047f7e10015fd82f0',
      },
      {
        _id: '60cc263147f7e10015fd82f2',
        courseCode: 'SOCLJUST 3380',
        shortName:
          'Social Justice and Peace Studies 3380F/G - JUSTICE AND ALTERNATIVE ECONOMIC SYSTEMS',
        courseName:
          'Social Justice and Peace Studies 3380F/G - JUSTICE AND ALTERNATIVE ECONOMIC SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263147f7e10015fd82f2',
      },
      {
        _id: '60cc263147f7e10015fd82f4',
        courseCode: 'SOCLJUST 3381',
        shortName:
          'Social Justice and Peace Studies 3381F/G - DEMOCRACY AND THE ECONOMY',
        courseName:
          'Social Justice and Peace Studies 3381F/G - DEMOCRACY AND THE ECONOMY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263147f7e10015fd82f4',
      },
      {
        _id: '60cc263147f7e10015fd82f6',
        courseCode: 'SOCLJUST 3382',
        shortName:
          'Social Justice and Peace Studies 3382F/G - LIVING A JUST LIFE IN AN UNJUST WORLD',
        courseName:
          'Social Justice and Peace Studies 3382F/G - LIVING A JUST LIFE IN AN UNJUST WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263147f7e10015fd82f6',
      },
      {
        _id: '60cc263147f7e10015fd82f8',
        courseCode: 'SOCLJUST 3383',
        shortName:
          'Social Justice and Peace Studies 3383F/G - ECOLOGICAL JUSTICE',
        courseName:
          'Social Justice and Peace Studies 3383F/G - ECOLOGICAL JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263147f7e10015fd82f8',
      },
      {
        _id: '60cc263247f7e10015fd82fa',
        courseCode: 'SOCLJUST 3385',
        shortName:
          'Social Justice and Peace Studies 3385F/G - ADVANCED ALTERNATIVE DISPUTE RESOLUTION',
        courseName:
          'Social Justice and Peace Studies 3385F/G - ADVANCED ALTERNATIVE DISPUTE RESOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263247f7e10015fd82fa',
      },
      {
        _id: '60cc263247f7e10015fd82fc',
        courseCode: 'SOCLJUST 3396',
        shortName:
          'Social Justice and Peace Studies 3396F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 3396F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263247f7e10015fd82fc',
      },
      {
        _id: '60cc263247f7e10015fd82fe',
        courseCode: 'SOCLJUST 3397',
        shortName:
          'Social Justice and Peace Studies 3397F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 3397F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263247f7e10015fd82fe',
      },
      {
        _id: '60cc263247f7e10015fd8300',
        courseCode: 'SOCLJUST 3398',
        shortName:
          'Social Justice and Peace Studies 3398F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 3398F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263247f7e10015fd8300',
      },
      {
        _id: '60cc263347f7e10015fd8302',
        courseCode: 'SOCLJUST 3399',
        shortName:
          'Social Justice and Peace Studies 3399F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 3399F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263347f7e10015fd8302',
      },
      {
        _id: '60cc263347f7e10015fd8304',
        courseCode: 'SOCLJUST 3451',
        shortName:
          'Social Justice and Peace Studies 3451F/G - RELIGION AND CONFLICT RESOLUTION',
        courseName:
          'Social Justice and Peace Studies 3451F/G - RELIGION AND CONFLICT RESOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263347f7e10015fd8304',
      },
      {
        _id: '60cc263347f7e10015fd8306',
        courseCode: 'SOCLJUST 3452',
        shortName:
          'Social Justice and Peace Studies 3452F/G - GLOBALIZATION, JUSTICE AND SPIRITUALITY',
        courseName:
          'Social Justice and Peace Studies 3452F/G - GLOBALIZATION, JUSTICE AND SPIRITUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263347f7e10015fd8306',
      },
      {
        _id: '60cc263447f7e10015fd8308',
        courseCode: 'SOCLJUST 3500',
        shortName:
          'Social Justice and Peace Studies 3500E - COMMUNITY-BASED LEARNING',
        courseName:
          'Social Justice and Peace Studies 3500E - COMMUNITY-BASED LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263447f7e10015fd8308',
      },
      {
        _id: '60cc263447f7e10015fd830a',
        courseCode: 'SOCLJUST 4404',
        shortName:
          'Social Justice and Peace Studies 4404F/G - RESEARCH SYMPOSIUM',
        courseName:
          'Social Justice and Peace Studies 4404F/G - RESEARCH SYMPOSIUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263447f7e10015fd830a',
      },
      {
        _id: '60cc263447f7e10015fd830c',
        courseCode: 'SOCLJUST 4496',
        shortName:
          'Social Justice and Peace Studies 4496F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 4496F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263447f7e10015fd830c',
      },
      {
        _id: '60cc263447f7e10015fd830e',
        courseCode: 'SOCLJUST 4497',
        shortName:
          'Social Justice and Peace Studies 4497F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 4497F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263447f7e10015fd830e',
      },
      {
        _id: '60cc263547f7e10015fd8310',
        courseCode: 'SOCLJUST 4498',
        shortName:
          'Social Justice and Peace Studies 4498F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 4498F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263547f7e10015fd8310',
      },
      {
        _id: '60cc263547f7e10015fd8312',
        courseCode: 'SOCLJUST 4499',
        shortName:
          'Social Justice and Peace Studies 4499F/G - INDEPENDENT STUDY',
        courseName:
          'Social Justice and Peace Studies 4499F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263547f7e10015fd8312',
      },
      {
        _id: '60cc263547f7e10015fd8314',
        courseCode: 'SOCSCI 3895',
        shortName: 'Social Science 3895E - SOCIAL SCIENCE IN THE COMMUNITY',
        courseName: 'Social Science 3895E - SOCIAL SCIENCE IN THE COMMUNITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263547f7e10015fd8314',
      },
      {
        _id: '60cc263547f7e10015fd8316',
        courseCode: 'SOCSCI 3990',
        shortName: 'Social Science 3990A/B/Y - INTERNSHIP',
        courseName: 'Social Science 3990A/B/Y - INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263547f7e10015fd8316',
      },
      {
        _id: '60cc263647f7e10015fd8318',
        courseCode: 'SOCSCI 3999',
        shortName: 'Social Science 3999A/B/Y - EXPERIENTIAL LEARNING',
        courseName: 'Social Science 3999A/B/Y - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263647f7e10015fd8318',
      },
      {
        _id: '60cc263647f7e10015fd831a',
        courseCode: 'SOCWORK 1025',
        shortName:
          'Social Work 1025A/B - INTRODUCTION TO SOCIAL WORK AND SOCIAL WELFARE',
        courseName:
          'Social Work 1025A/B - INTRODUCTION TO SOCIAL WORK AND SOCIAL WELFARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263647f7e10015fd831a',
      },
      {
        _id: '60cc263647f7e10015fd831c',
        courseCode: 'SOCWORK 1026',
        shortName:
          'Social Work 1026A/B - INTRODUCTION TO SOCIAL WORK PRACTICE AND COMMUNICATION SKILLS',
        courseName:
          'Social Work 1026A/B - INTRODUCTION TO SOCIAL WORK PRACTICE AND COMMUNICATION SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263647f7e10015fd831c',
      },
      {
        _id: '60cc263647f7e10015fd831e',
        courseCode: 'SOCWORK 2206',
        shortName: 'Social Work 2206A/B - RESEARCH METHODS FOR SOCIAL WORKERS',
        courseName: 'Social Work 2206A/B - RESEARCH METHODS FOR SOCIAL WORKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263647f7e10015fd831e',
      },
      {
        _id: '60cc263747f7e10015fd8320',
        courseCode: 'SOCWORK 2207',
        shortName:
          'Social Work 2207A/B - INTRODUCTORY STATISTICS FOR SOCIAL WORKERS',
        courseName:
          'Social Work 2207A/B - INTRODUCTORY STATISTICS FOR SOCIAL WORKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263747f7e10015fd8320',
      },
      {
        _id: '60cc263747f7e10015fd8322',
        courseCode: 'SOCWORK 2214',
        shortName:
          'Social Work 2214A/B - INTRODUCTION TO GENERALIST SOCIAL WORK PRACTICE',
        courseName:
          'Social Work 2214A/B - INTRODUCTION TO GENERALIST SOCIAL WORK PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263747f7e10015fd8322',
      },
      {
        _id: '60cc263747f7e10015fd8324',
        courseCode: 'SOCWORK 2215',
        shortName:
          'Social Work 2215A/B - INTRODUCTION TO SOCIAL WORK PRACTICE THEORIES AND SKILLS',
        courseName:
          'Social Work 2215A/B - INTRODUCTION TO SOCIAL WORK PRACTICE THEORIES AND SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263747f7e10015fd8324',
      },
      {
        _id: '60cc263847f7e10015fd8326',
        courseCode: 'SOCWORK 2216',
        shortName:
          'Social Work 2216A/B - INTRODUCTION TO GENERALIST SOCIAL WORK PRACTICE AND THEORIES',
        courseName:
          'Social Work 2216A/B - INTRODUCTION TO GENERALIST SOCIAL WORK PRACTICE AND THEORIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263847f7e10015fd8326',
      },
      {
        _id: '60cc263847f7e10015fd8328',
        courseCode: 'SOCWORK 2280',
        shortName:
          'Social Work 2280E - INTERCULTURAL UNDERSTANDING AND COMMUNICATION IN MULTICULTURAL CANADA',
        courseName:
          'Social Work 2280E - INTERCULTURAL UNDERSTANDING AND COMMUNICATION IN MULTICULTURAL CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263847f7e10015fd8328',
      },
      {
        _id: '60cc263847f7e10015fd832a',
        courseCode: 'SOCWORK 2284',
        shortName: 'Social Work 2284 - RESPONDING TO CHILD AND FAMILY ISSUES',
        courseName: 'Social Work 2284 - RESPONDING TO CHILD AND FAMILY ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263847f7e10015fd832a',
      },
      {
        _id: '60cc263847f7e10015fd832c',
        courseCode: 'SOCWORK 3301',
        shortName: 'Social Work 3301A/B - DIRECT PRACTICE SKILLS',
        courseName: 'Social Work 3301A/B - DIRECT PRACTICE SKILLS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263847f7e10015fd832c',
      },
      {
        _id: '60cc263947f7e10015fd832e',
        courseCode: 'SOCWORK 3302',
        shortName: 'Social Work 3302A/B - RESILIENCY AND SOCIAL WORK',
        courseName: 'Social Work 3302A/B - RESILIENCY AND SOCIAL WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263947f7e10015fd832e',
      },
      {
        _id: '60cc263947f7e10015fd8330',
        courseCode: 'SOCWORK 3303',
        shortName: 'Social Work 3303A/B - INDIVIDUAL AND FAMILY DEVELOPMENT',
        courseName: 'Social Work 3303A/B - INDIVIDUAL AND FAMILY DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263947f7e10015fd8330',
      },
      {
        _id: '60cc263947f7e10015fd8332',
        courseCode: 'SOCWORK 3308',
        shortName:
          'Social Work 3308F/G - THE POLITICAL, ECONOMIC AND SOCIAL CONTEXT OF CANADIAN SOCIAL WORK PRACTICE',
        courseName:
          'Social Work 3308F/G - THE POLITICAL, ECONOMIC AND SOCIAL CONTEXT OF CANADIAN SOCIAL WORK PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263947f7e10015fd8332',
      },
      {
        _id: '60cc263947f7e10015fd8334',
        courseCode: 'SOCWORK 3314',
        shortName: 'Social Work 3314A/B - FAMILY DEVELOPMENT PERSPECTIVES',
        courseName: 'Social Work 3314A/B - FAMILY DEVELOPMENT PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263947f7e10015fd8334',
      },
      {
        _id: '60cc263a47f7e10015fd8336',
        courseCode: 'SOCWORK 3316',
        shortName:
          'Social Work 3316A/B - SOCIAL WORK PRACTICE WITH INDIVIDUALS AND FAMILIES',
        courseName:
          'Social Work 3316A/B - SOCIAL WORK PRACTICE WITH INDIVIDUALS AND FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263a47f7e10015fd8336',
      },
      {
        _id: '60cc263a47f7e10015fd8338',
        courseCode: 'SOCWORK 3317',
        shortName:
          'Social Work 3317A/B - SOCIAL WORK PRACTICE WITH SMALL GROUPS',
        courseName:
          'Social Work 3317A/B - SOCIAL WORK PRACTICE WITH SMALL GROUPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263a47f7e10015fd8338',
      },
      {
        _id: '60cc263a47f7e10015fd833a',
        courseCode: 'SOCWORK 3318',
        shortName:
          'Social Work 3318A/B - SOCIAL WORK PRACTICE WITH COMMUNITIES',
        courseName:
          'Social Work 3318A/B - SOCIAL WORK PRACTICE WITH COMMUNITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263a47f7e10015fd833a',
      },
      {
        _id: '60cc263a47f7e10015fd833c',
        courseCode: 'SOCWORK 3319',
        shortName: 'Social Work 3319F/G - SOCIAL WORK, ETHICS AND THE LAW',
        courseName: 'Social Work 3319F/G - SOCIAL WORK, ETHICS AND THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263a47f7e10015fd833c',
      },
      {
        _id: '60cc263b47f7e10015fd833e',
        courseCode: 'SOCWORK 3320',
        shortName: 'Social Work 3320Y - PRACTICUM AND INTEGRATION SEMINAR',
        courseName: 'Social Work 3320Y - PRACTICUM AND INTEGRATION SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263b47f7e10015fd833e',
      },
      {
        _id: '60cc263b47f7e10015fd8340',
        courseCode: 'SOCWORK 3333',
        shortName:
          'Social Work 3333A/B - SOCIAL WORK THEORY AND PRACTICE WITH SMALL GROUPS',
        courseName:
          'Social Work 3333A/B - SOCIAL WORK THEORY AND PRACTICE WITH SMALL GROUPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263b47f7e10015fd8340',
      },
      {
        _id: '60cc263b47f7e10015fd8343',
        courseCode: 'SOCWORK 3350',
        shortName: 'Social Work 3350A/B - PRACTICUM & INTEGRATION SEMINAR',
        courseName: 'Social Work 3350A/B - PRACTICUM & INTEGRATION SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263b47f7e10015fd8343',
      },
      {
        _id: '60cc263b47f7e10015fd8345',
        courseCode: 'SOCWORK 3700',
        shortName: 'Social Work 3700E - SPECIAL TOPICS IN SOCIAL WORK',
        courseName: 'Social Work 3700E - SPECIAL TOPICS IN SOCIAL WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263b47f7e10015fd8345',
      },
      {
        _id: '60cc263c47f7e10015fd8347',
        courseCode: 'SOCWORK 4400',
        shortName: 'Social Work 4400 - PRACTICUM AND INTEGRATION SEMINAR',
        courseName: 'Social Work 4400 - PRACTICUM AND INTEGRATION SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263c47f7e10015fd8347',
      },
      {
        _id: '60cc263c47f7e10015fd8349',
        courseCode: 'SOCWORK 4414',
        shortName: 'Social Work 4414F/G - ANALYSIS OF CANADIAN SOCIAL POLICIES',
        courseName:
          'Social Work 4414F/G - ANALYSIS OF CANADIAN SOCIAL POLICIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263c47f7e10015fd8349',
      },
      {
        _id: '60cc263c47f7e10015fd834b',
        courseCode: 'SOCWORK 4415',
        shortName: 'Social Work 4415F/G - ORGANIZATIONAL CONTEXT OF PRACTICE',
        courseName: 'Social Work 4415F/G - ORGANIZATIONAL CONTEXT OF PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263c47f7e10015fd834b',
      },
      {
        _id: '60cc263c47f7e10015fd834d',
        courseCode: 'SOCWORK 4420',
        shortName: 'Social Work 4420A/B - CHILD WELFARE',
        courseName: 'Social Work 4420A/B - CHILD WELFARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263c47f7e10015fd834d',
      },
      {
        _id: '60cc263d47f7e10015fd834f',
        courseCode: 'SOCWORK 4421',
        shortName: 'Social Work 4421A/B - ANTI-OPPRESSIVE SOCIAL WORK PRACTICE',
        courseName:
          'Social Work 4421A/B - ANTI-OPPRESSIVE SOCIAL WORK PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263d47f7e10015fd834f',
      },
      {
        _id: '60cc263d47f7e10015fd8352',
        courseCode: 'SOCWORK 4429',
        shortName: 'Social Work 4429A/B - PRACTICE-BASED RESEARCH',
        courseName: 'Social Work 4429A/B - PRACTICE-BASED RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263d47f7e10015fd8352',
      },
      {
        _id: '60cc263d47f7e10015fd8354',
        courseCode: 'SOCWORK 4430',
        shortName: 'Social Work 4430A/B - OVERVIEW OF ADDICTIONS',
        courseName: 'Social Work 4430A/B - OVERVIEW OF ADDICTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263d47f7e10015fd8354',
      },
      {
        _id: '60cc263d47f7e10015fd8356',
        courseCode: 'SOCWORK 4440',
        shortName: 'Social Work 4440A/B - CHILD WELFARE II',
        courseName: 'Social Work 4440A/B - CHILD WELFARE II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263d47f7e10015fd8356',
      },
      {
        _id: '60cc263e47f7e10015fd8358',
        courseCode: 'SOCWORK 4454',
        shortName: 'Social Work 4454A/B - PRACTICE IN HEALTH CARE',
        courseName: 'Social Work 4454A/B - PRACTICE IN HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263e47f7e10015fd8358',
      },
      {
        _id: '60cc263e47f7e10015fd835a',
        courseCode: 'SOCWORK 4456',
        shortName: 'Social Work 4456A/B - WOMEN AND SOCIAL WORK',
        courseName: 'Social Work 4456A/B - WOMEN AND SOCIAL WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263e47f7e10015fd835a',
      },
      {
        _id: '60cc263e47f7e10015fd835c',
        courseCode: 'SOCWORK 4457',
        shortName: 'Social Work 4457A/B - SELECTED PRACTICE ISSUES',
        courseName: 'Social Work 4457A/B - SELECTED PRACTICE ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263e47f7e10015fd835c',
      },
      {
        _id: '60cc263f47f7e10015fd835e',
        courseCode: 'SOCWORK 4462',
        shortName: 'Social Work 4462A/B - SOCIAL WORK PRACTICE WITH THE AGED',
        courseName: 'Social Work 4462A/B - SOCIAL WORK PRACTICE WITH THE AGED',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263f47f7e10015fd835e',
      },
      {
        _id: '60cc263f47f7e10015fd8360',
        courseCode: 'SOCWORK 4463',
        shortName:
          'Social Work 4463A/B - SOCIAL WORK PRACTICE WITH CHILDREN AND ADOLESCENTS',
        courseName:
          'Social Work 4463A/B - SOCIAL WORK PRACTICE WITH CHILDREN AND ADOLESCENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263f47f7e10015fd8360',
      },
      {
        _id: '60cc263f47f7e10015fd8362',
        courseCode: 'SOCWORK 4465',
        shortName: 'Social Work 4465A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4465A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263f47f7e10015fd8362',
      },
      {
        _id: '60cc263f47f7e10015fd8364',
        courseCode: 'SOCWORK 4466',
        shortName: 'Social Work 4466F/G - INDEPENDENT STUDY',
        courseName: 'Social Work 4466F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc263f47f7e10015fd8364',
      },
      {
        _id: '60cc264047f7e10015fd8366',
        courseCode: 'SOCWORK 4467',
        shortName: 'Social Work 4467F/G - INDEPENDENT STUDY',
        courseName: 'Social Work 4467F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264047f7e10015fd8366',
      },
      {
        _id: '60cc264047f7e10015fd8368',
        courseCode: 'SOCWORK 4468',
        shortName: 'Social Work 4468A/B - VIOLENCE IN FAMILIES',
        courseName: 'Social Work 4468A/B - VIOLENCE IN FAMILIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264047f7e10015fd8368',
      },
      {
        _id: '60cc264047f7e10015fd836a',
        courseCode: 'SOCWORK 4474',
        shortName: 'Social Work 4474A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4474A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264047f7e10015fd836a',
      },
      {
        _id: '60cc264047f7e10015fd836c',
        courseCode: 'SOCWORK 4475',
        shortName: 'Social Work 4475A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4475A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264047f7e10015fd836c',
      },
      {
        _id: '60cc264147f7e10015fd836e',
        courseCode: 'SOCWORK 4476',
        shortName: 'Social Work 4476A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4476A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264147f7e10015fd836e',
      },
      {
        _id: '60cc264147f7e10015fd8370',
        courseCode: 'SOCWORK 4477',
        shortName: 'Social Work 4477A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4477A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264147f7e10015fd8370',
      },
      {
        _id: '60cc264147f7e10015fd8372',
        courseCode: 'SOCWORK 4478',
        shortName: 'Social Work 4478A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4478A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264147f7e10015fd8372',
      },
      {
        _id: '60cc264147f7e10015fd8374',
        courseCode: 'SOCWORK 4479',
        shortName: 'Social Work 4479A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        courseName:
          'Social Work 4479A/B - SPECIAL TOPICS IN SOCIAL WORK THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264147f7e10015fd8374',
      },
      {
        _id: '60cc264247f7e10015fd8376',
        courseCode: 'SOCWORK 4481',
        shortName: 'Social Work 4481A/B - ADVOCATING FOR SOCIAL CHANGE',
        courseName: 'Social Work 4481A/B - ADVOCATING FOR SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264247f7e10015fd8376',
      },
      {
        _id: '60cc264247f7e10015fd8378',
        courseCode: 'SOCWORK 4482',
        shortName:
          'Social Work 4482A/B - CONFLICT RESOLUTION: ALTERNATIVES IN DISPUTES',
        courseName:
          'Social Work 4482A/B - CONFLICT RESOLUTION: ALTERNATIVES IN DISPUTES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264247f7e10015fd8378',
      },
      {
        _id: '60cc264247f7e10015fd837a',
        courseCode: 'SOCWORK 4483',
        shortName:
          'Social Work 4483A/B - SOCIAL WORK PRACTICE IN CRISIS THEORY INTERVENTION',
        courseName:
          'Social Work 4483A/B - SOCIAL WORK PRACTICE IN CRISIS THEORY INTERVENTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264247f7e10015fd837a',
      },
      {
        _id: '60cc264347f7e10015fd837c',
        courseCode: 'SOCWORK 4484',
        shortName:
          'Social Work 4484A/B - SOCIAL WORK PRACTICE AND MENTAL HEALTH',
        courseName:
          'Social Work 4484A/B - SOCIAL WORK PRACTICE AND MENTAL HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264347f7e10015fd837c',
      },
      {
        _id: '60cc264347f7e10015fd837e',
        courseCode: 'SOCIOLOG 0010',
        shortName:
          'Sociology 0010A/B - UNDERSTANDING SOCIETY: SOCIAL LIFE AND SOCIAL INEQUALITY',
        courseName:
          'Sociology 0010A/B - UNDERSTANDING SOCIETY: SOCIAL LIFE AND SOCIAL INEQUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264347f7e10015fd837e',
      },
      {
        _id: '60cc264347f7e10015fd8380',
        courseCode: 'SOCIOLOG 0011',
        shortName:
          'Sociology 0011A/B - UNDERSTANDING SOCIETY: SOCIAL INSTITUTIONS AND SOCIAL CHANGE',
        courseName:
          'Sociology 0011A/B - UNDERSTANDING SOCIETY: SOCIAL INSTITUTIONS AND SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264347f7e10015fd8380',
      },
      {
        _id: '60cc264347f7e10015fd8382',
        courseCode: 'SOCIOLOG 0012',
        shortName: 'Sociology 0012 - INDIVIDUAL AND SOCIETY',
        courseName: 'Sociology 0012 - INDIVIDUAL AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264347f7e10015fd8382',
      },
      {
        _id: '60cc264447f7e10015fd8384',
        courseCode: 'SOCIOLOG 1020',
        shortName: 'Sociology 1020 - INTRODUCTION TO SOCIOLOGY',
        courseName: 'Sociology 1020 - INTRODUCTION TO SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264447f7e10015fd8384',
      },
      {
        _id: '60cc264447f7e10015fd8386',
        courseCode: 'SOCIOLOG 1020',
        shortName: 'Sociology 1020W/X - INTRODUCTION TO SOCIOLOGY',
        courseName: 'Sociology 1020W/X - INTRODUCTION TO SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264447f7e10015fd8386',
      },
      {
        _id: '60cc264547f7e10015fd8388',
        courseCode: 'SOCIOLOG 1021',
        shortName: 'Sociology 1021E - INTRODUCTION TO SOCIOLOGY',
        courseName: 'Sociology 1021E - INTRODUCTION TO SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264547f7e10015fd8388',
      },
      {
        _id: '60cc264647f7e10015fd838a',
        courseCode: 'SOCIOLOG 1025',
        shortName: 'Sociology 1025A/B - SOCIETY AND YOU',
        courseName: 'Sociology 1025A/B - SOCIETY AND YOU',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264647f7e10015fd838a',
      },
      {
        _id: '60cc264647f7e10015fd838c',
        courseCode: 'SOCIOLOG 1026',
        shortName: 'Sociology 1026F/G - CONTROVERSIES IN SOCIOLOGY',
        courseName: 'Sociology 1026F/G - CONTROVERSIES IN SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264647f7e10015fd838c',
      },
      {
        _id: '60cc264647f7e10015fd838e',
        courseCode: 'SOCIOLOG 1027',
        shortName: 'Sociology 1027A/B - LIFE IS NOT ALWAYS FAIR',
        courseName: 'Sociology 1027A/B - LIFE IS NOT ALWAYS FAIR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264647f7e10015fd838e',
      },
      {
        _id: '60cc264647f7e10015fd8390',
        courseCode: 'SOCIOLOG 1050',
        shortName:
          'Sociology 1050A/B - AN INTRO TO COMMUNITY, SERVICE AND ACTIVE CITIZENSHIP',
        courseName:
          'Sociology 1050A/B - AN INTRO TO COMMUNITY, SERVICE AND ACTIVE CITIZENSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264647f7e10015fd8390',
      },
      {
        _id: '60cc264747f7e10015fd8392',
        courseCode: 'SOCIOLOG 2105',
        shortName: 'Sociology 2105A/B - THE SOCIOLOGY OF YOUTH',
        courseName: 'Sociology 2105A/B - THE SOCIOLOGY OF YOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264747f7e10015fd8392',
      },
      {
        _id: '60cc264747f7e10015fd8394',
        courseCode: 'SOCIOLOG 2106',
        shortName: 'Sociology 2106A/B - TECHNOLOGY AND SOCIETY',
        courseName: 'Sociology 2106A/B - TECHNOLOGY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264747f7e10015fd8394',
      },
      {
        _id: '60cc264747f7e10015fd8396',
        courseCode: 'SOCIOLOG 2108',
        shortName: 'Sociology 2108F/G - DECOLONIZING SOCIAL SCIENCE',
        courseName: 'Sociology 2108F/G - DECOLONIZING SOCIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264747f7e10015fd8396',
      },
      {
        _id: '60cc264847f7e10015fd8398',
        courseCode: 'SOCIOLOG 2133',
        shortName: 'Sociology 2133A/B - PUBLIC OPINION',
        courseName: 'Sociology 2133A/B - PUBLIC OPINION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264847f7e10015fd8398',
      },
      {
        _id: '60cc264847f7e10015fd839a',
        courseCode: 'SOCIOLOG 2140',
        shortName: 'Sociology 2140 - SOCIAL PROBLEMS',
        courseName: 'Sociology 2140 - SOCIAL PROBLEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264847f7e10015fd839a',
      },
      {
        _id: '60cc264947f7e10015fd839c',
        courseCode: 'SOCIOLOG 2142',
        shortName: 'Sociology 2142A/B - FOOD AND SOCIETY',
        courseName: 'Sociology 2142A/B - FOOD AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264947f7e10015fd839c',
      },
      {
        _id: '60cc264947f7e10015fd839e',
        courseCode: 'SOCIOLOG 2143',
        shortName: 'Sociology 2143E - MINORITY GROUPS',
        courseName: 'Sociology 2143E - MINORITY GROUPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264947f7e10015fd839e',
      },
      {
        _id: '60cc264947f7e10015fd83a0',
        courseCode: 'SOCIOLOG 2144',
        shortName: 'Sociology 2144A/B - SOCIOLOGY OF EDUCATION',
        courseName: 'Sociology 2144A/B - SOCIOLOGY OF EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264947f7e10015fd83a0',
      },
      {
        _id: '60cc264947f7e10015fd83a2',
        courseCode: 'SOCIOLOG 2145',
        shortName: 'Sociology 2145A/B - SOCIOLOGY OF WORK',
        courseName: 'Sociology 2145A/B - SOCIOLOGY OF WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264947f7e10015fd83a2',
      },
      {
        _id: '60cc264a47f7e10015fd83a4',
        courseCode: 'SOCIOLOG 2151',
        shortName: 'Sociology 2151A/B - THE EVOLUTION OF CITIES',
        courseName: 'Sociology 2151A/B - THE EVOLUTION OF CITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264a47f7e10015fd83a4',
      },
      {
        _id: '60cc264a47f7e10015fd83a6',
        courseCode: 'SOCIOLOG 2152',
        shortName: 'Sociology 2152A/B - LIFE IN THE CONTEMPORARY CITY',
        courseName: 'Sociology 2152A/B - LIFE IN THE CONTEMPORARY CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264a47f7e10015fd83a6',
      },
      {
        _id: '60cc264b47f7e10015fd83a8',
        courseCode: 'SOCIOLOG 2155',
        shortName: 'Sociology 2155A/B - ENVIRONMENTAL SOCIOLOGY',
        courseName: 'Sociology 2155A/B - ENVIRONMENTAL SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264b47f7e10015fd83a8',
      },
      {
        _id: '60cc264b47f7e10015fd83aa',
        courseCode: 'SOCIOLOG 2166',
        shortName:
          'Sociology 2166A/B - THE ORGANIZATION AND EXPERIENCE OF WORK',
        courseName:
          'Sociology 2166A/B - THE ORGANIZATION AND EXPERIENCE OF WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264b47f7e10015fd83aa',
      },
      {
        _id: '60cc264b47f7e10015fd83ac',
        courseCode: 'SOCIOLOG 2172',
        shortName: 'Sociology 2172A/B - ADVERTISING AND SOCIETY',
        courseName: 'Sociology 2172A/B - ADVERTISING AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264b47f7e10015fd83ac',
      },
      {
        _id: '60cc264b47f7e10015fd83ae',
        courseCode: 'SOCIOLOG 2173',
        shortName: 'Sociology 2173A/B - PROBLEMS IN MASS SOCIETY',
        courseName: 'Sociology 2173A/B - PROBLEMS IN MASS SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264b47f7e10015fd83ae',
      },
      {
        _id: '60cc264c47f7e10015fd83b0',
        courseCode: 'SOCIOLOG 2179',
        shortName: 'Sociology 2179A/B - PROMOTION OF COMMUNITY HEALTH',
        courseName: 'Sociology 2179A/B - PROMOTION OF COMMUNITY HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264c47f7e10015fd83b0',
      },
      {
        _id: '60cc264c47f7e10015fd83b2',
        courseCode: 'SOCIOLOG 2180',
        shortName: 'Sociology 2180A/B - DEVELOPMENT AND HEALTH INEQUALITIES',
        courseName: 'Sociology 2180A/B - DEVELOPMENT AND HEALTH INEQUALITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264c47f7e10015fd83b2',
      },
      {
        _id: '60cc264d47f7e10015fd83b4',
        courseCode: 'SOCIOLOG 2190',
        shortName: 'Sociology 2190F/G - DECOLONIZING CANADA',
        courseName: 'Sociology 2190F/G - DECOLONIZING CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264d47f7e10015fd83b4',
      },
      {
        _id: '60cc264d47f7e10015fd83b6',
        courseCode: 'SOCIOLOG 2191',
        shortName: 'Sociology 2191A/B - SPECIAL TOPICS IN SOCIOLOGY',
        courseName: 'Sociology 2191A/B - SPECIAL TOPICS IN SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264d47f7e10015fd83b6',
      },
      {
        _id: '60cc264d47f7e10015fd83b8',
        courseCode: 'SOCIOLOG 2192',
        shortName: 'Sociology 2192A/B - SPECIAL TOPICS IN SOCIOLOGY',
        courseName: 'Sociology 2192A/B - SPECIAL TOPICS IN SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264d47f7e10015fd83b8',
      },
      {
        _id: '60cc264d47f7e10015fd83ba',
        courseCode: 'SOCIOLOG 2200',
        shortName: "Sociology 2200E - SOCIOLOGY OF 'RACE' AND RACISM",
        courseName: "Sociology 2200E - SOCIOLOGY OF 'RACE' AND RACISM",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264d47f7e10015fd83ba',
      },
      {
        _id: '60cc264e47f7e10015fd83bc',
        courseCode: 'SOCIOLOG 2202',
        shortName: 'Sociology 2202 - SOCIOLOGY OF AGING',
        courseName: 'Sociology 2202 - SOCIOLOGY OF AGING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264e47f7e10015fd83bc',
      },
      {
        _id: '60cc264e47f7e10015fd83be',
        courseCode: 'SOCIOLOG 2205',
        shortName: 'Sociology 2205A/B - STATISTICS FOR SOCIOLOGY',
        courseName: 'Sociology 2205A/B - STATISTICS FOR SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264e47f7e10015fd83be',
      },
      {
        _id: '60cc264f47f7e10015fd83c0',
        courseCode: 'SOCIOLOG 2206',
        shortName: 'Sociology 2206A/B - RESEARCH METHODS IN SOCIOLOGY',
        courseName: 'Sociology 2206A/B - RESEARCH METHODS IN SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264f47f7e10015fd83c0',
      },
      {
        _id: '60cc264f47f7e10015fd83c2',
        courseCode: 'SOCIOLOG 2209',
        shortName:
          'Sociology 2209F/G - SOCIOLOGICAL PERSPECTIVES ON INDIGENOUS WOMEN',
        courseName:
          'Sociology 2209F/G - SOCIOLOGICAL PERSPECTIVES ON INDIGENOUS WOMEN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc264f47f7e10015fd83c2',
      },
      {
        _id: '60cc265047f7e10015fd83c4',
        courseCode: 'SOCIOLOG 2212',
        shortName: 'Sociology 2212A/B - WOMEN AND THIRD WORLD DEVELOPMENT',
        courseName: 'Sociology 2212A/B - WOMEN AND THIRD WORLD DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265047f7e10015fd83c4',
      },
      {
        _id: '60cc265047f7e10015fd83c7',
        courseCode: 'SOCIOLOG 2220',
        shortName: 'Sociology 2220A/B - SOCIOLOGY OF THE ENVIRONMENT I',
        courseName: 'Sociology 2220A/B - SOCIOLOGY OF THE ENVIRONMENT I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265047f7e10015fd83c7',
      },
      {
        _id: '60cc265047f7e10015fd83c9',
        courseCode: 'SOCIOLOG 2222',
        shortName: 'Sociology 2222A/B - DRUGS AND SOCIETY',
        courseName: 'Sociology 2222A/B - DRUGS AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265047f7e10015fd83c9',
      },
      {
        _id: '60cc265047f7e10015fd83cb',
        courseCode: 'SOCIOLOG 2223',
        shortName: 'Sociology 2223A/B - POLICE AND SECURITY',
        courseName: 'Sociology 2223A/B - POLICE AND SECURITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265047f7e10015fd83cb',
      },
      {
        _id: '60cc265147f7e10015fd83cd',
        courseCode: 'SOCIOLOG 2227',
        shortName: 'Sociology 2227A/B - SOCIAL CHANGE AND DEVELOPMENT',
        courseName: 'Sociology 2227A/B - SOCIAL CHANGE AND DEVELOPMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265147f7e10015fd83cd',
      },
      {
        _id: '60cc265147f7e10015fd83cf',
        courseCode: 'SOCIOLOG 2228',
        shortName: 'Sociology 2228A/B - SOCIAL INEQUALITY IN CANADA',
        courseName: 'Sociology 2228A/B - SOCIAL INEQUALITY IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265147f7e10015fd83cf',
      },
      {
        _id: '60cc265247f7e10015fd83d1',
        courseCode: 'SOCIOLOG 2229',
        shortName: 'Sociology 2229A/B - GLOBAL INEQUALITY',
        courseName: 'Sociology 2229A/B - GLOBAL INEQUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265247f7e10015fd83d1',
      },
      {
        _id: '60cc265247f7e10015fd83d3',
        courseCode: 'SOCIOLOG 2232',
        shortName: 'Sociology 2232 - POPULATION AND SOCIETY',
        courseName: 'Sociology 2232 - POPULATION AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265247f7e10015fd83d3',
      },
      {
        _id: '60cc265247f7e10015fd83d5',
        courseCode: 'SOCIOLOG 2233',
        shortName: 'Sociology 2233 - SOCIAL PSYCHOLOGY',
        courseName: 'Sociology 2233 - SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265247f7e10015fd83d5',
      },
      {
        _id: '60cc265347f7e10015fd83d7',
        courseCode: 'SOCIOLOG 2234',
        shortName: 'Sociology 2234E - SOCIAL PSYCHOLOGY',
        courseName: 'Sociology 2234E - SOCIAL PSYCHOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265347f7e10015fd83d7',
      },
      {
        _id: '60cc265347f7e10015fd83d9',
        courseCode: 'SOCIOLOG 2235',
        shortName: 'Sociology 2235 - THE FAMILY',
        courseName: 'Sociology 2235 - THE FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265347f7e10015fd83d9',
      },
      {
        _id: '60cc265447f7e10015fd83db',
        courseCode: 'SOCIOLOG 2236',
        shortName: 'Sociology 2236A/B - POPULATION STUDIES',
        courseName: 'Sociology 2236A/B - POPULATION STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265447f7e10015fd83db',
      },
      {
        _id: '60cc265447f7e10015fd83dd',
        courseCode: 'SOCIOLOG 2239',
        shortName: 'Sociology 2239 - SOCIAL INEQUALITY',
        courseName: 'Sociology 2239 - SOCIAL INEQUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265447f7e10015fd83dd',
      },
      {
        _id: '60cc265447f7e10015fd83df',
        courseCode: 'SOCIOLOG 2240',
        shortName: 'Sociology 2240E - SURVEY OF SOCIOLOGICAL THEORY',
        courseName: 'Sociology 2240E - SURVEY OF SOCIOLOGICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265447f7e10015fd83df',
      },
      {
        _id: '60cc265547f7e10015fd83e1',
        courseCode: 'SOCIOLOG 2242',
        shortName: 'Sociology 2242A/B - GENDER AND SOCIETY',
        courseName: 'Sociology 2242A/B - GENDER AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265547f7e10015fd83e1',
      },
      {
        _id: '60cc265547f7e10015fd83e3',
        courseCode: 'SOCIOLOG 2245',
        shortName: 'Sociology 2245 - MEDICAL SOCIOLOGY',
        courseName: 'Sociology 2245 - MEDICAL SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265547f7e10015fd83e3',
      },
      {
        _id: '60cc265647f7e10015fd83e5',
        courseCode: 'SOCIOLOG 2246',
        shortName: 'Sociology 2246A/B - SOCIOLOGY OF HEALTH AND ILLNESS',
        courseName: 'Sociology 2246A/B - SOCIOLOGY OF HEALTH AND ILLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265647f7e10015fd83e5',
      },
      {
        _id: '60cc265647f7e10015fd83e7',
        courseCode: 'SOCIOLOG 2247',
        shortName: 'Sociology 2247A/B - SOCIOLOGY OF HEALTH CARE',
        courseName: 'Sociology 2247A/B - SOCIOLOGY OF HEALTH CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265647f7e10015fd83e7',
      },
      {
        _id: '60cc265647f7e10015fd83e9',
        courseCode: 'SOCIOLOG 2253',
        shortName: 'Sociology 2253A/B - ADMINISTRATION OF CRIMINAL JUSTICE',
        courseName: 'Sociology 2253A/B - ADMINISTRATION OF CRIMINAL JUSTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265647f7e10015fd83e9',
      },
      {
        _id: '60cc265647f7e10015fd83eb',
        courseCode: 'SOCIOLOG 2256',
        shortName: 'Sociology 2256A/B - SOCIOLOGY OF CORRECTIONS',
        courseName: 'Sociology 2256A/B - SOCIOLOGY OF CORRECTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265647f7e10015fd83eb',
      },
      {
        _id: '60cc265747f7e10015fd83ed',
        courseCode: 'SOCIOLOG 2259',
        shortName: 'Sociology 2259 - SOCIOLOGY OF DEVIANCE',
        courseName: 'Sociology 2259 - SOCIOLOGY OF DEVIANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265747f7e10015fd83ed',
      },
      {
        _id: '60cc265747f7e10015fd83ef',
        courseCode: 'SOCIOLOG 2260',
        shortName: 'Sociology 2260A/B - SOCIOLOGY OF LAW',
        courseName: 'Sociology 2260A/B - SOCIOLOGY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265747f7e10015fd83ef',
      },
      {
        _id: '60cc265847f7e10015fd83f1',
        courseCode: 'SOCIOLOG 2264',
        shortName: 'Sociology 2264F/G - SOCIAL MOVEMENTS',
        courseName: 'Sociology 2264F/G - SOCIAL MOVEMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265847f7e10015fd83f1',
      },
      {
        _id: '60cc265847f7e10015fd83f3',
        courseCode: 'SOCIOLOG 2266',
        shortName: 'Sociology 2266A/B - CRIME AND SOCIETY',
        courseName: 'Sociology 2266A/B - CRIME AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265847f7e10015fd83f3',
      },
      {
        _id: '60cc265947f7e10015fd83f5',
        courseCode: 'SOCIOLOG 2267',
        shortName: 'Sociology 2267A/B - YOUTH IN CONFLICT WITH THE LAW',
        courseName: 'Sociology 2267A/B - YOUTH IN CONFLICT WITH THE LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265947f7e10015fd83f5',
      },
      {
        _id: '60cc265947f7e10015fd83f7',
        courseCode: 'SOCIOLOG 2270',
        shortName: 'Sociology 2270A/B - FOUNDATIONS OF SOCIAL THEORY',
        courseName: 'Sociology 2270A/B - FOUNDATIONS OF SOCIAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265947f7e10015fd83f7',
      },
      {
        _id: '60cc265a47f7e10015fd83f9',
        courseCode: 'SOCIOLOG 2271',
        shortName: 'Sociology 2271A/B - SURVEY OF CONTEMPORARY THEORY',
        courseName: 'Sociology 2271A/B - SURVEY OF CONTEMPORARY THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265a47f7e10015fd83f9',
      },
      {
        _id: '60cc265a47f7e10015fd83fb',
        courseCode: 'SOCIOLOG 2275',
        shortName: 'Sociology 2275A/B - ADVERTISING, MEDIA AND SOCIETY',
        courseName: 'Sociology 2275A/B - ADVERTISING, MEDIA AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265a47f7e10015fd83fb',
      },
      {
        _id: '60cc265a47f7e10015fd83fd',
        courseCode: 'SOCIOLOG 2281',
        shortName:
          'Sociology 2281A/B - INTERNATIONAL MIGRATION IN A GLOBALIZED WORLD',
        courseName:
          'Sociology 2281A/B - INTERNATIONAL MIGRATION IN A GLOBALIZED WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265a47f7e10015fd83fd',
      },
      {
        _id: '60cc265b47f7e10015fd83ff',
        courseCode: 'SOCIOLOG 2282',
        shortName: 'Sociology 2282F/G - GENDER AND SOCIETY',
        courseName: 'Sociology 2282F/G - GENDER AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265b47f7e10015fd83ff',
      },
      {
        _id: '60cc265b47f7e10015fd8401',
        courseCode: 'SOCIOLOG 2290',
        shortName: 'Sociology 2290F/G - SPECIAL TOPICS',
        courseName: 'Sociology 2290F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265b47f7e10015fd8401',
      },
      {
        _id: '60cc265b47f7e10015fd8403',
        courseCode: 'SOCIOLOG 2291',
        shortName: 'Sociology 2291F/G - SPECIAL TOPICS',
        courseName: 'Sociology 2291F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265b47f7e10015fd8403',
      },
      {
        _id: '60cc265b47f7e10015fd8405',
        courseCode: 'SOCIOLOG 2292',
        shortName: 'Sociology 2292F/G - SPECIAL TOPICS',
        courseName: 'Sociology 2292F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265b47f7e10015fd8405',
      },
      {
        _id: '60cc265c47f7e10015fd8407',
        courseCode: 'SOCIOLOG 2293',
        shortName: 'Sociology 2293F/G - SPECIAL TOPICS',
        courseName: 'Sociology 2293F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265c47f7e10015fd8407',
      },
      {
        _id: '60cc265c47f7e10015fd8409',
        courseCode: 'SOCIOLOG 2298',
        shortName: 'Sociology 2298A/B - SPECIAL TOPICS IN SOCIOLOGY',
        courseName: 'Sociology 2298A/B - SPECIAL TOPICS IN SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265c47f7e10015fd8409',
      },
      {
        _id: '60cc265c47f7e10015fd840b',
        courseCode: 'SOCIOLOG 2299',
        shortName: 'Sociology 2299A/B - SPECIAL TOPICS IN SOCIOLOGY',
        courseName: 'Sociology 2299A/B - SPECIAL TOPICS IN SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265c47f7e10015fd840b',
      },
      {
        _id: '60cc265d47f7e10015fd840d',
        courseCode: 'SOCIOLOG 3210',
        shortName: 'Sociology 3210F/G - GLOBALIZATION AND CITIZENSHIP',
        courseName: 'Sociology 3210F/G - GLOBALIZATION AND CITIZENSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265d47f7e10015fd840d',
      },
      {
        _id: '60cc265d47f7e10015fd840f',
        courseCode: 'SOCIOLOG 3260',
        shortName: 'Sociology 3260A/B - SOCIOLOGY OF LAW',
        courseName: 'Sociology 3260A/B - SOCIOLOGY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265d47f7e10015fd840f',
      },
      {
        _id: '60cc265d47f7e10015fd8411',
        courseCode: 'SOCIOLOG 3301',
        shortName: 'Sociology 3301F/G - THE INTERNET AND SOCIETY',
        courseName: 'Sociology 3301F/G - THE INTERNET AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265d47f7e10015fd8411',
      },
      {
        _id: '60cc265d47f7e10015fd8413',
        courseCode: 'SOCIOLOG 3304',
        shortName: 'Sociology 3304F/G - THE EXPERIENCE OF HEALTH AND ILLNESS',
        courseName: 'Sociology 3304F/G - THE EXPERIENCE OF HEALTH AND ILLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265d47f7e10015fd8413',
      },
      {
        _id: '60cc265e47f7e10015fd8415',
        courseCode: 'SOCIOLOG 3305',
        shortName: 'Sociology 3305F/G - MANAGEMENT OF HEALTH AND ILLNESS',
        courseName: 'Sociology 3305F/G - MANAGEMENT OF HEALTH AND ILLNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265e47f7e10015fd8415',
      },
      {
        _id: '60cc265e47f7e10015fd8417',
        courseCode: 'SOCIOLOG 3306',
        shortName:
          'Sociology 3306A/B - INVESTIGATING THE SOCIAL WORLD: QUANTITATIVE RESEARCH',
        courseName:
          'Sociology 3306A/B - INVESTIGATING THE SOCIAL WORLD: QUANTITATIVE RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265e47f7e10015fd8417',
      },
      {
        _id: '60cc265e47f7e10015fd8419',
        courseCode: 'SOCIOLOG 3307',
        shortName:
          'Sociology 3307F/G - INVESTIGATING THE SOCIAL WORLD: QUALITATIVE RESEARCH',
        courseName:
          'Sociology 3307F/G - INVESTIGATING THE SOCIAL WORLD: QUALITATIVE RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265e47f7e10015fd8419',
      },
      {
        _id: '60cc265f47f7e10015fd841b',
        courseCode: 'SOCIOLOG 3308',
        shortName: 'Sociology 3308F/G - WORK AND HEALTH ACROSS THE LIFE COURSE',
        courseName:
          'Sociology 3308F/G - WORK AND HEALTH ACROSS THE LIFE COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265f47f7e10015fd841b',
      },
      {
        _id: '60cc265f47f7e10015fd841d',
        courseCode: 'SOCIOLOG 3310',
        shortName: 'Sociology 3310F/G - QUALITATIVE RESEARCH METHODOLOGIES',
        courseName: 'Sociology 3310F/G - QUALITATIVE RESEARCH METHODOLOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265f47f7e10015fd841d',
      },
      {
        _id: '60cc265f47f7e10015fd841f',
        courseCode: 'SOCIOLOG 3312',
        shortName: 'Sociology 3312A/B - WRONGFULLY CONVICTED',
        courseName: 'Sociology 3312A/B - WRONGFULLY CONVICTED',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265f47f7e10015fd841f',
      },
      {
        _id: '60cc265f47f7e10015fd8421',
        courseCode: 'SOCIOLOG 3314',
        shortName: 'Sociology 3314F/G - FAMILY AND WORK',
        courseName: 'Sociology 3314F/G - FAMILY AND WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc265f47f7e10015fd8421',
      },
      {
        _id: '60cc266047f7e10015fd8423',
        courseCode: 'SOCIOLOG 3317',
        shortName: 'Sociology 3317A/B - PROBLEMS OF MASS HIGHER EDUCATION',
        courseName: 'Sociology 3317A/B - PROBLEMS OF MASS HIGHER EDUCATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266047f7e10015fd8423',
      },
      {
        _id: '60cc266047f7e10015fd8425',
        courseCode: 'SOCIOLOG 3318',
        shortName:
          'Sociology 3318F/G - GLOBAL INJUSTICE, BORDERS AND MOBILITIES',
        courseName:
          'Sociology 3318F/G - GLOBAL INJUSTICE, BORDERS AND MOBILITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266047f7e10015fd8425',
      },
      {
        _id: '60cc266047f7e10015fd8427',
        courseCode: 'SOCIOLOG 3319',
        shortName: 'Sociology 3319F/G - SOCIOLOGY OF PILGRIMAGE',
        courseName: 'Sociology 3319F/G - SOCIOLOGY OF PILGRIMAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266047f7e10015fd8427',
      },
      {
        _id: '60cc266047f7e10015fd8429',
        courseCode: 'SOCIOLOG 3320',
        shortName: 'Sociology 3320F/G - YOUTH AND SOCIETY',
        courseName: 'Sociology 3320F/G - YOUTH AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266047f7e10015fd8429',
      },
      {
        _id: '60cc266147f7e10015fd842b',
        courseCode: 'SOCIOLOG 3321',
        shortName: 'Sociology 3321F/G - IDENTITY AND SOCIETY',
        courseName: 'Sociology 3321F/G - IDENTITY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266147f7e10015fd842b',
      },
      {
        _id: '60cc266147f7e10015fd842d',
        courseCode: 'SOCIOLOG 3322',
        shortName:
          'Sociology 3322A/B - COMMUNITY ANALYSIS: RESEARCH AND EVALUATION',
        courseName:
          'Sociology 3322A/B - COMMUNITY ANALYSIS: RESEARCH AND EVALUATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266147f7e10015fd842d',
      },
      {
        _id: '60cc266147f7e10015fd842f',
        courseCode: 'SOCIOLOG 3324',
        shortName: 'Sociology 3324F/G - SOCIAL INEQUALITY OVER THE LIFE COURSE',
        courseName:
          'Sociology 3324F/G - SOCIAL INEQUALITY OVER THE LIFE COURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266147f7e10015fd842f',
      },
      {
        _id: '60cc266247f7e10015fd8431',
        courseCode: 'SOCIOLOG 3325',
        shortName: 'Sociology 3325F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3325F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266247f7e10015fd8431',
      },
      {
        _id: '60cc266247f7e10015fd8433',
        courseCode: 'SOCIOLOG 3326',
        shortName: 'Sociology 3326F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3326F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266247f7e10015fd8433',
      },
      {
        _id: '60cc266247f7e10015fd8435',
        courseCode: 'SOCIOLOG 3327',
        shortName: 'Sociology 3327F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3327F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266247f7e10015fd8435',
      },
      {
        _id: '60cc266247f7e10015fd8437',
        courseCode: 'SOCIOLOG 3328',
        shortName: 'Sociology 3328F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3328F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266247f7e10015fd8437',
      },
      {
        _id: '60cc266347f7e10015fd8439',
        courseCode: 'SOCIOLOG 3329',
        shortName: 'Sociology 3329F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3329F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266347f7e10015fd8439',
      },
      {
        _id: '60cc266347f7e10015fd843b',
        courseCode: 'SOCIOLOG 3330',
        shortName: 'Sociology 3330F/G - COMMUNITY DEVELOPMENT: FOUNDATIONS',
        courseName: 'Sociology 3330F/G - COMMUNITY DEVELOPMENT: FOUNDATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266347f7e10015fd843b',
      },
      {
        _id: '60cc266347f7e10015fd843d',
        courseCode: 'SOCIOLOG 3331',
        shortName: 'Sociology 3331F/G - COMMUNITY DEVELOPMENT: PRACTICE',
        courseName: 'Sociology 3331F/G - COMMUNITY DEVELOPMENT: PRACTICE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266347f7e10015fd843d',
      },
      {
        _id: '60cc266347f7e10015fd843f',
        courseCode: 'SOCIOLOG 3332',
        shortName:
          'Sociology 3332A/B - BEYOND OUR BORDERS: COMMUNITY DEVELOPMENT IN GLOBAL CONTEXT',
        courseName:
          'Sociology 3332A/B - BEYOND OUR BORDERS: COMMUNITY DEVELOPMENT IN GLOBAL CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266347f7e10015fd843f',
      },
      {
        _id: '60cc266447f7e10015fd8441',
        courseCode: 'SOCIOLOG 3333',
        shortName:
          'Sociology 3333F/G - INTERPRETING LAW AND SOCIAL POLICY TO BUILD COMMUNITIES',
        courseName:
          'Sociology 3333F/G - INTERPRETING LAW AND SOCIAL POLICY TO BUILD COMMUNITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266447f7e10015fd8441',
      },
      {
        _id: '60cc266447f7e10015fd8443',
        courseCode: 'SOCIOLOG 3334',
        shortName: 'Sociology 3334A/B - COMMUNITY PRACTICUM',
        courseName: 'Sociology 3334A/B - COMMUNITY PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266447f7e10015fd8443',
      },
      {
        _id: '60cc266447f7e10015fd8445',
        courseCode: 'SOCIOLOG 3335',
        shortName: 'Sociology 3335A/B - COMMUNITY LEADERSHIP',
        courseName: 'Sociology 3335A/B - COMMUNITY LEADERSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266447f7e10015fd8445',
      },
      {
        _id: '60cc266447f7e10015fd8447',
        courseCode: 'SOCIOLOG 3336',
        shortName: 'Sociology 3336F/G - SOCIOLOGY OF SPORT & PHYSICAL FITNESS',
        courseName: 'Sociology 3336F/G - SOCIOLOGY OF SPORT & PHYSICAL FITNESS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266447f7e10015fd8447',
      },
      {
        _id: '60cc266447f7e10015fd8449',
        courseCode: 'SOCIOLOG 3337',
        shortName: 'Sociology 3337F/G - TECHNOLOGY AND SOCIAL CHANGE',
        courseName: 'Sociology 3337F/G - TECHNOLOGY AND SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266447f7e10015fd8449',
      },
      {
        _id: '60cc266547f7e10015fd844b',
        courseCode: 'SOCIOLOG 3338',
        shortName: 'Sociology 3338E - GLOBALIZATION AND SOCIAL CHANGE',
        courseName: 'Sociology 3338E - GLOBALIZATION AND SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266547f7e10015fd844b',
      },
      {
        _id: '60cc266547f7e10015fd844d',
        courseCode: 'SOCIOLOG 3339',
        shortName: 'Sociology 3339F/G - DISABILITY CROSS-CULTURALLY',
        courseName: 'Sociology 3339F/G - DISABILITY CROSS-CULTURALLY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266547f7e10015fd844d',
      },
      {
        _id: '60cc266547f7e10015fd844f',
        courseCode: 'SOCIOLOG 3340',
        shortName: 'Sociology 3340F/G - VIOLENCE IN CULTURAL PERSPECTIVE',
        courseName: 'Sociology 3340F/G - VIOLENCE IN CULTURAL PERSPECTIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266547f7e10015fd844f',
      },
      {
        _id: '60cc266547f7e10015fd8451',
        courseCode: 'SOCIOLOG 3341',
        shortName: 'Sociology 3341F/G - THE SOCIAL CONSTRUCTION OF GENDER',
        courseName: 'Sociology 3341F/G - THE SOCIAL CONSTRUCTION OF GENDER',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266547f7e10015fd8451',
      },
      {
        _id: '60cc266647f7e10015fd8453',
        courseCode: 'SOCIOLOG 3342',
        shortName:
          'Sociology 3342F/G - POWER AND RESISTANCE: DISRUPTING RESEARCH',
        courseName:
          'Sociology 3342F/G - POWER AND RESISTANCE: DISRUPTING RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266647f7e10015fd8453',
      },
      {
        _id: '60cc266647f7e10015fd8455',
        courseCode: 'SOCIOLOG 3343',
        shortName:
          'Sociology 3343F/G - THE SOCIOLOGY OF RELIGION: UNDERSTANDING ISLAM I',
        courseName:
          'Sociology 3343F/G - THE SOCIOLOGY OF RELIGION: UNDERSTANDING ISLAM I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266647f7e10015fd8455',
      },
      {
        _id: '60cc266647f7e10015fd8457',
        courseCode: 'SOCIOLOG 3344',
        shortName: 'Sociology 3344F/G - DIGITAL SOCIOLOGY',
        courseName: 'Sociology 3344F/G - DIGITAL SOCIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266647f7e10015fd8457',
      },
      {
        _id: '60cc266747f7e10015fd8459',
        courseCode: 'SOCIOLOG 3345',
        shortName: 'Sociology 3345F/G - RACE, RACISM AND CRIME',
        courseName: 'Sociology 3345F/G - RACE, RACISM AND CRIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266747f7e10015fd8459',
      },
      {
        _id: '60cc266747f7e10015fd845b',
        courseCode: 'SOCIOLOG 3346',
        shortName: 'Sociology 3346F/G - SOCIOLOGY OF MENTAL HEALTH',
        courseName: 'Sociology 3346F/G - SOCIOLOGY OF MENTAL HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266747f7e10015fd845b',
      },
      {
        _id: '60cc266747f7e10015fd845d',
        courseCode: 'SOCIOLOG 3347',
        shortName: 'Sociology 3347F/G - CURRENT ISSUES IN STRATIFICATION',
        courseName: 'Sociology 3347F/G - CURRENT ISSUES IN STRATIFICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266747f7e10015fd845d',
      },
      {
        _id: '60cc266847f7e10015fd845f',
        courseCode: 'SOCIOLOG 3349',
        shortName: 'Sociology 3349F/G - HATE CRIMES',
        courseName: 'Sociology 3349F/G - HATE CRIMES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266847f7e10015fd845f',
      },
      {
        _id: '60cc266847f7e10015fd8461',
        courseCode: 'SOCIOLOG 3351',
        shortName: 'Sociology 3351F/G - AMERICAN BORDERS AND BORDERLANDS',
        courseName: 'Sociology 3351F/G - AMERICAN BORDERS AND BORDERLANDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266847f7e10015fd8461',
      },
      {
        _id: '60cc266847f7e10015fd8463',
        courseCode: 'SOCIOLOG 3352',
        shortName:
          'Sociology 3352F/G - INEQUALITIES ACROSS FAMILIES: DIVERGING DESTINIES OF THE RICH AND POOR',
        courseName:
          'Sociology 3352F/G - INEQUALITIES ACROSS FAMILIES: DIVERGING DESTINIES OF THE RICH AND POOR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266847f7e10015fd8463',
      },
      {
        _id: '60cc266947f7e10015fd8465',
        courseCode: 'SOCIOLOG 3354',
        shortName: 'Sociology 3354F/G - MIGRATION, STRANGERS & BORDERS',
        courseName: 'Sociology 3354F/G - MIGRATION, STRANGERS & BORDERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266947f7e10015fd8465',
      },
      {
        _id: '60cc266947f7e10015fd8467',
        courseCode: 'SOCIOLOG 3355',
        shortName: 'Sociology 3355F/G - SOCIOLOGY OF CATASTROPHE',
        courseName: 'Sociology 3355F/G - SOCIOLOGY OF CATASTROPHE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266947f7e10015fd8467',
      },
      {
        _id: '60cc266947f7e10015fd8469',
        courseCode: 'SOCIOLOG 3356',
        shortName: 'Sociology 3356F/G - VICTIMOLOGY',
        courseName: 'Sociology 3356F/G - VICTIMOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266947f7e10015fd8469',
      },
      {
        _id: '60cc266947f7e10015fd846b',
        courseCode: 'SOCIOLOG 3357',
        shortName: 'Sociology 3357F/G - CRIME AND DEVIANCE IN THE WORKPLACE',
        courseName: 'Sociology 3357F/G - CRIME AND DEVIANCE IN THE WORKPLACE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266947f7e10015fd846b',
      },
      {
        _id: '60cc266a47f7e10015fd846d',
        courseCode: 'SOCIOLOG 3358',
        shortName: 'Sociology 3358F/G - WOMEN AND CRIME',
        courseName: 'Sociology 3358F/G - WOMEN AND CRIME',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266a47f7e10015fd846d',
      },
      {
        _id: '60cc266a47f7e10015fd846f',
        courseCode: 'SOCIOLOG 3359',
        shortName: 'Sociology 3359F/G - GENDER, VIOLENCE, AND DIGITAL MEDIA',
        courseName: 'Sociology 3359F/G - GENDER, VIOLENCE, AND DIGITAL MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266a47f7e10015fd846f',
      },
      {
        _id: '60cc266a47f7e10015fd8471',
        courseCode: 'SOCIOLOG 3360',
        shortName: 'Sociology 3360F/G - LAW AND SOCIAL INEQUALITY',
        courseName: 'Sociology 3360F/G - LAW AND SOCIAL INEQUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266a47f7e10015fd8471',
      },
      {
        _id: '60cc266a47f7e10015fd8473',
        courseCode: 'SOCIOLOG 3361',
        shortName: 'Sociology 3361F/G - CRIMES OF THE POWERFUL',
        courseName: 'Sociology 3361F/G - CRIMES OF THE POWERFUL',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266a47f7e10015fd8473',
      },
      {
        _id: '60cc266b47f7e10015fd8475',
        courseCode: 'SOCIOLOG 3362',
        shortName: 'Sociology 3362F/G - SOCIOLOGY OF UTOPIA',
        courseName: 'Sociology 3362F/G - SOCIOLOGY OF UTOPIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266b47f7e10015fd8475',
      },
      {
        _id: '60cc266b47f7e10015fd8477',
        courseCode: 'SOCIOLOG 3363',
        shortName: 'Sociology 3363F/G - SOCIOLOGY OF TERRORISM',
        courseName: 'Sociology 3363F/G - SOCIOLOGY OF TERRORISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266b47f7e10015fd8477',
      },
      {
        _id: '60cc266b47f7e10015fd8479',
        courseCode: 'SOCIOLOG 3364',
        shortName: 'Sociology 3364F/G - SELECTED TOPICS',
        courseName: 'Sociology 3364F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266b47f7e10015fd8479',
      },
      {
        _id: '60cc266b47f7e10015fd847b',
        courseCode: 'SOCIOLOG 3365',
        shortName: 'Sociology 3365F/G - SELECTED TOPICS',
        courseName: 'Sociology 3365F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266b47f7e10015fd847b',
      },
      {
        _id: '60cc266c47f7e10015fd847d',
        courseCode: 'SOCIOLOG 3366',
        shortName: 'Sociology 3366F/G - GANGSTERS AND THE MOB',
        courseName: 'Sociology 3366F/G - GANGSTERS AND THE MOB',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266c47f7e10015fd847d',
      },
      {
        _id: '60cc266c47f7e10015fd847f',
        courseCode: 'SOCIOLOG 3368',
        shortName: 'Sociology 3368F/G - MARRIAGE MARKETS',
        courseName: 'Sociology 3368F/G - MARRIAGE MARKETS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266c47f7e10015fd847f',
      },
      {
        _id: '60cc266c47f7e10015fd8481',
        courseCode: 'SOCIOLOG 3369',
        shortName: 'Sociology 3369F/G - BUILDING HEALTHY COMMUNITIES',
        courseName: 'Sociology 3369F/G - BUILDING HEALTHY COMMUNITIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266c47f7e10015fd8481',
      },
      {
        _id: '60cc266c47f7e10015fd8483',
        courseCode: 'SOCIOLOG 3370',
        shortName: 'Sociology 3370F/G - UNDERSTANDING MEDICAL DIVERSITY',
        courseName: 'Sociology 3370F/G - UNDERSTANDING MEDICAL DIVERSITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266c47f7e10015fd8483',
      },
      {
        _id: '60cc266c47f7e10015fd8485',
        courseCode: 'SOCIOLOG 3371',
        shortName: 'Sociology 3371F/G - GLOBAL HEALTH  AND HUMAN RIGHTS',
        courseName: 'Sociology 3371F/G - GLOBAL HEALTH  AND HUMAN RIGHTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266c47f7e10015fd8485',
      },
      {
        _id: '60cc266d47f7e10015fd8487',
        courseCode: 'SOCIOLOG 3372',
        shortName: 'Sociology 3372F/G - CONSUMER SOCIETY',
        courseName: 'Sociology 3372F/G - CONSUMER SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266d47f7e10015fd8487',
      },
      {
        _id: '60cc266d47f7e10015fd8489',
        courseCode: 'SOCIOLOG 3378',
        shortName: 'Sociology 3378F/G - GLOBAL DIVIDE',
        courseName: 'Sociology 3378F/G - GLOBAL DIVIDE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266d47f7e10015fd8489',
      },
      {
        _id: '60cc266d47f7e10015fd848b',
        courseCode: 'SOCIOLOG 3379',
        shortName: 'Sociology 3379F/G - CRIME, PUNISHMENT, AND POPULAR CULTURE',
        courseName:
          'Sociology 3379F/G - CRIME, PUNISHMENT, AND POPULAR CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266d47f7e10015fd848b',
      },
      {
        _id: '60cc266d47f7e10015fd848d',
        courseCode: 'SOCIOLOG 3380',
        shortName:
          'Sociology 3380F/G - ADVANCED GENDER RELATIONS: THE WORLD OF WORK',
        courseName:
          'Sociology 3380F/G - ADVANCED GENDER RELATIONS: THE WORLD OF WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266d47f7e10015fd848d',
      },
      {
        _id: '60cc266e47f7e10015fd848f',
        courseCode: 'SOCIOLOG 3381',
        shortName: 'Sociology 3381F/G - MIGRATION AND FAMILY',
        courseName: 'Sociology 3381F/G - MIGRATION AND FAMILY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266e47f7e10015fd848f',
      },
      {
        _id: '60cc266e47f7e10015fd8491',
        courseCode: 'SOCIOLOG 3382',
        shortName: 'Sociology 3382F/G - NEOLIBERALISM AND OUR SOCIETY',
        courseName: 'Sociology 3382F/G - NEOLIBERALISM AND OUR SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266e47f7e10015fd8491',
      },
      {
        _id: '60cc266e47f7e10015fd8493',
        courseCode: 'SOCIOLOG 3383',
        shortName:
          'Sociology 3383F/G - SCHOOLING THE NATION: EDUCATION, NATIONALISM AND THE STATE',
        courseName:
          'Sociology 3383F/G - SCHOOLING THE NATION: EDUCATION, NATIONALISM AND THE STATE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266e47f7e10015fd8493',
      },
      {
        _id: '60cc266e47f7e10015fd8495',
        courseCode: 'SOCIOLOG 3385',
        shortName: 'Sociology 3385F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3385F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266e47f7e10015fd8495',
      },
      {
        _id: '60cc266f47f7e10015fd8497',
        courseCode: 'SOCIOLOG 3386',
        shortName: 'Sociology 3386F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3386F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266f47f7e10015fd8497',
      },
      {
        _id: '60cc266f47f7e10015fd8499',
        courseCode: 'SOCIOLOG 3387',
        shortName: 'Sociology 3387F/G - SURVEILLANCE, SECURITY AND SOCIETY',
        courseName: 'Sociology 3387F/G - SURVEILLANCE, SECURITY AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266f47f7e10015fd8499',
      },
      {
        _id: '60cc266f47f7e10015fd849b',
        courseCode: 'SOCIOLOG 3388',
        shortName: 'Sociology 3388F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3388F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266f47f7e10015fd849b',
      },
      {
        _id: '60cc266f47f7e10015fd849d',
        courseCode: 'SOCIOLOG 3389',
        shortName: 'Sociology 3389F/G - SPECIAL TOPICS',
        courseName: 'Sociology 3389F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266f47f7e10015fd849d',
      },
      {
        _id: '60cc266f47f7e10015fd849f',
        courseCode: 'SOCIOLOG 3390',
        shortName: 'Sociology 3390F/G - DECOLONIZATION I',
        courseName: 'Sociology 3390F/G - DECOLONIZATION I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc266f47f7e10015fd849f',
      },
      {
        _id: '60cc267047f7e10015fd84a1',
        courseCode: 'SOCIOLOG 3391',
        shortName: 'Sociology 3391F/G - DECOLONIZATION II',
        courseName: 'Sociology 3391F/G - DECOLONIZATION II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267047f7e10015fd84a1',
      },
      {
        _id: '60cc267047f7e10015fd84a3',
        courseCode: 'SOCIOLOG 3398',
        shortName: 'Sociology 3398F/G - INDEPENDENT STUDY',
        courseName: 'Sociology 3398F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267047f7e10015fd84a3',
      },
      {
        _id: '60cc267047f7e10015fd84a5',
        courseCode: 'SOCIOLOG 3399',
        shortName: 'Sociology 3399F/G - INDEPENDENT STUDY',
        courseName: 'Sociology 3399F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267047f7e10015fd84a5',
      },
      {
        _id: '60cc267147f7e10015fd84a7',
        courseCode: 'SOCIOLOG 3404',
        shortName: 'Sociology 3404F/G - MODERN SOCIOLOGICAL THEORY',
        courseName: 'Sociology 3404F/G - MODERN SOCIOLOGICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267147f7e10015fd84a7',
      },
      {
        _id: '60cc267147f7e10015fd84a9',
        courseCode: 'SOCIOLOG 4400',
        shortName:
          'Sociology 4400A/B - INTRODUCTION TO MULTIVARIATE ANALYSIS FOR SOCIAL SCIENCE',
        courseName:
          'Sociology 4400A/B - INTRODUCTION TO MULTIVARIATE ANALYSIS FOR SOCIAL SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267147f7e10015fd84a9',
      },
      {
        _id: '60cc267147f7e10015fd84ab',
        courseCode: 'SOCIOLOG 4401',
        shortName: 'Sociology 4401F/G - EVERYDAY LIFE',
        courseName: 'Sociology 4401F/G - EVERYDAY LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267147f7e10015fd84ab',
      },
      {
        _id: '60cc267247f7e10015fd84ad',
        courseCode: 'SOCIOLOG 4402',
        shortName: 'Sociology 4402F/G - SOCIOLOGY OF SPACES',
        courseName: 'Sociology 4402F/G - SOCIOLOGY OF SPACES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267247f7e10015fd84ad',
      },
      {
        _id: '60cc267247f7e10015fd84af',
        courseCode: 'SOCIOLOG 4404',
        shortName: 'Sociology 4404F/G - SOCIOLOGICAL THEORY I',
        courseName: 'Sociology 4404F/G - SOCIOLOGICAL THEORY I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267247f7e10015fd84af',
      },
      {
        _id: '60cc267247f7e10015fd84b1',
        courseCode: 'SOCIOLOG 4405',
        shortName: 'Sociology 4405F/G - SEMINAR IN SOCIOLOGICAL THEORY',
        courseName: 'Sociology 4405F/G - SEMINAR IN SOCIOLOGICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267247f7e10015fd84b1',
      },
      {
        _id: '60cc267347f7e10015fd84b3',
        courseCode: 'SOCIOLOG 4407',
        shortName: 'Sociology 4407F/G - CRIMINOLOGICAL THEORY AND RESEARCH',
        courseName: 'Sociology 4407F/G - CRIMINOLOGICAL THEORY AND RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267347f7e10015fd84b3',
      },
      {
        _id: '60cc267347f7e10015fd84b5',
        courseCode: 'SOCIOLOG 4408',
        shortName: 'Sociology 4408F/G - ADVANCED SOCIOLOGY OF HEALTH',
        courseName: 'Sociology 4408F/G - ADVANCED SOCIOLOGY OF HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267347f7e10015fd84b5',
      },
      {
        _id: '60cc267347f7e10015fd84b7',
        courseCode: 'SOCIOLOG 4409',
        shortName:
          'Sociology 4409F/G - SEMINAR IN CRIMINOLOGICAL THEORY AND RESEARCH',
        courseName:
          'Sociology 4409F/G - SEMINAR IN CRIMINOLOGICAL THEORY AND RESEARCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267347f7e10015fd84b7',
      },
      {
        _id: '60cc267347f7e10015fd84b9',
        courseCode: 'SOCIOLOG 4416',
        shortName: 'Sociology 4416F/G - MIGRATION',
        courseName: 'Sociology 4416F/G - MIGRATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267347f7e10015fd84b9',
      },
      {
        _id: '60cc267447f7e10015fd84bb',
        courseCode: 'SOCIOLOG 4417',
        shortName: 'Sociology 4417F/G - POPULATION CHANGE IN CANADA',
        courseName: 'Sociology 4417F/G - POPULATION CHANGE IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267447f7e10015fd84bb',
      },
      {
        _id: '60cc267447f7e10015fd84bd',
        courseCode: 'SOCIOLOG 4420',
        shortName:
          'Sociology 4420F/G - THE SOCIAL CONTEXT OF RACIAL INEQUALITY',
        courseName:
          'Sociology 4420F/G - THE SOCIAL CONTEXT OF RACIAL INEQUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267447f7e10015fd84bd',
      },
      {
        _id: '60cc267447f7e10015fd84bf',
        courseCode: 'SOCIOLOG 4422',
        shortName: 'Sociology 4422F/G - LGBTQ2S+ POPULATIONS',
        courseName: 'Sociology 4422F/G - LGBTQ2S+ POPULATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267447f7e10015fd84bf',
      },
      {
        _id: '60cc267447f7e10015fd84c1',
        courseCode: 'SOCIOLOG 4425',
        shortName: 'Sociology 4425F/G - ADVANCED SOCIOLOGY OF YOUTH',
        courseName: 'Sociology 4425F/G - ADVANCED SOCIOLOGY OF YOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267447f7e10015fd84c1',
      },
      {
        _id: '60cc267547f7e10015fd84c3',
        courseCode: 'SOCIOLOG 4426',
        shortName: 'Sociology 4426F/G - PROTEST AND CONTENTIOUS ACTION',
        courseName: 'Sociology 4426F/G - PROTEST AND CONTENTIOUS ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267547f7e10015fd84c3',
      },
      {
        _id: '60cc267547f7e10015fd84c5',
        courseCode: 'SOCIOLOG 4432',
        shortName: 'Sociology 4432F/G - FORENSICS AND CRIMINAL INVESTIGATION',
        courseName: 'Sociology 4432F/G - FORENSICS AND CRIMINAL INVESTIGATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267547f7e10015fd84c5',
      },
      {
        _id: '60cc267547f7e10015fd84c7',
        courseCode: 'SOCIOLOG 4437',
        shortName: 'Sociology 4437F/G - ADVANCED SOCIOLOGY OF DEVIANCE',
        courseName: 'Sociology 4437F/G - ADVANCED SOCIOLOGY OF DEVIANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267547f7e10015fd84c7',
      },
      {
        _id: '60cc267647f7e10015fd84c9',
        courseCode: 'SOCIOLOG 4438',
        shortName: 'Sociology 4438F/G - ADVANCED CRIMINAL JUSTICE POLICY',
        courseName: 'Sociology 4438F/G - ADVANCED CRIMINAL JUSTICE POLICY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267647f7e10015fd84c9',
      },
      {
        _id: '60cc267647f7e10015fd84cb',
        courseCode: 'SOCIOLOG 4439',
        shortName: 'Sociology 4439F/G - DOMESTIC VIOLENCE',
        courseName: 'Sociology 4439F/G - DOMESTIC VIOLENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267647f7e10015fd84cb',
      },
      {
        _id: '60cc267647f7e10015fd84cd',
        courseCode: 'SOCIOLOG 4440',
        shortName: 'Sociology 4440F/G - SOCIAL WORLDS OF DRUGS AND ADDICTION',
        courseName: 'Sociology 4440F/G - SOCIAL WORLDS OF DRUGS AND ADDICTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267647f7e10015fd84cd',
      },
      {
        _id: '60cc267747f7e10015fd84cf',
        courseCode: 'SOCIOLOG 4441',
        shortName:
          'Sociology 4441A/B - DEATH, FERTILITY AND MIGRATION: DEMOGRAPHIC ANALYSIS OF SOCIAL CHANGE',
        courseName:
          'Sociology 4441A/B - DEATH, FERTILITY AND MIGRATION: DEMOGRAPHIC ANALYSIS OF SOCIAL CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267747f7e10015fd84cf',
      },
      {
        _id: '60cc267747f7e10015fd84d1',
        courseCode: 'SOCIOLOG 4442',
        shortName: 'Sociology 4442F/G - SERIAL KILLERS',
        courseName: 'Sociology 4442F/G - SERIAL KILLERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267747f7e10015fd84d1',
      },
      {
        _id: '60cc267747f7e10015fd84d3',
        courseCode: 'SOCIOLOG 4450',
        shortName: 'Sociology 4450F/G - EDUCATION AND SOCIETY',
        courseName: 'Sociology 4450F/G - EDUCATION AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267747f7e10015fd84d3',
      },
      {
        _id: '60cc267747f7e10015fd84d5',
        courseCode: 'SOCIOLOG 4451',
        shortName: 'Sociology 4451F/G - POLICING AND SOCIETY',
        courseName: 'Sociology 4451F/G - POLICING AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267747f7e10015fd84d5',
      },
      {
        _id: '60cc267847f7e10015fd84d7',
        courseCode: 'SOCIOLOG 4452',
        shortName: 'Sociology 4452F/G - THE SOCIAL CONSTRUCTION OF SEXUALITY',
        courseName: 'Sociology 4452F/G - THE SOCIAL CONSTRUCTION OF SEXUALITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267847f7e10015fd84d7',
      },
      {
        _id: '60cc267847f7e10015fd84d9',
        courseCode: 'SOCIOLOG 4455',
        shortName: 'Sociology 4455F/G - ADVANCED SOCIOLOGY OF LAW',
        courseName: 'Sociology 4455F/G - ADVANCED SOCIOLOGY OF LAW',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267847f7e10015fd84d9',
      },
      {
        _id: '60cc267847f7e10015fd84db',
        courseCode: 'SOCIOLOG 4465',
        shortName: 'Sociology 4465E - HONOURS THESIS',
        courseName: 'Sociology 4465E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267847f7e10015fd84db',
      },
      {
        _id: '60cc267947f7e10015fd84dd',
        courseCode: 'SOCIOLOG 4466',
        shortName:
          'Sociology 4466F/G - PROFESSIONS, PROFESSIONALS AND THEIR WORK',
        courseName:
          'Sociology 4466F/G - PROFESSIONS, PROFESSIONALS AND THEIR WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267947f7e10015fd84dd',
      },
      {
        _id: '60cc267947f7e10015fd84df',
        courseCode: 'SOCIOLOG 4469',
        shortName:
          'Sociology 4469F/G - SELECTED TOPICS IN THE SOCIOLOGY OF WORK',
        courseName:
          'Sociology 4469F/G - SELECTED TOPICS IN THE SOCIOLOGY OF WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267947f7e10015fd84df',
      },
      {
        _id: '60cc267947f7e10015fd84e1',
        courseCode: 'SOCIOLOG 4471',
        shortName: 'Sociology 4471F/G - THE SOCIOLOGY OF CLIMATE CHANGE',
        courseName: 'Sociology 4471F/G - THE SOCIOLOGY OF CLIMATE CHANGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267947f7e10015fd84e1',
      },
      {
        _id: '60cc267947f7e10015fd84e3',
        courseCode: 'SOCIOLOG 4472',
        shortName:
          'Sociology 4472F/G - HEALTH INEQUALITIES: THE SOCIAL DETERMINANTS OF HEALTH',
        courseName:
          'Sociology 4472F/G - HEALTH INEQUALITIES: THE SOCIAL DETERMINANTS OF HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267947f7e10015fd84e3',
      },
      {
        _id: '60cc267a47f7e10015fd84e5',
        courseCode: 'SOCIOLOG 4473',
        shortName:
          'Sociology 4473F/G - IMMIGRATION AND IMMIGRANT INTEGRATION IN CANADA',
        courseName:
          'Sociology 4473F/G - IMMIGRATION AND IMMIGRANT INTEGRATION IN CANADA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267a47f7e10015fd84e5',
      },
      {
        _id: '60cc267a47f7e10015fd84e7',
        courseCode: 'SOCIOLOG 4478',
        shortName: 'Sociology 4478F/G - ADVANCED PROGRAM AND POLICY EVALUATION',
        courseName:
          'Sociology 4478F/G - ADVANCED PROGRAM AND POLICY EVALUATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267a47f7e10015fd84e7',
      },
      {
        _id: '60cc267a47f7e10015fd84e9',
        courseCode: 'SOCIOLOG 4480',
        shortName: 'Sociology 4480E - CRITICAL SECURITY STUDIES',
        courseName: 'Sociology 4480E - CRITICAL SECURITY STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267a47f7e10015fd84e9',
      },
      {
        _id: '60cc267a47f7e10015fd84eb',
        courseCode: 'SOCIOLOG 4485',
        shortName: 'Sociology 4485F/G - SELECTED TOPICS',
        courseName: 'Sociology 4485F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267a47f7e10015fd84eb',
      },
      {
        _id: '60cc267b47f7e10015fd84ed',
        courseCode: 'SOCIOLOG 4486',
        shortName: 'Sociology 4486F/G - SELECTED TOPICS',
        courseName: 'Sociology 4486F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267b47f7e10015fd84ed',
      },
      {
        _id: '60cc267c47f7e10015fd84ef',
        courseCode: 'SOCIOLOG 4487',
        shortName: 'Sociology 4487F/G - SELECTED TOPICS',
        courseName: 'Sociology 4487F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267c47f7e10015fd84ef',
      },
      {
        _id: '60cc267c47f7e10015fd84f1',
        courseCode: 'SOCIOLOG 4488',
        shortName: 'Sociology 4488F/G - SELECTED TOPICS',
        courseName: 'Sociology 4488F/G - SELECTED TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267c47f7e10015fd84f1',
      },
      {
        _id: '60cc267c47f7e10015fd84f3',
        courseCode: 'SOCIOLOG 4490',
        shortName: 'Sociology 4490E - THESIS',
        courseName: 'Sociology 4490E - THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267c47f7e10015fd84f3',
      },
      {
        _id: '60cc267d47f7e10015fd84f5',
        courseCode: 'SOCIOLOG 4496',
        shortName: 'Sociology 4496E - THE CRAFT OF INQUIRY',
        courseName: 'Sociology 4496E - THE CRAFT OF INQUIRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267d47f7e10015fd84f5',
      },
      {
        _id: '60cc267d47f7e10015fd84f7',
        courseCode: 'SOCIOLOG 4498',
        shortName: 'Sociology 4498F/G - INDEPENDENT STUDY',
        courseName: 'Sociology 4498F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267d47f7e10015fd84f7',
      },
      {
        _id: '60cc267d47f7e10015fd84f9',
        courseCode: 'SOCIOLOG 4499',
        shortName: 'Sociology 4499F/G - INDEPENDENT STUDY',
        courseName: 'Sociology 4499F/G - INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267d47f7e10015fd84f9',
      },
      {
        _id: '60cc267e47f7e10015fd84fb',
        courseCode: 'SE 2202',
        shortName:
          'Software Engineering 2202A/B - SCRIPTING PROGRAMMING LANGUAGE FUNDAMENTALS',
        courseName:
          'Software Engineering 2202A/B - SCRIPTING PROGRAMMING LANGUAGE FUNDAMENTALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267e47f7e10015fd84fb',
      },
      {
        _id: '60cc267e47f7e10015fd84fd',
        courseCode: 'SE 2203',
        shortName: 'Software Engineering 2203A/B - SOFTWARE DESIGN',
        courseName: 'Software Engineering 2203A/B - SOFTWARE DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267e47f7e10015fd84fd',
      },
      {
        _id: '60cc267e47f7e10015fd84ff',
        courseCode: 'SE 2205',
        shortName:
          'Software Engineering 2205A/B - ALGORITHMS & DATA STRUCTURE FOR OBJECT-ORIENTED DESIGN',
        courseName:
          'Software Engineering 2205A/B - ALGORITHMS & DATA STRUCTURE FOR OBJECT-ORIENTED DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267e47f7e10015fd84ff',
      },
      {
        _id: '60cc267f47f7e10015fd8501',
        courseCode: 'SE 2250',
        shortName: 'Software Engineering 2250A/B - SOFTWARE CONSTRUCTION',
        courseName: 'Software Engineering 2250A/B - SOFTWARE CONSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267f47f7e10015fd8501',
      },
      {
        _id: '60cc267f47f7e10015fd8503',
        courseCode: 'SE 3309',
        shortName: 'Software Engineering 3309A/B - DATABASE MANAGEMENT SYSTEMS',
        courseName:
          'Software Engineering 3309A/B - DATABASE MANAGEMENT SYSTEMS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267f47f7e10015fd8503',
      },
      {
        _id: '60cc267f47f7e10015fd8505',
        courseCode: 'SE 3310',
        shortName:
          'Software Engineering 3310A/B - THEORETICAL FOUNDATIONS OF SOFTWARE ENGINEERING',
        courseName:
          'Software Engineering 3310A/B - THEORETICAL FOUNDATIONS OF SOFTWARE ENGINEERING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267f47f7e10015fd8505',
      },
      {
        _id: '60cc267f47f7e10015fd8507',
        courseCode: 'SE 3313',
        shortName:
          'Software Engineering 3313A/B - OPERATING SYSTEMS FOR SOFTWARE ENGINEERNG',
        courseName:
          'Software Engineering 3313A/B - OPERATING SYSTEMS FOR SOFTWARE ENGINEERNG',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc267f47f7e10015fd8507',
      },
      {
        _id: '60cc268047f7e10015fd8509',
        courseCode: 'SE 3314',
        shortName:
          'Software Engineering 3314A/B - COMPUTER NETWORKS APPLICATIONS',
        courseName:
          'Software Engineering 3314A/B - COMPUTER NETWORKS APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268047f7e10015fd8509',
      },
      {
        _id: '60cc268047f7e10015fd850b',
        courseCode: 'SE 3316',
        shortName: 'Software Engineering 3316A/B - WEB TECHNOLOGIES',
        courseName: 'Software Engineering 3316A/B - WEB TECHNOLOGIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268047f7e10015fd850b',
      },
      {
        _id: '60cc268047f7e10015fd850d',
        courseCode: 'SE 3350',
        shortName:
          'Software Engineering 3350A/B - SOFTWARE ENGINEERING DESIGN I',
        courseName:
          'Software Engineering 3350A/B - SOFTWARE ENGINEERING DESIGN I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268047f7e10015fd850d',
      },
      {
        _id: '60cc268047f7e10015fd850f',
        courseCode: 'SE 3351',
        shortName:
          'Software Engineering 3351A/B - SOFTWARE PROJECT & PROCESS MANAGEMENT',
        courseName:
          'Software Engineering 3351A/B - SOFTWARE PROJECT & PROCESS MANAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268047f7e10015fd850f',
      },
      {
        _id: '60cc268147f7e10015fd8511',
        courseCode: 'SE 3352',
        shortName:
          'Software Engineering 3352A/B - SOFTWARE REQUIREMENTS AND ANALYSIS',
        courseName:
          'Software Engineering 3352A/B - SOFTWARE REQUIREMENTS AND ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268147f7e10015fd8511',
      },
      {
        _id: '60cc268147f7e10015fd8513',
        courseCode: 'SE 3353',
        shortName:
          'Software Engineering 3353A/B - HUMAN-COMPUTER INTERFACE DESIGN',
        courseName:
          'Software Engineering 3353A/B - HUMAN-COMPUTER INTERFACE DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268147f7e10015fd8513',
      },
      {
        _id: '60cc268147f7e10015fd8515',
        courseCode: 'SE 4410',
        shortName: 'Software Engineering 4410A/B - WIRELESS LANS & WANS',
        courseName: 'Software Engineering 4410A/B - WIRELESS LANS & WANS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268147f7e10015fd8515',
      },
      {
        _id: '60cc268147f7e10015fd8517',
        courseCode: 'SE 4450',
        shortName: 'Software Engineering 4450 - SOFTWARE ENGINEERING DESIGN II',
        courseName:
          'Software Engineering 4450 - SOFTWARE ENGINEERING DESIGN II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268147f7e10015fd8517',
      },
      {
        _id: '60cc268247f7e10015fd8519',
        courseCode: 'SE 4452',
        shortName:
          'Software Engineering 4452A/B - SOFTWARE TESTING & MAINTENANCE',
        courseName:
          'Software Engineering 4452A/B - SOFTWARE TESTING & MAINTENANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268247f7e10015fd8519',
      },
      {
        _id: '60cc268247f7e10015fd851b',
        courseCode: 'SE 4455',
        shortName:
          'Software Engineering 4455A/B - CLOUD COMPUTING: CONCEPTS, TECHNOLOGIES AND APPLICATIONS',
        courseName:
          'Software Engineering 4455A/B - CLOUD COMPUTING: CONCEPTS, TECHNOLOGIES AND APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268247f7e10015fd851b',
      },
      {
        _id: '60cc268247f7e10015fd851d',
        courseCode: 'SE 4470',
        shortName:
          'Software Engineering 4470A/B - SELECTED TOPICS IN SOFTWARE ENGINEERING I',
        courseName:
          'Software Engineering 4470A/B - SELECTED TOPICS IN SOFTWARE ENGINEERING I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268247f7e10015fd851d',
      },
      {
        _id: '60cc268247f7e10015fd851f',
        courseCode: 'SE 4471',
        shortName:
          'Software Engineering 4471A/B - SELECTED TOPICS IN SOFTWARE ENGINEERING II',
        courseName:
          'Software Engineering 4471A/B - SELECTED TOPICS IN SOFTWARE ENGINEERING II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268247f7e10015fd851f',
      },
      {
        _id: '60cc268347f7e10015fd8521',
        courseCode: 'SE 4472',
        shortName: 'Software Engineering 4472A/B - INFORMATION SECURITY',
        courseName: 'Software Engineering 4472A/B - INFORMATION SECURITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268347f7e10015fd8521',
      },
      {
        _id: '60cc268347f7e10015fd8523',
        courseCode: 'SPANISH 1030',
        shortName: 'Spanish 1030 - SPANISH FOR BEGINNERS',
        courseName: 'Spanish 1030 - SPANISH FOR BEGINNERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268347f7e10015fd8523',
      },
      {
        _id: '60cc268347f7e10015fd8525',
        courseCode: 'SPANISH 2102',
        shortName: 'Spanish 2102A/B - MEXICO CITY',
        courseName: 'Spanish 2102A/B - MEXICO CITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268347f7e10015fd8525',
      },
      {
        _id: '60cc268447f7e10015fd8527',
        courseCode: 'SPANISH 2104',
        shortName:
          'Spanish 2104F/G - INTERNATIONAL EXPERIENTIAL LEARNING: HAVANA - 500 YEARS OF HISTORY AND LIFE',
        courseName:
          'Spanish 2104F/G - INTERNATIONAL EXPERIENTIAL LEARNING: HAVANA - 500 YEARS OF HISTORY AND LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268447f7e10015fd8527',
      },
      {
        _id: '60cc268447f7e10015fd8529',
        courseCode: 'SPANISH 2105',
        shortName: 'Spanish 2105F/G/Z - BARCELONA: CULTURE THROUGH THE AGES',
        courseName: 'Spanish 2105F/G/Z - BARCELONA: CULTURE THROUGH THE AGES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268447f7e10015fd8529',
      },
      {
        _id: '60cc268447f7e10015fd852b',
        courseCode: 'SPANISH 2121',
        shortName: 'Spanish 2121A/B - BILINGUALISM: CONTEXT AND COGNITION',
        courseName: 'Spanish 2121A/B - BILINGUALISM: CONTEXT AND COGNITION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268447f7e10015fd852b',
      },
      {
        _id: '60cc268447f7e10015fd852d',
        courseCode: 'SPANISH 2200',
        shortName: 'Spanish 2200 - INTERMEDIATE SPANISH',
        courseName: 'Spanish 2200 - INTERMEDIATE SPANISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268447f7e10015fd852d',
      },
      {
        _id: '60cc268547f7e10015fd852f',
        courseCode: 'SPANISH 2214',
        shortName:
          'Spanish 2214A/B - COMPARATIVE GRAMMAR OF ENGLISH AND SPANISH',
        courseName:
          'Spanish 2214A/B - COMPARATIVE GRAMMAR OF ENGLISH AND SPANISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268547f7e10015fd852f',
      },
      {
        _id: '60cc268547f7e10015fd8531',
        courseCode: 'SPANISH 2215',
        shortName: 'Spanish 2215F/G - EXPLORING HISPANIC CULTURES I',
        courseName: 'Spanish 2215F/G - EXPLORING HISPANIC CULTURES I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268547f7e10015fd8531',
      },
      {
        _id: '60cc268547f7e10015fd8533',
        courseCode: 'SPANISH 2216',
        shortName: 'Spanish 2216F/G - EXPLORING HISPANIC CULTURES II',
        courseName: 'Spanish 2216F/G - EXPLORING HISPANIC CULTURES II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268547f7e10015fd8533',
      },
      {
        _id: '60cc268547f7e10015fd8535',
        courseCode: 'SPANISH 2220',
        shortName: 'Spanish 2220A/B - SPANISH CONVERSATION',
        courseName: 'Spanish 2220A/B - SPANISH CONVERSATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268547f7e10015fd8535',
      },
      {
        _id: '60cc268647f7e10015fd8537',
        courseCode: 'SPANISH 2500',
        shortName:
          'Spanish 2500F/G - BRIDGING CLASSROOM AND COMMUNITY: LANGUAGES AND CULTURES IN ACTION',
        courseName:
          'Spanish 2500F/G - BRIDGING CLASSROOM AND COMMUNITY: LANGUAGES AND CULTURES IN ACTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268647f7e10015fd8537',
      },
      {
        _id: '60cc268647f7e10015fd8539',
        courseCode: 'SPANISH 2700',
        shortName: 'Spanish 2700F/G - WORLD CULTURES, GLOBAL SCREENS',
        courseName: 'Spanish 2700F/G - WORLD CULTURES, GLOBAL SCREENS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268647f7e10015fd8539',
      },
      {
        _id: '60cc268647f7e10015fd853b',
        courseCode: 'SPANISH 2901',
        shortName:
          'Spanish 2901A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2901A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268647f7e10015fd853b',
      },
      {
        _id: '60cc268647f7e10015fd853d',
        courseCode: 'SPANISH 2902',
        shortName:
          'Spanish 2902A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2902A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268647f7e10015fd853d',
      },
      {
        _id: '60cc268747f7e10015fd853f',
        courseCode: 'SPANISH 2903',
        shortName:
          'Spanish 2903A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2903A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268747f7e10015fd853f',
      },
      {
        _id: '60cc268747f7e10015fd8541',
        courseCode: 'SPANISH 2904',
        shortName:
          'Spanish 2904A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2904A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268747f7e10015fd8541',
      },
      {
        _id: '60cc268747f7e10015fd8543',
        courseCode: 'SPANISH 2905',
        shortName:
          'Spanish 2905A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2905A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268747f7e10015fd8543',
      },
      {
        _id: '60cc268747f7e10015fd8545',
        courseCode: 'SPANISH 2908',
        shortName:
          'Spanish 2908A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2908A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268747f7e10015fd8545',
      },
      {
        _id: '60cc268747f7e10015fd8547',
        courseCode: 'SPANISH 2911',
        shortName:
          'Spanish 2911A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 2911A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268747f7e10015fd8547',
      },
      {
        _id: '60cc268847f7e10015fd8549',
        courseCode: 'SPANISH 2956',
        shortName:
          'Spanish 2956A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND LINGUISTICS',
        courseName:
          'Spanish 2956A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268847f7e10015fd8549',
      },
      {
        _id: '60cc268847f7e10015fd854b',
        courseCode: 'SPANISH 2957',
        shortName:
          'Spanish 2957A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND LINGUISTICS',
        courseName:
          'Spanish 2957A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND LINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268847f7e10015fd854b',
      },
      {
        _id: '60cc268847f7e10015fd854d',
        courseCode: 'SPANISH 3300',
        shortName: 'Spanish 3300 - ADVANCED SPANISH LANGUAGE',
        courseName: 'Spanish 3300 - ADVANCED SPANISH LANGUAGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268847f7e10015fd854d',
      },
      {
        _id: '60cc268847f7e10015fd854f',
        courseCode: 'SPANISH 3303',
        shortName: 'Spanish 3303A/B - THE STRUCTURE OF SPANISH',
        courseName: 'Spanish 3303A/B - THE STRUCTURE OF SPANISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268847f7e10015fd854f',
      },
      {
        _id: '60cc268947f7e10015fd8551',
        courseCode: 'SPANISH 3314',
        shortName: 'Spanish 3314F/G - HISPANIC SOCIOLINGUISTICS',
        courseName: 'Spanish 3314F/G - HISPANIC SOCIOLINGUISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268947f7e10015fd8551',
      },
      {
        _id: '60cc268947f7e10015fd8553',
        courseCode: 'SPANISH 3317',
        shortName: 'Spanish 3317A/B - THE SPANISH SENTENCE',
        courseName: 'Spanish 3317A/B - THE SPANISH SENTENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268947f7e10015fd8553',
      },
      {
        _id: '60cc268947f7e10015fd8555',
        courseCode: 'SPANISH 3318',
        shortName: 'Spanish 3318A/B - THE SOUNDS OF SPANISH',
        courseName: 'Spanish 3318A/B - THE SOUNDS OF SPANISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268947f7e10015fd8555',
      },
      {
        _id: '60cc268947f7e10015fd8557',
        courseCode: 'SPANISH 3319',
        shortName: 'Spanish 3319A/B - THE ACQUISITION OF SPANISH',
        courseName: 'Spanish 3319A/B - THE ACQUISITION OF SPANISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268947f7e10015fd8557',
      },
      {
        _id: '60cc268a47f7e10015fd8559',
        courseCode: 'SPANISH 3327',
        shortName: 'Spanish 3327A/B - ADVANCED SPANISH CONVERSATION',
        courseName: 'Spanish 3327A/B - ADVANCED SPANISH CONVERSATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268a47f7e10015fd8559',
      },
      {
        _id: '60cc268a47f7e10015fd855b',
        courseCode: 'SPANISH 3333',
        shortName: 'Spanish 3333F/G - POPULAR CULTURE IN LATIN AMERICA',
        courseName: 'Spanish 3333F/G - POPULAR CULTURE IN LATIN AMERICA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268a47f7e10015fd855b',
      },
      {
        _id: '60cc268a47f7e10015fd855d',
        courseCode: 'SPANISH 3350',
        shortName: 'Spanish 3350F/G - WOMEN FILMMAKERS',
        courseName: 'Spanish 3350F/G - WOMEN FILMMAKERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268a47f7e10015fd855d',
      },
      {
        _id: '60cc268a47f7e10015fd855f',
        courseCode: 'SPANISH 3421',
        shortName: 'Spanish 3421F/G - TRANSLATION I:  PRACTICAL TEXTS',
        courseName: 'Spanish 3421F/G - TRANSLATION I:  PRACTICAL TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268a47f7e10015fd855f',
      },
      {
        _id: '60cc268b47f7e10015fd8561',
        courseCode: 'SPANISH 3422',
        shortName:
          'Spanish 3422F/G - TRANSLATION II: BUSINESS TEXTS AND ORAL PRODUCTION',
        courseName:
          'Spanish 3422F/G - TRANSLATION II: BUSINESS TEXTS AND ORAL PRODUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268b47f7e10015fd8561',
      },
      {
        _id: '60cc268b47f7e10015fd8564',
        courseCode: 'SPANISH 3505',
        shortName:
          'Spanish 3505A/B - CREATE & CONNECT! CULTURAL PRODUCTION AND COMMUNITY ENGAGEMENT',
        courseName:
          'Spanish 3505A/B - CREATE & CONNECT! CULTURAL PRODUCTION AND COMMUNITY ENGAGEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268b47f7e10015fd8564',
      },
      {
        _id: '60cc268b47f7e10015fd8566',
        courseCode: 'SPANISH 3511',
        shortName: 'Spanish 3511F/G - HISPANIC CULTURE ON FILM',
        courseName: 'Spanish 3511F/G - HISPANIC CULTURE ON FILM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268b47f7e10015fd8566',
      },
      {
        _id: '60cc268b47f7e10015fd8568',
        courseCode: 'SPANISH 3531',
        shortName: 'Spanish 3531F/G - MYTHS AND LEGENDS OF THE HISPANIC WORLD',
        courseName: 'Spanish 3531F/G - MYTHS AND LEGENDS OF THE HISPANIC WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268b47f7e10015fd8568',
      },
      {
        _id: '60cc268c47f7e10015fd856a',
        courseCode: 'SPANISH 3541',
        shortName: 'Spanish 3541F/G - LITERATURES OF THE HISPANIC WORLDS',
        courseName: 'Spanish 3541F/G - LITERATURES OF THE HISPANIC WORLDS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268c47f7e10015fd856a',
      },
      {
        _id: '60cc268c47f7e10015fd856c',
        courseCode: 'SPANISH 3551',
        shortName: 'Spanish 3551F/G - HUMAN RIGHTS AND THE HISPANIC WORLD',
        courseName: 'Spanish 3551F/G - HUMAN RIGHTS AND THE HISPANIC WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268c47f7e10015fd856c',
      },
      {
        _id: '60cc268c47f7e10015fd856e',
        courseCode: 'SPANISH 3561',
        shortName: 'Spanish 3561F/G - GENDER AND HISPANIC CULTURES',
        courseName: 'Spanish 3561F/G - GENDER AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268c47f7e10015fd856e',
      },
      {
        _id: '60cc268c47f7e10015fd8570',
        courseCode: 'SPANISH 3571',
        shortName: 'Spanish 3571F/G - HISPANIC VISUAL ARTS',
        courseName: 'Spanish 3571F/G - HISPANIC VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268c47f7e10015fd8570',
      },
      {
        _id: '60cc268d47f7e10015fd8572',
        courseCode: 'SPANISH 3591',
        shortName:
          'Spanish 3591F/G - MUSIC, DANCE AND PERFORMANCE IN THE HISPANIC WORLD',
        courseName:
          'Spanish 3591F/G - MUSIC, DANCE AND PERFORMANCE IN THE HISPANIC WORLD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268d47f7e10015fd8572',
      },
      {
        _id: '60cc268d47f7e10015fd8574',
        courseCode: 'SPANISH 3600',
        shortName: 'Spanish 3600F/G/Z - INTERNSHIP IN SPANISH',
        courseName: 'Spanish 3600F/G/Z - INTERNSHIP IN SPANISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268d47f7e10015fd8574',
      },
      {
        _id: '60cc268d47f7e10015fd8576',
        courseCode: 'SPANISH 3901',
        shortName:
          'Spanish 3901F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3901F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268d47f7e10015fd8576',
      },
      {
        _id: '60cc268d47f7e10015fd8578',
        courseCode: 'SPANISH 3902',
        shortName:
          'Spanish 3902F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3902F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268d47f7e10015fd8578',
      },
      {
        _id: '60cc268e47f7e10015fd857a',
        courseCode: 'SPANISH 3903',
        shortName:
          'Spanish 3903F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3903F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268e47f7e10015fd857a',
      },
      {
        _id: '60cc268e47f7e10015fd857c',
        courseCode: 'SPANISH 3904',
        shortName:
          'Spanish 3904F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3904F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268e47f7e10015fd857c',
      },
      {
        _id: '60cc268e47f7e10015fd857e',
        courseCode: 'SPANISH 3905',
        shortName:
          'Spanish 3905F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3905F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268e47f7e10015fd857e',
      },
      {
        _id: '60cc268e47f7e10015fd8580',
        courseCode: 'SPANISH 3906',
        shortName:
          'Spanish 3906A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3906A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268e47f7e10015fd8580',
      },
      {
        _id: '60cc268f47f7e10015fd8582',
        courseCode: 'SPANISH 3907',
        shortName:
          'Spanish 3907A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3907A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268f47f7e10015fd8582',
      },
      {
        _id: '60cc268f47f7e10015fd8584',
        courseCode: 'SPANISH 3908',
        shortName:
          'Spanish 3908A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3908A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268f47f7e10015fd8584',
      },
      {
        _id: '60cc268f47f7e10015fd8586',
        courseCode: 'SPANISH 3909',
        shortName:
          'Spanish 3909A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3909A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268f47f7e10015fd8586',
      },
      {
        _id: '60cc268f47f7e10015fd8588',
        courseCode: 'SPANISH 3910',
        shortName:
          'Spanish 3910A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 3910A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268f47f7e10015fd8588',
      },
      {
        _id: '60cc268f47f7e10015fd858a',
        courseCode: 'SPANISH 4410',
        shortName: 'Spanish 4410E - HONOURS THESIS',
        courseName: 'Spanish 4410E - HONOURS THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc268f47f7e10015fd858a',
      },
      {
        _id: '60cc269047f7e10015fd858c',
        courseCode: 'SPANISH 4412',
        shortName: 'Spanish 4412F/G - SPANISH IN CONTACT',
        courseName: 'Spanish 4412F/G - SPANISH IN CONTACT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269047f7e10015fd858c',
      },
      {
        _id: '60cc269047f7e10015fd858e',
        courseCode: 'SPANISH 4415',
        shortName: 'Spanish 4415A/B - THE SPANISH WORD',
        courseName: 'Spanish 4415A/B - THE SPANISH WORD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269047f7e10015fd858e',
      },
      {
        _id: '60cc269047f7e10015fd8590',
        courseCode: 'SPANISH 4500',
        shortName: 'Spanish 4500F/G - SENIOR RESEARCH PROJECT',
        courseName: 'Spanish 4500F/G - SENIOR RESEARCH PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269047f7e10015fd8590',
      },
      {
        _id: '60cc269047f7e10015fd8592',
        courseCode: 'SPANISH 4531',
        shortName: 'Spanish 4531F/G - HISPANIC MASTERS AND MASTERPIECES',
        courseName: 'Spanish 4531F/G - HISPANIC MASTERS AND MASTERPIECES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269047f7e10015fd8592',
      },
      {
        _id: '60cc269147f7e10015fd8594',
        courseCode: 'SPANISH 4532',
        shortName: 'Spanish 4532F/G - HISPANIC MASTERS AND MASTERPIECES',
        courseName: 'Spanish 4532F/G - HISPANIC MASTERS AND MASTERPIECES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269147f7e10015fd8594',
      },
      {
        _id: '60cc269147f7e10015fd8596',
        courseCode: 'SPANISH 4540',
        shortName: 'Spanish 4540F/G - HISPANIC MASTERS AND MASTERPIECES',
        courseName: 'Spanish 4540F/G - HISPANIC MASTERS AND MASTERPIECES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269147f7e10015fd8596',
      },
      {
        _id: '60cc269147f7e10015fd8598',
        courseCode: 'SPANISH 4901',
        shortName:
          'Spanish 4901F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4901F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269147f7e10015fd8598',
      },
      {
        _id: '60cc269147f7e10015fd859a',
        courseCode: 'SPANISH 4902',
        shortName:
          'Spanish 4902F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4902F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269147f7e10015fd859a',
      },
      {
        _id: '60cc269247f7e10015fd859c',
        courseCode: 'SPANISH 4903',
        shortName:
          'Spanish 4903F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4903F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269247f7e10015fd859c',
      },
      {
        _id: '60cc269247f7e10015fd859e',
        courseCode: 'SPANISH 4904',
        shortName:
          'Spanish 4904F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4904F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269247f7e10015fd859e',
      },
      {
        _id: '60cc269247f7e10015fd85a0',
        courseCode: 'SPANISH 4905',
        shortName:
          'Spanish 4905F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4905F/G - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269247f7e10015fd85a0',
      },
      {
        _id: '60cc269247f7e10015fd85a2',
        courseCode: 'SPANISH 4906',
        shortName:
          'Spanish 4906A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4906A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269247f7e10015fd85a2',
      },
      {
        _id: '60cc269247f7e10015fd85a4',
        courseCode: 'SPANISH 4907',
        shortName:
          'Spanish 4907A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4907A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269247f7e10015fd85a4',
      },
      {
        _id: '60cc269347f7e10015fd85a6',
        courseCode: 'SPANISH 4908',
        shortName:
          'Spanish 4908A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4908A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269347f7e10015fd85a6',
      },
      {
        _id: '60cc269347f7e10015fd85a8',
        courseCode: 'SPANISH 4909',
        shortName:
          'Spanish 4909A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4909A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269347f7e10015fd85a8',
      },
      {
        _id: '60cc269347f7e10015fd85aa',
        courseCode: 'SPANISH 4910',
        shortName:
          'Spanish 4910A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4910A/B - SPECIAL TOPICS IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269347f7e10015fd85aa',
      },
      {
        _id: '60cc269347f7e10015fd85ac',
        courseCode: 'SPANISH 4961',
        shortName:
          'Spanish 4961F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4961F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269347f7e10015fd85ac',
      },
      {
        _id: '60cc269447f7e10015fd85ae',
        courseCode: 'SPANISH 4962',
        shortName:
          'Spanish 4962F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4962F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269447f7e10015fd85ae',
      },
      {
        _id: '60cc269447f7e10015fd85b0',
        courseCode: 'SPANISH 4963',
        shortName:
          'Spanish 4963F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4963F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269447f7e10015fd85b0',
      },
      {
        _id: '60cc269447f7e10015fd85b2',
        courseCode: 'SPANISH 4964',
        shortName:
          'Spanish 4964F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4964F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269447f7e10015fd85b2',
      },
      {
        _id: '60cc269447f7e10015fd85b4',
        courseCode: 'SPANISH 4965',
        shortName:
          'Spanish 4965F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        courseName:
          'Spanish 4965F/G - DIRECTED STUDIES IN SPANISH LANGUAGE AND HISPANIC CULTURES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269447f7e10015fd85b4',
      },
      {
        _id: '60cc269547f7e10015fd85b6',
        courseCode: 'SPEECH 0005',
        shortName: 'Speech 0005W/X - INTRODUCTION TO SPEECH',
        courseName: 'Speech 0005W/X - INTRODUCTION TO SPEECH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269547f7e10015fd85b6',
      },
      {
        _id: '60cc269547f7e10015fd85b8',
        courseCode: 'SPEECH 2001',
        shortName: 'Speech 2001 - THE MAJOR FORMS OF ORAL DISCOURSE',
        courseName: 'Speech 2001 - THE MAJOR FORMS OF ORAL DISCOURSE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269547f7e10015fd85b8',
      },
      {
        _id: '60cc269547f7e10015fd85ba',
        courseCode: 'STATS 1023',
        shortName: 'Statistical Sciences 1023A/B - STATISTICAL CONCEPTS',
        courseName: 'Statistical Sciences 1023A/B - STATISTICAL CONCEPTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269547f7e10015fd85ba',
      },
      {
        _id: '60cc269647f7e10015fd85bc',
        courseCode: 'STATS 1024',
        shortName: 'Statistical Sciences 1024A/B - INTRODUCTION TO STATISTICS',
        courseName: 'Statistical Sciences 1024A/B - INTRODUCTION TO STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269647f7e10015fd85bc',
      },
      {
        _id: '60cc269647f7e10015fd85be',
        courseCode: 'STATS 2035',
        shortName:
          'Statistical Sciences 2035 - STATISTICS FOR BUSINESS AND SOCIAL SCIENCES',
        courseName:
          'Statistical Sciences 2035 - STATISTICS FOR BUSINESS AND SOCIAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        numResources: 21,
        id: '60cc269647f7e10015fd85be',
      },
      {
        _id: '60cc269647f7e10015fd85c0',
        courseCode: 'STATS 2037',
        shortName: 'Statistical Sciences 2037A/B - STATISTICS FOR HEALTH',
        courseName: 'Statistical Sciences 2037A/B - STATISTICS FOR HEALTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269647f7e10015fd85c0',
      },
      {
        _id: '60cc269747f7e10015fd85c2',
        courseCode: 'STATS 2141',
        shortName:
          'Statistical Sciences 2141A/B - APPLIED PROBABILITY AND STATISTICS FOR ENGINEERS',
        courseName:
          'Statistical Sciences 2141A/B - APPLIED PROBABILITY AND STATISTICS FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269747f7e10015fd85c2',
      },
      {
        _id: '60cc269747f7e10015fd85c4',
        courseCode: 'STATS 2143',
        shortName:
          'Statistical Sciences 2143A/B - APPLIED STATISTICS AND DATA ANLYSIS FOR ENGINEERS',
        courseName:
          'Statistical Sciences 2143A/B - APPLIED STATISTICS AND DATA ANLYSIS FOR ENGINEERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269747f7e10015fd85c4',
      },
      {
        _id: '60cc269747f7e10015fd85c6',
        courseCode: 'STATS 2244',
        shortName: 'Statistical Sciences 2244A/B - STATISTICS FOR SCIENCE',
        courseName: 'Statistical Sciences 2244A/B - STATISTICS FOR SCIENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269747f7e10015fd85c6',
      },
      {
        _id: '60cc269747f7e10015fd85c8',
        courseCode: 'STATS 2503',
        shortName:
          'Statistical Sciences 2503A/B - ADVANCED MATHEMATICS FOR STATISTICAL APPLICATIONS',
        courseName:
          'Statistical Sciences 2503A/B - ADVANCED MATHEMATICS FOR STATISTICAL APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269747f7e10015fd85c8',
      },
      {
        _id: '60cc269847f7e10015fd85ca',
        courseCode: 'STATS 2857',
        shortName:
          'Statistical Sciences 2857A/B - PROBABILITY AND STATISTICS I',
        courseName:
          'Statistical Sciences 2857A/B - PROBABILITY AND STATISTICS I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269847f7e10015fd85ca',
      },
      {
        _id: '60cc269847f7e10015fd85cc',
        courseCode: 'STATS 2858',
        shortName:
          'Statistical Sciences 2858A/B - PROBABILITY AND STATISTICS II',
        courseName:
          'Statistical Sciences 2858A/B - PROBABILITY AND STATISTICS II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269847f7e10015fd85cc',
      },
      {
        _id: '60cc269847f7e10015fd85ce',
        courseCode: 'STATS 2864',
        shortName: 'Statistical Sciences 2864A/B - STATISTICAL PROGRAMMING',
        courseName: 'Statistical Sciences 2864A/B - STATISTICAL PROGRAMMING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269847f7e10015fd85ce',
      },
      {
        _id: '60cc269847f7e10015fd85d0',
        courseCode: 'STATS 3657',
        shortName: 'Statistical Sciences 3657A/B - INTERMEDIATE PROBABILITY',
        courseName: 'Statistical Sciences 3657A/B - INTERMEDIATE PROBABILITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269847f7e10015fd85d0',
      },
      {
        _id: '60cc269947f7e10015fd85d2',
        courseCode: 'STATS 3843',
        shortName:
          'Statistical Sciences 3843A/B - INTRODUCTION TO STUDY DESIGN',
        courseName:
          'Statistical Sciences 3843A/B - INTRODUCTION TO STUDY DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269947f7e10015fd85d2',
      },
      {
        _id: '60cc269947f7e10015fd85d4',
        courseCode: 'STATS 3858',
        shortName: 'Statistical Sciences 3858A/B - MATHEMATICAL STATISTICS',
        courseName: 'Statistical Sciences 3858A/B - MATHEMATICAL STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269947f7e10015fd85d4',
      },
      {
        _id: '60cc269947f7e10015fd85d6',
        courseCode: 'STATS 3859',
        shortName: 'Statistical Sciences 3859A/B - REGRESSION',
        courseName: 'Statistical Sciences 3859A/B - REGRESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269947f7e10015fd85d6',
      },
      {
        _id: '60cc269947f7e10015fd85d8',
        courseCode: 'STATS 3860',
        shortName: 'Statistical Sciences 3860A/B - GENERALIZED LINEAR MODELS',
        courseName: 'Statistical Sciences 3860A/B - GENERALIZED LINEAR MODELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269947f7e10015fd85d8',
      },
      {
        _id: '60cc269a47f7e10015fd85da',
        courseCode: 'STATS 3869',
        shortName: 'Statistical Sciences 3869A/B - APPLIED LINEAR MODELS',
        courseName: 'Statistical Sciences 3869A/B - APPLIED LINEAR MODELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269a47f7e10015fd85da',
      },
      {
        _id: '60cc269a47f7e10015fd85dc',
        courseCode: 'STATS 4654',
        shortName:
          'Statistical Sciences 4654A/B - MARKOV CHAINS WITH APPLICATIONS',
        courseName:
          'Statistical Sciences 4654A/B - MARKOV CHAINS WITH APPLICATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269a47f7e10015fd85dc',
      },
      {
        _id: '60cc269a47f7e10015fd85de',
        courseCode: 'STATS 4844',
        shortName: 'Statistical Sciences 4844A/B - DATA ANALYTICS CONSULTING',
        courseName: 'Statistical Sciences 4844A/B - DATA ANALYTICS CONSULTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269a47f7e10015fd85de',
      },
      {
        _id: '60cc269a47f7e10015fd85e0',
        courseCode: 'STATS 4846',
        shortName: 'Statistical Sciences 4846A/B - EXPERIMENTAL DESIGN',
        courseName: 'Statistical Sciences 4846A/B - EXPERIMENTAL DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269a47f7e10015fd85e0',
      },
      {
        _id: '60cc269b47f7e10015fd85e2',
        courseCode: 'STATS 4850',
        shortName: 'Statistical Sciences 4850F/G - ADVANCED DATA ANALYSIS',
        courseName: 'Statistical Sciences 4850F/G - ADVANCED DATA ANALYSIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269b47f7e10015fd85e2',
      },
      {
        _id: '60cc269b47f7e10015fd85e4',
        courseCode: 'STATS 4853',
        shortName: 'Statistical Sciences 4853A/B - SAMPLING THEORY AND METHODS',
        courseName:
          'Statistical Sciences 4853A/B - SAMPLING THEORY AND METHODS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269b47f7e10015fd85e4',
      },
      {
        _id: '60cc269b47f7e10015fd85e6',
        courseCode: 'STATS 4860',
        shortName: 'Statistical Sciences 4860A/B - ADVANCED REGRESSION',
        courseName: 'Statistical Sciences 4860A/B - ADVANCED REGRESSION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269b47f7e10015fd85e6',
      },
      {
        _id: '60cc269b47f7e10015fd85e8',
        courseCode: 'STATS 4861',
        shortName: 'Statistical Sciences 4861A/B - TIME SERIES',
        courseName: 'Statistical Sciences 4861A/B - TIME SERIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269b47f7e10015fd85e8',
      },
      {
        _id: '60cc269c47f7e10015fd85ea',
        courseCode: 'STATS 4864',
        shortName:
          'Statistical Sciences 4864A/B - ADVANCED STATISTICAL COMPUTING',
        courseName:
          'Statistical Sciences 4864A/B - ADVANCED STATISTICAL COMPUTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269c47f7e10015fd85ea',
      },
      {
        _id: '60cc269c47f7e10015fd85ec',
        courseCode: 'STATS 4930',
        shortName:
          'Statistical Sciences 4930A/B - SELECTED TOPICS IN STATISTICS',
        courseName:
          'Statistical Sciences 4930A/B - SELECTED TOPICS IN STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269c47f7e10015fd85ec',
      },
      {
        _id: '60cc269c47f7e10015fd85ee',
        courseCode: 'STATS 4940',
        shortName:
          'Statistical Sciences 4940F/G - SELECTED TOPICS IN STATISTICS',
        courseName:
          'Statistical Sciences 4940F/G - SELECTED TOPICS IN STATISTICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269c47f7e10015fd85ee',
      },
      {
        _id: '60cc269c47f7e10015fd85f1',
        courseCode: 'STATS 4999',
        shortName:
          'Statistical Sciences 4999F/G/Z - PROJECT IN STATISTICAL SCIENCES',
        courseName:
          'Statistical Sciences 4999F/G/Z - PROJECT IN STATISTICAL SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269c47f7e10015fd85f1',
      },
      {
        _id: '60cc269d47f7e10015fd85f3',
        courseCode: 'SA 1601',
        shortName: 'Studio Art 1601 - FOUNDATIONS OF VISUAL ARTS',
        courseName: 'Studio Art 1601 - FOUNDATIONS OF VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269d47f7e10015fd85f3',
      },
      {
        _id: '60cc269d47f7e10015fd85f5',
        courseCode: 'SA 1605',
        shortName: 'Studio Art 1605 - ADVANCED VISUAL ARTS FOUNDATION STUDIO',
        courseName: 'Studio Art 1605 - ADVANCED VISUAL ARTS FOUNDATION STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269d47f7e10015fd85f5',
      },
      {
        _id: '60cc269d47f7e10015fd85f7',
        courseCode: 'SA 2500',
        shortName: 'Studio Art 2500A/B - ART NOW! I',
        courseName: 'Studio Art 2500A/B - ART NOW! I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269d47f7e10015fd85f7',
      },
      {
        _id: '60cc269d47f7e10015fd85f9',
        courseCode: 'SA 2502',
        shortName: 'Studio Art 2502A/B - ART NOW! II',
        courseName: 'Studio Art 2502A/B - ART NOW! II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269d47f7e10015fd85f9',
      },
      {
        _id: '60cc269e47f7e10015fd85fb',
        courseCode: 'SA 2504',
        shortName: 'Studio Art 2504Y - ART NOW!',
        courseName: 'Studio Art 2504Y - ART NOW!',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269e47f7e10015fd85fb',
      },
      {
        _id: '60cc269e47f7e10015fd85fd',
        courseCode: 'SA 2508',
        shortName: 'Studio Art 2508A/B - EXPLORATIONS 1: IMAGE EXPLORATIONS',
        courseName: 'Studio Art 2508A/B - EXPLORATIONS 1: IMAGE EXPLORATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269e47f7e10015fd85fd',
      },
      {
        _id: '60cc269e47f7e10015fd85ff',
        courseCode: 'SA 2510',
        shortName: 'Studio Art 2510A/B - EXPLORATIONS 2: DRAWING EXPLORATIONS',
        courseName: 'Studio Art 2510A/B - EXPLORATIONS 2: DRAWING EXPLORATIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269e47f7e10015fd85ff',
      },
      {
        _id: '60cc269e47f7e10015fd8601',
        courseCode: 'SA 2560',
        shortName:
          'Studio Art 2560A/B - EXPLORATIONS 3: MAKING ART WITH ACCESSIBLE TECHNOLOGY',
        courseName:
          'Studio Art 2560A/B - EXPLORATIONS 3: MAKING ART WITH ACCESSIBLE TECHNOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269e47f7e10015fd8601',
      },
      {
        _id: '60cc269f47f7e10015fd8603',
        courseCode: 'SA 2590',
        shortName: 'Studio Art 2590A/B - SPECIAL TOPICS IN VISUAL ARTS',
        courseName: 'Studio Art 2590A/B - SPECIAL TOPICS IN VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269f47f7e10015fd8603',
      },
      {
        _id: '60cc269f47f7e10015fd8605',
        courseCode: 'SA 2593',
        shortName: 'Studio Art 2593 - SPECIAL TOPICS IN VISUAL ARTS',
        courseName: 'Studio Art 2593 - SPECIAL TOPICS IN VISUAL ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269f47f7e10015fd8605',
      },
      {
        _id: '60cc269f47f7e10015fd8607',
        courseCode: 'SA 2600',
        shortName:
          'Studio Art 2600F/G - THEORIES AND PRACTICES OF ART HISTORY AND VISUAL CULTURE',
        courseName:
          'Studio Art 2600F/G - THEORIES AND PRACTICES OF ART HISTORY AND VISUAL CULTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc269f47f7e10015fd8607',
      },
      {
        _id: '60cc26a047f7e10015fd8609',
        courseCode: 'SA 2602',
        shortName: 'Studio Art 2602A/B/Y - STUDIO SEMINAR I',
        courseName: 'Studio Art 2602A/B/Y - STUDIO SEMINAR I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a047f7e10015fd8609',
      },
      {
        _id: '60cc26a047f7e10015fd860b',
        courseCode: 'SA 2610',
        shortName: 'Studio Art 2610A/B - INTRODUCTION TO DRAWING',
        courseName: 'Studio Art 2610A/B - INTRODUCTION TO DRAWING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a047f7e10015fd860b',
      },
      {
        _id: '60cc26a047f7e10015fd860d',
        courseCode: 'SA 2620',
        shortName: 'Studio Art 2620A/B - INTRODUCTION TO PAINTING',
        courseName: 'Studio Art 2620A/B - INTRODUCTION TO PAINTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a047f7e10015fd860d',
      },
      {
        _id: '60cc26a047f7e10015fd860f',
        courseCode: 'SA 2621',
        shortName: 'Studio Art 2621 - INTRODUCTION TO PAINTING',
        courseName: 'Studio Art 2621 - INTRODUCTION TO PAINTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a047f7e10015fd860f',
      },
      {
        _id: '60cc26a147f7e10015fd8611',
        courseCode: 'SA 2630',
        shortName: 'Studio Art 2630A/B - INTRODUCTION TO PRINT MEDIA',
        courseName: 'Studio Art 2630A/B - INTRODUCTION TO PRINT MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a147f7e10015fd8611',
      },
      {
        _id: '60cc26a147f7e10015fd8613',
        courseCode: 'SA 2640',
        shortName: 'Studio Art 2640A/B - INTRODUCTION TO SPATIAL PRACTICES',
        courseName: 'Studio Art 2640A/B - INTRODUCTION TO SPATIAL PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a147f7e10015fd8613',
      },
      {
        _id: '60cc26a147f7e10015fd8615',
        courseCode: 'SA 2642',
        shortName:
          'Studio Art 2642A/B - INTRODUCTION TO SCULPTURE AND INSTALLATION',
        courseName:
          'Studio Art 2642A/B - INTRODUCTION TO SCULPTURE AND INSTALLATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a147f7e10015fd8615',
      },
      {
        _id: '60cc26a147f7e10015fd8617',
        courseCode: 'SA 2643',
        shortName:
          'Studio Art 2643 - INTRODUCTION TO SCULPTURE AND INSTALLATION',
        courseName:
          'Studio Art 2643 - INTRODUCTION TO SCULPTURE AND INSTALLATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a147f7e10015fd8617',
      },
      {
        _id: '60cc26a247f7e10015fd8619',
        courseCode: 'SA 2650',
        shortName: 'Studio Art 2650A/B - INTRODUCTION TO PHOTOGRAPHY',
        courseName: 'Studio Art 2650A/B - INTRODUCTION TO PHOTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a247f7e10015fd8619',
      },
      {
        _id: '60cc26a247f7e10015fd861b',
        courseCode: 'SA 2652',
        shortName: 'Studio Art 2652A/B/Y - INTRODUCTION TO DIGITAL PHOTOGRAPHY',
        courseName:
          'Studio Art 2652A/B/Y - INTRODUCTION TO DIGITAL PHOTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a247f7e10015fd861b',
      },
      {
        _id: '60cc26a247f7e10015fd861d',
        courseCode: 'SA 2660',
        shortName:
          'Studio Art 2660A/B - INTRODUCTION TO TIME-BASED MEDIA ART: SOUND AND PERFORMANCE',
        courseName:
          'Studio Art 2660A/B - INTRODUCTION TO TIME-BASED MEDIA ART: SOUND AND PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a247f7e10015fd861d',
      },
      {
        _id: '60cc26a347f7e10015fd861f',
        courseCode: 'SA 2662',
        shortName:
          'Studio Art 2662A/B - INTRODUCTION TO TIME-BASED MEDIA ART: VIDEO AND ANIMATION',
        courseName:
          'Studio Art 2662A/B - INTRODUCTION TO TIME-BASED MEDIA ART: VIDEO AND ANIMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a347f7e10015fd861f',
      },
      {
        _id: '60cc26a347f7e10015fd8621',
        courseCode: 'SA 2663',
        shortName: 'Studio Art 2663 - INTRODUCTION TO TIME-BASED MEDIA ART',
        courseName: 'Studio Art 2663 - INTRODUCTION TO TIME-BASED MEDIA ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a347f7e10015fd8621',
      },
      {
        _id: '60cc26a347f7e10015fd8623',
        courseCode: 'SA 2690',
        shortName: 'Studio Art 2690A/B/Y - SPECIAL TOPICS IN STUDIO ARTS',
        courseName: 'Studio Art 2690A/B/Y - SPECIAL TOPICS IN STUDIO ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a347f7e10015fd8623',
      },
      {
        _id: '60cc26a347f7e10015fd8625',
        courseCode: 'SA 2691',
        shortName: 'Studio Art 2691 - SPECIAL TOPICS IN STUDIO ARTS',
        courseName: 'Studio Art 2691 - SPECIAL TOPICS IN STUDIO ARTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a347f7e10015fd8625',
      },
      {
        _id: '60cc26a447f7e10015fd8627',
        courseCode: 'SA 2692',
        shortName: 'Studio Art 2692A/B/Y - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 2692A/B/Y - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a447f7e10015fd8627',
      },
      {
        _id: '60cc26a447f7e10015fd8629',
        courseCode: 'SA 2693',
        shortName: 'Studio Art 2693 - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 2693 - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a447f7e10015fd8629',
      },
      {
        _id: '60cc26a447f7e10015fd862b',
        courseCode: 'SA 3502',
        shortName: 'Studio Art 3502F/G - ART HISTORY AND STUDIO IN DIALOGUE',
        courseName: 'Studio Art 3502F/G - ART HISTORY AND STUDIO IN DIALOGUE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a447f7e10015fd862b',
      },
      {
        _id: '60cc26a447f7e10015fd862d',
        courseCode: 'SA 3602',
        shortName: 'Studio Art 3602A/B/Y - STUDIO SEMINAR II',
        courseName: 'Studio Art 3602A/B/Y - STUDIO SEMINAR II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a447f7e10015fd862d',
      },
      {
        _id: '60cc26a447f7e10015fd862f',
        courseCode: 'SA 3606',
        shortName: 'Studio Art 3606A/B - INTERDISCIPLINARY OPEN STUDIO I',
        courseName: 'Studio Art 3606A/B - INTERDISCIPLINARY OPEN STUDIO I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a447f7e10015fd862f',
      },
      {
        _id: '60cc26a547f7e10015fd8631',
        courseCode: 'SA 3608',
        shortName: 'Studio Art 3608A/B - INTERDISCIPLINARY OPEN STUDIO II',
        courseName: 'Studio Art 3608A/B - INTERDISCIPLINARY OPEN STUDIO II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a547f7e10015fd8631',
      },
      {
        _id: '60cc26a547f7e10015fd8633',
        courseCode: 'SA 3611',
        shortName: 'Studio Art 3611 - DRAWING',
        courseName: 'Studio Art 3611 - DRAWING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a547f7e10015fd8633',
      },
      {
        _id: '60cc26a547f7e10015fd8635',
        courseCode: 'SA 3623',
        shortName: 'Studio Art 3623 - PAINTING',
        courseName: 'Studio Art 3623 - PAINTING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a547f7e10015fd8635',
      },
      {
        _id: '60cc26a547f7e10015fd8637',
        courseCode: 'SA 3630',
        shortName: 'Studio Art 3630A/B - PRINT MEDIA I',
        courseName: 'Studio Art 3630A/B - PRINT MEDIA I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a547f7e10015fd8637',
      },
      {
        _id: '60cc26a647f7e10015fd8639',
        courseCode: 'SA 3632',
        shortName: 'Studio Art 3632A/B - PRINT MEDIA II',
        courseName: 'Studio Art 3632A/B - PRINT MEDIA II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a647f7e10015fd8639',
      },
      {
        _id: '60cc26a647f7e10015fd863b',
        courseCode: 'SA 3633',
        shortName: 'Studio Art 3633 - PRINT MEDIA',
        courseName: 'Studio Art 3633 - PRINT MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a647f7e10015fd863b',
      },
      {
        _id: '60cc26a647f7e10015fd863d',
        courseCode: 'SA 3640',
        shortName: 'Studio Art 3640A/B - SCULPTURE I: ALTERNATIVE MATERIALS',
        courseName: 'Studio Art 3640A/B - SCULPTURE I: ALTERNATIVE MATERIALS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a647f7e10015fd863d',
      },
      {
        _id: '60cc26a647f7e10015fd863f',
        courseCode: 'SA 3642',
        shortName:
          'Studio Art 3642A/B - SCULPTURE II: INSTALLATION AND PERFORMANCE',
        courseName:
          'Studio Art 3642A/B - SCULPTURE II: INSTALLATION AND PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a647f7e10015fd863f',
      },
      {
        _id: '60cc26a747f7e10015fd8641',
        courseCode: 'SA 3643',
        shortName: 'Studio Art 3643 - SCULPTURE AND INSTALLATION',
        courseName: 'Studio Art 3643 - SCULPTURE AND INSTALLATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a747f7e10015fd8641',
      },
      {
        _id: '60cc26a747f7e10015fd8643',
        courseCode: 'SA 3650',
        shortName:
          'Studio Art 3650A/B - PHOTOGRAPHY I: OUTDOORS AND ARCHITECTURE',
        courseName:
          'Studio Art 3650A/B - PHOTOGRAPHY I: OUTDOORS AND ARCHITECTURE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a747f7e10015fd8643',
      },
      {
        _id: '60cc26a747f7e10015fd8645',
        courseCode: 'SA 3652',
        shortName: 'Studio Art 3652A/B - PHOTOGRAPHY II: STUDIO AND INTERIORS',
        courseName: 'Studio Art 3652A/B - PHOTOGRAPHY II: STUDIO AND INTERIORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a747f7e10015fd8645',
      },
      {
        _id: '60cc26a747f7e10015fd8647',
        courseCode: 'SA 3653',
        shortName: 'Studio Art 3653 - PHOTOGRAPHY',
        courseName: 'Studio Art 3653 - PHOTOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a747f7e10015fd8647',
      },
      {
        _id: '60cc26a847f7e10015fd8649',
        courseCode: 'SA 3660',
        shortName: 'Studio Art 3660A/B - TIME-BASED MEDIA ART: SOUND',
        courseName: 'Studio Art 3660A/B - TIME-BASED MEDIA ART: SOUND',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a847f7e10015fd8649',
      },
      {
        _id: '60cc26a847f7e10015fd864b',
        courseCode: 'SA 3662',
        shortName: 'Studio Art 3662A/B - TIME-BASED MEDIA ART: VIDEO',
        courseName: 'Studio Art 3662A/B - TIME-BASED MEDIA ART: VIDEO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a847f7e10015fd864b',
      },
      {
        _id: '60cc26a847f7e10015fd864d',
        courseCode: 'SA 3663',
        shortName: 'Studio Art 3663 - TIME-BASED MEDIA ART',
        courseName: 'Studio Art 3663 - TIME-BASED MEDIA ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a847f7e10015fd864d',
      },
      {
        _id: '60cc26a847f7e10015fd864f',
        courseCode: 'SA 3664',
        shortName: 'Studio Art 3664A/B - TIME-BASED MEDIA ART',
        courseName: 'Studio Art 3664A/B - TIME-BASED MEDIA ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a847f7e10015fd864f',
      },
      {
        _id: '60cc26a947f7e10015fd8651',
        courseCode: 'SA 3686',
        shortName: 'Studio Art 3686F/G - PROJECT-BASED INTERNSHIP',
        courseName: 'Studio Art 3686F/G - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a947f7e10015fd8651',
      },
      {
        _id: '60cc26a947f7e10015fd8653',
        courseCode: 'SA 3689',
        shortName: 'Studio Art 3689E - PROJECT-BASED INTERNSHIP',
        courseName: 'Studio Art 3689E - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a947f7e10015fd8653',
      },
      {
        _id: '60cc26a947f7e10015fd8655',
        courseCode: 'SA 3690',
        shortName: 'Studio Art 3690A/B/Y - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 3690A/B/Y - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a947f7e10015fd8655',
      },
      {
        _id: '60cc26a947f7e10015fd8657',
        courseCode: 'SA 3691',
        shortName: 'Studio Art 3691 - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 3691 - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26a947f7e10015fd8657',
      },
      {
        _id: '60cc26aa47f7e10015fd8659',
        courseCode: 'SA 3692',
        shortName: 'Studio Art 3692A/B/Y - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 3692A/B/Y - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26aa47f7e10015fd8659',
      },
      {
        _id: '60cc26aa47f7e10015fd865b',
        courseCode: 'SA 3693',
        shortName: 'Studio Art 3693 - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 3693 - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26aa47f7e10015fd865b',
      },
      {
        _id: '60cc26aa47f7e10015fd865d',
        courseCode: 'SA 3694',
        shortName: 'Studio Art 3694A/B - SPECIAL TOPICS IN STUDIO ART',
        courseName: 'Studio Art 3694A/B - SPECIAL TOPICS IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26aa47f7e10015fd865d',
      },
      {
        _id: '60cc26aa47f7e10015fd865f',
        courseCode: 'SA 4601',
        shortName: 'Studio Art 4601 - ADVANCED 4TH- YEAR STUDIO SEMINAR',
        courseName: 'Studio Art 4601 - ADVANCED 4TH- YEAR STUDIO SEMINAR',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26aa47f7e10015fd865f',
      },
      {
        _id: '60cc26ab47f7e10015fd8661',
        courseCode: 'SA 4603',
        shortName: 'Studio Art 4603 - EXPERIENTIAL LEARNING',
        courseName: 'Studio Art 4603 - EXPERIENTIAL LEARNING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ab47f7e10015fd8661',
      },
      {
        _id: '60cc26ab47f7e10015fd8663',
        courseCode: 'SA 4605',
        shortName: 'Studio Art 4605 - PRACTICUM',
        courseName: 'Studio Art 4605 - PRACTICUM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ab47f7e10015fd8663',
      },
      {
        _id: '60cc26ab47f7e10015fd8665',
        courseCode: 'SA 4606',
        shortName:
          'Studio Art 4606A/B - ADVANCED INTERDISCIPLINARY OPEN STUDIO I',
        courseName:
          'Studio Art 4606A/B - ADVANCED INTERDISCIPLINARY OPEN STUDIO I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ab47f7e10015fd8665',
      },
      {
        _id: '60cc26ab47f7e10015fd8667',
        courseCode: 'SA 4608',
        shortName:
          'Studio Art 4608A/B - ADVANCED INTERDISCIPLINARY OPEN STUDIO II',
        courseName:
          'Studio Art 4608A/B - ADVANCED INTERDISCIPLINARY OPEN STUDIO II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ab47f7e10015fd8667',
      },
      {
        _id: '60cc26ac47f7e10015fd8669',
        courseCode: 'SA 4630',
        shortName: 'Studio Art 4630A/B - INDEPENDENT PROJECTS IN PRINT MEDIA I',
        courseName:
          'Studio Art 4630A/B - INDEPENDENT PROJECTS IN PRINT MEDIA I',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ac47f7e10015fd8669',
      },
      {
        _id: '60cc26ac47f7e10015fd866b',
        courseCode: 'SA 4632',
        shortName:
          'Studio Art 4632A/B - INDEPENDENT PROJECTS IN PRINT MEDIA II',
        courseName:
          'Studio Art 4632A/B - INDEPENDENT PROJECTS IN PRINT MEDIA II',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ac47f7e10015fd866b',
      },
      {
        _id: '60cc26ac47f7e10015fd866d',
        courseCode: 'SA 4633',
        shortName: 'Studio Art 4633A/B - INDEPENDENT PROJECTS IN PRINT MEDIA',
        courseName: 'Studio Art 4633A/B - INDEPENDENT PROJECTS IN PRINT MEDIA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ac47f7e10015fd866d',
      },
      {
        _id: '60cc26ac47f7e10015fd866f',
        courseCode: 'SA 4682',
        shortName: 'Studio Art 4682A/B - INTERNSHIP IN STUDIO ART',
        courseName: 'Studio Art 4682A/B - INTERNSHIP IN STUDIO ART',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ac47f7e10015fd866f',
      },
      {
        _id: '60cc26ac47f7e10015fd8671',
        courseCode: 'SA 4686',
        shortName: 'Studio Art 4686F/G - PROJECT-BASED INTERNSHIP',
        courseName: 'Studio Art 4686F/G - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ac47f7e10015fd8671',
      },
      {
        _id: '60cc26ad47f7e10015fd8673',
        courseCode: 'SA 4689',
        shortName: 'Studio Art 4689E - PROJECT-BASED INTERNSHIP',
        courseName: 'Studio Art 4689E - PROJECT-BASED INTERNSHIP',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ad47f7e10015fd8673',
      },
      {
        _id: '60cc26ad47f7e10015fd8675',
        courseCode: 'SA 4690',
        shortName: 'Studio Art 4690A/B - SPECIAL PROJECTS IN STUDIO',
        courseName: 'Studio Art 4690A/B - SPECIAL PROJECTS IN STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ad47f7e10015fd8675',
      },
      {
        _id: '60cc26ad47f7e10015fd8677',
        courseCode: 'SA 4691',
        shortName: 'Studio Art 4691 - SPECIAL PROJECTS IN STUDIO',
        courseName: 'Studio Art 4691 - SPECIAL PROJECTS IN STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ad47f7e10015fd8677',
      },
      {
        _id: '60cc26ad47f7e10015fd8679',
        courseCode: 'SA 4694',
        shortName: 'Studio Art 4694A/B - INDEPENDENT PROJECTS IN STUDIO',
        courseName: 'Studio Art 4694A/B - INDEPENDENT PROJECTS IN STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ad47f7e10015fd8679',
      },
      {
        _id: '60cc26ae47f7e10015fd867b',
        courseCode: 'SA 4695',
        shortName: 'Studio Art 4695 - INDEPENDENT PROJECTS IN STUDIO',
        courseName: 'Studio Art 4695 - INDEPENDENT PROJECTS IN STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ae47f7e10015fd867b',
      },
      {
        _id: '60cc26ae47f7e10015fd867d',
        courseCode: 'SA 4696',
        shortName: 'Studio Art 4696A/B - INDEPENDENT PROJECTS IN STUDIO',
        courseName: 'Studio Art 4696A/B - INDEPENDENT PROJECTS IN STUDIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ae47f7e10015fd867d',
      },
      {
        _id: '60cc26ae47f7e10015fd867f',
        courseCode: 'SA 4698',
        shortName: 'Studio Art 4698W/X - ACCELERATED INDEPENDENT STUDY',
        courseName: 'Studio Art 4698W/X - ACCELERATED INDEPENDENT STUDY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ae47f7e10015fd867f',
      },
      {
        _id: '60cc26ae47f7e10015fd8681',
        courseCode: 'THANAT 1025',
        shortName: 'Thanatology 1025A/B - INTRODUCTION TO THANATOLOGY',
        courseName: 'Thanatology 1025A/B - INTRODUCTION TO THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ae47f7e10015fd8681',
      },
      {
        _id: '60cc26af47f7e10015fd8683',
        courseCode: 'THANAT 2201',
        shortName: 'Thanatology 2201F/G - RESEARCH METHODS IN THANATOLOGY',
        courseName: 'Thanatology 2201F/G - RESEARCH METHODS IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26af47f7e10015fd8683',
      },
      {
        _id: '60cc26af47f7e10015fd8685',
        courseCode: 'THANAT 2225',
        shortName: 'Thanatology 2225A/B - TOPICAL ISSUES IN THANATOLOGY',
        courseName: 'Thanatology 2225A/B - TOPICAL ISSUES IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26af47f7e10015fd8685',
      },
      {
        _id: '60cc26af47f7e10015fd8687',
        courseCode: 'THANAT 2230',
        shortName: 'Thanatology 2230A/B - PRINCIPLES OF PALLIATIVE CARE',
        courseName: 'Thanatology 2230A/B - PRINCIPLES OF PALLIATIVE CARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26af47f7e10015fd8687',
      },
      {
        _id: '60cc26af47f7e10015fd8689',
        courseCode: 'THANAT 2231',
        shortName:
          'Thanatology 2231A/B - BEREAVEMENT AND GRIEF: OVERVIEW AND INTERVENTIONS',
        courseName:
          'Thanatology 2231A/B - BEREAVEMENT AND GRIEF: OVERVIEW AND INTERVENTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26af47f7e10015fd8689',
      },
      {
        _id: '60cc26b047f7e10015fd868b',
        courseCode: 'THANAT 2232',
        shortName:
          'Thanatology 2232A/B - CHILDREN AND DEATH: THEORY AND INTERVENTIONS',
        courseName:
          'Thanatology 2232A/B - CHILDREN AND DEATH: THEORY AND INTERVENTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b047f7e10015fd868b',
      },
      {
        _id: '60cc26b047f7e10015fd868d',
        courseCode: 'THANAT 2233',
        shortName: 'Thanatology 2233A/B - SUICIDE: THEORY AND INTERVENTIONS',
        courseName: 'Thanatology 2233A/B - SUICIDE: THEORY AND INTERVENTIONS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b047f7e10015fd868d',
      },
      {
        _id: '60cc26b047f7e10015fd868f',
        courseCode: 'THANAT 2234',
        shortName: 'Thanatology 2234A/B - CHANGE, TRANSITION AND LOSS',
        courseName: 'Thanatology 2234A/B - CHANGE, TRANSITION AND LOSS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b047f7e10015fd868f',
      },
      {
        _id: '60cc26b047f7e10015fd8691',
        courseCode: 'THANAT 2235',
        shortName: 'Thanatology 2235A/B - POPULAR CULTURE AND DEATH',
        courseName: 'Thanatology 2235A/B - POPULAR CULTURE AND DEATH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b047f7e10015fd8691',
      },
      {
        _id: '60cc26b047f7e10015fd8693',
        courseCode: 'THANAT 2291',
        shortName: 'Thanatology 2291F/G - INDEPENDENT STUDY IN THANATOLOGY',
        courseName: 'Thanatology 2291F/G - INDEPENDENT STUDY IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b047f7e10015fd8693',
      },
      {
        _id: '60cc26b147f7e10015fd8695',
        courseCode: 'THANAT 2292',
        shortName: 'Thanatology 2292F/G - INDEPENDENT STUDY IN THANATOLOGY',
        courseName: 'Thanatology 2292F/G - INDEPENDENT STUDY IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b147f7e10015fd8695',
      },
      {
        _id: '60cc26b147f7e10015fd8697',
        courseCode: 'THANAT 2293',
        shortName: 'Thanatology 2293A/B - SPECIAL TOPICS',
        courseName: 'Thanatology 2293A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b147f7e10015fd8697',
      },
      {
        _id: '60cc26b147f7e10015fd8699',
        courseCode: 'THANAT 2294',
        shortName: 'Thanatology 2294A/B - SPECIAL TOPICS',
        courseName: 'Thanatology 2294A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b147f7e10015fd8699',
      },
      {
        _id: '60cc26b147f7e10015fd869b',
        courseCode: 'THANAT 2295',
        shortName: 'Thanatology 2295A/B - SPECIAL TOPICS',
        courseName: 'Thanatology 2295A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b147f7e10015fd869b',
      },
      {
        _id: '60cc26b247f7e10015fd869d',
        courseCode: 'THANAT 2296',
        shortName: 'Thanatology 2296F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 2296F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b247f7e10015fd869d',
      },
      {
        _id: '60cc26b247f7e10015fd869f',
        courseCode: 'THANAT 2297',
        shortName: 'Thanatology 2297F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 2297F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b247f7e10015fd869f',
      },
      {
        _id: '60cc26b247f7e10015fd86a1',
        courseCode: 'THANAT 2298',
        shortName: 'Thanatology 2298F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 2298F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b247f7e10015fd86a1',
      },
      {
        _id: '60cc26b247f7e10015fd86a3',
        courseCode: 'THANAT 2602',
        shortName:
          'Thanatology 2602A/B - COMMUNICATING WITH THE DYING AND BEREAVED',
        courseName:
          'Thanatology 2602A/B - COMMUNICATING WITH THE DYING AND BEREAVED',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b247f7e10015fd86a3',
      },
      {
        _id: '60cc26b347f7e10015fd86a5',
        courseCode: 'THANAT 2605',
        shortName: 'Thanatology 2605A/B - CREATIVE RESPONSES TO LOSS AND GRIEF',
        courseName:
          'Thanatology 2605A/B - CREATIVE RESPONSES TO LOSS AND GRIEF',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b347f7e10015fd86a5',
      },
      {
        _id: '60cc26b347f7e10015fd86a7',
        courseCode: 'THANAT 3320',
        shortName:
          'Thanatology 3320A/B - SPIRITUAL AND PHILOSOPHICAL ISSUES IN DEATH, DYING AND BEREAVEMENT',
        courseName:
          'Thanatology 3320A/B - SPIRITUAL AND PHILOSOPHICAL ISSUES IN DEATH, DYING AND BEREAVEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b347f7e10015fd86a7',
      },
      {
        _id: '60cc26b347f7e10015fd86a9',
        courseCode: 'THANAT 3321',
        shortName: 'Thanatology 3321A/B - GRIEF COUNSELLING AND SUPPORT',
        courseName: 'Thanatology 3321A/B - GRIEF COUNSELLING AND SUPPORT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b347f7e10015fd86a9',
      },
      {
        _id: '60cc26b347f7e10015fd86ab',
        courseCode: 'THANAT 3322',
        shortName:
          'Thanatology 3322A/B - DIVERSITY AND SOCIAL JUSTICE ISSUES IN THANATOLOGY',
        courseName:
          'Thanatology 3322A/B - DIVERSITY AND SOCIAL JUSTICE ISSUES IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b347f7e10015fd86ab',
      },
      {
        _id: '60cc26b447f7e10015fd86ad',
        courseCode: 'THANAT 3323',
        shortName: 'Thanatology 3323A/B - GRIEF AND TRAUMA',
        courseName: 'Thanatology 3323A/B - GRIEF AND TRAUMA',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b447f7e10015fd86ad',
      },
      {
        _id: '60cc26b447f7e10015fd86af',
        courseCode: 'THANAT 3324',
        shortName: 'Thanatology 3324A/B - EXTRAORDINARY EXPERIENCES',
        courseName: 'Thanatology 3324A/B - EXTRAORDINARY EXPERIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b447f7e10015fd86af',
      },
      {
        _id: '60cc26b447f7e10015fd86b1',
        courseCode: 'THANAT 3355',
        shortName:
          'Thanatology 3355A/B - ETHICAL ISSUES IN DEATH, DYING AND BEREAVEMENT',
        courseName:
          'Thanatology 3355A/B - ETHICAL ISSUES IN DEATH, DYING AND BEREAVEMENT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b447f7e10015fd86b1',
      },
      {
        _id: '60cc26b447f7e10015fd86b3',
        courseCode: 'THANAT 3360',
        shortName: 'Thanatology 3360A/B - PRACTICUM IN THANATOLOGY',
        courseName: 'Thanatology 3360A/B - PRACTICUM IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b447f7e10015fd86b3',
      },
      {
        _id: '60cc26b547f7e10015fd86b5',
        courseCode: 'THANAT 3393',
        shortName: 'Thanatology 3393A/B - SPECIAL TOPICS',
        courseName: 'Thanatology 3393A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b547f7e10015fd86b5',
      },
      {
        _id: '60cc26b547f7e10015fd86b7',
        courseCode: 'THANAT 3394',
        shortName: 'Thanatology 3394A/B - SPECIAL TOPICS',
        courseName: 'Thanatology 3394A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b547f7e10015fd86b7',
      },
      {
        _id: '60cc26b547f7e10015fd86b9',
        courseCode: 'THANAT 3395',
        shortName: 'Thanatology 3395A/B - SPECIAL TOPICS',
        courseName: 'Thanatology 3395A/B - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b547f7e10015fd86b9',
      },
      {
        _id: '60cc26b547f7e10015fd86bb',
        courseCode: 'THANAT 3396',
        shortName: 'Thanatology 3396F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 3396F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b547f7e10015fd86bb',
      },
      {
        _id: '60cc26b647f7e10015fd86bd',
        courseCode: 'THANAT 3397',
        shortName: 'Thanatology 3397F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 3397F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b647f7e10015fd86bd',
      },
      {
        _id: '60cc26b647f7e10015fd86bf',
        courseCode: 'THANAT 3398',
        shortName: 'Thanatology 3398F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 3398F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b647f7e10015fd86bf',
      },
      {
        _id: '60cc26b647f7e10015fd86c1',
        courseCode: 'THANAT 3601',
        shortName: 'Thanatology 3601A/B - CRISIS, TRAUMA IN EVERYDAY LIFE',
        courseName: 'Thanatology 3601A/B - CRISIS, TRAUMA IN EVERYDAY LIFE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b647f7e10015fd86c1',
      },
      {
        _id: '60cc26b647f7e10015fd86c3',
        courseCode: 'THANAT 3603',
        shortName: 'Thanatology 3603A/B - GRIEF SUPPORT GROUPS',
        courseName: 'Thanatology 3603A/B - GRIEF SUPPORT GROUPS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b647f7e10015fd86c3',
      },
      {
        _id: '60cc26b647f7e10015fd86c5',
        courseCode: 'THANAT 3606',
        shortName: 'Thanatology 3606A/B - PERSONAL AND PROFESSIONAL ISSUES',
        courseName: 'Thanatology 3606A/B - PERSONAL AND PROFESSIONAL ISSUES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b647f7e10015fd86c5',
      },
      {
        _id: '60cc26b747f7e10015fd86c7',
        courseCode: 'THANAT 3607',
        shortName:
          'Thanatology 3607A/B - THERAPEUTIC MODALITIES IN LOSS AND GRIEF',
        courseName:
          'Thanatology 3607A/B - THERAPEUTIC MODALITIES IN LOSS AND GRIEF',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b747f7e10015fd86c7',
      },
      {
        _id: '60cc26b747f7e10015fd86c9',
        courseCode: 'THANAT 4331',
        shortName:
          'Thanatology 4331F/G - SOCIAL AND POLITICAL DISCOURSE IN THANATOLOGY',
        courseName:
          'Thanatology 4331F/G - SOCIAL AND POLITICAL DISCOURSE IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b747f7e10015fd86c9',
      },
      {
        _id: '60cc26b747f7e10015fd86cb',
        courseCode: 'THANAT 4402',
        shortName: 'Thanatology 4402F/G - FIELD RESEARCH IN THANATOLOGY',
        courseName: 'Thanatology 4402F/G - FIELD RESEARCH IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b747f7e10015fd86cb',
      },
      {
        _id: '60cc26b747f7e10015fd86cd',
        courseCode: 'THANAT 4403',
        shortName: 'Thanatology 4403F/G - ADVANCED SEMINAR IN THANATOLOGY',
        courseName: 'Thanatology 4403F/G - ADVANCED SEMINAR IN THANATOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b747f7e10015fd86cd',
      },
      {
        _id: '60cc26b847f7e10015fd86cf',
        courseCode: 'THANAT 4496',
        shortName: 'Thanatology 4496F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 4496F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b847f7e10015fd86cf',
      },
      {
        _id: '60cc26b847f7e10015fd86d1',
        courseCode: 'THANAT 4497',
        shortName: 'Thanatology 4497F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 4497F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b847f7e10015fd86d1',
      },
      {
        _id: '60cc26b847f7e10015fd86d3',
        courseCode: 'THANAT 4498',
        shortName: 'Thanatology 4498F/G - SPECIAL TOPICS',
        courseName: 'Thanatology 4498F/G - SPECIAL TOPICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b847f7e10015fd86d3',
      },
      {
        _id: '60cc26b847f7e10015fd86d5',
        courseCode: 'THEATRE 2201',
        shortName: 'Theatre Studies 2201F/G - UNDERSTANDING PERFORMANCE',
        courseName: 'Theatre Studies 2201F/G - UNDERSTANDING PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b847f7e10015fd86d5',
      },
      {
        _id: '60cc26b947f7e10015fd86d7',
        courseCode: 'THEATRE 2202',
        shortName: 'Theatre Studies 2202F/G - PERFORMANCE BEYOND THEATRES',
        courseName: 'Theatre Studies 2202F/G - PERFORMANCE BEYOND THEATRES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b947f7e10015fd86d7',
      },
      {
        _id: '60cc26b947f7e10015fd86d9',
        courseCode: 'THEATRE 2203',
        shortName: 'Theatre Studies 2203E - FORMS AND GENRES OF THEATRE',
        courseName: 'Theatre Studies 2203E - FORMS AND GENRES OF THEATRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b947f7e10015fd86d9',
      },
      {
        _id: '60cc26b947f7e10015fd86db',
        courseCode: 'THEATRE 2204',
        shortName:
          'Theatre Studies 2204F/G - FORMS AND GENRES: THE GREEKS TO SHAKESPEARE',
        courseName:
          'Theatre Studies 2204F/G - FORMS AND GENRES: THE GREEKS TO SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b947f7e10015fd86db',
      },
      {
        _id: '60cc26b947f7e10015fd86dd',
        courseCode: 'THEATRE 2205',
        shortName:
          'Theatre Studies 2205F/G - FORMS AND GENRES: THE MODERN CONTEXT',
        courseName:
          'Theatre Studies 2205F/G - FORMS AND GENRES: THE MODERN CONTEXT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b947f7e10015fd86dd',
      },
      {
        _id: '60cc26b947f7e10015fd86df',
        courseCode: 'THEATRE 3201',
        shortName: 'Theatre Studies 3201F/G - GREAT DIRECTORS',
        courseName: 'Theatre Studies 3201F/G - GREAT DIRECTORS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26b947f7e10015fd86df',
      },
      {
        _id: '60cc26ba47f7e10015fd86e1',
        courseCode: 'THEATRE 3202',
        shortName: 'Theatre Studies 3202F/G - SPACE, LOCATION AND SCENOGRAPHY',
        courseName: 'Theatre Studies 3202F/G - SPACE, LOCATION AND SCENOGRAPHY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ba47f7e10015fd86e1',
      },
      {
        _id: '60cc26ba47f7e10015fd86e3',
        courseCode: 'THEATRE 3203',
        shortName:
          'Theatre Studies 3203F/G - THE PROFANE TEXT (THEATRICAL ADAPTATION)',
        courseName:
          'Theatre Studies 3203F/G - THE PROFANE TEXT (THEATRICAL ADAPTATION)',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ba47f7e10015fd86e3',
      },
      {
        _id: '60cc26ba47f7e10015fd86e5',
        courseCode: 'THEATRE 3204',
        shortName: 'Theatre Studies 3204F/G - CRAFTING THEATRE',
        courseName: 'Theatre Studies 3204F/G - CRAFTING THEATRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ba47f7e10015fd86e5',
      },
      {
        _id: '60cc26ba47f7e10015fd86e7',
        courseCode: 'THEATRE 3205',
        shortName: 'Theatre Studies 3205F/G - HISTORY OF PERFORMANCE THEORY',
        courseName: 'Theatre Studies 3205F/G - HISTORY OF PERFORMANCE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ba47f7e10015fd86e7',
      },
      {
        _id: '60cc26bb47f7e10015fd86e9',
        courseCode: 'THEATRE 3206',
        shortName: 'Theatre Studies 3206F/G - SHAKESPEARE IN PERFORMANCE',
        courseName: 'Theatre Studies 3206F/G - SHAKESPEARE IN PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bb47f7e10015fd86e9',
      },
      {
        _id: '60cc26bb47f7e10015fd86eb',
        courseCode: 'THEATRE 3207',
        shortName: 'Theatre Studies 3207F/G - VOICE AND TEXT IN THE THEATRE',
        courseName: 'Theatre Studies 3207F/G - VOICE AND TEXT IN THE THEATRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bb47f7e10015fd86eb',
      },
      {
        _id: '60cc26bb47f7e10015fd86ed',
        courseCode: 'THEATRE 3208',
        shortName: 'Theatre Studies 3208F/G - TABLE WORK',
        courseName: 'Theatre Studies 3208F/G - TABLE WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bb47f7e10015fd86ed',
      },
      {
        _id: '60cc26bb47f7e10015fd86ef',
        courseCode: 'THEATRE 3209',
        shortName:
          'Theatre Studies 3209F/G - INDIGENOUS THEATRE AND PERFORMANCE',
        courseName:
          'Theatre Studies 3209F/G - INDIGENOUS THEATRE AND PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bb47f7e10015fd86ef',
      },
      {
        _id: '60cc26bc47f7e10015fd86f1',
        courseCode: 'THEATRE 3210',
        shortName:
          'Theatre Studies 3210A/B - PERFORMING ARTS MANAGEMENT, MARKETING, AND CURATION',
        courseName:
          'Theatre Studies 3210A/B - PERFORMING ARTS MANAGEMENT, MARKETING, AND CURATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bc47f7e10015fd86f1',
      },
      {
        _id: '60cc26bc47f7e10015fd86f3',
        courseCode: 'THEATRE 3211',
        shortName:
          'Theatre Studies 3211F/G - IN YOUR SKIN: SEXUALITIES AND PERFORMANCE',
        courseName:
          'Theatre Studies 3211F/G - IN YOUR SKIN: SEXUALITIES AND PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bc47f7e10015fd86f3',
      },
      {
        _id: '60cc26bc47f7e10015fd86f5',
        courseCode: 'THEATRE 3327',
        shortName: 'Theatre Studies 3327A/B - REMEDIATED SHAKESPEARE',
        courseName: 'Theatre Studies 3327A/B - REMEDIATED SHAKESPEARE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bc47f7e10015fd86f5',
      },
      {
        _id: '60cc26bc47f7e10015fd86f7',
        courseCode: 'THEATRE 3581',
        shortName: 'Theatre Studies 3581F/G - TORONTO: CULTURE AND PERFORMANCE',
        courseName:
          'Theatre Studies 3581F/G - TORONTO: CULTURE AND PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bc47f7e10015fd86f7',
      },
      {
        _id: '60cc26bc47f7e10015fd86f9',
        courseCode: 'THEATRE 3900',
        shortName: 'Theatre Studies 3900F/G - DESTINATION THEATRE',
        courseName: 'Theatre Studies 3900F/G - DESTINATION THEATRE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bc47f7e10015fd86f9',
      },
      {
        _id: '60cc26bd47f7e10015fd86fb',
        courseCode: 'THEATRE 3950',
        shortName: 'Theatre Studies 3950E - SPECIAL TOPICS IN THEATRE STUDIES',
        courseName: 'Theatre Studies 3950E - SPECIAL TOPICS IN THEATRE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bd47f7e10015fd86fb',
      },
      {
        _id: '60cc26bd47f7e10015fd86fd',
        courseCode: 'THEATRE 3951',
        shortName:
          'Theatre Studies 3951F/G - SPECIAL TOPICS IN THEATRE STUDIES',
        courseName:
          'Theatre Studies 3951F/G - SPECIAL TOPICS IN THEATRE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bd47f7e10015fd86fd',
      },
      {
        _id: '60cc26bd47f7e10015fd86ff',
        courseCode: 'THEATRE 3952',
        shortName:
          'Theatre Studies 3952F/G - SPECIAL TOPICS IN THEATRE STUDIES',
        courseName:
          'Theatre Studies 3952F/G - SPECIAL TOPICS IN THEATRE STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bd47f7e10015fd86ff',
      },
      {
        _id: '60cc26bd47f7e10015fd8701',
        courseCode: 'THEATRE 4211',
        shortName: 'Theatre Studies 4211F/G - THE PERFORMANCE ARCHIVE',
        courseName: 'Theatre Studies 4211F/G - THE PERFORMANCE ARCHIVE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bd47f7e10015fd8701',
      },
      {
        _id: '60cc26be47f7e10015fd8703',
        courseCode: 'THEATRE 4212',
        shortName: 'Theatre Studies 4212F/G - THEATRE TALK',
        courseName: 'Theatre Studies 4212F/G - THEATRE TALK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26be47f7e10015fd8703',
      },
      {
        _id: '60cc26be47f7e10015fd8705',
        courseCode: 'THEATRE 4214',
        shortName:
          'Theatre Studies 4214F/G - CONTEMPORARY ISSUES IN PERFORMANCE THEORY',
        courseName:
          'Theatre Studies 4214F/G - CONTEMPORARY ISSUES IN PERFORMANCE THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26be47f7e10015fd8705',
      },
      {
        _id: '60cc26be47f7e10015fd8707',
        courseCode: 'THEATRE 4216',
        shortName: 'Theatre Studies 4216F/G - REVIEWING PERFORMANCE',
        courseName: 'Theatre Studies 4216F/G - REVIEWING PERFORMANCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26be47f7e10015fd8707',
      },
      {
        _id: '60cc26be47f7e10015fd8709',
        courseCode: 'THEATRE 4999',
        shortName: 'Theatre Studies 4999E - THESIS PROJECT',
        courseName: 'Theatre Studies 4999E - THESIS PROJECT',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26be47f7e10015fd8709',
      },
      {
        _id: '60cc26bf47f7e10015fd870b',
        courseCode: 'THEOLST 2207',
        shortName:
          'Theological Studies 2207F/G - INTRODUCTION TO SYSTEMATIC THEOLOGY',
        courseName:
          'Theological Studies 2207F/G - INTRODUCTION TO SYSTEMATIC THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bf47f7e10015fd870b',
      },
      {
        _id: '60cc26bf47f7e10015fd870d',
        courseCode: 'THEOLST 2208',
        shortName:
          'Theological Studies 2208F/G - CHRIST, SALVATION AND TRINITY',
        courseName:
          'Theological Studies 2208F/G - CHRIST, SALVATION AND TRINITY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bf47f7e10015fd870d',
      },
      {
        _id: '60cc26bf47f7e10015fd870f',
        courseCode: 'THEOLST 2210',
        shortName:
          'Theological Studies 2210F/G - BASIC QUESTIONS IN PHILOSOPHICAL THEOLOGY',
        courseName:
          'Theological Studies 2210F/G - BASIC QUESTIONS IN PHILOSOPHICAL THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bf47f7e10015fd870f',
      },
      {
        _id: '60cc26bf47f7e10015fd8711',
        courseCode: 'THEOLST 3200',
        shortName:
          'Theological Studies 3200F/G - THE CHRISTIAN DOCTRINE OF GOD',
        courseName:
          'Theological Studies 3200F/G - THE CHRISTIAN DOCTRINE OF GOD',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bf47f7e10015fd8711',
      },
      {
        _id: '60cc26bf47f7e10015fd8713',
        courseCode: 'THEOLST 3210',
        shortName: 'Theological Studies 3210F/G - CONTEXTUAL THEOLOGY',
        courseName: 'Theological Studies 3210F/G - CONTEXTUAL THEOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26bf47f7e10015fd8713',
      },
      {
        _id: '60cc26c047f7e10015fd8715',
        courseCode: 'THEOLST 3230',
        shortName: 'Theological Studies 3230F/G - ECUMENISM',
        courseName: 'Theological Studies 3230F/G - ECUMENISM',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c047f7e10015fd8715',
      },
      {
        _id: '60cc26c047f7e10015fd8717',
        courseCode: 'THEOLST 3240',
        shortName: 'Theological Studies 3240F/G - MISSIOLOGY',
        courseName: 'Theological Studies 3240F/G - MISSIOLOGY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c047f7e10015fd8717',
      },
      {
        _id: '60cc26c047f7e10015fd8719',
        courseCode: 'THEOLST 3250',
        shortName:
          'Theological Studies 3250F/G - CHRISTIAN THEOLOGY IN THE 19TH CENTURY',
        courseName:
          'Theological Studies 3250F/G - CHRISTIAN THEOLOGY IN THE 19TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c047f7e10015fd8719',
      },
      {
        _id: '60cc26c047f7e10015fd871b',
        courseCode: 'THEOLST 3260',
        shortName:
          'Theological Studies 3260F/G - CHRISTIAN THEOLOGY IN THE 20TH CENTURY',
        courseName:
          'Theological Studies 3260F/G - CHRISTIAN THEOLOGY IN THE 20TH CENTURY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c047f7e10015fd871b',
      },
      {
        _id: '60cc26c147f7e10015fd871d',
        courseCode: 'THEOLST 3312',
        shortName:
          'Theological Studies 3312F/G - CRITICAL INVESTIGATION OF THEOLOGICAL ETHICS',
        courseName:
          'Theological Studies 3312F/G - CRITICAL INVESTIGATION OF THEOLOGICAL ETHICS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c147f7e10015fd871d',
      },
      {
        _id: '60cc26c147f7e10015fd871f',
        courseCode: 'THEOLST 3313',
        shortName: 'Theological Studies 3313F/G - MAJOR AUTHORS AND TEXTS',
        courseName: 'Theological Studies 3313F/G - MAJOR AUTHORS AND TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c147f7e10015fd871f',
      },
      {
        _id: '60cc26c147f7e10015fd8721',
        courseCode: 'THEOLST 3330',
        shortName:
          'Theological Studies 3330F/G - THEOLOGY AND PRACTICE OF MUSIC IN THE CHURCH',
        courseName:
          'Theological Studies 3330F/G - THEOLOGY AND PRACTICE OF MUSIC IN THE CHURCH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c147f7e10015fd8721',
      },
      {
        _id: '60cc26c147f7e10015fd8723',
        courseCode: 'THEOLST 3331',
        shortName: 'Theological Studies 3331F/G - LITURGICAL MUSIC',
        courseName: 'Theological Studies 3331F/G - LITURGICAL MUSIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c147f7e10015fd8723',
      },
      {
        _id: '60cc26c247f7e10015fd8725',
        courseCode: 'THEOLST 3333',
        shortName:
          'Theological Studies 3333F/G - THEOLOGY, SCIENCE AND SOCIETY',
        courseName:
          'Theological Studies 3333F/G - THEOLOGY, SCIENCE AND SOCIETY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c247f7e10015fd8725',
      },
      {
        _id: '60cc26c247f7e10015fd8727',
        courseCode: 'THEOLST 4400',
        shortName: 'Theological Studies 4400E - THESIS',
        courseName: 'Theological Studies 4400E - THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c247f7e10015fd8727',
      },
      {
        _id: '60cc26c247f7e10015fd8729',
        courseCode: 'THEOLST 4405',
        shortName:
          'Theological Studies 4405F/G - BIBLICAL STUDIES - FURTHER STUDIES IN THE GOSPELS',
        courseName:
          'Theological Studies 4405F/G - BIBLICAL STUDIES - FURTHER STUDIES IN THE GOSPELS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c247f7e10015fd8729',
      },
      {
        _id: '60cc26c247f7e10015fd872b',
        courseCode: 'THEOLST 4410',
        shortName:
          'Theological Studies 4410F/G - SPIRIT, CHURCH AND SACRAMENTS',
        courseName:
          'Theological Studies 4410F/G - SPIRIT, CHURCH AND SACRAMENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c247f7e10015fd872b',
      },
      {
        _id: '60cc26c247f7e10015fd872d',
        courseCode: 'THEOLST 4412',
        shortName: 'Theological Studies 4412F/G - THEOLOGY OF THE REFORMATION',
        courseName: 'Theological Studies 4412F/G - THEOLOGY OF THE REFORMATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c247f7e10015fd872d',
      },
      {
        _id: '60cc26c347f7e10015fd872f',
        courseCode: 'THEOLST 4415',
        shortName: 'Theological Studies 4415F/G - MAJOR AUTHORS AND TEXTS',
        courseName: 'Theological Studies 4415F/G - MAJOR AUTHORS AND TEXTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c347f7e10015fd872f',
      },
      {
        _id: '60cc26c347f7e10015fd8731',
        courseCode: 'TJ 2001',
        shortName:
          'Transitional Justice 2001F/G - PROBLEMS IN TRANSITIONAL JUSTICE AND POST-CONFLICT RECONSTRUCTION',
        courseName:
          'Transitional Justice 2001F/G - PROBLEMS IN TRANSITIONAL JUSTICE AND POST-CONFLICT RECONSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c347f7e10015fd8731',
      },
      {
        _id: '60cc26c347f7e10015fd8733',
        courseCode: 'TJ 3001',
        shortName:
          'Transitional Justice 3001F/G - STUDIES IN TRANSITIONAL JUSTICE AND POST-CONFLICT RECONSTRUCTION',
        courseName:
          'Transitional Justice 3001F/G - STUDIES IN TRANSITIONAL JUSTICE AND POST-CONFLICT RECONSTRUCTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c347f7e10015fd8733',
      },
      {
        _id: '60cc26c347f7e10015fd8735',
        courseCode: 'WTC 2901',
        shortName:
          'Western Thought and Civilization 2901E - MEDIEVAL TO SCIENTIFIC REVOLUTION',
        courseName:
          'Western Thought and Civilization 2901E - MEDIEVAL TO SCIENTIFIC REVOLUTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c347f7e10015fd8735',
      },
      {
        _id: '60cc26c447f7e10015fd8737',
        courseCode: 'WTC 3901',
        shortName:
          'Western Thought and Civilization 3901F/G/Z - ENLIGHTENMENT TO FIN DE SIECLE',
        courseName:
          'Western Thought and Civilization 3901F/G/Z - ENLIGHTENMENT TO FIN DE SIECLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c447f7e10015fd8737',
      },
      {
        _id: '60cc26c447f7e10015fd8739',
        courseCode: 'WTC 4901',
        shortName:
          'Western Thought and Civilization 4901F/G/Z - 20TH CENTURY TO CONTEMPORARY',
        courseName:
          'Western Thought and Civilization 4901F/G/Z - 20TH CENTURY TO CONTEMPORARY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c447f7e10015fd8739',
      },
      {
        _id: '60cc26c447f7e10015fd873b',
        courseCode: 'WORLDLIT 1030',
        shortName:
          'World Literatures and Cultures 1030 - FROM EAST TO WEST AND NORTH TO SOUTH',
        courseName:
          'World Literatures and Cultures 1030 - FROM EAST TO WEST AND NORTH TO SOUTH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c447f7e10015fd873b',
      },
      {
        _id: '60cc26c447f7e10015fd873d',
        courseCode: 'WRITING 0002',
        shortName: 'Writing 0002F/G - INTRODUCTION TO WRITING IN ENGLISH',
        courseName: 'Writing 0002F/G - INTRODUCTION TO WRITING IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c447f7e10015fd873d',
      },
      {
        _id: '60cc26c547f7e10015fd873f',
        courseCode: 'WRITING 0005',
        shortName: 'Writing 0005W/X - CRITICAL READING AND EFFECTIVE WRITING',
        courseName: 'Writing 0005W/X - CRITICAL READING AND EFFECTIVE WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c547f7e10015fd873f',
      },
      {
        _id: '60cc26c547f7e10015fd8741',
        courseCode: 'WRITING 0010',
        shortName:
          'Writing 0010F/G - FUNDAMENTALS OF ACADEMIC WRITING FOR INTERNATIONAL STUDENTS',
        courseName:
          'Writing 0010F/G - FUNDAMENTALS OF ACADEMIC WRITING FOR INTERNATIONAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c547f7e10015fd8741',
      },
      {
        _id: '60cc26c547f7e10015fd8743',
        courseCode: 'WRITING 0011',
        shortName:
          'Writing 0011F/G - INTRODUCTION TO BASIC ACADEMIC WRITING IN ENGLISH FOR MULTILINGUAL STUDENTS',
        courseName:
          'Writing 0011F/G - INTRODUCTION TO BASIC ACADEMIC WRITING IN ENGLISH FOR MULTILINGUAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c547f7e10015fd8743',
      },
      {
        _id: '60cc26c647f7e10015fd8745',
        courseCode: 'WRITING 0012',
        shortName:
          'Writing 0012F/G - INTRODUCTION TO ADVANCED ACADEMIC WRITING IN ENGLISH FOR MULTILINGUAL STUDENTS',
        courseName:
          'Writing 0012F/G - INTRODUCTION TO ADVANCED ACADEMIC WRITING IN ENGLISH FOR MULTILINGUAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c647f7e10015fd8745',
      },
      {
        _id: '60cc26c647f7e10015fd8747',
        courseCode: 'WRITING 0015',
        shortName:
          'Writing 0015F/G - ADVANCED ACADEMIC WRITING FOR INTERNATIONAL STUDENTS',
        courseName:
          'Writing 0015F/G - ADVANCED ACADEMIC WRITING FOR INTERNATIONAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c647f7e10015fd8747',
      },
      {
        _id: '60cc26c647f7e10015fd8749',
        courseCode: 'WRITING 1000',
        shortName: "Writing 1000F/G - THE WRITERS' STUDIO",
        courseName: "Writing 1000F/G - THE WRITERS' STUDIO",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c647f7e10015fd8749',
      },
      {
        _id: '60cc26c647f7e10015fd874b',
        courseCode: 'WRITING 1002',
        shortName: 'Writing 1002F/G - INTRODUCTION TO WRITING IN ENGLISH',
        courseName: 'Writing 1002F/G - INTRODUCTION TO WRITING IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c647f7e10015fd874b',
      },
      {
        _id: '60cc26c747f7e10015fd874d',
        courseCode: 'WRITING 1020',
        shortName:
          'Writing 1020F/G - WRITING: INTRODUCTION TO UNIVERSITY ESSAY WRITING',
        courseName:
          'Writing 1020F/G - WRITING: INTRODUCTION TO UNIVERSITY ESSAY WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c747f7e10015fd874d',
      },
      {
        _id: '60cc26c747f7e10015fd874f',
        courseCode: 'WRITING 1021',
        shortName:
          'Writing 1021F/G - BASIC ACADEMIC WRITING FOR MULTILINGUAL STUDENTS',
        courseName:
          'Writing 1021F/G - BASIC ACADEMIC WRITING FOR MULTILINGUAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c747f7e10015fd874f',
      },
      {
        _id: '60cc26c747f7e10015fd8751',
        courseCode: 'WRITING 1022',
        shortName: 'Writing 1022F/G - UNIVERSITY WRITING IN ENGLISH',
        courseName: 'Writing 1022F/G - UNIVERSITY WRITING IN ENGLISH',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c747f7e10015fd8751',
      },
      {
        _id: '60cc26c847f7e10015fd8753',
        courseCode: 'WRITING 1025',
        shortName: 'Writing 1025F/G - CRITICAL READING AND WRITING',
        courseName: 'Writing 1025F/G - CRITICAL READING AND WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c847f7e10015fd8753',
      },
      {
        _id: '60cc26c847f7e10015fd8755',
        courseCode: 'WRITING 1030',
        shortName:
          'Writing 1030F/G - WRITING FOR PROFESSIONAL SUCCESS IN NURSING',
        courseName:
          'Writing 1030F/G - WRITING FOR PROFESSIONAL SUCCESS IN NURSING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c847f7e10015fd8755',
      },
      {
        _id: '60cc26c847f7e10015fd8757',
        courseCode: 'WRITING 1031',
        shortName:
          'Writing 1031F/G - GLOBAL POSITIONING: INTRODUCTION TO RHETORIC AND PROFESSIONAL COMMUNICATION',
        courseName:
          'Writing 1031F/G - GLOBAL POSITIONING: INTRODUCTION TO RHETORIC AND PROFESSIONAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c847f7e10015fd8757',
      },
      {
        _id: '60cc26c847f7e10015fd8759',
        courseCode: 'WRITING 2023',
        shortName:
          'Writing 2023F/G - ADVANCED ACADEMIC WRITING IN ENGLISH FOR MULTILINGUAL STUDENTS',
        courseName:
          'Writing 2023F/G - ADVANCED ACADEMIC WRITING IN ENGLISH FOR MULTILINGUAL STUDENTS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c847f7e10015fd8759',
      },
      {
        _id: '60cc26c947f7e10015fd875b',
        courseCode: 'WRITING 2101',
        shortName: 'Writing 2101F/G - INTRODUCTION TO EXPOSITORY WRITING',
        courseName: 'Writing 2101F/G - INTRODUCTION TO EXPOSITORY WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c947f7e10015fd875b',
      },
      {
        _id: '60cc26c947f7e10015fd875d',
        courseCode: 'WRITING 2111',
        shortName:
          'Writing 2111F/G - WRITING IN THE WORLD:  INTRODUCTION TO PROFESSIONAL WRITING',
        courseName:
          'Writing 2111F/G - WRITING IN THE WORLD:  INTRODUCTION TO PROFESSIONAL WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26c947f7e10015fd875d',
      },
      {
        _id: '60cc26ca47f7e10015fd875f',
        courseCode: 'WRITING 2125',
        shortName:
          'Writing 2125F/G - LET ME EXPLAIN IT TO YOU: EXPOSITION & VISUAL RHETORIC',
        courseName:
          'Writing 2125F/G - LET ME EXPLAIN IT TO YOU: EXPOSITION & VISUAL RHETORIC',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ca47f7e10015fd875f',
      },
      {
        _id: '60cc26ca47f7e10015fd8762',
        courseCode: 'WRITING 2131',
        shortName:
          'Writing 2131F/G - NO BONES ABOUT IT:  WRITING IN THE SCIENCES',
        courseName:
          'Writing 2131F/G - NO BONES ABOUT IT:  WRITING IN THE SCIENCES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ca47f7e10015fd8762',
      },
      {
        _id: '60cc26ca47f7e10015fd8764',
        courseCode: 'WRITING 2202',
        shortName:
          'Writing 2202F/G - WINNING YOUR ARGUMENT:  RHETORICAL STRATEGY IN A VISUAL AGE',
        courseName:
          'Writing 2202F/G - WINNING YOUR ARGUMENT:  RHETORICAL STRATEGY IN A VISUAL AGE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ca47f7e10015fd8764',
      },
      {
        _id: '60cc26cb47f7e10015fd8766',
        courseCode: 'WRITING 2203',
        shortName:
          'Writing 2203F/G - FROM HEADLINE TO DEADLINE:  WRITING FOR PUBLICATION',
        courseName:
          'Writing 2203F/G - FROM HEADLINE TO DEADLINE:  WRITING FOR PUBLICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cb47f7e10015fd8766',
      },
      {
        _id: '60cc26cb47f7e10015fd8768',
        courseCode: 'WRITING 2204',
        shortName:
          'Writing 2204F/G - SHORT FLICKS: AN INTRODUCTION TO SCREENWRITING',
        courseName:
          'Writing 2204F/G - SHORT FLICKS: AN INTRODUCTION TO SCREENWRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cb47f7e10015fd8768',
      },
      {
        _id: '60cc26cb47f7e10015fd876a',
        courseCode: 'WRITING 2208',
        shortName: 'Writing 2208F/G - TEACHING WRITING',
        courseName: 'Writing 2208F/G - TEACHING WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cb47f7e10015fd876a',
      },
      {
        _id: '60cc26cc47f7e10015fd876c',
        courseCode: 'WRITING 2209',
        shortName:
          'Writing 2209F/G - VISUAL INFORMATION PACKAGING:  DOCUMENT DESIGN',
        courseName:
          'Writing 2209F/G - VISUAL INFORMATION PACKAGING:  DOCUMENT DESIGN',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cc47f7e10015fd876c',
      },
      {
        _id: '60cc26cc47f7e10015fd876e',
        courseCode: 'WRITING 2210',
        shortName:
          'Writing 2210F/G - GRAMMARPHOBIA DEMYSTIFIED:  CONTEMPORARY GRAMMAR FOR WRITERS',
        courseName:
          'Writing 2210F/G - GRAMMARPHOBIA DEMYSTIFIED:  CONTEMPORARY GRAMMAR FOR WRITERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cc47f7e10015fd876e',
      },
      {
        _id: '60cc26cc47f7e10015fd8770',
        courseCode: 'WRITING 2211',
        shortName:
          'Writing 2211F/G - THE NAKED WRITER:  FUNDAMENTALS OF CREATIVE WRITING',
        courseName:
          'Writing 2211F/G - THE NAKED WRITER:  FUNDAMENTALS OF CREATIVE WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cc47f7e10015fd8770',
      },
      {
        _id: '60cc26cc47f7e10015fd8772',
        courseCode: 'WRITING 2213',
        shortName: 'Writing 2213F/G - LOL:  HUMOUR WRITING',
        courseName: 'Writing 2213F/G - LOL:  HUMOUR WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cc47f7e10015fd8772',
      },
      {
        _id: '60cc26cd47f7e10015fd8774',
        courseCode: 'WRITING 2214',
        shortName:
          'Writing 2214F/G - MEMOIR, MEMORIES, & DISCLOSURE: WRITING CREATIVE NON-FICTION',
        courseName:
          'Writing 2214F/G - MEMOIR, MEMORIES, & DISCLOSURE: WRITING CREATIVE NON-FICTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cd47f7e10015fd8774',
      },
      {
        _id: '60cc26cd47f7e10015fd8776',
        courseCode: 'WRITING 2215',
        shortName: 'Writing 2215F/G - ENCODING PERSUASION:  RHETORICAL THEORY',
        courseName: 'Writing 2215F/G - ENCODING PERSUASION:  RHETORICAL THEORY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cd47f7e10015fd8776',
      },
      {
        _id: '60cc26cd47f7e10015fd8778',
        courseCode: 'WRITING 2218',
        shortName:
          'Writing 2218F/G - TO MAKE A LONG STORY SHORT: INTRODUCTION TO WRITING SHORT FICTION',
        courseName:
          'Writing 2218F/G - TO MAKE A LONG STORY SHORT: INTRODUCTION TO WRITING SHORT FICTION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cd47f7e10015fd8778',
      },
      {
        _id: '60cc26cd47f7e10015fd877a',
        courseCode: 'WRITING 2219',
        shortName:
          'Writing 2219F/G - WORD TRAVELS: INTRODUCTION TO TRAVEL WRITING',
        courseName:
          'Writing 2219F/G - WORD TRAVELS: INTRODUCTION TO TRAVEL WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cd47f7e10015fd877a',
      },
      {
        _id: '60cc26ce47f7e10015fd877c',
        courseCode: 'WRITING 2220',
        shortName:
          'Writing 2220F/G - RENEWING YOUR POETIC LICENSE: INTRODUCTION TO WRITING POETRY',
        courseName:
          'Writing 2220F/G - RENEWING YOUR POETIC LICENSE: INTRODUCTION TO WRITING POETRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ce47f7e10015fd877c',
      },
      {
        _id: '60cc26ce47f7e10015fd877e',
        courseCode: 'WRITING 2222',
        shortName: 'Writing 2222F/G - FOOD WRITING',
        courseName: 'Writing 2222F/G - FOOD WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ce47f7e10015fd877e',
      },
      {
        _id: '60cc26ce47f7e10015fd8780',
        courseCode: 'WRITING 2223',
        shortName: 'Writing 2223F/G - FASHION WRITING:  ELEMENTS OF STYLE',
        courseName: 'Writing 2223F/G - FASHION WRITING:  ELEMENTS OF STYLE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ce47f7e10015fd8780',
      },
      {
        _id: '60cc26ce47f7e10015fd8782',
        courseCode: 'WRITING 2224',
        shortName:
          'Writing 2224F/G - WRITING FOR THE BIG SCREEN: INTRODUCTION TO FEATURE FILM WRITING',
        courseName:
          'Writing 2224F/G - WRITING FOR THE BIG SCREEN: INTRODUCTION TO FEATURE FILM WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ce47f7e10015fd8782',
      },
      {
        _id: '60cc26ce47f7e10015fd8784',
        courseCode: 'WRITING 2225',
        shortName: 'Writing 2225F/G - THE INSIDE TRACK: SPORT WRITING',
        courseName: 'Writing 2225F/G - THE INSIDE TRACK: SPORT WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26ce47f7e10015fd8784',
      },
      {
        _id: '60cc26cf47f7e10015fd8786',
        courseCode: 'WRITING 2226',
        shortName:
          'Writing 2226F/G - OUT OF THE BOOK: CONTEMPORARY EXPERIMENTAL WRITING PRACTICES',
        courseName:
          'Writing 2226F/G - OUT OF THE BOOK: CONTEMPORARY EXPERIMENTAL WRITING PRACTICES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cf47f7e10015fd8786',
      },
      {
        _id: '60cc26cf47f7e10015fd8788',
        courseCode: 'WRITING 2227',
        shortName:
          'Writing 2227F/G - CRIME WRITING: BLACK DAHLIAS, RED HERRINGS AND TEQUILA SUNRISES',
        courseName:
          'Writing 2227F/G - CRIME WRITING: BLACK DAHLIAS, RED HERRINGS AND TEQUILA SUNRISES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cf47f7e10015fd8788',
      },
      {
        _id: '60cc26cf47f7e10015fd878a',
        courseCode: 'WRITING 2260',
        shortName:
          'Writing 2260F/G - WORKPLACE WRITING: EXPLORING THE CULTURES OF WORK',
        courseName:
          'Writing 2260F/G - WORKPLACE WRITING: EXPLORING THE CULTURES OF WORK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cf47f7e10015fd878a',
      },
      {
        _id: '60cc26cf47f7e10015fd878c',
        courseCode: 'WRITING 2262',
        shortName:
          'Writing 2262F/G - WRITING INSTRUCTION AND DISABILITY STUDIES FRAMEWORKS',
        courseName:
          'Writing 2262F/G - WRITING INSTRUCTION AND DISABILITY STUDIES FRAMEWORKS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26cf47f7e10015fd878c',
      },
      {
        _id: '60cc26d047f7e10015fd878e',
        courseCode: 'WRITING 2291',
        shortName: 'Writing 2291F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2291F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d047f7e10015fd878e',
      },
      {
        _id: '60cc26d047f7e10015fd8790',
        courseCode: 'WRITING 2292',
        shortName: 'Writing 2292F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2292F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d047f7e10015fd8790',
      },
      {
        _id: '60cc26d047f7e10015fd8792',
        courseCode: 'WRITING 2293',
        shortName: 'Writing 2293F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2293F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d047f7e10015fd8792',
      },
      {
        _id: '60cc26d047f7e10015fd8794',
        courseCode: 'WRITING 2294',
        shortName: 'Writing 2294F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2294F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d047f7e10015fd8794',
      },
      {
        _id: '60cc26d147f7e10015fd8796',
        courseCode: 'WRITING 2295',
        shortName: 'Writing 2295F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2295F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d147f7e10015fd8796',
      },
      {
        _id: '60cc26d147f7e10015fd8798',
        courseCode: 'WRITING 2296',
        shortName: 'Writing 2296F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2296F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d147f7e10015fd8798',
      },
      {
        _id: '60cc26d147f7e10015fd879a',
        courseCode: 'WRITING 2297',
        shortName: 'Writing 2297F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2297F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d147f7e10015fd879a',
      },
      {
        _id: '60cc26d147f7e10015fd879c',
        courseCode: 'WRITING 2298',
        shortName: 'Writing 2298F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 2298F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d147f7e10015fd879c',
      },
      {
        _id: '60cc26d147f7e10015fd879e',
        courseCode: 'WRITING 2301',
        shortName: 'Writing 2301F/G/Z - TUTORING WRITERS',
        courseName: 'Writing 2301F/G/Z - TUTORING WRITERS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d147f7e10015fd879e',
      },
      {
        _id: '60cc26d247f7e10015fd87a0',
        courseCode: 'WRITING 2500',
        shortName: 'Writing 2500A/B - SPECIAL TOPICS IN WRITING STUDIES',
        courseName: 'Writing 2500A/B - SPECIAL TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d247f7e10015fd87a0',
      },
      {
        _id: '60cc26d247f7e10015fd87a2',
        courseCode: 'WRITING 2501',
        shortName: 'Writing 2501A/B - SPECIAL TOPICS IN WRITING STUDIES',
        courseName: 'Writing 2501A/B - SPECIAL TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d247f7e10015fd87a2',
      },
      {
        _id: '60cc26d247f7e10015fd87a4',
        courseCode: 'WRITING 2502',
        shortName: 'Writing 2502A/B - SPECIAL TOPICS IN WRITING STUDIES',
        courseName: 'Writing 2502A/B - SPECIAL TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d247f7e10015fd87a4',
      },
      {
        _id: '60cc26d247f7e10015fd87a6',
        courseCode: 'WRITING 2503',
        shortName: 'Writing 2503A/B - SPECIAL TOPICS IN WRITING STUDIES',
        courseName: 'Writing 2503A/B - SPECIAL TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d247f7e10015fd87a6',
      },
      {
        _id: '60cc26d347f7e10015fd87a8',
        courseCode: 'WRITING 2504',
        shortName: 'Writing 2504A/B - SPECIAL TOPICS IN WRITING STUDIES',
        courseName: 'Writing 2504A/B - SPECIAL TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d347f7e10015fd87a8',
      },
      {
        _id: '60cc26d347f7e10015fd87aa',
        courseCode: 'WRITING 2505',
        shortName: 'Writing 2505A/B - SPECIAL TOPICS IN WRITING STUDIES',
        courseName: 'Writing 2505A/B - SPECIAL TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d347f7e10015fd87aa',
      },
      {
        _id: '60cc26d347f7e10015fd87ac',
        courseCode: 'WRITING 2520',
        shortName: 'Writing 2520A/B - WRITE NOW! - WRITERS ON WRITING',
        courseName: 'Writing 2520A/B - WRITE NOW! - WRITERS ON WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d347f7e10015fd87ac',
      },
      {
        _id: '60cc26d347f7e10015fd87ae',
        courseCode: 'WRITING 2530',
        shortName:
          'Writing 2530A/B - TV OR NOT TV: WRITING FOR THE TELEVISION INDUSTRY',
        courseName:
          'Writing 2530A/B - TV OR NOT TV: WRITING FOR THE TELEVISION INDUSTRY',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d347f7e10015fd87ae',
      },
      {
        _id: '60cc26d447f7e10015fd87b0',
        courseCode: 'WRITING 3100',
        shortName: 'Writing 3100F/G - WRITING AND ANTI-RACIST PERSPECTIVES',
        courseName: 'Writing 3100F/G - WRITING AND ANTI-RACIST PERSPECTIVES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d447f7e10015fd87b0',
      },
      {
        _id: '60cc26d447f7e10015fd87b2',
        courseCode: 'WRITING 3220',
        shortName:
          'Writing 3220F/G - OUTSIDE THE ENVELOPE:ADVANCED PROFESSIONAL COMMUNICATION',
        courseName:
          'Writing 3220F/G - OUTSIDE THE ENVELOPE:ADVANCED PROFESSIONAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d447f7e10015fd87b2',
      },
      {
        _id: '60cc26d447f7e10015fd87b4',
        courseCode: 'WRITING 3221',
        shortName:
          'Writing 3221F/G - CRIME SCENE TO COURTROOM: FORENSIC WRITING',
        courseName:
          'Writing 3221F/G - CRIME SCENE TO COURTROOM: FORENSIC WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d447f7e10015fd87b4',
      },
      {
        _id: '60cc26d447f7e10015fd87b6',
        courseCode: 'WRITING 3222',
        shortName:
          'Writing 3222F/G - PATHOLOGY TO PATHOGRAPHY: ADV HEALTHCARE COMMUNICATION',
        courseName:
          'Writing 3222F/G - PATHOLOGY TO PATHOGRAPHY: ADV HEALTHCARE COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d447f7e10015fd87b6',
      },
      {
        _id: '60cc26d447f7e10015fd87b8',
        courseCode: 'WRITING 3223',
        shortName: 'Writing 3223F/G - HOT TYPE: TECHNICAL WRITING',
        courseName: 'Writing 3223F/G - HOT TYPE: TECHNICAL WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d447f7e10015fd87b8',
      },
      {
        _id: '60cc26d547f7e10015fd87ba',
        courseCode: 'WRITING 3224',
        shortName: 'Writing 3224F/G - MINDING YOUR PS & QS: TECHNICAL EDITING',
        courseName: 'Writing 3224F/G - MINDING YOUR PS & QS: TECHNICAL EDITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d547f7e10015fd87ba',
      },
      {
        _id: '60cc26d547f7e10015fd87bc',
        courseCode: 'WRITING 3225',
        shortName: 'Writing 3225F/G - MY NAME IS URL: WRITING FOR THE WEB',
        courseName: 'Writing 3225F/G - MY NAME IS URL: WRITING FOR THE WEB',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d547f7e10015fd87bc',
      },
      {
        _id: '60cc26d547f7e10015fd87be',
        courseCode: 'WRITING 3226',
        shortName:
          'Writing 3226F/G - FIGURES OF SPEECH: WRITING FOR ORAL PRESENTATION',
        courseName:
          'Writing 3226F/G - FIGURES OF SPEECH: WRITING FOR ORAL PRESENTATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d547f7e10015fd87be',
      },
      {
        _id: '60cc26d547f7e10015fd87c0',
        courseCode: 'WRITING 3227',
        shortName: 'Writing 3227F/G - RHETORIC: LAW TALK',
        courseName: 'Writing 3227F/G - RHETORIC: LAW TALK',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d547f7e10015fd87c0',
      },
      {
        _id: '60cc26d647f7e10015fd87c2',
        courseCode: 'WRITING 3228',
        shortName: 'Writing 3228F/G - CONCEPT TO PRODUCT: PUBLISHING',
        courseName: 'Writing 3228F/G - CONCEPT TO PRODUCT: PUBLISHING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d647f7e10015fd87c2',
      },
      {
        _id: '60cc26d647f7e10015fd87c4',
        courseCode: 'WRITING 3229',
        shortName:
          'Writing 3229F/G - SELF & THE RHETORICAL TRIANGLE: AN INTRO TO INTERPERSONAL COMMUNICATION',
        courseName:
          'Writing 3229F/G - SELF & THE RHETORICAL TRIANGLE: AN INTRO TO INTERPERSONAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d647f7e10015fd87c4',
      },
      {
        _id: '60cc26d647f7e10015fd87c6',
        courseCode: 'WRITING 3300',
        shortName: 'Writing 3300F/G - INTERNSHIP IN WRITING',
        courseName: 'Writing 3300F/G - INTERNSHIP IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d647f7e10015fd87c6',
      },
      {
        _id: '60cc26d647f7e10015fd87c8',
        courseCode: 'WRITING 3400',
        shortName: 'Writing 3400F/G - WRITING DIFFERENCE',
        courseName: 'Writing 3400F/G - WRITING DIFFERENCE',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d647f7e10015fd87c8',
      },
      {
        _id: '60cc26d747f7e10015fd87ca',
        courseCode: 'WRITING 3401',
        shortName:
          "Writing 3401F/G - YOU'RE A STRANGE ANIMAL: WRITING NATURE, WRITING THE SELF",
        courseName:
          "Writing 3401F/G - YOU'RE A STRANGE ANIMAL: WRITING NATURE, WRITING THE SELF",
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d747f7e10015fd87ca',
      },
      {
        _id: '60cc26d747f7e10015fd87cc',
        courseCode: 'WRITING 3402',
        shortName:
          'Writing 3402F/G - HITTING THE RIGHT NOTES: SONG LYRIC WRITING',
        courseName:
          'Writing 3402F/G - HITTING THE RIGHT NOTES: SONG LYRIC WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d747f7e10015fd87cc',
      },
      {
        _id: '60cc26d747f7e10015fd87ce',
        courseCode: 'WRITING 3700',
        shortName: 'Writing 3700F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 3700F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d747f7e10015fd87ce',
      },
      {
        _id: '60cc26d747f7e10015fd87d0',
        courseCode: 'WRITING 3701',
        shortName: 'Writing 3701F/G - SPECIAL TOPICS IN WRITING',
        courseName: 'Writing 3701F/G - SPECIAL TOPICS IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d747f7e10015fd87d0',
      },
      {
        _id: '60cc26d847f7e10015fd87d2',
        courseCode: 'WRITING 3900',
        shortName: 'Writing 3900F/G - SPEC TOPICS IN WRITING STUDIES',
        courseName: 'Writing 3900F/G - SPEC TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d847f7e10015fd87d2',
      },
      {
        _id: '60cc26d847f7e10015fd87d4',
        courseCode: 'WRITING 3901',
        shortName: 'Writing 3901F/G - SPEC TOPICS IN WRITING STUDIES',
        courseName: 'Writing 3901F/G - SPEC TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d847f7e10015fd87d4',
      },
      {
        _id: '60cc26d847f7e10015fd87d6',
        courseCode: 'WRITING 3902',
        shortName: 'Writing 3902F/G - SPEC TOPICS IN WRITING STUDIES',
        courseName: 'Writing 3902F/G - SPEC TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d847f7e10015fd87d6',
      },
      {
        _id: '60cc26d847f7e10015fd87d8',
        courseCode: 'WRITING 3903',
        shortName: 'Writing 3903F/G - SPEC TOPICS IN WRITING STUDIES',
        courseName: 'Writing 3903F/G - SPEC TOPICS IN WRITING STUDIES',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d847f7e10015fd87d8',
      },
      {
        _id: '60cc26d847f7e10015fd87da',
        courseCode: 'WRITING 4700',
        shortName: 'Writing 4700F/G - INDEPENDENT STUDY IN WRITING',
        courseName: 'Writing 4700F/G - INDEPENDENT STUDY IN WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d847f7e10015fd87da',
      },
      {
        _id: '60cc26d947f7e10015fd87dc',
        courseCode: 'WRITING 4880',
        shortName: 'Writing 4880F/G - SEMINAR IN CREATIVE WRITING',
        courseName: 'Writing 4880F/G - SEMINAR IN CREATIVE WRITING',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d947f7e10015fd87dc',
      },
      {
        _id: '60cc26d947f7e10015fd87de',
        courseCode: 'WRITING 4881',
        shortName: 'Writing 4881F/G - SEMINAR IN PROFESSIONAL COMMUNICATION',
        courseName: 'Writing 4881F/G - SEMINAR IN PROFESSIONAL COMMUNICATION',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d947f7e10015fd87de',
      },
      {
        _id: '60cc26d947f7e10015fd87e0',
        courseCode: 'WRITING 4998',
        shortName:
          'Writing 4998F/G - RE-VISIONING SELF: CREATING YOUR PROFESSIONAL PORTFOLIO',
        courseName:
          'Writing 4998F/G - RE-VISIONING SELF: CREATING YOUR PROFESSIONAL PORTFOLIO',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d947f7e10015fd87e0',
      },
      {
        _id: '60cc26d947f7e10015fd87e2',
        courseCode: 'WRITING 4999',
        shortName: 'Writing 4999E - CREATIVE WRITING THESIS',
        courseName: 'Writing 4999E - CREATIVE WRITING THESIS',
        university: '60cc1a0747f7e10015fd5945',
        id: '60cc26d947f7e10015fd87e2',
      },
    ],
    id: '60cc1a0747f7e10015fd5945',
  },
];
