import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import CreateEvent from '../../event/CreateEvent';

// context
import { ClubContext } from '../privatePages/ViewClubPage';
// queries
import { useCreateNewClubEvent_Mutation } from '../../../queries/clubQueries';

const CreateClubEventModal = () => {
  const { eventModalShow, closeEventModal, _id } = useContext(ClubContext);

  const { mutate: createNewClubEvent } = useCreateNewClubEvent_Mutation(_id);

  return (
    <Modal show={eventModalShow} onHide={closeEventModal} size='lg'>
      <Modal.Body>
        <CreateEvent customCreateNewEvent={createNewClubEvent} clubId={_id} />
      </Modal.Body>
    </Modal>
  );
};

export default CreateClubEventModal;
