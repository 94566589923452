import React, { Fragment } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';

const SearchHousingBox = () => {
  return (
    <Fragment>
      <Input
        className='rounded-pill'
        type='select'
        id='rentalTermSelect'
        name='rentalTerm'
        // value={`Search Houses near McMaster University`}
        required
      >
        <option value={null}>Search Houses Near...</option>
        <option value='mcmaster'>{'Housing near McMaster University '}</option>
        {/* <option value='queens'>{`Queen's University`}</option>
                <option value='toronto'>{'University of Toronto'}</option> */}
      </Input>
    </Fragment>
  );
};

SearchHousingBox.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SearchHousingBox);
