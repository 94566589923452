import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  Button,
} from 'reactstrap';

import Registration from '../auth/basic/Registration';

const LandingRightSideNavItem = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  return (
    <Nav navbar className='ml-auto'>
      <NavItem>
        <Button
          tag={Link}
          to={`/authentication/login`}
          color='light'
          className='fs--1 border-1x rounded-pill mr-2 text-dark mb-2 mb-lg-0'
        >
          Login
        </Button>
      </NavItem>
      <NavItem>
        <Button
          color='secondary'
          className='fs--1 border-1x rounded-pill '
          onClick={() => setShowRegistrationModal(!showRegistrationModal)}
        >
          Sign Up
        </Button>
        <Modal
          isOpen={showRegistrationModal}
          centered
          toggle={() => setShowRegistrationModal(!showRegistrationModal)}
        >
          <ModalBody className='p-0'>
            <Card>
              <CardBody className='fs--1 font-weight-normal p-4'>
                <Registration />
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </NavItem>
    </Nav>
  );
};

export default LandingRightSideNavItem;
