import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

// components
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';
import ChatStatusIcon from './chatNotification/ChatStatusIcon';

// misc
import AppContext from '../../context/Context';

const TopNavRightSideNavItem = ({ authReduxState: { userSetupStage } }) => {
  const { isTopNav } = useContext(AppContext);
  return (
    <Nav
      navbar
      className='navbar-nav-icons ml-auto flex-row align-items-center'
    >
      <NavItem>
        <SettingsAnimatedIcon />
      </NavItem>
      {isTopNav && (
        <NavItem className='d-none d-md-block p-2 px-lg-0 cursor-pointer'>
          <NavLink tag={Link} to='/changelog' id='changelog'>
            <FontAwesomeIcon icon='code-branch' transform='right-6 grow-4' />
          </NavLink>
          <UncontrolledTooltip
            autohide={false}
            placement='left'
            target='changelog'
          >
            Changelog
          </UncontrolledTooltip>
        </NavItem>
      )}
      <NotificationDropdown />
      {userSetupStage === 'done' && <ChatStatusIcon />}
      <ProfileDropdown />
    </Nav>
  );
};

TopNavRightSideNavItem.propTypes = {
  authReduxState: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  authReduxState: state.authReducer,
});

export default connect(mapStateToProps)(TopNavRightSideNavItem);
