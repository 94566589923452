import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// **libraries
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row, Spinner, Button } from 'reactstrap';

// ** actions
import { getHouseAdById_Action } from '../../redux/actions/housingActions';

// ** components
import Flex from '../common/Flex';
import ProductDetailsMainHousingAd from '../landlord/previewHousingAd/product-details/ProductDetailsMainHousingAd';
import ProductDetailsMediaHousingAd from '../landlord/previewHousingAd/product-details/ProductDetailsMediaHousingAd';
import ProductDetailsFooterHousingAd from '../landlord/previewHousingAd/product-details/ProductDetailsFooterHousingAd';
import {
  posthog_HousingAdOpened,
  posthog_HousingAdOpened_Public,
} from '../../utils/posthogEvents/posthogHousingEvents';
import ContactLandlord from './housingProductComponents/ContactLandlord';

const OpenSingleHousingAd = ({
  getHouseAdById_Action,
  housingState: { houseAd, loading },
  user,
  // ui params
  showBackBtn,
}) => {
  const history = useHistory();
  const { id: houseId } = useParams();

  function handleGoBack() {
    history.goBack();
  }

  const _id = houseId;

  useEffect(() => {
    if (houseId) {
      getHouseAdById_Action(houseId);
    }
  }, [getHouseAdById_Action, houseId]);

  useEffect(() => {
    if (houseAd && user) {
      posthog_HousingAdOpened(houseAd, user);
    } else if (houseAd) {
      posthog_HousingAdOpened_Public(houseAd);
    }
    // eslint-disable-next-line
  }, [houseAd]);

  return (
    <Fragment>
      {houseAd === null || loading ? (
        <div className='text-center'>
          <Spinner color='primary' />
        </div>
      ) : (
        <Fragment>
          {showBackBtn ? (
            <Card className='mb-3'>
              <CardBody className='my-0'>
                <Row>
                  <Col>
                    <Button
                      className='btn'
                      color='primary'
                      onClick={handleGoBack}
                    >
                      {`Go Back`}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}

          <Card>
            <CardBody>
              <Row>
                <Col lg={6} className='mb-4 mb-lg-0'>
                  <ProductDetailsMediaHousingAd {...houseAd} />
                </Col>
                <Col lg={6} tag={Flex} justify='between' column>
                  <ProductDetailsMainHousingAd id={_id} {...houseAd} />
                </Col>
              </Row>
              <ProductDetailsFooterHousingAd {...houseAd} />
              {/* // discuss - other situtations when to show the contact landlord form */}
              {/* only shows the contact form if the user is loggedin and status of the ad is paid_ad_show */}
              {user ? (
                <Fragment>
                  {houseAd.status === 'paid_ad_show' && (
                    <ContactLandlord landlordEmail={houseAd.email} />
                  )}
                </Fragment>
              ) : (
                <Button color='warning' block className='mt-3 text-dark'>
                  Login to Contact Landlord Directly
                </Button>
              )}
            </CardBody>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

OpenSingleHousingAd.propTypes = {
  getHouseAdById_Action: PropTypes.func.isRequired,
  housingState: PropTypes.object.isRequired,
  user: PropTypes.object,

  showBackBtn: PropTypes.bool,
};

OpenSingleHousingAd.defaultProps = {
  showBackBtn: true,
};

const mapStateToProps = (state) => ({
  housingState: state.housingReducer,
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { getHouseAdById_Action })(
  OpenSingleHousingAd
);
