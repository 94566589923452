import React from 'react';
import PropTypes from 'prop-types';

// libraries
import { Col, Card, CardBody, Row } from 'reactstrap';

const Banner = ({ title, description, img, children }) => {
  return (
    <Card>
      <CardBody className='overflow-hidden p-lg-6'>
        <Row className='align-items-center justify-content-between'>
          <Col lg={6}>
            <img src={img} className='img-fluid' alt='' />{' '}
          </Col>
          <Col lg={6} className='pl-lg-4 my-5 text-center text-lg-left'>
            <h3>{title}</h3>
            <p className='lead'>{description}</p>
            {children}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default React.memo(Banner);
