import axios from 'axios';
import { useQuery } from 'react-query';

// misc
import { axiosErrorToast } from '../utils/toastHandler';

export const useGetUniversities_Query = () => {
  return useQuery(
    'unis',
    () =>
      axios.get('/api/v2/university').then((res) => res.data.dataPayload.unis),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetUniversityByName_Query = (universityName) => {
  return useQuery(
    ['univeristy', universityName],
    () =>
      axios
        .get(`/api/v2/university/name/${universityName}`)
        .then((res) => res.data.dataPayload.university),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetUniversityCourseGroups_Query = (universityName) => {
  return useQuery(
    ['univeristyCourseGroups', universityName],
    () =>
      axios
        .get(`/api/v2/university/coursegroups/${universityName}`)
        .then((res) => res.data.dataPayload.university),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetUniversityById_Query = (universityId) => {
  return useQuery(
    ['univeristy', universityId],
    () =>
      axios
        .get(`/api/v2/university/${universityId}`)
        .then((res) => res.data.dataPayload.university),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};
