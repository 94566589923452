import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_AUTH_REDUCER_LOADING,
  SET_AUTH_REDUCER_LOADING_FALSE,
} from '../types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  userSetupStage: null,
  loading: false,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        userSetupStage: payload.user.profileSetupStage,
        loading: false,
        user: payload.user,
      };
    case SET_AUTH_REDUCER_LOADING:
      return { ...state, loading: true };
    case SET_AUTH_REDUCER_LOADING_FALSE:
      return { ...state, loading: false };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.tokenPayload.token);
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
        loading: false,
      };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return initialState;
    default:
      return state;
  }
}
