import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Media } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';

// components
import PeernetLastMessage from '../../chat/sidebar/PeernetLastMessage';
import Avatar from '../../common/Avatar';
import Flex from '../../common/Flex';

//actions
import { newMessageRecieved_Action } from '../../../redux/actions/chatActions';

const NavChatSidebarItem = ({
  thread,

  chatState: { activeThreadId },
}) => {
  const history = useHistory();

  const lastMsgFromChat = thread.messages[thread.messages.length - 1];
  function formatTime(time) {
    //now - 4 days > time -> format else bottom
    const curr = new Date(time).getTime();
    const time4DaysAgo = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
    const time3MonthAgo = new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
    if (time4DaysAgo < curr) {
      // The yourDate time is less than 4 days from now
      return <Moment fromNow>{time}</Moment>;
    } else if (time3MonthAgo < curr) {
      return <Moment format='ddd MMM D'>{time}</Moment>;
    } else {
      return <Moment format='MMM D'>{time}</Moment>;
    }
  }

  // const lastMsgDate = new Date(lastMsgFromChat.createdAt);
  const lastMessage = {
    senderUserId: 5,
    messageSenderUserId: lastMsgFromChat ? lastMsgFromChat.sender : '',
    message: lastMsgFromChat ? lastMsgFromChat.content : '',
    time: {
      day: lastMsgFromChat ? formatTime(lastMsgFromChat.createdAt) : '',
      // hour: '11:54 AM',
      // date: 'May 19, 2019',
    },
  };

  return (
    <Media
      className={classNames(
        `chat-contact hover-actions-trigger w-100 border border-bottom-0`,
        {
          'unread-message': !thread.threadRead,
          'read-message': thread.threadRead,
          active: thread._id === activeThreadId,
        }
      )}
      onClick={() => {
        // open specific chat
        history.push(`/chatrooms?chatId=${thread._id}`);
      }}
    >
      <Avatar className={thread._id} src={thread.chatAvatar} size='xl' />
      <Media body className='chat-contact-body ml-2 d-md-none d-lg-block'>
        <Flex justify='between'>
          <h6 className='mb-0 chat-contact-title'>{thread.chatTitle}</h6>
          <span className='message-time fs--2'>
            {' '}
            {!!lastMessage && lastMessage.time.day}{' '}
          </span>
        </Flex>
        <div className='min-w-0'>
          <div
            className='chat-contact-content pr-3'
            dangerouslySetInnerHTML={{
              __html: PeernetLastMessage(lastMessage, thread),
            }}
          />
          <div className='position-absolute b-0 r-0 hover-hide'>
            {!!lastMessage?.status && (
              <FontAwesomeIcon
                icon={classNames({
                  check:
                    lastMessage.status === 'seen' ||
                    lastMessage.status === 'sent',
                  'check-double': lastMessage.status === 'delivered',
                })}
                transform='shrink-5 down-4'
                className={classNames({
                  'text-success': lastMessage.status === 'seen',
                  'text-400':
                    lastMessage.status === 'delivered' ||
                    lastMessage.status === 'sent',
                })}
              />
            )}
          </div>
        </div>
      </Media>
    </Media>
  );
};

NavChatSidebarItem.propTypes = {
  thread: PropTypes.object.isRequired,

  //state
  chatState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  chatState: state.chatReducer,
  authState: state.authReducer,
});

export default connect(mapStateToProps, {
  newMessageRecieved_Action,
})(NavChatSidebarItem);
