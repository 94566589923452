import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_ViewUniversityPublic_Event = (uni) => {
  if (uni && uni._id && uni.name) {
    posthog.capture('viewUniversityPublic', {
      viewUniversityPublic___universityId: uni._id,
      viewUniversityPublic___universityName: uni.name,
    });

    appInsights.trackEvent(
      { name: 'viewUniversityPublic' },
      {
        viewUniversityPublic___universityId: uni._id,
        viewUniversityPublic___universityName: uni.name,
      }
    );
  }
};

export const posthog_ViewUniversity_Event = (uni, user) => {
  if (uni && uni._id && uni.name && user && user._id) {
    posthog.capture('viewUniversity', {
      viewUniversity___universityId: uni._id,
      viewUniversity___universityName: uni.name,
      userId: user?._id,
    });

    appInsights.trackEvent(
      { name: 'viewUniversity' },
      {
        viewUniversity___universityId: uni._id,
        viewUniversity___universityName: uni.name,
        userId: user?._id,
      }
    );
  }
};

export const posthog_GPACalculatUsed_Event = (user) => {
  if (user && user._id) {
    posthog.capture('gpaCalculatorUsed', {
      userId: user?._id,
    });

    appInsights.trackEvent(
      { name: 'gpaCalculatorUsed' },
      {
        userId: user?._id,
      }
    );
  }
};
