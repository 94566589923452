import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import Member from '../components/Member';
import Loader from '../../common/Loader';
import FalconCardHeader from '../../common/FalconCardHeader';
import { isIterableArray } from '../../../helpers/utils';
import { ClubContext } from '../privatePages/ViewClubPage';

// static assets
import undrawTeamImg from '../../../assets/img/undraw/undraw_team_ih79.svg';

const ClubMembersTab = ({ className, clubData }) => {
  const { members } = useContext(ClubContext);

  // const memberCategories = useMemo(() => {
  //   if (isIterableArray(members)) {
  //     const uniqueRoles = new Set();

  //     for (let i = 0; i < members.length; i++) {
  //       for (let j = 0; j < members[i].roles.length; j++) {
  //         uniqueRoles.add(members[i].roles[j]);
  //       }
  //     }
  //     return Array.from(uniqueRoles);
  //   }
  // }, [members]);

  // const searchPeople = ({ target }) => {
  //   const keyword = target.value.toLowerCase();
  //   const filteredResult = rawPeople.filter(
  //     (person) =>
  //       person.name.toLowerCase().includes(keyword) ||
  //       person.institution.toLowerCase().includes(keyword)
  //   );

  //   setPeople(keyword.length ? filteredResult : rawPeople);
  // };

  // const tempPeople = () => people.slice(0, peoples);

  return (
    <Fragment>
      {isIterableArray(members) && members?.length > 0 ? (
        <Fragment>
          <Row className='mt-3'>
            <Col>
              <Card>
                <FalconCardHeader title={`Club Members (${members.length})`}>
                  {/* <Form inline>
                    <Input
                      bsSize='sm'
                      placeholder='Search...'
                      // onChange={searchPeople}
                    />
                    <CustomInput
                      type='select'
                      id='exampleCustomSelect'
                      name='customSelect'
                      bsSize='sm'
                      className='d-md-block d-none ml-2'
                    >
                      {memberCategories.map((option, index) => (
                        <option value={index} key={index}>
                          {option}
                        </option>
                      ))}
                    </CustomInput>
                  </Form> */}
                </FalconCardHeader>
                <CardBody className='bg-light p-0'>
                  {!members ? (
                    <Loader />
                  ) : isIterableArray(members) ? (
                    <Row noGutters className='text-center fs--1'>
                      {members.map(({ userId }, index) => (
                        <Col className={className} key={userId._id}>
                          {/* only populate if member info is available */}
                          {userId && userId.name && userId.uni && (
                            <Member
                              avatarSrc={userId?.avatar}
                              name={userId.name}
                              institution={userId.uni}
                              profileLink={`/profile/${userId._id}`}
                            />
                          )}
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <Row className='p-card'>
                      <Col>
                        <Alert color='info' className='mb-0'>
                          No Clubs Members Found!
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <div className='mt-3'>
          <Card>
            <CardBody className='overflow-hidden p-lg-6'>
              <Row className='align-items-center justify-content-between'>
                <Fragment>
                  <Col lg={6} xl={4}>
                    <img src={undrawTeamImg} className='img-fluid' alt='' />{' '}
                  </Col>
                  <Col
                    lg={6}
                    xl={8}
                    className='pl-lg-4 my-5 text-center text-lg-left'
                  >
                    <h3>{`See all club members!`}</h3>
                    <p className='lead'>{`We'll Integrate a directory for the club so you can easily contact and collaborate with other members
                    `}</p>
                  </Col>
                </Fragment>{' '}
              </Row>
            </CardBody>
            {clubData?.isMember !== true && (
              <Row className='p-card'>
                <Col>
                  <Alert color='warning' className='mb-0'>
                    Join club to see members
                  </Alert>
                </Col>
              </Row>
            )}
          </Card>
        </div>
      )}
    </Fragment>
  );
};
ClubMembersTab.propTypes = {
  className: PropTypes.string,
  clubData: PropTypes.object,
};

ClubMembersTab.defaultProps = {
  className: 'col-6 col-md-4 col-lg-3 col-xxl-2',
};

export default ClubMembersTab;
