import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

// libraries
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Dropdown,
} from 'reactstrap';

// misc
import AuthCornerImage from '../../assets/img/illustrations/authentication-corner.png';
import { breakpoints } from '../../helpers/utils';
import { topNavbarBreakpoint } from '../../config';

const NavbarDropdownSimple = ({
  title,
  items,
  right,
  children,
  handleSetNavbarCollapsed,
  showCoolBg,
  dropdownStyle,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
          setDropdownOpen(true);
        }
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
          setDropdownOpen(false);
        }
      }}
    >
      <DropdownToggle nav caret>
        {title}
      </DropdownToggle>
      <DropdownMenu right={right} className='dropdown-menu-card mt-0'>
        {items.length > 0 && (
          <Card
            className={classNames('shadow-none max-h-dropdown', {
              'navbar-card-pages': dropdownStyle === 'widest',
              'navbar-card-auth': dropdownStyle === 'wide',
            })}
          >
            {showCoolBg === true && (
              <img
                src={AuthCornerImage}
                alt=''
                className='position-absolute b-0 r-0'
                width={130}
              />
            )}
            <CardBody className={classNames('scrollbar')}>
              {items.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <DropdownItem
                      onClick={handleSetNavbarCollapsed}
                      className={'px-0'}
                    >
                      <div className='nav-link py-1 pl-0 text-900 font-weight-bold'>
                        {item.name}
                      </div>
                    </DropdownItem>
                    <Row className='mb-3'>
                      {item.children.map((groupItem, index) => {
                        return (
                          <Fragment key={`${index}-${groupItem.name}`}>
                            <DropdownItem
                              tag={Link}
                              to={groupItem.to}
                              key={index}
                              onClick={handleSetNavbarCollapsed}
                            >
                              {groupItem.name}
                              {groupItem.badge && (
                                <Badge
                                  color={
                                    groupItem.badge.color || 'soft-success'
                                  }
                                  pill
                                  className='ml-2'
                                >
                                  {groupItem.badge.text}
                                </Badge>
                              )}
                            </DropdownItem>
                          </Fragment>
                        );
                      })}
                    </Row>
                  </Fragment>
                );
              })}
            </CardBody>
          </Card>
        )}
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};

NavbarDropdownSimple.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  items: PropTypes.array,
  right: PropTypes.bool,
  handleSetNavbarCollapsed: PropTypes.func,
  showCoolBg: PropTypes.bool,
  dropdownStyle: PropTypes.string,
};

NavbarDropdownSimple.defaultProps = {
  items: [],
  right: false,
  children: null,
  showCoolBg: true,
  dropdownStyle: 'slim',
};

export default NavbarDropdownSimple;
