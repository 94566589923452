export const resourceToEnumMap = {
  Exam: 'exam',
  'Exam solution': 'exam_solution',
  Test: 'test',
  'Test 1': 'test_1',
  'Test 2': 'test_2',
  'Test solution': 'test_solution',
  Lab: 'lab',
  Solution: 'solution',
  Quiz: 'quiz',
  Notes: 'notes',
  Presentation: 'presentation',
  Assignment: 'assignment',
  Homework: 'homework',
  Tutorial: 'tutorial',
  Other: 'other',
};

export const enumToResourceMap = {
  exam: 'Exam',
  exam_solution: 'Exam solution',
  test: 'Test',
  test_1: 'Test 1',
  test_2: 'Test 2',
  test_solution: 'Test solution',
  lab: 'Lab',
  solution: 'Solution',
  quiz: 'Quiz',
  notes: 'Notes',
  presentation: 'Presentation',
  assignment: 'Assignment',
  homework: 'Homework',
  tutorial: 'Tutorial',
  other: 'Other',
};

export const resourceStatusToEnumMap = {
  review: 'review',
  rejected: 'rejected',
  accepted: 'accepted',
  'to delete': 'to_delete',
};

export const enumToResourceStatusMap = {
  review: 'review',
  rejected: 'rejected',
  accepted: 'accepted',
  to_delete: 'to delete',
};

export const enumToAvailabilityMap = {
  Pre_9am: 'Pre 9am',
  '9am_12pm': '9am - 12pm',
  '12pm_3pm': '12pm - 3pm',
  '3pm_6pm': '3pm - 6pm',
  '6pm_9pm': '6pm - 9pm',
  After_9pm: 'After - 9pm',
};
