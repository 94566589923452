import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

// queries
import { useGetCourseResourcePublic_Query } from '../../../queries/resourceQueries';

// libraries
import { Spinner } from 'reactstrap';
import PageHeader from '../../common/PageHeader';
import { Button } from 'reactstrap';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import ShowResStats from '../resComponents/ShowResStats';
import ViewResTypeImage from '../resComponents/ShowResFile';

// utils
import { decodeURLSafeToStr } from '../../../utils/encryption';

// Events
import { posthog_viewResourcePublic_Event } from '../../../utils/posthogEvents/posthogResourceEvents';
import { posthog_sharedLinkOpened_Event } from '../../../utils/posthogEvents/posthogSharedLinkEvents';

const ViewSingleResourcePublic = () => {
  const { id } = useParams();
  const history = useHistory();

  const [fetchResource, setFetchResource] = useState(false);
  const [resourceId, setResourceId] = useState('');

  const {
    isError: resourceError,
    isLoading: resourceLoading,
    data: resource,
  } = useGetCourseResourcePublic_Query(
    resourceId,
    fetchResource && resourceId !== ''
  );

  useEffect(() => {
    const encodedData = JSON.parse(decodeURLSafeToStr(id));
    const now = new Date();
    const expiry = new Date(encodedData.shareLinkOpened___expiryDate);

    // track event
    posthog_sharedLinkOpened_Event(encodedData, now, expiry);

    if (now > expiry) {
      history.push('/authentication/register');
    } else {
      setFetchResource(true);
      setResourceId(encodedData.shareLinkOpened___resourceId);
    }
  }, [setResourceId, setFetchResource, id, history]);

  useEffect(() => {
    if (resourceId !== '') {
      posthog_viewResourcePublic_Event(resourceId);
    }
  }, [resourceId]);

  return (
    <Fragment>
      {(resource === undefined && resourceError === false) ||
      resourceLoading ? (
        <div className='text-center'>
          <Spinner color='primary' />
        </div>
      ) : (
        <Fragment>
          <NavbarStandard useDark={true} />
          <div className='container mt-4' style={{ paddingTop: '60px' }}>
            <PageHeader title={`${resource.resourceName}`} className='mb-3'>
              <Button tag='a' color='link' size='m' className='pl-0'>
                <Link
                  className='stretched-link'
                  to={`/course/${resource.course ? resource.course._id : null}`}
                >
                  {`${
                    resource.course
                      ? resource.course.shortName
                      : 'No course attacted to his resource'
                  }`}
                </Link>
              </Button>
            </PageHeader>

            <ShowResStats resource={resource} />

            <ViewResTypeImage resource={resource} />

            <Link
              to={`/course/${resource.course ? resource.course._id : null}`}
            >
              <Button color='success' size='m' className='py-2 mb-4' block>
                {`${
                  resource.course
                    ? `See more resources for ${resource.course.shortName}`
                    : 'No course attacted to his resource'
                }`}
              </Button>
            </Link>
          </div>
          <Cta />
          <FooterStandard />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ViewSingleResourcePublic;
