import React from 'react';
import PropTypes from 'prop-types';

// components
import Accordion from './Accordion';

// misc
import { isIterableArray } from '../../../helpers/utils';

const Accordions = ({
  items,
  titleKey,
  descriptionKey,
  isOpenKey,
  isAdmin,
  adminView,
}) => {
  return (
    <div className='border-x border-top'>
      {isIterableArray(items) &&
        items.map((item, index) => (
          <Accordion
            isAdmin={isAdmin}
            adminView={adminView}
            title={item[titleKey]}
            description={item[descriptionKey]}
            key={index}
            open={!!item[isOpenKey]}
          />
        ))}
    </div>
  );
};

Accordions.propTypes = {
  items: PropTypes.array.isRequired,
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string.isRequired,
  isOpenKey: PropTypes.string,
  isAdmin: PropTypes.bool,
  adminView: PropTypes.bool,
};

export default Accordions;
