import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

// libraries
import Modal from 'react-bootstrap/Modal';

// assets
import shareActive from '../../../../assets/img/illustrations/share-active.png';
import shareInactive from '../../../../assets/img/illustrations/share-inactive.png';

// components
import SendToPeerForm from './SendToPeerForm';

const SendToPeerModal = ({ share, postId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <img
        src={share ? shareActive : shareInactive}
        alt=''
        width='20'
        onClick={handleShow}
      />
      <span className='ml-1' onClick={handleShow}>
        Send to Peer
      </span>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Body>
          <SendToPeerForm closeModal={handleClose} postId={postId} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

SendToPeerModal.propTypes = {
  share: PropTypes.bool,
  postId: PropTypes.string.isRequired,
};

SendToPeerModal.defaultProps = {
  share: true,
};

export default SendToPeerModal;
