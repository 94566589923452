import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'query-string';
import { Form } from 'reactstrap';

//actions
import {
  createHousingAdPublic_Action,
  getUnFinshedHousingAd_Action,
  updateHousingAdPrivate_Action,
  updateHousingAdPublic_Action,
} from '../../../../redux/actions/createHousingAdAction';

//components
import CreateAdForm from '../components/createAdComponents/CreateAdForm';
import SelectPlan from '../components/createAdComponents/SelectPlan';
import CheckoutWithStripe from '../components/checkoutComponents/CheckoutWithStripe';
import PreviewHousingAd from '../../previewHousingAd/PreviewHousingAd';
import UpdateAdForm from '../components/updateAdComponents/UpdateAdForm';
import PageHeader from '../../../common/PageHeader';

// static
import { UNIS } from '../../../static/staticUniInfo';

const CreateAdPagePublic = ({
  //actions
  createHousingAdPublic_Action,
  getUnFinshedHousingAd_Action,
  updateHousingAdPrivate_Action,
  updateHousingAdPublic_Action,

  //state
  createAdState: { justCreatedAd, justCreatedFormatted },
  authState: { loading: userLoading, isAuthenticated },
}) => {
  const history = useHistory();
  const location = useLocation();
  const paramsInfo = qs.parse(location.search);
  let hid = '';
  hid = paramsInfo.hid || paramsInfo.HID;
  let uid = '';
  uid = paramsInfo.uid || paramsInfo.UID;

  const [showPreviewComponent, setShowPreviewComponent] = useState(false);

  const [, setAutoCompleteAddress] = useState(null);

  // const autoCompleteProvidedStreetAddress = `${
  //   autoCompleteAddress?.value?.terms[0].value
  // } ${autoCompleteAddress?.value?.terms[1].value}`;
  // const autoCompleteProvidedCity = autoCompleteAddress?.value?.terms[2].value;

  const [plan, setPlan] = useState('premium');
  const [adInfo, setAdInfo] = useState({
    rentalType: '',
    rentalTerm: '',
    rent: '',

    availableBedrooms: '',
    totalBedrooms: '',
    totalBathrooms: '',

    streetAddress: '',
    unitNumber: '',
    propertyCity: '',
    postalCode: '',

    description: '',

    dateAvailable: new Date(Date.now()),
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    contactMethod: '',

    amenities: [],
    utilities: [],
    accessibilityInfo: [],
  });
  const [adImgs, setAdImgs] = useState([]);

  useEffect(() => {
    if (hid && hid.length === 24) {
      setShowPreviewComponent(true);
      //api call to get housing ad
      getUnFinshedHousingAd_Action(hid, history);
    } else {
      setShowPreviewComponent(false);
      history.push({ search: '' });
      setAdImgs([]);
      setPlan('premium');
      setAdInfo({
        rentalType: '',
        rentalTerm: '',
        rent: '',

        availableBedrooms: '',
        totalBedrooms: '',
        totalBathrooms: '',

        streetAddress: '',
        unitNumber: '',
        propertyCity: '',
        postalCode: '',

        description: '',

        dateAvailable: new Date(Date.now()),
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        contactMethod: '',

        amenities: [],
        utilities: [],
        accessibilityInfo: [],
      });
    }
  }, [hid]);

  useEffect(() => {
    if (justCreatedAd && justCreatedAd.images) {
      setAdImgs(justCreatedAd.images);
    }
    if (justCreatedAd && justCreatedAd.plan) {
      setPlan(justCreatedAd.plan);
    }
    if (Object.keys(justCreatedFormatted).length !== 0) {
      setAdInfo({
        ...adInfo,
        rentalType: justCreatedFormatted.rentalType,
        rentalTerm: justCreatedFormatted.rentalTerm,
        rent: justCreatedFormatted.rent,

        availableBedrooms: justCreatedFormatted.availableBedrooms,
        totalBedrooms: justCreatedFormatted.totalBedrooms,
        totalBathrooms: justCreatedFormatted.totalBathrooms,

        streetAddress: justCreatedFormatted.streetAddress,
        unitNumber: justCreatedFormatted.unitNumber,
        propertyCity: justCreatedFormatted.propertyCity,
        postalCode: justCreatedFormatted.postalCode,

        description: justCreatedFormatted.description,

        dateAvailable: new Date(justCreatedFormatted.dateAvailable),
        contactName: justCreatedFormatted.contactName,
        contactPhone: justCreatedFormatted.contactPhone,
        contactEmail: justCreatedFormatted.contactEmail,
        contactMethod: justCreatedFormatted.contactMethod,

        amenities: justCreatedFormatted.amenities,
        utilities: justCreatedFormatted.utilities,
        accessibilityInfo: justCreatedFormatted.accessibilityInfo,
      });
    }
  }, [justCreatedAd]);

  const compareLocalWithRedux = () => {
    const diff = {};

    let aa = justCreatedFormatted;
    let bb = { ...adInfo, images: adImgs, plan };
    const keys = Object.keys(aa).sort();

    keys.forEach((key, index) => {
      if (key === 'dateAvailable') {
        var month1 = aa[key].getUTCMonth() + 1; //months from 1-12
        var day1 = aa[key].getUTCDate();
        var year1 = aa[key].getUTCFullYear();

        var month2 = bb[key].getUTCMonth() + 1; //months from 1-12
        var day2 = bb[key].getUTCDate();
        var year2 = bb[key].getUTCFullYear();

        if (month1 !== month2 || day1 !== day2 || year1 !== year2) {
          diff[key] = bb[key];
        }
      } else {
        diff[key] = bb[key];
      }
    });

    return diff;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (adImgs.length <= 0) {
      toast.error('Please Add Images for your Ad');
    } else {
      createHousingAdPublic_Action(
        {
          plan: plan,
          adInfo: { ...adInfo },
          adImgs: adImgs,
        },
        history
      );
      setShowPreviewComponent(true);

      // clear states
      // setAdImgs([]);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    //compare which feilds have been updated between FE and redux
    //package those into a json
    //send to update action function
    const toSend = compareLocalWithRedux();
    if (adImgs.length <= 0) {
      toast.error('Please Add Images for your Ad');
    } else if (Object.keys(toSend).length === 0 || toSend === {}) {
      toast.warning('No changes made yet');
    } else {
      if (isAuthenticated === true) {
        updateHousingAdPrivate_Action(hid, {
          plan: plan,
          adInfo: { ...adInfo },
          adImgs: adImgs,
        });
      } else {
        updateHousingAdPublic_Action(
          hid,
          {
            plan: plan,
            adInfo: { ...adInfo },
            adImgs: adImgs,
          },
          justCreatedAd.status === 'created_public' ? hid : uid
        );
      }
    }
  };

  return (
    <Fragment>
      <div className='container'>
        <PageHeader
          title={
            justCreatedAd && justCreatedAd.paid === 'no'
              ? 'Create your Ad'
              : Object.keys(justCreatedAd).length === 0
              ? 'Create your Ad'
              : 'Modify your Ad'
          }
          className='mb-3'
        />

        {(Object.keys(justCreatedAd).length === 0 ||
          (justCreatedAd && justCreatedAd.paid === 'no')) && (
          <SelectPlan
            selectedPlan={plan}
            setPlanParent={setPlan}
            showPreviewComponent={showPreviewComponent}
          />
        )}

        {hid && hid.length > 0 ? (
          <Fragment>
            <Form onSubmit={(e) => handleUpdate(e)}>
              <UpdateAdForm
                formData={adInfo}
                setFormData={setAdInfo}
                adImgs={adImgs}
                setAdImgs={setAdImgs}
                plan={plan}
                onChangeAutoCompleteAddressParent={(value) =>
                  setAutoCompleteAddress(value)
                }
                universities={UNIS}
              />
            </Form>
          </Fragment>
        ) : (
          <Fragment>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <CreateAdForm
                formData={adInfo}
                setFormData={setAdInfo}
                adImgs={adImgs}
                setAdImgs={setAdImgs}
                plan={plan}
                onChangeAutoCompleteAddressParent={(value) =>
                  setAutoCompleteAddress(value)
                }
                universities={UNIS}
              />
            </Form>
          </Fragment>
        )}

        {showPreviewComponent ? (
          <Fragment>
            <PreviewHousingAd className='text-dark' />

            {Object.keys(justCreatedAd).length === 0 ||
            (justCreatedAd && justCreatedAd.paid === 'no') ? (
              <CheckoutWithStripe />
            ) : null}
          </Fragment>
        ) : null}
      </div>
    </Fragment>
  );
};

CreateAdPagePublic.propTypes = {
  //state
  createAdState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,

  //action functions
  createHousingAdPublic_Action: PropTypes.func.isRequired,
  getUnFinshedHousingAd_Action: PropTypes.func.isRequired,
  updateHousingAdPrivate_Action: PropTypes.func.isRequired,
  updateHousingAdPublic_Action: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  createAdState: state.createHousingAdReducer,
  authState: state.authReducer,
});

export default connect(mapStateToProps, {
  createHousingAdPublic_Action,
  getUnFinshedHousingAd_Action,
  updateHousingAdPrivate_Action,
  updateHousingAdPublic_Action,
})(CreateAdPagePublic);
