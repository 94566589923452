import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

// components
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import ClubFeedSideBar from './ClubFeedSideBar';
import PostOnClubFeedForm from './PostOnClubFeedForm';
import FeedCardPeek from '../../feed/postComponents/FeedCardPeek';
import GroupTitleTile from '../GroupTitleTile';
import FeedPrevNextButtons from '../../feed/FeedPrevNextButtons';

// libraries
import { Spinner } from 'reactstrap';
import queryString from 'query-string';
import Moment from 'react-moment';

// misc
import { isIterableArray } from '../../../helpers/utils';

// queries and actions
import { useGetGroupFeedById_Query } from '../../../queries/feedQueries';
import { getFeedGroupPosts } from '../../../redux/actions/groupActions';

const ClubFeed = ({
  // states
  postStateFromRedux: { posts, loading: postLoading, error: postError },

  // action functions
  getFeedGroupPosts,

  // url params
  match,
  // location,
}) => {
  const location = useLocation();
  const obj = queryString.parse(location.search);
  const { clubId } = useParams();

  const {
    data: group,
    isLoading: groupLoading,
    isError: groupError,
  } = useGetGroupFeedById_Query(match.params.gid);

  useEffect(() => {
    // only fetch posts for the group if its a public feed
    if (
      group?.feedType === 'public_feed' ||
      group?.feedType === 'public_group' ||
      group?.isUserMember === true
    ) {
      getFeedGroupPosts(match.params.gid, obj.page);
    }
  }, [getFeedGroupPosts, match.params.gid, obj.page, group]);

  function formatTime(time) {
    //now - 4 days > time -> format else bottom
    const curr = new Date(time).getTime();
    const time4DaysAgo = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
    const time3MonthAgo = new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
    if (time4DaysAgo < curr) {
      // The yourDate time is less than 4 days from now
      return <Moment fromNow>{time}</Moment>;
    } else if (time3MonthAgo < curr) {
      return <Moment format='ddd MMM D'>{time}</Moment>;
    } else {
      return <Moment format='D/M/YYYY'>{time}</Moment>;
    }
  }
  function getReactionsCount(reactions, type) {
    const count = reactions.filter((r) => r.type === type).length;
    return count > 0 ? count : null;
  }

  return (
    <Fragment>
      {(group === undefined && groupError === false) ||
      groupLoading === true ? (
        <div className='text-center'>
          <Spinner color='primary' />
        </div>
      ) : (
        <ContentWithAsideLayout
          aside={
            groupLoading ? (
              <div />
            ) : group ? (
              <ClubFeedSideBar feedId={match.params.gid} groupDetails={group} />
            ) : (
              <div />
            )
          }
          isStickyAside={false}
        >
          <Fragment>
            {groupLoading ? null : group ? (
              <GroupTitleTile
                to={group._id}
                tilewidth={group.feedName.length > 20 ? 20 : 1}
                feedType={group.feedType}
                announcement={group.feedName}
                details={group.feedDescription}
                isUserMember={group.isUserMember}
                isUserMod={group.isUserModerator}
                modInfo={group.modInfo ? group.modInfo.role : null}
                showJoinBtn={false}
              />
            ) : null}
            <PostOnClubFeedForm
              feedId={match.params.gid}
              group={groupLoading ? null : group ? group : null}
              feedType={groupLoading ? '' : group ? group.feedType : ''}
              groupName={groupLoading ? '' : group ? group.feedName : ''}
              clubId={clubId}
              isUserMod={group.isUserModerator}
            />

            {postLoading ? (
              <div className='text-center'>
                <Spinner color='primary' />
              </div>
            ) : postError === 'error' ? (
              <div className='text-center'>
                <span>{`Join Group to see Posts`}</span>
              </div>
            ) : (
              <Fragment>
                {isIterableArray(posts) &&
                  posts.map((post) => (
                    <FeedCardPeek
                      key={post._id}
                      postId={post._id}
                      post={post}
                      postUser={{
                        avatarSrc: post.avatar,
                        name: post.posterName,
                        time: formatTime(post.createdAt),
                        privacy: post.postReach,
                        location: 'toronto',
                      }}
                      content={{
                        gallery: post.images,
                        status: post.postContent,
                      }}
                      footer={{
                        showOpen: true,
                        countLCS: {
                          like: getReactionsCount(post.reactions, 'like'),
                          dislike: getReactionsCount(post.reactions, 'dislike'),
                          comments: post.commentCount,
                        },
                      }}
                      className='mb-3'
                    />
                  ))}

                <FeedPrevNextButtons posts={posts} />
              </Fragment>
            )}
          </Fragment>
        </ContentWithAsideLayout>
      )}
    </Fragment>
  );
};

ClubFeed.propTypes = {
  getFeedGroupPosts: PropTypes.func.isRequired,
  postStateFromRedux: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  postStateFromRedux: state.postReducer,
});

export default connect(mapStateToProps, { getFeedGroupPosts })(ClubFeed);
