// libraries
import React from 'react';
import PropTypes from 'prop-types';

// Static
import { NOTIFICATION_TYPES } from '../static/enums';

// Notification Components
import Notification from './Notification';

// Posts/Comments
import NewPostCommentNotification from './components/notificationComponents/NewPostCommentNotification';
import NewPostReactionNotification from './components/notificationComponents/NewPostReactionNotification';
import NewSharedPostNotification from './components/notificationComponents/NewSharedPostNotification';

// Social
import FriendRequestAcceptedNotification from './components/notificationComponents/FriendRequestAcceptedNotification';
import NewFriendRequestNotification from './components/notificationComponents/NewFriendRequestNotification';

// Feeds/Groups
import NewGroupJoinRequestNotification from './components/notificationComponents/NewGroupJoinRequestNotification';
import GroupJoinRequestAcceptedNotification from './components/notificationComponents/GroupJoinRequestAcceptedNotification';
import GroupInviteNotification from './components/notificationComponents/GroupInviteNotification';

// Clubs
import NewClubJoinRequestNotification from './components/notificationComponents/NewClubJoinRequestNotification';
import ClubJoinRequestAcceptedNotification from './components/notificationComponents/ClubJoinRequestAcceptedNotification';

const NotificationSelector = ({
  notificationData,
  notificationType,
  className,
}) => {
  switch (notificationType) {
    case NOTIFICATION_TYPES.NEW_COMMENT_ON_POST:
      return (
        <NewPostCommentNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.NEW_POST_REACTION:
      return (
        <NewPostReactionNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.FRIEND_REQUEST_ACCEPTED:
      return (
        <FriendRequestAcceptedNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.NEW_FRIEND_REQUEST:
      return (
        <NewFriendRequestNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.NEW_GROUP_JOIN_REQUEST:
      return (
        <NewGroupJoinRequestNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.GROUP_JOIN_REQUEST_ACCEPTED:
      return (
        <GroupJoinRequestAcceptedNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.NEW_GROUP_INVITE:
      return (
        <GroupInviteNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.NEW_CLUB_JOIN_REQUEST:
      return (
        <NewClubJoinRequestNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.CLUB_JOIN_REQUEST_ACCEPTED:
      return (
        <ClubJoinRequestAcceptedNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    case NOTIFICATION_TYPES.SEND_POST_TO_PEER:
      return (
        <NewSharedPostNotification
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          frontendFields={notificationData.frontendFields}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
    default:
      return (
        <Notification
          avatar={
            notificationData.userToShow
              ? notificationData.userToShow.avatar
              : null
          }
          to={notificationData.notiLink ? notificationData.notiLink : '#!'}
          children={notificationData.notiInfo}
          unread={notificationData.status === 'unread' ? true : false}
          time={notificationData.createdAt}
          notificationId={notificationData._id}
          className={className}
        />
      );
  }
};

NotificationSelector.propTypes = {
  notificationData: PropTypes.object.isRequired,
  notificationType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

NotificationSelector.defaultProps = {
  className: '',
};

export default NotificationSelector;
