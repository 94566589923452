export const cleanKeyTerms = (str) => {
  if (str === 'rentalType') {
    return 'Property Type';
  } else if (str === 'rentalTerm') {
    return 'Rental Term';
  } else if (str === 'rent') {
    return 'Rent (per month)';
  } else if (str === 'availableBedrooms') {
    return 'Bedrooms Available';
  } else if (str === 'totalBedrooms') {
    return 'Total Bedrooms on Property';
  } else if (str === 'totalBathrooms') {
    return 'Total Bathrooms on Property';
  } else if (str === 'amenities') {
    return 'Amenities';
  } else if (str === 'utilites') {
    return 'Utilities';
  } else if (str === 'dateAvailable') {
    return 'Date Available';
  } else {
    return str;
  }
};
