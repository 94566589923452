import React, { useState, useContext, useEffect } from 'react';

// context
import { ClubContext } from '../../privatePages/ViewClubPage';

// component
import UpdateClubBannerForm from './UpdateClubBannerForm';

// queries
import {
  useGetClubById_Query,
  useUpdateBanner_Mutation,
} from '../../../../queries/clubQueries';

// misc
import { errorToast } from '../../../../utils/toastHandler';

const UpdateClubBanner = () => {
  const { _id } = useContext(ClubContext);
  const { data: clubData, isLoading: isClubLoading } =
    useGetClubById_Query(_id);
  const [clubInfo, setClubInfo] = useState({});
  const { mutate: updateBanner, isLoading: isImgLoading } =
    useUpdateBanner_Mutation();

  useEffect(() => {
    setClubInfo({ banner: clubData.clubBanner });
  }, [clubData]);

  const changeFormField = (e, custom, customValues) => {
    if (custom) {
      setClubInfo({ ...clubInfo, [custom]: customValues });
    } else {
      setClubInfo({
        ...clubInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onUpdateImgSubmit = (data) => {
    // Checks if banner is being updated
    let formattedData = {
      clubId: _id,
      banner:
        data?.attachBanner[0] instanceof File && data?.attachBanner.length > 0
          ? data.attachBanner[0]
          : null,
    };
    if (!formattedData.banner) {
      return errorToast('Must update banner!');
    }
    updateBanner(formattedData);
  };
  const props = {
    clubInfo,
    changeFormField,
    onUpdateImgSubmit,
    isImgLoading,
  };
  return !isClubLoading && <UpdateClubBannerForm {...props} />;
};

export default UpdateClubBanner;
