import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// libraries
import { toast } from 'react-toastify';
import { Col, Row, Button } from 'reactstrap';

// components
import ButtonImgPostCreate from '../../../../feed/ButtonImgPostCreate';
import LightBoxGallery from '../../../../common/LightBoxGallery';

// assets
import postImage from '../../../../../assets/img/illustrations/image.svg';

// misc
import { FILE_SIZE_MAP } from '../../../../static/config';

const UpdateHousingImages = ({ filesUploaded, setFiles, selectedPlan }) => {
  // state variables

  const onAddImageClicked = (e) => {
    //takes the multiple files uploaded and adds them to an array
    let filesToPush = [];
    for (const i of e.target.files) {
      if (i.size < FILE_SIZE_MAP.HOSUING_IMAGE) {
        filesToPush.push(i);
      } else {
        toast.warn(
          `Some of your files were larger than ${parseInt(
            FILE_SIZE_MAP.HOSUING_IMAGE / (1024 * 1024)
          )} MB. They will not be used.`,
          { id: 'hosuingImageWarning' }
        );
      }
    }

    let limit = 10;
    if (selectedPlan === 'premium') {
      limit = 25;
    } else if (selectedPlan === 'standard') {
      limit = 15;
    } else if (selectedPlan === 'basic') {
      limit = 10;
    }
    // checks if more then 9 files are attached, if so, deletes them
    if (filesToPush.length > limit) {
      toast.error(`Cannot attach more then 9 images in a post`);
      filesToPush = filesToPush.slice(0, limit);
    }

    // sets files
    setFiles([...filesToPush]);
  };

  return (
    <Fragment>
      {filesUploaded.length > 0 && typeof filesUploaded[0] === 'string' ? (
        <LightBoxGallery images={filesUploaded}>
          {(openImgIndex) => (
            <Row noGutters className='mx-n1 px-2 py-2'>
              {filesUploaded.map((f, index) => {
                return (
                  <Row className='mx-2' key={index}>
                    <img
                      className='img-fluid rounded mb-2 cursor-pointer'
                      src={f}
                      alt=''
                      style={{ maxHeight: 100 }}
                      onClick={() => openImgIndex(index)}
                    />
                  </Row>
                );
              })}
            </Row>
          )}
        </LightBoxGallery>
      ) : (
        <Fragment>
          {filesUploaded.length > 0 ? (
            <LightBoxGallery
              images={filesUploaded.map((f) => URL.createObjectURL(f))}
            >
              {(openImgIndex) => (
                <Row noGutters className='mx-n1 px-2 py-2'>
                  {filesUploaded.map((f, index) => {
                    return (
                      <Row className='mx-2' key={index}>
                        <img
                          className='img-fluid rounded mb-2 cursor-pointer'
                          src={URL.createObjectURL(f)}
                          alt=''
                          style={{ maxHeight: 100 }}
                          onClick={() => openImgIndex(index)}
                        />
                      </Row>
                    );
                  })}
                </Row>
              )}
            </LightBoxGallery>
          ) : null}
        </Fragment>
      )}

      <Col className='px-0'>
        <label>
          <input
            type='file'
            id='real-file'
            accept='image/png, image/jpeg'
            hidden
            name='attachimg'
            multiple
            onChange={(e) => onAddImageClicked(e)}
          />
          <ButtonImgPostCreate imgSrc={postImage}>
            <span>
              {filesUploaded.length > 0
                ? `Select / Change Images`
                : `Select Images`}
            </span>
          </ButtonImgPostCreate>
        </label>
      </Col>

      <Col className='text-center px-0'>
        <hr className='border-dashed d-block' />

        <Button color='warning' className='px-4 btn-sm' type='submit' outline>
          {`Save Changes & Generate New Preview `}
        </Button>
      </Col>
    </Fragment>
  );
};

UpdateHousingImages.propTypes = {
  filesUploaded: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
};

export default connect(null, {})(UpdateHousingImages);
