import {
  CREATE_AD_LOGGED_IN,
  GET_UNFINISHED_HOUSING_AD,
  UPDATE_UNFINISHED_HOUSING_AD,
  SHOW_LOAD_WHILE_UPDATING,
  CLAIM_UNFINISHED_HOUSING_AD,
  SET_CREATE_HOUSING_AD_REDUCER_LOADING,
} from '../types';

const initialState = {
  justCreatedAd: {},
  justCreatedFormatted: {},
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_AD_LOGGED_IN:
      return {
        ...state,
        justCreatedAd: payload,
        loading: false,
      };
    case SHOW_LOAD_WHILE_UPDATING:
      return {
        ...state,
        justCreatedAd: {},
        justCreatedFormatted: {},
        loading: true,
      };
    case UPDATE_UNFINISHED_HOUSING_AD:
    case CLAIM_UNFINISHED_HOUSING_AD:
    case GET_UNFINISHED_HOUSING_AD:
      const toAdd = {
        plan: payload.plan,
        images: payload.images,

        rentalType: payload.details.rentalType,
        rentalTerm: payload.details.rentalTerm,
        rent: payload.details.rent,

        availableBedrooms: payload.details.availableBedrooms,
        totalBedrooms: payload.details.totalBedrooms,
        totalBathrooms: payload.details.totalBathrooms,

        streetAddress: payload.addressDetails.streetAddress,
        unitNumber: payload.addressDetails.unitNumber ?? '',
        propertyCity: payload.addressDetails.propertyCity,
        propertyState: payload.addressDetails.propertyState,
        propertyCountry: payload.addressDetails.propertyCountry,
        postalCode: payload.addressDetails.postalCode,

        description: payload.description,

        dateAvailable: new Date(payload.dateAvailable),
        contactName: payload.contactName,
        contactPhone: payload.phone,
        contactEmail: payload.email,
        contactMethod: payload.contactMethod,

        amenities: payload.amenities,
        utilities: payload.utilities,
        university: payload.university,
        accessibilityInfo: payload.accessibilityInfo,

        // optionals

        owner: payload.user ? payload.user : null,
        _id: payload._id,
      };
      return {
        ...state,
        justCreatedAd: payload,
        justCreatedFormatted: toAdd,
        loading: false,
      };
    case SET_CREATE_HOUSING_AD_REDUCER_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}
