import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';

// misc
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';
import { minutesToMiliseconds } from '../utils/timeFunctions';

export const useGetGroupFeedById_Query = (feedId) => {
  return useQuery(
    ['feed', 'group', feedId],
    () =>
      axios
        .get(`/api/v2/feed/info/${feedId}`)
        .then((res) => res.data.dataPayload.feedInfo),
    {
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useUpdateGroupFeedById_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    // First param is the actual function that makes the HTTP request
    (inputData) =>
      axios
        .put(`/api/v2/feed/edit/${inputData.feedId}`, inputData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res),
    {
      // onMutate runs in parallel with the server data
      onMutate: async (inputData) => {
        // 1. Cancel all fetch queires related to this group
        await QueryClient.cancelQueries(['feed', 'group', inputData.feedId]);

        // 2. Get the current data present for this group
        const oldFeedData = QueryClient.getQueryData([
          'feed',
          'group',
          inputData.feedId,
        ]);

        // 3. Optimitstically update the group, treating the action as if was successful
        QueryClient.setQueryData(['feed', 'group', inputData.feedId], {
          ...oldFeedData,
          ...inputData,
        });

        // 4. Return some context data for handling failures
        return { oldFeedData, inputData };
      },
      onSuccess: (res) => axiosSuccessToast(res),

      onError: (err, inputData, context) => {
        axiosErrorToast(err);

        // if the action fails, reset the data back from the optimistic data to the current data
        QueryClient.setQueryData(
          ['feed', 'group', context.inputData.feedId],
          context.oldFeedData
        );
      },
    }
  );
};

export const useDeleteGroupFeedById_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (inputData) =>
      axios.delete(`/api/v2/feed/de/${inputData.feedId}`).then((res) => res),
    {
      onSuccess: (res, inputData) => {
        axiosSuccessToast(res);
        // remove the cached data if any
        QueryClient.removeQueries(['feed', 'group', inputData.feedId]);
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useLeaveGroupFeedById_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (inputData) =>
      axios.put(`/api/v2/feed/leave/${inputData.feedId}`).then((res) => res),
    {
      onSuccess: (res, inputData) => {
        axiosSuccessToast(res);
        // remove the cached data if any
        QueryClient.removeQueries(['feed', 'group', inputData.feedId]);
        QueryClient.refetchQueries(['mygroups']);
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useCreateGroup_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (inputData) =>
      axios
        .post(`/api/v2/feed/c`, inputData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          return { res: res, group: res.data.dataPayload.newFeed };
        }),
    {
      onSuccess: (res) => {
        axiosSuccessToast(res.res);
        // add new group to cache
        QueryClient.setQueryData(['feed', 'group', res.group._id]);
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useGetAllJoinRequests_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .get(`/api/v2/feed/gajr/${inputData.feedId}`)
        .then((res) => res.data.dataPayload.feed.usersRequestingJoin),
    {
      onSuccess: async (data, inputData) => {
        await QueryClient.cancelQueries(['feed', 'group', inputData.feedId]);
        const oldFeedData = QueryClient.getQueryData([
          'feed',
          'group',
          inputData.feedId,
        ]);

        if (oldFeedData === null || oldFeedData === undefined) {
          await QueryClient.fetchQuery(
            ['feed', 'group', inputData.feedId],
            () =>
              axios
                .get(`/api/v2/feed/info/${inputData.feedId}`)
                .then((res) => res.data.dataPayload.feedInfo)
          );
        }

        QueryClient.setQueryData(['feed', 'group', inputData.feedId], {
          ...oldFeedData,
          usersRequestingJoin: data,
        });
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useAcceptIncomingJoinRequest_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(
          `/api/v2/feed/ajr/${inputData.feedId}`,
          { userRequestingId: inputData.userId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res),
    {
      onSuccess: async (data, inputData) => {
        await QueryClient.cancelQueries(['feed', 'group', inputData.feedId]);
        const oldFeedData = QueryClient.getQueryData([
          'feed',
          'group',
          inputData.feedId,
        ]);
        QueryClient.setQueryData(['feed', 'group', inputData.feedId], {
          ...oldFeedData,
          usersRequestingJoin: oldFeedData.usersRequestingJoin.filter(
            (u) => u.userId._id !== inputData.userId
          ),
        });
      },
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useRejectIncomingJoinRequest_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(
          `/api/v2/feed/rjr/${inputData.feedId}`,
          { userRequestingId: inputData.userId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res),
    {
      onSuccess: async (data, inputData) => {
        await QueryClient.cancelQueries(['feed', 'group', inputData.feedId]);
        const oldFeedData = QueryClient.getQueryData([
          'feed',
          'group',
          inputData.feedId,
        ]);
        QueryClient.setQueryData(['feed', 'group', inputData.feedId], {
          ...oldFeedData,
          usersRequestingJoin: oldFeedData.usersRequestingJoin.filter(
            (u) => u.userId._id !== inputData.userId
          ),
        });
      },
      onError: (err) => axiosErrorToast(err),
    }
  );
};

// get discoverable feeds
const getDiscoverableFeeds = async (page = 1) => {
  const {
    data: { dataPayload, paginationInfo },
  } = await axios.get(`/api/v2/feed/getpublic?page=${page}`);

  const { discoverableFeeds } = dataPayload;

  return {
    groups: discoverableFeeds,
    paginationInfo: paginationInfo,
  };
};

export const useGetDiscoverableFeeds_Query = (page = 1) => {
  return useQuery(
    ['discoverableFeeds', page],
    () => getDiscoverableFeeds(page),
    {
      onError: (err) => axiosErrorToast(err),
      retry: false,
      cacheTime: minutesToMiliseconds(60),
      staleTime: minutesToMiliseconds(60),
    }
  );
};

// get my groups
const getMyGroups = async () => {
  const {
    data: { dataPayload, paginationInfo },
  } = await axios.get(`/api/v2/userSettings/mygroups`);

  const { groups } = dataPayload;

  return {
    myGroups: groups.feedsImPartOf.map((p) => p.feedId),
    groupsRequested: groups.feedsIveRequestedToJoin.map((p) => p.feedId),
    groupsInvited: groups.feedsImInvitedTo.map((p) => p.feedId),
    paginationInfo: paginationInfo,
  };
};

export const useGetMyGroups_Query = () => {
  return useQuery(['mygroups'], () => getMyGroups(), {
    onError: (err) => axiosErrorToast(err),
    retry: false,
    cacheTime: minutesToMiliseconds(60),
    staleTime: minutesToMiliseconds(60),
  });
};
