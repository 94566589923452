import axios from 'axios';
import { toast } from 'react-toastify';
import qs from 'query-string';

// utils
import { axiosErrorToast, axiosSuccessToast } from '../../utils/toastHandler';

import {
  CREATE_AD_LOGGED_IN,
  GET_UNFINISHED_HOUSING_AD,
  UPDATE_UNFINISHED_HOUSING_AD,
  SHOW_LOAD_WHILE_UPDATING,
  CLAIM_UNFINISHED_HOUSING_AD,
  SET_CREATE_HOUSING_AD_REDUCER_LOADING,
} from '../types';

export const createHousingAdPublic_Action =
  (input, hist) => async (dispatch) => {
    const formData = new FormData();
    // append all the files with key 'file' to the formdata
    // they will appear in an array called files in the BE
    input.adImgs.map((f) => formData.append('files', f));
    formData.append('plan', input.plan);
    for (var key of Object.keys(input.adInfo)) {
      formData.append(key, input.adInfo[key]);
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    try {
      const res = await axios.post(
        '/api/v1/housing/createAdPublic',
        formData,
        config
      );

      dispatch({
        type: CREATE_AD_LOGGED_IN,
        payload: res.data.dataPayload.house,
      });

      // add housingid to URL params - this will then fetch the newly created ad
      const query = { hid: res.data.dataPayload.house._id };
      const searchString = qs.stringify(query);
      hist.push({
        search: searchString,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  };

// todo - test
export const createHousingAdPrivate_Action =
  (input, hist) => async (dispatch) => {
    const formData = new FormData();
    // append all the files with key 'file' to the formdata
    // they will appear in an array called files in the BE
    input.adImgs.map((f) => formData.append('files', f));
    formData.append('plan', input.plan);
    for (var key of Object.keys(input.adInfo)) {
      formData.append(key, input.adInfo[key]);
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    try {
      const res = await axios.post(
        '/api/v1/housing/createAdPrivate',
        formData,
        config
      );

      dispatch({
        type: CREATE_AD_LOGGED_IN,
        payload: res.data.dataPayload.house,
      });

      //add housingid to params
      const query = { hid: res.data.dataPayload.house._id };
      const searchString = qs.stringify(query);
      hist.push({
        search: searchString,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  };

// todo - test
export const getUnFinshedHousingAd_Action =
  (hid, history) => async (dispatch) => {
    dispatch({
      type: SET_CREATE_HOUSING_AD_REDUCER_LOADING,
      payload: {},
    });

    try {
      const res = await axios.get(
        `/api/v1/housing/getUnfinishedHouseAd/${hid}`
      );
      dispatch({
        type: GET_UNFINISHED_HOUSING_AD,
        payload: res.data.dataPayload.house,
      });
    } catch (err) {
      axiosErrorToast(err);

      // todo - @adit - investigate why we do this and add a comment here
      history.push({ search: '' });
    }
  };

const checkUID = (hid, uid) => {
  //uid
  const temp = uid.split('').reverse().join('');

  if (hid.includes(temp) && uid.length === 5) {
    return true;
  } else if (uid === hid) {
    return true;
  }
  return false;
};

// todo - test
export const updateHousingAdPrivate_Action =
  (hid, input, uid) => async (dispatch) => {
    dispatch({
      type: SHOW_LOAD_WHILE_UPDATING,
    });
    const formData = new FormData();

    //append images in new images are add
    if (
      input.adImgs &&
      input.adImgs.length > 0 &&
      typeof input.adImgs[0] !== 'string'
    ) {
      // append all the files with key 'files' to the formdata
      // they will appear in an array called files in the BE
      input.adImgs.map((f) => formData.append('files', f));
    }

    formData.append('plan', input.plan);
    for (var key of Object.keys(input.adInfo)) {
      if (
        key === 'amenities' ||
        key === 'utilities' ||
        key === 'accessibilityInfo'
      ) {
        if (input.adInfo[key].length > 0) {
          let temp = '';
          input.adInfo[key].forEach((item) => (temp += item + ','));
          temp = temp.substring(0, temp.length - 1);
          formData.append(key, temp);
        }
      } else {
        formData.append(key, input.adInfo[key]);
      }
    }

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    try {
      const res = await axios.post(
        `/api/v1/housing/updateAdPrivate/${hid}`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_UNFINISHED_HOUSING_AD,
        payload: res.data.dataPayload.houseUpdated,
      });

      toast.success('Housing Ad Updated');
    } catch (err) {
      axiosErrorToast(err);
    }
  };

// todo - test
export const updateHousingAdPublic_Action =
  (hid, input, uid) => async (dispatch) => {
    let uidCorrect = checkUID(hid, uid ? uid : '');
    if (uidCorrect) {
      dispatch({ type: SHOW_LOAD_WHILE_UPDATING });

      const formData = new FormData();

      //append images in new images are add
      if (
        input.adImgs &&
        input.adImgs.length > 0 &&
        typeof input.adImgs[0] !== 'string'
      ) {
        // append all the files with key 'files' to the formdata
        // they will appear in an array called files in the BE
        input.adImgs.map((f) => formData.append('files', f));
      }

      formData.append('plan', input.plan);
      for (var key of Object.keys(input.adInfo)) {
        if (
          key === 'amenities' ||
          key === 'utilities' ||
          key === 'accessibilityInfo'
        ) {
          if (input.adInfo[key].length > 0) {
            let temp = '';
            input.adInfo[key].forEach((item) => (temp += item + ','));
            temp = temp.substring(0, temp.length - 1);
            formData.append(key, temp);
          }
        } else {
          formData.append(key, input.adInfo[key]);
        }
      }

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      try {
        const res = await axios.post(
          `/api/v1/housing/updateAdPublic/${hid}`,
          formData,
          config
        );

        dispatch({
          type: UPDATE_UNFINISHED_HOUSING_AD,
          payload: res.data.dataPayload.houseUpdated,
        });
      } catch (err) {
        axiosErrorToast(err);
      }
    } else {
      toast.error(
        'You do not have perms to update this ad. Use link in your email or login'
      );
    }
  };

// todo - test
export const claimUnownedHousingAd_Action = (hid, uid) => async (dispatch) => {
  let uidCorrect = checkUID(hid, uid ? uid : '');
  if (uidCorrect) {
    try {
      const res = await axios.post(`/api/v1/housing/claimAd/${hid}`);

      dispatch({
        type: CLAIM_UNFINISHED_HOUSING_AD,
        payload: res.data.dataPayload.updatedAd,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  }
};

// todo - test
export const claimUnownedHousingAdSkipUID_Action =
  (hid) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/v1/housing/claimAd/${hid}`);
      dispatch({
        type: CLAIM_UNFINISHED_HOUSING_AD,
        payload: res.data.dataPayload.updatedAd,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  };

export const activateFreeTrial_Action = (hid) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v1/housing/activateFreeTrial/${hid}`);
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};
