import {
  GET_CHAT_THREADS,
  SET_CHAT_REDUCER_LOADING,
  SET_CHAT_REDUCER_LOADING_FALSE,
  SET_ACTIVE_THREAD,
  LOGOUT,
  ADD_CHAT_CONN_OPENED,
  ADD_MSGS_TO_CHAT,
  SEND_MESSAGE,
  SEND_MESSAGE_DELIVERED,
  NEW_MESSAGE_RECIEVED,
  CREATE_NEW_CHAT_THREAD,
  MOVE_CHATBOX_TO_TOP,
  THREAD_LOAD_PREV_MSGS,
  USER_STARTED_TYPING,
  USER_STOPPED_TYPING,
  READ_STATUSES_UPDATE,
  RENAME_CHAT,
  MSG_DELIVERED,
  CHAT_UPDATE_READ_STATUS,
  CHAT_MESSAGE_DELETED,
  CHAT_MESSAGE_UPDATED,
  UPDATE_UNREAD_MSGS,
} from '../types';

const initialState = {
  loading: false,
  activeThreadId: null,
  myChats: [],
  openedChats: [],
  usersTyping: [],
  hasUnreadMessages: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // used to get all chats
    case GET_CHAT_THREADS:
      const temp = payload.chats.map((chat) => {
        let toReturn = { ...chat };

        const chatPartner = chat.users.filter((u) => {
          return u.userId._id !== payload.loggedInUserId;
        });

        // set has more messages for each thread
        if (chat.messages.length > 0) {
          toReturn.hasMore = true;
        } else {
          toReturn.hasMore = false;
        }

        // set chat titles
        if (toReturn.chatName && toReturn.chatName.length > 0) {
          // if name is set from db, use that
          toReturn.chatTitle = toReturn.chatName;
        } else {
          // update chat title based on the chat partner and members
          if (chatPartner.length === 2) {
            toReturn.chatTitle = `${
              chatPartner[0].userId.name.split(' ')[0]
            }, ${chatPartner[1].userId.name.split(' ')[0]}`;
          } else if (chatPartner.length > 2) {
            toReturn.chatTitle = `${
              chatPartner[0].userId.name.split(' ')[0]
            }, ${chatPartner[1].userId.name.split(' ')[0]} & others`;
          } else {
            toReturn.chatTitle = `${chatPartner[0].userId.name.split(' ')[0]}`;
          }
        }

        // set chat avatar
        if (chat.users.length > 2) {
          toReturn.chatAvatar = chat.users.map((u) => {
            return u.userId.avatar;
          });
        } else {
          toReturn.chatAvatar = chatPartner[0]?.userId?.avatar;
        }

        //set chat read
        toReturn.threadRead = false;
        const userReadInfo = chat.readStatus.find((i) => {
          return i.userId === payload.loggedInUserId;
        });

        if (
          userReadInfo &&
          userReadInfo.lastMessageId ===
            chat.messages[chat.messages.length - 1]._id
        ) {
          toReturn.threadRead = true;
        }
        // * info : handle case where last msg is your own
        else if (
          chat.messages.length > 0 &&
          chat.messages[chat.messages.length - 1].sender ===
            payload.loggedInUserId
        ) {
          toReturn.threadRead = true;
        }

        return toReturn;
      });

      // discuss below
      // * info : sort all chats by most recent msg - not scalable, but workaround for now, for chats without msgs, use chat creation date
      temp.sort((a, b) => {
        const aTime =
          a.messages.length > 0 ? a.messages[0].createdAt : a.createdAt;
        const bTime =
          b.messages.length > 0 ? b.messages[0].createdAt : b.createdAt;
        const aDate = new Date(aTime);
        const bDate = new Date(bTime);
        return bDate - aDate;
      });

      // check if there are any unread messages
      const hasUnreadMessages = (payload) => {
        let unRead = false;
        for (const i of payload.chats) {
          if (i.messages.length > 0) {
            var latestMessage = i.messages[0]._id;

            for (const user of i.readStatus) {
              if (
                user.userId === payload.loggedInUserId &&
                user.lastMessageId !== latestMessage
              ) {
                unRead = true;
                break;
              }
            }
            if (unRead === true) {
              break;
            }
          }
        }
        return unRead;
      };

      return {
        ...state,
        myChats: temp,
        loading: false,
        hasUnreadMessages: hasUnreadMessages(payload),
      };

    // used to set which chat is open right now
    case SET_ACTIVE_THREAD:
      return {
        ...state,
        activeThreadId: payload,
        loading: false,
      };

    // used to prevent multiple connections of the same thread
    case ADD_CHAT_CONN_OPENED:
      return {
        ...state,
        openedChats: [...state.openedChats, payload],
        loading: false,
      };

    case UPDATE_UNREAD_MSGS:
      // check if there are any unread messages
      const hasUnreadMessagesCheckAfterOpeningAChat = (payload) => {
        let unRead = false;
        for (const i of state.myChats) {
          if (i.messages.length > 0) {
            var latestMessage = i.messages[0]._id;

            for (const user of i.readStatus) {
              if (
                user.userId === payload.loggedInUserId &&
                user.lastMessageId !== latestMessage
              ) {
                unRead = true;
                break;
              }
            }
            if (unRead === true) {
              break;
            }
          }
        }
        return unRead;
      };

      return {
        ...state,
        openedChats: [...state.openedChats, payload],
        hasUnreadMessages: hasUnreadMessagesCheckAfterOpeningAChat(
          state.myChats
        ),
        loading: false,
      };

    case ADD_MSGS_TO_CHAT:
      let currChat = state.myChats.find((c) => {
        return c._id === payload.group._id;
      });
      currChat.messages = payload.group.messages;

      return {
        ...state,
        // replace the old chat with the new chat in the array
        myChats: state.myChats.map((c) =>
          c._id.toString() === currChat._id.toString() ? currChat : c
        ),
      };

    case SEND_MESSAGE:
      let currentGroupData = state.myChats.find((c) => {
        return c._id === payload.chatId;
      });

      currentGroupData.messages.push(payload.messageData);

      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id === payload.chatId) {
            return currentGroupData;
          } else {
            return c;
          }
        }),
        loading: false,
      };

    case SEND_MESSAGE_DELIVERED:
      let currentChatData = state.myChats.find((c) => {
        return c._id === payload.chatId;
      });

      currentChatData.messages = currentChatData.messages.map((m) => {
        if (m?.messageUuid === payload.messageUuid) {
          return payload.messageData;
        } else {
          return m;
        }
      });

      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id === payload.chatId) {
            return currentChatData;
          } else {
            return c;
          }
        }),
        loading: false,
      };
    case RENAME_CHAT:
      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id === payload.chatId) {
            c.chatTitle = payload.updatedChat.chatName;
            c.chatName = payload.updatedChat.chatName;
          }
          return c;
        }),
        loading: false,
      };

    case NEW_MESSAGE_RECIEVED:
      let chatData = state.myChats.find((c) => {
        return c._id === payload.messageData.chatId;
      });

      // add message to chatData messages array if the message not alrae in the array
      if (
        chatData.messages.find((m) => {
          return m._id === payload.messageData._id;
        }) === undefined
      ) {
        chatData.messages.push(payload.messageData);
      }

      // remark chat to unread if needed
      if (state.activeThreadId !== chatData._id) {
        chatData.threadRead = false;
      }

      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id === payload.messageData.chatId) {
            return chatData;
          } else {
            return c;
          }
        }),
        loading: false,
      };

    case MOVE_CHATBOX_TO_TOP:
      let chatToMove = state.myChats.find((c) => {
        return c._id === payload.chatId;
      });

      let newChatOrder = state.myChats.filter((c) => {
        return c._id !== payload.chatId;
      });

      return {
        ...state,
        myChats: [chatToMove, ...newChatOrder],
      };

    case THREAD_LOAD_PREV_MSGS:
      let chatToUpdate = state.myChats.find((c) => {
        return c._id === payload.groupId;
      });

      chatToUpdate.messages = [...payload.messages, ...chatToUpdate.messages];
      chatToUpdate.hasMore = payload.hasMore;
      return {
        ...state,
        // replace the old chat with the new chat in the array
        myChats: state.myChats.map((c) =>
          c._id.toString() === chatToUpdate._id.toString() ? chatToUpdate : c
        ),
      };

    case CREATE_NEW_CHAT_THREAD:
      // 1 - temp chat
      let newChat = { ...payload.chat };

      // 2 - find partners & calculate title
      const chatPartner = payload.chat.users.filter((u) => {
        return u.userId._id !== payload.loggedInUserId;
      });
      // set chat titles
      if (newChat.chatName && newChat.chatName.length > 0) {
        // if name is set from db, use that
        newChat.chatTitle = newChat.chatName;
      } else {
        // update chat title based on the chat partner and members
        if (chatPartner.length === 2) {
          newChat.chatTitle = `${chatPartner[0].userId.name.split(' ')[0]}, ${
            chatPartner[1].userId.name.split(' ')[0]
          }`;
        } else if (chatPartner.length > 2) {
          newChat.chatTitle = `${chatPartner[0].userId.name.split(' ')[0]}, ${
            chatPartner[1].userId.name.split(' ')[0]
          } & others`;
        } else {
          newChat.chatTitle = `${chatPartner[0].userId.name.split(' ')[0]}`;
        }
      }

      // 3 - calculate hasMore
      newChat.hasMore = false;

      // 4 - set chat avatar
      if (payload.chat.users.length > 2) {
        newChat.chatAvatar = payload.chat.users.map((u) => {
          return u.userId.avatar;
        });
      } else {
        newChat.chatAvatar = chatPartner[0]?.userId?.avatar;
      }

      newChat.threadRead = false;

      return {
        ...state,
        myChats: [newChat, ...state.myChats],
        loading: false,
      };

    case USER_STOPPED_TYPING:
      return {
        ...state,
        usersTyping: state.usersTyping.filter((i) => {
          return i.groupId !== payload.groupId && i.userId !== payload.userId;
        }),
        loading: false,
      };

    case USER_STARTED_TYPING:
      let groupId = payload.groupId;
      let userId = payload.userId;

      const infoAlreadyThere = state.usersTyping.find((i) => {
        return i.groupId === groupId && i.userId === userId;
      });

      const chatInfo = state.myChats.find((i) => {
        return i._id === groupId;
      });

      const userInfo = chatInfo.users.find((i) => {
        return i.userId._id === userId;
      });

      return {
        ...state,
        usersTyping: infoAlreadyThere
          ? state.usersTyping
          : [{ groupId, userId, userInfo }, ...state.usersTyping],
        loading: false,
      };

    case READ_STATUSES_UPDATE:
      let chatToUpdateReadStatus = state.myChats.find((i) => {
        return i._id === payload.groupId;
      });

      chatToUpdateReadStatus.readStatus = payload.readStatus;

      //set chat read
      chatToUpdateReadStatus.threadRead = false;
      const userReadStatusInfo = chatToUpdateReadStatus.readStatus.find((i) => {
        return i.userId === payload.loggedInUserId;
      });

      if (
        userReadStatusInfo &&
        userReadStatusInfo.lastMessageId ===
          chatToUpdateReadStatus.messages[
            chatToUpdateReadStatus.messages.length - 1
          ]._id
      ) {
        chatToUpdateReadStatus.threadRead = true;
      }
      // * info : handle case where last msg is your own
      else if (
        chatToUpdateReadStatus.messages.length > 0 &&
        chatToUpdateReadStatus.messages[
          chatToUpdateReadStatus.messages.length - 1
        ].sender === payload.loggedInUserId
      ) {
        chatToUpdateReadStatus.threadRead = true;
      }

      return {
        ...state,
        // replace the old chat with the new chat in the array
        myChats: state.myChats.map((c) =>
          c._id.toString() === chatToUpdateReadStatus._id.toString()
            ? chatToUpdateReadStatus
            : c
        ),
      };

    case MSG_DELIVERED:
      return {
        ...state,
        loading: false,
      };

    case CHAT_UPDATE_READ_STATUS:
      let chatToUpdateReadStatus2 = state.myChats.find((i) => {
        return i._id === payload.chatId;
      });

      chatToUpdateReadStatus2.readStatus = payload.readStatus;

      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id.toString() === chatToUpdateReadStatus2._id.toString()) {
            return chatToUpdateReadStatus2;
          }
          return c;
        }),
      };

    case CHAT_MESSAGE_DELETED:
      // find chat
      let newChat2 = state.myChats.find((i) => {
        return i._id === payload.chatId;
      });

      // replace message
      newChat2.messages = newChat2.messages.map((msg) => {
        if (msg._id.toString() === payload.messageId.toString()) {
          return {
            ...msg,
            state: 'removed',
            content: 'This message has been removed',
          };
        } else {
          return msg;
        }
      });

      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id.toString() === newChat2._id.toString()) {
            return newChat2;
          }
          return c;
        }),
        loading: false,
      };

    case CHAT_MESSAGE_UPDATED:
      // find chat
      let newChat3 = state.myChats.find((i) => {
        return i._id === payload.chatId;
      });

      // replace message
      newChat3.messages = newChat3.messages.map((msg) => {
        if (msg._id.toString() === payload.messageId.toString()) {
          return payload.updatedMessage;
        } else {
          return msg;
        }
      });

      return {
        ...state,
        myChats: state.myChats.map((c) => {
          if (c._id.toString() === newChat3._id.toString()) {
            return newChat3;
          }
          return c;
        }),
        loading: false,
      };

    case LOGOUT:
      return initialState;
    case SET_CHAT_REDUCER_LOADING:
      return { ...state, loading: true };
    case SET_CHAT_REDUCER_LOADING_FALSE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
