import React, { Fragment } from 'react';
import { connect } from 'react-redux';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import SingleUniPublic from '../uniComponents/SingleUniPublic';

const ViewUnisPublic = () => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container mt-7 mb-4'}>
          <SingleUniPublic />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

ViewUnisPublic.defaultProps = { isLast: false };

ViewUnisPublic.propTypes = {};

export default connect(null, {})(ViewUnisPublic);
