import React, { useEffect, useState, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from 'reactstrap';

// components
import NotificationDropdown from './NotificationDropdown';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import ProfileDropdown from './ProfileDropdown';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';
import TopDropDownMenusNonLoggedIn from './TopDropDownMenusNonLoggedIn';
import ChatStatusIcon from './chatNotification/ChatStatusIcon';

// misc
import AppContext from '../../context/Context';
import handleNavbarTransparency from '../../helpers/handleNavbarTransparency';
import { topNavbarBreakpoint } from '../../config';

const NavbarStandard = ({ useDark, auth: { user, userSetupStage } }) => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  const { isTopNav } = useContext(AppContext);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      dark={true}
      fixed='top'
      expand={topNavbarBreakpoint}
      className={
        useDark === true
          ? classNames('navbar-standard navbar-theme bg-dark')
          : classNames('navbar-standard navbar-theme', {
              'bg-dark': !navbarCollapsed,
            })
      }
    >
      <Container>
        <NavbarBrand className='text-white' tag={Link} to='/'>
          Peernet.co
        </NavbarBrand>
        <NavbarToggler onClick={() => setNavbarCollapsed(!navbarCollapsed)} />

        {user ? (
          <Fragment>
            <Nav
              navbar
              className='navbar-nav-icons ml-auto flex-row align-items-center'
            >
              <NavItem>
                <SettingsAnimatedIcon />
              </NavItem>
              {isTopNav && (
                <NavItem className='d-none d-md-block p-2 px-lg-0 cursor-pointer'>
                  <NavLink tag={Link} to='/changelog' id='changelog'>
                    <FontAwesomeIcon
                      icon='code-branch'
                      transform='right-6 grow-4'
                    />
                  </NavLink>
                  <UncontrolledTooltip
                    autohide={false}
                    placement='left'
                    target='changelog'
                  >
                    Changelog
                  </UncontrolledTooltip>
                </NavItem>
              )}
              {/* <CartNotification /> */}
              <NotificationDropdown />
              {/* {loading ? null : user ? <span className='ml-2'>{user.name}</span> : null} */}
              {userSetupStage === 'done' && <ChatStatusIcon />}
              <ProfileDropdown />
            </Nav>
          </Fragment>
        ) : (
          <Collapse isOpen={!navbarCollapsed} navbar className='scrollbar'>
            <Nav navbar>
              <TopDropDownMenusNonLoggedIn
                setNavbarCollapsed={setNavbarCollapsed}
              />
            </Nav>
            <LandingRightSideNavItem />
          </Collapse>
        )}
      </Container>
    </Navbar>
  );
};

NavbarStandard.defaultProps = { useDark: false };

NavbarStandard.propTypes = {
  auth: PropTypes.object.isRequired,
  useDark: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});
export default connect(mapStateToProps)(NavbarStandard);
