import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  Card,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import ListGroup from 'reactstrap/es/ListGroup';

// misc
import FalconCardHeader from '../../common/FalconCardHeader';

// components
import NavChatSidebar from './NavChatSidebar';

const ChatStatusIcon = ({
  chatReduxState: { myChats, hasUnreadMessages, loading: chatsLoading },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [unReadMsgs, setUnReadMsgs] = useState([]);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const filteredMsgs = myChats.filter(
      thread => thread.threadRead === false && thread.messages.length > 0
    );

    setUnReadMsgs(filteredMsgs);
  }, [myChats]);

  return (
    <Fragment>
      {chatsLoading ? (
        <div className='text-center'>
          <div className='text-center'>
            <Spinner color='primary' size='sm' />
          </div>
        </div>
      ) : (
        <Fragment>
          <Dropdown
            nav
            inNavbar
            isOpen={isOpen}
            toggle={handleToggle}
            onMouseOver={() => {
              let windowWidth = window.innerWidth;
              windowWidth > 992 && setIsOpen(true);
            }}
            onMouseLeave={() => {
              let windowWidth = window.innerWidth;
              windowWidth > 992 && setIsOpen(false);
            }}
          >
            <DropdownToggle
              nav
              className={classNames('px-0', {
                'notification-indicator notification-indicator-primary': hasUnreadMessages,
              })}
            >
              <FontAwesomeIcon
                icon='comment'
                transform='shrink-6'
                className='fs-4'
              />
            </DropdownToggle>
            <DropdownMenu right className='dropdown-menu-card'>
              <Card
                className='card-notification shadow-none'
                style={{ maxWidth: '20rem' }}
              >
                <FalconCardHeader
                  className='card-header'
                  title='Chat Notifications'
                  titleTag='h6'
                  light={false}
                />

                <ListGroup flush className='font-weight-normal fs--1'>
                  {unReadMsgs.length > 0 ? (
                    <div className='list-group-title'>NEW</div>
                  ) : (
                    <div className='list-group-title text-center'>
                      No New Messages
                    </div>
                  )}
                  <NavChatSidebar />
                </ListGroup>

                <div
                  className='card-footer text-center border-top-0'
                  onClick={handleToggle}
                >
                  <Link className='card-link d-block' to='/chatrooms'>
                    Open Chat
                  </Link>
                </div>
              </Card>
            </DropdownMenu>
          </Dropdown>
        </Fragment>
      )}
    </Fragment>
  );
};

ChatStatusIcon.propTypes = {
  chatReduxState: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  chatReduxState: state.chatReducer,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatStatusIcon);
