import React from 'react';
import { Link } from 'react-router-dom';

// libraries
import { Button, Card, Row } from 'reactstrap';

// assets
import img from '../../../../assets/img/undraw/undraw_Online_learning_re_qw08.svg';

// components
import Background from '../../../common/Background';

const AllTutorsBanner = () => {
  return (
    <Card className='mb-3'>
      <div className='position-relative py-4'>
        <Background image={img} overlay='1' className='rounded-soft' />
        <div className='position-relative text-center'>
          <h1 className='text-white fs-4'>Find a tutor</h1>
          <p
            className='text-white fs-2 text-center my-4 mx-auto'
            style={{ maxWidth: '40ch' }}
          >
            {`Connect and learn one-on-one with a knowledgeable student of your course.`}
          </p>

          <Row className='justify-content-center align-items-center'>
            <Button color='falcon-primary ' size='md' className='px-4 px-sm-5'>
              <Link to='/tutor/create-profile'>{`Register as a tutor!`}</Link>
            </Button>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default AllTutorsBanner;
