export const UNIS = [
  {
    location: {
      type: 'Point',
      coordinates: [-79.917013, 43.257928],
      formattedAddress: '1280 Main St W, Hamilton, ON L8S 4S5, CA',
      street: '1280 Main St W',
      city: 'Hamilton',
      state: 'ON',
      zipcode: 'L8S 4S5',
      country: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960199/Web%20App%20Static%20Files/McMaster_wb1b08.jpg',
    _id: '5ef1880c28f09dee8c2a2867',
    name: 'McMaster University',
    description:
      'McMaster University is a public research university in Hamilton, Ontario, Canada. The main McMaster campus is on 121 hectares of land near the residential neighbourhoods of Ainslie Wood and Westdale, adjacent to the Royal Botanical Gardens',
    website: 'https://www.mcmaster.ca/',
    phone: '905-525-9140',
    user: '5ee5584fea52cb1dd7536806',
    createdAt: '2020-06-23T04:41:48.385Z',
    slug: 'mcmaster-university',
    __v: 0,
    averageCost: 0,
    id: '5ef1880c28f09dee8c2a2867',
  },
  {
    location: {
      type: 'Point',
      latitude: 43.65784,
      longitude: -79.380172,
      coordinates: [-79.380172, 43.65784],
      formattedAddress: '350 Victoria St, Toronto, ON M5B 2K3, CA',
      street: '350 Victoria St',
      city: 'Toronto',
      state: 'ON',
      zipcode: 'M5B 2K3',
      countryCode: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960406/Web%20App%20Static%20Files/Ryerson_brk5bs.jpg',
    _id: '60b8581c4055ac0015fa2215',
    name: 'Ryerson University',
    description:
      "Ryerson University is a public research university located in Toronto, Ontario, Canada. The university's core campus is situated within the Garden District, although it also operates facilities elsewhere in downtown Toronto.",
    website: 'https://www.ryerson.ca/',
    phone: '(416)-979-5000',
    email: 'help@ryerson.ca',
    createdAt: '2021-06-03T04:18:36.175Z',
    slug: 'ryerson-university',
    __v: 0,
    id: '60b8581c4055ac0015fa2215',
  },
  {
    location: {
      type: 'Point',
      latitude: 43.009787,
      longitude: -81.262054,
      coordinates: [-81.262054, 43.009787],
      formattedAddress: '1151 Richmond St, London, ON N6A 5B9, CA',
      street: '1151 Richmond St',
      city: 'London',
      state: 'ON',
      zipcode: 'N6A 5B9',
      countryCode: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960407/Web%20App%20Static%20Files/Western_rev3zz.jpg',
    _id: '60cc1a0747f7e10015fd5945',
    name: 'Western University',
    description:
      'The University of Western Ontario, branded as Western University as of 2012 and commonly shortened to Western, is a public research university in London, Ontario, Canada.',
    website: 'https://www.uwo.ca/',
    phone: '(519)-661-2111',
    email: 'welcome@uwo.ca',
    createdAt: '2021-06-18T03:59:03.242Z',
    slug: 'western-university',
    __v: 0,
    dataloader_status: 'inserted_by_dataloader',
    id: '60cc1a0747f7e10015fd5945',
  },
  {
    location: {
      type: 'Point',
      latitude: 43.469585,
      longitude: -80.538718,
      coordinates: [-80.538718, 43.469585],
      formattedAddress: '200 University Ave W, Waterloo, ON N2L 3G1, CA',
      street: '200 University Ave W',
      city: 'Waterloo',
      state: 'ON',
      zipcode: 'N2L 3G1',
      countryCode: 'CA',
    },
    schemaType: 2,
    docStatus: 'active',
    photo:
      'https://res.cloudinary.com/dvxyk9kwc/image/upload/v1627960409/Web%20App%20Static%20Files/Waterloo_vtkibc.jpg',
    _id: '60dbb72db4fe9b0015f2b6d4',
    name: 'University of Waterloo',
    description:
      'The University of Waterloo is a public research university with a main campus in Waterloo, Ontario, Canada. The main campus is on 404 hectares of land adjacent to "Uptown" Waterloo and Waterloo Park. The university also operates three satellite campuses and four affiliated university colleges.',
    website: 'https://uwaterloo.ca/',
    phone: '(519)-888-4567',
    email: 'myapplication@uwaterloo.ca',
    createdAt: '2021-06-30T00:13:33.483Z',
    slug: 'university-of-waterloo',
    __v: 0,
    dataloader_status: 'inserted_by_dataloader',
    id: '60dbb72db4fe9b0015f2b6d4',
  },
];
