import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import {
  Row,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  InputGroup,
  Col,
  InputGroupAddon,
} from 'reactstrap';
import Datetime from 'react-datetime';
import Badge from 'reactstrap/es/Badge';

// components
import FalconCardHeader from '../../../../common/FalconCardHeader';
import AddHousingImages from './AddHousingImages';

const CreateAdForm = ({
  setFormData,
  formData,
  adImgs,
  setAdImgs,
  plan,
  universities,
}) => {
  const onChange = (e) => {
    const amenitiesArray = [
      'laundryCheck',
      'dishwasherCheck',
      'cleaningServiceCheck',
      'acCheck',
      'parkingCheck',
      'furnishedCheck',
      'partiallyFurnishedCheck',
      'poolCheck',
      'gymCheck',
      'petCheck',
      'balconyCheck',
      'frontYardCheck',
      'backYardCheck',
    ];

    const utilitiesArray = [
      'heatCheck',
      'waterCheck',
      'electricityCheck',
      'wifiCheck',
      'cableCheck',
    ];

    const accessibilityArray = ['accessibilityCheck'];

    if (e._isAMomentObject === true) {
      setFormData({ ...formData, dateAvailable: e._d });
    } else if (amenitiesArray.includes(e.target.name)) {
      if (formData['amenities'].includes(e.target.value)) {
        setFormData({
          ...formData,
          amenities: formData.amenities.filter((am) => am !== e.target.value),
        });
      } else {
        const arr = formData.amenities;
        arr.push(e.target.value);
        setFormData({ ...formData, amenities: arr });
      }
    } else if (utilitiesArray.includes(e.target.name)) {
      if (formData['utilities'].includes(e.target.value)) {
        setFormData({
          ...formData,
          utilities: formData.utilities.filter((am) => am !== e.target.value),
        });
      } else {
        const arr = formData.utilities;
        arr.push(e.target.value);
        setFormData({ ...formData, utilities: arr });
      }
    } else if (accessibilityArray.includes(e.target.name)) {
      if (formData['accessibilityInfo'].includes(e.target.value)) {
        setFormData({
          ...formData,
          accessibilityInfo: formData.accessibilityInfo.filter(
            (am) => am !== e.target.value
          ),
        });
      } else {
        const arr = formData.accessibilityInfo;
        arr.push(e.target.value);
        setFormData({ ...formData, accessibilityInfo: arr });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  return (
    <Fragment>
      <Card className='mb-3'>
        <FalconCardHeader title='Create Your Ad' titleTag='h5' />
        <CardBody>
          <Badge color={'soft-success'} className='btn-block mb-2'>
            General Product Information
          </Badge>
          <Row>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='rentalTypeSelect'>Rental Type</Label>
                <Input
                  type='select'
                  id='rentalTypeSelect'
                  name='rentalType'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>

                  <option value='Whole Apartment'>Whole Apartment</option>
                  <option value='Whole House'>Whole House </option>
                  <option value='Room in Shared Student Apartment'>
                    Room in Shared Student Apartment
                  </option>
                  <option value='Room in Shared Landlord Apartment'>
                    Room in Shared Landlord Apartment
                  </option>
                  <option value='Room in Shared Student House'>
                    Room in Shared Student House
                  </option>
                  <option value='Room in Shared Landlord House'>
                    Room in Shared Landlord House
                  </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='rentalTermSelect'>Rental Term</Label>
                <Input
                  type='select'
                  id='rentalTermSelect'
                  name='rentalTerm'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='12 Month Lease'>{'12 Month Lease'}</option>
                  <option value='8 Month Lease'>{'8 Month Lease'}</option>
                  <option value='4 Month Lease'>{'4 Month Lease'}</option>
                  <option value='Short-term/Sublet/Flexible'>
                    {'Short-term/Sublet/Flexible'}
                  </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='rentalTypeSelect'>Rent Per Bedroom / Month</Label>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                  <Input
                    type='number'
                    id='rent'
                    name='rent'
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='availableBedrooms'>Available Bedrooms</Label>
                <Input
                  type='select'
                  name='availableBedrooms'
                  id='availableBedrooms'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>

                  <option value='1'>1 </option>
                  <option value='2'>2 </option>
                  <option value='3'>3 </option>
                  <option value='4'>4 </option>
                  <option value='5'>5 </option>
                  <option value='6'>6 </option>
                  <option value='7'>7 </option>
                  <option value='8'>8 </option>
                  <option value='9'>9 </option>
                  <option value='10'>10 </option>
                  <option value='11'>11 </option>
                  <option value='12'>12 </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='totalBedrooms'>Total Number of Bedrooms</Label>
                <Input
                  type='select'
                  name='totalBedrooms'
                  id='totalBedrooms'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>

                  <option value='1'>1 </option>
                  <option value='2'>2 </option>
                  <option value='3'>3 </option>
                  <option value='4'>4 </option>
                  <option value='5'>5 </option>
                  <option value='6'>6 </option>
                  <option value='7'>7 </option>
                  <option value='8'>8 </option>
                  <option value='9'>9 </option>
                  <option value='10'>10 </option>
                  <option value='11'>11 </option>
                  <option value='12'>12 </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='totalBathrooms'>Total Number of Bathrooms</Label>
                <Input
                  type='select'
                  name='totalBathrooms'
                  id='totalBathrooms'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='1'>1 </option>
                  <option value='2'>2 </option>
                  <option value='3'>3 </option>
                  <option value='4'>4 </option>
                  <option value='5'>5 </option>
                  <option value='6'>6 </option>
                  <option value='7'>7 </option>
                  <option value='8'>8 </option>
                  <option value='9'>9 </option>
                  <option value='10'>10 </option>
                  <option value='11'>11 </option>
                  <option value='12'>12 </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='dateAvailable'>{`Date Available / Move-in Date`}</Label>
                <Datetime
                  dateFormat='MMM/DD/YYYY'
                  timeFormat={false}
                  name='dateAvailable'
                  id='dateAvailable'
                  defaultValue={new Date(Date.now())}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
          </Row>
          <FormGroup>
            <Label for='description'>{`Description`}</Label>
            <Input
              placeholder='Describe your property and individuals you are looking for'
              type='textarea'
              rows='4'
              spellCheck='false'
              name='description'
              onChange={(e) => onChange(e)}
            />
          </FormGroup>
          <Badge color={'soft-success'} className='btn-block mb-2 mt-2'>
            University
          </Badge>
          <Label>Nearest University or Collage</Label>
          <Input
            className='mb-4'
            label='courses'
            type='select'
            id='university'
            name='university'
            onChange={(e) => onChange(e)}
            defaultValue={''}
          >
            <option disabled value={''}>
              Select University
            </option>
            {universities &&
              universities.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </Input>
          <Badge color={'soft-success'} className='btn-block mb-2 mt-2'>
            {`Enter Property Address`}
          </Badge>
          <Row>
            <Fragment>
              <Col xs='12'>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='streetAddress'>{`Street Address / Address Line 1`}</Label>
                      <Input
                        type='text'
                        name='streetAddress'
                        id='streetAddress'
                        placeholder='1280 Main Street West'
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='unitNumber'>{`Unit Number / Address Line 2`}</Label>
                      <Input
                        type='text'
                        name='unitNumber'
                        id='unitNumber'
                        placeholder='Unit # (If Applicable)'
                        onChange={(e) => onChange(e)}
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='propertyCity'>{`City`}</Label>
                      <Input
                        type='text'
                        name='propertyCity'
                        id='propertyCity'
                        placeholder='Toronto'
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='propertyState'>{`State/Province`}</Label>
                      <Input
                        type='text'
                        name='propertyState'
                        id='propertyState'
                        placeholder='Ontario'
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='propertyCountry'>{`Country`}</Label>
                      <Input
                        placeholder='Canada'
                        type='text'
                        name='propertyCountry'
                        id='propertyCountry'
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='postalCode'>{`Postal Code`}</Label>
                      <Input
                        placeholder='M0M0M0'
                        type='text'
                        name='postalCode'
                        id='postalCode'
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
              </Col>
            </Fragment>
          </Row>

          <Badge color={'soft-success'} className='btn-block mb-2 mt-3'>
            Amenities + Additional Informaton
          </Badge>
          <Row>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-3'>
                <div className='card-body'>
                  <p className='d-inline-block'>
                    <strong className='text-dark'>Amenities</strong>
                  </p>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='laundryCheck'
                      name='laundryCheck'
                      value='On-Site Laundry'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='laundryCheck' check>
                      On-Site Laundry
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='dishwasherCheck'
                      name='dishwasherCheck'
                      value='Dishwasher'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='dishwasherCheck' check>
                      Dishwasher
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='cleaningServiceCheck'
                      name='cleaningServiceCheck'
                      value='Cleaning Service Included'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='cleaningServiceCheck' check>
                      Cleaning Service Included
                    </Label>
                  </FormGroup>
                  <hr />
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='acCheck'
                      name='acCheck'
                      value='Air-Conditioning'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='acCheck' check>
                      Air-Conditioning
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='parkingCheck'
                      name='parkingCheck'
                      value='Parking'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='parkingCheck' check>
                      Parking
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='furnishedCheck'
                      name='furnishedCheck'
                      value='Furnished'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='furnishedCheck' check>
                      Furnished
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='partiallyFurnishedCheck'
                      name='partiallyFurnishedCheck'
                      value='Partially Furnished'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='partiallyFurnishedCheck' check>
                      Partially Furnished
                    </Label>
                  </FormGroup>
                  <hr />
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='poolCheck'
                      name='poolCheck'
                      value='Pool'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='poolCheck' check>
                      Pool
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='gymCheck'
                      name='gymCheck'
                      value='Gym'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='gymCheck' check>
                      Gym
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='petCheck'
                      name='petCheck'
                      value='Pet Friendly'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='petCheck' check>
                      Pet Friendly
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='balconyCheck'
                      name='balconyCheck'
                      value='Balcony'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='balconyCheck' check>
                      Balcony
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='frontYardCheck'
                      name='frontYardCheck'
                      value='Front Yard'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='frontYardCheck' check>
                      Front Yard
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='backYardCheck'
                      name='backYardCheck'
                      value='Back Yard'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='backYardCheck' check>
                      Back Yard
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-3'>
                <div className='card-body'>
                  <p className='d-inline-block'>
                    <strong className='text-dark'>
                      Utilities Included in Rent
                    </strong>
                  </p>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='heatCheck'
                      name='heatCheck'
                      value='Heat'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='heatCheck' check>
                      Heat
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='waterCheck'
                      name='waterCheck'
                      value='Water'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='waterChecks' check>
                      Water
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='electricityCheck'
                      name='electricityCheck'
                      value='Electricity'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='electricityCheck' check>
                      Electricity
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='wifiCheck'
                      name='wifiCheck'
                      value='Wi-Fi'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='wifiCheck' check>
                      Wi-Fi
                    </Label>
                  </FormGroup>
                  <FormGroup className='form-check mb-0'>
                    <Input
                      type='checkbox'
                      id='cableCheck'
                      name='cableCheck'
                      value='Cable'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='cableCheck' check>
                      Cable
                    </Label>
                  </FormGroup>
                   
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-3'>
                <div className='card-body form-check'>
                  <p className='d-inline-block'>
                    <strong className='text-dark'>Accessibility</strong>
                  </p>
                  <FormGroup className='form-check'>
                    <Input
                      type='checkbox'
                      id='accessibilityCheck'
                      name='accessibilityCheck'
                      value='This property is wheelchair accessible'
                      onChange={(e) => onChange(e)}
                    />
                    <Label for='accessibilityCheck' check>
                      This property is wheelchair accessible
                    </Label>
                  </FormGroup>
                   
                </div>
              </div>
            </div>
          </Row>
          <Badge color={'soft-success'} className='btn-block mb-2 mt-4'>
            Contact Information
          </Badge>
          <Row>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactName'>Contact Name</Label>
                <Input
                  type='text'
                  name='contactName'
                  id='contactName'
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactPhone'>Contact Phone Number</Label>
                <Input
                  type='text'
                  name='contactPhone'
                  id='contactPhone'
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactEmail'>Contact Email</Label>
                <Input
                  type='text'
                  name='contactEmail'
                  id='contactEmail'
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactMethod'>Preferred Contact Method</Label>
                <Input
                  type='select'
                  name='contactMethod'
                  id='contactMethod'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='Email'>Email</option>
                  <option value='Phone'>Phone</option>
                  <option value='Text'>Text</option>
                  <option value='Either'>No Preference</option>
                </Input>
              </FormGroup>
            </div>
          </Row>
          <Badge color={'soft-success'} className='btn-block mb-3 mt-2'>
            Add Images
          </Badge>
          <AddHousingImages
            filesUploaded={adImgs}
            setFiles={setAdImgs}
            selectedPlan={plan}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

CreateAdForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  adImgs: PropTypes.array.isRequired,
  setAdImgs: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
  universities: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CreateAdForm);
