import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp as thinUp,
  faThumbsDown as thinDown,
} from '@fortawesome/free-regular-svg-icons';
import {
  faThumbsDown as fatDown,
  faThumbsUp as fatUp,
} from '@fortawesome/free-solid-svg-icons';

// components
import Flex from '../../common/Flex';
import SendToPeerModal from './sendToPeer/SendToPeerModal';

// actions
import { clickReaction } from '../../../redux/actions/postActions';

// Events
import {
  posthog_likePost_Event,
  posthog_dislikePost_Event,
} from '../../../utils/posthogEvents/posthogPostEvents';

const IconStatus = ({ clickReaction, postReactions, user, id, postId }) => {
  return (
    <Row noGutters className='font-weight-semi-bold text-center py-2 fs--1'>
      <Col xs='auto'>
        <Flex
          align='center'
          className='rounded text-700 mr-3 cursor-pointer'
          onClick={(e) => {
            e.preventDefault();
            clickReaction(id, 'like');
            posthog_likePost_Event(id, user);
          }}
        >
          <FontAwesomeIcon
            icon={
              postReactions &&
              postReactions.filter(
                (r) =>
                  r.user.toString() === user._id.toString() && r.type === 'like'
              ).length > 0
                ? fatUp
                : thinUp
            }
            size='lg'
            className='text-primary'
          />
        </Flex>
      </Col>
      <Col xs='auto'>
        <Flex
          align='center'
          className='rounded text-700 mr-3 cursor-pointer'
          onClick={(e) => {
            e.preventDefault();
            clickReaction(id, 'dislike');
            posthog_dislikePost_Event(id, user);
          }}
        >
          <FontAwesomeIcon
            icon={
              postReactions &&
              postReactions.filter(
                (r) =>
                  r.user.toString() === user._id.toString() &&
                  r.type === 'dislike'
              ).length > 0
                ? fatDown
                : thinDown
            }
            size='lg'
            className='text-danger'
          />
        </Flex>
      </Col>
      <Col xs='auto'>
        <Flex align='center' className='rounded text-700 mr-3 cursor-pointer'>
          <SendToPeerModal postId={postId} />
        </Flex>
      </Col>
    </Row>
  );
};

IconStatus.propTypes = {
  id: PropTypes.string.isRequired,
  clickReaction: PropTypes.func.isRequired,
  postReactions: PropTypes.array,
  user: PropTypes.object,
  postId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { clickReaction })(IconStatus);
