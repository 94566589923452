import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { CardBody, Card, Col, Row } from 'reactstrap';

// components
import Flex from '../../common/Flex';
import ProductDetailsMediaHousingAd from './product-details/ProductDetailsMediaHousingAd';
import ProductDetailsFooterHousingAd from './product-details/ProductDetailsFooterHousingAd';
import ProductDetailsMainHousingAd from './product-details/ProductDetailsMainHousingAd';
import { Spinner } from 'reactstrap';

//actions
import { getUnFinshedHousingAd_Action } from '../../../redux/actions/createHousingAdAction';
import { hideAd } from '../../../redux/actions/housingActions';

const SingleHousingAdComponent = ({
  //actions
  getUnFinshedHousingAd_Action,
  hideAd,
  // state
  createAdState: { justCreatedAd, loading },
}) => {
  const history = useHistory();
  const location = useLocation();
  const paramsInfo = qs.parse(location.search);
  let hid = '';
  hid = paramsInfo.hid || paramsInfo.HID;

  useEffect(() => {
    if (hid && hid.length === 24) {
      //api call to get housing ad
      getUnFinshedHousingAd_Action(hid, history);
      hideAd(hid);
    }
  }, [hid]);

  return (
    <Fragment>
      {justCreatedAd === null || loading ? (
        <div className='text-center'>
          <Spinner color='primary' />
        </div>
      ) : (
        <Card className='text-secondary'>
          <CardBody>
            <Row>
              <Col lg={6} className='mb-4 mb-lg-0'>
                <ProductDetailsMediaHousingAd {...justCreatedAd} />
              </Col>
              <Col lg={6} tag={Flex} justify='between' column>
                <ProductDetailsMainHousingAd
                  id={justCreatedAd._id}
                  {...justCreatedAd}
                />
              </Col>
            </Row>
            <ProductDetailsFooterHousingAd {...justCreatedAd} />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

SingleHousingAdComponent.propTypes = {
  //state
  createAdState: PropTypes.object.isRequired,
  //action functions
  getUnFinshedHousingAd_Action: PropTypes.func.isRequired,
  hideAd: PropTypes.func.isRequired,
};

SingleHousingAdComponent.defaultProps = {
  useQuery: true,
};

const mapStateToProps = (state) => ({
  createAdState: state.createHousingAdReducer,
});

export default connect(mapStateToProps, {
  getUnFinshedHousingAd_Action,
  hideAd,
})(SingleHousingAdComponent);
