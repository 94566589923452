import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

// components
import RedirectUser from '../layouts/RedirectUser';
import { useLeaveGroupFeedById_Query } from '../../queries/feedQueries';

const GroupMemberOptions = ({ groupFromState }) => {
  const [actionTaken, setActionTaken] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState('');

  // modal stuff
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setConfirmLeave('');
  };

  const { mutate: leaveGroup } = useLeaveGroupFeedById_Query();

  // on button click
  const onSubmit = async (e) => {
    e.preventDefault();

    if (confirmLeave === 'leave group') {
      if (groupFromState._id) {
        leaveGroup({ feedId: groupFromState._id });
        handleClose();
        setActionTaken(true);
      }
    } else {
      toast.warning('Failed update, try again');
    }
  };

  if (actionTaken === true) {
    // return <Redirect to='/feed' />;
    return <RedirectUser />;
  }

  return (
    <Fragment>
      <span onClick={handleShow} to='#' className=''>
        <FontAwesomeIcon
          icon={faEllipsisV}
          size='sm'
          className='ml-2'
          style={{ cursor: 'pointer' }}
        />
      </span>
      {/* <Link
        className='font-weight-semi-bold fs--1 text-nowrap'
        to={`#`}
        onClick={() => handleShow()}
      >
        {`Edit Group Info`}
      </Link> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Leave Group ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='form-group'>
              {/* <label className='text-danger'>
                {`This is an unreversible change. Do you want to delete this
                group and all it's contents?`}
              </label> */}
              <input
                className='form-control'
                aria-describedby='emailHelp'
                placeholder='confirmation...'
                value={confirmLeave}
                onChange={({ target }) => {
                  setConfirmLeave(target.value);
                }}
                required
              />
              <small id='emailHelp' className='form-text text-warning'>
                Please type 'leave group'
              </small>
            </div>

            <button type='submit' className='btn btn-warning'>
              Leave Group
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

GroupMemberOptions.propTypes = {
  groupFromState: PropTypes.object.isRequired,
};

export default GroupMemberOptions;
