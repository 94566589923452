import React, { Fragment } from 'react';
// import classNames from 'classnames';
import { Card, CardBody, Col, Media, Form, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

//components
import FalconCardHeader from '../../../common/FalconCardHeader';

const ClaimAdPrompt = () => {
  const location = useLocation();
  const paramsInfo = qs.parse(location.search);
  let hid = '';
  hid = paramsInfo.hid || paramsInfo.HID;
  let uid = '';
  uid = paramsInfo.uid || paramsInfo.UID;
  let offerId = '';
  offerId = paramsInfo.oid || paramsInfo.OID;

  return (
    <Card className='mb-3'>
      <FalconCardHeader
        title={
          <Fragment>
            <Media body className=''>
              <h5 className='mb-0 fs-0'>Is this your ad? Claim it!</h5>
            </Media>
          </Fragment>
        }
        titleTag={Media}
        titleClass='align-items-center'
      />
      <CardBody className='p-0'>
        <Form>
          <Col className='justify-content-between mt-3 px-3 pb-3'>
            <Col>
              {`Your Ad is ready, you can now checkout! Don't worry you can update and make changes to your ads anytime.`}
            </Col>
            <Col className='text-center text-xl-center'>
              <hr className='border-dashed d-block my-4' />
              <Link
                to={`create-housing-ad?${
                  hid && hid.length > 0 ? `&hid=${hid}` : ''
                }${uid && uid.length > 0 ? `&uid=${uid}` : ''}${
                  offerId && offerId.length > 0 ? `&oid=${offerId}` : ''
                }`}
              >
                <Button
                  color='success'
                  className='px-5 text-dark'
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {offerId === '1'
                    ? `Claim & get 1 Month Free trail`
                    : `Edit & Continue to Payment`}
                </Button>
              </Link>
            </Col>
          </Col>
        </Form>
      </CardBody>
    </Card>
  );
};

export default connect(null, {})(ClaimAdPrompt);
