import PropTypes from 'prop-types';

// misc
// import { isIterableArray } from '../../../helpers/utils';

const LastMessage = (lastMessage, thread) => {
  let msgSenderName = '';
  if (thread) {
    const user = thread.users.find(
      (u) => u.userId._id === lastMessage.messageSenderUserId
    );
    if (user) {
      msgSenderName = user.userId.name.split(' ')[0];
    }
  }

  const lastMassagePreview =
    lastMessage?.messageType === 'attachment'
      ? `${msgSenderName} sent ${lastMessage.attachment}`
      : lastMessage?.message.split('<br>');

  if (lastMessage && lastMessage.message.length > 0) {
    if (lastMessage.senderUserId === 3) {
      return `You: ${lastMassagePreview[0]}`;
    }

    if (msgSenderName.length > 0) {
      return `${msgSenderName}: ${lastMassagePreview}`;
    }

    return `${lastMassagePreview}`;
  }

  return 'Say hi to your new friend';
};
LastMessage.propTypes = {
  thread: PropTypes.object.isRequired,
  lastMessage: PropTypes.object.isRequired,
};

export default LastMessage;
