// react boilerplate
import React, { Fragment, useEffect, useState } from 'react';

// components
import FalconCardHeader from '../../common/FalconCardHeader';

// libraries
import { Card, CardBody, Spinner, Row, Col } from 'reactstrap';
import loadable from '@loadable/component';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';

// queries
import {
  useGetUniversityByName_Query,
  useGetUniversities_Query,
} from '../../../queries/universityQueries';

// Events
import { posthog_ViewUniversityPublic_Event } from '../../../utils/posthogEvents/posthogUniversityEvents';

const CoursesTable = loadable(() => import('./CoursesTable'));

const SingleUniPublic = () => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);

  // determin university to fetch
  const uniToFetchBasedOnParams = params.get('uni');
  const [universityName, setUniversityName] = useState(
    uniToFetchBasedOnParams && uniToFetchBasedOnParams.length > 0
      ? uniToFetchBasedOnParams
      : 'McMaster University'
  );

  const {
    isError: isUniversityError,
    isLoading: isUniversityLoading,
    data: universityData,
  } = useGetUniversityByName_Query(universityName);
  const { data: universities } = useGetUniversities_Query();

  useEffect(() => {
    if (universityData !== undefined && universityData !== null) {
      posthog_ViewUniversityPublic_Event(universityData);
    }
  }, [universityData]);

  // helper function
  const handleUniSelect = (e) => {
    setUniversityName(e.value);
  };

  return (
    <Fragment>
      {(universityData === undefined && isUniversityError === false) ||
      isUniversityLoading ? (
        <div className='text-center'>
          <Spinner color='primary' />
        </div>
      ) : (
        <Card className='mb-3'>
          <Row>
            <Col>
              <FalconCardHeader
                title={`${universityData.name} - All Courses`}
                light={false}
              />
            </Col>
            <Col>
              <Select
                className='basic-single border rounded mt-2 mr-1'
                classNamePrefix='react-select'
                isMulti={false}
                defaultValue={universityName}
                placeholder='Select another University...'
                onChange={handleUniSelect}
                options={universities.map((uni) => {
                  return { label: uni.name, value: uni.name };
                })}
              />
            </Col>
          </Row>
          <CardBody className='p-0'>
            <CoursesTable courseList={universityData.courses} />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

export default SingleUniPublic;
