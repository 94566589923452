// react boilerplate
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Libraries
import loadable from '@loadable/component';
import { toast, ToastContainer } from 'react-toastify';

// Layouts and Routing
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import PrivateRoute from './PrivateRoute';

// Components
import CreateHousingAdPublicWrapper from '../landlord/createAdPage/public/CreateHousingAdPublicWrapper';
import ClaimHousingAdPublic from '../landlord/claimAdPage/ClaimHousingAdPublic';
import HousingLayoutPub from '../housing/ViewAllHousingAdsPublicView';
import HousingLayoutDetailsPub from '../housing/OpenSingleHousingAdPublicView';
import UnsubscribePublic from '../pages/UnsubscribePublic';
import ViewSingleResourcePublic from '../resource/publicPages/ViewSingleResourcePublic';
import { CloseButton, Fade } from '../common/Toast';
import ViewUnisPublicPage from '../university/publicPages/ViewUnisPublicPage';
import OpenMarketplacePublic from '../marketplace/publicPages/OpenMarketplacePublic';
import ViewCompaniesPublicPage from '../internships/publicPages/ViewCompaniesPublicPage';
import TutorsPublic from '../tutoring/publicPages/TutorsPublic';
import ViewAllClubsPublic from '../clubs/publicPages/ViewAllClubsPublic';
import ViewClubPagePublic from '../clubs/publicPages/ViewClubPagePublic';

// hooks
import useEmailTracking from '../../utils/posthogEvents/useEmailTracking';
import useCampaignTracking from '../../utils/posthogEvents/useCampaignTracking';

// dynamic components
const Landing = loadable(() => import('../landing/Landing'));
const Terms = loadable(() => import('../legal/Terms'));
const ContactUsPub = loadable(() => import('../contact/ContactUsPublic'));
const AmbassadorInfo = loadable(() => import('../contact/AmbassadorInfo'));
const ContactUsJobs = loadable(() => import('../contact/ContactUsPublicJobs'));
const AuthCardRoutes = loadable(() => import('../auth/card/AuthCardRoutes'));

const Layout = () => {
  useEffect(() => {
    Landing.preload();
    AuthCardRoutes.preload();
  }, []);
  useEmailTracking();
  useCampaignTracking();
  return (
    <Router fallback={<span />}>
      <Switch>
        {/* <Route path='/test2' exact component={Starter} /> */}
        <Route path='/' exact component={Landing} />
        <Route path='/contact-us' exact component={ContactUsPub} />
        <Route path='/ambassador' exact component={AmbassadorInfo} />

        <Route path='/subscription' exact component={UnsubscribePublic} />
        <Route path='/terms' exact component={Terms} />
        <Route path='/careers' exact component={ContactUsJobs} />

        <Route path='/authentication' component={AuthCardRoutes} />
        {/* <Route path='/confirm/:id' component={Starter} /> */}
        <Route path='/errors' component={ErrorLayout} />

        {/* <Route path='/create-ad-pub' component={CreateAdPagePublic} /> */}
        <Route
          path='/create-housing-ad'
          component={CreateHousingAdPublicWrapper}
        />
        <Route path='/your-housing-ad' component={ClaimHousingAdPublic} />
        <Route path='/marketplace/public' component={OpenMarketplacePublic} />
        <Route path='/tutor/public' component={TutorsPublic} />
        <Route path='/clubs/view/public' component={ViewAllClubsPublic} />
        <Route path='/c/:clubName' component={ViewClubPagePublic} />

        <Route path='/internships/public' component={ViewCompaniesPublicPage} />
        {/* <Route path='/tutor/public' component={OpenMarketplacePublic} /> */}
        <Route path={`/listings`} exact component={HousingLayoutPub} />
        <Route
          path={`/listings/:id`}
          exact
          component={HousingLayoutDetailsPub}
        />

        <Route
          path={`/resource/public/:id`}
          exact
          component={ViewSingleResourcePublic}
        />

        <Route
          path={`/all-universities`}
          exact
          component={ViewUnisPublicPage}
        />

        {/* Check for auth and routes to login or error page */}
        <PrivateRoute component={DashboardLayout} />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.TOP_CENTER}
      />
    </Router>
  );
};

export default Layout;
