import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Spinner } from 'reactstrap';

// components
import NavbarTop from '../navbar/NavbarTop';
import NavbarVertical from '../navbar/NavbarVertical';
import Footer from '../footer/Footer';
import ProductProvider from '../e-commerce/ProductProvider';
import SidePanelModal from '../side-panel/SidePanelModal';
import SetUpProfile from './wizard/profileSetup/SetUpProfile';

const CheckProfileSetup = ({ auth: { user, loading } }) => {
  return loading ? (
    <Spinner color='warning' />
  ) : (
    <Fragment>
      <div className={'container'}>
        <NavbarVertical />
        <ProductProvider>
          <div className='content'>
            <NavbarTop />
            <div className=''>
              <div className='container mt-4'>
                <h3 className='large text-dark'>1. Email confirmed!</h3>
                <hr />
                <div>
                  <h6>Your email has been confirmed.</h6>
                </div>
              </div>
            </div>
            {/* // Set up profile */}
            <SetUpProfile />
            <Footer />
          </div>
          <SidePanelModal autoShow={false} />
        </ProductProvider>
      </div>
      {/* YEEEE */}
    </Fragment>
  );
};

// export default CheckProfileSetup;

CheckProfileSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {})(CheckProfileSetup);
