import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

// libraries
import {
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
} from 'reactstrap';

// components
import FalconCardHeader from '../../peernet/common/FalconCardHeader';
import NavbarStandard from '../../peernet/navbar/NavbarStandard';
import Cta from '../../peernet/landing/Cta';
import FooterStandard from '../../peernet/landing/FooterStandard';
import { decryptMidNidParams } from '../../utils/encryption';

// actions
import { updateEmailPreferenceAction } from '../../redux/actions/otherActions';

const UnsubscribePublic = ({ updateEmailPreferenceAction }) => {
  const location = useLocation();
  const paramsInfo = qs.parse(location.search);
  let nid = '';
  nid = paramsInfo.nid;
  let mid = '';
  mid = paramsInfo.mid;

  const [valueSelected, setValueSelected] = useState('subscribed');
  const [email, setEmail] = useState('');

  const onChange = (e) => {
    setValueSelected(e.target.value);
  };

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    updateEmailPreferenceAction({ email: email, preference: valueSelected });
  };

  const getInfoFromParams = (mid, nid) => {
    if (mid && mid.length > 10 && nid && nid.length > 10) {
      const objStr = decryptMidNidParams(mid, nid);
      if (objStr !== false) {
        setEmail(objStr.email);
      }
    }
  };

  useEffect(() => {
    getInfoFromParams(mid, nid);
  }, [mid, nid]);

  return (
    <Fragment>
      <NavbarStandard useDark={true} />

      <Row className='mt-4'>
        <div className='py-4 container col-6 mt-4'>
          <Card className='mb-3'>
            <FalconCardHeader
              title='Email Preferences Settings:'
              titleTag='h5'
            />
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Your Email address</Label>
                  <Input
                    placeholder={'Email address'}
                    value={email}
                    type='email'
                    name='email'
                    onChange={(e) => onChange(e)}
                    required
                  />
                </FormGroup>

                <Label className='text-dark'>Subscription Settings</Label>

                <FormGroup className='form-check mb-0'>
                  <Input
                    type='checkbox'
                    id='subscribeCheck'
                    name='subscribeCheck'
                    value='subscribed'
                    checked={valueSelected === 'subscribed'}
                    onChange={(e) => setValueSelected(e.target.value)}
                  />
                  <Label for='subscribeCheck' check>
                    {`Subscribed`}
                  </Label>
                </FormGroup>

                <FormGroup className='form-check mb-0'>
                  <Input
                    type='checkbox'
                    id='reducedCheck'
                    name='reducedCheck'
                    value='reduced'
                    checked={valueSelected === 'reduced'}
                    onChange={(e) => setValueSelected(e.target.value)}
                  />
                  <Label for='reducedCheck' check>
                    {`Reduced (Only send important emails)`}
                  </Label>
                </FormGroup>

                <FormGroup className='form-check mb-0'>
                  <Input
                    type='checkbox'
                    id='unsubscribeCheck'
                    name='unsubscribeCheck'
                    value='unsubscribe'
                    checked={valueSelected === 'unsubscribe'}
                    onChange={(e) => setValueSelected(e.target.value)}
                  />
                  <Label for='unsubscribeCheck' check>
                    {`Unsubscribe (Do not send me any emails)`}
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Button color='info' block className='mt-3'>
                    Update Email Settings
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>

      <Cta />
      <FooterStandard />
    </Fragment>
  );
};

UnsubscribePublic.propTypes = {
  updateEmailPreferenceAction: PropTypes.func.isRequired,
};

export default connect(null, { updateEmailPreferenceAction })(
  UnsubscribePublic
);
