import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

// components
import EventDetailsForm from './EventDetailsForm';

// queries
import { useCreateNewEvent_Query } from '../../queries/eventQueries.js';

const CreateEvent = ({ customCreateNewEvent, clubId }) => {
  // State
  const [eventInfo, setEventInfo] = useState({
    title: '',
    timezone: '',
    venue: '',
    address: '',
    city: '',
    state: '',
    country: '',
    description: '',
  });
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const { mutate: createNewEvent } = useCreateNewEvent_Query();

  const submitNewEvent = customCreateNewEvent ?? createNewEvent;

  const handleSubmit = (e) => {
    const {
      title,
      timezone,
      venue,
      address,
      city,
      state,
      country,
      description,
    } = eventInfo;
    const finalEventInfo = {
      title: title,
      startDate: startDate._d,
      startTime: startTime,
      endDate: endDate._d,
      endTime: endTime,
      registrationDeadline: deadline._d,
      address: address,
      city: city,
      country: country,
      description: description,
      timezone: timezone,
      venue: venue,
      state: state,
    };
    e.preventDefault();
    if (customCreateNewEvent && clubId) {
      submitNewEvent({ finalEventInfo, clubId });
    } else {
      submitNewEvent(finalEventInfo);
    }
  };

  return (
    <Fragment>
      <EventDetailsForm
        handleSubmit={handleSubmit}
        eventInfo={eventInfo}
        setEventInfo={setEventInfo}
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
        endDate={endDate}
        setEndDate={setEndDate}
        endTime={endTime}
        setEndTime={setEndTime}
        deadline={deadline}
        setDeadline={setDeadline}
      />
      {/* <EventTicket /> */}
      {/* <EventScheduleForm /> */}
      {/* <EventCustomField /> */}
    </Fragment>
  );
};

CreateEvent.propTypes = {
  customCreateNewEvent: PropTypes.func,
  clubId: PropTypes.string,
};

export default React.memo(CreateEvent);
