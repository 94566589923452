import axios from 'axios';
import { useMutation } from 'react-query';

// misc
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useSendPostToPeerQuery = () => {
  return useMutation(
    (inputData) =>
      axios
        .post(`/api/v2/post/sendToPeer/${inputData.postId}`, {
          peerId: inputData.peerId,
        })
        .then((res) => res),
    {
      onSuccess: async (data) => {
        axiosSuccessToast(data.res);
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};
