import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// libraries
import Modal from 'react-bootstrap/Modal';

import { Row, Button, Form, FormGroup, Input, Col, Label } from 'reactstrap';

//components
import ButtonIcon from '../../common/ButtonIcon';
// queries
import { useRegisterAndEnrollToClub_Mutation } from '../../../queries/clubQueries';

const PublicJoinClubModal = ({ clubName, hideBigButton }) => {
  // modal states
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // action functions
  const { mutate: registerAndEnrollNewUser } =
    useRegisterAndEnrollToClub_Mutation();

  // form states
  const [isDisabled, setIsDisabled] = useState(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  const { name, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email,
      name,
      clubName,
    };
    registerAndEnrollNewUser(data);
    setFormData({
      name: '',
      email: '',
    });
    handleClose();
  };

  useEffect(() => {
    setIsDisabled(!email);
  }, [email]);

  return (
    <Fragment>
      {hideBigButton === false ? (
        <ButtonIcon
          color={`success`}
          icon={`plus`}
          iconAlign={`left`}
          onClick={handleShow}
        >
          {`Join Club Now`}
        </ButtonIcon>
      ) : (
        <Button
          color='light'
          size='sm'
          className='py-0 mt-1 border mr-1'
          transform='shrink-5'
          onClick={handleShow}
        >
          <span className='fs--1'>{`Join Club`}</span>
        </Button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Join Club</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Your Name</Label>
                  <Input
                    placeholder={'Name'}
                    value={name}
                    type='message'
                    name='name'
                    onChange={(e) => onChange(e)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Your Email address</Label>
                  <Input
                    placeholder={'Email address'}
                    value={email}
                    type='email'
                    name='email'
                    onChange={(e) => onChange(e)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Button
                    color='success'
                    block
                    className='mt-3'
                    disabled={isDisabled}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Send
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

PublicJoinClubModal.propTypes = {
  clubName: PropTypes.string.isRequired,
  hideBigButton: PropTypes.bool,
};
PublicJoinClubModal.defaultProps = {
  hideBigButton: false,
};

export default connect(null, {})(PublicJoinClubModal);
