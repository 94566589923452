import axios from 'axios';
import { useQuery } from 'react-query';

// utils
import { minutesToMiliseconds } from '../utils/timeFunctions';
import { axiosErrorToast } from '../utils/toastHandler';

export const useGetHousingAdPrice_Query = (tier, enabled) => {
  return useQuery(
    ['price', 'housing', tier],
    () =>
      axios
        .get(`/api/v2/price/housing/${tier}`)
        .then((res) => res.data.dataPayload.price),
    {
      enabled: enabled,
      cacheTime: minutesToMiliseconds(60 * 5),
      staleTime: minutesToMiliseconds(60 * 5),
      onError: (err) => axiosErrorToast(err),
    }
  );
};
