import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

// libraires
import queryString from 'query-string';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

// misc
import { isIterableArray } from '../../helpers/utils';

// components
import ButtonIcon from '../common/ButtonIcon';

const FeedPrevNextButtons = ({ posts }) => {
  const location = useLocation();
  const obj = queryString.parse(location ? location.search : 0);

  return (
    <Fragment>
      {isIterableArray(posts) || (obj.page && parseInt(obj.page) > 1) ? (
        <Fragment>
          {/* <hr /> */}
          {obj.page &&
          parseInt(obj.page) >= 2 &&
          isIterableArray(posts) === false ? (
            <div className='d-flex justify-content-center mt-4'>
              <h6>
                <p className='text-secondary'>{'No more posts'}</p>
              </h6>
            </div>
          ) : null}
          <div className='d-flex justify-content-center'>
            {obj.page && parseInt(obj.page) >= 2 ? (
              <Fragment>
                <ButtonIcon
                  color='light'
                  size='sm'
                  icon={faCaretLeft}
                  className='py-0 mr-1'
                  // transform='shrink-2'
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  tag={Link}
                  to={{
                    search: `?page=${
                      obj.page && parseInt(obj.page) > 1
                        ? parseInt(obj.page) - 1
                        : 1
                    }`,
                  }}
                >
                  <span className='ml-1'>Previous Page</span>
                </ButtonIcon>
              </Fragment>
            ) : null}

            {isIterableArray(posts) === true ? (
              <Fragment>
                <ButtonIcon
                  color='light'
                  size='sm'
                  icon={faCaretRight}
                  iconAlign='right'
                  className='py-0 mr-1'
                  transform='shrink-2'
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  tag={Link}
                  to={{
                    search: `?page=${
                      obj.page && parseInt(obj.page) > 0
                        ? parseInt(obj.page) + 1
                        : 2
                    }`,
                  }}
                >
                  <span className='mr-1'>Next Page</span>
                </ButtonIcon>
              </Fragment>
            ) : null}
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

FeedPrevNextButtons.propTypes = {
  posts: PropTypes.array,
};

export default FeedPrevNextButtons;
