import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';

// static data
import { blogPostList } from '../data/footer';

const getArticles = async () => {
  // discuss - fetching from cloudflare is giving us cors errors.
  const { data } = await axios.get(`https://red-sea-eeb1.pn21.workers.dev`);

  return data;

  // return blogPostList;
};

export const useGetArticles_Query = () => {
  const client = useQueryClient();

  return useQuery(['articles'], () => getArticles(), {
    onError: (err) => {
      client.setQueryData('articles', blogPostList);
    },
    retry: false,
    cacheTime: minutesToMiliseconds(24 * 60),
    staleTime: minutesToMiliseconds(24 * 60),
  });
};
