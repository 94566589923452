import React from 'react';
// import classNames from 'classnames';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

import { connect } from 'react-redux';

const ClubPSA = () => {
  return (
    <Card className='mb-3'>
      <CardBody>
        <CardTitle tag='h5'>{`Find Club Announcements Here!`}</CardTitle>
        <CardText tag='p'>{`This group is used by the club to share updates and the latest information about the club!`}</CardText>
      </CardBody>
    </Card>
  );
};

export default connect(null, {})(ClubPSA);
