import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

// libs
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  Spinner,
  Badge,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';

// components
import FalconCardHeader from '../../../../../src/peernet/common/FalconCardHeader';
import Select from '../../../../../src/peernet/common/Select';

// static
import { UNIS } from '../../../static/staticUniInfo';

const UpdateClubDetailsForm = ({
  clubInfo,
  changeFormField,

  // react hook params
  defaultValues,
  onUpdateFormSubmit,

  // params from parent
  isUpdateClubLoading,
}) => {
  // Initial populated data
  const { twitter, facebook, instagram, linkedin, website, tags } =
    defaultValues;
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues,
  });
  const [tagInput, setTagInput] = useState('');

  const addTags = (e) => {
    if (!tagInput) return;
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        changeFormField(e, 'tags', [...tags, tagInput]);
        setTagInput('');
        e.preventDefault();
        break;
      default:
        break;
    }
  };
  const handleInputChange = (value) => {
    setTagInput(value);
  };
  const onChangeTags = (selectedOption) => {
    let changedData = selectedOption
      ? selectedOption?.map?.((item) => item.value)
      : [];
    changeFormField(selectedOption, 'tags', [...changedData]);
  };

  return (
    <>
      <Card className='mb-3'>
        <FalconCardHeader title='Update Club' />
        <CardBody
          tag={Form}
          className='bg-white'
          onSubmit={handleSubmit(onUpdateFormSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Badge color={'soft-success'} className='btn-block mb-2'>
                {`Club Information`}
              </Badge>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormGroup>
                <Label>
                  {`Enter Club Name`}
                  <span>
                    <small className='text-warning'> {` * required`}</small>
                  </span>
                </Label>
                <Input
                  type='text'
                  placeholder='Club Name'
                  name='clubName'
                  innerRef={register({
                    required: true,
                    pattern: /^([a-zA-Z0-9 ]+)$/,
                  })}
                  onChange={(e) => changeFormField(e)}
                />
                <span>
                  <small className='text-danger'>
                    {errors.clubName &&
                      'Club Name is required, only use letters and numbers'}
                  </small>
                </span>
              </FormGroup>
              <FormGroup>
                <Label>
                  {`Club Description`}
                  <span>
                    <small className='text-warning'> {` * required`}</small>
                  </span>
                </Label>
                <Input
                  label='Club Description'
                  name='clubDescription'
                  placeholder='Enter a description for the club'
                  size='12'
                  rows='4'
                  type='textarea'
                  innerRef={register({
                    required: true,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.clubDescription && 'Club Description is required'}
                  </small>
                </span>
              </FormGroup>
              <FormGroup>
                <Label>
                  {`Public Contact Email`}
                  <span>
                    <small className='text-warning'> {` * required`}</small>
                  </span>
                </Label>
                <Input
                  label='Club Contact Email'
                  name='contactEmail'
                  placeholder='Enter a contact email for the club'
                  type='email'
                  innerRef={register({
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.contactEmail && 'A Contact Email is required'}
                  </small>
                </span>
              </FormGroup>
              <FormGroup>
                <Label>
                  {`Select University`}
                  <span>
                    <small className='text-warning'> {` * required`}</small>
                  </span>
                </Label>
                <Input
                  label='Select University'
                  name='clubUniversity'
                  placeholder='Select University'
                  type='select'
                  innerRef={register({
                    required: true,
                  })}
                >
                  {
                    <Fragment>
                      <option value={''} disabled>
                        {`Select University`}
                      </option>
                      ;
                      {UNIS?.map?.((item, i) => {
                        return (
                          <option key={i} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Fragment>
                  }
                </Input>
                <span>
                  <small className='text-danger'>
                    {errors.clubUniversity && 'Univeristy is required'}
                  </small>
                </span>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Badge color={'soft-success'} className='btn-block mb-2'>
                {`Club Tags`}
              </Badge>
            </Col>
            <Col>
              <FormGroup>
                <Label for='tags'>
                  Update tags for your club or type your own
                </Label>
                <Controller
                  name='tagsInfo'
                  control={control}
                  as={
                    <Select
                      isCreatable
                      isMulti
                      components={{ DropdownIndicator: null }}
                      className='basic-single mb-3 border rounded'
                      classNamePrefix='react-select'
                      id='tags'
                      name='tagsInfo'
                      defaultValue={tags?.map?.((tag) => {
                        return { value: tag, label: tag };
                      })}
                      onKeyDown={(e) => addTags(e)}
                      onInputChange={handleInputChange}
                      onChange={onChangeTags}
                      inputValue={tagInput}
                      options={[
                        { value: 'academics', label: 'academics' },
                        { value: 'athletics', label: 'athletics' },
                        { value: 'engineering', label: 'engineering' },
                        { value: 'science', label: 'science' },
                        { value: 'arts', label: 'arts' },
                        { value: 'music', label: 'music' },
                        { value: 'dance', label: 'dance' },
                        { value: 'performance', label: 'performance' },
                        {
                          value: 'student politics',
                          label: 'student politics',
                        },
                        { value: 'cultural', label: 'cultural' },
                        { value: 'religious', label: 'religious' },
                        { value: 'spiritual', label: 'spiritual' },
                        { value: 'social issues', label: 'social issues' },
                        { value: 'volunteering', label: 'volunteering' },
                        { value: 'animals', label: 'animals' },
                        { value: 'sports', label: 'sports' },
                        { value: 'competitive', label: 'competitive' },
                        { value: 'journalism', label: 'journalism' },
                        { value: 'awareness', label: 'awareness' },
                        { value: 'environment', label: 'environment' },
                        { value: 'law', label: 'law' },
                      ]}
                    />
                  }
                ></Controller>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Badge color={'soft-success'} className='btn-block mb-2'>
                Add Club's Social Media Information
              </Badge>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup>
                <Label>{`Twitter`}</Label>
                <Input
                  type='text'
                  name='twitterInfo'
                  defaultValue={twitter}
                  innerRef={register({
                    pattern: /^https:\S+$/i,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.twitterInfo && 'Must be a valid https link'}
                  </small>
                </span>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup>
                <Label>{`Facebook`}</Label>
                <Input
                  type='text'
                  name='facebookInfo'
                  defaultValue={facebook}
                  innerRef={register({
                    pattern: /^https:\S+$/i,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.facebookInfo && 'Must be a valid https link'}
                  </small>
                </span>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup>
                <Label>{`Instagram`}</Label>
                <Input
                  type='text'
                  name='instagramInfo'
                  defaultValue={instagram}
                  innerRef={register({
                    pattern: /^https:\S+$/i,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.InstagramInfo && 'Must be a valid https link'}
                  </small>
                </span>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup>
                <Label>{`LinkedIn`}</Label>
                <Input
                  type='text'
                  name='linkedinInfo'
                  defaultValue={linkedin}
                  innerRef={register({
                    pattern: /^https:\S+$/i,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.linkedinInfo && 'Must be a valid https link'}
                  </small>
                </span>
              </FormGroup>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <FormGroup>
                <Label>{`Club Website`}</Label>
                <Input
                  type='text'
                  name='websiteInfo'
                  defaultValue={website}
                  innerRef={register({
                    pattern: /^https:\S+$/i,
                  })}
                />
                <span>
                  <small className='text-danger'>
                    {errors.websiteInfo && 'Must be a valid https link'}
                  </small>
                </span>
              </FormGroup>
            </Col>
            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Badge color={'soft-success'} className='btn-block mb-2'>
                  {`Club Images`}
                </Badge>
              </Col> */}
          </Row>
          <Button color='falcon-primary mt-4' type='submit' size='sm'>
            {isUpdateClubLoading === true ? (
              <Fragment>
                {`Update Club...`} <Spinner size='sm' />
              </Fragment>
            ) : (
              `Update Club`
            )}
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

UpdateClubDetailsForm.propTypes = {
  clubInfo: PropTypes.object.isRequired,
  changeFormField: PropTypes.func.isRequired,

  // react hook params
  defaultValues: PropTypes.object,
  onUpdateFormSubmit: PropTypes.func.isRequired,

  // params from parent
  isUpdateClubLoading: PropTypes.bool.isRequired,
};

export default UpdateClubDetailsForm;
