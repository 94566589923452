import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// libraries
import moment from 'moment';
import { Alert, Card, Col, Badge } from 'reactstrap';
import { connect } from 'react-redux';

//components
import Flex from '../../common/Flex';
import ButtonIcon from '../../common/ButtonIcon';

// actions
// actions
import {
  addFavouriteHousing_Action,
  removeFavouriteHousing_Action,
} from '../../../redux/actions/housingActions';

const ProductGridH = ({
  sale,
  isNew,
  sliderSettings,
  _id,
  amenities,
  description,
  details,
  plan,
  thumbnail,
  title,
  utilities,
  createdAt,
  user,
  contactMethod,
  contactName,
  quantity,
  // redux
  addFavouriteHousing_Action,
  removeFavouriteHousing_Action,
  housingState: { favouriteHouses },
  showFavourite,
  ...rest
}) => {
  //use location to set redirect url for detials
  const locationFromUrl = useLocation();
  // const obj = queryString.parse(locationFromUrl ? locationFromUrl.search : 0);

  const [userViewingFrom, setUserViewingFrom] = useState('loggedin');

  useEffect(() => {
    if (
      locationFromUrl.pathname === '/listings' ||
      locationFromUrl.pathname === '/'
    ) {
      setUserViewingFrom('public');
    }
  }, [locationFromUrl]);

  const handleFavouriteHousingAd = (houseId) => {
    if (favouriteHouses.map((h) => h._id).indexOf(houseId) < 0) {
      addFavouriteHousing_Action(houseId);
    } else {
      removeFavouriteHousing_Action(houseId);
    }
  };

  const bodyToReturn = (
    <Fragment>
      <Flex justify='between' column className='border rounded h-100 pb-3'>
        <div className='overflow-hidden'>
          <div className='position-relative rounded-top overflow-hidden'>
            <Link
              className='d-block h-100'
              to={
                userViewingFrom === 'loggedin'
                  ? `/housing/product-details/${_id}`
                  : `listings/${_id}`
              }
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <div style={{ height: 170, overflow: 'hidden' }}>
                <img
                  alt=''
                  className='img-fluid rounded-top'
                  src={thumbnail}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  // src={files[0]['src'] || files[0]['base64']}
                  // alt={files[0].path}
                />
              </div>
            </Link>

            <Flex
              align='center'
              justify='between'
              className='px-3 position-absolute t-0 l-0 w-100'
            >
              <div className='d-flex flex-column flex-wrap'>
                {/* FEATURED BADGE */}
                {plan === 'premium' && (
                  <Badge
                    color='danger'
                    pill
                    className='ml-2 mt-2 fs--2 z-index-2 text-dark'
                    style={{ backgroundColor: '#f9ca24' }}
                  >
                    <strong>Featured</strong>
                  </Badge>
                )}

                {/* NEW BADGE */}
                {createdAt &&
                  Math.abs(
                    moment(createdAt, 'YYYY-MM-DD').diff(
                      moment(new Date(), 'YYYY-MM-DD'),
                      'days'
                    )
                  ) <= 10 && (
                    <Badge
                      color='success'
                      pill
                      className='ml-2 mt-2 fs--2 z-index-2'
                    >
                      New
                    </Badge>
                  )}
              </div>

              {/* FAVOURITE ICON */}
              {showFavourite && (
                <ButtonIcon
                  color={
                    favouriteHouses.map((h) => h._id).indexOf(_id) >= 0
                      ? 'falcon-danger'
                      : 'falcon-default'
                  }
                  size='sm'
                  icon={[
                    favouriteHouses.map((p) => p._id).indexOf(_id) >= 0
                      ? 'fas'
                      : 'far',
                    'heart',
                  ]}
                  onClick={() => handleFavouriteHousingAd(_id)}
                  className='mr-2 mt-2'
                />
              )}
            </Flex>

            {
              <div className='p-3'>
                <h5 className='fs-0'>
                  <Link
                    className='text-dark'
                    to={
                      userViewingFrom === 'loggedin'
                        ? `/housing/product-details/${_id}`
                        : `listings/${_id}`
                    }
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {title}
                  </Link>
                </h5>
                <p className='fs--1 mb-3'>
                  <a className='text-700' href='#!'>
                    {`Rent: `}
                  </a>
                  <Badge
                    color='primary'
                    // pill
                    className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                  >
                    {`$${details.rent} / month`}
                  </Badge>
                  <Badge
                    color='soft-primary'
                    // pill
                    className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                  >
                    {details.availableBedrooms === '1'
                      ? `${details.availableBedrooms} room available`
                      : `${details.availableBedrooms} rooms available`}
                  </Badge>
                </p>
                <hr />
                <h6>
                  {description.length > 100
                    ? description.substring(0, 100) + '...'
                    : description}
                </h6>
                <hr />
                {amenities.map((item, i) => (
                  <Badge
                    color='soft-warning'
                    pill
                    key={i}
                    className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                  >
                    {`${item} `}
                  </Badge>
                ))}
                {utilities
                  .filter((item) => item.length > 0)
                  .map((item, index) => (
                    <Badge
                      key={index}
                      color='soft-info'
                      pill
                      className='t-0 r-0 mr-2 mt-2 fs--2 z-index-2'
                    >
                      {`${item} `}
                    </Badge>
                  ))}
              </div>
            }
          </div>
        </div>
      </Flex>
    </Fragment>
  );

  return (
    <Col className='mb-4' md={rest.md} lg={rest.lg} index={rest.index}>
      {plan === 'premium' ? (
        <Alert
          className='p-2 border-0'
          color='secondary'
          style={{ backgroundColor: '#f9ca24' }}
        >
          <Badge
            color='danger'
            pill
            className='position-absolute t-0 r-0 mr-2 mt-4 fs--2 z-index-3'
          >
            Featured
          </Badge>
          <Card className='border-0'>{bodyToReturn} </Card>
        </Alert>
      ) : (
        <Card>{bodyToReturn}</Card>
      )}
    </Col>
  );
};

ProductGridH.propTypes = {
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  amenities: PropTypes.array,
  details: PropTypes.object,
  sale: PropTypes.bool,
  sliderSettings: PropTypes.object,
  _id: PropTypes.string,
  description: PropTypes.string,
  utilities: PropTypes.array,
  createdAt: PropTypes.string,
  user: PropTypes.string,
  plan: PropTypes.string,
  contactMethod: PropTypes.string,
  contactName: PropTypes.string,
  quantity: PropTypes.string,

  // redux
  addFavouriteHousing_Action: PropTypes.func,
  removeFavouriteHousing_Action: PropTypes.func,
  housingState: PropTypes.object.isRequired,
  showFavourite: PropTypes.bool,
};

ProductGridH.defaultProps = { isNew: true };

const mapStateToProps = (state) => ({
  housingState: state.housingReducer,
});

const mapDispatchToProps = {
  addFavouriteHousing_Action,
  removeFavouriteHousing_Action,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGridH);
