import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// misc
import createMarkup from '../../helpers/createMarkup';
import { iconList, menuList1 } from '../../data/footer';
import { version } from '../../config';
import { isIterableArray } from '../../helpers/utils';

// components
import Section from '../common/Section';
import IconGroup from '../common/icon/IconGroup';

// libraries
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animateScroll } from 'react-scroll';

// queries
import { useGetArticles_Query } from '../../queries/otherQueries';

const FooterTitle = ({ children }) => (
  <h5 className='text-uppercase text-white opacity-85 mb-3'>{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className='list-unstyled'>
    {list.map(({ title, to }, index) => (
      <li className='mb-1' key={index}>
        <Link className='text-600' to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className='list-unstyled'>
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className='fs-0 mb-0'>
          <a
            className='text-600'
            href={blog.link}
            rel='noopener noreferrer'
            target='_blank'
          >
            {blog.title}
          </a>
        </h5>
        <p className='text-600 opacity-50'>
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const { data: articles } = useGetArticles_Query();

  return (
    <Fragment>
      <Section bg='dark' className='pt-8 pb-4'>
        <div
          className='position-absolute btn-back-to-top cursor-pointer bg-dark'
          onClick={animateScroll.scrollToTop}
        >
          <FontAwesomeIcon
            icon='chevron-up'
            transform='rotate-45'
            className='text-600'
          />
        </div>
        <Row>
          <Col lg={4}>
            <FooterTitle>Our Mission</FooterTitle>
            <div />
            <p className='text-600'>
              {`Peernet is focused on creating the most value for students.`}
            </p>
            <hr className='bg-light' />{' '}
            <p className='text-600'>
              {`A self supporting platform, Peernet enable post-secondary students to find all
              student related services and resources in one place. Students
              focus on learning while we supplement it in all aspects of student
              life.`}
            </p>
            <IconGroup className='mt-4' icons={iconList} />
          </Col>
          <Col className='pl-lg-6 pl-xl-8'>
            <Row className='mt-5 mt-lg-0'>
              <Col xs={6} md={3}>
                <FooterTitle>Company</FooterTitle>
                <FooterList list={menuList1} />
              </Col>
              <Col className='mt-5 mt-md-0'>
                {articles && isIterableArray(articles) && (
                  <Fragment>
                    <FooterTitle>From the Blog</FooterTitle>
                    <FooterBlogList list={articles.slice(0, 4)} />
                  </Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Section>
      <Section
        bg='dark'
        className='py-0 py-3 border-top border-600 text-center fs--1'
      >
        <Row className='justify-content-between'>
          <Col xs={12} sm='auto'>
            <p className='mb-0 text-600'>
              {`Made with`}
              <span className='px-1'>{`❤`}</span>
              {`  in Toronto`}
              <span className='d-none d-sm-inline-block'>| </span>
              <br className='d-sm-none' /> {new Date().getFullYear()} &copy;{' '}
              <a
                className='text-white opacity-85'
                href='https://www.peernet.co'
                target='_blank'
                rel='noopener noreferrer'
              >
                Peernet
              </a>
            </p>
          </Col>
          <Col xs={12} sm='auto'>
            <p className='mb-0 text-600'>v{version}</p>
          </Col>
        </Row>
      </Section>
    </Fragment>
  );
};

export default FooterStandard;
