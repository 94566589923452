import React, { useState, useContext, useEffect } from 'react';
// import { useForm } from 'react-hook-form';

// components
// context
import { ClubContext } from '../../privatePages/ViewClubPage';

// queries
import {
  useGetClubById_Query,
  useUpdateClub_Mutation,
} from '../../../../queries/clubQueries';
import UpdateClubDetailsForm from './UpdateClubDetailsForm';

// Update Club Details has the ability to update everything about the club besides the banner & logo
const UpdateClubDetails = () => {
  // const { register, handleSubmit, errors, control } = useForm();

  // mounting lifecycle
  const { _id } = useContext(ClubContext);
  const { data: clubData, isLoading } = useGetClubById_Query(_id);
  const [clubInfo, setClubInfo] = useState({});
  const { clubDescription, clubUniversity, clubName, tags, contactEmail } =
    clubData;
  let twitter = '';
  let facebook = '';
  let instagram = '';
  let linkedin = '';
  let website = '';

  if (clubData?.socialInfo?.twitter) {
    twitter = clubData.socialInfo.twitter;
  }
  if (clubData?.socialInfo?.facebook) {
    facebook = clubData.socialInfo.facebook;
  }
  if (clubData?.socialInfo?.instagram) {
    instagram = clubData.socialInfo.instagram;
  }
  if (clubData?.socialInfo?.linkedin) {
    linkedin = clubData.socialInfo.linkedin;
  }
  if (clubData?.socialInfo?.website) {
    website = clubData.socialInfo.website;
  }
  const defaultState = {
    clubDescription,
    clubUniversity: clubUniversity._id,
    clubName,
    tags,
    contactEmail,
    twitter,
    facebook,
    instagram,
    linkedin,
    website,
  };

  useEffect(() => {
    setClubInfo(defaultState);
  }, [clubData]);

  // updating lifecycle
  const { mutate: updateClub } = useUpdateClub_Mutation();

  const changeFormField = (e, custom, customValues) => {
    if (custom) {
      setClubInfo({ ...clubInfo, [custom]: customValues });
    } else {
      setClubInfo({
        ...clubInfo,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onUpdateFormSubmit = (data) => {
    const formattedClubInfo = {
      // club info
      clubName: data.clubName,
      clubDescription: data.clubDescription,
      contactEmail: data.contactEmail,
      clubUniversity: data.clubUniversity,

      // other info
      tags: data.tagsInfo ? data.tagsInfo.map((tag) => tag.value) : [],

      // social info
      socialInfo: {
        twitter: data.twitterInfo,
        facebook: data.facebookInfo,
        instagram: data.instagramInfo,
        linkedin: data.linkedinInfo,
        website: data.websiteInfo,
      },

      clubId: _id,
    };
    updateClub(formattedClubInfo);
  };

  const props = {
    clubInfo,
    defaultValues: defaultState,
    changeFormField,
    onUpdateFormSubmit,
    isUpdateClubLoading: isLoading,
  };

  return <UpdateClubDetailsForm {...props} />;
};

export default UpdateClubDetails;
