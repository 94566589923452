import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useGetProfileById_Query = (profile, isEnabled = true) => {
  return useQuery(
    ['profile', profile],
    () =>
      axios
        .get(`/api/v2/profile/${profile}`)
        .then((res) => res.data.dataPayload.profile),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      retry: false,
      enabled: isEnabled,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

// const create profile

// const update profile pic
const updateProfilePic = async (input) => {
  const formData = new FormData();
  formData.append('file', input.file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await axios.put('/api/v2/profile/newp', formData, config);

  return { res: res, newProfile: res.data.dataPayload.profile };
};

export const useUpdateProfilePic_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => updateProfilePic(inputData), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);

      await QueryClient.cancelQueries(['profile', inputData.userId]);

      const oldProfileData = QueryClient.getQueryData([
        'profile',
        inputData.userId,
      ]);

      QueryClient.setQueryData(['profile', inputData.userId], {
        ...oldProfileData,
        ...data.newProfile,
      });
    },
    onError: (err) => axiosErrorToast(err),
  });
};

// const update background pic
const updateBackgroundPic = async (input) => {
  const formData = new FormData();
  formData.append('bg', input.bg);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await axios.put('/api/v2/profile/newbg', formData, config);

  return { res: res, newProfile: res.data.dataPayload.profile };
};

export const useUpdateBackgroundPic_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => updateBackgroundPic(inputData), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);
      await QueryClient.cancelQueries(['profile', inputData.userId]);

      const oldProfileData = QueryClient.getQueryData([
        'profile',
        inputData.userId,
      ]);

      QueryClient.setQueryData(['profile', inputData.userId], {
        ...oldProfileData,
        ...data.newProfile,
      });
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const updateMyProfile = async (input) => {
  const newOb = {};
  newOb.name = input.firstName;
  newOb.lastName = input.lastName;
  newOb.currentProgram = input.heading;
  newOb.bio = input.intro;
  newOb.courses = input.courses;
  newOb.currentYear = input.currentYear;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios.put('/api/v2/profile/me', newOb, config);

  return res;
};

export const useUpdateMyProfileInfo_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => updateMyProfile(inputData), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);
      await QueryClient.refetchQueries(['profile', inputData.userId]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const createNewProfile = async (input) => {
  const formData = new FormData();
  formData.append('file', input.file);
  formData.append('gender', input.gender);
  formData.append('dob', input.dob);
  formData.append('currentProgram', input.currentProgram);
  formData.append('currentYear', input.currentYear);
  formData.append('courses', JSON.stringify(input.courses));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const res = await axios.post('/api/v2/profile/', formData, config);

  return res;
};

export const useCreateMyProfile_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => createNewProfile(inputData), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);
      await QueryClient.refetchQueries(['profile', inputData.userId]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};
