import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import CardSummary from './CardSummary';
import { enumToResourceMap } from '../../static/maps';

const ShowResStats = ({ resource }) => {
  return (
    <Fragment>
      <div className='card-deck mb-2'>
        <CardSummary title='Views' color='info'>
          {resource.viewCount}
        </CardSummary>
        <CardSummary title='Resource Type' color='warning'>
          {enumToResourceMap[resource.type]}
        </CardSummary>
      </div>
    </Fragment>
  );
};

ShowResStats.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default connect(null, {})(ShowResStats);
