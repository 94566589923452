import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// components
import NavbarDropdownSimple from './NavbarDropdownSimple';
import {
  // landlord options
  landlordOptions_forLanding,
  studentOptions_forLanding,
  studentandlandlordOptions_forLanding,

  //resources options
  studyMaterialOptions_forLanding,
  clubOptions_forLanding,

  //markeplace options
  products_forLanding,
  products_textbook_forLanding,
  // tutoring options
  tutors_register_forLanding,
  tutors_see_all_forLanding,
  view_internships_forLanding,
} from '../../routes';
import { breakpoints } from '../../helpers/utils';
import { topNavbarBreakpoint } from '../../config';

const TopDropDownMenusNonLoggedIn = ({ setNavbarCollapsed }) => {
  const studentHousingOptions = [
    studentandlandlordOptions_forLanding,
    studentOptions_forLanding,
    landlordOptions_forLanding,
  ];
  const studyMaterialOptions = [
    studyMaterialOptions_forLanding,
    clubOptions_forLanding,
  ];
  const TutoringOptions = [
    tutors_see_all_forLanding,
    tutors_register_forLanding,
  ];
  const MarketplaceOptions = [
    products_textbook_forLanding,
    products_forLanding,
  ];
  const InternshipOptions = [view_internships_forLanding];
  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };

  const enabledFeatured =
    process.env.REACT_APP_ENABLED_FEATURES === 'show_prod_enabled_features'
      ? [
          { title: 'Student Housing', items: studentHousingOptions },
          { title: 'Study Material & Groups', items: studyMaterialOptions },
          { title: 'Find Tutors for your Courses', items: TutoringOptions },
          { title: 'Buy & Sell', items: MarketplaceOptions },
          { title: 'Internships', items: InternshipOptions },
        ]
      : [
          { title: 'Student Housing', items: studentHousingOptions },
          { title: 'Study Material & Groups', items: studyMaterialOptions },
          { title: 'Find Tutors for your Courses', items: TutoringOptions },
          { title: 'Buy & Sell', items: MarketplaceOptions },
          { title: 'Internships', items: InternshipOptions },
        ];

  return (
    <Fragment>
      {enabledFeatured.map((item, index) => (
        <NavbarDropdownSimple
          key={index}
          title={item.title}
          items={item.items}
          handleSetNavbarCollapsed={handleSetNavbarCollapsed}
          dropdownStyle={'wide'}
        />
      ))}
      {/* // DO NOT REMOVE - talk to adit */}
      {/* <NavbarDropdownSimple
        title={'Student Housing'}
        items={studentHousingOptions}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        dropdownStyle={'wide'}
      />
      <NavbarDropdownSimple
        title={'Study Material & Groups'}
        items={studyMaterialOptions}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        dropdownStyle={'wide'}
      />
      <NavbarDropdownSimple
        title={'Find Tutors for your Courses'}
        items={TutoringOptions}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        dropdownStyle={'wide'}
      />
      <NavbarDropdownSimple
        title={'Buy & Sell'}
        items={MarketplaceOptions}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        dropdownStyle={'wide'}
      />
      <NavbarDropdownSimple
        title={'Internships'}
        items={InternshipOptions}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
        dropdownStyle={'wide'}
      />
      */}
    </Fragment>
  );
};

TopDropDownMenusNonLoggedIn.propTypes = {
  setNavbarCollapsed: PropTypes.func.isRequired,
};

export default TopDropDownMenusNonLoggedIn;
