import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// libraries
import classNames from 'classnames';
import Moment from 'react-moment';

// components
import Avatar from '../../../common/Avatar';

// queries
import { useMarkNotificationAsRead_Query } from '../../../../queries/notificationQueries';

const NewGroupJoinRequestNotification = ({
  // notificationData
  to,
  avatar,
  frontendFields,
  time,
  notificationId,

  // other
  className,
  unread,
  flush,
  emoji,
}) => {
  function formatTime(time) {
    //now - 4 days > time -> format else bottom
    const curr = new Date(time).getTime();
    const time4DaysAgo = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
    const time3MonthAgo = new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
    if (time4DaysAgo < curr) {
      // The yourDate time is less than 4 days from now
      return <Moment fromNow>{time}</Moment>;
    } else if (time3MonthAgo < curr) {
      return <Moment format='ddd MMM D'>{time}</Moment>;
    } else {
      return <Moment format='D/M/YYYY'>{time}</Moment>;
    }
  }

  const { mutate: markNotiAsRead } = useMarkNotificationAsRead_Query();
  return (
    <Link
      className={classNames(
        'notification',
        { 'bg-200': unread, 'notification-flush': flush },
        className
      )}
      to={to}
      onClick={() => {
        if (unread) {
          markNotiAsRead({ notificationId });
        } else {
        }
      }}
    >
      {avatar && (
        <div className='notification-avatar'>
          <Avatar src={avatar} size={'2xl'} className='mr-3' />
        </div>
      )}
      <div className='notification-body'>
        <p className={emoji ? 'mb-1' : 'mb-0'}>
          {' '}
          <b>{frontendFields.senderName}</b> {`has requested to join`}{' '}
          <b>{frontendFields.groupName}</b>
        </p>
        <span className='notification-time'>
          {emoji && (
            <span className='mr-1' role='img' aria-label='Emoji'>
              {emoji}
            </span>
          )}
          {formatTime(time)}
        </span>
      </div>
    </Link>
  );
};
NewGroupJoinRequestNotification.propTypes = {
  // notificationData
  to: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  frontendFields: PropTypes.object.isRequired,
  time: PropTypes.string.isRequired,
  notificationId: PropTypes.string,

  // other
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
};

NewGroupJoinRequestNotification.defaultProps = {
  unread: false,
  flush: false,
  notificationId: '',
};

export default NewGroupJoinRequestNotification;
