import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const ShowResFile = ({ resource }) => {
  let [loaded, setLoaded] = useState(false);

  const onLoadedPdf = () => {
    setLoaded(true);
  };

  return (
    <Fragment>
      <div className='text-center mb-3'>
        {resource.resourceName &&
        (resource.resourceName.includes('.jpg') ||
          resource.resourceName.includes('.jpeg') ||
          resource.resourceName.includes('.png')) ? (
          <Fragment>
            {loaded === false ? <Spinner color='warning' /> : null}
            <img
              alt=''
              width='100%'
              style={{ border: 'none' }}
              src={resource.resourceLink}
              onLoad={onLoadedPdf}
            />
          </Fragment>
        ) : resource.resourceLink.includes('drive.google.com') ? (
          <Fragment>
            {loaded === false ? <Spinner color='secondary' /> : null}

            <iframe
              title='unique1'
              src={
                resource.resourceLink.replace(
                  'https://drive.google.com/open?id=',
                  'https://drive.google.com/u/0/uc?id='
                ) + '&export=pdf#toolbar=0'
              }
              height='800px'
              width='100%'
              style={{ border: 'none' }}
              onLoad={onLoadedPdf}
            >
              This browser does not support PDFs. Please download the PDF to
              view it:
              <a href={resource.resourceLink}>Download PDF</a>
            </iframe>
          </Fragment>
        ) : (
          <Fragment>
            {loaded === false ? <Spinner color='primary' /> : null}
            <iframe
              title='unique2'
              src={resource.resourceLink}
              height='800px'
              width='100%'
              style={{ border: 'none' }}
              onLoad={onLoadedPdf}
            >
              This browser does not support PDFs. Please download the PDF to
              view it:
              <a href={resource.resourceLink}>Download PDF</a>
            </iframe>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
ShowResFile.propTypes = {
  resource: PropTypes.object.isRequired,
};
export default connect(null, {})(ShowResFile);
