import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_viewPost_Event = (post, user) => {
  if (user && user._id && post && post._id) {
    posthog.capture('viewPost', {
      viewPost___postId: post._id,
      viewPost___viewerId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewPost' },
      {
        viewPost___postId: post._id,
        viewPost___viewerId: user._id,
      }
    );
  }
};

export const posthog_likePost_Event = (postId, user) => {
  if (user && user._id && postId) {
    posthog.capture('likePost', {
      likePost___postId: postId,
      likePost___userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'likePost' },
      {
        likePost___postId: postId,
        likePost___userId: user._id,
      }
    );
  }
};

export const posthog_dislikePost_Event = (postId, user) => {
  if (user && user._id && postId) {
    posthog.capture('dislikePost', {
      dislikePost___postId: postId,
      dislikePost___userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'dislikePost' },
      {
        dislikePost___postId: postId,
        dislikePost___userId: user._id,
      }
    );
  }
};

export const posthog_postReactionsViewed_Event = (postId, user) => {
  if (user && user._id && postId) {
    posthog.capture('postReactionsViewed', {
      postReactionsViewed___postId: postId,
      postReactionsViewed___userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'postReactionsViewed' },
      {
        postReactionsViewed___postId: postId,
        postReactionsViewed___userId: user._id,
      }
    );
  }
};
