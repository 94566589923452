import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Spinner } from 'reactstrap';
import posthog from 'posthog-js';
import { setAiUser } from '../../AppInsights';

const RedirectUser = ({ auth: { loading, user } }) => {
  if (user) {
    posthog.identify(user._id, { email: user.email });
    setAiUser(user._id.toString());
  }

  if (loading === true) {
    return <Spinner color='primary' />;
  }

  if (user && user.role.includes('student')) {
    return <Redirect to='/feed' />;
  } else if (user && user.role.includes('landlord')) {
    return <Redirect to='/dash' />;
  } else {
    return <Spinner color='primary' />;
  }
};

RedirectUser.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps)(RedirectUser);
