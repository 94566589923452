import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_sharedLinkOpened_Event = (encodedData, now, expiry) => {
  if (
    encodedData &&
    encodedData.shareLinkOpened___resourceId &&
    encodedData.shareLinkOpened__originatorId &&
    encodedData.shareLinkOpened___expiryDate &&
    now &&
    expiry
  ) {
    posthog.capture('sharedLinkOpened', {
      shareLinkOpened___resourceId: encodedData.shareLinkOpened___resourceId,
      shareLinkOpened__originatorId: encodedData.shareLinkOpened__originatorId,
      shareLinkOpened___expiryDate: encodedData.shareLinkOpened___expiryDate,
      shareLinkOpened___linkExpired: now > expiry,
    });

    appInsights.trackEvent(
      { name: 'sharedLinkOpened' },
      {
        shareLinkOpened___resourceId: encodedData.shareLinkOpened___resourceId,
        shareLinkOpened__originatorId:
          encodedData.shareLinkOpened__originatorId,
        shareLinkOpened___expiryDate: encodedData.shareLinkOpened___expiryDate,
        shareLinkOpened___linkExpired: now > expiry,
      }
    );
  }
};
