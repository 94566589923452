import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//libraries
import { Row, Card } from 'reactstrap';
import FalconCardHeader from '../../../../common/FalconCardHeader';
import { toast } from 'react-toastify';

const SelectPlan = ({ selectedPlan, setPlanParent, showPreviewComponent }) => {
  const handleSubmit = (e, btnSelected) => {
    e.preventDefault();
    setPlanParent(btnSelected);
    if (showPreviewComponent === true) {
      toast.info(
        `Remember to press 'Save Changes' below to see your updates!`,
        {
          toastId: 'planSelect',
        }
      );
    }
  };

  return (
    <Fragment>
      <Card className='mb-3'>
        <FalconCardHeader title='Select Plan' titleTag='h5' />
        <div className='container'>
          <Row className='mb-4 mt-2 px-2'>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-1 bg-light'>
                <div className='card-body'>
                  <div className='text-center mt-2'>
                    <span className='text-dark font-weight-bold'>
                      <b>{`$25.00 `}</b>
                    </span>
                    <span style={{ textDecoration: 'line-through' }}>
                      $29.99
                    </span>
                  </div>

                  <div className='text-center mb-2 text-success'>
                    <span>
                      <b>Basic Plan</b>
                    </span>
                  </div>
                  <hr className='border-dashed d-block d-md-none d-xl-block d-xxl-none' />

                  <ul className='list-group mb-2'>
                    <li className='list-group-item text-gray-900 p-1'>
                      1 month
                    </li>
                    <li className='list-group-item text-gray-900 p-1'>
                      3 photos
                    </li>
                  </ul>

                  <div className='text-center mt-3 mb-1'>
                    <button
                      onClick={(e) => handleSubmit(e, 'basic')}
                      className={`btn ${
                        selectedPlan === 'basic'
                          ? 'btn-success'
                          : 'btn-outline-success'
                      }`}
                    >
                      {selectedPlan === 'basic' ? `Selected` : `Select`}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-1 bg-light'>
                <div className='card-body'>
                  <div className='text-center mt-2 '>
                    <span className='text-dark font-weight-bold'>
                      <b>{`$35.00 `}</b>
                    </span>
                    <span style={{ textDecoration: 'line-through' }}>
                      $44.99
                    </span>{' '}
                  </div>

                  <div className='text-center mb-2 text-success'>
                    <span>
                      <b>Standard Plan</b>
                    </span>
                  </div>
                  <hr className='border-dashed d-block d-md-none d-xl-block d-xxl-none' />

                  <ul className='list-group mb-2'>
                    <li className='list-group-item text-gray-900 p-1'>
                      1 month
                    </li>
                    <li className='list-group-item text-gray-900 p-1'>
                      15 photos
                    </li>
                    <li className='list-group-item text-gray-900 p-1'>
                      Search result priority over basic plans
                    </li>
                  </ul>

                  <div className='text-center mt-3 mb-1'>
                    <button
                      onClick={(e) => handleSubmit(e, 'standard')}
                      className={`btn ${
                        selectedPlan === 'standard'
                          ? 'btn-success'
                          : 'btn-outline-success'
                      }`}
                    >
                      {selectedPlan === 'standard' ? `Selected` : `Select`}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-1 bg-light'>
                <div className='card-body'>
                  <div className='text-center mt-2  '>
                    <span className='text-warning font-weight-bold'>
                      <b>{`25% off`}</b>
                    </span>
                  </div>
                  <div className='text-center mt-2  '>
                    <span className='text-dark font-weight-bold'>
                      <b>{`$45.00 `}</b>
                    </span>
                    <span style={{ textDecoration: 'line-through' }}>
                      $59.99
                    </span>
                  </div>

                  <div className=' text-center mb-2 text-primary'>
                    <span>
                      <b>Premium Plan</b>
                    </span>
                  </div>
                  <hr className='border-dashed d-block d-md-none d-xl-block d-xxl-none' />

                  <ul className='list-group mb-2'>
                    <li className='list-group-item text-gray-900 p-1'>
                      1 month
                    </li>
                    <li className='list-group-item text-gray-900 p-1'>
                      25 photos
                    </li>
                    <li className='list-group-item text-gray-900 p-1'>
                      Search result priority over ALL plans
                    </li>

                    <li className='list-group-item text-gray-900 p-1'>
                      Featured Tag
                    </li>
                    <li className='list-group-item text-gray-900 p-1'>
                      Included in rotating feature banner
                    </li>
                  </ul>

                  <div className='text-center mt-3 mb-1'>
                    <button
                      disabled=''
                      onClick={(e) => handleSubmit(e, 'premium')}
                      className={`btn ${
                        selectedPlan === 'premium'
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      }`}
                    >
                      {selectedPlan === 'premium' ? `Selected` : `Select`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Card>
    </Fragment>
  );
};

SelectPlan.propTypes = {
  selectedPlan: PropTypes.string.isRequired,
  setPlanParent: PropTypes.func.isRequired,
  showPreviewComponent: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  createAdState: state.createHousingAdReducer,
});

export default connect(
  mapStateToProps,
  {}
  // { setPlanInRedux }
)(SelectPlan);
