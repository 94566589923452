import React, { Fragment } from 'react';

// components
import ClubPSA from './clubSideBarComponents/ClubPSA';

const ClubFeedSideBar = () => (
  <Fragment>
    <ClubPSA></ClubPSA>
  </Fragment>
);

export default ClubFeedSideBar;
