import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// libs
import { useForm } from 'react-hook-form';
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from 'reactstrap';

// components
import FalconCardHeader from '../../../common/FalconCardHeader';
import LightBoxGallery from '../../../common/LightBoxGallery';
import ButtonImgPostCreate from '../../../feed/ButtonImgPostCreate';

// static
import postImage from '../../../../../src/assets/img/illustrations/image.svg';

const UpdateClubLogoForm = ({
  clubInfo,
  changeFormField,
  onUpdateImgSubmit,
  isImgLoading,
}) => {
  // Subject to change
  const { logo } = clubInfo;

  const {
    register: registerImg,
    handleSubmit: handleImgSubmit,
    errors: errorsImg,
    getValues: getImgValues,
  } = useForm({ attachLogo: logo });
  const noNewImgs = !getImgValues().attachLogo?.[0];

  const onAddLogo = (e) => {
    changeFormField(e, 'logo', e.target.files[0]);
  };
  const dynamicLogo =
    typeof logo === 'object' ? URL.createObjectURL(logo) : logo;

  return (
    <>
      <Card className='mb-3'>
        <FalconCardHeader title='Update Club Logo' />
        <CardBody
          tag={Form}
          className='bg-white'
          onSubmit={handleImgSubmit(onUpdateImgSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Col>
                <Row>
                  {logo ? (
                    <LightBoxGallery images={[dynamicLogo]}>
                      {(openImgIndex) => (
                        <img
                          className='img-fluid rounded mb-2 cursor-pointer'
                          src={dynamicLogo}
                          alt='logo'
                          style={{ maxHeight: 100 }}
                          onClick={() => openImgIndex(0)}
                        />
                      )}
                    </LightBoxGallery>
                  ) : null}
                </Row>
                <Row>
                  <Label>
                    <Input
                      type='file'
                      accept='image/png, image/jpeg'
                      hidden
                      name='attachLogo'
                      onChange={(e) => onAddLogo(e)}
                      innerRef={registerImg({
                        validate: {
                          fileSize: (v) => {
                            // do a check if the file exists
                            if (v && v[0]) {
                              return (
                                v[0].size / 1024 / 1024 < 2 ||
                                'File must be less than 2 MB'
                              );
                            } else {
                              return true;
                            }
                          },
                        },
                      })}
                    />
                    <Col>
                      <Row>
                        <ButtonImgPostCreate imgSrc={postImage}>
                          <span>{logo ? `Update Logo` : `Select Logo`}</span>
                        </ButtonImgPostCreate>
                      </Row>
                      <Row>
                        <span>
                          <small className='text-warning'>
                            {` * required (Recomended size 500x500 image)`}
                          </small>
                        </span>
                      </Row>
                      <Row>
                        {errorsImg.attachLogo && (
                          <span>
                            <small className='text-danger'>
                              {errorsImg.attachLogo.message}
                            </small>
                          </span>
                        )}
                      </Row>
                    </Col>
                  </Label>
                </Row>
              </Col>
            </Col>
          </Row>
          <Button
            color='falcon-primary mt-4'
            type='submit'
            disabled={noNewImgs}
            size='sm'
          >
            {isImgLoading === true ? (
              <Fragment>
                {`Updating Logo...`} <Spinner size='sm' />
              </Fragment>
            ) : (
              `Update Logo`
            )}
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

UpdateClubLogoForm.propTypes = {
  changeFormField: PropTypes.func,
  onUpdateImgSubmit: PropTypes.func,
  isImgLoading: PropTypes.bool,
  clubInfo: PropTypes.object,
};

export default UpdateClubLogoForm;
