import React, { Fragment } from 'react';
import PropTypes, { array, string } from 'prop-types';

// components
import FalconLightBox from '../common/FalconLightBox';

// misc
import { isIterableArray } from '../../helpers/utils';

const Avatar = ({
  size,
  rounded,
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact,
  expandable,
}) => {
  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass,
  ].join(' ');

  const getAvatar = () => {
    if (src) {
      if (isIterableArray(src) && src.length === 2) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className='w-50 border-right'>
              <img src={src[0]} alt='' />
            </div>
            <div className='w-50 d-flex border-left'>
              <img src={src[1]} alt='' />
            </div>
          </div>
        );
      }
      if (isIterableArray(src) && src.length > 2) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className='w-50 border-right'>
              <img src={src[0]} alt='' />
            </div>
            <div className='w-50 d-flex flex-column'>
              <img src={src[1]} alt='' className='h-50 border-bottom' />
              <img src={src[2]} alt='' className='h-50' />
            </div>
          </div>
        );
      } else {
        return (
          <Fragment>
            {expandable === true ? (
              <FalconLightBox imgSrc={src}>
                <div className={`avatar avatar-${size} mb-3`}>
                  <img src={src} className={mediaClasses} alt='' />
                </div>
              </FalconLightBox>
            ) : (
              <img className={mediaClasses} src={src} alt='' />
            )}
          </Fragment>
        );
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
        </div>
      );
    }

    // return (
    //   <div className={`avatar-emoji ${mediaClasses}`}>
    //     <span role='img' aria-label='Emoji'>
    //       {/* <FontAwesomeIcon icon='fa-file' className='text-dark' /> */}
    //       {/* <i className='fa fa-fw fa-file text-info' /> */}
    //       {/* <i className='fas fa-user-secret' aria-hidden='true' /> */}
    //       {emoji}
    //     </span>
    //   </div>
    // );

    // default emoji
    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role='img' aria-label='Emoji'>
          {emoji}
        </span>
      </div>
    );
  };

  return <div className={classNames}>{getAvatar()}</div>;
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([array, string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  expandable: PropTypes.bool,
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '🙂',
  isExact: false,
  expandable: false,
};

export default Avatar;
