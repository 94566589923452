// libraries
import qs from 'query-string';
import posthog from 'posthog-js';
import { useLocation } from 'react-router-dom';
import { appInsights } from '../../AppInsights';

// utils
import { decryptEmailOpenedParams } from '../encryption';

function useEmailTracking() {
  const locationInfo = useLocation();
  const paramsInfo = qs.parse(locationInfo.search);
  let eid = paramsInfo.eid || '';

  if (eid && eid.length > 5) {
    let paramsObj = {};
    paramsObj = decryptEmailOpenedParams(eid);
    if (paramsObj !== false) {
      if (paramsObj.email) {
        posthog.identify(paramsObj.email, {
          email: paramsObj.email,
        });
      }
      if (paramsObj.emailId) {
        posthog.capture('emailOpened', {
          emailOpened___emailId: paramsObj.emailId,
          emailOpened___pathname: locationInfo.pathname,
          emailOpened___search: locationInfo.search,
          emailOpened___openerEmail: paramsObj.email
            ? paramsObj.email
            : 'email not found',
        });

        appInsights.trackEvent(
          { name: 'emailOpened' },
          {
            emailOpened___emailId: paramsObj.emailId,
            emailOpened___pathname: locationInfo.pathname,
            emailOpened___search: locationInfo.search,
            emailOpened___openerEmail: paramsObj.email
              ? paramsObj.email
              : 'email not found',
          }
        );
      }
    }
  }
}

export default useEmailTracking;
