import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useGetVisibleResourceForCourse_Query = (courseId) => {
  return useQuery(
    ['resources', 'course', courseId, 'visible'],
    () =>
      axios
        .get(`/api/v2/resource/${courseId}/show`)
        .then((res) => res.data.dataPayload.resources),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      placeholderData: [],
      retry: false,
    }
  );
};

export const useGetInvisibleResourceForCourse_Query = (courseId) => {
  return useQuery(
    ['resources', 'course', courseId, 'invisible'],
    () =>
      axios
        .get(`/api/v2/resource/${courseId}/notshow`)
        .then((res) => res.data.dataPayload.resources),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      placeholderData: [],
      retry: false,
    }
  );
};

export const useGetCourseResource_Query = (resourceId) => {
  return useQuery(
    ['resources', 'resource', resourceId],
    () =>
      axios
        .get(`/api/v2/resource/${resourceId}`)
        .then((res) => res.data.dataPayload.resource),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      retry: false,
    }
  );
};

export const useGetCourseResourcePublic_Query = (
  resourceId,
  enabled = false
) => {
  return useQuery(
    ['resources', 'resource', resourceId],
    () =>
      axios
        .get(`/api/v2/resource/public/${resourceId}`)
        .then((res) => res.data.dataPayload.resource),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      retry: false,
      enabled: enabled,
    }
  );
};

export const useGetAllSubmittedResourcesModOnly_Query = () => {
  return useQuery(
    ['resources', 'modonly', 'all'],
    () =>
      axios
        .get(`/api/v2/resource/submitted/all`)
        .then((res) => res.data.dataPayload.resources),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      placeholderData: [],
      retry: false,
    }
  );
};

const updateResource = async (inputData) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let ob = {};
  if (inputData.resourceName) {
    ob.resourceName = inputData.resourceName;
  }
  if (inputData.show) {
    ob.show = inputData.show;
  }
  if (inputData.status) {
    ob.status = inputData.status;
  }
  if (inputData.type) {
    ob.type = inputData.type;
  }

  const res = await axios.put(`/api/v2/resource/${inputData.id}`, ob, config);

  return { res, updateResource: res.data.dataPayload.updatedResource };
};

export const useUpdateResource_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((data) => updateResource(data), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
    },
    onMutate: async (inputData) => {
      // 1. Cancel all fetch queries related to this resource
      await QueryClient.cancelQueries(['resources', 'resource', inputData.id]);

      // 2. Get the current resource
      const oldResourceData = QueryClient.getQueryData([
        'resources',
        'resource',
        inputData.id,
      ]);

      // 3. Optimitstically update the resource if it exists
      if (oldResourceData) {
        QueryClient.setQueryData(['resources', 'resource', inputData.id], {
          ...oldResourceData,
          ...inputData,
        });
      }

      // 4. Return some context data for handling failures
      return { oldResourceData: oldResourceData, inputData };
    },

    onError: async (err, inputData, context) => {
      // if the action fails, reset the data back from the optimistic data to the current data
      QueryClient.setQueryData(
        ['resources', 'resource', context.inputData.id],
        context.oldResourceData
      );

      axiosErrorToast(err);
    },
  });
};
