import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

// queries
import {
  useGetUniversities_Query,
  useGetUniversityByName_Query,
} from '../../../queries/universityQueries';

// components
import { Input, FormGroup, Label, Badge, Spinner } from 'reactstrap';

const TextbookCourseSelect = ({ onChangeCourse, auth }) => {
  // gets all unis

  const [university, setUniversity] = useState(auth?.user.uni);

  const {
    isError: isUniversityError,
    isLoading: isUniversityLoading,
    data: universityData,
  } = useGetUniversityByName_Query(university);

  const { data: universities } = useGetUniversities_Query();

  const [showOptions, setShowOptions] = useState(false);

  const onChangeUni = (e) => {
    setUniversity(e.target.value);
  };

  const handleInputChange = (typedOption) => {
    let typedOptionWithoutWhitespace = typedOption.replace(/\s/g, '');
    if (typedOptionWithoutWhitespace.length >= 3) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };
  return (
    <Fragment>
      {(universityData === undefined && isUniversityError === false) ||
      isUniversityLoading ? (
        <div className='text-center'>
          <div className='text-center'>
            <Spinner color='primary' />
          </div>
        </div>
      ) : (
        <FormGroup>
          <Badge color={'soft-warning'} className='btn-block mb-2'>
            Find textbooks for your course:
          </Badge>
          <Label for='university'>Select University for Textbook</Label>
          <Input
            className='mb-2'
            label='courses'
            type='select'
            id='university'
            name='university'
            onChange={(e) => {
              onChangeUni(e);
            }}
            required
            defaultValue={university}
          >
            <option disabled value={[]}>
              Select University
            </option>
            {universities &&
              universities.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </Input>
        </FormGroup>
      )}
      <FormGroup>
        <Label for='courses'>Select courses that use this textbook</Label>
        <Select
          className='basic-single mb-3 border rounded'
          classNamePrefix='react-select'
          id='courses'
          // value={{ value: course._id, label: course.courseName }}
          isMulti
          isClearable={true}
          isSearchable={true}
          name='courses'
          placeholder='Enter Course name (4 characters required before options show up)'
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
          }}
          options={
            showOptions
              ? universityData?.courses.map((course) => {
                  return {
                    value: course._id,
                    label: course.courseName,
                  };
                })
              : []
          }
          onChange={onChangeCourse}
          onInputChange={handleInputChange}
        />
      </FormGroup>
    </Fragment>
  );
};

TextbookCourseSelect.propTypes = {
  onChangeCourse: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default TextbookCourseSelect;
