import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

// componenets
import FalconCardHeader from '../../peernet/common/FalconCardHeader';
import { isIterableArray } from '../../helpers/utils';
import ManageClubEventSummary from './ManageClubEventSummary';

const ManageClubEvents = ({ title, clubEvents }) => {
  return (
    <Fragment>
      <Card>
        <FalconCardHeader title={title}></FalconCardHeader>
        <CardBody className='fs--1'>
          <Row>
            {isIterableArray(clubEvents) &&
              clubEvents.map((event, index) => {
                return (
                  <Col md={6} className='h-100 mb-2' key={index}>
                    <ManageClubEventSummary
                      divider={
                        clubEvents.length % 2 === 0
                          ? index + 2 >= clubEvents.length
                            ? false
                            : true
                          : index + 1 >= clubEvents.length
                          ? false
                          : true
                      }
                      event={event}
                      myEvent={true}
                    />
                  </Col>
                );
              })}
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

ManageClubEvents.defauiltProps = {
  title: 'My Events',
};

ManageClubEvents.propTypes = {
  title: PropTypes.string,
  clubEvents: PropTypes.array,
};

export default ManageClubEvents;
