import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

// library
import {
  Card,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  Badge,
  Button,
  CardFooter,
  Col,
} from 'reactstrap';

const TutorCard = ({ tutor }) => {
  const history = useHistory();

  const onTutorCardClick = () => {
    history.push(`/tutor/${tutor?.user?._id}`);
  };

  return (
    <Col style={{ flexGrow: 0, cursor: 'pointer' }} onClick={onTutorCardClick}>
      <Card
        style={{ width: 'clamp(18rem, 25vw, 20rem)', minHeight: '21.75rem' }}
        className='m-2'
      >
        <Badge
          color='danger'
          pill
          className='position-absolute t-0 l-0 ml-2 mt-2 fs--2 z-index-2 text-dark'
          style={{ backgroundColor: '#f9ca24' }}
        >
          <strong>Featured</strong>
        </Badge>
        <CardImg
          src={tutor?.user?.avatar}
          top
          style={{ height: '13rem', objectFit: 'cover' }}
        />
        <CardBody className='text-left pb-0'>
          <CardTitle tag='h5'>{tutor?.user?.name}</CardTitle>

          <CardText tag='p' className='mb-1' style={{ minHeight: '5rem' }}>
            {tutor?.description.length > 100
              ? tutor?.description.substring(0, 100) + '...'
              : tutor?.description}
          </CardText>
        </CardBody>
        <CardFooter className='text-left'>
          <h6 className='fs--1 pr-3'>Universities:</h6>
          <h6>
            {tutor?.universities.map((c, index) => {
              return (
                <Badge color='soft-warning mb-1 mr-1' key={index}>
                  {c.name}
                </Badge>
              );
            })}
          </h6>
          <h6 className='fs--1 pr-3'>Course Specializations:</h6>
          <h6>
            {tutor?.coursesToTutor.map((c, index) => {
              return (
                <Badge color='soft-warning mb-1 mr-1' key={index}>
                  {c.courseId.courseCode}
                </Badge>
              );
            })}
          </h6>
          <Badge color='success mb-1' pill className='text-dark'>
            {tutor?.tutorRate ? '$' + tutor?.tutorRate + ' / hr' : '$25 / hr'}
          </Badge>
          {tutor.negotiable ? (
            <Badge color='soft-success' pill className='mx-2'>
              Negotiable
            </Badge>
          ) : null}
          <hr />
          <Link to={`/tutor/${tutor?.user?._id}`}>
            <Button color='primary' size='sm'>
              Visit Profile
            </Button>
          </Link>
        </CardFooter>
      </Card>
    </Col>
  );
};

TutorCard.propTypes = {
  tutor: PropTypes.object.isRequired,
};

export default TutorCard;
