export default [
  {
    icon: ['fab', 'instagram'],
    color: 'instagram',
    bg: 'white',
    href: 'https://www.instagram.com/peernetco/',
  },
  {
    icon: ['fab', 'linkedin-in'],
    color: 'linkedin',
    bg: 'white',
    href: 'https://www.linkedin.com/company/peernet/',
  },
  {
    icon: ['fab', 'medium-m'],
    color: '700',
    bg: 'white',
    href: 'https://medium.com/@peernet',
  },
];
