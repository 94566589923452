import React, { Fragment } from 'react';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import ViewAllClubsPage from '../privatePages/ViewAllClubsPage';
import ClubUpsellCard from '../components/ClubUpsellCard';

const ViewAllClubsPublic = () => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container my-8'}>
          <ClubUpsellCard />

          <ViewAllClubsPage isPublicView={true} />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

export default ViewAllClubsPublic;
