import axios from 'axios';

import {
  GET_POSTS_FOR_GROUP,
  SET_POST_REDUCER_LOADING,
  SET_POST_REDUCER_LOADING_FALSE,
} from '../types';
import { toast } from 'react-toastify';
import { axiosErrorToast, axiosSuccessToast } from '../../utils/toastHandler';

export const getFeedGroupPosts = (feedId, pageNum) => async (dispatch) => {
  dispatch({ type: SET_POST_REDUCER_LOADING });

  try {
    const page = pageNum && pageNum >= 1 ? pageNum - 1 : 0;

    const res = await axios.get(`/api/v2/feed/getposts/${feedId}?page=${page}`);

    dispatch({
      type: GET_POSTS_FOR_GROUP,
      payload: res.data.dataPayload.groupPosts,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
  dispatch({ type: SET_POST_REDUCER_LOADING_FALSE });
};

export const getWallPosts = (userId, pageNum) => async (dispatch) => {
  try {
    const page = pageNum && pageNum >= 1 ? pageNum - 1 : 0;

    const res = await axios.get(
      `/api/v2/feed/getwallposts/${userId}?page=${page}`
    );

    dispatch({
      type: GET_POSTS_FOR_GROUP,
      payload: res.data.dataPayload.posts,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const inviteUserToJoinGroup = (input) => async (dispatch) => {
  const data = { userToInviteEmail: input.email };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      `/api/v2/feed/invite/${input.feedId}`,
      data,
      config
    );

    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const acceptInvite = (feedId) => async (dispatch) => {
  try {
    await axios.put(`/api/v2/feed/acceptinvite/${feedId}`);
    toast(`Accepted invite`);
    window.location.reload();
  } catch (err) {
    toast.error(`something went wrong`);
  }
};

export const rejectInvite = (feedId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v2/feed/rejectinvite/${feedId}`);
    axiosSuccessToast(res);
    window.location.reload();
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const sendJoinRequest =
  (feedId, feedType, refreshAfterJoining) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/v2/feed/join/${feedId}`);

      axiosSuccessToast(res);

      if (feedType === 'public_feed' && refreshAfterJoining === true) {
        window.location.reload();
      }
    } catch (err) {
      axiosErrorToast(err);
    }
  };
