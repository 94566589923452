import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { Spinner, Container } from 'react-bootstrap';
import { Button } from 'reactstrap';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = ({
  auth: { user, loading },
  priceId,
  quantity,
  metadata,
  planName,
}) => {
  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    const res = await axios.post('/api/v2/stripe/createCheckoutSession', {
      priceId: priceId,
      quantity: quantity,
      metadata: metadata,
    });
    const sessionId = res.data.dataPayload.session.id;
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
    if (error.message) {
      toast.error(
        'Something went wrong taking you to check out. Please try again later.'
      );
    }
  };

  return loading ? (
    <Fragment>
      <Container className='justify-content-center align-items-center d-flex'>
        <Spinner animation='border' role='status' />
      </Container>
    </Fragment>
  ) : (
    <Fragment>
      <Container>
        <Button
          color='success'
          className='mt-3 px-5'
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          {`Pay now`}
        </Button>
      </Container>
    </Fragment>
  );
};

Checkout.propTypes = {
  auth: PropTypes.object.isRequired,
  priceId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  metadata: PropTypes.object.isRequired,
  planName: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {})(Checkout);
