import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libs
import { Nav, Spinner } from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';

import ChatSidebarItem from './NavChatSidebarItem';

// misc
import { isIterableArray } from '../../../helpers/utils';

const NavChatSidebar = ({ chatState: { loading, myChats } }) => {
  const [unReadMsgs, setUnReadMsgs] = useState([]);

  useEffect(() => {
    const filteredMsgs = myChats.filter(
      (thread) => thread.threadRead === false && thread.messages.length > 0
    );

    setUnReadMsgs(filteredMsgs);
  }, [myChats]);

  return loading ? (
    <Spinner color='warning' />
  ) : (
    <div className='rounded-left' style={{ maxWidth: '20rem' }}>
      <hr className='m-0' />
      <div className='contacts-list bg-white'>
        <Scrollbar
          style={{
            width: '100%',
            height: Math.min(25 * 59, 400, unReadMsgs.length * 59),
          }}
        >
          <Nav className='border-0'>
            {isIterableArray(unReadMsgs) &&
              unReadMsgs.map((thread) => (
                <ChatSidebarItem thread={thread} key={thread._id} />
              ))}
          </Nav>
        </Scrollbar>
      </div>
    </div>
  );
};

NavChatSidebar.propTypes = {
  chatState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  chatState: state.chatReducer,
  authState: state.authReducer,
});

export default connect(mapStateToProps, {})(NavChatSidebar);
