import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// libs
import { Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

const DeleteEventModal = ({
  showDeleteModal,
  closeModal,
  deleteEvent,
  eventId,
}) => {
  return (
    <Modal
      centered
      show={showDeleteModal}
      onHide={closeModal}
      className='my-auto'
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Are you sure you want to delete this event?`}</Modal.Title>
      </Modal.Header>
      <Fragment>
        <Button
          color='danger'
          className='m-2'
          onClick={() => deleteEvent(eventId)}
        >
          Confirm Delete
        </Button>
        <Button color='primary' className='m-2' onClick={closeModal}>
          Cancel
        </Button>
      </Fragment>
    </Modal>
  );
};

DeleteEventModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
};

export default DeleteEventModal;
