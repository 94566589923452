import React, { useState, Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

// import classNames from 'classnames';
import {
  Card,
  CardBody,
  Col,
  Media,
  Input,
  Form,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//components
import FalconCardHeader from '../../../../common/FalconCardHeader';
import CheckLoggedInModal from './CheckLoggedInModal';

const CheckoutWithStripe = ({
  createHousingAdReducer: { loading, justCreatedFormatted },
}) => {
  // pull offerid from url params
  const location = useLocation();
  const paramsInfo = qs.parse(location.search);
  let offerId = '';
  offerId = paramsInfo.oid || paramsInfo.OID;

  const [adLength, setAdLength] = useState(3);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (justCreatedFormatted.plan === 'premium') {
      setTotalPrice(45 * adLength);
    } else if (justCreatedFormatted.plan === 'standard') {
      setTotalPrice(35 * adLength);
    } else if (justCreatedFormatted.plan === 'basic') {
      setTotalPrice(25 * adLength);
    }
  }, [adLength, justCreatedFormatted]);

  const onChange = (e) => {
    if (e.target.value > 0) {
      setAdLength(e.target.value);
    }
  };

  return (
    <Card className='mb-3'>
      <FalconCardHeader
        title={
          <Fragment>
            <Media body className=''>
              <h5 className='mb-0 fs-0'>Finalize and Checkout</h5>
            </Media>
          </Fragment>
        }
        titleTag={Media}
        titleClass='align-items-center'
      />
      <CardBody className='p-0'>
        <Form>
          <Col className='justify-content-between mt-3 px-3 pb-3'>
            <Col>
              <h5 className='mx-0 px-0 text-primary'>
                <small>
                  <b>Your Ad is ready, you can now checkout!</b>
                </small>
              </h5>
              Don't worry you can update and make changes to your ads anytime.
              <br />
              <b>The Preview above shows how your ad will look.</b>
            </Col>

            <Col>
              <hr className='border-dashed d-block my-4' />

              <Col className='col-xl-5 col-lg-5 col-md-6 col-sm-12 px-0'>
                <FormGroup>
                  <Label for='rentalTypeSelect'>
                    How long do you want your ad to run for?
                  </Label>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      # of Months
                    </InputGroupAddon>
                    <Input
                      type='number'
                      id='rent'
                      name='rent'
                      onChange={onChange}
                      required
                      value={adLength}
                    />
                  </InputGroup>
                  <Label for='rentalTypeSelect' className='text-primary'>
                    A successful ad typically runs for 90 days
                  </Label>
                </FormGroup>
              </Col>
            </Col>
            <Col className='text-center text-xl-center'>
              {/* <Col className='text-center text-xl-center'> */}
              <hr className='border-dashed d-block my-4' />

              {justCreatedFormatted ? (
                <Fragment>
                  <div className='fs-2 font-weight-semi-bold'>
                    All Total:{' '}
                    <span className='text-primary'>
                      {justCreatedFormatted.plan === 'premium'
                        ? `$ ${(45.0 * adLength).toFixed(2)}`
                        : justCreatedFormatted.plan === 'standard'
                        ? `$ ${(35.0 * adLength).toFixed(2)}`
                        : justCreatedFormatted.plan === 'basic'
                        ? `$ ${(25.0 * adLength).toFixed(2)}`
                        : 'No plan selected'}
                    </span>
                  </div>
                  <div className='fs-2 font-weight-semi-bold'>
                    <small>
                      Plan:{' '}
                      <span className='text-dark'>
                        {justCreatedFormatted.plan === 'premium'
                          ? 'Premium'
                          : justCreatedFormatted.plan === 'standard'
                          ? 'Standard'
                          : justCreatedFormatted.plan === 'basic'
                          ? 'Basic'
                          : 'No plan selected'}
                      </span>
                    </small>
                    {', '}
                    <small>
                      Ad Length:{' '}
                      <span className='text-dark'>{`${
                        adLength > 1
                          ? `${adLength} months`
                          : `${adLength} month`
                      }`}</span>
                    </small>
                  </div>
                </Fragment>
              ) : null}

              {justCreatedFormatted && justCreatedFormatted._id ? (
                <Fragment>
                  <CheckLoggedInModal
                    buttonText={
                      offerId === '1'
                        ? `Activate 1 month free trial!`
                        : `Confirm & Pay`
                    }
                    total={totalPrice}
                    houseAdId={justCreatedFormatted._id}
                    adLengthInMonths={adLength}
                    plan={
                      justCreatedFormatted.plan
                        ? justCreatedFormatted.plan
                        : 'No plan selected'
                    }
                  />
                </Fragment>
              ) : null}
            </Col>
          </Col>
        </Form>
      </CardBody>
    </Card>
  );
};

CheckoutWithStripe.propTypes = {
  // states
  createHousingAdReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  createHousingAdReducer: state.createHousingAdReducer,
});

export default connect(mapStateToProps, {})(CheckoutWithStripe);
