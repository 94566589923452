import axios from 'axios';
import {
  GET_CHAT_THREADS,
  SET_CHAT_REDUCER_LOADING,
  SET_CHAT_REDUCER_LOADING_FALSE,
  SET_ACTIVE_THREAD,
  ADD_CHAT_CONN_OPENED,
  ADD_MSGS_TO_CHAT,
  SEND_MESSAGE,
  SEND_MESSAGE_DELIVERED,
  NEW_MESSAGE_RECIEVED,
  CREATE_NEW_CHAT_THREAD,
  MOVE_CHATBOX_TO_TOP,
  THREAD_LOAD_PREV_MSGS,
  USER_STARTED_TYPING,
  USER_STOPPED_TYPING,
  READ_STATUSES_UPDATE,
  RENAME_CHAT,
  CHAT_UPDATE_READ_STATUS,
  CHAT_MESSAGE_DELETED,
  CHAT_MESSAGE_UPDATED,
  UPDATE_UNREAD_MSGS,
} from '../types';

// utils
import { axiosErrorToast, successToast } from '../../utils/toastHandler';

// Get post
export const getMyChatThreads_Action = (loggedInUserId) => async (dispatch) => {
  dispatch({ type: SET_CHAT_REDUCER_LOADING });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_CHAT_APP_URL}/api/v2/chat/myChats`
    );

    dispatch({
      type: GET_CHAT_THREADS,
      payload: { chats: res.data.dataPayload.chats, loggedInUserId },
    });
  } catch (err) {
    axiosErrorToast(err);
    dispatch({ type: SET_CHAT_REDUCER_LOADING_FALSE });
  }
};

// Get post
export const setActiveThread_Action = (threadId) => async (dispatch) => {
  dispatch({
    type: SET_ACTIVE_THREAD,
    payload: threadId,
  });
};

export const addChatConnectionOpened_Action =
  (chatId, group) => async (dispatch) => {
    // this dispatch has to happen first
    dispatch({
      type: ADD_MSGS_TO_CHAT,
      payload: { chatId, group },
    });

    // this dispatch has to happen 2nd, because it uses the above's data
    dispatch({
      type: ADD_CHAT_CONN_OPENED,
      payload: chatId,
    });

    // this happens 3rd
    dispatch({
      type: UPDATE_UNREAD_MSGS,
    });
  };

export const sendMessage_Action = (chatId, messageData) => async (dispatch) => {
  const newMessage = {
    content: messageData.messageContent,
    sender: messageData.userId,
    chatId: chatId,
    createdAt: new Date().getTime(),
    status: 'sent',
    messageUuid: messageData.messageUuid,
  };

  dispatch({
    type: SEND_MESSAGE,
    payload: { chatId, messageData: newMessage },
  });

  dispatch({
    type: MOVE_CHATBOX_TO_TOP,
    payload: { chatId },
  });
};

export const sendMessageDelievered_Action =
  (chatId, messageData, messageUuid, readStatus) => async (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_DELIVERED,
      payload: { chatId, messageData, messageUuid },
    });

    dispatch({
      type: CHAT_UPDATE_READ_STATUS,
      payload: { chatId, readStatus },
    });
  };

export const newMessageRecieved_Action = (messageData) => async (dispatch) => {
  dispatch({
    type: NEW_MESSAGE_RECIEVED,
    payload: { messageData },
  });

  dispatch({
    type: MOVE_CHATBOX_TO_TOP,
    payload: { chatId: messageData.chatId },
  });
};

export const createChat_Action = (chatData, userId) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CHAT_APP_URL}/api/v2/chat/createChat`,
      chatData,
      config
    );

    // if already exists, open chat
    if (res.data.message === 'Chat Already Exists') {
      successToast('Chat Already Exists, opening it now');
      dispatch({
        type: SET_ACTIVE_THREAD,
        payload: res.data.dataPayload.chatIds[0],
      });
    } else {
      dispatch({
        type: CREATE_NEW_CHAT_THREAD,
        payload: res.data.dataPayload,
        loggedInUserId: userId,
      });
    }
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const previousMessagesLoaded_Action = (data) => async (dispatch) => {
  dispatch({
    type: THREAD_LOAD_PREV_MSGS,
    payload: {
      messages: data.messages,
      groupId: data.groupId,
      hasMore: data.hasMore,
    },
  });
};

export const userStartedTyping_Action = (data) => async (dispatch) => {
  dispatch({
    type: USER_STARTED_TYPING,
    payload: {
      groupId: data.groupId,
      userId: data.userId,
    },
  });
};
export const userStoppedTyping_Action = (data) => async (dispatch) => {
  dispatch({
    type: USER_STOPPED_TYPING,
    payload: {
      groupId: data.groupId,
      userId: data.userId,
    },
  });
};

export const updateReadStatusForChat_Action =
  (data, user) => async (dispatch) => {
    dispatch({
      type: READ_STATUSES_UPDATE,
      payload: {
        groupId: data.groupId,
        readStatus: data.readStatus,
        loggedInUserId: user._id,
      },
    });
  };

export const renameChat_Action = (data, chatId) => async (dispatch) => {
  dispatch({ type: SET_CHAT_REDUCER_LOADING });

  try {
    const res = await axios.put(
      `${process.env.REACT_APP_CHAT_APP_URL}/api/v2/chat/renameChat/${chatId}`,
      data
    );
    dispatch({
      type: RENAME_CHAT,
      payload: {
        updatedChat: res.data.dataPayload.updatedChat,
        chatId: res.data.dataPayload.updatedChat._id,
      },
    });
  } catch (err) {
    dispatch({ type: SET_CHAT_REDUCER_LOADING_FALSE });
    axiosErrorToast(err);
  }
};

export const messageUpdated_Action = (data) => async (dispatch) => {
  dispatch({
    type: CHAT_MESSAGE_UPDATED,
    payload: {
      chatId: data.chatId,
      messageId: data._id,
      messageContent: data.content,
      updatedMessage: data,
    },
  });
};

export const messageDeleted_Action = (data) => async (dispatch) => {
  dispatch({
    type: CHAT_MESSAGE_DELETED,
    payload: {
      chatId: data.chatId,
      messageId: data.messageId,
    },
  });
};
