import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import {
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
} from 'reactstrap';

// components
import FalconCardHeader from '../../common/FalconCardHeader';

// actions
import { contactLandlordLoggedIn } from '../../../redux/actions/otherActions';

const ContactLandlord = ({
  user,
  landlordEmail,
  // actions
  contactLandlordLoggedIn,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const [formData, setFormData] = useState({
    message: '',
  });
  const { message } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();

    contactLandlordLoggedIn({ message, name: user.name, landlordEmail });
    setFormData({
      message: '',
    });
  };

  useEffect(() => {
    setIsDisabled(!message);
  }, [message]);
  return (
    <Fragment>
      <Row className='mt-4'>
        <div className='col-sm-12'>
          <Card className='mb-3'>
            <FalconCardHeader title='Contact Landlord (Email)' titleTag='h5' />
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Your Name</Label>
                  <Input
                    placeholder={'Name'}
                    value={user ? user.name : ''}
                    type='message'
                    name='name'
                    onChange={(e) => onChange(e)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Your Email address</Label>
                  <Input
                    placeholder={'Email address'}
                    value={user ? user.email : ''}
                    type='email'
                    name='email'
                    onChange={(e) => onChange(e)}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>{`Message`} </Label>

                  <Input
                    // className='resize-none'
                    placeholder='Type a question or a message you would like to ask the landlord'
                    type='textarea'
                    rows='4'
                    spellCheck='false'
                    value={message}
                    name='message'
                    onChange={(e) => onChange(e)}
                  />
                </FormGroup>

                <FormGroup>
                  <Button
                    color='success'
                    block
                    className='mt-3'
                    disabled={isDisabled}
                  >
                    Send
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Fragment>
  );
};

ContactLandlord.propTypes = {
  user: PropTypes.object.isRequired,
  contactLandlordLoggedIn: PropTypes.func.isRequired,
  landlordEmail: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { contactLandlordLoggedIn })(
  ContactLandlord
);
