import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';

// components
import ClubFeed from '../../groups/clubFeedComponents/ClubFeed';

// static assets
import undrawTeamImg from '../../../assets/img/illustrations/2.png';

const GroupTab = ({ isPublicView, clubData, match }) => {
  return (
    <div className='mt-3'>
      {isPublicView === false && clubData?.mainClubFeed?._id ? (
        <ClubFeed
          match={{ ...match, params: { gid: clubData.mainClubFeed._id } }}
        />
      ) : (
        <div className='mt-3'>
          <Card>
            <CardBody className='overflow-hidden p-lg-6'>
              <Row className='align-items-center justify-content-between'>
                <Fragment>
                  <Col lg={6} xl={4}>
                    <img src={undrawTeamImg} className='img-fluid' alt='' />{' '}
                  </Col>
                  <Col
                    lg={6}
                    xl={8}
                    className='pl-lg-4 my-5 text-center text-lg-left'
                  >
                    <h3>{`A feed with all your club's posts`}</h3>
                    <p className='lead'>{`We'll Integrate a directory for the club so you can easily contact and collaborate with other members
                    `}</p>
                  </Col>
                </Fragment>{' '}
              </Row>
            </CardBody>
            {clubData?.isMember !== true && (
              <Row className='p-card'>
                <Col>
                  <Alert color='warning' className='mb-0'>
                    Join club to see group
                  </Alert>
                </Col>
              </Row>
            )}
          </Card>
        </div>
      )}
    </div>
  );
};

GroupTab.defaultProps = {
  isPublicView: false,
};
GroupTab.propTypes = {
  isPublicView: PropTypes.bool,
  clubData: PropTypes.object.isRequired,

  match: PropTypes.object,
};
export default GroupTab;
