import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// libraries
import { Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const SearchCourseBox = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');

  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      // alert(`Enter clicked!!! ${searchText}`);
      if (searchText.length > 3) {
        history.push(`/search?s=${searchText}`);
        setSearchText('');
      } else {
        toast.warning('Please enter 4 or more characters.');
      }
    }
  };

  return (
    <Form
      inline
      className='search-box'
      onSubmit={(e) => {
        // this prevents teh form from doing default search refresh
        e.preventDefault();
      }}
    >
      <Input
        type='search'
        placeholder='Search Courses or Peers...'
        aria-label='Search'
        className='rounded-pill search-input'
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        onKeyPress={(e) => {
          handleKeyPress(e);
        }}
      />
      <FontAwesomeIcon
        icon='search'
        className='position-absolute text-400 search-box-icon'
      />
    </Form>
  );
};

export default SearchCourseBox;
