import React, { Fragment } from 'react';

// components
import NavbarStandard from '../../../navbar/NavbarStandard';
import Cta from '../../../landing/Cta';
import FooterStandard from '../../../landing/FooterStandard';
import CreateAdPagePublic from './CreateAdPagePublic';
import AdInfoBanner from '../../claimAdPage/ClaimHousingAdComponents/AdInfoBanner';

const CreateHousingAdPublic = () => {
  return (
    <Fragment>
      <NavbarStandard useDark={true} />
      <div className='container'>
        {/* //todo - only show this if its claim ad page */}
        <AdInfoBanner />
        <CreateAdPagePublic />
      </div>
      <Cta />
      <FooterStandard />
    </Fragment>
  );
};

export default CreateHousingAdPublic;
