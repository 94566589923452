import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, {
  useContext,
  useEffect,
  useRef,
  Fragment,
  useState,
} from 'react';

// libraries
import { Collapse, Nav, Navbar, Button } from 'reactstrap';
import Scrollbar from 'react-scrollbars-custom';

// components
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import Flex from '../common/Flex';

// routes
import {
  studentRoutes_Academic,
  studentRoutes_Social,
  studentRoutes_Services,
  modRoutes,
  landlordRoutes,
  comingSoonRoutes,
} from '../../routes';

// misc
import { navbarBreakPoint } from '../../config';
import AppContext from '../../context/Context';

const NavbarVertical = ({ userInfoFromState }) => {
  const navBarRef = useRef(null);

  const {
    isRTL,
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
  } = useContext(AppContext);

  const [isMouseHovering, setIsMouseHovering] = useState(false);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
        setIsMouseHovering(true);
      }, 100);
    }
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className='navbar-vertical navbar-glass'
      light
    >
      <Flex align='center'>
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at='navbar-vertical' width={40} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className='bg-200'
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
          setIsMouseHovering(false);
        }}
      >
        <Scrollbar
          style={{ height: 'calc(100vh - 77px)', display: 'block' }}
          rtl={isRTL}
          trackYProps={{
            // eslint-disable-next-line
            renderer: (props) => {
              // eslint-disable-next-line
              const { elementRef, ...restProps } = props;
              return (
                <span {...restProps} ref={elementRef} className='TrackY' />
              );
            },
          }}
        >
          {userInfoFromState && userInfoFromState.role.includes('student') ? (
            <Fragment>
              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Social</h6>}
              <Nav navbar vertical>
                <NavbarVerticalMenu routes={studentRoutes_Social} />
              </Nav>
              <hr className='border-300 my-3' />

              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Academics</h6>}
              <Nav navbar vertical>
                <NavbarVerticalMenu routes={studentRoutes_Academic} />
              </Nav>
              <hr className='border-300 my-3' />

              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Services</h6>}
              <Nav navbar vertical>
                <NavbarVerticalMenu routes={studentRoutes_Services} />
              </Nav>
              <hr className='border-300 my-3' />
            </Fragment>
          ) : null}

          {userInfoFromState && userInfoFromState.role.includes('moderator') ? (
            <Fragment>
              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Moderator</h6>}
              <Nav navbar vertical>
                <NavbarVerticalMenu routes={modRoutes} />
              </Nav>
              <hr className='border-300 my-3' />{' '}
            </Fragment>
          ) : null}

          {userInfoFromState && userInfoFromState.role.includes('landlord') ? (
            <Fragment>
              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Landlord</h6>}
              <Nav navbar vertical>
                <NavbarVerticalMenu routes={landlordRoutes} />
              </Nav>
              <hr className='border-300 my-3' />{' '}
            </Fragment>
          ) : null}

          {userInfoFromState && userInfoFromState.role.includes('dev') ? (
            <Fragment>
              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Developer</h6>}
              <hr className='border-300 my-3' />
              {(isNavbarVerticalCollapsed === false ||
                isMouseHovering === true) && <h6>Coming Soon</h6>}
              <Nav navbar vertical>
                <NavbarVerticalMenu routes={comingSoonRoutes} />
              </Nav>
              <hr className='border-300 my-3' />{' '}
            </Fragment>
          ) : null}
          <Link to='/ambassador' style={{ textDecoration: 'none' }}>
            <Button color='dark' size='sm' block className='mb-3 btn-purchase'>
              Become A Peernet Ambassador!
            </Button>{' '}
          </Link>
        </Scrollbar>
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  userInfoFromState: PropTypes.object,
};
const mapStateToProps = (state) => ({
  userInfoFromState: state.authReducer.user,
});

export default connect(mapStateToProps)(NavbarVertical);
