import React from 'react';
import { connect } from 'react-redux';

import { Row, Card, CardBody } from 'reactstrap';

const AdInfoBanner = () => {
  return (
    <div className='container'>
      <Row className='mt-4'>
        <div className='py-4 col-12 mt-4'>
          <Card className='mb-0'>
            <CardBody>
              <h4>
                We saw you recently posted a housing ad for McMaster students!
              </h4>
              <hr />
              <span className='mb-2'>{`Use our platform and reach over `}</span>
              <b className='text-primary'>
                {' '}
                2100+ McMaster University students
              </b>
              <span className='mb-2'>{` who use this platform`}</span>
              <b className='text-primary'>{' daily'}</b>
              {`!`}
              <br />
              <span className='mb-2'>{`Join `}</span>
              <b className='text-warning'> 200+ Hamilton Based Landlords</b>
              <span className='mb-2'>{` like yourself who use our platform to find student tenants.`}</span>
              <hr className='border-dashed d-block my-2' />
              <strong className='mb-2'>
                <h6 className='text-secondary'>{`Peernet.co is the first Student services platform focused on providing an online platform with all student related services!`}</h6>
              </strong>
            </CardBody>
          </Card>
        </div>
      </Row>
    </div>
  );
};

export default connect(null, {})(AdInfoBanner);
