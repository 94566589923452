// React Core
import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

// Libraries
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { CardBody, Col, Form, Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import qs from 'query-string';

// components
import Checkout from '../../../../stripe/Checkout';
import LoginForm from '../../../../auth/LoginForm';
import RegistrationFormForLandlords from '../../../../auth/RegistrationFormForLandlords';

// redux Actions
import {
  claimUnownedHousingAd_Action,
  claimUnownedHousingAdSkipUID_Action,
  activateFreeTrial_Action,
} from '../../../../../redux/actions/createHousingAdAction';

// queries
import { useGetHousingAdPrice_Query } from '../../../../../queries/priceQueries';

// static
import { HOUSING_AD_STATUS } from '../../../../static/enums';

const CheckLoggedInModal = ({
  //state
  auth: { user, loading },
  createHousingAdState: {
    justCreatedAd,
    justCreatedFormatted,
    loading: adLoading,
  },
  //action functions
  claimUnownedHousingAd_Action,
  claimUnownedHousingAdSkipUID_Action,
  activateFreeTrial_Action,

  //input for ui
  buttonText,
  total,
  plan,
  houseAdId,
  adLengthInMonths,
}) => {
  // console.log(
  //   justCreatedFormatted ? justCreatedFormatted : 'ad not loaded yet'
  // );
  const location = useLocation();
  const history = useHistory();
  const paramsInfo = qs.parse(location.search);
  let uid = '';
  uid = paramsInfo.uid || paramsInfo.UID;
  let offerId = '';
  offerId = paramsInfo.oid || paramsInfo.OID;

  const [showRegister, setShowRegister] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShowRegister(false);
  };
  const handleShow = () => setShow(true);

  // Pull the correct price from api
  const [priceQueryEnabled, setPriceQueryEnabled] = useState(false);

  useEffect(() => {
    if (plan && plan !== 'No plan selected') {
      setPriceQueryEnabled(true);
    }
    // setPriceQueryEnabled(user && plan && plan !== 'No plan selected');
  }, [plan]);

  const {
    // isError: isPriceError,
    isLoading: isPriceLoading,
    data: priceData,
  } = useGetHousingAdPrice_Query(plan, priceQueryEnabled);

  // LOGIC to claim the ad if its not owned
  useEffect(() => {
    if (
      show === true &&
      user &&
      justCreatedFormatted &&
      justCreatedFormatted.owner === null
    ) {
      // if ad is unclaimed allow claim only by checking UID
      if (
        justCreatedAd.status === HOUSING_AD_STATUS.UNCLAIMED_HIDDEN ||
        justCreatedAd.status === HOUSING_AD_STATUS.UNCLAIMED_SHOW
      ) {
        claimUnownedHousingAd_Action(justCreatedFormatted._id, uid);
      }
      // if ad is newly created allow claim without checking UID
      else {
        claimUnownedHousingAdSkipUID_Action(justCreatedFormatted._id);
      }
    }
  }, [user, justCreatedFormatted, show, justCreatedAd]);

  // LOGIC to make sure a user does not checkout on an ad they do not own
  useEffect(() => {
    if (
      show === true &&
      user &&
      justCreatedFormatted &&
      justCreatedFormatted.owner !== null
    ) {
      if (justCreatedFormatted.owner.toString() !== user._id.toString()) {
        toast.warning('This is not your ad, you cannot checkout!');
        handleClose();
      }
    }
  }, [justCreatedFormatted, user, show]);

  return isPriceLoading || loading ? (
    <Spinner color='warning' />
  ) : (
    <Fragment>
      <Button
        className={'btn-warning text-light px-6 mt-2'}
        color='btn-warning'
        size='md'
        // block
        onClick={handleShow}
      >
        {buttonText}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className='col'>
            {user ? (
              <Fragment>
                <Modal.Title>
                  <div>Your ad is ready!</div>
                </Modal.Title>
              </Fragment>
            ) : (
              <Fragment>
                <Modal.Title>Login/Register to checkout</Modal.Title>
                <p className='text-primary mb-0'>Takes 30 seconds!</p>
              </Fragment>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          {user ? (
            <Fragment>
              {/* {user ? user.name : 'loading user'} */}

              <CardBody className='p-0'>
                <Form>
                  <Col className='justify-content-between mt-3 px-3 pb-3'>
                    <Col>
                      {user ? (
                        <Fragment>
                          <b>{user.name}</b>
                          {` `}
                        </Fragment>
                      ) : (
                        ''
                      )}
                      {`you can now checkout! Need to make changes? No problem, you can update and make changes to your ads anytime!`}
                    </Col>
                    <Col className='text-center text-xl-center'>
                      <hr className='border-dashed d-block my-4' />
                      <div className='fs-2 font-weight-semi-bold'>
                        All Total:{' '}
                        <span className='text-primary'>{`$ ${total.toFixed(
                          2
                        )}`}</span>
                      </div>
                      {justCreatedAd && justCreatedAd.user ? (
                        <Fragment>
                          {justCreatedAd.user.toString() ===
                          user._id.toString() ? (
                            <Fragment>
                              <Checkout
                                priceId={priceData && priceData.priceId}
                                planName={plan}
                                quantity={adLengthInMonths} // # of weeks
                                metadata={{ housingId: houseAdId }}
                              />
                              {offerId === '1' ? (
                                <Button
                                  className={'btn-primary px-6 mt-2'}
                                  color='btn-info'
                                  size='md'
                                  onClick={(e) => {
                                    activateFreeTrial_Action(houseAdId);
                                    history.push('/dash');
                                  }}
                                >
                                  {`Activate 1 Month Free Trial`}
                                </Button>
                              ) : null}
                            </Fragment>
                          ) : (
                            <div>This is not your ad.</div>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {/* Prevents unclaimed adds from checking out at regular endpoint */}
                          {justCreatedAd &&
                          justCreatedAd.status === 'unclaimed' ? (
                            <Fragment>
                              <div className='text-danger'>
                                You do not own this Ad. You cannot checkout.
                              </div>
                              <hr />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Checkout
                                priceId={priceData && priceData.priceId}
                                planName={plan}
                                quantity={adLengthInMonths} // # of weeks
                                metadata={{ housingId: houseAdId }}
                              />
                              {/* <Button
                                className={'btn-primary px-6 mt-2'}
                                color='btn-info'
                                size='md'
                                onClick={(e) => {
                                  activateFreeTrial_Action(houseAdId);
                                  history.push('/dash');
                                }}
                              >
                                {`Activate 1 Month Free Trial`}
                              </Button> */}
                            </Fragment>
                          )}
                        </Fragment>
                      )}

                      <p className='fs--1 mt-3 mb-0'>
                        By clicking <strong>Pay now </strong>
                        button you agree to the{' '}
                        <Link to='#!'>Terms &amp; Conditions</Link>
                      </p>
                    </Col>
                  </Col>
                </Form>
              </CardBody>
            </Fragment>
          ) : (
            <Fragment>
              {showRegister === false ? (
                <div>
                  <div className='fs--1 font-weight-normal p-5 card-body'>
                    <div className='text-left justify-content-between row'>
                      <div className='col-auto'>
                        <h5>Log in</h5>
                      </div>
                      <div className='col-auto'>
                        <p className='fs--1 text-600'>
                          or
                          <a href='/authentication/basic/register'>
                            create an account
                          </a>
                        </p>
                      </div>
                    </div>
                    <LoginForm showSignUpLink={false} />

                    <div className='w-100 position-relative text-center mt-4'>
                      <hr className='text-300' />
                      <div className='position-absolute absolute-centered t-0 px-3 bg-white text-sans-serif fs--1 text-500 text-nowrap'>
                        or sign up now with
                      </div>
                    </div>
                    <div className='mb-0 form-group'>
                      <div className='no-gutters row'>
                        <div className='pr-sm-1 col-sm-12'>
                          <button
                            onClick={() => setShowRegister(true)}
                            to='#!'
                            className='mt-2 btn btn-outline-google-plus btn-sm btn-block'
                          >
                            Sign up!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {showRegister === true ? (
                <div>
                  <div className='fs--1 font-weight-normal p-5 card-body'>
                    <div className='text-left row'>
                      <div className='col'>
                        <h5 id='modalLabel'>Register</h5>
                      </div>
                    </div>
                    <RegistrationFormForLandlords />

                    <div className='w-100 position-relative text-center mt-4'>
                      <hr className='text-300' />
                    </div>
                    <div className='mb-0 form-group'>
                      <div className='no-gutters row'>
                        <div className='pl-sm-1 col-sm-12'>
                          <button
                            onClick={() => setShowRegister(false)}
                            to='#!'
                            className='mt-2 btn btn-outline-facebook btn-sm btn-block'
                          >
                            Have an Account? Log in
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Fragment>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

CheckLoggedInModal.propTypes = {
  // state
  auth: PropTypes.object.isRequired,
  createHousingAdState: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  plan: PropTypes.string.isRequired,
  adLengthInMonths: PropTypes.number.isRequired,
  houseAdId: PropTypes.string.isRequired,

  //action functions
  claimUnownedHousingAd_Action: PropTypes.func.isRequired,
  claimUnownedHousingAdSkipUID_Action: PropTypes.func.isRequired,
  activateFreeTrial_Action: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.authReducer,
  createHousingAdState: state.createHousingAdReducer,
});

const mapDispatchToProps = {
  claimUnownedHousingAd_Action,
  claimUnownedHousingAdSkipUID_Action,
  activateFreeTrial_Action,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckLoggedInModal);
