import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import { Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

// components
import Accordions from '../../common/accordion/Accordions';
import FalconCardHeader from '../../common/FalconCardHeader';
import CreateClubQandAModal from '../components/CreateClubQandAModal';

// utils
import { isIterableArray } from '../../../helpers/utils';

// context
import { ClubContext } from '../privatePages/ViewClubPage';

const ClubFaq = ({ adminView }) => {
  const { faqs, isAdmin, _id } = useContext(ClubContext);

  const allFaqs = faqs?.map?.((faq, index) => {
    return { ...faq, open: index === 0 ? false : false };
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      {adminView || isIterableArray(faqs) ? (
        <Fragment>
          <Card>
            <FalconCardHeader title='Manage Club FAQs' light={false} />
            <CardBody className='bg-light'>
              {isIterableArray(faqs) && faqs?.length > 0 ? (
                <Accordions
                  isAdmin={isAdmin}
                  adminView={adminView}
                  items={allFaqs}
                  titleKey='question'
                  descriptionKey='answer'
                  isOpenKey='open'
                />
              ) : (
                <div>{`No Club FAQ set yet.`}</div>
              )}

              {isAdmin && adminView && (
                <>
                  <Button color='warning' className='mt-4' onClick={handleShow}>
                    <FontAwesomeIcon icon='plus' /> Add to FAQ (Admin Only)
                  </Button>
                  <Modal show={show} onHide={handleClose} size='lg'>
                    <Modal.Header closeButton>
                      <Modal.Title>{`Create a new Q & A Section`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <CreateClubQandAModal
                        closeModal={handleClose}
                        clubId={_id}
                      />
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </CardBody>
          </Card>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

ClubFaq.propTypes = {
  adminView: PropTypes.bool,
};

export default ClubFaq;
