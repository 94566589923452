import React from 'react';
import PropTypes from 'prop-types';

// libraries
import { Card } from 'reactstrap';

// components
import FeedCardHeader from './FeedCardHeader';
import FeedCardFooterWithCommentImages from './FeedCardFooterWithCommentImages';
import FeedCardContent from './FeedCardContent';

const FeedCardPeek = ({
  post,
  postId,
  postUser,
  content,
  footer,
  className,
  showOpen,
}) => {
  return (
    <Card className={`${className}`}>
      {!!postUser && <FeedCardHeader post={post} {...postUser} />}
      {!!content && <FeedCardContent {...content} />}
      {!!footer && (
        // <FeedCardFooter
        <FeedCardFooterWithCommentImages
          post={post}
          id={postId}
          showOpen={showOpen}
          postReactions={post.reactions}
          {...footer}
        />
      )}
    </Card>
  );
};

FeedCardPeek.propTypes = {
  post: PropTypes.object.isRequired,
  postId: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
  postUser: PropTypes.object,
  className: PropTypes.string,
  showOpen: PropTypes.bool,
};

export default FeedCardPeek;
