import React, { createRef, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';

// libraries
import { Button, Card, CardBody, Col, Media, Row, Spinner } from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import FalconCardHeader from '../../common/FalconCardHeader';
import Flex from '../../common/Flex';
import Avatar from '../../common/Avatar';
import CardSummary from '../components/CardSummary';

// misc
import { getPaginationArray } from '../../../helpers/utils';
import { posthog_ViewAllCompaniesPublic } from '../../../utils/posthogEvents/posthogInternshipEvents';

// queries
import { useGetCompanies_Query } from '../../../queries/internshipQueries/companyQueries';

const nameFormatter = (dataField, { name, logo, _id }) => {
  return (
    <Link to={`/internships/companies/${_id}`}>
      <Media tag={Flex} align='center'>
        <Avatar {...{ size: 'xl', round: 'circle' }} src={logo} />
        <Media body className='ml-2'>
          <h5 className='mb-0 fs--1'>{name}</h5>
        </Media>
      </Media>
    </Link>
  );
};

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    formatter: nameFormatter,
    sort: true,
  },
  {
    dataField: 'numJobs',
    text: 'Jobs',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
  {
    dataField: 'numRecruiters',
    text: 'Recruiters',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true,
  },
];

const options = {
  custom: true,
  sizePerPage: 10,
};

const CompaniesPublic = () => {
  let table = createRef();
  // State
  const handleNextPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page + 1);
    };

  const handlePrevPage =
    ({ page, onPageChange }) =>
    () => {
      onPageChange(page - 1);
    };

  const {
    isLoading: companiesLoading,
    isError: companiesError,
    data: companies,
  } = useGetCompanies_Query();

  useEffect(() => {
    posthog_ViewAllCompaniesPublic(companies);
  });

  return (companies === undefined && companiesError === false) ||
    companiesLoading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='card-deck mb-2'>
        <CardSummary title='Number of companies' color='info'>
          {companies.length}
        </CardSummary>
        <CardSummary title='Number of jobs' color='warning'>
          {companies.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.numJobs;
          }, 0)}
        </CardSummary>
        <CardSummary title='Number of recruiters' color='success'>
          {companies.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.numRecruiters;
          }, 0)}
        </CardSummary>
      </div>

      <Card className='mb-3'>
        <FalconCardHeader title='Companies' light={false} />
        <CardBody className='p-0'>
          <PaginationProvider
            pagination={paginationFactory({
              ...options,
              totalSize: companies.length,
            })}
          >
            {({ paginationProps, paginationTableProps }) => {
              const lastIndex =
                paginationProps.page * paginationProps.sizePerPage;
              return (
                <Fragment>
                  <div className='table-responsive'>
                    <BootstrapTable
                      ref={table}
                      bootstrap4
                      keyField='_id'
                      data={companies}
                      columns={columns}
                      bordered={false}
                      classes='table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap'
                      rowClasses='btn-reveal-trigger border-top border-200'
                      headerClasses='bg-200 text-900 border-y border-200'
                      {...paginationTableProps}
                    />
                  </div>
                  <Row noGutters className='px-1 py-3 flex-center'>
                    <Col xs='auto'>
                      <Button
                        color='falcon-default'
                        size='sm'
                        onClick={handlePrevPage(paginationProps)}
                        disabled={paginationProps.page === 1}
                      >
                        <FontAwesomeIcon icon='chevron-left' />
                      </Button>
                      {getPaginationArray(
                        paginationProps.totalSize,
                        paginationProps.sizePerPage
                      ).map((pageNo) => (
                        <Button
                          color={
                            paginationProps.page === pageNo
                              ? 'falcon-primary'
                              : 'falcon-default'
                          }
                          size='sm'
                          className='ml-2'
                          onClick={() => paginationProps.onPageChange(pageNo)}
                          key={pageNo}
                        >
                          {pageNo}
                        </Button>
                      ))}
                      <Button
                        color='falcon-default'
                        size='sm'
                        className='ml-2'
                        onClick={handleNextPage(paginationProps)}
                        disabled={lastIndex >= paginationProps.totalSize}
                      >
                        <FontAwesomeIcon icon='chevron-right' />
                      </Button>
                    </Col>
                  </Row>
                </Fragment>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CompaniesPublic;
