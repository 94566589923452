import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// libraries
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Media, Col, Badge, Spinner } from 'reactstrap';
import {
  faThumbsDown as fatDown,
  faThumbsUp as fatUp,
} from '@fortawesome/free-solid-svg-icons';

// components
import Flex from '../../common/Flex';
import Avatar from '../../common/Avatar';

// css
import './style.css';

// actions
import { getUsersWhoReacted_Action } from '../../../redux/actions/postActions';

// Events
import { posthog_postReactionsViewed_Event } from '../../../utils/posthogEvents/posthogPostEvents';

const ShowWhoReactedModal = ({
  count,
  text,
  id,
  // action funtions
  getUsersWhoReacted_Action,
  // redux objects
  postState: { loading, userReaction },
  authState: { user },
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (show === true) {
      getUsersWhoReacted_Action(id);
      if (id && user) {
        posthog_postReactionsViewed_Event(id, user);
      }
    }
  }, [getUsersWhoReacted_Action, id, show, user]);

  return loading === true ? (
    <Spinner color='primary' />
  ) : (
    <Fragment>
      <span className='text-700' onClick={handleShow} id='likesAndDislikes'>
        {count} {text}
        {count !== 1 && 's'}
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`Reactions`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userReaction.map((user, index) => {
            return (
              <Fragment key={index}>
                <Col className='py-1'>
                  <Media className='align-items-center'>
                    <Avatar src={user.avatar} size='2xl' className='mr-3' />

                    <Media body className='position-relative'>
                      <Flex tag='h6' align='center' className='mb-0'>
                        <Link
                          className='text-800 stretched-link'
                          to={`/profile/${user._id}`}
                        >
                          {user.name}
                        </Link>
                        <Badge
                          pill
                          className={`ml-2 bg-200 ${
                            user?.reaction?.type === 'dislike'
                              ? 'text-danger'
                              : 'text-primary'
                          }`}
                        >
                          <FontAwesomeIcon
                            icon={
                              user?.reaction?.type === 'like' ? fatUp : fatDown
                            }
                            size='1x'
                          />
                        </Badge>
                      </Flex>
                    </Media>
                  </Media>
                </Col>
              </Fragment>
            );
          })}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

ShowWhoReactedModal.propTypes = {
  // redux state
  postState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,

  // component props
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,

  // action funtions
  getUsersWhoReacted_Action: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  postState: state.postReducer,
  authState: state.authReducer,
});

export default connect(mapStateToProps, { getUsersWhoReacted_Action })(
  ShowWhoReactedModal
);
