// Auth Types
export const USER_LOADED = 'USER_LOADED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_REDUCER_LOADING = 'SET_AUTH_REDUCER_LOADING';
export const SET_AUTH_REDUCER_LOADING_FALSE = 'SET_AUTH_REDUCER_LOADING_FALSE';

// Profile Types
export const SET_PROFILE_REDUCER_LOADING_FALSE =
  'SET_PROFILE_REDUCER_LOADING_FALSE';

// Post Types
export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_FOR_GROUP = 'GET_POSTS_FOR_GROUP';
export const GET_POST_WITH_COMMENTS = 'GET_POST_WITH_COMMENTS';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_REACTIONS = 'UPDATE_REACTIONS';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const SET_POST_REDUCER_LOADING = 'SET_POST_REDUCER_LOADING';
export const SET_POST_REDUCER_LOADING_FALSE = 'SET_POST_REDUCER_LOADING_FALSE';
export const OPEN_POST_REACTIONS = 'OPEN_POST_REACTIONS';

// Housing Types
export const GET_HOUSING = 'GET_HOUSING';
export const GET_MY_HOUSING_ADS = 'GET_MY_HOUSING_ADS';

// discuss - why some of the types are showing unused when they are being used?
// eslint-disable-next-line
export const GET_FAVOURITE_HOUSING_ADS = 'GET_FAVOURITE_HOUSING_ADS';
// eslint-disable-next-line
export const ADD_HOUSING_TO_FAV = 'ADD_HOUSING_TO_FAV';
// eslint-disable-next-line
export const REMOVE_HOUSING_FROM_FAV = 'REMOVE_HOUSING_FROM_FAV';
export const GET_SINGLE_HOUSE_AD = 'GET_SINGLE_HOUSE_AD';
export const HOUSE_ERROR = 'HOUSE_ERROR';
export const SET_HOUSING_REDUCER_LOADING = 'SET_HOUSING_REDUCER_LOADING';
export const SET_IGNORED = 'SET_IGNORED';

// Misc Types
// eslint-disable-next-line
export const SET_REFER_ID = 'SET_REFER_ID';

// Housing Ad Management Types
export const CREATE_AD_LOGGED_IN = 'CREATE_AD_LOGGED_IN';
export const GET_UNFINISHED_HOUSING_AD = 'GET_UNFINISHED_HOUSING_AD';
export const UPDATE_UNFINISHED_HOUSING_AD = 'UPDATE_UNFINISHED_HOUSING_AD';
export const SHOW_LOAD_WHILE_UPDATING = 'SHOW_LOAD_WHILE_UPDATING';
export const GET_MY_HOUSING_ADS_STATS = 'GET_MY_HOUSING_ADS_STATS';
export const CLAIM_UNFINISHED_HOUSING_AD = 'CLAIM_UNFINISHED_HOUSING_AD';
export const DELETE_HOUSING = 'DELETE_HOUSING';
export const SET_CREATE_HOUSING_AD_REDUCER_LOADING =
  'SET_CREATE_HOUSING_AD_REDUCER_LOADING';

// Product Types
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_USER_PRODUCTS = 'GET_USER_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const UPDATE_USER_PRODUCT = 'UPDATE_USER_PRODUCT';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const ADD_PRODUCT_TO_FAV = 'ADD_PRODUCT_TO_FAV';
export const REMOVE_PRODUCT_FROM_FAV = 'REMOVE_PRODUCT_FROM_FAV';
export const GET_FAVOURITE_PRODUCTS = 'GET_FAVOURITE_PRODUCTS';
export const SET_PRODUCT_REDUCER_LOADING = 'SET_PRODUCT_REDUCER_LOADING';
export const GET_FILTERED_PRODUCTS = 'GET_FILTERED_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const SELL_PRODUCT = 'SELL_PRODUCT';
export const RELIST_PRODUCT = 'RELIST_PRODUCT';

// Market Types
// export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const SET_PRODUCTS_REDUCER_LOADING = 'SET_PRODUCTS_REDUCER_LOADING';

// Chat Types
export const GET_CHAT_THREADS = 'GET_CHAT_THREADS';
export const SET_CHAT_REDUCER_LOADING = 'SET_CHAT_REDUCER_LOADING';
export const SET_CHAT_REDUCER_LOADING_FALSE = 'SET_CHAT_REDUCER_LOADING_FALSE';
export const SET_ACTIVE_THREAD = 'SET_ACTIVE_THREAD';
export const ADD_CHAT_CONN_OPENED = 'ADD_CHAT_CONN_OPENED';
export const ADD_MSGS_TO_CHAT = 'ADD_MSGS_TO_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_DELIVERED = 'SEND_MESSAGE_DELIVERED';
export const NEW_MESSAGE_RECIEVED = 'NEW_MESSAGE_RECIEVED';
export const CREATE_NEW_CHAT_THREAD = 'CREATE_NEW_CHAT_THREAD';
export const MOVE_CHATBOX_TO_TOP = 'MOVE_CHATBOX_TO_TOP';
export const THREAD_LOAD_PREV_MSGS = 'THREAD_LOAD_PREV_MSGS';
export const USER_STARTED_TYPING = 'USER_STARTED_TYPING';
export const USER_STOPPED_TYPING = 'USER_STOPPED_TYPING';
export const READ_STATUSES_UPDATE = 'READ_STATUSES_UPDATE';
export const RENAME_CHAT = 'RENAME_CHAT';
export const MSG_DELIVERED = 'MSG_DELIVERED';
export const CHAT_UPDATE_READ_STATUS = 'CHAT_UPDATE_READ_STATUS';
export const CHAT_MESSAGE_DELETED = 'CHAT_MESSAGE_DELETED';
export const CHAT_MESSAGE_UPDATED = 'CHAT_MESSAGE_UPDATED';
export const UPDATE_UNREAD_MSGS = 'UPDATE_UNREAD_MSGS';
