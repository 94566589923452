import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// components
import Avatar from '../../common/Avatar';
import LightBoxGallery from '../../common/LightBoxGallery';
import parse from 'html-react-parser';

// libraries
import { Col, Row, Media, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp as thinUp,
  faThumbsDown as thinDown,
} from '@fortawesome/free-regular-svg-icons';
import {
  faThumbsDown as fatDown,
  faThumbsUp as fatUp,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';

// actions
import {
  deleteComment,
  clickReaction,
} from '../../../redux/actions/postActions';

// misc
import { isIterableArray } from '../../../helpers/utils';

// Events
import {
  posthog_likePost_Event,
  posthog_dislikePost_Event,
} from '../../../utils/posthogEvents/posthogPostEvents';

const Comments = ({
  user: loggedinUser,
  deleteComment,
  clickReaction,
  comments,
  loadComment,
}) => {
  const [isOpenBottom, setIsOpenBottom] = useState({});

  function getReactionsCount(reactions, type) {
    const count = reactions
      ? reactions.filter((r) => r.type === type).length
      : 0;
    return count > 0 ? count : null;
  }

  return (
    <Fragment>
      {!!loadComment && loadComment.nextPage && (
        <Fragment>
          {/* <hr /> */}
          <div className='d-flex justify-content-center mt-3'>
            <Link
              to={{
                search: `?page=${
                  loadComment.nextPage && loadComment.nextPage > 2
                    ? loadComment.nextPage
                    : 2
                }`,
              }}
              onClick={() => {}}
              className='small'
              // className='btn btn-outline-primary btn-sm'
            >
              <i className='fas fa-times' />
              {`Load previous comments (Page ${loadComment.nextPage})`}
            </Link>
          </div>
        </Fragment>
      )}
      {comments
        .sort(
          (c1, c2) =>
            new Date(c1.postTime.props.children).getTime() -
            new Date(c2.postTime.props.children).getTime()
        )
        .map(
          (
            {
              id,
              commentUser,
              avatarSrc,
              name,
              content,
              postTime,
              isMyComment,
              isAnon,
              reactions,
              images,
            },
            index
          ) => {
            return (
              <Fragment key={id}>
                <Media className='mt-3'>
                  {avatarSrc && avatarSrc !== 'anonAvatar' ? (
                    // {avatarSrc && avatarSrc.includes('gravatar') == false ? (
                    <Avatar src={avatarSrc} size='2xl' />
                  ) : (
                    <Avatar name={name} size='2xl' />
                  )}
                  {/* <Avatar src={avatarSrc} size="xl" /> */}
                  <Media body className='ml-2 fs--1'>
                    <div className='mb-1 bg-200 rounded-soft p-2'>
                      <Link
                        className='font-weight-semi-bold'
                        to={commentUser ? `/profile/${commentUser}` : '#'}
                      >
                        {name}
                      </Link>
                      {content !== undefined && (
                        <span
                          dangerouslySetInnerHTML={{ __html: parse(content) }}
                          className='ql-editor'
                        />
                      )}

                      {isIterableArray(images) ? (
                        <LightBoxGallery images={images}>
                          {(openImgIndex) => (
                            <Row noGutters className='mx-n1 px-2 py-2'>
                              {images.map((img, index) => {
                                return (
                                  <Col
                                    xs={6}
                                    md={
                                      images.length === 1
                                        ? 12
                                        : images.length === 2
                                        ? 6
                                        : 4
                                    }
                                    className='px-1'
                                    style={{ minWidth: 50 }}
                                    key={index}
                                  >
                                    <img
                                      className='img-fluid rounded mb-2 cursor-pointer'
                                      src={img}
                                      alt=''
                                      onClick={() => openImgIndex(index)}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          )}
                        </LightBoxGallery>
                      ) : null}
                    </div>

                    <div>
                      <a href='#!'>
                        <FontAwesomeIcon
                          icon={
                            reactions &&
                            reactions.filter(
                              (r) =>
                                r.user === loggedinUser._id && r.type === 'like'
                            ).length > 0
                              ? fatUp
                              : thinUp
                          }
                          className='text-primary'
                          onClick={() => {
                            clickReaction(id, 'like');

                            posthog_likePost_Event(id, loggedinUser);
                          }}
                        />
                      </a>{' '}
                      <a href='#!' className='text-secondary'>
                        {getReactionsCount(reactions, 'like')}
                      </a>
                      {' • '}
                      <a href='#!'>
                        <FontAwesomeIcon
                          icon={
                            reactions &&
                            reactions.filter(
                              (r) =>
                                r.user === loggedinUser._id &&
                                r.type === 'dislike'
                            ).length > 0
                              ? fatDown
                              : thinDown
                          }
                          className='text-danger'
                          size='1x'
                          onClick={() => {
                            clickReaction(id, 'dislike');

                            posthog_dislikePost_Event(id, loggedinUser);
                          }}
                        />
                      </a>{' '}
                      <a href='#!' className='text-secondary'>
                        {getReactionsCount(reactions, 'dislike')}
                      </a>
                      {/* {' • '}
                    <a href='#!' className='text-primary'>
                      Like
                    </a>{' '} */}
                      {' • '}
                      {postTime}
                      {isAnon ? (
                        <Fragment>
                          {' • '}
                          <FontAwesomeIcon
                            icon={faUserSecret}
                            id={`i-${index}`}
                          />
                          <Tooltip
                            placement='bottom'
                            isOpen={isOpenBottom[index]}
                            target={`i-${index}`}
                            toggle={() => {
                              const key = index;
                              const n = {};
                              n[key] = !isOpenBottom[index];
                              setIsOpenBottom(n);
                            }}
                            container='.content'
                          >
                            This is an anonymous comment
                          </Tooltip>
                        </Fragment>
                      ) : null}
                      {isMyComment ? (
                        <Fragment>
                          {' • '}
                          <a
                            className='text-danger'
                            href='#!'
                            onClick={() => {
                              deleteComment(id);
                            }}
                          >
                            {` Delete `}
                            {/* <FontAwesomeIcon icon={faTimesCircle} /> */}
                          </a>{' '}
                        </Fragment>
                      ) : null}
                    </div>
                  </Media>
                </Media>
              </Fragment>
            );
          }
        )}
    </Fragment>
  );
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  loadComment: PropTypes.object,
  deleteComment: PropTypes.func.isRequired,
  clickReaction: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

// export default Comments;

// OpenPost.propTypes = {
//   getPostWithComments: PropTypes.func.isRequired,
// };

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { deleteComment, clickReaction })(
  Comments
);
