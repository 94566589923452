import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

// libraries
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// components
import UpdateClubDetails from '../components/UpdateClub/UpdateClubDetails';
import UpdateClubLogo from '../components/UpdateClub/UpdateClubLogo';
import UpdateClubBanner from '../components/UpdateClub/UpdateClubBanner';
import CreateClubEventModal from '../components/CreateClubEventModal';
import ClubFaq from '../components/ClubFaq';
import Banner from '../../common/Banner';
import ClubAcceptJoinReqs from '../../groups/clubFeedComponents/ClubAcceptJoinReqs';

// assets
import eventImg from '../../../assets/img/undraw/undraw_events_2p66.svg';
import adminImg from '../../../assets/img/undraw/undraw_personal_information_re_vw8a.svg';

// context
import { ClubContext } from '../privatePages/ViewClubPage';
import FalconCardHeader from '../../common/FalconCardHeader';
import ManageClubEvents from '../../event/ManageClubEvents';

const AdminTab = ({ clubData }) => {
  const { showEventModal, events } = useContext(ClubContext);
  const [showDetails, setShowDetails] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  let isAdmin = false;
  if (clubData?.isAdmin) {
    isAdmin = clubData.isAdmin;
  }

  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = () => setShowDetails(true);

  const handleCloseLogo = () => setShowLogo(false);
  const handleShowLogo = () => setShowLogo(true);

  const handleCloseBanner = () => setShowBanner(false);
  const handleShowBanner = () => setShowBanner(true);

  return (
    <div className='mt-3'>
      <Row>
        <Col className='d-flex flex-column justify-content-center'>
          {isAdmin ? (
            <Fragment>
              <Fragment>
                <Row>
                  <Col xs={12} className='mb-4'>
                    <Banner
                      title={`Update Club Info`}
                      description={`Edit your club's contact information,related tags or description as admin.`}
                      img={adminImg}
                    >
                      <Button
                        color='warning'
                        className='mx-2 my-2'
                        onClick={handleShowDetails}
                      >
                        <FontAwesomeIcon icon='plus' /> {`Update Club Details`}
                      </Button>
                      <Button
                        color='info'
                        className='mx-2 my-2'
                        onClick={handleShowLogo}
                      >
                        <FontAwesomeIcon icon='plus' /> {`Update Club Logo`}
                      </Button>
                      <Button
                        color='primary'
                        className='mx-2 my-2'
                        onClick={handleShowBanner}
                      >
                        <FontAwesomeIcon icon='plus' /> {`Update Club Banner`}
                      </Button>
                    </Banner>
                    <Modal
                      show={showDetails}
                      onHide={handleCloseDetails}
                      size='lg'
                    >
                      <Modal.Header closeButton>
                        {`Update Club Details`}
                      </Modal.Header>
                      <Modal.Body>
                        <UpdateClubDetails />
                      </Modal.Body>
                    </Modal>
                    <Modal show={showLogo} onHide={handleCloseLogo} size='lg'>
                      <Modal.Header
                        closeButton
                      >{`Update Club Logo`}</Modal.Header>
                      <Modal.Body>
                        <UpdateClubLogo />
                      </Modal.Body>
                    </Modal>
                    <Modal
                      show={showBanner}
                      onHide={handleCloseBanner}
                      size='lg'
                    >
                      <Modal.Header
                        closeButton
                      >{`Update Club Banner`}</Modal.Header>
                      <Modal.Body>
                        <UpdateClubBanner />
                      </Modal.Body>
                    </Modal>
                  </Col>

                  <Col xs={12} className='mb-4'>
                    <Card>
                      <FalconCardHeader title='Manage Club Events' />
                      <CardBody>
                        <Banner
                          title={`Create new club events!`}
                          description={`Club members will be able to follow along with upcoming events/special occasions.`}
                          img={eventImg}
                        >
                          <Button
                            color='info'
                            className='mx-auto my-4'
                            onClick={showEventModal}
                          >
                            <FontAwesomeIcon icon='plus' /> Create New Club
                            Event
                          </Button>
                        </Banner>

                        {events && events.length > 0 && (
                          <Fragment>
                            <br></br>
                            <ManageClubEvents
                              clubEvents={events}
                              title={`Active Club Events`}
                            />
                          </Fragment>
                        )}
                      </CardBody>
                    </Card>

                    <CreateClubEventModal />
                  </Col>

                  <Col xs={12} className='mb-4'>
                    <ClubFaq adminView />
                  </Col>

                  <Col xs={12} className='mb-4'>
                    <ClubAcceptJoinReqs clubData={clubData} />
                  </Col>
                </Row>
              </Fragment>
            </Fragment>
          ) : (
            <Card className='text-center'>
              <CardBody className='p-5'>
                <h1 className='lead mt-4 fs-4 text-800 text-sans-serif font-weight-semi-bold'>
                  You are not an admin of this club
                </h1>
                <hr />
                <p>Request to become an admin to access more privelleges</p>
                {/* <Link className='btn btn-primary btn-sm mt-3' to='/'>
                  Request to become admin
                </Link> */}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

AdminTab.propTypes = {
  clubData: PropTypes.object.isRequired,
};

export default AdminTab;
