import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// libraries
import classNames from 'classnames';

// misc
import { isIterableArray } from '../../../helpers/utils';

// component
import ShowWhoReactedModal from './ShowWhoReactedModal';

const LikeCommentShareCountText = ({
  //props
  count,
  text,
  id,

  // redux
  post,
}) => {
  if (text === 'Comment') {
    return (
      <Link className='text-700' to={`/post/${id}`}>
        {count} {text}
        {count !== 1 && 's'}
      </Link>
    );
  }
  return <ShowWhoReactedModal count={count} text={text} id={id} post={post} />;
};

const LikeCommentShareCount = ({ countLCS, id, post }) => {
  const { like = 0, share = 0, comments = 0, dislike = 0 } = countLCS;
  const countLCSArray = [like, dislike, comments, share];
  const countLCSObj = {};

  countLCSArray.map((count, index) => {
    if (count) {
      countLCSObj[
        classNames({
          Like: index === 0,
          Dislike: index === 1,
          Comment: index === 2,
          Share: index === 3,
        })
      ] = count;
    }
    return countLCSObj;
  });

  const keys = Object.keys(countLCSObj);

  return isIterableArray(keys) ? (
    <div className='border-bottom border-200 fs--1 py-3'>
      {keys.map((key, index) => (
        <Fragment key={index}>
          <LikeCommentShareCountText
            count={countLCSObj[key]}
            text={key}
            id={id}
            post={post}
          />
          {index < keys.length - 1 && ' • '}
        </Fragment>
      ))}
    </div>
  ) : null;
};

LikeCommentShareCount.propTypes = {
  post: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  countLCS: PropTypes.object.isRequired,
};

LikeCommentShareCountText.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  post: PropTypes.object.isRequired,
};

LikeCommentShareCount.defaultProps = {};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(LikeCommentShareCount);
