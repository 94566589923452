import React, { Fragment } from 'react';

// libraries
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

// components
import RegistrationForm from '../RegistrationForm';

const Registration = () => (
  <Fragment>
    <Row className='text-left'>
      <Col>
        <h5 id='modalLabel'>Register as a Student</h5>
      </Col>
      <Col xs='auto'>
        <p className='fs--1 text-600'>
          Have an account? <Link to='/authentication/login'>Login</Link>
        </p>
      </Col>
    </Row>
    <RegistrationForm />
  </Fragment>
);

export default Registration;
