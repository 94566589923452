import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import posthog from 'posthog-js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import SeeDemoAd from './ClaimHousingAdComponents/SeeDemoAd';
import AdInfoBanner from './ClaimHousingAdComponents/AdInfoBanner';
import { decryptMidNidParams } from '../../../utils/encryption';
import HousingDemo from '../../landing/HousingDemo';

// Events
import { posthog_unclaimedAdOpened_Event } from '../../../utils/posthogEvents/posthogHousingEvents';

const ClaimHousingAdPublic = ({
  // state
  createAdState: { justCreatedAd, loading },
}) => {
  const location = useLocation();
  const paramsInfo = qs.parse(location.search);
  let nid = '';
  nid = paramsInfo.nid;
  let mid = '';
  mid = paramsInfo.mid;
  let hid = '';
  hid = paramsInfo.hid || paramsInfo.HID;

  const getInfoFromParams = (mid, nid, hid) => {
    let paramsObj = {};
    if (mid && mid.length > 10 && nid && nid.length > 10) {
      paramsObj = decryptMidNidParams(mid, nid);

      if (paramsObj.email) {
        posthog.identify(paramsObj.email, {
          hid: paramsObj.hid,
          email: paramsObj.email,
        });
      }
    }

    const emailToSend = paramsObj.email ? paramsObj.email : '';
    if (hid && hid.length > 10) {
      posthog_unclaimedAdOpened_Event(hid, emailToSend ? emailToSend : '');
    }
  };

  useEffect(() => {
    getInfoFromParams(mid, nid, hid);
  }, [mid, nid, hid]);

  // logic to hid ad if ad is already claimed
  const [showAd, setShowAd] = useState(true);
  useEffect(() => {
    if (
      loading === false &&
      justCreatedAd !== null &&
      justCreatedAd !== undefined &&
      justCreatedAd
    ) {
      if (justCreatedAd.user) {
        setShowAd(false);
      }
    }
  }, [justCreatedAd, loading]);

  return (
    <Fragment>
      <NavbarStandard useDark={true} />
      <AdInfoBanner />
      <div className='container'>
        {showAd === true ? (
          <SeeDemoAd />
        ) : (
          'Ad is already claimed. Contact Peernet for help.'
        )}
      </div>
      <HousingDemo />

      <Cta />
      <FooterStandard />
    </Fragment>
  );
};

// export default ClaimHousingAdPublic;

ClaimHousingAdPublic.propTypes = {
  //state
  createAdState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  createAdState: state.createHousingAdReducer,
});

export default connect(mapStateToProps)(ClaimHousingAdPublic);
