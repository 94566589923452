import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import logo from '../../assets/img/illustrations/falcon.png';
import logo from '../../assets/img/customstuff/logov4.png';
import RedirectUser from '../layouts/RedirectUser';

const Logo = ({ at, width, className, ...rest }) => {
  const [logoClicked, setLogoClicked] = useState(false);

  const LogoClicked = (e) => {
    e.preventDefault();

    setLogoClicked(true);
  };
  useEffect(() => {
    if (logoClicked === true) {
      setLogoClicked(false);
    }
  }, [logoClicked]);

  if (logoClicked === true) {
    return <RedirectUser />;
  }

  return (
    <div
      // to='/feed'
      onClick={(e) => LogoClicked(e)}
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
          },
          className
        )}
        style={{ cursor: 'pointer' }}
      >
        <img className='mr-2' src={logo} alt='Logo' width={width} />
        <span className='text-sans-serif text-dark'>Peernet</span>
      </div>
    </div>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
