import React, { Fragment } from 'react';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import AllTutors from '../privatePages/AllTutors';

const TutorsPublic = () => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container mt-7 mb-4'}>
          <AllTutors publicState={true} />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

export default TutorsPublic;
