import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// libraries
import { Badge, Media, Button, Row } from 'reactstrap';

// components
import Calendar from '../common/Calendar';
import DeleteEventModal from './DeleteEventModal';

// queries
import { useDeleteEvent_Query } from '../../../src/queries/eventQueries.js';

const EventBadge = ({ text, ...rest }) => <Badge {...rest}>{text}</Badge>;

EventBadge.propTypes = {
  ...Badge.propTypes,
  text: PropTypes.string.isRequired,
};

const EventSummary = ({
  event,

  // below not needed
  // _id,
  // calendar,
  // title,
  badge,
  // eventCreator,
  address,
  divider,
  myEvent,
  // to,
  // startDate,
  // endDate,

  //props
  hideOrganizedByLine,
}) => {
  let startDateTime = new Date(event?.startDate);
  const { mutate: removeEvent } = useDeleteEvent_Query();

  const monthMap = {
    0: 'January',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'Aug',
    8: 'Sept',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  const [showDeleteModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const deleteEvent = (clubId) => {
    // console.log('deleting event', clubId);
    removeEvent(clubId);
    closeModal();
  };

  return (
    <Media>
      <Calendar
        month={monthMap[startDateTime?.getMonth()]}
        day={startDateTime?.getDate()}
      />
      <Media body className='position-relative pl-3'>
        <h6 className='fs-0 mb-2'>
          <Link to={`/events/view/${event?._id}`}>{event?.title}</Link>{' '}
          {badge && <EventBadge {...badge} className='ml-1' />}
        </h6>

        {hideOrganizedByLine === false ? (
          <h6 className='my-2 d-block'>
            Organized by{' '}
            <Link
              to={
                event?.eventCreator?._id
                  ? `/profile/${event?.eventCreator?._id}`
                  : `#`
              }
              className='text-700'
            >
              {event?.eventCreator?.name}
            </Link>
          </h6>
        ) : null}

        <h6>{`Start: ${event?.startDate.slice(0, 10)}`}</h6>
        {myEvent && (
          <Fragment>
            <Row className='px-3'>
              <Link
                className='d-block mr-2'
                to={`/events/update/${event?._id}`}
              >
                <Button className='text-center' color='primary' size='sm'>
                  <h6 className='my-auto text-white'>Edit Event</h6>
                </Button>
              </Link>
              {/* <Link className='d-block' to={`/events/update/${event?._id}`}>
                <Button className='text-center' color='danger' size='sm'>
                  <h6 className='my-auto text-white'>Delete</h6>
                </Button>
              </Link>{' '} */}
              <Button
                className='text-center'
                color='danger'
                size='sm'
                onClick={openModal}
              >
                <h6 className='my-auto text-white'>Delete</h6>
              </Button>
              <DeleteEventModal
                showDeleteModal={showDeleteModal}
                closeModal={closeModal}
                deleteEvent={deleteEvent}
                eventId={event?._id}
              />
            </Row>
          </Fragment>
        )}

        {address && <p className='mb-0'>Location: {address}</p>}
        {divider && <hr className='border-dashed border-bottom-0' />}
      </Media>
    </Media>
  );
};

EventSummary.propTypes = {
  calendar: PropTypes.shape(Calendar.propTypes).isRequired,
  title: PropTypes.string.isRequired,
  eventCreator: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  badge: PropTypes.shape(EventBadge.propTypes),
  address: PropTypes.string,
  children: PropTypes.node,
  divider: PropTypes.bool,
  myEvent: PropTypes.bool,
  event: PropTypes.object,
  hideOrganizedByLine: PropTypes.bool,
};

EventSummary.defaultProps = { divider: true, hideOrganizedByLine: false };

export default EventSummary;
