import React, { Fragment, useState, useMemo, useContext } from 'react';
import { Card, CardBody, Col, Row, Button, Badge } from 'reactstrap';

// components
import Modal from 'react-bootstrap/Modal';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import IconGroup from '../../common/icon/IconGroup';
import ClubFaq from '../components/ClubFaq';
import ContactClubModal from '../components/ContactClubModal';
import FalconCardHeader from '../../common/FalconCardHeader';

// context
import { ClubContext } from '../privatePages/ViewClubPage';
import { Link } from 'react-router-dom';

const ClubInformationTab = () => {
  // mounting lifecycle
  const { contactEmail, clubDescription, socialInfo, _id, clubName } =
    useContext(ClubContext);
  const [show, setShow] = useState(false);
  // update lifecycle
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const validSocialLinks = useMemo(
    () =>
      socialInfo &&
      Object.keys(socialInfo)
        .filter((social) => {
          if (socialInfo[social]) {
            return true;
          }
          return false;
        })
        .map((validSocial) => {
          let validSocialIcon;
          let iconType = 'fab';
          switch (validSocial) {
            case 'website':
              validSocialIcon = 'link';
              iconType = 'fa';
              break;
            case 'linkedin':
              validSocialIcon = 'linkedin-in';
              break;
            default:
              validSocialIcon = validSocial;
              break;
          }
          return {
            icon: [iconType, validSocialIcon],
            color: validSocial,
            bg: '200',
            href: socialInfo[validSocial],
          };
        }),
    [socialInfo]
  );

  return (
    <Row className='mb-3'>
      <Col sm='12 mt-3'>
        <ContentWithAsideLayout
          bigSide={'7'}
          smallSide={'5'}
          aside={
            <Fragment>
              <Card className='mb-3'>
                <FalconCardHeader title='Contact Info' titleTag='h5' />
                <CardBody className='pt-2'>
                  <span>
                    <small>{`Website: `}</small>
                  </span>
                  <br></br>
                  <Link to={`/c/${clubName?.replace(' ', '-')}`}>
                    <Badge color={`soft-success`} className='mr-2'>
                      {`www.peernet.co/c/${clubName?.replace(' ', '-')}`}
                    </Badge>
                  </Link>
                  <br></br>
                  <div className='my-2'></div>
                  <span>
                    <small>{`Email: `}</small>
                  </span>{' '}
                  <br></br>
                  <Badge className={'mb-2'} color={'soft-info'}>
                    {contactEmail}
                  </Badge>
                  <br></br>
                  <Button
                    color='light'
                    size='sm'
                    className='py-0 mt-1 border mr-1'
                    transform='shrink-5'
                    onClick={handleShow}
                    block
                  >
                    <span className='fs--1'> {`Contact / Send Message`}</span>
                  </Button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size='lg'
                    className=''
                  >
                    <ContactClubModal
                      email={contactEmail}
                      clubId={_id}
                      className=''
                    />
                  </Modal>
                </CardBody>
              </Card>
              {validSocialLinks && validSocialLinks.length > 0 ? (
                <Card>
                  <FalconCardHeader title={`Social Links`} />
                  <CardBody>
                    <IconGroup icons={validSocialLinks ?? []} />
                  </CardBody>
                </Card>
              ) : null}
            </Fragment>
          }
          isStickyAside={false}
        >
          <Card className='mb-3'>
            <FalconCardHeader title={`About`} />
            <CardBody>
              <p>{clubDescription}</p>
            </CardBody>
          </Card>
          <ClubFaq />
        </ContentWithAsideLayout>
      </Col>
    </Row>
  );
};

export default ClubInformationTab;
