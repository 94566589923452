import React from 'react';
import PropTypes from 'prop-types';

// components
import ProductListH from './ProductListH';
import ProductGridH from './ProductGridH';

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ProductH = ({ layoutStyle, ...rest }) => {
  // Context
  const productsLayout = layoutStyle ? layoutStyle : 'grid';
  // const { productsLayout } = useContext(ProductContext);
  const Tag =
    (productsLayout === 'list' && ProductListH) ||
    (productsLayout === 'grid' && ProductGridH);

  if (productsLayout === 'grid') {
    return (
      <ProductGridH {...rest} sliderSettings={sliderSettings} md={6} lg={4} />
    );
  }

  return <Tag {...rest} sliderSettings={sliderSettings} />;
};

ProductH.propTypes = {
  layoutStyle: PropTypes.string,
};

export default ProductH;
