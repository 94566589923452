import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//  libraries
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Row,
  Media,
  Input,
  Form,
  Button,
  FormGroup,
  Label,
} from 'reactstrap';

// components
import ButtonImgPostCreate from '../../feed/ButtonImgPostCreate';
import FalconCardHeader from '../../common/FalconCardHeader';
import LightBoxGallery from '../../common/LightBoxGallery';
import postImage from '../../../assets/img/illustrations/image.svg';

// actions
import {
  addPostToGroup_Action,
  addClubPostToGroup_Action,
} from '../../../redux/actions/postActions';

const PostOnClubFeedForm = ({
  group,
  addPostToGroup_Action,
  addClubPostToGroup_Action,
  groupName,
  feedId,
  isUserMod,
  clubId,
}) => {
  const [filesUploaded, setFiles] = useState([]);
  const [postHtml, setPostHtml] = useState('');
  const [postText, setPostText] = useState('');
  const [postLength, setPostLength] = useState(0);

  const [postAsClub, setPostAsClub] = useState(false);

  const onAddImageClicked = (e) => {
    let filesToPush = [];
    for (const i of e.target.files) {
      filesToPush.push(i);
    }
    if (filesToPush.length > 9) {
      toast.error(`Cannot attach more then 9 images in a post`);
      filesToPush = filesToPush.slice(0, 9);
    }

    setFiles([...filesToPush]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const input = { imageFiles: filesUploaded, postContent: postHtml };

    // check if user is member before allowing a post
    let postAllowed = false;
    if (group.feedType === 'public_feed') {
      postAllowed = true;
    } else if (
      (group.feedType === 'public_group' ||
        group.feedType === 'discoverable_private_group' ||
        group.feedType === 'hidden_private_group') &&
      group.isUserMember === true
    ) {
      postAllowed = true;
    }

    if (postAllowed === true) {
      if (input.postContent && input.postContent.length > 0) {
        if (postAsClub === true) {
          addClubPostToGroup_Action(
            feedId,
            {
              imageFiles: filesUploaded,
              postContent: postHtml,
              postText: postText,
              postLength: postLength,
            },
            clubId
          );
        } else {
          addPostToGroup_Action(feedId, {
            imageFiles: filesUploaded,
            postContent: postHtml,
            postText: postText,
            postLength: postLength,
          });
        }
        setPostAsClub(false);
        setPostHtml('');
        setFiles([]);
      } else {
        toast.warning(`Your post needs text`);
      }
    } else {
      toast.success(`You must join group before posting`);
    }
  };

  const onChangeReactQuill = (content, delta, source, editor) => {
    setPostHtml(content);
    setPostText(editor.getText());
    setPostLength(editor.getLength());
  };

  return (
    <Fragment>
      {group === null ? (
        <div className='text-center'></div>
      ) : (
        <Card className='mb-3'>
          <FalconCardHeader
            title={
              <Fragment>
                <Media body className=''>
                  <h5 className='mb-0 fs-0'>Post in Group</h5>
                </Media>
                <span className={`badge badge-dark rounded-capsule `}>
                  {groupName ? groupName : ''}
                </span>{' '}
              </Fragment>
            }
            titleTag={Media}
            titleClass='align-items-center'
          />
          <CardBody className='p-0'>
            <Form onSubmit={handleSubmit}>
              <ReactQuill
                className='border-0 rounded-0 resize-none'
                value={postHtml}
                onChange={onChangeReactQuill}
                style={{ height: 100, marginBottom: 44 }}
              />

              {filesUploaded.length > 0 ? (
                <LightBoxGallery
                  images={filesUploaded.map((f) => URL.createObjectURL(f))}
                >
                  {(openImgIndex) => (
                    <Row noGutters className='mx-n1 px-2 py-2'>
                      {filesUploaded.map((f, index) => {
                        return (
                          <Col
                            xs={6}
                            md={
                              filesUploaded.length === 1
                                ? 12
                                : filesUploaded.length === 2
                                ? 6
                                : 4
                            }
                            className='px-1'
                            style={{ minWidth: 50 }}
                            key={index}
                          >
                            <img
                              className='img-fluid rounded mb-2 cursor-pointer'
                              src={URL.createObjectURL(f)}
                              alt=''
                              onClick={() => openImgIndex(index)}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </LightBoxGallery>
              ) : null}

              <hr />
              <Row noGutters className='justify-content-between mt-3 px-3 pb-3'>
                <Col className='col'>
                  <label>
                    <input
                      type='file'
                      id='real-file'
                      accept='image/png, image/jpeg'
                      hidden
                      name='attachimg'
                      multiple
                      onChange={(e) => onAddImageClicked(e)}
                    />
                    <ButtonImgPostCreate imgSrc={postImage}>
                      <span className='d-none d-md-inline-block'>Image</span>
                    </ButtonImgPostCreate>
                  </label>
                </Col>
                <Col xs='auto'>
                  {isUserMod === true ? (
                    <Row className='d-inline-block mr-1'>
                      <FormGroup className={`form-check `}>
                        <Input
                          type='checkbox'
                          name='check'
                          id='exampleCheck'
                          checked={postAsClub}
                          onChange={() => setPostAsClub(!postAsClub)}
                        />
                        <Label for='exampleCheck' check>
                          Post As Club
                        </Label>
                      </FormGroup>
                    </Row>
                  ) : null}
                  <Button
                    className='px-4 px-sm-5 d-inline-block mr-1'
                    type='submit'
                    color='dark'
                    size='sm'
                  >
                    Share
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

PostOnClubFeedForm.propTypes = {
  feedType: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  addPostToGroup_Action: PropTypes.func.isRequired,
  addClubPostToGroup_Action: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  feedId: PropTypes.string.isRequired,
  clubId: PropTypes.string.isRequired,

  // ui props
  isUserMod: PropTypes.bool,
};

PostOnClubFeedForm.defaultProps = {
  isUserMod: false,
};

export default connect(null, {
  addPostToGroup_Action,
  addClubPostToGroup_Action,
})(PostOnClubFeedForm);
