import axios from 'axios';
import {
  axiosErrorToast,
  axiosSuccessToast,
  warningToast,
} from '../../utils/toastHandler';

export const reportPost = (input, postId) => async (dispatch) => {
  const newOb = {};
  if (input.details.length > 0) {
    newOb.details = input.details;
  }
  if (input.bullying === true) {
    newOb.bullying = input.bullying;
  }
  if (input.hateSpeech === true) {
    newOb.hateSpeech = input.hateSpeech;
  }
  if (input.misinformation === true) {
    newOb.misinformation = input.misinformation;
  }
  if (input.nudity === true) {
    newOb.nudity = input.nudity;
  }
  if (input.spam === true) {
    newOb.spam = input.spam;
  }

  newOb.postId = postId;

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (Object.keys(newOb).length >= 2) {
    try {
      const res = axios.post('/api/v2/report', newOb, config);
      axiosSuccessToast(res);
    } catch (err) {
      axiosErrorToast(err);
    }
  } else {
    warningToast('Cannot submit an empty report!');
  }
};
