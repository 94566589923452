import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// libraries
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  Row,
  Form,
  InputGroup,
  Button,
  InputGroupAddon,
  Spinner,
} from 'reactstrap';

// actions
import {
  getHousingAds_Action,
  getFavouriteHousingAds_Action,
  sortHousing,
} from '../../redux/actions/housingActions';

// components
import HousingSearch from './HousingSearch';
import ProductH from './housingProductComponents/ProductH';
import Flex from '../common/Flex';
import FalconCardHeader from '../common/FalconCardHeader';
import ProductFooterH from './housingProductComponents/ProductFooterH';

// misc
import { isIterableArray } from '../../helpers/utils';
import usePagination from '../../hooks/usePagination';

// events
import {
  posthog_ViewAllHousingAdsOpened,
  posthog_ViewAllHousingAdsOpened_Public,
} from '../../utils/posthogEvents/posthogHousingEvents';

const ViewAllHousingAds = ({
  match,
  getHousingAds_Action,
  getFavouriteHousingAds_Action,
  housingState: { housePosts, loading },
  sortHousing,
  user,

  userViewingFrom,
}) => {
  const query = useLocation().search;

  useEffect(() => {
    getHousingAds_Action(query);

    // Do not allow public to favourite housing
    if (userViewingFrom !== 'public') {
      getFavouriteHousingAds_Action();
    }
  }, [
    getHousingAds_Action,
    query,
    getFavouriteHousingAds_Action,
    userViewingFrom,
  ]);

  useEffect(() => {
    if (housePosts && user) {
      posthog_ViewAllHousingAdsOpened(housePosts, user);
    } else if (housePosts) {
      posthog_ViewAllHousingAdsOpened_Public(housePosts);
    }
  }, [housePosts, user]);

  // State
  const [isAsc, setIsAsc] = useState(false);
  const [sortBy, setSortBy] = useState('featured');
  useEffect(() => {
    sortHousing(sortBy, isAsc);
  }, [isAsc, sortBy, sortHousing]);

  const [productIds, setProductIds] = useState([]);
  const [productLayout, setProductsLayout] = useState('grid');

  // Hook
  const {
    data: paginationData,
    meta: paginationMeta,
    handler: paginationHandler,
  } = usePagination(productIds, 50);
  const { total, itemsPerPage, from, to } = paginationMeta;
  const { perPage } = paginationHandler;

  // const { productLayout } = match.params;
  const isList = productLayout === 'list';
  const isGrid = productLayout === 'grid';

  useEffect(() => {
    if (housePosts) setProductIds(housePosts.map((product) => product._id));
  }, [housePosts, setProductIds]);

  useEffect(() => {
    if (match && match.params && match.params.productLayout) {
      setProductsLayout(match.params.productLayout);
    }
  }, [match]);

  return (
    <Fragment>
      <Card className='mb-3'>
        <CardBody>
          <Row className='justify-content-between align-items-center'>
            <Col sm='auto' className='mb-2 mb-sm-0' tag={Flex} align='center'>
              <CustomInput
                id='itemsPerPage'
                type='select'
                bsSize='sm'
                value={itemsPerPage}
                onChange={({ target }) => perPage(Number(target.value))}
              >
                <option value={12}>12</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={total}>All</option>
              </CustomInput>
              <h6 className='mb-0 text-nowrap ml-2'>
                Showing {from}-{to} of {total} Products
              </h6>
            </Col>
            <Col sm='auto'>
              <Form className='d-inline-block mr-3'>
                <InputGroup size='sm' tag={Flex} align='center'>
                  <small className='mr-1'>Sort by:</small>
                  <CustomInput
                    type='select'
                    defaultValue={sortBy}
                    id='ProductSortBy'
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option value='featured'>Featured Properties</option>
                    <option value='rent'>Rent</option>
                    <option value='date available'>Move in Date</option>
                    <option value='date created'>Ad Created Date</option>
                    {/* <option value='off'>Off</option> */}
                  </CustomInput>
                  <InputGroupAddon addonType='append'>
                    <Button
                      onClick={() => setIsAsc(!isAsc)}
                      className='cursor-pointer'
                    >
                      <FontAwesomeIcon
                        icon={classNames({
                          'sort-amount-up': isAsc,
                          'sort-amount-down': !isAsc,
                        })}
                      />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Form>

              {/* button to allow switch between grid and list view */}
              {/* <FontAwesomeIcon
                className='cursor-pointer'
                icon={classNames({ 'list-ul': isGrid, th: isList })}
                onClick={() =>
                  history.push(`/housing/products/${isList ? 'grid' : 'list'}`)
                }
              /> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <HousingSearch />
      <Card>
        <FalconCardHeader
          title={`${
            loading === false && housePosts && housePosts.length > 0
              ? `${housePosts.length} Listings Available`
              : loading === true
              ? 'Loading...'
              : 'No Listings Found with this Search criteria'
          }`}
          titleTag='h5'
        />

        <CardBody
          className={classNames({
            'p-0  overflow-hidden': isList,
            'pb-0': isGrid,
          })}
        >
          {loading ? (
            <div className='text-center'>
              <Spinner color='primary' />
            </div>
          ) : (
            <Row noGutters={isList}>
              {isIterableArray(housePosts) &&
                housePosts
                  .filter((house) => paginationData.includes(house._id))
                  .map((house, index) => (
                    <ProductH
                      {...house}
                      key={house._id}
                      index={index}
                      layoutStyle={productLayout}
                      showFavourite={
                        userViewingFrom === 'public' ? false : true
                      }
                    />
                  ))}
            </Row>
          )}
        </CardBody>
        <ProductFooterH meta={paginationMeta} handler={paginationHandler} />
      </Card>
    </Fragment>
  );
};

ViewAllHousingAds.propTypes = {
  getHousingAds_Action: PropTypes.func.isRequired,
  housingState: PropTypes.object.isRequired,
  sortHousing: PropTypes.func.isRequired,
  getFavouriteHousingAds_Action: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  userViewingFrom: PropTypes.string.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  housingState: state.housingReducer,
  user: state.authReducer.user,
});

const mapDispatchToProps = {
  getHousingAds_Action,
  sortHousing,
  getFavouriteHousingAds_Action,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllHousingAds);
