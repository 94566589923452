// synched to backendApp
export const FILE_SIZE_MAP = {
  PROFILE_PIC: 1024 * 1024 * 2,
  PROFILE_BACKGROUND: 1024 * 1024 * 2,
  TUTOR_BACKGROUND: 1024 * 1024 * 2,
  RESOURCE: 1024 * 1024 * 10,
  POST_PIC: 1024 * 1024 * 2,
  HOSUING_IMAGE: 1024 * 1024 * 2,
  ROOMATE_IMAGE: 1024 * 1024 * 2,
  PRODUCT_IMAGE: 1024 * 1024 * 2,
};
