import React from 'react';
import PropTypes from 'prop-types';

// libs
import Datetime from 'react-datetime';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Badge,
  Button,
} from 'reactstrap';

// components
import FalconCardHeader from '../../peernet/common/FalconCardHeader';
import FormGroupSelect from '../../peernet/common/FormGroupSelect';
import rawTimezones from '../../data/event/timezones';

const EventDetailsForm = ({
  handleSubmit,
  eventInfo,
  setEventInfo,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
  endDate,
  setEndDate,
  endTime,
  setEndTime,
  deadline,
  setDeadline,
}) => {
  const timezones = rawTimezones.map((item) => ({
    value: `${item.offset} ${item.name}`,
    label: `${item.offset} ${item.name}`,
  }));
  return (
    <Card className='mb-3'>
      <FalconCardHeader title='Create New Event' light={false} />

      <CardBody tag={Form} className='bg-light' onSubmit={handleSubmit}>
        <FormGroup>
          <Badge color={'soft-success'} className='btn-block mb-2'>
            {`General Information`}
          </Badge>
          <Label for='title'>Event Title</Label>
          <Input
            id='title'
            placeholder='Event Title'
            value={eventInfo.title}
            onChange={({ target }) =>
              setEventInfo({ ...eventInfo, title: target.value })
            }
          />
        </FormGroup>
        <Row form>
          <Col sm className='mb-3'>
            <Label for='description'>Description</Label>
            <Input
              id='description'
              type='textarea'
              rows={6}
              placeholder='Description'
              value={eventInfo.description}
              onChange={({ target }) =>
                setEventInfo({ ...eventInfo, description: target.value })
              }
            />
          </Col>
        </Row>

        <Badge color={'soft-success'} className='btn-block mb-2'>
          {`Date & Time`}
        </Badge>
        <Row form>
          <Col sm tag={FormGroup}>
            <Label for='startDate'>Start Date</Label>
            <Datetime
              timeFormat={false}
              value={startDate}
              onChange={setStartDate}
              inputProps={{ placeholder: 'dd/md/yyyy', id: 'startDate' }}
            />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for='startTime'>Start Time</Label>
            <Datetime
              dateFormat={false}
              value={startTime}
              onChange={setStartTime}
              inputProps={{ placeholder: '', id: 'startTime' }}
            />
          </Col>
        </Row>
        <Row form>
          <Col sm tag={FormGroup}>
            <Label for='endDate'>End Date</Label>
            <Datetime
              timeFormat={false}
              value={endDate}
              onChange={setEndDate}
              inputProps={{ placeholder: 'dd/md/yyyy', id: 'endDate' }}
            />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for='endTime'>End Time</Label>
            <Datetime
              dateFormat={false}
              value={endTime}
              onChange={setEndTime}
              inputProps={{ placeholder: '', id: 'endTime' }}
            />
          </Col>
        </Row>
        <Row form>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <FormGroupSelect
              id='timezone'
              onChange={({ target }) => {
                setEventInfo({ ...eventInfo, timezone: target.value });
              }}
              label='Timezone'
              options={timezones}
              value={eventInfo.timezone}
              loading={false}
            />
          </Col>
        </Row>
        <Badge color={'soft-success'} className='btn-block mb-2'>
          {`Location`}
        </Badge>

        <Row form>
          <Col sm tag={FormGroup}>
            <Label for='venue'>Venue</Label>
            <Input
              id='venue'
              placeholder='Venue'
              value={eventInfo.venue}
              onChange={({ target }) =>
                setEventInfo({ ...eventInfo, venue: target.value })
              }
            />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for='address'>Address</Label>
            <Input
              id='address'
              placeholder='Address'
              value={eventInfo.address}
              onChange={({ target }) =>
                setEventInfo({ ...eventInfo, address: target.value })
              }
            />
          </Col>
        </Row>

        <Row form>
          <Col sm tag={FormGroup}>
            <Label for='city'>City</Label>
            <Input
              id='city'
              placeholder='City'
              value={eventInfo.city}
              onChange={({ target }) =>
                setEventInfo({ ...eventInfo, city: target.value })
              }
            />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for='state'>Province</Label>
            <Input
              id='state'
              placeholder='Province'
              value={eventInfo.state}
              onChange={({ target }) =>
                setEventInfo({ ...eventInfo, state: target.value })
              }
            />
          </Col>

          <Col sm tag={FormGroup}>
            <Label for='country'>Country</Label>
            <Input
              id='country'
              placeholder='Country'
              value={eventInfo.country}
              onChange={({ target }) =>
                setEventInfo({ ...eventInfo, country: target.value })
              }
            />
          </Col>
        </Row>
        <Badge color={'soft-success'} className='btn-block mb-2'>
          {`Other Information`}
        </Badge>

        <Row form>
          <Col xs={6} sm={6} md={6} lg={6} xl={6} tag={FormGroup}>
            <Label for='deadline'>Registration Deadline</Label>
            <Datetime
              timeFormat={false}
              value={deadline}
              onChange={setDeadline}
              inputProps={{ placeholder: 'dd/md/yyyy', id: 'deadline' }}
            />
          </Col>
        </Row>

        <Button
          color='falcon-primary mt-4'
          type='submit'
          onClick={handleSubmit}
          size='sm'
        >
          Publish your event
        </Button>
      </CardBody>
    </Card>
  );
};

EventDetailsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  eventInfo: PropTypes.object,
  setEventInfo: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  startTime: PropTypes.string,
  setStartTime: PropTypes.func.isRequired,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func.isRequired,
  endTime: PropTypes.string,
  setEndTime: PropTypes.func.isRequired,
  deadline: PropTypes.string,
  setDeadline: PropTypes.func.isRequired,
};

export default EventDetailsForm;
