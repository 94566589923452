import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import postReducer from './reducers/postReducer';
import housingReducer from './reducers/housingReducer';
import otherReducer from './reducers/otherReducer';
import createHousingAdReducer from './reducers/createHousingAdReducer';
import marketReducer from './reducers/marketReducer';
import chatReducer from './reducers/chatReducer';
import productReducer from './reducers/productReducer';

export default combineReducers({
  authReducer,
  housingReducer,
  postReducer,
  otherReducer,
  createHousingAdReducer,
  marketReducer,
  chatReducer,
  productReducer,
});
