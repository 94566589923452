import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_ViewAllProducts_Event = (products, user) => {
  if (user && user._id && products && products.length > 0) {
    posthog.capture('viewAllProducts', {
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewAllProducts' },
      {
        userId: user._id,
      }
    );
  }
};

export const posthog_ViewSingleProduct_Event = (product, user) => {
  if (user && user._id && product && product.productName && product._id) {
    posthog.capture('viewProduct', {
      viewProduct___productId: product._id,
      viewProduct___productName: product.productName,
      viewProduct___productType: product.productType,
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewProduct' },
      {
        viewProduct___productId: product._id,
        viewProduct___productName: product.productName,
        viewProduct___productType: product.productType,
        userId: user._id,
      }
    );
  }
};

export const posthog_createProduct_Event = (productInfo, user) => {
  if (user && user._id && productInfo) {
    posthog.capture('productCreated', {
      userId: user._id,
      createdProduct___productInfo: productInfo,
    });
  }
};

export const posthog_updatedProductDetails_Event = (productInfo, user) => {
  if (user && user._id && productInfo) {
    posthog.capture('productUpdated', {
      userId: user._id,
      updatedProduct___productInfo: productInfo,
    });
  }
};

export const posthog_productDetailsViewed_Event = (
  product,
  activeTab,
  user
) => {
  if (user && user._id && product && activeTab) {
    posthog.capture('productDetailsViewed', {
      productDetailsViewed___detailSection: activeTab,
      userId: user._id,
      productDetailsViewed___productId: product._id,
    });

    appInsights.trackEvent(
      { name: 'productDetailsViewed' },
      {
        productDetailsViewed___detailSection: activeTab,
        userId: user._id,
        productDetailsViewed___productId: product._id,
      }
    );
  }
};

export const posthog_productFavourited_Event = (product, user) => {
  if (user && user._id && product) {
    posthog.capture('productFavourited', {
      userId: user._id,
      productFavourited___productId: product._id,
    });
  }
};

export const posthog_productUnfavourited_Event = (product, user) => {
  if (user && user._id && product) {
    posthog.capture('productUnfavourited', {
      userId: user._id,
      productUnfavourited___productId: product._id,
    });
  }
};

export const posthog_productSearched_Event = (productInfo, user) => {
  if (user && user._id && productInfo) {
    posthog.capture('productSearched', {
      userId: user._id,
      productSearched___productInfo: productInfo,
    });

    appInsights.trackEvent(
      { name: 'productSearched' },
      {
        userId: user._id,
        productSearched___productInfo: productInfo,
      }
    );
  }
};
