import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

//libs
import { Card, CardBody, Button } from 'reactstrap';

// components
import Background from '../common/Background';
import { sendJoinRequest } from '../../redux/actions/groupActions';
import GroupMemberOptions from './GroupMemberOptions';

// assets
import corner1 from '../../assets/img/illustrations/corner-1.png';
import corner2 from '../../assets/img/illustrations/corner-2.png';
import corner3 from '../../assets/img/illustrations/corner-3.png';

const getImage = (color) => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner1;
  }
};

const GroupTitleTile = ({
  sendJoinRequest,
  // props
  tilewidth,
  to, // to = feedId
  color,
  children,
  announcement,
  details,
  feedType,
  isUserMember,
  isUserMod,
  modInfo,
  feedId,

  // default props
  showJoinBtn,
}) => {
  const getColorFromFeedType = () => {
    if (feedType === 'public_feed') {
      return 'info';
    } else if (feedType === 'public_group') {
      return 'info';
    } else if (feedType === 'discoverable_private_group') {
      return 'warning';
    } else if (feedType === 'hidden_private_group') {
      return 'danger';
    }
  };

  const getLabelFromFeedType = () => {
    if (feedType === 'public_feed') {
      return 'public feed';
    } else if (feedType === 'public_group') {
      return 'public group';
    } else if (feedType === 'discoverable_private_group') {
      return 'private group';
    } else if (feedType === 'hidden_private_group') {
      return 'hidden private group';
    }
  };

  return (
    <Card
      className='mb-3 overflow-hidden'
      style={{ minWidth: tilewidth > 10 ? '20rem' : '16rem' }}
    >
      <Background image={getImage(color)} className='bg-card' />
      <CardBody className='position-relative'>
        <h6>
          <span
            className={`badge badge-soft-${getColorFromFeedType(
              feedType
            )} rounded-capsule mr-1`}
          >
            {getLabelFromFeedType(feedType)}
          </span>
          {isUserMember ? (
            <>
              {feedType === 'public_feed' ? (
                <span
                  className={`badge badge-soft-success rounded-capsule mr-1`}
                >
                  {`Following Feed`}
                  <GroupMemberOptions groupFromState={{ _id: to }} />
                </span>
              ) : (
                <span
                  className={`badge badge-soft-primary rounded-capsule mr-1`}
                >
                  {`Group Member`}
                  {/* // DISCUSS */}
                  {/* talk to adit about this - Group member options disabled here, to leave group, you must leave club*/}
                  {/* <GroupMemberOptions groupFromState={{ _id: to }} /> */}
                </span>
              )}
            </>
          ) : null}
          {isUserMod ? (
            <span className={`badge badge-soft-warning rounded-capsule mr-1`}>
              {`Moderator`}
            </span>
          ) : null}
          {modInfo ? (
            <span className={`badge badge-soft-danger rounded-capsule mr-1`}>
              {modInfo}
            </span>
          ) : null}
        </h6>
        <Link className={`text-${color}`} to={`/g/${to}`}>
          <h4 className='text-text-200'>{announcement}</h4>{' '}
        </Link>
        <h6>
          <p className='text-secondary'>{details}</p>
        </h6>

        {isUserMember ? null : (
          <div className='row'>
            <div className='col'>
              {showJoinBtn === true ? (
                <Button
                  className='px-1'
                  block
                  color='info'
                  size='sm'
                  onClick={() => {
                    sendJoinRequest(to, feedType, true);
                  }}
                >
                  {feedType === 'public_feed'
                    ? `Subscribe to Feed`
                    : `Join Group`}
                </Button>
              ) : null}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

GroupTitleTile.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,

  sendJoinRequest: PropTypes.func.isRequired,

  tilewidth: PropTypes.number,
  color: PropTypes.string,
  announcement: PropTypes.string,
  details: PropTypes.string,
  feedType: PropTypes.string,
  isUserMember: PropTypes.bool,
  isUserMod: PropTypes.bool,
  modInfo: PropTypes.string,
  feedId: PropTypes.string,

  // default props
  showJoinBtn: PropTypes.bool,
};

GroupTitleTile.defaultProps = {
  // linkText: 'See all',
  to: '#!',
  color: 'primary',
  showJoinBtn: true,
};

// export default GroupTitleTile;
const mapStateToProps = (state) => ({
  groupsStateFromRedux: state.groupReducer,
});

export default connect(mapStateToProps, { sendJoinRequest })(GroupTitleTile);
