import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

// libraries

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardFooter,
  Badge,
  Spinner,
} from 'reactstrap';

// components
import FalconCardHeader from '../../common/FalconCardHeader';
import ProductSearch from '../productComponents/ProductSearch';
import ProductListings from '../components/productListings/ProductListings';
import Flex from '../../common/Flex';

// asets
import productImg from '../../../assets/img/undraw/undraw_book_lover_mkck.svg';

// actions
import {
  getProducts,
  getFavProducts_Action,
} from '../../../redux/actions/productActions';

// events
import { posthog_ViewAllProducts_Event } from '../../../utils/posthogEvents/posthogMarketplaceEvents';

const OpenMarketplacePage = ({
  //reducer
  productReducer: { products, loading },
  auth: { user },

  //action
  getProducts,
  getFavProducts_Action,

  // other
  type,
  url = `/marketplace/buy`,
  isPublic,
}) => {
  useEffect(() => {
    getProducts(type ?? {});
    if (!url === '/marketplace/public') {
      getFavProducts_Action();
    }
  }, [type, getProducts, getFavProducts_Action, url]);

  useEffect(() => {
    if (products && user) {
      posthog_ViewAllProducts_Event(products, user);
    }
  }, [products, user]);

  // FE pagination
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const history = useHistory();
  const currentPage = Math.abs(parseInt(params.get('page'), 10)) || 1;
  const leftClick = (e) => {
    e.preventDefault();
    history.push(`${url}?page=${currentPage - 1}`);
  };

  const rightClick = (e) => {
    e.preventDefault();
    history.push(`${url}?page=${currentPage + 1}`);
  };

  return (
    <Fragment>
      {loading ? (
        <div className='text-center'>
          <Spinner color='primary' />
        </div>
      ) : (
        <Fragment>
          <ProductSearch url={url} productType={type?.productType ?? ''} />

          <Card>
            {products ? (
              products.length !== 0 ? (
                <Fragment>
                  <FalconCardHeader
                    title={`Active Product Listings`}
                    titleTag='h5'
                  >
                    <Flex>
                      <Badge color='primary' size='sm' className='mr-2'>
                        {`${products.length} products available`}
                      </Badge>
                    </Flex>
                  </FalconCardHeader>
                  <CardBody>
                    <Row
                      xs={1}
                      md={2}
                      lg={3}
                      noGutters
                      className='fs--1 gap-4 mx-4'
                    >
                      {products
                        .slice((currentPage - 1) * 24, currentPage * 24)
                        .map((p, index) => (
                          <ProductListings
                            product={p}
                            key={index}
                            isPublic={isPublic}
                          />
                        ))}
                    </Row>{' '}
                  </CardBody>

                  <CardFooter
                    tag={Flex}
                    justify='center'
                    align='center'
                    className='bg-light border-top'
                  >
                    <Button
                      color='falcon-default'
                      size='sm'
                      className='ml-1 ml-sm-2'
                      disabled={currentPage === 1}
                      onClick={leftClick}
                    >
                      <FontAwesomeIcon icon={`chevron-left`} />
                    </Button>
                    <Button
                      color='falcon-default'
                      size='sm'
                      className='ml-1 ml-sm-2'
                      onClick={rightClick}
                      disabled={
                        currentPage * 24 >= products.length ? true : false
                      }
                    >
                      <FontAwesomeIcon icon={`chevron-right`} />
                    </Button>
                  </CardFooter>
                </Fragment>
              ) : (
                <Card className='text-center'>
                  <CardBody className='p-5'>
                    <Col xs={6} className='mx-auto'>
                      <img src={productImg} className='img-fluid' alt='' />
                    </Col>
                    <h1 className='mt-4 text-800 text-sans-serif font-weight-semi-bold'>
                      No products found.
                    </h1>
                    <hr />
                    <h3>Try searching for something else!</h3>
                  </CardBody>
                </Card>
              )
            ) : null}
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
};

OpenMarketplacePage.propTypes = {
  productReducer: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  getFavProducts_Action: PropTypes.func.isRequired,

  auth: PropTypes.object.isRequired,

  type: PropTypes.object,
  url: PropTypes.string.isRequired,
  isPublic: PropTypes.bool,
};

OpenMarketplacePage.defaultProps = {
  isPublic: false,
};

const mapStateToProps = (state) => ({
  productReducer: state.productReducer,
  auth: state.authReducer,
});

const mapDispatchToProps = {
  getProducts,
  getFavProducts_Action,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenMarketplacePage);
