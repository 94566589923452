import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// libraries
import { Media, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

// components
import Avatar from '../../common/Avatar';
import FeedDropDown from './FeedDropDown';
import FalconCardHeader from '../../common/FalconCardHeader';

const FeedCardHeader = ({
  post,
  name,
  avatarSrc,
  time,
  location,
  privacy,
  status,
  share,
}) => {
  const [isOpenBottom, setIsOpenBottom] = useState(false);
  const [isReachInfoOpen, setIsReachInfoOpen] = useState(false);

  return (
    <FalconCardHeader
      title={
        <Fragment>
          {avatarSrc && avatarSrc === 'anonAvatar' ? (
            <Avatar name={name} className={status} size='2xl' />
          ) : avatarSrc ? (
            <Avatar src={avatarSrc} className={status} size='2xl' />
          ) : (
            <Avatar name={name} className={status} size='2xl' />
          )}
          {/* <Avatar src={avatarSrc} className={status} size='2xl' /> */}
          <Media body className='align-self-center ml-2'>
            <p className='mb-1 line-height-1'>
              <Link
                className='font-weight-semi-bold'
                to={post.user ? `/profile/${post.user}` : `#`}
              >
                {name}
                {/* <h4 className='mb-1'>
                  {name}
                  {verified && <Verified placement='right' id='profileVerified' />}
                </h4> */}
              </Link>
              {!!share && (
                <span className='ml-1'>
                  shared {/^[aeiou]/g.test(share.toLowerCase()) ? 'an' : 'a'}{' '}
                  <a href='#!'>{share}</a>
                </span>
              )}
            </p>
            <p className='mb-0 fs--1'>
              {time}
              {location && location._id && location.feedName ? (
                <Fragment>
                  {' • '}
                  <Link
                    className='font-weight-semi-bold'
                    to={location ? `/g/${location._id}` : `#`}
                  >
                    {`g/${location.feedName}`}
                  </Link>
                </Fragment>
              ) : null}

              {' • '}
              <Fragment>
                <FontAwesomeIcon
                  icon={classNames({
                    users: privacy === 'group_members',
                    lock: privacy === 'private',
                    'globe-americas': privacy === 'public',
                  })}
                  id={
                    privacy === 'group_members' ? 'reach1' : 'purposefullylost'
                  }
                />

                {privacy === 'group_members' ? (
                  <Tooltip
                    placement='bottom'
                    isOpen={isReachInfoOpen && privacy === 'group_members'}
                    target={'reach1'}
                    toggle={() => setIsReachInfoOpen(!isReachInfoOpen)}
                    container='.content'
                  >
                    Only members in this private group can see post
                  </Tooltip>
                ) : null}
              </Fragment>

              {post.isAnon ? (
                <Fragment>
                  {' • '}
                  <FontAwesomeIcon icon={faUserSecret} id={'pop4'} />
                  <Tooltip
                    placement='bottom'
                    isOpen={isOpenBottom}
                    target='pop4'
                    toggle={() => setIsOpenBottom(!isOpenBottom)}
                    container='.content'
                  >
                    This is an anonymous post
                  </Tooltip>
                </Fragment>
              ) : null}
            </p>
          </Media>
        </Fragment>
      }
      titleTag={Media}
    >
      <FeedDropDown post={post} />
    </FalconCardHeader>
  );
};

FeedCardHeader.propTypes = {
  name: PropTypes.string.isRequired,
  avatarSrc: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  location: PropTypes.object,
  status: PropTypes.string,
  share: PropTypes.bool,
  post: PropTypes.object.isRequired,
  privacy: PropTypes.string.isRequired,
};

export default FeedCardHeader;
