import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// libraries
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card,
  CardFooter,
  CardBody,
  Button,
} from 'reactstrap';
import classNames from 'classnames';
import Select from 'react-select';

// components
import FalconCardHeader from '../../../common/FalconCardHeader';

// queries
import { useSearchTutors_Query } from '../../../../queries/tutorQueries';

// static data
import { UNIS } from '../../../static/staticUniInfo';
import { UNIS_WITH_COURSES } from '../../../static/staticCourseInfo';

const initailTutorSearchState = {
  courses: null,
};

const TutorSearch = ({ auth }) => {
  const [searchParams, setSearchParams] = useState(initailTutorSearchState);

  // set default university to McMaster University if user is not set
  const [university, setUniversity] = useState(
    auth && auth.user ? auth.user.uni : 'McMaster University'
  );

  const { mutateAsync: searchTutors } = useSearchTutors_Query();

  const [showOptions, setShowOptions] = useState(false);

  const onChangeUni = (e) => {
    setUniversity(e.target.value);
  };

  const handleInputChange = (typedOption) => {
    let typedOptionWithoutWhitespace = typedOption.replace(/\s/g, '');
    if (typedOptionWithoutWhitespace.length >= 3) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  const onChangeCourse = (selectedOption) => {
    setSearchParams({
      // Ensures null selected option does not get mapped
      courses:
        selectedOption === null
          ? null
          : selectedOption.map((course) => course.value),
    });
  };

  const history = useHistory();

  const submitSearch = (e) => {
    e.preventDefault();
    searchTutors(searchParams);
    history.push(`/tutor/view-all`);
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setSearchParams(initailTutorSearchState);
    searchTutors(initailTutorSearchState);
    history.push(`/tutor/view-all`);
  };

  return (
    <Fragment>
      <Card className='mb-4'>
        <FalconCardHeader title='Search Tutors' titleTag='h5' />
        <CardBody>
          <Row>
            <Col xs={12} className='my-4'>
              <FormGroup>
                <Label for='university'>University</Label>
                <Input
                  className='mb-4'
                  label='courses'
                  type='select'
                  id='university'
                  name='university'
                  onChange={(e) => {
                    onChangeUni(e);
                  }}
                  required
                  defaultValue={university}
                >
                  <option disabled value=''>
                    Select University
                  </option>
                  {UNIS?.map((item, i) => {
                    return (
                      <option key={i} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for='courses'>Course</Label>
                <Select
                  className='basic-single mb-3 border rounded'
                  classNamePrefix='react-select'
                  id='courses'
                  // value={{ value: course._id, label: course.courseName }}
                  isMulti
                  isClearable={true}
                  isSearchable={true}
                  name='courses'
                  placeholder='Enter Course name (4 characters required before options show up)'
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  options={
                    showOptions
                      ? UNIS_WITH_COURSES.find(
                          (uni) => uni.name === university
                        )?.courses.map((course) => {
                          return {
                            value: course._id,
                            label: course.courseName,
                          };
                        })
                      : []
                  }
                  onChange={onChangeCourse}
                  onInputChange={handleInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          className={classNames(`bg-light py-2`, {
            'border-top': true,
          })}
        >
          <Row>
            <Col className='float-right'>
              <Button
                className='btn mx-2 float-right'
                color='success'
                onClick={submitSearch}
              >
                Search
              </Button>
              <Button
                className='float-right btn-outline-warning mx-2'
                // color='danger'
                color='light'
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

TutorSearch.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {})(TutorSearch);
