import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// libraries
import { Badge, Media } from 'reactstrap';

// static assets
import logoApple from '../../../../src/assets/img/logos/apple.png';

// utils
import { checkIfSingleWordAndshorten } from '../../../../src/helpers/utils';
import PublicJoinClubModal from './PublicJoinClubModal';

const ClubTile = ({ clubInfo, isPublicView }) => {
  const {
    _id,
    logo,
    clubName,
    memberCount: membersLen,
    clubUniversity: { name: clubUniversityName },
  } = clubInfo;

  return (
    <Media className='align-items-center mb-2'>
      <img className='mr-2' src={logo ?? logoApple} width={50} alt='' />
      <Media body>
        <h6 className='fs-0 mb-0'>
          <Link
            className={`break-word ${
              isPublicView === false ? 'stretched-link' : ''
            }`}
            to={
              isPublicView === true
                ? `/c/${clubName.replace(' ', '-')}`
                : `/clubs/view/${_id}`
            }
          >
            {checkIfSingleWordAndshorten(clubName, 14)}
          </Link>
        </h6>

        <p className='mb-0'>
          <Badge color='soft-success mr-1'>
            {`${membersLen} member${membersLen > 1 ? 's' : ''}`}{' '}
          </Badge>

          <Badge color='soft-warning'>{clubUniversityName}</Badge>
        </p>

        {isPublicView === true ? (
          <Fragment>
            <PublicJoinClubModal clubName={clubName} hideBigButton={true} />
          </Fragment>
        ) : null}
      </Media>
    </Media>
  );
};

ClubTile.propTypes = {
  clubInfo: PropTypes.object.isRequired,
  isPublicView: PropTypes.bool,
};

ClubTile.defaultProps = { isPublicView: false };

export default ClubTile;
