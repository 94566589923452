import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import { Spinner } from 'reactstrap';

// components
import NavbarTop from '../navbar/NavbarTop';
import NavbarVertical from '../navbar/NavbarVertical';
import ProductProvider from '../e-commerce/ProductProvider';
import SidePanelModal from '../side-panel/SidePanelModal';

const EmptyPageForAuthFailure = ({ auth: { user, loading } }) => {
  return loading ? (
    <Spinner color='danger' />
  ) : (
    <Fragment>
      <div className={'container'}>
        <NavbarVertical />
        <ProductProvider>
          <div className='content'>
            <NavbarTop />
            <div className=''>
              <div className='container mt-4'>
                <h4 className='large text-dark'>
                  hmmm... something went wrong
                </h4>
                <hr />{' '}
                <h6>
                  Email us at <b>hello@peernet.co</b> for assistance
                </h6>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
          <SidePanelModal autoShow={false} />
        </ProductProvider>
      </div>
    </Fragment>
  );
};

EmptyPageForAuthFailure.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps, {})(EmptyPageForAuthFailure);
