import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

// components
import FalconCardHeader from '../../common/FalconCardHeader';
import Avatar from '../../common/Avatar';
import ButtonIcon from '../../common/ButtonIcon';

// libraries
import { Spinner, Card, CardBody, Media } from 'reactstrap';
import Moment from 'react-moment';

// misc
import { isIterableArray } from '../../../helpers/utils';

// queries
import {
  useGetAllClubJoinRequests_Query,
  useAcceptClubRequestByAdmin_Query,
  useRejectClubRequestByAdmin_Query,
} from '../../../queries/clubQueries';

const ClubAcceptJoinReqs = ({ clubData }) => {
  const {
    data: requests,
    isLoading,
    isError,
  } = useGetAllClubJoinRequests_Query(clubData._id);
  const isRequestsLoading = isLoading || isError || !requests;

  const { mutate: rejectIncomingJoinClubRequest } =
    useRejectClubRequestByAdmin_Query();

  const { mutate: acceptIncomingJoinClubRequest } =
    useAcceptClubRequestByAdmin_Query();

  // helper function
  function formatTime(time) {
    //now - 4 days > time -> format else bottom
    const curr = new Date(time).getTime();
    const time4DaysAgo = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
    const time3MonthAgo = new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
    if (time4DaysAgo < curr) {
      // The yourDate time is less than 4 days from now
      return <Moment fromNow>{time}</Moment>;
    } else if (time3MonthAgo < curr) {
      return <Moment format='ddd MMM D'>{time}</Moment>;
    } else {
      return <Moment format='D/M/YYYY'>{time}</Moment>;
    }
  }

  const acceptUser = (e, userId) => {
    e.preventDefault();

    const finalClubData = {
      clubId: clubData._id,
      requestingUserId: userId._id,
    };
    acceptIncomingJoinClubRequest(finalClubData);
  };

  const rejectUser = (e, userId) => {
    e.preventDefault();

    const finalClubData = {
      clubId: clubData._id,
      requestingUserId: userId._id,
    };
    rejectIncomingJoinClubRequest(finalClubData);
  };

  return (
    <Fragment>
      {isRequestsLoading ? (
        <div className='text-center'>
          <div className='text-center'>
            <Spinner color='primary' />
          </div>
        </div>
      ) : (
        <Card className='mb-3'>
          <FalconCardHeader
            title='Peers requesting to join your club'
            titleTag='h5'
          />
          <CardBody>
            {isIterableArray(requests?.joinRequests) &&
            requests?.joinRequests?.length > 0
              ? requests?.joinRequests.map((u, index) => (
                  <Media key={index}>
                    <Avatar size='3xl' src={u.userId.avatar} />
                    <Media body className='ml-2'>
                      <h6 className='mb-0'>
                        <Link to={`/pages/profile/${u._id}`}>
                          {u.userId.name}
                        </Link>
                      </h6>
                      {!!u.timestamp && (
                        <p className='fs--1 mb-0'>{formatTime(u.timestamp)}</p>
                      )}
                      <ButtonIcon
                        color='light'
                        size='sm'
                        icon='user-plus'
                        className='py-0 mt-1 border mr-1'
                        transform='shrink-5'
                        onClick={(e) => acceptUser(e, u.userId)}
                      >
                        <span className='fs--1'>Approve Join</span>
                      </ButtonIcon>
                      <ButtonIcon
                        color='light'
                        size='sm'
                        icon='user-plus'
                        className='py-0 mt-1 border'
                        transform='shrink-5'
                        onClick={(e) => rejectUser(e, u.userId)}
                      >
                        <span className='fs--1'>Reject</span>
                      </ButtonIcon>
                      {clubData?.joinRequests.length === index + 1 ? null : (
                        <hr className='border-bottom-0 border-dashed' />
                      )}
                    </Media>
                  </Media>
                ))
              : 'No new join requests'}
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};

ClubAcceptJoinReqs.propTypes = {
  clubData: PropTypes.object,
};

export default ClubAcceptJoinReqs;
