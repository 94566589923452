import {
  LOGOUT,
  GET_HOUSING,
  HOUSE_ERROR,
  SET_HOUSING_REDUCER_LOADING,
  GET_MY_HOUSING_ADS_STATS,
  GET_SINGLE_HOUSE_AD,
  DELETE_HOUSING,
  SET_IGNORED,
  GET_MY_HOUSING_ADS,
  GET_FAVOURITE_HOUSING_ADS,
  ADD_HOUSING_TO_FAV,
  REMOVE_HOUSING_FROM_FAV,
} from '../types';

import { filterIgnoredAd } from '../helpers/sortFunctions';

const initialState = {
  housePosts: [],
  myHouseAds: [],
  favouriteHouses: [],
  houseAd: null,
  loading: true,
  stats: null,
  error: {},
  ignoredAd: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IGNORED:
      return {
        ...state,
        ignoredAd: payload,
        loading: false,
      };
    case GET_HOUSING:
      let temp = [];
      if (payload.datapayload && payload.datapayload.length > 0) {
        temp = filterIgnoredAd(state.ignoredAd, payload.datapayload);
      } else {
        temp = payload.datapayload;
      }

      return {
        ...state,
        housePosts: temp,
        // housePosts:
        loading: false,
      };
    case GET_MY_HOUSING_ADS:
      return {
        ...state,
        myHouseAds: payload.datapayload,
        loading: false,
      };
    case GET_FAVOURITE_HOUSING_ADS:
      return {
        ...state,
        favouriteHouses: payload,
        loading: false,
      };
    case ADD_HOUSING_TO_FAV: {
      return {
        ...state,
        favouriteHouses: [...state.favouriteHouses, payload.addedHouse[0]],
        loading: false,
      };
    }
    case REMOVE_HOUSING_FROM_FAV:
      return {
        ...state,
        favouriteHouses: state.favouriteHouses.filter((house) =>
          payload.removedHouse.favouriteHouses.includes(house._id)
        ),
        loading: false,
      };
    case GET_SINGLE_HOUSE_AD:
      return {
        ...state,
        houseAd: payload.house,
        loading: false,
      };

    case HOUSE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case SET_HOUSING_REDUCER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MY_HOUSING_ADS_STATS:
      return {
        ...state,
        stats: payload,
        loading: false,
      };
    case DELETE_HOUSING:
      const newArr = state.myHouseAds.filter((item) => {
        return item._id !== payload;
      });
      return {
        ...state,
        myHouseAds: newArr,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
