import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_ViewInternshipCompany_Event = (company, user) => {
  if (user && user._id && company && company.name && company._id) {
    posthog.capture('viewInternshipCompany', {
      viewInternshipCompany___companyId: company._id,
      viewInternshipCompany___companyName: company.name,
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewInternshipCompany' },
      {
        viewInternshipCompany___companyId: company._id,
        viewInternshipCompany___companyName: company.name,
        userId: user._id,
      }
    );
  }
};

export const posthog_ViewAllCompanies = (user, companies) => {
  if (user && user._id && companies && companies.length > 0) {
    posthog.capture('viewAllCompanies', {
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewAllCompanies' },
      {
        userId: user._id,
      }
    );
  }
};

export const posthog_ViewAllCompaniesPublic = (companies) => {
  if (companies && companies.length > 0) {
    posthog.capture('viewAllCompaniesPublic');

    appInsights.trackEvent({ name: 'viewAllCompaniesPublic' });
  }
};

export const posthog_RecruiterEmailTemplateGenerated_Event = (
  company,
  recruiterName,
  user,
  template
) => {
  if (
    user &&
    user._id &&
    company &&
    company.name &&
    company._id &&
    recruiterName
  ) {
    posthog.capture('generateRecruiterEmailTemplate', {
      generateRecruiterEmailTemplate___companyId: company._id,
      generateRecruiterEmailTemplate___companyName: company.name,
      generateRecruiterEmailTemplate___recruiterName: recruiterName,
      userId: user._id,
      generateRecruiterEmailTemplate___template: template,
    });

    appInsights.trackEvent(
      { name: 'generateRecruiterEmailTemplate' },
      {
        generateRecruiterEmailTemplate___companyId: company._id,
        generateRecruiterEmailTemplate___companyName: company.name,
        generateRecruiterEmailTemplate___recruiterName: recruiterName,
        userId: user._id,
        generateRecruiterEmailTemplate___template: template,
      }
    );
  }
};
