import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_userLoggedOut_Event = (user) => {
  if (user && user._id) {
    posthog.capture('userLoggedOut', {
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'userLoggedOut' },
      {
        userId: user._id,
      }
    );
  }
};
