/* eslint-disable no-console */
import { toast } from 'react-toastify';

export const successToast = (msg) => {
  toast.success(msg);
};

export const warningToast = (msg) => {
  toast.warning(msg);
};

export const errorToast = (msg) => {
  toast.error(msg);
};

export const axiosErrorToast = (err, showPlaceholder = true) => {
  // LEAVE THIS HERE
  if (process.env.REACT_APP_DOMAIN === 'localhost:3000') {
    console.log(err);
    console.log(err?.response);
    console.log(err?.response?.data);
    console.log(err?.response?.data?.message);
  }
  if (err?.response?.data?.message) {
    errorToast(err.response.data.message);
  } else if (showPlaceholder === true) {
    toast.error('Something went wrong', { toastId: 'somethingWentWrongToast' });
  }
};

export const axiosSuccessToast = (res) => {
  successToast(res?.data?.message || 'Success');
};
