import React, { Fragment } from 'react';

// components
import NavbarStandard from '../../navbar/NavbarStandard';
import Cta from '../../landing/Cta';
import FooterStandard from '../../landing/FooterStandard';
import ViewClubPage from '../privatePages/ViewClubPage';

const ViewClubPagePublic = () => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container my-8'}>
          <ViewClubPage isPublicView={true} />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

export default ViewClubPagePublic;
