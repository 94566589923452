import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// components
import Dashboard from '../dashboard/Dashboard';
import NavbarTop from '../navbar/NavbarTop';
import NavbarVertical from '../navbar/NavbarVertical';
import Footer from '../footer/Footer';
import ProductProvider from '../e-commerce/ProductProvider';
import SidePanelModal from '../side-panel/SidePanelModal';

// libraries
import loadable from '@loadable/component';

// misc
import AppContext from '../../context/Context';
import { SocketContext, socket } from '../../context/socket';

// actions
import { getMyChatThreads_Action } from '../../redux/actions/chatActions';

// routes
const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({
  location,

  // redux state
  chatState: { myChats },
  authState: { user, userSetupStage },

  // actions
  getMyChatThreads_Action,
}) => {
  const { isFluid, isTopNav } = useContext(AppContext);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // FUTURETODO - Limit # threads returned from BE and load More threads if necessary
  useEffect(() => {
    // info: only run this once for the initial load
    if (myChats.length === 0 && userSetupStage === 'done') {
      getMyChatThreads_Action(user._id);
    }
  }, [getMyChatThreads_Action, myChats.length, user._id, userSetupStage]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {!isTopNav && <NavbarVertical />}
      <SocketContext.Provider value={socket}>
        <ProductProvider>
          <div className='content'>
            <NavbarTop />
            <Switch>
              <Route path='/dashboard' exact component={Dashboard} />
              {/* ALL OTHER ROUTES */}
              <DashboardRoutes />
            </Switch>
            <Footer />
          </div>
          <SidePanelModal autoShow={false} />
        </ProductProvider>
      </SocketContext.Provider>
    </div>
  );
};

DashboardLayout.propTypes = {
  location: PropTypes.object.isRequired,

  //state
  chatState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,

  //action functions
  getMyChatThreads_Action: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  chatState: state.chatReducer,
  authState: state.authReducer,
});

export default connect(mapStateToProps, { getMyChatThreads_Action })(
  DashboardLayout
);
