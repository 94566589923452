export default [
  {
    title: '10 Things to Know Before Going to McMaster',
    date: 'Sept 1',
    read: '7 min',
    star: true,
    link:
      'https://medium.com/@peernet/10-things-to-know-before-going-to-mcmaster-e7c09aed2119',
  },
  {
    title: 'McMaster Textbook Exchange',
    date: 'Aug 27',
    read: '2 min',
    link: 'https://medium.com/@peernet/mcmaster-textbook-exchange-7a78dd33d208',
  },
  {
    title: 'Essentials you NEED before heading off to University',
    date: 'Aug 17',
    read: '10 min',
    link:
      'https://medium.com/@peernet/essentials-you-need-before-heading-off-to-university-4cd095890f0e',
  },
  {
    title: 'McMaster University: Top 10 Places to Eat Off-Campus',
    date: 'Aug 14',
    read: '6 min',
    link:
      'https://medium.com/@peernet/we-all-need-a-change-now-and-again-so-if-youre-ever-craving-something-that-just-can-t-be-found-28aba6a27175',
  },
];
