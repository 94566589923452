import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import eventsImg from '../../../assets/img/undraw/undraw_events_2p66.svg';

// components
import AllEvents from '../../event/AllEvents';
import Banner from '../../common/Banner';
// context
import { ClubContext } from '../privatePages/ViewClubPage';

const ClubEvents = () => {
  const { showEventModal, events, isAdmin } = useContext(ClubContext);
  const noEventsAvailable = !events || events.length === 0;

  return (
    <div className='mt-3'>
      {noEventsAvailable ? (
        <>
          <Banner
            title={`No Club events!`}
            description={`Check this tab to find new events specific to this club!`}
            img={eventsImg}
          >
            {isAdmin && (
              <Button
                className='btn btn-falcon-primary'
                onClick={showEventModal}
              >
                Create a new event!
              </Button>
            )}
          </Banner>
        </>
      ) : (
        <AllEvents clubEvents={events} hideOrganizedByLine={true} />
      )}
    </div>
  );
};
export default React.memo(ClubEvents);
