import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_AI_INSTRU_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    autoTrackPageVisitTime: true,
  },
});
ai.loadAppInsights();

ai.addTelemetryInitializer((envelope) => {
  envelope.tags = envelope.tags || [];
  envelope.tags.push({ 'ai.cloud.role': 'frontend' });
});

export const setAiUser = (userId) => {
  ai.setAuthenticatedUserContext(userId);
};

export default (Component) => withAITracking(reactPlugin, Component);
export const appInsights = ai.appInsights;
