import React from 'react';
import PropTypes from 'prop-types';

// libraries
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';

// components
import Loader from '../../common/Loader';
import FalconCardHeader from '../../common/FalconCardHeader';
import ClubTile from '../components/ClubTile';

// misc
import { isIterableArray } from '../../../helpers/utils';

// queries
import { useGetAllClubs_Query } from '../../../queries/clubQueries';

const ViewAllClubsPage = ({
  // parent props
  isPublicView,
}) => {
  const { isLoading, isError, data: allClubs } = useGetAllClubs_Query();

  const areClubsLoaded = isLoading === false && isError === false && allClubs;
  return (
    <Card className='mb-3'>
      <FalconCardHeader title='All Clubs' />
      <CardBody className='fs--1'>
        {!areClubsLoaded ? (
          <Loader />
        ) : isIterableArray(allClubs) ? (
          <Row>
            {allClubs.map((clubInfo, index) => {
              return (
                <Col sm={6} md={4} className='mb-3' key={index}>
                  <ClubTile clubInfo={clubInfo} isPublicView={isPublicView} />
                </Col>
              );
            })}
          </Row>
        ) : (
          <Alert color='info' className='mb-0'>
            No clubs found
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

ViewAllClubsPage.propTypes = {
  // parent props
  isPublicView: PropTypes.bool,
};

ViewAllClubsPage.defaultProps = { isPublicView: false };

export default ViewAllClubsPage;
