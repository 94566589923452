import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Col,
} from 'reactstrap';
import Datetime from 'react-datetime';
import Badge from 'reactstrap/es/Badge';
import { connect } from 'react-redux';

//components
import FalconCardHeader from '../../../../common/FalconCardHeader';
import UpdateHousingImages from './UpdateHousingImages';

const UpdateAdForm = ({
  //functions from parent
  setFormData,
  formData,
  adImgs,
  setAdImgs,
  plan,
  universities,
}) => {
  const amenitiesMap = [
    { type: 'item', name: 'laundryCheck', value: 'On-Site Laundry' },
    { type: 'item', name: 'dishwasherCheck', value: 'Dishwasher' },
    {
      type: 'item',
      name: 'cleaningServiceCheck',
      value: 'Cleaning Service Included',
    },
    { type: 'seperate' },

    { type: 'item', name: 'acCheck', value: 'Air-Conditioning' },
    { type: 'item', name: 'parkingCheck', value: 'Parking' },
    { type: 'item', name: 'furnishedCheck', value: 'Furnished' },
    {
      type: 'item',
      name: 'partiallyFurnishedCheck',
      value: 'Partially Furnished',
    },
    { type: 'seperate' },

    { type: 'item', name: 'poolCheck', value: 'Pool' },
    { type: 'item', name: 'gymCheck', value: 'Gym' },
    { type: 'item', name: 'petCheck', value: 'Pet Friendly' },
    { type: 'item', name: 'balconyCheck', value: 'Balcony' },
    { type: 'item', name: 'frontYardCheck', value: 'Front Yard' },
    { type: 'item', name: 'backYardCheck', value: 'Back Yard' },
  ];

  const utilitiesMap = [
    { type: 'item', name: 'heatCheck', value: 'Heat' },
    { type: 'item', name: 'waterCheck', value: 'Water' },
    { type: 'item', name: 'electricityCheck', value: 'Electricity' },
    { type: 'item', name: 'wifiCheck', value: 'Wi-Fi' },
    { type: 'item', name: 'cableCheck', value: 'Cable' },
  ];

  const accessibilityMap = [
    {
      type: 'item',
      name: 'accessibilityCheck',
      value: 'This property is wheelchair accessible',
    },
  ];

  const {
    rentalType,
    rentalTerm,
    rent,

    availableBedrooms,
    totalBedrooms,
    totalBathrooms,

    streetAddress,
    unitNumber,
    propertyCity,
    propertyState,
    propertyCountry,
    postalCode,

    description,

    dateAvailable,
    contactName,
    contactPhone,
    contactEmail,
    contactMethod,
    university,

    utilities,
    amenities,
    accessibilityInfo,
  } = formData;

  const onChange = (e) => {
    if (e._isAMomentObject === true) {
      setFormData({ ...formData, dateAvailable: e._d });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleCheckClick = (e) => {
    const amenitiesArray = [
      'laundryCheck',
      'dishwasherCheck',
      'cleaningServiceCheck',
      'acCheck',
      'parkingCheck',
      'furnishedCheck',
      'partiallyFurnishedCheck',
      'poolCheck',
      'gymCheck',
      'petCheck',
      'balconyCheck',
      'frontYardCheck',
      'backYardCheck',
    ];

    const utilitiesArray = [
      'heatCheck',
      'waterCheck',
      'electricityCheck',
      'wifiCheck',
      'cableCheck',
    ];

    const accessibilityArray = ['accessibilityCheck'];

    if (amenitiesArray.includes(e.target.name)) {
      if (formData['amenities'].includes(e.target.value)) {
        setFormData({
          ...formData,
          amenities: formData.amenities.filter((am) => am !== e.target.value),
        });
      } else {
        const arr = formData.amenities;
        arr.push(e.target.value);
        setFormData({ ...formData, amenities: arr });
      }
    } else if (utilitiesArray.includes(e.target.name)) {
      if (formData['utilities'].includes(e.target.value)) {
        setFormData({
          ...formData,
          utilities: formData.utilities.filter((am) => am !== e.target.value),
        });
      } else {
        const arr = formData.utilities;
        arr.push(e.target.value);
        setFormData({ ...formData, utilities: arr });
      }
    } else if (accessibilityArray.includes(e.target.name)) {
      if (formData['accessibilityInfo'].includes(e.target.value)) {
        setFormData({
          ...formData,
          accessibilityInfo: formData.accessibilityInfo.filter(
            (am) => am !== e.target.value
          ),
        });
      } else {
        const arr = formData.accessibilityInfo;
        arr.push(e.target.value);
        setFormData({ ...formData, accessibilityInfo: arr });
      }
    }
  };

  const setChecked = (nameStr) => {
    if (
      utilities.includes(nameStr) ||
      amenities.includes(nameStr) ||
      accessibilityInfo.includes(nameStr)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Card className='mb-3'>
        <FalconCardHeader title='Update Ad' titleTag='h5' />
        <CardBody>
          <Badge color={'soft-success'} className='btn-block mb-2'>
            Rental Information
          </Badge>
          <Row>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='rentalTypeSelect'>Rental Type</Label>
                <Input
                  type='select'
                  id='rentalTypeSelect'
                  value={rentalType}
                  name='rentalType'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>

                  <option value='Whole Apartment'>Whole Apartment</option>
                  <option value='Whole House'>Whole House </option>
                  <option value='Room in Shared Student Apartment'>
                    Room in Shared Student Apartment
                  </option>
                  <option value='Room in Shared Landlord Apartment'>
                    Room in Shared Landlord Apartment
                  </option>
                  <option value='Room in Shared Student House'>
                    Room in Shared Student House
                  </option>
                  <option value='Room in Shared Landlord House'>
                    Room in Shared Landlord House
                  </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='rentalTermSelect'>Rental Term</Label>
                <Input
                  type='select'
                  id='rentalTermSelect'
                  value={rentalTerm}
                  name='rentalTerm'
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='12 Month Lease'>{'12 Month Lease'}</option>
                  <option value='8 Month Lease'>{'8 Month Lease'}</option>
                  <option value='4 Month Lease'>{'4 Month Lease'}</option>
                  <option value='Short-term/Sublet/Flexible'>
                    {'Short-term/Sublet/Flexible'}
                  </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='rentalTypeSelect'>Rent Per Bedroom / Month</Label>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                  <Input
                    type='number'
                    id='rent'
                    name='rent'
                    value={rent || 0}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <InputGroupAddon addonType='append'>.00</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='availableBedrooms'>Available Bedrooms</Label>
                <Input
                  type='select'
                  name='availableBedrooms'
                  id='availableBedrooms'
                  value={availableBedrooms}
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>

                  <option value='1'>1 </option>
                  <option value='2'>2 </option>
                  <option value='3'>3 </option>
                  <option value='4'>4 </option>
                  <option value='5'>5 </option>
                  <option value='6'>6 </option>
                  <option value='7'>7 </option>
                  <option value='8'>8 </option>
                  <option value='9'>9 </option>
                  <option value='10'>10 </option>
                  <option value='11'>11 </option>
                  <option value='12'>12 </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='totalBedrooms'>Total Number of Bedrooms</Label>
                <Input
                  type='select'
                  name='totalBedrooms'
                  id='totalBedrooms'
                  value={totalBedrooms}
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='1'>1 </option>
                  <option value='2'>2 </option>
                  <option value='3'>3 </option>
                  <option value='4'>4 </option>
                  <option value='5'>5 </option>
                  <option value='6'>6 </option>
                  <option value='7'>7 </option>
                  <option value='8'>8 </option>
                  <option value='9'>9 </option>
                  <option value='10'>10 </option>
                  <option value='11'>11 </option>
                  <option value='12'>12 </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-12'>
              <FormGroup>
                <Label for='totalBathrooms'>Total Number of Bathrooms</Label>
                <Input
                  type='select'
                  name='totalBathrooms'
                  id='totalBathrooms'
                  value={totalBathrooms}
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='1'>1 </option>
                  <option value='2'>2 </option>
                  <option value='3'>3 </option>
                  <option value='4'>4 </option>
                  <option value='5'>5 </option>
                  <option value='6'>6 </option>
                  <option value='7'>7 </option>
                  <option value='8'>8 </option>
                  <option value='9'>9 </option>
                  <option value='10'>10 </option>
                  <option value='11'>11 </option>
                  <option value='12'>12 </option>
                </Input>
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='dateAvailable'>{`Date Available / Move-in Date`}</Label>
                <Datetime
                  dateFormat='MMM/DD/YYYY'
                  timeFormat={false}
                  name='dateAvailable'
                  id='dateAvailable'
                  value={new Date(dateAvailable)}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
          </Row>
          <FormGroup>
            <Label for='description'>{`Description`}</Label>
            <Input
              placeholder='Describe your property and individuals you are looking for'
              type='textarea'
              rows='4'
              spellCheck='false'
              value={description}
              name='description'
              onChange={(e) => onChange(e)}
            />
          </FormGroup>
          <Badge color={'soft-success'} className='btn-block mb-2 mt-2'>
            University
          </Badge>
          <Label>Nearest University or Collage</Label>
          <Input
            className='mb-2'
            label='courses'
            type='select'
            id='university'
            name='university'
            onChange={(e) => onChange(e)}
            required
            value={university ?? ''}
          >
            <option disabled value={''}>
              Select University
            </option>
            {universities &&
              universities.map((item, i) => {
                return (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
          </Input>
          <Badge color={'soft-success'} className='btn-block mb-2 mt-2'>
            {`Enter Property Address`}
          </Badge>
          <Row>
            <Fragment>
              <Col xs='12'>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='streetAddress'>{`Street Address / Address Line 1`}</Label>
                      <Input
                        type='text'
                        name='streetAddress'
                        id='streetAddress'
                        placeholder='1280 Main Street West'
                        value={streetAddress || ''}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='unitNumber'>{`Unit Number / Address Line 2`}</Label>
                      <Input
                        type='text'
                        name='unitNumber'
                        id='unitNumber'
                        placeholder='Unit # (If Applicable)'
                        value={unitNumber || ''}
                        onChange={(e) => onChange(e)}
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='propertyCity'>{`City`}</Label>
                      <Input
                        type='text'
                        name='propertyCity'
                        id='propertyCity'
                        placeholder='Toronto'
                        value={propertyCity || ''}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='propertyState'>{`State/Province`}</Label>
                      <Input
                        type='text'
                        name='propertyState'
                        id='propertyState'
                        placeholder='Ontario'
                        value={propertyState || ''}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='propertyCountry'>{`Country`}</Label>
                      <Input
                        placeholder='Canada'
                        type='text'
                        name='propertyCountry'
                        id='propertyCountry'
                        value={propertyCountry || ''}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
                <Row>
                  <div className='col-md-6 col-sm-12'>
                    <FormGroup>
                      <Label for='postalCode'>{`Postal Code`}</Label>
                      <Input
                        placeholder='M0M0M0'
                        type='text'
                        name='postalCode'
                        id='postalCode'
                        value={postalCode || ''}
                        onChange={(e) => onChange(e)}
                        required
                      />
                    </FormGroup>
                  </div>
                </Row>
              </Col>
            </Fragment>
          </Row>

          <Badge color={'soft-success'} className='btn-block mb-2'>
            Amenities + Additional Informaton
          </Badge>
          <Row>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-3'>
                <div className='card-body'>
                  <p className='d-inline-block'>
                    <strong className='text-dark'>Amenities</strong>
                  </p>
                  {amenitiesMap.map((am, index) => (
                    <Fragment key={index}>
                      {am.type === 'seperate' ? (
                        <hr />
                      ) : (
                        <FormGroup className='form-check mb-0'>
                          <Input
                            type='checkbox'
                            id={am.name}
                            name={am.name}
                            value={am.value}
                            checked={setChecked(am.value)}
                            onClick={(e) => {
                              handleCheckClick(e);
                            }}
                            readOnly
                          />
                          <Label for={am.name} check>
                            {am.value}
                          </Label>
                        </FormGroup>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-3'>
                <div className='card-body'>
                  <p className='d-inline-block'>
                    <strong className='text-dark'>
                      Utilities Included in Rent
                    </strong>
                  </p>
                  {utilitiesMap.map((ut, index) => (
                    <Fragment key={index}>
                      {ut.type === 'seperate' ? (
                        <hr />
                      ) : (
                        <FormGroup className='form-check mb-0'>
                          <Input
                            type='checkbox'
                            id={ut.name}
                            name={ut.name}
                            value={ut.value}
                            checked={setChecked(ut.value)}
                            onClick={(e) => {
                              handleCheckClick(e);
                            }}
                            readOnly
                          />
                          <Label for={ut.name} check>
                            {ut.value}
                          </Label>
                        </FormGroup>
                      )}
                    </Fragment>
                  ))}
                   
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-sm-12 mt-2'>
              <div className='card card-shadow px-3'>
                <div className='card-body form-check'>
                  <p className='d-inline-block'>
                    <strong className='text-dark'>Accessibility</strong>
                  </p>
                  {accessibilityMap.map((am, index) => (
                    <Fragment key={index}>
                      {am.type === 'seperate' ? (
                        <hr />
                      ) : (
                        <FormGroup className='form-check mb-0'>
                          <Input
                            type='checkbox'
                            id={am.name}
                            name={am.name}
                            value={am.value}
                            checked={setChecked(am.value)}
                            onClick={(e) => {
                              handleCheckClick(e);
                            }}
                            readOnly
                          />
                          <Label for={am.name} check>
                            {am.value}
                          </Label>
                        </FormGroup>
                      )}
                    </Fragment>
                  ))}{' '}
                   
                </div>
              </div>
            </div>
          </Row>

          <Badge color={'soft-success'} className='btn-block mb-2 mt-4'>
            Contact Information
          </Badge>
          <Row>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='dateAvailable'>Date Available</Label>
                <Datetime
                  dateFormat='MMM/DD/YYYY'
                  timeFormat={false}
                  name='dateAvailable'
                  id='dateAvailable'
                  value={new Date(dateAvailable)}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactName'>Contact Name</Label>
                <Input
                  type='text'
                  name='contactName'
                  id='contactName'
                  value={contactName || ''}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactPhone'>Contact Phone Number</Label>
                <Input
                  type='text'
                  name='contactPhone'
                  id='contactPhone'
                  value={contactPhone || ''}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactEmail'>Contact Email</Label>
                <Input
                  type='text'
                  name='contactEmail'
                  id='contactEmail'
                  value={contactEmail || ''}
                  onChange={(e) => onChange(e)}
                  required
                />
              </FormGroup>
            </div>
            <div className='col-xl-4 col-lg-6 col-sm-6'>
              <FormGroup>
                <Label for='contactMethod'>Preferred Contact Method</Label>
                <Input
                  type='select'
                  name='contactMethod'
                  id='contactMethod'
                  value={contactMethod}
                  onChange={(e) => onChange(e)}
                  required
                >
                  <option value=''>Select</option>
                  <option value='Email'>Email</option>
                  <option value='Phone'>Phone</option>
                  <option value='Text'>Text</option>
                  <option value='Either'>No Preference</option>
                </Input>
              </FormGroup>
            </div>
          </Row>
          <Badge color={'soft-success'} className='btn-block mb-3 mt-2'>
            Update Images
          </Badge>
          <UpdateHousingImages
            filesUploaded={adImgs}
            setFiles={setAdImgs}
            selectedPlan={plan}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

UpdateAdForm.defaultProps = {};

UpdateAdForm.propTypes = {
  formData: PropTypes.object.isRequired,
  plan: PropTypes.string.isRequired,
  setAdImgs: PropTypes.func.isRequired,
  adImgs: PropTypes.array.isRequired,
  setFormData: PropTypes.func.isRequired,
  universities: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(UpdateAdForm);
