export default [
  // { title: 'About', to: '#!' },
  { title: 'Contact', to: '/contact-us' },
  { title: 'Careers', to: '/careers' },
  // { title: 'Blog', to: '#!' },
  { title: 'Terms', to: '/terms/#terms' },
  { title: 'Privacy', to: '/terms/#privacy' },
  { title: 'Ambassador Program', to: '/ambassador' },
  // { title: 'Imprint', to: '#!' },
];
