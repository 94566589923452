import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// libs
import { useForm } from 'react-hook-form';
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Spinner,
} from 'reactstrap';

// components
import FalconCardHeader from '../../../common/FalconCardHeader';
import LightBoxGallery from '../../../common/LightBoxGallery';
import ButtonImgPostCreate from '../../../feed/ButtonImgPostCreate';

// static
import postImage from '../../../../../src/assets/img/illustrations/image.svg';

const UpdateClubBannerForm = ({
  clubInfo,
  changeFormField,
  onUpdateImgSubmit,
  isImgLoading,
}) => {
  // Subject to change
  const { banner } = clubInfo;

  const {
    register: registerImg,
    handleSubmit: handleImgSubmit,
    errors: errorsImg,
    getValues: getImgValues,
  } = useForm({ attachBanner: banner });
  const noNewImgs = !getImgValues().attachBanner?.[0];

  const onAddBanner = (e) => {
    changeFormField(e, 'banner', e.target.files[0]);
  };
  const dynamicBanner =
    typeof banner === 'object' ? URL.createObjectURL(banner) : banner;

  return (
    <>
      <Card className='mb-3'>
        <FalconCardHeader title='Update Club Banner' />
        <CardBody
          tag={Form}
          className='bg-white'
          onSubmit={handleImgSubmit(onUpdateImgSubmit)}
        >
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Col>
                <Row>
                  {banner ? (
                    <LightBoxGallery images={[dynamicBanner]}>
                      {(openImgIndex) => (
                        <img
                          className='img-fluid rounded mb-2 cursor-pointer'
                          src={dynamicBanner}
                          alt='banner'
                          style={{ maxHeight: 100 }}
                          onClick={() => openImgIndex(0)}
                        />
                      )}
                    </LightBoxGallery>
                  ) : null}
                </Row>
                <Row>
                  <Label>
                    <Input
                      type='file'
                      accept='image/png, image/jpeg'
                      hidden
                      name='attachBanner'
                      onChange={(e) => onAddBanner(e)}
                      innerRef={registerImg({
                        validate: {
                          fileSize: (v) => {
                            // do a check if the file exists
                            if (v && v[0]) {
                              return (
                                v[0].size / 1024 / 1024 < 2 ||
                                'File must be less than 2 MB'
                              );
                            } else {
                              return true;
                            }
                          },
                        },
                      })}
                    />
                    <ButtonImgPostCreate imgSrc={postImage}>
                      <span>{banner ? `Update Banner` : `Select Banner`}</span>
                    </ButtonImgPostCreate>
                    <Row>
                      <span>
                        <small className='text-warning'>
                          {` * (Recomended size 1000x250 image)`}
                        </small>
                      </span>
                    </Row>
                    <Row>
                      {errorsImg.attachBanner && (
                        <span>
                          <small className='text-danger'>
                            {errorsImg.attachBanner.message}
                          </small>
                        </span>
                      )}
                    </Row>
                  </Label>
                </Row>
              </Col>
            </Col>
          </Row>
          <Button
            color='falcon-primary mt-4'
            type='submit'
            disabled={noNewImgs}
            size='sm'
          >
            {isImgLoading === true ? (
              <Fragment>
                {`Updating Banner...`} <Spinner size='sm' />
              </Fragment>
            ) : (
              `Update Banner`
            )}
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

UpdateClubBannerForm.propTypes = {
  changeFormField: PropTypes.func,
  onUpdateImgSubmit: PropTypes.func,
  isImgLoading: PropTypes.bool,
  clubInfo: PropTypes.object,
};

export default UpdateClubBannerForm;
