import React, { Fragment } from 'react';

// libraries
import { Card, CardBody, Col, Row } from 'reactstrap';

// assets
import calendar from '../../../assets/img/undraw/undraw_Online_calendar_re_wk3t.svg';

const CalendarTab = () => {
  return (
    <div className='mt-3'>
      <Card>
        <CardBody className='overflow-hidden p-lg-6'>
          <Row className='align-items-center justify-content-between'>
            <Fragment>
              <Col lg={6}>
                <img src={calendar} className='img-fluid' alt='' />{' '}
              </Col>
              <Col lg={6} className='pl-lg-4 my-5 text-center text-lg-left'>
                <h3>{`Track Club Activities`}</h3>
                <p className='lead'>{`We'll Integrate all your club meetings and club events in one place!`}</p>
              </Col>
            </Fragment>{' '}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
export default CalendarTab;
