import React, { Fragment } from 'react';
import { connect } from 'react-redux';

// components
import NavbarStandard from '../navbar/NavbarStandard';
import Cta from '../landing/Cta';
import FooterStandard from '../landing/FooterStandard';
import ViewAllHousingAds from './ViewAllHousingAds';

const HousingLayoutPub = (props) => {
  return (
    <Fragment>
      <div>
        <NavbarStandard useDark={true} />
        <div className={'container mt-7 mb-4'}>
          <ViewAllHousingAds userViewingFrom={'public'} />
        </div>
        <Cta />
        <FooterStandard />
      </div>
    </Fragment>
  );
};

// export default HousingLayoutPub;

HousingLayoutPub.defaultProps = { isLast: false };

HousingLayoutPub.propTypes = {};

export default connect(null, {})(HousingLayoutPub);
