import { SET_REFER_ID } from '../types';

const initialState = {
  referId: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REFER_ID:
      return {
        ...state,
        referId: payload.referId,
      };
    default:
      return state;
  }
}
