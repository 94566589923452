import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useGetEvents_Query = () => {
  return useQuery(
    'allEvents',
    () =>
      axios
        .get('/api/v2/event/all-events')
        .then((res) => res.data.dataPayload.allEvents),
    {
      cacheTime: minutesToMiliseconds(600),
      staleTime: minutesToMiliseconds(600),
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetMyEvents_Query = () => {
  return useQuery(
    'myEvents',
    () =>
      axios
        .get('/api/v2/event/my-events')
        .then((res) => res.data.dataPayload.myEvents),
    {
      cacheTime: minutesToMiliseconds(600),
      staleTime: minutesToMiliseconds(600),
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetEventById_Query = (eventId) => {
  return useQuery(
    ['event', eventId],
    () =>
      axios
        .get(`/api/v2/event/${eventId}`)
        .then((res) => res.data.dataPayload.event),
    {
      cacheTime: minutesToMiliseconds(200),
      staleTime: minutesToMiliseconds(100),
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

const createNewEvent = async (input) => {
  const newOb = {
    ...input,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios.post('/api/v2/event/create-event', newOb, config);

  return res;
};

export const useCreateNewEvent_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => createNewEvent(inputData), {
    onSuccess: async (data, inputData) => {
      axiosSuccessToast(data.res);
      await QueryClient.refetchQueries(['myEvents']);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const updateEventInfo = async (eventInfo) => {
  const res = await axios.put(`/api/v2/event/${eventInfo.id}`, eventInfo.body);
  return { res: res, eventId: eventInfo.id };
};

export const useUpdateEvent_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((eventInfo) => updateEventInfo(eventInfo), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);

      await Promise.all([
        QueryClient.invalidateQueries(['event', data.eventId]),
        QueryClient.invalidateQueries(['myEvents']),
        QueryClient.invalidateQueries(['allEvents']),
      ]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const deleteEvent = async (eventId) => {
  const res = await axios.delete(`/api/v2/event/${eventId}`);
  return { res: res, eventId };
};

export const useDeleteEvent_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation((id) => deleteEvent(id), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      await Promise.all([
        QueryClient.invalidateQueries(['event', data.eventId]),
        QueryClient.invalidateQueries(['myEvents']),
        QueryClient.invalidateQueries(['allEvents']),
      ]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};
