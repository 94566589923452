import { secretbox } from 'tweetnacl';
import { decodeBase64, encodeUTF8 } from 'tweetnacl-util';

export const decryptMidNidParams = (mid, nid) => {
  try {
    //btoa - decode url safe params
    const msg = Buffer.from(mid, 'base64').toString();
    const nonce = Buffer.from(nid, 'base64').toString();

    // get secretKey to decrypt
    const secretKey = Buffer.from(process.env.REACT_APP_URL_ENC_KEY, 'utf8');

    // decrypt string
    const decryptedStr = secretbox.open(
      decodeBase64(msg),
      decodeBase64(nonce),
      secretKey
    );

    // decrypt function return string, we want object
    const objStr = encodeUTF8(decryptedStr);

    // parse string and gett object
    const finalObj = JSON.parse(objStr);
    return finalObj;
  } catch {
    return false;
  }
};

export const decryptEmailOpenedParams = (eid) => {
  try {
    //btoa - decode url safe params
    const decodedEid = decodeURLSafeToStr(eid);
    const splitArr = decodedEid.split('::::::');

    // parse string and gett object
    if (splitArr.length === 1) {
      return { emailId: splitArr[0] };
    } else if (splitArr.length === 2) {
      return { emailId: splitArr[0], email: splitArr[1] };
    }
    return false;
  } catch {
    return false;
  }
};

export const decryptCampaignTrackerParams = (eid) => {
  try {
    //btoa - decode url safe params
    const decodedTracker = decodeURLSafeToStr(eid);

    return JSON.parse(decodedTracker);
  } catch {
    return false;
  }
};

export const decodeURLSafeToStr = (str) => {
  //btoa
  const originalStr = Buffer.from(str, 'base64').toString();
  return originalStr;
};
