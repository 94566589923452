import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// librarires
import { Spinner, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';

// queries
import { useGetMyFriends_Query } from '../../../../queries/socialQueries';
import { useSendPostToPeerQuery } from '../../../../queries/postQueries';

const SendToPeerForm = ({
  // functions
  closeModal,

  // data
  postId,
}) => {
  const {
    data: myFriends,
    isLoading: friendsLoading,
    isError: friendsError,
  } = useGetMyFriends_Query();

  const { register, handleSubmit, errors } = useForm({
    peerId: '',
  });

  const { mutate: sendPostToPeer } = useSendPostToPeerQuery();

  const handleFormSubmit = (data) => {
    sendPostToPeer({ ...data, postId });
    closeModal();
  };

  return (myFriends === undefined && friendsError === false) ||
    friendsLoading ? (
    <Fragment>
      <Spinner />
    </Fragment>
  ) : (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormGroup>
        <Label>
          {`Select Friend`}
          <span>
            <small className='text-warning'> {` * required`}</small>
          </span>
        </Label>
        <Input
          label='Select Friend'
          name='peerId'
          placeholder='Select Friend'
          type='select'
          innerRef={register({
            required: true,
          })}
        >
          {
            <Fragment>
              <option value={''} disabled>
                {`Select Friend`}
              </option>
              ;
              {myFriends.map?.((friend, i) => {
                return (
                  <option key={i} value={friend.user._id}>
                    {friend.user.name}
                  </option>
                );
              })}
            </Fragment>
          }
        </Input>
        <span>
          <small className='text-danger'>
            {errors.friend && 'Must Select a Friend'}
          </small>
        </span>
      </FormGroup>
      <Button type='submit' color='primary'>
        {' '}
        Send Post{' '}
      </Button>
    </Form>
  );
};

SendToPeerForm.propTypes = {
  // functions
  closeModal: PropTypes.func.isRequired,

  // data
  postId: PropTypes.string.isRequired,
};

export default SendToPeerForm;
