import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useGetAllClubs_Query = () => {
  return useQuery(
    ['clubs'],
    () =>
      axios
        .get(`api/v2/club/search/`)
        .then((res) => res.data.dataPayload.clubs),
    {
      cacheTime: minutesToMiliseconds(20),
      staleTime: minutesToMiliseconds(10),
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetClubById_Query = (clubId, isPublicView, clubName) => {
  return useQuery(
    ['clubs', clubId],
    () =>
      axios
        .get(
          isPublicView === true
            ? `/api/v2/club/public/nameview/${clubName}`
            : `/api/v2/club/view/${clubId}`
        )
        .then((res) => res.data.dataPayload.clubObj),
    {
      cacheTime: minutesToMiliseconds(20),
      staleTime: minutesToMiliseconds(10),
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

const updateFormData = (clubInfo) => {
  const formData = new FormData();
  for (var key of Object.keys(clubInfo)) {
    if (key === 'socialInfo') {
      formData.append(key, JSON.stringify(clubInfo[key]));
      continue;
    } else {
      formData.append(key, clubInfo[key]);
    }
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return { formData, config };
};

const createFormData = (clubInfo) => {
  const formData = new FormData();

  formData.append('logo', clubInfo.logo);
  delete clubInfo.logo;

  formData.append('banner', clubInfo.banner);
  delete clubInfo.banner;

  for (var key of Object.keys(clubInfo)) {
    if (key === 'socialInfo') {
      formData.append(key, JSON.stringify(clubInfo[key]));
      continue;
    } else {
      formData.append(key, clubInfo[key]);
    }
  }
  const config = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };
  return { formData, config };
};

const createClub = async (clubInfo) => {
  const { formData, config } = createFormData(clubInfo);
  const res = await axios.post('/api/v2/club/create', formData, config);
  return res.data.dataPayload.club;
};
export const useCreateClub_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((clubInfo) => createClub(clubInfo), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      await QueryClient.invalidateQueries(['clubs']);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const updateClub = async (data) => {
  const { formData, config } = await updateFormData(data);

  const res = await axios.put(
    `/api/v2/club/update/${data.clubId}`,
    formData,
    config
  );
  return { res, clubId: data.clubId };
};

export const useUpdateClub_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((clubInfo) => updateClub(clubInfo), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      await Promise.all([
        QueryClient.invalidateQueries(
          ['clubs'],
          QueryClient.invalidateQueries(['clubs', data.clubId])
        ),
      ]);
    },
    onError: (err) => {
      axiosErrorToast(err);
    },
  });
};

const updateBanner = async (clubInfo) => {
  const bannerFormData = new FormData();
  let res;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  if (clubInfo.banner) {
    bannerFormData.append('banner', clubInfo.banner);
    res = await axios.post(
      `/api/v2/club/image/banner/${clubInfo.clubId}`,
      bannerFormData,
      config
    );
  }
  return { res, clubId: clubInfo.clubId };
};

const updateLogo = async (clubInfo) => {
  const logoFormData = new FormData();
  let res;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  if (clubInfo.logo) {
    logoFormData.append('logo', clubInfo.logo);
    res = await axios.post(
      `/api/v2/club/image/logo/${clubInfo.clubId}`,
      logoFormData,
      config
    );
  }
  return { res, clubId: clubInfo.clubId };
};

export const useUpdateBanner_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((clubInfo) => updateBanner(clubInfo), {
    onSuccess: async (data) => {
      axiosSuccessToast('Image(s) updated');
      const newClubData = data.res.data.dataPayload.updatedClub;
      await QueryClient.cancelQueries(['clubs', data.clubId]);
      const oldClubData = QueryClient.getQueryData(
        ['clubs', data.clubId],
        newClubData
      );
      await QueryClient.setQueryData(['clubs', data.clubId], {
        ...oldClubData,
        clubBanner: newClubData.clubBanner,
      });
    },
    onError: (err) => {
      axiosErrorToast(err);
    },
  });
};

export const useUpdateLogo_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((clubInfo) => updateLogo(clubInfo), {
    onSuccess: async (data) => {
      axiosSuccessToast('Image(s) updated');
      const newClubData = data.res.data.dataPayload.updatedClub;
      await QueryClient.cancelQueries(['clubs', data.clubId]);
      const oldClubData = QueryClient.getQueryData(
        ['clubs', data.clubId],
        newClubData
      );
      await QueryClient.setQueryData(['clubs', data.clubId], {
        ...oldClubData,
        logo: newClubData.logo,
      });
    },
    onError: (err) => {
      axiosErrorToast(err);
    },
  });
};

const createClubQAndA = async (data) => {
  const res = await axios.post(`/api/v2/club/qanda/${data.clubId}`, data.QandA);
  return { res, clubId: data.clubId };
};

export const useCreateClubQAndA_Mutation = () => {
  const QueryClient = useQueryClient();
  return useMutation((data) => createClubQAndA(data), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      await QueryClient.invalidateQueries(['clubs', data.clubId]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

// ---------- JOIN REQUESTS BY USER TO CLUB ADMIN -----------
// '/request/send/:clubId' - used by regular user to send request to join a club admin
export const useJoinClub_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (clubId) =>
      axios.put(`/api/v2/club/request/send/${clubId}`).then((res) => {
        return { res, clubId };
      }),
    {
      onSuccess: async (data) => {
        await Promise.all([
          QueryClient.invalidateQueries(['clubs', data.clubId]),
          QueryClient.invalidateQueries(['myClubs']),
        ]);
        axiosSuccessToast(data.res);
      },
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

// '/request/recind/:clubId' - used by regular user to recind request to join a club
export const useRecindClubRequest_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (clubId) =>
      axios.put(`/api/v2/club/request/recind/${clubId}`).then((res) => {
        return { res, clubId };
      }),
    {
      onSuccess: async (data) => {
        await Promise.all([
          QueryClient.invalidateQueries(['clubs', data.clubId]),
          QueryClient.invalidateQueries(['myClubs']),
        ]);
        axiosSuccessToast(data.res);
      },
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useGetAllClubJoinRequests_Query = (clubId) => {
  return useQuery(
    ['requestsAndInvites', clubId],
    () =>
      axios
        .get(`/api/v2/club/invitesandrequests/${clubId}`)
        .then((res) => res.data.dataPayload.clubData),
    {
      cacheTime: minutesToMiliseconds(20),
      staleTime: minutesToMiliseconds(10),
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

// '/request/accept/:clubId' - used by admin to accept request to join a club
const acceptClubRequests = async (clubData) => {
  const { clubId, requestingUserId } = clubData;

  const res = await axios.put(`/api/v2/club/request/accept/${clubId}`, {
    requestingUserId,
  });
  return { res, clubId };
};
export const useAcceptClubRequestByAdmin_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation((clubData) => acceptClubRequests(clubData), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      await Promise.all([
        QueryClient.invalidateQueries(['requestsAndInvites', data.clubId]),
        QueryClient.invalidateQueries(['clubs', data.clubId]),
      ]);
    },
    retry: false,
    onError: (err) => axiosErrorToast(err),
  });
};

// '/request/decline/:clubId' - used by admin to decline request to join a club
const rejectInviteAsAdmin = async (clubData) => {
  const { clubId, requestingUserId } = clubData;
  const res = await axios.put(`/api/v2/club/request/decline/${clubId}`, {
    requestingUserId,
  });
  return { res, clubId };
};

export const useRejectClubRequestByAdmin_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation((clubData) => rejectInviteAsAdmin(clubData), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      await Promise.all([
        QueryClient.invalidateQueries(['requestsAndInvites', data.clubId]),
        QueryClient.invalidateQueries(['clubs', data.clubId]),
      ]);
    },
    retry: false,
    onError: (err) => {
      axiosErrorToast(err);
    },
  });
};

// ---------- INVITES BY ADMIN TO USER ----------
// '/invite/send/:clubId' - used by admin to send invite to user
// '/invite/recind/:clubId' - used by admin to recind invite to user

// '/invite/accept/:clubId' - used by regular user who got an invite from admin
export const useAcceptClubInvite_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (clubId) =>
      axios.put(`/api/v2/club/invite/accept/${clubId}`).then((res) => {
        return { res, clubId };
      }),
    {
      onSuccess: async (data) => {
        axiosSuccessToast(data.res);
        await Promise.all([
          QueryClient.refetchQueries(['clubs', data.clubId]),
          QueryClient.invalidateQueries(['myClubs']),
        ]);
      },
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};
// '/invite/decline/:clubId' - used by regular user who got an invite from admin
export const useDeclineClubInvite_Query = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (clubId) =>
      axios.put(`/api/v2/club/decline/accept/${clubId}`).then((res) => {
        return { res, clubId };
      }),
    {
      onSuccess: async (data) => {
        axiosSuccessToast(data.res);
        await Promise.all([
          QueryClient.refetchQueries(['clubs', data.clubId]),
          QueryClient.invalidateQueries(['myClubs']),
        ]);
      },
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

// get my clubs
const getMyClubs = async () => {
  const res = await axios.get(`/api/v2/userSettings/myclubs`);
  const { clubsImInvitedTo, clubsIveRequestedToJoin, myClubs } =
    res.data.dataPayload.userSettings;
  return { clubsImInvitedTo, clubsIveRequestedToJoin, myClubs };
};

export const useGetMyClubs_Query = () => {
  return useQuery(['myClubs'], () => getMyClubs(), {
    onError: (err) => axiosErrorToast(err),
    retry: false,
    cacheTime: minutesToMiliseconds(20),
    staleTime: minutesToMiliseconds(10),
  });
};

const createNewClubEvent = async (input) => {
  const newOb = input.finalEventInfo;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios.post(
    `/api/v2/club/event/${input.clubId}`,
    newOb,
    config
  );

  return { res, clubId: input.clubId };
};

export const useCreateNewClubEvent_Mutation = () => {
  const QueryClient = useQueryClient();

  return useMutation((inputData) => createNewClubEvent(inputData), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
      // await QueryClient.refetchQueries(['myClubs']);
      await QueryClient.refetchQueries(['clubs', data.clubId]);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const enrollStudentsToClub = async (input) => {
  const newOb = {
    name: input.name,
    email: input.email,
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios.post(
    `/api/v2/club/misc/enroll/${input.clubName}`,
    newOb,
    config
  );

  return { res, clubId: input.clubId };
};
export const useRegisterAndEnrollToClub_Mutation = () => {
  return useMutation((inputData) => enrollStudentsToClub(inputData), {
    onSuccess: async (data) => {
      axiosSuccessToast(data.res);
    },
    onError: (err) => axiosErrorToast(err),
  });
};

const deleteClubEvent = async ({ eventId, clubId }) => {
  const res = axios.delete(`/api/v2/club/event/${clubId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      eventId: eventId,
    },
  });

  return { res, clubId: clubId, eventId: eventId };
};

export const useDeleteClubEvent_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    ({ clubId, eventId }) => deleteClubEvent({ clubId, eventId }),
    {
      onSuccess: async (data) => {
        axiosSuccessToast(data.res);
        await Promise.all([
          QueryClient.removeQueries(['event', data.eventId]),
          QueryClient.refetchQueries(['clubs', data.clubId]),
        ]);
      },
      onError: (err) => axiosErrorToast(err),
    }
  );
};
