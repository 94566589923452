import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import posthog from 'posthog-js';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

// components
import CheckEmailAuth from '../auth/CheckEmailAuth';
import CheckProfileSetup from '../auth/CheckProfileSetup';
import EmptyPageForAuthFailure from '../auth/EmptyPageForAuthFailure';
import { setAiUser } from '../../AppInsights';

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, userSetupStage, loading, user },
  ...rest
}) => {
  const [loadingCount, setLoadingCount] = useState(1);

  if (user) {
    posthog.identify(user._id, { email: user.email });
    setAiUser(user._id.toString());
  }

  useEffect(() => {
    setLoadingCount(loadingCount + 1);
  }, [loading]);

  // 1 - if loading show spinner
  if (loading === true) {
    return (
      <div className='mt-4 text-center'>
        <Spinner color='warning' />
      </div>
    );
  }
  // 2 - is user data is available, reroute accordingly
  else if (isAuthenticated === true) {
    if (user && user.role.includes('student')) {
      //set up for students
      if (userSetupStage) {
        if (userSetupStage === 'email') {
          toast(`Please confirm your email before continuing.`, {
            toastId: 'confirmEmail',
          });
          return <CheckEmailAuth />;
        } else if (userSetupStage === 'profile') {
          toast(`Please complete your profile before continuing.`, {
            toastId: 'completeProfile',
          });
          return <CheckProfileSetup />;
        } else if (userSetupStage === 'done') {
          return <Component {...rest} />;
        } else {
          return <EmptyPageForAuthFailure />;
        }
      } else {
        return (
          <div className='mt-4 text-center'>
            <Spinner color='info' />
          </div>
        );
      }
    } else if (user && user.role.includes('landlord')) {
      return <Component {...rest} />;
    } else {
      return (
        <div className='mt-4 text-center'>
          <Spinner color='danger' />
        </div>
      );
    }
  }

  // 3 - wait for page to render atlease once before redirecting
  else {
    if (loadingCount >= 2) {
      return <Redirect to='/authentication/login' />;
    }
    return <div />;
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

export default connect(mapStateToProps)(PrivateRoute);
