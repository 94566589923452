import React from 'react';

// libraries
import { Col, Row } from 'reactstrap';

// components
import ProfileForm from './ProfileForm';
import Section from '../../../common/Section';
import AuthWizardProvider from '../AuthWizardProvider';

const SetUpProfile = () => {
  return (
    <Section className='py-0'>
      <Row className='flex-center '>
        <div className='container mt-4'>
          <h3 className='large text-dark'>2. Let's set up your profile!</h3>
          <hr />
          <div>
            <h6>Lets set up your profile!</h6>
          </div>{' '}
        </div>
        <Col sm={10} lg={8} className='col-xxl-5'>
          <AuthWizardProvider>
            <ProfileForm />
          </AuthWizardProvider>
        </Col>
      </Row>
    </Section>
  );
};

export default SetUpProfile;
