import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';

// misc
import { minutesToMiliseconds } from '../utils/timeFunctions';
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useGetMyNotifications_Query = () => {
  return useQuery(
    ['myNotifications'],
    () =>
      axios.get(`/api/v1/noti/recentnotis`).then((res) => res.data.datapayload),
    {
      cacheTime: minutesToMiliseconds(10),
      staleTime: minutesToMiliseconds(20),
      retry: false,
      onError: (err) => axiosErrorToast(err),
    }
  );
};

export const useMarkAllNotificationsAsRead_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v1/noti/markallread`, inputData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.data.datapayload),
    {
      onSuccess: async (data, inputData) => {
        axiosSuccessToast(data.res);
      },
      onMutate: async (inputData) => {
        // 1. Cancel all fetch queires related to notifications
        await QueryClient.cancelQueries(['myNotifications']);

        // 2. Get the current data present for this group
        const oldNotificationData = QueryClient.getQueryData([
          'myNotifications',
        ]);

        // 3. Mark all notifications as read
        const newNotificationData = oldNotificationData.map((noti) => {
          noti.status = 'read';
          return noti;
        });

        // 4. Optimitstically update the notifications, treating the action as if was successful
        QueryClient.setQueryData(['myNotifications'], newNotificationData);

        // 4. Return some context data for handling failures
        return { oldNotificationData, inputData };
      },
      onError: (err, inputData, context) => {
        // if the action fails, reset the data back from the optimistic data to the current data
        QueryClient.setQueryData(
          ['myNotifications'],
          context.oldNotificationData
        );

        axiosErrorToast(err);
      },
    }
  );
};

export const useMarkNotificationAsRead_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v1/noti/markread/${inputData.notificationId}`)
        .then((res) => res.data.datapayload),
    {
      onMutate: async (inputData) => {
        // 1. Cancel all fetch queires related to notifications
        await QueryClient.cancelQueries(['myNotifications']);

        // 2. Get the current data present for this group
        const oldNotificationData = QueryClient.getQueryData([
          'myNotifications',
        ]);

        // 3. Mark all notifications as read
        const newNotificationData = oldNotificationData.map((noti) => {
          if (noti._id === inputData.notificationId) {
            noti.status = 'read';
          }
          return noti;
        });

        // 4. Optimitstically update the notifications, treating the action as if was successful
        QueryClient.setQueryData(['myNotifications'], newNotificationData);

        // 4. Return some context data for handling failures
        return { oldNotificationData, inputData };
      },
      onError: (err, inputData, context) => {
        // if the action fails, reset the data back from the optimistic data to the current data
        QueryClient.setQueryData(
          ['myNotifications'],
          context.oldNotificationData
        );

        axiosErrorToast(err);
      },
    }
  );
};
