import posthog from 'posthog-js';
import { appInsights } from '../../AppInsights';

export const posthog_viewResource_Event = (resource, user) => {
  if (
    user &&
    user._id &&
    resource &&
    resource._id &&
    resource.resourceName &&
    resource.type &&
    resource.course &&
    resource.course._id
  ) {
    posthog.capture('viewResource', {
      resourceViewed___resourceId: resource._id,
      resourceViewed___resourceName: resource.resourceName,
      resourceViewed___resourceType: resource.type,
      resourceViewed___courseId: resource.course._id,
      userId: user._id,
    });

    appInsights.trackEvent(
      { name: 'viewResource' },
      {
        resourceViewed___resourceId: resource._id,
        resourceViewed___resourceName: resource.resourceName,
        resourceViewed___resourceType: resource.type,
        resourceViewed___courseId: resource.course._id,
        userId: user._id,
      }
    );
  }
};

export const posthog_submitResource_Event = (
  course,
  university,
  filesUploaded,
  user
) => {
  if (user && user._id && course && university && filesUploaded) {
    posthog.capture('submitResources', {
      submitResources___course: course,
      submitResources___university: university,
      submitResources___numberResources: filesUploaded.length,
      userId: user._id,
    });
  }
};

export const posthog_viewResourcePublic_Event = (resource) => {
  if (
    resource &&
    resource._id &&
    resource.resourceName &&
    resource.type &&
    resource.course &&
    resource.course._id
  ) {
    posthog.capture('viewResourcePublic', {
      resourceViewed___resourceId: resource._id,
      resourceViewed___resourceName: resource.resourceName,
      resourceViewed___resourceType: resource.type,
      resourceViewed___courseId: resource.course._id,
    });

    appInsights.trackEvent(
      { name: 'viewResourcePublic' },
      {
        resourceViewed___resourceId: resource._id,
        resourceViewed___resourceName: resource.resourceName,
        resourceViewed___resourceType: resource.type,
        resourceViewed___courseId: resource.course._id,
      }
    );
  }
};
