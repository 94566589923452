import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// libraries
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
} from 'reactstrap';
import Modal from 'react-bootstrap/Modal';

// actions
import { contactClubLoggedIn } from '../../../redux/actions/otherActions';

const ContactClubModal = ({
  clubId,

  // actions/reducer state
  user,
  contactClubLoggedIn,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const [formData, setFormData] = useState({
    message: '',
    name: user?.name ?? '',
    email: user?.email ?? '',
  });
  const { message, name, email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      message,
      email,
      name,
      clubId,
    };
    contactClubLoggedIn(data);
    setFormData({
      message: '',
      name: '',
      email: '',
    });
  };

  useEffect(() => {
    setIsDisabled(!message);
  }, [message]);
  return (
    <Fragment>
      <Modal.Header closeButton className='bg-light'>
        <Modal.Title>Contact Club (Email)</Modal.Title>
      </Modal.Header>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Your Name</Label>
              <Input
                placeholder={'Name'}
                value={name}
                type='message'
                name='name'
                onChange={(e) => onChange(e)}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Your Email address</Label>
              <Input
                placeholder={'Email address'}
                value={email}
                type='email'
                name='email'
                onChange={(e) => onChange(e)}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>{`Message`} </Label>

              <Input
                // className='resize-none'
                placeholder='Enter message or question you would like to ask the club'
                type='textarea'
                rows='4'
                spellCheck='false'
                value={message}
                name='message'
                onChange={(e) => onChange(e)}
              />
            </FormGroup>

            <FormGroup>
              <Button
                color='success'
                block
                className='mt-3'
                disabled={isDisabled}
                onClick={(e) => handleSubmit(e)}
              >
                Send
              </Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

ContactClubModal.propTypes = {
  user: PropTypes.object.isRequired,
  contactClubLoggedIn: PropTypes.func.isRequired,
  clubId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps, { contactClubLoggedIn })(
  React.memo(ContactClubModal)
);
