import axios from 'axios';
import { useMutation, useQueryClient, useQuery } from 'react-query';

// misc
import { axiosErrorToast, axiosSuccessToast } from '../utils/toastHandler';

export const useSendFriendRequest_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v2/socialSettings/send/${inputData.userId}`)
        .then((res) => res),
    {
      onSuccess: async (res, inputData) => {
        axiosSuccessToast(res);

        // get target's profile
        await QueryClient.cancelQueries(['profile', inputData.userId]);
        const oldProfileData = QueryClient.getQueryData([
          'profile',
          inputData.userId,
        ]);

        if (oldProfileData !== undefined && oldProfileData !== null) {
          const updatedProfileData = {
            ...oldProfileData,
            social: { ...oldProfileData.state, sentRequest: true },
          };
          QueryClient.setQueryData(
            ['profile', inputData.userId],
            updatedProfileData
          );
        }
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useCancelFriendRequest_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v2/socialSettings/cancel/${inputData.userId}`)
        .then((res) => res),
    {
      onSuccess: async (res, inputData) => {
        axiosSuccessToast(res);

        // get target's profile
        await QueryClient.cancelQueries(['profile', inputData.userId]);
        const oldProfileData = QueryClient.getQueryData([
          'profile',
          inputData.userId,
        ]);

        if (oldProfileData !== undefined && oldProfileData !== null) {
          const updatedProfileData = {
            ...oldProfileData,
            social: { ...oldProfileData.state, sentRequest: false },
          };
          QueryClient.setQueryData(
            ['profile', inputData.userId],
            updatedProfileData
          );
        }
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useAcceptFriendRequest_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v2/socialSettings/accept/${inputData.userId}`)
        .then((res) => res),
    {
      onSuccess: async (res, inputData) => {
        axiosSuccessToast(res);

        // get target's profile
        await QueryClient.cancelQueries(['profile', inputData.userId]);
        const oldProfileData = QueryClient.getQueryData([
          'profile',
          inputData.userId,
        ]);

        if (oldProfileData !== undefined && oldProfileData !== null) {
          const updatedProfileData = {
            ...oldProfileData,
            social: {
              ...oldProfileData.state,
              gotRequest: false,
              isFriend: true,
            },
          };
          QueryClient.setQueryData(
            ['profile', inputData.userId],
            updatedProfileData
          );
        }
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useUnfriendUser_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v2/socialSettings/unfriend/${inputData.userId}`)
        .then((res) => res),
    {
      onSuccess: async (res, inputData) => {
        axiosSuccessToast(res);

        // get target's profile
        await QueryClient.cancelQueries(['profile', inputData.userId]);
        const oldProfileData = QueryClient.getQueryData([
          'profile',
          inputData.userId,
        ]);

        if (oldProfileData !== undefined && oldProfileData !== null) {
          const updatedProfileData = {
            ...oldProfileData,
            social: {
              ...oldProfileData.state,
              isFriend: false,
            },
          };
          QueryClient.setQueryData(
            ['profile', inputData.userId],
            updatedProfileData
          );
        }
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useRejectFriendRequest_Query = () => {
  const QueryClient = useQueryClient();

  return useMutation(
    (inputData) =>
      axios
        .put(`/api/v2/socialSettings/deny/${inputData.userId}`)
        .then((res) => res),
    {
      onSuccess: async (res, inputData) => {
        axiosSuccessToast(res);

        // get target's profile
        await QueryClient.cancelQueries(['profile', inputData.userId]);
        const oldProfileData = QueryClient.getQueryData([
          'profile',
          inputData.userId,
        ]);

        if (oldProfileData !== undefined && oldProfileData !== null) {
          const updatedProfileData = {
            ...oldProfileData,
            social: {
              ...oldProfileData.state,
              gotRequest: false,
              isFriend: false,
            },
          };
          QueryClient.setQueryData(
            ['profile', inputData.userId],
            updatedProfileData
          );
        }
      },
      onError: (err) => {
        axiosErrorToast(err);
      },
    }
  );
};

export const useGetMyFriends_Query = () => {
  return useQuery(
    ['myfriends'],
    () =>
      axios
        .get(`/api/v2/socialSettings/myfriends`)
        .then((res) => res.data.dataPayload.friends),
    {
      onError: (err) => axiosErrorToast(err),
    }
  );
};
