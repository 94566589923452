import axios from 'axios';
import {
  GET_POSTS,
  UPDATE_REACTIONS,
  DELETE_POST,
  ADD_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_POST_WITH_COMMENTS,
  SET_POST_REDUCER_LOADING,
  OPEN_POST_REACTIONS,
} from '../types';
import { toast } from 'react-toastify';

// utils
import { axiosErrorToast } from '../../utils/toastHandler';

// Get post
export const getPostWithComments = (id, page) => async (dispatch) => {
  dispatch({
    type: SET_POST_REDUCER_LOADING,
    payload: {},
  });
  try {
    const res = page
      ? await axios.get(`/api/v2/post/c/${id}?page=${page}`)
      : await axios.get(`/api/v2/post/c/${id}`);
    dispatch({
      type: GET_POST_WITH_COMMENTS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Add comment
export const addComment = (postId, input) => async (dispatch) => {
  const formData = new FormData();
  // append all the files with key 'file' to the formdata
  // they will appear in an array called files in the BE
  input.imageFiles.map((f) => formData.append('file', f));
  formData.append('postContent', input.commentContent);
  formData.append('postText', input.commentText);
  formData.append('isAnon', input.isAnon);

  if (input.commentText.length > 0) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(
        `/api/v2/post/comment/${postId}`,
        formData,
        config
      );

      dispatch({
        type: ADD_COMMENT,
        payload: res.data.dataPayload.newComment,
      });
      toast.success(`Comment Added`);
    } catch (err) {
      axiosErrorToast(err);
    }
  } else {
    toast.warning(`No content in your comment`);
  }
};

// Delete comment
export const deleteComment = (commentId) => async (dispatch) => {
  try {
    await axios.delete(`/api/v2/post/comment/${commentId}`);
    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId,
    });

    toast.info(`Comment Removed`);
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Add like
export const clickReaction = (id, inputType) => async (dispatch) => {
  const newReaction = { type: inputType };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.put(
      `/api/v2/post/react/${id}`,
      newReaction,
      config
    );
    dispatch({
      type: UPDATE_REACTIONS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Delete post
export const deletePost = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/v2/post/dp/${id}`);

    dispatch({
      type: DELETE_POST,
      payload: id,
    });

    toast.info(`Post Removed`);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const getDiscoverablePostsForMyFeed = (pageNum) => async (dispatch) => {
  dispatch({
    type: SET_POST_REDUCER_LOADING,
    payload: {},
  });
  try {
    const page = pageNum ? pageNum : 1;
    const res = await axios.get(`/api/v2/post/page/${page}`);

    dispatch({
      type: GET_POSTS,
      payload: res.data.dataPayload.posts,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Add post
export const addPost_Action = (input) => async (dispatch) => {
  const formData = new FormData();
  // append all the files with key 'file' to the formdata
  // they will appear in an array called files in the BE
  input.imageFiles.map((f) => formData.append('file', f));
  formData.append('postContent', input.postContent);
  formData.append('postText', input.postText);
  formData.append('postLength', input.postLength);
  formData.append('isAnon', input.isAnon);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post('/api/v2/post/cp', formData, config);
    dispatch({
      type: ADD_POST,
      payload: res.data.dataPayload.newPost,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Add post
export const addPostToGroup_Action = (feedId, input) => async (dispatch) => {
  const formData = new FormData();
  // append all the files with key 'file' to the formdata
  // they will appear in an array called files in the BE
  input.imageFiles.map((f) => formData.append('file', f));
  formData.append('postContent', input.postContent);
  formData.append('postType', 'post');
  formData.append('postText', input.postText);

  // FORM DATA TURNS BOOLEAN to STRING TYPE - isAnon is now string
  formData.append('isAnon', input.isAnon);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post(`/api/v2/post/fg/${feedId}`, formData, config);

    dispatch({
      type: ADD_POST,
      payload: res.data.dataPayload.newPost,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Add club post
export const addClubPostToGroup_Action =
  (feedId, input, clubId) => async (dispatch) => {
    const formData = new FormData();
    // append all the files with key 'file' to the formdata
    // they will appear in an array called files in the BE
    input.imageFiles.map((f) => formData.append('imageFiles', f));
    formData.append('postContent', input.postContent);
    formData.append('postType', 'post');
    formData.append('postText', input.postText);
    formData.append('feedId', feedId);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(
        `/api/v2/club/feed/postasadmin/${clubId}`,
        formData,
        config
      );

      dispatch({
        type: ADD_POST,
        payload: res.data.dataPayload.post,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  };

// Add like
export const getUsersWhoReacted_Action = (postId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/v2/post/getReactingUsers/${postId}`);

    dispatch({
      type: OPEN_POST_REACTIONS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const addHomeworkHelpPost_Action =
  (courseId, input) => async (dispatch) => {
    const formData = new FormData();
    // append all the files with key 'file' to the formdata
    input.imageFiles.map((f) => formData.append('file', f));
    formData.append('postContent', input.postContent);
    formData.append('postType', 'post');
    formData.append('postText', input.postText);

    formData.append('isAnon', input.isAnon);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(
        `/api/v2/course/homeworkhelp/${courseId}`,
        formData,
        config
      );

      dispatch({
        type: ADD_POST,
        payload: res.data.dataPayload.newPost,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  };
