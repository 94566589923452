import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// libraries
import { Link } from 'react-router-dom';
import { Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';

// components
import Divider from '../common/Divider';
import RedirectUser from '../layouts/RedirectUser';

// actions
import { login } from '../../redux/actions/authActions';

const LoginForm = ({
  hasLabel,
  login,
  isAuthenticated,
  // vars for UI
  showSignUpLink,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);

    setFormData({
      email: '',
      password: '',
    });
  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  if (isAuthenticated) {
    // return <Redirect to='/feed' />;
    return <RedirectUser />;
  }

  return (
    <Fragment>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          {hasLabel && <Label>Email address</Label>}
          <Input
            placeholder={!hasLabel ? 'Email address' : ''}
            value={email}
            // onChange={({ target }) => setEmail(target.value)}
            type='email'
            // new stuff
            name='email'
            // value={email}
            onChange={(e) => onChange(e)}
            required
          />
        </FormGroup>
        <FormGroup>
          {hasLabel && <Label>Password</Label>}
          <Input
            placeholder={!hasLabel ? 'Password' : ''}
            value={password}
            // onChange={({ target }) => setPassword(target.value)}
            type='password'
            // new stuff
            name='password'
            // value={password}
            onChange={(e) => onChange(e)}
            minLength='8'
          />
        </FormGroup>
        <Row className='justify-content-between align-items-center'>
          {/* <Col xs='auto'>
          <CustomInput
            id='customCheckRemember'
            label='Remember me'
            checked={remember}
            // onChange={({ target }) => setRemember(target.checked)}
            type='checkbox'
          />
        </Col> */}
          <Col xs='auto'>
            <Link className='fs--1' to={`/authentication/forget-password`}>
              Forget Password?
            </Link>
          </Col>
        </Row>
        <FormGroup>
          <Button color='primary' block className='mt-3' disabled={isDisabled}>
            Log in
          </Button>
        </FormGroup>

        {/* <SocialAuthButtons /> */}
      </Form>
      {showSignUpLink ? (
        <Fragment>
          <Divider className='mt-4'>or Register Now!</Divider>

          <Button color='black' block>
            <Link
              className='text-primary center-align text-underline'
              to='/authentication/register'
            >
              Sign Up
            </Link>
          </Button>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  showSignUpLink: PropTypes.bool,
};

LoginForm.defaultProps = {
  hasLabel: false,
  showSignUpLink: true,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LoginForm);
