import axios from 'axios';
import { axiosSuccessToast, axiosErrorToast } from '../../utils/toastHandler';
import {
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_USER_PRODUCTS,
  UPDATE_USER_PRODUCT,
  PRODUCT_ERROR,
  ADD_PRODUCT_TO_FAV,
  GET_FAVOURITE_PRODUCTS,
  REMOVE_PRODUCT_FROM_FAV,
  SET_PRODUCT_REDUCER_LOADING,
  GET_FILTERED_PRODUCTS,
  DELETE_PRODUCT,
  SELL_PRODUCT,
  RELIST_PRODUCT,
} from '../types';

export const createProduct_Action = (input) => async () => {
  const formData = new FormData();

  // Adding body
  for (var key of Object.keys(input)) {
    if (key === 'images') {
      continue;
    }
    if (key === 'textbookDetails') {
      formData.append(key, JSON.stringify(input[key]));
      continue;
    }
    formData.append(key, input[key]);
  }

  // append images
  [...input.images].map((im) => formData.append('files', im));

  const config = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post(`/api/v2/product/create`, formData, config);
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const updateUserProduct = (id, input) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_REDUCER_LOADING,
    payload: {},
  });

  const formData = new FormData();

  // Adding body
  for (var key of Object.keys(input)) {
    if (key === 'images') {
      continue;
    }
    if (key === 'textbookDetails') {
      formData.append(key, JSON.stringify(input[key]));
      continue;
    }
    formData.append(key, input[key]);
  }

  // append images
  if (input.images) {
    [...input.images].map((im) => formData.append('files', im));
  }

  const config = {
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.put(
      `/api/v2/product/update/${id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_USER_PRODUCT,
      payload: res.data.dataPayload,
    });
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const deleteProduct_Action = (productId) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_REDUCER_LOADING,
    payload: {},
  });
  try {
    const res = await axios.delete(`/api/v2/product/delete/${productId}`);
    dispatch({
      type: DELETE_PRODUCT,
      payload: productId,
    });
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const getProducts = (searchParams = {}) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_REDUCER_LOADING,
    payload: {},
  });
  try {
    //// const res = await axios.get(`/api/v2/product/getAll`);
    const res = await axios.post('/api/v2/product/search/products', {
      searchParams,
    });

    dispatch({
      type: GET_PRODUCTS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

// Search Product
export const searchProduct = (searchParams = {}) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PRODUCT_REDUCER_LOADING,
      payload: {},
    });
    const res = await axios.post('/api/v2/product/search/products', {
      searchParams,
    });

    dispatch({
      type: GET_FILTERED_PRODUCTS,
      payload: { datapayload: res.data.dataPayload },
    });
  } catch (err) {
    dispatch({
      type: PRODUCT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
export const getProduct = (id) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_REDUCER_LOADING,
    payload: {},
  });
  try {
    const res = await axios.get(`/api/v2/product/view/${id}`);
    dispatch({
      type: GET_PRODUCT,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};
export const getUserProducts = (id) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_REDUCER_LOADING,
    payload: {},
  });
  try {
    const res = await axios.get(`/api/v2/product/getUserProducts/${id}`);

    dispatch({
      type: GET_USER_PRODUCTS,
      payload: res.data.dataPayload,
    });
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const getFavProducts_Action = () => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_REDUCER_LOADING,
    payload: {},
  });
  try {
    const res = await axios.get(`/api/v2/product/myfavourites`);

    dispatch({
      type: GET_FAVOURITE_PRODUCTS,
      payload: res.data.dataPayload,
    });
    // axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const favouriteProduct_Action = (productId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v2/product/addToFav/${productId}`);

    dispatch({
      type: ADD_PRODUCT_TO_FAV,
      payload: res.data.dataPayload,
    });

    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};
export const removeFavouriteProduct_Action = (productId) => async (
  dispatch
) => {
  try {
    const res = await axios.put(`/api/v2/product/removeFromFav/${productId}`);

    dispatch({
      type: REMOVE_PRODUCT_FROM_FAV,
      payload: res.data.dataPayload,
    });

    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const sellProduct_Action = (productId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v2/product/sell/${productId}`);
    dispatch({
      type: SELL_PRODUCT,
      payload: res.data.dataPayload,
    });
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};

export const relistProduct_Action = (productId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/v2/product/relist/${productId}`);
    dispatch({
      type: RELIST_PRODUCT,
      payload: res.data.dataPayload,
    });
    axiosSuccessToast(res);
  } catch (err) {
    axiosErrorToast(err);
  }
};
