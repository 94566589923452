export const HOUSING_AD_STATUS = {
  UNCLAIMED_SHOW: 'unclaimed_show',
  UNCLAIMED_HIDDEN: 'unclaimed_hidden',
  CLAIMED_HIDDEN: 'claimed_hidden',
  FREETRIAL_ACTIVE_SHOW: 'freetrial_active_show',
  FREETRIAL_EXPIRED_HIDDEN: 'freetrial_expired_hidden',
  MANUAL_INACTIVE: 'manual_inactive',
  CREATED_PRIVATE_HIDDEN: 'created_private_hidden',
  CREATED_PUBLIC_HIDDEN: 'created_public_hidden',
  PAID_AD_SHOW: 'paid_ad_show',
  PAID_AD_EXPIRED_HIDDEN: 'paid_ad_expired_hidden',
};

export const USER_ROLES = {
  ADMIN: 'admin',
};

export const RESOURCE_TYPES = {
  EXAM: 'exam',
  EXAM_SOLUTION: 'exam_solution',
  TEST: 'test',
  TEST_1: 'test_1',
  TEST_2: 'test_2',
  TEST_SOLUTION: 'test_solution',
  LAB: 'lab',
  SOLUTION: 'solution',
  QUIZ: 'quiz',
  NOTES: 'notes',
  PRESENTATION: 'presentation',
  ASSIGNMENT: 'assignment',
  HOMEWORK: 'homework',
  TUTORIAL: 'tutorial',
  OTHER: 'other',
};

export const LANGUAGE_TYPES = {
  ABKHAZ: 'Abkhaz',
  AFAR: 'Afar',
  AFRIKAANS: 'Afrikaans',
  AKAN: 'Akan',
  ALBANIAN: 'Albanian',
  AMHARIC: 'Amharic',
  ARABIC: 'Arabic',
  ARAGONESE: 'Aragonese',
  ARMENIAN: 'Armenian',
  ASSAMESE: 'Assamese',
  AVARIC: 'Avaric',
  AVESTAN: 'Avestan',
  AYMARA: 'Aymara',
  AZERBAIJANI: 'Azerbaijani',
  BAMBARA: 'Bambara',
  BASHKIR: 'Bashkir',
  BASQUE: 'Basque',
  BELARUSIAN: 'Belarusian',
  BENGALI: 'Bengali',
  BIHARI: 'Bihari',
  BISLAMA: 'Bislama',
  BOSNIAN: 'Bosnian',
  BRETON: 'Breton',
  BULGARIAN: 'Bulgarian',
  BURMESE: 'Burmese',
  CHAMORRO: 'Chamorro',
  CHECHEN: 'Chechen',
  CHINESE: 'Chinese',
  CHUVASH: 'Chuvash',
  CORNISH: 'Cornish',
  CORSICAN: 'Corsican',
  CREE: 'Cree',
  CROATIAN: 'Croatian',
  CZECH: 'Czech',
  DANISH: 'Danish',
  DUTCH: 'Dutch',
  ENGLISH: 'English',
  ESPERANTO: 'Esperanto',
  ESTONIAN: 'Estonian',
  EWE: 'Ewe',
  FAROESE: 'Faroese',
  FIJIAN: 'Fijian',
  FINNISH: 'Finnish',
  FRENCH: 'French',
  GALICIAN: 'Galician',
  GEORGIAN: 'Georgian',
  GERMAN: 'German',
  GUARANÍ: 'Guaraní',
  GUJARATI: 'Gujarati',
  HAITIAN: 'Haitian; Haitian Creole',
  HAUSA: 'Hausa',
  HEBREW: 'Hebrew (modern)',
  HERERO: 'Herero',
  HINDI: 'Hindi',
  HIRI_MOTU: 'Hiri Motu',
  HUNGARIAN: 'Hungarian',
  ICELANDIC: 'Icelandic',
  IDO: 'Ido',
  IGBO: 'Igbo',
  INDONESIAN: 'Indonesian',
  INTERLINGUA: 'Interlingua',
  INUKTITUT: 'Inuktitut',
  INUPIAQ: 'Inupiaq',
  IRISH: 'Irish',
  ITALIAN: 'Italian',
  JAPANESE: 'Japanese',
  JAVANESE: 'Javanese',
  KANNADA: 'Kannada',
  KANURI: 'Kanuri',
  KASHMIRI: 'Kashmiri',
  KAZAKH: 'Kazakh',
  KHMER: 'Khmer',
  KINYARWANDA: 'Kinyarwanda',
  KIRUNDI: 'Kirundi',
  KOMI: 'Komi',
  KONGO: 'Kongo',
  KOREAN: 'Korean',
  KURDISH: 'Kurdish',
  LAO: 'Lao',
  LATIN: 'Latin',
  LATVIAN: 'Latvian',
  LINGALA: 'Lingala',
  LITHUANIAN: 'Lithuanian',
  LUGANDA: 'Luganda',
  MACEDONIAN: 'Macedonian',
  MALAGASY: 'Malagasy',
  MALAY: 'Malay',
  MALAYALAM: 'Malayalam',
  MALTESE: 'Maltese',
  MANX: 'Manx',
  MARSHALLESE: 'Marshallese',
  MONGOLIAN: 'Mongolian',
  MĀORI: 'Māori',
  NAURU: 'Nauru',
  NDONGA: 'Ndonga',
  NEPALI: 'Nepali',
  NORWEGIAN: 'Norwegian',
  NUOSU: 'Nuosu',
  OCCITAN: 'Occitan',
  ORIYA: 'Oriya',
  OROMO: 'Oromo',
  PERSIAN: 'Persian',
  POLISH: 'Polish',
  PORTUGUESE: 'Portuguese',
  PĀLI: 'Pāli',
  QUECHUA: 'Quechua',
  ROMANSH: 'Romansh',
  RUSSIAN: 'Russian',
  SAMOAN: 'Samoan',
  SANGO: 'Sango',
  SARDINIAN: 'Sardinian',
  SERBIAN: 'Serbian',
  SHONA: 'Shona',
  SINDHI: 'Sindhi',
  SLOVAK: 'Slovak',
  SLOVENE: 'Slovene',
  SOMALI: 'Somali',
  SPANISH: 'Spanish',
  SUNDANESE: 'Sundanese',
  SWAHILI: 'Swahili',
  SWATI: 'Swati',
  SWEDISH: 'Swedish',
  TAGALOG: 'Tagalog',
  TAHITIAN: 'Tahitian',
  TAJIK: 'Tajik',
  TAMIL: 'Tamil',
  TATAR: 'Tatar',
  TELUGU: 'Telugu',
  THAI: 'Thai',
  TIGRINYA: 'Tigrinya',
  TSONGA: 'Tsonga',
  TSWANA: 'Tswana',
  TURKISH: 'Turkish',
  TURKMEN: 'Turkmen',
  TWI: 'Twi',
  UKRAINIAN: 'Ukrainian',
  URDU: 'Urdu',
  UZBEK: 'Uzbek',
  VENDA: 'Venda',
  VIETNAMESE: 'Vietnamese',
  VOLAPÜK: 'Volapük',
  WALLOON: 'Walloon',
  WELSH: 'Welsh',
  WOLOF: 'Wolof',
  XHOSA: 'Xhosa',
  YIDDISH: 'Yiddish',
  YORUBA: 'Yoruba',
};

export const LANGUAGE_PROFICIENCY = {
  BASIC: 'basic',
  LIMITED: 'limited',
  INTERMEDIATE: 'intermediate',
  PROFESSIONAL: 'professional',
  NATIVE: 'native',
};

const AVAILABILITY_DAYS = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

export const WEEKDAYS = [...Object.values(AVAILABILITY_DAYS)];

export const AVAILABILITY_TIMES = {
  PRE_9AM: 'pre_9am',
  '9AM_12PM': '9am_12pm',
  '12PM_3PM': '12pm_3pm',
  '3PM_6PM': '3pm_6pm',
  '6PM_9PM': '6pm_9pm',
  AFTER_9PM: 'after_9pm',
};

export const NOTIFICATION_TYPES = {
  REGULAR: 'regular',
  FRIEND_ACTION: 'friend_action',
  FEED_UPDATE: 'feed_update',
  INTERACTION_WITH_MY_POST: 'interaction_with_my_post',
  ANNOUNCEMENT: 'announcement',
  NEW_COMMENT_ON_POST: 'new_comment_on_post',
  NEW_FRIEND_REQUEST: 'new_friend_request',
  FRIEND_REQUEST_ACCEPTED: 'friend_request_accepted',
  NEW_GROUP_JOIN_REQUEST: 'new_group_join_request',
  NEW_GROUP_INVITE: 'new_group_invite',
  GROUP_JOIN_REQUEST_ACCEPTED: 'group_join_request_accepted',
  NEW_POST_REACTION: 'new_post_reaction',
  NEW_CLUB_JOIN_REQUEST: 'new_club_join_request',
  CLUB_JOIN_REQUEST_ACCEPTED: 'club_join_request_accepted',
  SEND_POST_TO_PEER: 'send_post_to_peer',
};
