import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

// libraries
import { Row, Col, Button } from 'reactstrap';
import Lottie from 'react-lottie';
import animationData from '../lottie/celebration.json';

const Success = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Fragment>
      <Row>
        <Col className='text-center'>
          <div className='wizard-lottie-wrapper'>
            <div className='wizard-lottie mx-auto'>
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <h4 className='mb-1'>Your account is all set!</h4>
          <p className='fs-0'>Now you can access all student services</p>
          <Link to={`/feed`}>
            <Button
              color='primary'
              onClick={() => window.location.reload()}
              className='px-5 my-3 text-white'
            >
              Go to Feed
            </Button>
          </Link>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Success;
